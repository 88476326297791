import React, { useEffect, useState, useContext, useCallback } from 'react';
import { TreeItem } from '@mui/lab';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { getPluginByMimeTypeIcon } from '@zert-packages/utils/getPluginByMimeType';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import getTranslation from '@zert-packages/utils/getTranslation.old';
import { useParams } from 'react-router-dom';
import BlockIcon from '@zert-packages/muiadopticons/BlockIcon';
import addId from '@zert-packages/utils/addId';
import { getApiTree, getApiLocalesFilters, getApiPublishingFilters, getApiTreeChild } from '../API';
import SkeletonLoading from './SkeletonLoading';
import { PanelContext } from '../../InfoElementWrapper/InfoElementWrapper';
import { useElementOpen } from '../../../ElementTile/utils/useElementOpen';
import OpenNewWindows from '../../components/OpenNewWindows';

const useStyles = makeStyles((theme) => ({
  treeItemRoot: {
    '& .MuiTreeItem-iconContainer': {
      width: 'fit-content'
    }
  },
  root: {
    marginRight: 5
  },
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '20px 20px 1fr 20px'
    // gridColumnGap: 5,
    // alignItems: 'center',
  },
  imgArrow: {
    height: 15,
    transform: 'rotate(270deg)',
    position: 'relative',
    top: 2
  },
  imgArrowExpend: {
    height: 15,
    display: 'flex',
    alignItems: 'center'
  },
  infoImg: {
    marginRight: 5,
    marginTop: 2,
    '& svg': {
      height: 15
    },
    height: 15,
    display: 'flex',
    alignItems: 'end'
  },
  openImg: {
    width: 18,
    marginLeft: 2
  },
  textTitle: {
    fontSize: 12,
    marginLeft: 3
  },
  textInfo: {
    color: '#918F8F',
    fontSize: 11,
    marginLeft: 4
  }
}));

function TreeItemBranch({ expand, el, setExpanded, selected, structureMain, context }) {
  const cl = useStyles();
  const [children, setChildren] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const { mylocale, formatTemplate } = useSelector((state) => state);
  const { versionId } = useParams();
  const { setValues, values } = context || useContext(PanelContext);
  const { publishingFilters } = useSelector((state) => state);
  const [publishingFiltersTranslation, setPublishingFiltersTranslation] = useState(null);
  const [loading, setLoading] = useState(false);
  const { open } = useElementOpen();
  const blockClick = values?.blockClickCreateItemsBlock;
  const type = el?.elementInfo?.mimeType?.split('/').pop();
  const notPhrase = type !== 'zert-phrase';
  const [getChildren, setGetChildren] = useState(true);

  const findPublishingFilters = () => {
    const result = publishingFilters.reduce((acu, cur) => {
      const findFilterType = el.publishingFilters.find((item) => item.filterIdentifier === cur.identifier);
      if (findFilterType) {
        const findFilterChild = cur.items.filter(
          (item) => item.identifier === findFilterType.filterItemIdentifiers.find((child) => child === item.identifier)
        );
        return [...acu, ...findFilterChild];
      }
      return [...acu, cur];
    }, []);
    if (result) {
      setPublishingFiltersTranslation(result.filter((el) => !el.items));
    }
  };

  useEffect(() => {
    if (publishingFilters && el.publishingFilters && el.publishingFilters.length > 0) {
      findPublishingFilters();
    }
  }, [publishingFilters, el]);

  const getChild = async (el, expandedFalse) => {
    if (children || !notPhrase) {
      return;
    }
    if (!getChildren) {
      return selectedTreeItem();
    }
    setLoading(true);
    setGetChildren(false);
    let childesFromApi = el.parentsTree
      ? await getApiTreeChild(el.elementInfo.versionId)
      : await getApiTree(el.elementInfo.versionId, mylocale);
    if (childesFromApi && el.parentsTree) {
      childesFromApi = Object.values(childesFromApi)
        .flat()
        .map((item) => ({
          ...item,
          elementInfo: item,
          parentsTree: true,
          identifier: item.id
        }));
    }
    if (childesFromApi && childesFromApi.length > 0) {
      if (children) {
        const findChild = children.find((item) => item.parentIdentifier === el.elementInfo.versionId);
        if (!findChild) {
          setChildren((prev) => [
            ...prev,
            ...childesFromApi.map((it) => ({
              ...it,
              parentIdentifier: el.elementInfo.versionId
            }))
          ]);
        }
      } else {
        setChildren(addId(childesFromApi.map((it) => ({ ...it, parentIdentifier: el.elementInfo.versionId }))));
      }
    }
    if (expandedFalse) {
      return setLoading(false);
    }
    selectedTreeItem();
    setLoading(false);
  };

  const handleActiveTreeBranch = async () => {
    const findActiveBranch = expand.find((it) => it === el.identifier);
    if (findActiveBranch) {
      await getChild(el, true);
    }
  };

  const getFormat = (item) => {
    const template = formatTemplate.blockFormats.find((el) => el.identifier === item);

    if (template != null) {
      return getTranslation(template.label);
    }
    return null;
  };

  const getFilters = async (item) => {
    if (!structureMain || blockClick) {
      return;
    }
    await handleChild();
    if (item.identifier && !el.parentsTree) {
      try {
        const resLocalesFilters = await getApiLocalesFilters(
          versionId,
          item.elementInfo.versionId,
          item.identifier.replace(/['[']/g, '(').replace(/['\]']/g, ')')
        );
        if (resLocalesFilters) {
          setValues((prev) => ({ ...prev, localesFilters: resLocalesFilters }));
        }
      } catch (e) {
        console.log('Error getApiLocalesFilters', e.message);
      }
      if (values?.treeItem?.identifier) {
        try {
          const resPublishingFilters = await getApiPublishingFilters(
            versionId,
            item.elementInfo.versionId,
            `${
              values.treeItem.identifier
                .replace(/['[']/g, '(')
                .replace(/['\]']/g, ')')
                .split(' ')[0]
                .split(')')[0]
            })`
          );
          setValues((prev) => ({ ...prev, publishingFilters: resPublishingFilters }));
        } catch (e) {
          console.log('Error getApiPublishingFilters', e.message);
        }
      }
    }
    setValues && setValues((prev) => ({ ...prev, treeItem: item, getTreeItemChild: item }));
  };

  const handleChild = async () => {
    if (blockClick) {
      return;
    }
    await getChild(el);
  };

  const selectedTreeItem = () => {
    if (expand.length > 0) {
      const findExpand = expand.find((item) => item === el.identifier);
      if (findExpand) {
        setExpanded((prev) => [...prev.filter((item) => item !== el.identifier)]);
      } else {
        setExpanded((prev) => [...prev, el.identifier]);
      }
    } else {
      setExpanded([el.identifier]);
    }
  };

  const treeItemRef = useCallback(
    (node) => {
      if (node && selected === el.identifier) {
        node.scrollIntoView({
          behavior: 'smooth'
          // block: 'center',
        });
      }
    },
    [selected]
  );

  useEffect(() => {
    if (!values?.treeItemDelete) {
      return;
    }
    if (values.treeItemDelete.identifier === el.identifier || selected === el.identifier) {
      if (el.identifier === values.treeItemDelete.identifier) {
        return setChildren(null);
      }
      setChildren((pre) => pre.filter((it) => it.identifier !== values.treeItemDelete?.identifier));
    }
  }, [values?.treeItemDelete]);

  if (values?.treeItemDelete?.identifier === el.identifier) {
    return;
  }

  return (
    <>
      {loading && el ? (
        <SkeletonLoading />
      ) : (
        <TreeItem
          className={cl.treeItemRoot}
          nodeId={el.identifier}
          label={
            <div
              className={cl.root}
              onClick={() => getFilters(el)}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
            >
              <div className={cl.wrapper}>
                <span className={expand.find((item) => item === el.identifier) ? cl.imgArrowExpend : cl.imgArrow}>
                  {notPhrase && <ArrowDropDownIcon />}
                </span>
                <span className={cl.infoImg}>
                  {el.elementInfo && el.elementInfo.mimeType && (
                    <BlockIcon svg={getPluginByMimeTypeIcon(el.elementInfo.mimeType, true)} />
                  )}
                  {/* {el.elementInfo && el.elementInfo.mimeType && getPluginByMimeTypeIcon(el.elementInfo.mimeType)} */}
                </span>
                <span
                  ref={treeItemRef}
                  style={{ color: selected === el.identifier ? '#01A1C7' : '' }}
                  className={cl.textTitle}
                >
                  {el?.localeFilter?.length > 0 && `(+${el.localeFilter.map((el) => el.language)}) `}
                  {publishingFiltersTranslation && (
                    <span>
                      (+
                      {publishingFiltersTranslation.map((item, i) => (
                        <span key={i}>{getTranslation(item.label)}</span>
                      ))}
                      =
                    </span>
                  )}
                  {el?.elementInfo?.name}
                </span>
                {selected === el.identifier || (isHovered && <OpenNewWindows el={el} blockClick={blockClick} />)}
              </div>
              <div className={cl.wrapper}>
                <div />
                <div />
                <div className={cl.textInfo}>
                  {formatTemplate &&
                    el.elementInfo &&
                    el.elementInfo?.properties &&
                    getFormat(Object.values(el.elementInfo?.properties)[1])}
                </div>
              </div>
            </div>
          }
        >
          {children &&
            children
              .filter((item) => item.parentIdentifier === el.elementInfo.versionId)
              .map((item, i) => (
                <TreeItemBranch
                  context={context}
                  key={`${el.elementInfo.versionId} ${i} ${el?.elementInfo?.changedAt}`}
                  structureMain={structureMain}
                  expand={expand}
                  el={item}
                  setExpanded={setExpanded}
                  selected={selected}
                />
              ))}
        </TreeItem>
      )}
    </>
  );
}

export default TreeItemBranch;
