import React, { useContext, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import { TabsContext } from "../../Stylesheet";
import Loading from "../../Loading";
import TemplateItemRow from "../../TemplateItemRow";
import { adobeColor, adobeRgba, handleTableSingleLevel, handleType } from "../../utils/helpers";
import { fontData } from "../../data/fontData";
import SelectedColor from "../../SelectedColor";
import DialogColors from "../../dialog/DialogColors";
import { textData, userTextData } from "../../data/text/textData";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridRowGap: 10
  },
  wrapperDropMenu: {
    marginBottom: 10
  },
  wrapperRow: {
    display: "grid",
    gridTemplateColumns: "130px 1fr",
    gridColumnGap: 10
  },
  title: {
    fontWeight: 700
  },
  wrapperCheckbox: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr ",
    maxWidth: 500
  },
  wrapperTemplate: {
    height: "Calc(100% - 88px)"
  },
  templateInput: {
    height: '100%',
    width: '100%',
    marginBottom: 0,
    '& .MuiInputBase-multiline.MuiInputBase-marginDense': {
      height: '100%'
    },
    "& textarea": {
      height: '100% !important'
    },
    '& .MuiInputBase-root': {
      height: '100%',
    },
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100% !important'
    }
  }
}));

function Text() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const text = values.stylesheet.textSettings[values.selectedLeftMenu];
  const [selectedTable, setSelectedTable] = useState("");
  const [selectedContentLimits, setSelectedContentLimits] = useState("User defined");
  const [color, setColor] = useState({
    backgroundColor: '#ffffff',
    color: '#ffffff',
    load: false,

  })

  const handleBlockTabs = (val) => {
    if (val === selectedTable) {
      return;
    }
    if (val === "Normal") {
      handleType(userTextData, "textSettings", values, setValues);
    }
    if (val === "User defined") {
      handleType(textData, "textSettings", values, setValues);
    }
    setSelectedTable(val);
  };

  const handleValuesBlock = (val, name) => {
    const result = { ...text.fontSize, unit: text.fontSize ? text.fontSize.unit : "pt", value: +val };
    handleTableSingleLevel(result, name, "textSettings", values, setValues);
  };

  const setDropMenuFirst = (val, name) => {
    if (val === "Default") {
      const result = { unit: "pt", value: 12 };
      handleTableSingleLevel(result, name, "textSettings", values, setValues);
    }
    setSelectedContentLimits(val);
  };

  const handleSelectedDropMenu = (valDropMenu, name, val) => {
    const result = { unit: valDropMenu, value: +val };
    handleTableSingleLevel(result, name, "textSettings", values, setValues);
  };

  const findCheckbox = (el) => {
    if(!text.fontStyle){
      return false
    }
    return text.fontStyle.map((it) => it.toLowerCase()).find((it) => it === el.toLowerCase());
  };

  const handleCheckboxFontStyle = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    if (val) {
      const result = text.fontStyle ? [ ...text.fontStyle, name.toUpperCase()] : [name.toUpperCase()];
      return handleTableSingleLevel(result, "fontStyle", "textSettings", values, setValues);
    }
    const result = text.fontStyle.filter((el) => el !== name.toUpperCase());
    handleTableSingleLevel(result, "fontStyle", "textSettings", values, setValues);
  };

  const handleOpenDialog = (type) => {
    renderInExceptionRoot(DialogColors, {
      color: text[type],
      title: "Select Color",
      setText: handleColor,
      type
    });
  };

  const handleColor = (val, name) => {
    setColor(pre => ({ ...pre, [name]:  val}))
    handleTableSingleLevel(adobeRgba(val), name, "textSettings", values, setValues);
  };

  const handleXSL = (e) => {
    const val = e.target.value;
    handleTableSingleLevel(val, "template", "textSettings", values, setValues);
  };

  useEffect(() => {
    if (!text.fontSize) {
      setSelectedContentLimits("Default");
    }
  }, [text]);

  useEffect(() => {
    if (text.class) {
      const getType = text.class.split("$Client")[1].replace("TextSettingsTemplate", "");
      if (getType === "Normal") {
        setSelectedTable("Normal");
      } else {
        setSelectedTable("User defined");
      }
    }
  }, [values.selectedLeftMenu]);

  useEffect(() => {
    if(!color.load && text.backgroundColor || text.color){
      setColor(pre => ({
        ...pre,
        backgroundColor: adobeColor(text.backgroundColor),
        color: adobeColor(text.color),
      }))
    }
  }, [text.backgroundColor])



  return (
    <>
      {text && text.class.includes("TextSettings") ?
        <>
          <div className={cl.wrapperDropMenu}>
            <ExpanderItem
              valueProps={selectedTable}
              propsLabel={["Normal", "User defined"]}
              setProps={handleBlockTabs}
            />
          </div>
          {selectedTable === "Normal" ?
            <div className={cl.root}>
              <div className={cl.wrapperRow}>
                <span className={cl.title}> Font family:</span>
                <ExpanderItem valueProps={text.fontFamily} type="type" />
              </div>
              <TemplateItemRow
                label="Font size:"
                type="fontSize"
                value={{
                  value: text.fontSize ? text.fontSize.value : "12",
                  unit: text.fontSize ? text.fontSize.unit : "POINTS"
                }}
                grid="130px 150px 1fr 55px"
                dropMenuFirst={{
                  label: ["Default", "User defined"],
                  selected: selectedContentLimits
                }}
                setDropMenu={handleSelectedDropMenu}
                setValueProps={handleValuesBlock}
                setDropMenuFirst={setDropMenuFirst}
                disabled={selectedContentLimits === "Default"}
              />

              <div className={cl.wrapperRow}>
                <span className={cl.title}>Font style:</span>
                <div className={cl.wrapperCheckbox}>
                  {fontData.map((el, i) => (
                    <div key={i}>
                      <FormControlLabel
                        control={
                          <Checkbox onChange={handleCheckboxFontStyle} checked={findCheckbox(el.name)} name={el.name} />
                        }
                        label={<span>{el.name}</span>}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <SelectedColor
                label="Color:"
                type="color"
                setValueProps={handleColor}
                handleOpenDialog={handleOpenDialog}
                style={{ grid: "130px 30px 1fr 25px" }}
                valueProps={color.color}
              />
              <SelectedColor
                label="Background color:"
                type="backgroundColor"
                setValueProps={handleColor}
                handleOpenDialog={handleOpenDialog}
                style={{ grid: "130px 30px 1fr 25px" }}
                valueProps={color.backgroundColor}
              />
            </div>
            : <div className={cl.wrapperTemplate}>
              <p className={cl.title}>template:</p>
              <TextField
                className={cl.templateInput}
                value={text.template}
                variant="outlined"
                multiline
                minRows={4}
                onChange={handleXSL}
              />
            </div>
          }
        </>
       :  <Loading /> }
    </>
  );
}

export default Text;
