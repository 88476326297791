import React, {useState} from "react";
import {Button, Checkbox, FormControlLabel, Tab, Tabs} from "@mui/material";
import FilterMenu from "./FilterMenu";
import {getTranslation} from "@zert-packages/utils";
import {usePublishingFilterStyles} from "./styles";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

const BoxItem = ({key, childCondition, changeChildData, categoryItems, deleteChildCondition}) =>{
    const cl = usePublishingFilterStyles()
    const [openPublishingFilterMenu, setOpenPublishingFilterMenu] = useState(null)

    const handleOpenFilter = () => {
        changeChildData({
            ...childCondition,
            checked: true
        })
    }

    const handleCloseFilter = () => {
        changeChildData({
            ...childCondition,
            checked: false
        })
    }


    return (
        <div key={key} style={{marginTop: '10px'}}>
            {!childCondition.checked &&
                <div style={{borderLeft: "1px solid grey", marginLeft: "10px"}} onClick={() => handleOpenFilter()}>
                    <div className={cl.boxTitleItem}>
                        <div>
                            {`${childCondition.category?.label.defaultLabel} ${childCondition.filterType === "INCLUDE" ? "IS" : "IS NOT"}`}
                        </div>
                        <div>
                            {`${childCondition.selectedCategoryItem?.label.defaultLabel}`}
                        </div>
                        <div className={cl.arrow}/>
                    </div>
                </div>
            }
            {!!childCondition.checked && <div className={cl.wrapperBoxItem} style={{borderLeft: "1px solid grey", marginLeft: "10px"}}>
                <FilterMenu anchorEl={openPublishingFilterMenu}
                            onClose={() => setOpenPublishingFilterMenu(null)}
                            onOpen={(e) => setOpenPublishingFilterMenu(e.currentTarget)}
                            selectedOption={childCondition.category}
                            options={categoryItems}
                            onChoseOption={(newCategory) => {
                                changeChildData({
                                    ...childCondition,
                                    category: newCategory,
                                    categoryItems: newCategory.items
                                })
                                setOpenPublishingFilterMenu(null)
                            }}
                            isOptionsTranslatable
                />

                <Tabs
                    indicatorColor=""
                    value={childCondition.filterType}
                    onChange={(newFilterType, value) => {
                        changeChildData({
                            ...childCondition,
                            filterType: value
                        })
                    }}
                    textColor="primary"
                    variant="standard"
                    scrollButtons="auto"
                    centered
                >
                  <Tab value={"INCLUDE"} label="Include for"/>
                  <Tab value={"EXCLUDE"} label="Exclude for"/>
                </Tabs>


                {childCondition && childCondition.category && (<RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={childCondition?.selectedCategoryItem?.identifier}
                    onChange={(event) => {
                        changeChildData({
                            ...childCondition,
                            selectedCategoryItem: childCondition.categoryItems.find(categoryItem => categoryItem.identifier === event.target.value)
                        })
                    }}
                >
                    {childCondition.categoryItems.map((el, i) => (
                            <div key={i} className={cl.wrapperCheckbox}>
                                <FormControlLabel
                                    value={el.identifier && el.identifier}
                                    control={<Radio/>}
                                    label={<span className={cl.checkboxLabel}>{el.label && getTranslation(el.label)}</span>}
                                />
                            </div>
                        ))}
                </RadioGroup>)}

                <div className={cl.wrapperButton}>
                  <Button onClick={() => {
                      if(childCondition.category && childCondition.selectedCategoryItem) {
                          handleCloseFilter()
                      }
                  }}>OK</Button>
                  <Button onClick={() => {
                      deleteChildCondition()
                  }}>Delete</Button>
                </div>
            </div>}
            <div className={cl.arrow}/>
        </div>
    )
}

export default BoxItem