import React, { useState, useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import VersionController from '../../common/VersionController';
import BurgerMenu from './BurgerMenu';
import { adobeTabs } from '../helpers/findActiveLocales';

const useStyles = makeStyles({
  appBar: {
    flex: '1'
  },
  wrapper: {},
  toolsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 5
    // maxWidth: "45vw",
    // overflowX: "auto",
    // "@media (max-width: 1100px)": {
    //   maxWidth: "40vw"
    // }

    // '@media (min-width: 1500px) and  (max-width: 1700px)': {
    //   maxWidth: "30vw",
    // },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '15px',
    color: 'white',
    marginTop: 2
  },
  item: {
    margin: '0 10px',
    display: 'grid',
    gridTemplateColumns: '0.2fr 1fr'
  },
  icon: {
    margin: '0 5px'
  },
  tabsItem: {
    '& div': {
      minWidth: 'max-content'
    },
    '& span': {
      fontSize: 12
    }
  }
});

const dataSizeTabs = {
  width: null,
  size: 100
}

function ToolBarElements({
  tabs,
  tabIndex,
  onChangeTabIndex,
  onSaveVersion,
  onApproveVersion,
  onCreateNewVersion,
  onPreview,
  onReport,
  onExport,
  canCreateVersion,
  canSaveVersion,
  canApproveVersion,
  canPreview,
  canReport,
  canExport,
  canShowNextTask,
  versionId,
  findBlock,
  setValues,
  whyDisabledText
}) {
  const cl = useStyles();
  const [tabsVales, setTabsVales] = useState({
    find: false
  });
  const [sizeTabs, setSizeTabs] = useState(dataSizeTabs);
  const ref = useRef(null);

  const handleResize = () => {
    const width = ref.current.offsetWidth;
    const size = adobeTabs(width);
    setSizeTabs((pre) => ({
      ...pre,
      width,
      size
    }));
  };

  useEffect(() => {
    if (ref.current) {
      handleResize();
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref.current, tabs]);





  return (
    <div className={cl.appBar} ref={ref} id="1111">
      <div className={cl.wrapper}>
        <div className={cl.container}>
          <div>
            <div className={cl.toolsWrapper}>
              {tabs.slice(0, sizeTabs.size).map((tab, index) => (
                <div key={index} className={cl.tabsItem}>
                  {tab.label}
                </div>
              ))}
              {sizeTabs.size < 0 && (
                <div style={{ marginLeft: 5 }}>
                  <BurgerMenu dropMenu={tabs.slice(sizeTabs.size)} />
                </div>
              )}
            </div>
          </div>
          <VersionController
            {...{
              onSaveVersion,
              onApproveVersion,
              onCreateNewVersion,
              onPreview,
              onReport,
              onExport,
              canCreateVersion,
              canSaveVersion,
              canApproveVersion,
              canPreview,
              canReport,
              canExport,
              canShowNextTask,
              versionId,
              whyDisabledText
            }}
          />
        </div>
      </div>
      {findBlock && tabsVales.find && { ...findBlock, props: { ...findBlock.props, setTabsVales } }}
    </div>
  );
}

export default ToolBarElements;
