import React, { useContext, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Structure from './structure/Structure';
import Styles from './tabs/stylesTabs/Styles';
import { TabsContext } from '../../../Stylesheet';

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    minHeight: 18,
    marginBottom: 15,
    '& .MuiTab-root': {
      padding: '0px 4px 0px 3px',
      minWidth: 'fit-content',
      minHeight: 18,
      background: '#EBEBEB',
      textTransform: 'capitalize'
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#ffffff',
      background: '#4db1d3',
      outline: 'none'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left',
      background: '#E3F2F7'
    }
  }
}));

function TableContents() {
  const cl = useStyles();
  const tabs = ['Structure', 'Style'];
  const { values } = useContext(TabsContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const structure = values.stylesheet.blockSettings[values.selectedLeftMenu];

  const handleChangeSelectedTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      {tabs && (
        <Tabs
          indicatorColor=""
          className={cl.tabsRoot}
          value={selectedTab}
          onChange={handleChangeSelectedTab}
          textColor="primary"
          variant="standard"
          scrollButtons="auto"
          centered
        >
          {tabs.map((el, i) => (
            <Tab label={el} key={i} />
          ))}
        </Tabs>
      )}
      {structure && structure.class.includes('TableOfContents') && (
        <>
          {selectedTab === 0 && <Structure />}
          {selectedTab === 1 && <Styles />}
        </>
      )}
    </>
  );
}

export default TableContents;
