import React, { memo, useState } from 'react';
import { TextField, List, ListItem as MListItem, ListItemText, Typography, ListItemIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import CheckIcon from '@mui/icons-material/Check';
import PendingIcon from '@mui/icons-material/Pending';
import RemoveIcon from '@mui/icons-material/Remove';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import { renderInExceptionRoot } from '@zert-packages/utils';
import getTranslation from '@zert-packages/utils/getTranslation.new';
import DemandEditorDialog from '../../AdministrationPlugin/components/DemandsTemplateManager/DemandDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '56px 1fr',
    overflow: 'hidden',
    height: '100%'
  },
  list: {
    overflow: 'auto',
    padding: 10
  },
  listItem: {
    cursor: 'pointer',
    marginTop: 20,
    borderRadius: 5,
    background: 'white',
    display: 'grid',
    gridGap: 5,
    gridTemplateColumns: '60px 0.5fr 4fr auto',
    alignItems: 'center',
    '@media (max-width: 780px)': {
      gridTemplateColumns: '1fr auto',
      gridTemplateRows: 'auto auto'
    }
  },
  selectedListItem: {
    boxShadow: `0 0 4px 1px ${theme.palette.primary.main}`
  },
  listItemName: {
    '@media (max-width: 780px)': {
      gridColumn: '-1 / 1'
    }
  },
  iconTypography: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const filterCompareCallback = (item, value) => {
  return (
    item.name.toLowerCase().includes(value.toLowerCase()) ||
    (item.number && item.number.defaultLabel && item.number.defaultLabel.toLowerCase().includes(value.toLowerCase()))
  );
};

const getFilteredItems = (items, value) => {
  if (value.trim().length === 0) return items;
  return items.filter((i) => filterCompareCallback(i, value));
};

const replaceWith = (template, demand) => {
  const me = Object.keys(template.replaceMap).filter((item) => {
    return demand.elementId == template.replaceMap[item];
  });

  if (me) {
    return template.demands.filter((demand) => {
      return demand.elementId == me;
    });
  }
  return [];
};

const replacedWith = (template, demand) => {
  const item = Object.keys(template.replaceMap).find((item) => demand.elementId == item);

  if (item != null) {
    return template.demands.filter((demand) => {
      return demand.elementId == template.replaceMap[item];
    });
  }
  return [];
};

const listDemands = (demandList, showName) => {
  return demandList.reduce((acu, cur) => {
    const name = showName ? getTranslation(cur.label) : '';
    if (!acu.length) {
      return `${getTranslation(cur.number)} ${name}`;
    }
    `${acu}, ${getTranslation(cur.number)} ${name}`;
  }, '');
};

export const getReplaceString = (template, demand, onlyReplace) => {
  const replaceList = replaceWith(template, demand);
  if (replaceList.length > 0) {
    return onlyReplace
      ? `Replace ${listDemands(replaceList, onlyReplace)}`
      : `${listDemands(replaceList, onlyReplace)}`;
  }
  if (onlyReplace) {
    return null;
  }
  const replacedList = replacedWith(template, demand);
  if (replacedList.length > 0) {
    return `Replaced by ${listDemands(replacedList)}`;
  }
  return '';
};

export const getReplaceItem = (template, demand) => {
  const replaceList = replaceWith(template, demand);
  if (replaceList.length > 0) {
    return replaceList[0];
  }

  const replacedList = replacedWith(template, demand);
  if (replacedList.length > 0) {
    return replacedList[0];
  }
  return null;
};

function LongSelectList({
  disabled,
  activeItems,
  availableItems,
  setActiveItems,
  template,
  showReplaceText,
  onConfirm
}) {
  const cl = useStyles();
  const [filterValue, setFilterValue] = useState('');
  const onSelect = (item, active) => {
    if (disabled) return;
    if (active) setActiveItems((p) => p.filter((i) => i.info.elementUUID !== item.info.elementUUID));
    else setActiveItems((p) => [...p, item]);
    if (onConfirm) {
      onConfirm({
        ...item,
        demandStatus: !active ? 'ACTIVE' : 'REMOVED',
        replacedWith:
          !active && template.replaceMap.hasOwnProperty(item.elementId) ? template.replaceMap[item.elementId] : null
      });
    }
  };

  return (
    <div className={cl.root}>
      <TextField
        variant="standard"
        onChange={(e) => setFilterValue(e.target.value)}
        value={filterValue}
        label={<FormattedMessage id="RMP.SelectList.QuickSearch" defaultMessage="Quick search" />}
      />
      <List dense disablePadding className={cl.list}>
        {getFilteredItems(availableItems, filterValue).map((item) => {
          const active = !!activeItems.find((i) => i.info.elementUUID === item.info.elementUUID);
          const replaceItem = template ? getReplaceItem(template, item) : null;
          return (
            <ListItem
              disabled={disabled}
              key={item.info.id}
              onClick={
                template
                  ? () => {
                      renderInExceptionRoot(DemandEditorDialog, {
                        demand: item,
                        template,
                        status:
                          template && template.demandsMap.hasOwnProperty(item.elementId)
                            ? template.demandsMap[item.elementId]
                            : null,
                        onConfirm
                      });
                    }
                  : null
              }
              active={active}
              name={item.label ? getTranslation(item.label) : null}
              onSelect={() => onSelect(item, active)}
              pending={
                template && template.demandsMap.hasOwnProperty(item.elementId)
                  ? template.demandsMap[item.elementId] == 'PENDING'
                  : false
              }
              number={item.number ? getTranslation(item.number) : null}
              replaceText={showReplaceText ? getReplaceString(template, item) : null}
              onReplaceClick={
                replaceItem
                  ? () => {
                      renderInExceptionRoot(DemandEditorDialog, {
                        demand: replaceItem,
                        template,
                        status: template.demandsMap.hasOwnProperty(replaceItem.elementId)
                          ? template.demandsMap[replaceItem.elementId]
                          : null,
                        onConfirm
                      });
                    }
                  : null
              }
            />
          );
        })}
      </List>
    </div>
  );
}

export default memo(
  LongSelectList,
  (prevProps, nextProps) =>
    prevProps.activeItems === nextProps.activeItems && prevProps.availableItems === nextProps.availableItems
);

const ListItem = memo(
  ({ disabled, name, number, active, onSelect, replaceText, onReplaceClick, onClick, pending }) => {
    const cl = useStyles();
    const color = active ? 'primary' : 'textSecondary';
    return (
      <MListItem disabled={disabled} className={cn(cl.listItem, { [cl.selectedListItem]: active })}>
        <ListItemIcon onClick={onSelect}>
          <Typography className={cl.iconTypography} color={color}>
            {active ? (
              <CheckIcon fontSize="large" color="inherit" />
            ) : !pending ? (
              <RemoveIcon fontSize="large" color="inherit" />
            ) : (
              <PendingIcon fontSize="large" color="inherit" />
            )}
          </Typography>
        </ListItemIcon>
        <ListItemText onClick={onClick}>
          <Typography variant="overline" color={color}>
            {number}
          </Typography>
        </ListItemText>
        <ListItemText onClick={onClick} className={cl.listItemName}>
          <Typography variant="button" color={color}>
            {name}
          </Typography>
        </ListItemText>
        {replaceText != null && (
          <ListItemText onClick={onReplaceClick} className={cl.listItemName}>
            <Typography variant="button" color={color}>
              {replaceText}
            </Typography>
          </ListItemText>
        )}
      </MListItem>
    );
  },
  selectiveMemoComparison(
    (p) => p.name,
    (p) => p.number,
    (p) => p.active,
    (p) => p.disabled
  )
);
