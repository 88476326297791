import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import withSearch, { styles, controlStyles } from '../withSearch';

import SearchComponent from './SearchComponent';

class VersionSearch extends SearchComponent {
  constructor(props) {
    super(props);

    /* if (!this.state || !this.state.Filter) {
      this.state = {
        Filter: 'current',
      };
      this.setStatus({Filter: 'current'});
    } */

    this.handleChange = this.handleChange.bind(this);
    this.textInput = React.createRef();
  }

  /*  componentDidUpdate(prev) {
    if (!this.state || !this.state.Filter) {
    /!*  this.state = {
        Filter: 'current',
      };*!/
      this.setStatus({Filter: 'current'});
    }
  } */

  handleChange = (name) => (event) => {
    this.setStatus({ [name]: event.target.value });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    this.props.expanded && this.textInput.current ? this.textInput.current.blur() : this.textInput.current.focus();
  }

  render() {
    //  this.props.handleChange(this.state);
    const { classes } = this.props;
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel htmlFor="version-filter">
            <FormattedMessage id="search.version.type" defaultMessage="Version" />
          </InputLabel>
          <Select
            autoFocus
            inputRef={this.textInput}
            value={this.state.Filter}
            size="small"
            onChange={(e) => {
              this.setStatus({ Filter: e.target.value });
            }}
            inputProps={{
              name: 'version-filter',
              id: 'version-filter'
            }}
          >
            <MenuItem value="current">
              <em>
                <FormattedMessage id="search.version.current" defaultMessage="Current version" />
              </em>
            </MenuItem>
            <MenuItem value="root">
              <em>
                <FormattedMessage id="search.version.rootversion" defaultMessage="Root version" />
              </em>
            </MenuItem>
            <MenuItem value="all">
              <em>
                <FormattedMessage id="search.version.allversions" defaultMessage="All versions" />
              </em>
            </MenuItem>
            <MenuItem value="approved">
              <em>
                <FormattedMessage id="search.version.approvedversions" defaultMessage="Approved versions" />
              </em>
            </MenuItem>
          </Select>
        </FormControl>
      </form>
    );
  }
}

VersionSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.version.header" defaultMessage="Search by version type" />,
  index: 'search-version',
  className: 'se.zert.backend.search.VersionCriteria',
  default: {
    Filter: 'current'
  }
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles)(VersionSearch), selectData))
);
