import React from 'react';
import {TASK_ACTIONS_TYPES} from './ELEMENT_TILE_CONTS';

const useElementActions = ({ selected, setDialog, setParamsGroup, setOpenIFS }) => {
  const changeType = (id) => {
    setDialog({ open: true, type: id });
  };

  const changeOpen = (id, value) => {
    setOpenIFS((prevState) => ({
      ...prevState,
      open: true
    }));
  };

  const paramsGroup = (versionId) => {
    setParamsGroup((prevState) => ({
      ...prevState,
      doubleClick: true,
      version: versionId,
      checkout: false,
      loadItems: true
    }));
  };

  const performActionHandler = (selected) => (actionId) => {
    if (actionId === TASK_ACTIONS_TYPES.newImage) return changeType(actionId);
    if (actionId === TASK_ACTIONS_TYPES.removeImage) return changeType(actionId);
    if (actionId === TASK_ACTIONS_TYPES.edit) return changeType(actionId);
    if (actionId === TASK_ACTIONS_TYPES.newHotspot) return changeType(actionId);
    if (actionId === TASK_ACTIONS_TYPES.open) return paramsGroup(selected[0].elementInfo.versionId);
    if (actionId === TASK_ACTIONS_TYPES.DOPFromIFS) return changeOpen(actionId, true);
  };

  const elementActions = [
    // ...(selected?.length > 0 ? NEW_IMAGE_ACTIONS : []),
    // ...(selected?.length > 0 ? REMOVE_IMAGE_ACTIONS : []),
    // ...(selected?.length > 0 ? EDIT_ACTIONS : []),
    // ...(selected?.length > 0 ? NEW_HOTSPOT_ACTIONS : []),
    // ...(selected?.length > 0 ? OPEN_ACTIONS : []),
    // ...(selected?.length > 0 ? DOP_FROM_IFS_ACTIONS : [])
  ];

  const toolbarActions = [
    // ...(selected?.length > 0 ? NEW_IMAGE_ACTIONS : []),
    // ...(selected?.length > 0 ? REMOVE_IMAGE_ACTIONS : []),
    // ...(selected?.length > 0 ? EDIT_ACTIONS : []),
    // ...(selected?.length > 0 ? NEW_HOTSPOT_ACTIONS : []),
    // ...(selected?.length > 0 ? OPEN_ACTIONS : []),
    // ...(selected?.length > 0 ? DOP_FROM_IFS_ACTIONS : [])
  ];

  const getMenuActions = (row) => [
    // ...NEW_IMAGE_ACTIONS,
    // ...REMOVE_IMAGE_ACTIONS,
    // ...EDIT_ACTIONS,
    // ...NEW_HOTSPOT_ACTIONS,
    // ...OPEN_ACTIONS,
    // ...DOP_FROM_IFS_ACTIONS
  ];

  return { elementActions, toolbarActions, getMenuActions, performActionHandler };
};

export default useElementActions;
