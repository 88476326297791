import React from 'react';
import { FormattedMessage } from 'react-intl';
import { handlePluginAction } from '@zert-packages/actions/coreReducers';
import { createInProgressSnack } from '../../common/snackBar';
import { storeBlockApi, updateBlockApi, updateTableApi } from '../components/API';

export const findDisabled = (values, setCheckboxState) => {
  if (!values.treeItem || (values.treeItem && values.treeItem.parentTreeItem)) {
    return setCheckboxState(true);
  }
  setCheckboxState(false);
};

export const saveBlock = async (snackbar, dispatch, values, mylocale, saveAllItem) => {
  const item = values.parentItemChild;

  if (item?.elementInfo?.approvedAt) {
    return;
  }

  const generatingSnackbar = createInProgressSnack(
    snackbar,
    <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..." />
  );
  if (saveAllItem) {
    await saveAllItem();
  }

  const createBlockData = {
    ...item,
    name: values.valuesName,
    elementInfo: {
      ...item.elementInfo,
      name: values.valuesName
    }
  };

  await storeBlockApi(item.elementInfo.versionId, mylocale, createBlockData);
  dispatch(handlePluginAction(null));
  snackbar.closeSnackbar(generatingSnackbar);
};

export const updateElement = async (snackbar, dispatch, elementInfo, values, mylocale) => {
  if (elementInfo?.approvedAt) {
    return;
  }

  const generatingSnackbar = createInProgressSnack(
    snackbar,
    <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..." />
  );

  const mimeType = elementInfo.mimeType.split('/').pop();

  if (mimeType === 'zert-block' || mimeType === 'zert-image') {
    await updateBlockApi(elementInfo.versionId, mylocale, values);
  }

  if (mimeType === 'zert-table') {
    await updateTableApi(elementInfo.versionId, mylocale, values);
  }

  dispatch(handlePluginAction(null));
  snackbar.closeSnackbar(generatingSnackbar);
};
