import { FormattedMessage } from 'react-intl';
import React from 'react';
import { getPlugins } from '@zert-packages/actions/api';
import {
  TableView,
  CalculateOutlined,
  FormatListBulletedSharp,
  Settings,
  FactCheck,
  AssignmentRounded,
  AccountTreeTwoTone,
  Calculate,
  ReportProblem,
  Engineering,
  CalendarToday,
  StackedLineChart,
  Reviews
} from '@mui/icons-material';

const API_ROOT_WAR = process.env.REACT_APP_API_ROOT_WAR;
const getUnknownMimeType = () => ({
  icon: Settings,
  mimeType: 'unknown',
  name: <FormattedMessage id="environment.title.unknown" defaultMessage="Unknown" />,
  openpath: `${API_ROOT_WAR}/undefined_element/:versionId`
});

export function getPluginByMimeType(mimeType) {
  if (!mimeType) return getUnknownMimeType();
  const plugin = getPlugins().find(
    (plugin) => mimeType.split('application/zert-')[1] == plugin.mimeType || mimeType == plugin.mimeType
  );

  if (!plugin) {
    return getUnknownMimeType();
  }

  return plugin;
}

export function getPluginByMimeTypeIcon(mimeType, customSvg) {
  if (!mimeType) {
    const Icon = Settings;
    return <Icon />;
  }

  const plugins = getPlugins();
  const plugin = plugins && plugins.find(
    (plugin) => mimeType.split('application/zert-')[1] == plugin.mimeType || mimeType == plugin.mimeType
  );

  if (!plugin) {
    const Icon = Settings;
    return <Icon />;
  }

  if(customSvg){
    return plugin.icon
  }
  const Icon = plugin.icon;
  return <Icon />;
}
export function getPluginTranslatableName(intl, plugin) {
  const resultstep = getPlugins().find((step) => {
    return plugin.trkey === step.trkey;
  });

  if (resultstep && resultstep.trkey && intl) {
    return intl.formatMessage({
      id: resultstep.trkey,
      defaultMessage: resultstep.defaultMessage
    });
  }
  if (resultstep && resultstep.trkey && !intl) {
    return resultstep.defaultMessage;
  }

  return '';
}

export function getAllPluginsSorted(intl) {
  return getPlugins()
    .filter((plugin) => plugin.mimeType != null)
    .sort(function (a, b) {
      const pluginA = intl.formatMessage({
        id: a.trkey,
        defaultMessage: a.defaultMessage
      });
      /// a.defaultMessage : getPluginTranslatableName(intl, getPluginByMimeType(a.mimeType));
      const pluginB = intl.formatMessage({
        id: b.trkey,
        defaultMessage: b.defaultMessage
      });
      return pluginA.localeCompare(pluginB, 'sv', { sensitivity: 'accent' });

      //        if(getPluginTranslatableName(intl, getPluginByMimeType(a.mimeType)) < getPluginTranslatableName(intl, getPluginByMimeType(b.mimeType))) { return -1; }
      //        if(getPluginTranslatableName(intl, getPluginByMimeType(a.mimeType)) > getPluginTranslatableName(intl, getPluginByMimeType(b.mimeType))) { return 1; }
      //        return 0;
    });
}
