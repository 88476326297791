import React, { useContext, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import { TabsContext } from "../../Stylesheet";
import {
  adobeColor,
  adobeDropMenuUpperCase, adobeRgba,
  adobeWord,
  handleParagraphValue,
  handleTableParents
} from "../../utils/helpers";
import Translatable from "./Translatable";
import SelectedColor from "../../SelectedColor";
import DialogColors from "../../dialog/DialogColors";
import TemplateItemRow from "../../TemplateItemRow";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: 10
  },
  title: {
    fontSize: "1rem",
    fontWeight: "700",
    marginBottom: 0
  },
  wrapperBlock: {
    display: "grid",
    gridRowGap: 10
  },
  wrapperRow: {
    display: "grid",
    gridTemplateColumns: "130px 1fr",
    gridColumnGap: 10,
    "& label": {
      marginBottom: 0
    }
  },
  notesInput: {
    margin: 0,
    width: "100%",
    "& input": {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15,
      background: "#ffffff"
    },
    "& textarea": {
      overflow: "hidden"
    },
    "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
      paddingTop: 5,
      paddingBottom: 5
    }
  }
}));

function BulletsNumbers({ paragraph, templatesIdx }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const selectImage = [
    "None",
    "toc-background",
    "cover-background",
    "attention-image",
    "toc-background-grey",
    "logo",
    "warning-image",
    "note-image"
  ];
  const [firstMenu, setFistMenu] = useState({
    textSeparation: "User defined",
    labelSeparation: "User defined"
  });
  const [color, setColor] = useState({
    color: "#ffffff",
    load: false
  });

  const setSelectedDropMenu = (val, name) => {
    handleParagraphValue(val.toUpperCase(), name, "paragraphSettings", templatesIdx, values, setValues);
  };

  const handleNumbering = (val, name) => {
    setColor(pre => ({ ...pre, color:  val}))
    handleParagraphValue(adobeRgba(val), name, "paragraphSettings", templatesIdx, values, setValues);
  };

  const handleTextField = (event) => {
    const val = event.target.value;
    const { name } = event.target;
    handleParagraphValue(+val, name, "paragraphSettings", templatesIdx, values, setValues);
  };

  const handleOpenDialog = (type) => {
    renderInExceptionRoot(DialogColors, {
      color: paragraph[type],
      title: "Select Color",
      setText: handleNumbering,
      type
    });
  };

  const findDisableSeparation = () => {
    if (!paragraph.textSeparation && !paragraph.labelSeparation) {
      return setFistMenu({ textSeparation: "Default", labelSeparation: "Default" });
    }
    if (!paragraph.textSeparation) {
      return setFistMenu((pre) => ({ ...pre, textSeparation: "Default" }));
    }
    if (!paragraph.labelSeparation) {
      return setFistMenu((pre) => ({ ...pre, labelSeparation: "Default" }));
    }
  };

  const handleValues = (val, name) => {
    const result = { ...paragraph[name], unit: paragraph[name] ? paragraph[name].unit : "pt", value: +val };
    handleParagraphValue(result, name, "paragraphSettings", templatesIdx, values, setValues);
  };

  const setDropMenuFirst = (val, name) => {
    if (val === "Default") {
      const result = { unit: "pt", value: 12 };
      handleParagraphValue(result, name, "paragraphSettings", templatesIdx, values, setValues);
    }
    setFistMenu((pre) => ({ ...pre, [name]: val }));
  };

  const handleDropMenu = (valDropMenu, name, val) => {
    const result = { unit: adobeDropMenuUpperCase(valDropMenu), value: +val };
    handleParagraphValue(result, name, "paragraphSettings", templatesIdx, values, setValues);
  };


  useEffect(() => {
    findDisableSeparation();
  }, [paragraph]);

  useEffect(() => {
    if (!color.load && paragraph.color) {
      setColor(pre => ({
          ...pre,
          color: adobeColor(paragraph.color),
          load: true
        }));
    }
  }, [paragraph.color]);


  return (
    <div className={cl.wrapperBlock}>
      <div className={cl.wrapper}>
        <div className={cl.wrapperBlock}>
          <div className={cl.wrapperRow}>
            <span className={cl.title}>Numbering:</span>
            <ExpanderItem
              valueProps={adobeWord(paragraph.numbering, true)}
              propsLabel={["Heading", "List", "Label", "None"]}
              setProps={setSelectedDropMenu}
              type="numbering"
            />
          </div>
          <div className={cl.wrapperRow}>
            <span className={cl.title}>Style:</span>
            <ExpanderItem
              valueProps={adobeWord(paragraph.numberingStyle.replace("_", " "), true)}
              propsLabel={["Heading", "List", "Label", "None"]}
              setProps={setSelectedDropMenu}
              type="numberingStyle"
            />
          </div>
          <Translatable
            title="Prefix:"
            selected={paragraph.numberingPrefix}
            typeParents="numberingPrefix"
            styles={{ gridTemplateColumns: `130px 180px 1fr 25px` }}
            setSelected={handleNumbering}
          />

          <div className={cl.wrapperRow}>
            <span className={cl.title}>Depth:</span>
            <TextField
              value={paragraph.numberingDepth}
              type="number"
              className={cl.notesInput}
              variant="outlined"
              onChange={handleTextField}
              name="numberingDepth"
            />
          </div>
        </div>

        <div className={cl.wrapperBlock}>
          <div className={cl.wrapperRow}>
            <span className={cl.title}>Numbering align:</span>
            <ExpanderItem
              valueProps={adobeWord(paragraph.numberingAlign, true)}
              propsLabel={["Left", "Right"]}
              setProps={setSelectedDropMenu}
              type="numberingAlign"
            />
          </div>
          <div className={cl.wrapperRow}>
            <span className={cl.title}>Select image:</span>
            <ExpanderItem
              valueProps={adobeWord(paragraph.selectImage.replace("_", " "), true)}
              propsLabel={selectImage}
              setProps={setSelectedDropMenu}
              type="selectImage"
            />
          </div>
          <Translatable
            title="Suffix:"
            // style="130"
            styles={{ gridTemplateColumns: `130px 180px 1fr 25px` }}
            selected={paragraph.numberingSuffix}
            typeParents="numberingSuffix"
            setSelected={handleNumbering}
          />
          <div className={cl.wrapperRow}>
            <span className={cl.title}>Display levels:</span>
            <TextField
              value={paragraph.numberingDisplayLevels}
              type="number"
              className={cl.notesInput}
              variant="outlined"
              onChange={handleTextField}
              name="numberingDisplayLevels"
            />
          </div>
        </div>
      </div>
      <SelectedColor
        label="Color:"
        type="color"
        setValueProps={handleNumbering}
        handleOpenDialog={handleOpenDialog}
        style={{ grid: "130px 30px 1fr 25px" }}
        valueProps={color.color}
      />
      <TemplateItemRow
        label="Text separation:"
        type="textSeparation"
        dropMenuFirst={{
          label: ["Default", "User defined"],
          selected: firstMenu.textSeparation
        }}
        value={{
          value: paragraph.textSeparation ? paragraph.textSeparation.value : 12,
          unit: paragraph.textSeparation ? paragraph.textSeparation.unit : "pt"
        }}
        grid="130px 150px 1fr 55px"
        setValueProps={handleValues}
        setDropMenuFirst={setDropMenuFirst}
        setDropMenu={handleDropMenu}
        disabled={firstMenu.textSeparation === "Default"}
      />
      <TemplateItemRow
        label="Label separation:"
        type="labelSeparation"
        dropMenuFirst={{
          label: ["Default", "User defined"],
          selected: firstMenu.labelSeparation
        }}
        value={{
          value: paragraph.labelSeparation ? paragraph.labelSeparation.value : 12,
          unit: paragraph.labelSeparation ? paragraph.labelSeparation.unit : "pt"
        }}
        grid="130px 150px 1fr 55px"
        setValueProps={handleValues}
        setDropMenuFirst={setDropMenuFirst}
        setDropMenu={handleDropMenu}
        disabled={firstMenu.labelSeparation === "Default"}
      />

      {/* textSeparation  labelSeparation */}
    </div>
  );
}

export default BulletsNumbers;
