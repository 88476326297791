import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormattedMessage } from 'react-intl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { fetchPublishingList } from '../servicesMiddlewares';

import useStyles from '../helpers/useStyles';

export function AudienceSwitcher() {
  const [audience, setAudiende] = useState(-1);
  const dispatch = useDispatch();
  const { myuser: myUser } = useSelector((state) => state);
  const handleChange = (event) => {
    setAudiende(event.target.value);
    dispatch(fetchPublishingList(event.target.value));
  };
  const cl = useStyles();
  return (
    <FormControl className={cl.formControl}>
      <InputLabel htmlFor="age-native-simple">
        <FormattedMessage id="Audience.SelectType" defaultMessage="Select audience" />
      </InputLabel>
      <Select
        value={audience}
        onChange={handleChange}
        inputProps={{
          name: 'age',
          id: 'age-native-simple'
        }}
      >
        <MenuItem value={-1}>
          <FormattedMessage id="Audience.AllPublications" defaultMessage="All publications" />
        </MenuItem>
        {myUser && (
          <MenuItem value={myUser.elementId}>
            <FormattedMessage id="Audience.MyPublications" defaultMessage="Mine publications" />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
