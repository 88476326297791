// @ts-ignore
import React, { useEffect, useState } from 'react';
import { AccountInfo, Configuration, LogLevel } from '@azure/msal-browser';
import { Box, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { GetEnv } from '@zert-packages/react/EnvProvider';
import AzureAuthenticationContext from './AzureAuthenticationContext';

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const isIE = msie > 0 || msie11 > 0;

// Log In, Log Out button
export function AzureAuthenticationButton({ onAuthenticated }: any): JSX.Element {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<AccountInfo>();
  const MSAL_CONFIG: Configuration = {
    auth: {
      clientId: GetEnv('REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID')
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              console.log(message);
          }
        }
      }
    }
  };

  // Azure client context
  const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext(MSAL_CONFIG);

  const logIn = (method: string): any => {
    const typeName = 'loginPopup';
    const logInType = isIE ? 'loginRedirect' : typeName;

    // Azure Login
    authenticationModule.login(logInType, returnedAccountInfo);
  };
  const logOut = (): any => {
    if (user) {
      onAuthenticated(undefined);
      // Azure Logout
      authenticationModule.logout(user);
    }
  };

  const returnedAccountInfo = (user: AccountInfo, accessToken: string) => {
    // set state
    console.log(user);
    setAuthenticated(!!user?.name);
    onAuthenticated(user, accessToken);
    setUser(user);
  };

  const showLogInButton = (): any => {
    return (
      <Button
        color="primary"
        variant="outlined"
        onClick={(e) => logIn('loginPopup')}
        fullWidth
        size="large"
        type="button"
      >
        <b>
          {' '}
          <FormattedMessage id="Login.Credentials.MicrosoftAzure" defaultMessage="Login with Microsoft" />{' '}
        </b>
      </Button>
    );
  };

  const showButton = (): any => {
    return showLogInButton();
  };

  return <Box mt={2}>{authenticationModule.isAuthenticationConfigured && showButton()}</Box>;
}
