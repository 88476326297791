import { FormattedMessage, injectIntl } from 'react-intl';
import React, { Fragment, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SvgIcon from '@zert-packages/icons/SvgIcon';

import makeStyles from '@mui/styles/makeStyles';

import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import { useElementOpen } from '@zert-packages/components/ElementTile/utils/useElementOpen';

import EnhancedTable from '@zert-packages/components/EnhancedTable/EnhancedTable';

import { descendingComparator } from '@zert-packages/components/ElementTile/utils/descendingComparator';
import { getTileElementStatus } from '@zert-packages/components/ElementTile/utils/getTileStatus';
import Link from '@mui/material/Link';

import { useDefaultActions } from '@zert-packages/components/EnhancedTable/helpers/useDefaultActions';
import useSelectionModel from '@zert-packages/components/EnhancedTable/useSelectionModel';
import { getPluginByMimeType, getPluginByMimeTypeIcon } from '@zert-packages/utils/getPluginByMimeType';
import { downloadPreview } from './servicesMiddlewares';

export const columns = [
  {
    identifier: 'nameNew',
    numeric: false,
    disablePadding: false,
    component: 'th',
    scope: 'row',
    isFilterable: true,
    label: <FormattedMessage id="Preview.TileTable.Name" defaultMessage="Name" />
  },
  {
    identifier: 'startDate',
    numeric: false,
    disablePadding: false,
    align: 'left',
    style: 'nowrapclass',
    isFilterable: true,
    label: <FormattedMessage id="Preview.TileTable.Date" defaultMessage="Date" />
  },

  {
    identifier: 'status',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="Preview.TileTable.Status" defaultMessage="Status" />
  },
  {
    identifier: 'message',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="Preview.TileTable.CurrentMessage" defaultMessage="Message" />
  },
  {
    identifier: 'dateDone',
    numeric: false,
    disablePadding: false,
    align: 'left',
    style: 'nowrapclass',
    isFilterable: true,
    label: <FormattedMessage id="Preview.TileTable.dateDone" defaultMessage="Date Done" />
  },
  {
    identifier: 'previewLocales',
    numeric: false,
    disablePadding: false,
    align: 'left',
    style: 'nowrapclass',
    isFilterable: true,
    label: <FormattedMessage id="Preview.TileTable.locales" defaultMessage="Locales" />
  },
  {
    identifier: 'download',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="Preview.TileTable.Version" defaultMessage="Download" />
  }
];

export const usePreviewSorter = () => {
  const myUser = useSelector((state) => state.myuser);
  const getSorter = (name) => {
    switch (name) {
      case 'nameNew':
        return (a, b, sortBy, sortDirection) => {
          if (
            a.elementInfo.mimeType == 'application/zert-folder' &&
            b.elementInfo.mimeType != 'application/zert-folder'
          ) {
            return sortDirection == 'asc' ? 1 : -1;
          }
          if (
            b.elementInfo.mimeType == 'application/zert-folder' &&
            a.elementInfo.mimeType != 'application/zert-folder'
          ) {
            return sortDirection == 'asc' ? -1 : 1;
          }

          if (
            a.elementInfo.mimeType == 'application/zert-search-folder' &&
            b.elementInfo.mimeType != 'application/zert-search-folder'
          ) {
            return sortDirection == 'asc' ? 1 : -1;
          }
          if (
            b.elementInfo.mimeType == 'application/zert-search-folder' &&
            a.elementInfo.mimeType != 'application/zert-search-folder'
          ) {
            return sortDirection == 'asc' ? -1 : 1;
          }
          if (b.elementInfo.name == a.elementInfo.name) {
            return descendingComparator(a, b, 'uuid', sortDirection);
          }

          // NUMBER_PATTERN.
          return descendingComparator(a.elementInfo, b.elementInfo, 'name', sortDirection);
        };

      case 'mimeTypeNew':
        return (a, b, orderBy) => {
          return descendingComparator(a, b, 'mimeType');
        };
      case 'status':
        return (a, b, orderBy) => {
          const status1 = getTileElementStatus(a, myUser);
          const status2 = getTileElementStatus(b, myUser);
          if (status1 < status2) {
            return -1;
          }
          if (status1 > status2) {
            return 1;
          }
          return 0;
        };
      default:
        return descendingComparator;
    }
  };

  return { getSorter };
};

function PreviewTable({ elements, maxHeight, loading, intl }) {
  const myUser = useSelector((state) => state.myuser);

  const { open } = useElementOpen();
  const { getSorter } = usePreviewSorter();
  const dispatch = useDispatch();
  const getCustomFilter = (element, name) => {
    switch (name) {
      case 'nameNew':
        return element.elementInfo.name;
      case 'status':
        return element.status;
      default:
        return null;
    }
  };

  const columnHandler = () => {
    const _handler = {
      get(target, key) {
        if (key == 'customComparator') {
          return getSorter(target.identifier);
        }
        if (key == 'customClickHandler' && target.identifier == 'nameNew') {
          return (row, column, event) => {
            open(row.elementInfo, column, event);
          };
        }
        if (key == 'customClickHandler' && target.identifier != 'nameNew') {
          return null;
        }
        return target[key];
      }
    };
    return _handler;
  };

  const handler = () => {
    const _handler = {
      get(target, key) {
        if (key == 'nameNew') {
          return (
            <label style={{ display: 'flex', alignItems: 'center', margin: 'unset' }}>
              <div className="icon-box" style={{ padding: '0px 3px 0 0' }}>
                {target.elementInfo &&
                  target.elementInfo.mimeType &&
                  getPluginByMimeTypeIcon(target.elementInfo.mimeType)}
              </div>
              {target.elementInfo && target.elementInfo.name}
            </label>
          );
        }
        if (key == 'message') {
          return target.status != 'DONE' && target.previewProgressStatus ? target.previewProgressStatus.message : null;
        }
        if (key == 'elementInfo') {
          return target[key];
        }
        if (typeof target[key] === 'object' && target[key] !== null) {
          return new Proxy(target[key], _handler);
        }
        if (key == 'download') {
          return target.status == 'DONE' ? (
            <Link
              component="button"
              variant="body2"
              style={{ paddingLeft: '5px' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(downloadPreview(true, target.uuid, target.elementInfo.name));
              }}
            >
              <FormattedMessage id="TranslationJobContent.Download" defaultMessage="Download" />
            </Link>
          ) : null;

          /* <label style={{display: "flex", alignItems: "center", margin: "unset"}}>
                            <div className="icon-box" style={{padding: "0px 3px 0 0"}}>
                                {target.elementInfo.mimeType &&
                                <SvgIcon icon={getPluginByMimeType(target.elementInfo.mimeType).icon}/>}</div>
                            {target.elementInfo.name}

                        </label> */
        }
        return target[key];
      }
    };
    return _handler;
  };
  const model = elements ? elements.map((element) => new Proxy(element, handler(element))) : [];
  const columnModel = columns ? columns.map((column) => new Proxy(column, columnHandler(column))) : [];

  const selectionModel = useSelectionModel(model, true, 'uuid');

  return (
    <EnhancedTable
      rows={model}
      identifier="uuid"
      columns={columnModel}
      maxHeight={maxHeight}
      actions={useDefaultActions}
      sortingKey="startDate"
      loading={loading}
      getCustomFilter={getCustomFilter}
      sortingOrder="desc"
      doNotShowSelect
      selectionModel={selectionModel}
    />
  );
}

export default injectIntl(
  memo(
    ({ elements, maxHeight, loading, intl }) => (
      <PreviewTable loading={loading} intl={intl} elements={elements} maxHeight={maxHeight} />
    ),
    selectiveMemoComparison(
      (p) => p.elements,
      (a) => a.loading,
      (c) => c.maxHeight
    )
  )
);

// export default injectIntl(memo(ElementTileTable));
