import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import UserSuggestBox from '@zert-packages/components/shared/UserSuggestBoxNew/UserSuggestBox';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import get from 'lodash/get';
import { FUNCTION_IDENTIFIERS, FUNCTION_LABELS } from '../CONSTS';

const useStyles = makeStyles({
  dialogContent: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: 10
  }
});

const getInitialState = () => ({
  user: null,
  function: 'PROJECTMANAGER',
  name: '',
  username: '',
  requestUser: false,
  email: '',
  active: false,
  sendOutInvitation: false
});

function AddProjectMemberDialog({ isAdding, handleClose, state: initialState = getInitialState(), onConfirm }) {
  const cl = useStyles();
  const [state, setState] = useState({ ...initialState });
  const handleConfirm = () => {
    onConfirm(state);
    handleClose();
  };

  const onChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const onChangeUser = (user) => {
    setState((prev) => ({
      ...prev,
      user,
      username: get(user, 'userName', ''),
      name: get(user, 'name', ''),
      active: true,
      email: get(user, 'email', '')
    }));
  };
  return (
    <Dialog fullWidth open onClose={handleClose}>
      <DialogTitle>
        {isAdding ? (
          <FormattedMessage id="RMP.Members.AddProjectMemberDialog.TitleAdding" defaultMessage="Add project member" />
        ) : (
          <FormattedMessage id="RMP.Members.AddProjectMemberDialog.TitleEditing" defaultMessage="Edit project member" />
        )}
      </DialogTitle>
      <DialogContent className={cl.dialogContent}>
        <UserSuggestBox
          symbolsToStart={3}
          getUsers={(value) => getUsersQuery(value)}
          initialValue={state.username}
          onChange={onChangeUser}
          label={<FormattedMessage id="RMP.Members.AddProjectMemberDialog.User" defaultMessage="User" />}
          getUserId={(user) => user.userName}
          getUserLabel={(user) => user.userName}
        />

        <TextField
          variant="standard"
          label="Email"
          defaultValue={' '}
          value={state.email}
          onChange={(e) => onChange('email', e.target.value)}
        />
        <FunctionSelector value={state.function || ''} onChange={(e) => onChange('function', e.target.value)} />
        {isAdding && (
          <FormControlLabel
            disabled={!state.email}
            control={
              <Checkbox
                onChange={(e) => onChange('sendOutInvitation', e.target.checked)}
                checked={state.sendOutInvitation}
                color="primary"
              />
            }
            label={
              <FormattedMessage
                id="RMP.Members.AddProjectMemberDialog.SendOutInvitation"
                defaultMessage="Send out invitation"
              />
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary">
          <FormattedMessage id="RMP.Members.AddProjectMemberDialog.Confirm" defaultMessage="Confirm" />
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          <FormattedMessage id="RMP.Members.AddProjectMemberDialog.Cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddProjectMemberDialog;

function FunctionSelector({ value, onChange }) {
  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel>
        <FormattedMessage id="RMP.Members.AddProject.FunctionLabel" defaultMessage="Function" />
      </InputLabel>
      <Select variant="standard" value={value} onChange={onChange}>
        {FUNCTION_IDENTIFIERS.map((func) => (
          <MenuItem key={func} value={func}>
            {FUNCTION_LABELS[func]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
