export default function isReadOnly(versionInfo, myUser) {
  return (
    versionInfo != null &&
    ((versionInfo.info && versionInfo.info.approvedAt != null) ||
      (versionInfo.info && versionInfo.info.archivedAt != null) ||
      versionInfo.approved ||
      versionInfo.archived ||
      (myUser != null && versionInfo.checkedOutBy != null && myUser.userName !== versionInfo.checkedOutBy))
  );
}
