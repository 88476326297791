import { getAllTemplates, getTemplatesBundle } from './API';
import {
  getMEASURETemplate,
  getMEASUREWFTTemplate,
  getQUESTTemplate,
  getRRTemplate,
  getTemplates,
  getWFTTemplate
} from './questionnaireReducers';

export const loadQuestinaireTemplates =
  (isChecklist, loadFirstTemplate) =>
    async (dispatch, getState, { snackbar }) => {
    // if (!getState().QUESTIONNAIRE.templates) {
      const templates = await getAllTemplates(isChecklist);
    dispatch(getTemplates(templates));

    if (loadFirstTemplate && templates.length > 0) {
        dispatch(loadQuestinaireTemplate(templates[0].versionId));
      }
    // }
    // }
    };

export const loadQuestinaireTemplate = (versionId) => async (dispatch) => {
  const templateBundle = await getTemplatesBundle(versionId);
  dispatch(getQUESTTemplate(templateBundle.questionnaireTemplate));
  dispatch(getMEASUREWFTTemplate(templateBundle.measuresWorkflowProcess));
  dispatch(getMEASURETemplate(templateBundle.measureTemplate));
  dispatch(getRRTemplate(templateBundle.riskRowTemplate));
  dispatch(getWFTTemplate(templateBundle.workflowProcess));
};
