import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import { duplicateChecklistAPI } from '@zert-packages/plugins/ExplorerPlugin/API';
import { showSnackbarError } from '@zert-packages/components/Snackbars';
import DuplicationDefaultDialog from '../components/DuplicationDefaultDialog';
import NameTextFieldWithMemo from '../components/NameTextFieldWithMemo';

const useStyles = makeStyles({
  root: {}
});

const getDefaultState = (element) => ({
  name: element.name
});

function Checklist({ unmountComponent, onFinish, element }) {
  const cl = useStyles();
  const snackbar = useSnackbar();
  const [state, setState] = useState(getDefaultState(element));
  const [loading, setLoading] = useState(false);
  const updateStateField = (field, value) =>
    setState((prev) => ({
      ...prev,
      [field]: value
    }));

  const onDuplicate = () => {
    setLoading(true);
    duplicateChecklistAPI(element.versionId, state.name, snackbar)
      .then((r) => {
        setLoading(false);
        onFinish(r);
        unmountComponent();
      })
      .catch((e) => {
        setLoading(false);
        showSnackbarError(snackbar, e.message);
      });
  };

  const onDuplicateDisabled = state.name.trim().length === 0;

  return (
    <DuplicationDefaultDialog
      unmountComponent={unmountComponent}
      onDuplicate={onDuplicate}
      onDuplicateDisabled={loading || onDuplicateDisabled}
      loading={loading}
      customDialogContent={
        <div className={cl.root}>
          <FormattedMessage
            id="DuplicationDialogs.Checklist.Confirmation"
            defaultMessage="Are you sure that you want to duplicate - {name}?"
            values={{
              name: element.name
            }}
          />
          <NameTextFieldWithMemo
            autoFocus={false}
            disabled={false}
            fullWidth
            value={state.name}
            onChange={(e) => updateStateField('name', e.target.value)}
          />
        </div>
      }
    />
  );
}

export default Checklist;
