import React, { forwardRef, Fragment, useContext, useImperativeHandle, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import { formStyle } from '@zert-packages/components/shared/FormElements/StylesHelper';
import SaveActions from '@zert-packages/plugins/MailSettingsPlugin/common/CancelSavePanel';
import ScedulerIntervalEditor from '../../common/ScedulerIntervalEditor';
import { SettingsContext } from './ContextProvider';

const useStyles = makeStyles(formStyle);

export const ScedulersTab = forwardRef(({ companyId, intl, backFunction }, ref) => {
  const checkInSceduler = useRef();
  const classes = useStyles();
  const reminderSceduler = useRef();

  useImperativeHandle(ref, () => ({
    store() {
      checkInSceduler.current.storeSettings();
      reminderSceduler.current.storeSettings();
    }
  }));

  const clickSave = (e) => {
    handleSave();
    backFunction();
  };

  const clickCancel = (e) => {
    handleCancel();
    backFunction();
  };

  const { handleSave, handleCancel } = useContext(SettingsContext);

  return (
    <>
      <ScedulerIntervalEditor
        ref={checkInSceduler}
        classes={classes}
        intl={intl}
        className="CheckinScheduleTask"
        companyId={companyId}
      >
        <FormattedMessage id="settings.companyeditor.checkinconf" defaultMessage="Check-in configuration" />
      </ScedulerIntervalEditor>
      <ScedulerIntervalEditor
        ref={reminderSceduler}
        classes={classes}
        intl={intl}
        className="ReminderScheduleTask"
        companyId={companyId}
      >
        <FormattedMessage id="settings.companyeditor.reminder" defaultMessage="Reminder configuration" />
      </ScedulerIntervalEditor>
      <SaveActions onSave={clickSave} onCancel={clickCancel} />
    </>
  );
});
