import { v4 as uuidv4 } from "uuid";

const blockItemsData = {
  block: {
    typeKey: "create",
    "identifier": uuidv4(),
    "class": "se.zert.clm.rest.part.pojo.ClientItem",
    "format": null,
    "settings": null,
    "languageSeparated": false,
    "label": null,
    "width": null,
    "height": null,
    "measurementUnit": null,
    "align": null,
    "currentInfo": null,
    "keepRatio": null,
    "indexEntries": [],
    "publishingFilters": [],
    "active": true,
    "obsolete": false,
    "localeFilterType": null,
    "localeFilter": [],
    "viewId": null,
    "parentViewId": null,
    "translatable": false,
    "itemComment": null,
    "parentItem": null,
    "elementInfo": {
      "id": 100256571,
      "metadataList": null,
      "references": {},
      "properties": {
        "zert:Format": "group",
        "zert:MimeInheritance": "'application/zert-block'",
        "zert-temporary-element": "false"
      },
      "parents": [],
      "approvedAt": null,
      "archivedAt": null,
      "approvedBy": null,
      "archivedBy": null,
      "changedBy": "zert-komatsu",
      "changedAt": "2023-10-06 16:00:05",
      "createdBy": "zert-komatsu",
      "createdAt": "2023-10-06 16:00:05",
      "name": "",
      "translatable": false,
      "checkedOut": false,
      "readOnly": false,
      "checkoutByUser": null,
      "published": false,
      "locale": "sv_SE",
      "label": "1.1",
      "mimeType": "application/zert-block",
      "versionId": 2391877,
      "additionalInfo": null,
      "companyId": 0,
      "versionUUID": "10418252-9570-be64-bcd8-21112dd32e20",
      "elementUUID": "10417656-550d-831e-9a04-1f45b01b8921",
      "currentVersionId": 2391877
    },
    "merged": false,
    "areas": []
  },
  phrase: {
    typeKey: "create",
    "identifier": uuidv4(),
    "class": "se.zert.clm.rest.part.pojo.ClientItem",
    "format": null,
    "settings": null,
    "languageSeparated": null,
    "label": null,
    "width": null,
    "height": null,
    "measurementUnit": null,
    "align": null,
    "currentInfo": null,
    "keepRatio": null,
    "indexEntries": [],
    "publishingFilters": [],
    "active": true,
    "obsolete": false,
    "localeFilterType": null,
    "localeFilter": [],
    "viewId": null,
    "parentViewId": null,
    "translatable": true,
    "itemComment": null,
    "parentItem": null,
    "elementInfo": {
      "id": 100256712,
      "metadataList": null,
      "references": {},
      "properties": {
        "zert:LimitOfCharactersProperty": "-1",
        "zert:aliases": "",
        "zert:MimeInheritance": "'application/zert-phrase'",
        "zert:Text": " "
      },
      "parents": [],
      "approvedAt": null,
      "archivedAt": null,
      "approvedBy": null,
      "archivedBy": null,
      "changedBy": "zert-komatsu",
      "changedAt": "2023-10-11 13:17:18",
      "createdBy": "zert-komatsu",
      "createdAt": "2023-10-04 14:36:55",
      "name": " ",
      "translatable": true,
      "checkedOut": false,
      "readOnly": false,
      "checkoutByUser": null,
      "published": false,
      "locale": "sv_SE",
      "label": "1.1",
      "mimeType": "application/zert-phrase",
      "versionId": 2391848,
      "additionalInfo": null,
      "companyId": 0,
      "versionUUID": "10418218-16e2-4917-40e4-2efcbdcce8fc",
      "elementUUID": "10418015-5933-2853-0f43-4dd0a8faacfc",
      "currentVersionId": 2391848
    },
    "merged": false,
    "areas": []
  },
  image: {
    "class": "se.zert.clm.rest.part.pojo.ClientItem",
    typeKey: "create",
    "identifier": uuidv4(),
    "format": null,
    "settings": null,
    "languageSeparated": null,
    "label": null,
    "width": null,
    "height": null,
    "measurementUnit": null,
    "align": null,
    "currentInfo": null,
    "keepRatio": null,
    "indexEntries": [],
    "publishingFilters": [],
    "active": true,
    "obsolete": false,
    "localeFilterType": null,
    "localeFilter": [],
    "viewId": null,
    "parentViewId": null,
    "translatable": false,
    "itemComment": null,
    "parentItem": null,
    "elementInfo": {
      "id": 100256795,
      "metadataList": null,
      "references": {},
      "properties": {
        "zert:XResolution": "37.79527559055118",
        "zert:ImageMD5": "adb910da06e8717731f4db1636f143b7",
        "zert:YResolution": "37.79527559055118",
        "zert:Width": "675",
        "zert:isQR": "false",
        "zert:XResolutionPI": "96.0",
        "zert:Size": "20997",
        "zert:Height": "564",
        "zert:YResolutionDPI": "96.0",
        "zert:MimeInheritance": "'application/zert-image'",
        "zert:AspectRatio": "1.196808510638298"
      },
      "parents": [],
      "approvedAt": "2023-10-11 12:36:31",
      "archivedAt": null,
      "approvedBy": "zert-komatsu",
      "archivedBy": null,
      "changedBy": "zert-komatsu",
      "changedAt": "2023-10-11 12:36:23",
      "createdBy": "zert-komatsu",
      "createdAt": "2023-10-11 12:36:22",
      "name": "Screenshot_1.png",
      "translatable": false,
      "checkedOut": false,
      "readOnly": false,
      "checkoutByUser": null,
      "published": false,
      "locale": "sv_SE",
      "label": "1.1",
      "mimeType": "application/zert-image",
      "versionId": 2391879,
      "additionalInfo": null,
      "companyId": 0,
      "versionUUID": "10418255-0abe-7dde-ae17-f1fc3b371800",
      "elementUUID": "10418254-2f77-cca4-2c4a-12163234ea98",
      "currentVersionId": 2391879
    },
    "merged": false,
    "areas": []
  },
  table: {
    typeKey: "create",
    "identifier": uuidv4(),
    "class": "se.zert.clm.rest.part.pojo.ClientItem",
    "format": null,
    "settings": null,
    "languageSeparated": null,
    "label": null,
    "width": null,
    "height": null,
    "measurementUnit": null,
    "align": null,
    "currentInfo": null,
    "keepRatio": null,
    "indexEntries": [],
    "publishingFilters": [],
    "active": true,
    "obsolete": false,
    "localeFilterType": null,
    "localeFilter": [],
    "viewId": null,
    "parentViewId": null,
    "translatable": false,
    "itemComment": null,
    "parentItem": null,
    "elementInfo": {
      "id": 100256797,
      "metadataList": null,
      "references": {},
      "properties": {
        "zert:Format": "data",
        "zert:MimeInheritance": "'application/zert-table'"
      },
      "parents": [],
      "approvedAt": null,
      "archivedAt": null,
      "approvedBy": null,
      "archivedBy": null,
      "changedBy": "zert-komatsu",
      "changedAt": "2023-10-11 13:28:48",
      "createdBy": "zert-komatsu",
      "createdAt": "2023-10-11 13:28:48",
      "name": "",
      "translatable": false,
      "checkedOut": false,
      "readOnly": false,
      "checkoutByUser": null,
      "published": false,
      "locale": "sv_SE",
      "label": "1.1",
      "mimeType": "application/zert-table",
      "versionId": 2391881,
      "additionalInfo": null,
      "companyId": 0,
      "versionUUID": "10418259-b10d-b507-8a3b-d2d03d4f89ce",
      "elementUUID": "10418258-2d22-0595-0f16-4bae593eb268",
      "currentVersionId": 2391881
    },
    "merged": false,
    "areas": []
  },
  index: {
    "class": "se.zert.clm.rest.part.pojo.ClientItem",
    "identifier": "zert:Item[3c6cbba0-53bc-11ee-a6e3-b2c37d60a7d0]2023-10-16 12:37:32",
    "format": null,
    "settings": null,
    "languageSeparated": null,
    "label": null,
    "width": null,
    "height": null,
    "measurementUnit": null,
    "align": null,
    "currentInfo": null,
    "keepRatio": null,
    "indexEntries": [],
    "publishingFilters": [],
    "active": true,
    "obsolete": false,
    "localeFilterType": null,
    "localeFilter": [],
    "viewId": null,
    "parentViewId": null,
    "translatable": true,
    "itemComment": null,
    "parentItem": null,
    "elementInfo": {
      "id": 100256803,
      "metadataList": null,
      "references": {},
      "properties": {
        "zert:MimeInheritance": "'application/zert-index'"
      },
      "parents": [],
      "approvedAt": null,
      "archivedAt": null,
      "approvedBy": null,
      "archivedBy": null,
      "changedBy": "zert-komatsu",
      "changedAt": "2023-10-16 12:37:32",
      "createdBy": "zert-komatsu",
      "createdAt": "2023-10-16 12:37:32",
      "name": "test",
      "translatable": true,
      "checkedOut": true,
      "readOnly": false,
      "checkoutByUser": "zert-komatsu",
      "published": false,
      "locale": "sv_SE",
      "label": "1.1",
      "mimeType": "application/zert-index",
      "versionId": 2391956,
      "additionalInfo": null,
      "companyId": 0,
      "versionUUID": "10418340-212e-4e00-164c-8e5f329ad191",
      "elementUUID": "10418339-ebd8-447f-edb4-4bffe4997f29",
      "currentVersionId": 2391956
    },
    "merged": false,
    "areas": []
  }
};

export default blockItemsData;