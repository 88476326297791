const makeValidSubProjectSubNodeJSON = ({
  name,
  description,
  uuid,
  comment,
  documents,
  basis,
  responsiblePerson,
  supplier,
  contractorPL,
  nodeStatus,
  documentationPositions,
  demandsStatusSimplified,
  demands
}) => ({
  name,
  description,
  uuid,
  comment,
  documents,
  basis,
  responsiblePerson,
  supplier,
  contractorPL,
  nodeStatus,
  documentationPositions,
  demandsStatusSimplified,
  demands
});

export default makeValidSubProjectSubNodeJSON;
