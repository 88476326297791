import {
  handlePostResultAsString,
  handleQuery,
  handlePostTextAsParam,
  fetchRA_Error,
  handlePostParamAsString
} from './api';
import { fetchCopyElements, fetchCutElements, fetchCatalogNodeStartLoad } from './catalogActions';

const ACTION_REDUCER_ROOT_NODE_START = 'ACTION_REDUCER_ROOT_NODE_START';
const ACTION_REDUCER_ROOT_NODE = 'ACTION_REDUCER_ROOT_NODE';
const ACTION_REDUCER_NODE_CHILDREN = 'ACTION_REDUCER_NODE_CHILDREN';
const ACTION_REDUCER_NODE_CHILDREN_START = 'ACTION_REDUCER_NODE_CHILDREN_START';
const ACTION_REDUCER_NODE_SELECT = 'ACTION_REDUCER_NODE_SELECT';
const ACTION_REDUCER_NODE_COUNT_CHILDREN_START = 'ACTION_REDUCER_NODE_COUNT_CHILDREN_START';
const ACTION_REDUCER_NODE_COUNT_CHILDREN = 'ACTION_REDUCER_NODE_COUNT_CHILDREN';
const ACTION_REDUCER_NODE_CONTENT_START = 'ACTION_REDUCER_NODE_CONTENT_START';
const ACTION_REDUCER_NODE_CONTENT = 'ACTION_REDUCER_NODE_CONTENT';

const ACTION_STORE_SEARCH = 'ACTION_STORE_SEARCH';
const ACTION_REMOVE_SEARCH = 'ACTION_REMOVE_SEARCH';
const ACTION_REDUCER_CHILD_FOLDER_START_CREATE = 'ACTION_REDUCER_CHILD_FOLDER_START_CREATE';
const ACTION_REDUCER_FULL_NODE_SELECT = 'ACTION_REDUCER_FULL_NODE_SELECT';
const ACTION_REDUCER_CREATE_AT_NODE = 'ACTION_REDUCER_CREATE_AT_NODE';
const ACTION_REDUCER_CUT_ROOT_NODE = 'ACTION_REDUCER_CUT_ROOT_NODE';

const REDUCER_ROOT_NODE = (explorerRootNode) => ({
  type: ACTION_REDUCER_ROOT_NODE_START,
  payload: { explorerRootNode }
});

const REDUCER_CUT_ROOT_NODE = (explorerRootNode) => ({
  type: ACTION_REDUCER_CUT_ROOT_NODE,
  payload: { explorerRootNode }
});

export const REDUCER_CREATE_AT_NODE = (explorerRootNode) => ({
  type: ACTION_REDUCER_CREATE_AT_NODE,
  payload: { explorerRootNode }
});

const REDUCER_ROOT_NODE_START = () => ({
  type: ACTION_REDUCER_ROOT_NODE,
  payload: {}
});

const REDUCER_NODE_CHILDREN = (ownerid, children) => ({
  type: ACTION_REDUCER_NODE_CHILDREN,
  payload: {
    ownerid,
    children
  }
});

const REDUCER_NODE_CHILDREN_START = (ownerid) => ({
  type: ACTION_REDUCER_NODE_CHILDREN_START,
  payload: { ownerid }
});

const REDUCER_NODE_COUNT_CHILDREN_START = (ownerid) => ({
  type: ACTION_REDUCER_NODE_COUNT_CHILDREN_START,
  payload: { ownerid }
});

const REDUCER_NODE_COUNT_CHILDREN = (ownerid, count) => ({
  type: ACTION_REDUCER_NODE_COUNT_CHILDREN,
  payload: {
    ownerid,
    count
  }
});

const REDUCER_NODE_CONTENT = (ownerid, content) => ({
  type: ACTION_REDUCER_NODE_CONTENT,
  payload: {
    ownerid,
    content
  }
});

const REDUCER_NODE_CONTENT_START = (ownerid) => ({
  type: ACTION_REDUCER_NODE_CONTENT_START,
  payload: { ownerid }
});

export const REDUCER_CHILD_FOLDER_START_CREATE = (updatingNode) => ({
  type: ACTION_REDUCER_CHILD_FOLDER_START_CREATE,
  payload: { updatingNode }
});

export const getNodeContent = (elementId, refresh) => (dispatch, getState, hooks) => {
  handleQuery(`/explorer/getNodeContent/${elementId}`)
    .then((children) => {
      dispatch(REDUCER_NODE_CONTENT(elementId, children));
      dispatch(selectNodeId(elementId));
    })
    .catch((error) => dispatch(fetchRA_Error(error)));
  dispatch(REDUCER_NODE_CONTENT_START(elementId));
};

export function getRootNode() {
  return function (dispatch) {
    handleQuery('/explorer/getRootNode')
      .then((rootNode) => {
        dispatch(REDUCER_ROOT_NODE(rootNode));
        dispatch(selectNode(rootNode));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_ROOT_NODE_START());
  };
}

export function cut(folderId, elementsid) {
  return function (dispatch) {
    handlePostResultAsString(`/explorer/cut/${folderId}`, elementsid)
      .then(() => {
        dispatch(getChildNodes(folderId));
        dispatch(getNodeContent(folderId));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
  };
}

//export const archiveElementAPI = (elementId) => handlePostResultAsString(`/common/archiveOneElement/${elementId}`, '');

export async function archiveOneElement(elementId) {
  const promise = await handleQuery(`/common/archiveOneElement/${elementId}`);
  return promise;
}

/*
function updateProgressStatus(callback, report, folderId, criterias) {
  return function (dispatch) {
    handleQuery('/report-react/updateProgressStatus/' + callback.callbackId + '/false')
      .then(result => {

          if (result != null && result.active) {
            dispatch(fetchReportStatus(result));
            dispatch(updateProgressStatus(result, report, folderId, criterias));
          } else {
            report.done = true;
            dispatch(fetchReportStatus(result));
            dispatch(reportDone(report));
            if (folderId && folderId > 0) {
              dispatch(getChildNodes(folderId));
              dispatch(getNodeContent(folderId));
            } else if (criterias) {
              dispatch(searchElements(criterias));
            }
          }
        },
      )
      .catch(error => {
        dispatch(fetchReportError(error));
      });
    return fetchReportStatus(callback);
  };
}
*/

export function renameAsync(elementId, name) {
  return handlePostParamAsString(`/common/renameElement/${elementId}`, name);
}

export const renameCommonInSearch = (versionId, name, reload) => (dispatch) => {
  handlePostParamAsString(`/common/rename/${versionId}`, name)
    .then(() => reload())
    .catch((error) => dispatch(fetchRA_Error(error)));
  return dispatch(fetchCatalogNodeStartLoad());
};

export function renameCommon(versionId, name, folderId) {
  return function (dispatch) {
    handlePostParamAsString(`/common/rename/${versionId}`, name)
      .then(() => {
        dispatch(getChildNodes(folderId));
        dispatch(getNodeContent(folderId));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchCatalogNodeStartLoad());
  };
}

export function copyElements(selectedElements, isCuted, folderElementId) {
  return function (dispatch) {
    if (isCuted) {
      dispatch(REDUCER_CUT_ROOT_NODE(folderElementId));
      return dispatch(fetchCutElements(selectedElements));
    }
    dispatch(REDUCER_CUT_ROOT_NODE(null));
    return dispatch(fetchCopyElements(selectedElements));
  };
}

export function createChildFolder(elementId, name) {
  return function (dispatch) {
    handlePostTextAsParam(`/explorer/createChildFolder/${elementId}`, name)
      .then(() => {
        dispatch(getChildNodes(elementId));
        dispatch(getNodeContent(elementId));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_CHILD_FOLDER_START_CREATE(elementId));
  };
}

export function getParentInfos(ownerid) {
  return handleQuery(`/common/getParents/${ownerid}`);
}

export function getTranslationInfo(ownerid, hasTranslation) {
  return handleQuery(`/explorer/loadChildren/${ownerid}/${hasTranslation}`);
}

export function getChildNodes(ownerid) {
  return function (dispatch) {
    handleQuery(`/explorer/getChildNodes/${ownerid}`)
      .then((children) => {
        dispatch(REDUCER_NODE_CHILDREN(ownerid, children));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_NODE_CHILDREN_START(ownerid));
  };
}

export function countChildNodes(ownerid) {
  return function (dispatch) {
    handleQuery(`/explorer/getChildNodesCount/${ownerid}`)
      .then((count) => {
        dispatch(REDUCER_NODE_COUNT_CHILDREN(ownerid, count));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_NODE_COUNT_CHILDREN_START(ownerid));
  };
}

export const selectNodeId = (selectNodeId) => ({
  type: ACTION_REDUCER_NODE_SELECT,
  payload: { selectNodeId }
});

export const selectNode = (selectedNode) => ({
  type: ACTION_REDUCER_FULL_NODE_SELECT,
  payload: { selectedNode }
});

export function explorerReducer(state, action) {
  switch (action.type) {
    case ACTION_REDUCER_NODE_SELECT:
      return {
        ...state,
        selectNodeId: action.payload.selectNodeId
      };

    case ACTION_REDUCER_ROOT_NODE_START:
      return {
        ...state,
        explorerRootNode: action.payload.explorerRootNode,
        loadingExplorer: false
      };
    case ACTION_REDUCER_ROOT_NODE:
      return {
        ...state,
        loadingExplorer: true
      };

    case ACTION_REDUCER_FULL_NODE_SELECT:
      return {
        ...state,

        selectedTreeNode: action.payload.selectedNode
      };

    case ACTION_REDUCER_NODE_COUNT_CHILDREN:
      return {
        ...state,

        loadingCountNodeId: action.payload.ownerid,
        nodeChildCount: action.payload.count
      };

    case ACTION_REDUCER_NODE_COUNT_CHILDREN_START:
      return {
        ...state,
        loadingCountNodeId: null,
        nodeChildCount: null
      };

    case ACTION_REDUCER_NODE_CHILDREN_START:
      return {
        ...state,
        explorer_childrens: null,
        loadingNodeId: null,
        updatingNode: null
      };
    case ACTION_REDUCER_NODE_CHILDREN:
      return {
        ...state,
        explorer_childrens: action.payload.children,
        loadingNodeId: action.payload.ownerid
      };
    case ACTION_REDUCER_CHILD_FOLDER_START_CREATE:
      return {
        ...state,
        loadingNodeId: null,
        updatingNode: action.payload.updatingNode
      };

    case ACTION_REDUCER_NODE_CONTENT:
      return {
        ...state,
        loading: false,
        explorer_infos: action.payload.content,
        loadingContentNodeId: action.payload.ownerid
      };

    case ACTION_REDUCER_CREATE_AT_NODE:
      return {
        ...state,
        create_at_node: action.payload.explorerRootNode
      };

    case ACTION_REDUCER_NODE_CONTENT_START:
      return {
        ...state,
        loading: true,
        explorer_infos: null,
        loadingContentNodeId: null
      };

    case ACTION_REDUCER_CUT_ROOT_NODE:
      return {
        ...state,
        cutedNode: action.payload.explorerRootNode
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
