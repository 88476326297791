import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import { noClass, definePEDClass } from '@zert-packages/utils/definePEDClass';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignContent: 'flex-start',
    gridGap: 15,
    overflow: 'hidden',
    margin: theme.spacing(2)
  },

  cardRoot: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: '1rem'
  },
  classG: {
    fontSize: '10rem'
  },
  pos: {
    marginBottom: 12
  }
}));
const categories = [
  {
    id: 'pressure.vessels',
    name: <FormattedMessage id="PEDView.pressure.vessels" defaultMessage="Pressure vessels" />
  },
  { id: 'pipelines', name: <FormattedMessage id="PEDView.pipelines" defaultMessage="Pipelines" /> },
  { id: 'tank', name: <FormattedMessage id="PEDView.cistern" defaultMessage="Tank" /> },
  { id: 'boiler', name: <FormattedMessage id="PEDView.boiler" defaultMessage="Boiler" /> },

  { id: 'vacuum.pipes', name: <FormattedMessage id="PEDView.vacuum" defaultMessage="Vacuum pipes" /> }
];

const substances = [
  { id: 'gas', name: <FormattedMessage id="PEDView.substance.gas" defaultMessage="Gas" /> },
  { id: 'liquid', name: <FormattedMessage id="PEDView.substance.Liquid" defaultMessage="Liquid" /> }
];
const substanceClasses = [
  { id: '1a', name: <FormattedMessage id="PEDView.substance.class.1a" defaultMessage="1a" /> },
  { id: '2a', name: <FormattedMessage id="PEDView.substance.class.2a" defaultMessage="2a" /> }
];

function PEDWrapper({ object }) {
  const { category, substance, substanceClass, temperature, pressure, volume, onChange } = object;

  const [value, setValue] = useState({
    category,
    substance,
    substanceClass,
    temperature,
    pressure,
    volume,
    classG: definePEDClass({ category, substance, substanceClass, temperature, pressure, volume })
  });

  const handleChange = (name) => (event) => {
    if (event.target.valueAsNumber || typeof event.target.value === 'string') {
      const value =
        event.target.valueAsNumber && event.target.valueAsNumber == 0 ? event.target.valueAsNumber : event.target.value;

      setValue((prevState) => {
        const newObj = {
          ...prevState,
          [name]: value
        };
        onChange(newObj);
        return {
          ...newObj,
          classG: definePEDClass(newObj)
        };
      });
    }
  };

  const getVolumeCaption = () => {
    if (value.category == 'boiler') {
      return <FormattedMessage id="PEDView.Power" defaultMessage="Power KW" />;
    }
    if (value.category == 'pipelines') {
      return <FormattedMessage id="PEDView.TubeDiameter" defaultMessage="Diameter in mm" />;
    }

    return <FormattedMessage id="PEDView.Volume" defaultMessage="Volume, liter" />;
  };

  const isPower = () => {
    return value.category == 'boiler';
  };

  const isTemperatureVisible = () => {
    return value.category != 'pressure.vessels';
  };
  /* const isTemperatureVisible = () => {
        return value.category != "pressure.vessels" && value.category != "pipelines";
    } */

  const isLiquidPressureVisible = () => {
    return !(value.category != 'pressure.vessels' && value.category != 'pipelines');
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl variant="standard">
        <InputLabel>
          <FormattedMessage id="PEDView.PEDCategory" defaultMessage="PEDCategory" />
        </InputLabel>
        <Select variant="standard" value={value.category} onChange={handleChange('category')}>
          {categories.map((activity) => (
            <MenuItem value={activity.id}>{activity.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {isLiquidPressureVisible() && (
        <FormControl variant="standard">
          <InputLabel>
            <FormattedMessage id="PEDView.TypeOf" defaultMessage="Gas or liquid" />
          </InputLabel>
          <Select variant="standard" value={value.substance} onChange={handleChange('substance')}>
            {substances.map((activity) => (
              <MenuItem value={activity.id}>{activity.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {!isPower() && (
        <FormControl variant="standard">
          <InputLabel>
            <FormattedMessage id="PEDView.SubstanceClass" defaultMessage="SubstanceClass" />
          </InputLabel>
          <Select variant="standard" value={value.substanceClass} onChange={handleChange('substanceClass')}>
            {substanceClasses.map((activity) => (
              <MenuItem value={activity.id}>{activity.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {isTemperatureVisible() && (
        <TextField
          id="standard-temperature"
          label={<FormattedMessage id="PEDView.Temperature" defaultMessage="Temperature" />}
          value={value.temperature}
          onChange={handleChange('temperature')}
          type="number"
          fullWidth
          variant="outlined"
        />
      )}
      <TextField
        id="standard-volume"
        label={getVolumeCaption()}
        value={value.volume}
        onChange={handleChange('volume')}
        type="number"
        fullWidth
        variant="outlined"
      />
      {isLiquidPressureVisible() && (
        <TextField
          id="standard-pressure"
          label={<FormattedMessage id="PEDView.Pressure" defaultMessage="Pressure, bar p" />}
          value={value.pressure}
          onChange={handleChange('pressure')}
          type="number"
          fullWidth
          variant="outlined"
        />
      )}
      <Card className={classes.cardRoot}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            <FormattedMessage id="PEDView.CLASS" defaultMessage="We defined your equipment class as" />
          </Typography>
          <Typography variant="h2" component="h2" className={!noClass && classes.classG}>
            {value.classG == noClass ? (
              <FormattedMessage id="PEDView.class.noClass" defaultMessage="No classification  AFS 2017:3." />
            ) : (
              value.classG
            )}
          </Typography>
          {/* <Typography className={classes.pos} color="textSecondary">
                        adjective
                    </Typography>
                    <Typography variant="body2" component="p">
                        well meaning and kindly.
                        <br />
                        {'"a benevolent smile"'}
                    </Typography> */}
        </CardContent>
        <CardActions>
          <Button
            size="small"
            onClick={() =>
              window.open(
                'https://www.av.se/globalassets/filer/publikationer/foreskrifter/anvandning-och-kontroll-av-trycksatta-anordningar-afs2017-3.pdf'
              )
            }
          >
            <FormattedMessage id="PEDView.class.LearnMore" defaultMessage="Learn More." />
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}

export default PEDWrapper;
