import React, {useState} from 'react';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";


const useStyles = makeStyles((theme) => ({
    widthController: {
        display: "flex",
        // gap: 4,
        padding: '0px 10px',
        alignItems: 'end',
    },
    textField: {
        margin: 0,
        '& .MuiInputBase-root': {
            padding: '2px 3px',
        }
    },
    wrapperTextField: {
        display: "grid",
        gridTemplateColumns: '1fr 11px',
        gap: 2,
    },
    interestIcon: {
        fontWeight: 700,
        color: 'rgb(24, 142, 199)',
        display: 'flex',
        alignItems: 'center',
    },

}))

const WidthControllerCell = ({headWidth, handleWidth}) => {
    const cl = useStyles();
    const [open, setOpen] = useState(true);

    const handleOpen = () => {
        setOpen(pre => !pre)
    }

    return (<div className={cl.widthController}>
       <span style={{color: '#188ec7'}} onClick={handleOpen}>
           {open ? <LockIcon/> : <LockOpenIcon/>}
       </span>
        <div className={cl.wrapperTextField}>
            <TextField
                disabled={open}
                type="number"
                value={headWidth?.width || ''}
                fullWidth={true}
                variant={"standard"}
                className={cl.textField}
                onChange={handleWidth}
            />
           <span className={cl.interestIcon}>%</span>
        </div>
    </div>)
}

export default WidthControllerCell;