import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import getTranslation from '@zert-packages/utils/getTranslation.new';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  /*getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }*/
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150
  },

  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(1) / 4
  },
  fontWeightRegular: {
    fontWeight: theme.typography.fontWeightRegular
  },
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium
  }
}));

function ComboSettingsField({ settings, value, handleChange, readOnly }) {
  const classes = useStyles();
  let selected = value ? value.value : null;
  if (!settings.allowMultiselect && selected) {
    selected = selected[0];
  }

  const getStyles = (option) => {
    return selected.indexOf(option) === -1 ? classes.fontWeightRegular : classes.fontWeightMedium;
  };
  const handleSelectChange = (event) => {
    if (!settings.allowMultiselect) {
      const selected = settings.items.filter((item) => item.value == event.target.value);
      if (selected) {
        handleChange(settings.identifier)({ target: { value: [selected[0].value] } });
      } else {
        handleChange(settings.identifier)({ target: { value: [] } });
      }
    } else {
      const selected = settings.items.filter((item) => event.target.value.indexOf(item.value) > -1);
      if (selected) {
        handleChange(settings.identifier)({ target: { value: selected.map((value) => value.value) } });
      } else {
        handleChange(settings.identifier)({ target: { value: [] } });
      }
    }
  };

  const findOption = (option) => {
    return settings.items.find((item) => option == item.value);
  };

  if (!settings.allowMultiselect) {
    return (
      <FormControl className={classes.formControl} disabled={readOnly}>
        <InputLabel htmlFor={settings.identifier}>{getTranslation(settings.labelObject)} </InputLabel>
        <Select
          value={selected}
          onChange={handleSelectChange}
          inputProps={{
            name: settings.identifier,
            id: settings.identifier
          }}
        >
          {settings.items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <em>{getTranslation(item.labelObject)}</em>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
  return (
    <FormControl className={classes.formControl} disabled={readOnly}>
      <InputLabel htmlFor="select-multiple-chip">{getTranslation(settings.labelObject)}</InputLabel>
      <Select
        multiple
        value={selected}
        onChange={handleSelectChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => {
              if (findOption(value)) {
                return (
                  <Chip key={value} label={getTranslation(findOption(value).labelObject)} className={classes.chip} />
                );
              }
            })}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {settings.items.map((item) => (
          <MenuItem key={item.value} value={item.value} className={getStyles(item.value)}>
            {getTranslation(item.labelObject)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ComboSettingsField;
