import {
  handlePost,
  handlePostFile,
  handleQueryFileAsResult,
  handlePostFileAsResult,
  handleQuery,
  handlePostTextPostFileAsResult,
  handleQueryFileAsResultHeaders
} from '@zert-packages/actions/api';
import getLang from '@zert-packages/utils/getLang';

const startQuery = '/translationjob';

export function loadTranslationJobAPI(versionId) {
  return handleQuery(`${startQuery}/getById/${versionId}`);
}

export function validateTranslationJobAPI(versionId, isImporting, locales) {
  // /validate/{versionId}/{isImporting}
  return handlePost(`${startQuery}/validate/${versionId}/${isImporting}`, locales);
}

export function uploadTranslationFile(versionId, locale, filedata) {
  return handlePostFile(`${startQuery}/uploadTranslationFile/${versionId}/${locale}`, filedata);
}

export function completeTranslationJobAPI(versionId, uuid, locales) {
  // /validate/{versionId}/{isImporting}
  return handlePost(`${startQuery}/complete/${uuid}/${versionId}`, locales);
}

export function cancelTranslationJobAPI(versionId, uuid, locales) {
  // /validate/{versionId}/{isImporting}
  return handlePost(`${startQuery}/cancel/${uuid}/${versionId}`, locales);
}

export function storeTranslationJobAPI(versionId, clientTranslationJob) {
  return handlePost(`${startQuery}/store/${versionId}`, clientTranslationJob);
}

export const generateReportAPI = (reportUUID, clientReportSetting) => {
  const locale = getLang();
  return handlePost(`${startQuery}/generateReport/${reportUUID}/${locale}`, clientReportSetting);
};

export function performTask(taskId, nextTransition) {
  return handlePost(`/actionplan-react/performTask/${taskId}/${nextTransition}`, {});
}

export function loadTranslationJobWFTAPI(versionId) {
  const locale = getLang();
  return handleQuery(`${startQuery}/loadWorkflowTemplate/${versionId}/${locale}`);
}

export function loadTranslationJobTemplateByJobId(versionId) {
  return handleQuery(`${startQuery}/getTranslationJobTemplateByJobId/${versionId}`);
}

export function downloadSource(versionId, locale) {
  return handlePostTextPostFileAsResult(`${startQuery}/downloadSource/${versionId}`, locale);
}

export function downloadTranslation(versionId, locale) {
  return handlePostTextPostFileAsResult(`${startQuery}/downloadTranslation/${versionId}`, locale);
}

export function downloadAll(versionId) {
  return handleQueryFileAsResultHeaders(`${startQuery}/downloadAll/${versionId}`);
}

export function downloadTermDB(lang) {
  return handlePostFileAsResult(`${startQuery}/downloadTBX`, lang);
}

export function downloadTermDBXLS(lang) {
  return handlePostFileAsResult(`${startQuery}/downloadXLS`, lang);
}
