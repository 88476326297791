import {
  fetchRA_Error,
  fetchRA_Rescent_Begin,
  fetchRA_Rescent_Success,
  handlePost,
  handlePostTextAsParam,
  handleQuery
} from '@zert-packages/actions/api';

import showCommentSnack, { createInProgressSnack } from '@zert-packages/components/common/snackBar';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import generateRMPReportMockUp from '@zert-packages/plugins/ReportPlugin/generateRMPReportMockUp';
import exportRMPMockUP from '@zert-packages/utils/exportRMPMockUP';
import { getVersionInfo } from '@zert-packages/actions/coreReducers';
import { reportMockUp } from '@zert-packages/utils/reportMockUp';
import { showSnackbarError } from '@zert-packages/components/Snackbars';
import { checkReportStatus } from '@zert-packages/actions/checklistActions';
import { getInitState } from './newTreeMiddlewares';
import {
  loadDemandTemplateAPI,
  loadProjectLogAPI,
  initDemandProjectAPI,
  loadDemandProjectAPI,
  loadRAEntriesAPI,
  loadDemandCoverageAPI,
  updateProjectPhaseAPI,
  getUpdateStatusSummaryAPI,
  setStatusSummaryAPI
} from '../APIs';
import getClientDemands from '../utils/getClientDemands';
import {
  loadLogListAction,
  loadTemplateNewRMPAction,
  makeProjectTreeAction,
  updateProjectAction,
  updateProjectViewIdAction,
  setRAEntriesAction,
  updateDemandCoverageCellsAction,
  turnOnRMPLoader,
  turnOffRMPLoader,
  updateStatusSummaryAction,
  resetRMPAction
} from './rmpReducer';

export const clearProject = () => (dispatch) => {
  dispatch(updateProjectAction(null));
  dispatch(updateProjectViewIdAction(null));
  dispatch(setRAEntriesAction([]));
};

export const sendInvitation = (versionId, callback) => (dispatch) => {};

export const fetchProject =
  (versionId, callback, viewId = null) =>
  (dispatch) => {
    dispatch(turnOnRMPLoader());
    loadDemandProjectAPI(versionId, viewId)
      .then((res) => {
        dispatch(updateProjectViewIdAction(res.right));
        dispatch(
          updateProjectAction({
            ...res.left,
            template: { ...res.left.template, ...getClientDemands(res.left.template.demands) }
          })
        );

        dispatch(makeProjectTreeAction());

        return loadRAEntriesAPI(versionId, res.right);
      })
      .then((res) => {
        dispatch(setRAEntriesAction(res));
        if (typeof callback === 'function') callback();
      });
    dispatch(turnOffRMPLoader());
  };

export const loadTemplateNewRMP = () => (dispatch) => {
  loadDemandTemplateAPI().then((res) =>
    dispatch(loadTemplateNewRMPAction({ ...res, ...getClientDemands(res.demands) }))
  );
};

export const loadLogList = (projectId, viewId) => (dispatch, getState) => {
  loadProjectLogAPI(projectId, viewId).then((res) => dispatch(loadLogListAction(res)));
};

export const creatDemandProject = (disciplineNumber, subprojectNumber, clientProject) => (dispatch, getState) => {
  initDemandProjectAPI(disciplineNumber, subprojectNumber, clientProject).then((res) =>
    dispatch(loadLogListAction(res))
  );
};

export function loadRescentElements(showCurrent, showPast) {
  return function (dispatch) {
    handleQuery(`/demandProjects/rescent/${showCurrent}/${showPast}`)
      .then((riskanalises) => {
        dispatch(fetchRA_Rescent_Success(riskanalises));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchRA_Rescent_Begin());
  };
}

export function searchElements(showPast, search) {
  return function (dispatch) {
    handlePostTextAsParam(`/demandProjects/search/${showPast}`, search)
      .then((riskanalises) => {
        dispatch(fetchRA_Rescent_Success(riskanalises));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchRA_Rescent_Begin());
  };
}

export const changeProjectPhase = (phase) => (dispatch, getState, hooks) => {
  const { project, viewId } = getState().RMP;
  const projectCopy = { ...project };
  dispatch(turnOnRMPLoader());

  updateProjectPhaseAPI(viewId, project.versionId, phase)
    .then((res) => {
      projectCopy.phase = phase;
      dispatch(updateProjectAction(projectCopy));
      dispatch(turnOffRMPLoader());
      showCommentSnack(hooks, res, project.versionId, viewId);
    })
    .catch((e) => {
      console.log(e);
      dispatch(turnOffRMPLoader());
    });
};

export const loadDemandCoverage = () => (dispatch, getState) => {
  const { project, viewId } = getInitState(getState);

  dispatch(turnOnRMPLoader());
  loadDemandCoverageAPI(project.versionId, viewId)
    .then((res) => {
      dispatch(updateDemandCoverageCellsAction(res));
      dispatch(dispatch(turnOffRMPLoader()));
    })
    .catch((e) => {
      dispatch(dispatch(turnOffRMPLoader()));
      console.log(e);
    });
};

export const exportProject =
  () =>
  (dispatch, getState, { snackbar }) => {
    const { viewId, project } = getState().RMP;
    const generatedReport = { ...exportRMPMockUP(), elementName: project.name };

    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="RMP.PrintManager.Snackbar.ExportProject" defaultMessage="...exporting project" />
    );

    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');

    handlePost(`/report-react/generateReport/${generatedReport.uuid}/publishDemandProject`, {
      ...reportMockUp,
      versionId: project.versionId,

      locale
    })
      //    generateReportAPI(viewId, project.versionId, generatedReport.uuid, reportType, 'all')
      .then((callback) => {
        dispatch(getVersionInfo(project.versionId));
        //   debugger
        checkReportStatus(callback, snackbar, generatingSnackbar, generatedReport, 'zip');
      })

      .catch((error) => {
        showSnackbarError(snackbar, error);
      });
  };

export const generatePrintReport =
  (reportType) =>
  (dispatch, getState, { snackbar }) => {
    const { viewId, project } = getState().RMP;
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    const generatedReport = generateRMPReportMockUp();
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="RMP.PrintManager.Snackbar.GeneratingLabel" defaultMessage="...generating report" />
    );

    handlePost(`/report-react/generateReport/${generatedReport.uuid}/projectPlanning`, {
      ...reportMockUp,
      versionId: project.versionId,

      reportNum: reportType,
      scope: 'all',
      locale,
      reportNumber: reportType
    })
      //    generateReportAPI(viewId, project.versionId, generatedReport.uuid, reportType, 'all')
      .then((callback) => {
        checkReportStatus(callback, snackbar, generatingSnackbar, generatedReport, 'pdf');
      })

      .catch((error) => {
        showSnackbarError(snackbar, error);
      });
  };

export const getStatusSummary = () => (dispatch, getState) => {
  const { viewId, project } = getState().RMP;
  getUpdateStatusSummaryAPI(project.versionId, viewId).then((res) => {
    dispatch(updateStatusSummaryAction(res));
  });
};

export const updateStatusSummary = (state) => (dispatch, getState) => {
  const { viewId, project } = getState().RMP;
  dispatch(turnOnRMPLoader());
  setStatusSummaryAPI(project.versionId, viewId, state)
    .then((res) => {
      console.log(res);
      dispatch(resetRMPAction());
      dispatch(turnOnRMPLoader());
      dispatch(clearProject());
      dispatch(getVersionInfo(project.versionId));
      dispatch(fetchProject(project.versionId));
    })
    .catch((e) => {
      console.log(e);
      dispatch(turnOffRMPLoader());
    });
};
