import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default function ModalCenter({ children, open = true }) {
  const classes = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}><div>{children}</div></Fade>
    </Modal>
  );
}
