import { FormattedMessage } from 'react-intl';
import React from 'react';

/* export const getTileTranslationStatus = (status, checkedOutByUser) => {
    switch (status) {
        case 'archived':
            return <FormattedMessage id="element.status.archived" defaultMessage="Archived"/>;
        case 'approved':
            return <FormattedMessage id="element.status.approved" defaultMessage="Approved"/>;
        case 'checked-out':
            return <FormattedMessage id="element.status.checkedout" defaultMessage="Checked out {checkedOutByUser}" values={{
                checkedOutByUser: checkedOutByUser,
            }}/>;
        default:
            return <FormattedMessage id="element.status.workingcopy" defaultMessage="Working copy"/>;
    }
}; */

export const getCardStatus = (element, myUser) => {
  const status = getTileElementStatus(element, myUser);
  switch (status) {
    case 'archived':
      return <FormattedMessage id="element.status.archivedCARD" defaultMessage="AR" />;
    case 'approved':
      return <FormattedMessage id="element.status.approvedCARD" defaultMessage="AP" />;
    case 'checked-out':
      return <FormattedMessage id="element.status.checkedoutCARD" defaultMessage="C" />;
    default:
      return <FormattedMessage id="element.status.workingcopyCARD" defaultMessage="W" />;
  }
};

export const getTileStatus = (element, myUser) => {
  const status = getTileElementStatus(element, myUser);
  switch (status) {
    case 'archived':
      return <FormattedMessage id="element.status.archived" defaultMessage="Archived" />;
    case 'approved':
      return <FormattedMessage id="element.status.approved" defaultMessage="Approved" />;
    case 'checked-out':
      return (
        <FormattedMessage
          id="element.status.checkedout"
          defaultMessage="Checked out {checkedOutByUser}"
          values={{
            checkedOutByUser: element.checkedOutByUser
          }}
        />
      );
    default:
      return <FormattedMessage id="element.status.workingcopy" defaultMessage="Working copy" />;
  }
};

export const getTileElementStatus = (element, myUser) => {
  if (element.archivedAt) {
    return 'archived';
  }
  if (element.approvedAt) {
    return 'approved';
  }
  if (element.checkedOut && element.checkedOutByUser && element.checkedOutByUser !== myUser.userName) {
    return 'checked-out';
  }
  return 'working-copy';
};

export const getTileElementStatusMessage = (element, myUser, intl) => {
  if (element.archivedAt) {
    return intl.formatMessage({ id: 'element.status.archived', defaultMessage: 'Archived' });
  }
  if (element.approvedAt) {
    return intl.formatMessage({ id: 'element.status.approved', defaultMessage: 'Approved' });
  }
  if (element.checkedOut && element.checkedOutByUser && element.checkedOutByUser !== myUser.userName) {
    return intl.formatMessage({
      id: 'element.status.checkedout',
      defaultMessage: 'Checked out {checkedOutByUser}',
      values: { checkedOutByUser: element.checkedOutByUser }
    });
  }
  return intl.formatMessage({ id: 'element.status.workingcopy', defaultMessage: 'Working copy' });
};
