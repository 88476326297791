export default function getCriteriaDefaultState(className) {
  switch (className) {
    case 'se.zert.backend.search.InsideContextElementCriteria':
      return {
        className: 'se.zert.backend.search.InsideContextElementCriteria',
        values: [
          {
            key: 'Context',
            value: ''
          }
        ]
      };
    case 'se.zert.backend.search.IdCriteria':
      return {
        className: 'se.zert.backend.search.IdCriteria',
        values: [
          {
            key: 'Type',
            value: 'element'
          },
          {
            key: 'Id',
            value: ''
          }
        ]
      };
    case 'se.zert.backend.search.StatusCriteria':
      return {
        className: 'se.zert.backend.search.StatusCriteria',
        values: [
          {
            key: 'Status',
            value: 'approved'
          }
        ]
      };

    case 'se.zert.backend.search.VersionCriteria':
      return {
        className: 'se.zert.backend.search.VersionCriteria',
        values: [
          {
            key: 'Filter',
            value: 'current'
          }
        ]
      };

    case 'se.zert.backend.search.NameCriteria':
      return {
        className: 'se.zert.backend.search.NameCriteria',
        values: [
          {
            key: 'Type',
            value: 'standard'
          },
          {
            key: 'SimilarityThreshold',
            value: '0.2'
          },
          {
            key: 'Name',
            value: ''
          }
        ]
      };

    case 'se.zert.backend.search.DateCriteria':
      return {
        className: 'se.zert.backend.search.DateCriteria',
        values: [
          {
            key: 'Status',
            value: 'created'
          },
          {
            key: 'Type',
            value: 'after'
          },
          {
            key: 'AfterUnit',
            value: 'days'
          },
          {
            key: 'AfterValue',
            value: '1'
          }
        ]
      };

    case 'se.zert.backend.search.ElementTypeCriteria':
      return {
        className: 'se.zert.backend.search.ElementTypeCriteria',
        values: [],
        MimeTypeCount: 0
      };

    case 'se.zert.rm.search.RiskRowCriteria':
      return {
        className: 'se.zert.rm.search.RiskRowCriteria',
        values: [
          {
            key: 'CriteriaCount',
            value: '0'
          }
        ]
      };
    case 'se.zert.rm.search.TemplatesCriteria':
      return {
        className: 'se.zert.rm.search.TemplatesCriteria',
        values: [],
        TemplatesCount: 0
      };
    case 'se.zert.rm.search.ChecklistCriteria':
      return {
        className: 'se.zert.rm.search.ChecklistCriteria',
        values: [
          {
            key: 'incorrect',
            value: 'false'
          },
          {
            key: 'noAnswers',
            value: 'false'
          },
          {
            key: 'countGTM',
            value: '-1'
          },
          {
            key: 'countGT',
            value: '-1'
          },
          {
            key: 'countLESSM',
            value: '-1'
          },
          {
            key: 'countLESS',
            value: '-1'
          }
        ]
      };
    case 'se.zert.backend.search.RiskAnalisisCriteria':
      return {
        className: 'se.zert.backend.search.RiskAnalisisCriteria',
        values: [
          {
            key: 'step',
            value: "risk-identification"
          },
          {
            key: 'countGTM',
            value: '-1'
          },
          {
            key: 'countGT',
            value: '-1'
          },
          {
            key: 'countLESSM',
            value: '-1'
          },
          {
            key: 'countLESS',
            value: '-1'
          }
        ]
      };
    default:
      throw new Error('Invalid className of criteria');
  }
}
