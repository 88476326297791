import { createInProgressSnack } from '@zert-packages/components/common/snackBar';
import { FormattedMessage } from 'react-intl';
import { showSnackbarError } from '@zert-packages/components/Snackbars';
import React from 'react';
import { checkReportStatus } from '@zert-packages/actions/checklistActions';
import { toLocaleString } from '@zert-packages/plugins/ExplorerPlugin/SearchConditions/helpers/getLocaleByLanguageCode';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { v4 as uuidv4 } from 'uuid';
import { exportTermDB } from './API';
import ExportTermDBDialog from './ExportTermDBDialog';

export const generateTermDBExport =
  (versionId) =>
  (dispatch, getState, { snackbar }) => {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="TermDB.GeneratingLabelCommon" defaultMessage="...generating report" />
    );
    const newReport = {
      name: <FormattedMessage id="TermDB.GeneratingLabelCommon" defaultMessage="...generating report" />,
      reportUUID: uuidv4()
    };

    renderInExceptionRoot(ExportTermDBDialog, {
      versionId,
      onConfirm: async (selectedCriteria, format) => {
        exportTermDB(
          versionId,
          selectedCriteria.map((item) => toLocaleString(item)),
          newReport.reportUUID,
          format
        )
          .then((callback) => {
            checkReportStatus(callback, snackbar, generatingSnackbar, newReport, format);
          })
          .catch((error) => {
            showSnackbarError(snackbar, error);
          });
      }
    });
  };
