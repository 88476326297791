import React, { memo } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';

const CheckboxWithMemo = memo(
  ({ isChecked, onChange, label, disabled }) => (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          checked={isChecked}
          onChange={(event) => onChange(event.target.checked)}
          color="primary"
        />
      }
      label={label}
    />
  ),
  selectiveMemoComparison(
    (p) => p.isChecked,
    (p) => p.disabled
  )
);

export const CheckboxWithMemoAlt = memo(
  ({ isChecked, onChange, label, disabled }) => (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          checked={isChecked}
          onChange={(event) => onChange(event.target.checked)}
          color="primary"
        />
      }
      label={label}
    />
  ),
  selectiveMemoComparison(
    (p) => p.isChecked,
    (p) => p.disabled,
    (p) => p.label
  )
);

export default CheckboxWithMemo;
