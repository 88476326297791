
interface TemplateKey<Type> {
  [key: string]: (e: Type) => string;
}

export interface DefaultKeyType {
  versionId: number,
  id: number
}

export const defaultKey = {
  versionId: (e: DefaultKeyType) => e.versionId?.toString(),
  id: (e: DefaultKeyType) => e.id.toString()
};

function getRegExp<Type>(key: TemplateKey<Type>) {
  return new RegExp(Object.keys(key).map(k => `:${k}`).join('|'), 'gi');
}

export function getFillFunction<Type>(key: TemplateKey<Type>) {
  const re = getRegExp(key);
  return (e: Type, str: string) => str.replace(re, (m) => key[m.slice(1)](e));
}

export function fillTemplateString<Type>(key: TemplateKey<Type>, dataSource: Type, str: string) {
  const re = getRegExp(key);
  return str.replace(re, (m) => key[m](dataSource));
}