import showCommentSnack, { createInProgressSnack } from '@zert-packages/components/common/snackBar';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { handleQuery } from '@zert-packages/actions/api';
import generateRMPReportMockUp from '@zert-packages/plugins/ReportPlugin/generateRMPReportMockUp';
import { sendInvitationAPI, storeProjectAPI } from '../APIs';
import { turnOffRMPLoader, turnOnRMPLoader, updateProjectAction } from './rmpReducer';

const throwError = (e) => {
  throw new Error(e);
};

export const removeMember = (userName) => (dispatch, getState, hooks) => {
  const { project, viewId } = getState().RMP;
  const newProject = { ...project };
  dispatch(turnOnRMPLoader());
  newProject.members = project.members.filter((member) => member.username !== userName);
  storeProjectAPI(viewId, newProject)
    .then((res) => {
      dispatch(updateProjectAction(newProject));
      console.log(res);
      dispatch(turnOffRMPLoader());
      showCommentSnack(hooks, res, project.versionId, viewId);
    })
    .catch(throwError);
};

export const addMember = (member) => (dispatch, getState, hooks) => {
  const { project, viewId } = getState().RMP;
  const newProject = { ...project };
  dispatch(turnOnRMPLoader());
  newProject.members = [...project.members, member];
  storeProjectAPI(viewId, newProject)
    .then((res) => {
      dispatch(updateProjectAction(newProject));
      dispatch(turnOffRMPLoader());
      showCommentSnack(hooks, res, project.versionId, viewId);
    })
    .catch(throwError);
};

export const updateMember = (newMember) => (dispatch, getState, hooks) => {
  const { project, viewId } = getState().RMP;
  const newProject = { ...project };
  dispatch(turnOnRMPLoader());
  newProject.members = project.members.map((member) => {
    if (member.username !== newMember.username) return member;
    return { ...newMember };
  });
  storeProjectAPI(viewId, newProject)
    .then((res) => {
      dispatch(updateProjectAction(newProject));
      dispatch(turnOffRMPLoader());
      showCommentSnack(hooks, res, project.versionId, viewId);
    })
    .catch(throwError);
};

export const updateRoundRobin = (roundRobin) => (dispatch, getState, hooks) => {
  const { project, viewId } = getState().RMP;
  const newProject = { ...project };
  dispatch(turnOnRMPLoader());

  const includedInProjectMembers = () => project.members.find((member) => member.username === roundRobin.username);

  if (roundRobin !== null && includedInProjectMembers()) {
    newProject.members = [...newProject.members, roundRobin];
  }

  newProject.roundRobin = roundRobin;

  storeProjectAPI(viewId, newProject)
    .then((res) => {
      dispatch(updateProjectAction(newProject));
      dispatch(turnOffRMPLoader());
      showCommentSnack(hooks, res, project.versionId, viewId);
    })
    .catch(throwError);
};

export const sendInvitation =
  (members, customMessage) =>
    (dispatch, getState, { snackbar }) => {
      const { project, viewId } = getState().RMP;

      let sendingSnackbar = null;

      const generatedReport = generateRMPReportMockUp();

      const checkStatus = (report) => {
        handleQuery(`/zert/report-react/updateProgressStatus/${report.callbackId}/false`).then((newReport) => {
          if (newReport !== null && newReport.active) {
            checkStatus(newReport);
          } else {
            snackbar.closeSnackbar(sendingSnackbar);
          }
        });
      };

      sendInvitationAPI(viewId, members, customMessage, generatedReport.uuid).then((res) => {
        sendingSnackbar = createInProgressSnack(
          snackbar,
          <FormattedMessage id="RMP.Members.SendingInvitations" defaultMessage="Sending invitations...." />,
          {
            variant: 'info',
            persist: true
          }
        );
        checkStatus(res);
      });
    };
