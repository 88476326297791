import React, { Fragment, useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import SettingsField from '@zert-packages/components/SettingsComponents/SetttingField';
import makeStyles from '@mui/styles/makeStyles';
import { convertSettingsToProperties } from '../servicesMiddlewares';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  }
}));

function ReadOnlySettingsPaper({ settings }) {
  const classes = useStyles();
  const [layoutProperties, setLayoutProperties] = useState();

  useEffect(() => {
    setLayoutProperties(convertSettingsToProperties(settings));
  }, [settings]);

  if (!layoutProperties) {
    return <></>;
  }

  return (
    <Paper className={classes.paper}>
      <Typography component="h3" gutterBottom>
        <FormattedMessage id="Preview.GeneralTable.LayoutSettings" defaultMessage="Settings" />
        <br />
      </Typography>
      {settings.map((setting) => (
        <SettingsField values={layoutProperties} readOnly settings={setting.property} />
      ))}
    </Paper>
  );
}

export default ReadOnlySettingsPaper;
