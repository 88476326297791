import { Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import DialogHandleLabelPart from './DialogHandleLabelPart';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 350,
    height: 350,
    padding: 11,
    background: '#E2F2F6'
  },
  wrapperTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      cursor: 'pointer'
    }
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 700
  },
  wrapperButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 10
  },
  button: {
    color: '#ffffff',
    background: '#4db1d3',
    marginLeft: 10,
    minWidth: 80,
    border: '1px solid #4db1d3',
    outline: 'none !important',
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
      color: '#212529',
      border: '1px solid #4db1d3'
    },
    '&:focus': {
      backgroundColor: '#4db1d3'
    }
  },
  wrapperContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 20px',
    gridColumnGap: 10,
    marginBottom: 25,
    height: '100%',
    marginTop: 10
  },
  wrapperEditor: {
    color: '#4db1d3',
    '& svg': {
      cursor: 'pointer'
    }
  },
  containerTable: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr'
  },
  PaperRoot: {},

  activeLabel: {
    background: '#4db1d3',
    color: '#ffffff'
  },
  defaultLabel: {
    background: '#ffffff',
    color: '#212529',
    cursor: 'pointer'
  },
  textRightBlock: {
    paddingLeft: 5
  }
}));

function DialogEditLabelPart({ values, setValues, text, handleTypeInclude }) {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [selectedLabel, setSelectedLabel] = useState(0);
  const [label, setLabel] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const deleteLabel = () => {
    if (label.translations.length === 0 || label.locales === 0) {
      return;
    }
    const findTranslations = label.translations.filter((el, i) => i !== selectedLabel);
    const findLocales = label.locales.filter((el, i) => i !== selectedLabel);
    const result = {
      ...label,
      translations: findTranslations,
      locales: findLocales
    };
    setLabel(result);
    if (result.locales.length === 0) {
      handleTypeInclude('', true);
    }
    if (selectedLabel === 0) {
      return;
    }
    setSelectedLabel((pre) => pre - 1);
  };

  const editLabel = () => {
    if (label.locales.length === 0) {
      return;
    }
    renderInExceptionRoot(DialogHandleLabelPart, { label, values, setValues, handleTypeInclude });
  };

  useEffect(() => {
    if (text) {
      setLabel(text);
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paperScrollPaper: cl.root
      }}
    >
      <div className={cl.wrapperTitle}>
        <span className={cl.title}>Edit Label</span>
        <CloseIcon onClick={handleClose} />
      </div>
      <div className={cl.wrapperContainer}>
        <Paper className={cl.PaperRoot}>
          <div
            style={{
              borderBottom: '1px solid #C6C6C5'
            }}
          >
            <div className={cl.containerTable}>
              <div
                style={{
                  borderRight: '1px solid #C6C6C5'
                }}
              >
                <span className={cl.textRightBlock}>Locale</span>
              </div>
              <div className={cl.textRightBlock}>Text</div>
            </div>
          </div>

          {label && (
            <div
              className={cl.containerTable}
              style={{
                height: 'Calc(100% - 21px)'
              }}
            >
              <div
                style={{
                  borderRight: '1px solid #C6C6C5'
                }}
              >
                {label.locales &&
                  label.locales.map((el, i) => (
                    <div
                      key={i}
                      className={selectedLabel === i ? cl.activeLabel : cl.defaultLabel}
                      onClick={() => setSelectedLabel(i)}
                    >
                      <span className={cl.textRightBlock}>{el}</span>
                    </div>
                  ))}
              </div>
              <div>
                {label.translations &&
                  label.translations.map((el, i) => (
                    <div
                      key={i}
                      className={selectedLabel === i ? cl.activeLabel : cl.defaultLabel}
                      onClick={() => setSelectedLabel(i)}
                    >
                      <span className={cl.textRightBlock}>{el}</span>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </Paper>
        <div className={cl.wrapperEditor}>
          {/* <AddIcon onClick={addLabel}/> */}
          <EditIcon onClick={editLabel} />
          <DeleteIcon onClick={deleteLabel} />
        </div>
      </div>

      <div className={cl.wrapperButton}>
        <Button className={cl.button} onClick={handleClose}>
          OK
        </Button>
        <Button className={cl.button} onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}

export default DialogEditLabelPart;
