import React, { Fragment } from 'react';
import { getTranslationStatuses } from '../helpers/getTranslationStatuses';
import TranslationStatusLabel from './TranslationStatusLabel';

function TranslationLegend() {
  return (
    <>
      {Object.keys(getTranslationStatuses).map((key) => (
        <TranslationStatusLabel type={key} />
      ))}
    </>
  );
}

export default TranslationLegend;
