import React, { Fragment } from 'react';
import {
  Fade,
  Popper,
  Grid,
  Typography,
  Paper,
  ClickAwayListener,
  ListItemText,
  List,
  ListItem,
  ListItemAvatar
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import cn from 'classnames';
import { DEMANDS_STATUSES, DEMANDS_STATUSES_COMP, STATUSES } from '../../CONSTS';

function StatusWithText({ text, img: Img }) {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Img />
      </Grid>
      <Grid item>
        <Typography variant="button">{text}</Typography>
      </Grid>
    </Grid>
  );
}

export function Status({ statusIndex, withText }) {
  const ERROR_OUT_OF_RANGE = `Status index is out of range! Please provide the index from 0 to ${STATUSES.length}`;
  if (statusIndex < 0 || statusIndex > STATUSES.length) throw new Error(ERROR_OUT_OF_RANGE);
  const { Img, text } = STATUSES[statusIndex];
  return withText ? <StatusWithText img={Img} text={text} /> : <Img />;
}

export function StatusFrom({ statuses, statusIndex, withText, backup_statuses }) {
  /* const ERROR_OUT_OF_RANGE = `Status index is out of range! Please provide the index from 0 to ${statuses.length}`;
  if (statusIndex < 0 || statusIndex > statuses.length) throw new Error(ERROR_OUT_OF_RANGE);
  */
  const { Img, text } = statuses.find(e => e.statusIndex === statusIndex) || backup_statuses[statusIndex];
  return withText ? <StatusWithText img={Img} text={text} /> : <Img />;
}

export function StatusDescription() {
  return (
    <Grid container spacing={4}>
      {STATUSES.map(({ statusIndex }) => (
        <Grid item key={statusIndex}>
          <Status statusIndex={statusIndex} withText />
        </Grid>
      ))}
    </Grid>
  );
}

const useChangeableStatusStyles = makeStyles((theme) => ({
  listContainer: {
    padding: 6
  },
  item: {
    userSelect: 'none',
    cursor: 'pointer',
    transition: 'color .5 ease',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  disabledImg: {
    opacity: '0.5'
  },
  disabledItem: {
    cursor: 'auto',
    '&:hover': {}
  }
}));

const StyledListItemAvatar = withStyles({
  root: {
    minWidth: 32
  }
})(ListItemAvatar);

export function ChangeableDocStatus(props) {
  return <ChangeableStatus {...props} statuses={STATUSES} />;
}

export function ChangeableDemandStatus(props) {
  return <ChangeableStatus {...props} statuses={DEMANDS_STATUSES_COMP} backup_statuses={DEMANDS_STATUSES} />;
}

export function ChangeableStatus({
  disabled = false,
  disabledIndexes = [],
  statusIndex,
  onChange,
  statuses,
  backup_statuses
}) {
  const cl = useChangeableStatusStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    if (disabled) return;
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const onSelect = (changeState, disabled) => (e) => {
    if (disabled) return;
    onChange(changeState);
    setAnchorEl(null);
  };
  const handleOnClickAway = () => {
    if (anchorEl) setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <div
        onClick={handleClick}
        className={cn({ [cl.disabledImg]: disabled })}
        style={{ cursor: 'pointer', lineHeight: '1' }}
      >
        <StatusFrom statuses={statuses} statusIndex={statusIndex} backup_statuses={backup_statuses} withText={false} />
      </div>
      <Popper open={open} anchorEl={anchorEl} placement="right-end">
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <ClickAwayListener onClickAway={handleOnClickAway}>
              <Paper className={cl.listContainer}>
                <List dense>
                  {statuses.map(({ statusIndex: _statusIndex, Img, text }) => {
                    const disabled = disabledIndexes.includes(_statusIndex);
                    return (
                      <ListItem
                        key={_statusIndex}
                        className={cn({
                          [cl.item]: true,
                          [cl.disabledItem]: disabled
                        })}
                        selected={statusIndex === _statusIndex}
                        onClick={onSelect(_statusIndex, disabled)}
                        disabled={disabled}
                      >
                        <StyledListItemAvatar>
                          <Img />
                        </StyledListItemAvatar>
                        <ListItemText primary={text} />
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
    </>
  );
}
