import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { ErrorDialog } from '../../ErrorBoundary/ErrorGlobalFallbackComponent';
import dialogCustom from '../../common/dialogCustom';

const useStyles = makeStyles({
  root: {
    height: '76px',
    maxHeight: '76px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    marginBottom: 5,
    padding: '8px 10px'
  },
  message: {
    fontSize: '1rem',
    color: '#666',
    letterSpacing: 1
  }
});

function TileError({ error, versionId }) {
  const cl = useStyles();
  const showError = () => {
    dialogCustom(ErrorDialog, { errorState: error });
  };
  return (
    <div key={versionId} className={cl.root}>
      <div className={cl.message}>
        <FormattedMessage id="TileError.Message" defaultMessage="Opps... something went wrong" />
      </div>
      <Button onClick={showError} variant="outlined" color="secondary">
        <FormattedMessage id="TileError.ShowErrors" defaultMessage="Show errors" />
      </Button>
    </div>
  );
}

export default TileError;
