import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export default function SaveConfirmDialog({ callbackClickNo, callbackClickYes, handleClose }) {
  const handleClickNo = () => {
    callbackClickNo();
    handleClose();
  };
  const handleClickYes = () => {
    callbackClickYes();
    handleClose();
  };
  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>
        <FormattedMessage id="SaveConfirmDialogTitle" defaultMessage="Changing tab" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id="SaveConfirmDialogDescription" defaultMessage="Do you want to save your changes?" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickNo} color="primary">
          <FormattedMessage id="SaveConfirmDialogNo" defaultMessage="No" />
        </Button>
        <Button onClick={handleClickYes} color="primary" variant="outlined" autoFocus>
          <FormattedMessage id="SaveConfirmDialogYes" defaultMessage="Yes" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
