import React, { memo } from 'react';
import { TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';

const DescriptionField = memo(
  ({ value, disabled, onChange, ...other }) => (
    <TextField
      variant="standard"
      fullWidth
      multiline
      disabled={disabled}
      value={value || ''}
      onChange={onChange}
      rows="7"
      label={<FormattedMessage id="RMP.EditRiskAssessmentDialog.DescriptionField" defaultMessage="Description" />}
      {...other}
    />
  ),
  selectiveMemoComparison(
    (p) => p.value,
    (p) => p.disabled
  )
);
export default DescriptionField;
