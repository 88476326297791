import React, { useContext, useState, useEffect, useRef } from 'react';

import { TextField } from '@mui/material';

import TextFieldWithLanguageSwitch from '@zert-packages/components/common/TextFieldWithLangaugeSwitch';
import { FormattedMessage } from 'react-intl';
import RolesList from '../Roles';

import getIdentifier from '../helpers/getIdentifier';
import { CatalogContext } from '../common/CatalogProvider';

import './TreeNodeContextEditor.sass';

function TreeNodeContextEditor() {
  const { selectedNode, debounceHandleRenderTree, isEverythingDisabled } = useContext(CatalogContext);

  const [name, setName] = useState(selectedNode.label);
  const [identifier, setIdentifier] = useState(getIdentifier(selectedNode));
  const noOtherLabelLanguage = useRef('New node');

  useEffect(() => {
    setName(selectedNode.label);
    setIdentifier(getIdentifier(selectedNode));
  }, [setName, selectedNode]);

  const onChangeIdentifier = (event) => {
    const { value } = event.target;
    setIdentifier(value);
    selectedNode.metadata = selectedNode.metadata.map((meta) => {
      if (meta.identifier === 'posid') {
        return {
          ...meta,
          value
        };
      }
      return meta;
    });
    debounceHandleRenderTree();
  };

  const onChangeName = (newLabel) => {
    const enIndex = newLabel.locales.findIndex((v) => v === 'en');
    const svIndex = newLabel.locales.findIndex((v) => v === 'sv');
    if (enIndex !== -1 && svIndex !== -1 && name.translations[enIndex] === noOtherLabelLanguage.current) {
      const translation = newLabel.translations[svIndex];
      noOtherLabelLanguage.current = translation;
      newLabel.translations[enIndex] = translation;
    }

    setName(newLabel);
    selectedNode.label = newLabel;
    debounceHandleRenderTree();
  };
  return (
    <div className="tree_root_context__container">
      <TextFieldWithLanguageSwitch
        state={name}
        onChange={onChangeName}
        label={<FormattedMessage id="tree.node.name.top" defaultMessage="Name" />}
        disabled={isEverythingDisabled}
      />

      <TextField
        label={<FormattedMessage id="tree.node.identifier" defaultMessage="Identifier" />}
        margin="dense"
        variant="outlined"
        value={identifier}
        onChange={onChangeIdentifier}
        disabled={isEverythingDisabled}
      />

      <RolesList />
    </div>
  );
}

export default TreeNodeContextEditor;
