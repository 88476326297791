import getParameterAsInt from '@zert-packages/utils/getParameterAsInt';
import {FormattedMessage, injectIntl} from 'react-intl';
import React, {useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {loadPart} from "./API";
import SparePartToolbar from "./components/SparePartToolbar";
import PartImagesTabs from "./components/PartImagesTabs";
import PartMachineTable from "./PartMachineTable";
import Resizable from "./components/resizable/Resizable";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {useHistory} from "react-router-dom";
import PartHeader from "./components/PartHeader";
import OpenDialog from "./OpenPartDialog";
import LoaderModal from "@zert-packages/components/shared/LoaderModal";
import {getVersionInfo} from "@zert-packages/actions/coreReducers";
import {useDispatch} from "react-redux";
import SideMenu from "./components/sideMetadataMenu/SideMenu";
import {getTranslations} from "../BlockPlugin/API";
import {initSparePartContext, SparePartContext} from "./store/SparePartContext";

export const formStyle = (theme) =>
    createStyles({
        partViewContainer: {
            padding: '10px',
            height: 'calc(100% - 56px)',
            background: '#e3f2f7'
        },
        mainContainer: {
            width: '100%',
            height: '100%'
        },
        rootBar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '5px'
        },
        bodyContainer: {
            padding: '8px',
            // height: 'calc(100% - 52px)',
            width: '100%',
            minHeight: '300px',

            display: 'flex',
            '@media (max-width: 900px)': {
                flexDirection: 'column'
            }
        },
        AppBar: {
            display: 'grid',
            gridTemplateColumns: '1fr auto auto',
            zIndex: '10',
            overflow: 'hidden',
            paddingRight: 8
        },
        formControl: {
            margin: theme.spacing(1),
            width: '100%'
        }
    });
const useStyles = makeStyles(formStyle);

const getElementIdentifier = element => {
    return element?.element?.elementUUID || element?.elementInfo?.elementUUID;
}

function PartView(props) {
    const cl = useStyles();
    const history = useHistory()
    const dispatch = useDispatch()
    const {match: {params}} = props;
    const versionId = getParameterAsInt(params.versionId);
    const [values, setValues] = useState(initSparePartContext);

    useEffect(() => {
        dispatch(getVersionInfo(versionId));
        loadPart(versionId)
            .then((loadedPart) => {
                setValues(prev => ({
                    ...prev,
                    viewedElements: [loadedPart],
                    currentViewedElement: loadedPart,
                    currentElementVersionId: loadedPart.element.versionId,
                    elementType: loadedPart.element?.mimeType,
                    parts: loadedPart.partItems,
                    partImages: loadedPart.imageItems,
                    partVersionId: versionId,
                    selectedElementLanguage: loadedPart.element.locale
                }))
            })
    }, [versionId]);
    useEffect(() => {
        if(values.currentElementVersionId) {
            getTranslations(values.currentElementVersionId).then(result => {
                setValues({...values, elementTranslations: result})
            })
        }
    }, [values.currentElementVersionId])
    useEffect(() => {
        const beforeUnload = (e) => {
            if (!values.isDataChanged) return;
            e.preventDefault();
        }

        window.addEventListener('beforeunload', beforeUnload);

        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [values.isDataChanged]);

    return (
        <SparePartContext.Provider value={{values, setValues}}>
            <div className={cl.partViewContainer}>
                <Tabs sx={{'& .MuiTabs-flexContainer': { columnGap: '10px' }}}
                      value={getElementIdentifier(values.currentViewedElement)}
                      onChange={(event, newTab) => {
                          const newViewedElement = values.viewedElements[newTab]
                          const newViewedPartElement =  newViewedElement.element || newViewedElement.elementInfo
                          if(newViewedPartElement?.mimeType === "application/zert-part") {
                              setValues({
                                  ...values,
                                  currentViewedElement: newViewedElement,
                                  currentElementVersionId: newViewedPartElement.versionId,
                                  elementType: "application/zert-part",
                                  partImages: newViewedElement.imageItems,
                                  parts: newViewedElement.partItems,
                                  partVersionId: newViewedPartElement.versionId,
                                  selectedPart: null,
                                  selectedElement: null
                              })
                          } else {
                              setValues({
                                  ...values,
                                  currentViewedElement: values.viewedElements[newTab],
                                  currentElementVersionId: newViewedElement.elementInfo.versionId,
                                  elementType: "application/zert-image",
                              })
                          }
                      }}>
                    {values.viewedElements.map((viewedElement, idx) => <Tab
                        sx={{
                            backgroundColor: getElementIdentifier(values.currentViewedElement) === getElementIdentifier(viewedElement) ? "white" : '#f2f2f2',
                            position: 'relative',
                            minWidth: '150px'
                        }}
                        label={<>
                            {viewedElement?.element?.name || viewedElement?.elementInfo?.name}
                            <IconButton sx={{position: 'absolute', top: 0, right: 0}} size="small" component="span"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()

                                            if(values.viewedElements.length === 1) {
                                                history.push("/action-plan")
                                                return
                                            }

                                            const newViewedElement = getElementIdentifier(values.currentViewedElement) === getElementIdentifier(viewedElement)
                                                ? values.viewedElements[idx - 1]
                                                : values.currentViewedElement

                                            if(newViewedElement?.element?.mimeType === "application/zert-part") {
                                                setValues({
                                                    ...values,
                                                    currentViewedElement: newViewedElement,
                                                    currentElementVersionId: newViewedElement.element.versionId,
                                                    elementType: "application/zert-part",
                                                    partImages: newViewedElement.imageItems,
                                                    parts: newViewedElement.partItems,
                                                    partVersionId: newViewedElement.element.versionId,
                                                    selectedPart: null,
                                                    selectedElement: null,
                                                    viewedElements: values.viewedElements
                                                        .filter(elem => getElementIdentifier(elem) !== getElementIdentifier(viewedElement))
                                                })
                                            } else {
                                                setValues({
                                                    ...values,
                                                    currentViewedElement: newViewedElement,
                                                    currentElementVersionId: newViewedElement.elementInfo.versionId,
                                                    elementType: "application/zert-image",
                                                    viewedElements: values.viewedElements
                                                        .filter(elem => getElementIdentifier(elem) !== getElementIdentifier(viewedElement))
                                                })
                                            }
                                        }}>
                                <CloseIcon/>
                            </IconButton>
                        </>} key={idx}/>)}
                </Tabs>

                {values.viewedElements.map((viewedElement, idx) => <Box
                    sx={{backgroundColor: 'white', display: "flex"}}
                    hidden={getElementIdentifier(values.currentViewedElement) !== getElementIdentifier(viewedElement)}
                    id={`tabpanel-${idx}`}
                >
                    {getElementIdentifier(values.currentViewedElement) === getElementIdentifier(viewedElement) && (
                        <Box>
                            {values && <>
                                <PartHeader partData={values.currentViewedElement}/>
                                <SparePartToolbar selectedElement={values.selectedElement}/>
                            </>}
                            <Box sx={{display: 'grid', gridTemplateColumns: '1fr 4fr', minHeight: '500px'}}>
                                <SideMenu/>
                                {values.elementType === "application/zert-image" &&
                                    <PartImagesTabs partImages={values.partImages}/>}
                                {values.elementType === "application/zert-part" &&
                                    <div className={cl.bodyContainer}>
                                        <Resizable leftPart={<PartImagesTabs partImages={values.partImages}/>}
                                                   rightPart={<PartMachineTable versionId={versionId} elements={values.parts}/>}
                                        />
                                    </div>
                                }
                            </Box>
                        </Box>
                    )}
                </Box>)}
            </div>
            {values.dialog.open && (
                <OpenDialog
                    dialog={values.dialog}
                    setDialog={(dialogData) => {
                        setValues(prev => ({
                            ...prev,
                            dialog: dialogData
                        }))
                    }}
                    editPart={values.dialog.editPart}
                    hotspotToEdit={values.dialog.hotspotToEdit}
                />
            )}

            <LoaderModal open={values.isLoading}>
                <FormattedMessage id="CLM.TranslationJOBView.Loading" defaultMessage="Loading..."/>
            </LoaderModal>
        </SparePartContext.Provider>
    );
}

export default injectIntl(PartView);
