import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { TextFieldWithLanguageSwitchMemo } from '@zert-packages/components/common/TextFieldWithLangaugeSwitch';
import getTranslation from '@zert-packages/utils/getTranslation.new';
import { filterActive } from './DemandsTemplateManager';
import { getReplaceString } from '../../../RMPPlugin/components/LongSelectList';

const useStyles = makeStyles({
  DialogContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    '& > *': {
      marginTop: 12
    }
  },
  contentContainer: {
    padding: 10
  }
});

const otherCategories = [
  {
    value: 'ELECTRICAL',
    fullLabel: <FormattedMessage id="Administration.Demands.Other.Category.ELECTRICAL" defaultMessage="ELECTRICAL" />
  },
  {
    value: 'MACHINERY',
    fullLabel: <FormattedMessage id="Administration.Demands.Other.Category.MACHINERY" defaultMessage="MACHINERY" />
  },
  {
    value: 'GENERAL',
    fullLabel: <FormattedMessage id="Administration.Demands.Other.Category.GENERAL" defaultMessage="GENERAL" />
  }
];
const standardCategories = [
  {
    value: 'HARMONIZED_STANDART',
    fullLabel: (
      <FormattedMessage
        id="Administration.Demands.Standard.Category.HARMONIZED_STANDART"
        defaultMessage="HARMONIZED STANDART"
      />
    )
  },
  {
    value: 'STANDART',
    fullLabel: <FormattedMessage id="Administration.Demands.Standard.Category.STANDART" defaultMessage="STANDART" />
  }
];

const statuses = [
  {
    value: 'ACTIVE',
    fullLabel: <FormattedMessage id="Administration.Demands.Status.ACTIVE" defaultMessage="ACTIVE" />
  },
  {
    value: 'REPLACED',
    fullLabel: <FormattedMessage id="Administration.Demands.Status.REPLACED" defaultMessage="REPLACED" />
  },
  {
    value: 'PENDING',
    fullLabel: <FormattedMessage id="Administration.Demands.Status.PENDING" defaultMessage="PENDING" />
  },
  {
    value: 'WINDRAWED',
    fullLabel: <FormattedMessage id="Administration.Demands.Status.WINDRAWED" defaultMessage="WITHDRAWN" />
  },
  {
    value: 'REMOVED',
    fullLabel: <FormattedMessage id="Administration.Demands.Status.REMOVED" defaultMessage="REMOVED" />
  },
  {
    value: 'ARCHIVED',
    fullLabel: <FormattedMessage id="Administration.Demands.Status.ARCHIVED" defaultMessage="ARCHIVED" />
  }
];

const getDemandClass = (type) => {
  switch (type) {
    case 'ClientProvision':
      return 'se.zert.rm.rest.demands.pojo.ClientProvision';
    case 'ClientOther':
      return 'se.zert.rm.rest.demands.pojo.ClientOther';
    case 'ClientDirective':
      return 'se.zert.rm.rest.demands.pojo.ClientDirective';
    case 'ClientStandart':
      return 'se.zert.rm.rest.demands.pojo.ClientStandart';
    default:
      return 'se.zert.rm.rest.demands.pojo.ClientDirective';
  }
};
const defaultEditor = (type) => ({
  class: getDemandClass(type),
  descriptionLabel: {
    defaultLabel: '',
    locales: ['en', 'sv'],
    translations: ['', '']
  },
  elementId: -1,
  category: type == 'ClientOther' ? 'GENERAL' : null,
  type: type == 'ClientStandart' ? 'HARMONIZED_STANDART' : null,
  label: null,
  link: true,
  name: '',
  number: null,

  referencedSize: 0,
  referencedTo: [],
  size: 0,
  templateVersionId: -1,
  url: '',
  versionId: -1
});

function DemandEditorDialog({ unmountComponent, template, onConfirm, demand, classType, status: initialStatus }) {
  const cl = useStyles();
  const [value, setValue] = useState(demand || defaultEditor(classType));
  const [status, setStatus] = useState(initialStatus);
  const [replacedWith, setReplaced] = useState(
    template.replaceMap.hasOwnProperty(value.elementId) ? template.replaceMap[value.elementId] : null
  );
  const handleConfirm = () => {
    unmountComponent();
    onConfirm({ ...value, replacedWith, demandStatus: status });
  };

  const updateName = (value) => {
    setValue((prev) => ({ ...prev, label: value, name: getTranslation(value) }));
  };
  const updateTextValue = (index) => (value) => setValue((prev) => ({ ...prev, [index]: value }));
  const updateValue = (index) => (e) => {
    const { value } = e.target;
    setValue((prev) => ({ ...prev, [index]: value }));
  };

  const listOfActivities = otherCategories.map((activity) => (
    <MenuItem value={activity.value}>{activity.fullLabel}</MenuItem>
  ));

  const listOfCategories = standardCategories.map((activity) => (
    <MenuItem value={activity.value}>{activity.fullLabel}</MenuItem>
  ));

  const listOfStatuses = statuses.map((activity) => <MenuItem value={activity.value}>{activity.fullLabel}</MenuItem>);
  const listOfDemands = filterActive(template, value.class, true)
    .filter((tdemand) => tdemand.elementId != value.elementId)
    .map((demand) => (
      <MenuItem value={demand.elementId}>
        {getTranslation(demand.number)} {getTranslation(demand.label)}
      </MenuItem>
    ));
  const replaceString = getReplaceString(template, value, true);

  return (
    <Dialog open onClose={unmountComponent} fullWidth maxWidth="sm">
      <DialogTitle>
        {demand ? (
          <FormattedMessage id="Administration.DemandEditor.Title" defaultMessage="Edit demand" />
        ) : (
          <FormattedMessage id="Administration.DemandEditor.Createtle" defaultMessage="Create demand" />
        )}
      </DialogTitle>
      <DialogContent className={cl.DialogContent}>
        <FormControl variant="standard" disabled={demand == null}>
          <InputLabel>
            <FormattedMessage id="Administration.DemandEditor.Status" defaultMessage="Status" />
          </InputLabel>
          <Select variant="standard" value={status} onChange={(e) => setStatus(e.target.value)}>
            {listOfStatuses}
          </Select>
        </FormControl>

        <TextFieldWithLanguageSwitchMemo
          disabled={false}
          onChange={updateTextValue('number')}
          state={
            value.number
              ? value.number
              : {
                  defaultLabel: '',
                  locales: ['en', 'sv'],
                  translations: ['', '']
                }
          }
          label={<FormattedMessage id="Administration.DemandEditor.Number" defaultMessage="Number" />}
        />
        <TextFieldWithLanguageSwitchMemo
          disabled={false}
          onChange={updateName}
          state={
            value.label
              ? value.label
              : {
                  defaultLabel: '',
                  locales: ['en', 'sv'],
                  translations: ['', '']
                }
          }
          label={<FormattedMessage id="Administration.DemandEditor.TextLabel" defaultMessage="Label" />}
        />

        <TextField
          style={{ gridArea: 'correct-limit' }}
          type="text"
          label={<FormattedMessage id="Administration.DemandEditor.Link" defaultMessage="Link" />}
          disabled={false}
          margin="dense"
          variant="outlined"
          value={value.url}
          onChange={updateValue('url')}
        />

        {value.class.indexOf('ClientOther') > 0 && (
          <FormControl variant="standard">
            <InputLabel>
              <FormattedMessage id="Administration.DemandEditor.Category" defaultMessage="Category" />
            </InputLabel>
            <Select variant="standard" value={value.category} onChange={updateValue('category')}>
              {listOfActivities}
            </Select>
          </FormControl>
        )}
        {value.class.indexOf('ClientStandart') > 0 && (
          <FormControl variant="standard">
            <InputLabel>
              <FormattedMessage id="Administration.DemandEditor.Type" defaultMessage="Type" />
            </InputLabel>
            <Select variant="standard" value={value.type} onChange={updateValue('type')}>
              {listOfCategories}
            </Select>
          </FormControl>
        )}
        {demand != null && (
          <FormControl variant="standard">
            <InputLabel>
              <FormattedMessage id="Administration.DemandEditor.ReplacedBy" defaultMessage="Replaced By" />
            </InputLabel>
            <Select
              variant="standard"
              value={replacedWith}
              onChange={(e) => {
                setReplaced(e.target.value);
                setStatus('REPLACED');
              }}
            >
              {listOfDemands}
            </Select>
          </FormControl>
        )}
        {replaceString != null && (
          <TextField
            style={{ gridArea: 'correct-limit' }}
            type="text"
            label={
              <FormattedMessage id="Administration.DemandEditor.Replace" defaultMessage="This demand is replacing" />
            }
            margin="dense"
            variant="outlined"
            value={replaceString}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary" variant="outlined">
          <FormattedMessage id="Administration.ActivityEditor.ActivityEditDialog.Confirm" defaultMessage="Confirm" />
        </Button>
        <Button onClick={unmountComponent} color="primary">
          <FormattedMessage id="Administration.ActivityEditor.ActivityEditDialog.Cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DemandEditorDialog;
