import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { FormattedMessage } from "react-intl";
import TextFieldItem from "@zert-packages/components/TextFieldItem";
import { handleValue } from "../../../../BlockPlugin/utils/main";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    display: "grid",
    gridTemplateColumns: "90px 1fr 1fr",
    gridColumnGap: 20,
    marginBottom: 20,
    alignItems: "center"
  },
  wrapperTitle: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: 5,
    alignItems: "center"
  },
  title: {
    fontWeight: 700
  },
  fontsWrapperTitle: {
    display: "grid",
    gridTemplateColumns: "50px 1fr"
  },
  symbol: (props) => ({
    fontFamily: props.font,
    textAlign: "center",
    fontSize: "1.75rem",
    fontWeight: 900,
    minHeight: 31.5
  }),
  wrapperSearchIcon: {
    display: "flex",
    gap: 10,
    alignItems: "center",
    "& svg": {
      cursor: "pointer",
      color: "#4db1d3"
    }
  }
}));

const SymbolTableHeader = ({ symbol, setSymbol }) => {
  const cl = useStyles({ font: symbol.selectedAvailableFontFamily });
  const [fontSize, setFontSize] = useState(symbol.fontSizeSymbol);


  const handleSymbol = (val, name) => {
    handleValue(val, name, setSymbol);
  };

  const handleFontSize = (val) => {
    setFontSize(val);
  };

  const handleFontSizeSymbol = () => {
    const val = +fontSize;
    let adobeVal = val;
    if (8 > val) {
      adobeVal = 8;
    }
    if (+val > 54) {
      adobeVal = 54;
    }
    setFontSize(adobeVal);
    handleSymbol(adobeVal, "fontSizeSymbol");
  };


  const handleSelectedSymbol = () => {
    const val = symbol.filterUnicodeValue.toLowerCase()
     if(val.length === 0){
       return
     }
     const res = symbol.unicodeSymbolMap.find(el => el.unicodeNumber.toLowerCase().includes(val))
    if(!res){
      return  handleSymbol(null, "selectedSymbol");
    }
    handleSymbol(res, "selectedSymbol");
  };


  useEffect(() => {
    if(symbol.unicodeSymbolMap.length > 0){
      handleSelectedSymbol()
    }
  }, [symbol.unicodeSymbolMap])


  return (<div className={cl.tableWrapper}>
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>
         <FormattedMessage id="symbol.dialog.character" defaultMessage="Character" />:
      </span>
      <div className={cl.symbol}>{symbol.selectedSymbol && symbol.selectedSymbol.code}</div>
    </div>
    <div className={cl.wrapperTitle}>
       <span className={cl.title}>
           <FormattedMessage id="symbol.dialog.symbol.size" defaultMessage="Symbol Size" />:
         </span>
      <TextFieldItem
        onBlur={handleFontSizeSymbol}
        values={fontSize}
        name={"fontSizeSymbol"}
        type={"number"}
        setValues={handleFontSize}
      />
    </div>
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>
          <FormattedMessage id="symbol.dialog.unicode" defaultMessage="Unicode" />:
      </span>
      <div className={cl.wrapperSearchIcon}>
        <TextFieldItem
          values={symbol.filterUnicodeValue}
          setValues={handleSymbol}
          name={"filterUnicodeValue"}
        />
        <SearchIcon onClick={handleSelectedSymbol} />
      </div>
    </div>
  </div>);
};

export default SymbolTableHeader;