import React from 'react';
import './style.css';

class InfoLabel extends React.Component {
  render() {
    const content = React.Children.toArray(this.props.children);
    const text = content.length == 0 ? <span>{this.props.text}</span> : content;
    return (
      <div className={`info-label ${this.props.styleName}`}>
        <p>{text}</p>
      </div>
    );
  }
}

export default InfoLabel;
