import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  selectedCellRoot: {
    border: '2px solid #C4C4C3',
    fontSize: 12.25,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#C4C4C3',
    color: '#FFFFFF',
    minHeight: 27,
    paddingLeft: 8
    // cursor: 'pointer',
  },
  cellRoot: {
    border: '2px solid #C4C4C3',
    minHeight: 27,
    fontSize: 12.25,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 8
    // cursor: 'pointer',
  },
  WrapperIcons: {
    height: 23,
    width: 50,
    color: '#000000',
    paddingRight: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  }
  // #C4C4C3
}));

function SelectedCell({ item, lastCell, idx, selected, setSelected, handleSetSelectedLanguage }) {
  const cl = useStyles();
  const { activeLocales } = useSelector((state) => state);

  const handleSelectedCell = () => {
    if (idx === selected) {
      return setSelected(null);
    }
    setSelected(idx);
  };

  return (
    <div
      onClick={() => handleSelectedCell()}
      className={selected === idx ? cl.selectedCellRoot : cl.cellRoot}
      style={{ borderBottom: lastCell ? '' : 'none' }}
    >
      <div>
        {/* {item} */}
        {activeLocales && activeLocales.find((el) => el.value.slice(0, 2) === item.slice(0, 2)).extendedDisplayName}
      </div>
      <div className={cl.WrapperIcons} onClick={() => handleSetSelectedLanguage(item)}>
        {selected === idx && <CloseIcon style={{cursor: 'pointer'}} />}
      </div>
    </div>
  );
}
export default SelectedCell;
