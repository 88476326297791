import React from 'react';
import NewImageDialog from './dialogs/NewImageDialog';
import CreateHotspotDialog from './dialogs/CreateHotspotDialog';
import EditPartDialog from './dialogs/EditPartDialog';
import CreatePartDialog from "./dialogs/CreatePartDialog";
import EditHotspotDialog from "./dialogs/EditHotspotDialog";

const OpenDialog = ({ setMoveNewHotspot, dialog, setDialog, setImages, selectItem, setSelectItem, editPart, hotspotToEdit }) => {
  return (
    <div>
      {dialog.type === 'newImage' && <NewImageDialog dialog={dialog} setDialog={setDialog} setImages={setImages} />}

      {dialog.type === 'newHotspot' && (
        <CreateHotspotDialog dialog={dialog} setDialog={setDialog} setMoveNewHotspot={setMoveNewHotspot} />
      )}

      {dialog.type === 'editHotspot' && (
          <EditHotspotDialog dialog={dialog} setDialog={setDialog} setMoveNewHotspot={setMoveNewHotspot} hotspotToEdit={hotspotToEdit}/>
      )}

      {dialog.type === 'editPart' && (
        <EditPartDialog dialog={dialog} setDialog={setDialog} selectItem={selectItem} setSelectItem={setSelectItem} editPart={editPart}/>
      )}

      {dialog.type === 'newPart' && (
        <CreatePartDialog dialog={dialog} setDialog={setDialog} selectItem={selectItem} setSelectItem={setSelectItem} />
      )}

      {dialog.type === 'newElement' && (
        <CreatePartDialog dialog={dialog} setDialog={setDialog} selectItem={selectItem} setSelectItem={setSelectItem} />
    )}
    </div>
  );
}

export default OpenDialog;
