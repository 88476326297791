import React, { useEffect, useRef, useState, Fragment } from 'react';
import { Button, Divider, Fade, IconButton, InputBase, Paper, Popover, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEscape } from './utils/hooks';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 8,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    minWidth: 320,
    width: '100%',
    maxWidth: 650,
    height: 32,
    background: 'rgba(0, 0, 0, 0.1)',
    boxShadow: 'none',
    '@media (min-width: 1020px)': {
      marginTop: 0,
      marginLeft: 8
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: 'white'
  },
  iconButton: {
    padding: 10
  },
  closeButton: {
    padding: 10,
    color: '#f5f5f5'
  },
  divider: {
    height: 28,
    margin: 4,
    backgroundColor: '#f5f5f5'
  },
  amountOfElements: {
    letterSpacing: 1,
    color: 'white'
  },
  typography: {
    padding: '4px 10px'
  }
}));

export default function SearchInput({
  value,
  onChange,
  onClose,
  onBack,
  onNext,
  currentPosition,
  amountOfPositions,
  readyToSearch
}) {
  const searchRef = useRef();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  const intl = useIntl();

  useEscape(onClose);
  useEffect(() => {
    searchRef.current.focus();
  }, []);

  useEffect(() => {
    if (readyToSearch && openPopover) {
      setAnchorEl(null);
    }
  }, [readyToSearch]);

  const handleKeyDown = (e) => {
    if (!readyToSearch) return setAnchorEl(e.currentTarget);
    const enterKey = 13;
    if (e.keyCode === enterKey && e.shiftKey) {
      onBack();
      return;
    }

    if (e.keyCode === enterKey) onNext();
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    if (!readyToSearch) return setAnchorEl(e.currentTarget);
    onChange(value);
  };

  return (
    <>
      <Paper component="form" onSubmit={(e) => e.preventDefault()} className={classes.paper}>
        {currentPosition !== -1 && amountOfPositions !== -1 && (
          <Fade in timeout={250}>
            <div className={classes.amountOfElements}>
              {currentPosition}/{amountOfPositions}
            </div>
          </Fade>
        )}
        <InputBase
          inputRef={searchRef}
          onChange={handleOnChange}
          className={classes.input}
          onKeyDown={handleKeyDown}
          placeholder={intl.formatMessage({ id: 'SearchInput.Placeholder', defaultMessage: 'Search...' })}
        />
        <IconButton className={classes.iconButton} onClick={onBack} size="large">
          <ArrowBackIcon htmlColor="#f5f5f5" />
        </IconButton>
        <IconButton className={classes.iconButton} onClick={onNext} size="large">
          <ArrowForwardIcon htmlColor="#f5f5f5" />
        </IconButton>
        <Divider className={classes.divider} orientation="vertical" />
        <Button onClick={onClose} color="primary" className={classes.closeButton}>
          <FormattedMessage id="search.inside.close" defaultMessage="CLOSE" />
        </Button>
      </Paper>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Typography className={classes.typography}>
          <FormattedMessage
            id="SearchInput.NotLoaded"
            defaultMessage="Your data has not been loaded yet, please wait."
          />
        </Typography>
      </Popover>
    </>
  );
}
