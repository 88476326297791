import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Loader from '@zert-packages/components/shared/Loader/Loader';

const useStyles = makeStyles({
  root: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box'
  }
});

function ZertLoaderCenter({ children }) {
  return (
    <div className={useStyles().root}>
      <Loader>{children}</Loader>
    </div>
  );
}

export default ZertLoaderCenter;
