import React from 'react';
import { getInterval, getDeadlineLabel } from '@zert-packages/actions/actionplan';
import { FormattedMessage, useIntl } from 'react-intl';
import LabelRM from '../../shared/Label';

function TaskContentOverviewTile({ element }) {
  const intl = useIntl();

  if (!element)
    return (
      <div className="status_wrapper">
        <LabelRM styleName="warning">
          <FormattedMessage id="TaskContentOverviewTile.WarningLoading" defaultMessage="Loading..." />
        </LabelRM>
        <LabelRM styleName="inactive">
          <FormattedMessage id="TaskContentOverviewTile.WarningLoading" defaultMessage="Loading..." />
        </LabelRM>
      </div>
    );
  const deadline = element && element.properties != null && element.properties['zert:CurrentActivityDeadline'];
  const startAgain =
    element &&
    element.properties != null &&
    element.properties['zert:StartAgainAmount'] != null &&
    element.properties['zert:StartAgainUnit'];
  return (
    <>
      {deadline && (
        <LabelRM
          key="first_button"
          text={getDeadlineLabel(element.properties['zert:CurrentActivityDeadline'], intl)}
          styleName="warning"
        />
      )}

      {startAgain && (
        <LabelRM
          key="second_button"
          text={getInterval(element.properties['zert:StartAgainAmount'], element.properties['zert:StartAgainUnit'])}
          styleName="inactive"
        />
      )}
    </>
  );
}

export default TaskContentOverviewTile;
