import React from 'react';

import { showSnackbarInfo } from '@zert-packages/components/Snackbars';
import { FormattedMessage } from 'react-intl';
import { renderInExceptionRoot } from '@zert-packages/utils';
import { CountedDataDialog } from '@zert-packages/components/Catalog/CountedDataDialog';
import {
  LOAD_CATALOG_PLACEMENT_SUCCESS,
  LOAD_CATALOG_PLACEMENT_BEGIN,
  LOAD_CATALOG_PLACEMENT_LOADED,
  LOAD_CATALOG_SUCCESS,
  LOAD_CATALOGS_SUCCESS,
  LOAD_CATALOG_BYID_SUCCESS,
  LOAD_CATALOG_BEGIN,
  LOAD_CATALOG_LOADED,
  LOAD_CATALOG_SUCCESS2,
  LOAD_CATALOG_BEGIN2,
  LOAD_CATALOG_LOADED2,
  LOAD_CATALOG_DATA_SUCCESS,
  LOAD_CATALOG_NODE_START,
  LOAD_CATALOG_COUNTERS_SUCCESS,
  LOAD_CATALOG_COUNTERS_START,
  FETCH_COPY_ELEMENTS,
  FETCH_CUT_ELEMENTS,
  FETCH_PASTE_ELEMENTS,
  CATALOG_COUNTER_INFOS_START,
  CATALOG_COUNTER_INFOS_DONE,
  CATALOG_COUNTER_INFOS_SUCCESS,
  LOAD_CATALOGS_BEGIN,
  LOAD_CATALOGS_LOADED
} from './actions';

import {
  handlePost,
  handleQuery,
  fetchRA_Error,
  fetchReportStatus,
  fetchReportStart,
  fetchReportError,
  handlePostResultAsString,
  handlePostFile,
  reportDone
} from './api';

import { fetchRABasisUpload, fetchRABasisUpload_Begin, fetchRABasisUpload_DONE } from './riskassesmentActions';

const fetchCatalogSuccess = (catalog, posId) => ({
  type: LOAD_CATALOG_SUCCESS,
  payload: {
    catalog,
    posId
  }
});

const fetchCatalogsSuccess = (catalogList) => ({
  type: LOAD_CATALOGS_SUCCESS,
  payload: {
    catalogList
  }
});

const fetchCatalogByIdSuccess = (catalog) => ({
  type: LOAD_CATALOG_BYID_SUCCESS,
  payload: { catalog }
});

const fetchCatalogSuccess2 = (catalogPlacement) => ({
  type: LOAD_CATALOG_SUCCESS2,
  payload: { catalogPlacement }
});

const fetchCatalogStart = () => ({
  type: LOAD_CATALOG_BEGIN
});

const fetchCatalogsStart = (id) => ({
  type: LOAD_CATALOGS_BEGIN,
  payload: id
});

const fetchCatalogsLoaded = (id) => ({
  type: LOAD_CATALOGS_LOADED,
  payload: id
});

const fetchCatalogLoaded = () => ({
  type: LOAD_CATALOG_LOADED
});

const fetchCatalogStart2 = () => ({
  type: LOAD_CATALOG_BEGIN2
});

const fetchCatalogLoaded2 = () => ({
  type: LOAD_CATALOG_LOADED2
});

const fetchCatalogPlacementStart = () => ({
  type: LOAD_CATALOG_PLACEMENT_BEGIN
});

const fetchCatalogPlacementSuccess = (catalogPlacement) => ({
  payload: { catalogPlacement },
  type: LOAD_CATALOG_PLACEMENT_SUCCESS
});

const fetchCatalogPlacementLoaded = () => ({
  type: LOAD_CATALOG_PLACEMENT_LOADED
});

const fetchCatalogNodeInfos = (cataloginfos) => ({
  payload: { cataloginfos },
  type: LOAD_CATALOG_DATA_SUCCESS
});

export const fetchCatalogNodeStartLoad = () => ({
  payload: {},
  type: LOAD_CATALOG_NODE_START
});

const fetchCatalogCountersSuccess = (countersData) => ({
  payload: { countersData },
  type: LOAD_CATALOG_COUNTERS_SUCCESS
});

export const fetchCatalogCountersStart = (nodeuuid) => ({
  payload: { nodeuuid },
  type: LOAD_CATALOG_COUNTERS_START
});

export const fetchCopyElements = (copiedElements) => ({
  payload: { copiedElements },
  type: FETCH_COPY_ELEMENTS
});

export const fetchCutElements = (copiedElements) => ({
  payload: { copiedElements },
  type: FETCH_CUT_ELEMENTS
});

export const fetchPasteElements = () => ({
  payload: {},
  type: FETCH_PASTE_ELEMENTS
});

export function pasteCopiedElements(selectedElements, posId, isCuted, catalogElementId) {
  const versionIds = [];
  const elementsid = [];
  selectedElements.forEach((value, key, map) => {
    if (value.versionId) {
      versionIds.push(value.versionId);
      elementsid.push(value.id);
    }
  });
  if (versionIds.length > 0) {
    const placement = [];
    placement.push(posId);
    const obj = JSON.parse(
      `{ "versionIds" : ${JSON.stringify(versionIds)}, "catalogMetadatas": ${JSON.stringify(placement)} }`
    );
    return function (dispatch) {
      handlePostResultAsString(`/catalog/placeElements/${catalogElementId}/${isCuted}`, obj).then((result) => {
        dispatch(findElements(posId, catalogElementId));
      });

      return dispatch(fetchPasteElements());
    };
  }
}

// function pasteCopiedElements(selectedElements, posid) {
//    return function(dispatch)  {
//        handlePostParamAsString( "/api/catalog/placeElement/"+versionId, posId)
//        .then( result => {
//               dispatch(findElements(posid))
//            } )
//
//        .catch(error => dispatch(fetchRA_Error(error)));
//        return dispatch(fetchCatalogNodeStartLoad());
//    }
// }

function placeElementDispatcher(versionId, posId, isCuted, catalogElementId) {
  return function (dispatch) {
    if (isCuted) {
      handlePostResultAsString(`/catalog/placeElement/${catalogElementId}/${versionId}/${true}`, posId);
    } else {
      handlePostResultAsString(`/catalog/placeElementCopy/${catalogElementId}/${versionId}`, posId);
    }
    return dispatch(fetchPasteElements());
  };
}

export function uploadCatalog(filedata) {
  return function (dispatch) {
    handlePostFile('/catalog/uploadFile', filedata)
      .then((basis) => {
        dispatch(fetchRABasisUpload(basis));
        dispatch(fetchRABasisUpload_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchRABasisUpload_Begin(idElement));
  };
}

export function getCatalogSettings() {
  return handleQuery('/catalog/getCatalogSettings/-100');
}

export function storeCatalogSettings(body) {
  return handlePostResultAsString('/catalog/storeCatalogSettings/-100', body);
}

export function getCatalogs() {
  return handleQuery('/catalog/getCatalogs/-100');
}

export function placeElementNew(versionId, posId1) {
  return handlePost(`/catalog/placeElement/${versionId}`, posId1);
}

export function placeElement(versionId, posId1, posId2, catalogElementId1, catalogElementId2) {
  handlePost(`/catalog/placeElement/${catalogElementId1}/${versionId}/${true}`, posId1).then(() => {
    if (catalogElementId2) {
      handlePost(`/catalog/placeElement/${catalogElementId2}/${versionId}/${false}`, posId2);
    }
  });
}

export function storeCatalog(catalog, versionId) {
  return handlePost(`/catalog/storeCatalog/-100/${versionId}`, catalog);
}

export function remove(report, nodeId, elementsid, catalogElementId) {
  return function (dispatch) {
    // var reportUUID = reportUUID;
    // var report = {reportUUID : reportUUID};
    const ids = [];
    elementsid.forEach((value, key, map) => {
      ids.push(value.id);
    });
    handlePost(`/explorer/remove/${report.reportUUID}/${-1}/true`, ids)
      .then((result) => {
        if (result != null && result.active) {
          dispatch(fetchReportStatus(result));
          dispatch(updateProgressStatus(result, nodeId, catalogElementId, report));
        } else {
          report.done = true;
          dispatch(fetchReportStatus(result));
          dispatch(reportDone(report));
          dispatch(findElements(nodeId, catalogElementId));
        }
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchReportStart(report));
  };
}

export const catalogCounterInfos = (cataloginfos, node_uuid, index) => ({
  payload: {
    cataloginfos,
    node_uuid,
    index
  },
  type: CATALOG_COUNTER_INFOS_SUCCESS
});

export const start_catalogNodeInfos = (node_uuid, index) => ({
  payload: {
    node_uuid,
    index
  },
  type: CATALOG_COUNTER_INFOS_START
});

export const done_catalogNodeInfos = (node_uuid, index) => ({
  payload: {
    node_uuid,
    index
  },
  type: CATALOG_COUNTER_INFOS_DONE
});

export const findCounted =
  (posid, catalogElementId, index, counterData) =>
  async (dispatch, getState, { snackbar }) => {
    try {
      /* const generatingSnackbar = createInProgressSnack(
          <FormattedMessage id="Search.Counted" defaultMessage="...Searching counters" />
     ); */
      const catalogInfos = await handleQuery(`/catalog/findCounted/${catalogElementId}/${posid}/${false}/${index}`);
      //  snackbar.closeSnackbar(generatingSnackbar);
      renderInExceptionRoot(CountedDataDialog, {
        counter: counterData,
        countedcataloginfos: catalogInfos
      });
    } catch (e) {
      dispatch(fetchRA_Error(e));
    }

    /* .then((cataloginfos) => {
        dispatch(catalogCounterInfos(cataloginfos, posid, index));
        return dispatch(done_catalogNodeInfos(posid, index));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(start_catalogNodeInfos(posid, index)); */
  };

export function findElements(posid, catalogElementId) {
  return function (dispatch) {
    handleQuery(`/catalog/findElements/${catalogElementId}/${posid}`)
      .then((catalogPlacement) => {
        return dispatch(fetchCatalogNodeInfos(catalogPlacement));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchCatalogNodeStartLoad());
  };
}

export function updateProgressStatus(callback, posid, catalogElementId, report) {
  return function (dispatch) {
    handleQuery(`/report-react/updateProgressStatus/${callback.callbackId}/false`)
      .then((result) => {
        if (result != null && result.active) {
          dispatch(fetchReportStatus(result));
          dispatch(updateProgressStatus(result, posid, catalogElementId, report));
        } else {
          dispatch(fetchReportStatus(result));
          if (report) {
            report.done = true;

            dispatch(reportDone(report));
          }
          dispatch(findElements(posid, catalogElementId));
        }
      })
      .catch((error) => {
        dispatch(fetchReportError(error));
      });
    return fetchReportStatus(callback);
  };
}

export function archiveElements(report, selectedElements, nodeId, catalogElementId) {
  const versionids = [];
  selectedElements.forEach((value, key, map) => {
    versionids.push(value.versionId);
  });

  return function (dispatch) {
    handlePost(`/common/archive/${report.reportUUID}`, versionids)
      .then((result) => {
        if (result != null && result.active) {
          dispatch(fetchReportStatus(result));
          dispatch(updateProgressStatus(result, nodeId, catalogElementId, report));
        } else {
          report.done = true;
          dispatch(fetchReportStatus(result));
          dispatch(reportDone(report));
          dispatch(findElements(nodeId, catalogElementId));
        }
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
  };
}

export function updateCounters(catalogVersionId, nodeuuid) {
  return function (dispatch) {
    handleQuery(`/catalog/updateCounters/${catalogVersionId}/${nodeuuid}`)
      .then((result) => {
        result.nodeuuid = nodeuuid;
        return dispatch(fetchCatalogCountersSuccess(result));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchCatalogCountersStart(nodeuuid));
  };
}

export function findElementsSearch(posid, searchString, catalogElementId) {
  return function (dispatch) {
    handlePost(`/catalog/findElements/${catalogElementId}/${posid}`, searchString)
      .then((catalogPlacement) => {
        return dispatch(fetchCatalogNodeInfos(catalogPlacement));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchCatalogNodeStartLoad());
  };
}

export function getPlacement(versionId) {
  return function (dispatch) {
    handleQuery(`/catalog/getPlacement/${versionId}`)
      .then((catalogPlacementData) => {
        dispatch(
          fetchCatalogPlacementSuccess({
            data: catalogPlacementData,
            versionId
          })
        );
        dispatch(fetchCatalogPlacementLoaded());
      })
      .catch((error) => {});
    return dispatch(fetchCatalogPlacementStart());
  };
}

export function setPlacement(versionId, placement) {
  return function (dispatch) {
    return dispatch(fetchCatalogPlacementSuccess({
            data: placement,
            versionId
          }));
  };
}

export function getCatalog(id) {
  return function (dispatch) {
    handleQuery(`/catalog/getCatalog/${id}`, true)
      .then((catalogPlacement) => {
        dispatch(fetchCatalogSuccess(catalogPlacement, id));
        return dispatch(fetchCatalogsLoaded(id));
      })
      .catch((error) => dispatch(fetchCatalogLoaded()));
    return dispatch(fetchCatalogsStart(id));
  };
}

export function getCatalogsForCompany() {
  return function (dispatch) {
    handleQuery(`/catalog/getCatalogInfos`)
      .then((catalogs) => {
        dispatch(fetchCatalogsSuccess(catalogs));
        dispatch(getCatalog(0));
        dispatch(getCatalog(1));
        dispatch(getCatalog(2));
        dispatch(getCatalog(3));
        return dispatch(fetchCatalogLoaded());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchCatalogStart());
  };
}

export function getCatalogById(companyId, versionId) {
  return function (dispatch) {
    handleQuery(`/catalog/getCatalogById/${companyId}/${versionId}`)
      .then((catalog) => {
        dispatch(fetchCatalogByIdSuccess(catalog));
        return dispatch(fetchCatalogLoaded());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchCatalogStart());
  };
}

export function getCatalog2() {
  return function (dispatch) {
    handleQuery('/catalog/getCatalog2/')
      .then((catalogPlacement) => {
        dispatch(fetchCatalogSuccess2(catalogPlacement));
        return dispatch(fetchCatalogLoaded2());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchCatalogStart2());
  };
}

export function findPath(value, catalog, returnArray) {
  if (catalog.rootNode.metadata.length == 0) {
    return;
  }
  const sel = [];
  const gData = [];
  gData.push({
    label: `${catalog.rootNode.label.defaultLabel}`,
    value: `${catalog.rootNode.metadata[0].value}`,
    key: `${catalog.rootNode.uuid}`,
    expanded: true,
    disabled: false,
    children: _loop(catalog.rootNode)
  });

  function _loop(node) {
    const children = [];
    node.children.map((child) => {
      if (child.metadata && child.metadata.length > 0) {
        children.push({
          label: `${child.label.defaultLabel}`,
          value: `${child.metadata[0].value}`,
          key: `${child.uuid}`,
          disabled: child.metadata[0].value == '',
          expanded: false,
          children: _loop(child)
        });
        if (child.children.length != 0) {
          child.children.map((_child) => {
            return _loop(_child);
          });
        } else {
          return '[]';
        }
      }
    });
    return children;
  }

  function loop(selected, children) {
    for (let i = 0; i < children.length; i++) {
      const item = children[i];
      if (selected === `${item.key}^${item.value}`) {
        sel.push(item);
        return;
      }
      if (selected === item.value) {
        sel.push(item);
        return;
      }
      if (item.children) {
        loop(selected, item.children, item);
        if (sel.length) {
          sel.push(item);
          return;
        }
      }
    }
  }

  loop(value, gData);
  if (returnArray) {
    return sel;
  }
  return sel
    .map((i) => i.label)
    .reverse()
    .join(' > ');
}

export function copyElements(selectedElements, isCuted) {
  return function (dispatch) {
    if (isCuted) {
      return dispatch(fetchCutElements(selectedElements));
    }
    return dispatch(fetchCopyElements(selectedElements));
  };
}

export const copyElement = (element) => (dispatch, getState, hooks) => {
  const message = (
    <FormattedMessage
      id="CatalogActions.CopiedMessage"
      defaultMessage="{name} - copied"
      values={{
        name: element.name || ''
      }}
    />
  );
  showSnackbarInfo(hooks.snackbar, message);
  dispatch(fetchCopyElements([element]));
};
