import React, { useContext, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DropMenu from "../../DropMenu";
import { useSelector } from "react-redux";
import PhraseEditor from "clm/src/plugins/PhrasePlugin/components/PhraseEditor";
import TabsPhrase from "./TabsPhrase";
import Button from "@mui/material/Button";
import TabsItem from "../../TabsItem";
import TabsMetaData from "./TabsMetaData";
import { PanelContext } from "../../InfoElement/InfoElementWrapper/InfoElementWrapper";
import { createPhraseApi, storeImagePhraseFormatted } from "../../InfoElement/components/API";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { createInProgressSnack } from "../../common/snackBar";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    maxWidth: 728,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700
  },
  wrapper: {
    minHeight: 419
  },
  wrapperPhrase: {
    display: "grid",
    gridTemplateColumns: "120px 1fr"
  },
  wrapperEditor: {
    backgroundColor: "#ffffff",
    borderRadius: 10,
    marginTop: 10
  },
  wrapperButton: {
    display: "flex",
    justifyContent: "end",
    marginTop: 20
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

const dataTabs = [
  { name: "Generally" },
  { name: "Metadata" }
];

const DialogAddPhrase = ({ openPhrase, setOpenPhrase, context }) => {
  const cl = useStyles();
  const { values, setValues } = useContext(context || PanelContext);
  const { mylocale } = useSelector((state) => state);
  const { activeLocales } = useSelector((state) => state);
  const [selectedLocales, setSelectedLocales] = useState(null);
  const [dialogAddWord, setDialogAddWord] = useState({
    selectedTab: 0,
    selectedWord: null
  });
  const [phrase, setPhrase] = useState({});
  const { versionId } = useParams();
  const id = values?.parentItem?.elementInfo?.versionId;
  const parentVersionId = (id && values?.investmentLevel > 1) ? id : versionId;
  const snackbar = useSnackbar();
  const text = phrase.phrase;
  const handlePhrase = (val) => {
    setPhrase(pre => ({ ...pre, ...val }));
  };

  const handleClose = () => {
    setOpenPhrase(false);
  };


  const handleSave = async () => {

    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="RMP.SnackBar.CreatePhrase" defaultMessage="Create phrase..." />
    );
    handleClose();
    generatingSnackbar;
    const createPhrase = await createPhraseApi(
      {
        locale: mylocale,
        parentVersionId: parentVersionId
      });

    if (!createPhrase) {
      snackbar.closeSnackbar(generatingSnackbar);
      return handleClose();
    }

    const addPhrase = {
      ...createPhrase,
      elementInfo: { ...createPhrase.elementInfo, name: text },
      elements: {
        ...createPhrase.elements,
        blocks: createPhrase.elements.blocks.reduce((acu, cur) => {
          if (+cur.key === +createPhrase.elementInfo.currentVersionId) {
            return [...acu, { ...cur, text }];
          }
          return [...acu, cur];
        }, [])
      },
      text
    };

    const res = await storeImagePhraseFormatted(createPhrase.elementInfo.versionId, addPhrase);
    setValues(pre => ({
      ...pre,
      addImgPhraseType: "group",
      phraseContent: {
        ...res,
        text,
        phrase: { ...res.phrase, name: text },
        id: res.identifier
      }
    }));

    snackbar.closeSnackbar(generatingSnackbar);
  };


  const handleTab = (val) => {
    setDialogAddWord(pre => ({ ...pre, selectedTab: val, selectedWord: null }));
  };


  useEffect(() => {
    if (activeLocales) {
      const selectedLng = activeLocales[0];
      setSelectedLocales({ ...selectedLng, name: selectedLng.displayName });
    }
  }, [activeLocales]);

  return (<Dialog
    open={openPhrase}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>The phrase</span>
      <CloseIcon onClick={handleClose} />
    </div>
    <div className={cl.wrapper}>
      <TabsItem
        selectedTab={dialogAddWord.selectedTab}
        tabs={dataTabs}
        setTab={handleTab}
      />
      {dialogAddWord.selectedTab === 0 && <>
        <div className={cl.wrapperPhrase}>
          <p>Source language:</p>
          {activeLocales && <DropMenu
            selected={selectedLocales}
            dropItem={activeLocales.map(el => ({ ...el, name: el.displayName }))}
            setDropItem={setSelectedLocales}
          />}
        </div>
        <div className={cl.wrapperEditor}>
          <PhraseEditor
            vue={true}
            setDialogAddWord={setDialogAddWord}
            setPhrase={handlePhrase}
          />
        </div>
        <TabsPhrase
          phrase={phrase}
          dialogAddWord={dialogAddWord}
        />
      </>}
      {dialogAddWord.selectedTab === 1 && <TabsMetaData />}
    </div>
    <div style={{ marginTop: 5 }}>
    </div>
    <div className={cl.wrapperButton}>
      <Button disabled={text?.length === 0} className={cl.button} onClick={handleSave}>
        OK
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>
  </Dialog>);
};

export default DialogAddPhrase;