export const dataAddFilter = [
  {
    "settingsTemplate": {
      "properties": [
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
          "identifier": "command",
          "value": null,
          "label": "",
          "translatable": false,
          "labelObject": null
        },
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
          "identifier": "extensions",
          "value": null,
          "label": "",
          "translatable": false,
          "labelObject": null
        }
      ]
    },
    "identifier": "external-image-filter",
    "settings": {
      "template": {
        "properties": [
          {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "command",
            "value": null,
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "extensions",
            "value": null,
            "label": "",
            "translatable": false,
            "labelObject": null
          }
        ]
      },
      "settings": [
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettings$TextSetting",
          "property": {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "command",
            "value": null,
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          "value": "1"
        },
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettings$TextSetting",
          "property": {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "extensions",
            "value": null,
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          "value": "2"
        },
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettings$TextSetting",
          "property": {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "command",
            "value": null,
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          "value": "1"
        },
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettings$TextSetting",
          "property": {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "extensions",
            "value": null,
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          "value": "2"
        }
      ]
    }
  },
  {
    "settingsTemplate": {
      "properties": [
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
          "identifier": "target-extension",
          "value": null,
          "label": "",
          "translatable": false,
          "labelObject": null
        },
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
          "identifier": "extensions",
          "value": null,
          "label": "",
          "translatable": false,
          "labelObject": null
        },
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
          "identifier": "dpi",
          "value": "74",
          "label": "",
          "translatable": false,
          "labelObject": null
        },
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
          "identifier": "cropPdf",
          "value": "false",
          "label": "",
          "translatable": false,
          "labelObject": null
        }
      ]
    },
    "identifier": "internal-image-filter",
    "settings": {
      "template": {
        "properties": [
          {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "target-extension",
            "value": null,
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "extensions",
            "value": null,
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "dpi",
            "value": "74",
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "cropPdf",
            "value": "false",
            "label": "",
            "translatable": false,
            "labelObject": null
          }
        ]
      },
      "settings": [
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettings$TextSetting",
          "property": {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "target-extension",
            "value": null,
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          "value": "3"
        },
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettings$TextSetting",
          "property": {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "extensions",
            "value": null,
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          "value": "4"
        },
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettings$TextSetting",
          "property": {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "dpi",
            "value": "74",
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          "value": "74"
        },
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettings$TextSetting",
          "property": {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "cropPdf",
            "value": "false",
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          "value": "false"
        },
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettings$TextSetting",
          "property": {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "extensions",
            "value": null,
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          "value": "4"
        },
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettings$TextSetting",
          "property": {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "target-extension",
            "value": null,
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          "value": "3"
        },
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettings$TextSetting",
          "property": {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "dpi",
            "value": "74",
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          "value": "74"
        },
        {
          "class": "se.zert.backend.rest.common.pojo.ClientSettings$TextSetting",
          "property": {
            "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
            "identifier": "cropPdf",
            "value": "false",
            "label": "",
            "translatable": false,
            "labelObject": null
          },
          "value": "false"
        }
      ]
    }
  }
]