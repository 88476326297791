import { handleQuery } from '@zert-packages/actions/api';

export const getApiTree = (versionId, locale) => {
  return handleQuery(`/common/getContentItemsTreeNode/${versionId}/${locale}`);
};

export const getApiTreeChild = (versionId) => {
  return handleQuery(`/common/getParents/${versionId}`);
};

export const getApiLocalesFilters = (idOwner, idItem, itemUUID) => {
    return handleQuery(`/infologic/getLocalesFilters/${idOwner}/${idItem}/${itemUUID}`);
};

export const getApiPublishingFilters = (idOwner, idItem, itemUUID) => {
  return handleQuery(`/infologic/getPublishingFilters/${idOwner}/${idItem}/${itemUUID}`);
};

export const getApiPublishingConditions = (idOwner, idItem, itemUUID) => {
  return handleQuery(`/infologic/getPublishingConditions/${idOwner}/${idItem}/${itemUUID}`);
};
