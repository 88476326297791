export const PROJECT_TREE_ID = 'project-root';

const getNodeInfoInChildren = (children, subIndex) => {
  const previousNodeIndex = subIndex - 1;
  const previousNode = children[previousNodeIndex];
  const nextNodeIndex = subIndex + 1;
  const nextNode = children[nextNodeIndex];
  return {
    index: subIndex,
    previousNodeIndex: previousNode ? previousNodeIndex : null,
    previousNodeId: previousNode ? previousNode.uuid : null,
    nextNodeIndex: nextNode ? nextNodeIndex : null,
    nextNodeId: nextNode ? nextNode.uuid : null
  };
};

const getParentInfo = (sub, parentIndex) => ({
  parentIndex,
  parentChildrenLength: sub.children.length,
  parentId: sub.uuid,
  isRoot: false
});

const makeRootNodeChildren = (subProject, subProjectChildren, index, parentInfo, path = '') => {
  path += '/';
  return {
    ...parentInfo,
    ...getNodeInfoInChildren(subProjectChildren, index),
    id: subProject.uuid,
    label: subProject.name,
    children: subProject.children.map((child, index) =>
      makeRootNodeChildren(child, subProject.children, index, getParentInfo(subProject, index), path)
    ),
    isRoot: false,
    pathLevel: path.length,
    disabledMoveOut: path.length < 3,
    disabledMoveIn: path.length < 2,
    strongLabel: path.length <= 1
  };
};

function makeProjectTree(project) {
  return {
    index: 0,
    id: PROJECT_TREE_ID,
    label: project.name,
    children: project.subprojects.map((sub, index) =>
      makeRootNodeChildren(sub, project.subprojects, index, {
        parentIndex: 0,
        parentChildrenLength: project.subprojects.length,
        parentId: PROJECT_TREE_ID,
        isParentRoot: true
      })
    ),
    type: 'project',
    level: 0,
    strongLabel: true,

    isRoot: true,
    previousNodeIndex: null,
    previousNodeId: null,
    nextNodeId: null,
    nextNodeIndex: null,

    parentId: null,
    parentIndex: null,
    isParentRoot: true,
    parentChildrenLength: 1
  };
}

export default makeProjectTree;
