import React, { useContext } from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  rootContainer: {
    display: 'grid',
    gridTemplateColumns: '200px 200px',
    gridGap: 10,
    justifyContent: 'flex-end',
    gridTemplateRows: 'auto',
    padding: '0 10px'
  }
});

export function VersionActionsPanel({ context }) {
  const { saveVersion, applyVersion, createNewVersion, canCreateVersion, canSaveVersion, canApprovalVersion } =
    useContext(context);
  const classes = useStyles();
  return (
    <div className={classes.rootContainer}>
      {canSaveVersion && (
        <Button color="primary" style={{ margin: '5px' }} onClick={saveVersion} coloraria-label="save">
          <FormattedMessage id="VersionPanel.Label.Save" defaultMessage="Save" />
        </Button>
      )}
      {canApprovalVersion && (
        <Button color="primary" style={{ margin: '5px' }} onClick={applyVersion} aria-label="apply">
          <FormattedMessage id="VersionPanel.Label.Approve" defaultMessage="Approve" />
        </Button>
      )}
      {canCreateVersion && (
        <Button color="primary" style={{ margin: '5px' }} onClick={createNewVersion} aria-label="create">
          <FormattedMessage id="VersionPanel.Label.CreateVersion" defaultMessage="Create version" />
        </Button>
      )}
    </div>
  );
}
