import { Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import getTranslation from '@zert-packages/utils/getTranslation.new';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 500,
    height: 345,
    padding: 11,
    background: '#E2F2F6'
  },
  wrapperUnderLabel: {
    marginTop: 10,
    marginBottom: 5
  },

  wrapperTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      cursor: 'pointer'
    }
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 700
  },
  wrapperButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 20
  },
  button: {
    color: '#ffffff',
    background: '#4db1d3',
    marginLeft: 10,
    minWidth: 80,
    border: '1px solid #4db1d3',
    outline: 'none !important',
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
      color: '#212529',
      border: '1px solid #4db1d3'
    },
    '&:focus': {
      backgroundColor: '#4db1d3'
    }
  },
  wrapperList: {
    border: '1px solid #BDBDBC',
    fontSize: 13,
    background: '#ffffff',
    marginBottom: 30,
    overflow: 'auto',
    maxHeight: 180
  },
  listItem: {
    padding: '2px 4px',
    cursor: 'pointer'
  },
  listItemActive: {
    padding: '2px 4px',
    color: '#ffffff',
    background: '#4db1d3'
  }
}));

function DialogAddReferences({ open, setOpen, text = null, setText }) {
  const cl = useStyles();
  const [selected, setSelected] = useState(0)


  const handleClose = () => {
    setOpen(false);
  };

  const handleSelected = (idx) => {
      setSelected(idx)
  }

  const saveItem = () => {
      if(text.addReferences){
          const result = text.addReferences[selected]
          if(text.setValues){
              text.setValues(prev => ({...prev,
                selectedReferences: prev.templateList.references.length,
                templateList: {...prev.templateList,
                  references: [ ...prev.templateList.references, result]}}))
          }
          if(setText){
              setText(result)
          }
      }
      if(text.addParagraph){
          if(setText){
              const result = text.addParagraph[selected]
              setText(result)
          }
      }
      setOpen(false)
  }


  useEffect(() => {
      if(selected > 0){
          setSelected(-1)
      }
  }, [open])


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paperScrollPaper: cl.root
      }}
    >
      {text && <div>
        <div className={cl.wrapperTitle}>
             <span className={cl.title}>
                 {text.label && text.label}
             </span>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={cl.wrapperUnderLabel}>
          {text.underLabel && text.underLabel}
        </div>
        {text.addReferences && <div class={cl.wrapperList}>
          {text.addReferences && text.addReferences.map((el, i) => (
            <div key={i} className={selected === i ? cl.listItemActive : cl.listItem} onClick={() => handleSelected(i)}>
              {`${el.type}: ${el.identifier}`}
            </div>
          ))}
        </div>}
        {text.addParagraph && <div className={cl.wrapperList}>
          {text.addParagraph.map((el, i) => (
            <div key={i} className={selected === i ? cl.listItemActive : cl.listItem} onClick={() => handleSelected(i)}>
              {getTranslation(el.label)}
            </div>
          ))}
        </div>}
        <div className={cl.wrapperButton}>
          <Button disabled={selected < 0} className={cl.button} onClick={saveItem}>
            OK
          </Button>
          <Button className={cl.button} onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </div>}
    </Dialog>
  );
}
export default DialogAddReferences;
