import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import UserSuggestBox from '@zert-packages/components/shared/UserSuggestBoxNew/UserSuggestBox';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import _ from 'lodash';

function DelegateeTasksParametersDialog({ unmountComponent, onConfirm }) {
  const [message, setMessage] = useState('');
  const [userLogin, setUserLogin] = useState(null);
  const handleConfirm = () => {
    unmountComponent();
    onConfirm(userLogin, message);
  };
  return (
    <Dialog fullWidth maxWidth="md" open onClose={unmountComponent}>
      <DialogTitle>
        <FormattedMessage id="DelegateTasksSettingsDialog.Title" defaultMessage="Delegation settings" />
      </DialogTitle>
      <DialogContent>
        <UserSuggestBox
          autoFocus
          onChange={(user) => setUserLogin(_.get(user, 'userName', null))}
          getUsers={getUsersQuery}
          getUserId={(user) => user.username}
          getUserLabel={(user) => user.name}
          initialValue=""
          label={<FormattedMessage id="DelegateTasksSettingsDialog.UserLabel" defaultMessage="User" />}
        />
        <div style={{ margin: '10px 0' }} />
        <TextField
          label={<FormattedMessage id="DelegateTasksSettingsDialog.Message" defaultMessage="Message" />}
          variant="outlined"
          fullWidth
          multiline
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows="7"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} disabled={userLogin === null} color="primary" variant="outlined">
          <FormattedMessage id="DelegateTasksSettingsDialog.Confirm" defaultMessage="Confirm" />
        </Button>
        <Button onClick={unmountComponent} color="primary">
          <FormattedMessage id="DelegateTasksSettingsDialog.Cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DelegateeTasksParametersDialog;
