import React, { useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { updateWantToSeeIntro } from '@zert-packages/actions/commonReducer';
import { finishIntroduction, useIntroduction } from '../IntroductionContext';
import { JoyrideBeaconComponentStart } from '../Introduction';
import TooltipLocale from '../TooltipLocale';
import checkIfStepTargetExist from '../../ElementSearcher/utils/checkIfStepTargetExist';

const id = 'safetyInspection';
const formattedMessages = {
  'IntroSafetyInspectionView.Date': (
    <FormattedMessage
      id="IntroSafetyInspectionView.Date"
      defaultMessage="This is the date when the safety inspection is avaliable to start with."
    />
  ),
  'IntroSafetyInspectionView.WorkflowButtons': (
    <FormattedMessage
      id="IntroSafetyInspectionView.WorkflowButtons"
      defaultMessage="With these buttons you can finalize or delegate your inspection."
    />
  ),
  'IntroSafetyInspectionView.FillButton': (
    <FormattedMessage
      id="IntroSafetyInspectionView.FillButton"
      defaultMessage="With this button you can start your inspection."
    />
  )
};

function SafetyInspectionIntro(wantToSeeIntro) {
  const [run, setRun] = useState(false);
  const dispatch = useDispatch();
  const [stepIndex, setStepIndex] = useState(0);
  const { startIntro } = useIntroduction(id, setRun);
  const steps = [
    {
      target: '[data-intro-id="SafetyInspectionView.Date"]',
      content: formattedMessages['IntroSafetyInspectionView.Date'],
      placement: 'auto'
    },
    {
      target: '[data-intro-id="SafetyInspectionView.WorkflowButtons"]',
      content: formattedMessages['IntroSafetyInspectionView.WorkflowButtons'],
      placement: 'auto'
    },
    {
      target: '[data-intro-id="SafetyInspectionView.FillButton"]',
      content: formattedMessages['IntroSafetyInspectionView.FillButton'],
      placement: 'auto'
    }
  ];

  useEffect(() => {
    if (wantToSeeIntro && !run) {
      setRun(true);
      setStepIndex(0);
    }
  }, [wantToSeeIntro]);

  const handleJoyrideCallback = (data) => {
    const { status, step, action, index, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (!checkIfStepTargetExist(step)) {
      setRun(false);
      dispatch(updateWantToSeeIntro(false));
      finishIntroduction(id);
      return;
    }
    if (finishedStatuses.includes(status)) {
      dispatch(updateWantToSeeIntro(false));
      finishIntroduction(id);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const newStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setRun(false);
      setStepIndex(newStepIndex);
      setTimeout(() => {
        setRun(true);
      }, 400);
    }
  };
  return {
    component: (
      <Joyride
        locale={TooltipLocale}
        callback={handleJoyrideCallback}
        beaconComponent={JoyrideBeaconComponentStart}
        continuous
        run={run}
        steps={steps}
        stepIndex={stepIndex}
        showProgress
        showSkipButton
        styles={{
          overlay: {
            position: 'fixed'
          },
          options: {
            primaryColor: '#01A1C7'
          }
        }}
      />
    ),
    startIntro
  };
}

export default SafetyInspectionIntro;
