import React from 'react';
import { CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    color: '#4db1d3',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
      minWidth: '200px !important'
    }
  }
});

function Loading() {
  const cl = useStyles();
  return (
    <div className={cl.root}>
      <CircularProgress />
    </div>
  );
}

export default Loading;
