export  const findBorder = (type) => {
  if(type === 'PRICKAD'){
    return [2 , 5]
  }
  if(type === 'STRECKAD'){
    return [20 , 3]
  }
  if(type === 'PUNKT STRECKAD'){
    return [10 , 3]
  }
  return []
}