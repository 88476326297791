import React, { useContext, useState } from 'react';
import { getTranslation } from '@zert-packages/utils';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import { TabsContext } from '../FormatTemplatePlugin';
import InputItem from '../InputItem';
import BlockList from '../BlockList';
import { handleInputItem, handleItemLabel, handleItemShortcut } from '../utils/handleInputItem';
import DialogList from '../dialog/DialogList';
import SelectedBlock from '../SelectedBlock';
import WrapperLabelInputItem from "../component/WrapperLabelInputItem";
import { convertKey } from "../utils/main";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogShortCut from "../dialog/DialogShortCut";

function PartTabs({disable}) {
  const { values, setValues } = useContext(TabsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [propsDialog, setPropsDialog] = useState(null);

  const handleValue = (val, Identifier) => {
    handleInputItem(Identifier, val, setValues);
  };


  const handleValueShortcut = (val, Identifier) => {
    handleItemShortcut(Identifier, val, setValues);
  };



  return (
    <div>
      <DialogList open={openDialog} setOpen={setOpenDialog} text={propsDialog} />

      <InputItem
        label="Identifier:"
        valueProps={values.templateList.identifier && values.templateList.identifier}
        disabled={disable}
        Identifier="identifier"
        setValueProps={handleValue}
        // style="100px 1fr"
      />
      <WrapperLabelInputItem disable={disable} />
      <InputItem
        label="Shortcut:"
        valueProps={values.templateList.shortcut && convertKey(values.templateList.shortcut.keyCode)}
        setValueProps={handleValueShortcut}
        Identifier="shortcut"
        disabled={true}
        icons={
          <FlipToBackIcon
            style={{ cursor: "pointer", color: "gray" }}
            onClick={() => !disable && renderInExceptionRoot(DialogShortCut, { setValues })}
          />
        }
      />
      <InputItem
        label="Name format:"
        valueProps={values.templateList.nameFormat && values.templateList.nameFormat.replace(/['{','}']/g, '')}
        disabled={disable}
        setValueProps={handleValue}
        Identifier="nameFormat"
      />
      <BlockList
        disable={disable}
        label="Settings:"
        formats={
          values.templateList.settings &&
          values.templateList.settings.properties.length > 0 &&
          values.templateList.settings.properties &&
          values.templateList.settings.properties
        }
      />
      <BlockList
        disable={disable}
        label="Item settings:"
        formats={
          values.templateList.itemSettings &&
          values.templateList.itemSettings.properties.length > 0 &&
          values.templateList.itemSettings.properties &&
          values.templateList.itemSettings.properties
        }
        typeList={'itemSettings'}
      />
      <SelectedBlock
        disable={disable}
        label="References:"
        formats={values.templateList.references && values.templateList.references}
        setOpenDialog={setOpenDialog}
      />
    </div>
  );
}

export default PartTabs;
