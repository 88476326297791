import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { ClickAwayListener, Dialog, Fade, Paper, Popper } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: '100vh',
    padding: 11,
    // background: "#E2F2F6",
    background: 'none',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorText: {
    textDecoration: "red wavy underline"
  },
  paperRoot: {
    position: "relative",
    zIndex: 1000,
    maxWidth: 'fit-content',
    "& div": {
      display: "block !important"
    },
    "& $itemList": {
      display: "flex !important"
    }
  },
  itemList: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: 5,
    paddingRight: 0,
    cursor: "pointer",
    minWidth: 95,
    "&:hover": {
      background: "#01A1C7",
      color: "#ffffff"
    }
  },
  border: {
    borderTop: "1px solid grey",
    marginTop: 10,
    marginBottom: 4
  }
}));

const defaultDropMenu = [
  {
    name: "Grammar",
    type: "Gd",
    child: [
      { name: "gd" },
      { name: "gad" },
      { name: "ged" },
      { name: "gdf" }
    ]
  },
  {
    name: "Spelling",
    type: "Ignore All",
    child: [
      { name: "gd" },
      { name: "gad" },
      { name: "ged" },
      { name: "gdf" }
    ]
  }

];

const DialogErrorSpelling = ({open, setOpen}) => {
  const cl = useStyles();
  // const [open, setOpen] = useState(true);


  const handleClose = () => {
    setOpen(false);
  };



  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <Paper elevation={3} className={cl.paperRoot}>
      {defaultDropMenu && defaultDropMenu.map((el, i) => (
        <ChildHintDecorator key={i} el={el} handleClose={handleClose} />
      ))}
    </Paper>


  </Dialog>);
};

export default DialogErrorSpelling;


const ChildHintDecorator = ({ el,  }) => {
  const cl = useStyles();
  const [isHovering, setIsHovering] = useState(false);
  const [anchorElChild, setAnchorElChild] = useState(null);


  const handleMouseOver = (e) => {
    setIsHovering(true);
    setAnchorElChild(e.currentTarget);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };


  return (<div className={cl.itemList}
               onMouseOver={handleMouseOver}
               onFocus={() => {
               }}
               onBlur={() => {
               }}
               onMouseOut={handleMouseOut}
  >
    {el.name}
    <Popper
      disablePortal={true}
      open={isHovering}
      //  open={true}
      anchorEl={anchorElChild}
      placement={"right-start"}
      transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={3} className={cl.paperRoot}>
            {el.child.map((it, i) => <div key={i} className={cl.itemList} >
              {it.name}
            </div>)}
            <div className={cl.border}></div>
            <div className={cl.itemList} >
              {el.type}
            </div>
          </Paper>
        </Fade>
      )}
    </Popper>

    <ArrowRightIcon />
  </div>);
};