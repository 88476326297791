import Dialog from '@mui/material/Dialog';
import React, {useContext, useState} from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import {NextPlan} from '@mui/icons-material';
import {makeStyles} from "@mui/styles";
import {SparePartContext} from "../store/SparePartContext";
import {storePart} from "../API";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        maxWidth: 728,
        padding: 11,
        background: "#E2F2F6"
    },
    wrapperTitle: {
        marginBottom: 10,
        display: "flex",
        justifyContent: "space-between",
        "& svg": {
            cursor: "pointer"
        }
    },
    title: {
        fontSize: "1.8rem",
        fontWeight: 700
    },
    wrapper: {
        minHeight: 419
    },
    wrapperPhrase: {
        display: "grid",
        gridTemplateColumns: "120px 1fr",
        alignItems: 'center',
        "& > p": {
            marginBottom: '0px'
        }
    },
    wrapperEditor: {
        backgroundColor: "#ffffff",
        borderRadius: 10,
        marginTop: 10
    },
    wrapperButton: {
        display: "flex",
        justifyContent: "end",
        marginTop: 20
    },
    button: {
        color: "#ffffff",
        background: "#4db1d3",
        marginLeft: 10,
        minWidth: 80,
        border: "1px solid #4db1d3",
        outline: "none !important",
        cursor: "pointer",
        "&:hover": {
            background: "none",
            color: "#212529",
            border: "1px solid #4db1d3"
        },
        "&:focus": {
            backgroundColor: "#4db1d3"
        }
    }
}));

const EditPartDialog = ({dialog, setDialog, editPart}) => {
    const cl = useStyles();
    const {values, setValues} = useContext(SparePartContext)
    const [part, setPart] = useState(editPart);

    const savePartData = () => {
        const resultPartData = {
            ...values.currentViewedElement,
            partItems: values.currentViewedElement.partItems.map(partItem => {
                if (partItem.identifier === part.identifier) {
                    return part
                }
                return partItem
            })
        }

        storePart(values.partVersionId, resultPartData).then(() => {
            setDialog({
                dialog: false,
                type: null
            })
        })
    }

    const assignChanges = () => {
        setValues({
            ...values,
            partItems: values.currentViewedElement.partItems.map(partItem => {
                if (partItem.identifier === part.identifier) {
                    return part
                }
                return partItem
            }),
            dialog: {open: false, type: null},
            isDataChanged: true
        })
    }

    return (
        <Dialog
            open={dialog}
            onClose={() => {
                setDialog({open: false, type: null});
            }}
            fullWidth
        >
            <DialogTitle sx={{fontSize: '20px'}}>Edit part</DialogTitle>
            {part && (
                <DialogContent fullWidth>
                    <div className={cl.wrapperPhrase}>
                        <p>Name:</p>
                        <TextField
                            id="outlined-helperText"
                            label="Name"
                            defaultValue={
                                part.part.formatSettings.settings[0].value && part.part.formatSettings.settings[0].value
                            }
                            inputProps={{style: {paddingLeft: '8px'}}}
                            value={part?.part?.formatSettings.settings[0]?.value}
                            onChange={(event) => {
                                setPart(part => ({
                                    ...part,
                                    part: {
                                        ...part.part,
                                        formatSettings: {
                                            ...part.part.formatSettings,
                                            settings: part?.part?.formatSettings.settings.map(setting => {
                                                if (setting.property.identifier === "name") {
                                                    return {
                                                        ...setting,
                                                        value: event.target.value
                                                    }
                                                }

                                                return setting
                                            })
                                        }
                                    }
                                }))
                            }}
                        />
                    </div>

                    <div className={cl.wrapperPhrase}>
                        <p>Recommended:</p>
                        <TextField
                            fullWidth
                            type="number"
                            label="Recommended"
                            inputProps={{style: {paddingLeft: '8px'}}}
                            value={part.settings.settings.find(setting => setting.property.identifier === "recomended-spare-parts")?.value}
                            onChange={(event) => {
                                setPart(part => {
                                    if(part.settings.settings.find(setting => setting.property.identifier === "recomended-spare-parts")) {
                                        return {
                                            ...part,
                                            settings: {
                                                ...part.settings,
                                                settings: part.settings.settings.map(setting => {
                                                    if (setting.property.identifier === "recomended-spare-parts") {
                                                        return {
                                                            ...setting,
                                                            value: Number.parseInt(event.target.value)
                                                        }
                                                    }

                                                    return setting;
                                                })
                                            }}
                                    }

                                    return {
                                        ...part,
                                        settings: {
                                            ...part.settings,
                                            settings: [...part.settings.settings,  {
                                                property: {
                                                    identifier: "recomended-spare-parts"
                                                },
                                                value: Number.parseInt(event.target.value)
                                            }]
                                        }
                                    }
                                })
                            }}
                        />
                    </div>

                    <div className={cl.wrapperPhrase}>
                        <p>Wear and tear:</p>
                        <TextField
                            fullWidth
                            type="number"
                            label="Wear and tear"
                            inputProps={{style: {paddingLeft: '8px'}}}
                            value={part.settings.settings.find(setting => setting.property.identifier === "wear-and-tear")?.value}
                            onChange={(event) => {
                                setPart(part => {
                                    if(part.settings.settings.find(setting => setting.property.identifier === "wear-and-tear")) {
                                        return {
                                            ...part,
                                            settings: {
                                                ...part.settings,
                                                settings: part.settings.settings.map(setting => {
                                                    if (setting.property.identifier === "wear-and-tear") {
                                                        return {
                                                            ...setting,
                                                            value: Number.parseInt(event.target.value)
                                                        }
                                                    }

                                                    return setting;
                                                })
                                            }
                                        }
                                    }

                                    return {
                                        ...part,
                                        settings: {
                                            ...part.settings,
                                            settings: [...part.settings.settings,  {
                                                property: {
                                                  identifier: "wear-and-tear"
                                                },
                                                value: Number.parseInt(event.target.value)
                                            }]
                                        }
                                    }
                                })
                            }}
                        />
                    </div>

                    <div className={cl.wrapperPhrase}>
                        <p>Quantity:</p>
                        <TextField
                            fullWidth
                            type="number"
                            label="Quantity"
                            inputProps={{style: {paddingLeft: '8px'}}}
                            value={part.quantity}
                            onChange={(event) => {
                                setPart(part => ({
                                    ...part,
                                    quantity: Number.parseInt(event.target.value)
                                }))
                            }}
                        />
                    </div>
                </DialogContent>
            )}
            <DialogActions>
                <Button
                    onClick={() => assignChanges()}
                    variant="contained"
                    color="primary"
                >
                    OK
                </Button>

                <Button
                    variant="contained"
                    endIcon={<NextPlan/>}
                    onClick={() => savePartData()}
                >
                    Save
                </Button>

                <Button
                    onClick={(e) => {
                        setDialog({open: false, type: null});
                    }}
                    variant="contained"
                    color="primary"
                >
                    CANCEL
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditPartDialog;
