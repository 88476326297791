import { connect } from 'react-redux';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import MediaQuery from 'react-responsive';
import { withRouter } from 'react-router-dom';
import withTile from '@zert-packages/components/ElementTile/shared/withTile';
import { ElementContentOverviewTile, OverviewTile } from '@zert-packages/components/ElementTile/tiles/AbstractTile';
import openAttachment from '@zert-packages/utils/openAttachment';

function ImageTileIcon({ selectElement, element, isSelected }) {
  const selected = isSelected;
  const [coverError, setCoverError] = useState(false);
  const accessToken = window.localStorage.getItem('accessToken');
  const onImgError = () => setCoverError(true);
  const Icon = getPluginByMimeType(element.mimeType).icon;
  if (coverError)
    return (
      <MediaQuery query="(min-width: 769px)">
        <div
          className="icon-box"
          onClick={selectElement}
          title={selected ? 'Click to deselect element' : 'Click to select element'}
        >
          <Icon style={{ height: '60px', width: '60px', fill: selected ? '#ffb951' : 'lightgray' }} />
        </div>
      </MediaQuery>
    );

  return (
    <MediaQuery query="(min-width: 769px)">
      <div
        className="icon-box"
        onClick={selectElement}
        title={selected ? 'Click to deselect element' : 'Click to select element'}
      >
        <img
          src={`/zert/image/getImagePreviewById/${element.versionId}?jwtToken=${accessToken}`}
          onError={onImgError}
        />
      </div>
    </MediaQuery>
  );
}

const open = (element, dispatch) => {
  //   window.open(basisEditorOpenAttachment +
  //				   element.versionId , element.name, 'width="800px",height="800px",resizable=1', true
  //                )
  openAttachment(element);
};

const mapStateToProps = (state) => ({
  catalog: state.catalog,
  myuser: state.myuser
});

export default injectIntl(
  withRouter(connect(mapStateToProps)(withTile(ImageTileIcon, OverviewTile, ElementContentOverviewTile, null, open)))
);
