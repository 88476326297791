import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUserAsync } from '@zert-packages/actions/coreReducers';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { PasswordField } from '@zert-packages/components/shared/FormElements/PasswordField';
import { FullField } from '@zert-packages/components/shared/FormElements/FullField';
import { EmailField } from '@zert-packages/components/shared/FormElements/EmailField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SelectField from '@zert-packages/components/shared/FormElements/SelectField';
import { API_ROOT_WAR } from '@zert-packages/actions/api';
import dialogCustom from '@zert-packages/components/common/dialogCustom';

const roles = [
  {
    value: 'administrator',
    label: <FormattedMessage id="NewUserDialog.role.administrator" defaultMessage="Super admin" />
  },

  {
    value: 'consumer',
    label: <FormattedMessage id="NewUserDialog.role.consumer" defaultMessage="Consumer" />
  },
  {
    value: 'task-administrator',
    label: <FormattedMessage id="NewUserDialog.role.taskAdmin" defaultMessage="Administrator" />
  },

  {
    value: 'producer',
    label: <FormattedMessage id="NewUserDialog.role.producer" defaultMessage="Producer" />
  }
];

/*

export default function NewUserDialog(props) {
  const [newUser, showUser, deleteConfirmation, hideConfirmation] = useContext(UserListContext);
  const [user, setUser] = React.useState(props.user);


  const [create_user, setCreateUser] = React.useState(false);

  const [delete_user, setDeleteUser] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [mailErrors, setMailErrors] = React.useState({});

  const [values, setValues] = React.useState({
    amount: '',
    email: '',
    password: '',
    weight: '',
    weightRange: '',
    fullName: '',
    confirmedPassword: '',
    showPassword: false,
    selectedDate: null,
    role: 'consumer',
  });


  const handleDateChange = (date) => {
    setValues({
      ...values,
      selectedDate: date,
    });
  };


  const classes = useStyles();

  const roleChanged = state => {

    setValues({
      ...values,
      role: state.value,
    });
  };


  const cancelUser = event => {
    hideConfirmation({ deleteConfirmation: false });
    showUser({ newUser: false });
  };


  const createUser = event => {
    cancelUser(event);

    async function createUser() {
      setCreateUser(true);
      let user = await createUserAsync(
        props.companyId,
        {
          userName: values.email,
          email: values.email,
          password: values.password,
          fullname: values.fullName,
          name: values.fullName,
          accountExpire: values.selectedDate,
          active: true,
          role: values.role,

        });
      props.history.push(API_ROOT_WAR + '/administration/usereditor/' + props.companyId + '/' + user.id);
      setCreateUser(false);


    }

    createUser();
  };

  const deleteUser = event => {
    cancelUser(event);

    async function deleteUserFunc() {
      setDeleteUser(true);
      await deleteGroupAsync(props.companyId, props.group.id);
      setDeleteUser(false);

    }

    deleteUserFunc();
  };


  const checkNulls = (errors) => {
    let hasNulls = false;
    let keys = Object.keys(errors);
    keys.map((key) => {
      if (errors[key]) {
        hasNulls = true;
      }
    });
    return hasNulls;
  };


  let formAccepted = ('' != values.email) && !checkNulls(errors) && !checkNulls(mailErrors) && values.confirmedPassword.length != 0 &&
    values.password.length != 0 && values.email.length != 0 && values.fullName.length != 0;
  return (<Fragment>
    {create_user && <Modal show>
      <Loader> <FormattedMessage id="company.createuser" defaultMessage="Creating user"/> </Loader>
    </Modal>}

    {delete_user && <Modal show>
      <Loader> <FormattedMessage id="company.deleteuser" defaultMessage="Deleting user"/> </Loader>

    </Modal>}

    {deleteConfirmation && <Modal show
                                  onClose={cancelUser}
                                  onOK={deleteUser}>

      <FormattedMessage id="company.deleteuser.confirm"
                        defaultMessage="Are you sure wants to delete this user {userName}"
                        values={{ userName: user.userName }}/>

    </Modal>}

    {newUser && <Modal show
                       onClose={cancelUser}
                       onOK={formAccepted && createUser}>
      <form>


        <EmailField values={values} setValues={setValues} errors={mailErrors}
                    setErrors={setMailErrors} required/>

        <FullField values={values} setValues={setValues} errors={errors} setErrors={setErrors}
                   required/>


        <PasswordField required key={'newuser.password'} name='password'
                       thresholdLength={props.company.passwordLength} minStrength={3}
                       values={values} setValues={setValues}
                       errors={errors} setErrors={setErrors}
                       isSpecialCharPassword={props.company.specialCharPassword} showLegend={true}
                       isUppercasePassword={props.company.uppercasePassword}
                       isNumericPassword={props.company.numericPassword}/>
        <PasswordField required key={'newuser.confirmpassword'} name='confirmedPassword'
                       comparePasswords={values.password} thresholdLength={false} minStrength={3}
                       errors={errors} setErrors={setErrors} values={values} setValues={setValues}
                       isSpecialCharPassword={false} showLegend={false}
                       isUppercasePassword={false} isNumericPassword={false}/>
        <SelectField
          values={roles} onStateChanged={roleChanged}
          value={values.role}
          caption={<FormattedMessage id="newuser.dialog.roles" defaultMessage="Role"/>}
          label="Role"
          placeholder="Role"
          fieldId="value"
          valLabel="label"
        />

        <MuiPickersUtilsProvider helpers={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd-MM-yyyy"
            margin="normal"
            id="date-picker-inline"
            label={'Valid until'}
            value={values.selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>


      </form>


    </Modal>}


  </Fragment>);


};

*/
function ErrorCallback({ handleClose, errorState }) {
  return (
    <Dialog open onClose={handleClose} fullWidth>
      <DialogTitle>
        <FormattedMessage id="NewUserDialog.ErrorOnCreate" defaultMessage="Can't create user" />
      </DialogTitle>
      <DialogContent>
        <div className="error-global-fallback-component__dialog-container">
          <p className="error-global-fallback-component__error-message">
            <FormattedMessage id="ErrorOnCreate.error.message" defaultMessage="Message" />: {errorState.message}
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="NewUserDialog.ErrorOnCreate.closeButton" defaultMessage="Close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const checkNulls = (errors) => {
  let hasNulls = false;
  const keys = Object.keys(errors);
  keys.map((key) => {
    if (errors[key]) {
      hasNulls = true;
    }
  });
  return hasNulls;
};

export default function NewUserDialog({ handleClose, company, companyId, history }) {
  const [values, setValues] = useState({
    amount: '',
    usermail: '',
    password: '',
    weight: '',
    weightRange: '',
    fullName: '',
    confirmedPassword: '',
    showPassword: false,
    selectedDate: null,
    role: 'consumer'
  });
  const [errors, setErrors] = React.useState({});
  const [mailErrors, setMailErrors] = React.useState({});

  const roleChanged = (state) => {
    setValues({
      ...values,
      role: state.value
    });
  };

  const handleDateChange = (selectedDate) => {
    setValues({
      ...values,
      selectedDate
    });
  };

  const handleSave = () => {
    async function createUser() {
      try {
        const user = await createUserAsync(companyId, {
          userName: values.usermail,
          email: values.usermail,
          password: values.password,
          fullname: values.fullName,
          name: values.fullName,
          accountExpire: values.selectedDate,
          active: true,
          role: values.role
        });
        history.push(`${API_ROOT_WAR}/settings/usereditor/${companyId}/${user.id}`);
      } catch (e) {
        dialogCustom(ErrorCallback, { errorState: e });
      }
      handleClose();
    }

    createUser();
  };

  const formAccepted =
    values.usermail !== '' &&
    !checkNulls(errors) &&
    !checkNulls(mailErrors) &&
    values.confirmedPassword.length !== 0 &&
    values.password.length !== 0 &&
    values.usermail.length !== 0 &&
    values.fullName.length !== 0;

  return (
    <Dialog open onClose={handleClose} fullWidth>
      <DialogTitle>
        <FormattedMessage id="NewUserDialog.title" defaultMessage="New user" />
      </DialogTitle>
      <DialogContent>
        <form
          style={{
            display: 'flex',
            justifyContent: 'stretch',
            flexFlow: 'column nowrap'
          }}
        >
          <EmailField
            values={values}
            setValues={setValues}
            errors={mailErrors}
            setErrors={setMailErrors}
            required
            autoFocus
          />

          <FullField values={values} setValues={setValues} errors={errors} setErrors={setErrors} required />

          <PasswordField
            required
            key="newuser.password"
            name="password"
            thresholdLength={company.passwordLength}
            minStrength={3}
            values={values}
            setValues={setValues}
            errors={errors}
            setErrors={setErrors}
            isSpecialCharPassword={company.specialCharPassword}
            showLegend
            isUppercasePassword={company.uppercasePassword}
            isNumericPassword={company.numericPassword}
          />
          <PasswordField
            required
            key="newuser.confirmpassword"
            name="confirmedPassword"
            comparePasswords={values.password}
            thresholdLength={false}
            minStrength={3}
            errors={errors}
            setErrors={setErrors}
            values={values}
            setValues={setValues}
            isSpecialCharPassword={false}
            showLegend={false}
            isUppercasePassword={false}
            isNumericPassword={false}
          />
          <SelectField
            values={roles}
            onStateChanged={roleChanged}
            value={values.role}
            caption={<FormattedMessage id="newuser.dialog.roles" defaultMessage="Role" />}
            fieldId="value"
            placeholder="Role"
            label="Role"
          />

          <DatePicker
            disableToolbar
            variant="inline"
            margin="normal"
            ampm={false}
            label={<FormattedMessage id="NewUserDialog.validuntil" defaultMessage="Valid until" />}
            invalidDateMessage={<FormattedMessage id="datepicker.invalid" defaultMessage="Invalid date format" />}
            maxDateMessage={
              <FormattedMessage id="datepicker.maxDateMessage" defaultMessage="Date should not be after maximal date" />
            }
            minDateMessage={
              <FormattedMessage
                id="datepicker.minDateMessage"
                defaultMessage="Date should not be before minimal date"
              />
            }
            value={values.selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            renderInput={(props) => <TextField {...props} />}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="NewUserDialog.closeButton" defaultMessage="Cancel" />
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!formAccepted}>
          <FormattedMessage id="NewUserDialog.saveButton" defaultMessage="Save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
