const NUMBER_PATTERN = /^(\d+)(.*)?$/g;

export function parse(x) {
  const parsed = Number.parseInt(x, 10);
  if (Number.isNaN(parsed)) {
    return 0;
  }
  return parsed;
}

export function descendingComparator(a, b, orderBy, sortDirection) {
  try {
    const [arrayg1] = a[orderBy].matchAll(NUMBER_PATTERN);
    const [arrayg2] = b[orderBy].matchAll(NUMBER_PATTERN);

    const [resukt1Array, result11, nameg] = arrayg1;
    const [resukt2Array, result12, name] = arrayg2;

    const num1 = parse(result11);
    const num2 = parse(result12);

    if (num1 && !num2) {
      return -1;
    }

    if (!num1 && num2) {
      return 1;
    }

    if (num1 && num2 && num1 != num2) {
      return num2 - num1;
    }
  } catch (e) {}

  try {
    if (b[orderBy].toUpperCase() < a[orderBy].toUpperCase()) {
      return -1;
    }
    if (b[orderBy].toUpperCase() > a[orderBy].toUpperCase()) {
      return 1;
    }
    return 0;
  } catch (e) {}

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
