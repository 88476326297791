import {
  PublicClientApplication,
  AuthenticationResult,
  AccountInfo,
  EndSessionRequest,
  RedirectRequest,
  PopupRequest
} from '@azure/msal-browser';

export class AzureAuthenticationContext {
  constructor(MSAL_CONFIG) {
    this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
    this.account = null;
    this.setRequestObjects();
    if (MSAL_CONFIG?.auth?.clientId) {
      this.isAuthenticationConfigured = true;
    }
  }

  private account?: AccountInfo;

  private loginRedirectRequest?: RedirectRequest;

  private loginRequest?: PopupRequest;

  public isAuthenticationConfigured = false;

  private myMSALObj: PublicClientApplication;

  private setRequestObjects(): void {
    this.loginRequest = {
      scopes: [],
      prompt: 'select_account'
    };

    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: window.location.href
    };
  }

  login(signInType: string, setUser: any): void {
    if (signInType === 'loginPopup') {
      this.myMSALObj
        .loginPopup(this.loginRequest)
        .then((resp: AuthenticationResult) => {
          console.log(resp);
          this.handleResponse(resp, setUser);
        })
        .catch((err) => {

          console.error(err);
          window.location.reload();
        });
    } else if (signInType === 'loginRedirect') {
      this.myMSALObj.loginRedirect(this.loginRedirectRequest);
    }
  }

  logout(account: AccountInfo): void {
    const logOutRequest: EndSessionRequest = {
      account
    };

    this.myMSALObj.logoutRedirect(logOutRequest);
  }

  handleResponse(response: AuthenticationResult, incomingFunction: any) {
    if (response !== null && response.account !== null) {
      this.account = response.account;
    } else {
      this.account = this.getAccount();
    }

    if (this.account) {
      incomingFunction(this.account, response.accessToken);
    }
  }

  private getAccount(): AccountInfo | undefined {
    console.log(`loadAuthModule`);
    const currentAccounts = this.myMSALObj.getAllAccounts();
    if (currentAccounts === null) {
      // @ts-ignore
      console.log('No accounts detected');
      return undefined;
    }

    if (currentAccounts.length > 1) {
      // TBD: Add choose account code here
      // @ts-ignore
      console.log('Multiple accounts detected, need to add choose account code.');
      return currentAccounts[0];
    }
    if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  }
}

export default AzureAuthenticationContext;
