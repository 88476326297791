import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { findPath } from '@zert-packages/actions/catalogActions';
import { useIntl } from 'react-intl';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import { defaultKey, getFillFunction } from '@zert-packages/utils/fillTemplateString';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Checkbox } from '@mui/material';
import BreadCrumb from '../../shared/BreadCrumbLabel/BreadCrumb';
import BreadCrumbParents from '../../shared/BreadCrumbLabel/BreadCrumbParents';
import LastModifiedLabel from '../../shared/BreadCrumbLabel/LastModifiedLabel';
import ExpandMoreHandler from '../../shared/ExpandMoreHandler/ExpandMoreHandler';
import ActionMenuNew from '../../shared/ItemCard/ActionMenu.new';
import LabelRM from '../../shared/Label';
import SlowTooltip from '../../SlowTooltip';
import { getTileElementStatus, getTileStatus } from '../utils/getTileStatus';
import {basisEditorOpenAttachmentForPOST, handleQueryFileAsResult} from "@zert-packages/actions/api";

const fillPath = getFillFunction(defaultKey);

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    padding: '6px 12px 12px',
    cursor: 'pointer',
    width: '100%',
    transition: 'background-color 0.3s ease',
    opacity: (props) => (props.drawCuted ? '0.4' : '1'),
    backgroundColor: (props) => (props.isSelected ? '#fce9be' : 'inherit'),
    '&:hover': {
      backgroundColor: '#f1f3f5'
    }
  },
  img: {
    maxWidth: '110px',
    height: 'auto',
    marginRight: '8px',
    marginBottom: '5px'
  }
}));

export default function withTile(TileIcon, TileOverview, TileContent, expandFunction?, openFunction?, TileCustom?) {
  return function WithTileComponent(props) {
    const [isSelected, setIsSelected] = useState(false);
    const [isShowContent, setIsShowContent] = useState(false);
    const [placement, setPlacement] = useState(null);
    const [imageUrl, setImageUrl] = useState('');

    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const cl = useStyles({ ...props, isSelected });

    const expand = (e) => {
      if (!isShowContent) {
        expandFunction && expandFunction(props.element, dispatch);
      }
      setIsShowContent(!isShowContent);
      e.preventDefault();
      e.stopPropagation();
    };

    useEffect(() => {
      const fileExtension = props.element.properties['zert:FileExtension'];
      const basisIsImage = fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg" ||
          fileExtension === "gif" || fileExtension === "bmp";
      if (basisIsImage) {
        getImageUrl(props.element.versionId).then(result => {
          setImageUrl(result);
        });
      }
    }, []);

    useEffect(() => {
      if (props.catalog && props.element.metadataList && !placement) {
        const newPlacement = [];

        props.element.metadataList.values.map((metadata) => {
          props.catalog.map((catalog) => {
            if (catalog && metadata.identifier === 'posid') {
              const meta = `${metadata.value.value}`;
              const placement = findPath(meta, catalog, false);
              newPlacement.push(placement);
            }
          });
        });
        setPlacement(newPlacement);
      }
    }, [props.catalog, props.element]);

    const openElement = (e) => {
      const plugin = getPluginByMimeType(props.element.mimeType);
      if (openFunction && !props.isNewWindow) {
        openFunction(props.element, dispatch, props.externalOpen, props.externalObject);
      } else if (props.externalOpen && props.externalObject && openFunction && !props.isNewWindow) {
        openFunction(props.element, dispatch, props.externalOpen, props.externalObject);
      } else if (openFunction && !props.isNewWindow) {
        openFunction(props.element, dispatch);
      } else if (props.element.approvedAt && plugin.approvepath && !props.isNewWindow) {
        history.push(
          getPluginByMimeType(props.element.mimeType).approvepath.replace(':versionId', props.element.versionId)
        );
      } else if (e.ctrlKey || props.isNewWindow) {
        window.open(fillPath(props.element, getPluginByMimeType(props.element.mimeType).openpath), '_blank');
      } else {
        history.push(fillPath(props.element, getPluginByMimeType(props.element.mimeType).openpath));
      }
    };

    const selectElement = (e) => {
      if (props.selectElement) {
        props.selectElement(props.element.versionId, props.element);
      }

      setIsSelected(!isSelected);
      e.preventDefault();
      e.stopPropagation();
    };

    const { parents } = props.element;

    const isCompanyName = props.element && props.element.properties && props.element.properties.companyName;

    return (
      <>
        <Grid container spacing={0} onClick={openElement} className={cl.root}>
          {imageUrl && <img src={imageUrl} alt="Basis image" className={cl.img}/>}
          {props.selectFunction && (
            <Grid container item xs="auto" style={{ justifyContent: 'flex-start' }}>
              <Checkbox onClick={selectElement} checked={isSelected} />
            </Grid>
          )}
          <Grid container item xs>
            <div className="element_name card-title-container">
              <TileIcon element={props.element} />
              <h3 data-searchable-element="1" className="card-title card-title--dark">
                {props.element.name}
              </h3>
            </div>
            {/* <h5 className="task-title">{getPluginByMimeType(props.element.mimeType).name}</h5> */}
          </Grid>
          <Grid container item xs="auto" style={{ justifyContent: 'flex-end' }}>
            {expandFunction && <ExpandMoreHandler expand={isShowContent} onClick={expand} size="small" />}
            {props.actions && (
              <div className="task-action-button">
                <ActionMenuNew
                  performAction={props.performAction}
                  visible="true"
                  actions={props.actions}
                  position="initial"
                  isArchived={props.element.archivedAt !== null}
                  isApproved={props.element.approvedAt !== null}
                  hasVersions={props.element.label !== '1.1'}
                  small
                />
              </div>
            )}
          </Grid>

          {/* row break */}
          <Grid item xs={12} style={{ margin: '-16px' }} />

          <Grid container item xs="auto" style={{ alignItems: 'center' }}>
            <LastModifiedLabel element={props.element} intl={intl} />
          </Grid>

          <Grid container item xs style={{ justifyContent: 'flex-end' }}>
            {props.showInTileOverview && <TileContent {...props} />}
            {props.element && (
              <LabelRM
                text={getTileStatus(props.element, props.myuser)}
                styleName={getTileElementStatus(props.element, props.myuser)}
              />
            )}
          </Grid>

          {/* row break */}
          <Grid item xs={12} style={{ margin: '-16px' }} />

          <Grid container item xs={isCompanyName ? 4 : 6}>
            {props.element && props.element.properties && props.element.properties.templateName && (
              <SlowTooltip title={props.element.properties.templateName}>
                <span className="info value">{props.element.properties.templateName}</span>
              </SlowTooltip>
            )}
          </Grid>

          {isCompanyName && (
            <Grid container item xs={4} style={{ justifyContent: 'center' }}>
              <span className="info value">{props.element.properties.companyName}</span>
            </Grid>
          )}

          <Grid container item xs={isCompanyName ? 4 : 6} style={{ justifyContent: 'flex-end' }}>
            {props.element && props.element.properties && props.element.properties['zert:CurrentActivityResource'] && (
              <SlowTooltip title={props.element.properties['zert:CurrentActivityResource']}>
                <span className="info value">{props.element.properties['zert:CurrentActivityResource']}</span>
              </SlowTooltip>
            )}
          </Grid>

          {parents && parents.length !== 0 && (
            <Grid container item xs={6}>
              <BreadCrumbParents itemLocation={parents} />
            </Grid>
          )}

          {placement && placement.length !== 0 && (
            <Grid container item xs={6} style={{ justifyContent: 'flex-end' }}>
              <BreadCrumb itemLocation={placement} />
            </Grid>
          )}

          {props.element && props.element.properties != null && props.element.properties.activityId && (
            <Grid container item xs={6} style={{ justifyContent: 'flex-end' }}>
              {props.element.properties.activityId}
            </Grid>
          )}

          {/* row break */}
          <Grid item xs={12} style={{ margin: '-16px' }} />

          {TileCustom && (
            <Grid item xs={12}>
              <TileCustom {...props} />
            </Grid>
          )}
        </Grid>

        {props.showInlineOverview && isShowContent && (
          <Grid container item xs={12} style={{ margin: '0', padding: '0.5em 1em' }}>
            <div style={{ width: '100%' }}>
              <TileOverview {...props} key={`riskOverview${props.element.versionId}`} showCL={isShowContent} />
            </div>
          </Grid>
        )}

        {isShowContent && !props.showInTileOverview && (
          <Grid container item xs={12} style={{ margin: '0', padding: '0.5em 1em' }}>
            <div style={{ width: '100%' }}>
              <TileContent key={`ContentOverview${props.element.versionId}`} {...props} />
            </div>
          </Grid>
        )}

        {!props.showInlineOverview && isShowContent && (
          <Grid container item xs={12} style={{ margin: '0', padding: '0.5em 1em' }}>
            <div style={{ width: '100%' }}>
              <TileOverview key={`riskOverview${props.element.versionId}`} {...props} showCL={isShowContent} />
            </div>
          </Grid>
        )}
      </>
    );
  };
}

async function getImageUrl(versionId) {
  const arrayBuffer = await handleQueryFileAsResult(basisEditorOpenAttachmentForPOST + versionId);
  const blob = new Blob([arrayBuffer], {type: 'image/png'});
  return URL.createObjectURL(blob);
}
