export function getMatchedElementsInContainer(node, isMatched, searchSettings, elements = [], deepSearch = false) {
  if (node.childNodes) {
    node.childNodes.forEach((n) => {
      if (n.dataset && n.dataset.searchableElement) {
        if (n.dataset.deepSearch === 'true') {
          getMatchedElementsInContainer(n, isMatched, searchSettings, elements, true);
        } else if (isMatched(n.textContent)) {
          elements.push(n);
        }
      } else {
        getMatchedElementsInContainer(n, isMatched, searchSettings, elements, deepSearch);
      }
    });
  }

  if (deepSearch && searchSettings.classNames && node.classList) {
    searchSettings.classNames.forEach((className) => {
      if (node.classList.contains(className)) {
        if (isMatched(node.value) || isMatched(node.textContent)) {
          elements.push(node);
        }
      }
    });
  }

  if (deepSearch && node.dataset && Array.isArray(searchSettings.dataset)) {
    const keys = Object.keys(node.dataset);
    let isIncluded = false;
    searchSettings.dataset.forEach((k) => {
      if (keys.includes(k)) {
        isIncluded = true;
      }
    });
    if (isIncluded && isMatched(node.textContent)) elements.push(node);
  }

  return elements;
}
