import React from 'react';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { adobeDropMenu, adobeDropMenuUpperCase } from './utils/helpers';

const useStyles = makeStyles((theme) => ({
  wrapperTextField: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr 55px',
    gridColumnGap: 10,
    alignItems: 'center'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    marginBottom: 5
  },
  noteActiveInputWrapper: {
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15,
      background: '#ffffff'
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  notesInput: {
    margin: 0,
    width: '100%'
  }
}));

function TemplateItemRow({
  label,
  value,
  setValueProps,
  setDropMenu,
  grid,
  type,
  disabled,
  styleDropMenu,
  dropMenuFirst,
  setDropMenuFirst,
  disableMenuFirst
}) {
  const cl = useStyles();
  const dropMenuData = [{ name: 'px' }, { name: 'pt' }, { name: 'mm' }, { name: 'cm' }, { name: 'in' }];

  const handleChangeNote = (event) => {
    const val = event.target.value;
    const { name } = event.target;
    if (setValueProps) {
      setValueProps(+val, name);
    }
  };
  const adobeNumber = (number) => {
    if (JSON.stringify(number).includes('.')) {
      return +number.toFixed(3);
    }
    return +number;
  };
  const setSelectedDropMenu = (val, name) => {
    const selectedDropMenu = adobeDropMenu(value.unit);
    if (selectedDropMenu === val.name || !setDropMenu) {
      return;
    }
    const valueAdopt = value.value;
    if (selectedDropMenu === 'px') {
      if (val.name === 'mm') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt / 1.1));
      }
      if (val.name === 'pt') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 0.752));
      }
      if (val.name === 'cm') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 0.0896));
      }
      if (val.name === 'in') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 0.0353));
      }
    }
    if (selectedDropMenu === 'pt') {
      if (val.name === 'px') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 1.3));
      }
      if (val.name === 'mm') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 1.1918));
      }
      if (val.name === 'cm') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 0.1192));
      }
      if (val.name === 'in') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 0.0469));
      }
    }

    if (selectedDropMenu === 'mm') {
      if (val.name === 'px') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 1.1));
      }
      if (val.name === 'pt') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 0.8391));
      }
      if (val.name === 'cm') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt / 10));
      }
      if (val.name === 'in') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 0.0394));
      }
    }

    if (selectedDropMenu === 'cm') {
      if (val.name === 'px') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 11.2));
      }
      if (val.name === 'pt') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 8.391));
      }
      if (val.name === 'mm') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 10));
      }
      if (val.name === 'in') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 0.0394));
      }
    }
    if (selectedDropMenu === 'in') {
      if (val.name === 'px') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 28));
      }
      if (val.name === 'pt') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 21.313));
      }
      if (val.name === 'mm') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 25.4));
      }
      if (val.name === 'cm') {
        setDropMenu(adobeDropMenuUpperCase(val.name), name, adobeNumber(valueAdopt * 2.54));
      }
    }
  };


  return (
    <div className={cl.wrapperTextField} style={{ gridTemplateColumns: grid || '' }}>
      {label && <div className={cl.title}>{label}</div>}
      {dropMenuFirst && (
        <ExpanderItem
          propsLabel={dropMenuFirst.label}
          valueProps={dropMenuFirst.selected}
          setProps={setDropMenuFirst}
          type={type}
          disabled={disableMenuFirst}
        />
      )}
      <div className={cl.noteActiveInputWrapper}>
        <TextField
          value={value.value}
          type="number"
          className={cl.notesInput}
          variant="outlined"
          onChange={handleChangeNote}
          name={type}
          disabled={disabled}
        />
      </div>

      <div>
        <ExpanderItem
          valueProps={adobeDropMenu(value.unit)}
          propsLabel={dropMenuData}
          setProps={setSelectedDropMenu}
          small
          type={type}
          disabled={disabled}
          styleDropMenu={styleDropMenu}
        />
      </div>
    </div>
  );
}

export default TemplateItemRow;
