import React, { useContext, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TabsContext } from '../../../../Stylesheet';
import GroupBlock from '../GroupBlock';
import { adobeWord, handlePageLayout, handlePageLayoutSingleLevel, handleStylesheet } from '../../../../utils/helpers';
import PageFormat from '../PageFormat';
import LayoutSettings from '../LayoutSettings';
import Landscape from '../../../../utils/Landscape';
import LayoutModel from '../LayoutModel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 7,
    paddingBottom: 10
  },
  wrapperLayout: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridRowGap: 10,
    gridColumnGap: 10,
    minHeight: 210,
    '& div': {
      gridRowGap: '5px !important'
    }
  }
}));

function Page() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const pageLayout = values.stylesheet.blockSettings[values.selectedLeftMenu];

  useEffect(() => {
    if (pageLayout && pageLayout.class.includes('PageLayout')) {
      handleStylePageLayout();
    }
  }, [pageLayout]);

  if (pageLayout && !pageLayout.class.includes('PageLayout')) {
    return;
  }

  const handleStylePageLayout = () => {
    const result = {
      ...values.stylePageLayout,
      gridTemplateColumns: Array.from({ length: +pageLayout.columnCount }, (_, i) => '1fr').join(' '),
      gridColumnGap: +pageLayout.columnGap.value,
      width: +pageLayout.pageWidth.value / 2 > 250 ? 250 : +pageLayout.pageWidth.value / 2,
      height: +pageLayout.pageHeight.value / 2 > 250 ? 250 : +pageLayout.pageHeight.value / 2,
      padding: `${+pageLayout.marginTop.value / 2}px ${+pageLayout.marginRight.value / 2}px ${
        +pageLayout.marginBottom.value / 2
      }px ${+pageLayout.marginLeft.value / 2}px`
    };
    handleStylesheet('stylePageLayout', result, setValues);
  };

  const data = {
    dropFormat: ['A3', 'A4', 'A5', 'Letter', 'Legal', 'User'],
    Orientation: ['Portrait', 'Landscape']
  };
  const marginsProps = [
    { label: 'Left:', value: pageLayout.marginLeft, type: 'marginLeft' },
    { label: 'Right:', value: pageLayout.marginRight, type: 'marginRight' },
    { label: 'Top:', value: pageLayout.marginTop, type: 'marginTop' },
    { label: 'Bottom:', value: pageLayout.marginBottom, type: 'marginBottom' }
  ];
  const style = {
    top: -50
  };
  const findInitialPageNumber = (number) => {
    if (number === 'AUTO') {
      return 'auto';
    }
    if (number === 'N1') {
      return '1';
    }
    if (number === 'USER') {
      return 'user';
    }
    return number;
  };
  const adobeNumbering = (number) => {
    if (number.includes('_')) {
      const word = number.split('_');
      return `${adobeWord(word[0])} ${word[1].toLocaleLowerCase()}`;
    }
    return number;
  };
  const LayoutSettingsProps = {
    flowDropMenu: [{ name: 'Auto' }, { name: '1' }, { name: 'User settings' }],
    selectedFlowDropMenu: findInitialPageNumber(pageLayout.initialPageNumber),
    typeDropMenu: 'initialPageNumber',
    pageCountDropMenu: [
      { name: 'Auto' },
      { name: 'Even' },
      { name: 'Odd' },
      { name: 'End on even' },
      { name: 'End on odd' },
      { name: 'No force' },
      { name: 'End on even if last' },
      { name: 'User settings' }
    ],
    selectedPageCount: 'Auto',
    checked: pageLayout.blankHeadersAndFooters,
    pageLayoutDropMenu: [{ name: 'Right and left' }, { name: 'Mirrored' }],
    pageLayout: adobeWord(pageLayout.pageLayout, true),
    selectedColumnCount: pageLayout.columnCount,
    child: [{ label: 'Column gap:', value: pageLayout.columnGap, type: 'columnGap' }],
    selectedColumnGap: 0,
    numberingDropMenu: [
      { name: 'Arabic numbers' },
      { name: 'Lower alphabetical' },
      { name: 'Upper alphabetical' },
      { name: 'Lower roman' },
      { name: 'Upper roman' }
    ],
    selectedNumbering: adobeNumbering(pageLayout.numbering),
    selectedTextOrientation: pageLayout.textOrientation === 'N0' ? 0 : pageLayout.textOrientation,
    TextOrientationDropMenu: [0, 90, 180, 270, -90, -180, 270]
  };
  const gropOffsetProps = [
    { label: 'Left:', value: pageLayout.cropOffsetLeft, type: 'cropOffsetLeft' },
    { label: 'Right:', value: pageLayout.cropOffsetRight, type: 'cropOffsetRight' },
    { label: 'Top:', value: pageLayout.cropOffsetTop, type: 'cropOffsetTop' },
    { label: 'Bottom:', value: pageLayout.cropOffsetBottom, type: 'cropOffsetBottom' }
  ];
  const bleedProps = [
    { label: 'Left:', value: pageLayout.bleedLeft, type: 'bleedLeft' },
    { label: 'Right:', value: pageLayout.bleedRight, type: 'bleedRight' },
    { label: 'Top:', value: pageLayout.bleedTop, type: 'bleedTop' },
    { label: 'Bottom:', value: pageLayout.bleedBottom, type: 'bleedBottom' }
  ];

  const handleValuesBlockMargins = (val, name) => {
    handlePageLayout(val, name, values, setValues);
  };

  const handleDropMenu = (valDropMenu, name, val) => {
    handlePageLayout(val, name, values, setValues, valDropMenu);
  };

  const handleCheckbox = (val) => {
    handlePageLayoutSingleLevel(val, 'blankHeadersAndFooters', values, setValues);
  };

  const handleCheckboxGroupBlock = (val) => {
    handlePageLayoutSingleLevel(val, 'cropMarksVisible', values, setValues);
  };

  return (
    <div className={cl.root}>
      <div className={cl.wrapperRow}>
        <div>{pageLayout && <PageFormat pageFormat={data} />}</div>
        <LayoutModel />
      </div>
      <div className={cl.wrapperRow}>
        <GroupBlock
          label="Margins"
          valueProps={marginsProps}
          style={{ height: 'Calc(100% - 26px)', gridAutoRows: 'min-content' }}
          setValueProps={handleValuesBlockMargins}
          setDropMenu={handleDropMenu}
        />
        <div>
          <LayoutSettings
            label="Layout settings"
            valueProps={LayoutSettingsProps}
            setValueProps={handleValuesBlockMargins}
            setDropMenu={handleDropMenu}
            setCheckbox={handleCheckbox}
          />
        </div>
        <GroupBlock
          label="gropOffset"
          valueProps={gropOffsetProps}
          checked={{ value: pageLayout.cropMarksVisible, label: 'Insert crop marks' }}
          setValueProps={handleValuesBlockMargins}
          setDropMenu={handleDropMenu}
          setCheckbox={handleCheckboxGroupBlock}
          styleDropMenu={style}
        />
        <GroupBlock
          label="Bleed"
          valueProps={bleedProps}
          style={{ height: 'Calc(100% - 26px)', gridAutoRows: 'min-content' }}
          setValueProps={handleValuesBlockMargins}
          setDropMenu={handleDropMenu}
          setCheckbox={handleCheckbox}
          styleDropMenu={style}
        />
      </div>
    </div>
  );
}

export default Page;
