import React, {useContext} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import DoneIcon from "@mui/icons-material/Done";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import {Button} from "@mui/material";
import Divider from "@mui/material/Divider";
import {storePart} from "../API";
import {SparePartContext} from "../store/SparePartContext";
import VersionBox from "@zert-packages/components/shared/VersionBox";

const PartHeader = ({partData}) => {
    const {values, setValues} = useContext(SparePartContext)

    const savePartData = () => {
        const resultPartData = {
            ...values.currentViewedElement,
            partItems: values.parts,
            imageItems: values.partImages
        }

        setValues(prev => ({
            ...prev,
            isLoading: true
        }))
        storePart(values.partVersionId, resultPartData).then(() => {
            setValues(prev => ({
                ...prev,
                isLoading: false
            }))
        })
    }

    return (
        <>
            <Box component={"nav"} sx={{padding: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Box>
                <Button variant="contained" color="primary">
                    General
                </Button>
            </Box>

            <Box sx={{display: 'flex', columnGap: '10px', alignItems: 'center'}}>
                <Typography variant={"h1"}>{partData.name}</Typography>
                <VersionBox/>
            </Box>

            <Box>
                <IconButton style={{ padding: '4px' }} onClick={savePartData} size="large">
                    <SaveIcon fontSize="inherit" />
                </IconButton>
                <IconButton style={{ padding: '4px' }} onClick={() => {}} size="large">
                    <DoneIcon fontSize="inherit" />
                </IconButton>
                <IconButton style={{ padding: '4px' }} onClick={() => {}} size="large">
                    <NoteAddIcon fontSize="inherit" />
                </IconButton>
            </Box>
        </Box>
            <Divider/>
        </>
    );
};

export default PartHeader;