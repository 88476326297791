import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import {useSelector} from "react-redux";
import Button from "@mui/material/Button";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import TextFieldItem from "@zert-packages/components/TextFieldItem";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 414,
        padding: 11,
        background: "#E2F2F6"
    },
    wrapperTitle: {
        marginBottom: 20,
        display: "flex",
        justifyContent: "space-between",
        "& svg": {
            cursor: "pointer"
        }
    },
    title: {
        fontSize: "1.8rem",
        fontWeight: 700
    },
    label: {
        fontWeight: 700
    },
    wrapperContent: {
        display: 'grid',
        gridRowGap: 10,
    },
    row: {
        display: 'grid',
        gridTemplateColumns: '100px 1fr'
    },
    wrapperButton: {
        display: "flex",
        justifyContent: "end",
        paddingRight: 20,
        marginTop: 20,
    },
    button: {
        color: "#ffffff",
        background: "#4db1d3",
        marginLeft: 10,
        minWidth: 80,
        border: "1px solid #4db1d3",
        outline: "none !important",
        cursor: "pointer",
        "&:hover": {
            background: "none",
            color: "#212529",
            border: "1px solid #4db1d3"
        },
        "&:focus": {
            backgroundColor: "#4db1d3"
        }
    }
}))

const AddMetaDataDialog = () => {
    const cl = useStyles();
    const [open, setOpen] = useState(true);
    const {metadataTemplates} = useSelector((state) => state)
    const [metadata, setMetadata] = useState({
        selectedCategory: null,
        selectedDescription: null,
        descriptionList: null,
        metadata: '',
    })
    console.log('metadataTemplates', metadataTemplates)

    const handleClose = () => {
        setOpen(false)
    };

    const handleAdd = () => {
        handleClose()
    }

    const handleMetadata = (val, name) => {
        setMetadata(pre => ({...pre, [name]: val}))
    }

    useEffect(() => {
        if (metadataTemplates && !metadata.selectedCategory) {
            const selected = metadataTemplates.templates[0]
            setMetadata(pre => ({
                ...pre,
                'selectedCategory': selected,
                descriptionList: selected.metadataDefinitions,
                selectedDescription: selected.metadataDefinitions[0],

            }))

        }
    }, [metadataTemplates]);

    console.log('metadata', metadata)
    console.log('descriptionList', metadata.descriptionList)


    return (<Dialog
        open={open}
        onClose={handleClose}
        classes={{
            paperScrollPaper: cl.root
        }}
    >
        <div className={cl.wrapperTitle}>
            <span className={cl.title}>Meta data</span>
            <CloseIcon onClick={handleClose}/>
        </div>

        <div className={cl.wrapperContent}>
            <div className={cl.row}>
                <div className={cl.label}>
                    Category:
                </div>
                {metadata.selectedCategory && <ExpanderItem
                    valueProps={metadata.selectedCategory.name}
                    propsLabel={metadataTemplates.templates}
                    type={'selectedCategory'}
                    setProps={handleMetadata}
                />}
            </div>


            <div className={cl.row}>
                <div className={cl.label}>
                    Description:
                </div>
                {metadata.descriptionList && <ExpanderItem
                    valueProps={metadata.selectedDescription.name}
                    propsLabel={metadata.descriptionList}
                    type={'selectedCategory'}
                    setProps={handleMetadata}
                />}
            </div>

            <div className={cl.row}>
                <div className={cl.label}>
                    Metadata:
                </div>
                <TextFieldItem
                    values={metadata.metadata}
                    name={"metadata"}
                    setValues={handleMetadata}
                    multiline={true}
                    row={3}
                />
            </div>
        </div>

        <div className={cl.wrapperButton}>
            <Button className={cl.button} onClick={handleAdd}>
                OK
            </Button>
            <Button className={cl.button} onClick={handleClose}>
                Cancel
            </Button>
        </div>
    </Dialog>)
}

export default AddMetaDataDialog
