import React, { useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { FormattedMessage } from 'react-intl';
import { updateWantToSeeIntro } from '@zert-packages/actions/commonReducer';
import { useDispatch } from 'react-redux';
import { finishIntroduction, useIntroduction } from '../IntroductionContext';
import { JoyrideBeaconComponentStart } from '../Introduction';
import TooltipLocale from '../TooltipLocale';
import checkIfStepTargetExist from '../../ElementSearcher/utils/checkIfStepTargetExist';

const id = 'catalog';

const formattedMessages = {
  'IntroCatalogExplorerPage.RootNode': (
    <FormattedMessage
      id="IntroCatalogExplorerPage.RootNode"
      defaultMessage="Here you can exapand your catalog to browse through it. "
    />
  ),
  'IntroCatalogExplorerPage.TopMenu': (
    <FormattedMessage
      id="IntroCatalogExplorerPage.TopMenu"
      defaultMessage=" From this panel you can manipulate the selected items in the catalog, you select an item in the catalog by clicking on the icon of the left side of it. After that these actions can be used to move, rename remove etc."
    />
  ),
  'IntroCatalogExplorerPage.ResponsiblePerson': (
    <FormattedMessage
      id="IntroCatalogExplorerPage.ResponsiblePerson.TopMenu"
      defaultMessage="In this area you can see witch roles that are assigned to different persons on this part of the catalog, this will affect the workflow of measures etc."
    />
  ),
  'IntroCatalogExplorerPage.Counter1': (
    <FormattedMessage
      id="IntroCatalogExplorerPage.Counter1"
      defaultMessage="Here you can see some statistics on this, and included subnodes. By clicking on the box you can see the items that are affecting the statistic."
    />
  ),
  'SideBar.CreateNew': (
    <FormattedMessage
      id="SideBar.CreateNew"
      defaultMessage="From here you can create new objects, the current selected node will be pre-selected as the objects placement."
    />
  )
};

function CatalogIntro(wantToSeeIntro) {
  const [run, setRun] = useState(false);
  const dispatch = useDispatch();
  const [stepIndex, setStepIndex] = useState(0);
  const { startIntro } = useIntroduction(id, setRun);
  const steps = [
    {
      target: '[data-intro-id="CatalogExplorerPage.RootNode"]',
      content: formattedMessages['IntroCatalogExplorerPage.RootNode']
    },
    {
      target: '[data-intro-id="CatalogExplorerPage.TopMenu"]',
      content: formattedMessages['IntroCatalogExplorerPage.TopMenu']
    },
    {
      target: '[data-intro-id="CatalogExplorerPage.ResponsiblePerson"]',
      content: formattedMessages['IntroCatalogExplorerPage.ResponsiblePerson'],
      disableScrolling: true
    },
    {
      target: '[data-intro-id="CatalogExplorerPage.Counter1"]',
      content: formattedMessages['IntroCatalogExplorerPage.Counter1'],
      disableScrolling: true
    },
    {
      target: '[data-intro-id="SideBar.CreateNew"]',
      content: formattedMessages['SideBar.CreateNew']
    }
  ];

  useEffect(() => {
    if (wantToSeeIntro && !run) {
      setRun(true);
      setStepIndex(0);
    }
  }, [wantToSeeIntro]);

  const handleJoyrideCallback = (data) => {
    const { status, step, action, index, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (!checkIfStepTargetExist(step)) {
      setRun(false);
      dispatch(updateWantToSeeIntro(false));
      finishIntroduction(id);
      return;
    }
    if (finishedStatuses.includes(status)) {
      dispatch(updateWantToSeeIntro(false));
      finishIntroduction(id);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const newStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setRun(false);
      setStepIndex(newStepIndex);
      setTimeout(() => {
        setRun(true);
      }, 400);
    }
  };
  return {
    component: (
      <Joyride
        locale={TooltipLocale}
        callback={handleJoyrideCallback}
        beaconComponent={JoyrideBeaconComponentStart}
        continuous
        run={run}
        steps={steps}
        showProgress
        showSkipButton
        styles={{
          overlay: {
            position: 'fixed'
          },
          options: {
            primaryColor: '#01A1C7'
          }
        }}
      />
    ),
    startIntro
  };
}

export default CatalogIntro;
