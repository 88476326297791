import React, {useEffect} from 'react'
import {makeStyles} from "@mui/styles";
import AddToolbarDialog from "./AddToolbarDialog";
import {useSelector} from "react-redux";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import getTranslation from "@zert-packages/utils/getTranslation.old";
import TextFieldItem from "@zert-packages/components/TextFieldItem";

const useStyles = makeStyles((theme) => ({
    rootPart: {
        marginTop: 20,
        display: 'grid',
        gridRowGap: 10,
    },
    row: {
        display: 'grid',
        gridTemplateColumns: '110px 1fr'
    },
    label: {
        fontWeight: 700
    },
}))

const CreatePart = ({createBlock, setCreateBlock}) => {
    const cl = useStyles();
    const {formatTemplate, activeLocales, myLanguage} = useSelector((state) => state)
    const part = createBlock.part



    // activeLocales
    // :
    //  part: {
    //             language: null,
    //             articleNumber: null,
    //             format: null,
    //             function: null,
    //             machineType: null,
    //         },

    const handlePart = (val, name) => {
        setCreateBlock(pre => ({...pre, part: ({...pre.part, [name]: val})}))
    }

    useEffect(() => {
        if (myLanguage && !part.language) {
            handlePart({...myLanguage, name: myLanguage.displayName}, 'language')
        }
    }, [myLanguage]);

    useEffect(() => {
        if(formatTemplate?.partFormats && !part.language){
            const res = formatTemplate?.partFormats[0]
            handlePart({...res, name: getTranslation(res.label)}, 'format')
        }
    }, [formatTemplate.partFormats])

    return (<div className={cl.rootPart}>
        <div className={cl.row}>
            <div className={cl.label}>
                Source language:
            </div>
            {part.language && activeLocales && <ExpanderItem
                valueProps={part.language.name}
                propsLabel={activeLocales.map(el => ({...el, name: el.displayName}))}
                type={'language'}
                setProps={handlePart}
            />}
        </div>

        <div className={cl.row}>
            <div className={cl.label}>
                Article number:
            </div>
            <TextFieldItem
                values={part.articleNumber}
                name={"articleNumber"}
                setValues={handlePart}
            />
        </div>

        <div className={cl.row}>
            <div className={cl.label}>
                Format:
            </div>
            {part.format  && <ExpanderItem
                valueProps={part.format.name}
                propsLabel={formatTemplate.partFormats.map(el => ({...el, name: getTranslation(el.label)}))}
                type={'format'}
                setProps={handlePart}
            />}
        </div>

        <div className={cl.row}>
            <div className={cl.label}>
                Function:
            </div>
            <TextFieldItem
                values={part.function}
                name={"function"}
                setValues={handlePart}
            />
        </div>

        <div className={cl.row}>
            <div className={cl.label}>
                Machine type:
            </div>
            <TextFieldItem
                values={part.machineType}
                name={"machineType"}
                setValues={handlePart}
            />
        </div>

    </div>)
}

export default CreatePart
