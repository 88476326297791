import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { duplicateProjectAPI } from '@zert-packages/plugins/ExplorerPlugin/API';
import { showSnackbarError } from '@zert-packages/components/Snackbars';
import DuplicationDefaultDialog from '../components/DuplicationDefaultDialog';

const useStyles = makeStyles({
  root: {}
});

function Project({ unmountComponent, onFinish, element }) {
  const cl = useStyles();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(`${get(element, 'name', '')} - duplication`);
  const onDuplicate = () => {
    setLoading(true);
    duplicateProjectAPI(element.versionId, name)
      .then((r) => {
        setLoading(false);
        unmountComponent();
        onFinish(r);
      })
      .catch((e) => {
        showSnackbarError(snackbar, e.message);
        setLoading(false);
      });
  };
  const onDuplicateDisabled = name.trim().length === 0;
  return (
    <DuplicationDefaultDialog
      unmountComponent={unmountComponent}
      onDuplicate={onDuplicate}
      onDuplicateDisabled={onDuplicateDisabled}
      loading={loading}
      customDialogContent={
        <div className={cl.root}>
          <TextField
            autoFocus={!loading}
            fullWidth
            label={<FormattedMessage id="DuplicationDialogs.Project.NameField" defaultMessage="Name" />}
            value={name}
            disabled={loading}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      }
    />
  );
}

export default Project;
