import React, { useEffect, useState, Fragment } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  dropMenuButton: {
    width: '100%',
    justifyContent: 'space-between',
    border: '1px solid #DCE3E5',
    marginBottom: 10,
    color: '#8B8B8B',
    fontWeight: '400',
    paddingBottom: 2,
    paddingTop: 2,
    background: '#ffffff',
    paddingRight: 15,
    borderRadius: 0,
    outline: 'none !important',
    '&:hover': {
      background: '#ffffff'
    },
    '& span:nth-child(2)': {
      display: 'none'
    }
  },
  menuLinkSiteWrapper: {
    borderRadius: 0,
    // width: 470,
    width: 'fit-content',
    minWidth: 284,
    maxWidth: 'Calc(100% - 40px)',
    marginTop: 40,
    border: '1px solid #DCE3E5',
    boxShadow: 'none',
    '@media (max-width: 900px)': {
      marginTop: 45,
      width: 'Calc(100% - 151px)'
    }
  },
  disable: {
    color: '#D3D3D3',
    cursor: 'default'
  }
}));

function DropMenu({
  itemProps,
  filteredState,
  category,
  filteredDefinitions,
  titleCategory,
  setTitleCategory,
  setNevCategory
}) {
  const cl = useStyles();
  const [open, setOpen] = useState(null);
  const [titleDropMenu, setTitleDropMenu] = useState(null);

  const handleopen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCategory = (item, disable) => {
    if (disable) {
      return;
    }
    if (category) {
      setOpen(null);
      setTitleCategory(item.name);
      filteredDefinitions[1](item);
    } else {
      setTitleDropMenu(item.name);
      filteredState[1](item);
      setOpen(null);
      setTitleCategory(item.metadataDefinitions[0].name);
      filteredDefinitions[1](item.metadataDefinitions[0]);
    }
    setNevCategory(null);
  };

  useEffect(() => {
    if (!titleDropMenu && itemProps && itemProps.templates && itemProps.templates.length > 0) {
      if (itemProps.templates[0].name) {
        setTitleDropMenu(itemProps.templates[0].name);
      }
    }
  }, [itemProps, titleDropMenu]);

  useEffect(() => {
    if (
      !titleCategory &&
      category &&
      filteredState[0].metadataDefinitions &&
      filteredState[0].metadataDefinitions.length > 0
    ) {
      setTitleCategory(filteredState[0].metadataDefinitions[0].name);
    }
  }, [category, filteredState, titleCategory]);

  return (
    <>
      {category ? (
        <div>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleopen} className={cl.dropMenuButton}>
            {titleCategory && titleCategory}
            <ArrowDropDownIcon style={{ color: open ? '#8B8B8B' : '#224952' }} />
          </Button>
          <Menu
            anchorEl={open}
            open={Boolean(open)}
            onClose={() => setOpen(null)}
            classes={{
              paper: cl.menuLinkSiteWrapper
            }}
          >
            {category &&
              filteredState[0].metadataDefinitions &&
              filteredState[0].metadataDefinitions.length > 0 &&
              filteredState[0].metadataDefinitions.map((el, i) => (
                <MenuItem
                  key={i}
                  onClick={() => handleCategory(el, titleCategory === el.name)}
                  className={titleCategory === el.name ? cl.disable : ''}
                >
                  {el.name && el.name}
                </MenuItem>
              ))}
          </Menu>
        </div>
      ) : (
        itemProps &&
        itemProps.templates &&
        itemProps.templates.length > 0 && (
          <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleopen} className={cl.dropMenuButton}>
              {titleDropMenu && titleDropMenu}
              <ArrowDropDownIcon style={{ color: open ? '#8B8B8B' : '#224952' }} />
            </Button>
            <Menu
              anchorEl={open}
              open={Boolean(open)}
              onClose={() => setOpen(null)}
              classes={{
                paper: cl.menuLinkSiteWrapper
              }}
            >
              {itemProps.templates.map((el, i) => (
                <MenuItem
                  key={i}
                  onClick={() => handleCategory(el, titleDropMenu === el.name)}
                  className={titleDropMenu === el.name ? cl.disable : ''}
                >
                  {el.name && el.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )
      )}
    </>
  );
}

export default DropMenu;
