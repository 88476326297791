import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { turnOnCLMLoader } from '../TranslationJobPlugin/tjReducers';
import { fetchPartGroup } from './servicesMiddlewares';
import PartGroupTable from './PartGroupTable';
import ImageCard from './ImageCard';
import OpenDialog from './OpenPartDialog';
import Header from './Header';

export const formStyle = (theme) =>
  createStyles({
    mainContainer: {
      width: '100%',
      height: '100%'
    },
    rootBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '5px'
    },
    bodyContainer: {
      padding: '8px',
      height: 'calc(100% - 52px)',
      width: '100%',
      minHeight: '300px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',

      '@media (max-width: 900px)': {
        flexDirection: 'column'
      }
    },
    AppBar: {
      display: 'grid',
      gridTemplateColumns: '1fr auto auto',
      zIndex: '10',
      overflow: 'hidden',
      paddingRight: 8
    },
    formControl: {
      margin: theme.spacing(1),
      width: '100%'
    }
  });
const useStyles = makeStyles(formStyle);

function GroupView({ paramsGroup }) {
  const cl = useStyles();
  const CLM = useSelector((state) => state.CLM);
  const isLoading = useSelector((state) => state.CLM.isLoading);
  const dispatch = useDispatch();
  const [parts, setParts] = useState(null);
  const [selectItem, setSelectItem] = useState(null);
  const [imageItemsArr, setImageItemsArr] = useState(null);
  const [color, setColor] = useState('#ADD8E6');
  const [dialog, setDialog] = useState({
    open: false,
    type: null
  });
  const [moveNewHotspot, setMoveNewHotspot] = useState(false);
  const [checkout, setCheckout] = useState(false);

  const [images, setImages] = useState([
    {
      name: null,
      src: null,
      imageItems: null
    }
  ]);

  const handleSelectedItem = (position) => {
    setSelectItem(null);
    setColor('green');
    setSelectItem(position);
  };

  useEffect(() => {
    setParts(null);
    dispatch(turnOnCLMLoader());
    dispatch(fetchPartGroup(paramsGroup.version, paramsGroup.checkout, paramsGroup.loadItems));
  }, [paramsGroup.versionId]);

  useEffect(() => {
    if (CLM && CLM.partsGroup && !parts) {
      setParts(CLM.partsGroup);
      console.log('DATA', CLM.partsGroup);
      setImageItemsArr(CLM.partsGroup.imageItems[0]);
    } else if (CLM && CLM.partsGroup === null && parts) {
      setParts(null);
    }
  }, [parts, CLM]);

  return (
    <div className={cl.mainContainer}>
      <Header />
      <div className={cl.bodyContainer}>
        {imageItemsArr && (
          <ImageCard
            newImages={images}
            moveNewHotspot={moveNewHotspot}
            setMoveNewHotspot={setMoveNewHotspot}
            versionImage={imageItemsArr}
            selectItem={selectItem}
            handleSelectedItem={handleSelectedItem}
            color={color}
          />
        )}
        {parts && (
          <PartGroupTable
            elements={parts}
            selectItem={selectItem}
            checkout={checkout}
            handleSelectedItem={handleSelectedItem}
            setDialog={setDialog}
          />
        )}
      </div>
      {dialog.open && (
        <OpenDialog
          setMoveNewHotspot={setMoveNewHotspot}
          setImages={setImages}
          dialog={dialog}
          setDialog={setDialog}
          selectItem={selectItem}
          setSelectItem={setSelectItem}
        />
      )}
    </div>
  );
}

export default injectIntl(GroupView);
