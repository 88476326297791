import Dialog from '@mui/material/Dialog';
import React, {useState} from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';

const type = ['Ellipse', 'Rectangle'];
function NewImageDialog({ dialog, setDialog, setImages, ...other }) {
  const onClick = (e) => {
    e.target.value = null;
  };

  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const addToArrImage = (e, value) => {
    if (value) {
      setImages((prevState) => ({
        ...prevState,
        name: 'new image',
        src: imgData,
        imageItems: null
      }));
      setDialog({ open: false, type: null });
    } else {
      setImgData(null);
      setDialog({ open: false, type: null });
    }
  };

  return (
    <Dialog
      open={dialog}
      onClose={() => {
        setDialog({ open: false, type: null });
      }}
      fullWidth
    >
      <DialogTitle>New image</DialogTitle>
      <DialogContent fullWidth>
        <TextField fullWidth id="standard-basic" label="Name" variant="standard" />
        <div style={{ height: 30 }} />
        <form>
          <div>
            <input id="profilePic" type="file" onChange={onChangePicture} />
          </div>
          <div style={{ height: 20 }} />
          <div>
            <h6>Preview:</h6>
            <img src={imgData} style={{maxWidth: '500px'}}/>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            addToArrImage(e, true);
          }}
          variant="contained"
          color="primary"
        >
          OK
        </Button>
        <Button
          onClick={() => {
            setDialog({ open: false, type: null });
          }}
          variant="contained"
          color="primary"
        >
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewImageDialog;
