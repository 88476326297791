import React, { useEffect } from "react";
import getTranslation from "@zert-packages/utils/getTranslation.new";
import { Button, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { usePublishingFilterStyles } from "./styles";

const FilterMenu = ({ options, anchorEl, onClose, selectedOption, onChoseOption, onOpen, isOptionsTranslatable }) => {
  const classes = usePublishingFilterStyles();



  const getTranslatable = (el) => {
    if (!el) {
      return "";
    }
    if (isOptionsTranslatable) {
      return getTranslation(el.label);
    }
    return el.label;
  };

  useEffect(() => {
    if(!selectedOption){
      onChoseOption(options[0])
    }
  }, [selectedOption])

  return <>
    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => options && options.length > 0 && onOpen(e)}
            className={classes.dropMenuButton}>
      {selectedOption && getTranslatable(selectedOption)}
      <ArrowDropDownIcon />
    </Button>
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      classes={{
        paper: classes.menuLinkSiteWrapper
      }}
    >
      {(options || []).map((el, i) => (
        <MenuItem
          key={i}
          onClick={() => onChoseOption(el)}
        >
          {getTranslatable(el)}
        </MenuItem>
      ))}
    </Menu>
  </>;
};

export default FilterMenu;