import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog, FormControlLabel, TextField } from "@mui/material";
import PaletteIcon from "@mui/icons-material/Palette";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import { Checkbox } from "antd";
import { handeValue } from "../table/helpers/main";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 400,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      cursor: "pointer",
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700
  },
  formRoom: {
    marginLeft: 0,
    display: 'grid',
    gridTemplateColumns: '13px 1fr',
    gridColumnGap: 10,
    '& label': {
      cursor: "pointer",
      marginBottom: 0,
      '& input': {
        marginTop: 4,
      },
    },
  },
  wrapperInput: {
    display: "grid",
    gridTemplateColumns: "47px 1fr 17px",
    gridColumnGap: 10,
    alignItems: "center",
    "& input": {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15
    },
    "& textarea": {
      overflow: "hidden"
    },
    "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  notesInput: {
    margin: 0,
    width: "100%"
  },
  text: {
    fontWeight: 700,
    fontSize: 14,
    marginBottom: 0,
  },
  wrapperButton: {
    display: "flex",
    justifyContent: "end",
    paddingRight: 20,
    marginTop: 20
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

const DialogHandleColumWidth = ({ set, tableState }) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState({
      width: 10,
      widthDefined: false
  })

  // const [defaultWidth, setDefaultWidth] = useState(false);
  // const [width, setWidth] = useState(10)




  const handleCheckbox = (e) => {
    handeValue('widthDefined', e.target.checked, setValue)
  };

  const handleWidth = (e) => {
    handeValue('width', e.target.value, setValue)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    set({...value, activeCellIndex: tableState.activeCellIndex })
  };
  // width: 20, widthDefined


  useEffect(() => {
      if(tableState  && tableState.interest){
        const findActiveCell = tableState.interest[tableState.activeCellIndex]
         setValue(pre=> ({...pre,  ...findActiveCell, activeCellIndex: tableState.activeCellIndex}))
      }
  }, [tableState])

  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}>
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>Column width</span>
      <CloseIcon onClick={handleClose} />
    </div>
    <div className={cl.wrapperInput}>
      <div></div>
    <FormControlLabel
      className={cl.formRoom}
      label="Proportional column width"
      control={
        <Checkbox
          checked={value.widthDefined}
          onChange={handleCheckbox}
        />}
    />
    </div>
    <div className={cl.wrapperInput}>
      <p className={cl.text}>Label:</p>
        <TextField
          type={'number'}
          value={value.width}
          className={cl.notesInput}
          variant="outlined"
          onChange={(e) => handleWidth(e)}
        />
      <p className={cl.text}>%</p>
    </div>
    <div className={cl.wrapperButton}>
      <Button className={cl.button} onClick={handleSave}>
        OK
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>

  </Dialog>);
};

export default DialogHandleColumWidth;
