import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {Dialog} from "@mui/material";
import DropMenu from "../../../DropMenu";
import {storeBlockApi} from "../API";
import getTranslation from "@zert-packages/utils/getTranslation.old";
import handleValueState from "@zert-packages/utils/handleValueState";
import {useSelector} from "react-redux";
import TextFieldItem from "../../../TextFieldItem";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 262,
        padding: "5px 10px",
        paddingBottom: 10,
        background: "#E2F2F6",
        minHeight: 168
    },
    wrapperTitle: {
        display: "flex",
        justifyContent: "flex-end",
        "& svg": {
            cursor: "pointer"
        }
    },
    wrapperList: {
        marginTop: 5,
        border: "1px solid lightgray",
        minHeight: 100,
        padding: 5,
        overflow: 'auto',
    },
    listItem: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#CECECE"
        }
    },
    listItemActive: {
        background: "blue",
        color: "#ffffff"
    },
    wrapperDropMenu: {
        display: "grid",
        rowGap: 7
    }
}));

const GroupDialog = ({block, el, parentItemCreate, setBlock}) => {
    const cl = useStyles();
    const [open, setOpen] = useState(true);
    const [available, setAvailable] = useState({
        format: null,
        indexSelectedFormat: 0,
        template: null,
        selectedTemplate: '',
        onChange: false,
        parentBlock: block.child,
    })
    const availableChildFormats = parentItemCreate.availableChildFormats
    const {formatTemplate} = useSelector((state) => state);
    const type = el?.elementInfo?.mimeType?.split('/')[1].replace('zert-', '').toLocaleUpperCase()
    const info = el?.elementInfo || el?.info;
    const getFormat = info?.properties?.['zert:Format'];
    const indexSelected = available.indexSelectedFormat


    const handleTemplate = (val, type) => {
        setAvailable(pre => ({
            ...pre,
            selectedTemplate: val.value ? val.value : val,
        }));
    }

    const handleClose = async () => {
        setOpen(false);

        if (!available.onChange) {
            return
        }

        const format = available.format[indexSelected]
        const res = {
            ...el, elementInfo:
                {
                    ...el.elementInfo, properties:
                        {...el.elementInfo.properties, 'zert:Format': format.identifier}
                }
        }

        const blockParent = block.child

        const items = blockParent.items.reduce((acu, cur) => {
            if (cur.identifier === el.identifier) {
                return [...acu, res]
            }
            return [...acu, cur]
        }, [])

        const properties = available.template.settings.properties

        const createItemData = {
            name: blockParent.name,
            targetLocale: blockParent.targetLocale,
            format: format.identifier,
            items
        };
        if (properties) {
            if (available.template?.identifier === "list-alpha") {
                createItemData.formatSettings = {
                    template: {properties: properties},
                    settings: [{
                        property: properties[0],
                        class :  "se.zert.backend.rest.common.pojo.ClientSettings$ChoiceSetting",
                        value: [available.selectedTemplate],
                        values: [available.selectedTemplate],
                    }],
                }
            }
            if (available.template?.identifier === "list-numbered") {
                createItemData.formatSettings = {
                    template: {properties: properties},
                    settings: [{property: properties[0], value: +available.selectedTemplate, class: "se.zert.backend.rest.common.pojo.ClientSettings$NumberSetting"}],
                }
            }
        } else {
            createItemData.formatSettings = {
                "template": {"properties": []},
                "settings": []
            }
        }

        setBlock(pre => ({...pre, blockItem: res, child: {...pre.child, ...createItemData}}))
        await storeBlockApi(blockParent.elementInfo.versionId, blockParent.targetLocale.language, createItemData);

    };

    const handleSelectedAvailable = (val, idx) => {
        if (available.indexSelectedAvailable === idx) {
            return;
        }
        setAvailable(pre => ({
            ...pre,
            indexSelectedFormat: idx,
            onChange: true,
        }));
    }

    const findFormatTemplate = () => {
        if ('BLOCK') {
            return 'blockFormats'
        }
        if ('TABLE') {
            return 'tableFormats'
        }
        if ('IMAGE') {
            return 'imageFormats'
        }
    }

    useEffect(() => {
        if (availableChildFormats && type) {
            const format = availableChildFormats.filter(el => el.type === type)
            const indexSelectedFormat = getFormat
                ? availableChildFormats.findIndex(el => el.identifier === getFormat)
                : 0
            if (format?.length > 0) {
                handleValueState(false, false, setAvailable, {
                    format,
                    indexSelectedFormat
                })
            }
        }
    }, [availableChildFormats, type]);

    useEffect(() => {
        if (indexSelected >= 0 && formatTemplate && available.format) {
            const type = formatTemplate[findFormatTemplate()].find(el => el.identifier === available.format[indexSelected]?.identifier)
            const res = {
                template: null,
                selectedTemplate: '',
            }
            if (type) {
                res.template = type
                if (type?.identifier === "list-numbered") {
                    const number = block?.child?.formatSettings?.settings[0]?.value;
                    if (number) {
                        res.selectedTemplate = number
                    } else {
                        res.selectedTemplate = type.settings.properties[0]?.value
                    }
                }
                if (type?.identifier === "list-alpha") {
                    const value = block?.child?.formatSettings?.settings[0]?.value;
                    if (value) {
                        const items = block?.child?.formatSettings?.template?.properties[0]?.items
                        if (items) {
                            const i = items?.findIndex(el => el.value === value[0])
                            res.selectedTemplate = items[i].value
                        }
                    } else {
                        res.selectedTemplate = type.settings.properties[0]?.items[0]?.value
                    }
                }
            }
            handleValueState(false, false, setAvailable, res)
        }
    }, [indexSelected, formatTemplate, available.format])



    return (<Dialog
        open={open}
        onClose={handleClose}
        classes={{
            paperScrollPaper: cl.root
        }}
    >
        <div className={cl.wrapperList}>
            {available.format && available.format.map((el, i) => <div
                className={indexSelected === i ? cl.listItemActive : cl.listItem}
                key={i} onClick={() => handleSelectedAvailable(el, i)}>
                {el.label ? getTranslation(el.label) : el.identifier}</div>)}
        </div>
        {available.template?.settings?.properties.length !== 0
            ? available.template?.settings.properties.map((el, i) => <div key={i}>
                {el?.labelObject && getTranslation(el.labelObject)}
                {available.template.identifier === "list-numbered"
                    ? <>
                        <TextFieldItem
                            values={available.selectedTemplate}
                            name={"nameBlock"}
                            type={'number'}
                            setValues={handleTemplate}
                            // error={errorImg}
                            // autoFocus={true}
                        />
                    </>
                    : <DropMenu
                        selected={available.selectedTemplate}
                        dropItem={el?.items.map(it => ({...it, name: getTranslation(it.labelObject), index: i}))}
                        name={'drop-menu'}
                        style={{textTransform: 'lowercase', color: '#000', fontSize: '1rem'}}
                        setDropItem={handleTemplate}
                    />
                }
            </div>)
            : <div>No properties available</div>}
    </Dialog>);
};

export default GroupDialog;
