import makeStyles from '@mui/styles/makeStyles';
import Stepper from '@mui/material/Stepper';
import React, { createContext, useContext, useState } from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { FormattedMessage } from 'react-intl';
import MobileStepper from '@mui/material/MobileStepper';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { DialogActions, useTheme } from '@mui/material';
import formatDate from './utils/formatDate';

export const AssertPublishingWizardContext = createContext(null);

export function AssertPublishingWizardProvider({ children }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [disabledNext, setDisabledNext] = React.useState(true);
  const [
    {
      from,
      to,
      splitLevel,
      elementId,
      documentId,
      versionId,
      versions,
      versionsLoad,
      name,
      nameChanged,
      loadingMetadata,
      published,
      element
    },
    setStatus
  ] = useState({
    from: formatDate(new Date()),
    to: formatDate(new Date()),
    splitLevel: 'NONE',
    elementId: -1,
    documentId: null,
    versionId: null,
    versions: [],
    name: '',
    nameChanged: false,
    loadingMetadata: false,
    published: formatDate(new Date()),
    element: null
  });

  const [{ serialNumber, productId }, updateMetaData] = useState({ serialNumber: [''], productId: [''] });

  const updateProductId = (result) => {
    updateMetaData((prevState) => ({ ...prevState, productId: result }));
    setStatus((prevState) => ({ ...prevState, nameChanged: true }));
  };

  /* const setVersionId  = (versionId) => {

        setStatus(prevState => ({...prevState,  versionId : versionId}))
    } */

  const updateSerialNumber = (result) => {
    updateMetaData((prevState) => ({ ...prevState, serialNumber: result }));
    setStatus((prevState) => ({ ...prevState, nameChanged: true }));
  };

  const [extended, setExtended] = React.useState(false);
  const [{ allowComments, notifyMeOnExpire, notifyMeOneNewComment, validUntil, purpose }, setPurpose] = useState({
    allowComments: false,
    notifyMeOnExpire: false,
    notifyMeOneNewComment: false,
    validUntil: null,
    purpose: ''
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const values = {
    activeStep,
    handleNext,
    handleBack,
    handleReset,
    extended,
    setExtended,
    allowComments,
    notifyMeOnExpire,
    notifyMeOneNewComment,
    validUntil,
    purpose,
    nameChanged,
    loadingMetadata,
    setPurpose,
    disabledNext,
    setDisabledNext,
    from,
    to,
    splitLevel,
    elementId,
    documentId,
    versionId,
    versions,
    versionsLoad,
    setStatus,
    serialNumber,
    productId,
    updateProductId,
    updateSerialNumber,
    name,
    published,
    element
  };
  return <AssertPublishingWizardContext.Provider value={values}>{children}</AssertPublishingWizardContext.Provider>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '300px'
  },
  rootMobileStepper: {
    maxWidth: 400,
    flexGrow: 1
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  }
}));

function getSteps() {
  return [
    <FormattedMessage id="AssertPublishingWizardDialog.ChooseLayoutStep" defaultMessage="Publishing setting" />,
    <FormattedMessage id="AssertPublishingWizardDialog.Purpose" defaultMessage="Purpose" />,
    <FormattedMessage id="AssertPublishingWizardDialog.Languages" defaultMessage="Languages" />,

    <FormattedMessage id="PreviewWizard.Preview" defaultMessage="Preview" />

    /*        <FormattedMessage id = "PreviewWizard.ChooseTime"
                          defaultMessage = 'Finalize Preview'/>,
        <FormattedMessage id = "PreviewWizard.PreviewServerSettings"
                          defaultMessage = 'Preview Server settings'/> */
  ];
}

export function getStepContent(step) {
  switch (step) {
    case 0:
      return (
        <FormattedMessage
          id="PublishingWizardDialog.Steps.LayoutAndLanguage.Description"
          defaultMessage="Follow these steps to publish your element."
        />
      );
    case 1:
      return (
        <FormattedMessage
          id="PublishingWizardDialog.Steps.Purpose"
          defaultMessage="Follow these steps to publish your element."
        />
      );
    case 2:
      return (
        <FormattedMessage
          id="PublishingWizardDialog.Steps.Languages"
          defaultMessage="Choose languages to include in publication."
        />
      );
    case 3:
      return (
        <FormattedMessage
          id="PublishingWizardDialog.Steps.Preview"
          defaultMessage="Verify that your publication is correct."
        />
      );

    default:
      return 'Unknown step';
  }
}
export function PublishingMobileStepper({ handleConfirm }) {
  const classes = useStyles();
  const theme = useTheme();
  const { activeStep, handleNext, handleBack, disabledNext } = useContext(AssertPublishingWizardContext);

  const handleFinish = (event) => {
    if (activeStep < getSteps().length - 1) {
      handleNext(event);
    } else {
      handleConfirm();
    }
  };
  return (
    <MobileStepper
      variant="dots"
      steps={getSteps().length}
      position="static"
      activeStep={activeStep}
      className={classes.rootMobileStepper}
      nextButton={
        <Button size="small" variant="outlined" disabled={disabledNext} onClick={handleFinish}>
          {activeStep === getSteps().length - 1 ? (
            <FormattedMessage id="AssertPublishingWizardDialog.Steps.Finish" defaultMessage="Start preview" />
          ) : (
            <FormattedMessage id="AssertPublishingWizardDialog.Steps.Next" defaultMessage="Next" />
          )}
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          <FormattedMessage id="AssertPublishingWizardDialog.Steps.Back" defaultMessage="Back" />
        </Button>
      }
    />
  );
}

export default function AssertVerticalLinearStepper() {
  const classes = useStyles();
  // const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const { activeStep, handleNext, handleBack } = useContext(AssertPublishingWizardContext);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
