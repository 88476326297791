import React, { useState } from "react";
import { Dialog, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import ListSelected from "../../components/ListSelected";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 350,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      cursor: "pointer"
    }
  },
  titleMain: {
    fontSize: "1.5rem",
    fontWeight: 700,
    marginBottom: 10
  },
  title: {
    fontWeight: 700,
    marginBottom: 5,
  },
  wrapperContent: {
    display: "grid",
    gridRowGap: 10,
    "& input": {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 10,
      background: "#ffffff",
      borderRadius: 5
    },
    "& textarea": {
      overflow: "hidden"
    },
    "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  input: {
    margin: 0,
    width: "100%",
    // maxWidth: 200
  },

  wrapperButton: {
    marginTop: 15,
    display: "flex",
    justifyContent: "end",
    paddingRight: 20
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}))

const DialogAddElementFilter = ({title= '', setValues}) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [filter, setFilter] = useState({
    mimeType: ''
  });

  const handleClose = () => {
    setOpen(false);
  };

  const saveFilter = () => {

  }

  const handlingFilter = (val, name) => {
    setFilter(pre => ({ ...pre, [name]: val }));
  }

  const handleTitle = (e) => {
    const val = e.target.value;
    const name = e.target.name;
    handlingFilter(val, name)
  }

  console.log('filter', filter);
  return(<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.titleMain}>{title}</span>
      <CloseIcon onClick={handleClose} />
    </div>
    <div className={cl.wrapperContent}>

      <div>
        <div className={cl.title}>Mime-type:</div>
        <TextField
          value={filter.mimeType}
          name={"mimeType"}
          variant="outlined"
          className={cl.input}
          onChange={handleTitle}
        />
      </div>

      <div>
        <div className={cl.title}>Property filters:</div>
        <ListSelected />
      </div>

      <div>
        <div className={cl.title}>Reference filters:</div>
        <ListSelected />
      </div>

      <div>
        <div className={cl.title}>Content filters:</div>
        <ListSelected />
      </div>



    </div>

    <div className={cl.wrapperButton}>
      <Button className={cl.button} onClick={saveFilter}>
        OK
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>
  </Dialog>)
}

export default DialogAddElementFilter