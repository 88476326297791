import { loadPEDAPI, storePEDAPI } from './API';
import { loadPED } from './pedReducers';

export const initialPED = {
  category: 'pressure.vessels',
  substance: 'gas',
  substanceClass: '1a',
  temperature: 0,
  pressure: 0,
  volume: 0
};

export const fetchPEDView = (versionId, callback) => (dispatch) => {
  const translationJob = null;
  loadPEDAPI(versionId).then((res) => {
    dispatch(loadPED(res));
    if (typeof callback === 'function') callback();
  });
};

export const storePEDView = (versionId, ped, callback) => (dispatch) => {
  const translationJob = null;
  storePEDAPI(versionId, ped).then((res) => {
    if (typeof callback === 'function') callback();
  });
};
