import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
 /* getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }*/
};

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: 2,
      zIndex: 9999
    }
  },
  chip: {
    margin: 2
  },
  chipContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignContent: 'center',
    alignItems: 'center',
    gridGap: '1px'
  }
}));

export default function MultiSelect({
  name,
  options,
  label,
  optionFieldValue = 'value',
  optionFieldLabel = 'label',
  value,
  onChange
}) {
  const classes = useStyles();
  const handleChange = (newValue) => {
    const formattedNewValue = options.filter((option) => newValue.includes(option[optionFieldValue]));
    onChange(formattedNewValue);
  };
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        input={<Input />}
        value={value.map((item) => item[optionFieldValue])}
        onChange={(event) => handleChange(event.target.value, event)}
        renderValue={(selectedOptions) => (
          <div className={classes.chips}>
            {createSelectedWithLabels(selectedOptions, options, optionFieldValue).map((item) => (
              <Chip key={item[optionFieldValue]} label={item[optionFieldLabel]} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem key={option[optionFieldValue]} value={option[optionFieldValue]}>
            {option[optionFieldLabel]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const createSelectedWithLabels = (selectedOptions, options, optionFieldValue) => {
  options.filter((item) => {
    selectedOptions.includes(item[optionFieldValue]);
  });
  return options.filter((item) => selectedOptions.includes(item[optionFieldValue]));
};
