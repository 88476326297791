import React from "react";
import {createInProgressSnack} from "@zert-packages/components/common/snackBar";
import {FormattedMessage} from "react-intl";
import {
    createBlockApi, createImgApi,
    createIndexApi,
    createPhraseApi,
    createTableApi, initImgApi, storeBlockApi,
} from "@zert-packages/components/InfoElement/components/API";
import {uploadImageFile} from "../../PhrasePlugin/servicesMiddlewares";
import {v4 as uuidv4} from "uuid";
import {saveInitItem} from "./main";


export const createPhrase = async (snackbar, mylocale, parentVersionId, setValues, block) => {

    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.CreatePhrase" defaultMessage="Create phrase..."/>
    );
    generatingSnackbar;
    const res = await createPhraseApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId
        });
    if (res.elementInfo) {
        saveInitItem({...res, blockParent: block?.blockItem}, setValues)
    }
    snackbar.closeSnackbar(generatingSnackbar);
};


export const createBlockItem = async (snackbar, mylocale, parentVersionId, setValues, block) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.CreateBlock" defaultMessage="Create block..."/>
    );
    generatingSnackbar;
    const res = await createBlockApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId
        });

    if (res.elementInfo) {
        setValues(pre => ({
            ...pre,
            createItem: res.elementInfo,
            beforeTreeItem: [],
            // expandedTreeItem: res,
            treeItem: {...res, blockParent: block?.blockItem}
        }));
    }

    snackbar.closeSnackbar(generatingSnackbar);
};

export const createTableItem = async (snackbar, mylocale, parentVersionId, setValues, block) => {
    const res = await createTableApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId
        });
    if (res.elementInfo) {
        saveInitItem({...res, blockParent: block?.blockItem}, setValues)
    }
};

export const createIndex = async (snackbar, mylocale, parentVersionId, setValues, block) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.CreatePhrase" defaultMessage="Create phrase..."/>
    );
    generatingSnackbar;
    const res = await createIndexApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId
        });
    if (res.elementInfo) {
        saveInitItem({...res, blockParent: block?.blockItem}, setValues)
    }
    snackbar.closeSnackbar(generatingSnackbar);
};


export const createImgItem = async (mylocale, parentVersionId, dispatch, parentItemCreate, values, setValues, setBlock, snackbar, block) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="CLM.UploadingFile.CreateImage" defaultMessage="CreateImage..."/>
    );
    generatingSnackbar;


    await initImgApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId
        });


    const saveImg = async (val) => {


        const createImgItem = await createImgApi(parentVersionId, mylocale, {
            imageName: val.info.image.name,
            imagePreview: val.right
        });

        const items = parentItemCreate.items.length > 0
            ? parentItemCreate.items.reduce((acu, cur, idx) => {
                if (idx === values?.action?.indexElementCreate) {
                    if (block.expandedToolbarBottom) {
                        return [...acu, cur, createImgItem,];
                    }
                    return [...acu, createImgItem, cur];
                }
                return [...acu, cur];
            }, [])
            : [createImgItem];
        const createItemData = {
            name: parentItemCreate.name,
            targetLocale: parentItemCreate.targetLocale,
            format: parentItemCreate.format,
            items
        };


        await storeBlockApi(parentItemCreate.versionId, parentItemCreate.targetLocale.language, createItemData);

        if (setBlock) {
            setBlock(pre => ({...pre, expandedToolbar: false, createType: null, expandedToolbarBottom: false,}));
        }
        setValues(pre => ({
            ...pre,
            parentItem: parentItemCreate,
            blockClickCreateItemsBlock: false,
            treeItem: createImgItem,
            investmentLevel: parentItemCreate.investmentLevel,
            action: {
                saveElement: createImgItem,
                parentItemCreate: {...parentItemCreate, items: items, type: "save"}
            }
        }));
        snackbar.closeSnackbar(generatingSnackbar);
    };
    await dispatch(uploadImageFile(uuidv4(), saveImg));
};

export const preSaveItems = (val, setBlock, setValues, block) => {
    setBlock(pre => ({
        ...pre,
        nameBlock: val,
    }));
    if (val.length > 0) {
        setValues(pre => ({
            ...pre,
            changes: true,
            blockClickCreateItemsBlock: false,
            beforeTreeItem: [],
            action: {
                ...pre.action,
                preSaveElement: pre.treeItem ? {
                    ...pre.treeItem,
                    blockSaveProps: block,
                    elementInfo: {...pre.treeItem.elementInfo, name: val}
                } : null
            }
        }));
    } else {
        setValues(pre => ({...pre, changes: false, blockClickCreateItemsBlock: true}));
    }
}

export const preSavePhrase = (type, block, setValues) => {
    if (type === "zert-phrase" && block.phraseEditor?.phrase) {
        if (block.phraseEditor?.phrase?.length === 0) {
            setValues(pre => ({...pre, changes: false, blockClickCreateItemsBlock: true}));
        }
        if (block.phraseEditor?.phrase?.length > 0) {
            setValues(pre => ({
                ...pre,
                changes: true,
                blockClickCreateItemsBlock: false,
                action: {
                    ...pre.action,
                    blockSaveProps: block,
                    preSaveElement: {
                        ...pre.treeItem,
                        elementInfo: {...pre.treeItem?.elementInfo, name: block.phraseEditor?.phrase}
                    }
                }
            }));
        }
    }
}