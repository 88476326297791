import React, { createContext, useCallback, useEffect, useState , useContext} from "react";
import { useSelector } from "react-redux";
import debounce from "lodash/debounce";
import Loader from "@zert-packages/components/shared/Loader/Loader";
import { handleSavePhrase } from "./API";
import { compareIfChanged, convertToJava } from "./utils/convertToSliteJS";
import PhraseEditor from "./components/PhraseEditor";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import { useParams } from "react-router-dom";



export const PhraseContext = createContext(null);

export function PhraseProvider({ children, versionId, selectedValue: initialSelectedValue }) {
  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);
  const [storedValue, setStoredValue] = useState(null);
  //    let timer;
  let isUpdating = false;
  const { formatTemplate } = useSelector((state) => state);
  const update = useCallback(
    debounce((func, _state) => {
      func(_state);
      isUpdating = false;
    }, 5000),
    [versionId]
  );

  useEffect(() => {
    //    if (initialState !== state && !isUpdating && !isInitiating) setState(initialState);
    if (!isUpdating) update(setValueMidleWare, storedValue);
  }, [storedValue]);

  const setValueMidleWare = async (selectedValue) => {
    try {
      isUpdating = true;
      await handleSavePhrase(
        versionId,
        selectedValue.map((element) => convertToJava(element, formatTemplate.textFormats))
      );
    } catch (e) {
    }
    return false;
  };

  const setValue = (value) => {
    setSelectedValue(value);
    if (value && selectedValue && compareIfChanged(value, selectedValue, formatTemplate.textFormats)) {
      setStoredValue(value);
    }
  };

  return (
    <PhraseContext.Provider value={{ setValue, selectedValue, formatTemplate }}>{children}</PhraseContext.Provider>
  );
}

function PhraseWrapper() {
  const { values, setValues } = useContext(PanelContext);
  const el = values?.parentBlock && {...values.parentBlock, elementInfo: values.parentBlock.info}
  const { versionId } = useParams();

  if (!el ||  +el.info.versionId !== +versionId) {
    return (
      <span>
        <Loader displayText={false} />
      </span>
    );
  }

  return (<div style={{marginTop: 5}}>
    <PhraseEditor
      el={el}
      vue={true}
      loadOne={true}
    />
  </div>);



}

export default PhraseWrapper;
