import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  wrapperList: {
    display: "grid",
    gridTemplateColumns: "11px 1fr",
    gridColumnGap: 2
  },
  listNumber: {
    display: "flex",
    alignItems: "center",
    fontSize: 14
  },
  wrapperBulletList: {
    fontSize: 30,
    position: 'relative',
    overflow: 'hidden'
  },
  bulletIcon: {
    display: 'flex',
    justifyContent: 'end',
  }
}));

const AddItemToList = ({ children, block, index, el }) => {
  const cl = useStyles({});
  const { formatTemplate } = useSelector((state) => state);
  const [listType, setListType] = useState("");


  const findTypeList = () => {
    if (el.settings && el.format) {
      if(el.format.identifier === "numbered-list" || el.format.identifier === "numbered-list"){
        return "numbered-list"
      }
      if(el.format.identifier === "bullet-list"){
        return "bullet-list"
      }
    }



    const findFormat = formatTemplate.blockFormats.find(el => el.identifier === block.format);

    if (findFormat ) {
      if (findFormat.style === "LOWER_ALPHA_LIST") {
        return "alphabetical";
      }
      if (findFormat.style === "NUMBERED_LIST") {
        return "numbered-list";
      }
      if (findFormat.style === "BULLET_LIST") {
        return "bullet-list";
      }
    }
    return "";
  };


  useEffect(() => {
    if (formatTemplate && block && el) {
      setListType(findTypeList());
    }
  }, [formatTemplate, block, el]);

  if (listType === "bullet-list") {
    return (<div className={cl.wrapperList} name={"allowСlick"}>
      <div className={cl.wrapperBulletList}>
        <div className={cl.bulletIcon}>.</div>
      </div>
      {children}
    </div>);
  }

  if (listType === "numbered-list") {
    const number = block?.formatSettings?.settings[0].value;
    return (<div className={cl.wrapperList} name={"allowСlick"}>
      <div style={{height: 'fit-content', width: 'max-content'}}>
        {number === "continue" ? "#" : number + index}.
      </div>
      {children}
    </div>);
  }

  if (listType === "alphabetical") {
    const value = block?.formatSettings?.settings[0].value;
    const items = block.formatSettings?.template.properties[0].items
    const res =  items.findIndex(el => el.value === value[0])
    return (<div className={cl.wrapperList} name={"allowСlick"}>
      <div  style={{height: 'fit-content'}}>
        {value
            ?  items[res + index]?.value
            : String.fromCharCode(65 + index).toLowerCase()}
      </div>
      {children}
    </div>);
  }

  return children;
};

export default AddItemToList;