import React, {useContext} from 'react'
import {makeStyles} from "@mui/styles";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import {BlockItemsContext} from "./AddToolbarDialog";
import TextFieldItem from "@zert-packages/components/TextFieldItem";

const useStyles = makeStyles((theme) => ({
    wrapperSoftware: {
        display: 'grid',
        rowGap: 5,
    },
    label: {
        color: '#B0C5CA',
    }
}))

const CreateSoftwareTexts = ({createBlock, setCreateBlock}) => {
    const cl = useStyles();
    const {block, setBlock} = useContext(BlockItemsContext)
    const strategyDropMenu = ['ST - Backend', 'ST - Frontend', 'APT - SofTware Texts']

    // createBlock={createBlock} setCreateBlock={setCreateBlock}


    const handleSoftwareTexts= (val, name) => {
        setCreateBlock(pre => ({...pre, [name]: val}))
    }


    return (<div className={cl.wrapperSoftware}>
        <div>
            <div>Strategy</div>
            <ExpanderItem
                valueProps={createBlock.softwareTextsStrategy}
                propsLabel={strategyDropMenu}
                setProps={handleSoftwareTexts}
                type={'softwareTextsStrategy'}
                height={'27.25px'}
            />
        </div>
        <div>
            <div>Description</div>
            <TextFieldItem
                values={createBlock.description}
                name={"description"}
                setValues={handleSoftwareTexts}
                multiline={true}
                row={3}
            />
        </div>
    </div>)
}

export default CreateSoftwareTexts