import React, { useEffect, useState } from 'react';
import ZertLoaderCenter from './ZertLoaderCenter';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';


interface ImageFromBlobParams {
  blob: Blob | null,
  loader?: React.ReactElement | null,
  errorIcon?: React.ReactElement,
  error?: boolean,
  alt?: string,
  [key: string]: unknown;
}

export default function ImageFromBlob(
  {
    blob,
    loader=null,
    errorIcon=<BrokenImageIcon />,
    error=false,
    alt="",
    ...imgProps }:
    ImageFromBlobParams) {
  const [imgSrc, setImgSrc] = useState<string | null>(null);

  useEffect(() => {
    if (blob) {
      const imgUrl = URL.createObjectURL(blob);
      setImgSrc(imgUrl);
    }
    return () => {
      if (imgSrc) {
        URL.revokeObjectURL(imgSrc);
        setImgSrc(null);
      }
    }
  }, [blob]);

  const altNode = loader || <ZertLoaderCenter children={null}/>;

  if (error) {
    return errorIcon;
  } else {
    return imgSrc ? <img src={imgSrc} {...imgProps} alt={alt}/> : altNode;
  }
}