import React, { Fragment } from 'react';
import {
  getBlockPos,
  getBlockPosMulti,
  calculateColorCoverage,
  getPositionByIdentifier
} from '@zert-packages/actions/overviewCoverage';
import { loadHistory } from '@zert-packages/actions/riskstructureActions';
import MenuItem from '@mui/material/MenuItem';
import { Select, FormControl, Button, Input } from "@mui/material";
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Switch from '@mui/material/Switch';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { PreviewImageDialog } from '@zert-packages/components/common/RiskAssesmentEdit/PreviewImageDialog';
import { FormattedMessage } from 'react-intl';
import { matrixImage } from '@zert-packages/actions/api';
import SlowTooltip from '../../SlowTooltip';
import './style.css'

class MatrixSquare extends React.Component {
  render() {
    if (this.props.selected && !this.props.isNotSignificantValue) {
      return (
        <div className="matrix-square" style={{ backgroundColor: this.props.value.color }}>
          <div
            style={{
              backgroundColor: 'black',
              width: '4px',
              paddingTop: '3px',
              paddingRight: '3px',
              height: '4px',
              borderRadius: '50%'
            }}
          />
        </div>
      );
    }
    if (this.props.history && !this.props.isNotSignificantValue) {
      return (
        <div className="matrix-square" style={{ backgroundColor: this.props.value.color, position: 'relative' }}>
          <span
            style={{
              color: 'grey',
              position: 'absolute',
              marginTop: '-3px',
              marginLeft: '-5px'
            }}
          >
            &#9650;
          </span>
        </div>
      );
    }

    return (
      <div
        className="matrix-square"
        style={{ backgroundColor: this.props.isNotSignificantValue ? '#ABC1C7' : this.props.value.color }}
      />
    );
  }
}

export default class MatrixInlineView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixValues: [],
      prevCell: null,
      hasSendPrevRequest: false,
      anchorEl: null,
      hasImage: false
    };
    this.checkBoxClickHandler = this.checkBoxClickHandler.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    this.onImgError = this.onImgError.bind(this);
    this.matrixHasImage = this.matrixHasImage.bind(this);
    this.getTextToDisplay = this.getTextToDisplay.bind(this);
  }

  clickMatrix(selection) {
    this.setState({
      selection
    });
    this.props.cell.selection.position = selection.position;
  }

  matrixHasImage = async () => {
    if (this.props.hasMatrixImage) {
      const hasMatrix = await this.props.hasMatrixImage(this.props.column.matrix.versionId);
      this.setState({ hasImage: hasMatrix });
    }
  };

  componentDidMount() {
    if (!this.state.hasSendPrevRequest && this.props.residual === false && this.props.riskrow.status.approved) {
      this.setState({ hasSendPrevRequest: true }, () => {
        loadHistory(this.props.riskrow.status.versionId, this.props.column.identifier).then((history) => {
          this.setState({ prevCell: history });
        });
      });
    }
    this.matrixHasImage();
  }

  componentDidUpdate(prevProps) {
    if (!this.state.hasSendPrevRequest && this.props.residual === false && this.props.riskrow.status.approved) {
      this.setState({ hasSendPrevRequest: true }, () => {
        loadHistory(this.props.riskrow.status.versionId, this.props.column.identifier).then((history) => {
          this.setState({ prevCell: history });
        });
      });
    }
  }

  onChangeSelect(e, index=0) {
    if (this.props.cell && this.props.cell.selection) {
      if (e.target.value !== -1 && this.props.cell) {
        this.props.riskrow.changed = true;
        this.props.cell.selection.position[index] = {
          index: parseInt(e.target.value),
          name: this.props.column.matrix.dimensions[index].identifier
        };
        this.setState({
          selection: { ...this.props.cell.selection }
        });
      } else {
        this.props.cell.selection.position = [];
      }
    }
  }

  onChange(e) {
    if (this.props.cell && this.props.cell.selection) {
      if (e.target.value !== -1 && this.props.cell) {
        this.props.riskrow.changed = true;
        this.props.cell.selection.position[parseInt(e.currentTarget.getAttribute('data-id'))] = {
          index: parseInt(e.target.value),
          name: this.props.column.matrix.dimensions[parseInt(e.currentTarget.getAttribute('data-id'))].identifier
        };
        this.setState({
          selection: { ...this.props.cell.selection }
        });
      } else {
        this.props.cell.selection.position = [];
      }
    }
  }

  checkBoxClickHandler(e) {
    this.props.cell.notSignificantValue = !this.props.cell.notSignificantValue;
    this.forceUpdate();
  }

  handlePopoverOpen(event) {
    if (this.state.hasImage) {
      this.setState({ anchorEl: event.currentTarget, open: true });
    }
  }

  handlePopoverClose() {
    this.setState({ anchorEl: null, open: false });
  }

  onImgError = () => {
    this.setState({ anchorEl: null, open: false });
  };

  getTextToDisplay = (value) => {
    if (value.text.defaultLabel.length > 50 && value.abbreviation.defaultLabel) {
      return value.abbreviation.defaultLabel;
    } else {
      return value.text.defaultLabel
    }
  }

  render() {
    if (this.props.column.matrix.dimensions.length === 2) {
      const coverage = calculateColorCoverage(this.props.column.matrix);
      const dimensionY = this.props.column.matrix.dimensions[1].identifier;
      var dimensionX = this.props.column.matrix.dimensions[0].identifier;
      var isRed = false;
      var isYellow = false;
      let isGreen = false;
      let stateselectorY = 1;
      var stateselectorX = 0;

      if (
        this.props.cell != null &&
        this.props.cell.selection != null &&
        this.props.cell.selection != undefined &&
        this.props.cell.selection.position.length >= 2
      ) {
        stateselectorY = dimensionY === this.props.cell.selection.position[1].name ? 0 : 1;
        stateselectorX = dimensionX === this.props.cell.selection.position[0].name ? 1 : 0;
      }
      const rows = [];
      for (let j = 0; j < this.props.column.matrix.dimensions[0].values.length; j++) {
        const columns = [];
        for (let i = 0; i < this.props.column.matrix.dimensions[1].values.length; i++) {
          if (
            this.props.cell &&
            this.props.cell.selection &&
            this.props.cell.selection.position.length >= 2 &&
            this.props.cell.selection.position[stateselectorY].index === j &&
            this.props.cell.selection.position[stateselectorX].index === i
          ) {
            var value = this.props.column.matrix.valueMaps[0].values[getBlockPos(this.props.column.matrix, j, i)];
            isRed = value.color === coverage.redColor;
            isYellow = !isRed && !(value.color === coverage.greenColor);
            isGreen = !isRed && !isYellow && value.color === coverage.greenColor;

            columns[i] = (
              <MatrixSquare
                key={`matrixId_${j}_${i}`}
                isNotSignificantValue={this.props.cell.notSignificantValue}
                selected="true"
                value={value}
              />
            );
          } else if (
            this.state.prevCell &&
            this.state.prevCell.selection.position.length >= 2 &&
            this.state.prevCell.selection.position[stateselectorY].index === j &&
            this.state.prevCell.selection.position[stateselectorX].index === i
          ) {
            var value = this.props.column.matrix.valueMaps[0].values[getBlockPos(this.props.column.matrix, j, i)];
            columns[i] = (
              <MatrixSquare
                key={`matrixId_${j}_${i}`}
                isNotSignificantValue={this.props.cell.notSignificantValue}
                history="true"
                value={value}
              />
            );
          } else {
            columns[i] = (
              <MatrixSquare
                key={`matrixId_${j}_${i}`}
                isNotSignificantValue={this.props.cell ? this.props.cell.notSignificantValue : false}
                value={this.props.column.matrix.valueMaps[0].values[getBlockPos(this.props.column.matrix, j, i)]}
              />
            );
          }
        }
        rows[j] = <div className="matrix-row">{columns}</div>;
      }
      isRed = isRed && !this.props.cell.notSignificantValue;
      isYellow = isYellow && !this.props.cell.notSignificantValue;
      isGreen = isGreen && !this.props.cell.notSignificantValue;
      const accessToken = window.localStorage.getItem('accessToken');

      return (
        <>
          <div
            className="matrix-area"
            key={
              `${this.props.riskrow.status.versionId}matrixCell${this.props.column.indentifier}residual${this.state.prevCell}`
                ? 'prevcell'
                : 'oryg'
            }
            onClick={(e) => {
              if (this.props.onMatrixClick) this.props.onMatrixClick(e);
            }}
            onMouseEnter={this.handlePopoverOpen}
            onMouseLeave={this.handlePopoverClose}
            style={{
              borderColor: isGreen || isYellow || isRed ? value.color : '#E1EAEE'
            }}
          >
            {rows}
            {this.state.open && (
              <Popover
                id={`mouse-over-popover${this.props.column.matrix.versionId}`}
                sx={{
                  pointerEvents: 'none'
                }}
                open={this.state.open}
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                onClose={this.handlePopoverClose}
                disableRestoreFocus
              >
                <img
                  src={`${matrixImage + this.props.column.matrix.versionId}?jwtToken=${accessToken}`}
                  onError={this.onImgError}
                />
              </Popover>
            )}
          </div>
          {this.props.showNotSignificant && (
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
              <Switch
                tabIndex="-1"
                size="small"
                checked={this.props.cell ? !this.props.cell.notSignificantValue : false}
                onChange={this.checkBoxClickHandler}
                color="primary"
              />
            </div>
          )}
        </>
      );
    }

    if (this.props.column.matrix.dimensions.length === 1) {
      var stateselectorX = 0;
      var index = -1;

      var value = null;
      if (this.props.cell && this.props.cell.selection.position.length >= 1) {
        index = this.props.cell.selection.position[stateselectorX].index;
        value = this.props.column.matrix.dimensions[0].values[this.props.cell.selection.position[stateselectorX].index];
      }
      if (!this.props.isEditor) {
        return (
          <div
            style={{
              backgroundColor: value && value.color !== undefined ? value.color : 'inherit'
            }}
          >
            {value != null ? value.text.defaultLabel : '-'}
          </div>
        );
      }
      return (
        <>
          {' '}
          <FormControl fullWidth>
            {' '}
            <Select
              value={index}
              className="form-control animated"
              autoWidth
              onChange={(e) => {
                this.onChangeSelect(e);
              }}
              input={<Input disableUnderline={this.props.underlinesDisabled} />}
            >
              <MenuItem value={-1} key={-1} data-id={0}>
                -
              </MenuItem>
              {this.props.column.matrix.dimensions[0].values.map((value, index) => {
                return (
                  <MenuItem
                    style={{ backgroundColor: value && value.color !== undefined ? value.color : 'inherit' }}
                    value={index}
                    key={index}
                    data-id={0}
                  >
                    <SlowTooltip title={value.text.defaultLabel}>
                      <Typography variant="body1" noWrap>
                        {this.getTextToDisplay(value)}
                      </Typography>
                    </SlowTooltip>
                  </MenuItem>
                );
              })}
            </Select>
            <SlowTooltip
              title={
                this.props.column.matrix.dimensions[0].values.find((value, mindex) => index === mindex) ?
                  this.getTextToDisplay(this.props.column.matrix.dimensions[0].values.find((value, mindex) => index === mindex)) : ''}
            >
              <Typography variant="body1">
                {this.props.column.matrix.dimensions[0].values.find((value, mindex) => index === mindex) ?
                  this.getTextToDisplay(this.props.column.matrix.dimensions[0].values.find((value, mindex) => index === mindex)) : ''}
              </Typography>
            </SlowTooltip>
          </FormControl>
          <Button
            color="primary"
            variant="outlined"
            style={{ margin: '5px' }}
            onClick={(e) => {
              renderInExceptionRoot(PreviewImageDialog, {
                id: this.props.column.matrix.versionId
              });
            }}
          >
            <FormattedMessage id="editriskrow.showMatrixImage" defaultMessage="Show Image" />
          </Button>
        </>
      );
    }

    if (this.props.column.matrix.dimensions.length > 2) {
      const smallerFontClass = this.props.smallerManyDimensionsMatrix ? "smaller-font-size" : "";
      const combos = [];
      let map = this.props.column.matrix.defaultValueMap;
      if (this.props.column.matrix.valueMaps != null) {
        const mapNew = this.props.column.matrix.valueMaps.find((mapmin) => {
          return mapmin.filter == null;
        });
        if (mapNew != null) {
          map = mapNew;
        }
      }

      if (this.props.isEditor) {
        for (let i = 0; i < this.props.column.matrix.dimensions.length; i++) {
          var value = null;
          var index = -1;
          const cellIndex = i;
          const pos = getPositionByIdentifier(
            this.props.column.matrix.dimensions[i].identifier,
            this.props.cell.selection.position
          );
          if (pos) {
            index = pos.index;
            value = pos.value;
          } else if (
            value &&
            this.props.cell &&
            this.props.cell.selection &&
            this.props.cell.selection.position.length > i
          ) {
            if (this.props.cell.selection.position[i]) {
              index = this.props.cell.selection.position[i].index;
              value = this.props.column.matrix.dimensions[i].values[index];
            }
          }

          if (this.props.isEditor) {
            var id = i;
            combos.push(
              <Fragment key={`fragmentFor${id}`}>
                <div className={smallerFontClass} style={this.props.horizontalManyDimensionsMatrix ? {marginRight: '10px'} : {}}>
                  {this.props.column.matrix.dimensions[i].name.defaultLabel}
                </div>
                <Select
                  style={this.props.horizontalManyDimensionsMatrix ? {marginRight: '10px'} : {}}
                  value={index}
                  key={id}
                  className={`animated${this.props.underlinesDisabled ? "" : " form-control"}`}
                onChange={(e) => {
                    this.onChangeSelect(e, cellIndex);
                  }}
                  renderValue={(selected) => (
                    <Typography variant="body1" noWrap className={`fade-out ${smallerFontClass}`}>
                      {this.props.column.matrix.dimensions[i].values[selected]?.text.defaultLabel || '-'}
                    </Typography>
                  )}
                  input={<Input disableUnderline={this.props.underlinesDisabled} />}
                >
                  <MenuItem value={-1} key={-1} data-id={id} className={smallerFontClass}>
                    -
                  </MenuItem>
                  {this.props.column.matrix.dimensions[i].values.map((value, key) => {
                    return (
                      <MenuItem value={key} key={key} data-id={id}>
                        <SlowTooltip title={value.text.defaultLabel}>
                          <Typography variant="body1" noWrap className={smallerFontClass}>
                            {value.text.defaultLabel}
                          </Typography>
                        </SlowTooltip>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Fragment>
            );
          }
        }

        var value =
          this.props.cell && this.props.cell.selection && this.props.cell.selection.position.length >= 2
            ? map.values[getBlockPosMulti(this.props.column.matrix, this.props.cell.selection.position)]
            : null;
        combos.push(
          <div className={smallerFontClass}
            style={{
              backgroundColor: value && value.color !== undefined ? value.color : 'inherit'
            }}
          >
            {value != null ? value.abbreviation.defaultLabel : '-'}
          </div>
        );
      } else {
        var value =
          this.props.cell !== undefined && this.props.cell.selection.position.length >= 2
            ? map.values[getBlockPosMulti(this.props.column.matrix, this.props.cell.selection.position)]
            : null;
        combos.push(
          <div className={smallerFontClass}
            style={{
              whiteSpace: 'nowrap',
              backgroundColor: value && value.color !== undefined ? value.color : 'inherit'
            }}
          >
            {value != null ? value.abbreviation.defaultLabel : '-'}
          </div>
        );
      }

      if (this.props.horizontalManyDimensionsMatrix) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {combos}
            <div style={{width: '80px'}}></div>
            <Button
              color="primary"
              variant="outlined"
              style={{ margin: '5px' }}
              onClick={(e) => {
                renderInExceptionRoot(PreviewImageDialog, {
                  id: this.props.column.matrix.versionId
                });
              }}
            >
              <FormattedMessage id="editriskrow.showMatrixImage" defaultMessage="Show Image" />
            </Button>
          </div>

        )
      }

      return (
        <>
          {combos}
          <Button
            color="primary"
            variant="outlined"
            style={{ margin: '5px' }}
            onClick={(e) => {
              renderInExceptionRoot(PreviewImageDialog, {
                id: this.props.column.matrix.versionId
              });
            }}
          >
            <FormattedMessage id="editriskrow.showMatrixImage" defaultMessage="Show Image" />
          </Button>
        </>
      );
    }
  }
}
