import React, { useRef, useState, memo, useCallback, useEffect } from 'react';
import { FormControl, Popper, Input, Grid, Avatar, InputLabel, Fade, CircularProgress, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '25px',
    height: '25px'
  },
  option: {
    cursor: 'pointer',
    padding: ' 0 10px',
    marginTop: '2px',
    zIndex: '2000',
    transition: 'color .5 ease',
    '& :hover': {
      color: theme.palette.primary.main
    }
  },
  selectedOption: {
    color: theme.palette.primary.main
  },
  inputLabel: {
    order: '-1',
    paddingTop: '5px',
    transition: 'all 0.1s ease-in',
    transform: 'translateY(20px)',
    pointerEvents: 'none'
  },
  inputFocused: {
    transform: 'translateY(-2px) scale(0.8)'
  },
  inputFilled: {
    transform: 'translateY(-2px) scale(0.8)'
  }
}));

let amountOfUsers = 0;

let searchValue = null;

const UserSuggestBox = memo(
  ({
     hasUser,
     autoFocus,
     initialValue = '',
     onChange,
     label,
     getUsers,
     getUserId,
     getUserLabel,
     symbolsToStart = 3,
     disabled,
     valueClean
   }) => {
    const cl = useStyles();
    const inputRef = useRef(null);
    const [value, setValue] = useState(initialValue);
    const [users, setUsers] = useState([]);
    const [selectedUserIndex, setSelectedUserIndex] = useState(-1);

    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenOptions = (event) => {
      setAnchorEl(inputRef.current);
      setIsOpen(true);
    };

    const handleCloseOptions = () => {
      setIsOpen(false);
      setAnchorEl(null);
    };

    useEffect(() => {
      if (initialValue && initialValue.length > 0) {
      }
    }, [initialValue]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchUsers = useCallback(
      _.debounce((currentValue) => {
        searchValue = currentValue;
        setIsLoading(false);
        if (currentValue.length >= symbolsToStart) {
          setIsLoading(true);
          getUsers(currentValue).then(setUsersList(currentValue));
        } else if (users.length) {
          setUsers([]);
        }
      }, 500),
      []
    );

    const setUsersList = (currentValue) => (newUsers) => {
      if (currentValue == searchValue) {
        setUsers(newUsers);
        amountOfUsers = newUsers.length;
        if (amountOfUsers > 0) {
          handleOpenOptions();
          setSelectedUserIndex(0);
        }
        setIsLoading(false);
      }
    };

    const handleChange = async (event) => {
      const currentValue = event.target.value;
      if (currentValue.length === 0) {
        onChange(null);
        setValue(currentValue);
        return;
      }
      fetchUsers(currentValue);
      setValue(currentValue);
    };

    const onSelect = (user) => {
      setUsers([]);
      amountOfUsers = 0;
      setSelectedUserIndex(-1);
      if (onChange) onChange(user);
      setValue(getUserLabel(user));
      handleCloseOptions();
    };
    const onKeyDown = (e) => {
      const keyUp = 38;
      const keyDown = 40;
      if (e.key === 'Escape') return handleCloseOptions();
      if (e.key === 'Enter' && selectedUserIndex !== -1) return onSelect(users[selectedUserIndex]);
      if (e.keyCode === keyUp && selectedUserIndex > 0) {
        return setSelectedUserIndex((prev) => prev - 1);
      }
      if (e.keyCode === keyDown && selectedUserIndex < amountOfUsers - 1) {
        return setSelectedUserIndex((prev) => prev + 1);
      }
    };
    const handleOnBlur = () => setTimeout(() => handleCloseOptions(), 150);
    return (
      <FormControl disabled={disabled} fullWidth onBlur={handleOnBlur}>
        <InputLabel
          className={value !== undefined && value !== '' ? `${cl.inputLabel} ${cl.inputFilled}` : cl.inputLabel}
          classes={{ focused: cl.inputFocused }}
        >
          {label}
        </InputLabel>
        <Input
          autoFocus={autoFocus}
          onFocus={handleOpenOptions}
          ref={inputRef}
          size="small"
          value={value || ''}
          onKeyDown={onKeyDown}
          onChange={handleChange}
          endAdornment={
            isLoading ? <CircularProgress style={{ marginRight: '0.5rem' }} color="inherit" size={20} /> : null
          }
        />
        <OptionsContainer canShow={isOpen && !isLoading && !!users.length} anchorEl={anchorEl}>
          {users.map((user, index) => (
            <Option
              key={getUserId(user)}
              user={user}
              selected={selectedUserIndex === index}
              onSelect={onSelect}
              cl={cl}
              getUserLabel={getUserLabel}
            />
          ))}
        </OptionsContainer>
      </FormControl>
    );
  },
  selectiveMemoComparison(
    (p) => p.initialValue,
    (p) => p.disabled,
    (p) => p.valueClean
  )
);

export default UserSuggestBox;

function OptionsContainer({ anchorEl, children, canShow }) {
  return (
    <Popper open={canShow} anchorEl={anchorEl} style={{ zIndex: 2000 }}>
      <Fade in={canShow}>
        <Paper
          style={{
            width: anchorEl ? anchorEl.clientWidth : undefined,
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '300px',
            padding: '10px',
            zIndex: 2000
          }}
        >
          {children}
        </Paper>
      </Fade>
    </Popper>
  );
}

function Option({ user, getUserLabel, onSelect, cl, selected }) {
  return (
    <div className={cl.option}>
      <Grid container spacing={1} onClick={() => onSelect(user)}>
        <Grid item>
          {user.guid ? (
            <Avatar className={cl.avatar} sizes="20px" src={`/zert/admin/getUserPhoto/${user.tenantId}/${user.guid}`}>
              {getUserLabel(user)[0]}
            </Avatar>
          ) : (
            <Avatar className={cl.avatar} sizes="20px">
              {getUserLabel(user)[0]}
            </Avatar>
          )}
        </Grid>
        <Grid className={cn({ [cl.selectedOption]: selected })} item xs>
          {getUserLabel(user)}
        </Grid>
      </Grid>
    </div>
  );
}
