import React, { useState } from 'react';
import MetadataPane from '@zert-packages/components/InfoElement/Metadata/MetadataPane';
import { SparePartContext } from '../../store/SparePartContext';
import Structure from './Structure';
import PartOf from '../../../LayoutPlugin/components/PartOf';

function SideMenu() {
  const [expandedPanel, setExpandedPanel] = useState({
    expandedPanelOne: true,
    expandedPanelTwo: false,
    expandedPanelThree: false
  });
  return (
    <div style={{ width: '300px', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Structure expanded={expandedPanel} setExpanded={setExpandedPanel} context={SparePartContext} />
      <PartOf expanded={expandedPanel} setExpanded={setExpandedPanel} />
      <MetadataPane expanded={expandedPanel} setExpanded={setExpandedPanel} context={SparePartContext} />
    </div>
  );
}

export default SideMenu;
