import AppEnv from '@zert-packages/react/interfaces/AppEnv';
import React from 'react';

const envContext = React.createContext({
  env: undefined,
  setEnv: (env) => {}
});

export function EnvProvider({ children }) {
  const [env, setEnv] = React.useState<AppEnv | undefined>();

  return <envContext.Provider value={{ env, setEnv }}>{children}</envContext.Provider>;
}

export const useEnv = () => {
  const { env, setEnv } = React.useContext(envContext);
  return { env, setEnv };
};

export function GetEnv(key: string): undefined | string {
  const { env } = useEnv();

  if (env && env[key]) {
    return env[key];
  }
}
