import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import DuplicationDefaultDialog from '@zert-packages/components/ElementTile/DuplicationDialogs/components/DuplicationDefaultDialog';
import { handlePostTextAsParam, updateWorkerStatus} from "@zert-packages/actions/api";
import {showSnackbarError, showSnackbarInfo} from "@zert-packages/components/Snackbars";


import {createInProgressSnack} from "@zert-packages/components/common/snackBar";


const useStyles = makeStyles({
  root: {}
});

function DuplicateProject({ unmountComponent, onFinish, element, versionId }) {
  const cl = useStyles();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(`${get(element, 'info.name', '')} - duplication`);
  const onDuplicate = () => {
    setLoading(true);
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="Project.DuplicateProject" defaultMessage="Duplicating project..."/>


    );

    handlePostTextAsParam(`/project/duplicateProject` + `/${versionId}`, name)
        .then((result) => {
          return updateWorkerStatus(result, {snackbar}, true);
        })

        .then((info) => {
          snackbar.closeSnackbar(generatingSnackbar);
          setLoading(false)
          showSnackbarInfo(snackbar, <FormattedMessage id="Project.DuplicateProject.Successful" defaultMessage="Duplicating successful"/>);
            unmountComponent();
          onFinish(info);

        })

        .catch((error) => {
            setLoading(false)
          snackbar.closeSnackbar(generatingSnackbar);
            unmountComponent();
          showSnackbarError(
              snackbar,
              error
          );
        });



  };
  const onDuplicateDisabled = name.trim().length === 0;
  return (
    <DuplicationDefaultDialog
      unmountComponent={unmountComponent}
      onDuplicate={onDuplicate}
      onDuplicateDisabled={onDuplicateDisabled}
      loading={loading}
      customDialogContent={
        <div className={cl.root}>
          <TextField
            autoFocus={!loading}
            fullWidth
            label={<FormattedMessage id="DuplicationDialogs.Project.NameField" defaultMessage="Name" />}
            value={name}
            disabled={loading}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      }
    />
  );
}

export default DuplicateProject;
