export const coverData = {
  class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientCoverBlockSettingsTemplate',
  identifier: 'Cover',
  label: 'Cover',
  pageWidth: {
    value: 148,
    unit: 'MILLIMETER'
  },
  pageHeight: {
    value: 210,
    unit: 'MILLIMETER'
  },
  cropOffsetTop: {
    value: 20,
    unit: 'MILLIMETER'
  },
  cropOffsetBottom: {
    value: 20,
    unit: 'MILLIMETER'
  },
  cropOffsetLeft: {
    value: 20,
    unit: 'MILLIMETER'
  },
  cropOffsetRight: {
    value: 20,
    unit: 'MILLIMETER'
  },
  cropMarksVisible: false,
  bleedTop: {
    value: 20,
    unit: 'MILLIMETER'
  },
  bleedBottom: {
    value: 20,
    unit: 'MILLIMETER'
  },
  bleedLeft: {
    value: 20,
    unit: 'MILLIMETER'
  },
  bleedRight: {
    value: 20,
    unit: 'MILLIMETER'
  },
  front: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientCoverBlockSettingsTemplate$ClientExtendedCoverPage',
    name: 'front',
    visible: false,
    content: '',
    insertBlankPage: false
  },
  back: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientCoverBlockSettingsTemplate$ClientExtendedCoverPage',
    name: 'back',
    visible: false,
    content: '',
    insertBlankPage: false
  },
  backstrip: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientCoverBlockSettingsTemplate$ClientExtendedCoverPage',
    name: 'backstrip',
    visible: false,
    content: '',
    insertBlankPage: false
  },
  separatedBlock: true,
  orientation: 'Portrait',
  format: 'A5'
};
