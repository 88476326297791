import React from 'react';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PaletteIcon from '@mui/icons-material/Palette';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import DialogColors from 'clm/src/plugins/StylesheetPlugin/dialog/DialogColors';

const useStyles = makeStyles((theme) => ({
  wrapperInput: {
    display: 'flex',
    gridTemplateColumns: '130px 30px 1fr 25px',
    gridColumnGap: 10,
    alignItems: 'center'
  },
  label: {
    fontWeight: 700,
    fontSize: '1rem'
  },
  iconsWrapper: {
    cursor: 'pointer'
  },
  bgBlockSelected: {
    height: 26.63,
    width: '100%',
    border: '1px solid #000000',
    borderRadius: 5
  },
  noteActiveInputWrapper: {
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  notesInput: {
    margin: 0,
    width: '100%'
  }
}));

function SelectedColor({ value = '', label = '', onChange, type, style, disabled }) {
  const cl = useStyles();

  const handleOpenDialog = (type) => {
    renderInExceptionRoot(DialogColors, {
      color: value,
      title: 'Select Color',
      setText: onChange
    });
  };

  const handleChangeNote = (event) => {
    const val = event.target.value;
    if (onChange) {
      onChange(val, type);
    }
  };

  return (
    <div className={cl.wrapperInput} style={{ gridTemplateColumns: style ? style.grid : '' }}>
      <div className={cl.label}>{label}</div>
      <div className={cl.bgBlockSelected} style={{ background: value }} />
      <div className={cl.noteActiveInputWrapper}>
        <TextField
          value={value}
          className={cl.notesInput}
          variant="outlined"
          onChange={handleChangeNote}
          disabled={disabled}
        />
      </div>
      <span className={cl.iconsWrapper}>
        <PaletteIcon
          onClick={() => !disabled && handleOpenDialog && handleOpenDialog(type)}
          style={{ color: 'gray' }}
        />
      </span>
    </div>
  );
}

export default SelectedColor;
