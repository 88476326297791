import React, { useEffect, useState } from "react";
import { Dialog, FormControlLabel, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import convertLength from "../../../utils/convertLength";
import { Checkbox } from "antd";
import Button from "@mui/material/Button";
import styleDialogHandleImg from "./styleDialogHandleImg";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { adobeJsonEditor } from "../../../utils/main";
import { compositeDecorator } from "../../../utils/decorator";


const DialogHandleImg = ({ data, values, setValues }) => {


  const cl = styleDialogHandleImg();
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState({
    width: "0",
    height: "0",
    selectedLength: "px",
    verticalAlign: "TOP",
    selectedVertical: "%",
    proportions: false,
    vertical: "0"
  });
  const dropMenuData = [{ name: "px" }, { name: "pt" }, { name: "mm" }, { name: "cm" }, { name: "in" }, { name: "%" }];
  const dropVertical = [{ name: "Top" }, { name: "Middle" }, { name: "Bottom" }, { name: "Relative" }];
  const disableVertical = value.verticalAlign !== 'Relative'

  //{
  //     "src": 2393192,
  //     "width": 25,+
  //     "height": 20,+
  //     "lengthUnit": "PIXELS",
  //     "verticalAlign": "MIDDLE",+
  //     "verticalAlignDistance": 0,
  //     "verticalAlignUnit": "PERCENT",
  //     "keepRatio": false
  // }

  const adobeLength = (name) => {
    if (name === "px") {
      return "PIXELS";
    }
    if (name === "%") {
      return "PERCENT";
    }
    return name;
  };
  const adobeDataLength = (name) => {
    if (name === "PIXELS") {
      return "px";
    }
    if (name === "PERCENT") {
      return "%";
    }
  }

    const handleClose = () => {
      setOpen(false);
    };

    const setSelectedDropMenu = (val, name) => {
      if (name === "selectedLength") {
        const resWidth = convertLength(val, value.selectedLength, value.width);
        const resHeight = convertLength(val, value.selectedLength, value.height);
        return setValue(pre => ({ ...pre, width: resWidth, height: resHeight, selectedLength: val.name }));
      }
      if(name === 'verticalAlign'){
        return   setValue(pre => ({ ...pre, [name] : val.name,  vertical: "0"  }))
      }
      const resVertical = convertLength(val, value.vertical, value.width);
      setValue(pre => ({ ...pre, vertical: resVertical, selectedVertical: val.name }));
    };

    const handleCheckbox = (e) => {
      const val = e.target.checked;
      setValue(pre => ({ ...pre, "proportions": val }));
    };

    const handleValue = (e) => {
      const val = e.target.value;
      const name = e.target.name;
      setValue(pre => ({ ...pre, [name]: val }));
    };


    const handleSave = () => {
      const editorState = values.editorState.getCurrentContent();
      const content = convertToRaw(editorState);

      content.blocks = content.blocks.map(el => ({ ...el, key: Math.random().toFixed(5) }));

      content.entityMap = Object.keys(content.entityMap).map(key => {
        const el = content.entityMap[key];
        if (content.entityMap[key].data.src === data.src) {
          return {
            ...el,
            data: {
              ...el.data,
              width: +value.width,
              height: +value.height,
              lengthUnit: adobeLength(value.selectedLength),
              verticalAlign: value.verticalAlign,
              verticalAlignDistance: +value.vertical,
              verticalAlignUnit: adobeLength(value.selectedVertical),
              keepRatio: value.proportions
            }
          };
        }
        return el;
      });
      adobeJsonEditor(content,
        "editorState", setValues, convertFromRaw, EditorState, compositeDecorator, true);
      handleClose();
    };

    const adobeHeight = (width) => {
      const currentWidth = 20;
      const currentHeight = 10 / 0.9;
      const result = ((currentHeight / currentWidth) * (width)).toFixed(3);
      setValue(pre => ({ ...pre, height: result }));
    };

    const adobeWidth = (height) => {
      const currentWidth = 20 / 1.1;
      const currentHeight = 10;
      const result = ((currentWidth / currentHeight) * (height)).toFixed(3);
      setValue(pre => ({ ...pre, width: result }));
    };

    useEffect(() => {
      if (value.proportions) {
        adobeHeight(value.width);
      }
    }, [value.proportions]);


    const adobeWidthHeight = (el) => {
      const res = el + ''
      return res.replace('%', '')
    }


    useEffect(() => {
      if (data) {
        setValue(pre => ({
          ...pre,
          width: +adobeWidthHeight(data.width),
          height: +adobeWidthHeight(data.height),
          selectedLength: data.lengthUnit
            ? adobeDataLength(data.lengthUnit)
            : pre.selectedLength,
          verticalAlign: data.verticalAlign || pre.verticalAlign,
          vertical: data.verticalAlignDistance || pre.vertical,
          selectedVertical: data.verticalAlignUnit
            ? adobeDataLength(data.verticalAlignUnit)
            : pre.selectedVertical,
          proportions: data.keepRatio || pre.proportions,
        }));
      }
    }, []);



    return (<Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paperScrollPaper: cl.root
      }}
    >
      <div className={cl.wrapperTitle}>
        <span className={cl.title}>Image...</span>
        <CloseIcon onClick={handleClose} />
      </div>
      <div className={cl.wrapper}>

        <div className={cl.wrapperLengthInput}>
          <div className={cl.containerInput}>
            <div className={cl.wrapperInput}>
              <div className={cl.inputTitle}>Width:</div>
              <TextField
                onBlur={(e) => value.proportions && adobeHeight(value.width)}
                value={value.width}
                type={"number"}
                name={"width"}
                className={cl.notesInput}
                variant="outlined"
                onChange={(e) => handleValue(e)}
              />
            </div>
            <div className={cl.wrapperInput}>
              <div className={cl.inputTitle}>Height:</div>
              <TextField
                onBlur={(e) => value.proportions && adobeWidth(value.height)}
                value={value.height}
                type={"number"}
                name={"height"}
                className={cl.notesInput}
                variant="outlined"
                onChange={(e) => handleValue(e)}
              />
            </div>
          </div>
          <ExpanderItem
            valueProps={value.selectedLength}
            propsLabel={dropMenuData}
            setProps={setSelectedDropMenu}
            small
            type={"selectedLength"}
            // styleDropMenu={styleDropMenu}
          />
        </div>

        <div className={cl.wrapperInput} style={{ maxWidth: 319 }}>
          <div></div>
          <FormControlLabel
            className={cl.formRoom}
            label="Keep proportions"
            control={
              <Checkbox
                checked={value.proportions}
                onChange={handleCheckbox}
              />}
          />
        </div>
        <div className={cl.wrapperLengthInput}>
          <div className={cl.wrapperVertical}>
            <div className={cl.inputTitle}>Vertical:</div>
            <ExpanderItem
              valueProps={value.verticalAlign}
              propsLabel={dropVertical}
              setProps={setSelectedDropMenu}
              small
              type={"verticalAlign"}
            />
            <TextField
              disabled={disableVertical}
              value={value.vertical}
              type={"number"}
              name={"vertical"}
              className={cl.notesInput}
              variant="outlined"
              onChange={(e) => handleValue(e)}
            />
          </div>
          <ExpanderItem
            valueProps={value.selectedVertical}
            propsLabel={dropMenuData}
            setProps={setSelectedDropMenu}
            small
            type={"selectedVertical"}
            disabled={disableVertical}
            // styleDropMenu={styleDropMenu}
          />
        </div>
        <div className={cl.rowButton}>
          <Button className={cl.button}>
            Open image
          </Button>
          <div className={cl.wrapperButton}>
            <Button className={cl.button} onClick={handleSave}>
              OK
            </Button>
            <Button className={cl.button} onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>);
  };

  export default DialogHandleImg
