import Dialog from '@mui/material/Dialog';
import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ChoiceView from '../Views/ChoiceView';
import TextView from '../Views/TextView';
import NumberView from '../Views/NumberView';
import DateView from '../Views/DateView';
import HiddenView from '../Views/HiddenView';
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";


const prefixSettingType = 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$';

const settingType = ['Text', 'Number', 'Choice', 'Date', 'Hidden'];

const labelObject = [
  {
    defaultLabel: '',
    locales: ['en', 'sv'],
    translations: ['', '']
  }
];


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 482,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700
  },
}));


const emptyBody = [
  {
    class: 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text',
    identifier: '',
    labelObject,
    label: '',
    translatable: false
  },
  {
    class: 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Choice',
    allowMultiselect: false,
    items: [],
    identifier: '',
    labelObject,
    label: ''
  },
  {
    class: 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Number',
    identifier: '',
    min: 1,
    max: 1000,
    value: 1,
    labelObject,
    label: '',
    translatable: false
  },
  {
    class: 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Date',
    identifier: '',
    labelObject,
    label: '',
    isTimeContained: false
  },
  {
    class: 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Hidden',
    identifier: '',
    value: ''
  }
];
function SettingDialog({ handleClose, successHandler, el }) {
  const cl = useStyles();
  const [data, setData] = useState(el === null ? emptyBody[0] : el);

  const getTypesNameValue = (el) => {
    const data = el.split('$');
    return data[1];
  };

  const onTypeChange = (value) => {
    const chosenData = emptyBody.find((item) => item.class.includes(value));
    chosenData.identifier = data.identifier;
    if (value !== 'Hidden') {
      if (el && el.class.includes(value)) {
        chosenData.label = el.label;
        chosenData.labelObject = el.labelObject;
        if (value === 'Choice') {
          chosenData.items = el.items;
          chosenData.allowMultiselect = el.allowMultiselect;
        } else if (value === 'Number') {
          chosenData.min = el.min;
          chosenData.max = el.max;
          chosenData.value = el.value;
        } else if (value === 'Date') {
          chosenData.isTimeContained = el.isTimeContained;
        } else if (value === 'Text') {
          chosenData.translatable = el.translatable;
        }
      } else {
        chosenData.label = data.label;
        chosenData.labelObject = data.labelObject ? data.labelObject : labelObject;
      }
    } else if (el && el.class.includes(value)) {
      chosenData.value = el.value;
    }
    setData(chosenData);
  };

  const onTextFieldChange = (event) => {
    event.persist();
    setData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const onSuccessButtonHandler = () => {
    successHandler(data, el === null);
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open  classes={{ paperScrollPaper: cl.root }}>
      <div className={cl.wrapperTitle}>
        <span className={cl.title}>Add Setting</span>
        <CloseIcon onClick={handleClose} />
      </div>

      <DialogContent style={{ height: '40rem', width: '40rem', padding: 4 }}>
        <Autocomplete
          size="small"
          disablePortal
          fullwidth
          onChange={(event, value) => onTypeChange(value)}
          value={getTypesNameValue(data.class)}
          id="combo-box-demo"
          options={settingType}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Type:" variant="outlined" />}
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Identifier:"
          onChange={onTextFieldChange}
          value={data.identifier}
          name="identifier"
          size="small"
        />

        {getTypesNameValue(data.class) === 'Choice' && <ChoiceView data={data} setData={setData} />}

        {getTypesNameValue(data.class) === 'Text' && <TextView data={data} setData={setData} />}

        {getTypesNameValue(data.class) === 'Number' && <NumberView data={data} setData={setData} />}

        {getTypesNameValue(data.class) === 'Date' && <DateView data={data} setData={setData} />}

        {getTypesNameValue(data.class) === 'Hidden' && <HiddenView data={data} setData={setData} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onSuccessButtonHandler} variant="contained" color="primary">
          OK
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SettingDialog;
