import React, { Fragment, Component } from 'react';
import { ICONS } from '@zert-packages/icons/SvgIcon/SvgIconConstant';
import { uploadBasis } from '@zert-packages/actions/deviationActions';
import { connect } from 'react-redux';
import ImageRow from '../../shared/ImageRow/ImageRow';
import AddFileButton from '../../shared/AddFileButton/AddFileButton';

class InlineElementEditor extends React.Component {
  constructor(props) {
    super(props);
    this.handleUpload = this.handleUpload.bind(this);
    this.findBasis = this.findBasis.bind(this);
    this.getCell = this.getCell.bind(this);
    this.removeBasis = this.removeBasis.bind(this);
    this.state = { uploadbasis: false, basises: this.getCell() };
  }

  handleUpload(formData, name) {
    this.props.dispatch(uploadBasis(formData, this.props.column.identifier));
  }

  cloneRows(rows) {
    const rowscloned = [];
    rows.map((row) => {
      const clonerow = { ...row };
      rowscloned.push(clonerow);
    });
    return rowscloned;
  }

  findBasis(id) {
    let selectedBasis = null;
    selectedBasis = this.getCell().filter((basis) => {
      return basis.versionId == id;
    });

    if (selectedBasis.length > 0) {
      return selectedBasis[0];
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.basis != null && this.props.column.identifier == this.props.idForm) {
      // const { match: { params } } = this.props;
      const existingBasis = this.findBasis(this.props.basis.element.versionId);
      if (existingBasis == null) {
        const basises = this.getCell();
        basises.push(this.props.basis.element);
        this.setState({
          basises
        });
      }
    }
  }

  removeBasis = (id) => {
    try {
      this.state.basises.splice(id, 1);
      this.forceUpdate();
    } catch (e) {
      throw e;
    }
  };

  getCell() {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });
    return cell.value;
  }

  render() {
    return (
      <div className="row-wrapper">
        <span className="captionDeviation">{this.props.column.name.defaultLabel}</span>
        <ImageRow itemImg={this.state.basises} isInfo removeBasis={this.removeBasis} />
        <AddFileButton iconName={ICONS.CAMERA} fillName="#fff" handleUpload={this.handleUpload} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  basis: state.ra_basis,
  idForm: state.idForm
});

export default connect(mapStateToProps)(InlineElementEditor);
