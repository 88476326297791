import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { API_ROOT_WAR } from '@zert-packages/actions/api';
import { Route, useParams } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import { generatePreviewReport } from 'clm/src/plugins/PreviewPlugin/servicesMiddlewares';
import { generateTermDBExport } from 'clm/src/plugins/SoftwareTextPlugin/serviceMiddleware';
import useVersionManager from '@zert-packages/utils/useVersionManager';
import { handleLanguage } from '@zert-packages/actions/commonReducer';
import { findActiveLocales } from '../helpers/findActiveLocales';
import { findVersionTranslatable, getColor, getTabsHeader } from '../helpers/main';
import VersionBox from '../../shared/VersionBox';
import ElementSearcher from '../../common/ElementSearcher/ElementSearcher';
import ToolBarElements from './ToolBarElements';
import { useCTRL_F } from '../../common/ElementSearcher/utils/hooks';
import useShowElementSearcher from '../../common/ElementSearcher/utils/useShowElementSearcher';
import PhraseEditorMenu from "./PhraseEditorMenu/PhraseEditorMenu";
import EditorBlockHead from "./EditorBlockHead";
import ImgEditorMenu from "./PhraseEditorMenu/ImgEditorMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    '& fieldset': {
      borderColor: 'white !important'
    }
  },
  headBar: {
    display: 'grid',
    background: '99% center rgb(5, 54, 74)',
  },
  title: {
    margin: '0 10px',
    '@media  (max-width: 1600px)': {
      fontSize: '1.15rem'
    }
  },
  autoComplete: {
    width: '170px',
    margin: '0 10px',
    '& .MuiAutocomplete-endAdornment': {
      top: 0
    },
    '@media  (max-width: 1200px)': {
      width: '120px'
    }
  },
  textField: {
    '& fieldset': {
      borderColor: 'white !important'
    },
    '& .MuiOutlinedInput-root': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important'
    }
  },
  box: {
    width: '30px',
    height: '30px',
    borderRadius: '5px',
    marginRight: 10,
  },
  wrapperVersionBox: {
    position: 'relative',
    top: 2,
    '& .HeadBar__MethodInfoContainer': {
      marginLeft: 0
    },
    '& .MuiSelect-select': {
      paddingTop: '2.5px',
      paddingBottom: '2.5px'
    }
  },
  headerWrapper: {
    height: (props) => (props.hide ? '1px' : '51px')
  }
}));


function HideOnScroll(props) {
  const { children } = props;
  const { scrollHeader } = useSelector((state) => state);
  const lockSidePanel = useSelector((state) => state.lockSidePanel == null || state.lockSidePanel);

  return (
    <Slide appear={false} direction="down" in={!scrollHeader || lockSidePanel}>
      {children}
    </Slide>
  );
}

function WebEditorHeader({ route, plugin }) {
  const { permits,  myuser, measure_riskrow, activeLocales, myLanguage, sideBarOpen, block  } = useSelector((state) => state);
  const [openSearch, setOpenSearch] = useState(false);
  const [showInlineEditor, setShowInlineEditor] = useState(true);
  const { versionId } = useParams();
  const isAllowedShowElementSearcher = useShowElementSearcher();
  const [versionManager, versionInfo] = useVersionManager();
  const classes = useStyles();
  const isSoftWare = window.location.pathname.includes('softwaretexts');
  const dispatch = useDispatch();
  const [localeActiveElements, setLocaleActiveElements] = useState([]);
  const url = window.location.pathname;
  const selectedBlockChild = block?.selectedItem && !block.selectedItem.parentTreeItem
  const typeSelectedBlock = block?.selectedItem  && block.selectedItem?.elementInfo?.mimeType.split('/').pop()

  

  const itemContainerStyle = getColor(plugin, measure_riskrow, myuser, versionInfo, permits);

  const localeChanged = (state) => {
    if (state.value === myLanguage.value) {
      return;
    }
    findVersionTranslatable(versionInfo, state);
    dispatch(handleLanguage(state));
  };

  useCTRL_F(() => {
    if (!isAllowedShowElementSearcher) return;
    setOpenSearch(true);
  });

  function MethodInfo() {
    return versionInfo && versionInfo.templateName ? (
      <div className="HeadBar__MethodInfoContainer">
        <span>{route.methodInfoTitle}</span>
        <span>{versionInfo.templateName}</span>
      </div>
    ) : null;
  }

  useEffect(() => {
    if (activeLocales && versionInfo && versionInfo.info) {
      // dispatch(handleLanguage(versionInfo.info.locale));
      const activeLocalesItem = findActiveLocales(versionInfo, activeLocales);
      setLocaleActiveElements(activeLocalesItem);
      const findActiveLocal = activeLocalesItem.find((el) => el.value === versionInfo.info.locale);
      if (findActiveLocal) {
        dispatch(handleLanguage(findActiveLocal));
      }
    }
  }, [activeLocales, versionInfo]);


  // console.log('block', block)

  return (
      <Route exact path={API_ROOT_WAR + route.path}>
        <HideOnScroll>
          <AppBar
              id="HeadBar"
              className={classes.headBar}
              style={{
                gridTemplateColumns: sideBarOpen ? '280px 1fr' : '50px 1fr'
              }}
          >
            <div style={{background: '#063040'}}/>
            <div>
              <div
                  className="HeadBar__ItemContainer"
                  style={{
                    //  background: 'linear-gradient(45deg, #06262f 0%, #05364b 50%, #05364a 100%)'
                    background: 'rgb(5, 54, 74) 99%',
                    paddingLeft: 0
                  }}
              >
                {/* <div style={{ margin: "0 5px" }}> */}
                {/*  <Link to="/about-zert" className="logo"> */}
                {/*    <Logo filename="sidebar-logo.png" style={{ maxWidth: "90px" }} /> */}
                {/*  </Link> */}
                {/* </div> */}

                {activeLocales && window.ISCLM && !isSoftWare && (
                    <div>
                      <Autocomplete
                          className={classes.autoComplete}
                          size="small"
                          value={myLanguage || ''}
                          // defaultValue={myLanguage}
                          // clearIcon={<DeleteSharp/>}
                          disableClearable
                          options={localeActiveElements}
                          getOptionLabel={(option) => `${option.displayName}`}
                          onChange={(event, value) => localeChanged(value)}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  className={classes.textField}
                                  variant="outlined"
                                  sx={{'& .MuiOutlinedInput-root': {
                                      backgroundColor: 'white'
                                    }
                                  }}
                                  size="small"
                              />
                          )}
                      />
                    </div>
                )}
                {versionInfo && (
                    <div className={classes.wrapperVersionBox}>
                      <VersionBox key={versionInfo.info.versionId}/>
                    </div>
                )}
                <div className={classes.box} style={itemContainerStyle}/>
                {isAllowedShowElementSearcher && (
                    <ElementSearcher
                        openSearch={openSearch}
                        setOpenSearch={setOpenSearch}
                        backgroundStyle={itemContainerStyle}
                    >
                      <MethodInfo/>
                    </ElementSearcher>
                )}
                {/* <h5 className={classes.title} */}
                {/*    onClick={() => setOpenSearch(true)}> */}
                {/*  {getNameFromVersionInfo(versionInfo)} */}
                {/* </h5> */}
                {/*{selectedBlockChild && <PhraseEditorMenu />}*/}


                {selectedBlockChild &&  <EditorBlockHead />}
                <div style={{
                   display: selectedBlockChild ? 'none': 'block',
                  width: '100%'}} >
                  <ToolBarElements
                      canCreateVersion={versionManager.canCreateNewVersion}
                      canSaveVersion={false} // versionManager.canSaveVersion}
                      canApproveVersion={versionManager.canApproveVersion}
                      onCreateNewVersion={versionManager.createNewVersion}
                      onApproveVersion={versionManager.approveVersion}
                      onSaveVersion={() => false}
                      canPreview
                      onPreview={() => {
                        dispatch(generatePreviewReport(versionInfo.info, -1));
                      }}
                      canExport
                      onExport={() => {
                        dispatch(generateTermDBExport(versionId));
                      }}
                      tabs={getTabsHeader(dispatch, url)}
                  />
                </div>
                {!openSearch && <MethodInfo/>}
                {/* <CloseButton /> */}
              </div>
            </div>
            {typeSelectedBlock === 'zert-phrase' &&  <>
              <div></div>
              <PhraseEditorMenu />
            </>}
            {typeSelectedBlock === 'zert-image' && <>
              <div></div>
              <ImgEditorMenu />
            </>}
          </AppBar>
        </HideOnScroll>

      </Route>
  );
}

export default WebEditorHeader;
