import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import getTranslation from '@zert-packages/utils/getTranslation.new';
import { getFormatting } from '@zert-packages/utils/getFormatting';
import SearchComponent from './SearchComponent';
import withSearch, { styles, controlStyles } from '../withSearch';

function getStyles(name, that) {
  return {
    fontWeight:
      !that.state.Format || !name || that.state.Format.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
 /* getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }*/
};

class BlockSearch extends SearchComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.getTranslation = this.getTranslation.bind(this);
    this.textInput = React.createRef();
  }

  getTranslation = (value) => {
    const template = getFormatting(value, 'blockFormats', this.props.formatTemplate);
    if (!template) {
      return '';
    }
    return getTranslation(template.label);
  };

  handleChange = (event) => {
    this.setStatus({ Format: event.target.value });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    this.props.expanded && this.textInput.current ? this.textInput.current.blur() : this.textInput.current.focus();
  }

  render() {
    const { classes } = this.props;
    // this.props.handleChange(this.state);
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        {this.props.formatTemplate && this.props.formatTemplate.blockFormats && (
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="select-multiple-chip">
              <FormattedMessage id="search.formats" defaultMessage="Format" />
            </InputLabel>
            <Select
              autoFocus
              size="small"
              inputRef={this.textInput}
              multiple
              value={this.state.Format ? this.state.Format : []}
              onChange={this.handleChange}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={this.getTranslation(value)} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {this.props.formatTemplate &&
                this.props.formatTemplate.blockFormats.map((format) => (
                  <MenuItem
                    key={format.identifier}
                    value={format.identifier}
                    style={getStyles(format.identifier, this)}
                  >
                    {getTranslation(format.label)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </form>
    );
  }
}

BlockSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.context.block" defaultMessage="Search in blocks" />,
  index: 'block-criteria',
  className: 'se.zert.clm.search.BlockCriteria',
  default: {
    Format: []
  }
};

const mapStateToProps = (state) => ({ formatTemplate: state.formatTemplate });
export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles, { withTheme: true })(BlockSearch), selectData))
);
