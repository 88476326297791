import {createContext} from "react";

export const SparePartContext = createContext({});

export const initSparePartContext = {
    viewedElements: [],
    currentViewedElement: null,
    currentElementVersionId: null,
    elementType: null,
    updatedViewedElement: null,
    sparePartContext: true,
    selectedPart: null,
    partImages: [],
    parts: [],
    isDataChanged: false,
    dialog: {
        open: false,
        type: null
    },
    isLoading: false,
    elementTranslations: [],
    selectedElementLanguage: null,

    //action tracking
    undoTracking: [],
    redoTracking: []
}
