import React, { useContext, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TabsContext } from '../../Stylesheet';
import TabItem from './TabItem';
import Loading from '../../Loading';

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    minHeight: 18,
    marginBottom: 15,
    '& .MuiTab-root': {
      padding: '0px 4px 0px 3px',
      minWidth: 'fit-content',
      minHeight: 18,
      background: '#EBEBEB',
      textTransform: 'capitalize'
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#ffffff',
      background: '#4db1d3',
      outline: 'none'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left',
      background: '#E3F2F7'
    }
  }
}));

function References() {
  const cl = useStyles();
  const { values } = useContext(TabsContext);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeSelectedTab = (e, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      {values.stylesheet ? (
        <>
          <Tabs
            indicatorColor=""
            className={cl.tabsRoot}
            value={selectedTab}
            onChange={handleChangeSelectedTab}
            textColor="primary"
            variant="standard"
            scrollButtons="auto"
            centered
          >
            <Tab label="Formats" />
            <Tab label="Page number" />
          </Tabs>

          {selectedTab === 0 && values.stylesheet.formattedCrossReference && (
            <TabItem references={values.stylesheet.formattedCrossReference} type="formattedCrossReference" />
          )}
          {selectedTab === 1 && values.stylesheet.pageNumberCrossReference && (
            <TabItem references={values.stylesheet.pageNumberCrossReference} type="pageNumberCrossReference" />
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default References;
