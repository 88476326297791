export const  findColorVersion = (el,myuser, findSocket, myLanguage) => {
  if(findSocket){
    if(findSocket.elementEvent.type === "CHECKED_IN" || findSocket.elementEvent.type === "ACTIVE_REFERENCE_CHANGED"){
      return {
        background : 'none'
      }
    }
    if(findSocket.elementEvent.type === "CHECKED_OUT"){
      return {
        background :  '#01A1C7'
      }
    }
    if(findSocket.elementEvent.type === "APPROVED"){
      return {
        background: "#78AE4F",
      }
    }
    //  APPROVED
  }
  if(el && el.obsolete){
    return {
      background: '#FEFFB1'
    }
  }
  if(el && myLanguage && el.translatable && el.elementInfo && el.elementInfo.locale !== myLanguage.value){
    return {
      background: '#FFDBB7'
    }
  }
  if (el && el.approvedAt) {
    return {
      background: "linear-gradient(to bottom, #80b15b 0%, #78AE4F 100%)",
      backgroundColor: "#78AE4F"
    }
  }
  if (myuser && el && el.checkedOut && el.checkoutByUser && myuser.userName !== el.checkoutByUser) {
    return {
      background: "linear-gradient(to bottom, #fb4444 0%, #e83535 100%)",
      backgroundColor: "#E83535"
    };
  }
  if(el && !el.approvedAt){
    if(myuser && el && el.checkedOut){
      return {background : '#01A1C7'}
    }
    return {
      background : 'none'
    }
  }

};