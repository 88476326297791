import React from 'react'
import {makeStyles} from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPropertiesDialog from "./AddPropertiesDialog";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "grid",
        gridTemplateColumns: "1fr 25px",
        gridColumnGap: 10,
    },
    wrapperImg: {
        color: "#4db1d3",
        "& svg": {
            cursor: "pointer",
            marginBottom: 3.5,
        }
    },
    row: {
        padding: '2px 4px',
    },
    listWrapper: {
        border: "1px solid #BDBDBC",
        borderRadius: 5,
        overflow: "auto",
        minHeight: 200,
        maxHeight: `100%`,
        paddingBottom: 15,
    },
    listLeftMenu: {
        cursor: 'pointer'
    },
    listSelected: {
        cursor: 'pointer',
        color: '#ffffff',
        background: '#4db1d3'
    },
}))

const Properties = ({connection, setConnection}) => {
    const cl = useStyles();

    const addProperties = () => {
        renderInExceptionRoot(AddPropertiesDialog, {connection, setConnection})
    }
    const deleteProperties = () => {
        if (!connection.selectedPropertiesKey) {
            return
        }
        delete connection.properties[connection.selectedPropertiesKey]
        setConnection(pre => ({...pre, properties: connection.properties, selectedPropertiesKey: null}))
    }


    const handleProperty = (val, name) => {
        setConnection(pre => ({...pre, [name]: val}))
    }

    return (<div className={cl.root}>
        <div className={cl.listWrapper}>
            {connection.properties && Object.entries(connection.properties).map(([key, value], i) =>
                <div
                    onClick={() => handleProperty(key, 'selectedPropertiesKey')}
                    key={i}
                    className={`${cl.row} ${connection.selectedPropertiesKey === key ? cl.listSelected : cl.listLeftMenu}`}>
                    {`${key} = ${value}`}
                </div>
            )}
        </div>
        <div className={cl.wrapperImg}>
            <AddIcon onClick={addProperties}/>
            <span style={{opacity: !connection.selectedPropertiesKey ? 0.5 : 1}}>
                 <DeleteIcon aria-disabled={true} onClick={deleteProperties}/>
           </span>
        </div>
    </div>)
}

export default Properties