import React, { useContext, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from '../../Loading';
import { handleXSLIncludes } from '../../utils/helpers';
import { TabsContext } from '../../Stylesheet';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    height: 'Calc(100% - 34px)',
    gridRowGap: 15
  },
  templateInput: {
    height: 441,
    width: '100%',
    marginBottom: 10,
    marginTop: 0,
    '& .MuiInputBase-multiline.MuiInputBase-marginDense': {
      height: '100%'
    },
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100% !important'
    }
  },
  title: {
    fontWeight: 700,
    marginBottom: 5
  },
  wrapperArrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#4DB1D3',
    color: '#4DB1D3',
    '& svg': {
      width: 15,
      height: 15
    },
    '& div': {
      background: '#ffffff',
      height: 15,
      display: 'grid',
      columnGap: 5,
      gridTemplateColumns: '1fr 1fr'
    }
  },
  listWrapper: {
    border: '1px solid #C6C6C5',
    borderRadius: 10,
    height: 'Calc(100% - 45px)'
  },
  scroll: {
    overflow: 'auto'
  },
  listItem: {
    padding: '2px 4px',
    cursor: 'pointer'
  },
  listItemActive: {
    padding: '2px 4px',
    cursor: 'pointer',
    background: '#4db1d3',
    color: '#ffffff'
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10
  },
  scrollRoot: {
    position: 'relative',
    height: 'Calc(100% - 25px)',
    overflow: 'auto'
  },
  scrollWrapper: {
    overflow: 'auto',
    position: 'absolute',
    width: '100%'
  },
  wrapperIcon: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10,
    width: 'fit-content',
    marginLeft: 'auto',
    marginTop: 11,
    color: '#4DB1D3',
    cursor: 'pointer'
  }
}));

function Include() {
  const cl = useStyles();
  const inputRef = React.useRef();
  const { values, setValues } = useContext(TabsContext);
  const [selectionStart, setSelectionStart] = useState({
    widthIndex: 0,
    heightIndex: 0
  });
  const [expander, setExpander] = useState(2);
  const [include, setInclude] = useState(null);
  const [selectedInclude, setSelectedInclude] = useState({
    idx: 0,
    name: null,
    typeName: null
  });

  const updateSelectionStart = () => {
    const resWidth = inputRef.current.selectionStart;
    const resHeight = values.stylesheet.includedXSL.substring(0, resWidth).split('<xsl').length;
    setSelectionStart({
      widthIndex: resWidth,
      heightIndex: +resHeight - 1
    });
  };

  const handleXSL = (e) => {
    handleXSLIncludes(e.target.value, 'includedXSL', values, setValues);
  };

  const findGridBlock = () => {
    if (expander === 1) {
      return '15px 1fr';
    }
    if (expander === 2) {
      return '1fr 15px 2fr';
    }
    if (expander === 3) {
      return '1fr 15px';
    }
  };

  const handleExpander = (arrow) => {
    if (arrow === 'Top') {
      if (expander === 3) {
        return setExpander(2);
      }
      return setExpander(1);
    }
    if (expander === 1) {
      setExpander(2);
    }
    if (expander === 2) {
      setExpander(3);
    }
  };

  const handleSelected = (i, el) => {
    setSelectedInclude({ idx: i, name: el.typeName, typeName: el.typeName });
  };

  const deleteInclude = () => {
    const idx = selectedInclude.idx !== 0 ? selectedInclude.idx - 1 : selectedInclude.idx;
    const objIncludedElements = values.stylesheet.includedElements;
    delete objIncludedElements[selectedInclude.idx !== 0 ? selectedInclude.typeName : include[idx].typeName];
    handleXSLIncludes(objIncludedElements, 'includedElements', values, setValues);
    setSelectedInclude({
      idx,
      name: include[idx].name,
      typeName: include[idx].typeName
    });
  };

  useEffect(() => {
    if (values.stylesheet) {
      const keyArrayIncluded = Object.keys(values.stylesheet.includedElements);
      setInclude(
        Object.values(values.stylesheet.includedElements).map((el, i) => ({
          ...el,
          typeName: keyArrayIncluded[i],
          nameParent: keyArrayIncluded[i].split('[')[1].replace(']', '')
        }))
      );
    }
  }, [values.stylesheet]);

  useEffect(() => {
    if (!selectedInclude.name && include && include.length > 0) {
      setSelectedInclude((pre) => ({
        ...pre,
        name: include[0].typeName,
        typeName: include[0].typeName
      }));
    }
  }, [include, selectedInclude]);

  return (
    <>
      {values.stylesheet ? (
        <div
          className={cl.root}
          style={{
            gridTemplateRows: findGridBlock()
          }}
        >
          {(expander === 2 || expander === 3) && (
            <div>
              <p className={cl.title}> Included elements:</p>
              <div className={cl.listWrapper}>
                <div className={cl.wrapperRow} style={{ padding: '2px 4px' }}>
                  <div>Identifier</div>
                  <div>Element</div>
                </div>
                <div className={cl.scrollRoot}>
                  <div className={cl.scrollWrapper}>
                    {include &&
                      include.length > 0 &&
                      include.map((el, i) => (
                        <div
                          key={el.id}
                          onClick={() => handleSelected(i, el)}
                          className={selectedInclude.idx === i ? cl.listItemActive : cl.listItem}
                        >
                          <div className={cl.wrapperRow}>
                            <div>{el.nameParent}</div>
                            <div> {el.name}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className={cl.wrapperIcon}>
                <AddIcon />
                <DeleteIcon onClick={deleteInclude} />
              </div>
            </div>
          )}
          <div className={cl.wrapperArrow}>
            <div>
              <ArrowDropUpIcon
                style={{ cursor: expander !== 1 ? 'pointer' : 'default' }}
                onClick={() => handleExpander('Top')}
              />
              <ArrowDropDownIcon
                style={{ cursor: expander !== 3 ? 'pointer' : 'default' }}
                onClick={() => handleExpander()}
              />
            </div>
          </div>
          {(expander === 2 || expander === 1) && (
            <div className={cl.scroll}>
              <p className={cl.title}>Included XSL:</p>
              <TextField
                className={cl.templateInput}
                value={values.stylesheet.includedXSL}
                inputRef={inputRef}
                onSelect={updateSelectionStart}
                variant="outlined"
                multiline
                minRows={4}
                onChange={handleXSL}
              />
              <p className={cl.title}>
                {selectionStart.heightIndex}:{selectionStart.widthIndex}
              </p>
            </div>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Include;
