import React, {useState} from 'react'
import CircleIcon from "@mui/icons-material/Circle";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        display: 'flex',
        height: 10,
        width: 10,
        cursor: 'pointer',
        marginLeft: 2,
        color: 'rgba(145, 145, 145, 0.25)',
        zIndex: 10,
    },
    top: {
        top: -2,
    },
    bottom: {
        bottom: -8,
    },
    addImgButton: {
        marginLeft: -3,
        marginTop: -5,
        position: 'absolute',
        height: 16,
        width: 16,
        backgroundColor: '#59C1DB',
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 3,
        fontSize: 15,
        fontWeight: 900,
        animation: 'fade-in 0.5s',
        cursor: 'pointer',
        zIndex: 100 ,
    },
}))


const AddCellTable = ({isLastItem,hover, type,  setHover, addRowLocal, index, addCell}) => {
    const cl = useStyles()
    const hoverSelected = () => {
        if(addCell){
            return  hover[type].index === index
        }
        return hover[type]
    }
    const selected = hoverSelected()

    const hoverHandle = (val) => {
        setHover(val, type)
    }

    const addRow = () => {

        addRowLocal(index, type, isLastItem)
    }


    return (<div
            onClick={addRow}
            className={`${ selected?  cl.addImgButton : cl.root } ${isLastItem ? cl.bottom : cl.top}`}
            onMouseEnter={() => hoverHandle({index})}
            onMouseLeave={() => hoverHandle(false)}
        >
            {selected
                ?  <AddIcon />
                : <CircleIcon style={{fontSize: "6px"}}/>
            }
        </div>

    )
}

export default AddCellTable