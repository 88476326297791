import Dialog from '@mui/material/Dialog';
import React, {useContext, useEffect, useState} from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import {useSelector} from 'react-redux';
import {NextPlan} from '@mui/icons-material';
import DropMenu from "@zert-packages/components/DropMenu";
import makeStyles from "@mui/styles/makeStyles";
import {SparePartContext} from "../store/SparePartContext";
import {initPart, storePart, storePartItem} from "../API";
import Loading from "../../StylesheetPlugin/Loading";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        maxWidth: 728,
        padding: 11,
        background: "#E2F2F6"
    },
    wrapperTitle: {
        marginBottom: 10,
        display: "flex",
        justifyContent: "space-between",
        "& svg": {
            cursor: "pointer"
        }
    },
    title: {
        fontSize: "1.8rem",
        fontWeight: 700
    },
    wrapper: {
        minHeight: 419
    },
    inputWrapper: {
        display: "grid",
        gridTemplateColumns: "120px 1fr",
        alignItems: 'center',
        "& > p": {
            marginBottom: '0px'
        }
    },
    wrapperEditor: {
        backgroundColor: "#ffffff",
        borderRadius: 10,
        marginTop: 10
    },
    wrapperButton: {
        display: "flex",
        justifyContent: "end",
        marginTop: 20
    },
    button: {
        color: "#ffffff",
        background: "#4db1d3",
        marginLeft: 10,
        minWidth: 80,
        border: "1px solid #4db1d3",
        outline: "none !important",
        cursor: "pointer",
        "&:hover": {
            background: "none",
            color: "#212529",
            border: "1px solid #4db1d3"
        },
        "&:focus": {
            backgroundColor: "#4db1d3"
        }
    }
}));

const CreatePartDialog = ({dialog, setDialog, selectItem, setSelectItem}) => {
    const cl = useStyles();
    const {activeLocales} = useSelector((state) => state);
    const { partFormats } = useSelector((state) => state.formatTemplate);
    const {values, setValues} = useContext(SparePartContext)
    const [part, setPart] = useState(null);
    const [availableFormats, setAvailableFormats] = useState([])
    const [initLoading, setInitLoading] = useState(false)
    const [error, setError] = useState({})

    useEffect(() => {
        setInitLoading(true)
        initPart(values.partVersionId).then(initPart => {
            setPart({
                ...initPart,
                targetLanguage: {
                    ...initPart.targetLanguage,
                    name: initPart.targetLanguage.displayName
                }
            })
        }).then(() => setInitLoading(false))
    }, [])

    useEffect(() => {
        if(values.currentViewedElement && partFormats) {
            const formatsToSelect = partFormats
                .find(format => format.identifier === values.currentViewedElement.format)
                .references;
            setAvailableFormats(formatsToSelect)
            setPart(prev => ({
                ...prev,
                format: formatsToSelect[0].identifier
            }))
        }
    }, [values, partFormats])

    const saveNewPart = () => {
        if(!part.name) {
            setError(prev => ({...prev, name: true}))
            return
        }

        setValues(prev => ({
            ...prev,
            isLoading: true
        }))

        storePartItem(part.element.versionId, part).then(partCreationResult => {
            const resultPartData = {
                ...values.currentViewedElement,
                partItems: [
                    ...values.parts,
                    {
                        ...partCreationResult,
                        position: values.parts.length + 1,
                        quantity: 1
                    }
                ],
                imageItems: values.partImages
            }
            storePart(values.partVersionId, resultPartData).then(() => {
                setValues(prev => ({
                    ...prev,
                    isLoading: false
                }))
            })
        }).then(() => setDialog({open: false, type: null})).catch(() =>  setValues(prev => ({
            ...prev,
            isLoading: false
        })))
    }

    return (
        <Dialog
            open={dialog}
            onClose={() => {
                setDialog({open: false, type: null});
            }}
            fullWidth
        >
            <DialogTitle sx={{fontSize: '20px'}}>Create new part</DialogTitle>
            <DialogContent fullWidth>
                {initLoading ? <Loading/> : <>
                    <div className={cl.inputWrapper}>
                        <p>Source language:</p>
                        {activeLocales && <DropMenu
                            selected={part?.targetLanguage}
                            dropItem={activeLocales.map(el => ({...el, name: el.displayName}))}
                            setDropItem={(selectedLanguage) => setPart(part => {
                                return {
                                    ...part,
                                    targetLanguage: selectedLanguage
                                }
                            })}
                        />}
                    </div>

                    <div className={cl.inputWrapper}>
                        <p>Article number:</p>
                        <TextField
                            id="outlined-helperText"
                            label="Article number"
                            inputProps={{style: {paddingLeft: '8px'}}}
                            value={part?.articleNumber}
                            onChange={(e) => {
                                setPart(prev => ({
                                    ...prev,
                                    articleNumber: e.target.value
                                }))
                            }}
                        />
                    </div>

                    <div className={cl.inputWrapper}>
                        <p>Format:</p>
                        {availableFormats && <DropMenu
                            selected={part?.format}
                            dropItem={availableFormats.map(el => ({...el, name: el.identifier}))}
                            setDropItem={(el) => setPart(part => {
                                return {
                                    ...part,
                                    format: el.identifier
                                }
                            })}
                        />}
                    </div>

                    <div className={cl.inputWrapper}>
                        <p>Name:</p>
                        <TextField
                            id="outlined-helperText"
                            label="Name"
                            inputProps={{ style: { paddingLeft: '8px' }}}
                            value={part?.name}
                            onChange={(e) => {
                                setError(prev => ({...prev, name: false}))
                                setPart(prev => ({
                                    ...prev,
                                    name: e.target.value
                                }))
                            }}
                            sx={{
                                fieldset: error.name ? { borderColor:  'red'} : {}
                            }}
                        />
                    </div>

                    <div className={cl.inputWrapper}>
                        <p>Recommended:</p>
                        <TextField
                            fullWidth
                            type="number"
                            label="Recommended"
                            inputProps={{style: {paddingLeft: '8px'}}}
                            value={part?.recommended}
                            onChange={(event) => {
                                if(Number.parseInt(event.target.value) < 0) {
                                    setPart(prev => ({
                                        ...prev,
                                        recommended: 0
                                    }))
                                } else {
                                    setPart(prev => ({
                                        ...prev,
                                        recommended: event.target.value
                                    }))
                                }
                            }}
                        />
                    </div>

                    <div className={cl.inputWrapper}>
                        <p>Wear and tear:</p>
                        <TextField
                            fullWidth
                            type="number"
                            label="Wear and tear"
                            value={part?.wearAndTear}
                            inputProps={{style: {paddingLeft: '8px'}}}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) => {
                                if(Number.parseInt(event.target.value) < 0) {
                                    setPart(prev => ({
                                        ...prev,
                                        wearAndTear: 0
                                    }))
                                } else {
                                    setPart(prev => ({
                                        ...prev,
                                        wearAndTear: event.target.value
                                    }))
                                }
                            }}
                        />
                    </div>
                </>}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setDialog({open: false, type: null});
                    }}
                    variant="contained"
                    color="primary"
                >
                    OK
                </Button>

                <Button
                    variant="contained"
                    endIcon={<NextPlan/>}
                    onClick={() => {
                        saveNewPart()
                    }}
                >
                    Save
                </Button>

                <Button
                    onClick={() => {
                        setDialog({open: false, type: null});
                    }}
                    variant="contained"
                    color="primary"
                >
                    CANCEL
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreatePartDialog;
