import React from 'react'
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  button: (props) =>({
    color: "#ffffff",
    background: "#4db1d3",
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#4db1d3",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3",
      color: "#ffffff",
    }
  })
}))

const ButtonCLM = ({label='',  handle, color, style={}, type}) => {
  const cl = useStyles(style);

  const handleButton = () => {
    if(handle){
      handle(type)
    }
  }
  return(<Button
    style={style}
    className={cl.button}
    onClick={handleButton}
  >
    {label}
  </Button>)
}


export default ButtonCLM