import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogListItem from "../../DialogListItem";
import getTranslation from "@zert-packages/utils/getTranslation.old";

const useStyles = makeStyles((theme) => ({
  wrapperInput: {
    display: "grid",
    gridTemplateColumns: "1fr 25px"
  },
  listWrapper: {
    border: "1px solid #BDBDBC",
    marginRight: 10,
    borderRadius: 5,
    overflow: "auto",
    minHeight: 200,
    maxHeight: 200
  },
  wrapperEditor: {
    color: "#4db1d3",
    "& svg": {
      cursor: "pointer"
    }
  },
  wrapperTitle: {
    padding: "2px 4px",
    cursor: "pointer"
  },
  wrapperTitleSelected: {
    padding: "2px 4px",
    cursor: "pointer",
    background: "#4db1d3",
    color: "#ffffff"
  }
}));

const ListItems = ({ selected, setSetting }) => {
  const cl = useStyles();
  const [selectedSettings, setSelectSettings] = useState(0);
  const dataCreateItem = {
    value: "a"
  }



  const handleSelectedTab = (i) => {
    setSelectSettings(i);
  };

  const handleOpenDialog = (type) => {
    if(type === 'edit'){
      return renderInExceptionRoot(DialogListItem, {
        label: 'Edit item',
        items: selected.items,
        index: selectedSettings,
        setSetting
      })
    }
    return renderInExceptionRoot(DialogListItem, {
      label: 'Add item',
      items: [dataCreateItem],
      index: selectedSettings,
      setSetting,
      addItem: true,
      setSelectSettings
    })
  };

  const deleteFormat = () => {
    setSetting(pre => ({
      ...pre,
      selected: {
        ...pre.selected,
        items: pre.selected.items.filter((el, i) => i !== selectedSettings)
      }
    }));
    //.selected.items
  };



  return (<div className={cl.wrapperInput}>
    <div className={cl.listWrapper}>
      {selected.items.length > 0 && selected.items.map((el, i) => (
        <div
          key={i}
          onClick={() => handleSelectedTab(i)}
          className={selectedSettings === i ? cl.wrapperTitleSelected : cl.wrapperTitle}
        >
          {getTranslation(el.labelObject)}
        </div>
      ))}
    </div>
    <div className={cl.wrapperEditor}>
      <AddIcon onClick={() => handleOpenDialog("Add")} />
      <CreateIcon onClick={() => handleOpenDialog("edit")} />
      <DeleteIcon onClick={deleteFormat} />
    </div>
  </div>);
};

export default ListItems;