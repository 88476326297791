import React, { useState } from 'react';
import './style.css';
import { FormattedMessage, injectIntl } from 'react-intl';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { formStyle } from '../../shared/FormElements/StylesHelper';

const useStyles = makeStyles(formStyle);

function IncrementSelector({ onChange, mode = 'enter' }) {
  const [value, setValue] = useState(mode);
  const classes = useStyles();
  const onChangeValue = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="IncrementSelector.Caption">
        <FormattedMessage id="IncrementSelector.Caption" defaultMessage="Increment" />{' '}
      </InputLabel>
      <Select
        value={value}
        onChange={onChangeValue}
        inputProps={{
          name: 'IncrementSelector.Caption',
          id: 'IncrementSelector.Caption'
        }}
      >
        <MenuItem key="enter" value="enter">
          <em>
            <FormattedMessage id="increment.startcycle" defaultMessage="Increment on started cycle" />
          </em>
        </MenuItem>
        <MenuItem key="exit" value="exit">
          <em>
            <FormattedMessage id="increment.endedcycle" defaultMessage="Increment on ended cycle" />
          </em>
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default injectIntl(IncrementSelector);
