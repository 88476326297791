const Landscape = (pageFormat, setStyle, valDropMenu) => {
  if (pageFormat === 'A3') {
    setStyle(420 / 2, 297 / 2, 420, 297, valDropMenu, pageFormat);
  }
  if (pageFormat === 'A4') {
    setStyle(297 / 2, 210 / 2, 297, 210, valDropMenu, pageFormat);
  }
  if (pageFormat === 'A5') {
    setStyle(210 / 2, 148 / 2, 210, 148, valDropMenu, pageFormat);
  }
  if (pageFormat === 'Letter') {
    setStyle(279 / 2, 216 / 2, 279, 216, valDropMenu, pageFormat);
  }
  if (pageFormat === 'Legal') {
    setStyle(356 / 2, 216 / 2, 356, 216, valDropMenu, pageFormat);
  }
  if (pageFormat === 'User') {
    setStyle(356 / 2, 216 / 2, 356, 216, valDropMenu, pageFormat);
  }
};
export default Landscape;
