export const blockData = {
  blockItem: null,
  nameBlock: "",
  child: null,
  saveCreateElement: false,
  expandedChild: false,
  loader: false,
  addImgRedactor: null,
  createType: null,
  phraseEditor: {
    contentStateJSON: null,
    editorState: null,
    maxLength: null
  },
  parentItemCreate: null,
  selectedItemBlock: [],
  colapse: null,
  isHoveredBlock: false,
  isHoveredAdd: false,
  isHoveredAddBottom: false,
  expandedToolbar: false,
  expandedToolbarBottom: false,
  expandedToolbarCreateLoad: true,
  expandedToolbarProps: false,
  table: false,
  // viewMode: true,
}

export const defaultSaveData  = (parentChild, investmentLevel) => {
  return {
    parentItem: parentChild,
    investmentLevel: investmentLevel,
    closeChild: null,
    dropBlockTable: false,
    blockKey: true,
    blockKeyRedactor: false,
    getTreeItemChild: false,
    closeAllBlockItems: false,
    severalSelectedTreeItem: null,
  }
}

export const closeData = {
  child: null,
  dropBlockTable: false,
  addImgRedactor: null,
  expandedToolbar: false,
  expandedChild: false,
  selectedItemBlock: []
}

export const parentCreateData = (block, el, findInvestmentLevel) => {
  //child
  const child = block.child ? block.child : {}
  return{
    ...child,
    ...block.parentItemCreate,
    versionId: el?.elementInfo?.versionId,
    identifier: el?.identifier,
    investmentLevel: findInvestmentLevel,
  }
}