import React from 'react'
import makeStyles from "@mui/styles/makeStyles";
import {Button} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    wrapperLatestVersion: {
      display: 'grid',
      gridTemplateColumns: '1fr 63px',
      gridColumnGap: 10,
      paddingRight: 5,
    },
    border: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 5,
        minHeight: 150,
        overflow: 'auto',
    },
    wrapperButton: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
    },
    button: {
        height: 'fit-content',
        border: '1px solid #4db1d3',
        color: '#4db1d3',
        outline: 'none !important',
        '&:focus': {
            backgroundColor: '#4db1d3'
        },
        '&:hover': {
            background: '#4db1d3',
            color: '#ffffff',
            border: '1px solid #4db1d3'
        },
    },

}))

const UpdateLatestVersion = () => {
    const cl = useStyles()


    return (<div className={cl.wrapperLatestVersion}>
        <div className={cl.border}>

        </div>

        <div className={cl.wrapperButton}>
            <Button className={cl.button}>
                All
            </Button>
            <Button className={cl.button}>
                None
            </Button>
        </div>
    </div>)
}

export default UpdateLatestVersion