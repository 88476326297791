import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function EditNameDialog({ onSave, name: initialName, handleClose, isAdding }) {
  const [name, setName] = useState(initialName);
  const handleSave = () => {
    onSave(name);
    handleClose();
  };
  return (
    <Dialog open onClose={handleClose} fullWidth>
      <DialogTitle>
        {isAdding ? (
          <FormattedMessage id="EditNameDialog.title.name" defaultMessage="Name" />
        ) : (
          <FormattedMessage id="EditNameDialog.title" defaultMessage="Edit name" />
        )}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
          label={<FormattedMessage id="EditNameDialog.title.name.label" defaultMessage="Name" />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="EditNameDialog.closeButton" defaultMessage="Cancel" />
        </Button>
        <Button onClick={handleSave} color="primary" variant="outlined">
          <FormattedMessage id="EditNameDialog.saveButton" defaultMessage="Save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditNameDialog;
