import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import { duplicateCommonAPI } from '@zert-packages/plugins/ExplorerPlugin/API';
import { showSnackbarError } from '@zert-packages/components/Snackbars';
import { Typography } from '@mui/material';
import NameTextFieldWithMemo from '../components/NameTextFieldWithMemo';
import DuplicationDefaultDialog from '../components/DuplicationDefaultDialog';

const getDefaultState = (element) => ({
  name: element.name
});

function Common({ unmountComponent, onFinish, element }) {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(getDefaultState(element));
  const updateStateField = (field, value) =>
    setState((prev) => ({
      ...prev,
      [field]: value
    }));

  const onDuplicate = () => {
    setLoading(true);
    duplicateCommonAPI(element.versionId, true, state.name)
      .then((res) => {
        setLoading(false);
        unmountComponent();
        onFinish(res);
      })
      .catch((error) => {
        showSnackbarError(snackbar, error.message);
        setLoading(false);
      });
  };
  const onDuplicateDisabled = state.name.trim().length === 0;

  return (
    <DuplicationDefaultDialog
      unmountComponent={unmountComponent}
      onDuplicate={onDuplicate}
      onDuplicateDisabled={onDuplicateDisabled}
      loading={loading}
      customDialogContent={
        <>
          <FormattedMessage
            id="DuplicationDialogs.Common.Confirmation"
            defaultMessage="Are you sure that you want to duplicate - {name}"
            values={{
              name: element.name
            }}
          />
          {window.ISCLM && (
            <Typography>
              <FormattedMessage
                id="DuplicationDialogs.Common.CLMMessage"
                defaultMessage="Reusing elements may be made more difficult if you duplicate. Only duplicate elements if you are going to continue. Working with the element in a different way than the original element."
              />
            </Typography>
          )}
          <NameTextFieldWithMemo
            autoFocus={false}
            disabled={false}
            value={state.name}
            onChange={(e) => updateStateField('name', e.target.value)}
          />
        </>
      }
    />
  );
}

export default Common;
