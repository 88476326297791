import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import UserSuggestBox from '@zert-packages/components/shared/UserSuggestBoxNew/UserSuggestBox';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import withSearch, { styles, controlStyles } from '../withSearch';

import SearchComponent from './SearchComponent';

class UserSearch extends SearchComponent {
  constructor(props) {
    super(props);
    if (!this.state.user && this.props.myuser) {
      this.state = {
        user: this.props.myuser,
        Username: this.props.myuser.username
      };
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.textInput = React.createRef();
  }

  handleUserChange = (user) => {
    this.setState({ user });
    this.setStatus({ Username: user != null ? user.userName : null });
  };

  handleChange = (name) => (event) => {
    this.setStatus({ [name]: event.target.value });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    this.props.expanded && this.textInput.current ? this.textInput.current.blur() : this.textInput.current.focus();
  }

  render() {
    //  this.props.handleChange(this.state);
    const { classes } = this.props;
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        <FormControl className={classes.formControl} fullWidth>
          <Select
            autoFocus
            size="small"
            inputRef={this.textInput}
            value={this.state.Option}
            onChange={(e) => {
              this.setStatus({ Option: e.target.value });
            }}
            inputProps={{
              name: 'selectedIndex',
              id: 'selectedIndex'
            }}
          >
            <MenuItem value={0}>
              <em>
                <FormattedMessage id="search.user.CreatedBy" defaultMessage="Created by" />
              </em>
            </MenuItem>
            <MenuItem value={1}>
              <em>
                <FormattedMessage id="search.user.ChangedBy" defaultMessage="ChangedBy" />
              </em>
            </MenuItem>
            <MenuItem value={2}>
              <em>
                <FormattedMessage id="search.user.CheckedOutBy" defaultMessage="CheckedOutBy" />
              </em>
            </MenuItem>
            <MenuItem value={3}>
              <em>
                <FormattedMessage id="search.user.ApprovedBy" defaultMessage="ApprovedBy" />
              </em>
            </MenuItem>
            <MenuItem value={4}>
              <em>
                <FormattedMessage id="search.user.ArchivedBy" defaultMessage="ArchivedBy" />
              </em>
            </MenuItem>
          </Select>
        </FormControl>
        <UserSuggestBox
          readonly={false}
          onChange={this.handleUserChange}
          getUsers={getUsersQuery}
          getUserId={(user) => user.username}
          getUserLabel={(user) => user.name}
          initialValue={this.state.user && this.state.user.name}
          label={<FormattedMessage id="DelegateTasksSettingsDialog.UserLabel" defaultMessage="User" />}
        />
      </form>
    );
  }
}

UserSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.user.header" defaultMessage="Search by user" />,
  index: 'element-user',
  className: 'se.zert.backend.search.UserCriteria',

  default: {
    Option: 0
  }
};
const mapStateToProps = (state) => ({
  myuser: state.myuser
});
export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles)(UserSearch), selectData))
);
