import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup, Input,
  TextField
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteIcon from '@mui/icons-material/Delete';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const useStyles = makeStyles({
  DialogContent: {
    minWidth: 320,
    width: '100%',
    overflow: 'auto'
  }
});



export default function TableOptionDialog(
  {open, onClose, caption, title, value, key, columns, hasDefault,
    useDefaultLabel, onStateChanged}
) {
  const cl = useStyles();
  const intl = useIntl();
  const [initialValue, setInitialValue] = useState(value);

  const handleUseDefaultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onStateChanged({...value, useDefault: event.target.checked});
  };

  useEffect(() => {
    if (open) {
      setInitialValue(value);
    }
  }, [open])

  const cancelChange = () => {
    onStateChanged(initialValue);
    onClose();
  }

  const defaultLabel = hasDefault && (useDefaultLabel
    ? useDefaultLabel
    : intl.formatMessage({
      id:'TableOptionDialog.defaultLabel',
      defaultMessage: 'Use default values'
    }));

  const deleteRow = (row) => {
    const newTable = value.data.slice(0,row).concat(value.data.slice(row + 1));
    onStateChanged({useDefault: value.useDefault, data: newTable})
  }

  const addRow = () => {
    const row = Object.fromEntries(columns.map(c => {
      return [c.key, c.defaultValue];
    }));
    const newTable = value.data.concat([row]);
    onStateChanged({useDefault: value.useDefault, data: newTable});
  }

  const swapRows = (r1, r2) => {
    const newTable = [...value.data];
    [ newTable[r2] ] = newTable.splice(r1, 1, newTable[r2]);
    onStateChanged({useDefault: value.useDefault, data: newTable});
  }

  const updateValue = (row, key) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTable = [...value.data];
    newTable[row] = {...newTable[row], [key]: event.target.value};
    onStateChanged({useDefault: value.useDefault, data: newTable})
  }

  const len = value.data.length;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent className={cl.DialogContent}>
        {caption}
        {hasDefault &&
          <FormGroup>
            <FormControlLabel
              label={defaultLabel}
              control={<Checkbox
                checked={value.useDefault}
                onChange={handleUseDefaultChange}
              />}
            />
          </FormGroup>}
        {!value.useDefault &&
          <Table sx={{ minWidth: 650 }} aria-label={'selector table' + key}>
            <TableHead>
              <TableRow>
                {columns.map(c => <TableCell key={c.key}>{c.label}</TableCell>)}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {value.data.map((row, i) => (
                <TableRow
                  key={key + '-row-' + i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {columns.map(c => {
                    return (<TableCell key={`${i}-${c.key}`}>
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 1 },
                        }}
                      >
                        {c.type === 'color' &&
                          <input type={"color"} value={row[c.key] || "#ffffff"}
                                 style={{ width: '130px', height: '30px',
                                   margin: 0, padding: 0, overflow: 'hidden' }}
                                 onChange={updateValue(i, c.key)}
                        />}
                        {c.type === 'text' &&
                          <TextField
                            value={row[c.key]}
                            onChange={updateValue(i, c.key)}
                          />}
                        {c.type === 'number' &&
                          <Input
                            type={"number"}
                            value={row[c.key]}
                            onChange={updateValue(i, c.key)}
                          />}
                      </Box>
                    </TableCell>)
                  })}
                  <TableCell key={`${i}-delete`}>
                    <div style={{
                      gridTemplateAreas: "'del up' 'del down'",
                      display: 'grid', alignItems: 'center',
                      justifyItems: 'end', alignContent: 'center'
                    }}>
                      <div style={{gridArea: 'del', gridRowEnd: 'span 2'}}>
                        <IconButton onClick={() => deleteRow(i)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                      <div style={{gridArea: 'up'}}>
                        {i > 0 &&
                          <IconButton onClick={() => swapRows(i-1, i)}>
                            <KeyboardArrowUpIcon />
                          </IconButton>}
                      </div>
                      <div style={{gridArea: 'down'}}>
                        {i < len - 1 &&
                          <IconButton onClick={() => swapRows(i, i + 1)}>
                            <KeyboardArrowDownIcon />
                          </IconButton>}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>}
        {!value.useDefault && <Button onClick={addRow}>
          <FormattedMessage
            id={"TableOptionDialog.addRow"}
            defaultMessage={"Add new row"} />
        </Button> }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          <FormattedMessage id="TableOptionsDialog.OKButton" defaultMessage="OK" />
        </Button>
        <Button onClick={cancelChange} color="primary">
          <FormattedMessage id="TableOptionsDialog.CancelButton" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );

}
