import React from 'react';
import withTile from '../shared/withTile';
import { TileIcon } from './AbstractTile';

function ProjectPlanningOverviewTile(props) {
  return (
    <div
      id="right_side"
      style={{
        flex: props.showCL ? '1' : '0',
        height: props.showCL ? '100%' : '0px',
        width: props.showCL ? '100%' : '0px',
        transition: 'flex 0s linear, height 0s step-start',
        transitionTimingFunction: 'linear, step-end'
      }}
    >
      <div>
        <div style={{ color: '#84A6AF', fontSize: '14px', fontWeight: '500', padding: '0 0 0 25px' }} />
        <div style={{ display: 'flex', padding: '0 0 0 25px' }} />
      </div>
    </div>
  );
}

function ProjectContentOverviewTile() {
  return (
    <div
      style={{
        color: '#97B3BB',
        fontSize: '14px',
        fontWeight: '500',
        padding: '12px 0 0 20px',
        overflowY: 'hidden',
        transition: '1s',
        height: '35px'
      }}
    >
      <ul className="circle-navigation" style={{ maxWidth: '0' }} />
    </div>
  );
}

export default withTile(TileIcon, ProjectPlanningOverviewTile, ProjectContentOverviewTile);
