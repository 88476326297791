import React from 'react';
import { FormattedMessage } from 'react-intl';

export const messages = [
  <FormattedMessage id="risk-assesment-templates" defaultMessage="Risk Assesment templates" />,
  <FormattedMessage id="worklflow-templates" defaultMessage="Workflow templates" />,
  <FormattedMessage id="project-templates" defaultMessage="Project Templates" />,
  <FormattedMessage id="checklist-templates" defaultMessage="Checklist templates" />,
  <FormattedMessage id="submits-templates" defaultMessage="Submits templates" />,
  <FormattedMessage id="safety-inspection-templates" defaultMessage="Safety inspection templates" />,
  <FormattedMessage id="settings.companyeditor.intervalMinutes" defaultMessage="Minutes" />,
  <FormattedMessage id="settings.companyeditor.intervalHours" defaultMessage="Hours" />,
  <FormattedMessage id="settings.companyeditor.intervalDays" defaultMessage="Days" />,
  <FormattedMessage id="settings.companyeditor.intervalMonthes" defaultMessage="Months" />,
  <FormattedMessage id="settings.companyeditor.intervalYears" defaultMessage="Years" />,
  <FormattedMessage id="settings.companyeditor.placeholder.fullname" defaultMessage="Full name" />,
  <FormattedMessage id="settings.caption.fullname" defaultMessage="Full name" />,
  <FormattedMessage id="settings.caption.email" defaultMessage="Email" />,
  <FormattedMessage id="settings.caption.password" defaultMessage="Password" />,
  <FormattedMessage id="settings.caption.confirmpassword" defaultMessage="Confirm password" />,
  <FormattedMessage id="settings.placeholder.fullname" defaultMessage="Enter full name" />,
  <FormattedMessage id="settings.placeholder.email" defaultMessage="Enter email" />,
  <FormattedMessage id="settings.placeholder.password" defaultMessage="Enter password" />,
  <FormattedMessage id="settings.placeholder.confirmpassword" defaultMessage="Confirm password" />,
  <FormattedMessage id="settings.caption.fullnameinvalid" defaultMessage="Fullname is invalid" />
];
