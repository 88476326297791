import React, { useContext, useEffect, useState } from "react";
import DialogColors from "@zert-packages/components/dialog/DialogColors";
import FlipToBackIcon from "@mui/icons-material/FlipToBack";
import makeStyles from "@mui/styles/makeStyles";
import { TabsContext } from "../FormatTemplatePlugin";
import InputItem from "../InputItem";
import BlockList from "../BlockList";
import ExpanderItem from "../ExpanderItem";
import { handleInputItem, handleItemColor,  handleItemShortcut } from "../utils/handleInputItem";
import DialogList from "../dialog/DialogList";
import FontStyle from "../component/FontStyle";
import WrapperLabelInputItem from "../component/WrapperLabelInputItem";
import { adobeColor, adobeRgba, convertKey } from "../utils/main";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogShortCut from "../dialog/DialogShortCut";
import { useSelector } from "react-redux";
import SelectedColor from "../../StylesheetPlugin/SelectedColor";
import SupportedFormats from "../SupportedFormats";

const useStyles = makeStyles((theme) => ({
  wrapperLabel: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr"
  },
  checkboxLabel: {
    fontSize: 15,
    color: "#212529"
  },
  wrapperExpander: {
    maxWidth: "Calc(100% - 35px)"
  },
  wrapperInput: {
    display: "grid",
    gridTemplateColumns: "100px 1fr",
    marginBottom: 10,
    gridColumnGap: 10,
    alignItems: "center"
  },
  wrapperColor: {
    display: "grid",
    gridTemplateColumns: "100px  25px  1fr 25px",
    gridColumnGap: 10,
    marginBottom: 10
  },
  label: {
    fontWeight: 900
  }
}));

const dataStyle = [
  {id: 'WARNING', name: 'Warning'},
  {id: 'INFO', name: 'Info'},
  {id: 'TIP', name: 'Tip'},
  {id: 'NOTE', name: 'Note'},
]

function ParagraphTabs({disable}) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const { formatTemplate } = useSelector((state) => state);
  const [openDialog, setOpenDialog] = useState(false);
  const [propsDialog, setPropsDialog] = useState(null);
  const [openDialogShortcut, setDialogShortcut] = useState(false);
  const fontColor =  values?.templateList?.typeSettings?.fontColor;
  const bgColor =  values?.templateList?.typeSettings?.bgcolor;
  const [color, setColor] = useState({
    fontColor: "#ffffff",
    bgcolor: "#ffffff",
    load: false,
  })


  const findColord = () => {
    if(color.load){
      return
    }
    if(fontColor && bgColor){
       setColor(pre => ({
        ...pre,
        fontColor: adobeColor(fontColor),
        bgcolor: adobeColor(bgColor),
        load: true}))
      return
    }
    if(fontColor){
      setColor(pre => ({...pre, fontColor: adobeColor(fontColor)}))
    }
    if(bgColor){
      setColor(pre => ({...pre, bgcolor: adobeColor(bgColor)}))
    }
  }





  const handleValue = (val, Identifier) => {
    handleInputItem(Identifier, val, setValues);
  };

  const handleValueShortcut = (val, Identifier) => {
    handleItemShortcut(Identifier, val, setValues);
  };


  const handleOpenDialogColor = ( name) => {
    renderInExceptionRoot(DialogColors, {
      text:{ label: `Color `},
      type: name,
      setText: handleSelectedColor
    })
  }

  const findStyleDropMenu = () => {
    if (!formatTemplate || !formatTemplate.blockStylesMap) {
      return false;
    }
    const res = Object.entries(formatTemplate.blockStylesMap).map(el => ({ id: el[0], name: el[1] }));
     return  [...res, ...dataStyle]
  };

  const handleSelectedColor = (val, name) => {
    setColor(pre => ({...pre, [name]: val, load: true}))
     handleItemColor(name, adobeRgba(val), setValues);
  }

  useEffect(() => {
    findColord()
  }, [fontColor, bgColor])

  console.log('color', color);

  return (
    <div>
      <DialogColors open={openDialog} setOpen={setOpenDialog} text={propsDialog} />
      <DialogList open={openDialogShortcut} setOpen={setDialogShortcut} text={propsDialog} />
      <InputItem
        label="Identifier:"
        valueProps={values.templateList.identifier && values.templateList.identifier}
        disabled={disable}
        Identifier="identifier"
        setValueProps={handleValue}
      />
      <WrapperLabelInputItem disable={disable}/>
      <InputItem
        label="Shortcut:"
        valueProps={values.templateList.shortcut && convertKey(values.templateList.shortcut.keyCode)}
        setValueProps={handleValueShortcut}
        Identifier="shortcut"
        disabled={true}
        icons={
          <FlipToBackIcon
            style={{ cursor: "pointer", color: "gray" }}
            onClick={() => !disable && renderInExceptionRoot(DialogShortCut, { setValues })}
          />
        }
      />
      <div className={cl.wrapperExpander}>
        <ExpanderItem
          disable={disable}
          label="Style:"
          valueProps={values.templateList.style && values.templateList.style }
          type="style"
          propsLabel={findStyleDropMenu()}
        />
      </div>
      <WrapperLabelInputItem title={"Style Label:"} name={"styleLabel"} disable={disable} />
      <div className={cl.wrapperExpander}>
        <ExpanderItem
          disable={disable}
          label="Font Family:"
          style={{ height: 34 }}
          valueProps={
            values.templateList.typeSettings &&
            values.templateList.typeSettings.fontFamily &&
            values.templateList.typeSettings.fontFamily
          }
          propsLabel={["Default", "Inherit", "Sans", "Serif", "Mono"]}
          type="fontFamily"
        />
      </div>
      <FontStyle disable={disable} />
      <div className={cl.wrapperExpander}>
        <ExpanderItem
          label="Font Size:"
          style={{ height: 34 }}
          valueProps={
            values.templateList.typeSettings &&
            values.templateList.typeSettings.fontSize &&
            values.templateList.typeSettings.fontSize
          }

          disable={disable}
          propsLabel={["Default", "XX Small", "X Small", "Small", "Medium", "Large", "X Large", "XX Large"]}
          type="fontSize"
          // setSelected={selectedFontSize}
        />
      </div>
      <div style={{marginBottom: 10}}>
        <SelectedColor
          style={{ grid: '100px 30px 1fr 25px' }}
          label="Font color:"
          type="fontColor"
          setValueProps={handleSelectedColor}
          handleOpenDialog={handleOpenDialogColor}
          valueProps={color.fontColor}
          disabled={disable}
        />
      </div>
      <div style={{marginBottom: 10}}>
        <SelectedColor
          style={{ grid: '100px 30px 1fr 25px' }}
          label="BG color:"
          type="bgcolor"
          setValueProps={handleSelectedColor}
          handleOpenDialog={handleOpenDialogColor}
          valueProps={color.bgcolor}
          disabled={disable}
        />
      </div>
      <BlockList
        disable={disable}
        label="Item settings:"
        formats={
          values.templateList.settings &&
          values.templateList.settings.properties.length > 0 &&
          values.templateList.settings.properties &&
          values.templateList.settings.properties
        }
      />


      <SupportedFormats
        disable={disable}
        label="Supported formats :"
        formats={
          values.templateList.supportedFormats &&
          values.templateList.supportedFormats.length > 0 &&
          values.templateList.supportedFormats
        }
        typeList={"supportedFormats"}
      />
    </div>
  );
}

export default ParagraphTabs;
