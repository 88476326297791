import isEmailInvalid from '@zert-packages/utils/isEmailInvalid';

export default function validateMainSettings(data, selectedTab) {
  const errors = {};
  if (data.general.hasOwnProperty('company') && selectedTab === 0) {
    /* if (isEmailInvalid(data.general.company.fallbackEmail)) {
      errors.fallbackEmail = 'Invalid email';
    } */
  }
  if (data.hasOwnProperty('server') && selectedTab === 1) {
    if (isEmailInvalid(data.server.supportAddress)) {
      errors.supportAddress = 'Invalid email';
    }
    if (isEmailInvalid(data.server.senderAddress) && selectedTab === 1) {
      errors.senderAddress = 'Invalid email';
    }
  }

  return errors;
}
