import exportRMPMockUP from '@zert-packages/utils/exportRMPMockUP';
import { createInProgressSnack, createOpenReportSnack } from '@zert-packages/components/common/snackBar';
import { FormattedMessage } from 'react-intl';

import { getVersionInfo } from '@zert-packages/actions/coreReducers';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { showSnackbarChangesSaved, showSnackbarError } from '@zert-packages/components/Snackbars';

import { performTaskAPI } from '@zert-packages/actions/actionplan';
import { toLocaleString } from '@zert-packages/plugins/ExplorerPlugin/SearchConditions/helpers/getLocaleByLanguageCode';
import { handleQuery } from '@zert-packages/actions/api';
import { loadReviewCommentsAPI, loadReviewJobAPI } from '../ReviewJobPlugin/API';
import LocalesSelectDialog from './LocalesSelectDialog';
import ValidateDialog from './ValidateDialog';
import {
  cancelTranslationJobAPI,
  completeTranslationJobAPI,
  downloadAll,
  downloadSource,
  downloadTermDB,
  downloadTermDBXLS,
  downloadTranslation,
  generateReportAPI,
  loadTranslationJobAPI,
  loadTranslationJobTemplateByJobId,
  loadTranslationJobWFTAPI,
  storeTranslationJobAPI,
  uploadTranslationFile,
  validateTranslationJobAPI
} from './API';
import {
  loadReviewJobAction,
  loadReviewJobActionComments,
  loadTJAction,
  loadTJActionWFT,
  loadTranslationJobTemplate,
  turnOffCLMLoader,
  turnOffCLMLoaderWFT,
  turnOnCLMLoader,
  turnOnCLMLoaderWFT
} from './tjReducers';

const generateRMPReportMockUp = () => ({
  name: <FormattedMessage id="RMP.GenerateReportMockUp.Name" defaultMessage="RMP Report" />,
  reportUUID: uuidv4()
});

export default generateRMPReportMockUp;
export const fetchTranslationJob = (versionId, callback) => (dispatch) => {
  dispatch(turnOnCLMLoader());
  let translationJob = null;
  loadTranslationJobAPI(versionId)
    .then((res) => {
      translationJob = res;
      return loadTranslationJobTemplateByJobId(versionId);
    })
    .then((res) => {
      dispatch(loadTranslationJobTemplate(res));
      dispatch(loadTJAction(translationJob));
      dispatch(turnOffCLMLoader());
      if (typeof callback === 'function') callback();
    });
};

export const checkReportStatus = (
  report,
  snackbar,
  generatingSnackbar,
  generatedReport,
  mimeType,
  callback,
  callbackArg,
  reportName
) => {
  handleQuery(`/report-react/updateProgressStatus/${report.callbackId}/false`)
    .then((newReport) => {
      if (newReport !== null && newReport.active) {
        checkReportStatus(
          newReport,
          snackbar,
          generatingSnackbar,
          generatedReport,
          mimeType,
          callback,
          callbackArg,
          reportName
        );
      } else {
        snackbar.closeSnackbar(generatingSnackbar);

        if (mimeType) {
          createOpenReportSnack(
            snackbar,
            generatedReport.reportUUID ? generatedReport.reportUUID : report.callbackId,
            mimeType,
            reportName
          );
        } else if (callback && typeof callback === 'function') callback(callbackArg);
      }
    })
    .catch((e) => {
      snackbar.closeSnackbar(generatingSnackbar);
      if (callback && typeof callback === 'function') callback();
    });
};

export const fetchReviewJob = (versionId, callback) => (dispatch) => {
  dispatch(turnOnCLMLoader());
  let reviewJob = null;
  dispatch(loadReviewJobAction(null));
  dispatch(loadReviewJobActionComments(null));
  loadReviewJobAPI(versionId)
    .then((res) => {
      reviewJob = res;
      return loadReviewCommentsAPI(versionId);
    })
    .then((res) => {
      dispatch(loadReviewJobActionComments(res));
      dispatch(loadReviewJobAction(reviewJob));

      dispatch(turnOffCLMLoader());
    });
};

export const loadTranslationJobWFT = (versionId, callback) => (dispatch) => {
  dispatch(turnOnCLMLoaderWFT());
  loadTranslationJobWFTAPI(versionId).then((res) => {
    dispatch(loadTJActionWFT(res));
    dispatch(turnOffCLMLoaderWFT());
  });
};

export const performMineTJ =
  (versionId, tj, nextTransition, callback) =>
  (dispatch, getState, { snackbar }) => {
    const func = () => {
      const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="CLM.PrintManager.Snackbar.Performing" defaultMessage="...Performing TJ" />
      );

      performTaskAPI(versionId, nextTransition.to.id)
        .then((res) => {
          snackbar.closeSnackbar(generatingSnackbar);
          showSnackbarChangesSaved(
            snackbar,
            <FormattedMessage
              id="CLM.PrintManager.Snackbar.Performed"
              defaultMessage="{value} performed"
              values={{ value: nextTransition.name }}
            />
          );
          dispatch(fetchTranslationJob(versionId));
          dispatch(getVersionInfo(versionId));
          if (typeof callback === 'function') callback();
        })
        .catch((e) => {
          snackbar.closeSnackbar(generatingSnackbar);
          showSnackbarError(snackbar, e.message);
        });
    };

    if (tj) {
      dispatch(saveTJ(versionId, tj, func));
    } else {
      func();
    }
  };

export const saveTJ =
  (versionId, tj, callback) =>
  (dispatch, getState, { snackbar }) => {
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="CLM.PrintManager.Snackbar.Save" defaultMessage="...Saving TJ" />
    );

    return storeTranslationJobAPI(versionId, tj)
      .then((res) => {
        snackbar.closeSnackbar(generatingSnackbar);
        showSnackbarChangesSaved(
          snackbar,
          <FormattedMessage id="CLM.PrintManager.Snackbar.Saved" defaultMessage="Translation JOB Saved!" />
        );
        if (typeof callback === 'function') callback();
      })
      .catch((e) => {
        snackbar.closeSnackbar(generatingSnackbar);
        showSnackbarError(snackbar, e.message);
      });
  };

export const cancelTJ =
  (versionId, locale, translationJob) =>
  (dispatch, getState, { snackbar }) => {
    const { translationJob } = getState().CLM;
    const generatedReport = exportRMPMockUP();
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage
        id="CLM.PrintManager.Snackbar.Cancel"
        defaultMessage="...completing locale {locale}"
        values={{ locale: locale.displayName }}
      />
    );

    cancelTranslationJobAPI(versionId, generatedReport.uuid, [toLocaleString(locale)])
      .then((res) => {
        checkReportStatus(res, snackbar, generatingSnackbar, generatedReport, null, (e) => {
          dispatch(fetchTranslationJob(versionId));
        });
      })
      .catch((e) => {
        snackbar.closeSnackbar(generatingSnackbar);
        showSnackbarError(snackbar, e.message);
      });
  };

const saveData = (function () {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  return function (blob, fileName) {
    const // json = JSON.stringify(data),
      // blob = new Blob([json], {type: "octet/stream"}),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
 //   window.URL.revokeObjectURL(url);
  };
})();

export const downloadTranslationFiles =
  (translationName, versionId, locale, isTranslation, sourceLocale, all) =>
  async (dispatch, getState, { snackbar }) => {
    const generatedReport = exportRMPMockUP();
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage
        id="CLM.PrintManager.Snackbar.DownloadTranslationFiles"
        defaultMessage="...downloading Translation Files {locale}"
        values={{ locale: locale && locale.displayName }}
      />
    );

    try {
      let blob = null;
      if (isTranslation && !all) {
        blob = await downloadTranslation(versionId, toLocaleString(locale));
      } else if (all) {
        blob = await downloadAll(versionId);
      } else {
        blob = await downloadSource(versionId, toLocaleString(locale));
      }
      snackbar.closeSnackbar(generatingSnackbar);
      const ending = blob.headers['content-disposition'].endsWith('zip') ? 'zip' : 'xliff';
      const url = window.URL.createObjectURL(new Blob([blob.data], { type: `application/${ending}` }));

      const link = document.createElement('a');
      link.href = url;
      link.download = `${translationName + (isTranslation ? '-translation-' : '')}-${
        sourceLocale ? toLocaleString(sourceLocale) : ''
      }-${locale ? toLocaleString(locale) : ''}.${ending}`;
      link.click();
      link.remove();

      /* let extesnion = "xliff";
        if (blob.type && blob.type && blob.type.endsWith("zip")) {
            extesnion = "zip"
        } */
      //      saveData(blob,  );
    } catch (e) {
      snackbar.closeSnackbar(generatingSnackbar);
      showSnackbarError(snackbar, e.message);
    }
  };

export const downlaodTERMDB =
  (locales, isTBX) =>
  async (dispatch, getState, { snackbar }) => {
    const generatedReport = exportRMPMockUP();
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="CLM.PrintManager.Snackbar.DownloadTermDB" defaultMessage="...downloading TERM DB" />
    );
    try {
      let blob = null;
      const localesStrs = locales.map((locale) => toLocaleString(locale));
      if (isTBX) {
        blob = await downloadTermDB(localesStrs);
      } else {
        blob = await downloadTermDBXLS(localesStrs);
      }
      snackbar.closeSnackbar(generatingSnackbar);
      saveData(blob, isTBX ? 'Term.tbx' : 'Term.xls');
    } catch (e) {
      snackbar.closeSnackbar(generatingSnackbar);
      showSnackbarError(snackbar, e.message);
    }
  };
export const uploadTJFile =
  (versionId, locale) =>
  (dispatch, getState, { snackbar }) => {
    const { translationJob } = getState().CLM;
    const generatedReport = exportRMPMockUP();

    const onChange = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { files } = e.target;
      const file = files[0];
      const fd = new FormData();

      if (!file) return;
      fd.append('file', file, file.name);
      e.target.value = null;
      const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage
          id="CLM.PrintManager.Snackbar.UploadFile"
          defaultMessage="..uploading locale {locale}"
          values={{ locale: locale.displayName }}
        />
      );

      uploadTranslationFile(versionId, toLocaleString(locale), fd)
        .then((res) => {
          snackbar.closeSnackbar(generatingSnackbar);
          if (res && res.length > 0) {
            showSnackbarError(snackbar, res[0]);
          }
          showSnackbarChangesSaved(
            snackbar,
            <FormattedMessage
              id="CLM.PrintManager.Snackbar.UploadFiled"
              defaultMessage="Locale {locale} uploaded succesefully"
              values={{ locale: locale.displayName }}
            />
          );
        })
        .catch((e) => {
          snackbar.closeSnackbar(generatingSnackbar);
          showSnackbarError(snackbar, e.message);
        });
    };

    const a = document.createElement('input');
    a.type = 'file';
    document.body.appendChild(a);
    a.style = 'visibility: hidden';

    a.click();
    a.onchange = (e) => {
      onChange(e);
    };
  };

export const completeTJ =
  (versionId, locale) =>
  (dispatch, getState, { snackbar }) => {
    const { translationJob } = getState().CLM;
    const generatedReport = exportRMPMockUP();
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage
        id="CLM.PrintManager.Snackbar.Complete"
        defaultMessage="...completing locale {locale}"
        values={{ locale: locale.displayName }}
      />
    );

    completeTranslationJobAPI(versionId, generatedReport.uuid, [toLocaleString(locale)])
      .then((res) => {
        checkReportStatus(res, snackbar, generatingSnackbar, generatedReport, null, () => {
          dispatch(fetchTranslationJob(versionId));
        });
      })
      .catch((e) => {
        snackbar.closeSnackbar(generatingSnackbar);
        showSnackbarError(snackbar, e.message);
      });
  };

export const generatePrintReport =
  () =>
  (dispatch, getState, { snackbar }) => {
    const { translationJob } = getState().CLM;
    const generatedReport = generateRMPReportMockUp();

    renderInExceptionRoot(LocalesSelectDialog, {
      locales: translationJob.targetLanguages,
      onConfirm: (selectedLocales) => {
        const generatingSnackbar = createInProgressSnack(
          snackbar,
          <FormattedMessage id="CLM.PrintManager.Snackbar.GeneratingLabel" defaultMessage="...generating report" />
        );
        const reportSettings = {
          clientLocales: selectedLocales.map((locale) => toLocaleString(locale)),
          versionIds: [translationJob.info.versionId]
        };
        generateReportAPI(generatedReport.uuid, reportSettings)
          .then((res) => {
            console.log(res);
            checkReportStatus(res, snackbar, generatingSnackbar, generatedReport, 'pdf');
          })
          .catch((e) => {
            snackbar.closeSnackbar(generatingSnackbar);
            showSnackbarError(snackbar, e.message);
          });
      }
    });
  };

export const validateTJ =
  (versionId, locale) =>
  (dispatch, getState, { snackbar }) => {
    const { translationJob } = getState().CLM;
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage
        id="CLM.PrintManager.Snackbar.Validate"
        defaultMessage="...validating file to import for locale {locale}"
        values={{ locale: locale.displayName }}
      />
    );

    validateTranslationJobAPI(versionId, true, [toLocaleString(locale)])
      .then((res) => {
        snackbar.closeSnackbar(generatingSnackbar);
        if (!res || res.length == 0 || !res[1]) {
          dispatch(completeTJ(versionId, locale));
        } else {
          renderInExceptionRoot(ValidateDialog, {
            onConfirm: () => {
              dispatch(completeTJ(versionId, locale));
            },
            locale: locale.displayName,
            html: res[1],
            legend: res[0],
            containsErrors: res[2] == 'true'
          });
        }
      })
      .catch((e) => {
        snackbar.closeSnackbar(generatingSnackbar);
        showSnackbarError(snackbar, e);
      });
  };
