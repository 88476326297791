import React, { memo, useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Loader from '@zert-packages/components/shared/Loader/Loader';
// import s from './RiskRowCriteria.module.sass';
import _ from 'lodash';
import { fetchAssessmentRiskRow } from '@zert-packages/actions/riskassesmentActions';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  increaseCriteriaCount,
  decreaseCriteriaCount,
  filterAndConvertValues,
  getDefaultRowRiskCriteriaValue
} from './helpers';
import ExpansionWrapperCriteria from '../../common/ExpansionWrapperCriteria';

const rowStyles = (theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridGap: '10px',
      gridTemplateColumns: '1fr 1fr 50px',
      width: '100%'
    },
    listOfValues: {
      gridColumn: '1 / -1',
      listStyleType: 'none'
    },
    listOfValuesItems: {
      marginTop: '5px',
      borderTop: '3px solid rgba(0, 0, 0, 0.1)',
      padding: '10px 5px',
      display: 'grid',
      gridGap: '5px',
      gridTemplateColumns: '1fr 40px'
    },
    matrix: {
      display: 'grid',
      gridGap: '7px',
      gridTemplateColumns: '1fr'
    }
  });

const useStyles = makeStyles(rowStyles);

const RiskRowCriteria = memo(
  ({ foundCriteria, updateCriteria, onChangeExpansionOfCriteria, classNameOfCriteria, isEverythingDisabled }) => {
    const classes = useStyles();
    const handleOnChangeExpansion = (event, isExpanded) => {
      onChangeExpansionOfCriteria(isExpanded, classNameOfCriteria);
    };
    const [RowRiskCriteriaData, setRowRiskCriteriaData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      async function fetchRowRiskCriteriaData() {
        setIsLoading(true);
        try {
          const result = await fetchAssessmentRiskRow();
          setRowRiskCriteriaData(result);
          setIsLoading(false);
        } catch (err) {
          throw err;
        }
      }

      fetchRowRiskCriteriaData();
    }, []);

    const [firstComboBox, setFirstComboBox] = useState({ id: '' });
    const onChangeFirstComboBox = (event) => {
      const idOfFirstComboBox = event.target.value;
      const foundFirsComboBox = RowRiskCriteriaData.find((item) => item.id === idOfFirstComboBox);
      if (typeof foundFirsComboBox === 'undefined') return;
      setFirstComboBox(foundFirsComboBox);
    };

    const [secondComboBox, setSecondComboBox] = useState({ id: '' });
    const onChangeSecondComboBox = (event) => {
      const indexOfColumn = event.target.value;
      const foundSecondComboBox = firstComboBox.columns[indexOfColumn];
      setSecondComboBox({
        id: indexOfColumn,
        state: foundSecondComboBox
      });
    };

    const handleGenerateField = () => {
      const { values } = foundCriteria;
      const criteriaCount = values.find((criteria) => criteria.key === 'CriteriaCount').value;

      const firstPartOfCriteriaKey = `Criteria[${criteriaCount}]`;
      const newValues = [
        ...values,
        {
          key: 'TemplateId',
          value: firstComboBox.id
        },

        ...getDefaultRowRiskCriteriaValue(
          secondComboBox.state.type,
          firstPartOfCriteriaKey,
          secondComboBox.state.identifier
        )
      ];
      increaseCriteriaCount(newValues);
      updateCriteria(classNameOfCriteria, newValues);
    };

    const handleDeleteValue = (index) => {
      const newValues = foundCriteria.values.filter((item) => !item.key.includes(`Criteria[${index}]`));
      decreaseCriteriaCount(newValues);
      updateCriteria(classNameOfCriteria, newValues);
    };

    const handleOnChangeValue = (index, type, value) => {
      const newValues = foundCriteria.values.map((item) => {
        if (item.key === `Criteria[${index}].${type}`) {
          return {
            ...item,
            value
          };
        }
        return item;
      });
      updateCriteria(classNameOfCriteria, newValues);
    };
    return (
      <ExpansionWrapperCriteria
        title={<FormattedMessage id="riskRowCriteriaCounter.context" defaultMessage="Risk row" />}
        handleOnChangeExpansion={handleOnChangeExpansion}
        expanded={!!foundCriteria}
      >
        {isLoading ? (
          <Loader displayText={false} />
        ) : (
          !!foundCriteria && (
            <div className={classes.root}>
              <FormControl variant="standard" disabled={isEverythingDisabled}>
                <InputLabel>
                  <FormattedMessage id="RiskRowCriteria.template" defaultMessage="Select Template" />
                </InputLabel>
                <Select variant="standard" value={firstComboBox.id} onChange={onChangeFirstComboBox}>
                  <MenuItem value="">
                    <FormattedMessage id="riskRowCriteriaCounter.none" defaultMessage="None" />
                  </MenuItem>
                  {RowRiskCriteriaData.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {firstComboBox.id !== '' && (
                <FormControl variant="standard" disabled={isEverythingDisabled}>
                  <InputLabel>
                    <FormattedMessage id="RiskRowCriteria.Column" defaultMessage="Column" />
                  </InputLabel>
                  <Select variant="standard" value={secondComboBox.id} onChange={onChangeSecondComboBox}>
                    <MenuItem value="">None</MenuItem>
                    {firstComboBox.columns.map(({ fieldName }, index) => (
                      <MenuItem key={index} value={index}>
                        {fieldName.defaultLabel}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {secondComboBox.id !== '' && (
                <IconButton onClick={handleGenerateField} size="large">
                  <AddIcon />
                </IconButton>
              )}

              <ListOfValues
                values={foundCriteria.values}
                onDelete={handleDeleteValue}
                onChange={handleOnChangeValue}
                disabled={isEverythingDisabled}
              />
            </div>
          )
        )}
      </ExpansionWrapperCriteria>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps.foundCriteria, nextProps.foundCriteria)
);

function ListOfValues({ values, onDelete, onChange, disabled }) {
  const classes = useStyles();
  return (
    <ul className={classes.listOfValues}>
      {filterAndConvertValues(values).map((item) => (
        <li className={classes.listOfValuesItems} key={item.index}>
          <div>{getFieldByType(item, item.index, onChange, disabled, classes)}</div>
          <div>
            <IconButton disabled={disabled} onClick={() => onDelete(item.index)} size="large">
              <DeleteIcon />
            </IconButton>
          </div>
        </li>
      ))}
    </ul>
  );
}

function getFieldByType(item, index, onChange, disabled, classes) {
  switch (item.Type) {
    case 'text':
      return (
        <TextField
          fullWidth
          disabled={disabled}
          label={item.ColumnIdentifier}
          margin="dense"
          variant="outlined"
          value={item.Value}
          onChange={(event) => onChange(index, 'Value', event.target.value)}
        />
      );
    case 'select':
      return (
        <TextField
          fullWidth
          disabled={disabled}
          label={item.ColumnIdentifier}
          margin="dense"
          variant="outlined"
          value={item.Value}
          onChange={(event) => onChange(index, 'Value', event.target.value)}
        />
      );
    case 'matrix':
      return (
        <div className={classes.matrix}>
          <FormControl variant="standard" fullWidth disabled={disabled}>
            <InputLabel>
              <FormattedMessage id="riskRowCriteriaCounter.method" defaultMessage="Method" />
            </InputLabel>
            <Select
              variant="standard"
              value={item.CompareType}
              onChange={(event) => onChange(index, 'CompareType', event.target.value)}
            >
              <MenuItem value="eq">
                <FormattedMessage id="riskRowCriteriaCounter.equals" defaultMessage="Equals" />
              </MenuItem>
              <MenuItem value="gt">
                <FormattedMessage id="riskRowCriteriaCounter.greaterThan" defaultMessage="Greater Than" />
              </MenuItem>
              <MenuItem value="lt">
                <FormattedMessage id="riskRowCriteriaCounter.lessThan" defaultMessage="Less Than" />
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            type="number"
            disabled={disabled}
            label={item.ColumnIdentifier}
            margin="dense"
            variant="outlined"
            value={item.Value}
            onChange={(event) => onChange(index, 'Value', event.target.value)}
          />
        </div>
      );
    default:
      return null;
  }
}

export default RiskRowCriteria;
