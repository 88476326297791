import React from 'react';
// import messages_en from '../../translations/en.json';
// import messages_sv from '../../translations/sv.json';
import { IntlProvider } from 'react-intl';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import svLocale from 'date-fns/locale/sv';
import enLocale from 'date-fns/locale/en-US';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PortalTheme } from '@zert-packages/react/themes/Themes';
import { getMessages, getStore } from '@zert-packages/actions/api';
import { GetEnv } from '@zert-packages/react/EnvProvider';
import {createRoot} from "react-dom/client";

require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/locale-data/sv');
require('@formatjs/intl-pluralrules/locale-data/en');

/* require('@formatjs/intl-pluralrules/polyfill')
require('@formatjs/intl-pluralrules/locale-data/sv')
require('@formatjs/intl-pluralrules/locale-data/en')
*/

export default function dialogCustom(Component, props = {}) {
  const language = props.language ? props.language : document.getElementsByTagName('html')[0].getAttribute('lang');
  const modalRoot = document.getElementById('exception-root');
  const lastNode = getLastNode(modalRoot);
  const mess = getMessages()[language];
  //const portal = GetEnv('REACT_APP_PORTAL');
  const root = createRoot(lastNode);

  const handleClose = () => {
    root.unmount();
  };
  root.render(
    <React.StrictMode>
      <Provider store={getStore()}>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={language === 'sv' ? svLocale : enLocale}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={PortalTheme()}>
                <IntlProvider key="app" locale={language} messages={mess}>
                  <div>
                    <Component handleClose={handleClose} {...props} />
                  </div>
                </IntlProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
      );
}

function getLastNode(rootNode) {
  if (rootNode.hasChildNodes()) {
    const lastChildOfRootNode = rootNode.lastChild;
    return getLastNode(lastChildOfRootNode);
  }
  return rootNode;
}
