import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import EntryView from './EntryView';
import EntryDialog from './EntryDialog';
import SideMenu from "@zert-packages/components/SideMenu";
import makeStyles from "@mui/styles/makeStyles";
import {IndexContext} from "./IndexView";
import handleValueState from "@zert-packages/utils/handleValueState";
import {gridBlock} from "../formatTemplatePlugin/utils/helpersBlock";
import {initEntry} from "./data/initEntryData";
import {adobeName} from "./utils/utils";

const useStyles = makeStyles((theme) => ({
    entryTab: {
        display: 'grid',
        height: '100%',
    },
}))


const initActionState = {
    isOpen: false,

    isCreate: false,
    isRemove: false,

};

function EntryTab() {
    const cl = useStyles();
    const CLM = useSelector((state) => state.CLM);
    const [action, setAction] = useState({...initActionState, selectedElement: -1,});
    const dispatch = useDispatch();
    const {values, setValues} = useContext(IndexContext)
    const [selectedEntry, setSelectedEntry] = useState(null)

    const expander = values.expander
    const entries = CLM?.index?.entries
    const indexSelectedEntry = values.selectedEntries


    const handlerCloseDialog = () => {
        setAction((prevState) => ({...prevState, ...initActionState,}));
    };


    const handlerOpenDialog = () => {
        setAction((prevState) => ({
            ...prevState,
            isOpen: true,
            selectedElement: -1
        }));
    };

    const handleExpander = (val) => {
        handleValueState(val, 'expander', setValues)
    }
    const handleSelectedEntries = (i) => {
        handleValueState(i, 'selectedEntries', setValues)
    }

    const handlerDelete = () => {
        setAction((prevState) => ({
            ...prevState,
            isOpen: true,
            isRemove: true,
            selectedElement: -1
        }));
    }



    const getEntries = (entries) => {
        if (!entries) {
            return
        }
        handleValueState(false, false, setValues, {
            selectedEntries: 0,
            entries,
        })
    }

    const handleEntry = () => {
        if (values.entries && values.entries.length === 0) {
            return handleValueState([selectedEntry], 'entries', setValues)
        }
        const res = values.entries.reduce((acu, cur, idx) => {
            if (idx === indexSelectedEntry) {
                return [...acu, selectedEntry]
            }
            return [...acu, cur]
        }, [])
        handleValueState(res, 'entries', setValues)
    }


    useEffect(() => {
        if (values.entries) {
            setSelectedEntry(values.entries[indexSelectedEntry])
        }
    }, [indexSelectedEntry])

    useEffect(() => {
        getEntries(entries)
    }, [entries])

    useEffect(() => {
        if (values.entries && values.entries.length === 0) {
            setSelectedEntry(initEntry)
            handleValueState(false, false, setValues, {selectedEntries: 0})
        }
    }, [values.entries, indexSelectedEntry]);

    useEffect(() => {
        if (selectedEntry) {
            handleEntry()
        }
    }, [selectedEntry])




    return (
        <div className={cl.entryTab} style={{gridTemplateColumns: gridBlock(expander)}}>
            <div style={{height: '100%'}}>
                {values.entries && <>{expander === 1
                    ? <div></div>
                    : <SideMenu
                        indexSelectedList={indexSelectedEntry}
                        setSelectedList={handleSelectedEntries}
                        list={values.entries.map(el => ({...el, name: adobeName(el)}))}
                        expander={expander}
                        setExpander={handleExpander}
                        addAction={handlerOpenDialog}
                        deleteAction={handlerDelete}
                    />}</>}
            </div>
            <div></div>
            <div>
                {selectedEntry && <EntryView
                    actionState={action}
                    entry={selectedEntry}
                    setEntry={setSelectedEntry}
                />}
            </div>

            {action.isOpen && (
                <EntryDialog
                    handleClose={handlerCloseDialog}
                    actionState={action}
                    entry={selectedEntry}
                    setEntry={setValues}
                />
            )}
        </div>
    );
}

export default EntryTab;
