import React, { useContext, useState } from "react";
import { Checkbox, FormControlLabel, Grid, TextField, Tab, Tabs } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TabsContext } from "../../../../Stylesheet";
import { handlePageLayoutChildObject } from "../../../../utils/helpers";
import LayoutModel from "../LayoutModel";
import TemplateItemRow from "../../../../TemplateItemRow";

const useStyles = makeStyles((theme) => ({
  wrapperCheckbox: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: 10,
    "& .MuiFormControlLabel-root": {
      marginBottom: 0
    }
  },
  itemRowWrapper: {
    display: "grid",
    gridRowGap: 10,
    marginTop: 10
  },
  wrapperRow: {
    display: "grid",
    gridTemplateColumns: "100px 1fr 55px"
  },
  containerCheckbox: {
    marginLeft: 10,
    marginTop: -10
  },
  templateTitle: {
    fontWeight: "700",
    marginBottom: 0
  },
  templateInput: {
    height: "Calc(100% - 540px)",
    width: "100%",
    "& .MuiInputBase-multiline.MuiInputBase-marginDense": {
      height: "100%"
    },
    "& .MuiOutlinedInput-inputMultiline": {
      height: "100% !important"
    }
  },
  tabsRoot: {
    minHeight: 18,
    marginBottom: 15,
    "& .MuiTab-root": {
      padding: "0px 4px 0px 3px",
      minWidth: "fit-content",
      minHeight: 18,
      background: "#EBEBEB"
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#ffffff",
      background: "#4db1d3",
      outline: "none"
    },
    "& .MuiTabs-centered": {
      justifyContent: "left",
      background: "#E3F2F7"
    }
  },
  blockInput: {
    height: "Calc(100% - 250px)",
    width: "100%",
    "& .MuiInputBase-multiline.MuiInputBase-marginDense": {
      height: "100%"
    },
    "& .MuiOutlinedInput-inputMultiline": {
      height: "100% !important"
    },
    "& .MuiInputBase-multiline": {
      height: "100%"
    },
    "& textarea": {
      height: "100% !important"
    }
  }
}));

function Footer() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const { footer } = values.stylesheet.blockSettings[values.selectedLeftMenu];
  const tabs = ["First page", "general"];
  const [selectedTab, setSelectedTab] = useState(0);

  // footer

  const handleCheckbox = (e) => {
    const val = e.target.checked;
    const { name } = e.target;

    if (name === "firstDifferent" && selectedTab !== 0 && footer.firstDifferent) {
      setSelectedTab(0);
    }
    handlePageLayoutChildObject(val, name, "footer", values, setValues);
  };

  const setValueTemplate = (e) => {
    const val = e.target.value;
    const { name } = e.target;
    handlePageLayoutChildObject(val, name, "footer", values, setValues);
  };

  const setValueProps = (val, name) => {
    handlePageLayoutChildObject(val, name, "footer", values, setValues, true);
  };
  const setDropMenu = (valDropMenu, name, val) => {
    handlePageLayoutChildObject(val, name, "footer", values, setValues, true, valDropMenu);
  };
  const handleChangeSelectedTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  if (!footer) {
    return;
  }

  console.log("footer", footer);

  return (
    <div style={{ height: "Calc(100% - 123px)" }}>
      <div className={cl.wrapperCheckbox}>
        <FormControlLabel
          control={<Checkbox onChange={handleCheckbox} checked={footer.visible} name="visible" />}
          label={<span>Use footer</span>}
        />
      </div>
      <div className={cl.wrapperCheckbox}>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={footer.firstDifferent}
                onChange={handleCheckbox}
                name="firstDifferent"
                disabled={!footer.visible}
              />
            }
            label={<span>Different footer on first page</span>}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={footer.firstDifferentInPageLayout}
                onChange={handleCheckbox}
                name="firstDifferentInPageLayout"
                disabled={!footer.visible}
              />
            }
            label={<span>in page layout</span>}
          />
        </div>
      </div>

      {footer.firstDifferent && (
        <Tabs
          indicatorColor=""
          value={selectedTab}
          className={cl.tabsRoot}
          onChange={handleChangeSelectedTab}
          textColor="primary"
          variant="standard"
          scrollButtons="auto"
          centered
        >
          {tabs.map((el, i) => (
            <Tab label={el} key={i} />
          ))}
        </Tabs>
      )}

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div className={cl.itemRowWrapper}>
            {selectedTab === 0 && <>
              <TemplateItemRow
                label="Spacing:"
                type={"firstSpacing"}
                value={footer.firstSpacing}
                grid="100px 1fr 55px"
                setValueProps={setValueProps}
                setDropMenu={setDropMenu}
                disabled={!footer.visible}
              />
              <TemplateItemRow
                label="Height:"
                type={"firstHeight"}
                value={footer.firstHeight}
                grid="100px 1fr 55px"
                setValueProps={setValueProps}
                setDropMenu={setDropMenu}
                disabled={!footer.visible}
              />
            </>}
            {selectedTab === 1 && <>
              <TemplateItemRow
                label="Spacing:"
                type={"spacing"}
                value={footer.spacing}
                grid="100px 1fr 55px"
                setValueProps={setValueProps}
                setDropMenu={setDropMenu}
                disabled={!footer.visible}
              />
              <TemplateItemRow
                label="Height:"
                type={"height"}
                value={footer.height}
                grid="100px 1fr 55px"
                setValueProps={setValueProps}
                setDropMenu={setDropMenu}
                disabled={!footer.visible}
              />
            </>}

            <div className={cl.wrapperRow}>
              <div />
              <div className={cl.containerCheckbox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckbox}
                      checked={footer.precedence}
                      name="precedence"
                      disabled={!footer.visible}
                    />
                  }
                  label={<span>Use entire page width</span>}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <LayoutModel />
        </Grid>
      </Grid>

      <div style={{ marginTop: "2rem" }} />
      {(selectedTab === 1 && footer.firstDifferent) || !footer.firstDifferent ? (
        <>
          <div>
            <p className={cl.templateTitle}>Content for odd pages:</p>
            <TextField
              value={footer.oddContent}
              className={cl.templateInput}
              variant="outlined"
              multiline
              minRows={9}
              onChange={setValueTemplate}
              name="oddContent"
              disabled={!footer.visible}
            />
          </div>
          <div>
            <p className={cl.templateTitle} style={{ marginTop: 10 }}>
              Content for even pages:
            </p>
            <TextField
              value={footer.evenContent}
              className={cl.templateInput}
              variant="outlined"
              multiline
              minRows={9}
              onChange={setValueTemplate}
              name="evenContent"
              disabled={!footer.visible}
            />
          </div>
        </>
      ) : (
        <>
          <p className={cl.templateTitle}>Content</p>
          <TextField
            value={footer.firstContent}
            onChange={setValueTemplate}
            name={"firstContent"}
            className={cl.blockInput}
            variant="outlined"
            multiline
            minRows={4}
            disabled={!footer.visible}
          />
        </>
      )}
    </div>
  );
}

export default Footer;
