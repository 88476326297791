import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import {
  loadCompaniesList,
  loadCompany,
  loadGroupsList,
  loadUser,
  storeUser
} from '@zert-packages/actions/coreReducers';
import UserSuggestBox from '@zert-packages/components/shared/UserSuggestBoxNew/UserSuggestBox';
import SelectField from '@zert-packages/components/shared/FormElements/SelectField';
import { FullField } from '@zert-packages/components/shared/FormElements/FullField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button, Tabs, TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { formStyle } from '@zert-packages/components/shared/FormElements/StylesHelper';
import { PasswordField } from '@zert-packages/components/shared/FormElements/PasswordField';
import { EmailField } from '@zert-packages/components/shared/FormElements/EmailField';
import Modal from '@zert-packages/components/shared/Modal/Modal';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MultiSelectField2 from '@zert-packages/components/shared/FormElements/MultiSelectField2';
import { TextField2 } from '@zert-packages/components/shared/FormElements/TextField2';
import Tab from '@mui/material/Tab';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import UserActionPlan from '../../common/UserActionPlan';

const roles = [
  {
    value: 'administrator',
    label: <FormattedMessage id="settings.role.administrator" defaultMessage="Super admin" />
  },

  {
    value: 'consumer',
    label: <FormattedMessage id="settings.role.consumer" defaultMessage="Consumer" />
  },
  {
    value: 'task-administrator',
    label: <FormattedMessage id="settings.role.taskAdmin" defaultMessage="Administrator" />
  },
  {
    value: 'producer',
    label: <FormattedMessage id="settings.role.producer" defaultMessage="Producer" />
  }
];

const getRoles = (myRole) => {
  if (myRole == 'consumer') {
    return roles.filter((role) => role.value == 'consumer');
  }
  if (myRole == 'producer') {
    return roles.filter((role) => role.value == 'producer' || role.value == 'consumer');
  }

  if (myRole != 'administrator') {
    return roles.filter((role) => role.value != 'administrator');
  }

  return roles;
};

const DATE_WITHOUT_LIMITS = 2100;

class UserEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      values: {
        amount: '',
        usermail: '',
        password: '',
        weight: '',
        weightRange: '',
        fullName: '',
        confirmedPassword: '',
        showPassword: false,
        selectedDate: null,
        accountExpire: null,
        role: 'consumer'
      },
      errors: {},
      mailErrors: {},
      elementId: -1,
      minPasswordLength: 8,
      isSpecialCharPassword: false,
      isUppercasePassword: false,
      isNumericPassword: false,
      groups: [],
      companiesSelected: [],
      allgroups: [],
      allCompanies: []
    };
    this.storeUser = this.storeUser.bind(this);

    // state change watch functions for each field
    this.setErrors = this.setErrors.bind(this);
    this.setValues = this.setValues.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleReplaceUntil = this.handleReplaceUntil.bind(this);
    this.handleReplaceFrom = this.handleReplaceFrom.bind(this);
    this.roleChanged = this.roleChanged.bind(this);
    this.setMailErrors = this.setMailErrors.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.onChangeUser = this.onChangeUser.bind(this);
    this.checkNulls = this.checkNulls.bind(this);
    this.handleGroups = this.handleGroups.bind(this);
    this.handleCompanies = this.handleCompanies.bind(this);
  }

  handleGroups = (state) => {
    // this.state.modulesSelected.push(state.value);
    this.setState({ groups: state });
  };

  handleCompanies = (state) => {
    // this.state.modulesSelected.push(state.value);
    this.setState({ companiesSelected: state });
  };

  componentDidMount() {
    this.props.dispatch(loadUser(this.props.companyid, this.props.userid));
    this.props.dispatch(loadGroupsList(this.props.companyid));
    this.props.dispatch(loadCompany(this.props.companyid));
    if (!this.props.companieslist || this.props.companieslist.length == 0) {
      this.props.dispatch(loadCompaniesList(false));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.companieslist &&
      this.props.groupslist &&
      this.state.user == null &&
      this.props.user &&
      this.props.userid == this.props.user.elementId
    ) {
      let roleLabel;
      const companies = [];

      Object.keys(roles).forEach((key) => {
        if (roles[key].value == this.props.user.role) {
          roleLabel = roles[key].label;
        }
      });

      this.props.companieslist.forEach((companySelected) => {
        this.props.user.companies.forEach((company) => {
          if (companySelected.elementId == company.elementId) {
            companies.push(companySelected);
          }
        });
      });

      this.setState({
        user: this.props.user,
        values: {
          amount: '',

          usermail: this.props.user.email,
          password: '',
          weight: '',
          weightRange: '',
          fullName: this.props.user.fullname,
          confirmedPassword: '',
          showPassword: false,
          accountExpire: this.props.user.accountExpire,

          role: this.props.user.role,
          replaceUntil: this.props.user.replaceUntil,
          replaceFrom: this.props.user.replaceFrom,
          initialEmail: this.props.user.email,
          activeDirectory: this.props.user.activeDirectory
        },
        companiesSelected: companies,
        active: this.props.user.active,
        temporaryReplacer: this.props.user.temporaryReplacer,
        groups: this.props.user.groups,
        allgroups: this.props.groupslist
      });
    }
  }

  storeUser() {
    const groups = [];
    this.state.groups.map((group) => {
      groups.push({
        name: group.name,
        elementId: group.id
      });
    });

    const user = {
      userName: this.props.user.userName,
      name: this.state.values.fullName,
      fullname: this.state.values.fullName,
      email: this.state.values.usermail,
      role: this.state.values.role,
      password: this.state.values.password,
      active: this.state.active,
      companies: this.state.companiesSelected,
      elementId: this.state.elementId,
      replaceFrom: this.state.values.replaceFrom,
      accountExpire: this.state.values.accountExpire,
      activeDirectory: this.state.activeDirectory,
      temporaryReplacer: !this.state.temporaryReplacer
        ? null
        : {
            userName: this.state.temporaryReplacer.userName
          },
      replaceUntil: this.state.values.replaceUntil,
      groups
    };

    async function storeUserAsync(props) {
      await storeUser(props.companyid, props.user.elementId, user);

      props.confirmUser();
    }

    this.setState({ storing: true });
    storeUserAsync(this.props);
  }

  setErrors(errors) {
    this.setState({ errors });
  }

  setMailErrors(errors) {
    this.setState({ mailErrors: errors });
  }

  setValues(values) {
    this.setState({ values });
  }

  handleDateChange = (date) => {
    this.setValues({
      ...this.state.values,
      accountExpire: date
    });
  };

  handleReplaceFrom = (date) => {
    this.setValues({
      ...this.state.values,
      replaceFrom: date
    });
  };

  handleReplaceUntil = (date) => {
    this.setValues({
      ...this.state.values,
      replaceUntil: date
    });
  };

  handleCheckBoxChange = (name) => (event) => {
    this.setState({ active: !this.state.active });
  };

  roleChanged = (state) => {
    this.setValues({
      ...this.state.values,
      role: state.value
    });
  };

  onChangeUser(user) {
    if (user && user.name) {
      this.setState({
        wasEdited: true,
        temporaryReplacer: user
      });
      if (!this.state.replaceFrom) {
      }
    } else {
    }
  }

  checkNulls = (errors) => {
    let hasNulls = false;
    const keys = Object.keys(errors);
    keys.map((key) => {
      if (errors[key]) {
        hasNulls = true;
      }
    });
    return hasNulls;
  };

  onChangeTabIndex = (e, tabIndex) => this.setState({ tabIndex });

  onChangeReplaceWithoutLimits = (e) => {
    const { checked } = e.target;
    this.setValues({
      ...this.state.values,
      replaceFrom: checked ? new Date() : null,
      replaceUntil: checked ? new Date(DATE_WITHOUT_LIMITS, 6, 0, 0, 0, 0, 0) : null
    });
  };

  render() {
    const { intl, classes, company } = this.props;
    const { mailErrors, errors, values, active, storing } = this.state;

    if (this.props.loading || !this.state.user || !company) {
      return <Loader />;
    }
    const formAccepted =
      ((values.usermail != null && values.usermail.length != 0) || !active) &&
      !this.checkNulls(errors) &&
      !this.checkNulls(mailErrors) &&
      ((values.fullName != null && values.fullName.length != 0) || !active);
    return (
      <div
        style={{
          height: 'calc(var(--vh, 1vh) * 100 - 62px)',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: '48px 1fr 56px',
          overflow: 'hidden'
        }}
      >
        <Tabs
          value={this.state.tabIndex}
          onChange={this.onChangeTabIndex}
          style={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
          }}
          textColor="primary"
          color="primary"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label={<FormattedMessage id="Administration.PersonalSettingsViews.TabMain" defaultMessage="Main" />} />

          <Tab
            label={
              <FormattedMessage id="Administration.PersonalSettingsViews.TabActionPlan" defaultMessage="Action plan" />
            }
          />
        </Tabs>

        {storing && (
          <Modal show>
            <Loader loaderAction={LoaderAction.Storing}>
              <FormattedMessage id="settings.usereditor.saving" defaultMessage="User" />
            </Loader>
          </Modal>
        )}

        {this.state.tabIndex === 0 && (
          <div className="myaccount-wrapper UserEditor__MainContent">
            <div className="question-block">
              <h3>
                <FormattedMessage id="settings.ue.caption.generalsetting" defaultMessage="General settings" />
              </h3>

              {/** Render the fullname form field passing the name validation fn * */}

              <TextField2
                required
                readOnly
                value={this.props.user.userName}
                id="userName"
                label={<FormattedMessage id="settings.usereditor.username" defaultMessage="User name" />}
              />

              <EmailField
                values={values}
                setValues={this.setValues}
                errors={mailErrors}
                readOnly={this.props.user.activeDirectory}
                setErrors={this.setMailErrors}
                required
              />

              <FullField
                values={values}
                readOnly={this.props.user.activeDirectory}
                setValues={this.setValues}
                errors={errors}
                setErrors={this.setErrors}
                required
              />
              {this.props.user.activeDirectory ? (
                <FormattedMessage
                  id="settings.usereditor.passowrd.ad"
                  defaultMessage="Password is managed via Azure AD"
                />
              ) : (
                <PasswordField
                  required
                  key="newuser.password"
                  name="password"
                  thresholdLength={company.passwordLength}
                  minStrength={3}
                  values={values}
                  setValues={this.setValues}
                  errors={errors}
                  setErrors={this.setErrors}
                  isSpecialCharPassword={company.specialCharPassword}
                  showLegend
                  isUppercasePassword={company.uppercasePassword}
                  isNumericPassword={company.numericPassword}
                />
              )}

              <SelectField
                values={getRoles(this.props.myUser.role)}
                onStateChanged={this.roleChanged}
                value={values.role}
                caption={<FormattedMessage id="useredito.dialog.roles" defaultMessage="Role" />}
              />
            </div>
            <div className="question-block">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={active}
                    onChange={this.handleCheckBoxChange('active')}
                    value={active}
                    color="primary"
                  />
                }
                label={intl.formatMessage({
                  id: 'user.editor',
                  defaultMessage: 'Active'
                })}
              />
            </div>

            <div className="question-block">
              <DatePicker
                disableToolbar
                variant="inline"
                margin="normal"
                ampm={false}
                id="date-picker-inline"
                label={<FormattedMessage id="datepicker.valid" defaultMessage="Valid until" />}
                invalidDateMessage={<FormattedMessage id="datepicker.invalid" defaultMessage="Invalid date format" />}
                maxDateMessage={
                  <FormattedMessage
                    id="datepicker.maxDateMessage"
                    defaultMessage="Date should not be after maximal date"
                  />
                }
                minDateMessage={
                  <FormattedMessage
                    id="datepicker.minDateMessage"
                    defaultMessage="Date should not be before minimal date"
                  />
                }
                value={values.accountExpire}
                onChange={this.handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                renderInput={(props) => <TextField {...props} />}
              />
            </div>
            <div className="question-block">
              <h3>
                <FormattedMessage id="settings.ue.caption.groupsettings" defaultMessage="Groups" />
              </h3>

              <MultiSelectField2
                fieldId="groups"
                value={this.state.groups}
                onStateChanged={this.handleGroups}
                options={this.state.allgroups}
              />
              {this.props.myUser && this.props.myUser.role == 'administrator' && this.props.companieslist && (
                <>
                  <h3>
                    <FormattedMessage id="settings.ue.caption.companies" defaultMessage="Companies" />
                  </h3>

                  <MultiSelectField2
                    fieldId="companies"
                    value={this.state.companiesSelected}
                    propName="displayName"
                    propId="elementId"
                    onStateChanged={this.handleCompanies}
                    options={this.props.companieslist}
                  />
                </>
              )}
            </div>
            <div className="question-block">
              <h3>
                <FormattedMessage id="usereditor.caption.temporaryreplacer" defaultMessage="Temporary replacer" />
              </h3>
              <UserSuggestBox
                readonly={this.props.readonly}
                onChange={this.onChangeUser}
                getUsers={getUsersQuery}
                getUserId={(user) => user.username}
                getUserLabel={(user) => user.name}
                initialValue={this.state.temporaryReplacer && this.state.temporaryReplacer.name}
                label={<FormattedMessage id="DelegateTasksSettingsDialog.UserLabel" defaultMessage="User" />}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={this.onChangeReplaceWithoutLimits}
                    checked={DATE_WITHOUT_LIMITS === new Date(values.replaceUntil).getFullYear()}
                    color="primary"
                  />
                }
                label={<FormattedMessage id="Administration.UserEditor.Permanent" defaultMessage="Permanent" />}
              />
              {DATE_WITHOUT_LIMITS !== new Date(values.replaceUntil).getFullYear() && (
                <div>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    margin="normal"
                    ampm={false}
                    id="date-picker-inline"
                    label={intl.formatMessage({
                      id: 'usereditor.from',
                      defaultMessage: 'From'
                    })}
                    invalidDateMessage={intl.formatMessage({
                      id: 'datepicker.invalid',
                      defaultMessage: 'Invalid date format'
                    })}
                    maxDateMessage={intl.formatMessage({
                      id: 'datepicker.maxDateMessage',
                      defaultMessage: 'Date should not be after maximal date'
                    })}
                    minDateMessage={intl.formatMessage({
                      id: 'datepicker.minDateMessage',
                      defaultMessage: 'Date should not be before minimal date'
                    })}
                    value={values.replaceFrom}
                    onChange={this.handleReplaceFrom}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    renderInput={(props) => <TextField {...props} />}
                  />

                  <DatePicker
                    disableToolbar
                    variant="inline"
                    margin="normal"
                    id="date-picker-inline"
                    ampm={false}
                    label={intl.formatMessage({
                      id: 'usereditor.to',
                      defaultMessage: 'To'
                    })}
                    invalidDateMessage={intl.formatMessage({
                      id: 'datepicker.invalid',
                      defaultMessage: 'Invalid date format'
                    })}
                    maxDateMessage={intl.formatMessage({
                      id: 'datepicker.maxDateMessage',
                      defaultMessage: 'Date should not be after maximal date'
                    })}
                    minDateMessage={intl.formatMessage({
                      id: 'datepicker.minDateMessage',
                      defaultMessage: 'Date should not be before minimal date'
                    })}
                    value={values.replaceUntil}
                    onChange={this.handleReplaceUntil}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {this.state.tabIndex === 1 && <UserActionPlan userName={this.props.user.userName} />}
        <div className="tableButtons">
          <Button variant="outlined" color="primary" onClick={this.storeUser} disabled={!formAccepted}>
            <FormattedMessage id="settings.ue.savesettings" defaultMessage="OK" />
          </Button>
          <Button color="primary" onClick={this.props.confirmUser}>
            <FormattedMessage id="settings.btn.cancel" defaultMessage="Cancel" />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading,
  groupslist: state.groupslist,
  user: state.user,
  myUser: state.myuser,

  company: state.company,
  companieslist: state.companieslist
});

export default withStyles(formStyle)(injectIntl(connect(mapStateToProps)(UserEditor)));
