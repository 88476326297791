import React from 'react';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { formStyle } from './StylesHelper';

const useStyles = makeStyles(formStyle);

export function FullField({ values, setValues, required, readOnly }) {
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const classes = useStyles();

  return (
    <FormControl required={required} className={classes.formControl}>
      <InputLabel htmlFor="fullname">
        <FormattedMessage id="settings.newuserdialog.fullname" defaultMessage="Full name" />
      </InputLabel>
      <Input
        id="fullname"
        value={values.fullName}
        fullWidth
        readOnly={readOnly}
        onChange={handleChange('fullName')}
        aria-describedby="fullname"
      />
    </FormControl>
  );
}
