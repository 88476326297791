import React, { useContext, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { PanelContext } from "../InfoElementWrapper/InfoElementWrapper";
import { findColorVersion } from "@zert-packages/utils/findColorVersion";


const useStyles = makeStyles((theme) => ({
  root: {
    width: 2,
    margin: '1px 2px',
    height: 'Calc(100% - 2px)',
  }
}));

const BorderTypeColor = ({ el , context, name=''}) => {
  const cl = useStyles();
  const [color, setColor] = useState({ background: null, backgroundColor: null });
  const { myuser, myLanguage } = useSelector((state) => state);
  const { values, } = useContext(context ? context : PanelContext);
  const findSocket = values.socket?.length > 0 && values.socket.find(sock => sock.elementEvent.elementId === el.id )
  //const test = values.socket?.length > 0 && values.socket.filter(sock => sock.elementEvent.elementId === el.id )


  useEffect(() => {
    setColor(findColorVersion(el,myuser, findSocket, myLanguage))
  }, [el , myLanguage]);


  return (<>
    <div name={name} className={cl.root} style={color}></div>
  </>);
};

export default BorderTypeColor;