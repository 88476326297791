import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { updateWantToSeeIntro } from '@zert-packages/actions/commonReducer';
import { useDispatch } from 'react-redux';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { finishIntroduction, useIntroduction } from '../IntroductionContext';
import { JoyrideBeaconComponentStart } from '../Introduction';
import TooltipLocale from '../TooltipLocale';
import checkIfStepTargetExist from '../../ElementSearcher/utils/checkIfStepTargetExist';

const id = 'search';
const formattedMessages = {
  'IntroSearch.NameSearch': (
    <FormattedMessage
      id="IntroSearch.NameSearch"
      defaultMessage="By expanding a search option the search option will be used. If you dont want to include that in the search just minimize the option again and redo your search"
    />
  ),
  'IntroSearch.TopMenu': (
    <FormattedMessage
      id="IntroSearch.TopMenu"
      defaultMessage="From this panel you can manipulate the selected items in the list, you select an item in the list by clicking on the icon of the left side of it. After that these actions can be used to move, rename remove etc."
    />
  )
};

function SearchIntro(wantToSeeIntro) {
  const [run, setRun] = useState(false);
  const dispatch = useDispatch();
  const [stepIndex, setStepIndex] = useState(0);
  const { startIntro } = useIntroduction(id, setRun);
  const steps = [
    {
      target: '[data-intro-id="Search.NameSearch"]',
      content: formattedMessages['IntroSearch.NameSearch'],
      placement: 'auto'
    },
    {
      target: '[data-intro-id="Search.TopMenu"]',
      content: formattedMessages['IntroSearch.TopMenu'],
      placement: 'auto'
    }
  ];
  useEffect(() => {
    if (wantToSeeIntro && !run) {
      setRun(true);
      setStepIndex(0);
    }
  }, [wantToSeeIntro]);
  const handleJoyrideCallback = (data) => {
    const { status, step, action, index, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (!checkIfStepTargetExist(step)) {
      setRun(false);
      dispatch(updateWantToSeeIntro(false));
      finishIntroduction(id);
      return;
    }
    if (finishedStatuses.includes(status)) {
      dispatch(updateWantToSeeIntro(false));
      finishIntroduction(id);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const newStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setRun(false);
      setStepIndex(newStepIndex);
      setTimeout(() => {
        setRun(true);
      }, 400);
    }
  };
  return {
    component: (
      <Joyride
        locale={TooltipLocale}
        callback={handleJoyrideCallback}
        beaconComponent={JoyrideBeaconComponentStart}
        continuous
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        showProgress
        showSkipButton
        styles={{
          overlay: {
            position: 'fixed'
          },
          options: {
            primaryColor: '#01A1C7'
          }
        }}
      />
    ),
    startIntro
  };
}

export default SearchIntro;
