import React from 'react';
import './imageRow.css';
import { basisEditorAttachment } from '@zert-packages/actions/api';
import { FormattedMessage } from 'react-intl';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import openAttachment from '@zert-packages/utils/openAttachment';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ConfirmationDialog from '../../common/dialogs/ConfirmationDialog';

function ImageRow({ itemImg, isInfo, removeBasis, readonly, callTo, openTo }) {
  const accessToken = window.localStorage.getItem('accessToken');
  const reqDest = callTo || basisEditorAttachment;

  const openItem = (item) => () => {
    openAttachment(item, openTo);
  };

  const remove = (item, index) => () => {
    if (removeBasis) {
      renderInExceptionRoot(ConfirmationDialog, {
        onConfirm: async () => {
          removeBasis(index);
        },
        contextText: (
          <FormattedMessage
            id="imageRow.confirmDelete"
            defaultMessage="Are you sure you want to remove {name} from this risk row?"
            values={{ name: item.name }}
          />
        ),
        confirmButtonText: <FormattedMessage id="imageRow.confirmDelete.ok" defaultMessage="Ok" />,
        titleText: <FormattedMessage id="imageRow.confirmDeleteTitle" defaultMessage="Warning" />
      });
    }
  };

  return (
    <>
      {itemImg && itemImg.length > 0 && (
        <div className="image-row" style={{ alignItems: 'end' }}>
          {itemImg.map((item, index) => {
            const imageDestination = `${
              reqDest + (!isInfo ? item.element.versionId : item.versionId)
            }?jwtToken=${accessToken}`;

            const actions = [];
            actions.push({
              id: 'open.image',
              name: <FormattedMessage id="open.question.basis" defaultMessage="Open" />,
              performAction: openItem(item)
            });
            removeBasis &&
              actions.push({
                id: 'delete.image',
                name: <FormattedMessage id="delete.question.basis" defaultMessage="Delete" />,
                performAction: remove(item, index),
                isHidden: readonly
              });

            return <ImageBox actions={actions} imageDestination={imageDestination} index={index} name={item.name} />;
          })}
        </div>
      )}
    </>
  );
}

function ImageBox({ actions, imageDestination, index, name }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [imageError, setImageError] = React.useState(false);
  const handleClickOption = (event, action) => {
    event.preventDefault();
    event.stopPropagation();
    handleClose();
    action.performAction();
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div className="image-box" onClick={handleClick} key={`basis_image${index}`}>
      {!imageError ? (
        <img
          src={imageDestination}
          className="dropdown-toggle rowMenu row-image"
          alt="No preview"
          id={`dropdownMenuButton${index}`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onError={handleImageError}
        />
      ) : (
        <div style={{ textAlign: 'center', padding: '2px', lineHeight: 1 }}>
          <InsertDriveFileIcon sx={{ fontSize: 75, color: 'gray' }} />
          <p style={{ userSelect: 'none', maxWidth: '125px', fontSize: '0.8rem' }}>{name}</p>
        </div>
      )}
      <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {actions
          .filter((action) => !action.isHidden)
          .map((action) => (
            <MenuItem key={action.id} onClick={(e) => handleClickOption(e, action)}>
              <Typography variant="inherit" noWrap>
                {action.name}
              </Typography>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

export default ImageRow;
