import { showSnackbarChangesSaved, showSnackbarError } from '@zert-packages/components/Snackbars';
import React from 'react';
import { renderInExceptionRoot } from '@zert-packages/utils';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { disableRAActions, enableRAActions, fetchRA_Success } from './riskassesmentActions';
import {
  handlePost,
  handlePostFile,
  handlePostResultAsString,
  handleQuery,
  handlePostTextAsParam,
  fetchRA_Error,
  handleText
} from './api';

const RENAME_NODE_DONE = 'RENAME_NODE_DONE';
const RENAME_NODE_START = 'RENAME_NODE_START';
const RENAME_NODE = 'RENAME_NODE';

const NODES_START_UPDATE = 'NODES_START_UPDATE';
const NODES_STOP_UPDATE = 'NODES_STOP_UPDATE';

const MOVE_NODE_DONE = 'MOVE_NODE_DONE';
const MOVE_NODE_START = 'MOVE_NODE_START';
const MOVE_NODE = 'MOVE_NODE';

const PASTE_NODE_DONE = 'PASTE_NODE_DONE';
const PASTE_NODE_START = 'PASTE_NODE_START';
const PASTE_NODE = 'PASTE_NODE';

const REMOVE_NODE_DONE = 'REMOVE_NODE_DONE';
const REMOVE_NODE_START = 'REMOVE_NODE_START';
const REMOVE_NODE = 'REMOVE_NODE';

const STORE_PARTICIPANTS_START = 'STORE_PARTICIPANTS_START';
const STORE_BASIS_START = 'STORE_BASIS_START';
const STORE_EVENTS_START = 'STORE_EVENTS_START';
const STORE_SESSIONS_START = 'STORE_SESSIONS_START';
const STORE_POLICY_START = 'STORE_POLICY_START';
const STORE_DESCRIPTION_START = 'STORE_DESCRIPTION_START';

const STORE_PARTICIPANTS_DONE = 'STORE_PARTICIPANTS_DONE';
const STORE_SESSIONS_DONE = 'STORE_SESSIONS_DONE';
const STORE_BASIS_DONE = 'STORE_BASIS_DONE';
const STORE_EVENTS_DONE = 'STORE_EVENTS_DONE';
const STORE_POLICY_DONE = 'STORE_POLICY_DONE';
const STORE_DESCRIPTION_DONE = 'STORE_DESCRIPTION_DONE';
const UPDATE_STATUS = 'UPDATE_STATUS';
const UPDATE_STATUS_DONE = 'UPDATE_STATUS_DONE';
const UPDATE_STATUS_START = 'UPDATE_STATUS_START';
const COPY_NODE = 'COPY_NODE';
const CUT_NODE = 'CUT_NODE';

const CUT_RISKROW = 'CUT_RISKROW';
const COPY_RISKROW = 'COPY_RISKROW';
const PASTE_RISKROW_DONE = 'PASTE_RISKROW_DONE';
const PASTE_RISKROW_START = 'PASTE_RISKROW_START';
const PASTE_RISKROW = 'PASTE_RISKROW';

const REMOVE_ROW = 'REMOVE_ROW';

const REMOVE_ROW_DONE = 'REMOVE_ROW_DONE';

const REMOVE_ROW_START = 'REMOVE_ROW_START';
const REMOVE_ROW_BASIS = 'REMOVE_ROW_BASIS';
const REMOVE_ROW_BASIS_START = 'REMOVE_ROW_BASIS_START';
const ADD_ROW_BASIS = 'ADD_ROW_BASIS';
const ADD_ROW_BASIS_DONE = 'ADD_ROW_BASIS_DONE';
const ADD_ROW_BASIS_START = 'ADD_ROW_BASIS_START';
const HASMATRIX_IMAGE = 'HASMATRIX_IMAGE';
const HASMATRIX_IMAGE_START = 'HASMATRIX_IMAGE_START';

export const REDUCER_RENAME_NODE_DONE = () => ({
  type: RENAME_NODE_DONE,
  payload: {}
});

const REDUCER_RENAME_NODE = (node_rename_uuid, new_node_name) => ({
  type: RENAME_NODE,
  payload: {
    node_rename_uuid,
    new_node_name
  }
});

const REDUCER_COPY_NODE = (copiedElements) => ({
  type: COPY_NODE,
  payload: {
    copiedElements
  }
});

const REDUCER_CUT_NODE = (copiedElements) => ({
  type: CUT_NODE,
  payload: {
    copiedElements
  }
});

const REDUCER_CUT_RISKROW = (copiedElements) => ({
  type: CUT_RISKROW,
  payload: {
    copiedElements
  }
});

const REDUCER_COPY_RISKROW = (copiedElements) => ({
  type: COPY_RISKROW,
  payload: {
    copiedElements
  }
});

export const REDUCER_RENAME_NODE_START = () => ({
  type: RENAME_NODE_START,
  payload: {}
});

export const REDUCER_START_UPDATE = () => ({
  type: NODES_START_UPDATE,
  payload: {}
});

export const REDUCER_STOP_UPDATE = () => ({
  type: NODES_STOP_UPDATE,
  payload: {}
});

const REDUCER_MOVE_NODE_DONE = () => ({
  type: MOVE_NODE_DONE,
  payload: {}
});

const REDUCER_MOVE_NODE = (node_move_uuid, direction, movein, moveout) => ({
  type: MOVE_NODE,
  payload: {
    node_move_uuid,
    direction,
    moveout,
    movein
  }
});

const REDUCER_MOVE_NODE_START = () => ({
  type: MOVE_NODE_START,
  payload: {}
});

const REDUCER_PASTE_NODE_START = () => ({
  type: PASTE_NODE_START,
  payload: {}
});

const REDUCER_PASTE_NODE_DONE = () => ({
  type: PASTE_NODE_DONE,
  payload: {}
});

const REDUCER_PASTE_RISKROW_DONE = () => ({
  type: PASTE_RISKROW_DONE,
  payload: {}
});
const REDUCER_PASTE_RISKROW_START = () => ({
  type: PASTE_RISKROW_START,
  payload: {}
});
const REDUCER_PASTE_RISKROW = (idRiskRow, after_pasted_node, old_uuid, riskrows, iscut) => ({
  type: PASTE_RISKROW,
  payload: {
    idRiskRow,
    old_uuid,
    after_pasted_node,
    riskrows,
    iscut
  }
});

const REDUCER_PASTE_NODE = (pasted_node, old_uuid, after_pasted_node, iscut) => ({
  type: PASTE_NODE,
  payload: {
    pasted_node,
    old_uuid,
    after_pasted_node,
    iscut
  }
});

const REDUCER_REMOVE_NODE_START = () => ({
  type: REMOVE_NODE_START,
  payload: {}
});

const REDUCER_REMOVE_NODE_DONE = () => ({
  type: REMOVE_NODE_DONE,
  payload: {}
});

const REDUCER_REMOVE_NODE = (node_remove_uuid) => ({
  type: REMOVE_NODE,
  payload: {
    node_remove_uuid
  }
});

const REDUCER_STORE_PARTICIPANTS_START = () => ({
  type: STORE_PARTICIPANTS_START,
  payload: {}
});
const REDUCER_STORE_BASIS_START = () => ({
  type: STORE_BASIS_START,
  payload: {}
});
const REDUCER_STORE_EVENTS_START = () => ({
  type: STORE_EVENTS_START,
  payload: {}
});
const REDUCER_STORE_SESSIONS_START = () => ({
  type: STORE_SESSIONS_START,
  payload: {}
});
const REDUCER_STORE_POLICY_START = () => ({
  type: STORE_POLICY_START,
  payload: {}
});
const REDUCER_STORE_DESCRIPTION_START = () => ({
  type: STORE_DESCRIPTION_START,
  payload: {}
});

const REDUCER_STORE_PARTICIPANTS_DONE = (participants) => ({
  type: STORE_PARTICIPANTS_DONE,
  payload: {participants}
});
const REDUCER_STORE_SESSIONS_DONE = (sessions) => ({
  type: STORE_SESSIONS_DONE,
  payload: {sessions}
});
const REDUCER_STORE_BASIS_DONE = () => ({
  type: STORE_BASIS_DONE,
  payload: {}
});
const REDUCER_STORE_EVENTS_DONE = () => ({
  type: STORE_EVENTS_DONE,
  payload: {}
});
const REDUCER_STORE_POLICY_DONE = () => ({
  type: STORE_POLICY_DONE,
  payload: {}
});
const REDUCER_STORE_DESCRIPTION_DONE = () => ({
  type: STORE_DESCRIPTION_DONE,
  payload: {}
});

const REDUCER_UPDATE_STATUS = (new_ra_id, status) => ({
  type: UPDATE_STATUS,
  payload: {
    new_ra_id,
    status
  }
});

const REDUCER_UPDATE_STATUS_DONE = () => ({
  type: UPDATE_STATUS_DONE,
  payload: {}
});

const REDUCER_UPDATE_STATUS_START = () => ({
  type: UPDATE_STATUS_START,
  payload: {}
});

const REDUCER_REMOVE_ROW = (idrow) => ({
  type: REMOVE_ROW,
  payload: { idrow }
});

const REDUCER_REMOVE_ROW_DONE = () => ({
  type: REMOVE_ROW_DONE,
  payload: {}
});

const REDUCER_REMOVE_ROW_START = () => ({
  type: REMOVE_ROW_START,
  payload: {}
});

const REDUCER_REMOVE_ROW_BASIS = () => ({
  type: REMOVE_ROW_BASIS,
  payload: {}
});

const REDUCER_REMOVE_ROW_BASIS_START = (elementId) => ({
  type: REMOVE_ROW_BASIS_START,
  payload: { elementId }
});

const REDUCER_ADD_ROW_BASIS = (basis, elementId) => ({
  type: ADD_ROW_BASIS,
  payload: {
    basis,
    elementId
  }
});

const REDUCER_ADD_ROW_BASIS_DONE = () => ({
  type: ADD_ROW_BASIS_DONE,
  payload: {}
});

const REDUCER_ADD_ROW_BASIS_START = (elementId) => ({
  type: ADD_ROW_BASIS_START,
  payload: { elementId }
});

const REDUCER_HASMATRIX_IMAGE = (
  hasMatrix,
  templateVersionId,
  matrixIdentifier,
  forceAutoGeneration,
  positionStr,
  columnIdentifier
) => ({
  type: HASMATRIX_IMAGE,
  payload: {
    hasMatrix,
    templateVersionId,
    matrixIdentifier,
    forceAutoGeneration,
    positionStr,
    columnIdentifier
  }
});

const REDUCER_HASMATRIX_IMAGE_START = () => ({
  type: HASMATRIX_IMAGE_START,
  payload: {}
});

export function hasMatrixImageById(
  templateVersionId,
  matrixIdentifier,
  forceAutoGeneration,
  positionStr,
  columnIdentifier
) {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return function (dispatch) {
    handleText(
      `/riskanalises/hasMatrixImageById/${templateVersionId}/${matrixIdentifier}/${forceAutoGeneration}/${locale}/${positionStr}/${columnIdentifier}`
    )
      .then((result) => {
        dispatch(
          REDUCER_HASMATRIX_IMAGE(
            result,
            templateVersionId,
            matrixIdentifier,
            forceAutoGeneration,
            positionStr,
            columnIdentifier
          )
        );
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_HASMATRIX_IMAGE_START());
  };
}

export function removeRowBasis(idAssesment, basisId, elementId) {
  return function (dispatch) {
    handleQuery(`/riskanalises/removeRowBasis/${idAssesment}/${basisId}`)
      .then(() => {
        dispatch(REDUCER_REMOVE_ROW_BASIS());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_REMOVE_ROW_BASIS_START(elementId));
  };
}

export function uploadRiskRowBasis(filedata, filename, versionId, elementId) {
  return function (dispatch) {
    handlePostFile(`/riskanalises/uploadRiskRowBasis/${versionId}`, filedata)
      .then((basis) => {
        dispatch(REDUCER_ADD_ROW_BASIS(basis, elementId));
        dispatch(REDUCER_ADD_ROW_BASIS_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_ADD_ROW_BASIS_START(elementId));
  };
}

export function storeRiskAnalisesParticipants(idAssesment, participants) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handlePostResultAsString(`/riskanalises/storeRiskAnalisisParticipants/${idAssesment}`, participants)
      .then(() => {
        dispatch(enableRAActions());
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_STORE_PARTICIPANTS_DONE(participants));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_PARTICIPANTS_START());
  };
}

export function storeRiskAnalisesBasises(idAssesment, basises) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handlePostResultAsString(`/riskanalises/storeRiskAnalisisBasises/${idAssesment}`, basises)
      .then(() => {
        dispatch(enableRAActions());
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_STORE_BASIS_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_BASIS_START());
  };
}

export function loadHistory(versionId, identifier) {
  return handleQuery(`/riskanalises/getPreviosVersionOfMatrix/${versionId}/${identifier}`);
}

export function loadRiskRow(elementId) {
  return function (dispatch) {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    handleQuery(`/riskanalises/getRowByElementId/${elementId}`)
      .then((riskrow) => {
        dispatch(REDUCER_RISK_ROW_SUCCESS(riskrow));
        dispatch(REDUCER_RISK_ROW_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_RISK_ROW_BEGIN());
  };
}

const RISK_ROW_DONE = 'RISK_ROW_DONE';
const RISK_ROW_BEGIN = 'RISK_ROW_BEGIN';
const RISK_ROW_SUCCESS = 'RISK_ROW_SUCCESS';

const REDUCER_RISK_ROW_BEGIN = () => ({
  type: RISK_ROW_BEGIN,
  payload: {}
});

const REDUCER_RISK_ROW_DONE = () => ({
  type: RISK_ROW_DONE,
  payload: {}
});

const REDUCER_RISK_ROW_SUCCESS = (updatedRiskrow) => ({
  type: RISK_ROW_SUCCESS,
  payload: { updatedRiskrow }
});

export function storeRiskAnalisesEvents(idAssesment, events, participants) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handlePostResultAsString(`/riskanalises/storeRiskAnalisisSessions/${idAssesment}`, events)
      .then(() => {
        return handlePostResultAsString(`/riskanalises/storeRiskAnalisisParticipants/${idAssesment}`, participants);
      })
      .then(() => {
        dispatch(enableRAActions());
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_STORE_EVENTS_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_EVENTS_START());
  };

}export function storeRiskAnalisesSessions(idAssesment, sessions, participants) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handlePostResultAsString(`/riskanalises/storeRiskAnalisisSessions/${idAssesment}`, sessions)
      .then(() => {
        dispatch(enableRAActions());
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_STORE_SESSIONS_DONE(sessions));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_SESSIONS_START());
  };
}

export function storeRiskAnalisesSessionsAndMembers(idAssesment, sessions, members) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handlePostResultAsString(`/riskanalises/storeRiskAnalisisSessions/${idAssesment}`, sessions)
      .then(() => {
        return handlePostResultAsString(`/riskanalises/storeRiskAnalisisParticipants/${idAssesment}`, members);
      })
      .then(() => {
        dispatch(enableRAActions());
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_STORE_SESSIONS_DONE(sessions));
        dispatch(REDUCER_STORE_PARTICIPANTS_DONE(members));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_EVENTS_START());
  };
}

export function updateRiskAnalisesStatus(idAssesment, status) {
  return function (dispatch) {
    handleQuery(`/riskanalises/updateStatus/${idAssesment}/${status}`)
      .then((id) => {
        dispatch(REDUCER_UPDATE_STATUS(id, status));
        dispatch(REDUCER_UPDATE_STATUS_DONE());
      })
      .catch((error) => {
        console.log('ERROR');
        console.log(error);
        console.log(error.message);
        if (error.type === 400) {
          renderInExceptionRoot(
            ({ unmountComponent, message }) => {
              return (
                <Dialog fullWidth maxWidth="md" open onClose={unmountComponent}>
                  <DialogTitle>
                    <FormattedMessage id="updateRiskAnalisesStatus.Error" defaultMessage="Error" />
                  </DialogTitle>
                  <DialogContent>
                    <div style={{ whiteSpace: 'pre-line' }}>{error.message}</div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={unmountComponent} color="primary">
                      <FormattedMessage id="updateRiskAnalisesStatus.Close" defaultMessage="Close" />
                    </Button>
                  </DialogActions>
                </Dialog>
              );
            },
            { message: error.message }
          );
          dispatch(REDUCER_UPDATE_STATUS_DONE());
        } else {
          dispatch(fetchRA_Error(error));
        }
      });
    return dispatch(REDUCER_UPDATE_STATUS_START());
  };
}

export function storeRiskAnalisesPolicy(idAssesment, policy) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handlePostTextAsParam(`/riskanalises/storeRiskAnalisesPolicy/${idAssesment}`, policy)
      .then(() => {
        dispatch(enableRAActions());
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_STORE_POLICY_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_POLICY_START());
  };
}

export function storeRiskAnalisesRestricted(idAssesment, restricted) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handleQuery(`/riskanalises/storeRiskAnalisesRestricted/${idAssesment}/${restricted}`)
      .then(() => {
        dispatch(enableRAActions());
        showSnackbarChangesSaved(hooks.snackbar);
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_POLICY_START());
  };
}

export function storeRiskAnalisesDescription(idAssesment, description) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handlePostTextAsParam(`/riskanalises/storeRiskAnalisesDescription/${idAssesment}`, description)
      .then(() => {
        dispatch(enableRAActions());
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_STORE_DESCRIPTION_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_STORE_DESCRIPTION_START());
  };
}

export function storeRiskAnalisesNodeName(id, uuid, data) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handlePostTextAsParam(`/riskanalises/storeRiskAnalisisNodeName/${id}/${uuid}`, data)
      .then(() => {
        dispatch(enableRAActions());
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_RENAME_NODE(uuid, data));
        dispatch(REDUCER_RENAME_NODE_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_RENAME_NODE_START());
  };
}

// @PathVariable("versionId") int versionId,
//			  @PathVariable("nodeuuid") String nodeuuid,
//	      @PathVariable("updown")boolean updown,
//	      @PathVariable("moveto")boolean moveto,
//	      @PathVariable("moveunder")boolean moveunder)

export const copyNode = (selectedNodes, isCuted) => async (dispatch, getState, hooks) => {
  await handlePost('/common/copy', selectedNodes);
  if (isCuted) {
    return dispatch(REDUCER_CUT_RISKROW(selectedNodes));
  }
  return dispatch(REDUCER_COPY_RISKROW(selectedNodes));
};

export const copyRiskRow = (selectedRiskRow, isCuted) => async (dispatch, getState, hooks) => {
  await handlePost('/common/copy', selectedRiskRow);
  if (isCuted) {
    return dispatch(REDUCER_CUT_RISKROW(selectedRiskRow));
  }
  return dispatch(REDUCER_COPY_RISKROW(selectedRiskRow));
};

export function moveNode(id, uuid, direction, moveout, movein) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handleQuery(`/riskanalises/moveNode/${id}/${uuid}/${direction}/${movein}/${moveout}`)
      .then(() => {
        dispatch(enableRAActions());
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_MOVE_NODE(uuid, direction, movein, moveout));
        dispatch(REDUCER_MOVE_NODE_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_MOVE_NODE_START());
  };
}

export async function pasteRiskRowAsync(idAssesment, index, uuid, isCuted, addedRiskRow, dispatch, snackbar) {
  // pasteRiskRow(this.props.idAssesment, this.props.riskrow.status.id, this.props.node.uuid, after_nodeuuid, this.props.isCuted, ids)

  dispatch(disableRAActions());
  try {
    const riskrow = await handlePost(
      `/riskanalises/pasteOneRiskRow/${idAssesment}/${index}/${uuid}/${isCuted}`,
      addedRiskRow
    );

    dispatch(enableRAActions());
    showSnackbarChangesSaved(snackbar);
    return riskrow;
  } catch (error) {
    dispatch(enableRAActions());
    showSnackbarError(snackbar, error.message);
  }
}

export function pasteRiskRow(idAssesment, idRow, uuid, afteruuid, iscut, ids) {
  // pasteRiskRow(this.props.idAssesment, this.props.riskrow.status.id, this.props.node.uuid, after_nodeuuid, this.props.isCuted, ids)
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handlePost(`/riskanalises/pasteRiskRow/${idAssesment}/${uuid}/${afteruuid}/${iscut}/${idRow}`, ids)
      .then((riskrows) => {
        dispatch(enableRAActions());
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_PASTE_RISKROW(idRow, uuid, afteruuid, riskrows, iscut));
        dispatch(REDUCER_PASTE_RISKROW_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_PASTE_RISKROW_START());
  };
}

export function pasteNode(id, uuid, afteruuid, iscut) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handlePost(`/riskanalises/pasteNode/${id}/${uuid}/${afteruuid}/${iscut}`)
      .then((node) => {
        dispatch(enableRAActions());
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_PASTE_NODE(node, uuid, afteruuid, iscut));
        dispatch(REDUCER_PASTE_NODE_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_PASTE_NODE_START());
  };
}

export async function moveNodeAsync(id, uuid, direction, moveout, movein, dispatch, snackbar) {
  try {
    dispatch(disableRAActions());
    await handleQuery(`/riskanalises/moveNode/${id}/${uuid}/${direction}/${movein}/${moveout}`);
    dispatch(enableRAActions());
    showSnackbarChangesSaved(snackbar);
  } catch (e) {
    dispatch(enableRAActions());
    showSnackbarError(snackbar, e.message);
    return null;
  }
}

export async function pasteNodeAsync(id, uuid, afteruuid, iscut, dispatch, snackbar, cellsMoved, idDuplicate) {
  try {
    dispatch(disableRAActions());
    const node = await handlePost(
      `/riskanalises/pasteNode/${id}/${idDuplicate}/${uuid}/${afteruuid}/${iscut}`,
      cellsMoved
    );
    dispatch(enableRAActions());
    showSnackbarChangesSaved(snackbar);

    return node;
  } catch (e) {
    dispatch(enableRAActions());
    showSnackbarError(snackbar, e.message);
    return null;
  }
}

export async function removeNodeAsync(id, uuid, dispatch, snackbar) {
  try {
    dispatch(disableRAActions());
    await handleQuery(`/riskanalises/removeNode/${id}/${uuid}`);
    dispatch(enableRAActions());
    showSnackbarChangesSaved(snackbar);
  } catch (e) {
    dispatch(enableRAActions());
    showSnackbarError(snackbar, e.message);
  }
}

export function removeNode(id, uuid) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handleQuery(`/riskanalises/removeNode/${id}/${uuid}`)
      .then(() => {
        dispatch(enableRAActions());
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_REMOVE_NODE(uuid));
        dispatch(REDUCER_REMOVE_NODE_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_REMOVE_NODE_START());
  };
}

const deleteRiskRow = (rootNode, nodeUUID, riskRowId) => {
  let cachedRiskRows = [];
  if (rootNode.uuid === nodeUUID) {
    cachedRiskRows = rootNode.cachedRiskRows.filter((r) => r.status.id !== riskRowId);
  } else {
    cachedRiskRows = rootNode.cachedRiskRows;
  }

  const children = rootNode.children.map((child) => deleteRiskRow(child, nodeUUID, riskRowId));
  return {
    ...rootNode,
    cachedRiskRows,
    children
  };
};

export const removeRiskRowFixedColumn =
  (riskAssessmentId, nodeUUID, riskRowId) => async (dispatch, getState, hooks) => {
    dispatch(disableRAActions());
    const deleteRiskRow = (rootNode) => {
      let cachedRiskRows = [];
      if (rootNode.uuid === nodeUUID) {
        cachedRiskRows = rootNode.cachedRiskRows.filter((r) => r.status.id !== riskRowId);
      } else {
        cachedRiskRows = rootNode.cachedRiskRows;
      }

      const children = rootNode.children.map((child) => deleteRiskRow(child));
      return {
        ...rootNode,
        cachedRiskRows,
        children
      };
    };
    const deleteResult = await handleQuery(`/riskanalises/deleteRow/${riskAssessmentId}/${nodeUUID}/${riskRowId}`);
    const RA = { ...getState().riskassesment };
    RA.rootNode = deleteRiskRow(RA.rootNode);

    dispatch(fetchRA_Success(RA));
    dispatch(enableRAActions());
    showSnackbarChangesSaved(hooks.snackbar);
  };

export function deleteRowAsync(id, nodeuuid, idrow, deleteFromSystem) {
  return handleQuery(`/riskanalises/deleteRow/${id}/${nodeuuid}/${idrow}/${deleteFromSystem}`);
}

export function deleteRow(id, nodeuuid, idrow) {
  return function (dispatch, getState, hooks) {
    dispatch(disableRAActions());
    handleQuery(`/riskanalises/deleteRow/${id}/${nodeuuid}/${idrow}`)
      .then(() => {
        dispatch(enableRAActions());
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(REDUCER_REMOVE_ROW(idrow));
        dispatch(REDUCER_REMOVE_ROW_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_REMOVE_ROW_START());
  };
}

export function riskstructureReducer(state, action) {
  switch (action.type) {
    case COPY_RISKROW:
      return {
        ...state,
        copiedElements: action.payload.copiedElements,
        isCuted: false
      };
    case CUT_RISKROW:
      return {
        ...state,
        copiedElements: action.payload.copiedElements,
        isCuted: true
      };
    case COPY_NODE:
      return {
        ...state,
        copiedElements: action.payload.copiedElements,
        isCuted: false
      };
    case CUT_NODE:
      return {
        ...state,
        copiedElements: action.payload.copiedElements,
        isCuted: true
      };
    case STORE_PARTICIPANTS_START:
      return {
        ...state,
        saving_risk_assesment: true
      };
    case STORE_BASIS_START:
      return {
        ...state,
        saving_risk_assesment: true
      };
    case STORE_EVENTS_START:
      return {
        ...state,
        saving_risk_assesment: true
      };
    case STORE_SESSIONS_START:
      return {
        ...state,
        saving_risk_assesment: true
      };
    case STORE_POLICY_START:
      return {
        ...state,
        saving_risk_assesment: true
      };
    case STORE_DESCRIPTION_START:
      return {
        ...state,
        saving_risk_assesment: true
      };
    case STORE_PARTICIPANTS_DONE:
      return {
        ...state,
        riskassesment: {...state.riskassesment,
          participants: action.payload.participants},
        saving_risk_assesment: false
      };
    case STORE_SESSIONS_DONE:
      return {
        ...state,
        riskassesment: {...state.riskassesment,
          sessions: action.payload.sessions},
        saving_risk_assesment: false
      };
    case STORE_BASIS_DONE:
      return {
        ...state,
        saving_risk_assesment: false
      };
    case STORE_EVENTS_DONE:
      return {
        ...state,
        saving_risk_assesment: false
      };
    case STORE_POLICY_DONE:
      return {
        ...state,
        saving_risk_assesment: false
      };
    case STORE_DESCRIPTION_DONE:
      return {
        ...state,
        saving_risk_assesment: false
      };

    case RENAME_NODE_DONE:
      return {
        ...state,
        node_rename_uuid: null,
        new_node_name: null,
        renaming_node: false
      };
    case RENAME_NODE_START:
      return {
        ...state,
        node_rename_uuid: null,
        new_node_name: null,
        renaming_node: true
      };
    case RENAME_NODE:
      return {
        ...state,
        node_rename_uuid: action.payload.node_rename_uuid,
        new_node_name: action.payload.new_node_name
      };

    case MOVE_NODE_DONE:
      return {
        ...state,
        move_node_uuid: null,
        move_node_direction: null,
        move_node_moveout: null,
        move_node_movein: null
      };
    case MOVE_NODE_START:
      return {
        ...state,
        move_node_uuid: null,
        move_node_direction: null,
        move_node_moveout: null,
        move_node_movein: null
      };
    case MOVE_NODE:
      return {
        ...state,
        move_node_uuid: action.payload.node_move_uuid,
        move_node_direction: action.payload.direction,
        move_node_moveout: action.payload.moveout,
        move_node_movein: action.payload.movein
      };

    case REMOVE_NODE_DONE:
      return {
        ...state,
        node_remove_uuid: null
      };
    case REMOVE_NODE_START:
      return {
        ...state,
        node_remove_uuid: null
      };
    case REMOVE_NODE:
      return {
        ...state,
        node_remove_uuid: action.payload.node_remove_uuid
      };

    case RISK_ROW_BEGIN:
      return {
        ...state,
        updatedRiskrow: null
      };
    case RISK_ROW_SUCCESS:
      return {
        ...state,
        updatedRiskrow: action.payload.updatedRiskrow
      };

    case RISK_ROW_DONE:
      return {
        ...state,
        updatedRiskrow: null
      };

    case PASTE_RISKROW_DONE:
      return {
        ...state,
        iscut: false,
        pasted_riskrow: null,
        old_uuid: null,
        pasteAfter: -1,
        copiedElements: state.iscut ? [] : state.copiedElements
      };
    case PASTE_RISKROW_START:
      return {
        ...state
      };
    case PASTE_RISKROW:
      return {
        ...state,
        pasteAfter: action.payload.idRiskRow,
        pasted_riskrow: action.payload.riskrows,
        old_uuid: action.payload.old_uuid,
        after_pasted_node: action.payload.after_pasted_node,
        iscut: action.payload.iscut
      };

    case HASMATRIX_IMAGE:
      state.matrixes[
        `${action.payload.templateVersionId}_${action.payload.columnIdentifier}_${action.payload.positionStr}`
      ] = action.payload.hasMatrix;
      var newArray = [];
      Object.assign(newArray, state.matrixes);
      return {
        ...state,
        matrixes: newArray
      };

    case PASTE_NODE_DONE:
      return {
        ...state,
        iscut: false,
        pasted_node: null,
        old_uuid: null,
        copiedElements: []
      };
    case PASTE_NODE_START:
      return {
        ...state
      };
    case PASTE_NODE:
      return {
        ...state,
        pasted_node: action.payload.pasted_node,
        old_uuid: action.payload.old_uuid,
        after_pasted_node: action.payload.after_pasted_node,
        iscut: action.payload.iscut
      };

    case UPDATE_STATUS:
      return {
        ...state,
        new_ra_id: action.payload.new_ra_id,
        statusra: action.payload.status,
        updating_status: false
      };

    case UPDATE_STATUS_DONE:
      return {
        ...state,
        updating_status: false
        //  new_ra_id: null,
        //   statusra: null
      };

    case UPDATE_STATUS_START:
      return {
        ...state,
        updating_status: true
      };

    case REMOVE_ROW:
      return {
        ...state
      };

    case REMOVE_ROW_DONE:
      return {
        ...state
      };

    case REMOVE_ROW_START:
      return {
        ...state
      };

    case REMOVE_ROW_BASIS:
      return {
        ...state,
        updating_row: false
      };

    case REMOVE_ROW_BASIS_START:
      return {
        ...state,
        updating_row: true,
        focusedId: action.payload.elementId
      };

    case ADD_ROW_BASIS:
      return {
        ...state,
        new_rr_basis: action.payload.basis,
        focusedId: action.payload.elementId,
        updating_row: true
      };
    case ADD_ROW_BASIS_DONE:
      return {
        ...state,
        updating_row: false,
        new_rr_basis: null
      };
    case ADD_ROW_BASIS_START:
      return {
        ...state,
        updating_row: true,
        new_rr_basis: null,
        focusedId: action.payload.elementId
      };
    case NODES_START_UPDATE:
      return {
        ...state,
        wasUpdated: true
      };

    case NODES_STOP_UPDATE:
      return {
        ...state,
        wasUpdated: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
