import { handleQuery, handleQueryFileAsResultHeaders } from "@zert-packages/actions/api";

export const API_ROOT_CALLS = process.env.REACT_APP_API_ROOT_CALLS;

export const getImageLoad = (versionId) => {
  return handleQuery(`/image/load/${versionId}`);
}

export const getImageContent = async (versionId) => {
  return handleQueryFileAsResultHeaders(`/image/getImageContentById/${versionId}`);
};
