import { scrollIntoViewCenter } from '@zert-packages/components/common/Introduction/Introduction';

export default function smartScrollIntoViewCenter(node, scrolledY) {
  if (isNodeVisible(node)) return;
  scrollIntoViewCenter(node, scrolledY);
}

export function isNodeVisible(node) {
  const rect = node.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  return elemTop >= 0 && elemBottom <= window.innerHeight;
}
