import React, {useContext, useEffect} from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import handleValueState from "@zert-packages/utils/handleValueState";
import { ButtonCLM } from "@zert-packages/components";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import ImportTextDialog from "./components/dialog/ImportTextDialog";
import RemoveWorkingCopyDialog from "./components/dialog/RemoveWorkingCopyDialog";
import AlertTextDialog from "./components/dialog/AlertTextDialog";
import {copyElementBlock, pastElementBlock} from "@zert-packages/components/InfoElement/helpers/ToolBarAction";
import {pasteElementBlockApi, storeBlockApi} from "@zert-packages/components/InfoElement/components/API";
import BufferAlertDialog from "./components/dialog/BufferAlertDialog";
import {createInProgressSnack} from "@zert-packages/components/common/snackBar";
import {useSnackbar} from "notistack";
import { v4 as uuidv4 } from 'uuid';
import {useRoveFocus} from "@zert-packages/utils";



const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
    marginTop: 30,
    marginBottom: 10,
    maxWidth: 'fit-content',
    '& button': {
      textTransform: 'lowercase',
    },
  }
}));

const getButtons = (values) => {
  return [
    {
      label: <FormattedMessage id="ElementTile.Actions.Copy" defaultMessage="Copy" />,
      type: "Copy",
      show: values.treeItem && values.treeItem.parentTreeItem,
    },
    {
      label: <FormattedMessage id="ElementTile.Actions.Paste" defaultMessage="Paste" />,
      type: "Paste",
      show: values.treeItem && values.treeItem.parentTreeItem,
    },
    // {
    //   label: <FormattedMessage id="ElementTile.Actions.RemoveWorkingCopy" defaultMessage="Remove working copy" />,
    //   type: "RemoveWorkingCopy",
    //   show: values.parentBlock,
    // },
    {
      label: <FormattedMessage id="ElementTile.Actions.ImportPhrases" defaultMessage="Import phrases" />,
      type: "ImportPhrases",
      show: true,
    },
    {
      label: <FormattedMessage id="ElementTile.Actions.ImportTable" defaultMessage="Import table" />,
      type: "ImportTable",
      show: true,
    }
  ].filter(el => el.show);
};

const FooterSideBar = () => {
  const cl = useStyles();
  const { values, setValues } = useContext(PanelContext);
  const snackbar = useSnackbar();
  const {insert} = useRoveFocus()


  useEffect(() => {
    if(values.treeItem?.parentTreeItem && insert){
      pastElementBlock()
    }
  }, [insert])

  const pastElementBlock = async () => {
    console.log('111111')
    const res = await pasteElementBlockApi();
    if (!res.elementInfo) {
      return  renderInExceptionRoot(BufferAlertDialog, {});
    }
    if(res.parentTreeItem){
      const text = 'An element tried to refrence itself, this is not allowed, operation aborted'
      return renderInExceptionRoot(AlertTextDialog, { text, cancel: false  });
    }
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..."/>
    );

    const elementSave = values.parentItemChild
    const createItemData = {
      name: elementSave.name,
      targetLocale: elementSave.targetLocale,
      format: elementSave.format
    };
    const adobeRes = {...res, identifier: `zert:Item[${uuidv4()}]`}
    const items = [...elementSave.items, adobeRes]
    // identifier


    await storeBlockApi(elementSave.elementInfo.versionId, elementSave.targetLocale.language, {
      ...createItemData,
      items,
    });

    setValues(pre => ({...pre, treeItem: adobeRes, investmentLevel:  1, parentItemChild: {
      ...pre.parentItemChild, items
      },
      action: {...pre.action, parentItemCreate: {...pre.parentItemChild, items}},
    }));
    snackbar.closeSnackbar(generatingSnackbar);
  }



  const handleButton =async (type) => {
    if(type === "RemoveWorkingCopy"){
      renderInExceptionRoot(RemoveWorkingCopyDialog, {values})
    }
    if(type === "ImportPhrases" || type === "ImportTable"){
      renderInExceptionRoot(ImportTextDialog, {type})
    }
    if(type ===  "Paste"){
      await pastElementBlock()
      // handleValueState({  }, 'pasteMainParent', setValues)
    }
    if(type === "Copy"){
      await copyElementBlock(values);
      // handleValueState({actionType: 'copy'}, 'copyMainParent', setValues)
    }
  }

  return (<div className={cl.wrapper}>
    {getButtons(values).map((el, i) =>
      <ButtonCLM
        key={i}
        style={{height: 25, minWidth: 59}}
        label={el.label}
        type={el.type}
        handle={handleButton}
      />
    )}
  </div>);

};

export default FooterSideBar;