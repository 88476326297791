import React, { useContext, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { TabsContext } from '../../Stylesheet';
import { adobeWord, handleTableSingleLevel, handleType } from '../../utils/helpers';
import FontImage from './FontImage';
import Decorations from '../table/decorations/Decorations';
import TemplateItemRow from '../../TemplateItemRow';
import LeftImageBlock from './LeftImageBlock';
import Loading from '../../Loading';
import { normalData } from '../../data/image/normalData';
import { userDefinedData } from '../../data/image/userDefinedData';

const useStyles = makeStyles((theme) => ({
  wrapperDropMenu: {
    marginBottom: 10
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10,
    marginTop: 10
  },
  title: {
    fontSize: '1rem',
    fontWeight: '700',
    marginBottom: 5
  },
  borderBlock: {
    border: '1px solid #C6C6C5',
    padding: '5px 10px',
    display: 'grid',
    gridRowGap: 10
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10
  },
  borderBlockRow: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
    gridColumnGap: 10
  },
  wrapperBlock: {
    display: 'grid',
    gridRowGap: '10px'
  },
  wrapperTemplate: {
    height: 'Calc(100% - 88px)'
  },
  templateInput: {
    height: '100%',
    width: '100%',
    marginBottom: 0,
    '& .MuiInputBase-multiline.MuiInputBase-marginDense': {
      height: '100%'
    },
    "& textarea": {
      height: '100% !important'
    },
    '& .MuiInputBase-root': {
      height: '100%',
    },
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100% !important'
    }
  }
}));

function Image() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [selectedTable, setSelectedTable] = useState('User defined');
  const image = values.stylesheet.imageSettings[values.selectedLeftMenu];
  const [selectedContentLimits, setSelectedContentLimits] = useState({
    maxWidth: 'User defined',
    maxHeight: 'User defined'
  });

  const handleBlockTabs = (val) => {
    if (val === selectedTable) {
      return;
    }
    if (val === 'Normal') {
      handleType(normalData, 'imageSettings', values, setValues);
    }
    if (val === 'User defined') {
      handleType(userDefinedData, 'imageSettings', values, setValues);
    }
    setSelectedTable(val);
  };

  const handleExpanderItem = (val, name) => {
    handleTableSingleLevel(val, name, 'imageSettings', values, setValues);
  };

  const handleValuesMaximum = (val, name) => {
    const result = { ...image[name], unit: image[name] ? image[name].unit : 'pt', value: +val };
    handleTableSingleLevel(result, name, 'imageSettings', values, setValues);
  };

  const handleDropMenuMaximum = (valDropMenu, name, val) => {
    const result = { unit: valDropMenu, value: +val };
    handleTableSingleLevel(result, name, 'imageSettings', values, setValues);
  };

  const setDropMenuFirstMaximum = (val, name) => {
    if (val === 'Default') {
      const result = { unit: 'pt', value: 12 };
      handleTableSingleLevel(result, name, 'imageSettings', values, setValues);
    }
    setSelectedContentLimits((pre) => ({ ...pre, [name]: val }));
  };

  const findMaximumSize = () => {
    if (!image.maxWidth && !image.maxHeight) {
      return setSelectedContentLimits({ maxWidth: 'Default', maxHeight: 'Default' });
    }
    if (!image.maxWidth) {
      return setSelectedContentLimits((pre) => ({ ...pre, maxWidth: 'Default' }));
    }
    if (!image.maxHeight) {
      setSelectedContentLimits((pre) => ({ ...pre, maxHeight: 'Default' }));
    }
  };

  const handleXSL = (e) => {
    const val = e.target.value;
    handleTableSingleLevel(val, 'template', 'imageSettings', values, setValues);
  };

  useEffect(() => {
    findMaximumSize();
  }, [image]);

  useEffect(() => {
    if (image && image.class.includes('Normal')) {
      setSelectedTable('Normal');
    } else {
      setSelectedTable('User defined');
    }
  }, [image && image.class]);

  return (
    <>
      {image ? (
        <>
          <ExpanderItem valueProps={selectedTable} propsLabel={['Normal', 'User defined']} setProps={handleBlockTabs} />

          {image.class.includes('Normal') ? (
            <div className={cl.wrapper}>
              <div className={cl.wrapperBlock}>
                <div>
                  <FontImage font={image} />
                  <p className={cl.title}>Alignment</p>
                  <div className={cl.borderBlock}>
                    <div className={cl.borderBlockRow}>
                      <p className={cl.title}>Align:</p>
                      <ExpanderItem
                        valueProps={adobeWord(image.imageAlign, true)}
                        propsLabel={['None', 'Left', 'Center', 'Right', 'Justify']}
                        setProps={handleExpanderItem}
                        type="imageAlign"
                      />
                    </div>
                  </div>
                  <Decorations type="imageSettings" table={image} setTable={handleTableSingleLevel} />
                  <div>
                    <p className={cl.title}>Maximum size</p>
                    <div className={cl.borderBlock}>
                      <TemplateItemRow
                        label="Widow:"
                        type="maxWidth"
                        value={{
                          value: image.maxWidth ? image.maxWidth.value : '12',
                          unit: image.maxWidth ? image.maxWidth.unit : 'POINTS'
                        }}
                        dropMenuFirst={{
                          label: ['Default', 'User defined'],
                          selected: selectedContentLimits.maxWidth
                        }}
                        grid="100px 150px 1fr 55px"
                        disabled={selectedContentLimits.maxWidth === 'Default'}
                        setDropMenuFirst={setDropMenuFirstMaximum}
                        setValueProps={handleValuesMaximum}
                        setDropMenu={handleDropMenuMaximum}
                      />
                      <TemplateItemRow
                        label="Height:"
                        type="maxHeight"
                        value={{
                          value: image.maxHeight ? image.maxHeight.value : '12',
                          unit: image.maxHeight ? image.maxHeight.unit : 'POINTS'
                        }}
                        dropMenuFirst={{
                          label: ['Default', 'User defined'],
                          selected: selectedContentLimits.maxHeight
                        }}
                        grid="100px 150px 1fr 55px"
                        disabled={selectedContentLimits.maxHeight === 'Default'}
                        setDropMenuFirst={setDropMenuFirstMaximum}
                        setValueProps={handleValuesMaximum}
                        setDropMenu={handleDropMenuMaximum}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <LeftImageBlock image={image} />
            </div>
          ) : (
            <div className={cl.wrapperTemplate}>
              <TextField
                className={cl.templateInput}
                value={image.template}
                variant="outlined"
                multiline
                minRows={4}
                onChange={handleXSL}
              />
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Image;
