import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GTranslateIcon from '@mui/icons-material/GTranslate';

const useStyles = makeStyles((theme) => ({
  wrapperInput: (props) =>({
    display: 'grid',
    gridTemplateColumns:  '100px 1fr 25px',
    // gridTemplateColumns:  props.icons ? '100px 1fr 25px': '100px 1fr',
    marginBottom: 10,
    gridColumnGap: 10,
    alignItems: 'center'
  }),
  label: {
    fontWeight: 900
  },
  noteActiveInputWrapper: {
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  notesInput: {
    margin: 0,
    width: '100%'
  },
  bgBlockSelected: {
    height: '100%',
    width: '100%',
    border: '1px solid #000000',
    borderRadius: 5
  },
  iconWrapper: {
    cursor: 'pointer',
    '& ': {
      fontSize: 22,
      position: 'relative',
      top: 2
    }
  },
  iconsWrapper: {
    cursor: 'pointer'
  }
}));

function InputItem({
  label,
  valueProps = '',
  disabled,
  style = '',
  focus,
  setValueProps,
  selectedColor,
  switchLanguages,
  Identifier,
  icons,
   classStyle,
  type
}) {
  const cl = useStyles({icons});
  const [value, setValue] = useState('');

  const handleChangeNote = (event) => {
    const val = type ==='number'
      ? event.target.value.replace(/[^.\d]+/g, "")
      : event.target.value
    setValue(val);
    if (setValueProps && Identifier) {
      setValueProps(val, Identifier);
    }
  };

  function checkIsAllowedUpdateLanguages(locales, languages) {
    if (languages === null) return true;
    const val1 = locales[languages.indexOfEnglish] !== 'en';
    const val2 = locales[languages.indexOfSwedish] !== 'sv';
    if (val1) return true;
    if (val2) return true;
    return false;
  }

  const [languages, setLanguages] = useState(null);
  const [isTranslation, setIsTranslation] = useState(true);
  const language = document.getElementsByTagName('html')[0].getAttribute('lang');

  function getIndexOfTranslation() {
    if (languages === null) return 0;
    if (languages.indexOfEnglish === -1) return 0;
    if (languages.indexOfSwedish === -1) return 0;

    if (language === 'sv') {
      return isTranslation ? languages.indexOfSwedish : languages.indexOfEnglish;
    }

    if (language === 'en') {
      return isTranslation ? languages.indexOfEnglish : languages.indexOfSwedish;
    }
  }

  const indexOfTranslation = getIndexOfTranslation();

  const findIndexOfLanguages = (locales) => {
    if (checkIsAllowedUpdateLanguages(locales, languages)) {
      const indexOfEnglish = locales.findIndex((language) => language === 'en');
      const indexOfSwedish = locales.findIndex((language) => language === 'sv');
      setLanguages({
        indexOfEnglish,
        indexOfSwedish
      });
    }
  };

  const toggleTranslation = () => {
    if (switchLanguages && switchLanguages.locales) {
      const { indexOfEnglish, indexOfSwedish } = languages;
      if (indexOfEnglish === -1 || indexOfSwedish === -1) return;
      setIsTranslation((prev) => !prev);
    }
  };

  useEffect(() => {
    if (switchLanguages && switchLanguages.locales) {
      findIndexOfLanguages(switchLanguages.locales);
    }
  }, []);

  useEffect(() => {
    if (switchLanguages && switchLanguages.translations) {
      setValue(switchLanguages.translations[indexOfTranslation]);
    }
  }, [isTranslation]);

  useEffect(() => {
    setValue(valueProps);
  }, [valueProps]);

  return (
    <div className={classStyle || cl.wrapperInput } style={{ gridTemplateColumns: style, }}>
      <div className={cl.label}>{label}</div>
      {selectedColor && <div className={cl.bgBlockSelected} style={{ background: selectedColor }} />}
      <div className={cl.noteActiveInputWrapper}>
        <TextField
          inputRef={(input) => focus && input && input.focus()}
          disabled={disabled}
          value={value}
          className={cl.notesInput}
          variant="outlined"
          onChange={handleChangeNote}
          multiline
        />
      </div>
      {switchLanguages && switchLanguages.locales && (
        <div className={cl.iconWrapper}>
          <GTranslateIcon onClick={toggleTranslation} style={{ color: isTranslation ? 'gray' : 'blue' }} />
        </div>
      )}
      {icons && <span className={cl.iconsWrapper}>{icons}</span>}
    </div>
  );
}

export default InputItem;
