import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import {useParams} from "react-router-dom";
import {getMetaDataApi} from "./api";
import handleValueState from "@zert-packages/utils/handleValueState";
import MetaDataTabs from "./MetaDataTabs";
import Box from "@mui/material/Box";
import {Tabs} from "@mui/material";
import Tab from "@mui/material/Tab";
import ConnectionsTabs from "./ConnectionsTabs";
import TabsWithVersionController from "@zert-packages/components/common/TabsWithVersionController";
import {generatePreviewReport} from "../PreviewPlugin/servicesMiddlewares";
import {generateTermDBExport} from "../TermDBPlugin/serviceMiddleware";
import useVersionManager from "@zert-packages/utils/useVersionManager";
import {getVersionInfo} from "@zert-packages/actions/coreReducers";
import {turnOnCLMLoader} from "../TranslationJobPlugin/tjReducers";
import {fetchIndex} from "../IndexPlugin/servicesMiddlewares";
import get from "lodash/get";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        width: '100%',
        height: 'Calc(100% - 50px)',
        padding: '10px 12px',
    },
    tabs: {
        minHeight: 18,
        '& .MuiTab-root': {
            padding: '0px 4px 0px 3px',
            minWidth: 'fit-content',
            minHeight: 18,
            background: '#EBEBEB',
            textTransform: "capitalize",
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: '#ffffff',
            background: '#4db1d3',
            outline: 'none'
        },
        '& .MuiTabs-centered': {
            justifyContent: 'left',
            background: '#E3F2F7'
        }
    },
}))

const MetaDataView = (props) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const routeVersionId = get(props, 'match.params.versionId', null);
    const {versionId} = useParams();
    const {mylocale} = useSelector((state) => state)
    const [versionManager, versionInfo] = useVersionManager();
    const [metaData, setMetadata] = useState({
        meta: null,
        selectedTab: 1,
        selectedElement: 0,
        selectedConnections: 0,
    })



    const getMetaData = async () => {
        const getMeta = await getMetaDataApi(versionId, mylocale)
        if (!getMeta) {
            return
        }
        handleValueState(getMeta, 'meta', setMetadata)
    }

    const handleTab = (e, val) => {
        handleValueState(val, 'selectedTab', setMetadata)
    }


    const saveMetaData = () => {
        console.log('saveMetaData')
    }



    useEffect(() => {
        if (routeVersionId === null) return;
        dispatch(getVersionInfo(routeVersionId));
        dispatch(turnOnCLMLoader());
    }, [routeVersionId]);

    useEffect(() => {
        getMetaData()
    }, []);


    return (
        <div>
            <TabsWithVersionController
                canCreateVersion={versionManager.canCreateNewVersion}
                canSaveVersion={versionManager.canSaveVersion}
                canApproveVersion={versionManager.canApproveVersion}
                onCreateNewVersion={versionManager.createNewVersion}
                onApproveVersion={versionManager.approveVersion}
                onSaveVersion={saveMetaData}
                canPreview
                onPreview={() => {
                    dispatch(generatePreviewReport(versionInfo.info, -1));
                }}
                canExport
                onExport={() => {
                    dispatch(generateTermDBExport(versionId));
                }}
                tabs={[]}
            />
            <div className={cl.mainContainer}>
                <Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom: '5px',}}>
                    <Tabs
                        value={metaData.selectedTab}
                        className={cl.tabs}
                        onChange={handleTab}
                    >
                        <Tab label="Metadata" value={0}/>
                        <Tab label="Connections" value={1}/>
                    </Tabs>
                </Box>
                {metaData.selectedTab === 0 && <MetaDataTabs
                    metaData={metaData}
                    setMetadata={setMetadata}
                />}
                {metaData.selectedTab === 1 && <ConnectionsTabs
                    metaData={metaData}
                    setMetadata={setMetadata}
                />}
            </div>
        </div>)
}

export default MetaDataView