import React, { useContext, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { TabsContext } from "./FormatTemplatePlugin";
import DialogSettings from "./dialog/dialogSettings/DialogSettings";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogAlertText from "./dialog/DialogAlertText";
import { adobeName } from "./utils/main";

const useStyles = makeStyles((theme) => ({
  wrapperInput: {
    display: "grid",
    gridTemplateColumns: "100px 1fr 25px",
    marginBottom: 10
  },
  label: {
    fontWeight: 900
  },
  listWrapper: {
    border: "1px solid #BDBDBC",
    margin: "0px 10px",
    borderRadius: 5,
    overflow: "auto",
    minHeight: 200,
    maxHeight: 200
  },
  listWrapperScroll: {},
  wrapperTitle: {
    padding: "2px 4px",
    cursor: "pointer"
  },
  wrapperTitleSelected: {
    padding: "2px 4px",
    cursor: "pointer",
    background: "#4db1d3"
  },

  wrapperEditor: {
    color: "#4db1d3",
    "& svg": {
      cursor: "pointer"
    }
  }
}));

function SupportedFormats({ label, formats, typeList='settings', disable }) {
  const cl = useStyles();
  const [selectedSettings, setSelectSettings] = useState(0);
  const {  setValues } = useContext(TabsContext);

  const handleSelectedTab = (i) => {
    setSelectSettings(i);
  };

  const handleOpenDialog = (type) => {
    if (type === "edit") {
      if(!formats[selectedSettings]){
        return
      }

      return  renderInExceptionRoot(DialogSettings, {
        label: "Edit property",
        formats,
        selectedSettings: formats[ selectedSettings],
        setValues,
        indexSelected: selectedSettings,
        typeSettings: typeList,
      })
    }
    renderInExceptionRoot(DialogSettings, {
      label: "Add property",
      formats,
      selectedSettings:{
        class: "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Hidden",
      },
      addSetting: true,
      // selectedSettings: formats[ selectedSettings],
      setValues,
      indexSelected: selectedSettings,
      typeSettings: typeList,
    })
  };

  const deleteFormat = () => {
    if(!formats || disable){
      return
    }
    const remove = () => {
      setValues(pre => ({
        ...pre, templateList: {
          ...pre.templateList, [typeList]: {
            ...pre.templateList[typeList],
            properties: formats.filter((el, i) => i !== selectedSettings)
          }
        }
      }));
    };
    renderInExceptionRoot(DialogAlertText, {
      title: "Remove ?",
      text: `Are you sure to remove  ${formats[selectedSettings].identifier} ?`,
      save: remove
    });

  };


  return (
    <div className={cl.wrapperInput}>
      <div className={cl.label}>{label}</div>
      <div className={cl.listWrapper}>
        <div className={cl.listWrapperScroll}>
          {formats && formats.map((el, i) => (
            <div
              key={i}
              style={{
                color: el.deprecated ? "#B1B0AF" : selectedSettings === i ? "#ffffff" : "#212529"
              }}
              onClick={() => handleSelectedTab(i)}
              className={selectedSettings === i ? cl.wrapperTitleSelected : cl.wrapperTitle}
            >
              {el.type &&  adobeName(el.type)}
              {/* {el.identifier && getTranslation(el.identifier)} */}
            </div>
          ))}
        </div>
      </div>
      <div className={cl.wrapperEditor}>
        <AddIcon  onClick={() => !disable && handleOpenDialog("Add")} />
        <CreateIcon  onClick={() => !disable && handleOpenDialog("edit")} />
        <DeleteIcon  onClick={deleteFormat} />
      </div>
    </div>
  );
}

export default SupportedFormats;
