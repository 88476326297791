import React, { useContext, useEffect, useState } from 'react';
import { Tab, Tabs, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { TabsContext } from '../../Stylesheet';
import General from './general/General';
import Header from './general/Header';
import Loading from '../../Loading';
import { handleTableSingleLevel, handleType } from '../../utils/helpers';
import { normalData } from '../../data/table/normalData';
import { userDefinedData } from '../../data/table/userDefinedData';
import OddRows from './OddRows';
import EvenRows from './EvenRow';
import LastRow from './LastRow';

const useStyles = makeStyles((theme) => ({
  wrapperDropMenu: {
    marginBottom: 10
  },
  tabsRoot: {
    minHeight: 18,
    marginBottom: 15,
    '& .MuiTab-root': {
      padding: '0px 4px 0px 3px',
      minWidth: 'fit-content',
      minHeight: 18,
      background: '#EBEBEB',
      textTransform: 'capitalize'
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#ffffff',
      background: '#4db1d3',
      outline: 'none'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left',
      background: '#E3F2F7'
    }
  },

  wrapperTemplate: {
    height: 'Calc(100% - 88px)'
  },
  title: {
    fontWeight: '700',
    marginBottom: 0
  },
  templateInput: {
    height: '100%',
    width: '100%',
    marginBottom: 0,
    '& .MuiInputBase-multiline.MuiInputBase-marginDense': {
      height: '100%'
    },
    "& textarea": {
      height: '100% !important'
    },
    '& .MuiInputBase-root': {
      height: '100%',
    },
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100% !important'
    }
  }
}));

function Table() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const tabs = ['general', 'Header', 'Odd rows', 'Even rows', 'Last row'];
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTable, setSelectedTable] = useState('User defined');
  const table = values.stylesheet.tableSettings[values.selectedLeftMenu];






  const handleChangeSelectedTab = (e, newValue) => {
    setSelectedTab(newValue);
  };

  const handleXSL = (e) => {
    const val = e.target.value;
    handleTableSingleLevel(val, 'template', 'tableSettings', values, setValues);
  };

  const handleBlockTabs = (val) => {
    if (val === selectedTable) {
      return;
    }
    if (val === 'Normal') {
      handleType(normalData, 'tableSettings', values, setValues);
    }
    if (val === 'User defined') {
      handleType(userDefinedData, 'tableSettings', values, setValues);
    }
    setSelectedTable(val);
  };

  useEffect(() => {
    if (table && table.class.includes('Normal')) {
      setSelectedTable('Normal');
    } else {
      setSelectedTable('User defined');
    }
  }, [table && table.class]);

  return (
    <>
      <div className={cl.wrapperDropMenu}>
        <ExpanderItem valueProps={selectedTable} propsLabel={['Normal', 'User defined']} setProps={handleBlockTabs} />
      </div>

      {table ? (
        <>
          {table.class.includes('Normal') ? (
            <div>
              <Tabs
                indicatorColor=""
                className={cl.tabsRoot}
                value={selectedTab}
                onChange={handleChangeSelectedTab}
                textColor="primary"
                variant="standard"
                scrollButtons="auto"
                centered
              >
                {tabs.map((el, i) => (
                  <Tab label={el} key={i} />
                ))}
              </Tabs>
              {table && (
                <>
                  {selectedTab === 0 && <General table={table} />}
                  {selectedTab === 1 && <Header table={table} />}
                  {selectedTab === 2 && <OddRows table={table} />}
                  {selectedTab === 3 && <EvenRows table={table} />}
                  {selectedTab === 4 && <LastRow table={table} />}
                </>
              )}
            </div>
          ) : (
            <div className={cl.wrapperTemplate}>
              <p className={cl.title}>XSL template:</p>
              <TextField
                className={cl.templateInput}
                value={table.template}
                variant="outlined"
                multiline
                minRows={4}
                onChange={handleXSL}
              />
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Table;
