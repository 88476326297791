import {
  handlePost,
  handlePostFile,
  handlePostParamAsString,
  handlePostTextAsParam,
  handleQuery
} from "@zert-packages/actions/api";

import getLang from "@zert-packages/utils/getLang";

export const isSymbolFormat = (format) => {
  if (format.settings.properties) {
    const isSymbol = format.settings.properties.find((settings) => settings.identifier == "isSymbol");
    return !!(isSymbol && isSymbol.value == "true");
  }
  return false;
};

export const parseFontFamily = (format) => {
  if (format.settings.properties) {
    const family = format.settings.properties.find((settings) => settings.identifier == "font-family");
    return family ? family.value : null;
  }
  return null;
};

export function uploadImage(filedata) {
  return handlePostFile(`/image/uploadImageFile`, filedata);
}

export function handleSavePhrase(versionId, phrase) {
  return handlePost(`/phrase/store/${versionId}`, phrase);
}


export function createImage(md5, name, dimensions) {
  const locale = getLang();
  return handlePost(`/image/createImage/${locale}`, {
    imageName: name,
    imagePreview: md5,
    lengthUnit: "PIXELS",
    verticalAlign: "MIDDLE",
    verticalAlignDistance: 0,
    verticalAlignUnit: "PERCENT",
    ...dimensions,
  });
}

export function getDoubles(md5) {
  return handlePostTextAsParam(`/image/getDoubles`, md5);
}

export const loadNewImageReference = (versionId) => {
  return handleQuery(`/phrase/loadNewImageReference/${versionId}`, false);
};

export const loadPhrase = (versionId, locale, loadFormatted) => {
  ///${locale}
  return handleQuery(`/phrase/loadFormatted/${versionId}`);
};




export const getTableApi = (versionId, locale) => {
  return handleQuery(`/table/load/${versionId}/${locale}`);
};

export const getTableNewApi = (versionId, locale) => {
  return handleQuery(`/table/newLoad/${versionId}/${locale}`);
};


export const checkGrammar = async (locale, text) => {
  return handlePostTextAsParam(`/phrase/checkGrammar/${locale}`, text);
};

export const addIgnoreWordToSpelling = async (locale, text) => {
  return handlePostTextAsParam(`/phrase/addIgnoreWordToSpelling/${locale}`, text);
};

export const checkSpelling = async (locale, text) => {
  return handlePostTextAsParam(`/phrase/checkSpelling/${locale}`, text);
};

export const getAliasesByPhraseApi = async (versionId) => {
  return handleQuery(`/phrase/loadAliasesByPhrase/${versionId}`);
}

export const getLoadAliasesByPhraseApi = async (versionId) => {
  return handleQuery(`/block/aliases/${versionId}`)
}

export const getUnicodeMap = (fontFamily) => {
  return handlePostParamAsString("/common/getUnicodeMap", fontFamily);
};

export const getFontFamily = (fontFamily) => {
  return handlePostParamAsString("/common/getFontFamily", fontFamily);
};

export const getAvailableFontFamilyNamesApi = () => {
  return  handleQuery(`/common/getAvailableFontFamilyNames`)
}

///zert/common/getAvailableFontFamilyNames