import React, {lazy} from 'react';
import {FormattedMessage} from 'react-intl';
import {
    AnnouncementOutlined,
    AttachEmail,
    Business,
    Equalizer,
    Folder,
    InsertDriveFile,
    ManageSearchTwoTone,
    MoveToInbox,
    PeopleOutline,
    PersonOutline,
    SearchTwoTone,
    Settings
} from '@mui/icons-material';
import {ROUTE_TYPES} from '@zert-packages/react/ROUTE_TYPES';
import {steps} from '@zert-packages/translations';
import CreateNewDocument from '@zert-packages/plugins/DocumentPlugin/CreateNewDocument';
import MetaDataView from "clm/src/plugins/MetaData/MetaDataView";

const API_ROOT_WAR = process.env.REACT_APP_API_ROOT_WAR;

export const groupPlugin = {
    icon: PeopleOutline,
    mimeType: 'group',
    trkey: 'environment.title.group',
    defaultMessage: 'Group',
    isHideInSearch: true,
    name: <FormattedMessage id="environment.title.group" defaultMessage="Group"/>,

};

export const companyPlugin = {
    icon: Business,
    mimeType: 'company',
    trkey: 'environment.title.company',
    defaultMessage: 'Company',
    isHideInSearch: true,
    name: <FormattedMessage id="environment.title.company" defaultMessage="Company"/>,
    routes: []
};
export const newsPlugin = {
    icon: AnnouncementOutlined,
    mimeType: 'news',
    trkey: 'environment.title.news',
    defaultMessage: 'News',
    name: <FormattedMessage id="environment.title.news" defaultMessage="News"/>,
    openpath: `${API_ROOT_WAR}/newsEdit/:versionId`,
    routes: [
        {
            type: ROUTE_TYPES.ELEMENT,
            path: '/newsEdit/:versionId',
            component: lazy(() => import('./NewsPlugin/NewsEditView'))
        },
        {
            type: ROUTE_TYPES.NEWS,
            path: '/news/:versionId',
            component: lazy(() => import('./NewsPlugin/NewsView'))
        }
    ]
};
export const searchFolderPlugin = {
    icon: ManageSearchTwoTone,
    trkey: 'environment.title.searchfolder',
    defaultMessage: 'Search Folder',
    mimeType: 'search-folder',
    isCreateNew: true,
    name: <FormattedMessage id="environment.title.searchfolder" defaultMessage="Search Folder"/>,
    routes: [
        {
            type: ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR,
            showInSearch: true,
            path: '/explorer/createSearchFolder',
            title: <FormattedMessage id="headbar.title.createSearchFolder" defaultMessage="Create search folder"/>,
            component: lazy(() => import('./ExplorerPlugin/ExplorerView'))
        }
    ]
};

export const folderPlugin = {
    icon: Folder,
    mimeType: 'folder',
    trkey: 'environment.title.folder',
    defaultMessage: 'Folder',
    isCreateNew: true,
    name: <FormattedMessage id="environment.title.folder" defaultMessage="Folder"/>,
    routes: [
        {
            type: ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR,
            showInExplorer: true,
            path: '/explorer/createFolder',
            title: <FormattedMessage id="headbar.title.createFolder" defaultMessage="Create folder"/>,
            component: lazy(() => import('./ExplorerPlugin/ExplorerView'))
        }
    ]
};

export const metaDataPlugin = {
    icon: Settings,
    trkey: 'environment.title.metadataTemplate',
    isCreateNew: true,
    allowPublishing: false,
    defaultMessage: 'Metadata Template',
    mimeType: 'metadatatemplate',
    isTask: false,
    name: <FormattedMessage id="environment.title.MetadataTemplate" defaultMessage="Metadata Template"
                            className="clip"/>,
    newpath: '/metadata_new',
    newpermit: 'zert-metadata/create-metadata',
    readpermit: 'zert-metadata/read-metadata',
    editpermit: 'zert-metadata/edit-metadata',

    openpath: `${API_ROOT_WAR}/meta-data/:versionId`,

    routes: [
        {
            type: ROUTE_TYPES.ELEMENT,
            path: '/meta-data/:versionId',
            component: MetaDataView
        }
    ]
};
/*export const metadataTemplatePlugin = {
  icon: Settings,
  trkey: 'environment.title.metadataTemplate',
  isCreateNew: true,
  allowPublishing: true,
  defaultMessage: 'Metadata template',
  mimeType: 'metadatatemplate',
  isTask: false,
  name: (
    <FormattedMessage id="environment.title.metadataTemplate" defaultMessage="Metadata template" className="clip" />
  ),

  openpath: `${API_ROOT_WAR}/layout/:versionId`
};*/

export const userPlugin = {
    icon: PersonOutline,
    mimeType: 'user',
    trkey: 'environment.title.user',
    defaultMessage: 'User',
    name: <FormattedMessage id="environment.title.user" defaultMessage="User"/>,
    isTask: false,
    isHideInSearch: true,
    openpath: `${API_ROOT_WAR}/settings/usereditor/-100/:versionId`
};

export const workflowTemplatePlugin = {
    icon: Settings,
    mimeType: 'workflowtemplate',
    trkey: 'environment.title.workflow',
    defaultMessage: 'Workflow template',
    name: <FormattedMessage id="environment.title.workflow" defaultMessage="Workflow template"/>,
    isTask: false,
    newpermit: 'zert-workflowtemplate',
    readpermit: 'zert-workflowtemplate',
    editpermit: 'zert-workflowtemplate',
    openpath: `${API_ROOT_WAR}/workflow-template/:versionId`,
    routes: [
        {
            type: ROUTE_TYPES.ELEMENT,
            path: '/workflow-template/:versionId',
            component: lazy(() => import('./AdministrationPlugin/components/WorkflowTemplateView'))
        }
    ]
};





export const actionPlanPlugin = {
    icon: MoveToInbox,
    trkey: 'environment.title.actionPlan',
    //  mimeType: 'actionPlan',
    order: 0,
    defaultMessage: 'actionPlan',
    name: <FormattedMessage id="environment.title.actionPlan" defaultMessage="Action plan"/>,
    routes: [
        {
            type: ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR,
            isOverview: true,
            path: '/action-plan',
            component: lazy(() => import('./ActionPlanPlugin/ActionPlan')),
            title: <FormattedMessage id="headbar.title.actionplan" defaultMessage="Action plan"/>
        },
        {
            type: ROUTE_TYPES.SIMPLE,
            path: '/about-zert',
            component: lazy(() => import('@zert-packages/components/AboutZertRM')),
            title: <FormattedMessage id="headbar.title.AboutZert" defaultMessage="About Zert"/>
        },
        {
            type: ROUTE_TYPES.NOTIFICATION,
            path: '/notification/:id',
            component: lazy(() => import('@zert-packages/plugins/NewsPlugin/NotificationsView')),
            title: <FormattedMessage id="headbar.title.Notification" defaultMessage="Notification"/>
        }
    ]
};

export const statisticsPlugin = {
    icon: Equalizer,
    trkey: 'environment.title.statistics',
    //   mimeType: 'Statistcics',
    defaultMessage: 'Statistics',
    order: 6,
    name: <FormattedMessage id="environment.title.Statistcics" defaultMessage="Statistics View "/>,
    routes: [
        {
            type: ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR,
            isOverview: true,
            path: '/statistics',
            component: lazy(() => import('./StatisticsPlugin/StatisticsView')),
            title: <FormattedMessage id="headbar.title.statisticsview" defaultMessage="Statistics View"/>
        }
    ]
};

export const explorerPlugin = {
    icon: Folder,
    trkey: 'environment.title.explorer',
    //  mimeType: 'explorer',
    defaultMessage: 'Explorer',
    order: 7,
    name: <FormattedMessage id="environment.title.explorer" defaultMessage="Explorer"/>,
    routes: [
        {
            type: ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR,
            path: '/explorer',
            isOverview: true,
            component: lazy(() => import('./ExplorerPlugin/ExplorerView')),
            title: <FormattedMessage id="headbar.title.explorer" defaultMessage="Explorer"/>
        }
    ]
};

export const searchPlugin = {
    icon: SearchTwoTone,
    trkey: 'environment.title.search',
    // mimeType: 'search',
    order: 8,
    defaultMessage: 'Search',
    name: <FormattedMessage id="environment.title.search" defaultMessage="Search"/>,
    routes: [
        {
            type: ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR,
            path: '/search',
            isOverview: true,
            component: lazy(() => import('./ExplorerPlugin/SearchView')),
            title: <FormattedMessage id="headbar.title.search" defaultMessage="Search"/>
        }
    ]
};

/* {
            type: ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR,
            path: '/settings/news',
            component: lazy(() => import('./AdministrationPlugin/SettingsView')),
            title: <FormattedMessage
                id="headbar.title.News" defaultMessage="News view"
            />,
        },

        {
            type: ROUTE_TYPES.SIMPLE,
            path: '/about-zert',
            component: AboutZertRM,
            title: <FormattedMessage
                id="headbar.title.AboutZertRM"
                defaultMessage="About Zert RM"
            />,
        },
        {
            type: ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR,
            path: '/explorer',
            isOverview : true,
            component: ExplorerView,
            title: <FormattedMessage
                id="headbar.title.explorer"
                defaultMessage="Explorer"
            />,
        },
        {
            type: ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR,
            path: '/search',
            isOverview : true,
            component: SearchView,
            title: <FormattedMessage
                id="headbar.title.search" defaultMessage="Search"
            />,
        },

        {
            type: ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR,
            path: '/explorer/createFolder',
            title: <FormattedMessage
                id="headbar.title.createFolder"
                defaultMessage="Create folder"
            />,
            component: ExplorerView,
        },
        {
            type: ROUTE_TYPES.CATALOG,

            listOfRoutes: CatalogExplorerRoutes,
        },

    ],
}; */

export const paperPlugin = {
    icon: AttachEmail,
    mimeType: 'paper',
    trkey: 'environment.title.papercopy',
    defaultMessage: 'Paper copy',
    name: <FormattedMessage id="environment.title.paperCopy" defaultMessage="Paper copy"/>
};

export const documentPlugin = {
    icon: InsertDriveFile,
    mimeType: 'document',

    createNewDialog: CreateNewDocument,
    isCreateNew: true,
    summaryLabel: <FormattedMessage id="documents.summary.caption" defaultMessage="DOCUMENTS"/>,
    trkey: 'documents.title.document',
    defaultMessage: 'Document',
    newpermit: 'zert-document/create-document',
    readpermit: 'zert-document/read-document',
    editpermit: 'zert-document/edit-document',
    name: <FormattedMessage id="documents.title.document" defaultMessage="Document"/>,
    isTask: false,
    openpath: `${API_ROOT_WAR}/document/:versionId`
};

export const plugins = [
    searchFolderPlugin,
    newsPlugin,
    companyPlugin,
    groupPlugin,
    userPlugin,
    folderPlugin,
    workflowTemplatePlugin,
    paperPlugin,
    documentPlugin,
    searchPlugin,
    explorerPlugin,
    statisticsPlugin,
    metaDataPlugin
];
export default function getPlugins() {
    return plugins;
}

let productActions = [];

export function getProductActions() {
    return productActions;
}

export function setProductActions(actions) {
    productActions = actions;
}

export function getSteps(intl) {
    const resultsteps = steps.map((step) => {
        const step1 = {
            id: step.id,
            message: step.message,
            tr_message: intl.formatMessage({
                id: step.trkey,
                defaultMessage: step.defaultMessage
            }),
            description: step.description
        };
        return step1;
    });

    return resultsteps;
}

export function getStepNameByIndetifiers(identifier) {
    const plugin = steps.find((plugin) => identifier == plugin.id);
    if (!plugin) {
        return steps[0].message;
    }

    return plugin.message;
}

export const MAX_FILE_SIZE = 20971520;
