import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  DialogActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
function SomethingWentWrongDialog({
  unmountComponent,
  contextText = <FormattedMessage id="SomethingWentWrongDialog.Title" defaultMessage="Something went wrong" />
}) {
  return (
    <Dialog open onClose={unmountComponent}>
      <DialogTitle>{contextText}</DialogTitle>
      <DialogActions className={useStyles().DialogActions}>
        <Button variant="outlined" onClick={unmountComponent} color="primary">
          <FormattedMessage id="SomethingWentWrongDialog.Exit" defaultMessage="Exit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SomethingWentWrongDialog;
