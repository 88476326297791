import { makeProjectTreeAction, updateProjectAction } from './rmpReducer';
import { addStoreSubProjectAPI, storeProjectAPI } from '../APIs';

const findUpdateNode = (node, id, updateFunc = (n) => n, store = { updatedNode: undefined }) => {
  if (store.updatedNode) return [node, store.updatedNode];
  let newNode = { ...node };
  if (node.uuid === id) {
    newNode = { ...newNode, ...updateFunc(newNode) };
    store.updatedNode = newNode;
  }
  if (!store.updatedNode) {
    newNode.children = newNode.children.map((n) => findUpdateNode(n, id, updateFunc, store)[0]);
  }
  return [newNode, store.updatedNode];
};

export const getInitState = (getState) => {
  const { project, viewId } = getState().RMP;
  return {
    viewId,
    project: { ...project }
  };
};

const APIHandler = (APIMethod, successCallback, failCallback) => {
  APIMethod()
    .then((res) => {
      console.log(res);
      if (successCallback) successCallback(res);
    })
    .catch((e) => {
      console.log(e);
      if (failCallback) failCallback(e);
    });
};

export const moveNode = (node, nodeId, newIndex) => {};

export const moveSubNode = (subProjectId, subNodeId, newIndex) => (dispatch, getState) => {
  const { project, viewId } = getInitState(getState);
  const index = 0;
  const node = null;
  APIHandler(
    () => addStoreSubProjectAPI(viewId, index, project.versionId, node),
    () => dispatchProject(project, dispatch, true)
  );
};

export const moveSubProject = (subProjectId, newIndex) => (dispatch, getState) => {
  const { project, viewId } = getInitState(getState);

  dispatchProject(project, dispatch, true);
};

export const deleteNode = (node, nodeId) => {};

export const deleteSubNode = (subProjectId, subNodeId) => (dispatch, getState) => {
  const { project, viewId } = getInitState(getState);

  dispatchProject(project, dispatch, true);
};

export const deleteSubProject = (subProjectId) => (dispatch, getState) => {
  const { project, viewId } = getInitState(getState);

  dispatchProject(project, dispatch, true);
};

export const updateNode = (node, nodeId, updateFunc) => {};

export const updateSubNode = (subProjectId, subNodeId, entry, updateTree) => (dispatch, getState) => {
  const { project, viewId } = getInitState(getState);

  dispatchProject(project, dispatch, updateTree);
};

export const updateSubProject = (subProjectId, entry, updateTree) => (dispatch, getState) => {
  const { project, viewId } = getInitState(getState);

  dispatchProject(project, dispatch, updateTree);
};

export const addNode = (parentId, index, node) => {};

export const addSubNode = (subProjectId, subNodeId, index, node) => (dispatch, getState) => {
  const { project, viewId } = getInitState(getState);

  dispatchProject(project, dispatch, true);
};

export const addSubProject = (index, node) => (dispatch, getState) => {
  const { project, viewId } = getInitState(getState);
  APIHandler(
    () => addStoreSubProjectAPI(viewId, index, project.versionId, node),
    () => dispatchProject(project, dispatch, true)
  );
};

export const updateProject = (entry, updateTree) => (dispatch, getState) => {
  const { project, viewId } = getInitState(getState);
  APIHandler(
    () => storeProjectAPI(viewId, project),
    () => dispatchProject(project, dispatch, updateTree)
  );
};

const dispatchProject = (project, dispatch, updateTree) => {
  dispatch(updateProjectAction(project));
  if (updateTree) dispatch(makeProjectTreeAction());
};
