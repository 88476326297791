import { approveVersionApi, storeBlockApi } from "../components/API";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import AlertTextDialog from "../components/dialog/AlertTextDialog";


export const multipleApprove = (generatingSnackbar, snackbar, items) => {
  const approve = async () => {
     await Promise.allSettled(items.map(el => approveVersionApi(el.elementInfo.companyId, el.elementInfo.versionId)))
    snackbar.closeSnackbar(generatingSnackbar);
  };
  const cancellation = () => {
    snackbar.closeSnackbar(generatingSnackbar);
  };
  renderInExceptionRoot(AlertTextDialog, { "setConfirm": approve, "setClose": cancellation, "title": "Approve ?" });
}

export const multipleRemove = (generatingSnackbar,itemsBlock,  values, setValues, snackbar) => {
  const createItemData = {};
  const parentItemCreate = itemsBlock[0].parent
  if (parentItemCreate) {
    const items = parentItemCreate.items.filter((el, i) => !itemsBlock.find(it => it.indexElementCreate === i));
    createItemData.name = parentItemCreate.name;
    createItemData.targetLocale = parentItemCreate.targetLocale;
    createItemData.format = parentItemCreate.format;
    createItemData.items = items;
  }


  const remove = async () => {
    await storeBlockApi(parentItemCreate.versionId, parentItemCreate.targetLocale.language, createItemData);
    if (setValues) {
      setValues(pre => ({
        ...pre,
        treeItem: null,
        severalSelectedTreeItem: null,
        closeChild: false,
        dropBlockTable: false,
        investmentLevel: 0,
        action: {
          ...pre.action,
          parentItemCreate: { ...parentItemCreate, items: createItemData.items, type: "remove" }
        }
      }));
    }
    snackbar.closeSnackbar(generatingSnackbar);
  }

  const cancellation = () => {
    snackbar.closeSnackbar(generatingSnackbar);
  };
  renderInExceptionRoot(AlertTextDialog, { "setConfirm": remove, "setClose": cancellation, "title": "Information" });
}