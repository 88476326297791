import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useEffect } from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import getTranslation from '@zert-packages/utils/getTranslation.new';
import { setPreviewPublishingFilter } from '../previewReducers';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  }
}));

function PublishingFilterPaper({ readOnly, previewPublishingFilters }) {
  const { publishingFilters } = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const getValue = (filter) => {
    if (!previewPublishingFilters) {
      return null;
    }
    return previewPublishingFilters.find((item) => filter.identifier == item.identifier);
  };
  const getRender = (filter) => {
    const value = publishingFilters.find((item) => filter && filter.identifier == item.identifier);
    if (value) {
      const item = value.items.find((item) => filter.itemIdentifier == item.identifier);
      if (item) {
        return getTranslation(item.label);
      }
    }
  };

  const handleChange = (filter) => (event) => {
    dispatch(setPreviewPublishingFilter({ filter, value: event.target.value }));

    //  setValues([...values, value]);
  };

  return (
    <Paper className={classes.paper}>
      <Typography component="h3" gutterBottom>
        <FormattedMessage id="Preview.GeneralTable.PublishingFilter" defaultMessage="Publishing Filter" />
        <br />
      </Typography>
      {!readOnly &&
        publishingFilters.map((filter) => (
          <FormControl fullWidth>
            <InputLabel htmlFor={`select-preview-publishing-filters${filter.identifier}`}>
              {getTranslation(filter.label)}
            </InputLabel>
            <Select
              value={getValue(filter)}
              onChange={handleChange(filter)}
              inputProps={{
                name: `select-preview-publishing-filters${filter.identifier}`,
                id: `select-preview-publishing-filters${filter.identifier}`
              }}
              renderValue={(selected) => <div>{getRender(selected)}</div>}
            >
              {filter.items.map((item) => (
                <MenuItem
                  key={`${filter.identifier} ${item.identifier}`}
                  value={{ identifier: filter.identifier, itemIdentifier: item.identifier }}
                >
                  <em>{getTranslation(item.label)}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
      {readOnly &&
        publishingFilters.map(
          (filter) =>
            getValue(filter) && (
              <TextField
                id="layout"
                label={getTranslation(filter.label)}
                className={classes.formControl}
                value={getRender(getValue(filter))}
                type="text"
                variant="outlined"
              />
            )
        )}
    </Paper>
  );
}

export default PublishingFilterPaper;
