import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { TabsContext } from '../../../Stylesheet';
import { adobeDropMenu, handlePageLayout, handlePageLayoutSingleLevel } from '../../../utils/helpers';
import SelectedBlock from '../../../SelectedBlock';
import TemplateItemRow from '../../../TemplateItemRow';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridRowGap: '15px'
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '120px 2fr',
    gridColumnGap: 10
  },
  label: {
    fontWeight: 700,
    fontSize: '1rem',
    marginTop: 5
  },
  wrapperIndent: {
    border: '1px solid #C6C6C5',
    display: 'grid',
    padding: '7px 10px',
    gridRowGap: '10px'
  },
  selectedBlockWrapper: {
    marginTop: 10
  }
}));

function AnchoredTopic() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const anchoredTopic = values.stylesheet.blockSettings[values.selectedLeftMenu];
  const dropMenu = ['Left', 'Right'];

  const handleIndent = (val, name) => {
    handlePageLayout(val, name, values, setValues);
  };

  const handleDropMenu = (valDropMenu, name, val) => {
    handlePageLayout(val, name, values, setValues, valDropMenu);
  };

  const handleDropMenuFlow = (valDropMenu, name) => {
    const val = valDropMenu === 'Left';
    handlePageLayoutSingleLevel(val, name, values, setValues);
  };

  const adopterFlow = (leftFlow) => {
    if (leftFlow) {
      return 'Left';
    }
    return 'Right';
  };

  return (
    <>
      {anchoredTopic && anchoredTopic.class.includes('AnchoredTopic') && (
        <div>
          <div className={cl.label}>Flow</div>
          <div className={cl.wrapperIndent}>
            <div className={cl.wrapperRow}>
              <div className={cl.label}>Flow to the:</div>

              <ExpanderItem
                valueProps={adopterFlow(anchoredTopic.leftFlow)}
                propsLabel={dropMenu}
                setProps={handleDropMenuFlow}
                type="leftFlow"
                key={anchoredTopic.leftFlow}
              />
            </div>
            <TemplateItemRow
              label="Flow width:"
              type="flowWidth"
              value={{
                value: anchoredTopic.flowWidth.value,
                unit: adobeDropMenu(anchoredTopic.flowWidth.unit)
              }}
              grid="121px 1fr 100px"
              setDropMenu={handleDropMenu}
              setValueProps={handleIndent}
            />
            <TemplateItemRow
              label="Gap:"
              type="gap"
              value={{
                value: anchoredTopic.gap.value,
                unit: adobeDropMenu(anchoredTopic.gap.unit)
              }}
              grid="121px 1fr 100px"
              setDropMenu={handleDropMenu}
              setValueProps={handleIndent}
            />
          </div>
          <div className={cl.label}>Indent</div>
          <div className={cl.wrapperIndent}>
            <TemplateItemRow
              label="Left:"
              type="marginLeft"
              value={{
                value: anchoredTopic.marginLeft.value,
                unit: adobeDropMenu(anchoredTopic.marginLeft.unit)
              }}
              grid="121px 1fr 100px"
              setDropMenu={handleDropMenu}
              setValueProps={handleIndent}
            />
            <TemplateItemRow
              label="Right:"
              type="marginRight"
              value={{
                value: anchoredTopic.marginRight.value,
                unit: adobeDropMenu(anchoredTopic.marginRight.unit)
              }}
              grid="121px 1fr 100px"
              setDropMenu={handleDropMenu}
              setValueProps={handleIndent}
            />
          </div>

          <div className={cl.selectedBlockWrapper}>
            <SelectedBlock
              label="Anchored formats"
              formats={anchoredTopic.anchoredFormats}
              ollFormats={[...anchoredTopic.anchoredFormats, ...anchoredTopic.fullWidthFormats]}
              type="anchoredFormats"
            />
          </div>
          <div className={cl.selectedBlockWrapper}>
            <SelectedBlock
              label="Full width formats"
              formats={anchoredTopic.fullWidthFormats}
              ollFormats={[...anchoredTopic.anchoredFormats, ...anchoredTopic.fullWidthFormats]}
              type="fullWidthFormats"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default AnchoredTopic;
