import { useEffect, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { GetEnv } from '@zert-packages/react/EnvProvider';
import { useIntl } from 'react-intl';
import {useSelector} from "react-redux";
import {getNameFromVersionInfo} from "@zert-packages/components/HeadBar/HeadBarRoutes";

const useTitle = (routes, title?, language = undefined) => {
  const documentDefined = typeof document !== 'undefined';
  const originalTitle = useRef(documentDefined ? document.title : null);
  const appTitle = GetEnv('REACT_APP_PORTAL') === 'LogMax' ? 'Log Max' : GetEnv('REACT_APP_PORTAL');
  const suffix = originalTitle.current !== '' ? originalTitle.current : appTitle;
  const location = useLocation();
  const intl = useIntl();
  const { versionInfo } = useSelector((state) => state);

  useEffect(() => {
    if (!documentDefined) {
      return;
    }

    if (title) {
      if (document.title !== title) {
        document.title = `${title} - ${suffix}`;
      }
    }

    routes.map((route) => {
      if (matchPath(location.pathname, route)) {
        const routeName = route.name ? route.name : route.title ? route.title : '';
        if (route.type === 'ELEMENT' && versionInfo && getNameFromVersionInfo(versionInfo)) {
          document.title = `${getNameFromVersionInfo(versionInfo)} - ${suffix}`;
        } else if (routeName && typeof routeName === 'object' && routeName.props && routeName.props.id) {
          document.title = `${intl.formatMessage({ id: routeName.props.id })} - ${suffix}`;
        } else if (routeName && routeName !== '') {
          document.title = `${routeName} - ${suffix}`;
        }
      }
    });

    return () => {
      document.title = suffix;
    };
  }, [location, title, language, intl, versionInfo]);
};

export default useTitle;
