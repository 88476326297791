import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { FormattedMessage } from "react-intl";
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { getAvailableFontFamilyNamesApi, getFontFamily, getUnicodeMap } from "../../../API";
import { handleValue } from "../../../../BlockPlugin/utils/main";
import DropMenu from "@zert-packages/components/DropMenu";
import { createUnicodeMap } from "../../../utils/createUnicodeMap";
import UnicodeList from "./UnicodeList";
import SymbolTable from "./SymbolTable";
import SymbolTableHeader from "./SymbolTableHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 870,
    // height: 700,
    minWidth: 'fit-content',
    padding: 15,
    boxSizing: 'content-box',
    background: "#E2F2F6"
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700
  },
  wrapperDropMenu: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: 30,
    marginTop: 5,
    marginBottom: 10,
  },
  fontsWrapperTitle: {
    display: "grid",
    gridTemplateColumns: "50px 1fr",
  },
  titleDropMenu: {
    fontWeight: 700
  },
  wrapperButton: {
    display: "flex",
    justifyContent: "end",
    paddingRight: 20,
    marginTop: 30
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

const SymbolDialog = ({addUnicode}) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [symbol, setSymbol] = useState({
    availableFontFamily: [],
    selectedAvailableFontFamily: "",
    font: null,
    fontMap: null,
    selectedFontArial: "",
    unicodeSymbolMap: [],
    fontSizeSymbol: 20,
    selectedSymbol: null,
    filterUnicodeValue: ''
  });


  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if(addUnicode){
      addUnicode(symbol.selectedSymbol)
    }
    setOpen(false);
  };

  const getAvailableFontFamilyNames = async () => {
    const res = await getAvailableFontFamilyNamesApi();
    if (res?.length > 0) {
      // renderInExceptionRoot(SymbolSelectDialog, {fontFamily: res[0]})
      handleValue(false, false, setSymbol, {
        availableFontFamily: res,
        selectedAvailableFontFamily: res[0]
      });
    }
  };

  const loadFontFamily = async (fontFamily) => {
    const font = await getFontFamily(fontFamily);
    const fontMap = await getUnicodeMap(fontFamily);
    const res = {};
    if (font) {
      res.font = font;
    }
    if (fontMap) {
      res.fontMap = fontMap;
      res.selectedFontArial = fontMap.unicodeMap[0];
    }
    if (Object.keys(res).length > 0) {
      handleValue(false, false, setSymbol, res);
    }
  };

  const handleSymbol = (val, name) => {
    handleValue(val, name, setSymbol);
  }


  useEffect(() => {
    if (symbol.selectedAvailableFontFamily.length > 0) {
      loadFontFamily(symbol.selectedAvailableFontFamily);
    }

  }, [symbol.selectedAvailableFontFamily]);

  useEffect(() => {
    if(symbol.selectedFontArial){
      handleSymbol(createUnicodeMap(symbol.selectedFontArial), 'unicodeSymbolMap')
    }
  }, [symbol.selectedFontArial])

  useEffect(() => {
    getAvailableFontFamilyNames();
  }, []);




  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}>
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>
        <FormattedMessage id="SymbolSelectDialog.title" defaultMessage="Select symbol" />
      </span>
      <CloseIcon onClick={handleClose} />
    </div>

    <div className={cl.wrapperDropMenu}>
      <div className={cl.fontsWrapperTitle}>
        <span className={cl.titleDropMenu}>
          <FormattedMessage id="symbol.dialog.fonts" defaultMessage="Fonts" />:
        </span>

        <DropMenu
          selected={symbol.selectedAvailableFontFamily}
          dropItem={symbol.availableFontFamily}
          setDropItem={handleSymbol}
          height={400}
          name={"selectedAvailableFontFamily"}
        />
      </div>

      <div className={cl.fontsWrapperTitle}>
        <span className={cl.titleDropMenu}>
          <FormattedMessage id="symbol.dialog.area" defaultMessage="Area" />
        </span>
        <DropMenu
          setDropItem={handleSymbol}
          selected={symbol.selectedFontArial}
          dropItem={symbol.fontMap?.unicodeMap}
          height={400}
          name={"selectedFontArial"}
        />
      </div>
    </div>
    <div>
      <SymbolTableHeader symbol={symbol} setSymbol={setSymbol} />
      <UnicodeList symbol={symbol} setSymbol={setSymbol} />
      {/*<SymbolTable symbol={symbol} setSymbol={setSymbol} />*/}
    </div>

    <div className={cl.wrapperButton}>
      <Button disabled={!symbol.selectedSymbol} className={cl.button} onClick={handleSave}>
        OK
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>

  </Dialog>);
};

export default SymbolDialog;