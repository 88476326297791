const handlePortrait = (pageFormat, setStyle, valDropMenu) => {
  if (pageFormat === 'A3') {
    setStyle(297 / 2, 420 / 2, 297, 420, valDropMenu, pageFormat);
  }
  if (pageFormat === 'A4') {
    setStyle(210 / 2, 297 / 2, 210, 297, valDropMenu, pageFormat);
  }
  if (pageFormat === 'A5') {
    setStyle(148 / 2, 210 / 2, 148, 210, valDropMenu, pageFormat);
  }
  if (pageFormat === 'Letter') {
    setStyle(216 / 2, 279 / 2, 216, 279, valDropMenu, pageFormat);
  }
  if (pageFormat === 'Legal') {
    setStyle(216 / 2, 356 / 2, 216, 356, valDropMenu, pageFormat);
  }
  if (pageFormat === 'User') {
    setStyle(216 / 2, 356 / 2, 216, 356, valDropMenu, pageFormat);
  }
};

export default handlePortrait;
