import { useEffect, useState } from 'react';

function useTableSort(rows) {
  const [sortValues, setSortValues] = useState({
    identifier: null,
    type: null
  });

  const [sortedRows, setSortedRows] = useState(rows);

  function getType(type) {
    if (type === null) return 'down';
    if (type === 'down') return 'up';
    if (type === 'up') return null;
  }

  function updateSortValues(identifier) {
    if (identifier !== sortValues.identifier) {
      setSortValues({
        identifier,
        type: 'down'
      });
    } else {
      setSortValues({
        identifier,
        type: getType(sortValues.type)
      });
    }
  }

  useEffect(() => {
    const canSort = sortValues.type !== null && sortValues.identifier !== null;
    if (canSort) {
      setSortedRows(sortRows(rows, sortValues));
    } else {
      setSortValues(rows);
    }
  }, [rows, sortValues]);

  return {
    sortValues,
    updateSortValues,
    sortedRows
  };
}

export default useTableSort;

function sortRows(rows, sortValues) {
  const copyOfRows = [...rows];
  const compareFunction = getCompareFunction(sortValues);
  copyOfRows.sort(compareFunction);
  return copyOfRows;
}

function getCompareFunction(sortValues) {
  const { identifier, type } = sortValues;
  const valOrId = (v) => (typeof v === 'string' || !v) ? v : v?.props?.id;
  return (row1, row2) => {
    const value1 = valOrId(row1[identifier]);
    const value2 = valOrId(row2[identifier]);
    if (typeof value1 !== 'string') return 1;
    if (typeof value2 !== 'string') return -1;
    const res = value2.localeCompare(value1, "sv", { sensitivity: 'accent', numeric: true });
    return (type === "down") ? (res * -1) : res;
  };
}
