import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import React, { Fragment, useEffect, useRef } from 'react';
import zxcvbn from 'zxcvbn';
import { FormattedMessage } from 'react-intl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import makeStyles from '@mui/styles/makeStyles';
import FormHelperText from '@mui/material/FormHelperText';
import { formStyle } from './StylesHelper';

const useStyles = makeStyles(formStyle);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function PasswordField(props = props) {
  const { comparePasswords } = props;
  const prevAmount = usePrevious({ comparePasswords });
  useEffect(() => {
    if (values && values.comparePasswords !== comparePasswords) {
      setValues({ ...values, comparePasswords });
      if (values.comparePasswords != values.password && values.password != '') {
        setException(
          'confirmpassword',
          <FormattedMessage id="field.password.passwordDoesNotMatch" defaultMessage="Password does not match" />
        );
      }
      // process here
    }
  });

  const minStrength = typeof props.minStrength === 'number' ? Math.max(Math.min(props.minStrength, 4), 0) : 3;

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [error, setError] = React.useState(null);
  const [values, setValues] = React.useState({
    amount: '',
    email: '',
    [props.name]: '',
    weight: '',
    weightRange: '',
    showPassword: false,
    strength: 0
  });
  const setException = (prop, exception) => {
    setError(exception);
    if (props.setErrors) {
      props.setErrors({ ...props.errors, [prop]: exception });
    }
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value, strength: zxcvbn(event.target.value).score });
    if (props.setValues) {
      props.setValues({ ...props.values, [prop]: event.target.value });
    }

    if (!values.comparePasswords) {
      validatePasswordStrong(event.target.value);
    } else if (values.comparePasswords != event.target.value) {
      setException(
        'confirmpassword',
        <FormattedMessage id="field.password.passwordDoesNotMatch" defaultMessage="Password does not match" />
      );
    } else {
      setException('confirmpassword', null);
    }
  };

  const classes = useStyles();

  // set default thresholdLength to 7 if not a number or not specified
  // thresholdLength must be a minimum value of 7

  const thresholdLength = typeof props.thresholdLength === 'number' ? Math.max(props.thresholdLength, 7) : 7;

  const passwordLength = values[props.name].length;
  const passwordStrong = values.strength >= minStrength;
  const passwordLong = passwordLength > thresholdLength;

  const validatePasswordStrong = (value) => {
    // ensure password is long enough
    if (value.length < thresholdLength) {
      setException(
        'password',
        <FormattedMessage id="field.password.passwordtoshort" defaultMessage="Password is to short." />
      );
      return;
    }
    if (props.isUppercasePassword && !/[A-Z]/.test(value)) {
      setException(
        'password',
        <FormattedMessage
          id="field.password.passwordnotuppercase"
          defaultMessage="Password must contain at least one capital letter"
        />
      );
      return;
    }

    if (props.isNumericPassword && !/\d/.test(value)) {
      setException(
        'password',
        <FormattedMessage
          id="field.password.passwordnonumber"
          defaultMessage="Password must contain at least one number"
        />
      );
      return;
    }

    if (
      props.isSpecialCharPassword &&
      !/[\!\"\@\#\£\¤\$\%\&\/\{\(\[\)\]\=\}\?\+\\\´\`\^\*\'\.\,\:\;\-\_]/.test(value)
    ) {
      setException(
        'password',
        <FormattedMessage
          id="field.password.passwordnospecialchar"
          defaultMessage="Password must contain at least one special character"
        />
      );
      return;
    }

    // ensure password is strong enough using the zxcvbn library
    if (zxcvbn(value).score < minStrength) {
      setException(
        'password',
        <FormattedMessage id="field.password.passwordtoweek" defaultMessage="Password is to weak" />
      );
      return;
    }
    setException('password', null);
  };

  const counterClass = [
    'badge badge-pill',
    passwordLong ? (passwordStrong ? 'badge-success' : 'badge-warning') : 'badge-danger'
  ]
    .join(' ')
    .trim();

  // password strength meter is only visible when password is not empty
  const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'invisible'].join(' ').trim();

  return <>
    {props.showLegend && !props.comparePasswords && (
      <>
        <span className="d-block form-hint nested">
          <FormattedMessage
            id="settings.field.password.legendinfo"
            defaultMessage="To conform with our Strong Password policy, you are required to use a sufficiently strong password."
          />
        </span>
        <span className="d-block form-hint nested">
          <FormattedMessage
            id="settings.field.password.length"
            defaultMessage="Your password must be at least {thresholdLength} characters long."
            values={{ thresholdLength }}
          />
        </span>
        <span className="d-block form-hint nested">
          {props.isUppercasePassword && (
            <FormattedMessage
              id="settings.field.password.isuppercasepassword"
              defaultMessage="Your password must contain at least one capital letter."
            />
          )}
        </span>
        <span className="d-block form-hint nested">
          {props.isNumericPassword && (
            <FormattedMessage
              id="settings.field.password.isnumericpassword"
              defaultMessage="Your password must contain at least one number."
            />
          )}
        </span>
        <span className="d-block form-hint nested">
          {props.isSpecialCharPassword && (
            <FormattedMessage
              id="settings.field.password.isspecialcharpassword"
              defaultMessage="Your password must contain at least one special character."
            />
          )}
        </span>
      </>
    )}

    {props.showLegend && !props.comparePasswords && (
      <>
        <div className={strengthClass}>
          <div className="strength-meter-fill" data-strength={values.strength} />
        </div>
        <div className="position-absolute password-count mx-3">
          {/** Render the password length counter indicator * */}
          <span className={counterClass}>
            {passwordLength ? (passwordLong ? `${thresholdLength}+` : passwordLength) : ''}
          </span>
        </div>
      </>
    )}

    <FormControl className={classes.formControl} error={error != null} required={props.required}>
      <InputLabel htmlFor="adornment-password">
        {values.comparePasswords ? (
          <FormattedMessage id="password.field.confirmpassword" defaultMessage="Confirm password" />
        ) : (
          <FormattedMessage id="password.field.password" defaultMessage="Password" />
        )}
      </InputLabel>
      <Input
        id="adornment-password"
        type={values.showPassword ? 'text' : 'password'}
        value={values[props.name]}
        fullWidth
        onChange={handleChange(props.name)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              size="large">
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && <FormHelperText id="adornment-password">{error}</FormHelperText>}
    </FormControl>
  </>;
}

// export default  PasswordField;
