import React, { useContext, useEffect, useRef, useState } from "react";
import { Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TabsContext } from './FormatTemplatePlugin';
import { handleInputItem } from './utils/handleInputItem';
import { adobeName } from "./utils/main";

const useStyles = makeStyles((theme) => ({
  wrapperInput: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    marginBottom: 10,
    gridColumnGap: 10,
    alignItems: 'center'
  },
  label: {
    fontWeight: 900
  },
  dropMenuButton: {
    width: '100%',
    justifyContent: 'space-between',
    border: '1px solid #C6C6C5',
    fontSize: '1rem',
    borderRadius: 4,
    color: 'rgb(33, 37, 41)',
    fontWeight: '400',
    paddingBottom: 0.25,
    paddingTop: 0.25,
    background: '#ffffff',
    paddingRight: 15,
    outline: 'none !important',
    textTransform: 'initial',
    '&:hover': {
      background: '#ffffff'
    },
    '& :focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  menuLinkSiteWrapper: (props) => ({
    borderRadius: 0,
    // width: 470,
    width: 'fit-content',
    minWidth: 315,
    maxWidth: 'Calc(100% - 40px)',
    // marginTop: 40,
    border: '1px solid #DCE3E5',
    boxShadow: 'none',
    maxHeight: '400px',
    '@media (max-width: 900px)': {
      // marginTop: 45,
      width: 'Calc(100% - 151px)'
    }
  })
}));

function ExpanderItem({ label = '', valueProps = '', propsLabel, type, style={}, setSelected, disable }) {

  const arrLabel = propsLabel || [
    'None',
    'Lower alpha list',
    'Upper alpha list',
    'Numbered list',
    'Bullet list',
    'Dash list'
  ];

  const { setValues } = useContext(TabsContext);
  const [open, setOpen] = useState(null);
  const [width, setWidth] = useState(null)
  const cl = useStyles({width});
  const refRoot = useRef(null);


  const handleOpen = (event) => {
    if(disable){
      return
    }
    setOpen(event.currentTarget);
  };

  const handleDropSelectedSettings = (name, value) => {
    setValues((prev) => ({
      ...prev,
      templateList: {
        ...prev.templateList,
        typeSettings: {
          ...prev.templateList.typeSettings,
          [name]: value.replace(/[' ']/g, '_')
        }
      }
    }));
  };

  const handleSelectedItem = (val) => {
    setOpen(null);
    const adobeValue = !val.name && val.toUpperCase()
    if(setSelected){
      return setSelected(adobeValue, type)
    }

    if (type === 'fontFamily' || type === 'fontSize') {
      return handleDropSelectedSettings(type, adobeValue);
    }
    handleInputItem('style', val.id, setValues);
  };

  useEffect(() => {
    const handleResize = () => {
      if (refRoot.current) {
        setWidth(refRoot.current.offsetWidth)
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [refRoot.current?.offsetWidth]);




  return (
    <div className={cl.wrapperInput}>
      <div className={cl.label}>{label}</div>
      <div>
        <Button
          style={style}
          ref={refRoot}
          aria-controls="simple-menu"
          aria-haspopup="true"
          disable={disable}
          onClick={handleOpen}
          className={cl.dropMenuButton}>
          <span>{adobeName(valueProps).replace(/['_']/g, ' ')}</span>
          <ArrowDropDownIcon
            style={{
              color: open ? '#224952' : '#8B8B8B',
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
          />
        </Button>
        <Menu
          anchorEl={open}
          open={Boolean(open)}
          onClose={() => setOpen(null)}
          classes={{
            paper: cl.menuLinkSiteWrapper
          }}
        >
          {arrLabel.map((el, i) => (
            <MenuItem key={i} onClick={() => handleSelectedItem(el)} style={{
              minWidth: width ? width : 0
            }}>
              {el.name || el}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
}

export default ExpanderItem;
