import { handleStylesheet } from './helpers';

export const findSelectedDropMenuBlock = (values, setValues) => {
  const findActiveList = values.leftMenu[values.selectedLeftMenu].class;

  if(!findActiveList){
    return
  }
  if (findActiveList.includes('Publication')) {
    return handleStylesheet('selectedDropMenu', 'Publication', setValues);
  }
  if (findActiveList.includes('PageLayout')) {
    return handleStylesheet('selectedDropMenu', 'Page layout', setValues);
  }
  if (findActiveList.includes('ListBlock')) {
    return handleStylesheet('selectedDropMenu', 'List', setValues);
  }
  if (findActiveList.includes('TableOfContents')) {
    return handleStylesheet('selectedDropMenu', 'table of contents', setValues);
  }
  if (findActiveList.includes('Cover')) {
    return handleStylesheet('selectedDropMenu', 'Cover', setValues);
  }
  if (findActiveList.includes('ExternalFile')) {
    return handleStylesheet('selectedDropMenu', 'ExternalFile', setValues);
  }
  if (findActiveList.includes('AnchoredTopic')) {
    return handleStylesheet('selectedDropMenu', 'Anchored topic', setValues);
  }
  if (findActiveList.includes('Topic')) {
    return handleStylesheet('selectedDropMenu', 'Topic', setValues);
  }
  if (findActiveList.includes('UserDefined')) {
    return handleStylesheet('selectedDropMenu', 'User defined', setValues);
  }
  return handleStylesheet('selectedDropMenu', 'Group', setValues);
};
