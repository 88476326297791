import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import notDeliveredPNG from './components/Status/NotDelivered.png';
import deliveredPNG from './components/Status/Delivered.png';
import onReviewPNG from './components/Status/OnReview.png';
import onReviewWithCommentPNG from './components/Status/OnReviewWithComment.png';
import donePNG from './components/Status/Done.png';

export function StatusImg({ src, alt }) {
  return <img src={src} width="18px" height="18px" alt={alt} />;
}
export function NotDelivered() {
  return <StatusImg src={notDeliveredPNG} alt="1/4" />;
}
export function Delivered() {
  return <StatusImg src={deliveredPNG} alt="2/4" />;
}
export function OnReview() {
  return <StatusImg src={onReviewPNG} alt="3/4" />;
}
export function ReviewedWithComments() {
  return <StatusImg src={onReviewWithCommentPNG} alt="3/4" />;
}
export function Done() {
  return <StatusImg src={donePNG} alt="4/4" />;
}

export const SUB_PROJECT_CONTAINER_HEIGHT = 120;
export const SUB_PROJECT_CONTAINER_WIDTH = 270;
export const DISCIPLINE_CONTAINER_HEIGHT = 100;
export const DISCIPLINE_CONTAINER_WIDTH = 280;
export const STATUSES = [
  {
    statusIndex: 0,
    Img: NotDelivered,
    text: <FormattedMessage id="RMP.STATUS.StatusInfo.NotDelivered" defaultMessage="Not delivered" />
  },
  {
    statusIndex: 1,
    Img: Delivered,
    text: <FormattedMessage id="RMP.STATUS.StatusInfo.Delivered" defaultMessage="Delivered" />
  },
  {
    statusIndex: 2,
    Img: OnReview,
    text: <FormattedMessage id="RMP.STATUS.StatusInfo.OnReview" defaultMessage="On review" />
  },
  {
    statusIndex: 3,
    Img: ReviewedWithComments,
    text: <FormattedMessage id="RMP.STATUS.StatusInfo.ReviewedWithComments" defaultMessage="Reviewed with comments" />
  },
  {
    statusIndex: 4,
    Img: Done,
    text: <FormattedMessage id="RMP.STATUS.StatusInfo.Done" defaultMessage="Done" />
  }
];

export const DEMANDS_STATUSES = [
  {
    statusIndex: 0,
    Img: NotDelivered,
    text: <FormattedMessage id="RMP.DemandsStatuses.StatusInfo.Planned" defaultMessage="Planned" />
  },
  {
    statusIndex: 1,
    Img: OnReview,
    text: <FormattedMessage id="RMP.DemandsStatuses.StatusInfo.Ongoing" defaultMessage="Ongoing" />
  },
  {
    statusIndex: 2,
    Img: OnReview,
    text: <FormattedMessage id="RMP.DemandsStatuses.StatusInfo.Ongoing" defaultMessage="Ongoing" />
  },
  {
    statusIndex: 3,
    Img: OnReview,
    text: <FormattedMessage id="RMP.DemandsStatuses.StatusInfo.Ongoing" defaultMessage="Ongoing" />
  },
  {
    statusIndex: 4,
    Img: Done,
    text: <FormattedMessage id="RMP.DemandsStatuses.StatusInfo.Done" defaultMessage="Done" />
  }
];

export const DEMANDS_STATUSES_COMP = [
  {
    statusIndex: 0,
    Img: NotDelivered,
    text: <FormattedMessage id="RMP.DemandsStatuses.StatusInfo.Planned" defaultMessage="Planned" />
  },
  {
    statusIndex: 1,
    Img: OnReview,
    text: <FormattedMessage id="RMP.DemandsStatuses.StatusInfo.Ongoing" defaultMessage="Ongoing" />
  },

  {
    statusIndex: 4,
    Img: Done,
    text: <FormattedMessage id="RMP.DemandsStatuses.StatusInfo.Done" defaultMessage="Done" />
  }
];

export const DEMANDS_TABS_LABELS = [
  {
    key: 'clientDirectives',
    label: <FormattedMessage id="RMP.EditRiskAssessmentDialog.DialogTitleTab.Directives" defaultMessage="Directives" />
  },
  {
    key: 'clientProvision',
    label: <FormattedMessage id="RMP.EditRiskAssessmentDialog.DialogTitleTab.Provisions" defaultMessage="Provisions" />
  },
  {
    key: 'clientStandarts',
    label: <FormattedMessage id="RMP.EditRiskAssessmentDialog.DialogTitleTab.Standards" defaultMessage="Standards" />
  },
  {
    key: 'clientOther',
    label: (
      <FormattedMessage
        id="RMP.EditRiskAssessmentDialog.DialogTitleTab.CompanySpecific"
        defaultMessage="Company specific"
      />
    )
  }
];

const PROJECT_MANAGER = 'PROJECTMANAGER';
const SUB_PROJECT_MANAGER = 'SUBPROJECTMANAGER';
const ROW_MANAGER = 'ROWMANAGER';
const PROJECT_ADMIN = 'PROJECTADMIN';
const SUPPLIER = 'SUPPLIER';
const REVIEWER = 'REVIEWER';
const MEMBER = 'MEMBER';

export const MEMBER_FUNCTIONS_ENUM = {
  PROJECT_MANAGER,
  SUB_PROJECT_MANAGER,
  ROW_MANAGER,
  PROJECT_ADMIN,
  SUPPLIER,
  REVIEWER,
  MEMBER
};

export const FUNCTION_IDENTIFIERS = [
  PROJECT_MANAGER,
  SUB_PROJECT_MANAGER,
  ROW_MANAGER,
  PROJECT_ADMIN,
  SUPPLIER,
  REVIEWER,
  MEMBER
];

export const getMemberFunction = (roleValue) => {
  for (const [key, value] of Object.entries(MEMBER_FUNCTIONS_ENUM)) {
    if (value === roleValue) return [key, value];
  }
  return ['MEMBER', MEMBER];
};

export const FUNCTION_LABELS = {
  [PROJECT_MANAGER]: <FormattedMessage id="RMP.Members.FuncLabel.PROJECTMANAGER" defaultMessage="Project manager" />,
  [SUB_PROJECT_MANAGER]: (
    <FormattedMessage id="RMP.Members.FuncLabel.SUBPROJECTMANAGER" defaultMessage="Sub Project manager" />
  ),
  [ROW_MANAGER]: <FormattedMessage id="RMP.Members.FuncLabel.ROWMANAGER" defaultMessage="Row manager" />,
  [PROJECT_ADMIN]: <FormattedMessage id="RMP.Members.FuncLabel.PROJECTADMIN" defaultMessage="Project administrator" />,
  [SUPPLIER]: <FormattedMessage id="RMP.Members.FuncLabel.SUPPLIER" defaultMessage="Supplier" />,
  [REVIEWER]: <FormattedMessage id="RMP.Members.FuncLabel.REVIEWER" defaultMessage="Reviewer" />,
  [MEMBER]: <FormattedMessage id="RMP.Members.FuncLabel.MEMBER" defaultMessage="Member" />
};

export const GET_DEFAULT_SUB_PROJECT = () => ({
  description: null,
  comment: null,
  uuid: uuidv4(),
  name: `Created at ${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`,
  documents: [],
  demands: [],
  supplier: '',
  contractorPL: null,
  imageName: 'dot_blue_1',
  date: new Date().valueOf(),
  nodeStatus: 'CHECKED_OUT',
  children: [],
  demandsStatus: {},
  documentationPositions: {},
  responsiblePerson: null,
  basises: [],
  clientDirectives: [],
  clientStandarts: [],
  clientProvision: [],
  clientOther: [],
  sortDocuments: [],
  demandsStatusSimplified: {}
});

export const getDocTypeDemands = () => {
  return [
    {
      defaultLabel: 'Validation protocol',
      locales: ['en', 'sv'],
      translations: ['Validation protocol', 'Valideringsprotokoll']
    },
    {
      defaultLabel: 'User manual',
      locales: ['en', 'sv'],
      translations: ['User manual', 'Bruksanvisning']
    },
    {
      defaultLabel: 'Risk assessment',
      locales: ['en', 'sv'],
      translations: ['Risk assessment', 'Riskbedömning']
    },
    {
      defaultLabel: 'Declaration of conformity',
      locales: ['en', 'sv'],
      translations: ['Declaration of conformity', 'Försäkran om överensstämmelse']
    }
  ];
};

export const PROJECT_PHASES = {
  PLANNING: {
    index: 0,
    key: 'PLANNING',
    name: <FormattedMessage id="RMP.PROGRESS_STEPS.Planing.Name" defaultMessage="Planning" />,
    tooltip: (
      <FormattedMessage
        id="RMP.PROGRESS_STEPS.Planing.Tooltip"
        defaultMessage="In this phase only Administrators, Project managers and Subproject managers can work with the project. It’s possible to setup demands and structure but it’s not possible to upload documentation or change statuses."
      />
    )
  },
  IMPLEMENTATION: {
    index: 1,
    key: 'IMPLEMENTATION',
    name: <FormattedMessage id="RMP.PROGRESS_STEPS.Implementation.Name" defaultMessage="Implementation" />,
    tooltip: (
      <FormattedMessage
        id="RMP.PROGRESS_STEPS.Implementation.ToolTip"
        defaultMessage="In this phase all project members can work with the project, it’s possible change statuses, upload documents and work with risk assessments."
      />
    )
  },
  FINALIZATION: {
    index: 2,
    key: 'FINALIZATION',
    name: <FormattedMessage id="RMP.PROGRESS_STEPS.Finalization.Name" defaultMessage="Finalization" />,
    tooltip: (
      <FormattedMessage
        id="RMP.PROGRESS_STEPS.Finalization.ToolTip"
        defaultMessage="In this phase only Administrators, Project managers, Subproject managers and Reviewers can work with the project. It’s possible to review documentation and change statuses but it’s not possible to add or remove demands."
      />
    )
  }
};

export const DEMANDS_PROGRESS = {
  DONE: {
    progressLevel: 2,
    key: 'DONE',
    color: '#10be43',
    formattedMessage: <FormattedMessage id="RMP.DEMANDS_PROGRESS.DONE" defaultMessage="Done" />
  },
  ONGOING: {
    progressLevel: 1,
    key: 'ONGOING',
    color: '#ff9800',
    formattedMessage: <FormattedMessage id="RMP.DEMANDS_PROGRESS.ONGOING" defaultMessage="Ongoing" />
  },
  PLANNED: {
    progressLevel: 0,
    key: 'PLANNED',
    color: '#d4d4d4',
    formattedMessage: <FormattedMessage id="RMP.DEMANDS_PROGRESS.PLANNED" defaultMessage="Planned" />
  }
};

export const getDefaultDiscipline = () => ({
  uuid: uuidv4(),
  address: '',
  zipCode: '',
  city: '',
  telephone: '',
  comment: '',
  name: `Created at ${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`,
  supplier: '',
  basis: [],
  demands: [],
  contractorPL: null,
  date: new Date().valueOf(),
  responsiblePerson: null
});

export const COPY_BUFFER_TYPES = {
  COPY: 'COPY',
  CUT: 'CUT',
  PASTE: 'PASTE'
};

export const TREE_NODE_TYPES = {
  NODE: 'NODE',
  SUB_PROJECT: 'SUB_PROJECT'
};

export const REPORT_TYPES = {
  TREE_TYPE: 0,
  DETAILS_TYPE: 1,
  REMAINING_THINGS_TYPE: 2,
  MATRIX_TYPE: 3,
  DEMANDS_TYPE: 4
};
