import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import getCriteriaDefaultState from '@zert-packages/utils/getCriteriaDefaultState';
import { connect } from 'react-redux';
import { prepareSearch, removeSearch } from './explorerReducers';

export const styles = (theme) => ({
  root: {},
  expanded: {
    margin: 0
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  details: {
    display: 'flex',
    padding: '0 1em 1em'
  }
});
export const controlStyles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 190
  },
  selectEmpty: {
    marginTop: '5px'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 190
  },

  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(1) / 4
  },
  noLabel: {
    marginTop: theme.spacing(1) * 3
  },

  spinField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 100
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  dense: {
    marginTop: 0
  },
  menu: {
    width: 200
  }
});

function lowercaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export default function withSearch(WrappedComponent, selectData) {
  const mapStateToProps = (state) => ({
    searchIgnoreLocalStorage: state.CORE.ignoreSearchLocalStorage,
    selectDataState: state.CORE.searchConditions[selectData.className]
  });
  return connect(mapStateToProps)(
    class extends React.Component {
      constructor(props) {
        super(props);
        this.expandPanel = this.expandPanel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        let data = {};
        let expanded = false;
        if (!props.searchIgnoreLocalStorage) {
          if (localStorage.getItem(selectData.index)) {
            try {
              data = JSON.parse(localStorage.getItem(selectData.index));
            } catch (error) {}
          }
        } else if (props.selectDataState) {
          expanded = props.selectDataState.expanded;
          props.selectDataState.values.forEach((v) => {
            data[v.key] = v.value;
          });
        }
        this.state = {
          expanded,
          data
        };
      }

      handleChange(state) {
        const keys = Object.keys(state);
        const json = {
          className: selectData.className,
          expanded: true,
          values: []
        };

        keys.map((key) => {
          json.values.push({
            key,
            value: state[key]
          });
        });
        state.expanded = true;
        this.props.dispatch(prepareSearch({ className: selectData.className, search: json }));
        if (!this.props.searchIgnoreLocalStorage) {
          localStorage.setItem(selectData.index, JSON.stringify(state));
        }
      }

      componentDidMount() {
        // ... that takes care of the subscription...
        //   DataSource.addChangeListener(this.handleChange);
        if (this.props.searchIgnoreLocalStorage) {
          if (this.props.selectDataState) {
            const { expanded } = this.props.selectDataState;
            const data = {};
            this.props.selectDataState.values.forEach((v) => {
              data[v.key] = v.value;
            });
            this.setState({
              className: this.props.selectDataState.className,
              expanded,
              data
            });
          }
        } else if (localStorage.getItem(selectData.index)) {
          let data = {};
          try {
            data = JSON.parse(localStorage.getItem(selectData.index));
          } catch (error) {}

          this.setState({
            expanded: data.expanded,
            data
          });
          const keys = Object.keys(data);
          const json = {
            className: selectData.className,
            expanded: data.expanded,
            values: []
          };

          keys.map((key) => {
            json.values.push({
              key,
              value: data[key]
            });
          });
          this.props.dispatch(prepareSearch({ className: selectData.className, search: json }));
        }
      }

      componentWillUnmount() {
        // DataSource.removeChangeListener(this.handleChange);
      }

      expandPanel = (panel) => (event, expanded) => {
        let data = { expanded };
        let useDefault = true;
        if (!this.props.searchIgnoreLocalStorage) {
          if (localStorage.getItem(panel)) {
            try {
              data = JSON.parse(localStorage.getItem(selectData.index));
              data.expanded = expanded;
            } catch (error) {}
            useDefault = false;
          }
        } else if (this.props.selectDataState) {
          this.props.selectDataState.values.forEach((v) => {
            data[v.key] = v.value;
          });
          useDefault = false;
        }
        if (useDefault) {
          try {
            const defaultCriteria = getCriteriaDefaultState(selectData.className);
            defaultCriteria.values.map((entry) => {
              data[entry.key] = entry.value;
            });
          } catch (error) {}
        }
        if (!this.props.searchIgnoreLocalStorage) {
          localStorage.setItem(panel, data);
        }
        if (!expanded) {
          this.props.dispatch(removeSearch({ className: selectData.className }));
          if (!this.props.searchIgnoreLocalStorage) localStorage.setItem(selectData.index, JSON.stringify(data));
        } else {
          this.handleChange(data);
        }
        this.setState({
          className: this.props.selectDataState ? this.props.selectDataState.className : '',
          expanded,
          data
        });
      };

      render() {
        const { classes } = this.props;
        // ... and renders the wrapped component with the fresh data!
        // Notice that we pass through any additional props
        return (
          <Accordion
            classes={classes}
            size="small"
            expanded={this.state.expanded === true}
            onChange={this.expandPanel(selectData.index)}
          >
            <AccordionSummary summary={classes} content={classes.expanded} expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>{selectData.header}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <WrappedComponent data={this.state.data} handleChange={this.handleChange} {...this.props} />
            </AccordionDetails>
          </Accordion>
        );
      }
    }
  );
}
