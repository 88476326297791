import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Divider, FormControlLabel, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import translateable from './image/translateable-icon-colored.png';
import SelectedCell from './SelectedCell';
import SelectedLanguagePopup from '../SelectedLanguagePopup';
import { PanelContext } from '../InfoElementWrapper/InfoElementWrapper';
import { storeLocalesFilters } from './API';
import { getApiLocalesFilters, getApiTree } from '../Structure/API';
import { findDisabled } from '../helpers/main';
import PublishingFilters from './PublicationFilters';

const useStyles = makeStyles(() => ({
  languageFiltersRoot: {
    flexGrow: 1,
    minHeight: 250,
    '& .MuiCollapse-entered': {
      height: '100% !important'
    },
    '& .MuiCollapse-wrapper': {
      height: '100%'
    },
    '& #panel1a-content': {
      height: '100%'
    }
  },
  titleFilter: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: 5
  },
  checkboxLabel: {
    fontSize: 15
  },
  wrapperContainerDisable: {
    position: 'relative',
    opacity: '0.57',
    '&:before': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      content: "' '",
      zIndex: 2
    }
  },
  wrapperTabs: {
    '& .MuiTab-textColorPrimary': {
      background: '#EBEBEB',
      padding: '5px 2.5px',
      minWidth: 'fit-content',
      minHeight: 26,
      height: 26,
      outline: 'none',
      '&:hover': {
        background: '#4db1d3 ',
        color: '#ffffff'
      }
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      background: '#4db1d3 ',
      color: '#ffffff'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left'
    },
    '& .MuiTabs-root': {
      minHeight: 26,
      marginBottom: 10
    },
    '& .PrivateTabIndicator-colorSecondary': {
      display: 'none'
    }
  }
}));

function FiltersPane({}) {
  const [checkboxState, setCheckboxState] = useState(false);
  const [selectedTab, setSelectTab] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const cl = useStyles();
  const [selected, setSelected] = useState(null);
  const { values, setValues } = useContext(PanelContext);
  const { versionId } = useParams();
  const { mylocale } = useSelector((state) => state);
  const id = values?.parentItem?.elementInfo?.versionId;
  const parentVersionId = id && values.investmentLevel > 0 ? id : versionId;
  const [disabled, setDisabled] = useState(true);

  const handleChangeCheckbox = (e) => {
    const val = e.target.checked;
  };

  const handleChangeSelectedTab = (event, newValue) => {
    setSelectTab(newValue);
  };

  const handleSetSelectedLanguage = async (el) => {
    const result = selectedLanguage.filter((item) => item !== el);
    await handleLanguage(false, result);
  };

  const getTree = async () => {
    return getApiTree(versionId, mylocale);
  };

  const handleLanguage = async (lng, remove) => {
    if (values.treeItem) {
      let resultFilters;
      let selectedTabLng;
      if (selectedTab === 1) {
        selectedTabLng = 'INCLUDE';
      }
      if (selectedTab === 2) {
        selectedTabLng = 'EXCLUDE';
      }

      if (values && values.localesFilters && values.localesFilters.right) {
        resultFilters = [lng, ...values.localesFilters.right];
      } else {
        resultFilters = [lng];
      }
      if (remove) {
        resultFilters = remove;
      }
      if (selectedTab === 0) {
        selectedTabLng = 'NONE';
        resultFilters = [];
      }
      const identifier = `${
        values.treeItem.identifier
          .replace(/['[']/g, '(')
          .replace(/['\]']/g, ')')
          .split(' ')[0]
          .split(')')[0]
      })`;
      await storeLocalesFilters(
        parentVersionId,
        values.treeItem.elementInfo.versionId,
        identifier,
        selectedTabLng,
        resultFilters
      );
      await getLocalesFilters();
      const resultGetTree = await getTree();
      setValues((prev) => ({ ...prev, treeChildren: resultGetTree }));
    }
  };

  const getLocalesFilters = async () => {
    const identifier = `${
      values.treeItem.identifier
        .replace(/['[']/g, '(')
        .replace(/['\]']/g, ')')
        .split(' ')[0]
        .split(')')[0]
    })`;
    const resLocalesFilters = await getApiLocalesFilters(
      parentVersionId,
      values.treeItem.elementInfo.versionId,
      identifier
    );

    setValues((prev) => ({ ...prev, localesFilters: resLocalesFilters }));
  };

  useEffect(() => {
    if (
      values.treeItem &&
      values.treeItem.identifier &&
      values.treeItem?.elementInfo?.versionId &&
      !values.treeItem.parentTreeItem
    ) {
      try {
        getLocalesFilters();
      } catch (e) {
        console.log('Error getLocalesFilters!', e.messages);
      }
    }
  }, [values.treeItem]);

  useEffect(() => {
    if (values.localesFilters) {
      if (values.localesFilters.left) {
        if (values.localesFilters.left === 'include') {
          setSelectTab(1);
        }
      }
      if (values.localesFilters.right && values.localesFilters.right.length > 0) {
        setSelectedLanguage(values.localesFilters.right);
      } else {
        setSelectedLanguage(null);
      }
      if (!values.localesFilters.left) {
        if (selectedTab > 0) {
          setSelectTab(0);
        }
        if (selectedLanguage) {
          setSelectedLanguage(null);
        }
      }
    }
  }, [values.localesFilters, values.treeItem]);

  useEffect(() => {
    if (values.treeItem) {
      setCheckboxState(values.treeItem.translatable);
    }
    findDisabled(values, setDisabled);
  }, [values.treeItem]);

  return (
    <div>
      <FormControlLabel
        control={<Checkbox disabled={disabled} onChange={handleChangeCheckbox} checked={checkboxState ?? false} />}
        label={
          <span className={cl.checkboxLabel}>
            <img src={translateable} alt="translateable" />{' '}
            <FormattedMessage id="translatable" defaultMessage="Translatable" />
          </span>
        }
      />
      <p className={cl.titleFilter}>
        <FormattedMessage id="LanguageFilters" defaultMessage="Language filter" />
      </p>

      <div className={disabled ? cl.wrapperContainerDisable : ''}>
        <div className={cl.wrapperTabs}>
          <Tabs
            value={selectedTab}
            onChange={handleChangeSelectedTab}
            textColor="primary"
            variant="standard"
            scrollButtons="auto"
            centered
          >
            <Tab label={<FormattedMessage id="none" defaultMessage="None" />} />
            <Tab label={<FormattedMessage id="include" defaultMessage="Include" />} />
            <Tab label={<FormattedMessage id="exclude" defaultMessage="Exclude" />} />
          </Tabs>
        </div>
        <SelectedLanguagePopup handleLanguage={handleLanguage} />
        <div>
          {selectedLanguage &&
            selectedLanguage.length > 0 &&
            selectedLanguage.map((el, idx, arr) => (
              <SelectedCell
                selected={selected}
                setSelected={setSelected}
                handleSetSelectedLanguage={handleSetSelectedLanguage}
                item={el}
                key={idx}
                idx={idx}
                lastCell={idx === arr.length - 1}
              />
            ))}
        </div>
        <Divider />
        <PublishingFilters />
      </div>
    </div>
  );
}
export default FiltersPane;
