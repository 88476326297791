export const adobeNumber = (number) => {
  if (JSON.stringify(number).includes(".")) {
    return number.toFixed(3);
  }
  return number;
};

const convertLength = (val, selectedDropMenu, valueAdopt) => {

  if (valueAdopt === 0) {
    return 0;
  }
  if (selectedDropMenu === "px") {
    if (val.name === "mm") {
      return valueAdopt * 0.26458;
    }
    if (val.name === "pt") {
      return valueAdopt * 0.75;
    }
    if (val.name === "cm") {
      return valueAdopt  / 37.8;
    }
    if (val.name === "in") {
      return valueAdopt * 0.0104166667;
    }
    if (val.name === "%") {
      return valueAdopt * 6.25;
    }
  }

  if (selectedDropMenu === 'pt') {
    if (val.name === 'px') {
      return valueAdopt / 0.75
    }
    if (val.name === 'mm') {
      return valueAdopt * 1.1918
    }
    if (val.name === 'cm') {
      return valueAdopt * 0.1192
    }
    if (val.name === 'in') {
     return  valueAdopt * 0.0469
    }
    if (val.name === "%") {
      return valueAdopt * 5.25;
    }
  }

  if (selectedDropMenu === 'mm') {
    if (val.name === 'px') {
      return valueAdopt * 1.1
    }
    if (val.name === 'pt') {
      return valueAdopt * 0.8391
    }
    if (val.name === 'cm') {
      return valueAdopt / 10
    }
    if (val.name === 'in') {
      return valueAdopt * 0.0394
    }
    if (val.name === "%") {
      return valueAdopt * 5.25;
    }
  }
  if (selectedDropMenu === 'cm') {
    if (val.name === 'px') {
      return valueAdopt * 11.2
    }
    if (val.name === 'pt') {
      return valueAdopt * 8.391
    }
    if (val.name === 'mm') {
      return valueAdopt * 10
    }
    if (val.name === 'in') {
      return valueAdopt * 0.0394
    }
    if (val.name === "%") {
      return valueAdopt * 5.25;
    }
  }

  if (selectedDropMenu === 'in') {
    if (val.name === 'px') {
      return valueAdopt * 28
    }
    if (val.name === 'pt') {
      return valueAdopt * 21.313
    }
    if (val.name === 'mm') {
      return valueAdopt * 25.4
    }
    if (val.name === 'cm') {
      return valueAdopt * 2.54
    }
    if (val.name === "%") {
      return valueAdopt * 5.25;
    }
  }
  if (selectedDropMenu === '%') {
    if (val.name === 'px') {
      return valueAdopt / 6.25
    }
    if (val.name === 'pt') {
      return valueAdopt * 75156.48
    }
    if (val.name === 'mm') {
      return valueAdopt * 25.4
    }
    if (val.name === 'cm') {
      return valueAdopt * 2.54
    }
  }

};

export default convertLength;