import React, { Fragment, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import { ListItemSecondaryAction, ListItemText, ListItem, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { turnOnCLMLoader } from '../TranslationJobPlugin/tjReducers';
import { removeTerm } from './API';

const useStyles = makeStyles((theme) => ({
  scrollWrapper: {
    position: 'relative',
    width: '25%',
    /*  height: '100%',
        maxHeight: '100%', */
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    margin: '2px',

    '@media (max-width: 900px)': {
      width: '100%',
      height: '40%'
    }
  },
  scrollContainer: {
    position: 'absolute',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    scrollbarWidth: 'none',
    padding: '16px'
  },
  listItem: {
    transition: 'all .3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      transition: 'all .3s'
    }
  },
  listItemSelected: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    transition: 'all .3s'
  },

  addIcon: {
    height: '16px',
    width: '16px'
  },
  addIconButton: {
    position: 'absolute',
    left: -11.25,
    bottom: -11.25,
    zIndex: 1,
    outline: 'none'
  }
}));
const scrollableListRef = React.createRef();

function TermsList({ termsList, handleAddNewTerm, handleScrollToItem, versionId, scrollToItem, versionManager }) {
  const cl = useStyles();
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);

  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (scrollableListRef && scrollableListRef.current && scrollableListRef.current.scrollTo) {
      const LIST_ITEM_HEIGHT = 33.5;
      const NUM_OF_VISIBLE_LIST_ITEMS = 9;
      const index = termsList.findIndex((term, index) => {
        return scrollToItem == term.id;
      });

      const amountToScroll = LIST_ITEM_HEIGHT * index;
      scrollableListRef.current.scrollTo(0, amountToScroll);
    }
  }, [scrollToItem]);

  const handleChangeTextField = (e) => {
    e.persist();

    setValue(e.target.value);
  };

  const handleProcessingAddNewTerm = async (e) => {
    e.persist();
    setError(null);

    try {
      await handleAddNewTerm(e, value);
      setValue('');
    } catch (err) {
      if (err && err.message) setError(err.message);
      console.error(err);
    }
  };

  const handleDeleteTerm = async (e, id) => {
    e.persist();
    dispatch(turnOnCLMLoader());
    await removeTerm(versionId, id);
    await handleAddNewTerm(e, null, id);
  };

  return <>
    {termsList && (
      <div className={cl.scrollWrapper}>
        {!versionManager.elementDisabled && (
          <Box display="grid" padding="1em 1em 0em 1em" gap="0.5em">
            <TextField
              id="standard-basic"
              fullWidth
              margin="0"
              label={intl.formatMessage({ id: 'TermsList.NewTerm', defaultMessage: 'New term' })}
              onChange={handleChangeTextField}
              value={value}
            />
            <Button color="primary" variant="outlined" onClick={handleProcessingAddNewTerm}>
              <FormattedMessage id="TermsList.Add" defaultMessage="Add" />
            </Button>
          </Box>
        )}
        <div
          className={cl.scrollContainer}
          ref={scrollableListRef}
          style={{ height: !versionManager.elementDisabled ? 'calc(100% - 110px)' : '100%' }}
        >
          {error && (
            <Typography variant="subtitle2" style={{ color: 'red' }}>
              {error}
            </Typography>
          )}
          <List dense selected={cl.listItemSelected}>
            {termsList.map((item) => (
              <Fragment key={`termsList${item.id}`}>
                <ListItem
                  classes={{ selected: cl.listItemSelected, root: cl.listItem }}
                  selected={scrollToItem && scrollToItem == item.id}
                  onClick={(e) => handleScrollToItem(e, item)}
                >
                  <ListItemText primary={item.name} />
                  {!versionManager.elementDisabled && (
                    <ListItemSecondaryAction>
                      <IconButton size="small" onClick={(e) => handleDeleteTerm(e, item.id)}>
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
                <Divider variant="middle" component="li" />
              </Fragment>
            ))}
          </List>
        </div>
      </div>
    )}
  </>;
}

export default TermsList;
