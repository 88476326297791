import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { FormattedMessage, injectIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import withSearch, { styles, controlStyles } from '../withSearch';

import SearchComponent from './SearchComponent';

const componentStyles = {
  numberOfPeriodContainer: {
    display: 'grid',
    margin: '10px'
  }
};

class ChecklistSearch extends SearchComponent {
  constructor(props) {
    super(props);
    if (!this.state || !this.state.countLESS) {
      this.state = {
        incorrect: false,
        noAnswers: false,
        countGT: -1,
        countLESS: -1,
        countGTM: -1,
        countLESSM: -1
      };
    }
    this.handleChange = this.handleChange.bind(this);
    this.textInput = React.createRef();
  }

  handleChange = (name) => (event) => {
    this.setStatus({ [name]: event.target.value });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    this.props.expanded && this.textInput.current ? this.textInput.current.blur() : this.textInput.current.focus();
  }

  render() {
    const { classes } = this.props;
    // this.props.handleChange(this.state);
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        <FormControlLabel
          autoFocus
          inputRef={this.textInput}
          value="in_the_last"
          className={classes.formControl}
          fullWidth
          control={
            <Checkbox
              color="primary"
              checked={this.state.noAnswers}
              onChange={(e) => {
                this.setStatus({ noAnswers: e.target.checked });
              }}
              value="after"
              name="Type"
              aria-label={<FormattedMessage id="search.rm.noAnswers" defaultMessage="Contains no answers" />}
              label={<FormattedMessage id="search.rm.noAnswers" defaultMessage="Contains no answers" />}
            />
          }
          label={<FormattedMessage id="search.rm.noAnswers" defaultMessage="Contains no answers" />}
          labelPlacement="end"
        />
        <FormControlLabel
          value="in_the_last"
          className={classes.formControl}
          fullWidth
          control={
            <Checkbox
              color="primary"
              checked={this.state.incorrect}
              onChange={(e) => {
                this.setStatus({ incorrect: e.target.checked });
              }}
              value="after"
              name="Type"
              aria-label={<FormattedMessage id="search.rm.incorrect" defaultMessage="Contains incorrect answers" />}
              label={<FormattedMessage id="search.rm.incorrect" defaultMessage="Contains incorrect answers" />}
            />
          }
          label={<FormattedMessage id="search.rm.incorrect" defaultMessage="Contains incorrect answers" />}
          labelPlacement="end"
        />
        <div style={componentStyles.numberOfPeriodContainer}>
          <TextField
              size="small"
            style={{ width: '100%' }}
            id="standard-in_the_last"
            label={<FormattedMessage id="search.riskrow.morethan" defaultMessage="Has more than risks" />}
            className={classes.spinField}
            value={this.state.countGT}
            onChange={this.handleChange('countGT')}
            type="number"
            variant="outlined"
          />
        </div>
        <div style={componentStyles.numberOfPeriodContainer}>
          <TextField
              size="small"
            style={{ width: '100%' }}
            id="standard-in_the_last"
            label={<FormattedMessage id="search.riskrow.lessthan" defaultMessage="Has less than risks" />}
            className={classes.spinField}
            value={this.state.countLESS}
            onChange={this.handleChange('countLESS')}
            type="number"
            variant="outlined"
          />
        </div>

        <div style={componentStyles.numberOfPeriodContainer}>
          <TextField
              size="small"
            style={{ width: '100%' }}
            id="standard-in_the_last"
            label={<FormattedMessage id="search.measures.morethan" defaultMessage="Has more than measures" />}
            className={classes.spinField}
            value={this.state.countGTM}
            onChange={this.handleChange('countGTM')}
            type="number"
            variant="outlined"
          />
        </div>
        <div style={componentStyles.numberOfPeriodContainer}>
          <TextField
              size="small"
            style={{ width: '100%' }}
            id="standard-in_the_last"
            label={<FormattedMessage id="search.measures.lessthan" defaultMessage="Has less than measures" />}
            className={classes.spinField}
            value={this.state.countLESSM}
            onChange={this.handleChange('countLESSM')}
            type="number"
            variant="outlined"
          />
        </div>
      </form>
    );
  }
}

ChecklistSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.context.checklist" defaultMessage="Search in checklist" />,
  index: 'checklist-criteria',
  className: 'se.zert.rm.search.ChecklistCriteria',
  default: {
    countGT: -1,
    countLESS: -1,
    countGTM: -1,
    incorrect: false,
    noAnswers: false,
    countLESSM: -1
  }
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(
  injectIntl(withStyles(styles)(withSearch(withStyles(controlStyles)(ChecklistSearch), selectData)))
);
