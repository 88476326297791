import { useSelector } from 'react-redux';
import { getPluginByMimeType, getPluginTranslatableName } from '@zert-packages/utils/getPluginByMimeType';
import { getTileElementStatus } from './getTileStatus';
import { descendingComparator } from './descendingComparator';

export const useElementSorter = (intl) => {
  const myUser = useSelector((state) => state.myuser);
  const getSorter = (name) => {
    switch (name) {
      case 'nameNew':
        return (a, b, sortBy, sortDirection) => {
          if (a.mimeType === 'application/zert-folder' && b.mimeType !== 'application/zert-folder') {
            return sortDirection === 'asc' ? 1 : -1;
          }
          if (b.mimeType === 'application/zert-folder' && a.mimeType !== 'application/zert-folder') {
            return sortDirection === 'asc' ? -1 : 1;
          }

          if (a.mimeType === 'application/zert-search-folder' && b.mimeType !== 'application/zert-search-folder') {
            return sortDirection === 'asc' ? 1 : -1;
          }
          if (b.mimeType === 'application/zert-search-folder' && a.mimeType !== 'application/zert-search-folder') {
            return sortDirection === 'asc' ? -1 : 1;
          }

          // NUMBER_PATTERN.
          return descendingComparator(a, b, 'name', sortDirection);
        };
      case 'label':
        return (a, b, sortBy, sortDirection) => {
          if (a.label === b.label) {
            return 0;
          }

          // Special sorting algorithm compares version labels:
          // 1.2.3 vs 4.23.7

          const parts1 = a.label.split('.');
          const parts2 = b.label.split('.');

          const size = Math.min(parts1.length, parts2.length);
          for (let i = 0; i < size; i++) {
            if (parts1[i] == parts2[i]) {
              // proceed to next point
              continue;
            }
            try {
              return parseInt(parts1[i]) - parseInt(parts2[i]);
            } catch (e) {
              return parts1[i].compareTo(parts2[i]);
            }
            /// jakso ne pryvesty do integera porivnujuts dovzyny v string
          }
          return parts1.length - parts2.length;
        };
      case 'deadline':
        return (a, b, orderBy) => {
          if (a.properties !== null && b.properties !== null) {
            if (a.properties['zert:CurrentActivityDeadline'] > b.properties['zert:CurrentActivityDeadline']) {
              return 1;
            }
            if (a.properties['zert:CurrentActivityDeadline'] < b.properties['zert:CurrentActivityDeadline']) {
              return -1;
            }
          } else if (a.createdAt !== null && b.createdAt !== null) {
            if (a.createdAt > b.createdAt) {
              return 1;
            }
            if (a.createdAt < b.createdAt) {
              return -1;
            }
          }
        };

      case 'mimeTypeNew':
        return (a, b, orderBy) => {
          const pluginA = getPluginTranslatableName(intl, getPluginByMimeType(a.mimeType));
          const pluginB = getPluginTranslatableName(intl, getPluginByMimeType(b.mimeType));

          if (pluginA < pluginB) {
            return 1;
          }
          if (pluginA > pluginB) {
            return -1;
          }
          return 0;
        };
      case 'status':
        return (a, b, orderBy) => {
          const status1 = getTileElementStatus(a, myUser);
          const status2 = getTileElementStatus(b, myUser);
          if (status1 < status2) {
            return -1;
          }
          if (status1 > status2) {
            return 1;
          }
          return 0;
        };
      case 'date':
        return (a, b, sortBy, sortDirection) => {
          if (a.changedAt !== null && b.changedAt !== null) {
            if (a.changedAt > b.changedAt) {
              return 1;
            }
            if (a.changedAt < b.changedAt) {
              return -1;
            }
          } else if (a.createdAt !== null && b.createdAt !== null) {
            if (a.createdAt > b.createdAt) {
              return 1;
            }
            if (a.createdAt < b.createdAt) {
              return -1;
            }
          }
        };
      case 'changedAt':
        return (a, b) => {
          if (a.changedAt !== null && b.changedAt !== null && a.changedAt > b.changedAt) {
            return 1;
          }
          if (a.changedAt !== null && b.changedAt !== null && a.changedAt < b.changedAt) {
            return -1;
          }
        };
      case 'createdAt':
        return (a, b) => {
          if (a.createdAt !== null && b.createdAt !== null && a.createdAt > b.createdAt) {
            return 1;
          }
          if (a.createdAt !== null && b.createdAt !== null && a.createdAt < b.createdAt) {
            return -1;
          }
        };
      case 'changedBy':
        return (a, b) => {
          if (a.changedBy !== null && b.changedBy !== null && a.changedBy > b.changedBy) {
            return 1;
          }
          if (a.changedBy !== null && b.changedBy !== null && a.changedBy < b.changedBy) {
            return -1;
          }
        };
      case 'createdBy':
        return (a, b) => {
          if (a.createdBy !== null && b.createdBy !== null && a.createdBy > b.createdBy) {
            return 1;
          }
          if (a.createdBy !== null && b.createdBy !== null && a.createdBy < b.createdBy) {
            return -1;
          }
        };
      case 'id':
        return (a, b) => {
          if (a.id !== null && b.id !== null && a.id > b.id) {
            return 1;
          }
          if (a.id !== null && b.id !== null && a.id < b.id) {
            return -1;
          }
        };
      default:
        return descendingComparator;
    }
  };

  return { getSorter };
};
