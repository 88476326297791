import React from 'react';
import get from 'lodash/get';
import SomethingWentWrongDialog from '@zert-packages/components/common/dialogs/SomethingWentWrongDialog';
import RiskAnalyses from './dialogs/RiskAnalyses';
import Checklist from './dialogs/Checklist';
import Project from './dialogs/Project';
import Common from './dialogs/Common';

const getDuplicationDialog = (element) => {
  const mimeType = get(element, 'mimeType', null);
  if (!mimeType) return SomethingWentWrongDialog;

  const Dialogs = {
    'application/zert-risk-analysis': RiskAnalyses,
    'application/zert-checklist': Checklist,
    'application/zert-project': Project,
    common: Common
  };

  return Dialogs[mimeType] || Dialogs.common;
};

export default getDuplicationDialog;
