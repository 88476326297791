import React, { useContext, useState } from "react";

import { TextFieldWithLanguageSwitchMemo } from "@zert-packages/components/common/TextFieldWithLangaugeSwitch";
import makeStyles from "@mui/styles/makeStyles";

const style = {
  display: "flex",
  flexDirection: "row-reverse"
};

const useStyles = makeStyles((theme) => ({
  wrapperLanguage: {
    display: "grid",
    gridTemplateColumns: "100px 1fr",
    gridColumnGap: 10,
    "& button": {
      padding: 9,
      position: "relative",
      bottom: 4
    },
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginBottom: 10
    }
  },
  title: {
    fontWeight: 900
  }
}))

const WrapperLabelInputItem = ({title='Label:', name ='label',labelValues, setLabelValues }) => {
  const cl = useStyles();

  const updateName = (value) => {
    setLabelValues(value)
  };

  return(
    <div className={cl.wrapperLanguage}>
      <div className={cl.title}>{title}</div>
    <TextFieldWithLanguageSwitchMemo
      disabled={false}
      onChange={updateName}
      state={labelValues}
      style={style}
    />
    </div>
  )
}

export default WrapperLabelInputItem