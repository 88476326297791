import React, { memo, useMemo } from 'react';
import MTreeView from '@mui/lab/TreeView';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TreeWidgetNode from './TreeWidgetNode';
import { TreeWidgetProvider } from './TreeWidgetContext';

const useStyles = makeStyles({
  root: {
    padding: 8,
    display: 'grid',
    overflow: 'hidden',
    position: 'relative',
    gridTemplateRows: '1fr auto'
  },
  treeContainer: {
    overflow: 'auto',
    height: '100%'
  },
  actionsContainer: {
    overflow: 'auto',
    display: 'flex',
    alignItems: 'stretch',
    flexFlow: 'column nowrap'
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'flex-start'
  }
});

const HEIGHT_OF_ACTION = 30;
const TreeWidget = memo((props) => {
  const {
    rootNode,
    expanded,
    onNodeToggle,
    defaultExpanded = [],
    selectedNodeId,
    onSelect,
    actions = [],
    highlightedId
  } = props;
  const cl = useStyles();
  const actionsHeight = actions.length * HEIGHT_OF_ACTION;
  const createOnSelect = (id, label) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    onSelect(id, label);
  };

  const contextValue = useMemo(() => ({ highlightedId }), [highlightedId]);
  return (
    <div className={cl.root}>
      <div className={cl.treeContainer}>
        <TreeWidgetProvider value={contextValue}>
          <MTreeView
            expanded={expanded}
            onNodeToggle={onNodeToggle}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultExpanded={defaultExpanded}
            defaultEndIcon={<div style={{ width: 24 }} />}
          >
            <TreeWidgetNode root node={rootNode} createOnSelect={createOnSelect} selectedNodeId={selectedNodeId}>
              {rootNode.children.map((child, index) => (
                <TreeWidgetNode
                  selectedNodeId={selectedNodeId}
                  createOnSelect={createOnSelect}
                  node={child}
                  nodePath={`!${child.id}!`}
                  key={child.id}
                  index={index}
                />
              ))}
            </TreeWidgetNode>
          </MTreeView>
        </TreeWidgetProvider>
      </div>
      {actions.length > 0 && (
        <div className={cl.actionsContainer}>
          {actions.map((action, index) => (
            <ActionButton
              key={index}
              label={action.label}
              onClick={action.onClick}
              disabled={action.disabled}
              icon={action.icon}
            />
          ))}
        </div>
      )}
    </div>
  );
});

export default memo(TreeWidget);

function ActionButton({ disabled, label, onClick, icon: Icon }) {
  const cl = useStyles();
  return (
    <Button
      className={cl.actionButton}
      size="small"
      startIcon={<Icon color="black" />}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}
