import React, { useRef, useState } from 'react';
import './addFileButton.css';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@zert-packages/icons/SvgIcon/index';
import classNames from 'classnames';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
// import SomethingWentWrongDialog from '@zert-packages/components/common/dialog/SomethingWentWrongDialog';
import SomethingWentWrongDialog from '@zert-packages/components/common/dialogs/SomethingWentWrongDialog';
import { FormattedMessage } from 'react-intl';
import { MAX_FILE_SIZE } from '@zert-packages/plugins/Environment';
import { v4 as uuidv4 } from 'uuid';
import ImageUploader from "../ImageUploader/ImageUploader";

const SIZE_OF_MB = 1048576;

function FileToLargeMessage() {
  return (
    <FormattedMessage
      id="addFileButton.fileTooLargeError"
      defaultMessage="File is too large to upload. File size can not exceed {maxSize}MB."
      values={{ maxSize: MAX_FILE_SIZE / SIZE_OF_MB }}
    />
  );
}

function FileTypeNotAllowedMessage({ fileTypes = "" }) {
  return (
    <FormattedMessage
      id="addFileButton.fileTypeNotAllowedError"
      defaultMessage="Unsupported file type. Supported file types: {fileTypes}"
      values={{ fileTypes: fileTypes }}
    />
  );
}

export function validateFileType(restrictedFileTypes, file) {
  const restrictedTypesArray = restrictedFileTypes.split(',').map(type => type.trim());
  const fileExtension = file.type.split('/').pop();

  if (!restrictedTypesArray.includes(`.${fileExtension}`)) {
    return renderInExceptionRoot(SomethingWentWrongDialog, {
      contextText: <div><FileTypeNotAllowedMessage fileTypes={restrictedFileTypes} /></div>
    })
  }
  return null;
}

export function AddFileButtonLimited({ handleUpload, label, icon,
                                       size = 'small', onlyIcon = false,
                                       color='primary', disabled = false}) {
  const [file, setFile] = useState(null);
  const [index, setIndex] = useState(uuidv4());
  const [error, setError] = useState(false);
  const form = useRef();
  const uploadFile = useRef();

  const onClick = async (e) => {
    if (uploadFile.current != null) uploadFile.current.click();
  };

  const onChange = async (e) => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      return renderInExceptionRoot(SomethingWentWrongDialog, {
        contextText: <FileToLargeMessage />
      });
    }
    setFile(e.target.files[0]);
    await handleSubmit(e.target.files[0]);
  };

  const handleSubmit = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    await handleUpload(formData, file.name);
    // e.preventDefault();
    setIndex(uuidv4());
  };

  const btnExpandClass = classNames({
    'add-file-buttonLimited': true
  });

  return (
    <>
      {onlyIcon ?
        (<IconButton size={size} onClick={(e) => onClick(e)} ref={form} color={color}
        disabled={disabled}>
          {icon}
        </IconButton>) :
        (<Button size={size} onClick={(e) => onClick(e)} startIcon={icon}
                ref={form} color={color}
        disabled={disabled}>
          {label}
        </Button>)
      }

      <input
        ref={uploadFile}
        type="file"
        onChange={(e) => {
          onChange(e);
        }}
        style={{ display: 'none' }}
        disabled={disabled}
      />
    </>
  );
}

function AddFileButton({ handleUpload, className, iconName, text, fillName, isImageUpload = false, restrictedFileTypes = ""}) {
  const [file, setFile] = useState(null);
  const [index, setIndex] = useState(uuidv4());
  const [error, setError] = useState(false);
  const form = useRef();
  const uploadFile = useRef();
  const [showImageEditor, setShowImageEditor] = useState(false);

  const onChange = async (e) => {
    handleFileSelect(e.target.files[0]);
  };

  const imageUploaderSelectImage = async (image) => {
    setShowImageEditor(false);
    handleFileSelect(image);
  }

  const handleFileSelect = async (file) => {
    if (file.size > MAX_FILE_SIZE) {
      return renderInExceptionRoot(SomethingWentWrongDialog, {
        contextText: <div><FileToLargeMessage /></div>
      });
    }
    if (restrictedFileTypes && validateFileType(restrictedFileTypes, file) != null) {
      return;
    }
    setFile(file);
    await handleSubmit(file);
  }

  const handleSubmit = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    await handleUpload(formData, file.name);
    setIndex(uuidv4());
    setFile(null);
  };

  const addedClass = !className ? 'defaultClass' : className;
  const btnExpandClass = classNames({
    [addedClass]: className != null,
    'add-file-button': true
  });

  return (
    <>
        <label className={btnExpandClass} style={{ width: iconName && text && 'unset' }} onClick={() => setShowImageEditor(true)}>
            {iconName && !text && <SvgIcon icon={iconName} fill={fillName} />}
            {iconName && text && (
                <span style={{ color: '#188ec7' }}>
          <SvgIcon icon={iconName} fill={fillName} width="20px" height="16px" />
        </span>
            )}
            {text !== undefined && <span style={{ color: '#188ec7', padding: '5px' }}>{text}</span>}
            {isImageUpload && showImageEditor ? <></> :
                (
                    <form key={index} onSubmit={handleSubmit} ref={form}>
                      {restrictedFileTypes ?
                          <input ref={uploadFile} type="file" accept={restrictedFileTypes} onChange={onChange}/>
                          : <input ref={uploadFile} type="file" onChange={onChange}/>}
                    </form>
                )}
        </label>
        {isImageUpload && showImageEditor && (<ImageUploader restrictedFileTypes={restrictedFileTypes} onChange={imageUploaderSelectImage} onClose={() => setShowImageEditor(false)}/>)}
    </>
  );
}

export default AddFileButton;
