import React, {useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import AddMetaDataDialog from "./AddMetaDataDialog";


const useStyles = makeStyles((theme) => ({
    wrapperInput: {
        display: "grid",
        gridTemplateColumns: "1fr 25px",
        marginBottom: 10
    },
    label: {
        fontWeight: 900
    },
    listWrapper: {
        border: "1px solid #BDBDBC",
        margin: "0px 10px",
        borderRadius: 5,
        overflow: "auto",
        minHeight: 200,
        maxHeight: 200
    },
    listWrapperScroll: {},
    wrapperTitle: {
        padding: "2px 4px",
        cursor: "pointer"
    },
    wrapperTitleSelected: {
        padding: "2px 4px",
        cursor: "pointer",
        background: "#4db1d3"
    },

    wrapperEditor: {
        color: "#4db1d3",
        "& svg": {
            cursor: "pointer"
        }
    }
}));

const ListSelected = () => {
    const cl = useStyles();
    const [selectedSettings, setSelectSettings] = useState(-1);

    const handleOpenDialog = (type) => {
        renderInExceptionRoot(AddMetaDataDialog, {})
    }

    const deleteFormat = () => {

    }

    return(<div className={cl.wrapperInput}>
        <div className={cl.listWrapper}></div>
        <div className={cl.wrapperEditor}>
            <AddIcon onClick={() =>  handleOpenDialog("Add")}/>
            <CreateIcon onClick={() =>  handleOpenDialog("edit")}/>
            <DeleteIcon onClick={deleteFormat}/>
        </div>
    </div>)
}

export default ListSelected