import React from 'react';
import { useParams } from 'react-router-dom';
import InfoElementWrapper from '@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper';
import BlockWrapper from './BlockWrapper';

function BlockView() {
  const { versionId } = useParams();

  if (!versionId || versionId < 0) {
    return <div className="content-cage" />;
  }

  return (
    <div className="content-cage" style={{ overflowY: 'auto' }}>
      <InfoElementWrapper>
        <BlockWrapper />
      </InfoElementWrapper>
    </div>
  );
}

export default BlockView;
