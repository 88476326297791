import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function VerifyXLIFFDialog({ unmountComponent, html, legend, onConfirm, onCancel, locale, containsErrors }) {
  const handleOnCancel = () => {
    unmountComponent();
    if (onCancel) onCancel();
  };
  return (
    <Dialog maxWidth="sm" fullWidth open onClose={onCancel}>
      <DialogTitle>
        <FormattedMessage
          id="ValidateDialog.Title"
          defaultMessage="Validating xliff file for locale {locale}"
          values={{ locale }}
        />
      </DialogTitle>

      <DialogContent>
        <Typography variant="body2" gutterBottom>
          {legend}
        </Typography>
        <div className="news" style={{ marginTop: '30px' }} dangerouslySetInnerHTML={{ __html: html }} />
      </DialogContent>

      <DialogActions>
        <Button onClick={onConfirm} disabled={containsErrors} color="primary">
          <FormattedMessage id="VerifyPassword.Confirm" defaultMessage="Confirm" />
        </Button>

        <Button onClick={handleOnCancel} color="primary" variant="outlined">
          <FormattedMessage id="VerifyPassword.Cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default VerifyXLIFFDialog;
