import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 414,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700
  },
  wrapper: {},
  titleInput: {
    fontWeight: 700,
  },
  wrapperInput: {
    display: 'flex',
    gap: 10,
    marginTop: 10,
    marginBottom: 15,
  },
  wrapperButton: {
    display: "flex"
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

const DialogDownloadImg = ({action}) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  // const [img, setImg] = useState(null)

  const handleClose = () => {
    setOpen(false);
  };

   const saveImg = (e) => {
     const file = e.target.files[0]
     const reader = new FileReader();
     // reader.onload = (event) => {
     //   setImg(event.target.result)
     // };
     reader.readAsDataURL(file);
     if(action){
       action()
     }
   }

   const handleSave = () => {
     handleClose()
   }

  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>Upload file...</span>
      <CloseIcon onClick={handleClose} />
    </div>
    <div className={cl.wrapper}>
      <div className={cl.wrapperInput}>
        <p className={cl.titleInput}>File name:</p>
        <input
          type="file"
          id="file1"
          onChange={saveImg}
        />
      </div>
      <div className={cl.wrapperButton}>
        <Button className={cl.button} onClick={handleSave}>
          OK
        </Button>
        <Button className={cl.button} onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </div>
  </Dialog>);
};

export default DialogDownloadImg;