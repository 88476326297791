import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadUserList,
  loadCompany,
  removeUser,
  storeGroupAsync,
  createGroupAsync,
  SELECT_USER
} from '@zert-packages/actions/coreReducers';

import { API_ROOT_WAR } from '@zert-packages/actions/api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import dialogCustom from '@zert-packages/components/common/dialogCustom';
import SearchableTable from '@zert-packages/components/shared/SearchableTable/SearchableTable';
import DeleteConfirmDialog from '@zert-packages/components/shared/DeleteConfirmDialog';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton,   Avatar} from '@mui/material';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { useHistory } from 'react-router-dom';
import NewUserDialog from './NewUserDialog';

export const UserListContext = React.createContext(null);

const roles = [
  {
    value: 'administrator',
    label: <FormattedMessage id="settings.role.1administrator" defaultMessage="Super admin" />
  },
  {
    value: 'consumer',
    label: <FormattedMessage id="settings.role.4consumer" defaultMessage="Consumer" />
  },
  {
    value: 'task-administrator',
    label: <FormattedMessage id="settings.role.2taskAdmin" defaultMessage="Administrator" />
  },
  {
    value: 'producer',
    label: <FormattedMessage id="settings.role.3producer" defaultMessage="Producer" />
  }
];

const styles = {
  actionsContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr',
    gridGap: '10px'
  }
};

const columns = [
  {
    label: '',
    identifier: 'edit',
    isFilterable: false,
    isSortable: false,
    isAction: true,
    width: '2.5%'
  },
  {
    label: '',
    identifier: 'delete',
    isFilterable: false,
    isSortable: false,
    isAction: true,
    width: '2.5%'
  },
]

const groupColumns = [
  ...columns,
  {
    label: <FormattedMessage id="settings.table.name" defaultMessage="Name" />,
    identifier: 'fullName',
    isFilterable: true,
    isSortable: true,
    isAction: false,
    width: '25%'
  },
]

const userColumns = [
  ...columns,
  {
    label: <FormattedMessage id="settings.table.username" defaultMessage="User name" />,
    identifier: 'avatar',
    isFilterable: false,
    isSortable: false,
    isAction: false,
    width: '2.5%'
  },
  {
    label: <FormattedMessage id="settings.table.username" defaultMessage="User name" />,
    identifier: 'userName',
    isFilterable: true,
    isSortable: true,
    isAction: false,
    width: '25%'
  },
  {
    label: <FormattedMessage id="settings.table.name" defaultMessage="Name" />,
    identifier: 'fullName',
    isFilterable: true,
    isSortable: true,
    isAction: false,
    width: '25%'
  },
  {
    label: <FormattedMessage id="settings.table.email" defaultMessage="Email" />,
    identifier: 'email',
    isFilterable: true,
    isSortable: true,
    isAction: false,
    width: '20%'
  },
  {
    label: <FormattedMessage id="settings.table.role" defaultMessage="Role" />,
    identifier: 'role',
    isFilterable: true,
    isSortable: true,
    isAction: false,
    width: '10%'
  },
  {
    label: <FormattedMessage id="settings.table.active" defaultMessage="Active" />,
    identifier: 'active',
    isFilterable: true,
    isSortable: true,
    isAction: false,
    width: '5%'
  }
];

function createRows(data, handles) {
  return data.map((item) => {
    const itemId = item.elementId;

    let roleLabel;
    Object.keys(roles).forEach((key) => {
      if (roles[key].value == item.properties.role) {
        roleLabel = roles[key].label;
      }
    });

    return {
      edit: (
        <IconButton onClick={() => handles.edit(itemId)} size="small">
          <EditIcon fontSize="inherit" />
        </IconButton>
      ),
      delete: (
        <IconButton onClick={() => handles.delete(itemId)} size="small">
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      ),
      id: itemId,
      avatar : item.properties.username ? (item.properties.GUID ? <Avatar style = {{width: '25px', height: '25px'}}sizes="20px" src = {`/zert/admin/getUserPhoto/${item.properties.TENANTID}/${item.properties.GUID}`}>
            { item.properties.username[0] }
            </Avatar>
            : <Avatar style = {{width: '25px', height: '25px'}}  sizes="20px">
            { item.properties.username[0]}
            </Avatar>) : null,
      userName: item.properties.username,
      fullName: item.properties.fullname,
      email: item.properties.email,
      role: roleLabel,
      active:
        item.properties.active === 'true' ? (
          <FormattedMessage id="settings.tablelist.active" defaultMessage="Active" />
        ) : (
          <FormattedMessage id="settings.tablelist.disabled" defaultMessage="Disabled" />
        )
    };
  });
}

const getFilterFunc = (groups) => {
  if (groups) {
    return (user) => user?.mimeType === 'application/zert-group';
  } else {
    return (user) => user?.mimeType === 'application/zert-user';
  }
}

export default function UserList({ companyid: companyId, groups=false }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userslist: usersList, company, loading, loadingUsers, selectedUserId } = useSelector((state) => state);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    const newList = usersList.filter(getFilterFunc(groups));
    setFilteredList(newList);
  }, [usersList]);

  useEffect(() => {
    const newList = usersList.filter(getFilterFunc(groups));
    setFilteredList(newList);
  }, []);

  useEffect(() => {
    if (!company || company.companyId != companyId) {
      dispatch(loadCompany(companyId));
    }
  }, []);

  useEffect(() => {
    if (company) {
      dispatch(loadUserList(companyId, -1));
    }
  }, [company]);

  useEffect(() => {
    if (selectedUserId) setSelectedRow(selectedUserId);
  }, [selectedUserId]);

  const handleAddNewGroup = () => {
    dialogCustom(GroupDialog, {
      name: 'New Group',
      onSave: async (groupName, guid) => {
        await createGroupAsync(companyId, { name: groupName, guid: guid });
      }
    });
  };

  const handleAddNewUser = () => {
    dialogCustom(NewUserDialog, {
      companyId,
      company,
      history
    });
  };

  const handleEdit = (userId) => {
    dispatch({
      type: SELECT_USER,
      payload: { userId }
    });
    const foundUser = filteredList.find((user) => user.elementId === userId);
    const isUser = typeof foundUser.properties.username !== 'undefined';
    if (isUser) {
      history.push(`${API_ROOT_WAR}/settings/usereditor/${companyId}/${userId}`);
    } else {
      async function saveGroup(name, guid) {
       // foundUser.displayName = newName;
       // foundUser.name = foundUser.displayName;
        await storeGroupAsync(companyId, userId, {name, guid});
        dispatch(loadUserList(companyId, -1));
      }

      dialogCustom(GroupDialog, {
        name: foundUser.properties.fullname,
        guid: foundUser.properties.GUID,
        onSave: saveGroup
      });
    }
  };

  const handleDelete = (userId) => {
    dialogCustom(DeleteConfirmDialog, {
      onDelete: () => {
        dispatch(removeUser(userId, companyId));
      }
    });
  };
  if (loadingUsers || loading || !filteredList) {
    return <Loader />;
  }

  const rows = createRows(
    filteredList.sort((a, b) => {
      if (!a.properties.fullName) return 1;
      if (!b.properties.fullName) return -1;
      if (a.properties.fullname.toLowerCase() < b.properties.fullname.toLowerCase()) {
        return -1;
      }
      if (a.properties.fullname.toLowerCase() > b.properties.fullname.toLowerCase()) {
        return 1;
      }
      return 0;
    }),
    {
      edit: handleEdit,
      delete: handleDelete
    }
  );
  return (
    <div
      style={{
        height: '90%',
        width: '100%'
      }}
    >
      <SearchableTable rows={rows} columns={groups ? groupColumns : userColumns} initialSelectedRow={selectedRow} initialPagination={25} />
      <div style={styles.actionsContainer}>
        {!groups
          ? <Button disabled={!company} color="primary" variant="outlined" onClick={handleAddNewUser}>
              <FormattedMessage id="settings.btn.new" defaultMessage="New User" />
            </Button>
          : <Button disabled={!company} color="primary" variant="outlined" onClick={handleAddNewGroup}>
              <FormattedMessage id="settings.btn.newgroup" defaultMessage="New group" />
            </Button>}
      </div>
    </div>
  );
}

function GroupDialog({ onSave, name: initialName, guid: initialGuid, handleClose }) {
  const [name, setName] = useState(initialName);
  const [guid, setGuid] = useState(initialGuid);

  const handleSave = () => {
    onSave(name, guid);
    handleClose();
  };
  return (
    <Dialog open onClose={handleClose} fullWidth>
      <DialogTitle>
        <FormattedMessage id="UserList.Dialog.Title" defaultMessage="Group name" />
      </DialogTitle>
      <DialogContent>
        <div style={styles.container}>
          <TextField
            autoFocus
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
            label={<FormattedMessage id="UserList.Dialog.TextFieldLabel" defaultMessage="Name" />}
          />
          <TextField
              autoFocus
              fullWidth
              value={guid}
              onChange={(event) => setGuid(event.target.value)}
              label={<FormattedMessage id="UserList.Dialog.GUIDFieldLabel" defaultMessage="Azure Group id" />}
          />
        </div>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="UserList.Dialog.Cancel" defaultMessage="Cancel" />
        </Button>
        <Button onClick={handleSave} color="primary" variant="outlined">
          <FormattedMessage id="UserList.Dialog.Save" defaultMessage="Save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
