const settingsData = {
  Text: {
    "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text",
  },
  Hidden: {
    "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Hidden",
  },
  Number: {
    "class": "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Number",
  },
}

export default settingsData