import { handleQuery } from '@zert-packages/actions/api';
import { FormattedMessage } from 'react-intl';
import React from 'react';

const startQuery = '/reviewjob';

export const CommentStatus = {
  Proposed: {
    id: 'Proposed',
    name: <FormattedMessage id="ReviewJobPlugin.CommentStatus.Proposed" defaultMessage="Proposed" />
  },
  Applied: {
    id: 'Applied',
    name: <FormattedMessage id="ReviewJobPlugin.CommentStatus.Applied" defaultMessage="Applied" />
  },
  Dismissed: {
    id: 'Dismissed',
    name: <FormattedMessage id="ReviewJobPlugin.CommentStatus.Dismissed" defaultMessage="Dismissed" />
  }
};
export const CommentType = {
  Remove: { id: 'Remove', name: <FormattedMessage id="ReviewJobPlugin.CommentType.Remove" defaultMessage="Remove" /> },
  ReplaceWith: {
    id: 'ReplaceWith',
    name: <FormattedMessage id="ReviewJobPlugin.CommentType.ReplaceWith" defaultMessage="ReplaceWith" />
  },
  InsertContent: {
    id: 'InsertContent',
    name: <FormattedMessage id="ReviewJobPlugin.CommentType.InsertContent" defaultMessage="InsertContent" />
  },
  Comment: {
    id: 'Comment',
    name: <FormattedMessage id="ReviewJobPlugin.CommentType.Comment" defaultMessage="Comment" />
  }
};

export const ReviewStatus = {
  NOT_ACTIVATED: {
    id: 'NOT_ACTIVATED',
    name: <FormattedMessage id="ReviewJobPlugin.CommentType.NOT_ACTIVATED" defaultMessage="NOT ACTIVATED" />
  },
  ACTIVE: { id: 'ACTIVE', name: <FormattedMessage id="ReviewJobPlugin.CommentType.ACTIVE" defaultMessage="ACTIVE" /> },
  COMPLETED: {
    id: 'COMPLETED',
    name: <FormattedMessage id="ReviewJobPlugin.CommentType.COMPLETED" defaultMessage="COMPLETED" />
  },
  CANCELED: {
    id: 'CANCELED',
    name: <FormattedMessage id="ReviewJobPlugin.CommentType.CANCELED" defaultMessage="CANCELED" />
  },
  NO_CONTENT: {
    id: 'NO_CONTENT',
    name: <FormattedMessage id="ReviewJobPlugin.CommentType.NO_CONTENT" defaultMessage="NO CONTENT" />
  }
};

export const MemberStatus = {
  WORKING: {
    id: 'WORKING',
    name: <FormattedMessage id="ReviewJobPlugin.MemberStatus.WORKING" defaultMessage="NOT WORKING" />
  },
  DONE: { id: 'DONE', name: <FormattedMessage id="ReviewJobPlugin.MemberStatus.DONE" defaultMessage="DONE" /> }
};

export const getReviewStatusById = (id) => {
  return ReviewStatus[id].name;
};

export const getMemberStatusById = (id) => {
  return MemberStatus[id].name;
};

export const getCommentStatusById = (id) => {
  return CommentStatus[id].name;
};

export const getCommentTypeById = (id) => {
  return CommentType[id].name;
};

export function loadReviewJobAPI(versionId) {
  return handleQuery(`${startQuery}/getById/${versionId}`);
}

export function loadReviewCommentsAPI(versionId) {
  return handleQuery(`${startQuery}/getComments/${versionId}`);
}
