import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TextFieldWithLanguageSwitch from '@zert-packages/components/common/TextFieldWithLangaugeSwitch';

function TextChanger({ data, setData }) {
  const [translations, setTranslations] = useState(data.labelObject);

  useEffect(() => {
    if (translations.locales && translations.locales.length > 0) {
      setData((prevState) => ({
        ...prevState,
        label: translations.translations[0],
        labelObject: translations
      }));
    }
  }, [translations]);

  const labelObjectChangeHandler = (locales) => {
    setTranslations(locales);
  };

  return (
    <div>
      <TextFieldWithLanguageSwitch
        disabled={false}
        onChange={(event) => {
          labelObjectChangeHandler(event);
        }}
        state={{
          defaultLabel: translations.defaultLabel,
          locales: translations.locales
            ? translations.locales.map((el) => {
                return el;
              })
            : ['en', 'sv'],
          translations: translations.translations
            ? translations.translations.map((el) => {
                return el;
              })
            : ['', '']
        }}
        label={<FormattedMessage id="Administration.DemandEditor.Label" defaultMessage="Label" />}
      />
    </div>
  );
}

export default TextChanger;
