import React, { useContext } from 'react';

import { Select, FormControl, InputLabel, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CountersSearchContainer from './CountersSearchContainer';

import TextFieldWithLanguageSwitch from '@zert-packages/components/common/TextFieldWithLangaugeSwitch';

import { CatalogContext } from '../common/CatalogProvider';

import getCriteriaDefaultState from '@zert-packages/utils/getCriteriaDefaultState';

import './TreeManagerCounters.sass';
import { FormattedMessage } from 'react-intl';

function CounterEditPanel({ foundCounter, selectedCounterIndex }) {
  const { debounceHandleRenderTree, globalTree, handleRenderTree, isEverythingDisabled } = useContext(CatalogContext);

  const updateCounter = (field, value) => {
    globalTree.counters = globalTree.counters.map((counter, index) =>
      index === selectedCounterIndex
        ? {
            ...counter,
            [field]: value
          }
        : counter
    );
    handleRenderTree();
  };

  const handleOnChangeCounterType = (event) => {
    const defaultLabel = {
      defaultLabel: '',
      locales: ['en', 'sv'],
      translations: ['', '']
    };
    const { value } = event.target;
    const isCompare = value === 'COMPARE';
    const labelCompareLeft = isCompare ? { ...defaultLabel } : null;
    const labelCompareRight = isCompare ? { ...defaultLabel } : null;
    const searchCompareWidth = isCompare ? { criterias: [] } : null;
    const globalTreeFoundCounter = globalTree.counters[selectedCounterIndex];
    globalTreeFoundCounter.clientCounterType = value;
    globalTreeFoundCounter.labelCompareLeft = labelCompareLeft;
    globalTreeFoundCounter.labelCompareRight = labelCompareRight;
    globalTreeFoundCounter.searchCompareWidth = searchCompareWidth;

    handleRenderTree();
  };
  const onChangeExpansionOfCriteria = (isExpanded, className, field) => {
    if (isEverythingDisabled) return;
    if (isExpanded) {
      addCriteria(className, field);
    } else {
      removeCriteria(className, field);
    }
  };

  const removeCriteria = (className, field) => {
    foundCounter[field].criterias = foundCounter[field].criterias.filter(
      (criteria) => criteria.className !== className
    );
    updateCounter(field, foundCounter[field]);
  };

  const addCriteria = (className, field) => {
    foundCounter[field].criterias.push(getCriteriaDefaultState(className));
    updateCounter(field, foundCounter[field]);
  };

  const getCriteria = (className, field) => {
    return foundCounter[field].criterias.find((criteria) => criteria.className === className);
  };

  const updateCriteria = (className, values, field) => {
    if (isEverythingDisabled) return;
    const newCriterias = foundCounter[field].criterias.map((criteria) =>
      criteria.className === className
        ? {
            ...criteria,
            values
          }
        : criteria
    );
    foundCounter[field].criterias = newCriterias;
    updateCounter(field, foundCounter[field]);
  };

  if (foundCounter === null) return null;
  return (
    <div className="counter-edit__container">
      <div className="counter-edit__static-fields">
        <TextFieldWithLanguageSwitch
          disabled={isEverythingDisabled}
          state={foundCounter.label}
          onChange={(value) => updateCounter('label', value)}
          label={<FormattedMessage id="structure.counter.label" defaultMessage="label" />}
        />
        <FormControl variant="standard" disabled={isEverythingDisabled}>
          <InputLabel shrink>
            <FormattedMessage id="structure.counter.type" defaultMessage="Counter type" />
          </InputLabel>
          <Select variant="standard" native value={foundCounter.clientCounterType} onChange={handleOnChangeCounterType}>
            <FormattedMessage
              id="structure.counter.count"
              defaultMessage="COUNT"
              children={(formattedMessage) => <option value="COUNT">{formattedMessage}</option>}
            />
            <FormattedMessage
              id="structure.counter.compare"
              defaultMessage="COMPARE"
              children={(formattedMessage) => <option value="COMPARE">{formattedMessage}</option>}
            />
            <FormattedMessage
              id="structure.counter.overview"
              defaultMessage="OVERVIEW"
              children={(formattedMessage) => <option value="OVERVIEW">{formattedMessage}</option>}
            />
          </Select>
        </FormControl>
      </div>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {' '}
          <FormattedMessage id="structure.counter.search" defaultMessage="Search" />{' '}
        </AccordionSummary>
        <AccordionDetails>
          <div className="expansion-panel-container">
            {foundCounter.clientCounterType === 'COMPARE' && (
              <TextFieldWithLanguageSwitch
                disabled={isEverythingDisabled}
                fullWidth
                state={foundCounter.labelCompareLeft}
                onChange={(newState) => updateCounter('labelCompareLeft', newState)}
                label="Label"
              />
            )}
            <CountersSearchContainer
              isEverythingDisabled={isEverythingDisabled}
              getCriteria={(className) => getCriteria(className, 'search')}
              updateCriteria={(className, values) => updateCriteria(className, values, 'search')}
              onChangeExpansionOfCriteria={(isExpanded, className) =>
                onChangeExpansionOfCriteria(isExpanded, className, 'search')
              }
            />
          </div>
        </AccordionDetails>
      </Accordion>
      {foundCounter.clientCounterType === 'COMPARE' && (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <FormattedMessage id="structure.counter.searchCompareWidth" defaultMessage="Search Compare Width" />
          </AccordionSummary>
          <AccordionDetails>
            <div className="expansion-panel-container">
              <TextFieldWithLanguageSwitch
                disabled={isEverythingDisabled}
                state={foundCounter.labelCompareRight}
                onChange={(value) => updateCounter('labelCompareRight', value)}
                label="Label"
              />
              <CountersSearchContainer
                isEverythingDisabled={isEverythingDisabled}
                getCriteria={(className) => getCriteria(className, 'searchCompareWidth')}
                updateCriteria={(className, values) => updateCriteria(className, values, 'searchCompareWidth')}
                onChangeExpansionOfCriteria={(isExpanded, className) =>
                  onChangeExpansionOfCriteria(isExpanded, className, 'searchCompareWidth')
                }
              />
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}

export default CounterEditPanel;
