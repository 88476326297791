export const normalData = {
  class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientNormalPartSettingsTemplate',
  identifier: 'group',
  label: 'Group',
  heading: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientPartHeading',
    visible: true,
    fontFamily: 'zert-category-heading',
    fontSize: {
      value: 18,
      unit: 'POINTS'
    },
    fontStyle: ['BOLD'],
    color: null,
    spaceAfter: {
      name: 'after',
      conditionality: 'DISCARD',
      value: {
        value: 10,
        unit: 'POINTS'
      },
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    breakBefore: 'AUTO',
    breakAfter: 'AUTO',
    contentType: 'FORMAT_SETTING',
    content: 'name'
  },
  spaceBefore: {
    name: 'before',
    conditionality: 'DISCARD',
    value: {
      value: 0,
      unit: 'POINTS'
    },
    minimum: null,
    maximum: null,
    precedence: 'DEFAULT'
  },
  spaceAfter: {
    name: 'after',
    conditionality: 'DISCARD',
    value: {
      value: 10,
      unit: 'POINTS'
    },
    minimum: null,
    maximum: null,
    precedence: 'DEFAULT'
  },
  breakBefore: 'PAGE',
  breakAfter: 'AUTO',
  maxImageWidth: null,
  spaceAfterImage: {
    name: 'after-image',
    conditionality: 'DISCARD',
    value: {
      value: 10,
      unit: 'POINTS'
    },
    minimum: {
      value: 10,
      unit: 'POINTS'
    },
    maximum: {
      value: 10,
      unit: 'POINTS'
    },
    precedence: 'DEFAULT'
  },
  columns: [
    {
      width: {
        value: 15,
        unit: 'MILLIMETER'
      },
      titleAlign: 'INHERIT',
      title: {
        name: 'title',
        type: 'INCLUDE',
        text: {
          defaultLabel: null,
          locales: [],
          translations: []
        },
        untranslatableText: '',
        include: 'position'
      },
      contentAlign: 'INHERIT',
      contentType: 'POSITION',
      content: '',
      separated: false,
      languageSeparated: false,
      startFromLeft: false,
      spaceTop: null,
      fontSize: null
    },
    {
      width: {
        value: 30,
        unit: 'MILLIMETER'
      },
      titleAlign: 'INHERIT',
      title: {
        name: 'title',
        type: 'INCLUDE',
        text: {
          defaultLabel: null,
          locales: [],
          translations: []
        },
        untranslatableText: '',
        include: 'part-number'
      },
      contentAlign: 'INHERIT',
      contentType: 'PART_NUMBER',
      content: '',
      separated: false,
      languageSeparated: false,
      startFromLeft: false,
      spaceTop: null,
      fontSize: null
    },
    {
      width: null,
      titleAlign: 'INHERIT',
      title: {
        name: 'title',
        type: 'INCLUDE',
        text: {
          defaultLabel: null,
          locales: [],
          translations: []
        },
        untranslatableText: '',
        include: 'part-name'
      },
      contentAlign: 'INHERIT',
      contentType: 'FORMAT_SETTING',
      content: 'name',
      separated: false,
      languageSeparated: true,
      startFromLeft: false,
      spaceTop: null,
      fontSize: null
    },
    {
      width: {
        value: 15,
        unit: 'MILLIMETER'
      },
      titleAlign: 'INHERIT',
      title: {
        name: 'title',
        type: 'INCLUDE',
        text: {
          defaultLabel: null,
          locales: [],
          translations: []
        },
        untranslatableText: '',
        include: 'pieces'
      },
      contentAlign: 'INHERIT',
      contentType: 'QUANTITY',
      content: '',
      separated: false,
      languageSeparated: false,
      startFromLeft: false,
      spaceTop: null,
      fontSize: null
    },
    {
      width: {
        value: 15,
        unit: 'MILLIMETER'
      },
      titleAlign: 'INHERIT',
      title: {
        name: 'title',
        type: 'INCLUDE',
        text: {
          defaultLabel: null,
          locales: [],
          translations: []
        },
        untranslatableText: '',
        include: 'recomended'
      },
      contentAlign: 'INHERIT',
      contentType: 'ITEM_SETTING',
      content: 'recomended-spare-parts',
      separated: false,
      languageSeparated: false,
      startFromLeft: false,
      spaceTop: null,
      fontSize: null
    },
    {
      width: {
        value: 15,
        unit: 'MILLIMETER'
      },
      titleAlign: 'INHERIT',
      title: {
        name: 'title',
        type: 'INCLUDE',
        text: {
          defaultLabel: null,
          locales: [],
          translations: []
        },
        untranslatableText: '',
        include: 'wear-and-tear'
      },
      contentAlign: 'INHERIT',
      contentType: 'ITEM_SETTING',
      content: 'wear-and-tear',
      separated: false,
      languageSeparated: false,
      startFromLeft: false,
      spaceTop: null,
      fontSize: null
    }
  ],
  evenSameAsOdd: false,
  insertEmptyEven: false,
  headerRow: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientNormalTableRowTemplate',
    fontFamily: 'default',
    fontSize: null,
    fontStyle: ['BOLD'],
    color: null,
    backgroundColor: null,
    horizontalAlign: 'LEFT',
    verticalAlign: 'TOP',
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    },
    spaceBefore: {
      name: 'before',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    spaceAfter: {
      name: 'after',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    borderTop: {
      edge: 'top',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderBottom: {
      edge: 'bottom',
      width: {
        value: 1,
        unit: 'POINTS'
      },
      style: 'SOLID',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderLeft: {
      edge: 'left',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderRight: {
      edge: 'right',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderCenter: {
      edge: 'center',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderMiddle: {
      edge: 'middle',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    paddingTop: {
      value: 0,
      unit: 'POINTS'
    },
    paddingBottom: {
      value: 0,
      unit: 'POINTS'
    },
    paddingLeft: {
      value: 0,
      unit: 'POINTS'
    },
    paddingRight: {
      value: 0,
      unit: 'POINTS'
    }
  },
  oddRow: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientNormalTableRowTemplate',
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: {
      id: -1,
      metadataList: null,
      references: {},
      properties: {},
      parents: [],
      approvedAt: null,
      archivedAt: null,
      approvedBy: null,
      archivedBy: null,
      changedBy: null,
      changedAt: null,
      createdBy: null,
      createdAt: null,
      name: null,
      translatable: false,
      checkedOut: false,
      readOnly: false,
      checkoutByUser: null,
      published: false,
      locale: null,
      label: null,
      mimeType: null,
      versionId: -1,
      additionalInfo: null,
      companyId: 0,
      versionUUID: null,
      elementUUID: null,
      currentVersionId: -1,
      cmyk: true,
      red: 209,
      green: 209,
      blue: 209,
      alpha: 255
    },
    horizontalAlign: 'LEFT',
    verticalAlign: 'TOP',
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    },
    spaceBefore: {
      name: 'before',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    spaceAfter: {
      name: 'after',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    borderTop: {
      edge: 'top',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderBottom: {
      edge: 'bottom',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderLeft: {
      edge: 'left',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderRight: {
      edge: 'right',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderCenter: {
      edge: 'center',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderMiddle: {
      edge: 'middle',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    paddingTop: {
      value: 1,
      unit: 'POINTS'
    },
    paddingBottom: {
      value: 0,
      unit: 'POINTS'
    },
    paddingLeft: {
      value: 0,
      unit: 'POINTS'
    },
    paddingRight: {
      value: 0,
      unit: 'POINTS'
    }
  },
  evenRow: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientNormalTableRowTemplate',
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    horizontalAlign: 'LEFT',
    verticalAlign: 'TOP',
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    },
    spaceBefore: {
      name: 'before',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    spaceAfter: {
      name: 'after',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    borderTop: {
      edge: 'top',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderBottom: {
      edge: 'bottom',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderLeft: {
      edge: 'left',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderRight: {
      edge: 'right',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderCenter: {
      edge: 'center',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderMiddle: {
      edge: 'middle',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    paddingTop: {
      value: 1,
      unit: 'POINTS'
    },
    paddingBottom: {
      value: 0,
      unit: 'POINTS'
    },
    paddingLeft: {
      value: 0,
      unit: 'POINTS'
    },
    paddingRight: {
      value: 0,
      unit: 'POINTS'
    }
  },
  separatedRow: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientNormalTableRowTemplate',
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    horizontalAlign: 'LEFT',
    verticalAlign: 'TOP',
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    },
    spaceBefore: {
      name: 'before',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    spaceAfter: {
      name: 'after',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    borderTop: {
      edge: 'top',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderBottom: {
      edge: 'bottom',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderLeft: {
      edge: 'left',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderRight: {
      edge: 'right',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderCenter: {
      edge: 'center',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderMiddle: {
      edge: 'middle',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    paddingTop: {
      value: 0,
      unit: 'POINTS'
    },
    paddingBottom: {
      value: 0,
      unit: 'POINTS'
    },
    paddingLeft: {
      value: 0,
      unit: 'POINTS'
    },
    paddingRight: {
      value: 0,
      unit: 'POINTS'
    }
  },
  emptyRowHeight: {
    value: 0,
    unit: 'POINTS'
  }
};
