import React, { useEffect, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import ZertLoaderCenter from '@zert-packages/components/shared/ZertLoaderCenter';
import getTranslation from '@zert-packages/utils/getTranslation.new';
import { renderInExceptionRoot } from '@zert-packages/utils';
import LongSelectList from '../../../RMPPlugin/components/LongSelectList';
import { WithDemandsTabs } from '../../../RMPPlugin/components/DemandsComponents';
import {
  clearDemandTemplate,
  createDemand,
  editDemand,
  loadDemands,
  loadDemandTemplate
} from '../../../RMPPlugin/APIs';
import DemandEditorDialog from './DemandDialog';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignContent: 'flex-start',
    gridGap: 15,
    overflow: 'hidden'
  },
  demandsContainer: {
    display: 'grid',
    gridTemplateRows: '48px 48px 400px'
  }
}));
const useSetState = (initialState = {}) => {
  const [state, regularSetState] = useState(initialState);

  const setState = (key, newState) => {
    regularSetState((prevState) => ({
      ...prevState,
      [key]: newState
    }));
  };

  return [state, setState];
};

export const filterActive = (template, typeInTemplate, activeOnly) => {
  return template.demands.filter((demand) => {
    return (
      demand.class.indexOf(typeInTemplate) >= 0 &&
      (!activeOnly ||
        (template.demandsMap.hasOwnProperty(demand.elementId) && template.demandsMap[demand.elementId] === 'ACTIVE'))
    );
  });
};

function TemplatesSelector({ template, type, typeInTemplate, onConfirm, setActive }) {
  const [documents, setDocuments] = useState(null);
  useEffect(() => {
    //  debugger;
    loadDemands(type)
      .then((result) => {
        setDocuments(
          result
            .reduce((acu, cur) => {
              //  debugger;
              return acu.find((demand) => demand.info.id == cur.info.id) ? acu : [...acu, cur];
            }, filterActive(template, typeInTemplate))
            .filter((demand) => demand.number != null)
            .sort((demand0, demand1) => {
              if (demand0.number != null && demand1.number != null) {
                const numberText = getTranslation(demand0.number);
                const numberText2 = getTranslation(demand1.number);
                //    debugger;
                return numberText.localeCompare(numberText2);
              }
              return demand0.name.localeCompare(demand1.name);
            })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (template === null || documents === null) return <ZertLoaderCenter />;

  return (
    <LongSelectList
      disabled={false}
      setActiveItems={() => {}}
      template={template}
      showReplaceText
      availableItems={documents}
      onConfirm={onConfirm}
      activeItems={filterActive(template, typeInTemplate, true)}
    />
  );
}

const getClassByTabId = (tabIndex) => {
  if (tabIndex === 0) return 'ClientDirective';

  if (tabIndex === 1) return 'ClientProvision';

  if (tabIndex === 2) return 'ClientStandart';
  if (tabIndex === 3) return 'ClientOther';
};

function DemandTemplateManager({}) {
  const [template, setTemplate] = useState(null);

  const cl = useStyles();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    clearDemandTemplate().then(() =>
      loadDemandTemplate().then((template) => {
        setTemplate(template);
      })
    );
  }, []);

  if (template === null) return <ZertLoaderCenter />;
  const onConfirm = async (demand) => {
    setTemplate(null);
    delete demand.info;
    await editDemand(demand);
    await clearDemandTemplate();
    const template = await loadDemandTemplate();
    setTemplate(template);
  };

  return (
    <div className={cl.rootContainer}>
      <WithDemandsTabs
        tabIndex={tabIndex}
        addButtonAction={() => {
          renderInExceptionRoot(DemandEditorDialog, {
            classType: getClassByTabId(tabIndex),
            template,
            status: 'PENDING',
            onConfirm: async (demand) => {
              setTemplate(null);
              await createDemand(demand);
              await clearDemandTemplate();
              const template = await loadDemandTemplate();
              setTemplate(template);
            }
          });
        }}
        setTabIndex={setTabIndex}
        yourTabs={[]}
      />

      {tabIndex === 0 && (
        <TemplatesSelector
          template={template}
          type="Directive"
          onConfirm={onConfirm}
          typeInTemplate="ClientDirective"
        />
      )}
      {tabIndex === 1 && (
        <TemplatesSelector
          template={template}
          type="Provision"
          onConfirm={onConfirm}
          typeInTemplate="ClientProvision"
        />
      )}
      {tabIndex === 2 && (
        <TemplatesSelector template={template} type="Standard" onConfirm={onConfirm} typeInTemplate="ClientStandart" />
      )}

      {tabIndex === 3 && (
        <TemplatesSelector template={template} type="Other" onConfirm={onConfirm} typeInTemplate="ClientOther" />
      )}
    </div>
  );
}

export default DemandTemplateManager;
