export const containsMyMeasure = (myUser, riskRow) => {
  if (myUser === null) {
    return false;
  }

  if (myUser.role === 'task-administrator' || myUser.role == 'administrator') {
    return true;
  }

  return (
    riskRow.measures.find((measure) => {
      return (
        (measure.currentTaskUser && measure.currentTaskUser == myUser.userName) ||
        (measure.responsible && measure.responsible.userName == myUser.userName) ||
        (measure.responsibleText && measure.responsibleText == myUser.fullname)
      );
    }) != null
  );
};
