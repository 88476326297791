import React, { useContext, useState } from "react";
import { IconButton } from "@mui/material";
import { RichUtils } from "draft-js";
import { makeStyles } from "@mui/styles";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import {useDispatch, useSelector} from "react-redux";
import {handleBlock} from "@zert-packages/actions/commonReducer";
import DropMenuStyle from "clm/src/plugins/PhrasePlugin/components/header/DropMenuStyle";

const useStyles = makeStyles((theme) => ({
    buttonRoot: (props) => ({
        color: props.color,
        // background: props.background,
        padding: 5,
        "& svg": {
            fontSize: "1.25rem"
        },
        "&:hover": {
            color: "#ffffff !important",
            background: "#01A1C7 !important"
        }
    })
}));

const TextFormats = ({ type = "" }) => {
    const {  block } = useSelector((state) => state);
    const dispatch = useDispatch();
    const currentStyle = block?.editorState?.getCurrentInlineStyle();
    const selected = currentStyle.has(type);
    const cl = useStyles({
        color: selected ? "#ffffff !important" : "#555555 !important",
        background: selected ? "#01A1C7 !important" : "none !important"
    });


    const handleStyle = () => {
        dispatch(handleBlock( type, 'phraseEditorActionType'))
    }


    return (
        <div>
            <IconButton
                className={cl.buttonRoot}
                onClick={(e) => handleStyle(e)}
                onMouseDown={(e) => e.preventDefault()}
            >
                {type === "BOLD" && <FormatBoldIcon />}
                {type === "ITALIC" && <FormatItalicIcon />}
            </IconButton>

        </div>

    );
}

export default TextFormats