import React, { useEffect, useState, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { PanelContext } from './InfoElementWrapper/InfoElementWrapper';

const useStyles = makeStyles((theme) => ({
  rootExpanderElementProperties: {
    flexGrow: 1,
    '&.MuiCollapse-root': {
      position: 'absolute',
      width: '100%'
    }
  },
  wrapperText: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 1fr',
    fontSize: 10,
    gap: 1,
    '& p': {
      marginBottom: 1
    }
  },
  textTitle: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  textDate: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: '500'
  }
}));

const defaultState = {
  name: '',
  elementId: -1,
  versionId: -1,
  created: {
    value: '',
    name: ''
  },
  lastChange: {
    value: '',
    name: ''
  },
  approved: {
    value: '',
    name: ''
  }
};

function ElementPropertiesPane({ context }) {
  const { values, setValues } = useContext(context || PanelContext);
  const { versionInfo } = useSelector((state) => state);
  const [properties, setProperties] = useState(defaultState);
  const cl = useStyles();

  useEffect(() => {
    if (versionInfo?.info) {
      const info = versionInfo?.info;
      if(!info){
        return
      }
      setProperties((pre) => ({
        ...pre,
        name: info.name,
        id: info.id,
        versionId: info.currentVersionId,
        created: {
          value: info.createdAt,
          name: info.createdBy
        },
        lastChange: {
          value: info.changedAt,
          name: info.changedBy
        },
        approved: {
          value: info.approvedAt,
          name: info.approvedBy
        }
      }));
    }
  }, [versionInfo?.info]);

  useEffect(() => {
    if (values.treeItem) {
      if (values.treeItem.type === 'default') {
        return setProperties(defaultState);
      }
      const info = context ? values.treeItem : values.treeItem.elementInfo;
      if(!info){
        return
      }
      setProperties((pre) => ({
        ...pre,
        name: info.name,
        id: info.id,
        versionId: info.currentVersionId,
        created: {
          value: info.createdAt,
          name: info.createdBy
        },
        lastChange: {
          value: info.changedAt,
          name: info.changedBy
        },
        approved: {
          value: info.approvedAt,
          name: info.approvedBy
        }
      }));
    }
  }, [values.treeItem]);

  return (
    <>
      <div className={cl.wrapperText}>
        <p className={cl.textTitle}>
          <FormattedMessage id="Name" defaultMessage="Name" />:
        </p>
        <p>{properties.name}</p>
      </div>
      <div className={cl.wrapperText}>
        <p className={cl.textTitle}>
          <FormattedMessage id="ElementId" defaultMessage="Element id" />:
        </p>
        <p>{properties.id}</p>
      </div>
      <div className={cl.wrapperText}>
        <p className={cl.textTitle}>
          <FormattedMessage id="VersionId" defaultMessage="Version id" />:
        </p>
        <p>{properties.versionId}</p>
      </div>
      <div className={cl.wrapperText}>
        <p className={cl.textTitle}>
          <FormattedMessage id="Created" defaultMessage="Created" />:
        </p>
        <p>{properties.created.value ? properties.created.value : '-'}</p>
        <span className={cl.textDate} />
        <p>{properties.created.name ? properties.created.name : '-'}</p>
      </div>
      <div className={cl.wrapperText}>
        <p className={cl.textTitle}>
          <FormattedMessage id="LastChange" defaultMessage="Last change" />:
        </p>
        <p>{properties.lastChange.value ? properties.lastChange.value : '-'}</p>
        <span className={cl.textDate} />
        <p>{properties.lastChange.name ? properties.lastChange.name : '-'}</p>
      </div>
      <div className={cl.wrapperText}>
        <p className={cl.textTitle}>
          <FormattedMessage id="Approved" defaultMessage="Approved" />:
        </p>
        <p>{properties.approved.value ? properties.approved.value : '-'}</p>
        <span className={cl.textDate} />
        <p>{properties.approved.name ? properties.approved.name : '-'}</p>
      </div>
    </>
  );
}

export default ElementPropertiesPane;
