export const dataCanvas = () => {
  const defaultData = {
    defaultX: 0,
    defaultY: 0,
    defaultWidth: 100,
    defaultHeight: 100,
  }
  //
  return [
    {
      x: 0,
      y: 0,
      width: 100,
      height: 100,
      type: "group",
      label: "[1.1]",
      text: "",
      editor:true,
      cornerRadius: 0,
      strokeWidth: 1,
      // stroke:"black",
      stroke: 1,
      dash: [],
      ...defaultData,
    },
    {
      x: 0,
      y: 0,
      width: 100,
      height: 100,
      type: "Ellips",
      text: "",
      cornerRadius: 50,
      strokeWidth: 1,
      stroke: 1,
      dash: [],

      ...defaultData,
    },
    {
      x: 0,
      y: 0,
      width: 100,
      height: 100,
      type: "Rektangel",
      text: "",
      cornerRadius: 0,
      strokeWidth: 1,
      stroke: 1,
      dash: [],
      ...defaultData,
    },
    {
      type: "Arrow",
      x: 15,
      y: 15,
      scaleX: 1,
      scaleY: 1,
      editor:true,
      ...defaultData,
      defaultX: 15,
      defaultY: 15,
    }
  ];
};