import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TextField from '@mui/material/TextField';
import TextChanger from '../dialogs/TextChanger';

export const formStyle = (theme) =>
  createStyles({
    divRoot: {
      display: 'flex'
      /*  height:'25rem',
          border:'1px solid lightgrey',
          padding:4 */
    },
    divButtons: {
      width: '5%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    divContentContainer: {
      border: '1px solid lightgrey',
      width: '95%',
      height: '20rem'
    },
    divContentContainerNoBorder: {
      width: '95%',
      height: '100%'
    },
    listStyle: {
      '&.MuiTypography-body1': {
        fontSize: '1em'
      }
    }
  });
const useStyles = makeStyles(formStyle);

const initState = {
  languageDialog: false
};

function NumberView({ data, setData }) {
  const classes = useStyles();
  const [details, setDetails] = useState(data);
  const [numberState, setNumberState] = useState(initState);

  const openCloseLanguageDialog = () => {
    setNumberState((prevState) => ({
      ...prevState,
      languageDialog: !prevState.languageDialog
    }));
  };

  const onTextFieldChange = (event) => {
    event.persist();
    setDetails((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  useEffect(() => {
    setData(details);
  }, [details]);

  useEffect(() => {
    setDetails(data);
  }, [data]);

  return (
    <div>
      <TextChanger data={details} setData={setDetails} />
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        label="Min:"
        type="number"
        name="min"
        value={details.min}
        onChange={onTextFieldChange}
      />
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        label="Max:"
        type="number"
        name="max"
        value={details.max}
        onChange={onTextFieldChange}
      />
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        label="Value:"
        type="number"
        name="value"
        value={details.value}
        onChange={onTextFieldChange}
      />
    </div>
  );
}
export default NumberView;
