export const allowUpload = (translationJobTemplate, translationJob) => {
  return (
    translationJobTemplate &&
    translationJobTemplate.translationActivities.find((value) => value == translationJob.currentActivity.id)
  );
};

export const allowImport = (translationJobTemplate, translationJob) => {
  return (
    translationJobTemplate &&
    translationJobTemplate.importActivities.find((value) => value == translationJob.currentActivity.id)
  );
};
