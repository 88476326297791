import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { showSnackbarLoaderWithMessage } from '@zert-packages/components/Snackbars';
import { FormattedMessage } from 'react-intl';
import { Dialog } from '@mui/material';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: 'calc(var(--vh, 1vh) * 100)',
    position: 'fixed',
    zIndex: '9000',
    opacity: '1'
  },

  loaderContainer: {
    position: 'fixed',
    right: '0',
    top: '0'
  }
});

function HoverWithSnackbarLoaderDialog({ snackbar: parentSnackbar }) {
  const cl = useStyles();
  const snackbar = useSnackbar();
  useEffect(() => {
    const snackbarId = showSnackbarLoaderWithMessage(parentSnackbar || snackbar);
    return () => {
      (parentSnackbar || snackbar).closeSnackbar(snackbarId);
    };
  });
  return (
    <Dialog open hideBackdrop>
      <div className={cl.root} />
    </Dialog>
  );
}

export default HoverWithSnackbarLoaderDialog;
