import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { FormattedMessage } from 'react-intl';
import Chip from '@mui/material/Chip';
import { connect } from 'react-redux';
import getAllPlugins from '@zert-packages/utils/getAllPlugins';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import SearchComponent from './SearchComponent';
import withSearch, { styles, controlStyles } from '../withSearch';

function getStyles(name, that) {
  return {
    fontWeight:
      !that.state.MimeType || that.state.MimeType.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
 /* getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }*/
};

class BrokenReferenceSearch extends SearchComponent {
  constructor(props) {
    super(props);
    /*   if (!this.state || !this.state.MimeType) {
             this.state = { MimeType: [] };
           } */
    this.handleChange = this.handleChange.bind(this);
    this.textInput = React.createRef();
  }

  handleChange = (event) => {
    this.setStatus({ MimeType: event.target.value });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    this.props.expanded && this.textInput.current ? this.textInput.current.blur() : this.textInput.current.focus();
  }

  render() {
    //   this.props.handleChange(this.state);
    const { classes } = this.props;
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel htmlFor="select-multiple-chip">
            <FormattedMessage id="search.referencesTo" defaultMessage="Element type" />
          </InputLabel>
          <Select
            autoFocus
            inputRef={this.textInput}
            multiple
            size="small"
            value={this.state.MimeType ? this.state.MimeType : []}
            onChange={this.handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={getPluginByMimeType(value).name} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {getAllPlugins().map((plugin) => (
              <MenuItem
                key={plugin.index}
                value={`application/zert-${plugin.mimetype}`}
                style={getStyles(`application/zert-${plugin.mimetype}`, this)}
              >
                {plugin.title.text.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>
    );
  }
}

BrokenReferenceSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.brokenreference.header" defaultMessage="Search for broken references" />,
  index: 'broken-references',
  className: 'se.zert.backend.search.BrokenReferenceCondition',
  default: {
    MimeType: []
  }
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles, { withTheme: true })(BrokenReferenceSearch), selectData))
);
