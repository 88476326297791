import React, { Fragment } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function Sorting(props) {
  const { handleSortingChange, order, orderBy } = props;

  return (
    <>
      <FormControl>
        <InputLabel htmlFor="sorting-by">
          <FormattedMessage id="filter.sorting" defaultMessage="Sorting" />
        </InputLabel>
        <Select
          style={{ width: '150px' }}
          value={orderBy}
          onChange={handleSortingChange}
          inputProps={{
            name: 'orderBy',
            id: 'sorting-by'
          }}
        >
          <MenuItem value="nameNew">
            <em>
              <FormattedMessage id="filter.name" defaultMessage="By name" />
            </em>
          </MenuItem>
          <MenuItem value="mimeType">
            <em>
              <FormattedMessage id="filter.object" defaultMessage="By object" />
            </em>
          </MenuItem>
          <MenuItem value="status">
            <em>
              <FormattedMessage id="filter.status" defaultMessage="By Status" />
            </em>
          </MenuItem>
          <MenuItem value="createdBy">
            <em>
              <FormattedMessage id="filter.created.by" defaultMessage="By created by" />
            </em>
          </MenuItem>
          <MenuItem value="changedBy">
            <em>
              <FormattedMessage id="filter.changed.by" defaultMessage="By changed by" />
            </em>
          </MenuItem>
          <MenuItem value="id">
            <em>
              <FormattedMessage id="filter.id" defaultMessage="By id" />
            </em>
          </MenuItem>
          <MenuItem value="createdAt">
            <em>
              <FormattedMessage id="filter.createdat.date" defaultMessage="By created date" />
            </em>
          </MenuItem>
          <MenuItem value="changedAt">
            <em>
              <FormattedMessage id="filter.created.date" defaultMessage="By modified date" />
            </em>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel htmlFor="sorting-by-direction">
          <FormattedMessage id="filter.sorting.direction" defaultMessage="Direction" />
        </InputLabel>
        <Select
          value={order}
          onChange={handleSortingChange}
          inputProps={{
            name: 'order',
            id: 'sorting-by-direction'
          }}
        >
          <MenuItem value="asc">
            <em>
              <FormattedMessage id="filter.ascending" defaultMessage="Ascending" />
            </em>
          </MenuItem>
          <MenuItem value="desc">
            <em>
              <FormattedMessage id="filter.descending" defaultMessage="Descending" />
            </em>
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
}

export default Sorting;
