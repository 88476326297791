import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import withSearch, { styles, controlStyles } from '../withSearch';
import {Button} from "@mui/material";

import SearchComponent from './SearchComponent';

class NameSearchPanel extends SearchComponent {
  constructor(props) {
    super(props);
    if (!this.state || !this.state.Type) {
      this.state = {
        Name: '',
        Type: 'standard',
        SimilarityThreshold: 0.2
      };
    }
    this.textInput = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (name) => (event) => {
    this.setStatus({ [name]: event.target.value });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    this.props.expanded && this.textInput.current ? this.textInput.current.blur() : this.textInput.current.focus();
  }

  render() {
    //  this.props.handleChange(this.state);
    const { classes } = this.props;
    return (
      <form onSubmit={this.onSubmit} style={{width: this.props.hide ? "100%" : ''}}>
        {!this.props.hide &&
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel htmlFor="search-by">
            <FormattedMessage id="search.name.method" defaultMessage="Method"/>{' '}
          </InputLabel>
          <Select
              // value={this.state.Type}
              value={true}
              onChange={(e) => {
                this.setStatus({Type: e.target.value});
              }}
              inputProps={{
                name: 'search-method',
                id: 'search-by'
              }}

          >
            <MenuItem value="standard">
              <em>
                <FormattedMessage id="search.name.bytext" defaultMessage="By text"/>
              </em>
            </MenuItem>
            <MenuItem value="fulltext">
              <em>
                <FormattedMessage id="search.name.fulltext" defaultMessage="Full text search"/>
              </em>
            </MenuItem>
          </Select>
        </FormControl>
        }

        <div style={{display: "flex", alignItems: "center"}}>
          <TextField
              autoFocus
              inputRef={this.textInput}
              id="standard-name"
              label="Text"
              style={{width: "100%"}}
              className={classes.formControl}
              value={this.state.Name}
              onChange={this.handleChange('Name')}
              type="search"
              fullWidth

              variant="outlined"
          />

          {this.props.hide &&
          <div style={{marginLeft: "10px"}}>
            <Button onClick={this.props.startSearchOnSubmit} color="primary" variant="outlined">
              <FormattedMessage id="search.start" defaultMessage="Search" />
            </Button>
          </div>
          }
        </div>


        {this.state.Type === 'fulltext' && !this.props.hide && (
          <TextField
            id="standard-percenatge"
            label={<FormattedMessage id="search.freetext.percentage" defaultMessage="Percentage %" />}
            fullWidth

            className={classes.formControl}
            value={this.state.SimilarityThreshold * 100}
            onChange={(e) => {
              this.setStatus({ SimilarityThreshold: e.target.value / 100 });
            }}

            type="number"
            variant="outlined"
          />
        )}
      </form>
    );
  }
}

NameSearchPanel.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.name.header" defaultMessage="Search by name" />,
  index: 'search-name',
  className: 'se.zert.backend.search.NameCriteria',

  default: {
    Type: 'standard'
  }
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles)(NameSearchPanel), selectData))
);
