import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogErrorSpelling from "./dialog/DialogErrorSpelling";
import { ClickAwayListener, Fade, Paper, Popper } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";


const useStyles = makeStyles((theme) => ({
  errorText: {
    // textDecoration: "red wavy underline",
    // backgroundColor: "yellow",
   display: 'inline-block',
    position: 'relative',

    "&:before": {
      content: "'~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~'",
      fontSize: 9,
      height: 12,
      color: "red",
      width: "110%",
      position: "absolute",
      top: 14,
      left: -1,
      overflow: "hidden"
    }
  },
  handleClose: {},
  paperRoot: {
    position: "relative",
    zIndex: 1000,
    maxWidth: "fit-content",
    "& div": {
      display: "block !important"
    },
    "& $itemList": {
      display: "flex !important"
    }
  },
  itemList: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: 5,
    paddingRight: 0,
    cursor: "pointer",
    minWidth: 95,
    "&:hover": {
      background: "#01A1C7",
      color: "#ffffff"
    }
  },
  border: {
    borderTop: "1px solid grey",
    marginTop: 10,
    marginBottom: 4
  },
  closeBlock: {
    top: 0,
    left: 0,
    right: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 100,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
}
}));

const defaultDropMenu = [
  {
    name: "Grammar",
    type: "Gd",
    child: [
      { name: "gd" },
      { name: "gad" },
      { name: "ged" },
      { name: "gdf" }
    ]
  },
  {
    name: "Spelling",
    type: "Ignore All",
    child: [
      { name: "gd" },
      { name: "gad" },
      { name: "ged" },
      { name: "gdf" }
    ]
  }

];

const ErrorSpelling = (props) => {
  const cl = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopUp = (event) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    setOpen(true);
    // renderInExceptionRoot(DialogErrorSpelling, {})
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <span>
      <span className={cl.errorText} onContextMenu={handleOpenPopUp}>
        {props.children}
      </span>
      {open &&  <Popper  open={open} class='111'><div className={cl.closeBlock} onClick={handleClose} >1111</div></Popper>}
      <ClickAwayListener onClickAway={handleClose} >
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Paper elevation={3} className={cl.paperRoot}>
                {defaultDropMenu && defaultDropMenu.map((el, i) => (
                  <ChildHintDecorator key={i} el={el} handleClose={handleClose} />
                ))}
              </Paper>
            </Paper>
          </Fade>
        )}
      </Popper>
          </ClickAwayListener>
    </span>
  );
};

export default ErrorSpelling;


const ChildHintDecorator = ({ el }) => {
  const cl = useStyles();
  const [isHovering, setIsHovering] = useState(false);
  const [anchorElChild, setAnchorElChild] = useState(null);


  const handleMouseOver = (e) => {
    setIsHovering(true);
    setAnchorElChild(e.currentTarget);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };


  return (<div className={cl.itemList}
               onMouseOver={handleMouseOver}
               onFocus={() => {
               }}
               onBlur={() => {
               }}
               onMouseOut={handleMouseOut}
  >
    {el.name}
    <Popper
      disablePortal={true}
      open={isHovering}
      //  open={true}
      anchorEl={anchorElChild}
      placement={"right-start"}
      transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={3} className={cl.paperRoot}>
            {el.child.map((it, i) => <div key={i} className={cl.itemList}>
              {it.name}
            </div>)}
            <div className={cl.border}></div>
            <div className={cl.itemList}>
              {el.type}
            </div>
          </Paper>
        </Fade>
      )}
    </Popper>

    <ArrowRightIcon />
  </div>);
};