import React, { useContext, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import DialogAdvanced from '../../../dialog/DialogAdvanced';
import { TabsContext } from '../../../Stylesheet';
import { adobeDropMenu, adobeWord } from '../../../utils/helpers';
import TemplateItemRow from '../../../TemplateItemRow';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    marginBottom: 5
  },
  wrapperTextField: {
    display: 'grid',
    gridTemplateColumns: '55px 150px 1fr 55px 30px',
    gridColumnGap: 10,
    alignItems: 'center'
  },
  notesInput: {
    margin: 0,
    width: '100%'
  },
  noteActiveInputWrapper: {
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  wrapperIconsDialog: {
    cursor: 'pointer',
    '& p': {
      marginBottom: 5,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 25px',
    gridColumnGap: 10
  }
}));

function SpacingTemplate({
  title = '',
  valueProps,
  setValueProps,
  grid,
  type = '',
  setDropMenu,
  handleBefore,
  setValuePropsDialog,
  setDropMenuPropsDialog,
  setDropMenuPrecedence,
  disabled
}) {
  const cl = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const dropMenu = [{ name: 'Default' }, { name: 'Discard' }, { name: 'Retain' }];
  const { setValues, values } = useContext(TabsContext);
  const [disable, setDisable] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
    setDisable(valueProps.conditionality === 'Default');
  };

  const setDropMenuFirst = (valDropMenu, name) => {
    if (handleBefore) {
      handleBefore(valDropMenu.name, name, 'conditionality');
    }
  };

  useEffect(() => {
    if (disabled) {
      setDisable(true);
    } else if (adobeWord(valueProps.conditionality, true) === 'Default') {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [valueProps]);

  return (
    <>
      <DialogAdvanced
        open={openDialog}
        setOpen={setOpenDialog}
        context={{ values: valueProps, setValues, valuesContext: values }}
        disable={disable || disabled}
        type={type}
        setValuePropsDialog={setValuePropsDialog}
        setDropMenuPropsDialog={setDropMenuPropsDialog}
        setDropMenuPrecedence={setDropMenuPrecedence}
      />
      <div className={cl.wrapperRow}>
        <TemplateItemRow
          label={title}
          type={type}
          value={{
            value: valueProps.value ? valueProps.value.value : 0,
            unit: valueProps.value ? adobeDropMenu(valueProps.value.unit) : 'pt'
          }}
          grid={grid || '55px 150px 1fr 55px'}
          dropMenuFirst={{ label: dropMenu, selected: adobeWord(valueProps.conditionality, true) }}
          setDropMenuFirst={setDropMenuFirst}
          setDropMenu={setDropMenu}
          setValueProps={setValueProps}
          disabled={disable || disabled}
          disableMenuFirst={disabled}
        />
        <div
          style={{
            color: disable || disabled ? 'rgba(0, 0, 0, 0.38)' : '#212529'
          }}
          className={cl.wrapperIconsDialog}
          onClick={() => handleOpenDialog()}
        >
          <p>...</p>
        </div>
      </div>

    </>
  );
}

export default SpacingTemplate;
