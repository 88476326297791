import React, { useContext, useEffect, useState } from 'react';
import DialogColors from '@zert-packages/components/dialog/DialogColors';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import makeStyles from '@mui/styles/makeStyles';
import { TabsContext } from '../../../Stylesheet';
import {
  adobeDropMenu,
  adobeDropMenuUpperCase,
  adobeWord,
  handlePageLayout,
  handlePageLayoutSingleLevel
} from '../../../utils/helpers';
import SelectedColor from '../../../SelectedColor';
import TextFieldItem from '../../../TextFieldItem';
import TemplateItemRow from '../../../TemplateItemRow';
import { adobeColor, adobeRgba } from "../../../../formatTemplatePlugin/utils/main";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 10
  },
  wrapperItem: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 20,
    '& $wrapperInput': {
      marginBottom: 0
    }
  },
  wrapperInput: {
    display: 'grid',
    gridTemplateColumns: '121px 1fr',
    // marginBottom: 10,
    gridColumnGap: 10,
    alignItems: 'center'
  },
  label: {
    fontWeight: 700,
    fontSize: '1rem'
  },
  wrapperMargin: {
    border: '1px solid #C6C6C5',
    display: 'grid',
    padding: '7px 10px',
    gridRowGap: '10px'
  }
}));

function ListTemplate() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const listItem = values.stylesheet.blockSettings[values.selectedLeftMenu];
  const [imageDropMenu, setImageDropMenu] = useState(null);
  const [color, setColor] = useState({
    color: "#ffffff",
    load: false,
  })

  const styleDropMenu = [
    { name: 'Arabic numbers' },
    { name: 'Lower alphabetical' },
    { name: 'Upper alphabetical' },
    { name: 'Disc' },
    { name: 'Square' },
    { name: 'Dash' },
    { name: 'Image' }
  ];

  const findImg = () => {
    const key = Object.keys(values.stylesheet.includedElements);
    const val = Object.values(values.stylesheet.includedElements);
    const res = val
      .map((el, i) => ({
        ...el,
        nameType: key[i]
      }))
      .filter((el) => el.mimeType.includes('zert-image'))
      .map((el) => el.nameType);
    setImageDropMenu(res);
  };

  const handleSelectedColor = (val) => {
    setColor(pre => ({...pre, 'color': val, load: true}))
    handlePageLayoutSingleLevel(adobeRgba(val), 'color', values, setValues);
  };

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleFlowDropMenu = (val, name) => {
    if (name === 'type') {
      return handlePageLayoutSingleLevel(
        val.name ? adobeDropMenuUpperCase(val.name) : adobeDropMenuUpperCase(val),
        name,
        values,
        setValues
      );
    }
    handlePageLayoutSingleLevel(val.name ? val.name : val, name, values, setValues);
  };

  const handleTextField = (val, name) => {
    handlePageLayoutSingleLevel(+val, name, values, setValues);
  };

  const handleText = (val, name) => {
    handlePageLayoutSingleLevel(val, name, values, setValues);
  };

  const handleTextSeparation = (val) => {
    handlePageLayoutSingleLevel(val, 'provisionalDistanceBetweenStartsLengthUnit', values, setValues);
  };

  const handleLabelSeparation = (val) => {
    handlePageLayoutSingleLevel(val, 'provisionalLabelSeparationLengthUnit', values, setValues);
  };

  const handleIndent = (val, name) => {
    handlePageLayout(val, name, values, setValues);
  };

  const handleDropMenu = (valDropMenu, name, val) => {
    handlePageLayout(val, name, values, setValues, valDropMenu);
  };




  useEffect(() => {
    if(listItem.color && !color.load){
      setColor(pre => ({
        ...pre,
        color: adobeColor(listItem.color),
        load: true}))
    }
  }, [listItem.color])

  useEffect(() => {
    findImg();
  }, [listItem]);

  return (
    <div className={cl.root}>
      {listItem && listItem.class.includes('ListBlock') && (
        <>
          <DialogColors
            open={openDialog}
            setOpen={setOpenDialog}
            setText={handleSelectedColor}
            text={{ label: 'Select Color' }}
          />

          <div className={cl.wrapperItem}>
            <div className={cl.wrapperInput}>
              <div className={cl.label}>Style:</div>
              <ExpanderItem
                valueProps={adobeWord(listItem.type, true)}
                propsLabel={styleDropMenu}
                setProps={handleFlowDropMenu}
                type="type"
              />
            </div>
            <div>
              <div className={cl.wrapperInput}>
                <div className={cl.label}>Select image:</div>
                <ExpanderItem
                  valueProps={listItem.selectImage}
                  propsLabel={imageDropMenu}
                  setProps={handleFlowDropMenu}
                  type="selectImage"
                  disabled={adobeWord(listItem.type, true) !== 'Image'}
                  key={listItem.selectImage}
                />
              </div>
            </div>
          </div>

          <div className={cl.wrapperInput}>
            <div className={cl.label}>Disk size:</div>
            <ExpanderItem
              valueProps="Normal"
              //  propsLabel={values.stylesheetTabs[values.selectedLeftMenu].dropMenu[4].imageDropMenu}
              setProps={handleFlowDropMenu}
              type="selectImage"
              disabled
            />
          </div>

          <div className={cl.wrapperItem}>
            <div className={cl.wrapperInput}>
              <div className={cl.label}>Prefix:</div>
              <TextFieldItem setProps={handleText} name="prefix" values={listItem.prefix} />
            </div>
            <div className={cl.wrapperInput}>
              <div className={cl.label}>Suffix:</div>
              <TextFieldItem setProps={handleText} name="suffix" values={listItem.suffix} />
            </div>
          </div>
          <SelectedColor
            label="Color:"
            setValueProps={handleSelectedColor}
            handleOpenDialog={handleOpenDialog}
            valueProps={color.color}
            style={{ grid: '121px 30px 1fr 25px' }}
            type="color"
          />

          <TemplateItemRow
            label="Text separation:"
            type="provisionalDistanceBetweenStarts"
            value={{
              value: listItem.provisionalDistanceBetweenStarts,
              unit: adobeDropMenu(listItem.provisionalDistanceBetweenStartsLengthUnit)
            }}
            grid="121px 1fr 55px"
            setValueProps={handleTextField}
            setDropMenu={handleTextSeparation}
          />
          <TemplateItemRow
            label="Label separation:"
            type="provisionalLabelSeparation"
            value={{
              value: listItem.provisionalLabelSeparation,
              unit: adobeDropMenu(listItem.provisionalLabelSeparationLengthUnit)
            }}
            grid="121px 1fr 55px"
            setValueProps={handleTextField}
            setDropMenu={handleLabelSeparation}
          />

          <div className={cl.wrapperItem}>
            <div className={cl.wrapperInput}>
              <div className={cl.label}>Orphans:</div>
              <TextFieldItem setProps={handleTextField} values={listItem.orphans} name="orphans" type="number" />
            </div>

            <div className={cl.wrapperInput}>
              <div className={cl.label}>Widows</div>
              <TextFieldItem setProps={handleTextField} values={listItem.widows} name="widows" type="number" />
            </div>
          </div>

          <div className={cl.label}>Indent</div>
          <div className={cl.wrapperMargin}>
            <TemplateItemRow
              label="Left:"
              type="marginLeft"
              value={{
                value: listItem.marginLeft.value,
                unit: adobeDropMenu(listItem.marginLeft.unit)
              }}
              grid="121px 1fr 55px"
              setDropMenu={handleDropMenu}
              setValueProps={handleIndent}
            />
            <TemplateItemRow
              label="Right:"
              type="marginRight"
              value={{
                value: listItem.marginRight.value,
                unit: adobeDropMenu(listItem.marginRight.unit)
              }}
              grid="121px 1fr 55px"
              setDropMenu={handleDropMenu}
              setValueProps={handleIndent}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default ListTemplate;
