import React from 'react';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import withTile from '../shared/withTile';

export const expandFunction = () => {};

export function TileIcon({ element, selectElement, isSelected }) {
  const Icon = getPluginByMimeType(element.mimeType).icon;
  return (
    <div
      className="icon-box"
      style={{
        padding: '0px 0.5em 0 0'
      }}
    >
      <Icon style={{ fill: '#1797c7' }} />
    </div>
  );
}

export function ElementContentOverviewTile() {
  return <div className="status_wrapper" />;
}

export function OverviewTile() {
  return <></>;
}

export default withTile(TileIcon, OverviewTile, ElementContentOverviewTile);
