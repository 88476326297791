import Tooltip from '@mui/material/Tooltip';
import React, { memo, useRef } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import { MAX_FILE_SIZE } from '@zert-packages/plugins/Environment';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import SomethingWentWrongDialog from './common/dialogs/SomethingWentWrongDialog';

const useStyles = makeStyles({
  input: {
    visibility: 'hidden',
    width: 0,
    height: 0
  }
});
const AttachButton = memo(({ onChange, inputProps = {}, ...other }) => {
  const cl = useStyles();
  const inputRef = useRef();
  const intl = useIntl();
  const onClick = (e) => {
    e.target.value = null;
  };

  const internalOnChange = (e) => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      return renderInExceptionRoot(SomethingWentWrongDialog, {
        contextText: <FormattedMessage id="attachButton.fileTooLargeError" defaultMessage="File too large to upload" />
      });
    }
    onChange(e);
  };
  return (
    <Tooltip
      title={intl.formatMessage({
        id: 'AttachButton.ToolTip.Upload',
        defaultMessage: 'Upload'
      })}
    >
      <IconButton onClick={() => inputRef.current.click()} {...other} size="large">
        <AttachFileIcon />
        <input
          type="file"
          onClick={onClick}
          ref={inputRef}
          className={cl.input}
          onChange={(e) => internalOnChange(e)}
          {...inputProps}
        />
      </IconButton>
    </Tooltip>
  );
});

export default AttachButton;
