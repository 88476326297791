import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { showSnackbarLoaderWithMessage } from './Snackbars';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: 'calc(var(--vh, 1vh) * 100)',
    position: 'fixed',
    zIndex: '9000',
    opacity: '1'
  },

  loaderContainer: {
    position: 'fixed',
    right: '0',
    top: '0'
  }
});

function HoverWithSnackbarLoader() {
  const cl = useStyles();
  const snackbar = useSnackbar();
  useEffect(() => {
    const snackbarId = showSnackbarLoaderWithMessage(snackbar);
    return () => {
      snackbar.closeSnackbar(snackbarId);
    };
  });
  return <div className={cl.root} />;
}

export default HoverWithSnackbarLoader;
