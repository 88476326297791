import { FormattedMessage, useIntl } from "react-intl";
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { findNode } from "./findNode";
import { useDispatch, useSelector } from "react-redux";
import { generateRiskAnalisesReport } from '@zert-packages/actions/riskassesmentActions';
import { RiskRowGroupButton } from '@zert-packages/components/common/dialogs/ReportDialogs/utils/RiskRowGroupDialog';
import { loadColumnsAPI, loadColumnsWithFnAPI } from "@zert-packages/components/common/dialogs/ReportDialogs/API";
import { generalCheckBoxes } from './checkboxes';
import generalRadioBtns from './generalRadioBtns';

export const RA_REPORT_TYPES = {
  REPORT: 0,
  OPEN_MEASURES: 1,
  MEASUREMENTS_REPORT: 2,
  TEMPLATE_REPORT: 3,
  CUSTOM_REPORT: 4,
  OVERVIEW_REPORT: 5,
  PARTICIPANTS_REPORT: 6
};

export default function useRiskAnalisisReport({ selectedNode, selectedValues, catalog, id }) {
  const rrtemplates = useSelector((state) => state.rrtemplates);

  const getReportCaption = () => {
    switch (id) {
      case RA_REPORT_TYPES.REPORT:
        return <FormattedMessage id="reassessment.report" defaultMessage="Risk assessment report" />;
      case RA_REPORT_TYPES.OPEN_MEASURES:
        return <FormattedMessage id="reassessment.open_measures_report" defaultMessage="Remaining tasks" />;
      case RA_REPORT_TYPES.MEASUREMENTS_REPORT:
        return <FormattedMessage id="reassessment.measurements_report" defaultMessage="Measurements Report" />;
      case RA_REPORT_TYPES.TEMPLATE_REPORT:
        return <FormattedMessage id="reassessment.TemplateReport" defaultMessage="Template Report" />;
      case RA_REPORT_TYPES.CUSTOM_REPORT:
        return <FormattedMessage id="reassessment.CustomReport" defaultMessage="Custom Report" />;
      case RA_REPORT_TYPES.OVERVIEW_REPORT:
        return <FormattedMessage id="reassessment.OverviewReport" defaultMessage="Risk assessment overview report" />;
      case RA_REPORT_TYPES.PARTICIPANTS_REPORT:
        return <FormattedMessage id="reassessment.ParticipantsReports" defaultMessage="Participants Report" />;
      default:
        return <FormattedMessage id="reassessment.report" defaultMessage="Risk assessment report" />;
    }
  };
  const caption = getReportCaption();

  const getReportDescription = () => {
    switch (id) {
      case RA_REPORT_TYPES.REPORT:
        return (
          <FormattedMessage
            id="ra.report.description"
            defaultMessage="This report shows an overview of a whole risk assessment."
          />
        );
      case RA_REPORT_TYPES.OPEN_MEASURES:
        return (
          <FormattedMessage
            id="ra.controlreport.description"
            defaultMessage="The quality control of the risk assessment generates a report that verifies the quality of the risk assessment according to rules set by the user."
          />
        );
      case RA_REPORT_TYPES.MEASUREMENTS_REPORT:
        return (
          <FormattedMessage
            id="ra.measurereport.description"
            defaultMessage="This report shows information on the actions carried out."
          />
        );
      case RA_REPORT_TYPES.CUSTOM_REPORT:
        return (
          <FormattedMessage
            id="ra.customreport.description"
            defaultMessage="This report is fully customizable, allowing you to choose what to include and which cells from the risk rows and the order in which they are presented."
          />
        );
      case RA_REPORT_TYPES.OVERVIEW_REPORT:
        return (
          <FormattedMessage
            id="ra.overviewreport.description"
            defaultMessage="This report generates an overview of all risk assessments placed on the selected node. You can choose which types of assessments to include and how many of the highest rated risk rows to include from each assessment."
          />
        );
      case RA_REPORT_TYPES.PARTICIPANTS_REPORT:
        return (
          <FormattedMessage
            id="ra.participantreport.description"
            defaultMessage="This report generates a list participants."
          />
        );
      default:
        return null;
    }
  };
  const description = getReportDescription();

  const getKey = () => {
    switch (id) {
      case RA_REPORT_TYPES.REPORT:
        return 'risk-analysis-report';
      case RA_REPORT_TYPES.OPEN_MEASURES:
        return 'control-report';
      case RA_REPORT_TYPES.MEASUREMENTS_REPORT:
        return 'measurements-report';
      case RA_REPORT_TYPES.TEMPLATE_REPORT:
        return 'template-report';
      case RA_REPORT_TYPES.CUSTOM_REPORT:
        return 'custom-report';
      case RA_REPORT_TYPES.OVERVIEW_REPORT:
        return 'overview-report';
      case RA_REPORT_TYPES.PARTICIPANTS_REPORT:
        return 'participants-report';
      default:
        return 'risk-analysis-report';
    }
  };
  const multiSelectors =
    rrtemplates && id === RA_REPORT_TYPES.OVERVIEW_REPORT
      ? [
        {
          name: 'ra_templates',
          section: 'templates',
          label: <FormattedMessage id="reports.templates" defaultMessage="Templates" />,
          selected: rrtemplates,
          options: rrtemplates
        }
      ]
      : [];
  const textFields = id === RA_REPORT_TYPES.OVERVIEW_REPORT ? [
    {
      name: 'rrCount',
      label: <FormattedMessage
        id="reports.rrcount"
        defaultMessage="Number of top risks included for each matrix column" />,
      //defaultValue:3,
      value:3,
      type: "number",
      errMess: <FormattedMessage
        id="reports.rrcountErr"
        defaultMessage="Number have to be 0 or higher" />,
      validationFunc: (value) => (value >= 0)
    }
  ] : [];
  const index = 'riskassessment';
  const dispatch = useDispatch();

  const radiobtns = catalog ? generalRadioBtns : [];
  // eslint-disable-next-line no-use-before-define
  const dateSelectors = [];
  const selectors = [];
  // eslint-disable-next-line no-use-before-define
  const checkBoxes = [];
  const checkedItemKeys = new Set(radiobtns.map(v => v.name).values())

  switch (id) {
    case RA_REPORT_TYPES.OVERVIEW_REPORT:
      checkBoxes.push({
        name: 'cb_include_archived',
        section: 'scope',
        label: <FormattedMessage id="reports.riskassesment.includeArchied" defaultMessage="Include archived" />,
        checked: false
      });
      break;
    case RA_REPORT_TYPES.PARTICIPANTS_REPORT:
      break;
    default:
      checkBoxes.push(...generalCheckBoxes);
  }

  for (const checkBox of checkBoxes) {
    checkedItemKeys.add(checkBox.name);
  }

  const generateReport = async (checkedItems, setItems) => {
    let selectedIds = [];
    if (selectedValues) {
      selectedIds = selectedValues.map((value) => value.versionId);
    }

    if (id === RA_REPORT_TYPES.OVERVIEW_REPORT) {
      const raTemplates = checkedItems('ra_templates');
      const ids = raTemplates && raTemplates.length ? raTemplates : rrtemplates;
      let rowsList = checkedItems('rrGroupButton');
      if (!rowsList || !rowsList.length) {
        rowsList = await loadColumnsWithFnAPI(checkedItems, rrtemplates);
        // setItems("rrGroupButton" , rowsList);
      }

      selectedIds = {
        riskGroups: rowsList,
        templateElementIds: ids.map((template) => template.id),
        rrCount: checkedItems('rrCount')
      };
    }

    dispatch(
      generateRiskAnalisesReport(
        {
          name: caption,
          reportUUID: uuidv4()
        },
        catalog ? catalog.info.versionId : -1,
        //TODO: Not only for id RA_REPORT_TYPES.OVERVIEW_REPORT?
        catalog ? findNode(catalog, selectedNode) : (selectedNode || 'none'),
        catalog ? checkedItems('reports.subnode') : "elements",
        checkedItemKeys.has('cb_separateImages') ? checkedItems('cb_separateImages') : false,
        checkedItemKeys.has('cb_include_archived') ? checkedItems('cb_include_archived') : false,
        id,
        selectedIds
      )
    );
  };

  //  console.log("racallback" , selected)

  return {
    index,
    generateReport,
    caption,
    description,
    radiobtns,
    dateSelectors,
    multiSelectors,
    selectors,
    checkBoxes,
    textFields,
    buttons:
      id === RA_REPORT_TYPES.OVERVIEW_REPORT && rrtemplates && rrtemplates.length > 0
        ? [{ name: 'rrGroupButton', section: 'other',
          component: RiskRowGroupButton }]
        : [],
    key: getKey(),
    disabled: id === RA_REPORT_TYPES.OVERVIEW_REPORT && !rrtemplates
  };
}
