import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import withTile from '@zert-packages/components/ElementTile/shared/withTile';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { TileIcon } from '@zert-packages/components/ElementTile/tiles/AbstractTile';
import TranslationJobContentTable, { getStatusById, statuses } from './TranslationJobContentTable';
import TaskContentOverviewTile from '@zert-packages/components/ElementTile/shared/TaskContentOverviewTile';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import Link from '@mui/material/Link';
import { toLocaleString } from '@zert-packages/plugins/ExplorerPlugin/SearchConditions/helpers/getLocaleByLanguageCode';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { allowUpload } from './helpers/checkTranslationJobStatus';
import Box from '@mui/material/Box';
import {
  cancelTJ,
  downlaodTERMDB,
  downloadTranslationFiles,
  fetchTranslationJob,
  uploadTJFile,
  validateTJ
} from './servicesMiddlewares';

export const collapseCL = (element, dispatch) => {
  dispatch(fetchTranslationJob(element.versionId));
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

export const TranslationContentOverviewTile = ({ element, intl }) => {
  const uploadCallback = (locale) => (e) => {
    // console.log(e);
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.target;
    const file = files[0];
    const fd = new FormData();
    if (!file) return;
    fd.append('file', file, file.name);
    dispatch(uploadTJFile(element.versionId, locale, fd));
    // uploadTranslationFile(versionId, toLocaleString(list[selectedRow].locale), fd);
    // dispatch(validateTJ(versionId, list[selectedRow].locale));
  };
  const classes = useStyles();
  const CLM = useSelector((state) => state.CLM);
  const dispatch = useDispatch();
  const [translationJobTemplate, setTranslationJobTemplate] = useState();
  const [translationJob, setTranslationJob] = useState(null);
  useEffect(() => {
    if (CLM.translationJob && CLM.translationJob.info && CLM.translationJob.info.versionId == element.versionId) {
      setTranslationJob(CLM.translationJob);
      setTranslationJobTemplate(CLM.translationJobTemplate);
    }
  }, [CLM.translationJob]);

  if (!translationJob) {
    return (
      <div className="togglable-info-header" style={{ display: 'flex' }}>
        <Loader />
      </div>
    );
  }
  const { translationContent } = translationJob;

  if (translationContent == []) {
    return <div className="togglable-info-header" style={{ display: 'flex' }} />;
  }
  //   "past-entry"

  var content = null;
  if (translationContent) {
    content = translationContent.map((translation, index) => {
      return (
        <div key={index} className="current-entry" style={{ display: 'flex' }}>
          <div style={{ flexBasis: '20em' }}>
            {`${translation.locale.displayName} - ${toLocaleString(translation.locale)}`}
          </div>
          <div style={{ flexBasis: '12em' }}>{getStatusById(translation.localeStatus).name}</div>
          <div className="hide_on_mobile" style={{ flexBasis: '10em' }}>
            {translation.wordCount}
          </div>
          <div className="hide_on_mobile" style={{ flexBasis: '25em' }}>
            <Typography className={classes.root}>
              {translation.localeStatus && translation.localeStatus == statuses[1].id && (
                <Link
                  component="button"
                  variant="body2"
                  style={{ paddingLeft: '5px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(cancelTJ(element.versionId, translation.locale));
                  }}
                >
                  <FormattedMessage id="TranslationJobContent.Cancel" defaultMessage="Cancel" />
                </Link>
              )}
              {allowUpload(translationJobTemplate, translationJob) &&
                translation.localeStatus &&
                translation.localeStatus == statuses[1].id && (
                  <Link
                    component="button"
                    variant="body2"
                    style={{ paddingLeft: '5px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch(uploadTJFile(element.versionId, translation.locale));
                    }}
                  >
                    <FormattedMessage id="TranslationJobContent.Upload" defaultMessage="Upload" />
                  </Link>
                )}
              {translation.localeStatus &&
                translation.localeStatus != statuses[4].id &&
                translation.localeStatus != statuses[0].id && (
                  <Link
                    component="button"
                    variant="body2"
                    style={{ paddingLeft: '5px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch(
                        downloadTranslationFiles(
                          translationJob.sequenceNumber,
                          element.versionId,
                          translation.locale,
                          true,
                          translationJob.sourceLanguage,
                          false
                        )
                      );
                    }}
                  >
                    <FormattedMessage id="TranslationJobContent.Download" defaultMessage="Download" />
                  </Link>
                )}
              {allowUpload(translationJobTemplate, translationJob) &&
                translation.localeStatus &&
                translation.localeStatus == statuses[1].id &&
                translation.localeStatus.hasTranslatedContent && (
                  <Link
                    component="button"
                    variant="body2"
                    style={{ paddingLeft: '5px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch(validateTJ(element.versionId, translation.locale));
                    }}
                  >
                    <FormattedMessage id="TranslationJobContent.Import" defaultMessage="Import" />
                  </Link>
                )}
            </Typography>
          </div>
        </div>
      );
    });
  }

  return (
    <div style={{ width: '100%' }}>
      <div className="togglable-info-header">
        <FormattedMessage id="TranslationJobTile.Name" defaultMessage="Elements" />
      </div>
      {translationJob.elements.map((element, index) => (
        <div className={'current-entry'}>
          {' '}
          {element.name}({element.label})
        </div>
      ))}

      <div className="togglable-info-header" style={{ display: 'flex' }}>
        <div style={{ flexBasis: '20em' }}>
          <FormattedMessage id="TranslationJob.ContentTable.Language" defaultMessage="Language" />
        </div>

        <div style={{ flexBasis: '12em' }}>
          <FormattedMessage id="TranslationJob.ContentTable.Status" defaultMessage="Status" />
        </div>
        <div className="hide_on_mobile" style={{ flexBasis: '10em' }}>
          <FormattedMessage id="TranslationJob.ContentTable.Words" defaultMessage="Words" />
        </div>
        <div className="hide_on_mobile" style={{ flexBasis: '25em' }}>
          <FormattedMessage id="TranslationJob.ContentTable.Actions" defaultMessage="Actions" />
        </div>
      </div>
      {content}
      <div className="togglable-info-header" style={{ display: 'flex' }}>
        <Box m={2} />
        <Link
          component="button"
          variant="body2"
          style={{ paddingLeft: '5px' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(
              downloadTranslationFiles(
                translationJob.sequenceNumber,
                element.versionId,
                null,
                false,
                translationJob.sourceLanguage,
                true
              )
            );
          }}
        >
          <FormattedMessage id="TranslationJobContent.DownloaAll" defaultMessage="Download All" />
        </Link>
        <Box m={2} />
        <Link
          component="button"
          variant="body2"
          key="downloadTermDB"
          style={{ paddingLeft: '5px' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(downlaodTERMDB(translationJob.targetLanguages, true));
          }}
        >
          <FormattedMessage id="TranslationJobContent.DownloaTermDB" defaultMessage="Download as TBX" />
        </Link>
        <Box m={2} />
        <Link
          key="downloadTermDBXLS"
          component="button"
          variant="body2"
          style={{ paddingLeft: '5px' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(downlaodTERMDB(translationJob.targetLanguages, false));
          }}
        >
          <FormattedMessage id="TranslationJobContent.DownloaTermXLS" defaultMessage="Download as XLS" />
        </Link>
      </div>
      <div id="si_colaps" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  translationJob: state.translationJob,
  myuser: state.myuser
});

export default injectIntl(
  withRouter(
    connect(mapStateToProps)(withTile(TileIcon, TranslationContentOverviewTile, TaskContentOverviewTile, collapseCL))
  )
);
