import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextChanger from '../dialogs/TextChanger';

export const formStyle = (theme) =>
  createStyles({
    divRoot: {
      display: 'flex'
      /*  height:'25rem',
          border:'1px solid lightgrey',
          padding:4 */
    },
    divButtons: {
      width: '5%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    divContentContainer: {
      border: '1px solid lightgrey',
      width: '95%',
      height: '20rem'
    },
    divContentContainerNoBorder: {
      width: '95%',
      height: '100%'
    },
    listStyle: {
      '&.MuiTypography-body1': {
        fontSize: '1em'
      }
    }
  });
const useStyles = makeStyles(formStyle);

const initState = {
  languageDialog: false
};

function DateView({ data, setData }) {
  const [details, setDetails] = useState(data);
  const [dateState, setDateState] = useState(initState);

  useEffect(() => {
    setData(details);
  }, [details]);

  useEffect(() => {
    setDetails(data);
  }, [data]);

  const openCloseLanguageDialog = () => {
    setDateState((prevState) => ({
      ...prevState,
      languageDialog: !prevState.languageDialog
    }));
  };

  return (
    <div>
      <TextChanger data={details} setData={setDetails} />
      <Typography align="center" variant="h6">
        Contains time:
      </Typography>
      <Select
        labelId="demo-simple-select-label"
        fullWidth
        size="small"
        variant="outlined"
        id="demo-simple-select"
        value={details.isTimeContained}
        onChange={(event) => {
          setDetails((prevState) => ({
            ...prevState,
            isTimeContained: event.target.value
          }));
        }}
      >
        <MenuItem value>Yes</MenuItem>
        <MenuItem value={false}>No</MenuItem>
      </Select>
    </div>
  );
}

export default DateView;
