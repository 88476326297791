import {
  API_ROOT,
  fetchPerformMeasureError,
  handlePost,
  handlePostFile,
  handlePostParamAsString,
  handleQuery,
  handleText
} from '@zert-packages/actions/api';

const startQuery = '/publishing';
const toReplaceQuery = '/common';

export function exportAloHTML(versionId, locales, fallbackLocales, previewPublishingFilters) {
  return handlePost(`/aloexport/runExport/${versionId}`, {
    locales,
    fallbackLocales,
    publicationFilters: previewPublishingFilters
  });
}

export function loadMetadataList(versionId, publishingId, identifier) {
  return handleQuery(`/assert/loadMetadata/${versionId}/${publishingId}/${identifier}/`);
}

export function loadAssertName(versionId, publishingId) {
  return handleQuery(`/assert/getBlockProductName/${versionId}/${publishingId}/`);
}

export function exportKomatsuAPI(publication, uuid) {
  return handlePost(`/assert/runAssert/${uuid}`, publication);
}

export function loadPublishingList(selectedAudience) {
  return handleQuery(`${startQuery}/getAll/${selectedAudience}`);
}

export function loadPublishings(elementId) {
  return handleQuery(`${startQuery}/getPublishings/${elementId}`);
}
export function removePublishing(publishingId) {
  return handleText(`${startQuery}/removePublishing/${publishingId}`);
}

export function loadMetadata(versionId) {
  return handleQuery(`${toReplaceQuery}/getMetadata/${versionId}`);
}

export function removePublishingCommentAsync(publishingId, comment) {
  return handlePost(`${startQuery}/removePublishingComment/${publishingId}`, comment);
}

export function addPublishingCommentAsync(publishingId, comment) {
  return handlePostParamAsString(`${startQuery}/addPublishingComment/${publishingId}`, comment);
}

export function createPublication(
  publishingId,
  versionId,
  allowComments,
  notifyMeOnExpire,
  notifyMeOneNewComment,
  validUntil,
  purpose,
  settings,
  comments
) {
  return handlePost(`${startQuery}/createPublishing`, {
    settings,
    publishingId,
    versionId,
    allowComments,
    notifyMeOnExpire,
    notifyMeOneNewComment,
    validUntil,
    comments: comments || [],
    purpose
  });
}
