import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SpacingTemplate from '../blockTabs/topic/SpacingTemplate';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    marginBottom: 5
  },
  borderBlock: {
    border: '1px solid #C6C6C5',
    padding: 10,
    display: 'grid',
    gridRowGap: 10
  }
}));

function Spacing({ spacing, setSpacing }) {
  const cl = useStyles();

  const handleSpacing = (val, name) => {
    const res = {
      ...spacing[name],
      value: {
        ...spacing[name].value,
        unit: spacing[name].value ? spacing[name].value.unit : 'pt',
        value: +val
      }
    };
    setSpacing(res, name);
  };
  const handleSpacingMenu = (valDrop, name, val) => {
    const res = { ...spacing[name], value: { unit: valDrop, value: +val } };
    setSpacing(res, name);
  };
  const handleSpacingBefore = (val, name, nameObject) => {
    const res =
      val === 'Default'
        ? { ...spacing[name], [nameObject]: val, value: { ...spacing[name].value, value: 0, unit: 'pt' } }
        : { ...spacing[name], [nameObject]: val };
    setSpacing(res, name);
  };
  const handleValuePropsDialogBefore = (val, name, type) => {
    const res = {
      ...spacing.spaceBefore,
      [name]: {
        unit: spacing.spaceBefore[name] ? spacing.spaceBefore[name].unit : 'pt',
        value: +val
      }
    };
    setSpacing(res, 'spaceBefore');
  };

  const setDropMenuPropsDialogBefore = (valDrop, name, val) => {
    const res = {
      ...spacing.spaceBefore,
      [name]: {
        unit: valDrop,
        value: +val
      }
    };
    setSpacing(res, 'spaceBefore');
  };

  const setDropMenuPrecedenceBefore = (val) => {
    const res = { ...spacing.spaceBefore, precedence: val.name };
    setSpacing(res, 'spaceBefore');
  };

  const handleValuePropsDialogAfter = (val, name) => {
    const res = {
      ...spacing.spaceAfter,
      [name]: {
        unit: spacing.spaceAfter[name] ? spacing.spaceAfter[name].unit : 'pt',
        value: +val
      }
    };
    setSpacing(res, 'spaceAfter');
  };

  const setDropMenuPropsDialogAfter = (valDrop, name, val) => {
    const res = {
      ...spacing.spaceAfter,
      [name]: {
        unit: valDrop,
        value: +val
      }
    };
    setSpacing(res, 'spaceAfter');
  };

  const setDropMenuPrecedenceAfter = (val) => {
    const res = { ...spacing.spaceAfter, precedence: val.name };
    setSpacing(res, 'spaceAfter');
  };

  return (
    <div>
      {' '}
      <p className={cl.title}>Space</p>
      <div className={cl.borderBlock}>
        <SpacingTemplate
          title="Before:"
          type="spaceBefore"
          valueProps={spacing.spaceBefore}
          grid="100px 150px 1fr 55px"
          setValueProps={handleSpacing}
          setDropMenu={handleSpacingMenu}
          handleBefore={handleSpacingBefore}
          setValuePropsDialog={handleValuePropsDialogBefore}
          setDropMenuPropsDialog={setDropMenuPropsDialogBefore}
          setDropMenuPrecedence={setDropMenuPrecedenceBefore}
        />
        <SpacingTemplate
          title="After:"
          type="spaceAfter"
          valueProps={spacing.spaceAfter}
          grid="100px 150px 1fr 55px"
          setValueProps={handleSpacing}
          setDropMenu={handleSpacingMenu}
          handleBefore={handleSpacingBefore}
          setValuePropsDialog={handleValuePropsDialogAfter}
          setDropMenuPropsDialog={setDropMenuPropsDialogAfter}
          setDropMenuPrecedence={setDropMenuPrecedenceAfter}
        />
      </div>
    </div>
  );
}

export default Spacing;
