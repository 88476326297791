import React, { useContext, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import getTranslation from "@zert-packages/utils/getTranslation.old";
import { Checkbox } from "@mui/material";
import { getApiGroupsList } from "../../API";
import { TabsContext } from "../../FormatTemplatePlugin";
import SkeletonLoader from "@zert-packages/components/SkeletonLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 730,
    minWidth: "fit-content"
  },
  border: {
    background: "#1898C7",
    height: 1
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 95px 95px 95px 95px 95px ",
    gridRowGap: 5,
    gridColumnGap: 5,
    alignItems: "center"
  },
  activeFormat: {
    color: "#ffffff",
    background: "#1898C7",
    "& $wrapperCheckbox": {
      "& svg": {
        color: "#ffffff"
      }
    }
  },
  format: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  wrapperCheckbox: {
    marginLeft: -5,
    "& svg": {
      color: "#1898C7"
    },
    "& .MuiButtonBase-root": {
      padding: 5
    }
  }
}));

const HiddenFormatsTabs = () => {
  const cl = useStyles();
  const { myuser, formatTemplate } = useSelector((state) => state);
  const { values, setValues } = useContext(TabsContext);
  const [hidden, setHidden] = useState({
    format: null,
    selectedFormat: null,
    checked: null
  });

  const handleFormat = (el, i) => {
    setHidden(pre => ({ ...pre, selectedFormat: el }));
  };

  const findChecked = (el, item) => {
    if (hidden.checked === 0 || !hidden.checked) {
      return false;
    }
    const findParen = hidden.checked.filter(it => (it.id === el.identifierWithPrefix) && (it.idChecked === item.info.elementUUID));
    return findParen.length > 0;
  };

  //{
  //     "0": "zert:FormatTemplate/Format[block-user-book]/Group[everyone]",
  //     "1": true,
  //     "checked": true,
  //     "id": "block-user-book",
  //     "idChecked": "everyone"
  // }
  console.log('hidden', hidden);

  const handleCheckbox = (event, el, item) => {
    const val = event.target.checked;
    const id = el.identifierWithPrefix;
    const idChecked = item.info.elementUUID;
    if (!val) {
      const res =  hidden.checked.filter(el => !(el.id === id && el.idChecked === idChecked));
      const  findTemplate = res.reduce((acu, cur) => {
        return {...acu, [cur[0]]: true}
      }, {})
      setValues(pre => ({...pre, templateList: findTemplate}))
      return setHidden(pre => ({ ...pre, checked: res }));
    }
    const res = {
      [`zert:FormatTemplate/Format[${id}]/Group[${idChecked}]`]: val,
      id,
      idChecked,
      0 : `zert:FormatTemplate/Format[${id}]/Group[${idChecked}] : ${val}`
    };
    const  findTemplate = [...hidden.checked, res].reduce((acu, cur) => {
      return {...acu, [cur[0]]: true}
    }, {})
     setValues(pre => ({...pre, templateList: findTemplate}))
    setHidden(pre => ({ ...pre, checked: [...pre.checked, res] }));
  };


  const getGroupsList = async () => {
    const res = await getApiGroupsList(myuser.companyId);
    const everyone = {
      "info": {
        elementUUID: "everyone",
        name: "Everyone"
      }
    };
    if (res && res.length > 0) {
      setValues((prev) => ({ ...prev, groups: [everyone, ...res] }));
    }
  };


  const getChecked = () => {
    if(values.templateList.identifier){
      return
    }
    const res = Object.entries(values.templateList).map((el) => {
      return {
        ...el,
        checked: el[1],
        id: el[0]?.split("/")[1].match(/\[(.*?)\]/)[1],
        idChecked: el[0].split("/Group")[1].replace(/[[.\s]/g, "").split("]").join().replace(/[',']/g, "")
      };
    });
    setHidden(pre => ({ ...pre, checked: res }));
  };


  useEffect(() => {
    if (formatTemplate) {
      const res = [...formatTemplate.blockFormats,
        ...formatTemplate.tableFormats,
        ...formatTemplate.imageFormats,
        ...formatTemplate.partFormats,
        ...formatTemplate.paragraphFormats,
        ...formatTemplate.textFormats
      ];
      setHidden(pre => ({ ...pre, format: res }));
    }
  }, [formatTemplate]);

  useEffect(() => {
    if (myuser && !values.groups) {
      getGroupsList();
    }
  }, [myuser]);

  useEffect(() => {
    if (values.templateList && !hidden.checked) {
      getChecked();
    }
  }, [values.templateList, hidden.checked]);



  return (<div className={cl.root}>
    <div className={cl.border}></div>
    <div className={cl.grid}>
      <div>Format</div>
      {values.groups && values.groups.map((el, i) => <div key={i}>
        {el.info.name}
      </div>)}
    </div>
    <div className={cl.border} style={{ height: 2 }}></div>

    {!values.groups  && Array.from({length: 50}, (_, index) => index).map((el, i) =>
      <SkeletonLoader key={i} height={38}/>)}
    {hidden.format?.length > 0 && hidden.format?.map(el =>
      <div key={el.identifier}
           className={`${cl.grid} ${el.identifier === hidden.selectedFormat?.identifier ? cl.activeFormat : cl.format}`}
           onClick={() => handleFormat(el)}
      >
        <span style={{ textTransform: "capitalize" }}>
          <span>[{el.identifierWithPrefix.split("-")[0]}]</span>
          {el.label && getTranslation(el.label)}
        </span>
        {values.groups && values.groups.map((it, i) =>
          <div key={i} className={cl.wrapperCheckbox}>
            <Checkbox
              checked={findChecked(el, it)}
              onChange={(e) => handleCheckbox(e, el, it)}
              name="languageChildren"
            />
          </div>)}
      </div>
    )}

  </div>);
};

export default HiddenFormatsTabs;