import { createAction, createReducer } from '@reduxjs/toolkit';

export const getPublishingList = createAction('Publishing: Load Publishing List');
export const setPublishingLoading = createAction('Publishing: Set loading');

export const getMetaData = createAction('Publishing: LoadMetadata');
export const setPublishingForElement = createAction('Publishing: Set Publishing for Element');

export const initialState = {
  publishingList: null,
  loading: false,
  metadataBundle: null
};

export const PublishingReducer = createReducer(initialState, {
  [getPublishingList]: (state, action) => {
    state.publishingList = action.payload;
  },
  [setPublishingForElement]: (state, action) => {
    const index = state.publishingList.indexOf(action.payload.selectedPublishing);
    if (index > -1) {
      const newPublishing = {
        ...state.publishingList[index]
      };
      const selectedPublicationIndex = newPublishing.publishings.indexOf(action.payload.selectedPublication);
      if (selectedPublicationIndex > -1 && newPublishing.publishings.length > 1) {
        const updatedPublishing = {
          ...state.publishingList[index],
          publishings: newPublishing.publishings.filter((publiciaction, index) => index != selectedPublicationIndex)
        };
        state.publishingList = state.publishingList.map((publiciaction, indexNew) =>
          index != indexNew ? publiciaction : updatedPublishing
        );
      } else {
        state.publishingList = state.publishingList.filter((publishing, indexNew) => index != indexNew);
      }
    }
  },
  [setPublishingLoading]: (state, action) => {
    state.loading = action.payload;
  },

  [getMetaData]: (state, action) => {
    state.metadataBundle = { metaData: action.payload.metaData, versionId: action.payload.versionId };
  }
});
