import {makeStyles} from '@mui/styles';


const styleTableEditor = makeStyles((theme) => ({
  root: {
    padding: "10px 0px"
  },
  fixedBlock: {
    position: 'absolute',
    zIndex: 10,
    backgroundColor: '#ffffff',
    right: 32,
    border: '1px solid rgb(1, 161, 199)',
    padding: '5px 0px',
  },
  wrapperPopUpMenu: {
    display: 'grid',
    gridTemplateColumns: '1fr 25px',
    gap: 10,
  },
  tableWrapper: {
    width: "100%",
    "& table": {
      width: "100%",
      borderSpacing: "0px",
      // borderTop: "2px solid #ced4da",
      // borderRight: "2px solid #ced4da"
    },
    "& td": (props) =>( {
      // minWidth: 50,
      minWidth: 25,
      borderBottom: "2px solid #ced4da",
      borderLeft: "2px solid #ced4da",
      padding: "0px",
      boxSizing: "border-box",
      cursor:  props.blockClick ? "not-allowed":"pointer",
    }),
    "& .selectedCell": {
      backgroundColor: "rgba(200, 200, 255, 0.4)"
    },
    "& .ProseMirror": {
      height: '100%',
      outline: "none"
    },
    '& img': {
      maxHeight: 128,
    },
    // '& p': {
    //     margin: 0,
    //     color: '#ffffff'
    // }
  },
  wrapperAddCellHeader: {
    display: "flex",
    justifyContent: 'space-between',
    "& div": {
      margin: '0 auto',
      width: 22,
      "& svg": {
        width: 22,
        height: 21,
        borderRadius: '5px 5px 0px 0px',
        position: "relative",
        top: 6.5,
        borderTop: "1px dashed #ced4da",
        borderLeft: "1px dashed #ced4da",
        borderRight: "1px dashed #ced4da",
        borderBottom: '1px solid #000000',
        '&:hover': {
          color: '#01A1C7',
          borderTop: '1px solid #929292',
          borderLeft: '1px solid #929292',
          borderRight: '1px solid #929292',
          borderBottom: 'none',
          backgroundColor: '#F5F5F5',
        }
      },

    },
  },
  wrapperAddCell: {
    display: "grid",
    "& div": {
      // flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      "& svg": {
        width: 22,
        height: 21,
        borderRadius: '5px 5px 0px 0px',
        position: "relative",
        top: 6,
        borderTop: "1px dashed #ced4da",
        borderLeft: "1px dashed #ced4da",
        borderRight: "1px dashed #ced4da",
        borderBottom: '1px solid #000000',
        '&:hover': {
          color: '#01A1C7',
          borderTop: '1px solid #929292',
          borderLeft: '1px solid #929292',
          borderRight: '1px solid #929292',
          borderBottom: 'none',
          backgroundColor: '#F5F5F5',
        }
      },

    },
  },
  wrapperTableHeader: {
    '& table': {
      width: '100%',
      height: '100%',
    },
    '& td': {
      textAlign: 'center',
      minWidth: 44,
    },
  },
  cellContainer: {
    margin: '0px 25px',
    display: 'grid',
    gridTemplateColumns: '15px 1fr 15px',
    minHeight: 18,
  },
  editorWrapper: {
    '& div:first-child': {
      height: '100%',
    },
    '& table': {
      height: '100%',
    },
    // '& img': {
    //   maxHeight: 128,
    // },
    height: '100%',
    '& img[alt="img-main"]': {
      height: 100,
      minWidth: 50,
      display: 'block',
      margin: '5px auto',
      objectFit: 'cover',
      objectPosition: 'center',
      // padding: '0px 5px',
      backgroundColor: '#C8C8C8',
    },
    '& p': {
      marginBottom: 0
    },
    '& td': {
      padding: 3.5,
      minWidth: 44,
      border: '1px solid #ced4da',
      height: 31,
      cursor: 'pointer',
      // '&:hover': {
      //   boxShadow: '0 0 10px #01A1C7',
      //   backgroundColor: 'rgba(200, 200, 255, 0.4)',
      // }
    },
    '& .ProseMirror-trailingBreak':{
      display: 'none',
    },
    '& .ProseMirror-separator': {
      display: 'none !important',
    },
    '& .active': {
      backgroundColor: 'rgba(200, 200, 255, 0.4)',
      boxShadow: '0 0 10px #01A1C7',
    }
  },
  editorWrapperVue: {
    '& img[alt="img-main"]': {
      height: 100,
      minWidth: 50,
      display: 'block',
      margin: '5px auto',
      objectFit: 'cover',
      objectPosition: 'center',
      // padding: '0px 5px',
      backgroundColor: '#C8C8C8',
    },
    '& p': {
      marginBottom: 0
    },
    '& td': {
      border: '1px solid black',
      height: 18,
      minWidth: 44,
      padding: 3.5,
    },
    '& .ProseMirror-trailingBreak':{
      display: 'none',
    },
    '& .ProseMirror-separator': {
      display: 'none !important',
    },
    '& .active': {
      backgroundColor: 'rgba(200, 200, 255, 0.4)',
    }
  },
  wrapperRemove: {
    display: "flex",
    "& div": {
      flexGrow: 1,
      textAlign: 'center',
      "& svg": {
        width: 22,
        height: 21,
        position: "relative",
        top: -1.5,
        borderRadius: '0px 0px 5px 5px',
        borderBottom: "1px dashed #ced4da",
        borderLeft: "1px dashed #ced4da",
        borderRight: "1px dashed #ced4da",
        color: "red",
        '&:hover': {
          color: '#01A1C7',
          borderTop: 'none',
          borderBottom: '1px solid #929292',
          borderLeft: '1px solid #929292',
          borderRight: '1px solid #929292',
          backgroundColor: '#F5F5F5',
        }
      }

    },
    // "& div:last-child": {
    //   display: "flex",
    //   justifyContent: "end"
    // }
  },
  icon: {
    cursor: "pointer"
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "25px 15px  1fr 15px 25px"
  },
  wrapperRowIcon: {
    display: "flex",
    flexDirection: "column"

  },
  wrapperIcon: {
    display: "flex",
    // flex: "1 1 100%",
    alignItems: 'center'
  },
  rowIconAdd: {
    width: 22,
    height: 21,
    borderTop: "1px dashed #ced4da",
    borderLeft: "1px dashed #ced4da",
    borderBottom: "1px dashed #ced4da",
    cursor: "pointer",
    marginLeft: 'auto',
    borderRadius: '5px 0px 0px 5px',
    position: 'relative',
    left: 0.5,
    '&:hover': {
      color: '#01A1C7',
      borderRight: 'none',
      borderTop: '1px solid #929292',
      borderLeft: '1px solid #929292',
      borderBottom: '1px solid #929292',
      backgroundColor: '#F5F5F5',
    }
  },
  rowIconAddRemove: {
    width: 22,
    height: 21,
    borderRadius: '0px 5px 5px 0px',
    position: "relative",
    borderTop: "1px dashed #ced4da",
    borderRight: "1px dashed #ced4da",
    borderBottom: "1px dashed #ced4da",
    borderLeft: '1px solid #000000',
    cursor: "pointer",
    marginRight: 'auto',
    right: 1,
    '&:hover': {
      color: '#01A1C7',
      borderLeft: 'none',
      borderTop: '1px solid #929292',
      borderRight: '1px solid #929292',
      borderBottom: '1px solid #929292',
      backgroundColor: '#F5F5F5',
    }
  },
  wrapperFrazeImg: {
    height: 12
  },
  afterAddCell:{
    '& div': {
      borderTop: 'none',
      border: '1px solid black',
      // backgroundColor: '#F7F7F7',
    },
    "& div:last-child": {
      borderBottom: 'none',
    },

  },
  border: {
    border: '1px solid black',
  },
  paddingX: {
    padding: '0px 40px'
  },
  interest: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 500,
  },
  wrapperTableIcons: {
    width: '100% !important',
    height: '100%',
    '& td': {
      minWidth: 44,
    }
  }
}));

export default  styleTableEditor