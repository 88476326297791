export const tableOfContents = {
  class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientTableOfContentsBlockSettingsTemplate',
  identifier: 'toc',
  label: 'Innehållsförteckning',
  scope: 'PUBLICATION',
  includingAllLanguages: false,
  includePrecedingParagraphs: false,
  marginTop: {
    value: 0,
    unit: 'MILLIMETER'
  },
  marginBottom: {
    value: 0,
    unit: 'MILLIMETER'
  },
  marginLeft: {
    value: 0,
    unit: 'MILLIMETER'
  },
  marginRight: {
    value: 0,
    unit: 'MILLIMETER'
  },
  oddBackgroundColor: null,
  evenBackgroundColor: null,
  spanAll: true,
  breakBefore: 'AUTO',
  breakAfter: 'AUTO',
  heading: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientTableOfContentsBlockSettingsTemplate$TOCHeading',
    visible: false,
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    spaceAfter: {
      name: 'after',
      conditionality: 'DISCARD',
      value: {
        value: 0,
        unit: 'POINTS'
      },
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    breakBefore: 'AUTO',
    breakAfter: 'AUTO',
    content: {
      name: 'content',
      type: 'TEXT',
      text: {
        defaultLabel: 'Table of Contents',
        locales: ['en', 'en_US', 'en_GB', 'sv', 'sv_SE'],
        translations: [
          'Table of Contents',
          'Table of Contents',
          'Table of Contents',
          'Innehållsförteckning',
          'Innehållsförteckning'
        ]
      },
      untranslatableText: '',
      include: ''
    },
    marginLeft: {
      value: 0,
      unit: 'MILLIMETER'
    }
  },
  paragraphFormats: [
    {
      identifier: 'section-level1-standard',
      label: null,
      columns: [
        {
          width: {
            value: 12,
            unit: 'POINTS'
          },
          content: 'PARAGRAPH_TEXT',
          userContent: null,
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        },
        {
          width: {
            value: 10,
            unit: 'MILLIMETER'
          },
          content: 'PAGE_LAYOUT_AND_PAGE_NUMBER',
          userContent: null,
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        }
      ]
    },
    {
      identifier: 'section-level1-numbered',
      label: null,
      columns: [
        {
          width: null,
          content: 'PARAGRAPH_NUMBER_AND_TEXT',
          userContent: null,
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        },
        {
          width: {
            value: 10,
            unit: 'MILLIMETER'
          },
          content: 'PAGE_LAYOUT_AND_PAGE_NUMBER',
          userContent: null,
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        }
      ]
    },
    {
      identifier: 'section-level2-standard',
      label: null,
      columns: [
        {
          width: {
            value: 4,
            unit: 'MILLIMETER'
          },
          content: 'USER_DEFINED',
          userContent: '',
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        },
        {
          width: null,
          content: 'PARAGRAPH_TEXT',
          userContent: null,
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        },
        {
          width: {
            value: 10,
            unit: 'MILLIMETER'
          },
          content: 'PAGE_LAYOUT_AND_PAGE_NUMBER',
          userContent: null,
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        }
      ]
    },
    {
      identifier: 'section-level2-numbered',
      label: null,
      columns: [
        {
          width: {
            value: 4,
            unit: 'MILLIMETER'
          },
          content: 'USER_DEFINED',
          userContent: '',
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        },
        {
          width: null,
          content: 'PARAGRAPH_NUMBER_AND_TEXT',
          userContent: null,
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        },
        {
          width: {
            value: 10,
            unit: 'MILLIMETER'
          },
          content: 'PAGE_LAYOUT_AND_PAGE_NUMBER',
          userContent: null,
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        }
      ]
    },
    {
      identifier: 'section-level3-standard',
      label: null,
      columns: [
        {
          width: {
            value: 8,
            unit: 'MILLIMETER'
          },
          content: 'USER_DEFINED',
          userContent: '',
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        },
        {
          width: null,
          content: 'PARAGRAPH_TEXT',
          userContent: null,
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        },
        {
          width: {
            value: 10,
            unit: 'MILLIMETER'
          },
          content: 'PAGE_LAYOUT_AND_PAGE_NUMBER',
          userContent: null,
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        }
      ]
    },
    {
      identifier: 'section-level3-numbered',
      label: null,
      columns: [
        {
          width: {
            value: 8,
            unit: 'MILLIMETER'
          },
          content: 'USER_DEFINED',
          userContent: '',
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        },
        {
          width: null,
          content: 'PARAGRAPH_NUMBER_AND_TEXT',
          userContent: null,
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        },
        {
          width: {
            value: 10,
            unit: 'MILLIMETER'
          },
          content: 'PAGE_LAYOUT_AND_PAGE_NUMBER',
          userContent: null,
          align: 'LEFT',
          alignLast: 'LEFT',
          verticalAlign: 'TOP',
          fillBefore: 'NONE',
          fillAfter: 'NONE',
          fontFamily: 'zert-category-heading',
          fontSize: {
            value: 10,
            unit: 'POINTS'
          },
          fontStyle: [],
          color: null,
          backgroundColor: null,
          spaceLeft: {
            value: 0,
            unit: 'POINTS'
          },
          spaceRight: {
            value: 0,
            unit: 'POINTS'
          },
          spaceTop: {
            value: 0,
            unit: 'POINTS'
          },
          spaceBottom: {
            value: 0,
            unit: 'POINTS'
          },
          lineHeight: {
            class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
            tagName: 'line-height',
            resetValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            defaultValue: {
              class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
              type: 'DEFAULT',
              value: {
                value: 12,
                unit: 'POINTS'
              }
            },
            valueCount: 0
          }
        }
      ]
    }
  ],
  useUserSetting: false,
  userSettingXSL: '',
  separatedBlock: false
};
