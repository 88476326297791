import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  checkboxLabel: {
    fontSize: 15
  },
  checkboxRoot: {},
}))

const CheckBoxItem = ({label, checked, setChecked, name }) => {
  const cl = useStyles();
  const handleChangeCheckbox = (e) => {
    setChecked(e.target.checked, name)
  }

  return(<>
    <FormControlLabel
      onChange={(e) => handleChangeCheckbox(e)}
      control={
        <Checkbox
          checked={checked}
          className={cl.checkboxRoot}
        />
      }
      label={<span
        className={cl.checkboxLabel}>
        {label}
    </span>}
    />
  </>)
}

export default CheckBoxItem