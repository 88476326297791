import { FormattedMessage } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import createStyles from '@mui/styles/createStyles';
import { ConfirmPasswordField } from '../shared/FormElements/ConfirmPasswordField.new';

const defaultFunc = () => {};
export const formStyle = (theme) =>
  createStyles({
    DialogContent: {
      display: 'flex',
      width: '350px',
      height: '100px',
      '& > *': {
        marginTop: 10
      }
    }
  });
const useStyles = makeStyles(formStyle);
function ConfirmPasswordDialog({
  onConfirm = defaultFunc,
  onCancel = defaultFunc,
  unmountComponent,
  contextText = <FormattedMessage id="ConfirmationDialog.Text" defaultMessage="Are you sure?" />,
  titleText = <FormattedMessage id="ConfirmationDialog.Title" defaultMessage="Confirmation" />,
  confirmButtonText = <FormattedMessage id="ConfirmationDialog.Confirm" defaultMessage="Confirm" />,
  cancelButtonText = <FormattedMessage id="ConfirmationDialog.Cancel" defaultMessage="Cancel" />
}) {
  const withUnmountComponent = (callback) => () => {
    unmountComponent();
    if (callback) callback();
  };
  const [passwordApproved, setPasswordApproved] = useState(false);

  const classes = useStyles();
  return (
    <Dialog open onClose={withUnmountComponent(onCancel)}>
      <DialogTitle>{titleText}</DialogTitle>
      <DialogContent className={classes.DialogContent}>
        <ConfirmPasswordField passwordApproved={passwordApproved} setPasswordApproved={setPasswordApproved} />
      </DialogContent>
      <DialogActions>
        {passwordApproved && (
          <Button onClick={withUnmountComponent(onConfirm)} color="primary" variant="outlined">
            {confirmButtonText}
          </Button>
        )}
        {cancelButtonText && (
          <Button onClick={withUnmountComponent(onCancel)} color="primary">
            {cancelButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmPasswordDialog;
