import React, { useEffect, useRef, useState } from 'react';
import { getTaskMetaData, getWorkflowTemplateAPI } from '@zert-packages/actions/actionplan';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import LoadingTile from '../tiles/LoadingTile';
import TileError from '../tiles/TileError';
import { workflowIdKey } from './ELEMENT_TILE_CONTS';

const checkIsTask = (mimeType) =>
  [
    'application/zert-project-document',
    'application/zert-safety-inspection',
    'application/zert-deviation',
    'application/zert-measure',
    'application/zert-review-job',
    'application/zert-translation-job'
  ].includes(mimeType);

const useStyles = makeStyles({
  root: {
    height: '86px',
    maxHeight: '86px',
    position: 'relative'
  },
  rootShowing: {
    padding: '0.25em 0',
    backgroundColor: '#FBFCFD',
    '&:first-child': {
      padding: '0.5em 0 0 0'
    },
    '&:nth-child(even)': {
      backgroundColor: '#f4f8fa'
    },
    '&:first-last': {
      padding: '0 0 0.5em 0'
    }
  }
});

const observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
};

const WithTileDelay = (WrappedComponent) =>
  function (WrappedComponentProps) {
    function InnerComponent(props) {
      const cl = useStyles();
      const replacerRef = useRef();
      const previousVersionId = useRef(null);
      const [workflowTemplate, setWorkflowTemplate] = useState(null);
      const [element, setElement] = useState(null);
      const [canMount, setCanMount] = useState(false);
      const [error, setError] = useState(null);
      const isTask = checkIsTask(props.element.mimeType);

      const [canShowElement, setCanShowElement] = useState(false);

      useEffect(() => {
        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(replacerRef.current);
        return () => observer.disconnect();
      }, []);

      const observerCallback = ([entry]) => {
        if (entry.intersectionRatio > 0.1) {
          setCanMount(true);
        }
      };

      useEffect(() => {
        if (!canMount) return;
        if (element === null && props.element && props.element.versionId) handleGetTaskMetaData();
        else setCanShowElement(true);
      }, [canMount]);

      const handleGetTaskMetaData = () =>
        getTaskMetaData(props.element.versionId)
          .then(fetchWorkflowTemplate)
          .catch((e) => setError(e));

      const fetchWorkflowTemplate = (element) => {
        if (isTask) {
          const workflowTemplateId = _.get(element, `properties[${workflowIdKey}]`, null);

          if (workflowTemplateId === null) {
            setElement(element);
            setCanShowElement(true);
            return;
          }

          getWorkflowTemplateAPI(workflowTemplateId)
            .then((r) => {
              setWorkflowTemplate(r);
              setElement(element);
              setCanShowElement(true);
            })
            .catch((e) => setError(e));
        } else {
          setElement(element);
          /*    props.element.properties =_.get(element, 'properties', props.element.properties);
        props.element.metadataList =_.get(element, 'parents', props.element.parents);
        props.element.parents =_.get(element, 'parents', props.element.parents); */

          setCanShowElement(true);
        }
      };

      if (error) return <TileError error={error} versionId={props.element.versionId} />;
      if (previousVersionId.current === null) {
        previousVersionId.current = props.element.id;
      }
      /* if (canShowElement) {
      console.log(_.get(element, 'properties', props.element.properties));
    } */

      return (
        <div key={props.element.versionId} className={canShowElement ? cl.rootShowing : cl.root}>
          {canShowElement ? (
            <WrappedComponent
              {...props}
              element={{
                ...props.element,
                properties: _.has(element, 'properties')
                  ? _.merge(_.get(element, 'properties'), props.element.properties)
                  : _.get(element, 'properties', props.element.properties),
                metadataList: _.get(element, 'metadataList', props.element.metadataList),
                parents: _.get(element, 'parents', props.element.parents)
              }}
              setElement={setElement}
              workflowTemplate={workflowTemplate}
              workflowTemplateId={workflowTemplate ? workflowTemplate.versionId : null}
              isTask={isTask}
            />
          ) : (
            <LoadingTile ref={replacerRef} />
          )}
        </div>
      );
    }

    return <InnerComponent {...WrappedComponentProps} />;
  };

export default WithTileDelay;
