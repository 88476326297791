import React, { useCallback, useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Accordion, AccordionDetails, AccordionSummary, Box, ListItem, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Spacer from '@zert-packages/components/shared/Spacer';
import { useViewport } from '@zert-packages/utils/ViewportProvider';
import { loadCurrentTerm } from './API';
import { turnOffCLMLoader, turnOnCLMLoader } from '../TranslationJobPlugin/tjReducers';
import ReferenceView from './ReferenceView';
import AddReferenceButtons from './AddReferenceButtons';

const useStyles = makeStyles((theme) => ({
  summary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    margin: '6px',
    '& > *': {
      marginRight: '1em'
    }
  },
  actionsMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '6px',
    '& > *': {
      marginTop: '1em'
    }
  },
  heading: {
    color: '#c46e78'
  }
}));

function TermAccordion({ term, idx, versionId, scrollToItem, handleScrollToItem, versionManager }) {
  const cl = useStyles();
  const dispatch = useDispatch();
  const ref = useRef(null);

  const [expanded, setExpanded] = useState(idx === 0);
  const [termData, setTermData] = useState(null);
  const [error, setError] = useState(null);

  const { isMobile } = useViewport();

  const handleExpand = (e, isExpanded) => {
    e.preventDefault();
    e.stopPropagation();

    setExpanded(isExpanded);
    handleScrollToItem(e, term);
  };

  const addData = (e, isExpanded) => {};

  const getTermData = useCallback(async () => {
    if (expanded && !termData) {
      dispatch(turnOnCLMLoader());

      const result = await loadCurrentTerm(versionId, term.id);

      setTermData(result);
      dispatch(turnOffCLMLoader());
    }
  }, [expanded, termData]);

  useEffect(() => {
    getTermData();
  }, [getTermData]);

  useEffect(() => {
    if (scrollToItem === term.id && ref.current) {
      ref.current.scrollIntoView();
      setExpanded(true);
    }
  }, [scrollToItem, ref]);

  return (
    <Accordion elevation={term.id == scrollToItem ? 3 : 0} expanded={expanded} onChange={handleExpand} ref={ref}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h4" className={cl.heading}>
          {term.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <div className={isMobile ? cl.actionsMobile : cl.actions}>
          {!versionManager.elementDisabled && (
            <AddReferenceButtons versionId={versionId} termId={term.id} setTermData={setTermData} isImage />
          )}
          {!versionManager.elementDisabled && (
            <AddReferenceButtons versionId={versionId} termId={term.id} setTermData={setTermData} />
          )}
        </div>
        {error && (
          <Typography variant="subtitle2" style={{ color: 'red' }}>
            {error}
          </Typography>
        )}
        <Box flexDirection="column">
          {termData &&
            termData.references
              .filter((item) => item != null && item.left != null)
              .map((item, idx) => (
                <ReferenceView
                  reference={item}
                  key={item.right.id + item.left + idx}
                  versionId={versionId}
                  setTermData={setTermData}
                  termId={term.id}
                />
              ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default TermAccordion;
