import { useCallback, useEffect, useRef, useState } from 'react';

const useMouseEvents = (callbacks) => {
  const {
    onElementClick,
    onElementDoubleClick,
    onElementCtrlClick,
    onElementShiftClick,
    handleLongTouch,
    handleSingleTouch
  } = callbacks;

  const [longPressTriggered, setLongPressTriggered] = useState(false);

  const timeout = useRef();
  const target = useRef();
  const isScrolling = useRef(false);
  const scrollTimeout = useRef();

  const handleClick = (event, element, elements) => {
    event.preventDefault();

    if (event.metaKey || event.ctrlKey) {
      // Ctrl/Meta/Command + Click
      onElementCtrlClick(element);
    } else if (event.shiftKey) {
      // Shift + Click
      onElementShiftClick(element, elements);
    } else {
      // Single Click
      onElementClick(element);
    }
  };

  const handleDoubleClick = (event, element) => {
    event.preventDefault();
    onElementDoubleClick(element); // Double click callback
  };

  const handleTouchStart = (event, element) => {
    if (isScrolling.current) return;
    start(event, element);
  };

  const handleTouchEnd = (event, element) => {
    if (isScrolling.current) return;
    event.preventDefault();
    clear(event, element);
  };

  const start = useCallback(
    (event, element) => {
      if (isScrolling.current) return;
      if (event.target) {
        event.target.addEventListener('touchend', null, { passive: false });
        target.current = event.target;
      }
      timeout.current = setTimeout(() => {
        if (isScrolling.current) return;
        handleLongTouch(element);
        setLongPressTriggered(true);
      }, 500);
    },
    [handleLongTouch]
  );

  const clear = useCallback(
    (event, element, shouldTriggerClick = true) => {
      if (isScrolling.current) return;
      timeout.current && clearTimeout(timeout.current);
      shouldTriggerClick && !longPressTriggered && handleSingleTouch(element);
      setLongPressTriggered(false);
      if (event.target.current) {
        event.target.current.removeEventListener('touchend', null);
      }
    },
    [handleSingleTouch, longPressTriggered]
  );

  useEffect(() => {
    const handleScrollStart = () => {
      isScrolling.current = true;
    };

    const handleScrollEnd = () => {
      clearTimeout(scrollTimeout.current);
      setTimeout(() => {
        isScrolling.current = false;
      }, 500);
    };

    window.addEventListener('touchmove', handleScrollStart, { passive: true });
    window.addEventListener('touchend', handleScrollEnd, { passive: true });

    return () => {
      clearTimeout(scrollTimeout.current);
      window.removeEventListener('touchmove', handleScrollStart);
      window.removeEventListener('touchend', handleScrollEnd);
    };
  }, []);

  return {
    handleClick,
    onElementClick,
    onElementCtrlClick,
    onElementShiftClick,
    handleDoubleClick,
    onElementDoubleClick,
    handleTouchStart,
    handleTouchEnd,
    handleLongTouch,
    handleSingleTouch
  };
};

export default useMouseEvents;
