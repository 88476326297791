import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  wrapperBlockItems: {
    display: 'grid',
    border: '1px solid rgba(145, 145, 145, 0.25)',
    gridTemplateColumns: '117px 1fr',
    minHeight: 42
  },
  rootAbstract: {
    display: 'grid'
  },
  rootAbstractSelect: {
    display: 'grid',
    backgroundColor: '#F4F8FA',
    position: 'sticky',
    top: 0,
    zIndex: 10
  },
  wrapperInfo: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  wrapperAbstract: {
    paddingLeft: 0,
    display: 'flex',
    alignItems: 'center',
    marginTop: 2,
    fontSize: '0.9rem',
    lineHeight: '0.9rem',
    height: 16,
    padding: '2px 0',
    '& > *': {
      marginRight: 10
    },
    '& > *:nth-child(2)': {
      marginRight: 0
    }

    // '& span': {
    //   background: '#ffffff !important',
    //   backgroundColor: '#ffffff !important'
    // },
  },
  titleParagraph: (props) => ({
    color: '#01A1C7',
    cursor: props.blockClick ? 'not-allowed' : 'pointer',
    // backgroundColor: '#F4F8FA',
    // paddingLeft: 45,
    // paddingBottom: 5,
    width: 'fit-content',
    '&:hover': {
      textDecoration: props.blockClick ? '' : 'underline'
    }
  }),

  borderLeft: {
    width: 6,
    backgroundColor: '#80CFE2',
    height: '100%',
    marginLeft: 1
  },
  wrapperFraze: {
    cursor: 'pointer',
    backgroundColor: '#F8F8F8',
    fontSize: 10,
    '& p': {
      margin: 0
    }
  },
  wrapperLogo: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapperLogoRevert: {
    display: 'block',
    textAlign: 'center',
    marginTop: 10,
    '& svg': {
      margin: '0 auto'
    }
  },
  revertImg: {
    display: 'block'
  },
  wrapperText: {
    padding: 5,
    paddingRight: 2
  },
  wrapperFrazeImg: {
    height: 12
  },
  iconDone: {
    display: 'block',
    width: '11px',
    height: 14,
    color: '#91BD6B'
  },
  wrapperIcon: {
    display: 'grid',
    gridTemplateColumns: '6px 1fr',
    gridColumnGap: 5,
    height: '100%'
  },
  wrapperLogoText: {
    fontSize: 10,
    color: '#919191',
    lineHeight: '10px'
  },
  partText: (props) => ({
    position: 'relative',
    cursor: props.blockClick ? 'not-allowed' : 'pointer',
    width: 'fit-content',
    '&:hover': {
      textDecoration: props.blockClick ? '' : 'underline'
    }
  }),
  wrapperOpenIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  labelHeaderIcon: {
    fontSize: 15
  },
  wrapper: {
    display: 'grid'
  },
  addIcon: {
    color: '#E5E5E5',
    cursor: 'pointer',
    fontSize: '25px',
    '&:hover': {
      color: '#01A1C7'
    }
  },
  TreeRootPopup: {
    minWidth: 317,
    minHeight: 100,
    marginLeft: 0,
    marginTop: 7,
    padding: 5,
    border: '1px solid lightgray',
    boxShadow: 'none',
    fontSize: 12,
    lineHeight: '12px',
    maxHeight: 400
  }
}));
export default useStyles;
