import React, { memo } from 'react';
import { Tab, Tabs, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import { DEMANDS_TABS_LABELS } from '../CONSTS';

const useStyles = makeStyles({
  Tabs: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
  },
  summaryContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gridGap: '20px'
  }
});

export const WithDemandsTabs = memo(
  ({ yourTabs = [], tabIndex, setTabIndex, addButtonAction }) => {
    const cl = useStyles();
    return (
      <div className={addButtonAction ? cl.summaryContainer : null}>
        {addButtonAction && (
          <IconButton onClick={addButtonAction} size="small">
            <AddIcon />
          </IconButton>
        )}
        <Tabs
          className={cl.Tabs}
          textColor="primary"
          color="primary"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons
          value={tabIndex}
          onChange={(e, tabIndex) => setTabIndex(tabIndex)}
          allowScrollButtonsMobile
        >
          {yourTabs.map((tab, index) => (
            <Tab key={index} label={tab} />
          ))}
          {DEMANDS_TABS_LABELS.map((demand) => (
            <Tab key={demand.key} label={demand.label} />
          ))}
        </Tabs>
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.tabIndex === nextProps.tabIndex
);
