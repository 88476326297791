import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';

import { PurposePage } from './PurposePage';
import PublishingFilterPaper from '../../PreviewPlugin/components/PublishingFilterPaper';
import LayoutSettingsPaper from '../../PreviewPlugin/components/LayoutSettingsPaper';
import PublishingSettingSummaryList from '../components/PublishingSettingSummaryList';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190
  }
}));

function SummaryPage() {
  const { selectedLayout, selectedLayoutFilter, layoutSettings, selectedElementToPreview } = useSelector(
    (state) => state.PREVIEW
  );

  const { previewPublishingFilters } = useSelector((state) => state.PREVIEW);
  const { multipleSelectedLanguage, languageSeparated, fallbackLocale } = useSelector((state) => state.PREVIEW);
  const { publishingFilters } = useSelector((state) => state);

  return (
    <form
      style={{
        display: 'flex',
        justifyContent: 'stretch',
        flexFlow: 'column nowrap'
        // backgroundColor: "rgb(224, 224, 224, 1)" //"rgb(220, 239, 246)"
      }}
    >
      <PublishingSettingSummaryList
        multipleSelectedLanguage={multipleSelectedLanguage}
        languageSeparated={languageSeparated}
        fallbackLocale={fallbackLocale}
        selectedElementToPreview={selectedElementToPreview}
        selectedLayoutFilter={selectedLayoutFilter}
        selectedLayout={selectedLayout}
      />

      {selectedLayout &&
        layoutSettings &&
        layoutSettings[selectedLayout] &&
        layoutSettings[selectedLayout].properties.length > 0 && (
          <LayoutSettingsPaper
            layoutVersionId={selectedLayout}
            readOnly
            settings={layoutSettings[selectedLayout].properties}
          />
        )}
      {publishingFilters &&
        publishingFilters.length > 0 &&
        previewPublishingFilters &&
        previewPublishingFilters.length > 0 && (
          <PublishingFilterPaper previewPublishingFilters={previewPublishingFilters} readOnly />
        )}
      <PurposePage readOnly />
    </form>
  );
}

export default SummaryPage;
