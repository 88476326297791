import React, { useEffect, useState } from 'react';
import { handleQuery } from '@zert-packages/actions/api';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import { definePEDClass, noClass } from '@zert-packages/utils/definePEDClass';
import withTile from '../shared/withTile';
import { ElementContentOverviewTile, OverviewTile } from './AbstractTile';

function PEDTileIcon(props) {
  const [PED, setPED] = useState(null);

  useEffect(() => {
    handleQuery(`/ped/load/${props.element.versionId}`).then((result) => {
      setPED({ ...result, classG: definePEDClass(result) });
    });
  }, []);

  return (
    <>
      {PED && (
        <div className="icon-box" style={{ padding: '0px 0.5em 0px 0px', width: '25px' }}>
          <Typography variant="h1" component="h3" style={{ color: 'rgb(23, 151, 199)' }}>
            {PED.classG == noClass ? (
              <FormattedMessage id="PEDView.class.noClassShort" defaultMessage="NC" />
            ) : (
              PED.classG
            )}
          </Typography>
        </div>
      )}
      {!PED && <></>}
    </>
  );
}

export default withTile(PEDTileIcon, OverviewTile, ElementContentOverviewTile);
