import React, {useContext, useState} from 'react'
import Switch from '@mui/material/Switch';
import {makeStyles} from "@mui/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListSelected from "./ListSelected";

const useStyles = makeStyles((theme) => ({

}))

const MetaDataWrapper = () => {
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return <div>
        <FormControlLabel
            control={<Switch
                checked={checked}
                onChange={handleChange}
            />}
            label="Show advanced settings"
        />
        {checked && <div style={{marginTop: 10}}>
            <ListSelected/>
        </div>}
    </div>
}

export default MetaDataWrapper