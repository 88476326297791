import { plugins } from '@zert-packages/plugins/Environment';
import { getPluginByMimeType } from './getPluginByMimeType';

export default function getAllPlugins() {
  return plugins.map((plugin, index) => {
    return {
      id: index,
      title: {
        text: getPluginByMimeType(plugin.mimeType)
      },
      mimetype: plugin.mimeType
    };
  });
}
