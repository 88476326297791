import React, {useState} from 'react'
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import {makeStyles} from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import {Radio, RadioGroup} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useSelector} from "react-redux";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import TextFieldItem from "@zert-packages/components/TextFieldItem";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%", maxWidth: 500, padding: 11, background: "#E2F2F6"
    }, wrapperTitle: {
        marginBottom: 10, display: "flex", justifyContent: "space-between", textTransform: "uppercase", "& svg": {
            cursor: "pointer"
        }
    }, title: {
        fontSize: "1.8rem", fontWeight: 700, textTransform: "capitalize"
    }, row: {
        display: 'grid', gridTemplateColumns: '100px 100px 1fr', gridColumnGap: 10,
    }, wrapperBlock: {
        display: 'flex', flexDirection: 'column', paddingTop: 9,
    }, radioGroup: {
        '& label': {
            marginBottom: 0,
        }
    }, formatRow: {
        minHeight: 21, marginBottom: 14,
    }, wrapperButton: {
        display: "flex", marginTop: 30, justifyContent: "end", gap: 10
    }, button: {
        color: "#ffffff",
        background: "#4db1d3",
        minWidth: 80,
        border: "1px solid #4db1d3",
        outline: "none !important",
        cursor: "pointer",
        "&:hover": {
            background: "none", color: "#212529", border: "1px solid #4db1d3"
        },
        "&:focus": {
            backgroundColor: "#4db1d3"
        }
    },
}))

const AddPropertiesDialog = ({connection, setConnection}) => {
    const cl = useStyles();
    const [open, setOpen] = useState(true);
    const {formatTemplate} = useSelector((state) => state)
    const [properties, setProperties] = useState({
        selected: 0,
        format: null,
        propertiesKey: '',
        propertiesValue: '',
    })
    const disableFormats = +properties.selected === 0


    const handleProperties = (val, name) => {
        setProperties(pre => ({...pre, [name]: val}))
    }

    const handleClose = () => {
        setOpen(false);
    }
    console.log('connection', connection)
    const handleSave = () => {
        let res = {}
        if(properties.format){
            res = {...res, 'zert:Format': properties.format}
        }
        if(properties.propertiesKey.length > 0 && properties.propertiesValue.length > 0){
            res = {...res, [properties.propertiesKey]: properties.propertiesValue}
        }
        setConnection(pre => ({...pre, properties: {...pre.properties, ...res}}))
        handleClose()
    }

    const handleFormat = (val, name) => {
        handleProperties(val.identifier, name)
    }

    
    const findDisabled = () => {
        if(+properties.selected === 0 && !properties.format){
            return true
        }
        if(+properties.selected === 1 && (properties.propertiesKey.length === 0 || properties.propertiesValue.length === 0)
        ){
            return true
        }
      return false
    }



    return (<Dialog
        open={open}
        onClose={handleClose}
        classes={{
            paperScrollPaper: cl.root
        }}
    >
        <div className={cl.wrapperTitle}>
              <span className={cl.title} style={{fontWeight: 700}}>
                add property
              </span>
            <CloseIcon onClick={handleClose}/>
        </div>
        <div className={cl.row}>
            <FormControl className={cl.radioGroup}>
                <RadioGroup
                    value={properties.selected}
                    onChange={(e) => handleProperties(e.target.value, 'selected')}
                >
                    <FormControlLabel
                        value={0}
                        control={<Radio color="primary"/>}
                        label={<span>Formats</span>}
                    />
                    <FormControlLabel
                        value={1}
                        control={<Radio color="primary"/>}
                        label={<span>Property</span>}
                    />
                </RadioGroup>
            </FormControl>
            <div className={cl.wrapperBlock}>
                <div className={cl.formatRow}>Format:</div>
                <TextFieldItem
                    disabled={disableFormats}
                    values={properties.propertiesKey}
                    name={"propertiesKey"}
                    setValues={handleProperties}
                />
            </div>
            <div className={cl.wrapperBlock}>
                <div className={cl.formatRow}>
                    <ExpanderItem
                        disabled={!disableFormats}
                        propsLabel={formatTemplate?.blockFormats?.map(el => ({...el, name: el.comboValue}))}
                        type={'format'}
                        setProps={handleFormat}
                    />
                </div>
                <TextFieldItem
                    disabled={disableFormats}
                    values={properties.propertiesValue}
                    name={"propertiesValue"}
                    setValues={handleProperties}

                />
            </div>
        </div>
        <div className={cl.wrapperButton}>
            <Button
                disabled={findDisabled()}
                className={cl.button}
                onClick={handleSave}
            >
                Ok
            </Button>
            <Button className={cl.button} onClick={handleClose}>
                Cancel
            </Button>
        </div>
    </Dialog>)
}

export default AddPropertiesDialog