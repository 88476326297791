import React, { createRef, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { updateWantToSeeIntro } from '@zert-packages/actions/commonReducer';
import { useDispatch } from 'react-redux';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { finishIntroduction, useIntroduction } from '../IntroductionContext';
import { JoyrideBeaconComponentStart, scrollIntoViewCenter } from '../Introduction';
import TooltipLocale from '../TooltipLocale';
import checkIfStepTargetExist from '../../ElementSearcher/utils/checkIfStepTargetExist';

const id = 'riskAssessment';
const formattedMessages = {
  'IntroRiskAssessment.StepsContainer': (
    <FormattedMessage
      id="IntroRiskAssessment.StepsContainer"
      defaultMessage="The risk assessment is process is divided into these four steps. Click on the step or the Next button to switch between them."
    />
  ),
  'IntroRiskAssessment.ImageArea': (
    <FormattedMessage
      id="IntroRiskAssessment.ImageArea"
      defaultMessage="Click here to add an cover image for your risk assessment. This will then be presented in the risk assessment PDF report cover."
    />
  ),
  'IntroRiskAssessment.Section': (
    <FormattedMessage
      id="IntroRiskAssessment.Section"
      defaultMessage="Click here to add an cover image for your risk assessment. This will then be presented in the risk assessment PDF report cover."
    />
  ),
  'IntroRiskAssessment.TabList': (
    <FormattedMessage
      id="IntroRiskAssessment.TabList"
      defaultMessage="Here you can add participants, specify which person that participated in each session, add basis for your risk assessment, write a description on what the assessment covers and also read your company policy if there is one avaliable."
    />
  ),
  'IntroRiskAssessment.PDFReport': (
    <FormattedMessage
      id="IntroRiskAssessment.PDFReport"
      defaultMessage="Here you can access different PDF reports that can be generated from your risk assessment."
    />
  ),
  'IntroRiskAssessment.RootNodeRename': (
    <FormattedMessage
      id="IntroRiskAssessment.RootNodeRename"
      defaultMessage="Click on the heading if you want to rename the section. "
    />
  ),
  'IntroRiskAssessment.AddDescription': (
    <FormattedMessage
      id="IntroRiskAssessment.AddDescription"
      defaultMessage="Here you can add a description on what you are risk assessing."
    />
  ),
  'IntroRiskAssessment.AddRisks': (
    <FormattedMessage
      id="IntroRiskAssessment.AddRisks"
      defaultMessage="Click here to add risks and start your risk assessment"
    />
  )
};

function RiskAssessmentIntro(wantToSeeIntro) {
  const [run, setRun] = useState(false);
  const { startIntro } = useIntroduction(id, setRun);
  const dispatch = useDispatch();
  const [stepIndex, setStepIndex] = useState(0);
  const addRisksButtonRef = createRef();
  const steps = [
    {
      isFixed: true,
      target: '[data-intro-id="RiskAssessment.StepsContainer"]',
      content: formattedMessages['IntroRiskAssessment.StepsContainer']
    },
    {
      target: '[data-intro-id="RiskAssessment.ImageArea"]',
      content: formattedMessages['IntroRiskAssessment.ImageArea'],
      placement: 'auto'
    },
    {
      target: '[data-intro-id="RiskAssessment.Section"]',
      content: formattedMessages.IntroRiskAssessmentSection,
      placement: 'auto'
    },
    {
      target: '[data-intro-id="RiskAssessment.TabList"]',
      content: formattedMessages['IntroRiskAssessment.TabList'],
      placement: 'auto'
    },
    {
      target: '[data-intro-id="RiskAssessment.PDFReport"]',
      content: formattedMessages['IntroRiskAssessment.PDFReport'],
      placement: 'auto'
    },
    {
      target: '[data-intro-id="RiskAssessment.RootNodeRename"]',
      content: formattedMessages['IntroRiskAssessment.RootNodeRename'],
      placement: 'auto'
    },
    {
      target: '[data-intro-id="RiskAssessment.AddDescription"]',
      content: formattedMessages['IntroRiskAssessment.AddDescription'],
      placement: 'auto'
    },
    {
      target: '[data-intro-id="RiskAssessment.AddRisks"]',
      content: formattedMessages['IntroRiskAssessment.AddRisks'],
      elementRef: 'addRisksButtonRef',
      spotlightPadding: 10,
      scrollPosition: 'center'
    }
  ];

  useEffect(() => {
    if (wantToSeeIntro && !run) {
      setRun(true);
      setStepIndex(0);
    }
  }, [wantToSeeIntro]);

  const handleJoyrideCallback = (data) => {
    const { status, step, action, type, index } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (!checkIfStepTargetExist(step)) {
      setRun(false);
      dispatch(updateWantToSeeIntro(false));
      finishIntroduction(id);
      return;
    }
    if (finishedStatuses.includes(status)) {
      dispatch(updateWantToSeeIntro(false));
      finishIntroduction(id);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const newStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setRun(false);
      setStepIndex(newStepIndex);
      setTimeout(() => {
        setRun(true);
      }, 400);
    }
    if (step.elementRef === 'addRisksButtonRef' && addRisksButtonRef.current) {
      scrollIntoViewCenter(addRisksButtonRef.current, 160);
    }
  };
  return {
    component: (
      <Joyride
        locale={TooltipLocale}
        callback={handleJoyrideCallback}
        beaconComponent={JoyrideBeaconComponentStart}
        continuous
        run={run}
        steps={steps}
        showProgress
        stepIndex={stepIndex}
        showSkipButton
        styles={{
          overlay: {
            position: 'fixed'
          },
          options: {
            primaryColor: '#01A1C7'
          }
        }}
      />
    ),
    addRisksButtonRef,
    startIntro
  };
}

export default RiskAssessmentIntro;
