import React, { useContext, useEffect } from "react";
import TabsWithVersionController from "@zert-packages/components/common/TabsWithVersionController";
import { generatePreviewReport } from "../PreviewPlugin/servicesMiddlewares";
import { generateTermDBExport } from "../SoftwareTextPlugin/serviceMiddleware";
import useVersionManager from "@zert-packages/utils/useVersionManager";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { createInProgressSnack } from "@zert-packages/components/common/snackBar";
import { useSnackbar } from "notistack";
import { getVersionInfo } from "@zert-packages/actions/coreReducers";
import { LayoutContext } from "./LayoutView";
import { storeLayout } from "./API";

const LayoutWrapper = ({children}) => {
  const [versionManager, versionInfo] = useVersionManager();
  const { formatTemplate } = useSelector((state) => state);
  const { versionId } = useParams();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const {values} = useContext(LayoutContext)




  const handleLayout = async (type) => {
    if (type === "save") {
      const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..." />
      );
      const res = await storeLayout(versionId, values)

      snackbar.closeSnackbar(generatingSnackbar);
    }
  }

  const ButtonList = () => {
    return [
      {
        type: "Save",
        label: <span onClick={() => handleLayout("save")}><FormattedMessage id="ElementTile.Actions.Save"
                                                                            defaultMessage="Save" /></span>
      }
    ];
  };


  useEffect(() => {
    dispatch(getVersionInfo(versionId));
  }, [versionId])

  return (<div>
    <TabsWithVersionController
      canCreateVersion={versionManager.canCreateNewVersion}
      canSaveVersion={false} // versionManager.canSaveVersion}
      canApproveVersion={versionManager.canApproveVersion}
      onCreateNewVersion={versionManager.createNewVersion}
      onApproveVersion={versionManager.approveVersion}
      onSaveVersion={() => false}
      canPreview
      onPreview={() => {
        dispatch(generatePreviewReport(versionInfo.info, -1));
      }}
      canExport
      onExport={() => {
        dispatch(generateTermDBExport(versionId));
      }}
      tabs={ButtonList()}
    />
    {children}
  </div>);
}

export default LayoutWrapper