import React from 'react'
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tooltipRoot: {
    background: "#ffffff !important",
    color: "#000000",
    border: "1px solid #000000",
    marginTop: 5,
    fontSize: 10,
  },
}))

const TooltipCustom = ({children, title='' }) => {
  const cl = useStyles();
  return(<Tooltip
    title={title}
    classes={{   tooltip: cl.tooltipRoot }}
  >
    {children}
  </Tooltip>)
}


export default TooltipCustom