const makeValidDemandProjectRA = ({
  versionId,
  demands,
  name,
  description,
  comment,
  documentStatus,
  templateInfo,
  coverage,
  document,
  referencedElement,
  ownerNodeUUID
}) => ({
  versionId,
  demands,
  name,
  description,
  comment,
  documentStatus,
  templateInfo,
  coverage,
  document,
  referencedElement,
  ownerNodeUUID
});

export default makeValidDemandProjectRA;
