import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormattedMessage } from 'react-intl';
import RichTextEditor from '@zert-packages/components/shared/RichTextEdit/RichTextEditor';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { formStyle } from '@zert-packages/components/shared/FormElements/StylesHelper';
import SaveActions from '@zert-packages/plugins/MailSettingsPlugin/common/CancelSavePanel';
import { storeCompany, loadCompany } from '@zert-packages/actions/coreReducers';

import { useDispatch, useSelector } from 'react-redux';
import { stateToHTML } from 'draft-js-export-html';
import Loader, { LoaderAction } from "@zert-packages/components/shared/Loader/Loader";
import Box from '@mui/material/Box';
import { SettingsContext } from './ContextProvider';
import { Button } from "@mui/material";
import { handleQuery } from "@zert-packages/actions/api";

const useStyles = makeStyles(formStyle);

const useForceUpdate = () => {
  const [, setIt] = useState(false);
  return () => setIt((it) => !it);
};

export const CompanyTab = forwardRef(({ companyId, intl, backFunction }, ref) => {
  const [policy, setPolicy] = useState('');
  const [policyLoaded, setPolicyLoaded] = useState(false);
  const [generatingToken, setGeneratingToken] = useState(false);
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const { company } = useSelector((state) => state);
  useImperativeHandle(ref, () => ({
    store() {
      async function storeCompanyAsync() {
        setStoreCompany(true);
        const result = await storeCompany(company);
        setStoreCompany(false);
      }

      storeCompanyAsync();
    }
  }));

  const generateToken = () => {
    setGeneratingToken(true);
    handleQuery(
      '/admin/startScimTokenGeneration?companyId='+companyId
    ).finally(() => setGeneratingToken(false));
  }

  useEffect(() => {
    if (!company || companyId !== company.info.id) {
      dispatch(loadCompany(companyId));
    }
    if (company && companyId === company.info.id) {
      setValue({
        uppercasePassword: company.uppercasePassword,
        numericPassword: company.numericPassword,
        specialCharPassword: company.specialCharPassword,
        passwordLength: company.passwordLength,
        licenseNumber: company.licenseNumber,
        requiredExpirationDate: company.requiredExpirationDate,
        name: company.name,
        provisioningEnabled: company.provisioningEnabled,
        provisioningUser: company.provisioningUser,
        provisioningResponsibleUser: company.provisioningResponsibleUser,
        emailNewADUsers: company.emailNewADUsers,
        maxUsers: company.maxUsers,
        companyDomain: company.companyDomain,
      });
      setPolicy(company.policy);
      setPolicyLoaded(true);
      setActive(company.active);
      setWorkingDays(company.workingDays);
      setAccountExpires(company.accountExpires);
      setIsSomethingChanged(false);
    }
  }, [company]);
  const [value, setValue] = useState({
    uppercasePassword: true,
    numericPassword: true,
    specialCharPassword: true,
    passwordLength: true,
    name: '',
    provisioningEnabled: false,
    provisioningUser: '',
    provisioningResponsibleUser: '',
    companyDomain: '',
    licenseNumber: '',
    emailNewADUsers: false,
    maxUsers: undefined,
    requiredExpirationDate: true
  });

  const [accountExpires, setAccountExpires] = useState();
  const [active, setActive] = useState(false);
  const [workingDays, setWorkingDays] = useState([1, 2, 3, 4, 5, 6, 7]);
  const { handleSave, handleCancel, errors, setIsSomethingChanged, setStoreCompany } = useContext(SettingsContext);

  const classes = useStyles();

  const onChangePolicy = (editorState) => {
    setPolicy(editorState);
    const html = stateToHTML(editorState.getCurrentContent());
    company.policy = html;
    setIsSomethingChanged(true);
  };

  const handleChange = (name) => (event) => {
    setValue({ ...value, [name]: event.target.value });
    company[name] = event.target.value;
    setIsSomethingChanged(true);
  };
  const handleDateChange = (selectedDate) => {
    setAccountExpires(selectedDate);
    company.accountExpires = selectedDate;
    setIsSomethingChanged(true);
  };

  const handleActive = (name) => (event) => {
    setActive(event.target.checked);
    company[name] = event.target.checked;
    setIsSomethingChanged(true);
  };

  const handleCheckBoxChange = (name) => (event) => {
    setValue({ ...value, [name]: event.target.checked });
    company[name] = event.target.checked;
    setIsSomethingChanged(true);
  };

  const handleWorkingCheckBox = (name) => (event) => {
    if (workingDays.includes(name)) {
      const index = workingDays.indexOf(name);
      workingDays.splice(index, 1);
      setWorkingDays(workingDays);
    } else {
      workingDays.push(name);
      setWorkingDays(workingDays);
    }
    setIsSomethingChanged(true);
    forceUpdate();
    //    this.forceUpdate();
  };

  const onPolicyBlur = (editorState) => {
    setPolicy(policy);
    company.policy = stateToHTML(editorState.getCurrentContent());
    setIsSomethingChanged(true);
  };

  const clickSave = (e) => {
    handleSave();
    backFunction();
  };

  const clickCancel = (e) => {
    handleCancel();
    backFunction();
  };

  if (company == null || companyId !== company.info.id) {
    return <Loader />;
  }

  return (
    <div className="question-block" style={{ margin: '20px' }}>
      <TextField
        id="name"
        label={<FormattedMessage id="settings.ce.caption.fullname" defaultMessage="Company name" />}
        className={classes.formControl}
        value={value.name}
        onChange={handleChange('name')}
        type="text"
        fullWidth
        variant="outlined"
      />

        <TextField
            id="name"
            label={<FormattedMessage id="settings.ce.caption.AzureDomain" defaultMessage="Domain name" />}
            className={classes.formControl}
            value={value.companyDomain}
            onChange={handleChange('companyDomain')}
            type="text"
            fullWidth
            variant="outlined"
        />

      <TextField
        id="licenseNumber"
        label={<FormattedMessage id="settings.ue.caption.license" defaultMessage="License Number" />}
        value={value.licenseNumber}
        onChange={handleChange('licenseNumber')}
        type="text"
        fullWidth
        className={classes.formControl}
        variant="outlined"
      />


      <FormControlLabel
        control={
          <Checkbox
            checked={value.uppercasePassword}
            onChange={handleCheckBoxChange('uppercasePassword')}
            value={value.uppercasePassword}
            color="primary"
          />
        }
        label={<FormattedMessage id="settings.ce.caption.paswordComplecity" defaultMessage="Password complexity" />}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={value.numericPassword}
            onChange={handleCheckBoxChange('numericPassword')}
            value={value.numericPassword}
            color="primary"
          />
        }
        label={<FormattedMessage id="settings.ue.placeholder.number" defaultMessage="Require number" />}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={value.specialCharPassword}
            onChange={handleCheckBoxChange('specialCharPassword')}
            value={value.specialCharPassword}
            color="primary"
          />
        }
        label={<FormattedMessage id="settings.ue.placeholder.special" defaultMessage="Require special character" />}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={value.emailNewADUsers}
            onChange={handleCheckBoxChange('emailNewADUsers')}
            value={value.emailNewADUsers}
            color="primary"
          />
        }
        label={<FormattedMessage id="settings.ue.placeholder.emailNewUsers" defaultMessage="Send email to new users" />}
      />

      <TextField
        type="number"
        id="minpasswordlength"
        label={
          <FormattedMessage id="settings.ue.placeholder.minpasswordlength" defaultMessage="Minimum password length" />
        }
        className={classes.formControl}
        value={value.passwordLength}
        onChange={handleChange('passwordLength')}
        fullWidth
        variant="outlined"
      />

      <TextField
        type="number"
        id="passwordexapires"
        label={<FormattedMessage id="settings.ue.caption.expires" defaultMessage="Password expires in (days)" />}
        className={classes.formControl}
        value={value.requiredExpirationDate}
        onChange={handleChange('requiredExpirationDate')}
        fullWidth
        variant="outlined"
      />
      <TextField
        type="number"
        id="maxUsers"
        label={<FormattedMessage id="settings.ue.caption.usernumber" defaultMessage="User count" />}
        className={classes.formControl}
        value={value.maxUsers}
        onChange={handleChange('maxUsers')}
        InputProps={{ inputProps: { min: -1 } }}
        fullWidth
        variant="outlined"
      />
        <>
            <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>
                <FormattedMessage
                    id="settings.companyeditor.provisioning"
                    defaultMessage="Provisioning (SCIM)"
                />
            </h3>
        </div>
        <FormControlLabel
            control={
                <Checkbox
                    checked={value.provisioningEnabled}
                    onChange={handleCheckBoxChange('provisioningEnabled')}
                    value={value.provisioningEnabled}
                    color="primary"
                />
            }
            label={<FormattedMessage
                id="settings.ce.caption.provisioningEnabled"
                defaultMessage="Provisioning enabled"
            />}
        />
        <TextField
            id="provisioningUser"
            label={<FormattedMessage
                id="settings.ue.caption.provisioningUser"
                defaultMessage="Provisioning system user"
            />}
            value={value.provisioningUser}
            onChange={handleChange('provisioningUser')}
            type="text"
            fullWidth
            className={classes.formControl}
            variant="outlined"
        />
        <TextField
            id="provisioningResponsibleUser"
            label={<FormattedMessage
                id="settings.ue.caption.provisioningResponsibleUser"
                defaultMessage="User responsible for provisioning management"
            />}
            value={value.provisioningResponsibleUser}
            onChange={handleChange('provisioningResponsibleUser')}
            type="text"
            fullWidth
            className={classes.formControl}
            variant="outlined"
        />
      {company.provisioningEnabled && !generatingToken &&
        <Button onClick={generateToken}>
          <FormattedMessage
            id="settings.ue.caption.generateNewToken"
            defaultMessage="Generate new authentication token"
          />
        </Button>}
      {generatingToken &&
        <Loader loaderAction={LoaderAction.None}>
          <FormattedMessage
            id="settings.ue.caption.generatingNewToken"
            defaultMessage="Generating new authentication token"
          />
        </Loader>
      }
    </>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="settings.companyeditor.workingdays" defaultMessage="Working days" />
        </h3>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={workingDays.includes(1)}
            onChange={handleWorkingCheckBox(1)}
            value={workingDays.includes(1)}
            color="primary"
          />
        }
        label={<FormattedMessage id="settings.ue.placeholder.monday" defaultMessage="Monday" />}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={workingDays.includes(2)}
            onChange={handleWorkingCheckBox(2)}
            value={workingDays.includes(2)}
            color="primary"
          />
        }
        label={<FormattedMessage id="settings.ue.placeholder.tuesday" defaultMessage="Tuesday" />}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={workingDays.includes(3)}
            onChange={handleWorkingCheckBox(3)}
            value={workingDays.includes(3)}
            color="primary"
          />
        }
        label={<FormattedMessage id="settings.ue.placeholder.wensday" defaultMessage="Wednesday" />}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={workingDays.includes(4)}
            onChange={handleWorkingCheckBox(4)}
            value={workingDays.includes(4)}
            color="primary"
          />
        }
        label={<FormattedMessage id="settings.ue.placeholder.thursday" defaultMessage="Thursday" />}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={workingDays.includes(5)}
            onChange={handleWorkingCheckBox(5)}
            value={workingDays.includes(5)}
            color="primary"
          />
        }
        label={<FormattedMessage id="settings.ue.placeholder.friday" defaultMessage="Friday" />}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={workingDays.includes(6)}
            onChange={handleWorkingCheckBox(6)}
            value={workingDays.includes(6)}
            color="primary"
          />
        }
        label={<FormattedMessage id="settings.ue.placeholder.saturday" defaultMessage="Saturday" />}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={workingDays.includes(7)}
            onChange={handleWorkingCheckBox(7)}
            value={workingDays.includes(7)}
            color="primary"
          />
        }
        label={<FormattedMessage id="settings.ue.placeholder.sanday" defaultMessage="Sunday" />}
      />
      <h3>
        <FormattedMessage id="settings.companyeditor.policy" defaultMessage="Policy" />
      </h3>
      {policyLoaded && <RichTextEditor text={policy} onChange={onChangePolicy} onBlur={onPolicyBlur} />}
      <h3>
        <FormattedMessage id="settings.companyeditor.active" defaultMessage="Active" />
      </h3>
        <Box
            component="div"
            sx={{
                '& .MuiTextField-root': { paddingRight: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
      <DatePicker
        disableToolbar
        variant="inline"
        margin="normal"
        label={<FormattedMessage id="settings.companyeditor.validuntil" defaultMessage="Valid until" />}
        invalidDateMessage={<FormattedMessage id="datepicker.invalid" defaultMessage="Invalid date format" />}
        maxDateMessage={
          <FormattedMessage id="datepicker.maxDateMessage" defaultMessage="Date should not be after maximal date" />
        }
        minDateMessage={
          <FormattedMessage id="datepicker.minDateMessage" defaultMessage="Date should not be before minimal date" />
        }
        value={accountExpires}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        renderInput={(props) => <TextField {...props} />}
      />

      <FormControlLabel
        control={<Checkbox checked={active} onChange={handleActive('active')} value={active} color="primary" />}
        label={<FormattedMessage id="settings.companyeditor.active" defaultMessage="Active" />}
      />
        </Box>
      <SaveActions onSave={clickSave} onCancel={clickCancel} />
    </div>
  );
});
