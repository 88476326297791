import { handlePostFile, handlePostTextAsParam } from './api';

const ACTION_REDUCER_SEARCH_START = 'ACTION_REDUCER_CESEARCH_START';
const ACTION_REDUCER_SEARCH = 'ACTION_REDUCER_CESEARCH';

const REDUCER_SEARCH_START = () => ({
  type: ACTION_REDUCER_SEARCH_START,
  payload: {}
});

const REDUCER_SEARCH = (searchCEResults) => ({
  type: ACTION_REDUCER_SEARCH,
  payload: { searchCEResults }
});

export function searchByFile(search) {
  return function (dispatch) {
    handlePostFile('/cestandard/searchCEStandardFile', search).then((result) => {
      dispatch(REDUCER_SEARCH(result));
    });
    return dispatch(REDUCER_SEARCH_START());
  };
}

export function searchByText(search) {
  return function (dispatch) {
    handlePostTextAsParam('/cestandard/searchCEStandard', search).then((result) => {
      dispatch(REDUCER_SEARCH(result));
    });
    return dispatch(REDUCER_SEARCH_START());
  };
}
const REDUCER_CLEAR_SEARCH = 'REDUCER_CLEAR_SEARCH';
export const clearSearch = () => ({
  type: REDUCER_CLEAR_SEARCH,
  payload: {}
});

export function ceSearchReducer(state, action) {
  switch (action.type) {
    case ACTION_REDUCER_SEARCH_START:
      return {
        ...state,
        loadingCESearch: true,
        searchCEResults: null
      };

    case ACTION_REDUCER_SEARCH:
      return {
        ...state,
        searchCEResults: action.payload.searchCEResults,
        loadingCESearch: false
      };

    case REDUCER_CLEAR_SEARCH:
      return {
        ...state,
        search: null,
        loadingCESearch: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
