import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { TabsContext } from '../../Stylesheet';
import Loading from '../../Loading';
import GroupBlock from '../blockTabs/pageLayout/GroupBlock';
import { adobeWord, handleIndex } from '../../utils/helpers';
import Spacing from './Spacing';
import Translatable from '../paragraph/Translatable';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridRowGap: 10,
    padding: '0px 10px'
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10
  },
  listWrapper: {
    border: '1px solid #C6C6C5',
    borderRadius: 10,
    overflow: 'hidden'
  },
  listItem: {
    padding: '2px 4px',
    cursor: 'pointer'
  },
  listItemActive: {
    padding: '2px 4px',
    cursor: 'pointer',
    background: '#4db1d3',
    color: '#ffffff'
  },
  title: {
    fontWeight: 700,
    marginBottom: 5
  },
  borderBlock: {
    border: '1px solid #C6C6C5',
    padding: 10,
    display: 'grid',
    gridRowGap: 10
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '155px 1fr',
    gridColumnGap: 10
  }
}));

function Structure() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const structureItem = values.stylesheet && values.stylesheet.indexSettings && values.stylesheet.indexSettings[0];
  const [selectedStructure, setSelectedStructure] = useState({
    idx: 0,
    name: null
  });
  const selectedStructureItem = selectedStructure.name && structureItem[selectedStructure.name];
  const indentProps = [
    { label: 'Left:', value: selectedStructureItem && selectedStructureItem.marginLeft, type: 'marginLeft' },
    { label: 'Right:', value: selectedStructureItem && selectedStructureItem.marginRight, type: 'marginRight' }
  ];

  const handleSelected = (i, el) => {
    setSelectedStructure({ idx: i, name: el });
  };

  const handleValuesIndent = (val, name) => {
    const result = { ...selectedStructureItem[name], value: +val };
    handleIndex(result, name, 'indexSettings', selectedStructure.name, values, setValues);
  };
  const handleDropMenuIndent = (valDropMenu, name, val) => {
    const result = { unit: valDropMenu, value: +val };
    handleIndex(result, name, 'indexSettings', selectedStructure.name, values, setValues);
  };
  const handleSpacing = (res, name) => {
    handleIndex(res, name, 'indexSettings', selectedStructure.name, values, setValues);
  };
  const handleTranslations = (val, name) => {
    handleIndex(val, name, 'indexSettings', selectedStructure.name, values, setValues, true);
  };

  const handlePageNumbering = (val, type) => {
    const res = val
      .toUpperCase()
      .replace(/[-, ' ']/g, '_')
      .split('___')
      .join('_');
    if (res === structureItem.pageNumberType) {
      return;
    }
    handleIndex(res, type, 'indexSettings', selectedStructure.name, values, setValues, true);
  };

  useEffect(() => {
    if (!selectedStructure.name && structureItem) {
      setSelectedStructure((pre) => ({
        ...pre,
        name: Object.keys(structureItem).slice(0, Object.keys(structureItem).length - 2)[0]
      }));
    }
  }, [selectedStructure.name, structureItem]);



  return (
    <div className={cl.container}>
      {structureItem ?
        <>
          <div className={cl.wrapper}>
            <div className={cl.listWrapper}>
              {Object.keys(structureItem)
                .slice(0, Object.keys(structureItem).length - 2)
                .map((el, i) => (
                  <div
                    key={i}
                    className={selectedStructure.idx === i ? cl.listItemActive : cl.listItem}
                    onClick={() => handleSelected(i, el)}
                  >
                    {el}
                  </div>
                ))}
            </div>
            <div className={cl.container}>
              {selectedStructureItem && (
                <>
                  {' '}
                  <GroupBlock
                    label="Indent"
                    valueProps={indentProps}
                    style={{ height: 'Calc(100% - 26px)', gridAutoRows: 'min-content' }}
                    setValueProps={handleValuesIndent}
                    setDropMenu={handleDropMenuIndent}
                  />
                  <Spacing spacing={selectedStructureItem} setSpacing={handleSpacing} />
                </>
              )}
            </div>
          </div>
          <div>
            <p className={cl.title}>Translations</p>
            <div className={cl.borderBlock}>
              <Translatable
                title="Page number type:"
                // style="155"
                typeParents="see"
                selected={structureItem.see}
                setSelected={handleTranslations}
              />
            </div>
          </div>
          <div>
            <p className={cl.title}>Page numbering</p>
            <div className={cl.borderBlock}>
              <div className={cl.wrapperRow}>
                <span className={cl.title}>Page numbering type:</span>
                <ExpanderItem
                  valueProps={adobeWord(structureItem.pageNumberType).replace('_', ' ')}
                  type="pageNumberType"
                  propsLabel={['Page number', 'Chapter number - page number inside chapter']}
                  setProps={handlePageNumbering}
                />
              </div>
            </div>
          </div>
        </>
       :
        <Loading />
      }
    </div>
  );
}

export default Structure;
