import { FormattedMessage } from 'react-intl';
import React from 'react';

export const generalDateSelectors = [
  {
    name: 'reports.from',
    section: 'time',
    label: <FormattedMessage id="reports.from" defaultMessage="From" />
  },
  {
    name: 'reports.to',
    section: 'time',
    label: <FormattedMessage id="reports.measuresoverview.to" defaultMessage="To" />
  }
];
