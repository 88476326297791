import React, { useContext, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Page from './tabs/Page';
import Header from './tabs/Header';
import Footer from './tabs/Footer';
import Background from './tabs/Background';
import LeftEdge from './tabs/LeftEdge';
import RightEdge from './tabs/RightEdge';
import { TabsContext } from '../../../Stylesheet';
import Loading from '../../../Loading';

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    minHeight: 18,
    marginBottom: 15,
    '& .MuiTab-root': {
      padding: '0px 4px 0px 3px',
      minWidth: 'fit-content',
      minHeight: 18,
      background: '#EBEBEB',
      textTransform: 'capitalize',
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#ffffff',
      background: '#4db1d3',
      outline: 'none'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left',
      background: '#E3F2F7'
    }
  }
}));

function PageLayout() {
  const cl = useStyles();
  const tabs = ['Page', 'Header', 'Footer', 'Left edge', 'Right edge', 'Background'];
  const [selectedTab, setSelectedTab] = useState(0);
  const { values } = useContext(TabsContext);
  const pageLout = values.stylesheet.blockSettings[values.selectedLeftMenu];

  const handleChangeSelectedTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      {pageLout && pageLout.class.includes('PageLayout') ? (
        <>
          {tabs && (
            <Tabs
              indicatorColor=""
              className={cl.tabsRoot}
              value={selectedTab}
              onChange={handleChangeSelectedTab}
              textColor="primary"
              variant="standard"
              scrollButtons="auto"
              centered
            >
              {tabs.map((el, i) => (
                <Tab label={el} key={i} />
              ))}
            </Tabs>
          )}
          {selectedTab === 0 && <Page />}
          {selectedTab === 1 && <Header />}
          {selectedTab === 2 && <Footer />}
          {selectedTab === 3 && <LeftEdge />}
          {selectedTab === 4 && <RightEdge />}
          {selectedTab === 5 && <Background />}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default PageLayout;
