import React from 'react';
import DocumentProjectView from 'rm/src/Plugins/RMPPlugin/components/DocumentProjectView';
import withTile from '../shared/withTile';
import { TileIcon } from './AbstractTile';
import TaskContentOverviewTile from '../shared/TaskContentOverviewTile';

export function ProjectDocumentOverviewTile(props) {
  const documentProps = { match: { params: { versionId: props.element.versionId } }, isShortView: true };

  return <DocumentProjectView {...documentProps} />;
}

export default withTile(TileIcon, ProjectDocumentOverviewTile, TaskContentOverviewTile);
