import React, { memo } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { getTilesFunc } from '@zert-packages/actions/api';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import withTileDelay from './shared/withTileDelay';
import useElementActions from './useElementActions';

const ElementTile = memo(
  withTileDelay((props) => {
    const snackbar = useSnackbar();
    const {
      workflowTemplate,
      reloadElement,
      element,
      overviewActions,
      showCut,
      showFileUpload,
      showCreateLink,
      selectElement
    } = props;
    const myUser = useSelector((state) => state.myuser);
    const dispatch = useDispatch();
    const selected = [element];
    const { performActionHandler, getMenuActions } = useElementActions({
      workflowTemplate,
      reloadElement,
      selected,
      overviewActions,
      showCut,
      showFileUpload,
      showCreateLink
    });

    return getTilesFunc()({
      ...props,
      workflowtemplateid: props.workflowTemplateId,
      performAction: performActionHandler(selected),
      actions: props.hideActions || getMenuActions(element).length === 0 ? undefined : getMenuActions(element),
      myUser
    });
  }),
  selectiveMemoComparison((p) => p.element.versionId)
);

export function ElementTileWithMenus() {
  return <ElementTile />;
}

export default ElementTile;
