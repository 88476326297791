import { handlePost, handleQuery } from '@zert-packages/actions/api';

export const getAllTemplates = () => {
  return handleQuery('/deviation-react/getAllTemplates');
};

export const getTemplatesBundle = (versionId) => {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return handleQuery(`/deviation-react/getDeviationTemplate/${locale}/${versionId}`);
};

export const initDeviationAPI = (templateVersionId, state) =>
  handlePost('/demandProjects' + `/initDeviation/${templateVersionId}`, state);

export function createDeviation(deviationtemplate, olddeviation) {
  const deviation = {
    cells: [],
    class: 'se.zert.rm.rest.deviation.pojo.ClientDeviation',
    changed: false,
    template: { versionId: deviationtemplate.versionId },
    reporter: null,
    anonymous: false,
    elementId: olddeviation ? olddeviation.elementId : -1,
    riskRows: olddeviation ? olddeviation.riskRows : []
  };
  deviationtemplate.columns.map((col) => {
    let value = null;
    let cellIndentifier = 'se.zert.rm.rest.template.cell.pojo.';
    if (col.class.includes('IntegerColumn')) {
      cellIndentifier = `${cellIndentifier}ClientIntegerCell`;
      value = 0;
    } else if (col.class.includes('UserColumn')) {
      cellIndentifier = `${cellIndentifier}ClientUserCell`;
    } else if (col.class.includes('MultiSelectColumn')) {
      cellIndentifier = `${cellIndentifier}ClientMultiSelectCell`;
      value = [];
    } else if (col.class.includes('SelectColumn')) {
      cellIndentifier += 'ClientSelectCell';
    } else if (col.class.includes('TextColumn')) {
      cellIndentifier += 'ClientTextCell';
    } else if (col.class.includes('ElementColumn')) {
      cellIndentifier += 'ClientElementCell';
      value = [];
    } else if (col.class.includes('DateColumn')) {
      cellIndentifier = `${cellIndentifier}ClientDateCell`;
    } else {
      return;
    }
    if (olddeviation) {
      const mycell = olddeviation.cells.find((cell) => cell.columnIdentifier == col.identifier);
      if (mycell != null) {
        value = mycell.value;
      }
    }

    const cell = {
      class: cellIndentifier,
      columnIdentifier: col.identifier,
      changed: false,
      editable: true,
      value
    };
    deviation.cells.push(cell);
  });
  return deviation;
}
