import { Checkbox, FormControlLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useEffect, useState } from "react";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import Heading from "./Heading";
import GroupBlock from "../../../pageLayout/GroupBlock";
import { TabsContext } from "../../../../../Stylesheet";
import SelectedColor from "../../../../../SelectedColor";
import {
  adobeColor,
  adobeDropMenuUpperCase, adobeRgba,
  adobeWord,
  handlePageLayout,
  handlePageLayoutSingleLevel
} from "../../../../../utils/helpers";
import DialogColors from "../../../../../dialog/DialogColors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridRowGap: 10,
    paddingBottom: 50,
    "& $wrapperTextField": {
      background: "red",
      gridTemplateColumns: "50px 1fr 55px !important"
    }
  },
  container: {
    border: "1px solid #C6C6C5",
    display: "grid",
    padding: "7px 10px 20px",
    gridRowGap: 10
  },
  wrapperRow: {
    display: "grid",
    gridTemplateColumns: "100px 1fr 1fr",
    gridColumnGap: 10,
    alignItems: "center"
  },
  checkbox: {
    marginBottom: 0
  },
  wrapperDropMenu: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: 10
  },
  title: {
    fontSize: "1rem",
    fontWeight: 700,
    marginBottom: 5
  },
  wrapperText: {
    display: "grid",
    gridTemplateColumns: "80px 1fr ",
    gridColumnGap: 10,
    alignItems: "center"
  }
}));

function Styles() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const styles = values.stylesheet.blockSettings[values.selectedLeftMenu];
  const marginsProps = [
    { label: "Left:", value: styles.marginLeft && styles.marginLeft, type: "marginLeft" },
    { label: "Top:", value: styles.marginTop && styles.marginTop, type: "marginTop" },
    { label: "Right:", value: styles.marginRight && styles.marginRight, type: "marginRight" },
    { label: "Bottom:", value: styles.marginBottom && styles.marginBottom, type: "marginBottom" }
  ];
  const dropMenuBefore = ["Auto", "Column", "Page", "Even page", "Odd page"];
  const [color, setColor] = useState({
    color: "#ffffff",
    oddBackgroundColor: "#ffffff",
    evenBackgroundColor: "#ffffff",
    load: false
  });


  const handleOpenDialog = (type) => {
    renderInExceptionRoot(DialogColors, {
      color: styles[type],
      title: "Select Color",
      setText: handleColor,
      type
    });
  };

  const handleValuesBlockMargins = (val, name) => {
    handlePageLayout(val, name, values, setValues);
  };

  const handleDropMenu = (valDropMenu, name, val) => {
    handlePageLayout(val, name, values, setValues, valDropMenu);
  };

  const handleColor = (val, name) => {
    if (name === "breakBefore" || name === "breakAfter") {
      return handlePageLayoutSingleLevel(adobeDropMenuUpperCase(val), name, values, setValues);
    }
    handlePageLayoutSingleLevel(val, name, values, setValues);
  };

  const handleSelectedColor = (val, name) => {
    setColor(pre => ({ ...pre, [name]: val }));
    handlePageLayoutSingleLevel(adobeRgba(val), name, values, setValues);
  };

  const handleCheckbox = (e) => {
    handlePageLayoutSingleLevel(e.target.checked, "spanAll", values, setValues);
  };

  useEffect(() => {
    if (!color.load) {
      const res = {};
      if (styles.oddBackgroundColor) {
        res.oddBackgroundColor = adobeColor(styles.oddBackgroundColor);
      }
      if (styles.evenBackgroundColor) {
        res.evenBackgroundColor = adobeColor(styles.evenBackgroundColor);
      }
      if (styles.heading.color) {
        res.color = adobeColor(styles.heading.color);
      }
      setColor(pre => ({ ...pre, ...res, load: true }));
    }
  }, [styles.oddBackgroundColor, styles.evenBackgroundColor]);

  // if styles.color
  console.log("color", color);

  return (
    <div className={cl.root}>
      {styles && (
        <>
          <Heading color={color} setColor={setColor} />
          <GroupBlock
            label="Margins"
            valueProps={marginsProps}
            style={{ gridTemplateColumns: "1fr 1fr", gridColumnGap: 10 }}
            setValueProps={handleValuesBlockMargins}
            setDropMenu={handleDropMenu}
            styleRow="80px 1fr 55px"
          />
          <div className={cl.title}>Background color:</div>
          <div className={cl.container}>
            <SelectedColor
              label="Odd rows:"
              type="oddBackgroundColor"
              setValueProps={handleSelectedColor}
              handleOpenDialog={handleOpenDialog}
              valueProps={color.oddBackgroundColor}
              style={{ grid: "80px 30px 1fr 25px" }}
            />
            <SelectedColor
              label="Even rows:"
              type="evenBackgroundColor"
              setValueProps={handleSelectedColor}
              handleOpenDialog={handleOpenDialog}
              valueProps={color.evenBackgroundColor}
              style={{ grid: "80px 30px 1fr 25px" }}
            />
          </div>
          <div className={cl.title}>Text flow:</div>
          <div className={cl.container}>
            <div className={cl.wrapperDropMenu}>
              <div className={cl.wrapperText}>
                <div className={cl.title}>Before:</div>
                <ExpanderItem
                  valueProps={adobeWord(styles.breakBefore, true)}
                  propsLabel={dropMenuBefore}
                  type="breakBefore"
                  setProps={handleColor}
                />
              </div>
              <div className={cl.wrapperText}>
                <div className={cl.title}>After:</div>
                <ExpanderItem
                  valueProps={adobeWord(styles.breakAfter, true)}
                  propsLabel={dropMenuBefore}
                  type="breakAfter"
                  setProps={handleColor}
                />
              </div>
            </div>

            <div className={cl.wrapperText}>
              <div />
              <FormControlLabel
                className={cl.checkbox}
                control={<Checkbox onChange={handleCheckbox} checked={styles.spanAll} name="spanAll" />}
                label={<span>Span all columns</span>}
              />
            </div>
          </div>
        </>
      )}
      {/* <Heading /> */}
    </div>
  );
}

export default Styles;
