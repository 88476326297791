import { TextField } from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import React from 'react';
import getTranslation from '@zert-packages/utils/getTranslation.new';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import ComboSettingsField from './ComboSettingsField';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150
  }
}));

function SettingsField({ settings, values, handleChange, readOnly }) {
  const classes = useStyles();
  const value = values.find((value) => value && value.property.identifier === settings.identifier);

  switch (settings.class) {
    case 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text':
      return (
        <TextField
          id={settings.identifier}
          label={getTranslation(settings.labelObject)}
          className={classes.formControl}
          value={value.value}
          onChange={!readOnly ? handleChange(settings.identifier) : null}
          type="text"
          variant="outlined"
        />
      );
    case 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Number':
      return (
        <TextField
          id={settings.identifier}
          label={getTranslation(settings.labelObject)}
          className={classes.formControl}
          value={value.value}
          onChange={!readOnly ? handleChange(settings.identifier) : null}
          type="number"
          InputProps={{ inputProps: { min: settings.min, max: settings.max } }}
          variant="outlined"
        />
      );
    case 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Choice':
      return (
        <ComboSettingsField
          settings={settings}
          handleChange={!readOnly ? handleChange : null}
          readOnly={readOnly}
          value={value}
        />
      );
    case 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Date':
      return (
        <DatePicker
          disableToolbar
          disabled={readOnly}
          variant="inline"
          margin="normal"
          ampm={false}
          id={settings.identifier}
          label={getTranslation(settings.labelObject)}
          invalidDateMessage={<FormattedMessage id="datepicker.invalid" defaultMessage="Invalid date format" />}
          maxDateMessage={
            <FormattedMessage id="datepicker.maxDateMessage" defaultMessage="Date should not be after maximal date" />
          }
          minDateMessage={
            <FormattedMessage id="datepicker.minDateMessage" defaultMessage="Date should not be before minimal date" />
          }
          value={value.value}
          onChange={!readOnly ? handleChange(settings.identifier) : null}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          renderInput={(props) => <TextField {...props} />}

        />
      );
    default:
      return <div />;
  }
}

export default SettingsField;
