import React, {useContext, useEffect, useState} from 'react';
import {Button, Checkbox, FormControlLabel, Tab, Tabs} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {useSelector} from 'react-redux';
import getTranslation from '@zert-packages/utils/getTranslation.new';
import {useParams} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {PanelContext} from '../InfoElementWrapper/InfoElementWrapper';
import {storePublishingFilters} from './API';
import {getApiPublishingFilters, getApiTree} from '../Structure/API';
import {showSnackbarError} from '../../Snackbars';
import FilterMenu from "./components/publishingFilters/FilterMenu";
import {usePublishingFilterStyles} from "./components/publishingFilters/styles";

function Category({context}) {
  const cl = usePublishingFilterStyles();
  const { versionId } = useParams();
  const { values, setValues } = useContext(context ? context :PanelContext);
  const snackbar = useSnackbar();
  const { publishingFilters, mylocale } = useSelector((state) => state);

  const [openPublishingFilterMenu, setOpenPublishingFilterMenu] = useState(null);
  const [openGroupOptionsMenu, setOpenGroupOptionsMenu] = useState(null);
  const [selectedTab, setSelectTab] = useState(0);
  const [activeCategory, setActiveCategory] = useState(false);
  const [categoryItems, setCategoryItems] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filterSave, setFilterSave] = useState(null);


  const handleChangeSelectedTab = (event, newValue) => {
    setSelectTab(newValue);
    if (newValue === 0) {
      setSelectedCategory({ ...selectedCategory, filterType: 'INCLUDE' });
    } else {
      setSelectedCategory({ ...selectedCategory, filterType: 'EXCLUDE' });
    }
  };

  const handleOpen = (event) => {
    setOpenPublishingFilterMenu(event.currentTarget);
  };

  useEffect(() => {
    if (selectedCategory) {
      if (selectedCategory.filterType === 'INCLUDE') {
        setSelectTab(0);
      } else {
        setSelectTab(1);
      }
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (!categoryItems && publishingFilters && publishingFilters.length > 0) {
      if (values && values.publishingFilters && values.publishingFilters.length > 0) {
        const adapterPublishingFilters = publishingFilters
          .reduce((acu, cur) => {
            const findFilterType = values.publishingFilters.find((el) => el.filterIdentifier === cur.identifier);
            if (findFilterType && findFilterType.filterIdentifier === cur.identifier) {
              const res = cur.items.reduce((acuitems, curitems) => {
                const findChild = findFilterType.filterItemIdentifiers.find((el) => el === curitems.identifier);
                if (findChild) {
                  return [...acuitems, { ...curitems, checked: true }];
                }
                return [...acuitems, { ...curitems, checked: false }];
              }, []);
              return [...acu, { ...cur, items: res, filterType: findFilterType.filterType }];
            }
            return [...acu, cur];
          }, [])
          .map((el) => ({ ...el, filterType: 'INCLUDE' }));
        setCategoryItems(adapterPublishingFilters);
        setSelectedCategory(adapterPublishingFilters[0]);
      } else {
        const adapterPublishingFilters = publishingFilters.map((el) => ({
          ...el,
          filterType: 'INCLUDE',
          items: el.items.map((child) => ({ ...child, checked: false }))
        }));
        setCategoryItems(adapterPublishingFilters);
        setSelectedCategory(adapterPublishingFilters[0]);
      }
    }
  }, [publishingFilters, values]);

  useEffect(() => {
    if (values && values.publishingFilters && values.publishingFilters.length > 0) {
      setFilterSave(values.publishingFilters);
    } else {
      setFilterSave(null);
    }
  }, [values]);

  const handleCategory = (el) => {
    setOpenPublishingFilterMenu(null);
    setSelectedCategory(categoryItems.find(category => category.identifier === el));
  };

  const handleChangeCheckbox = (event) => {
    setSelectedCategory({
      ...selectedCategory,
      items: selectedCategory.items.map((item) =>
        item.identifier === event.target.name
          ? {
            ...item,
            checked: event.target.checked
          }
          : item
      )
    });
  };

  const getTree = async () => {
    return getApiTree(versionId, mylocale);
  };

  const handleFilter = async () => {
    const result = selectedCategory.items.filter((el) => el.checked);
    if (result.length === 0) return;
    const findSelectedCategory = categoryItems.filter((el) => el.identifier !== selectedCategory.identifier);
    // setCategoryItems(findSelectedCategory)
    setSelectedCategory(findSelectedCategory.slice(0, 1)[0]);
    setActiveCategory(!activeCategory);
    const findFiltersArray = categoryItems.map((el) =>
      el.identifier === selectedCategory.identifier ? selectedCategory : el
    );
    const findFilters = findFiltersArray.filter((el) => el.items.find((item) => item.checked));
    const resultFilters = findFilters.map((el) => ({
      filterType: el.filterType,
      filterIdentifier: el.identifier,
      filterItemIdentifiers: el.items.filter((el) => el.checked).map((el) => el.identifier)
    }));
    const resultArray = filterSave && filterSave.length > 0 ? [...findFilters, ...resultFilters] : [...resultFilters];

    try {
      await storePublishingFilters(
        versionId,
        values.treeItem.elementInfo.versionId,
        values.treeItem.identifier.replace(/['[']/g, '(').replace(/['\]']/g, ')'),
        resultArray
      );

      const resPublishingFilters = await getApiPublishingFilters(
        versionId,
        values.treeItem.elementInfo.versionId,
        values.treeItem.identifier.replace(/['[']/g, '(').replace(/['\]']/g, ')').split(' ')[0].split(')')[0] + ')'
      );
      setValues((prev) => ({ ...prev, publishingFilters: resPublishingFilters }));
      const resultGetTree = await getTree();
      setValues((prev) => ({ ...prev, treeChildren: resultGetTree }));
    } catch (e) {
      showSnackbarError(snackbar, e.message);
      // console.log(e)
    }
  };

  const handleFilterCancel = () => {
    setActiveCategory(!activeCategory);
    const result = {
      ...selectedCategory,
      children: selectedCategory.children
        ? selectedCategory.children.map((it) => ({
          ...it,
          checked: false
        }))
        : null
    };
    setSelectedCategory(result);
  };

  const handleFilterSave = (item) => {
    setActiveCategory(!activeCategory);
    const findSelectedFilter = categoryItems.find((el) => el.identifier === item.filterIdentifier);
    const findSelectedChild = findSelectedFilter.items.reduce((acu, cur) => {
      const findChild = item.filterItemIdentifiers.find((el) => el === cur.identifier);
      if (findChild) {
        return [...acu, { ...cur, checked: true }];
      }
      return [...acu, cur];
    }, []);
    setSelectedCategory({ ...findSelectedFilter, items: findSelectedChild });
  };

  return (
    <div>
      {publishingFilters && publishingFilters.length > 0 && (
        <div className={cl.rootTitle}>
          <p className={cl.title}>Publishing Filters</p>
          <div className={cl.AddIcon} onClick={() => setActiveCategory(!activeCategory)}>
            {activeCategory ? <RemoveIcon /> : <AddIcon />}
          </div>
        </div>
      )}
      {activeCategory && (
        <div className={cl.wrapperSelectCategory}>

          <FilterMenu anchorEl={openGroupOptionsMenu}
                      onClose={() => setOpenGroupOptionsMenu(null)}
                      onOpen={event => setOpenGroupOptionsMenu(event.currentTarget)} cl={cl}
                      selectedOption={null}
                      options={[
                        {label: "OR", identifier: "or"},
                        {label: "AND", identifier: "and"},
                        {label: "Group (AND)", identifier: "groupAnd"},
                        {label: "Group (OR)", identifier: "groupOr"}
                      ]}
                      onChoseOption={(ind) => handleCategory(ind)}/>

          <FilterMenu anchorEl={openPublishingFilterMenu}
                      onClose={() => setOpenPublishingFilterMenu(null)}
                      onOpen={handleOpen}
                      selectedOption={selectedCategory}
                      options={categoryItems}
                      onChoseOption={(ind) => handleCategory(ind)}
                      isOptionsTranslatable
          />

          <Tabs
            indicatorColor=""
            value={selectedTab}
            onChange={handleChangeSelectedTab}
            textColor="primary"
            variant="standard"
            scrollButtons="auto"
            centered
          >
            <Tab label="Include for"/>
            <Tab label="Exclude for"/>
          </Tabs>

          {selectedCategory &&
            selectedCategory.items &&
            selectedCategory.items.map((el, i) => (
              <div key={i} className={cl.wrapperCheckbox}>
                <FormControlLabel
                  className={cl.formControlRoot}
                  control={
                    <Checkbox
                      onChange={(e) => handleChangeCheckbox(e, i)}
                      name={el.identifier && el.identifier}
                      checked={el.checked}
                    />
                  }
                  label={<span className={cl.checkboxLabel}>{el.label && getTranslation(el.label)}</span>}
                />
              </div>
            ))}

          <div className={cl.wrapperButton}>
            <Button onClick={handleFilter}>OK</Button>
            <Button onClick={() => handleFilterCancel()}>Cancel</Button>
          </div>
        </div>
      )}

      {filterSave && <div className={cl.borderTop} />}
      {filterSave && filterSave.map((el, i) => (
        <div className={cl.filterSaveRoot} key={i} onClick={() => handleFilterSave(el)}>
          <div className={cl.filterSaveWrapper}>
            <p className={cl.filterSaveWrapperTitle}>{el.filterType}</p>
            <p>{el.filterIdentifier}</p>
            <p>
              {el.filterItemIdentifiers.map((item, i) => (
                <span key={i}>{item} </span>
              ))}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Category;
