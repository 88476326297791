import React from 'react';
import './style.css';
import { FormattedMessage } from 'react-intl';

class BreadCrumb extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.props.handleClick(e);
  }

  render() {
    let locationItem;
    let locationClass;
    if (!this.props.itemLocation || this.props.itemLocation.length === 0) {
      locationItem = (
        <li>
          <a>
            <FormattedMessage
              id="breadcrumb.noplacement"
              defaultMessage="No placement, click here to select placement"
            />
          </a>
        </li>
      );
      locationClass = 'breadcrumb-group no-placement';
    } else {
      locationItem = this.props.itemLocation.map((item, index) => (
        <li key={index}>
          <a>{item.substr(item.lastIndexOf('>') !== -1 ? item.lastIndexOf('>') + 2 : 0, item.length)}</a>
        </li>
      ));
      locationClass = 'breadcrumb-group';
    }
    return (
      <div className={`breadcrumb-vaadin ${this.props.styleName}`} onClick={this.handleClick}>
        {this.props.text !== undefined && <h4 className="title">{this.props.text}</h4>}
        <ul className={locationClass}>{locationItem}</ul>
      </div>
    );
  }
}

export default BreadCrumb;
