import { showSnackbarChangesSaved, showSnackbarError } from '@zert-packages/components/Snackbars';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { createInProgressSnack } from '@zert-packages/components/common/snackBar';
import { reportMockUp } from '@zert-packages/utils/reportMockUp';
import {
  handlePost,
  handleQuery,
  fetchRA_Error,
  fetchPerformMeasureError,
  handlePostTextAsParamResultAsText,
  updateEmptyRiskRow
} from './api';
import {
  fetchRA_WorkFlowTemplate_Success,
  fetchRA_MeasureTemplate_Success,
  fetchRA_Template_Success,
  disableRAActions,
  enableRAActions,
  loadEmptyRiskRowTemplate
} from './riskassesmentActions';
import {
  FETCH_MEASURE_BEGIN,
  FETCH_MEASURE_SUCCESS,
  FETCH_MEASURE_DONE,
  PERFORM_MEASURE_START,
  PERFORM_MEASURE_DONE,
  PERFORM_MEASURE_FINISHED
} from './actions';

import { checkReportStatus } from './checklistActions';

const fetch_MEASURE_BEGIN = () => ({
  type: FETCH_MEASURE_BEGIN,
  payload: {}
});

const fetch_MEASURE_SUCCESS = (riskrow) => ({
  type: FETCH_MEASURE_SUCCESS,
  payload: { riskrow }
});

const fetch_MEASURE_DONE = () => ({
  type: FETCH_MEASURE_DONE,
  payload: {}
});

export const fetchPerformMeasure = () => ({
  type: PERFORM_MEASURE_START,
  payload: {}
});

const fetchMeasurePerformed = (updated_measure) => ({
  type: PERFORM_MEASURE_DONE,
  payload: { updated_measure }
});

const fetchMeasureDone = () => ({
  type: PERFORM_MEASURE_FINISHED,
  payload: {}
});

export function verifyPassword(password) {
  return handleQuery(`/measure/verifyPassword/${password}`);
}

export function verifyPasswordPost(password) {
  return handlePostTextAsParamResultAsText('/measure/verifyPassword', password);
}

export async function performMeasureAsync({ riskRow, measure, ownerId = -1, uuid, activity, dispatch, snackbar }) {
  dispatch(disableRAActions());
  try {
    const { versionId } = riskRow.status;
    riskRow.status.identifier = uuid;
    const storedRiskRow = await handlePost(`/riskanalises/storeRiskRow/${ownerId}/${versionId}`, riskRow);
    await handlePost(`/measure/performTransition/${measure.status.versionId}/${activity.id}`, {});
    const newRiskRow = await getRiskRowAsync(riskRow.status.id);
    dispatch(enableRAActions());
    showSnackbarChangesSaved(
      snackbar,
      <FormattedMessage
        id="performed.measure"
        defaultMessage="Performed measure {measure} to activity {activity}"
        values={{ measure: measure.measure, activity: activity.name }}
      />
    );
    return newRiskRow;
  } catch (e) {
    showSnackbarError(snackbar, `${e.name} ${e.message}`);
    dispatch(enableRAActions());
    return riskRow;
  }
}
export function getRiskRowAsync(id) {
  return handleQuery(`/measure/getRiskRow/${id}`);
}

export function performMeasure(taskId, nextTransition) {
  return function (dispatch) {
    handlePost(`/measure/performTransition/${taskId}/${nextTransition}`, {})
      .then((measure) => {
        dispatch(fetchMeasurePerformed(measure));
        dispatch(fetchMeasureDone());
      })
      .catch((error) => {
        dispatch(fetchPerformMeasureError(error));
      });
    return dispatch(fetchPerformMeasure());
  };
}

export const generateXLSMeasureReport =
  (report, catalogVersionId, nodeUUID, scope, includeAll, startDate, endDate,
   clientColumns, versionIds) =>
  (dispatch, getState, { snackbar }) => {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="Measure.Actions.GeneratingLabel" defaultMessage="...Exporting measures to SpreadSheet" />
    );

    handlePost(`/report-react/generateReport/ ${report.reportUUID}/exportMeasuresXLS`, {
      ...reportMockUp,
      catalogVersionId,
      nodeUUID: nodeUUID || 'none',
      scope,

      includeArchived: includeAll,
      locale,
      object: JSON.stringify({
        reportSettings: {
          startDate,
          endDate,
          templateElementId: -1,
          clientColumns},
      versionIds
      })
    })
      .then((callback) => {
        checkReportStatus(callback, snackbar, generatingSnackbar, report, 'xls', null, null, 'export');
      })

      .catch((error) => {
        showSnackbarError(snackbar, error);
      });
  };

export function loadMeasure(measureId) {
  return async function (dispatch) {
    dispatch(fetch_MEASURE_SUCCESS(null));
    dispatch(fetchRA_WorkFlowTemplate_Success(null));
    dispatch(fetchRA_MeasureTemplate_Success(null));
    dispatch(fetchRA_Template_Success(null));
    dispatch(fetch_MEASURE_BEGIN());
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    try {
      const templateBundle = await handleQuery(`/measure/getAllTemplates/${locale}/${measureId}`);
      dispatch(fetchRA_WorkFlowTemplate_Success(templateBundle.measuresWorkflowProcess));
      dispatch(fetchRA_MeasureTemplate_Success(templateBundle.measureTemplate));
      dispatch(fetchRA_Template_Success(templateBundle.riskRowTemplate));
      const emptyRiskRow = await loadEmptyRiskRowTemplate(templateBundle.riskRowTemplate.versionId);
      dispatch(updateEmptyRiskRow(emptyRiskRow));
      const riskrow = await handleQuery(`/measure/getMeasure/${measureId}`);
      dispatch(fetch_MEASURE_SUCCESS(riskrow));
      dispatch(fetch_MEASURE_DONE());
    } catch (error) {
      dispatch(fetchRA_Error(error));
    }
  };
}
