import React, { useContext, useEffect, useState } from 'react';
import { Tab, Tabs, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { TabsContext } from '../../Stylesheet';
import Loading from '../../Loading';
import Heading from './Heading';
import Content from './Content';
import TableHeader from './TableHeader';
import TableRowOdd from './TableRowOdd';
import TableEvenRow from './TableEvenRow';
import TableRowSeparated from './TableRowSeparated';
import { handleTableSingleLevel, handleType } from '../../utils/helpers';
import { normalData } from '../../data/part/normalData';
import { userDefinedData } from '../../data/part/userDefinedData';

const useStyles = makeStyles((theme) => ({
  wrapperDropMenu: {
    marginBottom: 10
  },
  tabsRoot: {
    minHeight: 18,
    marginBottom: 15,
    '& .MuiTab-root': {
      padding: '0px 4px 0px 3px',
      minWidth: 'fit-content',
      minHeight: 18,
      background: '#EBEBEB',
      textTransform: 'capitalize'
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#ffffff',
      background: '#4db1d3',
      outline: 'none'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left',
      background: '#E3F2F7'
    }
  },
  wrapperTemplate: {
    height: 'Calc(100% - 88px)'
  },
  templateInput: {
    height: '100%',
    width: '100%',
    marginBottom: 0,
    marginTop: 0,
    '& .MuiInputBase-multiline.MuiInputBase-marginDense': {
      height: '100%'
    },
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100% !important'
    }
  }
}));

function Part() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const part = values.stylesheet.partSettings[values.selectedLeftMenu];
  const tabs = ['Heading', 'Content', 'Table header', 'Table row(odd)', 'Table row(even)', 'Table row(separated)'];
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTable, setSelectedTable] = useState('User defined');

  console.log('part', part);
  console.log('values.stylesheet.partSettings', values.stylesheet.partSettings);

  const handleBlockTabs = (val) => {
    if (val === selectedTable) {
      return;
    }
    if (val === 'Normal') {
      handleType(normalData, 'partSettings', values, setValues);
    }
    if (val === 'User defined') {
      handleType(userDefinedData, 'partSettings', values, setValues);
    }
    setSelectedTable(val);
  };

  const handleChangeSelectedTab = (e, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (part && part.class.includes('Normal')) {
      setSelectedTable('Normal');
    } else {
      setSelectedTable('User defined');
    }
  }, [part && part.class]);

  const handleXSL = (e) => {
    const val = e.target.value;
    handleTableSingleLevel(val, 'template', 'partSettings', values, setValues);
  };

  return (
    <>
      {part ? (
        <>
          <div className={cl.wrapperDropMenu}>
            <ExpanderItem
              valueProps={selectedTable}
              propsLabel={['Normal', 'User defined']}
              setProps={handleBlockTabs}
            />
          </div>

          {part.class.includes('Normal') ? (
            <>
              <Tabs
                indicatorColor=""
                className={cl.tabsRoot}
                value={selectedTab}
                onChange={handleChangeSelectedTab}
                textColor="primary"
                variant="standard"
                scrollButtons="auto"
                centered
              >
                {tabs.map((el, i) => (
                  <Tab label={el} key={i} />
                ))}
              </Tabs>
              {selectedTab === 0 && part.heading && <Heading part={part.heading} />}
              {selectedTab === 1 && <Content part={part} />}
              {selectedTab === 2 && part.headerRow && <TableHeader part={part} />}
              {selectedTab === 3 && part.oddRow && <TableRowOdd part={part} />}
              {selectedTab === 4 && part.evenRow && <TableEvenRow part={part} />}
              {selectedTab === 5 && part.separatedRow && <TableRowSeparated part={part} />}
            </>
          ) : (
            <div className={cl.wrapperTemplate}>
              <p className={cl.title}>XSL template:</p>
              <TextField
                className={cl.templateInput}
                value={part.template}
                variant="outlined"
                multiline
                minRows={4}
                onChange={handleXSL}
              />
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Part;
