import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  dropMenuButton: {
    width: '100%',
    justifyContent: 'space-between',
    border: '1px solid #DCE3E5',
    marginBottom: 15,
    color: '#8B8B8B',
    fontWeight: '400',
    paddingBottom: 2,
    paddingTop: 2,
    background: '#ffffff',
    paddingRight: 15,
    borderRadius: 0,
    outline: 'none !important',
    '&:hover': {
      background: '#ffffff'
    },
    '& span:nth-child(2)': {
      display: 'none'
    }
  },
  menuLinkSiteWrapper: {
    borderRadius: 0,
    // width: 470,
    width: 'fit-content',
    minWidth: 315,
    maxWidth: 'Calc(100% - 40px)',
    marginTop: 40,
    border: '1px solid #DCE3E5',
    boxShadow: 'none',
    maxHeight: '400px',
    '@media (max-width: 900px)': {
      marginTop: 45,
      width: 'Calc(100% - 151px)'
    }
  }
}));

function SelectedLanguagePopup({ selectedLocales, setSelectedLocales, disable }) {
  const cl = useStyles();
  const { activeLocales } = useSelector((state) => state);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    if (disable) {
      return;
    }
    setOpen(event.currentTarget);
  };

  const handleSelectedLanguage = (el) => {
    setOpen(null);
    const val = adobeLng(el.value);
    if (setSelectedLocales) {
      setSelectedLocales(val);
    }
  };

  const adobeLng = (lg) => {
    if (lg.split('')[lg.length - 1] === '_') {
      return lg.slice(0, -1);
    }
    return lg;
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        className={cl.dropMenuButton}
        disable={disable}
      >
        {selectedLocales}
        <ArrowDropDownIcon style={{ color: open ? '#8B8B8B' : '#224952' }} />
      </Button>
      <Menu
        anchorEl={open}
        open={Boolean(open)}
        onClose={() => setOpen(null)}
        classes={{
          paper: cl.menuLinkSiteWrapper
        }}
      >
        {activeLocales.map((el, i) => (
          <MenuItem key={i} onClick={() => handleSelectedLanguage(el)}>
            {adobeLng(el.value)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default SelectedLanguagePopup;
