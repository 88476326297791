import React, { useContext, useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import TemplateItemRow from '../../../TemplateItemRow';
import { handlePageLayoutSingleLevel } from '../../../utils/helpers';
import { TabsContext } from '../../../Stylesheet';
import DialogTemplate from '../../../dialog/DialogTemplate';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: '1px solid #C6C6C5',
    padding: '7px 10px 0px',
    display: 'grid',
    gridRowGap: 5,
    '& div': {
      gridRowGap: '5px !important'
    }
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    marginBottom: 5
  },
  underTitle: {
    fontSize: '1rem',
    fontWeight: 700
  },
  wrapperCheckbox: {
    display: 'block',
    marginTop: -1,
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    }
  },
  wrapperGrid: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr 55px',
    gridColumnGap: 10,
    alignItems: 'center'
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr 20px',
    gridColumnGap: 10
  },
  notesInput: {
    margin: 0,
    width: '100%',
    '& input': {
      paddingTop: 5,
      paddingLeft: 15,
      paddingBottom: 5
    }
  }
}));

function LayoutSettings({ valueProps, setValueProps, setDropMenu, setCheckbox, label, style, styleRow }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [open, setOpen] = useState(false);

  const handleCheckbox = (event) => {
    if (setCheckbox) {
      setCheckbox(event.target.checked);
    }
  };

  const handleDropMenu = (event) => {
    const val = event.target.value;
    const { name } = event.target;
    if (name === 'columnCount' && (val > 10 || val < 1)) {
      return;
    }
    handlePageLayoutSingleLevel(val, name, values, setValues);
  };

  const setLayoutSettings = (val, name) => {
    handlePageLayoutSingleLevel(val.name, name, values, setValues);
  };

  const saveTemplate = (item) => {
    if (item === 'User settings') {
      setOpen(true);
    }
  };

  return (
    <div>
      <DialogTemplate open={open} setOpen={setOpen} />
      <p className={cl.title}>{label && label}</p>
      <div className={cl.wrapper} style={style}>
        <div className={cl.wrapperRow}>
          <div className={cl.underTitle}>Initial page number:</div>
          <ExpanderItem
            valueProps={valueProps.selectedFlowDropMenu}
            propsLabel={valueProps.flowDropMenu}
            key={valueProps.selectedFlowDropMenu}
            setProps={setLayoutSettings}
            type={valueProps.typeDropMenu}
          />
          <span
            style={{
              cursor: 'pointer',
              color: valueProps.selectedFlowDropMenu === 'User settings' ? '#212529' : '#C6C6C5'
            }}
            onClick={() => saveTemplate(valueProps.selectedFlowDropMenu)}
          >
            ...
          </span>
        </div>
        <div className={cl.wrapperRow}>
          <div className={cl.underTitle}>Page count:</div>
          <ExpanderItem
            valueProps={valueProps.selectedPageCount}
            propsLabel={valueProps.pageCountDropMenu}
            key={valueProps.selectedPageCount}
            // setProps={setDropMenu}
            type="selectedPageCount"
          />
          <span
            style={{
              cursor: 'pointer',
              color: valueProps.selectedPageCount === 'User settings' ? '#212529' : '#C6C6C5'
            }}
            onClick={() => saveTemplate(valueProps.selectedPageCount)}
          >
            ...
          </span>
        </div>
        <div className={cl.wrapperRow}>
          <div className={cl.underTitle}>Page layout:</div>
          <ExpanderItem
            valueProps={valueProps.pageLayout}
            propsLabel={valueProps.pageLayoutDropMenu}
            key={valueProps.pageLayout}
            setProps={setLayoutSettings}
            type="pageLayout"
          />
        </div>
        <div className={cl.wrapperRow}>
          <div className={cl.underTitle}>Column count:</div>
          <div style={{ maxWidth: 250 }}>
            <TextField
              type="number"
              variant="outlined"
              className={cl.notesInput}
              value={valueProps.selectedColumnCount}
              onChange={handleDropMenu}
              name="columnCount"
            />
          </div>
        </div>
        <div style={{ paddingRight: 30 }}>
          {valueProps &&
            valueProps.child &&
            valueProps.child.map((el, i) => (
              <TemplateItemRow
                key={i}
                label={el.label}
                type={el.type}
                value={el.value}
                grid={styleRow || '150px 1fr 55px'}
                setValueProps={setValueProps}
                setDropMenu={setDropMenu}
              />
            ))}
        </div>
        <div className={cl.wrapperRow}>
          <div className={cl.underTitle}>Numbering:</div>
          <ExpanderItem
            valueProps={valueProps.selectedNumbering}
            propsLabel={valueProps.numberingDropMenu}
            key={valueProps.selectedNumbering}
            setProps={setLayoutSettings}
            type="numbering"
          />
        </div>
        <div className={cl.wrapperRow}>
          <div className={cl.underTitle}>Text orientation:</div>
          <ExpanderItem
            valueProps={valueProps.selectedTextOrientation}
            propsLabel={valueProps.TextOrientationDropMenu}
            // key={valueProps.selectedTextOrientation}
            // setProps={setSelectedDropMenu}
            type="selectedTextOrientation"
          />
        </div>
        {valueProps.checked !== undefined && (
          <span className={cl.wrapperCheckbox}>
            <div className={cl.wrapperGrid}>
              <div />
              <div>
                <FormControlLabel
                  control={<Checkbox onChange={handleCheckbox} checked={valueProps.checked} />}
                  label={<span>Blank headers and footers</span>}
                />
              </div>
            </div>
          </span>
        )}
      </div>
    </div>
  );
}

export default LayoutSettings;
