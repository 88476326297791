import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import withSearch, { styles, controlStyles } from '../withSearch';

import SearchComponent from './SearchComponent';

class StatusSearchPanel extends SearchComponent {
  constructor(props) {
    super(props);

    this.textInput = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  /*  componentDidUpdate(prev) {
    if (!this.state || !this.state.Status) {
      /!*  this.state = {
          Filter: 'current',
        };*!/
      this.setStatus({Status: 'workingcopy'});
    }
  } */

  handleChange = (name) => (event) => {
    this.setStatus({ [name]: event.target.value });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    this.props.expanded && this.textInput.current ? this.textInput.current.blur() : this.textInput.current.focus();
  }

  render() {
    //  this.props.handleChange(this.state);
    const { classes } = this.props;
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel htmlFor="statusId">
            <FormattedMessage id="search.status" defaultMessage="Status" />{' '}
          </InputLabel>
          <Select
            autoFocus
            inputRef={this.textInput}
            value={this.state.Status}
            size="small"
            onChange={(e) => {
              this.setStatus({ Status: e.target.value });
            }}
            inputProps={{
              name: 'status',
              id: 'statusId'
            }}
          >
            <MenuItem value="workingcopy">
              <em>
                <FormattedMessage id="search.status.workingCopy" defaultMessage="Working copy" />
              </em>
            </MenuItem>
            <MenuItem value="checkedin">
              <em>
                <FormattedMessage id="search.status.checkedIn" defaultMessage="Checked In" />
              </em>
            </MenuItem>
            <MenuItem value="checkedout">
              <em>
                <FormattedMessage id="search.status.checkedOut" defaultMessage="Checked Out" />
              </em>
            </MenuItem>
            <MenuItem value="approved">
              <em>
                <FormattedMessage id="search.status.approved" defaultMessage="Approved" />
              </em>
            </MenuItem>
            <MenuItem value="archived">
              <em>
                <FormattedMessage id="search.status.archived" defaultMessage="Archived" />
              </em>
            </MenuItem>
            <MenuItem value="notarchived">
              <em>
                <FormattedMessage id="search.status.notArchived" defaultMessage="Not archived" />
              </em>
            </MenuItem>
          </Select>
        </FormControl>
      </form>
    );
  }
}

StatusSearchPanel.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.status.header" defaultMessage="Search by status" />,
  index: 'search-status',
  className: 'se.zert.backend.search.StatusCriteria',

  default: {
    Status: 'workingcopy'
  }
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles)(StatusSearchPanel), selectData))
);
