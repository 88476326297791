import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { TileIcon } from './AbstractTile';
import '../styles/ChecklistTile.css';
import TileMatrixOverview from '../shared/TileMatrixOverview';
import { loadChecklist, loadChecklistOverviewById } from '@zert-packages/actions/checklistActions';
import withTile from '../shared/withTile';

export const expandChecklist = (element, dispatch) => {
  dispatch(loadChecklist(element.versionId, false));
  dispatch(loadChecklistOverviewById(element.versionId));
};

const checkLimit = (correct, alternatives) => {
  if (alternatives.additionalLimitAllow) {
    let lowerLimit;
    let upperLimit;
    if (alternatives.additionalLimit < alternatives.correctLimit) {
      lowerLimit = alternatives.additionalLimit;
      upperLimit = alternatives.correctLimit;
    } else {
      lowerLimit = alternatives.correctLimit;
      upperLimit = alternatives.additionalLimit;
    }
    return correct < lowerLimit
      ? 'circle-mark all-fail'
      : correct < upperLimit
        ? 'circle-mark all-bad'
        : 'circle-mark all-good';
  }
  return correct < alternatives.correctLimit ? 'circle-mark all-bad' : 'circle-mark all-good';
};

function calculateAnswerCorrect(answerid, categoryid, questionid, qtemplate) {
  const category = qtemplate.categories.find((item) => categoryid == item.id);

  if (category != null) {
    const question = category.questions.find((question) => questionid == question.id);
    // alternatives answerAlts buttonText text correctLimit correct
    const data1 = [];
    if (question != null && question.alternatives != null) {
      var answer = question.alternatives.answerAlts.find((item) => answerid == item.id);
      if (answer != null) {
        return checkLimit(answer.correct, question.alternatives);
      }
    } else if (category.alternatives != null) {
      var answer = category.alternatives.answerAlts.find((item) => answerid == item.id);
      if (answer != null) {
        return checkLimit(answer.correct, category.alternatives);
      }
    }
  }
  return 'circle-mark';
}

export function ChecklistOverviewTile(props) {
  const raoverview = useSelector((state) => state.raoverview);
  const loadingoverview = useSelector((state) => state.loadingoverview);
  const checklist = useSelector((state) => state.checklist);
  const questionairetemplate = useSelector((state) => state.questionairetemplate);

  const [RAOverview, setRAOverview] = useState(null);
  const [RRTemplateId, setRRTemplateId] = useState(null);
  const [incorrectAnswers, setIncorrectAnswers] = useState(null);

  useEffect(() => {
    if (!RAOverview && raoverview != null && raoverview.info.versionId == props.element.versionId) {
      setRAOverview(raoverview);
      setRRTemplateId(RRTemplateId);
    }
  }, [raoverview]);

  useEffect(() => {
    if (
      !loadingoverview &&
      !incorrectAnswers &&
      checklist != null &&
      questionairetemplate != null &&
      checklist.info.versionId == props.element.versionId
    ) {
      const newIncorrectAnswers = [];

      questionairetemplate.categories.map((category) => {
        category.questions.map((question) => {
          const key = `${category.id},${question.id}`;
          const val = checklist.answerAltIds[key];
          if (
            calculateAnswerCorrect(val, category.id, question.id, questionairetemplate).includes('all-bad') ||
            calculateAnswerCorrect(val, category.id, question.id, questionairetemplate).includes('all-fail')
          ) {
            newIncorrectAnswers.push(question);
          }
        });
      });
      setIncorrectAnswers(newIncorrectAnswers);
    }
  }, [loadingoverview, checklist, questionairetemplate]);

  return (
    <div
      id="right_side"
      style={{
        flex: '1',
        height: '100%',
        width: '100%',
        transition: 'flex 0s linear, height 0s step-start',
        transitionTimingFunction: 'linear, step-end'
      }}
    >
      <div>
        <div
          style={{
            color: '#84A6AF'
          }}
        >
          {RAOverview && (
            <FormattedMessage
              id="checklisttile.overview.identifiedrisk"
              defaultMessage="IDENTIFIED RISKS {countRA}"
              values={{
                countRA: <span style={{ color: '#000000' }}>{RAOverview.totalRiskRowsCount}</span>
              }}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex'
          }}
        >
          <TileMatrixOverview element={props.element} rrtemplateid={RRTemplateId} />
        </div>
        {incorrectAnswers && (
          <div
            style={{
              color: '#84A6AF'
            }}
          >
            <FormattedMessage
              id="checklisttile.overview.badcount"
              defaultMessage="Wrong answers count {count}"
              values={{
                count: <span style={{ color: '#000000' }}>{incorrectAnswers.length}</span>
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export function ChecklistContentOverviewTile(props) {
  const checklist = useSelector((state) => state.checklist);
  const questionairetemplate = useSelector((state) => state.questionairetemplate);

  const [overview, setOverview] = useState(null);

  useEffect(() => {
    let newOverview = [];

    if (
      !overview &&
      checklist != null &&
      questionairetemplate != null &&
      checklist.info.versionId == props.element.versionId
    ) {
      const category = questionairetemplate.categories[0];
      newOverview = category.questions.map((question) => {
        const key = `${category.id},${question.id}`;
        const val = checklist.answerAltIds[key];
        return (
          <li>
            <div className={calculateAnswerCorrect(val, category.id, question.id, questionairetemplate)}>
              <div className="circle-half-left" />
              <div className="circle-half-right" />
            </div>
          </li>
        );
      });
      setOverview(newOverview);
    }
  }, [checklist, questionairetemplate]);

  if (overview == null) {
    return (
      <div className="togglable-info-header" style={{ padding: '1em 0' }}>
        <LinearProgress />
      </div>
    );
  }

  return (
    <div
      style={{
        color: '#97B3BB',
        fontSize: '14px',
        fontWeight: '500',
        overflowY: 'hidden',
        transition: '1s',
        height: '35px',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <ul className="circle-navigation" style={{ maxWidth: '0' }}>
        {overview}
      </ul>
    </div>
  );
}

export default withTile(TileIcon, ChecklistOverviewTile, ChecklistContentOverviewTile, expandChecklist);
