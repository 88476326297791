import Dialog from '@mui/material/Dialog';
import React, { useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TextChanger from './TextChanger';

export const formStyle = (theme) =>
  createStyles({
    divRoot: {
      display: 'flex'
      /*  height:'25rem',
          border:'1px solid lightgrey',
          padding:4 */
    },
    divButtons: {
      width: '5%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    divContentContainer: {
      border: '1px solid lightgrey',
      width: '95%',
      height: '20rem'
    },
    divContentContainerNoBorder: {
      width: '95%',
      height: '100%'
    },
    listStyle: {
      '&.MuiTypography-body1': {
        fontSize: '1em'
      }
    }
  });
const useStyles = makeStyles(formStyle);

const initDialogView = {
  languageDialog: false
};

const initItem = {
  label: '',
  value: '',
  selected: false,
  labelObject: {
    translations: [],
    locales: [],
    defaultLabel: ''
  }
};

function ItemDialog({ onSave, handleClose, method, data }) {
  const classes = useStyles();
  const [item, setItem] = useState(data !== null ? data : initItem);

  const acceptHandler = () => {
    onSave(item);
    handleClose();
  };

  return (
    <Dialog open handleClose={handleClose}>
      <DialogTitle>{`${method} Item`}</DialogTitle>
      <DialogContent>
        <TextChanger data={item} setData={setItem} />
        <TextField
          fullWidth
          variant="outlined"
          label="Value:"
          value={item.value}
          name="name"
          onChange={(event) =>
            setItem((prevState) => ({
              ...prevState,
              value: event.target.value
            }))
          }
        />
        <Typography align="center" variant="h6">
          Selected:
        </Typography>
        <Select
          labelId="demo-simple-select-label"
          fullWidth
          label="Selected"
          size="small"
          variant="outlined"
          id="demo-simple-select"
          value={item.selected}
          onChange={(event) => {
            setItem((prevState) => ({
              ...prevState,
              selected: event.target.value
            }));
          }}
        >
          <MenuItem value>Yes</MenuItem>
          <MenuItem value={false}>No</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={acceptHandler} variant="contained" color="primary">
          OK
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default ItemDialog;
