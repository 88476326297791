import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import GTranslateIcon from '@mui/icons-material/GTranslate';

const withLanguageSwitch = (Component) =>
  (function(props) {
    const language = document.getElementsByTagName('html')[0].getAttribute('lang');
    const [isTranslation, setIsTranslation] = useState(true);
    const toggleTranslation = () => {
      const { indexOfEnglish, indexOfSwedish } = languages;
      if (indexOfEnglish === -1 || indexOfSwedish === -1) return;
      setIsTranslation((prev) => !prev);
    };

    function LanguageSwitcher() {
      return (
        <IconButton
          onClick={toggleTranslation}
          style={{ color: isTranslation ? 'gray' : 'blue' }}
          size="large">
          <GTranslateIcon />
        </IconButton>
      );
    }

    const [languages, setLanguages] = useState(null);
    const indexOfTranslation = getIndexOfTranslation();

    function getIndexOfTranslation() {
      if (languages === null) return 0;
      if (languages.indexOfEnglish === -1) return 0;
      if (languages.indexOfSwedish === -1) return 0;

      if (language === 'sv') {
        return isTranslation ? languages.indexOfSwedish : languages.indexOfEnglish;
      }

      if (language === 'en') {
        return isTranslation ? languages.indexOfEnglish : languages.indexOfSwedish;
      }
    }

    const findIndexOfLanguages = (locales) => {
      if (checkIsAllowedUpdateLanguages(locales, languages)) {
        const indexOfEnglish = locales.findIndex((language) => language === 'en');
        const indexOfSwedish = locales.findIndex((language) => language === 'sv');
        setLanguages({
          indexOfEnglish,
          indexOfSwedish
        });
      }
    };

    return (
      <Component
        {...props}
        {...{
          indexOfTranslation,
          languageSwitcher: <LanguageSwitcher />,
          findIndexOfLanguages
        }}
      />
    );
  });

export default withLanguageSwitch;

function checkIsAllowedUpdateLanguages(locales, languages) {
  if (languages === null) return true;
  const val1 = locales[languages.indexOfEnglish] !== 'en';
  const val2 = locales[languages.indexOfSwedish] !== 'sv';
  if (val1) return true;
  if (val2) return true;
  return false;
}
