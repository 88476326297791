import React, {useContext} from 'react';
import {Button} from "@mui/material";
import {MOVE_DOWN_ACTION, MOVE_UP_ACTION} from "../../utils/ELEMENT_TILE_CONTS";
import {SparePartContext} from "../../store/SparePartContext";

const MoveActions = () => {
    const { values, setValues } = useContext(SparePartContext);

    const moveElementUp = () => {
        const currentElementPosition = values.selectedElement.position

        setValues({
            ...values,
            parts: values.parts.map(item => {
                if(item.position === currentElementPosition - 1) {
                    return {
                        ...item,
                        position: currentElementPosition
                    }
                }

                if(item.position === currentElementPosition) {
                    return {
                        ...item,
                        position: currentElementPosition - 1
                    }
                }

                return item
            }),
            selectedElement: {
                ...values.selectedElement,
                position: values.selectedElement.position - 1
            },
            isDataChanged: true
        })
    }

    const moveElementDown = () => {
        const currentElementPosition = values.selectedElement.position

        setValues({
            ...values,
            parts: values.parts.map(item => {
                if(item.position === currentElementPosition + 1) {
                    return {
                        ...item,
                        position: currentElementPosition
                    }
                }

                if(item.position === currentElementPosition) {
                    return {
                        ...item,
                        position: currentElementPosition + 1
                    }
                }

                return item
            }),
            selectedElement: {
                ...values.selectedElement,
                position: values.selectedElement.position + 1
            },
            isDataChanged: true
        })
    }

    return (
        <>
            {values.selectedElement.position !== 1 && <Button key={MOVE_UP_ACTION.id} startIcon={MOVE_UP_ACTION.icon} onClick={() => moveElementUp()}>
                {MOVE_UP_ACTION.name}
            </Button>}
            {values.selectedElement.position !== values.parts.length && <Button key={MOVE_DOWN_ACTION.id} startIcon={MOVE_DOWN_ACTION.icon} onClick={() => moveElementDown()}>
                {MOVE_DOWN_ACTION.name}
            </Button>}
        </>
    );
};

export default MoveActions;