import { useDispatch } from 'react-redux';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import { expandRiskAssessment } from '../tiles/RiskAnalisysTile';
// import {collapseCL as expandTJ} from "../tiles/TranslationJobTile";
// import {collapseCL as expandRJ} from "../tiles/ReviewJobTile";
import { expandChecklist } from '../tiles/ChecklistTile';
import { expandSafetyInspection } from '../tiles/SafetyInspectionTile';
// import { expandProject } from '../tiles/ProjectTile';
import { expandFunction as expandDefault } from '../tiles/AbstractTile';

const useExpandByMimeType = (props) => {
  const dispatch = useDispatch();
  const plugin = getPluginByMimeType(props.element.mimeType);
  if (plugin && plugin.expandFunction) {
    // const Tile = plugin.expandFunction;
    return plugin.expandFunction;
  }
  const expand = () => {
    switch (props.element.mimeType) {
      case 'application/zert-risk-analysis':
        return expandRiskAssessment(props.element, dispatch);

      /* case 'application/zert-translation-job':
                return expandTJ(props.element, dispatch);
            case 'application/zert-review-job':
                return expandRJ(props.element, dispatch); */
      /* case 'application/zert-projectplanning':
                return <ElementContentOverviewTile {...props} />;
            */
      case 'application/zert-checklist':
        return expandChecklist(props.element, dispatch);
      /* case 'application/zert-measure':
                return <ElementContentOverviewTile {...props} />;
            case 'application/zert-deviation':
                return <ElementContentOverviewTile {...props} />;
            case 'application/zert-document':
                return <ElementContentOverviewTile {...props} />;
            case 'application/zert-paper':
                return <ElementContentOverviewTile {...props} />;
            case 'application/zert-folder':
                return <ElementContentOverviewTile {...props} />;
            case 'application/zert-search-folder':
                return <ElementContentOverviewTile {...props} />;
            */
      case 'application/zert-safety-inspection':
        return expandSafetyInspection(props.element, dispatch);
      /* case 'application/zert-project':
        return expandProject(props.element, dispatch); */

      default:
        return expandDefault(props.element, dispatch);
    }
  };

  return { expand };
};

export default useExpandByMimeType;
