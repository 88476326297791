export default function findNode(node, uuid) {
  if (uuid === node.uuid) {
    return node;
  }
  for (let i = 0; i < node.activities.length; i += 1) {
    const currentChild = node.activities[i];

    // Search in the current child
    const result = findNode(currentChild, uuid);

    // Return the result if the node has been found
    if (result != null) {
      return result;
    }
  }
  return null;
}
