import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { splitLevels } from './AssertPublishingPage';
import { AssertPublishingWizardContext } from './PublishingWizardStepper';

const useStyles = makeStyles((theme) => ({
  DialogContent: {
    width: '100%',
    height: '600px',
    display: 'grid',
    gridTemplateRows: '48px 48px 48px 1fr'
  },
  bodyContainer: {
    overflow: 'auto'
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%'
    //   minWidth: 190,
  }
}));

export function AssertFinishPage() {
  const { layouts, selectedLayout, selectedLanguage, layoutFilters, selectedLayoutFilter, layoutSettings } =
    useSelector((state) => state.PREVIEW);
  const [selectedLayoutElement, setSelectedLayoutElement] = useState(null);

  const activeLocales = useSelector((state) => state.activeLocales);
  const { multipleSelectedLanguage, languageSeparated, fallbackLocale } = useSelector((state) => state.PREVIEW);
  const { compareWithVersions, compareWithVersionId } = useSelector((state) => state.PREVIEW);
  const { previewPublishingFilters } = useSelector((state) => state.PREVIEW);
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    setDisabledNext,
    from,
    to,
    splitLevel,
    elementId,
    documentId,
    name,
    element,
    versionId,
    versions,
    versionsLoad,
    setStatus,
    serialNumber,
    productId,
    updateSerialNumber,
    updateProductId,
    nameChanged,
    loadingMetadata,
    published
  } = useContext(AssertPublishingWizardContext);
  const snackbar = useSnackbar();
  const getExtValue = (val, acum) => {
    const value = activeLocales.find((locale) => val == locale.value);
    if (value) {
      const ext = `${value.displayName}/${value.value}`;
      return acum + (!acum ? '' : ', ') + ext;
    }
    return acum;
  };
  const getLayout = () => {
    const layout = layouts.find((layout) => layout.versionId == selectedLayout);
    if (layout) {
      return `${layout.name}(${layout.label})`;
    }
    return '-';
  };

  return (
    <form
      style={{
        display: 'flex',
        justifyContent: 'stretch',
        flexFlow: 'column nowrap'
      }}
    >
      <Typography variant="h2">
        <FormattedMessage
          id="Publish.Assert.Finish.AssertFinishPage"
          defaultMessage="Verify that your publication is correct."
        />
      </Typography>
      <TextField
        fullWidth
        id="standard-avaiabilty"
        label={<FormattedMessage id="Publish.Assert.Finish.Selected" defaultMessage="Selected" />}
        className={classes.formControl}
        variant="outlined"
        value={`${element.name}(${element.label})`}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        fullWidth
        id="standard-avaiabilty"
        label={<FormattedMessage id="Publish.Assert.Finish.AvailabilityDates" defaultMessage="Availability dates" />}
        className={classes.formControl}
        variant="outlined"
        value={`${from || ''} - ${to || '-'}`}
        InputLabelProps={{ shrink: true }}
      />

      <TextField
        fullWidth
        id="standard-Layout"
        label={<FormattedMessage id="Publish.Assert.Finish.Layout" defaultMessage="Layout" />}
        className={classes.formControl}
        variant="outlined"
        value={getLayout()}
        InputLabelProps={{ shrink: true }}
      />

      <Typography variant="h6">
        <FormattedMessage id="Publish.Assert.Finish.SplitLevel" defaultMessage="Split on level" /> -{' '}
        {splitLevel >= 0 ? splitLevels[splitLevel].label : splitLevels[0].label}
      </Typography>
      <TextField
        fullWidth
        id="standard-ElementId"
        label={<FormattedMessage id="Publish.Assert.Finish.ElementId" defaultMessage="Element Id" />}
        className={classes.formControl}
        variant="outlined"
        value={elementId}
        InputLabelProps={{ shrink: true }}
      />

      <TextField
        fullWidth
        id="standard-PublicationDate"
        label={<FormattedMessage id="Publish.Assert.Finish.PublicationDate" defaultMessage="Publication date" />}
        className={classes.formControl}
        variant="outlined"
        value={published}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        fullWidth
        id="standard-DocumentType"
        label={<FormattedMessage id="Publish.Assert.Finish.DocumentType" defaultMessage="Document Type" />}
        className={classes.formControl}
        variant="outlined"
        value={name}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        fullWidth
        id="standard-DocumentId"
        label={<FormattedMessage id="Publish.Assert.Finish.DocumentId" defaultMessage="Document Id" />}
        className={classes.formControl}
        variant="outlined"
        value={documentId}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        fullWidth
        id="standard-ProductId"
        label={<FormattedMessage id="Publish.Assert.Finish.ProductId" defaultMessage="Product Id" />}
        className={classes.formControl}
        variant="outlined"
        value={productId.reduce((acum, value) => {
          return acum + (!acum ? '' : ', ') + value;
        }, '')}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        fullWidth
        id="standard-SerialId"
        label={<FormattedMessage id="Publish.Assert.Finish.SerialId" defaultMessage="Serial Id" />}
        className={classes.formControl}
        variant="outlined"
        value={serialNumber.reduce((acum, value) => {
          return acum + (!acum ? '' : ', ') + value;
        }, '')}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        fullWidth
        id="standard-multipleSelectedLanguage"
        label={
          <FormattedMessage id="Publish.Assert.Finish.MultipleSelectedLanguage" defaultMessage="Selected Languages" />
        }
        className={classes.formControl}
        variant="outlined"
        value={multipleSelectedLanguage.reduce((acum, value) => {
          return getExtValue(value, acum);
        }, '')}
        InputLabelProps={{ shrink: true }}
      />

      <TextField
        fullWidth
        id="standard-fallbackSelectedLanguage"
        label={<FormattedMessage id="Publish.Assert.Finish.FallbackLanguage" defaultMessage="Fallback Languages" />}
        className={classes.formControl}
        variant="outlined"
        value={[fallbackLocale].reduce((acum, value) => {
          return getExtValue(value, acum);
        }, '')}
        InputLabelProps={{ shrink: true }}
      />

      {/*  Layout:
        HTML (1.13)
        Split level:
        None
        Element id:
        71624
        Publication date:
        2020-11-26
        Document type:
        Ritningar & scheman
        Document id:
        5343156
        Product id:
        931XC, 951, 931, 901XC, 901, 911
        Serial-number:
        9013061331-, 9010064301-, 9510015291-, 9112073291-, 9310033281-, 9110073291-, 9313032321-
        Language:
        Swedish (Sweden)/SE [sv_SE], Croatian (Croatia)/HR [hr_HR], Czech (Czech Republic)/CZ [cs_CZ], Danish (Denmark)/DK [da_DK], Dutch [nl], English (United Kingdom)/GB [en_GB], Estonian (Estonia)/EE [et_EE], Finnish (Finland)/FI [fi_FI], French (France)/FR [fr_FR], German (Germany)/DE [de_DE], Hungarian (Hungary)/HU [hu_HU], Italian (Italy)/IT [it_IT], Latvian (Latvia)/LV [lv_LV], Lithuanian (Lithuania)/LT [lt_LT], Norwegian (Norway)/NO [no_NO], Polish (Poland)/PL [pl_PL], Portuguese (Portugal)/PT [pt_PT], Romanian (Romania)/RO [ro_RO], Russian (Russia)/RU [ru_RU], Serbian [sr], Spanish (Spain)/ES [es_ES]
        Fallback language:
        English (United Kingdom)/GB [en_GB] */}
    </form>
  );
}
