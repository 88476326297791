import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorGlobalFallbackComponent from '@zert-packages/components/ErrorBoundary/ErrorGlobalFallbackComponent';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, parsed: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, message: error.message, stack: error.stack };
  }

  componentDidCatch(error, errorInfo) {
    /*   TODO: SEND ERROR SOMEWHERE AUTOMATICALLY WHERE SOURCE MAP EXISTS?   */
  }

  render() {
    if (this.state.hasError || this.props.error) {
      return <ErrorGlobalFallbackComponent errorState={this.state.hasError ? this.state : this.props.error} />;
    }

    return this.props.children;
  }
}

function mapStateToProps(state) {
  const { error } = state;

  return {
    error
  };
}

export default connect(mapStateToProps)(ErrorBoundary);
