import React, {useState} from "react";
import { makeStyles } from "@mui/styles";
import SearchWrapper from "./SearchWrapper";
import { Checkbox, FormControlLabel } from "@mui/material";
import handleValueState from "@zert-packages/utils/handleValueState";
import SelectedBlockMetaData from "./SelectedBlockMetaData";
import SearchPane from "@zert-packages/plugins/ExplorerPlugin/SearchPane";
import Paper from "@mui/material/Paper";
import Filter from "./Filter";
import {searchElements, updateSearchElements} from "@zert-packages/plugins/ExplorerPlugin/servicesMidleware";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import TileList from "@zert-packages/components/ElementTile/TileList";
import {checkFilter} from "./BrowseDialog";
import {columns} from "@zert-packages/components/ElementTile/ElementTileTable";
import {FormattedMessage} from "react-intl";
import {TASK_ACTIONS_TYPES} from "@zert-packages/components/ElementTile/shared/ELEMENT_TILE_CONTS";


const useStyles = makeStyles((theme) => ({

  button: {
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    width: '100%',
    color: '#ffffffff',
    textTransform: 'capitalize',
    marginTop: 10,
    border: '1px solid' + theme.palette.primary.light,
    '&:focus': {
      color: theme.palette.primary.light,
      outline: 'none',
    },
    '&:hover': {
      color: theme.palette.primary.light,
      border: '1px solid' + theme.palette.primary.light,
    },
  }

}));



const MetadataSearchTab = ({browse,setActiveElement,columnsNew,  setBrowse}) => {
  const cl = useStyles();
  const CORE = useSelector((state) => state.CORE);
  const [isAllCompanies, setAllCompanies] = useState(false);
  const dispatch = useDispatch();
  const { searchResult, isLoadingNodeContent } = CORE;

  const explorer_infos = searchResult ? checkFilter(browse.selectedFilter, searchResult.result) : [];


  const reloadSearchOnSubmit = ({ actionType, onFinishState }) => {
    if (actionType === TASK_ACTIONS_TYPES.duplicate) {
      const duplicatedElement = onFinishState;
      dispatch(updateSearchElements({ count: searchResult.count + 1, result: [duplicatedElement, ...explorer_infos] }));
      return;
    }
    dispatch(searchElements(isAllCompanies));
  };


  const handleCheckbox = (event) => {
    const val = event.target.checked;
    handleValueState(val, 'checkRequire', setBrowse, )
  };

  const startSearchOnSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    // setSearchhappens(true)
    dispatch(searchElements(isAllCompanies));
  };

  let searchMessage = <FormattedMessage id="search.list" defaultMessage="Search result" />;

  return(
      <div>
        {/*<SearchWrapper browse={browse} setBrowse={setBrowse}/>*/}
        {/*<SelectedBlockMetaData*/}
        {/*    browse={browse}*/}
        {/*    setBrowse={setBrowse}*/}
        {/*/>*/}
        {/*<FormControlLabel*/}
        {/*    control={<Checkbox onChange={handleCheckbox} checked={browse.checkRequire} />}*/}
        {/*    label={<span>Require All</span>}*/}
        {/*/>*/}

        <div >
          <div>
            <SearchPane
                key="searchPane"
                hide={true}
                metadataOnly={true}
                startSearchOnSubmit={startSearchOnSubmit}
            />
            <div  style={{ padding: '10px' }}>
              <Paper elevation={2} style={{ padding: '10px' }}>
                <Filter browse={browse} setBrowse={setBrowse}/>
              </Paper>
            </div>

            <div  style={{ padding: '10px' }}>
              <Button onClick={startSearchOnSubmit} variant={"outlined"}>
                search
              </Button>
            </div>
          </div>

        </div>

        <div>
          <TileList
              explorer_infos={explorer_infos}
              loading={isLoadingNodeContent}
              columnsNew={columnsNew}
              hideToolBar={true}
              reloadElement={reloadSearchOnSubmit}
              showCut={false}
              fullPage={true}
              bradCrumbComponent={searchMessage}
              setActiveElement={setActiveElement}
          />
        </div>

        {/*<EnhancedTable />*/}
      </div>
  )
}

export default MetadataSearchTab