import React,{ useState, useEffect, } from 'react';
import SockJS from "sockjs-client";
 import Stomp from "stompjs";

const proxy = process.env.REACT_APP_PROXY
const  socketItems = []

const useWebSocket = () => {
  const [socket, setSocket] = useState([])

  const adobeElementEvent = (el) => {
     const adobe = {...el , uuid: el.uuid}
    socketItems.push(adobe)
     const res = socketItems.filter(soc => soc.elementEvent.type && (soc.elementEvent.type !== adobe.elementEvent.type))
    return [...res, adobe]
  }


  const messageHandler =(elementEvent) => {
    if(elementEvent){
      const res =  adobeElementEvent(JSON.parse(elementEvent.body))
      if(res.length > 2){
        return setSocket(res.shift())
      }
      setSocket( res)
    }

  }

  useEffect(() => {
    // try {
    //   const sock = new SockJS(proxy + '/check-events');
    //   const stompClient = Stomp.over(sock);
    //   stompClient.connect({}, function (frame) {
    //     stompClient.subscribe('/event/CORE_EVENT', messageHandler)
    //   });
    // } catch (e) {
    //   console.log('error useWebSocket', e.message);
    // }
  }, [])


  return {socket, setSocket}
}

export default useWebSocket