import React, {useEffect, useState} from 'react'
import {makeStyles} from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import getCLMPlugins from "../../../../Environment";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Checkbox} from "@mui/material";
import Properties from "./Properties";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 414,
        padding: 11,
        background: "#E2F2F6"
    },
    wrapperTitle: {
        marginBottom: 10,
        display: "flex",
        justifyContent: "space-between",
        textTransform: "uppercase",
        "& svg": {
            cursor: "pointer"
        }
    },
    title: {
        fontSize: "1.8rem",
        fontWeight: 700,
        textTransform: "capitalize"
    },
    container: {
        display: 'grid',
        gridRowGap: 5,
    },
    label: {
        fontWeight: '700',
    },
    row: {
        display: 'grid',
        gridTemplateColumns: '150px 1fr',
        gridColumnGap: 5,
        '& input': {
            height: 27.5,
            boxSizing: 'border-box',
        },
    },
    wrapperCheckbox: {
        maxHeight: 171,
        paddingLeft: 11,
        overflow: 'auto',
        '& label': {
            marginBottom: 0,
        },
    },
    wrapperButton: {
        display: "flex",
        marginTop: 30,
        justifyContent: "end",
        gap: 10
    },
    button: {
        color: "#ffffff",
        background: "#4db1d3",
        minWidth: 80,
        border: "1px solid #4db1d3",
        outline: "none !important",
        cursor: "pointer",
        "&:hover": {
            background: "none",
            color: "#212529",
            border: "1px solid #4db1d3"
        },
        "&:focus": {
            backgroundColor: "#4db1d3"
        }
    },
}))

const ConnectionsDialog = ({selected, metaData, handleConnectionsList, add}) => {
    const cl = useStyles();
    const getPlugins = getCLMPlugins()
    const [open, setOpen] = useState(true);
    const [connection, setConnection] = useState({
        mimeType: '',
        selectedPropertiesKey: null,
        properties: {},
        references: []
    })
    const list = metaData?.meta?.structure?.connections || []

    const handleClose = () => {
        setOpen(false);
    }

    const handleConnection = (val, name) => {
        setConnection(pre => ({...pre, [name]: val}))
    }

    const handleType = (val, name) => {
        setConnection(pre => ({...pre, [name]: `application/zert-${val.mimeType}`, properties: {}, name: val.name}))
    }

    const handleCheckbox = (e) => {
        const val = e.target.checked
        const name = e.target.name

        if (connection.references.length === 0) {
            return
        }
        if (!val) {
            const res = connection.references.filter(el => el.metadataIdentifier !== name)
            return handleConnection(res, 'references')
        }
        handleConnection([...connection.references, {metadataIdentifier: name}], 'references')

    }

    const handleSave = () => {
        handleClose()
        if (add) {
            return handleConnectionsList([...list, connection])
        }
        const res = list.map((cur, idx) =>
            idx === metaData.selectedConnections
                ? { ...cur, ...connection }
                : cur
        );
        handleConnectionsList(res)
    }

    console.log('1111', add, {selected, connection, getPlugins, metaData})
    useEffect(() => {
        if (getPlugins?.length > 0 && add) {

            setConnection(pre => ({...pre, mimeType: getPlugins[0].mimeType, name: getPlugins[0].name}))
        }
    }, [getPlugins]);

    useEffect(() => {
        if (selected) {
            setConnection(pre => ({...pre, ...selected}))
        }
    }, []);


    return (<Dialog
        open={open}
        onClose={handleClose}
        classes={{
            paperScrollPaper: cl.root
        }}
    >
        <div className={cl.wrapperTitle}>
              <span className={cl.title}>
                {selected ? 'Edit' : 'Add'} connections
              </span>
            <CloseIcon onClick={handleClose}/>
        </div>

        <div className={cl.container}>
            <div className={cl.row}>
                <div className={cl.label}>
                    Type:
                </div>
                <ExpanderItem
                    propsLabel={getPlugins?.map(el => ({...el, name: el.mimeType})).filter(el => el.name)}
                    valueProps={connection.mimeType?.replace('application/zert-', '')}
                    type={'mimeType'}
                    setProps={handleType}
                    style={{
                        height: 27.5,
                        textTransform: 'none',
                    }}
                />
            </div>
            <div className={cl.row}>
                <div className={cl.label}>
                    Properties:
                </div>
                <Properties connection={connection} setConnection={setConnection}/>
            </div>
            <div className={cl.row}>
                <div className={cl.label}>
                    Metadata:
                </div>
                <div className={cl.wrapperCheckbox}>
                    {metaData?.meta?.structure?.metadataDefinitions.map((el, i) => <div key={i}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleCheckbox}
                                    name={el.identifier}
                                    checked={!!(connection?.references && connection?.references
                                        ?.find(it => it.metadataIdentifier === el.identifier))}
                                />
                            }
                            label={<span>{el.identifier}</span>}
                        />
                    </div>)}
                </div>
            </div>


        </div>

        <div className={cl.wrapperButton}>
            <Button className={cl.button} onClick={handleSave}>
                Ok
            </Button>
            <Button className={cl.button} onClick={handleClose}>
                Cancel
            </Button>
        </div>
    </Dialog>)
}
export default ConnectionsDialog