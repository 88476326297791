import React, { memo, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTemplates } from '@zert-packages/actions/api';
import ExpansionWrapperCriteria from '../common/ExpansionWrapperCriteria';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }
};

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  chipContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignContent: 'center',
    alignItems: 'center',
    gridGap: '1px'
  }
}));

const TemplateSearchCriteria = memo(
  ({ foundCriteria, updateCriteria, onChangeExpansionOfCriteria, classNameOfCriteria, isEverythingDisabled }) => {
    const classes = useStyles();
    const { allTemplates } = useSelector((state) => state);
    const dispatch = useDispatch();
    const onChangeCriteria = (event) => {
      const options = event.target.value;
      updateCriteria(classNameOfCriteria, makeStateForCriteria(options));
    };

    useEffect(() => {
      dispatch(getAllTemplates());
    }, []);

    const handleOnChangeExpansion = (event, isExpanded) => {
      onChangeExpansionOfCriteria(isExpanded, classNameOfCriteria);
    };
    const handleDeleteChip = (event, value) => {
      event.stopPropagation();
      const newValues = foundCriteria.values.filter((item) => item !== value.id);
      const remadeValues = getStateFromCriteria(newValues);
      updateCriteria(classNameOfCriteria, makeStateForCriteria(remadeValues));
    };

    const getTemplateById = (id) => {
      const template = allTemplates && allTemplates.find((template) => template.id == id);
      if (template) {
        return template;
      }
    };

    return (
      <ExpansionWrapperCriteria
        title={<FormattedMessage id="templateCriteriaCounter.template" defaultMessage="Templates" />}
        handleOnChangeExpansion={handleOnChangeExpansion}
        expanded={!!foundCriteria && allTemplates}
      >
        {!!foundCriteria && allTemplates && (
          <FormControl variant="standard" fullWidth disabled={isEverythingDisabled}>
            <InputLabel>
              <FormattedMessage id="search.templates" defaultMessage="Templates" />
            </InputLabel>
            <Select
              variant="standard"
              multiple
              value={getStateFromCriteria(foundCriteria.values)}
              onChange={onChangeCriteria}
              input={<Input />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={
                        <div className={classes.chipContainer}>
                          <span> {getTemplateById(value).name}</span>
                          <IconButton
                            disabled={isEverythingDisabled}
                            style={{ padding: '4px' }}
                            onClick={(event) => handleDeleteChip(event, getTemplateById(value))}
                            size="large"
                          >
                            <DeleteIcon
                              style={{
                                width: '16px',
                                height: '20px'
                              }}
                            />
                          </IconButton>
                        </div>
                      }
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {allTemplates.map((template) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </ExpansionWrapperCriteria>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps.foundCriteria, nextProps.foundCriteria)
);

export default TemplateSearchCriteria;

const getStateFromCriteria = (values) => {
  const filteredValues = values.filter((item) => item.key !== 'TemplatesCount');
  return filteredValues.map((item) => item.value);
};

function makeStateForCriteria(options) {
  const values = options.map((option, index) => ({
    key: `Templates[${index}]`,
    value: option
  }));
  const mimeTypeCount = {
    key: 'TemplatesCount',
    value: `${options.length}`
  };
  values.push(mimeTypeCount);
  return values;
}
