import React from 'react';
import {makeStyles} from '@mui/styles';
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
    noteActiveInputWrapper: {
        '& input': {
            paddingBottom: 5,
            paddingTop: 5,
            paddingLeft: 15
        },
        '& textarea': {
            overflow: 'hidden'
        },
        '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
            paddingTop: 5,
            paddingBottom: 5
        }
    },
    notesInput: {
        margin: 0,
        width: '100%'
    }
}));

function TextFieldItem({values, name, setProps, type = 'text', maxWidth, placeholder, error}) {
    const cl = useStyles();


    const handleChangeNote = (event) => {
        const val = event.target.value;
        if (setProps) {
            setProps(pre => ({...pre, [name]: val}));
        }
    };
    return (
        <div className={cl.noteActiveInputWrapper}
             style={{
                 maxWidth: maxWidth ? maxWidth : '100%'
                }}>
            <TextField

                placeholder={placeholder ? placeholder : ''}
                type={type}
                value={values && values}
                className={cl.notesInput}
                variant="outlined"
                onChange={handleChangeNote}
                error={error}
            />
        </div>
    );
}

export default TextFieldItem;
