import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { FormattedMessage } from 'react-intl';
import ButtonRM from '../Button/index';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    let validated = this.props.isValidated !== false;
    let disabledAccordingToProps = this.props.shouldBeDisabled
    this.state = {
      isOpen: false,
      shouldBeDisabled: !validated || disabledAccordingToProps
    };
    //    this.onWorkFlowActivityDone = this.onWorkFlowActivityDone.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.shouldBeDisabled !== this.props.shouldBeDisabled) {
      let validated = this.props.isValidated !== false;
      let disabledAccordingToProps = this.props.shouldBeDisabled
      this.setState({
        shouldBeDisabled: !validated || disabledAccordingToProps
      });
    }
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div className="backdropstyle">
        <div className="modalstyle" style={{ maxWidth: this.props.showFullSize && 'unset', height: this.props.showFullSize && '90vh'}}>
          <div className="modalcontent">{this.props.children}</div>
          <div className="modalfooter">
            {this.props.onOK && (
              <ButtonRM text="" whenClick={this.props.onOK} disabled={this.state.shouldBeDisabled}>
                <FormattedMessage id={this.props.customMainButtonText ? this.props.customMainButtonText : "modal.OK"}
                                  defaultMessage="OK" />
              </ButtonRM>
            )}
            {this.props.onClose && (
              <ButtonRM text="" whenClick={this.props.onClose} styleName="inline">
                <FormattedMessage id="modal.cancel" defaultMessage="Cancel" />
              </ButtonRM>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
  children: PropTypes.node,
  showFullSize: PropTypes.bool
};

export default Modal;
