import React from 'react';
import { TableHead as MTableHead, TableRow, TableCell } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function TableHead({ columns, sortValues, updateSortValues }) {
  return (
    <MTableHead>
      <TableRow>
        {columns.map((column) =>
          column.isAction ? (
            <TableCell key={column.identifier} padding="none" />
          ) : (
            <TableCell key={column.identifier} padding="none">
              <div className="searchable-table__head-cell" onClick={() => updateSortValues(column.identifier)}>
                {column.label}
                {column.isSortable && <SortIcon sortValues={sortValues} identifier={column.identifier} />}
              </div>
            </TableCell>
          )
        )}
      </TableRow>
    </MTableHead>
  );
}

export default TableHead;

function SortIcon({ sortValues, identifier }) {
  if (sortValues.type === 'up' && sortValues.identifier === identifier) {
    return <ArrowDropUpIcon />;
  }
  if (sortValues.type === 'down' && sortValues.identifier === identifier) {
    return <ArrowDropDownIcon />;
  }
  return null;
}
