export function filterAndConvertValues(values) {
  const filteredValues = [];
  const foundIndexes = findIndexes(values);
  foundIndexes.forEach((currentCriteriaIndex) => {
    const foundAllRelatedFieldToCriteria = values.filter((item) =>
      item.key.includes(`Criteria[${currentCriteriaIndex}]`)
    );
    const convertedToObject = foundAllRelatedFieldToCriteria.reduce((prev, curr) => {
      prev[formatKeyForObject(curr.key)] = curr.value;
      return prev;
    }, {});

    convertedToObject.index = currentCriteriaIndex;
    filteredValues.push(convertedToObject);
  });
  return filteredValues;
}

export function formatKeyForObject(key) {
  const pattern = /(Type|Value|CompareType|Identifier|ColumnIdentifier)/;
  return key.match(pattern)[0];
}

export const increaseCriteriaCount = (values) => changeCriteriaCount(values, 1);
export const decreaseCriteriaCount = (values) => changeCriteriaCount(values, -1);

export function changeCriteriaCount(values, number) {
  const indexOfCriteriaCount = values.findIndex((criteria) => criteria.key === 'CriteriaCount');
  values[indexOfCriteriaCount].value = Number(values[indexOfCriteriaCount].value) + number;
}

export function getDefaultRowRiskCriteriaValue(type, firstPartOfCriteriaKey, identifier) {
  if (type === 'text') {
    return [
      {
        key: `${firstPartOfCriteriaKey}.Type`,
        value: 'text'
      },
      {
        key: `${firstPartOfCriteriaKey}.Value`,
        value: ''
      },
      {
        key: `${firstPartOfCriteriaKey}.ColumnIdentifier`,
        value: identifier
      }
    ];
  }
  if (type === 'select') {
    return [
      {
        key: `${firstPartOfCriteriaKey}.Type`,
        value: 'select'
      },
      {
        key: `${firstPartOfCriteriaKey}.Value`,
        value: ''
      },
      {
        key: `${firstPartOfCriteriaKey}.ColumnIdentifier`,
        value: identifier
      }
    ];
  }
  if (type === 'matrix') {
    return [
      {
        key: `${firstPartOfCriteriaKey}.CompareType`,
        value: 'gt' // gt lt eq
      },
      {
        key: `${firstPartOfCriteriaKey}.Type`,
        value: 'matrix'
      },
      {
        key: `${firstPartOfCriteriaKey}.Value`,
        value: ''
      },
      {
        key: `${firstPartOfCriteriaKey}.ColumnIdentifier`,
        value: identifier
      }
    ];
  }
}

function findIndexes(values) {
  return values.reduce((accum, current) => {
    if (!current.key) return accum;
    const match = current.key.match(/\[(\d+)\]/);
    if (!match) return accum;
    const index = match[1];
    return accum.includes(index) ? accum : [...accum, ...index];
  }, []);
}
