import React, {useContext} from 'react';
import {Button} from "@mui/material";
import {NEW_TRANSLATION_ACTION} from "../../utils/ELEMENT_TILE_CONTS";
import {getTranslations} from "../../../BlockPlugin/API";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import AddLanguageDialog from "../../../SoftwareTextPlugin/components/AddLanguageDialog";
import {SparePartContext} from "../../store/SparePartContext";

const TranslationActions = () => {
    const {values, setValues} = useContext(SparePartContext)

    const addNewTranslation = async () => {
        const lang = await getTranslations(values.currentViewedElement?.element?.versionId)
        renderInExceptionRoot(AddLanguageDialog, {
            versionId: values.currentViewedElement?.element?.versionId,
            langList: lang,
            handlePhrase: () => {}
        })
    }

    return (
        <>
            <Button key={NEW_TRANSLATION_ACTION.id} startIcon={NEW_TRANSLATION_ACTION.icon} onClick={addNewTranslation} disabled={!values.currentViewedElement?.element?.translatable}>
                {NEW_TRANSLATION_ACTION.name}
            </Button>
        </>
    );
};

export default TranslationActions;