import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { TabsContext } from '../../Stylesheet';
import Font from '../table/Font';
import TextFlow from '../table/TextFlow';
import Decorations from '../table/decorations/Decorations';
import GroupBlock from '../blockTabs/pageLayout/GroupBlock';
import { handleTableParents } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 10
  },
  title: {
    fontSize: '1rem',
    fontWeight: '700'
  },
  templateInput: {
    margin: 0,
    width: '100%'
  },
  noteActiveInputWrapper: {
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15,
      background: '#ffffff'
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    },
    '& .MuiFormControl-marginDense': {
      width: '100%'
    }
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10
  },
  wrapperRowBlock: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gridColumnGap: 10
  },
  rowInput: {
    margin: 0,
    width: '100%',
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15,
      background: '#ffffff'
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  rowCountWrapper: {
    display: 'grid',
    gridTemplateColumns: '140px 1fr',
    gridColumnGap: 10
  }
}));

function TableHeader({ part }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);

  const headed = part.headerRow && part.headerRow;

  const spacingProps = [
    { label: 'Left:', value: headed.paddingLeft, type: 'paddingLeft' },
    { label: 'Right:', value: headed.paddingRight, type: 'paddingRight' },
    { label: 'Top:', value: headed.paddingTop, type: 'paddingTop' },
    { label: 'Bottom:', value: headed.paddingBottom, type: 'paddingBottom' }
  ];

  const handleValuesSpacing = (val, name) => {
    const res = { ...headed[name], value: +val };
    handleTableParents(res, name, 'partSettings', 'headerRow', values, setValues);
  };
  const handleDropMenuSpacing = (dropMenu, name, val) => {
    const res = { unit: dropMenu, value: +val };
    handleTableParents(res, name, 'partSettings', 'headerRow', values, setValues);
  };

  return (
    <div>
      <div className={cl.root}>
        <div className={cl.wrapperRow}>
          <Font table={headed} typeParents="partSettings" />
          <TextFlow table={headed} typeParents="partSettings" />
        </div>
        <div className={cl.wrapperRow}>
          <Decorations table={headed} typeParents="partSettings" />
          <GroupBlock
            label="Spacing to contents"
            valueProps={spacingProps}
            style={{ height: 'Calc(100% - 26px)', gridAutoRows: 'min-content' }}
            setValueProps={handleValuesSpacing}
            setDropMenu={handleDropMenuSpacing}
          />
        </div>
      </div>
    </div>
  );
}

export default TableHeader;
