import React, { Fragment } from 'react';
import ButtonRM from '@zert-packages/components/shared/Button';
import Modal from '@zert-packages/components/shared/Modal/Modal.js';
import { connect } from 'react-redux';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import { performSafetyInspection } from '@zert-packages/actions/checklistActions';
import { verifyPasswordPost } from '@zert-packages/actions/measureActions';
import { cleanMeasureError } from '@zert-packages/actions/api';

class WorkflowStageButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmPasword: false,
      isOpenSafetyInspection: false,
      isOpenTransition: false,
      store_element: false
    };

    this.onWorkFlowActivityDone = this.onWorkFlowActivityDone.bind(this);

    this.toggleOwnerConfirmPasword = this.toggleOwnerConfirmPasword.bind(this);
    this.toggleConfirmPasword = this.toggleConfirmPasword.bind(this);

    this.confirmOwnerConfirmPasword = this.confirmOwnerConfirmPasword.bind(this);
    this.confirmConfirmPasword = this.confirmConfirmPasword.bind(this);

    this.errorOk = this.errorOk.bind(this);
    this.errorCancel = this.errorCancel.bind(this);
    this.getActivity = this.getActivity.bind(this);
    this.ownerpassword = React.createRef();
    this.password = React.createRef();
  }

  errorOk() {
    this.props.dispatch(cleanMeasureError());
  }

  errorCancel() {
    this.props.dispatch(cleanMeasureError());
  }

  onWorkFlowActivityDone(transition) {
    if (transition.passwordRequired && !this.state.passwordConfirmed) {
      this.setState({
        isConfirmPasword: true,
        transition
      });
    } else if (transition.ownerPasswordRequired) {
      this.setState({
        isOwnerConfirmPasword: true,
        transition
      });
    } else {
      this.setState(
        {
          transition
        },
        () => {
          this.props.dispatch(
            performSafetyInspection(this.props.safetyinspection.versionId, this.getActivity(transition).id)
          );
        }
      );
    }
  }
  //  this.props.onWorkflowClickDone(transition, this.props.measure)

  toggleOwnerConfirmPasword = () => {
    this.setState({
      isOwnerConfirmPasword: !this.state.isOwnerConfirmPasword
    });
  };

  toggleConfirmPasword = () => {
    this.setState({
      isConfirmPasword: !this.state.isConfirmPasword
    });
  };

  confirmOwnerConfirmPasword = () => {
    this.setState({
      isOwnerConfirmPasword: !this.state.isOwnerConfirmPasword
    });
  };

  confirmConfirmPasword = () => {
    verifyPasswordPost(this.password.current.value)
      .then((result) => {
        if (result) {
          this.setState(
            {
              isConfirmPasword: !this.state.isConfirmPasword,
              passwordConfirmed: true
            },
            () => {
              this.onWorkFlowActivityDone(this.state.transition);
            }
          );
        } else {
          this.setState({
            showPasswordError: true
          });
        }
      })
      .catch((err) => {});
  };

  getActivity(transition) {
    return this.props.workflowtemplate.activities.find((activity) => {
      return activity.id === transition.to.id;
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps != null &&
      prevProps.store_element &&
      !this.props.store_element &&
      this.state.transition != null &&
      this.state.understore
    ) {
      this.setState({ understore: false }, () => {
        this.props.dispatch(
          performSafetyInspection(this.props.safetyinspection.versionId, this.getActivity(this.state.transition).id)
        );
      });
    }
  }

  render() {
    const { length } = this.props.workflowtemplate.transitions;
    const buttons = this.props.workflowtemplate.transitions.map((t, index) => {
      if (t.from.id === this.props.currentActivityId) {
        return (
          <ButtonRM
            text={t.name}
            key={t.id}
            styleName="inline"
            whenClick={(e) => {
              this.onWorkFlowActivityDone(t);
            }}
          />
        );
      }
    });
    return (
      <>
        {buttons}
        <Modal show={this.props.store_element}>
          <Loader loaderAction={LoaderAction.Storing} />
        </Modal>
        <Modal show={this.props.measure_performing}>
          <Loader loaderAction={LoaderAction.Performing} />
        </Modal>

        {this.props.perform_measure_error && (
          <Modal show={this.props.perform_measure_error} onClose={this.errorCancel} onOK={this.errorOk}>
            <div dangerouslySetInnerHTML={{ __html: this.props.perform_measure_error.message }} />
          </Modal>
        )}

        <Modal
          show={this.state.isOwnerConfirmPasword}
          onClose={this.toggleOwnerConfirmPasword}
          onOK={this.confirmOwnerConfirmPasword}
        >
          The task owner, {this.state.transition != null && this.state.transition.name}, need to enter the password to
          perform the action {this.state.transition != null && this.state.transition.name} on the following tasks.
          <input ref={this.ownerpassword} type="password" />
        </Modal>

        <Modal show={this.state.isConfirmPasword} onClose={this.toggleConfirmPasword} onOK={this.confirmConfirmPasword}>
          You need to enter your password to perform the action{' '}
          {this.state.transition != null && this.state.transition.name} on the following tasks.
          <div style={this.state.showPasswordError && { backgroundColor: 'red' }}>
            <input ref={this.password} type="password" />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentActivityId: state.safetyinspection?.currentActivity.id,
  store_element: state.store_element,
  measure_performing: state.measure_performing,
  storedriskrow: state.storedriskrow,
  safetyinspection: state.safetyinspection,
  perform_measure_error: state.perform_measure_error
});

export default connect(mapStateToProps)(WorkflowStageButtons);
