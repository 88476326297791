import React, {useEffect, useState} from 'react'
import {makeStyles} from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import getCLMPlugins from "../../../Environment";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 414,
        padding: 11,
        background: "#E2F2F6"
    },
    wrapperTitle: {
        marginBottom: 10,
        display: "flex",
        justifyContent: "space-between",
        textTransform: "uppercase",
        "& svg": {
            cursor: "pointer"
        }
    },
    title: {
        fontSize: "1.8rem",
        fontWeight: 700,
        textTransform: "capitalize"
    },
    label: {
        fontWeight: 700,
        marginTop: 10,
    },
    listWrapper: {
        border: "1px solid #BDBDBC",
        display: 'grid',
        borderRadius: 5,
        paddingBottom: 15,
        padding: '0px 7px'
    },
    container: {

    },
    list: {
        cursor: 'pointer',
        padding: '0 5px'
    },
    selectedList: {
        padding: '0 5px',
        cursor: 'pointer',
        color: '#ffffff',
        background: '#4db1d3'
    },
    wrapperButton: {
        display: "flex",
        marginTop: 30,
        justifyContent: "end",
        gap: 10
    },
    button: {
        color: "#ffffff",
        background: "#4db1d3",
        minWidth: 80,
        border: "1px solid #4db1d3",
        outline: "none !important",
        cursor: "pointer",
        "&:hover": {
            background: "none",
            color: "#212529",
            border: "1px solid #4db1d3"
        },
        "&:focus": {
            backgroundColor: "#4db1d3"
        }
    },
}))

const ReferenceTypeDialog = ({meta, setMeta}) => {
    const cl = useStyles();
    const [open, setOpen] = useState(true);
    const [selected, setSelected] = useState(null)
    const getPlugins = getCLMPlugins()



    const handleClose = () => {
        setOpen(false);
    };

    const handleSelected = (el) => {
        setSelected(`application/zert-${el.mimeType}`)
    }

    const handleSave = () => {
        handleClose()
        if(!selected){
           return
        }
        setMeta(pre => ({...pre, mimeTypes: [selected]}))
    }

    useEffect(() => {
        if(meta?.mimeTypes?.length > 0){
            setSelected(meta.mimeTypes[0])
        }
    }, []);


    return (<Dialog
        open={open}
        onClose={handleClose}
        classes={{
            paperScrollPaper: cl.root
        }}
    >
        <div className={cl.wrapperTitle}>
              <span className={cl.title}>
                Reference type
              </span>
            <CloseIcon onClick={handleClose}/>
        </div>

        <div className={cl.container}>
            <div className={cl.label}>Element type:</div>
            <div className={cl.listWrapper}>
                {getPlugins?.length > 0 && getPlugins.filter(el => el.mimeType).map((el, i) => <div
                    key={i}
                    className={(`application/zert-${el.mimeType}` === selected ) ? cl.selectedList : cl.list}
                    onClick={() => handleSelected(el)}
                >
                    {el.defaultMessage}
                </div>)}
            </div>
        </div>


        <div className={cl.wrapperButton}>
            <Button className={cl.button} onClick={handleSave}>
                Yes
            </Button>
            <Button className={cl.button} onClick={handleClose}>
                No
            </Button>
        </div>

    </Dialog>)
}

export default ReferenceTypeDialog