const percents = 100;
const minSize = 25

export const findX = (el, hotspots, ) => {
  return  el.defaultX * (hotspots.percents / percents)
}

export const findY = (el, hotspots) => {
  return el.defaultY * (hotspots.percents / percents)
}

export const findWidthCanvasItem = (el, hotspots) => {
  const res = el.defaultWidth * (hotspots.percents / percents)
  return minSize > res ? minSize : res
}

export const findHeightCanvasItem = (el, hotspots) => {
  const res = el.defaultHeight * (hotspots.percents / percents)
  return minSize > res ? minSize : res
}

export const findSize = (size, percents) => {
  return (percents / 100) * size;
}

export const findDefaultSize = (el) => {
  return {
    defaultWidth: el.width,
    defaultHeight: el.height,
    defaultX: el.x,
    defaultY: el.y,
  }
}

