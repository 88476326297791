import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import ArrowToggleButton, { ARROW_TOGGLE_VARIANTS } from '../../shared/ArrowToggleButton';
import NotificationSquareNumber from '../../shared/NotificationSquareNumber';
import UserNameLabel from './UserNameLabel';
import ListCore from './ListCore';
import { BACKGROUND_LINEAR_GRADIENT } from '../SIDE_BAR_CONTS';

const useStyles = makeStyles({
  rootWrapper: {
    position: 'relative'
  },
  contentContainer: {
    display: 'grid',
    gridTemplateColumns: '30px 15px 1fr 50px',
    alignItems: 'center',
    borderBottom: 'solid 1px rgba(27, 161, 197, .05)',
    cursor: 'pointer',
    height: '100%',
    padding: '0px 10px 0px 20px'
  },
  badgeContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  additionalListContainer: {
    transition: 'all 0.5s ease',
    position: 'absolute',
    width: '100%',
    bottom: '100%',
    transform: 'scale(0)',
    transformOrigin: '50% 100%',
    background: BACKGROUND_LINEAR_GRADIENT
  },
  additionalListContainerOpen: {
    transform: 'scale(1)'
  }
});

function AdditionalList({ user, list, number, onClick }) {
  const cl = useStyles();

  const [showUserList, setShowUserList] = useState(false);
  const additionalListClassName = cn({
    [cl.additionalListContainer]: true,
    [cl.additionalListContainerOpen]: showUserList
  });

  return (
    <div data-intro-id="SideBar.Settings" className={cl.rootWrapper}>
      {showUserList && (
        <div className={additionalListClassName}>
          <ListCore list={list} onClick={onClick} />
        </div>
      )}

      <div className={cl.contentContainer} onClick={() => setShowUserList((prev) => !prev)}>
        <ArrowToggleButton
          variant={ARROW_TOGGLE_VARIANTS.bottomTop}
          toggled={showUserList}
          iconColor="white"
          IconButtonProps={{ size: 'small' }}
        />
        <UserNameLabel name={user.name} />
        {number > 0 && <NotificationSquareNumber number={number} />}
      </div>
    </div>
  );
}

export default AdditionalList;
