import React, { useContext, useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { TabsContext } from '../../Stylesheet';
import { adobeDropMenuUpperCase, adobeFont, adobeWord, handleTableParents } from '../../utils/helpers';
import SelectedColor from '../../SelectedColor';
import DialogColors from '../../dialog/DialogColors';
import TemplateItemRow from '../../TemplateItemRow';
import { fontData } from '../../data/fontData';
import SpacingTemplate from '../blockTabs/topic/SpacingTemplate';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1rem',
    fontWeight: '700',
    marginBottom: 0
  },
  borderBlock: {
    border: '1px solid #C6C6C5',
    padding: '5px 10px',
    display: 'grid',
    gridRowGap: 10,
    marginTop: 5
  },
  wrapperRowTitle: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gridColumnGap: 10,
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    }
  },
  templateInput: {
    height: '100%',
    width: '100%',
    marginBottom: 0,
    marginTop: 0,
    '& .MuiInputBase-multiline.MuiInputBase-marginDense': {
      height: '100%'
    },
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100% !important'
    }
  },
  wrapperCheckbox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr ',
    maxWidth: 500
  }
}));

function Heading({ part }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const contentType = part.contentType && adobeWord(part.contentType, true).replace('_', ' ');
  const [firstMenu, setFistMenu] = useState({
    fontSize: 'User defined'
  });
  const breakDropMenu = ['Auto', 'Column', 'Page', 'Even page', 'Odd page'];

  const handleCheckbox = (e) => {
    const res = e.target.checked;
    const { name } = e.target;
    handleTableParents(res, name, 'partSettings', 'heading', values, setValues);
  };

  const handleContentType = (val, name) => {
    handleTableParents(val.toUpperCase().replace(' ', '_'), name, 'partSettings', 'heading', values, setValues);
  };
  const handleXSL = (e) => {
    const val = e.target.value;
    const { name } = e.target;
    handleTableParents(val, name, 'partSettings', 'heading', values, setValues);
  };

  const handleOpenDialog = (type) => {
    renderInExceptionRoot(DialogColors, {
      color: part[type],
      title: 'Select Color',
      setText: handleColor,
      type
    });
  };

  const handleColor = (val, name) => {
    handleTableParents(val, name, 'partSettings', 'heading', values, setValues);
  };

  const handleValues = (val, name) => {
    const result = { ...part[name], unit: part[name] ? part[name].unit : 'pt', value: +val };
    handleTableParents(result, name, 'partSettings', 'heading', values, setValues);
  };

  const handleDropMenu = (valDropMenu, name, val) => {
    const result = { unit: adobeDropMenuUpperCase(valDropMenu), value: +val };
    handleTableParents(result, name, 'partSettings', 'heading', values, setValues);
  };

  const setDropMenuFirstInline = (val, name) => {
    if (val === 'Default') {
      const result = { unit: 'pt', value: 18 };
      handleTableParents(result, name, 'partSettings', 'heading', values, setValues);
    }
    setFistMenu((pre) => ({ ...pre, [name]: val }));
  };

  const findCheckbox = (el) => {
    return part.fontStyle.map((it) => it.toLowerCase()).find((it) => it === el.toLowerCase());
  };

  const handleCheckboxFontStyle = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    if (val) {
      const result = [...part.fontStyle, name.toUpperCase()];
      return handleTableParents(result, 'fontStyle', 'partSettings', 'heading', values, setValues);
    }
    const result = part.fontStyle.filter((el) => el !== name.toUpperCase());
    handleTableParents(result, 'fontStyle', 'partSettings', 'heading', values, setValues);
  };

  const handleSpacing = (val, name) => {
    const res = {
      ...part[name],
      value: {
        ...part[name].value,
        unit: part[name].value ? part[name].value.unit : 'pt',
        value: +val
      }
    };
    handleTableParents(res, name, 'partSettings', 'heading', values, setValues);
  };
  const handleSpacingMenu = (valDrop, name, val) => {
    const res = { ...part[name], value: { unit: valDrop, value: +val } };
    handleTableParents(res, name, 'partSettings', 'heading', values, setValues);
  };

  const handleSpacingBefore = (val, name, nameObject) => {
    const res = { ...part[name], [nameObject]: val };
    handleTableParents(res, name, 'partSettings', 'heading', values, setValues);
  };

  const handleValuePropsDialogAfter = (val, name) => {
    const res = {
      ...part.spaceAfter,
      [name]: {
        unit: part.spaceAfter[name] ? part.spaceAfter[name].unit : 'pt',
        value: +val
      }
    };
    handleTableParents(res, 'spaceAfter', 'partSettings', 'heading', values, setValues);
  };

  const setDropMenuPropsDialogAfter = (valDrop, name, val) => {
    const res = {
      ...part.spaceAfter,
      [name]: {
        unit: valDrop,
        value: +val
      }
    };
    handleTableParents(res, 'spaceAfter', 'partSettings', 'heading', values, setValues);
  };

  const setDropMenuPrecedenceAfter = (val) => {
    const res = { ...part.spaceAfter, precedence: val.name };
    handleTableParents(res, 'spaceAfter', 'partSettings', 'heading', values, setValues);
  };

  return (
    <>
      <p className={cl.title}>Heading</p>
      <div className={cl.borderBlock}>
        <div className={cl.wrapperRowTitle}>
          <div />
          <FormControlLabel
            control={<Checkbox onChange={handleCheckbox} checked={part.visible} name="visible" />}
            label="Use Heading"
          />
        </div>

        <div className={cl.wrapperRowTitle}>
          <p className={cl.title}>Content: </p>
          <ExpanderItem
            valueProps={contentType}
            propsLabel={['Part number', 'Name', 'Function', 'Machine type', 'User defined']}
            type="contentType"
            setProps={handleContentType}
            disabled={!part.visible}
          />
        </div>

        <div className={cl.wrapperRowTitle}>
          <div />
          {contentType === 'User defined' && (
            <TextField
              className={cl.templateInput}
              value={part.content}
              variant="outlined"
              multiline
              minRows={8}
              disabled={!part.visible}
              onChange={handleXSL}
            />
          )}
        </div>

        <div className={cl.wrapperRowTitle}>
          <p className={cl.title}>Font family:</p>
          <ExpanderItem valueProps={adobeFont(part.fontFamily)} type="family" disabled={!part.visible} />
        </div>
        <TemplateItemRow
          label="Font size:"
          type="fontSize"
          dropMenuFirst={{
            label: ['Default', 'User defined'],
            selected: firstMenu.fontSize
          }}
          value={part.fontSize}
          grid="100px 150px 1fr 55px"
          setValueProps={handleValues}
          setDropMenuFirst={setDropMenuFirstInline}
          setDropMenu={handleDropMenu}
          disabled={firstMenu.fontSize === 'Default' || !part.visible}
        />

        <div className={cl.wrapperRowTitle}>
          <p className={cl.title}>Font style:</p>
          <div className={cl.wrapperCheckbox}>
            {fontData.map((el, i) => (
              <div key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxFontStyle}
                      checked={findCheckbox(el.name)}
                      name={el.name}
                      disabled={!part.visible}
                    />
                  }
                  label={<span>{el.name}</span>}
                />
              </div>
            ))}
          </div>
        </div>
        <SelectedColor
          label="Color:"
          type="color"
          setValueProps={handleColor}
          handleOpenDialog={handleOpenDialog}
          style={{ grid: '100px 30px 1fr 25px' }}
          valueProps={part.color}
          disabled={!part.visible}
        />
        <SpacingTemplate
          title="Space after:"
          type="spaceAfter"
          valueProps={part.spaceAfter}
          grid="100px 150px 1fr 55px"
          setValueProps={handleSpacing}
          setDropMenu={handleSpacingMenu}
          handleBefore={handleSpacingBefore}
          setValuePropsDialog={handleValuePropsDialogAfter}
          setDropMenuPropsDialog={setDropMenuPropsDialogAfter}
          setDropMenuPrecedence={setDropMenuPrecedenceAfter}
          disabled={!part.visible}
        />

        <div className={cl.wrapperRowTitle}>
          <p className={cl.title}>Break before: </p>
          <ExpanderItem
            valueProps={adobeWord(part.breakAfter, true).replace('_', ' ')}
            propsLabel={breakDropMenu}
            type="breakAfter"
            setProps={handleContentType}
            disabled={!part.visible}
          />
        </div>
        <div className={cl.wrapperRowTitle}>
          <p className={cl.title}>Break after: </p>
          <ExpanderItem
            valueProps={adobeWord(part.breakBefore, true).replace('_', ' ')}
            propsLabel={breakDropMenu}
            type="breakBefore"
            setProps={handleContentType}
            disabled={!part.visible}
          />
        </div>
      </div>
    </>
  );
}

export default Heading;
