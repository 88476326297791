import React, { useContext } from 'react';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TabsContext } from '../../../Stylesheet';
import Decorations from '../decorations/Decorations';
import GroupBlock from '../../blockTabs/pageLayout/GroupBlock';
import { handleTableParents, handleTabsValue } from '../../../utils/helpers';
import Font from '../Font';
import TextFlow from '../TextFlow';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 10
  },
  title: {
    fontSize: '1rem',
    fontWeight: '700'
  },
  templateInput: {
    margin: 0,
    width: '100%'
  },
  noteActiveInputWrapper: {
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15,
      background: '#ffffff'
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    },
    '& .MuiFormControl-marginDense': {
      width: '100%'
    }
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10
  },
  wrapperRowBlock: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gridColumnGap: 10
  },
  rowInput: {
    margin: 0,
    width: '100%',
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15,
      background: '#ffffff'
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  rowCountWrapper: {
    display: 'grid',
    gridTemplateColumns: '140px 1fr',
    gridColumnGap: 10
  }
}));

function Header({ table }) {
  const { values, setValues } = useContext(TabsContext);
  const cl = useStyles();

  const headed = table.headerRow && table.headerRow;

  const spacingProps = [
    { label: 'Left:', value: headed.paddingLeft, type: 'paddingLeft' },
    { label: 'Right:', value: headed.paddingRight, type: 'paddingRight' },
    { label: 'Top:', value: headed.paddingTop, type: 'paddingTop' },
    { label: 'Bottom:', value: headed.paddingBottom, type: 'paddingBottom' }
  ];

  const handleValuesSpacing = (val, name) => {
    const res = { ...headed[name], value: +val };
    handleTableParents(res, name, 'tableSettings', 'headerRow', values, setValues);
  };
  const handleDropMenuSpacing = (dropMenu, name, val) => {
    const res = { unit: dropMenu, value: +val };
    handleTableParents(res, name, 'tableSettings', 'headerRow', values, setValues);
  };

  const handleHeaderRowCount = (e) => {
    const val = e.target.value;
    const { name } = e.target;
    handleTabsValue(+val, name, 'tableSettings', values, setValues);
  };

  return (
    <>
      {headed && (
        <div className={cl.root}>
          <div className={cl.rowCountWrapper}>
            <div className={cl.title}>Header row count:</div>
            <TextField
              type="number"
              variant="outlined"
              className={cl.rowInput}
              value={table.headerRowCount}
              onChange={handleHeaderRowCount}
              name="headerRowCount"
            />
          </div>

          <div className={cl.wrapperRow}>
            <Font table={headed} />
            <TextFlow table={headed} />
          </div>

          <div className={cl.wrapperRow}>
            <Decorations table={headed} />
            <GroupBlock
              label="Spacing to contents"
              valueProps={spacingProps}
              style={{ height: 'Calc(100% - 26px)', gridAutoRows: 'min-content' }}
              setValueProps={handleValuesSpacing}
              setDropMenu={handleDropMenuSpacing}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
