import React from 'react';
import { getElementDate } from '@zert-packages/actions/actionplan';
import LabelRM from '../Label';

export default class LastModifiedLabel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="label-row">
        <LabelRM text={getElementDate(this.props.element, this.props.intl)} styleName="info-no-border" />
      </div>
    );
  }
}
