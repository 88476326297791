import React, { Fragment, memo, useEffect, useState } from 'react';
import _ from 'lodash';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { IconButton, List, ListItem as _ListItem, ListItemText, TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import getTranslation from '@zert-packages/utils/getTranslation.old';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AttachButton from '@zert-packages/components/AttachButton';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { format, format as formatDate } from 'date-fns';
import ModalCenter from '@zert-packages/components/common/ModalCenter';
import dialogCustom from '@zert-packages/components/common/dialogCustom';
import ElementSearchingDialog from '@zert-packages/components/ElementSearchingDialog';
import { v4 as uuidv4 } from 'uuid';
import { getVersionInfo } from '@zert-packages/actions/coreReducers';
import { useDispatch } from 'react-redux';

import openAttachment from '@zert-packages/utils/openAttachment';
import { ChangeableDocStatus, Status } from './Status';
import {
  changeDocumentDateAPI,
  changeDocumentStatusAPI,
  clearDocumentAPI,
  getDocumentExtended,
  getOriginAPI,
  getSubNodeAPI,
  uploadBasisAPI,
  uploadDocumentBasisAPI,
  uploadDocumentElementAPI
} from '../APIs';

const ListItem = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    margin: '5px 0'
  }
})(_ListItem);

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    padding: 10,
    overflow: 'auto'
  },
  list: {
    display: 'flex',
    overflow: 'auto'
  }
});

const handleError = (e) => {
  throw new Error(e);
};

const DONE_STATUS = 4;

function DocumentProjectView(props) {
  const cl = useStyles();
  const dispatch = useDispatch();
  const documentVersionId = _.get(props, 'match.params.versionId', null);
  const [state, setState] = useState(null);
  const [node, setNode] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [loading, setLoading] = useState(false);

  const getNode = (info) => {
    const nodeId = _.get(info, 'parentUuid', _.get(info, 'ownerNodeUUID', -1));
    const nodeVersionId = _.get(info, 'parentVersionId', -1);
    if (nodeId === -1 || nodeVersionId === -1) {
      setNode({});
      setOrigin('NaN');
      return;
    }
    getOriginAPI(nodeId, nodeVersionId).then((res) => {
      if (res.length !== 0) setOrigin(res.join('/'));
      else setOrigin('NaN');
    });
    getSubNodeAPI(null, nodeVersionId, nodeId).then((res) => {
      setNode(res);
    });
  };

  useEffect(() => {
    if (state === null && documentVersionId !== null) dispatch(getVersionInfo(documentVersionId));
    getDocumentExtended(documentVersionId).then((res) => {
      getNode(res);
      setState(res);
    });
  }, [documentVersionId]);

  const onAttachElement = () => {
    const handleConfirm = (element) => {
      delete element.properties;
      const newState = {
        element,
        name: element.name,
        uuid: uuidv4()
      };
      setLoading(true);

      uploadDocumentBasisAPI(null, state.parentVersionId, state.parentUuid, state.versionId, newState)
        .then((res) => {
          setState((prev) => ({
            ...prev,
            ...res.right,
            parentVersionId: prev.parentVersionId
          }));
          setLoading(false);
        })
        .catch(handleError);
    };

    dialogCustom(ElementSearchingDialog, {
      onConfirm: handleConfirm
    });
  };

  const onUploadField = (e) => {
    setLoading(true);
    const { files } = e.target;
    const file = files[0];
    const fd = new FormData();
    if (!file) return;
    fd.append('file', file, file.name);
    uploadBasisAPI(fd)
      .then((res) => {
        return uploadDocumentElementAPI(state.parentUuid, state.versionId, null, state.parentVersionId, res);
      })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          ...res.right,
          parentVersionId: prev.parentVersionId
        }));
        setLoading(false);
      })
      .catch(handleError);
  };

  const updateDeadlineDate = (date) => {
    setLoading(true);
    const formattedDate = format(date, 'yyyy-MM-dd HH:mm:ss');
    changeDocumentDateAPI(
      encodeURIComponent(formattedDate),
      state.versionId,
      state.parentUuid,
      null,
      state.parentVersionId
    )
      .then((res) => {
        setState((prev) => ({
          ...prev,
          deadLineDate: res
        }));
        setLoading(false);
      })
      .catch(handleError);
  };

  const openElement = () => {
    const element = getElement(state);
    openAttachment(element);
  };

  const onChangeStatus = (documentStatus) => {
    if (documentStatus === state.documentStatus) return;
    setLoading(true);
    changeDocumentStatusAPI(null, state.parentVersionId, state.versionId, documentStatus, state.parentUuid)
      .then(() => {
        const doneStatus = 4;
        if (documentStatus === doneStatus) {
          dispatch(getVersionInfo(state.versionId));
        }
        setState((prev) => ({
          ...prev,
          documentStatus
        }));

        setLoading(false);
      })
      .catch(handleError);
  };
  const onClearElement = () => {
    setLoading(true);
    clearDocumentAPI(state.parentUuid, state.versionId, null, state.parentVersionId)
      .then((res) => {
        setState((prev) => {
          delete prev.element;
          delete prev.referencedElement;
          delete prev.document;
          return {
            ...prev,
            ...res.right,
            parentVersionId: prev.parentVersionId
          };
        });
        setLoading(false);
      })
      .catch(handleError);
  };
  if (state === null || node === null || origin === null) return <Loading />;
  const element = getElement(state);
  return (
    <div className={cl.root}>
      <ModalCenter open={loading}>
        <div>
          <Loading />
        </div>
      </ModalCenter>
      <ActionsPanel
        deadlineValue={state.deadLineDate}
        removeDisabled={!state.document && !state.element}
        statusIndex={state.documentStatus}
        openElementDisabled={!state.element && !state.document && !state.referencedElement}
        attachElementDisabled={state.referencedElement}
        uploadFileDisabled={state.referencedElement}
        disabled={state.documentStatus === DONE_STATUS || state.versionId === -1}
        disabledStatusIndexes={state.referencedElement || state.element || state.document ? [0] : [1, 2, 3, 4]}
        onChangeStatus={onChangeStatus}
        onAttachElement={onAttachElement}
        onUploadField={onUploadField}
        updateDeadlineDate={updateDeadlineDate}
        openElement={openElement}
        onClearElement={onClearElement}
      />
      <InfoPanel
        isShortView={props.isShortView ? props.isShortView : false}
        uuid={state.versionId}
        type={getTranslation(state.type)}
        name={state.name}
        documentStatus={state.documentStatus}
        nodeDescription={_.get(node, 'description', 'NaN')}
        origin={origin}
        responsiblePerson={_.get(node, 'responsiblePerson.username', 'NaN')}
        deadlineDate={state.deadLineDate}
        elementName={_.get(element, 'name', 'NaN')}
        versionId={_.get(element, 'versionId', state.versionId)}
        createdAt={_.get(element, 'createdAt', 'NaN')}
        createdBy={_.get(element, 'createdBy', 'NaN')}
        changedAt={_.get(element, 'changedAt', 'NaN')}
        changedBy={_.get(element, 'changedBy', 'NaN')}
      />
    </div>
  );
}

export default DocumentProjectView;

const ListItemField = memo(
  ({ name, value }) => (
    <ListItem>
      <ListItemText
        secondaryTypographyProps={{
          component: 'span'
        }}
        primary={<>{name}: </>}
        secondary={value}
      />
    </ListItem>
  ),
  selectiveMemoComparison(
    (p) => p.name,
    (p) => p.value
  )
);

function Loading() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Loader />
    </div>
  );
}

const InfoPanel = memo(
  ({
    isShortView,
    uuid,
    type,
    name,
    documentStatus,
    elementName,
    versionId,
    createdAt,
    createdBy,
    changedAt,
    changedBy,
    deadlineDate,
    nodeDescription,
    responsiblePerson,
    origin
  }) => (
    <List disablePadding style={{ display: 'flex', overflow: 'auto', flexDirection: isShortView ? 'row' : 'column' }}>
      <ListItemField
        name={<FormattedMessage id="RMP.DocumentProjectView.InfoPanel.Deadline" defaultMessage="Deadline" />}
        value={deadlineDate ? formatDate(new Date(deadlineDate), 'yyyy-MM-dd') : 'NaN'}
      />
      <ListItemField
        name={<FormattedMessage id="RMP.DocumentProjectView.InfoPanel.ID" defaultMessage="ID" />}
        value={uuid}
      />
      <ListItemField
        name={<FormattedMessage id="RMP.DocumentProjectView.InfoPanel.Type" defaultMessage="Type" />}
        value={type}
      />
      <ListItemField
        name={<FormattedMessage id="RMP.DocumentProjectView.InfoPanel.Name" defaultMessage="Name" />}
        value={name}
      />
      <ListItemField
        name={
          <FormattedMessage id="RMP.DocumentProjectView.InfoPanel.NodeDescription" defaultMessage="NodeDescription" />
        }
        value={nodeDescription}
      />
      <ListItemField
        name={
          <FormattedMessage
            id="RMP.DocumentProjectView.InfoPanel.ResponsiblePerson"
            defaultMessage="Responsible person"
          />
        }
        value={responsiblePerson}
      />
      <ListItemField
        name={<FormattedMessage id="RMP.DocumentProjectView.InfoPanel.Origin" defaultMessage="Origin" />}
        value={origin}
      />
      <ListItemField
        name={<FormattedMessage id="RMP.DocumentProjectView.InfoPanel.ElementName" defaultMessage="Element name" />}
        value={elementName}
      />
      <ListItemField
        name={<FormattedMessage id="RMP.DocumentProjectView.InfoPanel.Status" defaultMessage="Status" />}
        value={<Status statusIndex={documentStatus} withText />}
      />
      <ListItemField
        name={<FormattedMessage id="RMP.DocumentProjectView.InfoPanel.Version" defaultMessage="Version" />}
        value={versionId}
      />

      <ListItemField
        name={<FormattedMessage id="RMP.DocumentProjectView.InfoPanel.CreatedAt" defaultMessage="Created at" />}
        value={createdAt}
      />
      <ListItemField
        name={<FormattedMessage id="RMP.DocumentProjectView.InfoPanel.CreatedBy" defaultMessage="Created by" />}
        value={createdBy}
      />
      <ListItemField
        name={<FormattedMessage id="RMP.DocumentProjectView.InfoPanel.ChangedData" defaultMessage="Changed at" />}
        value={changedAt}
      />
      <ListItemField
        name={<FormattedMessage id="RMP.DocumentProjectView.InfoPanel.ChangedBy" defaultMessage="Changed by" />}
        value={changedBy}
      />
    </List>
  )
);

function ActionButton({ icon: Icon, ...other }) {
  return (
    <IconButton size="small" {...other}>
      <Icon />
    </IconButton>
  );
}

const ActionsPanel = memo(
  ({
    deadlineValue = null,
    removeDisabled,
    disabled,
    statusIndex,
    openElementDisabled,
    attachElementDisabled,
    uploadFileDisabled,
    disabledStatusIndexes,

    onChangeStatus,
    onAttachElement,
    onUploadField,
    updateDeadlineDate,
    openElement,
    onClearElement
  }) => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '48px 30px 30px 30px 30px 170px',
          gridGap: 10
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid rgba(0, 0, 0, 0.1)'
          }}
        >
          <ChangeableDocStatus
            disabled={disabled}
            statusIndex={statusIndex}
            onChange={onChangeStatus}
            disabledIndexes={disabledStatusIndexes}
          />
        </div>
        <ActionButton onClick={openElement} disabled={openElementDisabled} icon={SearchIcon} />
        <ActionButton onClick={onAttachElement} disabled={disabled || attachElementDisabled} icon={AddIcon} />
        <AttachButton onChange={onUploadField} disabled={disabled || uploadFileDisabled} size="small" />
        <ActionButton onClick={onClearElement} disabled={disabled || removeDisabled} icon={RemoveIcon} />
        <DatePicker
          disabled={disabled}
          label={<FormattedMessage id="RMP.DocumentProjectView.ActionsPanel.Deadline" defaultMessage="Deadline" />}
          value={deadlineValue}
          onChange={updateDeadlineDate}
          renderInput={(props) => <TextField variant="standard" {...props} />}
        />
      </div>
    );
  },
  selectiveMemoComparison(
    (p) => p.deadlineValue,
    (p) => p.removeDisabled,
    (p) => p.disabled,
    (p) => p.statusIndex,
    (p) => p.openElementDisabled,
    (p) => p.attachElementDisabled,
    (p) => p.uploadFileDisabled
  )
);

const getElement = (state) => {
  if (typeof state.referencedElement !== 'undefined') return state.referencedElement;
  if (typeof state.element !== 'undefined') return state.element;
  return _.get(state, 'document.element', {});
};
