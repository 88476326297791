import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import { API_ROOT } from '@zert-packages/actions/api';
import { API_ROOT_CALLS } from "./components/mediaBlockRenderer";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345
  },
  media: {
    objectFit: 'contain',
    width: '100%'
    // paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: red[500]
  }
}));

export default function ImageCard({ info, handleClick }) {
  const classes = useStyles();
  const accessToken = window.localStorage.getItem('accessToken');
  return (
    <Card className={classes.root}>
      <CardHeader
        /* avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        R
                    </Avatar>
                } */
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertIcon />
          </IconButton>
        }
        title={info.name}
        subheader={info.createdAt}
      />
      <img
        className={classes.media}
        src={`${API_ROOT_CALLS}/image/getImageById/${info.versionId}?jwtToken=${accessToken}`}
        alt=""
      />
      {/*<CardMedia*/}
      {/*  className={classes.media}*/}
      {/*  image={`${API_ROOT}/image/getImageById/${info.versionId}?jwtToken=${accessToken}`}*/}
      {/*  title={info.name}*/}
      {/*/>*/}

      <CardActions disableSpacing>
        <Button size="small" color="primary" onClick={handleClick}>
          <FormattedMessage id="ResizeImageDialog.UseOpen" defaultMessage="Use image" />
        </Button>
      </CardActions>
    </Card>
  );
}
