import { basisEditorOpenAttachmentForPOST, handleQueryFileAsResult, handleQuery } from '@zert-packages/actions/api';
import { getPluginByMimeType } from './getPluginByMimeType';

const saveData = (function () {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  return function (blob, fileName) {
    const url = window.URL.createObjectURL(new Blob([blob], { type: getMimeType(fileName) }));
    if (!canOpenInWindow(fileName)) {
      a.href = url;
      a.download = fileName;
      a.click();
    //  window.URL.revokeObjectURL(url);
    } else {
      try {
        const wnd = window.open(url, 'Image', 'width="800px",height="800px",resizable=1');

        wnd.onload = function () {
          wnd.focus();
          wnd.document.title = fileName;
        };
      } catch (e) {}
    }
  };
})();

export const getMimeType = (name) => {
  let type = 'application/octet-stream';
  try {
    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(name);
    if (ext && ext.length > 1 && ext[1].toLowerCase() === 'png') {
      type = 'image/png';
    } else if ((ext && ext.length > 1 && ext[1].toLowerCase() === 'jpg') || (ext && ext.length > 1 && ext[1].toLowerCase() === 'jpeg')) {
      type = 'image/jpeg';
    } else if (ext && ext.length > 1 && ext[1].toLowerCase() === 'pdf') {
      type = 'application/pdf';
    }
  } catch (e) {}

  return type;
};

export const canOpenInWindow = (name) => {
  return (
    name.toLowerCase().endsWith('.pdf') ||
    name.toLowerCase().endsWith('.png') ||
    name.toLowerCase().endsWith('.jpg') ||
    name.toLowerCase().endsWith('.jpeg')
  );
};

const openAttachment = async (attachment, url = basisEditorOpenAttachmentForPOST, uuid) => {
  const accessToken = window.localStorage.getItem('accessToken');
  const foundAttachment = Array.isArray(attachment) ? attachment.find((a) => a.uuid === uuid) : attachment;
  const { mimeType, versionId, name } =
    foundAttachment.element ||
    foundAttachment.referencedElement ||
    (foundAttachment.document ? foundAttachment.document.element : foundAttachment);
  if (mimeType === 'application/zert-document') {
    const document = await handleQuery(`/document/load/${versionId}`);
    if (document.link) {
      window.open(document.url, name);
    } else {
      const blob = await handleQueryFileAsResult((url != null ? url : basisEditorOpenAttachmentForPOST) + versionId);
      saveData(blob, name);
    }
  } else {
    window.open(getPluginByMimeType(mimeType).openpath.replace(':versionId', versionId), name);
  }
};

export default openAttachment;
/* const openAttachment = (attachment, uuid) => {
  const accessToken = window.localStorage.getItem('accessToken');
  const foundAttachment = attachment.find(a => a.uuid === uuid);
  const { mimeType, versionId, name } = foundAttachment.element || foundAttachment.referencedElement || foundAttachment.document.element;
  if (mimeType === 'application/zert-document') {
    window.open(basisEditorOpenAttachment + versionId + '?jwtToken='+accessToken, name);
    return;
  }
  window.open(getPluginByMimeType(mimeType)
    .openpath
    .replace(':versionId', versionId), name);
}; */
