import { FormattedMessage } from 'react-intl';
import React from 'react';

export const getTranslationStatuses = {
  UNTRANSLATED: <FormattedMessage id="UNTRANSLATED" defaultMessage="UNTRANSLATED" />,
  LOCKED: <FormattedMessage id="LOCKED" defaultMessage="LOCKED" />,
  APPROVED: <FormattedMessage id="APPROVED" defaultMessage="APPROVED" />,
  CHECKED_OUT: <FormattedMessage id="CHECKED_OUT" defaultMessage="CHECKED OUT" />,
  CHECKED_OUT_BY_OTHER: <FormattedMessage id="CHECKED_OUT_BY_OTHER" defaultMessage="CHECKED OUT BY OTHER" />,
  CHECKED_IN: <FormattedMessage id="CHECKED IN" defaultMessage="CHECKED IN" />
};
