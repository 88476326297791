import { getPlugins } from '@zert-packages/actions/api';
import { useLocation, useHistory } from 'react-router-dom';
import { createNewFolder, createSearchFolder } from '@zert-packages/plugins/ExplorerPlugin/servicesMidleware';
import { useDispatch, useSelector } from 'react-redux';

export const useNewRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { permits } = useSelector((state) => state);
  const history = useHistory();
  const getClick = (route) => {
    if (location.pathname.includes('explorer') && route.showInExplorer) {
      return () => {
        dispatch(createNewFolder());
      };
    }
    if (location.pathname.includes('search') && route.showInSearch) {
      return () => {
        history.push('/explorer');
        dispatch(createSearchFolder());
      };
    }
    return null;
  };

  const minePlugins = getPlugins()
    .filter((plugin) => plugin.isCreateNew)
    .filter((plugin) => plugin.routes)
    .reduce((acum, plugin) => {
      return [
        ...plugin.routes
          .filter(
            (route) =>
              (!location.pathname.includes('explorer') &&
                route.isCreateNew &&
                (!plugin.readpermit || permits[plugin.readpermit])) ||
              (location.pathname.includes('explorer') &&
                route.isCreateNewExplorer &&
                (!plugin.readpermit || permits[plugin.readpermit])) ||
              (location.pathname.includes('explorer') &&
                route.showInExplorer &&
                (!plugin.readpermit || permits[plugin.readpermit])) ||
              (location.pathname.includes('search') &&
                route.showInSearch &&
                (!plugin.readpermit || permits[plugin.readpermit]))
          )
          .map((route) => ({
            to: route.path,
            order: plugin.order,
            name: plugin.name,
            icon: plugin.icon,
            onClick: getClick(route)
          })),
        ...acum
      ];
    }, [])
    .sort((item1, item2) => {
      if (item1.order > item2.order) {
        return 1;
      }
      if (item1.order < item2.order) {
        return -1;
      }
      return 0;
    });

  return minePlugins;
};
