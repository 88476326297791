import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {useDispatch, useSelector} from "react-redux";
import {handleBlock} from "@zert-packages/actions/commonReducer";

const useStyles = makeStyles((theme) => ({
    root: {
        listStyle: "none",
        padding: 0,
        margin: 0,
        display: "flex",
        flexWrap: 'wrap',
        // border: "1px solid #d3d3d3",
        borderRadius: 5,
        marginBottom: 10
    },
    itemMenu: {
        cursor: "pointer",
        padding: "5px 10px 5px 6px",
        borderRight: "1px solid #919191",
        "&:hover": {
            backgroundColor: "#ffffff",
            color: "#05364A"
        }
    }
}))

const dataMenu = [
    { name: "Upload", type: 'Upload'},
    { name: "Download", type: 'Download'},
    { name: "Add new phrase", type: 'newPhrase'},
    { name: "New hotspot", type: 'NewHotspot'},
    { name: "New Form", type: 'NewForm'},
    { name: "New arrow", type: 'NewArrow'},
    { name: "Zoom in", type: 'ZoomIn'},
    { name: "Zoom out", type: 'ZoomOut'}
]

const ImgEditorMenu = () => {
    const cl = useStyles();
    const {block} = useSelector((state) => state);
    const dispatch = useDispatch();


    const handleAction = (type) => {
        dispatch(handleBlock(type, 'imgEditorActionType'))
    }



    return (<ul className={cl.root}>
        {dataMenu.map((el, i) => <li
            key={i}
            className={cl.itemMenu}
            onClick={() => handleAction(el.type)}
        >
            {el.name}
        </li>)}
        {block.selectedImgFigure && <li
            className={cl.itemMenu}
            onClick={() => handleAction('Remove')}
        >Remove</li>}
        {block.selectedImgFigure?.label && <>
            <li className={cl.itemMenu} onClick={() => handleAction('left')}>Align left</li>
            <li className={cl.itemMenu} onClick={() => handleAction('center')}>Align center</li>
            <li className={cl.itemMenu} onClick={() => handleAction('right')}>Align right</li>
            <li className={cl.itemMenu} onClick={() => handleAction('Remove')}>Lock reference</li>
            <li className={cl.itemMenu} onClick={() => handleAction('Remove')}>Unlock reference</li>
        </>}



    </ul>)
}

export default ImgEditorMenu