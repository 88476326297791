import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {makeStyles} from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import cn from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import _ from 'lodash';

const useStyles = makeStyles({
  mainContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 50px',
    height: '100%',
    overflow: 'hidden',
    '@media (max-width: 700px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '50px 1fr'
    }
  },
  tableContainer: {
    overflow: 'auto',
    height: '100%'
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
    flexFlow: 'column nowrap',
    overflowY: 'auto',
    '@media (max-width: 700px)': {
      order: '-1',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      overflowY: 'initial',
      overflowX: 'auto'
    }
  },
  row: {
    cursor: 'pointer'
  },
  sortableHeadCellContainer: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    gridGap: 2,
    justifyContent: 'flex-start',
    cursor: 'pointer'
  },
  verticalCenter: {
    display: 'flex',
    alignItems: 'center'
  }
});

function sortArray(array, orderBy, order) {
  return orderBy === null ? array : order === 'asc' ? _.sortBy(array, [orderBy]).reverse() : _.sortBy(array, [orderBy]);
}

function ActionableTable({ columns, rows, onRowClick, actions = [], initialSelectedRowId, tableProps }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(null);
  const sortedRows = sortArray(rows, orderBy, order);
  const handleSort = (dataKey) => {
    if (dataKey === orderBy && order === 'asc') {
      setOrder('asc');
      setOrderBy(null);
      return;
    }
    const isDesc = orderBy === dataKey && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(dataKey);
  };

  const cl = useStyles();
  const [selectedRowId, setSelectedRowId] = useState(initialSelectedRowId);
  const handleOnRowClick = (_id) => {
    setSelectedRowId(_id);
    if (onRowClick) onRowClick(_id);
  };
  return (
    <div className={cl.mainContainer}>
      <div className={cl.tableContainer}>
        <Table stickyHeader {...tableProps}>
          <TableHead>
            <TableRow>
              {columns.map((cell, index) =>
                cell.sortable ? (
                  <TableCell
                    key={index}
                    className={cn(cl.tableHeadCell, cl.flexContainer)}
                    onClick={() => handleSort(cell.identifier)}
                    style={cell.style}
                  >
                    <div className={cl.sortableHeadCellContainer}>
                      <span className={cl.verticalCenter}>
                        {orderBy === cell.identifier && (order === 'asc' ? <ExpandMoreIcon /> : <ExpandLessIcon />)}
                      </span>
                      <span className={cl.verticalCenter}>{cell.label}</span>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell style={cell.style} key={index}>
                    {cell.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedRows.map((row) => {
              return (
                <TableRow
                  hover
                  onClick={() => handleOnRowClick(row.id)}
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  selected={selectedRowId === row.id}
                  className={cl.row}
                >
                  {columns.map((cell) => (
                    <TableCell style={cell.style} key={cell.identifier}>
                      {row[cell.identifier]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className={cl.actionsContainer}>
        {actions.map((action, index) => (
          <IconButton
            key={index}
            disabled={action.disabled ? action.disabled(selectedRowId) : false}
            onClick={(e) => action.onClick(selectedRowId, e)}
            size="large">
            <action.icon />
          </IconButton>
        ))}
      </div>
    </div>
  );
}

export default ActionableTable;
