import React, { createRef, useEffect, useState, Fragment, useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import style from './styles/style';
import InputCell from './components/InputCell';
import DropMenu from './components/DropMenu';
import DropMenuEnums from './components/DropMenuEnums';
import { getApiTemplates } from './API';
import { PanelContext } from '../InfoElementWrapper/InfoElementWrapper';

function MetadataPane({ context }) {
  const [cellElement, setCellElement] = useState(null);
  const [showMetadataButton, setShowMetadataButton] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [filteredState, setFilteredState] = useState(null);
  const [filteredStateDefinitions, setFilteredStateDefinitions] = useState(null);
  const [titleCategory, setTitleCategory] = useState(null);
  const [nevCategory, setNevCategory] = useState(null);
  const [height, setHeight] = useState();
  const cl = style();
  const { versionInfo } = useSelector((state) => state);
  const { values, setValues } = useContext(context || PanelContext);
  const refScrollBlock = createRef();

  const findId = () => {
    if (context && values?.treeItem?.versionId) {
      return values?.treeItem?.versionId;
    }
    if (values?.treeItem) {
      return values?.treeItem?.elementInfo?.versionId;
    }
    return versionInfo?.info?.versionId;
  };
  const idBlock = findId();

  const handleShowMettaButton = () => {
    setShowMetadataButton(!showMetadataButton);
  };

  const getMeta = async (id) => {
    const result = await getApiTemplates(id);
    if (result) {
      setTemplates(result);
      if (result.templates && result.templates.length > 0) {
        setFilteredState(result.templates[0]);
        if (result.templates[0].metadataDefinitions && result.templates[0].metadataDefinitions.length > 0) {
          setFilteredStateDefinitions(result.templates[0].metadataDefinitions[0]);
        }
      }
    }
  };

  const getCellValue = async () => {
    const findValue = templates.values.map((el, i) => ({ ...el, value: el?.value?.value, idSort: i }));
    setCellElement(findValue);
  };

  useEffect(() => {
    if (templates) {
      getCellValue();
    }
  }, [templates]);

  useEffect(() => {
    if (idBlock) {
      getMeta(idBlock);
    }
  }, [values?.treeItem]);

  return (
    <>
      <div className={cl.dropMenuButtonIcon} onClick={() => handleShowMettaButton()}>
        <AddIcon />
      </div>
      {showMetadataButton && templates && (
        <DropMenu
          titleCategory={titleCategory}
          setTitleCategory={setTitleCategory}
          itemProps={templates}
          filteredState={[filteredState, setFilteredState]}
          filteredDefinitions={[filteredStateDefinitions, setFilteredStateDefinitions]}
          setNevCategory={setNevCategory}
        />
      )}
      {showMetadataButton && filteredState && (
        <DropMenu
          titleCategory={titleCategory}
          setTitleCategory={setTitleCategory}
          filteredState={[filteredState, setFilteredState]}
          filteredDefinitions={[filteredStateDefinitions, setFilteredStateDefinitions]}
          setNevCategory={setNevCategory}
          category
        />
      )}

      <div className={cl.scrollContainerWrapper} style={{ minHeight: `${height}px` }}>
        <div className={cl.scrollContainer}>
          {showMetadataButton && filteredStateDefinitions && (
            <>
              {filteredStateDefinitions.enums ? (
                <div>
                  {filteredStateDefinitions && (
                    <DropMenuEnums
                      itemProps={filteredStateDefinitions}
                      setShowMettaButton={setShowMetadataButton}
                      setCellElement={setCellElement}
                      cellElement={cellElement}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <InputCell
                    valueProps={filteredStateDefinitions}
                    lastCell={false}
                    resultCell={[cellElement, setCellElement, setShowMetadataButton]}
                    create
                    setNevCategory={setNevCategory}
                    nevCategory={nevCategory}
                    idBlock={idBlock}
                  />
                </div>
              )}
            </>
          )}

          {showMetadataButton && nevCategory && (
            <div className={cl.wrapperInput}>
              {nevCategory.map((el, idx) => (
                <InputCell
                  valueProps={el}
                  key={idx}
                  lastCell={false}
                  resultCell={[cellElement, setCellElement, setShowMetadataButton]}
                  create
                  setNevCategory={setNevCategory}
                  nevCategory={nevCategory}
                  idBlock={idBlock}
                />
              ))}
            </div>
          )}

          {cellElement && (
            <div className={cl.wrapperInput}>
              {cellElement.map((el, idx, arr) => (
                <InputCell
                  key={cellElement + idx}
                  valueProps={el}
                  firstCell={idx === 0}
                  lastCell={idx === arr.length - 1}
                  resultCell={[cellElement, setCellElement, setShowMetadataButton]}
                  idBlock={idBlock}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default MetadataPane;
