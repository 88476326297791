import React, { Fragment, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SvgIcon from '@zert-packages/icons/SvgIcon';
import { useSelector } from 'react-redux';
import { getFormat } from '@zert-packages/actions/coreReducers';
import getTranslation from '@zert-packages/utils/getTranslation.new';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormattedMessage } from 'react-intl';
import Input from '@mui/material/Input';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import { API_ROOT_CALLS } from '@zert-packages/actions/api';
import { getFormattingTemplates } from '@zert-packages/utils/getFormattingTemplates';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridGap: '6px',
    gridTemplateRows: '1fr',
    height: '100%',
    gridTemplateColumns: '1fr 400px'
  },
  rootInfo: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: 'grey 1px solid'
  },
  caption: {
    float: 'left',
    marginLeft: '15px'
  },
  margin: {
    margin: '12px'
  },
  iconMap: {
    float: 'left',
    marginLeft: '10px',
    marginTop: '2px',
    fontSize: '1.2em'
  },
  label: {
    padding: '2px',
    flex: '1 1 0%'
  },
  greyDot: {
    width: '0px',
    height: '0px',
    margin: '3px',
    padding: '8px',
    display: 'flex',
    backgroundColor: 'rgb(128, 128, 128)'
  },
  greyLine: {
    display: 'flex',
    borderTop: '1px solid gray',
    backgroundColor: 'lightgray'
  },
  internal: {
    flex: '1 1 0%',
    marginTop: '5px'
  },
  labelContainer: {
    flex: '1 1 0%',
    marginTop: '5px'
  },
  previewContainer: {
    flex: '5 1 0%',
    marginTop: '5px'
  },
  center: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});
function StatusColor({ info }) {
  const formatTemplate = useSelector((state) => state.formatTemplate);
  const [format, setFormat] = useState(null);
  const cl = useStyles();
  let color = 'rgb(128, 128, 128)';
  if (info.approvedAt) {
    color = 'rgb(108, 189, 37)';
  }
  if (info.archivedAt) {
    color = 'rgb(255, 255, 0)';
  }
  if (info.checkedOut && !info.readOnly) {
    color = 'rgb(1, 169, 209)';
  } else if (info.checkedOut && info.readOnly) {
    color = '#E83535';
  }

  useEffect(() => {
    const getStatus = async () => {
      const status = await getFormat(info.versionId);
      setFormat(getFormattingTemplates(status, formatTemplate));
    };
    if (formatTemplate) {
      setFormat(null);
      getStatus();
    }
  }, [info, formatTemplate]);

  return (
    <>
      <div style={{ padding: '2px', flex: '1 1 0%' }}>
        {' '}
        [{info.label}] {format && getTranslation(format.label)}{' '}
      </div>
      <div className={cl.greyDot} style={{ backgroundColor: color }} />
    </>
  );
}
function GeneralTab({ info }) {
  const cl = useStyles();
  const accessToken = window.localStorage.getItem('accessToken');
  return (
    <div className={cl.root}>
      <div className={cl.rootInfo}>
        <div className={cl.internal}>
          <div className={cl.caption}>
            <SvgIcon
              className={cl.iconMap}
              icon={getPluginByMimeType(info.mimeType).icon}
              height="28px"
              margin="5px"
              width="28px"
            />
            {info.name}
          </div>
          <div className={cl.labelContainer} />
        </div>

        {info.mimeType == 'application/zert-image' && (
          <div className={cl.previewContainer}>
            <img
              src={`${API_ROOT_CALLS}/common/getImageById/${info.versionId}?jwtToken=${accessToken}`}
              className={cl.center}
            />
          </div>
        )}

        <div style={{ display: 'flex', borderTop: '1px solid gray', backgroundColor: 'lightgray' }}>
          <StatusColor info={info} />
        </div>
      </div>

      <div className={cl.margin}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={3}>
            <FormControl fullWidth className={cl.margin}>
              <InputLabel htmlFor="standard-adornment-amount">
                <FormattedMessage id="OriginView.Id" defaultMessage="Id" />
              </InputLabel>
              <Input id="standard-adornment-amount" value={info.id} />
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={3}>
            <FormControl fullWidth className={cl.margin}>
              <InputLabel htmlFor="standard-adornment-amount">
                <FormattedMessage id="OriginView.VersionId" defaultMessage="VersionId" />
              </InputLabel>
              <Input id="standard-adornment-amount" value={info.versionId} />
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={3}>
            <FormControl fullWidth className={cl.margin}>
              <InputLabel htmlFor="standard-adornment-amount">
                <FormattedMessage id="OriginView.DateCreated" defaultMessage="Date created" />
              </InputLabel>
              <Input id="standard-adornment-amount" value={info.createdAt} />
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={3}>
            <FormControl fullWidth className={cl.margin}>
              <InputLabel htmlFor="standard-adornment-amount">
                <FormattedMessage id="OriginView.UserCreated" defaultMessage="User created" />
              </InputLabel>
              <Input id="standard-adornment-amount" value={info.createdBy} />
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={3}>
            <FormControl fullWidth className={cl.margin}>
              <InputLabel htmlFor="standard-adornment-amount">
                <FormattedMessage id="OriginView.DateChanged" defaultMessage="Date changed" />
              </InputLabel>
              <Input id="standard-adornment-amount" value={info.changedAt} />
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={3}>
            <FormControl fullWidth className={cl.margin}>
              <InputLabel htmlFor="standard-adornment-amount">
                <FormattedMessage id="OriginView.UserChanged" defaultMessage="User changed" />
              </InputLabel>
              <Input id="standard-adornment-amount" value={info.changedBy} />
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default GeneralTab;
