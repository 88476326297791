import React, { useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TabsContext } from '../../Stylesheet';
import Loading from '../../Loading';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { handleStylesheetParent } from "../../utils/helpers";
import DialogAddElementFilter from "../../dialog/DialogAddElementFilter/DialogAddElementFilter";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";


const useStyles = makeStyles((theme) => ({
  wrapperList: {
    display: "grid",
    gridTemplateColumns: "1fr 25px",
    gridColumnGap: 5,
    height: "100%"
  },
  wrapperEditor: {
    color: "#4db1d3",
    "& svg": {
      cursor: "pointer"
    }
  },
  listWrapper: {
    border: '1px solid #C6C6C5',
    borderRadius: 10,
    overflow: 'hidden',
    height: 'Calc(100% - 34px)'
  },
  listItem: {
    padding: '2px 4px',
    cursor: 'pointer'
  },
  listItemActive: {
    padding: '2px 4px',
    cursor: 'pointer',
    background: '#4db1d3',
    color: '#ffffff'
  },
  arrowImg: {
    position: 'relative',
    top: 5
  }
}));

function ElementFilters() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [selected, setSelected] = useState(0);

  const elementFilters = values.stylesheet?.elementFilters

  const handleSelected = (i) => {
    setSelected(i);
  };

  const addFilter = () => {
    renderInExceptionRoot(DialogAddElementFilter, {title: 'Add Element Filter', setValues})
  }

  const deleteFilters = () => {
    const res = elementFilters.filter((el, i) => i !== selected)
    setSelected(-1)
    handleStylesheetParent(res, "elementFilters",  setValues);
  }


  console.log('values.stylesheet', values.stylesheet);
  console.log('1', elementFilters);

  return (
    <>
      {elementFilters ?
        <div className={cl.wrapperList}>
        <div className={cl.listWrapper}>
          {elementFilters.map((el, i) => (
            <div key={i} className={selected === i ? cl.listItemActive : cl.listItem} onClick={() => handleSelected(i)}>
              {el.mimeType}
            </div>
          ))}
        </div>
          <div className={cl.wrapperEditor}>
            <AddIcon onClick={addFilter} />
            <EditIcon />
            <DeleteIcon onClick={deleteFilters}/>
          </div>
        </div>
       : (
        <Loading />
      )}
    </>
  );
}

export default ElementFilters;
