import { Card, CardHeader, Collapse } from '@mui/material';
import ExpandMoreHandler from '@zert-packages/components/shared/ExpandMoreHandler/ExpandMoreHandler';
import React, { ReactNode, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    margin: '2em 1em'
  },
  cardHeaderRoot: {
    // @ts-ignore
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column'
    },
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      transition: '0.5s ease all'
    }
  },
  cardHeaderAction: {
    marginTop: '0',
    marginBottom: '0'
  },
  collapseRoot: {
    padding: '0 0 1em 0'
  }
}));

interface CatalogCardProps {
  show?: boolean;
  handleShow?: (boolean) => void;
  title: ReactNode;
  action?: ReactNode;
  content: ReactNode;
}

function CatalogCard({ show = true, handleShow, title, action, content }: CatalogCardProps) {
  const [isShow, setIsShow] = useState(show);
  const cl = useStyles();

  const setShow = () => {
    if (handleShow) {
      handleShow(!isShow);
    }
    setIsShow(!isShow);
  };

  return (
    <Card classes={{ root: cl.paperRoot }}>
      <CardHeader
        classes={{ root: cl.cardHeaderRoot, action: cl.cardHeaderAction }}
        title={
          <div onClick={setShow}>
            <ExpandMoreHandler expand={isShow} aria-expanded={isShow} />
            {title}
          </div>
        }
        action={action}
      />
      <Collapse classes={{ root: cl.collapseRoot }} in={isShow} timeout="auto" unmountOnExit>
        {content}
      </Collapse>
    </Card>
  );
}

export default CatalogCard;
