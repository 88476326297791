export const groupData = {
  class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientGroupBlockSettingsTemplate',
  identifier: 'index',
  label: 'Index',
  backgroundColor: null,
  marginLeft: {
    value: 0,
    unit: 'POINTS'
  },
  marginRight: {
    value: 0,
    unit: 'POINTS'
  },
  separatedBlock: false
};
