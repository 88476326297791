import React from "react";
import { makeStyles } from "@mui/styles";
import { FormControlLabel, Checkbox } from "@mui/material";



const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10
  },
  formRoom: {
    marginLeft: 0,
    display: "grid",
    gridTemplateColumns: "13px 1fr",
    gridColumnGap: 11,
    marginBottom: 0,
    "& label": {
      cursor: "pointer",
      marginBottom: 0,
      "& input": {
        marginTop: 4
      }
    }
  },
  wrapperPercents: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  }
}));

const FooterImg = ({hotspots, setHotspots}) => {
  const cl = useStyles();


  const handleCheckbox = (e) => {
    e.stopPropagation()
    setHotspots(pre => ({...pre,hideHotspots: !pre.hideHotspots}))
  }


  return (<div className={cl.root}>
    <div className={cl.wrapperPercents}>
      {hotspots.percents}%
      <FormControlLabel
        className={cl.formRoom}
        label=" Hide hotspots"
        control={
          <Checkbox
            name={'dontBlock'}
            checked={hotspots.hideHotspots}
            onChange={handleCheckbox}
          />}
      />

    </div>
  </div>);
};


export default FooterImg;