import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import BreadCrumbs from "./BreadCrumbs";
import Filter from "./Filter";
import TextFieldItem from "@zert-packages/components/TextFieldItem";
import Button from "@mui/material/Button";
import handleValueState from "@zert-packages/utils/handleValueState";


const useStyles = makeStyles((theme) => ({
  wrapperFind: {
    display: 'grid'
  },
  label: {
    fontWeight: 700,
  },
  wrapperSearch: {
    display: 'flex',
    gap: 15,
    marginBottom: 5,
  },

  button: {
    color: "#ffffff",
    background: "#4db1d3",
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  },

}));



const SearchWrapper = ({browse, setBrowse,search, setSearch, handleSearch}) => {
  const cl = useStyles();
  const selectedTabs = browse.selectedTab === 1 ? 'searchValue' : 'metaSearchValue'

  const handleFindValue = (val, name) => {
    handleValueState(val, name, setBrowse)
  }


  return(<div>
    <div className={cl.wrapperSearch}>
      <div className={cl.label}>Find:</div>
      <TextFieldItem
          values={browse[selectedTabs]}
        // values={search}
        name={selectedTabs}
        setValues={handleFindValue}
        // setValues={setSearch}
      />
      <Button onClick={handleSearch} disabled={browse[selectedTabs].length === 0} className={cl.button}>
        Search
      </Button>
    </div>
    <Filter browse={browse} setBrowse={setBrowse}/>
  </div>)
}

export default SearchWrapper