import makeStyles from "@mui/styles/makeStyles";
import { Dialog } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import DropMenu from "../../DropMenu";
import AddMetaData from "../../AddMetaData";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 300,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700
  },
  wrapper: {},
  wrapperDropMenu: {
    display: 'grid',
    gridTemplateColumns: '75px 1fr',
    gridColumnGap: 10
  },
  titleMenu: {
    fontSize: 12,
  },
  wrapperButton: {
    display: "flex",
    justifyContent: "end",
    marginTop: 20
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

const categoryData = [
  { name: "Komatsu Forest" },
  { name: "Software Texts" },
  { name: "Terminology" },
  { name: "Terminology (default)" },
  { name: "XLIFF" },
  { name: "XLIFF (default)" },
  { name: "Zert" },
  { name: "Zert (default)" }
];
const metadataData = [
  { name: "CO-nummer", description: "Bara numret" },
  { name: "Maskintyp", description: "Huvudprodukterna" },
  { name: "Säkerhetsrelaterat", description: "Ange juhin eller co som orsakar säkerhetsrelaterad text" },
  { name: "Maskinmodell", description: "Maskinmodell" }
];

const DialogAddMetaData = () => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [valueMeta, setValueMeta] = useState({
    selectedCategory: "Komatsu Forest",
    selectedData: null,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    handleClose();
  };

  const handleCategory = (el , name) => {
    setValueMeta(pre => ({...pre, [name]: el.name}))
    console.log('1111', el , name);
  }

  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>Add metadata</span>
      <CloseIcon onClick={handleClose} />
    </div>

    <div className={cl.wrapper}>
      <AddMetaData />

      <div className={cl.wrapperDropMenu}>
        <div className={cl.titleMenu}>Category:</div>
        <DropMenu
          selected={valueMeta.selectedCategory}
          name={'selectedCategory'}
          dropItem={categoryData}
          setDropItem={handleCategory}
        />
      </div>
      <div>
        <div>Metadata:</div>
      </div>
      <div>
        <div>
          Description:
        </div>
      </div>
    </div>

    <div className={cl.wrapperButton}>
      <Button className={cl.button} onClick={handleSave}>
        OK
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>
  </Dialog>);
};

export default DialogAddMetaData;