import React, {useState, useEffect, useRef} from 'react';
import makeStyles from "@mui/styles/makeStyles";
import BurgerMenu from "@zert-packages/components/HeadBar/components/BurgerMenu";



const useStyles = makeStyles((theme) => ({
    rootDynamicRow: {
        display: 'flex',
        alignItems: 'end',
        gap: 5,
    },
    wrapperUpperRow: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        opacity: -100,
        position: 'absolute',
    },
    row: {
        display: 'flex',
        gap: 5,
        alignItems: 'end',
    },
}))

const DynamicRowBurgerMenu = ({items, }) => {
    const cl =useStyles()
    const [visibleElements, setVisibleElements] = useState([]);
    const [overflowElements, setOverflowElements] = useState([]);
    const containerRef = useRef(null);
    const findShowItems = items.filter(el => !el.notShow)
    const buttonWidth= 30 + 11
    const adobeDropMenu = overflowElements.length > 0 && overflowElements.map(el => ({...el, label: el.component}))




    const updateLayout = () => {
        const containerWidth = containerRef.current.offsetWidth;
        let totalWidth = 0;
        const visible = [];
        const overflow = [];

        const elementWidth = document.getElementById(`element-${1}`)?.offsetWidth;
        if (elementWidth && findShowItems.length > 0) {
            findShowItems.forEach((element, index) => {
                const elementWidth = document.getElementById(`element-${index}`).offsetWidth;
                if ((totalWidth + buttonWidth + elementWidth) < containerWidth) {
                    visible.push(element);
                    totalWidth += elementWidth;
                } else {
                    overflow.push(element);
                }
            });
        }

        setVisibleElements(visible);
        setOverflowElements(overflow);
    };

    useEffect(() => {
        if (containerRef.current) {
            updateLayout();
        }
        window.addEventListener('resize', updateLayout);
        return () => {
            window.removeEventListener('resize', updateLayout);
        };
    }, [items, containerRef.current,]);



    return (
        <div   className={cl.rootDynamicRow}  ref={containerRef}>
            <div>
                <div  className={cl.wrapperUpperRow}>
                    {findShowItems.length > 0 && findShowItems.map((el, index) =>
                        <div key={index} id={`element-${index}`} style={{minWidth: 'fit-content'}}>
                            {el.component}
                        </div>
                    )}
                </div>
                <div className={cl.row}>
                    {visibleElements.map((el, index) =>
                        <div key={index} style={{ minWidth: 'fit-content' }}>
                            {el.component}
                        </div>
                    )}
                </div>
            </div>

            {overflowElements.length > 0 && (
                <>
                    <BurgerMenu color={'rgb(24, 142, 199)'}  dropMenu={adobeDropMenu} />
                </>
            )}

        </div>
    );
};

export default DynamicRowBurgerMenu;
