import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "grid",
    gridTemplateColumns: "57px 1fr",
    '& button': {
      borderRadius: '5px !important',
    }
  },
  titleRow: {
    fontWeight: 700
  },

}))

const RowDialog = ({ children, title, style={} }) => {
  const cl = useStyles();

  return (<div className={cl.row} style={style}>
    <div className={cl.titleRow}>
      {title}
    </div>
    <div>
      {children}
    </div>
  </div>);
};

export default RowDialog