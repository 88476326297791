import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TermAccordion from './TermAccordion';

const useStyles = makeStyles((theme) => ({
  scrollWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    margin: '2px',

    '@media (max-width: 900px)': {
      width: '100%',
      height: '60%'
    }
  },
  scrollContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    scrollbarWidth: 'none',
    padding: '16px'
  }
}));

function TermsListWithReferences({ termsData }) {
  const cl = useStyles();

  return (
    termsData &&
    termsData.terms && (
      <div className={cl.scrollWrapper}>
        <div className={cl.scrollContainer}>
          {termsData.terms.map((item, idx) => (
            <TermAccordion
              versionManager={termsData.versionManager}
              handleScrollToItem={termsData.handleScrollToItem}
              versionId={termsData.versionId}
              scrollToItem={termsData.scrollToItem}
              term={item}
              idx={idx}
              key={`term${item.id}`}
            />
          ))}
        </div>
      </div>
    )
  );
}

export default TermsListWithReferences;
