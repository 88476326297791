import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import {
  showSnackbarChangesSaved,
  showSnackbarError,
  showSnackbarLoaderWithMessage
} from '@zert-packages/components/Snackbars';
import { FormattedMessage } from 'react-intl';
import { addElementsToFolderApi } from '@zert-packages/plugins/ExplorerPlugin/API';
import { createInProgressSnack, createOpenReportSnack } from '@zert-packages/components/common/snackBar';
import { reportMockUp } from '@zert-packages/utils/reportMockUp';
import { placeElementNew } from './catalogActions';
import {
  handlePost,
  handlePostFile,
  handleQuery,
  fetchRA_Error,
  fetchPerformMeasureError,
  fetchSTORE_Error,
  fetchElementStored,
  fetchElementStoreStart,
  fetchElementCreated,
  fetchElementCreate,
  fetchElementAproved,
  fetchElementAproveStart,
  fetchRA_Rescent_Success,
  fetchRA_Rescent_Begin,
  handlePostTextAsParam,
  handlePostResultAsString
} from './api';
import {
  LOAD_QUESTINAIRE_TEMPLATES,
  START_LOAD_QUESTINAIRE_TEMPLATES,
  LOAD_QUESTIONAIRE_TEMPLATE,
  CHECKLIST_SUCCESS,
  CHECKLIST_LOADED,
  CHECKLIST_START_LOADING,
  CHECKLIST_UPLOAD_BASIS_BEGIN,
  CHECKLIST_UPLOAD_BASIS,
  CHECKLIST_UPLOAD_BASIS_SUCCESS,
  FETCH_WF_SI_SUCCESS,
  FETCH_QUESTINAIRE_TEMPLATE_SUCCESS,
  FETCH_QUESTINAIRE_TEMPLATE_BEGIN,
  SafetyInspection_START_LOADING,
  SafetyInspection_LOADED,
  SafetyInspection_SUCCESS,
  PERFORM_SafetyInspection_DONE,
  QUESTION_FILE_REMOVE,
  QUESTION_FILE_REMOVE_START,
  LOAD_QUESTIONAIRE_TEMPLATE_UPDATE_DONE,
  LOAD_QUESTIONAIRE_TEMPLATE_UPDATE_START
} from './actions';
import { fetchPerformMeasure } from './measureActions';
import {
  fetchRA_WorkFlowTemplate_Success,
  fetchRA_MeasureTemplate_Success,
  fetchRA_Template_Success,
  fetchRA_overview_Success,
  fetch_RA_overview_DONE,
  fetch_RA_overview_BEGIN
} from './riskassesmentActions';

const fetchSI_WorkFlowTemplate_Success = (workflowtemplate) => ({
  type: FETCH_WF_SI_SUCCESS,
  payload: { workflowtemplate }
});

export const checkReportStatus = (report, snackbar, generatingSnackbar, generatedReport, mimeType) => {
  handleQuery(`/report-react/updateProgressStatus/${report.callbackId}/false`)
    .then((newReport) => {
      if (newReport !== null && newReport.active) {
        checkReportStatus(newReport, snackbar, generatingSnackbar, generatedReport, mimeType);
      } else {
        generatedReport.reportUUID = report.callbackId;
        snackbar.closeSnackbar(generatingSnackbar);
        createOpenReportSnack(snackbar, generatedReport, mimeType);
      }
    })
    .catch((error) => {
      snackbar.closeSnackbar(generatingSnackbar);
      showSnackbarError(snackbar, error);
    });
};

export const fetch_QuestionaireTemplate_Success = (questionairetemplate) => ({
  type: LOAD_QUESTIONAIRE_TEMPLATE,
  payload: { questionairetemplate }
});

export const fetchCheckList_Success = (checklist) => ({
  type: CHECKLIST_SUCCESS,
  payload: { checklist }
});

const fetchCheckList_Loaded = () => ({
  type: CHECKLIST_LOADED
});

const fetchQTEMPLATE_SUCCESS = () => ({
  type: FETCH_QUESTINAIRE_TEMPLATE_SUCCESS
});

const fetchQTEMPLATE_BEGIN = () => ({
  type: FETCH_QUESTINAIRE_TEMPLATE_BEGIN
});

const fetchCheckList_Begin = (loading) => ({
  payload: { loading },
  type: CHECKLIST_START_LOADING
});

const fetchQuestionUpload_Begin = () => ({
  type: CHECKLIST_UPLOAD_BASIS_BEGIN
});

const fetchQuestionUpload = (basis) => ({
  type: CHECKLIST_UPLOAD_BASIS,
  payload: { basis }
});

const fetchQuestionUpload_DONE = () => ({
  type: CHECKLIST_UPLOAD_BASIS_SUCCESS,
  payload: {}
});

const fetchSafetyInspection_Begin = (loading) => ({
  type: SafetyInspection_START_LOADING,
  payload: { loading }
});

const fetchSafetyInspection_Loaded = () => ({
  type: SafetyInspection_LOADED
});

const fetchSafetyInspection_Success = (safetyinspection) => ({
  type: SafetyInspection_SUCCESS,
  payload: { safetyinspection }
});

const fetchSafetyInspectionPerformed = (updated_safetyinspection) => ({
  type: PERFORM_SafetyInspection_DONE,
  payload: { updated_safetyinspection }
});

const fetchQuestionaireTemplatesSuccess = (templateslist) => ({
  type: LOAD_QUESTINAIRE_TEMPLATES,
  payload: { templateslist }
});

const fetch_QuestionaireTemplateUpdated_Success = (questionairetemplate) => ({
  type: LOAD_QUESTIONAIRE_TEMPLATE_UPDATE_DONE,
  payload: { questionairetemplate }
});

const fetchQuestionaireTemplateUpdated_Begin = () => ({
  type: LOAD_QUESTIONAIRE_TEMPLATE_UPDATE_START,
  payload: {}
});
const fetchQuestionaireTemplatesStart = () => ({
  type: START_LOAD_QUESTINAIRE_TEMPLATES
});
const removeQuestionFileAction = (versionId, categoryId, questionId, basisId) => ({
  type: QUESTION_FILE_REMOVE,
  payload: {
    versionId,
    categoryId,
    questionId,
    basisId
  }
});

const removeQuestionFileStart = () => ({
  type: QUESTION_FILE_REMOVE_START,
  payload: {}
});

export const PREPARE_STATE_FOR_NEW_CHECKLIST = 'PREPARE_STATE_FOR_NEW_CHECKLIST';
const prepareStateForNewChecklist = () => ({
  type: PREPARE_STATE_FOR_NEW_CHECKLIST
});

export function storeTemplate(companyId, versionId, template) {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return handlePostResultAsString(`/checklist-react/storeTemplate/${companyId}/${locale}/${versionId}`, template);
}

export async function removeQuestionFileAsync(versionId, categoryId, questionId, basisId) {
  const remove = await handleQuery(
    `/checklist-react/removeQuestionFile/${versionId}/${categoryId}/${questionId}/${basisId}`
  );
  return remove;
}

export function removeQuestionFile(versionId, categoryId, questionId, basisId) {
  return function (dispatch) {
    handleQuery(`/checklist-react/removeQuestionFile/${versionId}/${categoryId}/${questionId}/${basisId}`)
      .then(() => {
        dispatch(removeQuestionFileAction(versionId, categoryId, questionId, basisId));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(removeQuestionFileStart());
  };
}

export const initQuestionnaireTemplateOverload =
  (scope) =>
  async ({ name }) => {
    const promise = await handlePost('/checklist-react/initQuestionnaireTemplate', {
      name,
      scope: scope ? 'CHECKLIST' : 'SAFETY_INSPECTION'
    });
    return promise;
  };

export async function initQuestionnaireTemplate(questionnaireTemplate) {
  const promise = await handlePost('/checklist-react/initQuestionnaireTemplate', questionnaireTemplate);
  return promise;
}

export function storeSafetyInspection(id, data) {
  return function (dispatch) {
    handlePost(`/checklist-react/storeSafetyInspection/${id}`, data)
      .then((id) => {
        dispatch(fetchElementStored(id));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function storeChecklistFast(id, categoryid, questionid, data, showStoreLoader) {
  const getValidBasises = () =>
    _.get(data, 'basises', []).map((basis) => {
      const element = basis.element || null;
      // saving without properties require page reload
      // if (element) {
      //   delete element.properties;
      // }
      return {
        ...basis,
        element
      };
    });
  const validData = {
    ...data,
    basises: getValidBasises()
  };
  return function (dispatch, getState, hooks) {
    const snackbarId = showSnackbarLoaderWithMessage(
      hooks.snackbar,
      <FormattedMessage id="HoverWithLoader.SnackbarMessage" defaultMessage="Loading..." />
    );
    handlePost(`/checklist-react/storeCheckList/${id}/${categoryid}/${questionid}`, validData)
      .then((id) => {
        hooks.snackbar.closeSnackbar(snackbarId);
        showSnackbarChangesSaved(hooks.snackbar);

        dispatch(fetchElementStored(id));
        if (showStoreLoader) {
          dispatch(loadChecklist(id, true));
        }
      })
      .catch((error) => {
        dispatch(fetchSTORE_Error(error));
        showSnackbarError(
          hooks.snackbar,
          <FormattedMessage
            id="ChecklistActions.Snackbar.ChecklistNotSaved"
            defaultMessage="Oops... Something went wrong"
          />
        );
      });
    return dispatch(fetchElementStoreStart());
  };
}

export function loadRescentElements(showCurrent, showPast) {
  return function (dispatch) {
    handleQuery(`/checklist-react/rescent/${showCurrent}/${showPast}`)
      .then((riskanalises) => {
        dispatch(fetchRA_Rescent_Success(riskanalises));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchRA_Rescent_Begin());
  };
}

export function loadRescentSafetyInspections(showCurrent, showPast) {
  return function (dispatch) {
    handleQuery(`/checklist-react/rescent/safety-inspections/${showCurrent}/${showPast}`)
      .then((riskanalises) => {
        dispatch(fetchRA_Rescent_Success(riskanalises));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchRA_Rescent_Begin());
  };
}

export function storeChecklist(id, data) {
  return function (dispatch) {
    handlePost(`/checklist-react/storeCheckList/${id}`, data)
      .then((id) => {
        dispatch(fetchElementStored(id));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function checkIfQuestionnaryTemplateUpdated(safetyInspectionVersionId) {
  return function (dispatch) {
    handleQuery(`/checklist-react/checkIfQuestionnaryTemplateUpdated/${safetyInspectionVersionId}`)
      .then((questionaireTemplate) => {
        dispatch(fetch_QuestionaireTemplateUpdated_Success(questionaireTemplate));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchQuestionaireTemplateUpdated_Begin());
  };
}

export function updateQuestionnaryTemplate(safetyInspectionElementId, templateVersionId) {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return function (dispatch) {
    handleQuery(`/checklist-react/updateQuestionnaryTemplate/${safetyInspectionElementId}/${templateVersionId}`)
      .then((safetyInspection) => {
        dispatch(fetchSafetyInspection_Success(safetyInspection));
        return handleQuery(`/checklist-react/getTemplates/${locale}/${safetyInspection.template.versionId}`);
      })
      .then((templateBundle) => {
        dispatch(fetch_QuestionaireTemplate_Success(templateBundle.questionnaireTemplate));
        dispatch(fetchRA_WorkFlowTemplate_Success(templateBundle.measuresWorkflowProcess));
        dispatch(fetchRA_MeasureTemplate_Success(templateBundle.measureTemplate));
        dispatch(fetchRA_Template_Success(templateBundle.riskRowTemplate));
        dispatch(fetchSI_WorkFlowTemplate_Success(templateBundle.workflowProcess));
        dispatch(fetchSafetyInspection_Loaded());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchSafetyInspection_Begin(false));
  };
}

export function loadSafetyInspection(id, loading) {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return function (dispatch) {
    handleQuery(`/checklist-react/loadSafetyInspection/${locale}/${id}`)
      .then((safetyinspection) => {
        dispatch(fetchSafetyInspection_Success(safetyinspection));
        return handleQuery(`/checklist-react/getTemplates/${locale}/${safetyinspection.template.versionId}`);
      })
      .then((templateBundle) => {
        dispatch(fetch_QuestionaireTemplate_Success(templateBundle.questionnaireTemplate));
        dispatch(fetchRA_WorkFlowTemplate_Success(templateBundle.measuresWorkflowProcess));
        dispatch(fetchRA_MeasureTemplate_Success(templateBundle.measureTemplate));
        dispatch(fetchRA_Template_Success(templateBundle.riskRowTemplate));
        dispatch(fetchSI_WorkFlowTemplate_Success(templateBundle.workflowProcess));
        dispatch(fetchSafetyInspection_Loaded());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchSafetyInspection_Begin(loading));
  };
}

export function loadChecklist(id, loading, storeFullRiskRows=true) {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return function (dispatch, getState) {
    if (getState().store_element) {
      setTimeout(() => {
        dispatch(loadChecklist(id, loading, storeFullRiskRows));
      }, 250);
      return;
    }
    dispatch(prepareStateForNewChecklist());
    handleQuery(`/checklist-react/loadCheckList/${id}?storeFullRiskRows=${storeFullRiskRows}`)
      .then((checklist) => {
        dispatch(fetchCheckList_Success(checklist));
        return handleQuery(`/checklist-react/getTemplates/${locale}/${checklist.template.versionId}`);
      })
      .then((templateBundle) => {
        dispatch(fetch_QuestionaireTemplate_Success(templateBundle.questionnaireTemplate));
        dispatch(fetchRA_WorkFlowTemplate_Success(templateBundle.measuresWorkflowProcess));
        dispatch(fetchRA_MeasureTemplate_Success(templateBundle.measureTemplate));
        dispatch(fetchRA_Template_Success(templateBundle.riskRowTemplate));
        dispatch(fetchCheckList_Loaded());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchCheckList_Begin(loading));
  };
}

export function loadCompanyQuestinnaireTemplatesAsync(isChecklist, isApproved) {
  return handleQuery(`/checklist-react/getCompanyTemplates/${isChecklist}/${isApproved}`);
}

export function loadQuestinaireTemplatesAsync(isChecklist, excludeArchived=true) {
  return handleQuery(`/checklist-react/getAllTemplates/${isChecklist}?excludeArchived=${excludeArchived}`);
}

export function loadQuestinaireTemplates(isChecklist, loadFirstTemplate, noTemplatesCallback,
                                         excludeArchived=true) {
  return function (dispatch) {
    handleQuery(`/checklist-react/getAllTemplates/${isChecklist}?excludeArchived=${excludeArchived}`)
      .then((templates) => {
        dispatch(fetchQuestionaireTemplatesSuccess(templates));
        if (templates != null && loadFirstTemplate && typeof templates[0] !== 'undefined') {
          const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
          return handleQuery(`/checklist-react/getTemplates/${locale}/${templates[0].versionId}`);
        }
        if (typeof noTemplatesCallback === 'function') noTemplatesCallback();
        return null;
      })
      .then((templateBundle) => {
        if (templateBundle) {
          dispatch(fetch_QuestionaireTemplate_Success(templateBundle.questionnaireTemplate));
          dispatch(fetchRA_WorkFlowTemplate_Success(templateBundle.measuresWorkflowProcess));
          dispatch(fetchRA_MeasureTemplate_Success(templateBundle.measureTemplate));
          dispatch(fetchRA_Template_Success(templateBundle.riskRowTemplate));
          dispatch(fetchSI_WorkFlowTemplate_Success(templateBundle.workflowProcess));
          dispatch(fetchQTEMPLATE_SUCCESS());
        }
      })
      .catch((error) => {
        dispatch(fetchRA_Error(error));
        dispatch(fetchQTEMPLATE_SUCCESS());
      });
    return dispatch(fetchQuestionaireTemplatesStart());
  };
}

export function loadQuestinaireTemplateOnce(idTemplate) {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return function (dispatch) {
    handleQuery(`/checklist-react/getTemplates/${locale}/${idTemplate}`)
      .then((templateBundle) => {
        dispatch(fetch_QuestionaireTemplate_Success(templateBundle.questionnaireTemplate));
        dispatch(fetchRA_WorkFlowTemplate_Success(templateBundle.measuresWorkflowProcess));
        dispatch(fetchRA_MeasureTemplate_Success(templateBundle.measureTemplate));
        dispatch(fetchRA_Template_Success(templateBundle.riskRowTemplate));
        dispatch(fetchSI_WorkFlowTemplate_Success(templateBundle.workflowProcess));
        dispatch(fetchQTEMPLATE_SUCCESS());
      })
      .catch((error) => {
        dispatch(fetchRA_Error(error));
        dispatch(fetchQTEMPLATE_SUCCESS());
      });
    return dispatch(fetchQTEMPLATE_BEGIN());
  };
}

export function loadQuestinaireTemplate(companyId, idTemplate) {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return function (dispatch) {
    handleQuery(`/checklist-react/getTemplate/${companyId}/${locale}/${idTemplate}`)
      .then((questionnaireTemplate) => {
        dispatch(fetch_QuestionaireTemplate_Success(questionnaireTemplate));
      })
      .catch((error) => {
        dispatch(fetchRA_Error(error));
      });
    return dispatch(fetchQTEMPLATE_BEGIN());
  };
}

export function initSafetyInspection(id, data, placement1, folderId, versionId, activityId) {
  let idElement = -1;
  return function (dispatch) {
    handlePost(`/checklist-react/initSafetyInspection/${id}`, data)
      .then((info) => {
        idElement = info.versionId;
        if (folderId) {
          return addElementsToFolderApi(folderId, [info.versionId]);
        }
        return null;
      })
      .then(() => {
        return placeElementNew(idElement, placement1);
      })
      .then(() => {
        dispatch(fetchElementCreated(idElement, versionId, activityId));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchElementCreate());
  };
}

export function initChecklist(idTemplate, data, placement1, folderId, versionId, activityId) {
  let idElement = -1;
  return function (dispatch) {
    handlePost(`/checklist-react/initCheckList/${idTemplate}`, data)
      .then((id) => {
        idElement = id;
        if (folderId) {
          return addElementsToFolderApi(folderId, [idElement]);
        }
        return null;
      })
      .then(() => {
        return placeElementNew(idElement, placement1);
      })
      .then(() => {
        dispatch(fetchElementCreated(idElement, versionId, activityId));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchElementCreate());
  };
}

export const approveChecklist = (id, data) => (dispatch, getState, hooks) => {
  handlePost(`/checklist-react/approveCheckList/${id}`, data)
    .then((id) => {
      showSnackbarChangesSaved(hooks.snackbar);
      dispatch(fetchElementAproved(id));
    })
    .catch((error) => {
      showSnackbarError(hooks.snackbar, error.message);
      dispatch(fetchElementAproved(id));
    });
  return dispatch(fetchElementAproveStart());
};

export const performSafetyInspection = (taskId, nextTransition) => (dispatch, getState, hooks) => {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');

  handleQuery(`/actionplan-react/performSafetyInspection/${locale}/${taskId}/${nextTransition}`, {})
    .then((safetyInspection) => {
      showSnackbarChangesSaved(hooks.snackbar);
      dispatch(fetchSafetyInspectionPerformed(safetyInspection));
    })
    .catch((error) => {
      dispatch(fetchPerformMeasureError(error));
      showSnackbarError(hooks.snackbar, error.message);
    });
  return dispatch(fetchPerformMeasure());
};

export function uploadBasisAnswerAsync(filedata, name, versionId, categoryId, questionId) {
  return handlePostFile(`/checklist-react/uploadQuestionFile/${versionId}/${categoryId}/${questionId}`, filedata);
}

export function uploadBasisAnswer(filedata, name, versionId, categoryId, questionId) {
  return function (dispatch) {
    handlePostFile(`/checklist-react/uploadQuestionFile/${versionId}/${categoryId}/${questionId}`, filedata)
      .then((basis) => {
        dispatch(fetchQuestionUpload(basis));
        dispatch(fetchQuestionUpload_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchQuestionUpload_Begin());
  };
}

export const generateSingleSafetyInspectionReport =
  (versionId, report) =>
  (dispatch, getState, { snackbar }) => {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="RiskAssement.GeneratingLabelCommon" defaultMessage="...generating report" />
    );
    const newReport = {
      ...report,
      reportUUID: uuidv4()
    };
    handlePost(
      `/report-react/generateReport/${newReport.reportUUID}/checklist_generateSafetyInspectionOverviewReport`,
      { ...reportMockUp, versionId, locale }
    )
      .then((callback) => {
        checkReportStatus(callback, snackbar, generatingSnackbar, newReport, 'pdf');
      })
      .catch((error) => {
        showSnackbarError(snackbar, error);
      });
  };

export const generateSafetyInspectionReport =
  (versionId, report, catalogVersionId, nodeUUID, scope, reportSettings) =>
    (dispatch, getState, { snackbar }) => {
      const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
      const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RiskAssement.GeneratingLabelCommon" defaultMessage="...generating report" />
      );
      const newReport = {
        ...report,
        reportUUID: uuidv4()
      };
      handlePost(
        `/report-react/generateReport/${newReport.reportUUID}/checklist_generateGeneralSafetyInspectionOverviewReport`,
        { ...reportMockUp, versionId, locale, catalogVersionId, nodeUUID, scope,
          object: reportSettings
        }
      )
        .then((callback) => {
          checkReportStatus(callback, snackbar, generatingSnackbar, newReport, 'pdf');
        })
        .catch((error) => {
          showSnackbarError(snackbar, error);
        });
    };

export function loadQuestionaireTemplate(id) {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return function (dispatch) {
    handleQuery(`/checklist-react/loadQuestionaireTemplate/${locale}/${id}`)
      .then((templateBundle) => {
        dispatch(fetch_QuestionaireTemplate_Success(templateBundle));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetch_RA_overview_BEGIN(id));
  };
}

export function loadChecklistOverviewById(id) {
  let checklistT = null;
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return function (dispatch) {
    handleQuery(`/checklist-react/loadCheckList/${id}`)
      .then((checklist) => {
        checklistT = checklist;
        dispatch(fetchCheckList_Success(checklist));
        return handleQuery(`/checklist-react/getTemplates/${locale}/${checklist.template.versionId}`);
      })
      .then((templateBundle) => {
        checklistT.riskRowTemplate = templateBundle.riskRowTemplate;
        dispatch(fetch_QuestionaireTemplate_Success(templateBundle.questionnaireTemplate));
        dispatch(fetchRA_WorkFlowTemplate_Success(templateBundle.measuresWorkflowProcess));
        dispatch(fetchRA_MeasureTemplate_Success(templateBundle.measureTemplate));
        dispatch(fetchRA_Template_Success(templateBundle.riskRowTemplate));
        dispatch(fetchCheckList_Loaded());
        return handleQuery(`/checklist-react/calculateOverview/${id}`);
      })

      .then((riskrowoverview) => {
        dispatch(fetchRA_overview_Success(riskrowoverview));
        dispatch(fetch_RA_overview_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetch_RA_overview_BEGIN(id));
  };
}

export const generateChecklistOverviewReport =
  (report, catalogVersionId, nodeUUID, includeSubNodes, diagramid, includeMatrix, showImagesSeparatly, versionIds) =>
  (dispatch, getState, { snackbar }) => {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="ChecklistOverview.GeneratingLabel" defaultMessage="...generating report" />
    );
    const newReport = {
      ...report,
      reportUUID: uuidv4()
    };

    /*  {versionId : -1,
   chartTypes : 0,
   includeMatrix : false,
   showImagesSeparatly : false,
    isPrintReport : false,
   catalogVersionId : -1,
   nodeUUID : "",
      locale,
  includeSubNodes,
  scope,
  riskanalisesscope,
   selectedNodeUUID,
  includeStatistics : false,
  includeArchived: false,
   pageBreakBeforeNodes: false,
  reportNr : 1,
  object}
  */

    handlePost(`/report-react/generateReport/${newReport.reportUUID}/checklist_generateChecklistReportOverview`, {
      ...reportMockUp,
      versionId: -1,
      chartTypes: diagramid,
      catalogVersionId,
      nodeUUID: nodeUUID || 'none',
      scope: includeSubNodes,
      includeMatrix,
      showImagesSeparatly,
      locale,
      object: JSON.stringify(versionIds)
    })
      .then((callback) => {
        checkReportStatus(callback, snackbar, generatingSnackbar, newReport, 'pdf');
      })

      .catch((error) => {
        showSnackbarError(snackbar, error);
      });
  };

export const generateXLSChecklistsReport =
  (report, catalogVersionId, nodeUUID, scope, includeAll, startDate, endDate,
   template, clientColumns, versionIds) =>
  (dispatch, getState, { snackbar }) => {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage
        id="RiskAssement.GeneratingLabel.checklist"
        defaultMessage="...Exporting checklists to SpreadSheet"
      />
    );

    //      handlePost(`/checklist-react/generateCheckListXLSReport/${report.reportUUID}/${catalogVersionId}/${nodeUUID}/${scope}/${includeAll}`, { startDate,
    // endDate,
    // clientColumns})aa
    handlePost(`/report-react/generateReport/ ${report.reportUUID}/exportChecklistXLS`, {
      ...reportMockUp,
      catalogVersionId,
      nodeUUID: nodeUUID || 'none',
      scope,

      includeArchived: includeAll,
      locale,
      object: JSON.stringify({
        reportSettings: {
          startDate,
          endDate,
          templateElementId: template,
          clientColumns
        },
        versionIds
      })
    })
      .then((callback) => {
        checkReportStatus(callback, snackbar, generatingSnackbar, report, 'xls', null, null, 'export');
      })

      .catch((error) => {
        showSnackbarError(snackbar, error);
      });
  };

export const generateChecklistReport =
  (versionId, report, diagramid, includeMatrix, showImagesSeparatly, isPrint,
   versionIds) =>
  (dispatch, getState, { snackbar }) => {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="RiskAssement.GeneratingLabelCommon" defaultMessage="...generating report" />
    );
    const newReport = {
      ...report,
      reportUUID: uuidv4()
    };
    handlePost(`/report-react/generateReport/${newReport.reportUUID}/checklist_generateCheckListReport`, {
      ...reportMockUp,
      versionId,
      chartTypes: diagramid,

      isPrintReport: isPrint,
      includeMatrix,
      showImagesSeparatly,
      locale,
      object: JSON.stringify(versionIds)
    })
      .then((callback) => {
        checkReportStatus(callback, snackbar, generatingSnackbar, newReport, 'pdf');
      })

      .catch((error) => {
        showSnackbarError(snackbar, error);
      });
  };

export const generateSafetyInspectionOverviewReport =
  (
    report,
    catalogVersionId,
    nodeUUID,
    scope,

    data
  ) =>
  (dispatch, getState, { snackbar }) => {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    if (data.includeMode && data.includeMode.value) {
      data.includeMode = data.includeMode.value;
    }
    const newReport = {
      ...report,
      reportUUID: uuidv4()
    };
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="SafetyInspectionOverview.GeneratingLabel" defaultMessage="...generating report" />
    );

    handlePost(
      `/report-react/generateReport/${newReport.reportUUID}/checklist_generateGeneralSafetyInspectionOverviewReport`,
      {
        ...reportMockUp,
        catalogVersionId,
        nodeUUID: nodeUUID || 'none',
        scope: scope,
        locale,
        object: JSON.stringify(data)
      }
    )
      .then((callback) => {
        checkReportStatus(callback, snackbar, generatingSnackbar, newReport, 'pdf');
      })
      .catch((error) => {
        showSnackbarError(snackbar, error);
      });
  };

export function searchElements(showPast, search) {
  return function (dispatch) {
    handlePostTextAsParam(`/checklist-react/search/${showPast}`, search)
      .then((riskanalises) => {
        dispatch(fetchRA_Rescent_Success(riskanalises));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchRA_Rescent_Begin());
  };
}

export function searchSafetyElements(showPast, search) {
  return function (dispatch) {
    handlePostTextAsParam(`/checklist-react/search/safety-inspections/${showPast}`, search)
      .then((riskanalises) => {
        dispatch(fetchRA_Rescent_Success(riskanalises));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchRA_Rescent_Begin());
  };
}
