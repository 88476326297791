import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const publishingTableStyles = (theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: '10px'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary
    },
    title: {
      fontSize: '1rem'
    },
    formControl: {
      minWidth: 120
    },
    card: {
      margin: '10px',
      padding: '10px'
    },
    avatar: {
      backgroundColor: '#c73c3c'
    }
  });

const useStyles = makeStyles(publishingTableStyles);
export default useStyles;
