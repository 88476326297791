import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarProvider } from "notistack";


export const hooksInMiddleware = {
  snackbar: {},
  history: {},
  dispatch: {}
};

export const CloseButton = ({onClick, htmlColor = 'white'}) =>
  <IconButton size="small" onClick={onClick}>
    <CloseIcon htmlColor={htmlColor}/>
  </IconButton>

const showSnackbarErrorAxios = (snackbar, message, persist = false, variant = 'info', autoHideDuration = 2000,) => {
  const snackbarKey = new Date().valueOf();
  snackbar.enqueueSnackbar(message ? message : 'Error', {
    autoHideDuration: (autoHideDuration),
    anchorOrigin: {horizontal: "right", vertical: "top"},
    style: {
      zIndex: 99999,
    },
    key: snackbarKey,
    hideiconvariant: 'false',
    variant: variant,
    persist: persist,
    action: (<div>
        <CloseButton
          onClick={() => {
            snackbar.closeSnackbar(snackbarKey);
          }}
        />
    </div>
    )
  })

  return snackbarKey;
};



export const errorSnackBar = (message) => {


  return (<SnackbarProvider >
    {showSnackbarErrorAxios(hooksInMiddleware.snackbar, message, false, "error", 3000)}
  </SnackbarProvider>)
}