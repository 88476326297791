import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Tab, Tabs } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  wrapperTabs: {
    "& .MuiTab-textColorPrimary": {
      padding: "5px 10px",
      minWidth: "fit-content",
      minHeight: 26,
      height: 26,
      outline: "none",
      "&:hover": {
        background: "#4db1d3 ",
        color: "#ffffff"
      }
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      background: "#4db1d3 ",
      color: "#ffffff"
    },
    "& .MuiTabs-centered": {
      justifyContent: "left",
      width: '100%',
    },
    "& .MuiTabs-root": {
      minHeight: 26,
      marginBottom: 10
    },
    "& .PrivateTabIndicator-colorSecondary": {
      display: "none"
    }
  }
}));

const TabsItem = ({ selectedTab, tabs, setTab,  }) => {
  const cl = useStyles();
  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  return (<div className={cl.wrapperTabs}>
    <Tabs
      value={selectedTab}
      onChange={handleTab}
      indicatorColor=""
      textColor="primary"
      variant="standard"
      scrollButtons="auto"
      centered
    >
      {tabs && tabs.length > 0 && tabs.map((el, i) =>
        <Tab label={el.name} key={i} />
      )}
    </Tabs>
  </div>);
};

export default TabsItem;