// tag::vars[]
import React from 'react';
// const React = require('react');
// const ReactDOM = require('react-dom');
import TaskMembersEditor from '@zert-packages/components/common/Task/TaskMembersEditor';
import DeadlineList from '@zert-packages/components/common/Task/DeadlineList';
import IntervalEditor from '@zert-packages/components/common/Task/IntervalEditor';

import { FormattedMessage } from 'react-intl';

export default class DeadlinePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { idCheckList: -1 };
    this.onEarliestStartChange = this.onEarliestStartChange.bind(this);
  }

  componentDidMount() {}

  onEarliestStartChange(unit, amount) {
    this.props.earlieststart.amount = amount;
    this.props.earlieststart.unit = unit;
    if (this.props.onModification) {
      this.props.onModification()
    }
  }

  render() {
    return (
      <div className="content-group deadline-page">
        <div className="text-box">
          <TaskMembersEditor
            participantMappings={this.props.participantMappings}
            versionInfo={this.props.task}
            disabled={this.props.disabled}
            onModification={this.props.onModification}
          />
          <DeadlineList
            deadlinemappings={this.props.remindDeadlineMappings}
            task={this.props.task}
            disabled={this.props.disabled}
            onModification={this.props.onModification}
          />
          <div className="captionLabel">
            <FormattedMessage id="safetyinspection.deadline" defaultMessage="Earliest start date(before deadline)" />
          </div>
          <IntervalEditor
            interval={this.props.earlieststart}
            onChange={this.onEarliestStartChange}
            disabled={this.props.disabled}
          />
        </div>
      </div>
    );
  }
}
