import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TabsContext } from '../../../../Stylesheet';
import PageFormat from '../../pageLayout/PageFormat';
import GroupBlock from '../../pageLayout/GroupBlock';
import { handlePageLayout, handlePageLayoutSingleLevel } from '../../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10,
    minHeight: 210,
    '& div': {
      gridRowGap: '5px !important'
    }
  }
}));

function Page({ cover }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);

  const data = {
    dropFormat: ['A3', 'A4', 'A5', 'Letter', 'Legal', 'User'],
    Orientation: ['Portrait', 'Landscape']
  };

  const gropOffsetProps = [
    { label: 'Left:', value: cover.cropOffsetLeft, type: 'cropOffsetLeft' },
    { label: 'Right:', value: cover.cropOffsetRight, type: 'cropOffsetRight' },
    { label: 'Top:', value: cover.cropOffsetTop, type: 'cropOffsetTop' },
    { label: 'Bottom:', value: cover.cropOffsetBottom, type: 'cropOffsetBottom' }
  ];
  const bleedProps = [
    { label: 'Left:', value: cover.bleedLeft, type: 'bleedLeft' },
    { label: 'Right:', value: cover.bleedRight, type: 'bleedRight' },
    { label: 'Top:', value: cover.bleedTop, type: 'bleedTop' },
    { label: 'Bottom:', value: cover.bleedBottom, type: 'bleedBottom' }
  ];

  const handleValuesBlockMargins = (val, name) => {
    handlePageLayout(val, name, values, setValues);
  };

  const handleDropMenu = (valDropMenu, name, val) => {
    handlePageLayout(val, name, values, setValues, valDropMenu);
  };

  const handleCheckboxGroupBlock = (val) => {
    handlePageLayoutSingleLevel(val, 'cropMarksVisible', values, setValues);
  };

  const handleCheckbox = (val) => {
    handlePageLayoutSingleLevel(val, 'blankHeadersAndFooters', values, setValues);
  };

  return (
    <>
      {cover && <PageFormat pageFormat={data} />}
      <div style={{ marginBottom: 10 }} />
      <div className={cl.wrapperRow}>
        <GroupBlock
          label="gropOffset"
          valueProps={gropOffsetProps}
          checked={{ value: cover.cropMarksVisible, label: 'Insert crop marks' }}
          setValueProps={handleValuesBlockMargins}
          setDropMenu={handleDropMenu}
          setCheckbox={handleCheckboxGroupBlock}
        />
        <GroupBlock
          label="Bleed"
          valueProps={bleedProps}
          style={{ height: 'Calc(100% - 26px)', gridAutoRows: 'min-content' }}
          setValueProps={handleValuesBlockMargins}
          setDropMenu={handleDropMenu}
          setCheckbox={handleCheckbox}
        />
      </div>
    </>
  );
}

export default Page;
