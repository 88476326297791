const selectiveMemoComparison =
  (...compareFunctions) =>
  (prevProps, nextProps) => {
    for (let compareFuncIndex = 0; compareFuncIndex < compareFunctions.length; compareFuncIndex++) {
      const compareFunc = compareFunctions[compareFuncIndex];
      if (compareFunc(prevProps) !== compareFunc(nextProps)) return false;
    }
    return true;
  };

export default selectiveMemoComparison;
