import React, { useEffect, useRef } from 'react';

export default function CustomJoyrideStart(props) {
  const ref = useRef();
  useEffect(() => {
    if (typeof ref.current.click === 'function') {
      ref.current.click();
    }
  });
  return <span ref={ref} onClick={props.onClick} />;
}
