import React, { Fragment } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import IconButton from '@mui/material/IconButton';
import { FormattedMessage } from 'react-intl';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import EditIcon from '@mui/icons-material/Edit';
import { API_ROOT_WAR } from '@zert-packages/actions/api';
import Accordion from '@zert-packages/components/shared/Accordion';
import MatrixSelectDialog from './MatrixSelectDialog';

class InfoTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixSelected: [],
      active: false,
      editMatrixes: false
    };
    this.fieldStateChanged = (field, fieldvalue) => (state) =>
      this.setState({
        [field]: state.errors.length === 0,
        [fieldvalue]: state.value
      });

    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectMatrixes = this.selectMatrixes.bind(this);

    this.storeMatrixes = this.storeMatrixes.bind(this);
    this.closeMatrix = this.closeMatrix.bind(this);
    this.openTemplate = this.openTemplate.bind(this);
  }

  componentDidMount() {
    const { template } = this.props;
    if (template.properties.disabled) {
      this.setState({ active: false });
    } else {
      this.setState({ active: true });
    }
  }

  handleMultiSelectChange(matrix) {}

  handleChange = (name) => (event) => {
    if (this.state.active) {
      this.props.template.properties.disabled = true;
    } else {
      this.props.template.properties.disabled = false;
    }
    this.setState({ [name]: !this.state.active });
    if (this.props.handleChange) {
      this.props.handleChange(event);
    }
  };

  selectMatrixes() {
    this.setState({ editMatrixes: true });
  }

  storeMatrixes() {
    this.setState({ editMatrixes: false });
    this.forceUpdate();
  }

  closeMatrix() {
    this.setState({ editMatrixes: false });
  }

  openTemplate() {
    const { template, history } = this.props;
    history.push(`${API_ROOT_WAR}/questionnaireTemplate/${this.props.companyId}/${template.versionId}`);
  }

  render() {
    const { template, showMatrixes, companyId, allowEditTemplate } = this.props;
    const { editMatrixes } = this.state;

    return (
      <>
        {editMatrixes && (
          <MatrixSelectDialog
            showDialog={this.state.editMatrixes}
            closeMatrix={this.closeMatrix}
            storeMatrixes={this.storeMatrixes}
            companyId={companyId}
            template={template}
            templateId={template.id}
          />
        )}

        <tr className="notSelectedTableRow">
          {/*  {allowEditTemplate && <td><div><IconButton aria-label="Edit template" onClick={(e)=>{this.openTemplate()}}><EditIcon onClick={(e)=>{this.openTemplate()}}/></IconButton></div></td>} */}
          <td>
            <div>{template.name}</div>
          </td>
          <td>
            <div>
              <Checkbox
                checked={this.state.active}
                onChange={this.handleChange('active')}
                value={this.state.active}
                color="primary"
              />
            </div>
          </td>
          <td>
            <div>
              <Radio
                checked={this.props.default == `${template.id}`}
                onChange={this.props.handleDefault('default')}
                value={`${template.id}`}
                name="radio-button-demo"
                color="primary"
                inputProps={{ 'aria-label': 'A' }}
              />
            </div>
          </td>
          {showMatrixes && (
            <td>
              <div>
                {' '}
                {template.properties.templatesCount}
                <IconButton
                  aria-label="Comments"
                  onClick={(e) => {
                    this.selectMatrixes();
                  }}
                  size="large"
                >
                  <EditIcon
                    onClick={(e) => {
                      this.selectMatrixes();
                    }}
                  />
                </IconButton>
              </div>
            </td>
          )}
        </tr>
      </>
    );
  }
}

export default class TemplateEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      defaultTemplate: -1,
      expanded: false
    };

    this.handleDefault = this.handleDefault.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.templates) {
      this.props.templates.map((template) => {
        if (template.properties.default) {
          this.setState({ defaultTemplate: `${template.id}` });
        }
      });
    }
  }

  handleChange = (event) => {
    if (this.props.handleChange) {
      this.props.handleChange(event);
    }
  };

  toggleExpansionPanel = () => {};

  handleDefault = (name) => (event) => {
    this.setState({ defaultTemplate: event.target.value });
    this.props.templates.map((template) => {
      if (`${template.id}` == this.state.defaultTemplate) {
        template.properties.default = true;
      } else {
        template.properties.default = false;
      }
    });
    if (this.props.handleChange) {
      this.props.handleChange(event);
    }
  };

  render() {
    const { intl, module, templates, companyId, classes, history } = this.props;
    const { defaultTemplate, expanded } = this.state;
    return (
      <Accordion
        title={intl.formatMessage({
          id: this.props.module.templateName.props.id,
          defaultMessage: this.props.module.templateName.props.defaultMessage
        })}
      >
        <table className="settingsTable">
          <thead>
            <tr className="infoTableHeader">
              {/*      {module.allowEditTemplate && <th scope="col">{<FormattedMessage id="settings.companyeditor.edittemplate" defaultMessage="Edit template"/>}</th>} */}
              <th scope="col">
                <FormattedMessage id="settings.companyeditor.templatename" defaultMessage="Template name" />
              </th>
              <th scope="col">
                <FormattedMessage id="settings.companyeditor.availiable" defaultMessage="Availiable" />
              </th>
              <th scope="col">
                <FormattedMessage id="settings.companyeditor.default" defaultMessage="Default" />
              </th>
              {module.showMatrixes && (
                <th scope="col">
                  <FormattedMessage id="settings.companyeditor.matrixes" defaultMessage="Matrixes" />
                </th>
              )}
            </tr>
          </thead>
          <tbody className="tbody">
            {this.props.loading && <Loader displayText={false} />}
            {templates &&
              templates.map((template, index) => {
                return (
                  <InfoTableRow
                    history={history}
                    handleChange={this.handleChange}
                    classes={classes}
                    intl={intl}
                    default={defaultTemplate}
                    showMatrixes={module.showMatrixes}
                    allowEditTemplate={module.allowEditTemplate}
                    companyId={companyId}
                    template={template}
                    handleDefault={this.handleDefault}
                    key={`template_${template.id}`}
                  />
                );
              })}
          </tbody>
        </table>
      </Accordion>
    );
  }
}
