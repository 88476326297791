import React, { useState, useRef, useEffect } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { updateWantToSeeIntro } from '@zert-packages/actions/commonReducer';
import { v4 as uuidv4 } from 'uuid';
import checkIfStepTargetExist from '../../ElementSearcher/utils/checkIfStepTargetExist';
import TooltipLocale from '../TooltipLocale';
import { JoyrideBeaconComponentStart } from '../Introduction';
import { finishIntroduction, useIntroduction } from '../IntroductionContext';

const id = 'checklist';
const formattedMessages = {
  'IntroChecklist.Category': (
    <FormattedMessage
      id="IntroChecklist.Category"
      defaultMessage="You can maximize and minimize categories of questions by clicking on them."
    />
  ),
  'IntroChecklist.Tabs': (
    <FormattedMessage
      id="IntroChecklist.Tabs"
      defaultMessage="In these tabs you can switch between the different views to add participans, add sessions, attach basis and write a description on whats covered by the checklist"
    />
  ),
  'IntroChecklist.QuestionAnswer': (
    <FormattedMessage
      id="IntroChecklist.QuestionAnswer"
      defaultMessage="Here you can answer your question, a blue orange highlight means that you have given an differentiated answer and that you should take other measures."
    />
  ),
  'IntroChecklist.BottomControlPanel': (
    <FormattedMessage
      id="IntroChecklist.BottomControlPanel"
      defaultMessage="Here you can navigate through the questions, you can also swipe left and right to switch between them. The summary page will inform you of the result of the checklist with the options to send it in or generate PDF-reports."
    />
  ),
  'IntroChecklist.ListOfCategoriesMobile': (
    <FormattedMessage id="IntroChecklist.ListOfCategoriesMobile" defaultMessage="Here you can see all categories" />
  ),
  'IntroChecklist.MobileCategoriesMenu': (
    <FormattedMessage id="IntroChecklist.MobileCategoriesMenu" defaultMessage="Click here to see all categories" />
  )
};

function ChecklistIntro(isMobile, wantToSeeIntro) {
  const hideAllCategoriesMobile = useRef();
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const dispatch = useDispatch();
  const [uuid, setUUID] = useState(uuidv4());
  const { startIntro } = useIntroduction(id, setRun);

  function toggleShowAllCategories(state, handleClose) {
    setRun(false);
    if (isMobile && wantToSeeIntro) {
      setStepIndex(1);
      setTimeout(() => {
        setRun(true);
      }, 300);
    }
    if (handleClose) {
      hideAllCategoriesMobile.current = handleClose;
    }
  }

  let steps = [
    {
      target: '[data-intro-id="Checklist.Category"]',
      content: formattedMessages['IntroChecklist.Category'],
      placement: 'auto',
      spotlightPadding: 0,
      disableScrolling: true
    },
    {
      target: '[data-intro-id="Checklist.Tabs"]',
      content: formattedMessages['IntroChecklist.Tabs'],
      placement: 'auto',
      spotlightPadding: 0,
      disableScrolling: true
    },
    {
      target: '[data-intro-id="Checklist.QuestionAnswer"]',
      content: formattedMessages['IntroChecklist.QuestionAnswer'],
      placement: 'auto',
      disableScrolling: true,
      spotlightPadding: 5
    },
    {
      target: '[data-intro-id="Checklist.BottomControlPanel"]',
      content: formattedMessages['IntroChecklist.BottomControlPanel'],
      placement: 'top',
      disableScrolling: true,
      spotlightPadding: 0
    }
  ];

  if (isMobile) {
    steps[0] = {
      target: '[data-intro-id="Checklist.ListOfCategoriesMobile"]',
      content: formattedMessages['IntroChecklist.ListOfCategoriesMobile'],
      placement: 'auto',
      disableScrolling: true,
      hideListOfCategories: true,
      spotlightPadding: 0
    };
    steps = [
      {
        target: '[data-intro-id="Checklist.MobileCategoriesMenu"]',
        content: formattedMessages['IntroChecklist.MobileCategoriesMenu'],
        elementRef: 'mobileCategoriesMenuRef',
        spotlightPadding: 0,
        disableScrolling: true,
        spotlightClicks: true,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        sidebar: true
      },
      ...steps
    ];
  }
  useEffect(() => {
    if (wantToSeeIntro && !run) {
      setUUID(uuidv4());
      setRun(true);
      setStepIndex(0);
    }
  }, [wantToSeeIntro]);
  const handleJoyrideCallback = (data) => {
    const { status, type, action, step, index } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (!checkIfStepTargetExist(step)) {
      setRun(false);
      dispatch(updateWantToSeeIntro(false));
      finishIntroduction(id);
      return;
    }
    if (finishedStatuses.includes(status)) {
      setRun(false);
      dispatch(updateWantToSeeIntro(false));
      finishIntroduction(id);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (step.hideListOfCategories) hideAllCategoriesMobile.current();
      const newStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setRun(false);
      setStepIndex(newStepIndex);
      setTimeout(() => {
        setRun(true);
      }, 400);
    }
  };
  return {
    component: (
      <Joyride
        key={uuid}
        locale={TooltipLocale}
        callback={handleJoyrideCallback}
        beaconComponent={JoyrideBeaconComponentStart}
        continuous
        run={run}
        disableScrolling
        disableScrollParentFix
        steps={steps}
        stepIndex={stepIndex}
        showProgress
        showSkipButton
        styles={{
          overlay: {
            position: 'fixed'
          },
          options: {
            primaryColor: '#01A1C7'
          }
        }}
      />
    ),
    toggleShowAllCategories,
    startIntro
  };
}

export default ChecklistIntro;
