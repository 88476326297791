import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogAddReferences from '@zert-packages/components/dialog/DialogAddReferences';
import { TabsContext } from './FormatTemplatePlugin';
import { array_move } from './utils/handleSort';
import DialogList from './dialog/DialogList';
import { adobeName } from "./utils/main";

const useStyles = makeStyles((theme) => ({
  wrapperInput: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr 25px',
    marginBottom: 10
  },
  label: {
    fontWeight: 900
  },
  listWrapper: {
    border: '1px solid #BDBDBC',
    margin: '0px 10px',
    borderRadius: 5,
    overflow: 'auto',
    minHeight: 200,
    maxHeight: 200
  },
  listWrapperScroll: {},
  wrapperTitle: {
    padding: '2px 4px',
    cursor: 'pointer'
  },
  wrapperTitleSelected: {
    padding: '2px 4px',
    cursor: 'pointer',
    background: '#4db1d3'
  },
  arrowUp: {
    color: '#4db1d3',
    cursor: 'pointer',
    transform: 'rotate(90deg)'
  },
  arrowDown: {
    color: '#4db1d3',
    cursor: 'pointer',
    transform: 'rotate(270deg)'
  },
  wrapperImg: {
    display: 'flex',
    flexDirection: 'column'
  },
  checkboxLabel: {
    fontSize: 15,
    color: '#212529'
  },
  wrapperEdditor: {
    color: '#4db1d3',
    textAlign: 'end',
    '& svg': {
      cursor: 'pointer'
    }
  },
  icons: {
    marginLeft: 15
  }
}));

function SelectedBlock({ label, formats, disable  }) {
  const cl = useStyles();
  const [openDialogReferences, setOpenDialogReferences] = useState(false);
  const { values, setValues } = useContext(TabsContext);
  const [propsDialog, setPropsDialog] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const selectedItem = formats && formats[values.selectedReferences]



  const handleSelectedTab = (i) => {
    setValues((prev) => ({ ...prev, selectedReferences: i }));
  };

  const handleSort = (ArrowUp) => {
    if (ArrowUp) {
      if (values.selectedReferences === 0) {
        return;
      }
      const result = array_move(formats, values.selectedReferences, values.selectedReferences - 1);
      return setValues((prev) => ({
        ...prev,
        treeChildren: { ...prev.treeChildren, references: result },
        selectedReferences: values.selectedReferences - 1
      }));
    }
    if (values.selectedReferences + 1 === formats.length) {
      return;
    }
    const result = array_move(formats, values.selectedReferences, values.selectedReferences + 1);
    setValues((prev) => ({ ...prev }));
    setValues((prev) => ({
      ...prev,
      treeChildren: { ...prev.treeChildren, references: result },
      selectedReferences: values.selectedReferences + 1
    }));
  };

  const handleOpenDialogReferences = () => {
    setOpenDialogReferences(true);
    setPropsDialog({
      label: 'Add References',
      underLabel: 'References:',
      addReferences: values && values.addReferences,
      setValues
    });
  };

  const handleDeprecated = () => {
    setValues((prev) => ({
      ...prev,

      templateList: {
        ...prev.templateList,
        references: prev.templateList.references.map((el, i) =>
          i === values.selectedReferences
            ? { ...el, deprecated: !el.deprecated }
            : el
        )
      }
    }));
  };

  const handleOpenDialog = (label) => {
    setOpenDialog(true);
    setPropsDialog(label);
  };

  const handleSave = () => {
    const references = formats.filter((el, i) => i  !== values.selectedReferences)
    setValues(pre => ({...pre, templateList:  {...pre.templateList, references} }))
  }

  const deleteReferences = () => {
    if(!selectedItem || disable ){
      return
    }
    handleOpenDialog({
      label: 'Remove ?',
      text: `Are you sure to remove ${selectedItem.type}
                ${selectedItem.identifier} ?`,
      id: 2
    })
  }



  return (
    <div>
      <DialogList
        open={openDialog}
        setOpen={setOpenDialog}
        text={propsDialog}
        save={handleSave}
      />
      <DialogAddReferences open={openDialogReferences} setOpen={setOpenDialogReferences} text={propsDialog} />
      <div className={cl.wrapperInput}>
        <div className={cl.label}>{label}</div>
          <div className={cl.listWrapper}>
            <div className={cl.listWrapperScroll}>
              {formats && formats.map((el, i) => (
                <div
                  key={i}
                  style={{
                    color: el.deprecated ? '#B1B0AF' : values.selectedReferences === i ? '#ffffff' : '#212529'
                  }}
                  onClick={() => handleSelectedTab(i)}
                  className={values.selectedReferences === i ? cl.wrapperTitleSelected : cl.wrapperTitle}
                >
                  <span>{el.type && adobeName(el.type)}: </span>
                  {el.identifier && el.identifier}
                </div>
              ))}
            </div>
          </div>

        <div className={cl.wrapperImg}>
          <ArrowBackIcon className={cl.arrowUp} onClick={() => !disable && handleSort(true)} />
          <ArrowBackIcon className={cl.arrowDown} onClick={() => !disable && handleSort()} />
        </div>
      </div>
      <div className={cl.wrapperEdditor}>
        <FormControlLabel
          disabled={disable}
          control={<Checkbox
            onChange={handleDeprecated}
            name="deprecated"
            checked={selectedItem?.deprecated}
          />}
          label={<span className={cl.checkboxLabel}>Deprecated</span>}
        />
        <AddIcon onClick={() => !disable && handleOpenDialogReferences()} className={cl.icons} />
        <DeleteIcon
          onClick={deleteReferences }
          className={cl.icons}
        />
      </div>
    </div>
  );
}

export default SelectedBlock;
