import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  wrapperRoot: {
    position: 'relative',
    '&:hover $imgWrapper': {
      display: 'block'
    }
    // '& ::selection,div::selection': {
    //   background: '#ffffff !important',
    //   outline: 'none',
    //   border: 'none !important',
    // },
    // '& ::-moz-selection,div::-moz-selection,': {
    //   background: '#ffffff !importan',
    //   outline: 'none',
    //   border: 'none !important',
    // }
  },
  clickContainer: {
    paddingLeft: 21
  },
  wrapperBlockItems: {
    display: 'grid',
    border: '1px solid rgba(145, 145, 145, 0.25)',
    gridTemplateColumns: '7px 1fr',
    gap: 5,
    paddingRight: 5,
    transition: '0.5s'
  },
  borderLeft: {
    width: 6,
    backgroundColor: '#80CFE2',
    height: '100%',
    marginLeft: 1
  },
  wrapperFraze: {
    cursor: 'pointer',
    backgroundColor: '#F8F8F8',
    fontSize: 10,
    '& p': {
      margin: 0
    }
  },
  wrapperLogo: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapperText: {
    marginBottom: 10,
  },
  wrapperFrazeImg: {
    height: 12
  },
  iconDone: {
    display: 'block',
    width: '11px',
    height: 14,
    color: '#91BD6B'
  },
  wrapperIcon: {
    paddingTop: 5
  },
  wrapperLogoText: {
    fontSize: 10,
    color: '#919191',
    lineHeight: '10px'
  },
  partText: {
    fontSize: 10,
    color: '#919191',
    lineHeight: '10px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  wrapper: {
    display: 'grid',
    gap: 5,
    marginTop: -1
  },

  iconBlock: (props) => ({
    cursor: 'pointer',
    transform: props.expanded ? '' : 'rotate(91deg)',
    position: 'relative',
    right: 2
  }),
  TreeRootPopup: {
    minWidth: 317,
    minHeight: 100,
    marginLeft: 88,
    marginTop: 7,
    padding: 5,
    border: '1px solid lightgray',
    boxShadow: 'none',
    fontSize: 12,
    lineHeight: '12px',
    maxHeight: 400
  },
  fixedBlock: {
    position: 'sticky',
    marginTop: 9,
    top: 51.3,
    zIndex: 10,
    backgroundColor: '#ffffff'
  },
  title: {
    display: 'block',
    fontSize: '1.1rem',
    lineHeight: '1.1rem',
    color: 'rgb(25 25 25)',
    padding: '0.25rem 1.85em 0.5em'
  },
  closeImgButton: {
    position: 'absolute',
    width: '20px',
    height: '20px',
    top: 19,
    left: -2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& div': {
      display: 'flex'
    }
  },
  addImgButton: (props) => ({
    top: -8,
    position: 'absolute',
    height: 16,
    width: 16,
    backgroundColor: '#59C1DB',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    fontSize: 15,
    fontWeight: 900,
    animation: 'fade-in 0.5s',
    cursor: props.blockClick ? 'not-allowed' : 'pointer',
    '& div': {
      display: 'flex'
    }
  }),
  addImgButtonPlaceholder: {
    position: 'absolute',
    width: '20px',
    height: '20px',
    top: -10,
    left: -2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'rgba(145, 145, 145, 0.25)',
    '& div': {
      display: 'flex'
    }
  },
  removeIcon: {
    fontSize: 18,
    cursor: 'pointer',
    '&:hover': {
      color: '#01A1C7'
    }
  },
  createElementWrapper: {
    position: 'relative',
    minHeight: 21
  },
  expandedWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: 5,
    marginBottom: 5
  },
  expandedItem: {
    background: '#919191',
    height: 4,
    width: 4
  },
  border: {
    position: 'relative',
    '& div': {
      height: 1,
      background: 'rgb(89, 193, 219)',
      position: 'absolute',
      width: '100%',
      animation: 'fade-in 0.5s'
    }
  },
  imgWrapper: {
    position: 'absolute',
    width: 21,
    left: -21,
    top: -1,
    gridRowGap: 3
  },
  addBlockBottom: {
    position: 'absolute',
    bottom: -5,
    left: -21
  },
  expandableWrapper: {
    position: 'relative',
    width: '100%',
    height: 'calc(100% - 20px)',
    overflow: 'hidden',
    cursor: 'pointer',
    opacity: 0,
    '&:hover': {
      opacity: 1
    }
  },
  expandableWrapperAdding: {
    height: 'calc(100% - 54px)',
    marginTop: '54px'
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    left: '35%',
    cursor: 'pointer',
    '& span': {
      display: 'block',
      width: 10,
      height: 10,
      borderBottom: '2px solid #00000080',
      borderRight: '2px solid #00000080',
      transform: 'rotate(45deg)',
      margin: '-10px 0',
      animation: 'animate 2s infinite'
    },
    '& span:nth-child(2)': {
      animationDelay: '-0.2s'
    },
    '& span:nth-child(3)': {
      animationDelay: '-0.4s'
    }
  },
  expand: {
    transform: 'translate(-50%, -50%) rotate(0deg)'
  },
  collapse: {
    transform: 'translate(-50%, -50%) rotate(180deg)'
  },
  wrapperAddIcon: {
    width: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}));
export default useStyles;
