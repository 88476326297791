import React, {createContext, useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import CreateItems from "./CreateItems";
import AddToolbar from "../../../../../BlockPlugin/components/AddToolbar";
import {createInProgressSnack} from "@zert-packages/components/common/snackBar";
import {FormattedMessage} from "react-intl";
import {useSnackbar} from "notistack";
import {createImgApi, initImgApi, storeBlockApi} from "@zert-packages/components/InfoElement/components/API";
import {useDispatch, useSelector} from "react-redux";
import {uploadImageFile} from "../../../../../PhrasePlugin/servicesMiddlewares";
import {v4 as uuidv4} from "uuid";
import {PanelContext} from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import {useHistory} from "react-router-dom";
import CreateSoftwareTexts from "./CreateSoftwareTexts";

export const BlockItemsContext = createContext(null);

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        // maxWidth: 414,
        padding: 11,
        background: "#E2F2F6"
    },

}))

const AddToolbarDialog = ({
                              values,
                              it,
                              tableData,
                              setValues,
                              setTableData,
                              setOpenToolbarDialog,
                              setSelected,
                              createRoot
                          }) => {
    const cl = useStyles();
    const [block, setBlock] = useState({
        browseBlock: null,
        createType: null,
        item: null,
        open: true,
        phraseEditor: {
            editorState: null,
            contentStateJSON: null,
            maxLength: null,
            phrase: '',
        },
    })
    const snackbar = useSnackbar();
    const {mylocale} = useSelector((state) => state);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClose = () => {
        setOpenToolbarDialog(false)
    };


    const saveImg = async (val) => {
        const id = createRoot ? -1 : values.tableDefault.elementInfo.versionId

        const createImgItem = await createImgApi(id, mylocale, {
            imageName: val.info.image.name,
            imagePreview: val.right
        });

        if (createImgItem) {
            setBlock(pre => ({...pre, item: createImgItem}))
        }

        if (createRoot) {
            const id = val.info.image.info.versionId
            history.push(`/image/${id}`)
        }
    };

    const createImg = async (mimeType) => {
        const generatingSnackbar = createInProgressSnack(
            snackbar,
            <FormattedMessage id="CLM.UploadingFile.CreateImage" defaultMessage="CreateImage..."/>
        );
        generatingSnackbar;
        setBlock(pre => ({...pre, createType: mimeType}))
        dispatch(uploadImageFile(uuidv4(), saveImg));
        snackbar.closeSnackbar(generatingSnackbar);
    }


    useEffect(() => {
        if (createRoot?.createType) {
            setBlock(pre => ({...pre, createType: createRoot.createType}))
            // if (createRoot.createType === 'image') {
            //     createImg(createRoot.createType)
            // }
        }
    }, [createRoot?.createType]);


    if (createRoot) {
        return <BlockItemsContext.Provider value={{block, setBlock, values: block, setValues: setBlock}}>
            {block.createType && <CreateItems
                blockCreate={block}
                setBlockCreate={setBlock}
                values={values}
                it={it}
                tableData={tableData}
                setValues={setValues}
                setTableData={setTableData}
                setOpenToolbarDialog={setOpenToolbarDialog}
                setSelected={setSelected}
                createRoot={createRoot}
                saveImg={saveImg}
            />}
        </BlockItemsContext.Provider>
    }


    return (<Dialog
        open={true}
        onClose={handleClose}
        classes={{
            paperScrollPaper: cl.root
        }}
    >
        <BlockItemsContext.Provider value={{block, setBlock, values: block, setValues: setBlock}}>
            <AddToolbar contextBlock={BlockItemsContext} createImg={createImg}/>
            {block.createType && <CreateItems
                blockCreate={block}
                setBlockCreate={setBlock}
                values={values}
                it={it}
                tableData={tableData}
                setValues={setValues}
                setTableData={setTableData}
                setOpenToolbarDialog={setOpenToolbarDialog}
                setSelected={setSelected}
                createRoot={createRoot}
            />}
        </BlockItemsContext.Provider>
    </Dialog>)
}

export default AddToolbarDialog
