import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import makeStyles from '@mui/styles/makeStyles';
import { emphasize } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Chip from '@mui/material/Chip';
import { useElementOpen } from '@zert-packages/components/ElementTile/utils/useElementOpen';
import IconButton from '@mui/material/IconButton';
import { ArrowUpward } from '@mui/icons-material';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    flexWrap: 'wrap',
    padding: '10px',
    margin: '10px'
  },
  margin: {
    margin: '10px'
  }
}));

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300]
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12)
    },
    marginLeft: '0.5em'
  }
}))(Chip);

function BreadCrumbLink({ info }) {
  const { open } = useElementOpen();
  const Icon = getPluginByMimeType(info.mimeType).icon;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon style={{ height: '16px', width: '16px', fill: 'lightgray' }} />
      <StyledBreadcrumb
        component="div"
        label={info.name}
        onClick={(event) => {
          event.preventDefault();
          open(info, null, event);
        }}
      />
    </div>
  );
}

function UpLink({ info }) {
  const { open } = useElementOpen();
  return (
    <IconButton
      size="small"
      onClick={(event) => {
        event.preventDefault();
        open(info, null, event);
      }}
    >
      <ArrowUpward />
    </IconButton>
  );
}

export default function CatalogOriginLink({ versionId, mine, up }) {
  const [origin, setOrigin] = useState(null);

  useEffect(() => {
    async function getOriginFunction() {
      //    let origin = await getOrigin(versionId);
      //   setOrigin(origin.reverse());
    }
    getOriginFunction();
  }, [versionId]);

  return (
    <>
      {origin && origin.length > 0 && (
        <Grid item xs={12}>
          <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
            {up && origin.length > 0 && <UpLink info={origin[0]} />}
            {origin.map((info) => {
              return <BreadCrumbLink info={info} />;
            })}
            {mine && <BreadCrumbLink info={mine} />}
          </Breadcrumbs>
        </Grid>
      )}
      {mine && origin && origin.length === 0 && (
        <Grid item xs={12}>
          <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
            {mine && <BreadCrumbLink info={mine} />}
          </Breadcrumbs>
        </Grid>
      )}
    </>
  );
}
