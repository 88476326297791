import React, {useContext} from 'react'
import {TextField} from "@mui/material";
import {handleValue} from "../../utils/main";
import {PhraseContext} from "../PhraseEditor";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 1,
    '& input': {
      padding: '0px 5px',
      background: '#ffffff',
      paddingBottom: 1,
    },
    '& textarea': {
      overflow: 'hidden',
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  slash: {
    marginLeft: 4,
    marginRight: 4,
  },
  mainText: {
    fontWeight: '700',
  },
  maxLengthField: {
    width: 47,
    top: -1,
  }
}))

const MaxLength = () => {
  const cl = useStyles()
  const {values, setValues} = useContext(PhraseContext)


  const handleMaxLengthValue = (e) => {
    handleValue(e.target.value.replace(/[^0-9]/g, ''), 'maxLengthValue', setValues)
  }

  return(<div className={cl.root}>
    <span className={cl.mainText}>{values.lengthValue} </span>
    <div className={cl.slash}>/</div>
    <TextField
      className={cl.maxLengthField}
      value={values.maxLengthValue}
      onChange={(e) => handleMaxLengthValue(e)}
      inputProps={{ maxLength: 4 }}
    />
  </div>)
}

export default MaxLength