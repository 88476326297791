import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { updateWantToSeeIntro } from '@zert-packages/actions/commonReducer';
import { useDispatch, useSelector } from 'react-redux';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { finishIntroduction, useIntroduction } from '../IntroductionContext';
import { JoyrideBeaconComponentStart } from '../Introduction';
import TooltipLocale from '../TooltipLocale';
import checkIfStepTargetExist from '../../ElementSearcher/utils/checkIfStepTargetExist';

const id = 'project';
const formattedMessages = {
  'IntroProjectView.MainTabs': (
    <FormattedMessage
      id="IntroProjectView.MainTabs"
      defaultMessage="Here you can switch between the activities, the participants, a list of all included basises and the suppliers in the project."
    />
  ),
  'IntroProjectView.FirstActivity': (
    <FormattedMessage
      id="IntroProjectView.FirstActivity"
      defaultMessage="Here you can structure your project in different activities and sub activites, each activity can then have its own status, its own responsible person and a description on what should be done. You can add more activities with the +-button."
    />
  ),
  'IntroProjectView.PrintButton': (
    <FormattedMessage
      id="IntroProjectView.PrintButton"
      defaultMessage="Here you can print out reports from the project, create a duplicate of an existing project and close it when its done. "
    />
  )
};

function ProjectIntro(wantToSeeIntro) {
  const [run, setRun] = useState(false);
  const dispatch = useDispatch();
  const [stepIndex, setStepIndex] = useState(0);
  const { startIntro } = useIntroduction(id, setRun);
  const steps = [
    {
      target: '[data-intro-id="ProjectView.MainTabs"]',
      content: formattedMessages['IntroProjectView.MainTabs']
    },
    {
      target: '[data-intro-id="ProjectView.FirstActivity"]',
      content: formattedMessages['IntroProjectView.FirstActivity'],
      spotlightPadding: 20
    },
    {
      target: '[data-intro-id="ProjectView.PrintButton"]',
      content: formattedMessages['IntroProjectView.PrintButton']
    }
  ];
  useEffect(() => {
    if (wantToSeeIntro && !run) {
      setRun(true);
      setStepIndex(0);
    }
  }, [wantToSeeIntro]);
  const handleJoyrideCallback = (data) => {
    const { status, step, action, index, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (!checkIfStepTargetExist(step)) {
      setRun(false);
      dispatch(updateWantToSeeIntro(false));
      finishIntroduction(id);
      return;
    }
    if (finishedStatuses.includes(status)) {
      dispatch(updateWantToSeeIntro(false));
      finishIntroduction(id);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const newStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setRun(false);
      setStepIndex(newStepIndex);
      setTimeout(() => {
        setRun(true);
      }, 400);
    }
  };
  return {
    component: (
      <Joyride
        locale={TooltipLocale}
        callback={handleJoyrideCallback}
        beaconComponent={JoyrideBeaconComponentStart}
        continuous
        run={run}
        steps={steps}
        stepIndex={stepIndex}
        showProgress
        showSkipButton
        styles={{
          overlay: {
            position: 'fixed'
          },
          options: {
            primaryColor: '#01A1C7'
          }
        }}
      />
    ),
    startIntro
  };
}

export default ProjectIntro;
