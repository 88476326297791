import Lock from '@mui/icons-material/Lock';
import React from 'react';
import { Close } from '@mui/icons-material';
import { useTreeItemStyles } from '../TranslationStatusNode';

function TranslationStatusFlag({ type, extraXStyle }) {
  const classes = useTreeItemStyles();
  const untranslated = (
    <div className={classes.greyDot} style={{ backgroundColor: 'transparent' }}>
      <Close style={{ height: '0.7em', width: '0.7em', margin: '-8px' }} />
    </div>
  );

  switch (type) {
    case 'UNTRANSLATED':
      return untranslated;
    case 'LOCKED':
      return (
        <div className={classes.greyDot} style={{ backgroundColor: 'transparent' }}>
          <Lock style={{ height: '0.7em', width: '0.7em', margin: '-8px' }} />
        </div>
      );
    case 'APPROVED':
      return <div className={classes.greyDot} style={{ backgroundColor: 'rgb(108, 189, 37)' }} />;
    case 'CHECKED_OUT':
      return <div className={classes.greyDot} style={{ backgroundColor: 'rgb(1, 169, 209)' }} />;
    case 'CHECKED_OUT_BY_OTHER':
      return <div className={classes.greyDot} style={{ backgroundColor: '#E83535' }} />;
    case 'CHECKED_IN':
      return <div className={classes.greyDot} style={{ backgroundColor: 'rgb(128, 128, 128)' }} />;
    default:
      return untranslated;
  }
}

export default TranslationStatusFlag;
