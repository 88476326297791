import React, {useContext, useEffect, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import {useDispatch} from "react-redux";
import {getBlockApi, getTranslationPrhase} from "../../BlockPlugin/API";
import BlockItems from "../../BlockPlugin/BlockItems";
import SkeletonLoader from "@zert-packages/components/SkeletonLoader";
import {PanelContext} from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";




const useStyles = makeStyles((theme) => ({
    root : {
        margin: "15px 0"
    }
}))


const LangBlock = ({lang, newLang, versionId,setSelectedRange,selectedRange,i, context}) =>{

    const dispatch = useDispatch();

    const { values, setValues } = useContext(context);

    const [state, setState] = useState(null);
    const cl = useStyles()

    const handleLang = async () =>{
        const req = lang.country ? `${ lang.language}_${ lang.country}` : `${ lang.language}`
        const res = await getTranslationPrhase(versionId, req)

        if(res){
            setState(res)
        }
        if(newLang){
            setState(lang)
        }
    }


    useEffect(()=>{
        if(lang && versionId){
            handleLang()
        }
    },[lang])
    // },[lang, versionId])



    return (
        <div className={cl.root} style={{border: newLang ? "3px solid red": ''}}>
            {lang?.displayName &&
                <div>
                    {lang?.displayName}
                </div>
            }
            {(state || newLang )?
                <BlockItems
                    context={context}
                    index={i}
                    parent={state}
                    // el={state}
                    el={{...state,softWareText:true}}
                    dropBlockTable={false}
                    investmentLevel={0}
                    // selected={false}
                    selected={values?.treeItem?.elementInfo?.versionId === state?.elementInfo?.versionId}
                    selectedRange={selectedRange}
                    setSelectedRange={setSelectedRange}
                />
           :
                <SkeletonLoader height={28} />
            }
        </div>
    )
}

export default LangBlock