import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TabsContext } from '../../Stylesheet';
import GroupBlock from '../blockTabs/pageLayout/GroupBlock';
import { handleTableParents } from '../../utils/helpers';
import Decorations from './decorations/Decorations';
import Font from './Font';
import TextFlow from './TextFlow';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 10
  },
  title: {
    fontSize: '1rem',
    fontWeight: '700'
  },
  templateInput: {
    margin: 0,
    width: '100%'
  },
  noteActiveInputWrapper: {
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15,
      background: '#ffffff'
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    },
    '& .MuiFormControl-marginDense': {
      width: '100%'
    }
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10
  },
  wrapperRowBlock: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gridColumnGap: 10
  },
  rowInput: {
    margin: 0,
    width: '100%',
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15,
      background: '#ffffff'
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  rowCountWrapper: {
    display: 'grid',
    gridTemplateColumns: '140px 1fr',
    gridColumnGap: 10
  }
}));

function OddRows({ table }) {
  const { values, setValues } = useContext(TabsContext);
  const cl = useStyles();

  const oddRow = table.oddRow && table.oddRow;

  const spacingProps = [
    { label: 'Left:', value: oddRow.paddingLeft, type: 'paddingLeft' },
    { label: 'Right:', value: oddRow.paddingRight, type: 'paddingRight' },
    { label: 'Top:', value: oddRow.paddingTop, type: 'paddingTop' },
    { label: 'Bottom:', value: oddRow.paddingBottom, type: 'paddingBottom' }
  ];

  const handleValuesSpacing = (val, name) => {
    const res = { ...oddRow[name], value: +val };
    handleTableParents(res, name, 'tableSettings', 'headerRow', values, setValues);
  };
  const handleDropMenuSpacing = (dropMenu, name, val) => {
    const res = { unit: dropMenu, value: +val };
    handleTableParents(res, name, 'tableSettings', 'headerRow', values, setValues);
  };

  return (
    <>
      {oddRow && (
        <div className={cl.root}>
          <div className={cl.wrapperRow}>
            <Font table={oddRow} type="oddRow" />
            <TextFlow table={oddRow} type="oddRow" />
          </div>

          <div className={cl.wrapperRow}>
            <Decorations table={oddRow} type="oddRow" />
            <GroupBlock
              label="Spacing to contents"
              valueProps={spacingProps}
              style={{ height: 'Calc(100% - 26px)', gridAutoRows: 'min-content' }}
              setValueProps={handleValuesSpacing}
              setDropMenu={handleDropMenuSpacing}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default OddRows;
