import React, { useContext, useEffect, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getVersionInfo } from '@zert-packages/actions/coreReducers';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PanelContext } from './InfoElementWrapper/InfoElementWrapper';

const useStyles = makeStyles((theme) => ({
  dropMenuButton: {
    width: '100%',
    justifyContent: 'space-between',
    border: '1px solid #DCE3E5',
    marginBottom: 15,
    color: '#8B8B8B',
    fontWeight: '400',
    paddingBottom: 2,
    paddingTop: 2,
    background: '#ffffff',
    paddingRight: 15,
    borderRadius: 0,
    outline: 'none !important',
    textTransform: 'initial',
    '&:hover': {
      background: '#ffffff'
    },
    '& span:nth-child(2)': {
      display: 'none'
    }
  },
  menuLinkSiteWrapper: {
    borderRadius: 0,
    // width: 470,
    width: 'fit-content',
    minWidth: 315,
    maxWidth: 'Calc(100% - 40px)',
    border: '1px solid #DCE3E5',
    boxShadow: 'none',
    maxHeight: '400px',
    '@media (max-width: 900px)': {
      width: 'Calc(100% - 151px)'
    }
  }
}));
//PanelContext
function SelectedLanguagePopup({ handleLanguage, context }) {
  const cl = useStyles();
  const dispatch = useDispatch();
  const { versionId } = useParams();
  const { activeLocales } = useSelector((state) => state);
  const [open, setOpen] = useState(null);

  const [localesDropMenu, setLocalesDropMenu] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('Add a language')

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleSelectedLanguage = (el) => {
    setOpen(null);
    if (handleLanguage) {
      handleLanguage(el.value);
    }
    setSelectedLanguage(el.extendedDisplayName)
  };



  useEffect(() => {
    if (versionId === null) return;
    dispatch(getVersionInfo(versionId));
  }, [versionId]);

  useEffect(() => {
    if (activeLocales && activeLocales.length > 0) {
      setLocalesDropMenu(activeLocales);
    }
  }, [activeLocales]);

  return (
    <div>
      {localesDropMenu && (
        <>

          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpen} className={cl.dropMenuButton}>
            {selectedLanguage}
            <ArrowDropDownIcon style={{ color: open ? '#8B8B8B' : '#224952' }} />
          </Button>
          <Menu
            anchorEl={open}
            open={Boolean(open)}
            onClose={() => setOpen(null)}
            classes={{
              paper: cl.menuLinkSiteWrapper
            }}
          >
            {localesDropMenu.map((el, i) => (
              <MenuItem key={i} onClick={() => handleSelectedLanguage(el)}>
                {el.extendedDisplayName}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </div>
  );
}

export default SelectedLanguagePopup;
