import React, { Fragment } from 'react';
import './style.css';
import { FormattedMessage } from 'react-intl';
import DeadlinePicker from '../../shared/DeadlinePicker/DeadlinePicker';
import IntervalEditor from './IntervalEditor';
import IncrementSelector from './IncrementSelector';

export default class DeadlineEditor extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeDateCallback = this.onChangeDateCallback.bind(this);
    this.onChangeMode = this.onChangeMode.bind(this);
    this.onChangeInterval = this.onChangeInterval.bind(this);
  }

  callOnModificationIfPresent() {
    if (this.props.onModification) {
      this.props.onModification();
    }
  }

  onChangeDateCallback(date) {
    this.props.deadline.deadline = date;
    if (this.props.onChangeDateCallback) {
      this.props.onChangeDateCallback(date);
    }
    if (this.props.task && this.props.task.currentActivity.remindDeadline == this.props.deadline.identifier) {
      this.props.task.currentActivityDeadline = date;
    }
    this.callOnModificationIfPresent()
  }

  onChangeInterval(unit, amount) {
    if (this.props.task && this.props.task.currentActivity && this.props.task.remindDeadlineMappings) {
      const dlId = this.props.task.currentActivity.remindDeadline;
      const dlIndex = this.props.task.remindDeadlineMappings.findIndex((m) => m.identifier === dlId);
      this.props.task.remindDeadlineMappings[dlIndex].interval.amount = amount;
      this.props.task.remindDeadlineMappings[dlIndex].interval.unit = unit;
    }
    this.props.deadline.interval.amount = amount;
    this.props.deadline.interval.unit = unit;
    this.callOnModificationIfPresent()
  }

  onChangeMode(e) {
    if (this.props.task && this.props.task.currentActivity && this.props.task.remindDeadlineMappings) {
      const dlId = this.props.task.currentActivity.remindDeadline;
      const dlIndex = this.props.task.remindDeadlineMappings.findIndex((m) => m.identifier === dlId);
      this.props.task.remindDeadlineMappings[dlIndex].mode = e;
    }
    this.props.deadline.mode = e;
    this.callOnModificationIfPresent()
  }

  render() {
    return (
      <>
        <div className="intervalSelector" style={{ background: 'none' }}>
          <div className="captionLabel">{this.props.deadline.name}</div>
          <div style={{ background: 'none', paddingLeft: '20px', marginTop: '18px' }}>
            <DeadlinePicker
              readonly={this.props.disabled}
              deadline={this.props.deadline.deadline}
              onChangeCallback={this.onChangeDateCallback}
            />
          </div>
        </div>

        {this.props.deadline.interval && !this.props.disabled && (
          <>
            <div className="captionLabel">
              <FormattedMessage id="interval.deadline.editor" defaultMessage="Interval" />
            </div>
            <IntervalEditor onChange={this.onChangeInterval} interval={this.props.deadline.interval} />
            <div className="edit" style={{ background: 'none' }}>
              <IncrementSelector onChange={this.onChangeMode} mode={this.props.deadline.mode} />
            </div>
          </>
        )}
      </>
    );
  }
}
