import React, { Fragment } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

function ButtonWithMenus({
  menuItems = [],
  ButtonLabel = '',
  ButtonProps = {},
  onOpen = () => {},
  onClose = () => {}
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const createMenuItemOnClick = (onClickFunc) => () => {
    setAnchorEl(null);
    onClickFunc();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };
  return (
    <>
      <Button color="primary" variant="outlined" onClick={handleClick} {...ButtonProps}>
        {ButtonLabel}
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map(({ onClick, ...other }, index) => (
          <MenuItem key={index} onClick={createMenuItemOnClick(onClick)} {...other} />
        ))}
      </Menu>
    </>
  );
}

export default ButtonWithMenus;
