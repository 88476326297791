import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function WantToSeeIntoDialog({ unmountComponent, onYes, onNo }) {
  const withUnmountComponent = (callback) => () => {
    callback();
    unmountComponent();
  };
  return (
    <Dialog fullWidth maxWidth="sm" open onClose={unmountComponent}>
      <DialogTitle>
        <FormattedMessage id="Introduction.WantToSeeIntroDialog.Title" defaultMessage="Guide manager" />
      </DialogTitle>
      <DialogContent>
        <FormattedMessage id="Introduction.WantToSeeIntroDialog.Content" defaultMessage="Do you want to see a guide?" />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={withUnmountComponent(onYes)} color="primary" variant="outlined">
          <FormattedMessage id="Introduction.WantToSeeIntroDialog.Yes" defaultMessage="Yes" />
        </Button>
        <Button onClick={withUnmountComponent(onNo)} color="primary">
          <FormattedMessage id="Introduction.WantToSeeIntroDialog.No" defaultMessage="No" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WantToSeeIntoDialog;
