export default function findParentNode(parentNode, searchNodeUuid) {
  for (let i = 0; i < parentNode.activities.length; i += 1) {
    const currentChild = parentNode.activities[i];
    if (searchNodeUuid === currentChild.uuid) {
      return parentNode;
    }
  }

  for (let i = 0; i < parentNode.activities.length; i += 1) {
    const foundParent = findParentNode(parentNode.activities[i], searchNodeUuid);
    if (foundParent) {
      return foundParent;
    }
  }
  return null;
}
