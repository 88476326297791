import useMouseEvents from '@zert-packages/react/hooks/useMouseEvents';
import { useElementOpen } from '@zert-packages/components/ElementTile/utils/useElementOpen';
import { usePrevious } from '@zert-packages/react/hooks/usePrevious';
import React, { useEffect } from 'react';

const useSelectionModel = (rows, selectOnlyOne, identifier, openAction) => {
  const [selected, setSelected] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const { open } = useElementOpen();
  const previousRows = usePrevious(rows);

  const { handleClick, onElementCtrlClick, handleDoubleClick, onElementDoubleClick, handleTouchStart, handleTouchEnd } =
    useMouseEvents({
      onElementClick: (element) => {
        setSelected(selected.includes(element) ? [] : [element]);
      },
      onElementCtrlClick: (element) => {
        setSelected(selected.includes(element) ? selected.filter((el) => el !== element) : [...selected, element]);
      },
      onElementShiftClick: (element, elements) => {
        const start = selected && selected.length > 0 && selected[selected.length - 1];
        const end = element;
        const range = getRangeOfElements(elements, start, end);
        setSelected([...new Set([...selected, ...range])]);
      },
      onElementDoubleClick: (element) => {
        if (openAction) {
          openAction(element);
        } else {
          open(element);
        }
      },
      handleSingleTouch: (element) => {
        onElementDoubleClick(element);
      },
      handleLongTouch: (element) => {
        onElementCtrlClick(element);
      }
    });

  useEffect(() => {
    setSelectedRows(rows.filter((row) => selected.indexOf(row[identifier]) > -1));
  }, [selected]);

  /* const handleClick = (event, name, column) => {
    if (column && column.customClickHandler) {
      const row = rows.find((row) => name == row[identifier]);
      column.customClickHandler(row, column, event);
      return;
    }

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectOnlyOne) {
      newSelected = [name];
    } else if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelectedRows(rows.filter((row) => newSelected.indexOf(row[identifier]) > -1));
    setSelected(newSelected);
  }; */

  useEffect(() => {
    if (selectedRows.length > 0) {
      const newSelectedRows = rows.filter((row) => selectedRows.find((value) => row[identifier] == value[identifier]));
      if (newSelectedRows.length != selectedRows.length) {
        setSelected(newSelectedRows.map((newRow) => newRow[identifier]));
        setSelectedRows(newSelectedRows);
      }
    }
    // setSelectedRows([]);
    // setSelected([]);
  }, [rows]);

  return {
    selected,
    setSelected,
    selectedRows,
    setSelectedRows,
    handleClick,
    handleDoubleClick,
    handleTouchStart,
    handleTouchEnd
  };
};

const getRangeOfElements = (elements, start, end) => {
  const startIndex = elements.findIndex((el) => el.versionId === start);
  const endIndex = elements.findIndex((el) => el.versionId === end);
  if (startIndex === -1 || endIndex === -1) {
    return [];
  }
  const rangeStart = Math.min(startIndex, endIndex);
  const rangeEnd = Math.max(startIndex, endIndex);
  const selection = elements.slice(rangeStart, rangeEnd + 1);
  const flattenedData = selection.map((obj) => obj.versionId);
  return flattenedData;
};

export default useSelectionModel;
