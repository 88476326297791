import { FormattedMessage } from "react-intl";
import React from "react";


interface SelectorDict {
  [index: string]: Array<any>
}

interface ReportCategoryBase {
  label: any,
  orderWeight: number,
}

interface InitializedReportCategory extends ReportCategoryBase{
  containsElements: boolean,
  key: string,
  selectorLists: SelectorDict
}

interface ReportOptionDict<Type extends ReportCategoryBase> {
  [index: string]: Type
}

export const defaultTypes = {
  scope: {
    label: <FormattedMessage id="reports.optionType.scope"
                             defaultMessage="Scope" />,
    orderWeight: 1
  },
  time: {
    label: <FormattedMessage id="reports.optionType.time"
                             defaultMessage="Time period" />,
    orderWeight: 2
  },
  columns: {
    label: <FormattedMessage id="reports.optionType.columns"
                             defaultMessage="Columns" />,
    orderWeight: 3
  },
  order: {
    label: <FormattedMessage id="reports.optionType.order"
                             defaultMessage="Order" />,
    orderWeight: 4
  },
  templates: {
    label: <FormattedMessage id="reports.optionType.templates"
                             defaultMessage="Included templates" />,
    orderWeight: 5
  },
  other: {
    label: <FormattedMessage id="reports.optionType.other"
                             defaultMessage="Other options" />,
    orderWeight: Infinity
  }
}

export function createInitalTypeMap(types: ReportOptionDict<ReportCategoryBase>, selectors) : ReportOptionDict<InitializedReportCategory> {
  const res = {}
  for (const typesKey in types) {
    const selectorLists = Object.fromEntries(selectors.map(s => [s.name, []]))
    const baseObj = types[typesKey];
    res[typesKey] = {...baseObj, selectorLists, containsElements: false, key:typesKey};
  }
  return res;
}

export function filterAndSortTypeMap(types : ReportOptionDict<InitializedReportCategory>): Array<InitializedReportCategory> {
  return Object.values(types)
    .filter(value => value.containsElements)
    .sort((a, b) => {
      return a.orderWeight == b.orderWeight ? 0 : a.orderWeight - b.orderWeight;
    });
}