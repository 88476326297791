import getLang from '@zert-packages/utils/getLang';
import get from 'lodash/get';

const langIdentifier = getLang();
export const findMetaCaptionByIdentifier = (label) => _getNamesTranslation(label, langIdentifier);

const _getNamesTranslation = (names, langIdentifier) => {
  if (!names || names.length == 0) {
    return '';
  }

  const locale = names.find((l) => get(l.locale, 'language') === langIdentifier);

  if (locale != null) {
    return locale.name;
  }

  return names[0].name;
};
