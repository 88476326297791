import { useSelector } from 'react-redux';
import React, { memo, useEffect, useState, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import makeStyles from '@mui/styles/makeStyles';
import ReactFlow, { removeElements, addEdge, MiniMap, Controls, Background, Handle } from 'react-flow-renderer';
import Typography from '@mui/material/Typography';
import isReadOnly from '@zert-packages/utils/isReadOnly';
import { getTask, loadWorkflowTemplate, updateDeadline, updateParticipant } from './API';
import DeadlineEditor from '../common/Task/DeadlineEditor';
import { TaskMember } from '../common/Task/TaskMembersEditor';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    flexWrap: 'wrap',
    padding: '10px',
    margin: '10px'
  },
  margin: {
    margin: '10px'
  }
}));

const onLoad = (reactFlowInstance) => {
  console.log('flow loaded:', reactFlowInstance);
  reactFlowInstance.fitView();
};

const adoptTasks = (acum, activity, workflow, task) => {
  const deadline = task.remindDeadlineMappings.find((reminder) => reminder.identifier == activity.remindDeadline);
  const isStart = workflow.process.startActivity.id == activity.id;
  const wftCount = countWFTCount(activity, workflow);
  const isDone = workflow.process.doneActivities.find((activityM) => activityM.id == activity.id);
  const isCurrent = activity.id == task.currentActivity.id;
  const participant = task.participantMappings.find(
    (participant) => participant.workflowParticipant.id == activity.participant.id
  );
  const history = task.history
    .filter(
      (history) =>
        history.transitionId &&
        workflow.process.transitions.find(
          (transition) => history.transitionId == transition.id && transition.from.id == activity.id
        )
    )
    .sort((a, b) => new Date(b.performedAt) - new Date(a.performedAt));
  const historyEntry = history.length > 0 ? history[0] : null;
  const transitionFrom = workflow.process.transitions.filter((transition) => transition.from.id == activity.id);
  const transitionTo = workflow.process.transitions.filter((transition) => transition.to.id == activity.id);

  let hasCompleted = false;
  if (
    transitionFrom.length > 0 &&
    transitionTo.length < 2 &&
    historyEntry &&
    historyEntry.transitionId == transitionFrom[0].id
  ) {
    if (
      task.currentActivity != activity.id &&
      !workflow.process.transitions.find(
        (transition) => transition.from.id == task.currentActivity.id && transition.to.id == activity.id
      )
    ) {
      hasCompleted = true;
    }
  }

  return [
    ...acum,
    {
      ...activity,
      type: 'selectorNode',

      data: {
        isCurrent,
        isDone,
        isStart,
        activity,
        task,
        deadline,
        participant,
        historyEntry,
        hasCompleted
      },
      style: {
        background: isCurrent ? '#D6D5E6' : null,
        colorstroke: isDone ? '#ff0072' : '#1a192b',
        color: isCurrent ? '#0041d0' : 'black',
        border: isDone ? '1px solid #ff0072' : isCurrent ? '1px solid #0041d0' : '1px solid #1a192b',
        borderRadius: '3px',
        padding: '10px',
        textAlign: 'center',
        width: 180
      },
      position: {
        x: wftCount,
        y:
          acum.length == 0
            ? 0
            : acum[acum.length - 1].position.y +
              (!hasCompleted && acum[acum.length - 1].data.deadline ? 200 : 100) +
              (!hasCompleted && acum[acum.length - 1].data.participant ? 100 : 0) +
              (historyEntry ? 100 : 0)
      }
    }
  ];
};

const adoptWFT = (transition) => ({
  id: transition.id,

  source: transition.from.id,
  target: transition.to.id,
  label: transition.name,
  animated: true
  /* position: { x: 250, y: 0 } */
});

const countWFTCount = (activity, workflow) => {
  const transition = workflow.process.transitions.find((transition) => transition.to.id == activity.id);
  if (transition) {
    const activityNew = workflow.process.activities.find((activity) => transition.from.id == activity.id);
    const transitionsNew = workflow.process.transitions.filter((transition) => transition.from.id == activityNew.id);
    if (transitionsNew.length >= 2 && transitionsNew[0].to.id == activity.id) {
      return -125;
    }
    if (transitionsNew.length >= 2) {
      return 125;
    }
  }
  return 0;
};

export const MyNode = memo(({ data, isConnectable }) => {
  const { versionInfo, myuser } = useSelector((state) => state);

  const onChangeUser = async (user) => {
    await updateParticipant({
      versionId: versionInfo.info.versionId,
      userName: user.userName,
      participant: data.participant.workflowParticipant.id
    });
  };

  const onChangeDateCallback = async (date) => {
    await updateDeadline({ versionId: versionInfo.info.versionId, deadlineName: data.deadline.identifier, date });
  };

  return (
    <>
      {!data.isStart && (
        <Handle
          type="target"
          position="top"
          style={{ background: '#555' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
      )}
      <Typography variant="h6" gutterBottom component="div" title={data.activity.description}>
        {data.activity.name}
      </Typography>

      {data.deadline && !data.hasCompleted && (
        <DeadlineEditor
          disabled={isReadOnly(versionInfo, myuser)}
          onChangeDateCallback={onChangeDateCallback}
          deadline={data.deadline}
          task={data.task}
        />
      )}
      {data.participant && !data.hasCompleted && (
        <TaskMember
          onChangeUser={onChangeUser}
          disabled={isReadOnly(versionInfo, myuser)}
          participant={data.participant}
        />
      )}
      {data.historyEntry && (
        <Typography variant="h6" gutterBottom component="div" title={data.historyEntry.text}>
          <FormattedMessage
            id="TaskWorkfloweView.HistoryMessage"
            defaultMessage="{user} at {performedAt}"
            values={{
              performedAt: data.historyEntry.performedAt,
              user: data.historyEntry.performedBy && data.historyEntry.performedBy.displayName
            }}
          />
        </Typography>
      )}
      {!data.isDone && (
        <Handle type="source" position="bottom" id="a" style={{ background: '#555' }} isConnectable={isConnectable} />
      )}
      {/* <Handle
                type="source"
                position="bottom"
                id="b"
                style={{ left: 10, top: 'auto', background: '#555' }}
                isConnectable={isConnectable}
            /> */}
    </>
  );
});

const nodeTypes = {
  selectorNode: MyNode
};

export default function TaskWorkflowView({ versionId, onModification = () => {}}) {
  const classes = useStyles();
  const [workflow, setWorkflow] = useState(null);
  const [elements, setElements] = useState(null);
  const [task, setTask] = useState(null);
  const onElementsRemove = (elementsToRemove) => setElements((els) => removeElements(elementsToRemove, els));
  const onConnect = (params) => setElements((els) => addEdge(params, els));

  const asynLoadTemplate = async () => {
    const workflow = await loadWorkflowTemplate(versionId);
    const task = await getTask(versionId);
    const wft = [
      ...workflow.process.activities.reduce((acum, activity) => adoptTasks(acum, activity, workflow, task), []),
      ...workflow.process.transitions.map((activity) => adoptWFT(activity))
    ];

    setTask(task);
    setElements(wft);
    setWorkflow(workflow);
  };

  useEffect(() => {
    asynLoadTemplate();
  }, [versionId]);

  if (!workflow) {
    return (
      <Loader>
        <FormattedMessage id="TaskWorkflowView.Loading" defaultMessage="Loading task worflow..." />
      </Loader>
    );
  }

  return (
    <div className={classes.root}>
      <ReactFlow
        elements={elements}
        /* onElementsRemove={()=>} */
        onConnect={onConnect}
        /* onElementClick={(event, element)=>{renderInExceptionRoot(ConfirmationDialog, {

                contextText: <p>{workflow.process.activities.find(act => act.id == element.id).name} -> {workflow.process.activities.find(act => act.id == element.id).description}</p>,
                confirmButtonText: <FormattedMessage id="cachedRiskRow.confirmDelete.ok" defaultMessage="Ok"/>,
                titleText: <p>{workflow.process.activities.find(act => act.id == element.id).name}</p>,
            })}} */
        nodeTypes={nodeTypes}
        onLoad={onLoad}
        snapToGrid
        snapGrid={[15, 15]}
        style={{ height: '800px' }}
        onElementClick={onModification}
      >
        <MiniMap
          nodeStrokeColor={(n) => {
            if (n.style?.background) return n.style.background;
            if (n.style?.colorstroke) return n.style.colorstroke;
            if (n.type === 'input') return '#0041d0';
            if (n.type === 'output') return '#ff0072';
            //         if (n.isCurrent) return '#ff0072';
            if (n.type === 'selectorNode') return '#1a192b';

            return '#eee';
          }}
          nodeColor={(n) => {
            if (n.style?.background) return n.style.background;
            if (n.type === 'selectorNode') return '#fff';

            return '#fff';
          }}
          nodeBorderRadius={2}
        />
        <Controls />
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </div>
  );
}
