import React, { useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { handlePageLayoutSingleLevel } from './utils/helpers';
import DialogAddReferences from './dialog/DialogAddReferences';
import { TabsContext } from './Stylesheet';
import { adobeName } from "../formatTemplatePlugin/utils/main";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 900
  },
  wrapperBlock: {
    display: 'grid',
    gridTemplateColumns: '1fr 25px',
    gridColumnGap: 5
  },
  listWrapper: {
    border: '1px solid #BDBDBC',
    borderRadius: 5,
    overflow: 'auto',
    height: 198
  },
  wrapperEditor: {
    color: '#4db1d3',
    '& svg': {
      cursor: 'pointer'
    }
  },
  wrapperTitle: {
    padding: '2px 4px',
    cursor: 'pointer'
  },
  wrapperTitleSelected: {
    padding: '2px 4px',
    cursor: 'pointer',
    background: '#4db1d3',
    color: '#ffffff'
  }
}));

function SelectedBlock({ label, formats, ollFormats, type }) {
  const cl = useStyles();
  const [selectedReferences, setSelectedReferences] = useState(0);
  const { values, setValues } = useContext(TabsContext);

  const handleFormats = (id, val) => {
    handlePageLayoutSingleLevel([val, ...formats], type, values, setValues);
  };
  const handleOpenDialog = () => {
    const result = values.addReferences.reduce((acu, cur) => {
      const findActiveTemplateList = ollFormats.find((el) => el.identifier === cur.identifier);
      if (findActiveTemplateList) {
        return acu;
      }
      return [...acu, cur];
    }, []);

    renderInExceptionRoot(DialogAddReferences, {
      values: result,
      title: 'Add format',
      setValues: handleFormats
    });
  };

  const handleSelectedTab = (i) => {
    setSelectedReferences(i);
  };

  const handleIdentifier = () => {
    if (formats.length === 0) {
      return;
    }
    const result = formats.filter((el, i) => i !== selectedReferences);
    handlePageLayoutSingleLevel(result, type, values, setValues);
  };

  return (
    <div>
      <span className={cl.label}>{label}</span>
      <div className={cl.wrapperBlock}>
        <div className={cl.listWrapper}>
          {formats.length > 0 && (
            <div>
              {formats.map((el, i) => (
                <div
                  key={i}
                  onClick={() => handleSelectedTab(i)}
                  className={selectedReferences === i ? cl.wrapperTitleSelected : cl.wrapperTitle}
                >
                  <span>{el.type &&  adobeName( el.type)}: </span>
                  {el.identifier && el.identifier}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={cl.wrapperEditor}>
          <AddIcon onClick={handleOpenDialog} />
          <DeleteIcon onClick={handleIdentifier} />
        </div>
      </div>
    </div>
  );
}

export default SelectedBlock;
