import React, {useContext, useEffect, useState} from 'react';
import {Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {PanelContext} from '../InfoElementWrapper/InfoElementWrapper';
import {storePublishingConditions, storePublishingFilters} from './API';
import {getApiPublishingConditions, getApiPublishingFilters, getApiTree} from '../Structure/API';
import CategoryRoot from "./components/publishingFilters/CategoryRoot";
import Divider from "@mui/material/Divider";
import {createFilterConditionRequest} from "./components/publishingFilters/utils/publishingFilterUtils";
import {usePublishingFilterStyles} from "./components/publishingFilters/styles";
import Loading from "clm/src/plugins/StylesheetPlugin/Loading";

export const conditionOptions = [
    {label: "OR", identifier: "OR"},
    {label: "AND", identifier: "AND"},
]

export const getFilterCondition = (conditionOption, category, selectedCategoryItem) => {
    return {
        condition: conditionOption || conditionOptions[1],
        category: category || null,
        ...category && {
            categoryItems: category.items
        },
        selectedCategoryItem: selectedCategoryItem || null,
        filterType: 'INCLUDE',
        checked: true
    }
}

const getInitConditions = () => {
    return [{
        condition: conditionOptions[1],
        childConditions: [
            {
                condition: conditionOptions[1],
                filters: [
                    getFilterCondition()
                ]
            }
        ]
    }]
}

const PublishingFilters = ({context}) => {
    const cl = usePublishingFilterStyles();
    const {versionId} = useParams();
    const {values, setValues} = useContext(context ? context : PanelContext);
    const {publishingFilters, mylocale} = useSelector((state) => state);

    const [openFilterConditions, setOpenFilterConditions] = useState(false)
    const [filterConditions, setFilterConditions] = useState()
    const [savedFilters, setSavedFilters] = useState(null);
    const [categoryItems, setCategoryItems] = useState(null);
    const [filterLoading, setFilterLoading] = useState(false)
    const [test, setTest] = useState({})

    const getPublishingFilters = async () => {
        const resPublishingFilters = await getApiPublishingFilters(
            versionId,
            values.treeItem.elementInfo.versionId,
            values.treeItem.identifier.replace(/['[']/g, '(').replace(/['\]']/g, ')').split(' ')[0].split(')')[0] + ')'
        );

        const resPublishingConditions = await getApiPublishingConditions(
            versionId,
            values.treeItem.elementInfo.versionId,
            values.treeItem.identifier.replace(/['[']/g, '(').replace(/['\]']/g, ')').split(' ')[0].split(')')[0] + ')'
        );

        setValues((prev) => ({
            ...prev,
            publishingFilters: resPublishingFilters,
            publishingConditions: resPublishingConditions
        }));
        //publishingFilters
        const resultGetTree = await getTree();
        setValues((prev) => ({...prev,
            treeChildren: resultGetTree,
            addPublishingFilters: {...prev.treeItem, publishingConditions: resPublishingConditions, },

        }));

    }
    // console.log('test', test);
    const loadInitialFilters = async () => {
        setValues((prev) => ({
            ...prev,
            publishingFilters: values.treeItem.publishingFilters,
            publishingConditions: values.treeItem.publishingConditions
        }));

        if((values.treeItem.publishingFilters && values.treeItem.publishingFilters.length > 0)
            || (values.treeItem.publishingConditions && values.treeItem.publishingConditions.length > 0)) {
            setOpenFilterConditions(true)
        }

        const resultGetTree = await getTree();
        setValues((prev) => ({...prev, treeChildren: resultGetTree}));

        setFilterLoading(false)
    }

    useEffect(() => {
        if (values.treeItem && values.treeItem.identifier && values.treeItem?.elementInfo?.versionId && !values.treeItem.parentTreeItem) {
            try {
                setFilterLoading(true)
                setOpenFilterConditions(false)
                loadInitialFilters()
            } catch (e) {
                console.log('Error get publishing filters!', e.messages);
            }
        }
    }, [values.treeItem])

    useEffect(() => {
        if (values.publishingConditions && categoryItems) {
            const adoptedFilterConditions = values.publishingConditions.map(item => {
                return {
                    condition: conditionOptions.find(condition => condition.identifier === item.condition),
                    childConditions: item.childConditions.map(childCondition => {
                        return {
                            ...childCondition,
                            condition: conditionOptions.find(condition => condition.identifier === childCondition.condition),
                            filters: childCondition.filters.map(filter => {
                                const selectedCategory = categoryItems.find(category => category.identifier === filter.filterIdentifier)
                                return {
                                    category: selectedCategory,
                                    categoryItems: selectedCategory.items,
                                    selectedCategoryItem: selectedCategory.items.find(subCategory => subCategory.identifier === filter.filterItemIdentifier),
                                    filterType: filter.filterType
                                }
                            })
                        }
                    })
                }
            })

            if (adoptedFilterConditions && adoptedFilterConditions.length > 0) {
                setFilterConditions(adoptedFilterConditions)
            } else {
                setFilterConditions(getInitConditions())
            }
        }
    }, [values.publishingConditions])

    useEffect(() => {
        if (!categoryItems && publishingFilters && publishingFilters.length > 0) {
            if (values && values.publishingFilters && values.publishingFilters.length > 0) {
                const adapterPublishingFilters = publishingFilters
                    .reduce((acu, cur) => {
                        const findFilterType = values.publishingFilters.find((el) => el.filterIdentifier === cur.identifier);
                        if (findFilterType && findFilterType.filterIdentifier === cur.identifier) {
                            const res = cur.items.reduce((acuitems, curitems) => {
                                const findChild = findFilterType.filterItemIdentifiers.find((el) => el === curitems.identifier);
                                if (findChild) {
                                    return [...acuitems, {...curitems, checked: true}];
                                }
                                return [...acuitems, {...curitems, checked: false}];
                            }, []);
                            return [...acu, {...cur, items: res, filterType: findFilterType.filterType}];
                        }
                        return [...acu, cur];
                    }, [])
                    .map((el) => ({...el, filterType: 'INCLUDE'}));
                setCategoryItems(adapterPublishingFilters);
            } else {
                const adapterPublishingFilters = publishingFilters.map((el) => ({
                    ...el,
                    filterType: 'INCLUDE',
                    items: el.items.map((child) => ({...child, checked: false}))
                }));
                setCategoryItems(adapterPublishingFilters);
            }
        }
    }, [publishingFilters, values]);

    useEffect(() => {
        if (categoryItems && values && values.publishingFilters && values.publishingFilters.length > 0) {
            setSavedFilters(values.publishingFilters);
            setFilterConditions([{
                condition: conditionOptions[0],
                childConditions: [{
                    condition: conditionOptions[0],
                    filters: values.publishingFilters.reduce((acc, filter) => {
                        const selectedCategory = categoryItems.find(category => category.identifier === filter.filterIdentifier)

                        filter.filterItemIdentifiers.map(filterItem => {
                            acc.push({
                                category: selectedCategory,
                                categoryItems: selectedCategory.items,
                                selectedCategoryItem: selectedCategory.items.find(subCategory => subCategory.identifier === filterItem),
                                filterType: filter.filterType
                            })

                        })

                        return acc
                    }, [])
                }]
            }])
        } else {
            setSavedFilters(null);
        }
    }, [values, categoryItems]);

    const getTree = async () => {
        return getApiTree(versionId, mylocale);
    };

    const addFirstLevelCondition = () => {
        setFilterConditions(prev => {
            if(!prev[0]?.childConditions) {
                const conditionsChildren = []
                conditionsChildren.push({
                    condition: conditionOptions[1],
                    filters: [
                        {
                            condition: conditionOptions[1],
                            checked: true,
                            filterType: 'INCLUDE'
                        }
                    ]
                })
                const condition = {
                    childConditions: conditionsChildren,
                    condition: conditionOptions[1],
                }
                return [...prev, condition]
            }

            const conditionsChildren = prev[0].childConditions
            conditionsChildren.push({
                condition: conditionOptions[1],
                filters: [
                    {
                        condition: conditionOptions[1],
                        checked: true,
                        filterType: 'INCLUDE'
                    }
                ]
            })
            const condition = {
                childConditions: conditionsChildren,
                condition: conditionOptions[1],
            }
            return [condition]
        })
    }

    const savePublicationFilters = async () => {
        setFilterLoading(true)
        await storePublishingConditions(
            versionId,
            values.treeItem.elementInfo.versionId,
            values.treeItem.identifier.replace(/['[']/g, '(').replace(/['\]']/g, ')'),
            createFilterConditionRequest(filterConditions)
        );

        if (savedFilters) {
            await storePublishingFilters(
                versionId,
                values.treeItem.elementInfo.versionId,
                values.treeItem.identifier.replace(/['[']/g, '(').replace(/['\]']/g, ')'),
                []
            );
        }

        getPublishingFilters()

        setFilterLoading(false)
    }

    return (
        <div>
            {publishingFilters && publishingFilters.length > 0 && (
                <div className={cl.rootTitle}>
                    <p className={cl.title}>Publishing Filters</p>
                    <div className={cl.AddIcon}
                         onClick={() => {
                             setOpenFilterConditions(prev => !prev)
                         }}>
                        {openFilterConditions ? <RemoveIcon/> : <AddIcon/>}
                    </div>
                </div>
            )}

            {!filterLoading && openFilterConditions && <>
                {filterConditions && <>
                    {filterConditions.map((condition, idx) => {
                        return <CategoryRoot conditionIdx={idx}
                                             setFilterConditions={setFilterConditions}
                                             condition={condition}
                                             categoryItems={categoryItems}
                        />
                    })}
                    <Button variant={"contained"} onClick={() => addFirstLevelCondition()}>+ Grupp</Button>
                    <Divider sx={{margin: '10px 0px'}}/>
                    <Button sx={{marginBottom: '10px'}}
                                   variant={"contained"}
                                   onClick={() => savePublicationFilters()}>
                        Save
                    </Button>
                </>}
            </>}

            {filterLoading && <Loading/>}

        </div>
    );
}

export default PublishingFilters;
