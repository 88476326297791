import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import enLocale from 'date-fns/locale/en-US';
import svLocale from 'date-fns/locale/sv';
import { API_ROOT_WAR, getPlugins } from '@zert-packages/actions/api';
import hasPermit from '@zert-packages/utils/hasPermit';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function ContentCage() {
  const language = document.getElementsByTagName('html')[0].getAttribute('lang');
  const MuiPickersLocale = language === 'sv' ? svLocale : enLocale;
  const permits = useSelector((state) => state.permits);
  const error = useSelector((state) => state.error);
  const isOfline = useSelector((state) => state.isOfline);
  const dispatch = useDispatch();
  const history = useHistory();

  const [offline, setOffline] = useState(false);
  const [mypermits, setPermits] = useState([]);

  /* useEffect(() => {
      if (offline != isOfline) {
        setOffline(isOfline);
      }
       history.block((location, action) => {
           if (isOfline) {
               dispatch(REDUCER_SET_ONLINE());
           }
       });
      if (!isOfline) {
          setPermits(permits);
      }
     },
       [isOfline, permits]
   ) */

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={MuiPickersLocale}>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/action-plan" />
          </Route>
          <Route path="/app">
            <Redirect to="/action-plan" />
          </Route>
          {getPlugins().map(
            (plugin) =>
              plugin.routes &&
              plugin.routes.map((route) => {
                if (route.listOfRoutes) return route.listOfRoutes();
                if (offline) {
                  return (
                    <Route exact path={+route.path} key={route.path}>
                      <div>
                        <h2 data-intro-id="content.offline" className="separator-text" style={{ textAlign: 'center' }}>
                          <FormattedMessage
                            id="content.offline"
                            defaultMessage="You currently offline and some functions are not available"
                          />
                        </h2>
                      </div>
                    </Route>
                  );
                }
                if (plugin.isSecured && !hasPermit(plugin, mypermits)) {
                  return (
                    <Route exact path={API_ROOT_WAR + route.path} key={route.path}>
                      <div>
                        <h2 data-intro-id="content.offline" className="separator-text" style={{ textAlign: 'center' }}>
                          <FormattedMessage
                            id="content.access.denied"
                            defaultMessage="You dont have permissions to this plugin"
                          />
                        </h2>
                      </div>
                    </Route>
                  );
                }
                return <Route exact path={route.path} key={route.path} component={route.component} />;
              })
          )}
        </Switch>
      </Suspense>
    </LocalizationProvider>
  );
}
