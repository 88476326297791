import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import findAllBasisProject from '@zert-packages/utils/findAllBasisProject';
import buildIsMatched from '../utils/buildIsMatched';
import { IntroductionContext } from '../../Introduction';
import countParticipantsInJSON from '../utils/countParticipantsInJSON';
import countBasis from '../utils/countBasis';

function countComments(comments, isMatched, counter) {
  comments.forEach((comment) => {
    if (isMatched(comment.text)) counter.rightPanel++;
  });
}

function countSuppliers(suppliers, isMatched, counter) {
  suppliers.forEach((supplier) => {
    if (isMatched(supplier.name)) counter.suppliers++;
    if (isMatched(supplier.contract)) counter.suppliers++;
  });
}

function findAndCountBasis(project, isMatched, counter) {
  const basis = [];
  findAllBasisProject(project, basis);
  countBasis(basis, isMatched, counter);
}

function useProjectConfig() {
  const { project } = useSelector((state) => state);
  const { setSearchTab } = useContext(IntroductionContext);

  function countMatchedElementsJSON(searchValue) {
    const counter = {
      rightPanel: 0,
      participants: 0,
      suppliers: 0,
      basis: 0
    };
    const isMatched = buildIsMatched(searchValue);
    if (isMatched(project.name)) counter.rightPanel++;
    if (isMatched(project.projectId)) counter.rightPanel++;
    if (project.manager && isMatched(project.manager.name)) counter.rightPanel++;
    if (project.leader && isMatched(project.leader.name)) counter.rightPanel++;
    if (isMatched(project.responsibleText)) counter.rightPanel++;
    if (isMatched(project.description)) counter.rightPanel++;
    countComments(project.comments, isMatched, counter);
    countParticipantsInJSON(project.participants, isMatched, counter);
    countSuppliers(project.suppliers, isMatched, counter);
    findAndCountBasis(project, isMatched, counter);
    return counter;
  }

  return {
    readyToSearch: project !== null,
    countMatchedElementsJSON,
    searchSettings: {
      dataset: ['contents'],
      classNames: ['react-autosuggest__input', 'MuiInputBase-input']
    },
    containers: [
      {
        counterField: 'rightPanel',
        id: 'right_panel',
        startSearching: (callback) => {
          setTimeout(() => {
            callback();
          }, 500);
        }
      },
      {
        counterField: 'participants',
        id: 'left_panel',
        startSearching: (callback) => {
          setSearchTab(1);
          setTimeout(() => {
            callback();
          }, 500);
        }
      },
      {
        counterField: 'basis',
        id: 'left_panel',
        startSearching: (callback) => {
          setSearchTab(2);
          setTimeout(() => {
            callback();
          }, 500);
        }
      },
      {
        counterField: 'suppliers',
        id: 'left_panel',
        startSearching: (callback) => {
          setSearchTab(3);
          setTimeout(() => {
            callback();
          }, 500);
        }
      }
    ]
  };
}

export default useProjectConfig;
