import React from 'react';
import { loadMyLocale, storeMyLocale, storeMyUser } from '@zert-packages/actions/api';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import UserSuggestBox from '@zert-packages/components/shared/UserSuggestBoxNew/UserSuggestBox';
import { EmailField } from '@zert-packages/components/shared/FormElements/EmailField';
import { PasswordField } from '@zert-packages/components/shared/FormElements/PasswordField';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { TextField2 } from '@zert-packages/components/shared/FormElements/TextField2';
import { FullField } from '@zert-packages/components/shared/FormElements/FullField';
import LicensePage from '@zert-packages/components/LicensePage';
import { Button, Select, MenuItem, FormControl, InputLabel, TextField } from '@mui/material';
import LoaderModal from '@zert-packages/components/shared/LoaderModal';
import HoverWithSnackbarLoader from '@zert-packages/components/HoverWithSnackbarLoader';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import Box from '@mui/material/Box';

class PersonalSettingsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locales: [
        {
          value: 'default',
          label: this.props.intl.formatMessage({
            id: 'settings.language.default',
            defaultMessage: 'Default'
          }),
          label2: <FormattedMessage id="settings.language.default" defaultMessage="Default" />
        },
        {
          value: 'sv',
          label: this.props.intl.formatMessage({
            id: 'settings.language.sv',
            defaultMessage: 'Swedish'
          }),
          label2: <FormattedMessage id="settings.language.sv" defaultMessage="Swedish" />
        },
        {
          value: 'en',
          label: this.props.intl.formatMessage({
            id: 'settings.language.en',
            defaultMessage: 'English'
          }),
          label2: <FormattedMessage id="settings.language.en" defaultMessage="English" />
        },
        {
          value: 'da',
          label: this.props.intl.formatMessage({
            id: 'settings.language.da',
            defaultMessage: 'Danish'
          }),
          label2: <FormattedMessage id="settings.language.da" defaultMessage="Danish" />
        }
      ],
      password: true,
      confirmPassword: true,
      errors: {},
      mailErrors: {},
      showLicenseAgreement: false
    };
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    this.onChangeUser = this.onChangeUser.bind(this);
    this.storeUser = this.storeUser.bind(this);

    this.setErrors = this.setErrors.bind(this);
    this.setValues = this.setValues.bind(this);
    this.setMailErrors = this.setMailErrors.bind(this);
    this.checkNulls = this.checkNulls.bind(this);
    this.showLicenseAgreement = this.showLicenseAgreement.bind(this);
  }

  showLicenseAgreement() {
    this.setState({ showLicenseAgreement: true });
  }

  hideLicenseAgreement = () => {
    this.setState({ showLicenseAgreement: false });
  };

  componentDidMount() {
    if (this.state.mylocale == null && !this.props.mylocale) {
      this.props.dispatch(loadMyLocale());
    } else if (this.props.mylocale) {
      this.setState({ mylocale: this.props.mylocale });
    }
  }

  handleChangeLanguage(e) {
    this.setState({ mylocale: e.target.value });
    caches.keys().then((keyList) => {
      Promise.all(
        keyList.map((key) => {
          console.log(`Deleting cache: ${key}`);
          return caches.delete(key);
        })
      );

      this.props.dispatch(storeMyLocale(e.target.value));
    });
  }

  componentDidUpdate(prevPropps) {
    if (this.props.mylocale != null && this.state.mylocale == null) {
      this.setState({ mylocale: this.props.mylocale });
    }

    if (this.state.myuser == null && this.props.myuser) {
      this.setState({
        myuser: this.props.myuser,
        values: {
          amount: '',
          usermail: this.props.myuser.email,
          password: '',
          weight: '',
          weightRange: '',
          fullName: this.props.myuser.fullname,
          confirmedPassword: '',
          showPassword: false,
          selectedDate: this.props.myuser.accountExpire,
          role: this.props.myuser.role,
          replaceTo: this.props.myuser.replaceUntil,
          replaceFrom: this.props.myuser.replaceFrom,
          initialEmail: this.props.myuser.email
        },
        active: this.props.myuser.active,
        activeDirectory: this.state.activeDirectory,
        temporaryReplacer: this.props.myuser.temporaryReplacer,
        replaceFrom: this.props.myuser.replaceFrom,
        replaceUntil: this.props.myuser.replaceUntil,
        minPasswordLength: this.props.myuser.minPasswordLength,
        isSpecialCharPassword: this.props.myuser.specialCharPassword,
        isUppercasePassword: this.props.myuser.uppercasePassword,
        isNumericPassword: this.props.myuser.numericPassword
      });
    }
  }

  onChangeUser(user) {
    this.setState({ temporaryReplacer: user });
  }

  handleReplaceFrom = (date) => {
    this.setValues({
      ...this.state.values,
      replaceFrom: date
    });
  };

  handleReplaceTo = (date) => {
    this.setValues({
      ...this.state.values,
      replaceTo: date
    });
  };

  setErrors(errors) {
    this.setState({ errors });
  }

  setMailErrors(errors) {
    this.setState({ mailErrors: errors });
  }

  setValues(values) {
    this.setState({ values });
  }

  storeUser() {
    const myuser = {
      fullname: this.state.values.fullName,
      email: this.state.values.usermail,
      replaceFrom: this.state.values.replaceFrom,
      activeDirectory: this.state.activeDirectory,
      temporaryReplacer: !this.state.temporaryReplacer
        ? null
        : {
            userName: this.state.temporaryReplacer.userName
          },
      replaceUntil: this.state.values.replaceTo,
      password: this.state.values.password
    };
    this.props.dispatch(storeMyUser(myuser));
  }

  checkNulls = (errors) => {
    let hasNulls = false;
    const keys = Object.keys(errors);
    keys.map((key) => {
      if (errors[key]) {
        hasNulls = true;
      }
    });
    return hasNulls;
  };

  render() {
    const { values, mailErrors, errors, showLicenseAgreement } = this.state;
    const { intl } = this.props;
    if (!this.state.myuser) {
      return <Loader />;
    }

    const formAccepted =
      values.usermail != null &&
      values.usermail.length != 0 &&
      !this.checkNulls(errors) &&
      !this.checkNulls(mailErrors) &&
      values.fullName != null &&
      values.fullName.length != 0;

    return (
      <div className="content-cage PersonalSettings__ViewContainer">
        {!showLicenseAgreement && (
          <div className="PersonalSettings__MainContainer">
            <h3 className="question-block">
              <FormattedMessage id="settings.caption.languagesetting" defaultMessage="Language Settings" />
            </h3>

            <div className="question-section">
              {this.state.mylocale && (
                <FormControl fullWidth>
                  <InputLabel>
                    <FormattedMessage id="settings.language" defaultMessage="My language" />
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    name="language"
                    value={this.state.mylocale}
                    onChange={this.handleChangeLanguage}
                  >
                    {this.state.locales.map((locale) => (
                      <MenuItem key={locale.value} value={locale.value}>
                        {locale.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>

            <h3 className="question-block">
              <FormattedMessage id="settings.caption.generalsetting" defaultMessage="General Settings" />
            </h3>

            {/** Render the fullname form field passing the name validation fn * */}
            <TextField2
              required
              readOnly
              value={this.props.myuser.userName}
              id="userName"
              label={<FormattedMessage id="settings.personalsettings.myusername" defaultMessage="User name" />}
            />

            <FullField
              values={values}
              setValues={this.setValues}
              errors={errors}
              setErrors={this.setErrors}
              required
              readOnly={this.props.myuser.activeDirectory}
            />

            {/** Render the email field component * */}
            <EmailField
              values={values}
              setValues={this.setValues}
              errors={mailErrors}
              readOnly={this.props.myuser.activeDirectory}
              setErrors={this.setMailErrors}
              required
            />

            {this.props.myuser.activeDirectory ? (
              <FormattedMessage
                id="PersinalSettings.usereditor.passowrd.ad"
                defaultMessage="Password is managed via Azure AD"
              />
            ) : (
              <PasswordField
                name="password"
                fieldId="password"
                showLegend
                label={intl.formatMessage({
                  id: 'settings.caption.password',
                  defaultMessage: 'Password'
                })}
                values={values}
                setValues={this.setValues}
                errors={errors}
                setErrors={this.setErrors}
                thresholdLength={this.state.minPasswordLength}
                minStrength={3}
                autoComplete="new-password"
                isSpecialCharPassword={this.state.isSpecialCharPassword}
                isUppercasePassword={this.state.isUppercasePassword}
                isNumericPassword={this.state.isNumericPassword}
              />
            )}

            <h3 className="question-block">
              <FormattedMessage id="settings.caption.temporaryreplacer" defaultMessage="Temporary replacer" />
            </h3>
            <div className="question-section">
              <UserSuggestBox
                readonly={this.props.readonly}
                onChange={this.onChangeUser}
                getUsers={getUsersQuery}
                getUserId={(user) => user.username}
                getUserLabel={(user) => user.name}
                initialValue={this.state.temporaryReplacer && this.state.temporaryReplacer.name}
                label={<FormattedMessage id="DelegateTasksSettingsDialog.UserLabel" defaultMessage="User" />}
              />
              <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' }
                }}
                noValidate
                margin="dense"
                autoComplete="off"
              >
                <DatePicker
                  disableToolbar
                  variant="inline"
                  /* variant="inline"
                margin="normal" */
                  ampm={false}
                  id="date-picker-inline"
                  label={<FormattedMessage id="usereditor.from" defaultMessage="From" />}
                  invalidDateMessage={<FormattedMessage id="datepicker.invalid" defaultMessage="Invalid date format" />}
                  maxDateMessage={
                    <FormattedMessage
                      id="datepicker.maxDateMessage"
                      defaultMessage="Date should not be after maximal date"
                    />
                  }
                  minDateMessage={
                    <FormattedMessage
                      id="datepicker.minDateMessage"
                      defaultMessage="Date should not be before minimal date"
                    />
                  }
                  value={values.replaceFrom}
                  onChange={this.handleReplaceFrom}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  renderInput={(props) => <TextField label={'margin="normal"'} margin="normal" {...props} />}
                />

                <DatePicker
                  disableToolbar
                  variant="inline"
                  style={{ margin: '5px' }}
                  /* margin="normal" */
                  ampm={false}
                  id="date-picker-inline"
                  label={<FormattedMessage id="usereditor.to" defaultMessage="To" />}
                  invalidDateMessage={<FormattedMessage id="datepicker.invalid" defaultMessage="Invalid date format" />}
                  maxDateMessage={
                    <FormattedMessage
                      id="datepicker.maxDateMessage"
                      defaultMessage="Date should not be after maximal date"
                    />
                  }
                  minDateMessage={
                    <FormattedMessage
                      id="datepicker.minDateMessage"
                      defaultMessage="Date should not be before minimal date"
                    />
                  }
                  value={values.replaceTo}
                  onChange={this.handleReplaceTo}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  renderInput={(props) => <TextField label={'margin="normal"'} margin="normal" {...props} />}
                />
              </Box>
            </div>
            <div className="PersonalSettings__ActionContainer">
              {formAccepted && (
                <Button onClick={this.storeUser} variant="outlined" color="primary">
                  <FormattedMessage id="settings.savesettings" defaultMessage="OK" />
                </Button>
              )}
              <Button onClick={this.showLicenseAgreement} color="primary" variant="outlined">
                <FormattedMessage id="settings.showLicenseAgreement" defaultMessage="Show License agreement" />
              </Button>
            </div>
          </div>
        )}

        {this.props.storing && <HoverWithSnackbarLoader />}
        <LoaderModal open={this.props.loading} />
        {showLicenseAgreement && <LicensePage readOnly hideLicenseAgreement={this.hideLicenseAgreement} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mylocale: state.mylocale,
  myuser: state.myuser,
  loading: state.loading,
  storing: state.storing
});

export default injectIntl(connect(mapStateToProps)(PersonalSettingsView));
