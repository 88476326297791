import React, { useContext } from 'react';
import { MailSettingsContext } from './common/ContextProvider';
import BoxWithTitle from '@zert-packages/components/shared/BoxWithTitle';
import TextFieldWithMemo from '@zert-packages/components/common/TextFieldWithMemo';
import CheckboxWithMemo from '@zert-packages/components/common/CheckboxWithMemo';
import SaveActions from './common/CancelSavePanel';
import './MailSettings.css';
import { FormattedMessage } from 'react-intl';
import UserSuggestBox from '@zert-packages/components/shared/UserSuggestBoxNew/UserSuggestBox';
import { getUsersQuery } from '@zert-packages/actions/actionplan';

function GeneralPage() {
  const { mainData, handleUpdateGeneral, handleSave, handleCancel, errors, userRole, roles } =
    useContext(MailSettingsContext);
  const { general } = mainData;

  const updatePersonal = (fieldToUpdate, value) => handleUpdateGeneral('personal', fieldToUpdate, value);

  const updateCompany = (fieldToUpdate, value) => handleUpdateGeneral('company', fieldToUpdate, value);

  const updateSystem = (fieldToUpdate, value) => handleUpdateGeneral('system', fieldToUpdate, value);

  return (
    <div className="mail-settings-general__root-container">
      <div className="mail-settings-general__body-container">
        <BoxWithTitle
          title={<FormattedMessage id="MailSettings.GeneralPage.titlePersonal" defaultMessage="Personal" />}
        >
          <ItemContainer>
            <DeadLineRemind
              value={general.personal.deadlineRemind}
              onChange={(value) => updatePersonal('deadlineRemind', value)}
            />
            <CheckboxWithMemo
              isChecked={general.personal.mailMeOnlyOnceADay}
              onChange={(isChecked) => updatePersonal('mailMeOnlyOnceADay', isChecked)}
              label={
                <FormattedMessage
                  id="MailSettings.GeneralPage.checkBox.mailMeOnce"
                  defaultMessage="Mail me only once a day"
                />
              }
            />
          </ItemContainer>
        </BoxWithTitle>

        {(userRole === roles.ADMINISTRATOR || userRole === roles.TASK_ADMINISTRATOR) && (
          <BoxWithTitle
            title={<FormattedMessage id="MailSettings.GeneralPage.titleCompany" defaultMessage="Company" />}
          >
            <ItemContainer>
              <DeadLineRemind
                value={general.company.deadlineRemind}
                onChange={(value) => updateCompany('deadlineRemind', value)}
              />
              <CompanyFallBack
                value={general.company.fallbackEmail}
                onChange={(value) => updateCompany('fallbackEmail', value)}
              />
              <CheckboxWithMemo
                isChecked={general.company.mailMeOnlyOnceADay}
                onChange={(isChecked) => updateCompany('mailMeOnlyOnceADay', isChecked)}
                label={
                  <FormattedMessage
                    id="MailSettings.GeneralPage.checkBox.mailMeOnce"
                    defaultMessage="Mail me only once a day"
                  />
                }
              />
            </ItemContainer>
          </BoxWithTitle>
        )}

        {userRole === roles.ADMINISTRATOR && (
          <BoxWithTitle title={<FormattedMessage id="MailSettings.GeneralPage.titleSystem" defaultMessage="System" />}>
            <ItemContainer>
              <DeadLineRemind
                value={general.system.deadlineRemind}
                onChange={(value) => updateSystem('deadlineRemind', value)}
              />
              <CheckboxWithMemo
                isChecked={general.system.mailMeOnlyOnceADay}
                onChange={(isChecked) => updateSystem('mailMeOnlyOnceADay', isChecked)}
                label={
                  <FormattedMessage
                    id="MailSettings.GeneralPage.checkBox.mailMeOnce"
                    defaultMessage="Mail me only once a day"
                  />
                }
              />
            </ItemContainer>
          </BoxWithTitle>
        )}
      </div>
      <SaveActions onSave={handleSave} onCancel={handleCancel} />
    </div>
  );
}

export default GeneralPage;

function DeadLineRemind({ value, onChange }) {
  return (
    <TextFieldWithMemo
      value={value}
      onChange={onChange}
      helperText={
        <FormattedMessage
          id="MailSettings.GeneralPage.DeadLineRemind.helperText"
          defaultMessage="Deadline remind advance working days"
        />
      }
      type="number"
    />
  );
}
/* <TextFieldWithMemo
   helperText={<FormattedMessage
     id="MailSettings.GeneralPage.CompanyFallBack.helperText"
     defaultMessage="Company fallback email notify"
   />}
   value={value}
   type="email"
   onChange={onChange}
   error={error}
 /> */

function CompanyFallBack({ value, onChange, error }) {
  return (
    <div className="firstItem">
      <div className="label">
        <FormattedMessage
          id="MailSettings.GeneralPage.CompanyFallBack.helperText"
          defaultMessage="Company fallback email notify"
        />
      </div>
      <div className="edit">
        <UserSuggestBox
          onChange={onChange}
          getUsers={getUsersQuery}
          getUserId={(user) => user.username}
          getUserLabel={(user) => user.name}
          initialValue={value && value.name}
          label={<FormattedMessage id="DelegateTasksSettingsDialog.UserLabel" defaultMessage="User" />}
        />
      </div>
    </div>
  );
}
function ItemContainer({ children }) {
  return <div className="mail-settings-general__item-container">{children}</div>;
}
