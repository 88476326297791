import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import DialogDownloadImg from "./dialog/DialogDownloadImg";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogAddPhrase from "@zert-packages/components/dialog/DialogAddPhrase/DialogAddPhrase";
import DialogHotspot from "./dialog/dialoghotsport/DialogHotspot";
import {PanelContext} from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import DialogNewForm from "./dialog/DialogNewForm";
import DialogArrow from "./dialog/DialogArrow";
import {getImageContent} from "./API";
import {useDispatch, useSelector} from "react-redux";
import {handleBlock} from "@zert-packages/actions/commonReducer";

const useStyles = makeStyles((theme) => ({
    root: {
        listStyle: "none",
        padding: 0,
        margin: 0,
        display: "flex",
        flexWrap: 'wrap',
        border: "1px solid #d3d3d3",
        borderRadius: 5,
        marginBottom: 10
    },
    itemMenu: {
        cursor: "pointer",
        padding: "5px 10px 5px 6px",
        borderRight: "1px solid #919191",
        "&:hover": {
            backgroundColor: "#4db1d3",
            color: "#ffffff"
        }
    }
}));

const HeaderImg = ({setHotspots, el, hotspots, values, setCanvas, context}) => {
    const cl = useStyles();
    const {block} = useSelector((state) => state);
    const [openPhrase, setOpenPhrase] = useState(false);
    const {setValues} = useContext(context || PanelContext);
    const dispatch = useDispatch();
    const headerActionType = block.imgEditorActionType

    const addSaveBlock = () => {
        setValues(pre => ({...pre, changeBlock: true}))
    }


    const handleReduce = () => {
        const res = +(hotspots.percents / 1.5).toFixed(0)
        if (13 > res) {
            return setHotspots(pre => ({...pre, percents: 13}));
        }
        return setHotspots(pre => ({...pre, percents: res}));
    };

    const handleIncrease = () => {
        const res = +(hotspots.percents * 1.5).toFixed(0)
        if (2566 < res) {
            return setHotspots(pre => ({...pre, percents: 2566}));
        }
        return setHotspots(pre => ({...pre, percents: res}));
    };

    const DownloadImg = async () => {
        const res = await getImageContent(el.elementInfo.versionId);
        const url = window.URL.createObjectURL(new Blob([res.data], {type: res.headers["content-type"]}));
        const link = document.createElement("a");
        link.href = url;
        link.download = res.headers["content-disposition"].split("=")[1].replace(/(\.[^.]+)\1+/g, "$1");
        link.click();
        link.remove();

    };

    const saveImg = () => {
        renderInExceptionRoot(DialogDownloadImg, {action: addSaveBlock});
    };
    const AddPhrase = () => {
        setOpenPhrase(true)
    };
    const addHotspot = () => {
        renderInExceptionRoot(DialogHotspot, {setValues, values, action: addSaveBlock});
    }
    const addNewForm = () => {
        renderInExceptionRoot(DialogNewForm, {setValues, action: addSaveBlock});
    }

    const addNewArrow = () => {
        renderInExceptionRoot(DialogArrow, {setValues, action: addSaveBlock});
    }

    const removeItem = () => {
        setCanvas(pre => ({
            ...pre,
            item: pre.item.reduce((acu, cur) => {
                if (cur.id === values.selectedItem.id) {
                    return acu
                }
                return [...acu, cur]
            }, []),
            selectedItem: null
        }))
    }

    const dataMenu = [
        {name: "Upload", onClick: saveImg, type: 'Upload'},
        {name: "Download", onClick: DownloadImg, type: 'Download'},
        {name: "Add new phrase", onClick: AddPhrase, type: 'newPhrase'},
        {name: "New hotspot", onClick: addHotspot, type: 'NewHotspot'},
        {name: "New Form", onClick: addNewForm, type: 'NewForm'},
        {name: "New arrow", onClick: addNewArrow, type: 'NewArrow'},
        {name: "Zoom in", onClick: handleIncrease, type: 'ZoomIn'},
        {name: "Zoom out", onClick: handleReduce, type: 'ZoomOut'}
    ]

    const handleAlignText = (val) => {
        setCanvas(pre => ({
            ...pre,
            item: pre.item.reduce((acu, cur) => {
                if (cur.id === values.selectedItem.id) {
                    return [...acu, {...cur, align: val.toUpperCase()}
                    ]
                }
                return [...acu, cur]
            }, [])
        }))
    }

    const handleAction = () => {
        if(headerActionType === 'Remove'){
            dispatch(handleBlock(null, 'selectedImgFigure'))
            dispatch(handleBlock("", 'imgEditorActionType'))
            return removeItem()
        }
        if(headerActionType === 'left'){
            handleAlignText('left')
        }
        if(headerActionType === 'center'){
            handleAlignText('center')
        }
        if(headerActionType === 'right'){
            handleAlignText('right')
        }

        const menuAction = dataMenu.find(item => item.type === headerActionType);
        if (menuAction) {
            dispatch(handleBlock(null, 'imgEditorActionType'))
            menuAction.onClick();
        }
    }

    useEffect(() => {
        if (headerActionType) {
            handleAction()
        }
    }, [headerActionType]);


    useEffect(() => {
        if(values.selectedItem){
            dispatch(handleBlock(values.selectedItem, 'selectedImgFigure'))
        }
    }, [values.selectedItem])

    useEffect(() => {
        dispatch(handleBlock(null, 'selectedImgFigure'))
    }, [])

    if(block){
        return <div></div>
    }

    return (<>
        <ul className={cl.root} >
            {dataMenu.map((el, i) => <li key={i} className={cl.itemMenu} onClick={el.onClick}>
                {el.name}
            </li>)}
            {values.selectedItem && <li className={cl.itemMenu} onClick={removeItem}>Remove</li>}
            {values.selectedItem?.label && <>
                <li className={cl.itemMenu} onClick={() => handleAlignText('left')}>Align left</li>
                <li className={cl.itemMenu} onClick={() => handleAlignText('center')}>Align center</li>
                <li className={cl.itemMenu} onClick={() => handleAlignText('right')}>Align right</li>
                <li className={cl.itemMenu} onClick={removeItem}>Lock reference</li>
                <li className={cl.itemMenu} onClick={removeItem}>Unlock reference</li>
            </>}
        </ul>
        <DialogAddPhrase
            openPhrase={openPhrase}
            setOpenPhrase={setOpenPhrase}
            context={context}
        />
    </>);
};

export default HeaderImg;