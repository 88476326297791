import React from "react";
import {getPluginByMimeType} from "@zert-packages/utils/getPluginByMimeType";
import {useHistory} from "react-router-dom";
import {defaultKey, getFillFunction} from "@zert-packages/utils/fillTemplateString";
import {getNodeContext} from "../ExplorerPlugin/servicesMidleware";
import {useDispatch} from "react-redux";

const Shortcut = ({ identifier, url, id, versionId, name, mimeType, groups, sortValue, children}) => {
  const history = useHistory();
  const fillPath = getFillFunction(defaultKey);
  const dispatch = useDispatch();

  const navigateToTarget = () => {
    if (mimeType === 'application/zert-search-folder') {
      if (!history.location.pathname.includes('explorer')) {
        history.push('/explorer');
      }
      dispatch(getNodeContext({id: id, versionId: versionId}, true));
    }
    else if (url.startsWith("project_new") || url.startsWith("deviation_new") || url.startsWith("risk-assesment_new") ||
      url.startsWith("checklist_new") || url.startsWith("periodic-checklist_new") || url.startsWith("rmp_new")) {
      history.push("/" + url)
    }
    else if (url) {
      window.open(url);
    }
    else if (versionId !== 0){
      history.push(fillPath({versionId: versionId, id: id}, getPluginByMimeType(mimeType).openpath));
    }
  }

  return (
    <span onClick={navigateToTarget} style={{cursor: 'pointer'}}>
      {children ? children : name}
    </span>
  );
};

export default Shortcut;
