import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getСardFunc } from '@zert-packages/actions/api';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import { useSnackbar } from 'notistack';
import useElementActions from '../useElementActions';
import withTileDelayCard from './withTileDelayCard';

const AbstractCardTile = memo(
  withTileDelayCard((props) => {
    const snackbar = useSnackbar();
    const { workflowTemplate, reloadElement, element, overviewActions, showCut } = props;
    const myUser = useSelector((state) => state.myuser);
    const dispatch = useDispatch();
    const selected = [element];
    const { performActionHandler, getMenuActions } = useElementActions({
      workflowTemplate,
      reloadElement,
      selected,
      overviewActions,
      showCut
    });

    return getСardFunc()({
      ...props,
      workflowtemplateid: props.workflowTemplateId,
      performAction: performActionHandler(selected),
      actions: props.hideActions || getMenuActions(element).length === 0 ? undefined : getMenuActions(element),
      myUser
    });
  }),
  selectiveMemoComparison((p) => p.element.versionId)
);

export default AbstractCardTile;
