import React from 'react';
import PropTypes from 'prop-types';

function TabPanel({ children, value, index, overflow }) {
  return (
    index === value && (
      <div
        style={{
          display: value === index ? 'block' : 'none',
          overflow: !overflow ? 'hidden' : overflow,
          height: '100%',
          width: '100%'
        }}
      >
        {value === index && children}
      </div>
    )
  );
}

export default TabPanel;

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
