export const templatesData = {
  class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientNormalParagraphSettingsTemplate',
  condition: null,
  marker: false,
  marginLeft: {
    value: 3,
    unit: 'MILLIMETER'
  },
  marginRight: {
    value: 0,
    unit: 'MILLIMETER'
  },
  spaceBefore: {
    name: 'before',
    conditionality: 'DISCARD',
    value: null,
    minimum: null,
    maximum: null,
    precedence: 'DEFAULT'
  },
  spaceAfter: {
    name: 'after',
    conditionality: 'DISCARD',
    value: null,
    minimum: null,
    maximum: null,
    precedence: 'DEFAULT'
  },
  lineHeight: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
    tagName: 'line-height',
    resetValue: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
      type: 'DEFAULT',
      value: {
        value: 12,
        unit: 'POINTS'
      }
    },
    defaultValue: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
      type: 'DEFAULT',
      value: {
        value: 12,
        unit: 'POINTS'
      }
    },
    valueCount: 0
  },
  horizontalAlign: 'LEFT',
  fontFamily: 'default',
  fontSize: null,
  fontStyle: [],
  orphans: 1,
  widows: 1,
  keepTogether: false,
  keepWithPrevious: false,
  keepWithNext: false,
  spanAll: false,
  breakBefore: 'AUTO',
  breakAfter: 'AUTO',
  color: null,
  backgroundColor: null,
  borderTop: {
    edge: 'top',
    width: {
      value: 0,
      unit: 'POINTS'
    },
    style: 'NONE',
    color: {
      id: -1,
      metadataList: null,
      references: {},
      properties: {},
      parents: [],
      approvedAt: null,
      archivedAt: null,
      approvedBy: null,
      archivedBy: null,
      changedBy: null,
      changedAt: null,
      createdBy: null,
      createdAt: null,
      name: null,
      translatable: false,
      checkedOut: false,
      readOnly: false,
      checkoutByUser: null,
      published: false,
      locale: null,
      label: null,
      mimeType: null,
      versionId: -1,
      additionalInfo: null,
      companyId: 0,
      versionUUID: null,
      elementUUID: null,
      currentVersionId: -1,
      cmyk: false,
      red: 0,
      green: 0,
      blue: 0,
      alpha: 255
    }
  },
  borderBottom: {
    edge: 'bottom',
    width: {
      value: 0,
      unit: 'POINTS'
    },
    style: 'NONE',
    color: {
      id: -1,
      metadataList: null,
      references: {},
      properties: {},
      parents: [],
      approvedAt: null,
      archivedAt: null,
      approvedBy: null,
      archivedBy: null,
      changedBy: null,
      changedAt: null,
      createdBy: null,
      createdAt: null,
      name: null,
      translatable: false,
      checkedOut: false,
      readOnly: false,
      checkoutByUser: null,
      published: false,
      locale: null,
      label: null,
      mimeType: null,
      versionId: -1,
      additionalInfo: null,
      companyId: 0,
      versionUUID: null,
      elementUUID: null,
      currentVersionId: -1,
      cmyk: false,
      red: 0,
      green: 0,
      blue: 0,
      alpha: 255
    }
  },
  borderLeft: {
    edge: 'left',
    width: {
      value: 0,
      unit: 'POINTS'
    },
    style: 'NONE',
    color: {
      id: -1,
      metadataList: null,
      references: {},
      properties: {},
      parents: [],
      approvedAt: null,
      archivedAt: null,
      approvedBy: null,
      archivedBy: null,
      changedBy: null,
      changedAt: null,
      createdBy: null,
      createdAt: null,
      name: null,
      translatable: false,
      checkedOut: false,
      readOnly: false,
      checkoutByUser: null,
      published: false,
      locale: null,
      label: null,
      mimeType: null,
      versionId: -1,
      additionalInfo: null,
      companyId: 0,
      versionUUID: null,
      elementUUID: null,
      currentVersionId: -1,
      cmyk: false,
      red: 0,
      green: 0,
      blue: 0,
      alpha: 255
    }
  },
  borderRight: {
    edge: 'right',
    width: {
      value: 0,
      unit: 'POINTS'
    },
    style: 'NONE',
    color: {
      id: -1,
      metadataList: null,
      references: {},
      properties: {},
      parents: [],
      approvedAt: null,
      archivedAt: null,
      approvedBy: null,
      archivedBy: null,
      changedBy: null,
      changedAt: null,
      createdBy: null,
      createdAt: null,
      name: null,
      translatable: false,
      checkedOut: false,
      readOnly: false,
      checkoutByUser: null,
      published: false,
      locale: null,
      label: null,
      mimeType: null,
      versionId: -1,
      additionalInfo: null,
      companyId: 0,
      versionUUID: null,
      elementUUID: null,
      currentVersionId: -1,
      cmyk: false,
      red: 0,
      green: 0,
      blue: 0,
      alpha: 255
    }
  },
  paddingTop: {
    value: 0,
    unit: 'POINTS'
  },
  paddingBottom: {
    value: 0,
    unit: 'POINTS'
  },
  paddingLeft: {
    value: 0,
    unit: 'POINTS'
  },
  paddingRight: {
    value: 0,
    unit: 'POINTS'
  },
  numbering: 'HEADING',
  numberingAlign: 'LEFT',
  numberingStyle: 'ARABIC_NUMBERS',
  selectImage: 'None',
  numberingPrefix: {
    name: 'numbering-prefix',
    type: 'UNTRANSLATABLE_TEXT',
    text: {
      defaultLabel: null,
      locales: [],
      translations: []
    },
    untranslatableText: '',
    include: ''
  },
  numberingSuffix: {
    name: 'numbering-suffix',
    type: 'UNTRANSLATABLE_TEXT',
    text: {
      defaultLabel: null,
      locales: [],
      translations: []
    },
    untranslatableText: '',
    include: ''
  },
  numberingDepth: 1,
  numberingDisplayLevels: 1,
  numberingColor: null,
  textSeparation: null,
  labelSeparation: null,
  paragraphPrefixLabel: {
    visible: false,
    position: 'PREFIX',
    content: {
      name: 'content',
      type: 'UNTRANSLATABLE_TEXT',
      text: {
        defaultLabel: null,
        locales: [],
        translations: []
      },
      untranslatableText: '',
      include: ''
    },
    listContent: {
      name: 'list-content',
      type: 'DEFAULT',
      text: {
        defaultLabel: null,
        locales: [],
        translations: []
      },
      untranslatableText: '',
      include: ''
    },
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    horizontalAlign: 'LEFT',
    verticalAlign: 'TOP',
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    },
    borderTop: {
      edge: 'top',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderBottom: {
      edge: 'bottom',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderLeft: {
      edge: 'left',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderRight: {
      edge: 'right',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    paddingTop: {
      value: 0,
      unit: 'POINTS'
    },
    paddingBottom: {
      value: 0,
      unit: 'POINTS'
    },
    paddingLeft: {
      value: 0,
      unit: 'POINTS'
    },
    paddingRight: {
      value: 0,
      unit: 'POINTS'
    }
  },
  paragraphTopLabel: {
    visible: false,
    position: 'TOP',
    content: {
      name: 'content',
      type: 'UNTRANSLATABLE_TEXT',
      text: {
        defaultLabel: null,
        locales: [],
        translations: []
      },
      untranslatableText: '',
      include: ''
    },
    listContent: {
      name: 'list-content',
      type: 'DEFAULT',
      text: {
        defaultLabel: null,
        locales: [],
        translations: []
      },
      untranslatableText: '',
      include: ''
    },
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    horizontalAlign: 'LEFT',
    verticalAlign: 'TOP',
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    },
    borderTop: {
      edge: 'top',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderBottom: {
      edge: 'bottom',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderLeft: {
      edge: 'left',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderRight: {
      edge: 'right',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    paddingTop: {
      value: 0,
      unit: 'POINTS'
    },
    paddingBottom: {
      value: 0,
      unit: 'POINTS'
    },
    paddingLeft: {
      value: 0,
      unit: 'POINTS'
    },
    paddingRight: {
      value: 0,
      unit: 'POINTS'
    }
  },
  paragraphBottomLabel: {
    visible: false,
    position: 'BOTTOM',
    content: {
      name: 'content',
      type: 'UNTRANSLATABLE_TEXT',
      text: {
        defaultLabel: null,
        locales: [],
        translations: []
      },
      untranslatableText: '',
      include: ''
    },
    listContent: {
      name: 'list-content',
      type: 'DEFAULT',
      text: {
        defaultLabel: null,
        locales: [],
        translations: []
      },
      untranslatableText: '',
      include: ''
    },
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    horizontalAlign: 'LEFT',
    verticalAlign: 'TOP',
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    },
    borderTop: {
      edge: 'top',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderBottom: {
      edge: 'bottom',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderLeft: {
      edge: 'left',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderRight: {
      edge: 'right',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    paddingTop: {
      value: 0,
      unit: 'POINTS'
    },
    paddingBottom: {
      value: 0,
      unit: 'POINTS'
    },
    paddingLeft: {
      value: 0,
      unit: 'POINTS'
    },
    paddingRight: {
      value: 0,
      unit: 'POINTS'
    }
  },
  paragraphLeftLabel: {
    visible: false,
    position: 'LEFT',
    content: {
      name: 'content',
      type: 'UNTRANSLATABLE_TEXT',
      text: {
        defaultLabel: null,
        locales: [],
        translations: []
      },
      untranslatableText: '',
      include: ''
    },
    listContent: {
      name: 'list-content',
      type: 'DEFAULT',
      text: {
        defaultLabel: null,
        locales: [],
        translations: []
      },
      untranslatableText: '',
      include: ''
    },
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    horizontalAlign: 'LEFT',
    verticalAlign: 'TOP',
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    },
    borderTop: {
      edge: 'top',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderBottom: {
      edge: 'bottom',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderLeft: {
      edge: 'left',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderRight: {
      edge: 'right',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    paddingTop: {
      value: 0,
      unit: 'POINTS'
    },
    paddingBottom: {
      value: 0,
      unit: 'POINTS'
    },
    paddingLeft: {
      value: 0,
      unit: 'POINTS'
    },
    paddingRight: {
      value: 0,
      unit: 'POINTS'
    },
    width: {
      value: 10,
      unit: 'MILLIMETER'
    }
  },
  paragraphRightLabel: {
    visible: false,
    position: 'RIGHT',
    content: {
      name: 'content',
      type: 'UNTRANSLATABLE_TEXT',
      text: {
        defaultLabel: null,
        locales: [],
        translations: []
      },
      untranslatableText: '',
      include: ''
    },
    listContent: {
      name: 'list-content',
      type: 'DEFAULT',
      text: {
        defaultLabel: null,
        locales: [],
        translations: []
      },
      untranslatableText: '',
      include: ''
    },
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    horizontalAlign: 'LEFT',
    verticalAlign: 'TOP',
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    },
    borderTop: {
      edge: 'top',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderBottom: {
      edge: 'bottom',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderLeft: {
      edge: 'left',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    borderRight: {
      edge: 'right',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255
      }
    },
    paddingTop: {
      value: 0,
      unit: 'POINTS'
    },
    paddingBottom: {
      value: 0,
      unit: 'POINTS'
    },
    paddingLeft: {
      value: 0,
      unit: 'POINTS'
    },
    paddingRight: {
      value: 0,
      unit: 'POINTS'
    },
    width: {
      value: 10,
      unit: 'MILLIMETER'
    }
  },
  type: 'NORMAL'
};
