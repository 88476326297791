import React, { useContext } from 'react';
import TextFieldWithMemo from '@zert-packages/components/common/TextFieldWithMemo';
import { MailSettingsContext } from './common/ContextProvider';
import SaveActions from './common/CancelSavePanel';
import './MailSettings.css';
import { FormattedMessage } from 'react-intl';

function ServerPage() {
  const { mainData, handleUpdateServer, handleSave, handleCancel, errors } = useContext(MailSettingsContext);
  const { server } = mainData;

  return (
    <div className="mail-settings-server__root-container">
      <div className="mail-settings-server__body-container">
        <ItemContainer>
          <TextFieldWithMemo
            value={server.siteURL}
            onChange={(value) => handleUpdateServer('siteURL', value)}
            helperText={<FormattedMessage id="MailSettings.ServerPage.siteUrl.helperText" defaultMessage="Site URL" />}
          />
        </ItemContainer>
        <ItemContainer>
          <TextFieldWithMemo
            value={server.host}
            onChange={(value) => handleUpdateServer('host', value)}
            helperText={
              <FormattedMessage id="MailSettings.ServerPage.SMPTHost.helperText" defaultMessage="SMTP Host" />
            }
          />
        </ItemContainer>
        <ItemContainer>
          <TextFieldWithMemo
            value={server.port}
            onChange={(value) => handleUpdateServer('port', value)}
            helperText={
              <FormattedMessage id="MailSettings.ServerPage.SMTPPort.helperText" defaultMessage="SMTP Port" />
            }
            type="numer"
          />
        </ItemContainer>
        <ItemContainer>
          <TextFieldWithMemo
            value={server.username}
            onChange={(value) => handleUpdateServer('username', value)}
            helperText={
              <FormattedMessage id="MailSettings.ServerPage.SMPTUserName.helperText" defaultMessage="SMTP Username" />
            }
          />
        </ItemContainer>
        <ItemContainer>
          <TextFieldWithMemo
            value={server.password}
            onChange={(value) => handleUpdateServer('password', value)}
            helperText={
              <FormattedMessage id="MailSettings.ServerPage.SMPTPassword.helperText" defaultMessage="SMTP Password" />
            }
            fullWidth
            type="password"
          />
        </ItemContainer>
        <ItemContainer>
          <TextFieldWithMemo
            value={server.senderAddress}
            onChange={(value) => handleUpdateServer('senderAddress', value)}
            helperText={
              <FormattedMessage
                id="MailSettings.ServerPage.mailSenderAddress.helperText"
                defaultMessage="Mail sender address"
              />
            }
            type="email"
            error={typeof errors.senderAddress !== 'undefined'}
          />
        </ItemContainer>
        <ItemContainer>
          <TextFieldWithMemo
            value={server.senderName}
            onChange={(value) => handleUpdateServer('senderName', value)}
            helperText={
              <FormattedMessage
                id="MailSettings.ServerPage.mailSenderName.helperText"
                defaultMessage="Mail sender name"
              />
            }
            fullWidth
          />
        </ItemContainer>
        <ItemContainer>
          <TextFieldWithMemo
            value={server.supportAddress}
            onChange={(value) => handleUpdateServer('supportAddress', value)}
            helperText={
              <FormattedMessage
                id="MailSettings.ServerPage.supportAddress.helperText"
                defaultMessage="Support address"
              />
            }
            error={typeof errors.supportAddress !== 'undefined'}
          />
        </ItemContainer>
      </div>
      <SaveActions onSave={handleSave} onCancel={handleCancel} />
    </div>
  );
}

function ItemContainer({ children }) {
  return <div className="mail-settings-server__item-container">{children}</div>;
}

export default ServerPage;
