import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import classNames from 'classnames';
import './style.css';
import Typography from '@mui/material/Typography';

export default function ActionMenuNew(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClickOption = (event, option) => {
    event.preventDefault();
    event.stopPropagation();
    handleClose();
    if (typeof option.performAction === 'function') {
      option.performAction(option.id);

      return;
    }
    props.performAction(option.id);
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAnchorEl(null);
  };
  // 'dotsmenu':true,
  const btnClass = classNames({
    dots_row: !props.inlineAction,
    dots_inline: props.inlineAction,
    action_menu: true,
    dropdown: true,
    show: true
  });

  const filters = {
    isArchived: props.isArchived,
    isApproved: props.isApproved,
    hasVersions: props.hasVersions
  };

  return (
    <div
      className={btnClass}
      style={{
        order: props.inlineAction ? 2 : 3,
        display: props.visible ? 'block' : 'none',
        position: props.position === 'initial' ? 'initial' : '',
        alignSelf: 'center',
        marginRight: '1px'
      }}
    >
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size={props.small ? 'small' : 'medium'}
      >
        <MoreVertIcon fontSize={props.small ? 'small' : 'medium'} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        /* keepMounted */
        open={open}
        onClose={handleClose}
      >
        {props.actions
          .filter((option) => {
            if (option.isHidden) {
              return false;
            }

            for (const key in filters) {
              if (option[key] !== undefined && option[key] !== filters[key]) {
                return false;
              }
            }
            return true;
          })
          .map((option) => (
            <MenuItem key={option.id} onClick={(e) => handleClickOption(e, option)}>
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Typography variant="inherit" noWrap>
                {option.name}
              </Typography>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
