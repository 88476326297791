import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { getPlacement, placeElementNew, setPlacement } from '@zert-packages/actions/catalogActions';
import { FormattedMessage } from 'react-intl';
import CatalogPlacement from './CatalogPlacement';
import BreadCrumb from '../BreadCrumb/index';

function findPath(value, data) {
  const sel = [];
  function loop(selected, children) {
    for (let i = 0; i < children.length; i++) {
      const item = children[i];
      if (selected === item.value) {
        sel.push(item);
        return;
      }
      if (item.children) {
        loop(selected, item.children, item);
        if (sel.length) {
          sel.push(item);
          return;
        }
      }
    }
  }
  loop(value, data);
  return sel;
}

function _loop(node) {
  const children = [];
  node.children.map((child) => {
    if (child.metadata && child.metadata.length > 0)
      children.push({
        label: `${child.label.defaultLabel}`,
        value: `${child.metadata[0].value}`,
        key: `${child.uuid}`,
        disabled: child.metadata[0].value == '',
        expanded: true,
        children: _loop(child)
      });
    if (child.children.length != 0) {
      child.children.map((_child) => {
        return _loop(_child);
      });
    } else {
      return '[]';
    }
  });
  return children;
}
class CatalogInlineEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multipleValue: [],
      location1: null,
      expanded: false,
      gData: [],
      versionId: -1
    };
    this.assignChagedValues = this.assignChagedValues.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.populateCatalog = this.populateCatalog.bind(this);
  }

  handleClick(e) {
    if (!this.state.expanded) {
      this.setState({ expanded: true });
    }
  }

  populateCatalog(catalogArray) {
    const gData = [];
    catalogArray.map((catalog) => {
      if (catalog && catalog.rootNode && catalog.rootNode.metadata.length > 0) {
        gData.push({
          label: `${catalog.rootNode.label.defaultLabel}`,
          value: `${catalog.rootNode.metadata[0].value}`,
          key: `${catalog.rootNode.uuid}`,
          expanded: true,
          disabled: false,
          children: _loop(catalog.rootNode)
        });
      }
    });
    return gData;
  }

  componentDidMount() {
    this.setState({ multipleValue: [], versionId: this.props.versionId });
    if (!this.props.avoidReload) {
      this.props.dispatch(getPlacement(this.props.versionId));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.catalogPlacement &&
      (!this.state.location1 ||
        prevProps.catalogPlacement !== this.props.catalogPlacement ||
        prevProps.catalog !== this.props.catalog) &&
      this.props.versionId === this.props.catalogPlacement.versionId &&
      this.props.catalog
    ) {
      const gData = this.populateCatalog(this.props.catalog);
      const location1 = [];
      var multipleValue = [];
      this.props.catalogPlacement.data.map((placement) => {
        location1.push(
          findPath(placement, gData)
            .map((i) => i.label)
            .reverse()
            .join(' > ')
        );
        multipleValue.push({ value: placement });
      });
      this.setState({ location1, multipleValue, versionId: this.props.versionId });
    } else if (this.props.catalogPlacement && this.props.versionId !== this.props.catalogPlacement.versionId) {
      this.setState({ location1: null, multipleValue });
      this.props.dispatch(getPlacement(this.props.versionId));
    }
  }

  assignChagedValues(data) {
    const location1 = [];
    data.map((location) => {
      location1.push(location.label);
    });
    this.setState({ expanded: false, location1, multipleValue: data });

    const placement = [];
    data.map((value) => {
      placement.push(value);
    });
    this.props.dispatch(setPlacement(
      this.props.versionId, placement.map(o => o.value)
    ));
    placeElementNew(this.props.versionId, placement);
  }

  render() {
    if (!this.props.catalogPlacement || !this.state.location1 || !this.props.catalog) {
     if(this.props.setShowInlineEditor){
       this.props.setShowInlineEditor(true)
     }
      return <div className="breadcrumb-vaadin light" />;
    }
    const breadCrumbText = this.props.labelText != null
      ? this.props.labelText
      : <FormattedMessage id="headbar.catalogEditor" defaultMessage="In catalog" />;
    return (
      <>
        <CSSTransition
          in={!this.state.expanded}
          timeout={300}
          classNames="message"
          unmountOnExit
          onExited={() => {
            this.setState({
              showButtons: false
            });
          }}
        >
          <BreadCrumb
            itemLocation={this.state.location1}
            styleName={this.props.styleName || "light"}
            text={breadCrumbText}
            handleClick={this.handleClick}
          />
        </CSSTransition>

        <CSSTransition
          in={this.state.expanded}
          timeout={300}
          classNames="message"
          unmountOnExit
          onExited={() => {
            this.setState({
              showButtons: false
            });
          }}
        >
          <CatalogPlacement
            assignChagedValues={this.assignChagedValues}
            multipleValue={this.state.multipleValue}
            catalog={this.props.catalog}
            loading={this.props.loadingCatalog}
          />
        </CSSTransition>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  catalogPlacement: state.catalogPlacement,
  catalog: state.catalog,
  loadingCatalog: Object.values(state.loadingCatalogs)
    .reduce((a, v) => a || v, false)
});

export default connect(mapStateToProps)(CatalogInlineEditor);
