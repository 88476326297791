import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import getTranslation from '@zert-packages/utils/getTranslation.new';
import TextField from '@mui/material/TextField';
import { getFormatting } from '@zert-packages/utils/getFormatting';
import SearchComponent from './SearchComponent';
import withSearch, { styles, controlStyles } from '../withSearch';

function getStyles(name, that) {
  return {
    fontWeight:
      !that.state.Format || !name || that.state.Format.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
 /* getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }*/
};

class SparepartSearch extends SearchComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.getTranslation = this.getTranslation.bind(this);
    this.textInput = React.createRef();
  }

  handleChange = (name) => (event) => {
    this.setStatus({ [name]: event.target.value });
  };

  getTranslation = (value) => {
    const template = getFormatting(value, 'partFormats', this.props.formatTemplate);
    if (!template) {
      return '';
    }
    return getTranslation(template.label);
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    this.props.expanded && this.textInput.current ? this.textInput.current.blur() : this.textInput.current.focus();
  }

  render() {
    const { classes } = this.props;
    // this.props.handleChange(this.state);
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        <TextField
          autoFocus
          inputRef={this.textInput}
          id="partNumber"
          label={<FormattedMessage id="search.context.partNumber" defaultMessage="Part number" />}
          className={classes.formControl}
          value={this.state.PartNumber}
          onChange={this.handleChange('PartNumber')}
          type="search"
          placeholder="Part number"
          fullWidth
          variant="outlined"
        />

        <FormControl className={classes.formControl} fullWidth>
          <InputLabel htmlFor="select-multiple-chip">
            <FormattedMessage id="search part.formats" defaultMessage="Format" />
          </InputLabel>
          <Select
              size="small"
            value={this.state.Format}
            onChange={(e) => {
              this.setStatus({ Format: e.target.value });
            }}
            inputProps={{
              name: 'search-method',
              id: 'search-by'
            }}
          >
            {this.props.formatTemplate &&
              this.props.formatTemplate.partFormats.map((format) => (
                <MenuItem key={format.identifier} value={format.identifier} style={getStyles(format.identifier, this)}>
                  {getTranslation(format.label)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </form>
    );
  }
}

SparepartSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.context.parts" defaultMessage="Search in parts" />,
  index: 'part-criteria',
  className: 'se.zert.clm.search.PartCriteria',
  default: {
    Format: null,
    PartNumber: null
  }
};

const mapStateToProps = (state) => ({ formatTemplate: state.formatTemplate });
export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles, { withTheme: true })(SparepartSearch), selectData))
);
