import React, { createRef, useEffect, useRef, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { FormattedMessage } from 'react-intl';
import { updateWantToSeeIntro } from '@zert-packages/actions/commonReducer';
import { useDispatch } from 'react-redux';
import { JoyrideBeaconComponentStart, scrollIntoViewCenter } from '../Introduction';
import { useIntroduction, finishIntroduction } from '../IntroductionContext';
import TooltipLocale from '../TooltipLocale';
import checkIfStepTargetExist from '../../ElementSearcher/utils/checkIfStepTargetExist';

const formattedMessages = {
  'IntroActionPlanSideBar.Settings': (
    <FormattedMessage id="IntroActionPlanSideBar.Settings" defaultMessage="Click here to access your settings." />
  ),
  'IntroActionPlan.MainContainer': (
    <FormattedMessage
      id="IntroActionPlan.MainContainer"
      defaultMessage="In this area you can find your tasks and also browse though your company."
    />
  ),
  'IntroActionPlan.ElementTileTask': (
    <FormattedMessage
      id="IntroActionPlan.ElementTileTask"
      defaultMessage="With these actions you can see your current tasks, your planned tasks and use the filter to see other tasks."
    />
  ),
  'IntroActionPlan.AnyProgressOnThis': (
    <FormattedMessage
      id="IntroActionPlan.AnyProgressOnThis"
      defaultMessage="Here you can find your recent items that aren't finalized"
    />
  ),
  'IntroActionPlanSideBar.ToggleShowBtn': (
    <FormattedMessage id="IntroActionPlanSideBar.ToggleShowBtn" defaultMessage="Click here to open menu" />
  )
};
const id = 'actionPlan';

export default function ActionPlanIntro(isMobile, sideBarSettingsRef, wantToSeeInto) {
  const anyProgressRef = createRef();
  const [run, setRun] = useState(false);
  const { startIntro } = useIntroduction(id, setRun, wantToSeeInto);
  const [stepIndex, setStepIndex] = useState(0);
  const dispatch = useDispatch();
  const refSideBarMenu = useRef();
  let steps = [
    {
      target: '[data-intro-id="SideBar.Settings"]',
      content: formattedMessages['IntroActionPlanSideBar.Settings'],
      elementRef: 'sideBarSettingsRef'
    },
    {
      target: '[data-intro-id="ActionPlan.MainContainer"]',
      content: formattedMessages['IntroActionPlan.MainContainer']
    },
    {
      target: '[data-intro-id="ActionPlan.ElementTileTask"]',
      content: formattedMessages['IntroActionPlan.ElementTileTask']
    },
    {
      target: '[data-intro-id="ActionPlan.AnyProgressOnThis"]',
      content: formattedMessages['IntroActionPlan.AnyProgressOnThis'],
      placement: 'top',
      elementRef: 'anyProgressRef'
    }
  ];

  useEffect(() => {
    if (wantToSeeInto && !run) {
      setRun(true);
      setStepIndex(0);
    }
  }, [wantToSeeInto]);

  function toggleSideBarMenu(state, handleClose) {
    if (isMobile) {
      setTimeout(() => {
        setStepIndex(1);
      }, 200);
    }
    if (handleClose) {
      refSideBarMenu.current = handleClose;
    }
  }

  if (isMobile) {
    steps = [
      {
        target: '[data-intro-id="SideBar.ToggleShowBtn"]',
        content: formattedMessages['IntroActionPlanSideBar.ToggleShowBtn'],
        spotlightClicks: true,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        sidebar: true
      },
      ...steps
    ];
  }
  const handleJoyrideCallback = (data) => {
    const { step, status, type, index, action } = data;

    if (!checkIfStepTargetExist(step)) {
      setRun(false);
      dispatch(updateWantToSeeIntro(false));
      finishIntroduction(id);
      return;
    }

    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (step && step.elementRef) {
      if (step.elementRef === 'anyProgressRef') {
        scrollIntoViewCenter(anyProgressRef.current, 56);
      }
      if (step.elementRef === 'sideBarSettingsRef' && isMobile) {
        scrollIntoViewCenter(sideBarSettingsRef.current, 0);
      }
    }

    if (finishedStatuses.includes(status)) {
      setRun(false);
      dispatch(updateWantToSeeIntro(false));
      finishIntroduction(id);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const newStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setRun(false);
      if (isMobile && index === 1) {
        refSideBarMenu.current();
        setStepIndex(2);
      } else {
        setStepIndex(newStepIndex);
      }
      setTimeout(() => {
        setRun(true);
      }, 400);
    }
  };

  return {
    component: (
      <Joyride
        callback={handleJoyrideCallback}
        beaconComponent={JoyrideBeaconComponentStart}
        continuous
        locale={TooltipLocale}
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        showProgress
        showSkipButton
        styles={{
          overlay: {
            position: 'fixed'
          },
          options: {
            primaryColor: '#01A1C7'
          }
        }}
      />
    ),
    anyProgressRef,
    toggleSideBarMenu,
    startIntro
  };
}
