export const filterItems = (value, initialState) => {
  const adobeValue = value.trim()
    if(adobeValue.length === 0){
      return  initialState
    }
  return  initialState.filter(item => filter(item, adobeValue))
}


const filter = (item, filterValue) => {
  return  item.name && item.name.toLowerCase().includes(filterValue.toLowerCase())
          || item.id && item.id.toString().includes(filterValue)
          ||  item.versionId && item.versionId.toString().includes(filterValue)
}
