import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextFieldItem from "@zert-packages/components/TextFieldItem";
import { handleValue } from "../../utils/main";
import DropMenu from "@zert-packages/components/DropMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
    padding: 11,
    background: "#E2F2F6",
    minHeight: 400,
    display: 'flex',
    justifyContent: 'space-between',
  },
  wrapperTitle: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700,
    textTransform: "capitalize"
  },
  label: {
    fontWeight: 700,
  },
  wrapperContent: {
    marginTop: 20,
    display: 'grid',
    gridRowGap: 10,
  },
  wrapperTextField: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
  },
  wrapperButton: {
    display: "flex",
    marginTop: 30,
    justifyContent: "end",
    gap: 10
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));


const SetupQRDialog = () => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [qr, setQr] = useState({
    name: "",
    link: "",
    type: "Embed"
  });
  const dropItem= ['Embed', 'Latest publishing']

  const handleClose = () => {
    setOpen(false);
  };

  const handleQr = (val, name) => {
    handleValue(val, name, setQr);
  };


  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div>
      <div className={cl.wrapperTitle}>
        <span className={cl.title}>Setup QR/Embed content </span>
        <CloseIcon onClick={handleClose} />
      </div>
      <div className={cl.wrapperContent}>
        <div className={cl.wrapperTextField}>
          <div className={cl.label}>Name:</div>
          <TextFieldItem name={"name"} values={qr.name} setValues={handleQr} />
        </div>
        <div className={cl.wrapperTextField}>
          <div className={cl.label}>Link:</div>
          <TextFieldItem name={"link"} values={qr.link} setValues={handleQr} />
        </div>
        <div className={cl.wrapperTextField}>
          <div className={cl.label}>Type:</div>
          <DropMenu
            selected={qr.type}
            name={'type'}
            dropItem={dropItem}
            setDropItem={handleQr}
            style={{
              borderRadius: 5,
              height: 27.25
            }}
          />
        </div>
      </div>
    </div>

    <div className={cl.wrapperButton}>
      <Button className={cl.button} onClick={handleClose}>
        Ok
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>
  </Dialog>);
};

export default SetupQRDialog;