import React, { useContext, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { TabsContext } from "../../Stylesheet";
import { TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { handleObjectStylesheet, handleTabsValue } from "../../utils/helpers";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 700,
    fontSize: "1rem"
  },
  wrapperList: {
    display: "grid",
    gridTemplateColumns: "1fr 25px",
    gridColumnGap: 5,
    height: "Calc(100% - 95px)"
  },
  list: {
    border: "1px solid #BDBDBC",
    borderRadius: 5,
    overflow: "auto",
    height: "100%"
  },
  listItem: {
    display: "grid",
    gridTemplateColumns: "230px 1fr",
    alignItems: "center",
    padding: 5,
    gridColumnGap: 10
  },
  wrapperInput: {
    "& input": {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 10,
      background: "#ffffff",
      borderRadius: 5
    },
    "& textarea": {
      overflow: "hidden"
    },
    "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  input: {
    margin: 0,
    width: "100%",
    maxWidth: 200
  },
  wrapperEditor: {
    color: "#4db1d3",
    "& svg": {
      cursor: "pointer"
    }
  },
  selectedListItem: {
    background: "#4db1d3",
    color: "#ffffff"
  },
  notActiveListItem: {
    cursor: "pointer"
  }
}));

const Characters = () => {
  const cl = useStyles();
  const { activeLocales } = useSelector((state) => state);
  const { values, setValues } = useContext(TabsContext);
  const [characters, setCharacters] = useState({
    selected: null
  });

  const hyphenationCharacters = values?.stylesheet?.hyphenationCharacters;
  const locales = hyphenationCharacters && activeLocales.reduce((acu, cur) => {
    const findLng = hyphenationCharacters[cur.value];
    if (findLng !== undefined) {
      return [...acu, { name: findLng, ...cur }];
    }
    return acu;
  }, []);

  const findActiveLocales = () => {
    return activeLocales.reduce((acu, cur) => {
      const findLng = hyphenationCharacters[cur.value];
      if (findLng !== undefined) {
        return acu;
      }
      return [...acu, {...cur, name: cur.displayName}];
    }, []);
  };

  const activeCharactersLocales = findActiveLocales()



  const handleSelected = (el) => {
    setCharacters(pre => ({ ...pre, selected: el }));
  };

  const handeCharacters = (val) => {
    setValues(pre => ({
      ...pre, stylesheet: {
        ...pre.stylesheet,
        hyphenationCharacters: val
      }
    }));
  };

  const addLanguage = () => {
    const res = activeCharactersLocales;
    if (res.length === 0) {
      return;
    }
    handeCharacters({ [res[0].value]: "", ...hyphenationCharacters });
    handleSelected(null);
  };

  const deleteLanguage = () => {
    if (!characters.selected) {
      return;
    }
    const res = hyphenationCharacters;
    delete res[characters.selected.value];
    handeCharacters(res);
    handleSelected(null);
  };

  const handleLanguageValue = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const name = e.target.name;
    handleObjectStylesheet(val, name, "hyphenationCharacters", values, setValues);
  };

  const findLocales = () => {
    if (!activeLocales) {
      return [];
    }
    const res = activeCharactersLocales;
    if (res.length === 0) {
      return [];
    }
    return res;
  };

  const handleLanguage = (val, type) => {
    const res = hyphenationCharacters;
    delete res[type];
    res[val.value] = hyphenationCharacters.type || '';
    handeCharacters(res);
  };



  return (<>
    <div className={cl.label}> Characters:</div>
    <div className={cl.wrapperList}>
      <div className={cl.list}>
        <div className={`${cl.listItem}`} style={{
          borderBottom: "1px solid #BDBDBC"
        }}>
          <div>Language</div>
          <div>Character</div>
        </div>
        {locales && locales.length > 0 && locales.map((el, i) => <div
          key={i}
          onClick={() => handleSelected(el)}
          className={`${cl.listItem} ${
            characters?.selected?.value === el.value
              ? cl.selectedListItem : cl.notActiveListItem
          }`}
        >
          <div>
            <ExpanderItem
              valueProps={el.displayName}
              type={el.value}
              setProps={handleLanguage}
              propsLabel={findLocales()}
            />
            {/*{el.value}*/}
          </div>
          <div className={cl.wrapperInput}>
            <TextField
              value={el.name}
              name={el.value}
              variant="outlined"
              className={cl.input}
              onChange={handleLanguageValue}
            />
          </div>
        </div>)}
      </div>
      <div className={cl.wrapperEditor}>
        <AddIcon onClick={addLanguage} />
        <DeleteIcon onClick={deleteLanguage} style={{
          opacity: !characters.selected ? 0.35 : 1
        }} />
      </div>
    </div>

  </>);
};

export default Characters;