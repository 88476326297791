import {FormattedMessage} from 'react-intl';
import React from 'react';
import {ArrowUpward, CloudDownload, Delete, FolderOpen, HideImage, Image} from '@mui/icons-material';
import UndoIcon from "@mui/icons-material/Undo";
import SettingIcon from "@zert-packages/icons/zert/SettingIcon";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RedoIcon from "@mui/icons-material/Redo";
import LockIcon from '@mui/icons-material/Lock';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import DownloadIcon from '@mui/icons-material/Download';

export const TASK_ACTIONS_TYPES = {
  download: "downloadImage",
  newTranslation: "newTranslation",
  undo: "undo",
  redo: "redo",
  newImage: 'newImage',
  newPart: 'newPart',
  insertElement: 'insertElement',
  openImage: 'openImage',
  removeImage: 'removeImage',
  openPart: 'openPart',
  removePart: 'removePart',
  moveUp: 'moveUp',
  moveDown: 'moveDown',
  checkoutPart: 'checkoutPart',
  lockReference: 'lockReference',
  removeWorkingCopy: 'removeWorkingCopy',

  inform: 'inform',
  delegate: 'delegate',
  delete: 'delete',
  rename: 'rename',
  archive: 'archive',
  unarchive: 'unarchive',
  duplicate: 'duplicate',
  copy: 'copy',
  cut: 'cut',
  paste: 'paste',
  tableView: 'tableView',
  listView: 'listView',
  preview: 'preview',
  publish: 'publish',
  report: 'report',
  edit: 'edit',
  open: 'open',
  newHotspot: 'newHotspot',
  DOPFromIFS: 'DOPFromIFS'
};

export const DOWNLOAD_ACTION = {
  icon: <DownloadIcon />,
  id: TASK_ACTIONS_TYPES.download,
  name: <FormattedMessage id="AttachmentsDetectedNodeManager.ToolTip.Download" defaultMessage="Download"/>
};

export const NEW_TRANSLATION_ACTION = {
  icon: <CloudDownload />,
  id: TASK_ACTIONS_TYPES.newTranslation,
  name: <FormattedMessage id="CLM.AddLanguageDialog.AddTranslation" defaultMessage="Add new translation"/>
};

export const UNDO_ACTION = {
  icon: <UndoIcon />,
  id: TASK_ACTIONS_TYPES.undo,
  name: <FormattedMessage id="ElementTile.Actions.Undo" defaultMessage="Undo" />
};

export const REDO_ACTION = {
  icon: <RedoIcon />,
  id: TASK_ACTIONS_TYPES.redo,
  name: <FormattedMessage id="ElementTile.Actions.Redo" defaultMessage="Redo" />
};

export const NEW_IMAGE_ACTION = {
  icon: <Image />,
  id: TASK_ACTIONS_TYPES.newImage,
  name: <FormattedMessage id="ElementTile.Actions.New image" defaultMessage="New image" />
};

export const NEW_PART_ACTION = {
  icon: <SettingIcon/>,
  id: TASK_ACTIONS_TYPES.newPart,
  name: <FormattedMessage id="ElementTile.Actions.New part" defaultMessage="New part" />
};

export const INSERT_ELEMENT_ACTION = {
  icon: <CloudDownload />,
  id: TASK_ACTIONS_TYPES.insertElement,
  name: <FormattedMessage id="ElementTile.Actions.Insert element" defaultMessage="Insert element" />
}

export const OPEN_IMAGE = {
  icon: <FolderOpen />,
  id: TASK_ACTIONS_TYPES.openImage,
  name: <FormattedMessage id="ElementTile.Actions.Open image" defaultMessage="Open image" />
}

export const REMOVE_IMAGE_ACTIONS = {
  icon: <HideImage />,
  id: TASK_ACTIONS_TYPES.removeImage,
  name: <FormattedMessage id="ElementTile.Actions.Remove image" defaultMessage="Remove image" />
}

export const OPEN_PART = {
  icon: <FolderOpen />,
  id: TASK_ACTIONS_TYPES.openPart,
  name: <FormattedMessage id="ElementTile.Actions.Open part" defaultMessage="Open part" />
}

export const REMOVE_PART_ACTIONS = {
  icon: <HideImage />,
  id: TASK_ACTIONS_TYPES.removePart,
  name: <FormattedMessage id="ElementTile.Actions.Remove part" defaultMessage="Remove part" />
}
export const MOVE_UP_ACTION = {
  icon: <ArrowUpward/>,
  id: TASK_ACTIONS_TYPES.openPart,
  name: <FormattedMessage id="ElementTile.Actions.MoveUp" defaultMessage="Move up" />
}

export const MOVE_DOWN_ACTION = {
  icon: <ArrowDownwardIcon/>,
  id: TASK_ACTIONS_TYPES.removePart,
  name: <FormattedMessage id="ElementTile.Actions.MoveDown" defaultMessage="Move down" />
}

export const CHECKOUT_PART_ACTION = {
  icon: <FactCheckIcon/>,
  id: TASK_ACTIONS_TYPES.checkoutPart,
  name: <FormattedMessage id="ElementTile.Actions.CheckoutPart" defaultMessage="Checkout part" />
}

export const LOCK_REFERENCE_ACTION = {
  icon: <LockIcon/>,
  id: TASK_ACTIONS_TYPES.lockReference,
  name: <FormattedMessage id="ElementTile.Actions.Lock reference" defaultMessage="Lock reference" />
}

export const REMOVE_WORKING_COPY_ACTION = {
  icon: <Delete />,
  id: TASK_ACTIONS_TYPES.removeWorkingCopy,
  name: <FormattedMessage id="ElementTile.Actions.Remove working copy" defaultMessage="Remove working copy" />
}
