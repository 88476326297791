import React, { useContext } from "react";
import clsx from "clsx";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import CircleIcon from "@mui/icons-material/Circle";
import styleAccordionWrapper from "./components/style/styleAccordionWrapper";

function AddBlockItemIcons({
                             block,
                             setBlock,
                             blockClick,
                             style,
                             index,
                             el,
                             type = "expandedToolbar",
                             addSaveBlock,
                             handleBlock

                           }) {
  const cl = styleAccordionWrapper({ blockClick });
  const context = useContext(PanelContext);


  const handleExpanded = async (e) => {
    e.stopPropagation();

    if (context?.values.blockClickCreateItemsBlock && !block[type]) {
      return;
    }
    if (addSaveBlock) {
      await addSaveBlock();
    }
    if (context) {
      context.setValues((pre) => ({
        ...pre,
        blockClickCreateItemsBlock: false,
        action: {
          ...pre.action,
          indexElementCreate: index
        }
      }));
    }

    setBlock((pre) => ({
      ...pre,
      [type]: !pre[type],
      createType: null
    }));
  };

  return (
    <div
      style={{
        position: "relative"
      }}
    >
      <div
        className={clsx({
          [cl.closeImgButton]: block.expandedToolbar,
          [cl.addImgButton]: block.isHoveredAdd,
          [cl.addImgButtonPlaceholder]: !block.expandedToolbar && !block.isHoveredAdd
        })}
        onMouseEnter={() => (!block.expandedToolbar ? handleBlock(true, "isHoveredAdd") : null)}
        onMouseLeave={() => (!block.expandedToolbar ? handleBlock(false, "isHoveredAdd") : null)}
      >
        {block[type] ? (
          <div style={style}>
            <CloseIcon onClick={(e) => handleExpanded(e)} className={cl.removeIcon} />
          </div>
        ) : block.isHoveredAdd ? (
          <div
            style={style}
            onClick={(e) => {
              handleExpanded(e);
              handleBlock(false, "isHoveredAdd");
            }}
          >
            <AddIcon />
          </div>
        ) : (
          <div style={style}>
            <CircleIcon style={{ fontSize: "6px" }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AddBlockItemIcons;
