import { createAction, createReducer } from '@reduxjs/toolkit';

export const loadPED = createAction('loadPED');
export const resetPED = createAction('resetPED');

export const initialState = {
  ped: null
};

export const PedReducers = createReducer(initialState, {
  [loadPED]: (state, action) => {
    state.ped = action.payload;
  },
  [resetPED]: (state, action) => {
    state.ped = null;
  }
});
