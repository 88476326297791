import React, {useEffect,  useState} from 'react';
import './Resizable.css';

const Resizable = ({leftPart, rightPart}) => {
  const [leftWidth, setLeftWidth] = useState(400);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    setLeftWidth(prev => {
        if (prev + e.movementX < 400) {
           return 400
        }
        if (prev + e.movementX > 1000) {
            return 1000
        }

        return prev + e.movementX
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
    }

  }, [isDragging]);

  return (
    <div style={{display: 'flex', width: '100%'}}>
      <div className="left" style={{ width: `${leftWidth}px` }}>{leftPart}</div>
      <div className="resizer" onMouseDown={handleMouseDown} style={{backgroundColor: isDragging ? "grey" : "#e9e9e9"}}></div>
      <div className="right" style={{ width: `${1300 - leftWidth}px` }}>
          {rightPart}
      </div>
    </div>
  );
};

export default Resizable;
