import React, {useEffect, useState} from 'react';
import {Menu, MenuItem} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
    wrapperInput: {
        display: 'grid',
        gridTemplateColumns: '100px 1fr',
        marginBottom: 10,
        gridColumnGap: 10,
        alignItems: 'center'
    },
    label: {
        fontWeight: 900
    },
    dropMenuButton: {
        minWidth: '100%',
        width: '100%',
        justifyContent: 'space-between',
        border: '1px solid #C6C6C5',
        fontSize: '1rem',
        borderRadius: 4,
        color: 'rgb(33, 37, 41)',
        fontWeight: '400',
        paddingBottom: 0.25,
        paddingTop: 0.25,
        background: '#ffffff',
        paddingRight: 15,
        outline: 'none !important',
        textTransform: 'lowercase',
        '&:hover': {
            background: '#ffffff'
        },
        '& :focus': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    },
    menuLinkSiteWrapper: {
        borderRadius: 0,
        // width: 470,
        width: 'fit-content',
        // minWidth: 315,
        maxWidth: 'Calc(100% - 40px)',
        border: '1px solid #DCE3E5',
        boxShadow: 'none',
        maxHeight: '400px',
    },
    menuLinkSiteWrapperSmall: {
        borderRadius: 0,
        // width: 470,
        width: 'fit-content',
        // minWidth: 315,
        // maxWidth: 'Calc(100% - 40px)',
        border: '1px solid #DCE3E5',
        boxShadow: 'none',
        maxHeight: '400px',
        maxWidth: 'fit-content',
        // '@media (max-width: 900px)': {
        //   width: 'Calc(100% - 151px)'
        // },
        '& li': {
            padding: '6px 10px'
        }
    }
}));

function ExpanderItem({
                          valueProps = '',
                          propsLabel,
                          setProps,
                          small,
                          type = '',
                          disabled,
                          height = '',
                          styleDropMenu,
                          style = {},
                      }) {
    const cl = useStyles();
    const [open, setOpen] = useState(null);
    const [width, setWidth] = useState(null);
    const [selected, setSelected] = useState('');

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
        setWidth(event.currentTarget.offsetWidth);
    };

    const handleSelectedItem = (val, index) => {
        if (setProps) {
            setProps(val, type, index);
        }
        if (val.name) {
            setSelected(val.name, index);
        }
        setOpen(null);
    };


    useEffect(() => {
        setSelected(valueProps);
    }, [valueProps]);


    return (
        <>
            <Button
                style={{height, ...style}}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleOpen}
                className={cl.dropMenuButton}
                disabled={disabled}
            >
                <span>{selected}</span>
                <ArrowDropDownIcon
                    style={{
                        color: open ? '#224952' : '#8B8B8B',
                        transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
                    }}
                />
            </Button>
            <Menu
                anchorEl={open}
                open={Boolean(open)}
                onClose={() => setOpen(null)}
                style={styleDropMenu}

                classes={{
                    paper: small ? cl.menuLinkSiteWrapperSmall : cl.menuLinkSiteWrapper
                }}
            >
                {propsLabel && propsLabel.map((el, i) => (
                    <MenuItem
                        key={i}
                        onClick={() => handleSelectedItem(el, i)}
                        selected={selected === (el.name || el)}
                        style={{
                            width: width || '100%'
                        }}
                    >
                        {el.name ? el.name : el}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default ExpanderItem;
