import React, { useContext, useEffect, useState } from "react";
import { TabsContext } from "../../../Stylesheet";
import useStyles from "./useStyles";
import ColorSelected from "./ColorSelected";
import BorderStyle from "./BorderStyle";
import { adobeColor, } from "../../../utils/helpers";


function Decorations({ table, type = "headerRow", disabled, setTable, typeParents = "tableSettings" }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [selectedLine, setSelectedLine] = useState(0);
  const [selectedBorder, setSelectedBorder] = useState({
    color: "",
    edge: "center",
    style: "NONE",
    width: { value: 1, unit: "POINTS" }
  });


  const handleSelectedLine = (i) => {
    setSelectedLine(i);
  };

  const findBorder = () => {
    if (selectedLine === 1) return "borderTop";
    if (selectedLine === 2) return "borderRight";
    if (selectedLine === 3) return "borderBottom";
    if (selectedLine === 4) return "borderLeft";
    if (selectedLine === 5) return "borderCenter";
    if (selectedLine === 7) return "borderMiddle";
  };

  const findStyle = (type, index, typeBorder) => {
    return {
      [`border${typeBorder}Color`]: adobeColor(table[type].color),
      [`border${typeBorder}Width`]:  table[type].width.value / 1.5 ,
      [`border${typeBorder}Style`]: table[type].style.toLowerCase(),
      zIndex: index
    };
  };


  useEffect(() => {
    if (selectedLine > 0) {
      if (table) {
        const getBorder = table[findBorder()];
        setSelectedBorder({ ...getBorder, color: adobeColor(getBorder.color) });
      }
    }
  }, [selectedLine]);
  //  }, [selectedLine, table]);


  return (
    <div>
      <p className={cl.title}>Decorations</p>
      <div className={cl.borderBlock}>
        <ColorSelected
          table={table}
          setTable={setTable}
          type={type}
          values={values}
          setValues={setValues}
          typeParents={typeParents}
          disabled={disabled}
        />
        <div className={cl.wrapperLoyaltyBlock}>
          <p className={cl.title}>Border:</p>
          <div className={cl.loyaltyBlock}>
            <div className={cl.borderYLeft} />
            <div className={cl.borderYRight} />
            <div className={cl.borderXLeft} />
            <div className={cl.borderXRight} />
            <div
              className={cl.line}
              style={findStyle('borderTop', selectedLine === 1 ? 10 : "",'Bottom')}
            />
            <div
              style={findStyle('borderLeft', selectedLine === 4 ? 10 : "",'Left')}
              className={cl.lineY}
            />
            <div
              style={findStyle('borderRight', selectedLine === 2 ? 10 : "",'Left')}
              className={cl.lineYRight}
            >
              {" "}
            </div>
            <div
              className={cl.lineBottom}
              style={findStyle('borderBottom', selectedLine === 3 ? 10 : "",'Bottom')}
            />
            <div
              className={cl.activeLine}
              style={{ border: selectedLine === 1 ? "2px solid #BFBFBF" : "" }}
              onClick={() => handleSelectedLine(1)}
            />
            <div
              className={cl.activeYLineRight}
              onClick={() => handleSelectedLine(2)}
              style={{ border: selectedLine === 2 ? "2px solid #BFBFBF" : "" }}
            />
            <div
              className={cl.activeLineBottom}
              onClick={() => handleSelectedLine(3)}
              style={{ border: selectedLine === 3 ? "2px solid #BFBFBF" : "" }}
            />
            <div
              className={cl.activeYLine}
              onClick={() => handleSelectedLine(4)}
              style={{ border: selectedLine === 4 ? "2px solid #BFBFBF" : "" }}
            />

            {!setTable &&
              <>
                <div
                  className={cl.activeLineCenterY}
                  onClick={() => handleSelectedLine(5)}
                  style={{ border: selectedLine === 5 ? "2px solid #BFBFBF" : "" }}
                />

                <div
                  className={cl.activeLineCenterX}
                  onClick={() => handleSelectedLine(7)}
                  style={{ border: selectedLine === 7 ? "2px solid #BFBFBF" : "" }}
                />
              </>
            }

            <div className={cl.loyaltyBlockCenter}>
              {!setTable &&
                <>
                  <div
                    style={findStyle('borderMiddle', selectedLine === 7 ? 10 : "",'Bottom')}
                    className={cl.loyaltyBlockCenterX}
                  />
                  <div
                    style={{
                      ...findStyle("borderCenter", selectedLine === 5 ? 10 : "","Left"),
                      marginLeft: -table.borderCenter.width.value / 4,
                  }}
                    className={cl.loyaltyBlockCenterY}
                  />
                </>
              }
            </div>
            <div className={cl.borderBottomYLeft} />
            <div className={cl.borderBottomYRight} />
            <div className={cl.borderBottomXLeft} />
            <div className={cl.borderBottomXRight} />
          </div>
          <BorderStyle
            selectedBorder={selectedBorder}
            selectedLine={selectedLine}
            findBorder={findBorder}
            setTable={setTable}
            typeParents={typeParents}
            type={type}
            table={table}
            values={values}
            setValues={setValues}
            disabled={disabled}
            setSelectedBorder={setSelectedBorder}
          />
        </div>
      </div>
    </div>
  );
}

export default Decorations;
