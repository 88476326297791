import { createAction, createReducer } from '@reduxjs/toolkit';

export const getTemplates = createAction('Deviation: Load templates List');
export const RA_WorkFlowTemplate = createAction('Deviation: Load WorkFlow Template');
export const RA_MeasureTemplate = createAction('Deviation: RA Measure Template');
export const RA_Template = createAction('Deviation: Load RA TEMPLATE');
export const DEVIATION_TEMPLATE_WORKFLOW = createAction('Deviation: Load DEVIATION TEMPLATE WORKFLOW');
export const DEVIATION_TEMPLATE = createAction('Deviation: DEVIATION TEMPLATE');

export const initialState = {
  templates: null,
  measureWFTTemplate: null,
  rrTemplate: null,
  measureTemplate: null,
  wftTemplate: null,
  deviationTemplate: null
};

export const DeviationReducers = createReducer(initialState, {
  [getTemplates]: (state, action) => {
    state.templates = action.payload;
  },
  [RA_MeasureTemplate]: (state, action) => {
    state.measureTemplate = action.payload;
  },
  [RA_Template]: (state, action) => {
    state.rrTemplate = action.payload;
  },
  [RA_WorkFlowTemplate]: (state, action) => {
    state.measureWFTTemplate = action.payload;
  },
  [DEVIATION_TEMPLATE]: (state, action) => {
    state.deviationTemplate = action.payload;
  },
  [DEVIATION_TEMPLATE_WORKFLOW]: (state, action) => {
    state.wftTemplate = action.payload;
  }
  /*  measureTemplate :  (state, action) => {
    state.templates = action.payload;
},
    wftTemplate :  (state, action) => {
    state.templates = action.payload;
},
    deviationTemplate :  (state, action) => {
    state.templates = action.payload;
}, */
});
