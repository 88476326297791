import React from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  templateInput: {
    marginTop: 0,
    marginBottom: 0,
    background: "#ffffff",
    "& .MuiInputBase-input": {
      paddingTop: 4,
      paddingBottom: 4
    },
    width: "100%",
    "& .MuiInputBase-multiline.MuiInputBase-marginDense": {
      height: "100%"
    },
    "& .MuiOutlinedInput-inputMultiline": {
      height: "100% !important"
    }
  }
}));

const FieldDialog = ({ name, val, setValue, transparency }) => {
  const cl = useStyles();


  const adobeValue  = (val) => {
    if(transparency){
      if(val < 0){
        return 0
      }
      if(val > 1){
        return  1
      }
    }
    return  val
  }

  const handleValue = (e) => {
    const val = adobeValue(e.target.value)
    setValue(pre => ({ ...pre, [name]: +val }));
  };


  return (
    <TextField
      value={val}
      type={"number"}
      className={cl.templateInput}
      variant="outlined"
      inputProps={{
        step: transparency ? "0.1" : 1
      }}
      onChange={handleValue} />
  );
};

export default FieldDialog