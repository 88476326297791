import { showSnackbarError } from '@zert-packages/components/Snackbars';
import React from 'react';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { createInProgressSnack } from '@zert-packages/components/common/snackBar';
import { FormattedMessage } from 'react-intl';

import { v4 as uuidv4 } from 'uuid';
import { checkReportStatus } from '@zert-packages/actions/api';
import {
  addPublishingCommentAsync,
  createPublication,
  exportAloHTML,
  exportKomatsuAPI,
  loadMetadata,
  loadPublishingList,
  removePublishing,
  removePublishingCommentAsync
} from './API';
import { getMetaData, getPublishingList, setPublishingLoading } from './publishingReducers';

import {
  // checkPreviewStatus,
  downloadLayoutSettings,
  preview,
  setExistingPropertyFromSetting
} from '../PreviewPlugin/servicesMiddlewares';
import PublishingWizardContextWrapper from './PublishWizardDialog/PublishingWizardDialog';
import EditPublishing from './EditPublishing';
import {
  setFallbackLocale,
  setLanguageSeparated,
  setMultipleLanguage,
  setPreviewPublishingFilters,
  setSelectedLayout,
  setSelectedLayoutFilter,
  setSelectedPreviewJOBElement
} from '../PreviewPlugin/previewReducers';
import AddCommentDialog from './AddCommentDialog';

import AssertPublishingWizardContextWrapper from './AssertPublishingWizard/AssertPublishingWizard';
import { AloHTMLPublishingDialog } from './AloHTMLPublishingDialog';

const timer = null;

export const fetchMetaData = (versionId) => async (dispatch) => {
  const metaData = await loadMetadata(versionId);
  dispatch(getMetaData({ metaData, versionId }));
};

export const addPublishingComment =
  (publishing, unMountParent) =>
  (dispatch, getState, { snackbar }) => {
    renderInExceptionRoot(AddCommentDialog, {
      onConfirm: async (comment) => {
        if (unMountParent) {
          unMountParent();
        }
        await addPublishingCommentAsync(publishing.publishingId, comment);

        dispatch(editPublishing(publishing, publishing.publishingId));
      }
    });
  };

export const removePublishingComment =
  (publishing, comment, unMountParent) =>
  async (dispatch, getState, { snackbar }) => {
    await removePublishingCommentAsync(publishing.publishingId, comment);
    if (unMountParent) {
      unMountParent();
    }
    dispatch(editPublishing(publishing, publishing.publishingId));
  };

export const editPublishing =
  (selected, selectedPublishingId) =>
  (dispatch, getState, { snackbar }) => {
    renderInExceptionRoot(EditPublishing, {
      publishing: selected,
      selectedPublishingId,
      createNew: (publishing, unMountParent) => {
        dispatch(setPublishingToEdit(publishing));
        dispatch(
          createPublishing(
            publishing.elementInfo,
            -1,
            {
              allowComments: publishing.allowComments,
              notifyMeOnExpire: publishing.notifyMeOnExpire,
              notifyMeOneNewComment: publishing.notifyMeOneNewComment,
              validUntil: publishing.validUntil,
              purpose: publishing.purpose
            },
            unMountParent
          )
        );
      },
      addComment: (publishing, unMountParent) => {
        // unMountParent();
        dispatch(addPublishingComment(publishing, unMountParent));
      },
      deletePublishing: async (publishing, publishings, unMountParent) => {
        try {
          const result = await removePublishing(publishing.publishingId);
          if (result !== 'true') {
            showSnackbarError(snackbar, result);
          } else {
            unMountParent();
            if (publishings.length > 1) {
              dispatch(editPublishing(selected));
            } else {
              dispatch(fetchPublishingList());
            }
          }
        } catch (e) {
          showSnackbarError(snackbar, e.message);
        }
      },
      onConfirm: (publishing, unMountParent) => {
        dispatch(setPublishingToEdit(publishing));
        dispatch(
          createPublishing(
            publishing.elementInfo,
            publishing.publishingId,
            {
              allowComments: publishing.allowComments,
              notifyMeOnExpire: publishing.notifyMeOnExpire,
              notifyMeOneNewComment: publishing.notifyMeOneNewComment,
              validUntil: publishing.validUntil,
              purpose: publishing.purpose,
              comments: publishing.comments
            },
            unMountParent
          )
        );
      }
    });
  };

export const setPublishingToEdit =
  (publishing) =>
  (dispatch, getState, { snackbar }) => {
    dispatch(setMultipleLanguage(publishing.settings.languages));
    dispatch(setSelectedLayout(publishing.settings.layoutVersionId));
    dispatch(setLanguageSeparated(publishing.settings.separatedLanguages));
    dispatch(downloadLayoutSettings(publishing.settings.layoutVersionId));
    dispatch(
      setExistingPropertyFromSetting(publishing.settings.layoutVersionId, publishing.settings.layoutSettings.settings)
    );
    // dispatch(setLayoutSettings(publishing.settings.layoutSettings.settings));
    dispatch(
      setSelectedLayoutFilter(
        !publishing.settings.layoutFilterUUID || publishing.settings.layoutFilterUUID == null
          ? '-1'
          : publishing.settings.layoutFilterUUID
      )
    );
    dispatch(setFallbackLocale(publishing.settings.fallbackLanguage));

    dispatch(setPreviewPublishingFilters(publishing.settings.clientFilters));
    // dispatch(setLayoutProperties())

    /* multipleSelectedLanguage = {getLatestPublishingSettins().languages}
    fallbackLocale = {getLatestPublishingSettins().fallbackLanguage}
    selectedLayoutFilter = {getLatestPublishingSettins().layoutFilterUUID}
    selectedLayout = {getLatestPublishingSettins().layoutVersionId}
    selectedElementToPreview = {getLatestPublishing().elementInfo}
    languageSeparated = {getLatestPublishingSettins().separatedLanguages} */

    dispatch(setSelectedPreviewJOBElement(publishing.elementInfo));
  };

export const exportHTMLALO =
  (selectElement) =>
  (dispatch, getState, { snackbar }) => {
    renderInExceptionRoot(AloHTMLPublishingDialog, {
      selectElement,
      onConfirm: (compareWithVersionId, multipleSelectedLanguage, fallbackLocale, previewPublishingFilters) => {
        const generatingSnackbar = createInProgressSnack(
          snackbar,
          <FormattedMessage id="Export.HTML.ALO" defaultMessage="Export HTML" />
        );
        const report = {
          name: <FormattedMessage id="Export.HTML.ALO" defaultMessage="Export HTML" />,
          reportUUID: uuidv4()
        };
        exportAloHTML(compareWithVersionId, multipleSelectedLanguage, fallbackLocale, previewPublishingFilters)
          .then((callback) => {
            checkReportStatus(callback, snackbar, generatingSnackbar, report, 'pdf');
          })
          .catch((error) => {
            showSnackbarError(snackbar, error);
          });
      }
    });
  };

export const exportKomatsuAssert =
  (selectElement) =>
  (dispatch, getState, { snackbar }) => {
    renderInExceptionRoot(AssertPublishingWizardContextWrapper, {
      selectElement,
      onConfirm: (
        { from, to, splitLevel, elementId, documentId, name, versionId, serialNumber, productId },
        { allowComments, notifyMeOnExpire, notifyMeOneNewComment, validUntil, purpose }
      ) => {
        const generatingSnackbar = createInProgressSnack(
          snackbar,
          <FormattedMessage id="Export.HTML.ALO" defaultMessage="Export HTML" />
        );

        const {
          selectedElementToPreview,
          selectedLayout,
          previewPublishingFilters,
          layoutProperties,
          multipleSelectedLanguage,
          languageSeparated,
          selectedLayoutFilter,
          fallbackLocale,
          layoutSettings,
          selectedLanguage
        } = getState().PREVIEW;

        const reportSettings = {
          versionId: versionId,
          compareWithVersionId: -1,
          layoutVersionId: selectedLayout,
          layoutSettings: {
            template: layoutSettings[selectedLayout],
            settings: layoutProperties[selectedLayout]
          },

          separatedLanguages: languageSeparated,
          fallbackLanguage: fallbackLocale,
          languages: multipleSelectedLanguage,
          layoutFilterUUID: null,
          publishingId: selectElement ? selectElement.publishingId : -1,
          clientFilters: previewPublishingFilters,
          metadataCommentSettings: []
        };

        const report = {
          name: <FormattedMessage id="Export.Assert.Komatsu" defaultMessage="Export Assert" />,
          reportUUID: uuidv4()
        };

        const values = serialNumber.reduce((accumulator, currentValue, index) => {
          const key = `zert:Metadata[Machine type][${index}]`;
          return { [key]: `[0][${currentValue}]`, ...accumulator };
        }, {});

        const productIds = productId.reduce((accumulator, currentValue, index) => {
          const key = `zert:Metadata[ChassisNumber][${index}]`;
          return { [key]: `[0][${currentValue}]`, ...accumulator };
        }, {});

        exportKomatsuAPI(
          {
            settings: reportSettings,
            publishingId: selectElement ? selectElement.publishingId : -1,
            versionId,
            allowComments,
            notifyMeOnExpire,
            notifyMeOneNewComment,
            validUntil,
            comments: [],
            properties: {
              'zert:StartFrom': from,
              'zert:EndFrom': to,
              'zert:Metadata[IBNumber][0]': `[0][${documentId}]`,
              'zert:Metadata[Book type][0]': `[0][${name}]`,
              ...values,
              ...productIds,
              'zert:SplitLevel': splitLevel
            },
            purpose
          },
          report.reportUUID
        )
          .then((callback) => {
            checkReportStatus(callback, snackbar, generatingSnackbar, report, 'pdf');
          })
          .catch((error) => {
            showSnackbarError(snackbar, error);
          });
      }
    });
  };

export const createPublishing =
  (element, publishingId, context, unMountParent) =>
  (dispatch, getState, { snackbar }) => {
    renderInExceptionRoot(PublishingWizardContextWrapper, {
      element: element,
      context,
      publishingId,
      unMountParent,
      onConfirm: async (allowComments, notifyMeOnExpire, notifyMeOneNewComment, validUntil, purpose) => {
        const {
          selectedElementToPreview,
          selectedLayout,
          previewPublishingFilters,
          layoutProperties,
          multipleSelectedLanguage,
          languageSeparated,
          selectedLayoutFilter,
          fallbackLocale,
          layoutSettings,
          selectedLanguage
        } = getState().PREVIEW;

        const reportSettings = {
          versionId: selectedElementToPreview.versionId,
          compareWithVersionId: -1,
          layoutVersionId: selectedLayout,
          layoutSettings: {
            template: layoutSettings[selectedLayout],
            settings: layoutProperties[selectedLayout]
          },
          separatedLanguages: languageSeparated,
          fallbackLanguage: fallbackLocale,
          languages: multipleSelectedLanguage,
          layoutFilterUUID: selectedLayoutFilter,
          publishingId: publishingId,
          clientFilters: previewPublishingFilters,
          metadataCommentSettings: []
        };
        try {
          const publishing = await createPublication(
            publishingId,
            selectedElementToPreview.versionId,
            allowComments,
            notifyMeOnExpire,
            notifyMeOneNewComment,
            validUntil,
            purpose,
            reportSettings,
            context ? context.comments : []
          );
          if (!publishing.publishingId) {
            showSnackbarError(snackbar, publishing);
          } else {
            dispatch(fetchPublishingList(-1));
            dispatch(preview(element, false, true, publishing.publishingId));
          }
        } catch (e) {
          showSnackbarError(snackbar, e.message);
        }
      }
    });
  };

export const fetchPublishingList =
  (selectedAudience) =>
  async (dispatch, getState, { snackbar }) => {
    dispatch(setPublishingLoading(true));
    const previewList = await loadPublishingList(selectedAudience);
    dispatch(getPublishingList(previewList));
    dispatch(setPublishingLoading(false));
  };
