import React, { useContext, useState } from 'react';

import CounterEditPanel from './CounterEditPanel';

import DeleteConfirmDialog from '@zert-packages/components/shared/DeleteConfirmDialog';
import dialogCustom from '@zert-packages/components/common/dialogCustom';
import ActionableTableOld from '@zert-packages/components/shared/ActionableTable/ActionableTable.old';

import moveItemInArray from '@zert-packages/utils/moveItemInArray';
import addDefaultLanguages from '@zert-packages/utils/addDefaultLanguages';

import './TreeManagerCounters.sass';
import getTranslation from '@zert-packages/utils/getTranslation.old';
import { FormattedMessage } from 'react-intl';
import { getDefaultCounter } from '../assets/CONSTS';
import canChangePositionOfCounter from '../helpers/canChangePositionOfCounter';
import { CatalogContext } from '../common/CatalogProvider';

const columns = [
  {
    name: <FormattedMessage id="structure.countersname" defaultMessage="Counter" />,
    identifier: 'counter'
  }
];

function CountersList() {
  const { globalTree, treeForRendering, handleRenderTree, isEverythingDisabled } = useContext(CatalogContext);

  const { counters } = treeForRendering;

  const [selectedCounterIndex, setSelectedCounterIndex] = useState(null);

  let foundCounter = null;
  if (selectedCounterIndex !== null) {
    foundCounter = counters[selectedCounterIndex];
    addDefaultLanguages(foundCounter.label);
  }

  const handleDeleteCounter = () => {
    if (selectedCounterIndex === null) return;
    dialogCustom(DeleteConfirmDialog, {
      onDelete: () => {
        globalTree.counters.splice(selectedCounterIndex, 1);
        setSelectedCounterIndex(null);
        handleRenderTree();
      }
    });
  };

  const moveCounter = (position) => {
    if (!canChangePositionOfCounter(counters, selectedCounterIndex, position)) return;
    globalTree.counters = moveItemInArray(counters, 'index', selectedCounterIndex, position);
    setSelectedCounterIndex((prev) => prev + position);
    handleRenderTree();
  };

  const handleAddCounter = () => {
    globalTree.counters.push(getDefaultCounter());
    handleRenderTree();
  };

  return (
    <div className="root_counters">
      <ActionableTableOld
        disabled={isEverythingDisabled}
        columns={columns}
        rows={createRowsOfCounters(counters)}
        handleSelectRow={(index) => setSelectedCounterIndex(index)}
        selectedRowId={selectedCounterIndex}
        onAdd={handleAddCounter}
        onDelete={handleDeleteCounter}
        onUp={() => moveCounter(-1)}
        onDown={() => moveCounter(1)}
        controlButtons={{
          edit: false
        }}
      />
      {foundCounter !== null && (
        <CounterEditPanel foundCounter={foundCounter} selectedCounterIndex={selectedCounterIndex} />
      )}
    </div>
  );
}

export default CountersList;

// helpers

function createRowsOfCounters(counters) {
  const rows = [];
  counters.forEach((counter, index) => {
    const row = {
      id: index,
      counter: getTranslation(counter.label)
    };
    rows.push(row);
  });

  return rows;
}
