import React, { useContext } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { TabsContext } from '../../Stylesheet';
import { adobeWord, handleParagraphValue } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 10
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '159px 1fr',
    gridColumnGap: 10,
    '& label': {
      marginBottom: 0
    }
  },
  title: {
    fontWeight: 700
  },
  notesInput: {
    margin: 0,
    width: '100%',
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15,
      background: '#ffffff'
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    }
  }
}));

function TextFlow({ paragraph, templatesIdx }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const breakLabel = ['Auto', 'Column', 'Page', 'Even page', 'Odd page'];

  const handleCheckbox = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    handleParagraphValue(val, name, 'paragraphSettings', templatesIdx, values, setValues);
  };
  const handleTextField = (e) => {
    const val = e.target.value;
    const { name } = e.target;
    handleParagraphValue(val, name, 'paragraphSettings', templatesIdx, values, setValues);
  };

  const setSelectedDropMenu = (val, name) => {
    handleParagraphValue(val.toUpperCase(), name, 'paragraphSettings', templatesIdx, values, setValues);
  };

  return (
    <div className={cl.root}>
      <div className={cl.wrapperRow}>
        <span className={cl.title}>Orphans:</span>
        <TextField
          value={paragraph.orphans}
          type="orphans"
          className={cl.notesInput}
          variant="outlined"
          onChange={handleTextField}
          name="widows"
        />
      </div>

      <div className={cl.wrapperRow}>
        <span className={cl.title}>Widows:</span>
        <div>
          <TextField
            value={paragraph.widows}
            type="number"
            className={cl.notesInput}
            variant="outlined"
            onChange={handleTextField}
            name="widows"
          />
          <div>
            <FormControlLabel
              control={<Checkbox onChange={handleCheckbox} checked={paragraph.keepTogether} name="keepTogether" />}
              label={<span>Do not split</span>}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox onChange={handleCheckbox} checked={paragraph.keepWithPrevious} name="keepWithPrevious" />
              }
              label={<span>Keep with previous</span>}
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox onChange={handleCheckbox} checked={paragraph.keepWithNext} name="keepWithNext" />}
              label={<span>Keep with next</span>}
            />
          </div>
        </div>
      </div>

      <div className={cl.wrapperRow}>
        <span className={cl.title}> Break before:</span>
        <ExpanderItem
          valueProps={adobeWord(paragraph.breakBefore, true)}
          propsLabel={breakLabel}
          setProps={setSelectedDropMenu}
          type="breakBefore"
        />
      </div>

      <div className={cl.wrapperRow}>
        <span className={cl.title}> Break after:</span>
        <div>
          <ExpanderItem
            valueProps={adobeWord(paragraph.breakAfter, true)}
            propsLabel={breakLabel}
            setProps={setSelectedDropMenu}
            type="breakAfter"
          />
          <div>
            <FormControlLabel
              control={<Checkbox onChange={handleCheckbox} checked={paragraph.marker} name="marker" />}
              label={<span>Marker</span>}
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox onChange={handleCheckbox} checked={paragraph.spanAll} name="spanAll" />}
              label={<span>Span all columns</span>}
            />
          </div>
        </div>
      </div>

      <div className={cl.wrapperRow}>
        <span className={cl.title}>Horizontal alignment:</span>
        <ExpanderItem
          valueProps={adobeWord(paragraph.horizontalAlign, true)}
          propsLabel={breakLabel}
          setProps={setSelectedDropMenu}
          type="horizontalAlign"
        />
      </div>
    </div>
  );
}

export default TextFlow;
