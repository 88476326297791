// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.
import firebase from 'firebase/app';
import '@firebase/messaging';
import { showSnackbarPushNotification } from '@zert-packages/components/Snackbars';

const INSTANCE_TOKEN = 'INSTANCE_TOKEN';
const config = {
  apiKey: 'AIzaSyBDD6bHEYK55dZzZJwflU7Gf7uovrWe9Ds',
  authDomain: 'zert-test.firebaseapp.com',
  databaseURL: 'https://zert-test.firebaseio.com',
  projectId: 'zert-test',
  storageBucket: 'zert-test.appspot.com',
  messagingSenderId: '1050409533692',
  appId: '1:1050409533692:web:0d82e50e3a8bf82b185779',
  measurementId: 'G-B54EN3T9EJ'
};

firebase.initializeApp(config);

let messaging;

// we need to check if messaging is supported by the browser
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export default function register(hooksInMiddleware) {
  if ('serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    //  const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
    //  if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
    //   return;
    // }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/custom-sw.js`;

      if (isLocalhost) {
        // This is running on localhost. Lets check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, hooksInMiddleware);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://goo.gl/SC7cgQ'
          );
        });
      } else {
        // Is not local host. Just register service worker
        registerValidSW(swUrl, hooksInMiddleware);
      }
    });
  }
}

function registerValidSW(swUrl, hooksInMiddleware) {
  navigator.serviceWorker
    .register(swUrl)
    .then(async (registration) => {
      registration.onupdatefound = () => {
        console.log('New update found');
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          console.log('Is not installing, returning');
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            console.log('New service worker installed');
            if (navigator.serviceWorker.controller) {
              // At this point, the old content will have been purged and
              // the fresh content will have been added to the cache.
              // It's the perfect time to display a "New content is
              // available; please refresh." message in your web app.
              console.log('New content is available; please refresh.');

              // Send a message to the waiting service worker to stop waiting
              if (registration.waiting) {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');
            }
          }
        };
      };

      messaging.useServiceWorker(registration);

      messaging.onMessage((payload) => {
        const { title } = payload.data;

        const { body } = payload.data;
        const name = payload.data.taskName;
        const id = payload.data.notificationId;
        const { link } = payload.data;

        showSnackbarPushNotification(
          hooksInMiddleware.snackbar,
          hooksInMiddleware.history,
          hooksInMiddleware.dispatch,
          `${title} -- ${body}`,
          link,
          id,
          name
        );
      });
      if (Notification.permission !== 'granted') {
        await messaging.requestPermission();
      }

      try {
        if (!localStorage.getItem(INSTANCE_TOKEN)) {
          const token = await messaging.getToken();
          //   registerToken(token);

          localStorage.setItem(INSTANCE_TOKEN, token);
        }
      } catch (e) {
        console.log(e);
      }
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl, hooksInMiddleware) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' }
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');

      if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, hooksInMiddleware);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App2 is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
