import React, { useContext, useState } from 'react';
import ActionableTableOld from '@zert-packages/components/shared/ActionableTable/ActionableTable.old';
import addDefaultLanguages from '@zert-packages/utils/addDefaultLanguages';
import { FormattedMessage } from 'react-intl';

import moveItemInArray from '@zert-packages/utils/moveItemInArray';

import './Roles.css';
import DeleteConfirmDialog from '@zert-packages/components/shared/DeleteConfirmDialog';
import getTranslation from '@zert-packages/utils/getTranslation.old';
import dialogCustom from '@zert-packages/components/common/dialogCustom';
import EditRoleDialog from './EditRoleDialog';
import { getDefaultRole } from '../assets/CONSTS';
import { CatalogContext } from '../common/CatalogProvider';

function RolesList() {
  const { handleRenderTree, globalTree, treeForRendering, selectedNode, isEverythingDisabled } =
    useContext(CatalogContext);

  const [selectedRole, setSelectedRole] = useState(null);

  const handleSelect = (id) => {
    const foundRole = treeForRendering.roles.find((role) => role.identifier === id);
    addDefaultLanguages(foundRole.label);
    setSelectedRole(foundRole);
  };

  const updateRoles = (newRoles) => (globalTree.roles = newRoles);

  const updateSelectedNodeAssignments = (newAssignments) => {
    selectedNode.assignments = newAssignments;
    handleRenderTree();
  };

  const moveRole = (position) => {
    const newRoles = moveItemInArray(treeForRendering.roles, 'identifier', selectedRole.identifier, position);
    updateRoles(newRoles);
    handleRenderTree();
  };

  const handleDeleteRole = () => {
    function deleteRole() {
      const newAssignments = [
        ...selectedNode.assignments.filter((assignment) => assignment.identifier !== selectedRole.identifier)
      ];
      updateSelectedNodeAssignments(newAssignments);

      const newRoles = [...treeForRendering.roles.filter((role) => role.identifier !== selectedRole.identifier)];
      updateRoles(newRoles);

      handleRenderTree();
    }

    dialogCustom(DeleteConfirmDialog, {
      onDelete: deleteRole,
      customTitle:  <FormattedMessage
        id="deleteconfirm.dialog.roleDeletionTitle"
        defaultMessage="Delete role">
      </FormattedMessage>,
      customMessage: <FormattedMessage
        id="deleteconfirm.dialog.roleDeletionMessage"
        defaultMessage="This will delete the role for the entire structure. Are you sure you want to proceed?">
      </FormattedMessage>
    });
  };

  const handleAddRole = () => {
    function addRole({ label, user, identifier }) {
      const newRole = {
        ...getDefaultRole(),
        identifier,
        label
      };
      if (user) {
        updateSelectedNodeAssignments([
          ...selectedNode.assignments,
          ...[
            {
              identifier,
              user
            }
          ]
        ]);
      }
      updateRoles([...treeForRendering.roles, ...[newRole]]);
      handleRenderTree();
    }

    const state = {
      identifier: '',
      label: {
        locales: ['en', 'sv'],
        translations: ['', '']
      },
      user: null
    };
    dialogCustom(EditRoleDialog, {
      state,
      saveRole: addRole
    });
  };

  const handleEditRole = () => {
    function editRole({ label, user, identifier }) {
      const previousIdentifier = selectedRole.identifier;
      const newRoles = treeForRendering.roles.map((role) =>
        role.identifier === previousIdentifier
          ? {
            ...role,
            label,
            identifier
          }
          : role
      );
      const isFoundAssignment = selectedNode.assignments.find((assign) => assign.identifier === previousIdentifier);
      let newAssignments;
      if (isFoundAssignment) {
        newAssignments = selectedNode.assignments.map((assign) =>
          assign.identifier === previousIdentifier
            ? {
              ...assign,
              identifier,
              user
            }
            : assign
        );
      } else {
        newAssignments = [
          ...selectedNode.assignments,
          ...[
            {
              identifier,
              user
            }
          ]
        ];
      }

      console.log(newAssignments);

      updateRoles(newRoles);
      updateSelectedNodeAssignments(newAssignments);
      setSelectedRole(null);
      handleRenderTree();
    }

    const foundAssignment = selectedNode.assignments.find((assign) => assign.identifier === selectedRole.identifier);
    dialogCustom(EditRoleDialog, {
      state: {
        identifier: selectedRole.identifier,
        label: selectedRole.label,
        user: (foundAssignment && foundAssignment.user) || null
      },
      saveRole: editRole
    });
  };

  return (
    <div className="roles__container">
      <ActionableTableOld
        disabled={isEverythingDisabled}
        columns={columns}
        rows={makeRowsOfRoles({
          roles: treeForRendering.roles,
          assignments: selectedNode.assignments
        })}
        selectedRowId={selectedRole !== null ? selectedRole.identifier : null}
        handleSelectRow={handleSelect}
        onAdd={handleAddRole}
        onEdit={handleEditRole}
        onDelete={handleDeleteRole}
        onUp={() => moveRole(-1)}
        onDown={() => moveRole(1)}
      />
    </div>
  );
}

export default RolesList;

const columns = [
  {
    name: <FormattedMessage id="tree.node.column.name" defaultMessage="Name" />,
    identifier: 'name'
  },
  {
    name: <FormattedMessage id="tree.node.column.user" defaultMessage="User" />,
    identifier: 'user'
  }
];

function makeRowsOfRoles({ roles, assignments }) {
  const rows = [];
  roles.forEach((role) => {
    const user = assignments.find((assignment) => assignment.identifier === role.identifier);
    const row = {
      id: role.identifier,
      name: getTranslation(role.label),
      user: user && user.user ? user.user.fullname : ''
    };
    rows.push(row);
  });
  return rows;
}
