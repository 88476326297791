import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  root: (props) =>  ({
    width: "100%",
    maxWidth: props.width || 414,
    padding: 11,
    background: "#E2F2F6"
  }),
  wrapperTitle: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700,
    textTransform: "capitalize"
  },
  wrapperButton: {
    display: "flex",
    marginTop: 30,
    justifyContent: "end",
    gap: 10
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

const AlertTextDialog = ({width, text, title='', cancel = true, setConfirm, setClose }) => {
  const cl = useStyles({width});
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    if(setClose){
      setClose()
    }
  };

  const confirm = () => {
    if (setConfirm) {
      setConfirm();
    }
    setOpen(false);
  };


  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>{title}</span>
      <CloseIcon onClick={handleClose} />
    </div>
    <div>
      {text}
    </div>
    <div className={cl.wrapperButton}>
      {cancel &&
        <Button className={cl.button} onClick={handleClose}>
          Cancel
        </Button>}
      <Button className={cl.button} onClick={confirm}>
        OK
      </Button>
    </div>
  </Dialog>);
};

export default AlertTextDialog;