import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 414,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  wrapperButton: {
    display: "flex",
    marginTop: 30,
    justifyContent: 'end',
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}))


const BufferAlertDialog = () => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return(<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>Information</span>
      <CloseIcon onClick={handleClose} />
    </div>
      <div>
        There is nothing to paste, the clipboard is empty.
      </div>
    <div className={cl.wrapperButton}>
      <Button className={cl.button} onClick={handleClose}>
        OK
      </Button>
    </div>
  </Dialog>)
}

export default BufferAlertDialog