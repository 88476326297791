


interface CriteriaValue {
  key: string,
  value: string
}

interface CriteriaArray {
  className: string,
  values: Array<CriteriaValue>
}

const isArrayRegex =  /\[\d+\]/;
const splitArrayRegex = /[\[\]]/;

/*Converts search criteria from the format returned by the backend to
the format stored in the redux state.*/
export function convertFromBackendCriteria(criteria: Array<CriteriaArray>) {
  const searchCondition = {};
  criteria.forEach((c) => {
    const newCond = {
      className: c.className,
      expanded: true,
      values: {}
    };
    const valuesObj = {expanded: true}
    c.values.forEach((v) => {
      const newValues = {}
      if (isArrayRegex.test(v.key)) {
        const keyParts = v.key.split(splitArrayRegex);
        const i = parseInt(keyParts[1], 10);
        if (!(keyParts[0] in valuesObj)) {
          valuesObj[keyParts[0]] = [];
        }
        if (keyParts.length > 2 && keyParts[2]) {
          console.log(keyParts);
          console.log(v.key);
          if (keyParts[2] === "/Id") {
            keyParts[2] = "identity";
          } else if (keyParts[2] === "/Value") {
            keyParts[2] = "value";
          }
          if (!(i in valuesObj[keyParts[0]])) {
            valuesObj[keyParts[0]][i] = {};
          }
          valuesObj[keyParts[0]][i][keyParts[2]] = v.value;
        } else {
          valuesObj[keyParts[0]][i] = v.value;
        }
      } else {
        valuesObj[v.key] = v.value;
      }
    });
    newCond.values = Object.entries(valuesObj)
      .map(([k,v]) => {return {key: k, value: v}});
    searchCondition[c.className] = newCond;
  });
  return searchCondition;
}

