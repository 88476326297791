import React, { useState, useEffect, useContext } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import SelectField from '@zert-packages/components/shared/FormElements/SelectField';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import Autocomplete from '@mui/material/Autocomplete';

import ElementSearchingDialog from '@zert-packages/components/ElementSearchingDialog';
import { showSnackbarError } from '@zert-packages/components/Snackbars';
import dialogCustom from '@zert-packages/components/common/dialogCustom';
import { useSnackbar } from 'notistack';
import { loadCompareWithVersions } from '../../PreviewPlugin/API';
import { AssertPublishingWizardContext } from './PublishingWizardStepper';
import { MetadataInput } from '../components/MetadataInput';
import ShowMoreLayouts from '../../PreviewPlugin/components/ShowMoreLayouts';
import { downloadLayouts } from '../../PreviewPlugin/servicesMiddlewares';

import { addMoreLayouts, setSelectedLayout } from '../../PreviewPlugin/previewReducers';
import { loadAssertName, loadMetadataList } from '../API';

export const splitLevels = [
  {
    value: 'HEADER_1',
    label: <FormattedMessage id="Publish.Assert.Split.Heading" defaultMessage="Header 1" />
  },
  {
    value: 'HEADER_2',
    label: <FormattedMessage id="Publish.Assert.Split.Heading2" defaultMessage="Header 1 and 2" />
  },
  {
    value: 'NONE',
    label: <FormattedMessage id="Publish.Assert.Split.None" defaultMessage="None" />
  }
];
const useStyles = makeStyles((theme) => ({
  DialogContent: {
    width: '100%',
    height: '600px',
    display: 'grid',
    gridTemplateRows: '48px 48px 48px 1fr'
  },
  bodyContainer: {
    overflow: 'auto'
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%'
    //   minWidth: 190,
  }
}));

const options = [
  { label: 'Bruksanvisning' },
  { label: 'Extrautrustning' },
  { label: 'Scheman' },
  { label: 'Serviceanvisning' },
  { label: 'Reparationsanvisning' },
  { label: 'Reservdelskatalog' },
  { label: 'Verkstadshandbok' }
];

export function AssertPublishingPage({ unmountComponent, selectElement, onConfirm }) {
  const cl = useStyles();
  const { layouts, selectedLayout, selectedLanguage, layoutFilters, selectedLayoutFilter, layoutSettings } =
    useSelector((state) => state.PREVIEW);
  const [selectedLayoutElement, setSelectedLayoutElement] = useState(null);

  const { multipleSelectedLanguage, languageSeparated, fallbackLocale } = useSelector((state) => state.PREVIEW);
  const { compareWithVersions, compareWithVersionId } = useSelector((state) => state.PREVIEW);
  const { previewPublishingFilters } = useSelector((state) => state.PREVIEW);
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    setDisabledNext,
    from,
    to,
    splitLevel,
    elementId,
    documentId,
    name,
    versionId,
    versions,
    versionsLoad,
    setStatus,
    serialNumber,
    productId,
    updateSerialNumber,
    updateProductId,
    nameChanged,
    loadingMetadata,
    published
  } = useContext(AssertPublishingWizardContext);
  const snackbar = useSnackbar();

  const withUnmountComponent = (callback) => () => {
    unmountComponent();
    if (callback) callback();
  };
  useEffect(() => {
    if (layouts && selectedLayout)
      setSelectedLayoutElement(layouts.find((layout) => layout.versionId == selectedLayout));
  }, [selectedLayout, layouts]);
  const handleCancel = () => unmountComponent();
  const handleConfirm = () => {
    onConfirm();
    unmountComponent();
    //  handleClose();
  };

  const layoutChanged = (e) => {
    dispatch(setSelectedLayout(e.target.value));
  };

  const versionIdChanged = (event) => {
    setStatus((prevState) => ({ ...prevState, versionId: event.target.value, nameChanged: false }));
    setDisabledNext(false);
  };

  const changeSplitLevel = (state) => {
    setStatus((prevState) => ({ ...prevState, splitLevel: state.value }));
  };

  const handleChange = (field) => (event) => {
    const { value } = event.target;

    setStatus((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleChangeName = (event) => {
    const { value } = event.target;

    setStatus((prevState) => ({ ...prevState, name: value, nameChanged: true }));
  };

  const handleChangeAuto = (field) => (event) => {
    const { value } = event.target;
    if (value >= 0) {
      setStatus((prevState) => ({ ...prevState, [field]: options[value].label, nameChanged: true }));
    } else {
      setStatus((prevState) => ({ ...prevState, [field]: null, nameChanged: true }));
    }
  };

  useEffect(() => {
    dispatch(downloadLayouts());
    if (!versions && versionId) {
      loadVersions({ versionId, properties: {} });
    }
  }, []);

  useEffect(() => {
    // dispatch(downloadLayouts());
    if (versionId) {
      loadMetadata();
      /// loadVersions({versionId : versionId, properties : {}})
    }
  }, [versionId]);

  const showMoreLayout = () => {
    renderInExceptionRoot(ShowMoreLayouts, {
      selectedLayoutElement,

      onConfirm: (selectedLayout) => {
        dispatch(addMoreLayouts(selectedLayout));
        dispatch(setSelectedLayout(selectedLayout.versionId));
      }
    });
  };

  const loadMetadata = async () => {
    if (!nameChanged) {
      setStatus((prevState) => ({ ...prevState, loadingMetadata: true }));
      const metadata = await loadMetadataList(versionId, -1, 'ChassisNumber');
      updateSerialNumber(metadata && metadata.length > 0 ? metadata.map((value) => value.value) : ['']);
      const metadata1 = await loadMetadataList(versionId, -1, 'Machine type');
      updateProductId(metadata1 && metadata1.length > 0 ? metadata1.map((value) => value.value) : ['']);
      const metadata2 = await loadMetadataList(versionId, -1, 'IBNumber');
      setStatus((prevState) => ({ ...prevState, documentId: metadata2 && metadata2.length ? metadata2[0].value : '' }));

      const name = await loadAssertName(versionId, -1);

      setStatus((prevState) => ({ ...prevState, name: name || '' }));
      setStatus((prevState) => ({ ...prevState, loadingMetadata: false }));
    }
  };

  const loadVersions = async (elementNew) => {
    delete elementNew.properties;
    setStatus((prevState) => ({
      ...prevState,
      versions: null,
      versionId: null,
      versionsLoad: true,
      nameChanged: false,
      element: null
    }));
    try {
      const versions = await loadCompareWithVersions(elementNew.id);
      setStatus((prevState) => ({
        ...prevState,
        versions,
        versionId: elementNew.versionId,
        versionsLoad: false,
        elementId: elementNew.id,
        nameChanged: false,
        element: elementNew
      }));
      setDisabledNext(false);
    } catch (e) {
      setStatus((prevState) => ({ ...prevState, versions: null, versionId: null, versionsLoad: false }));
      setDisabledNext(false);
      //    snackbar.closeSnackbar(generatingSnackbar);
      showSnackbarError(snackbar, e);
    }
  };

  const showVersionSearch = () => {
    dialogCustom(ElementSearchingDialog, {
      onConfirm: loadVersions
    });
  };

  return (
    <form
      style={{
        display: 'flex',
        justifyContent: 'stretch',
        flexFlow: 'column nowrap'
      }}
    >
      <Typography variant="h2">
        <FormattedMessage id="Publish.Assert.Follow" defaultMessage="Follow these steps to publish your element" />
      </Typography>

      <Typography variant="h6">
        <FormattedMessage id="Publish.Assert.AvailibiltyDates" defaultMessage="Availibilty Dates" />
      </Typography>
      <Grid container spacing={2} direction="row">
        <Grid item xs={46} md={6}>
          <TextField
            fullWidth
            id="standard-from_date_between"
            label={<FormattedMessage id="Publish.Assert.from" defaultMessage="From" />}
            placeholder={<FormattedMessage id="Publish.Assert.from" defaultMessage="From" />}
            className={classes.formControl}
            value={from}
            onChange={handleChange('from')}
            type="date"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextField
            fullWidth
            id="standard-to_date_between"
            label={<FormattedMessage id="Publish.Assertto" defaultMessage="To" />}
            placeholder={<FormattedMessage id="Publish.Assert.to" defaultMessage="To" />}
            className={classes.formControl}
            value={to}
            onChange={handleChange('to')}
            type="date"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {versionsLoad ? (
            <Loader />
          ) : (
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="layout">
                <FormattedMessage id="Publish.Assert.Element" defaultMessage="Elements" />{' '}
              </InputLabel>
              <Select
                value={versionId}
                onChange={versionIdChanged}
                inputProps={{
                  name: 'layout',
                  id: 'layout'
                }}
                endAdornment={
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle layout details" onClick={showVersionSearch} size="large">
                      <MoreHorizIcon />
                    </IconButton>
                  </InputAdornment>
                }
              >
                {versions.map((version) => (
                  <MenuItem key={version.versionId} value={version.versionId}>
                    <em>
                      {version.name} {`(${version.label})`}
                    </em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={6} md={6}>
          {layouts ? (
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="layout">
                <FormattedMessage id="Publish.Assert.Layout" defaultMessage="Layouts" />{' '}
              </InputLabel>
              <Select
                value={selectedLayout}
                onChange={layoutChanged}
                inputProps={{
                  name: 'layout',
                  id: 'layout'
                }}
                endAdornment={
                  selectedLayoutElement ? (
                    <InputAdornment position="start">
                      <IconButton aria-label="toggle layout details" onClick={showMoreLayout} size="large">
                        <MoreHorizIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null
                }
              >
                {layouts.map((value) => (
                  <MenuItem key={value.versionId} value={value.versionId}>
                    <em>
                      {value.name} {value.extended ? `(${value.label})` : ''}
                    </em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Loader />
          )}
        </Grid>
        <Grid item xs={6} md={6}>
          <SelectField
            values={splitLevels}
            onStateChanged={changeSplitLevel}
            value={splitLevel}
            caption={<FormattedMessage id="AssertPublishing.Split" defaultMessage="Split level" />}
          />
        </Grid>

        <Grid item xs={6} md={6}>
          <TextField
            fullWidth
            id="elementId"
            label={<FormattedMessage id="Publish.Assert.ElementId" defaultMessage="Element Id" />}
            placeholder={<FormattedMessage id="Publish.Assert.ElementId" defaultMessage="Element Id" />}
            className={classes.formControl}
            value={elementId}
            disabled
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextField
            fullWidth
            id="datePublishing"
            type="date"
            label={<FormattedMessage id="Publish.Assert.DatePublishing" defaultMessage="Published" />}
            placeholder={<FormattedMessage id="Publish.Assert.DatePublishing" defaultMessage="Published" />}
            className={classes.formControl}
            value={published}
            disabled
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={6} md={6}>
          <Autocomplete
            disablePortal
            freeSolo
            id="combo-box-demo"
            options={options}
            className={classes.formControl}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            label={<FormattedMessage id="Publish.Assert.DocumentType" defaultMessage="Document type" />}
            placeholder="Document type"
            autoHighlight
            inputValue={name}
            onChange={handleChangeAuto('name')}
            getOptionLabel={(option) => option.label}
            /* renderOption={(option, props) => {
                         //       debugger;
                               return (<Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        alt=""
                                    />
                                    {option.label} ({option.code}) +{option.phone}
                                </Box>)
                            }} */
            renderInput={(params) => (
              <TextField
                options={options}
                {...params}
                value={name}
                onChange={handleChangeName}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password' // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextField
            fullWidth
            id="datePublishing"
            onChange={handleChange('documentId')}
            label={<FormattedMessage id="Publish.Assert.DocumentId" defaultMessage="DocumentId" />}
            placeholder="DocumentId"
            className={classes.formControl}
            value={documentId}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          {!loadingMetadata && (
            <MetadataInput
              key={`productId${versionId}`}
              list={productId}
              text={<FormattedMessage id="Publish.Assert.ProductId" defaultMessage="Product id" />}
              identifier="Machine type"
              handleUpdate={updateProductId}
            />
          )}
        </Grid>
        <Grid item xs={6} md={6}>
          {!loadingMetadata && (
            <MetadataInput
              key={`serialNumber${versionId}`}
              list={serialNumber}
              text={<FormattedMessage id="Publish.Assert.SerialNumber" defaultMessage="Serial number" />}
              identifier="ChassisNumber"
              handleUpdate={updateSerialNumber}
            />
          )}
        </Grid>
      </Grid>
    </form>
  );
}
