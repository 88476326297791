import React, { memo } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import ExpansionWrapperCriteria from '../common/ExpansionWrapperCriteria';

const VersionSearchCriteria = memo(
  ({ foundCriteria, updateCriteria, onChangeExpansionOfCriteria, classNameOfCriteria, isEverythingDisabled }) => {
    const onChangeVersion = (event) => {
      const { value } = event.target;
      const newValues = foundCriteria.values.map((item) =>
        item.key === 'Filter'
          ? {
            ...item,
            value
          }
          : item
      );
      updateCriteria(classNameOfCriteria, newValues);
    };

    const handleOnChangeExpansion = (event, isExpanded) => {
      onChangeExpansionOfCriteria(isExpanded, classNameOfCriteria);
    };

    return (
      <ExpansionWrapperCriteria
        title={<FormattedMessage id="searchCriteriaCounter.versionSearch" defaultMessage="Version" />}
        handleOnChangeExpansion={handleOnChangeExpansion}
        expanded={!!foundCriteria}
      >
        {!!foundCriteria && (
          <FormControl variant="standard" fullWidth disabled={isEverythingDisabled}>
            <InputLabel>
              <FormattedMessage id="searchCriteriaCounter.version" defaultMessage="Version" />
            </InputLabel>
            <Select
              variant="standard"
              value={foundCriteria.values.find((item) => item.key === 'Filter').value}
              onChange={onChangeVersion}
            >
              <MenuItem value="current">
                <FormattedMessage id="searchCriteriaCounter.currentVersion" defaultMessage="Current version" />
              </MenuItem>
              <MenuItem value="root">
                <FormattedMessage id="searchCriteriaCounter.rootVersion" defaultMessage="Root version" />
              </MenuItem>
              <MenuItem value="all">
                <FormattedMessage id="searchCriteriaCounter.allVersions" defaultMessage="All versions" />
              </MenuItem>
              <MenuItem value="approved">
                <FormattedMessage id="searchCriteriaCounter.approvedVersions" defaultMessage="Approved versions" />
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </ExpansionWrapperCriteria>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps.foundCriteria, nextProps.foundCriteria)
);

export default VersionSearchCriteria;
