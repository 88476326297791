import { makeStyles } from "@mui/styles";

const styleDialogHandleImg = makeStyles((theme) => (
  {
    root: {
      width: "100%",
      maxWidth: 414,
      padding: 11,
      background: "#E2F2F6"
    },
    wrapperTitle: {
      marginBottom: 10,
      display: "flex",
      justifyContent: "space-between",
      textTransform: "uppercase",
      "& svg": {
        cursor: "pointer"
      }
    },
    title: {
      fontSize: "1.8rem",
      fontWeight: 700
    },
    wrapper: {},
    notesInput: {
      margin: 0,
      width: "100%",
      maxWidth: 245,
      "& input": {
        background: "#ffffff",
        paddingBottom: 5,
        paddingTop: 5,
        paddingLeft: 15
      }
    },
    wrapperLengthInput: {
      display: "grid",
      gridColumnGap: 15,
      gridTemplateColumns: "1fr 58px",
      alignItems: "center"
    },
    wrapperInput: {
      display: "grid",
      gridTemplateColumns: "1fr 245px",
      gridColumnGap: 15
    },
    wrapperVertical: {
      display: "grid",
      gridTemplateColumns: "59px auto 1fr",
      gridColumnGap: 15
    },
    containerInput: {
      display: "grid",
      gridRowGap: 5
    },
    inputTitle: {
      fontWeight: 700,
      fontSize: 14
    },
    formRoom: {
      marginLeft: 0,
      display: "grid",
      gridTemplateColumns: "13px 1fr",
      gridColumnGap: 10,
      marginTop: 10,
      marginBottom: 25,
      "& label": {
        cursor: "pointer",
        marginBottom: 0,
        "& input": {
          marginTop: 4
        }
      }
    },
    rowButton: {
      display: "flex",
      marginTop: 30,
      justifyContent: "space-between",
      "& button": {
        textTransform: "initial"
      }
    },
    wrapperButton: {
      display: "flex"
    },
    button: {
      color: "#ffffff",
      background: "#4db1d3",
      marginLeft: 10,
      minWidth: 80,
      border: "1px solid #4db1d3",
      outline: "none !important",
      cursor: "pointer",
      "&:hover": {
        background: "none",
        color: "#212529",
        border: "1px solid #4db1d3"
      },
      "&:focus": {
        backgroundColor: "#4db1d3"
      }
    }
  }
));

export default styleDialogHandleImg;