import React from 'react';

export default class SearchComponent extends React.Component {
  constructor(props) {
    super(props);
    // this.state = this.props.data;
    this.state = this.props.data;
    this.setStatus = this.setStatus.bind(this);
  }

  expand() {
    this.props.handleChange(this.state);
  }

  setStatus(state) {
    this.setState(state, (prev) => {
      this.props.handleChange(this.state);
    });
  }
}
