import { FormattedMessage } from 'react-intl';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const generateRMPReportMockUp = () => ({
  name: <FormattedMessage id="RMP.GenerateReportMockUp.Name" defaultMessage="RMP Report" />,
  uuid: uuidv4()
});

export default generateRMPReportMockUp;
