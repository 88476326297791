import React from 'react';
import { makeStyles } from '@mui/styles';
import { Skeleton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  }
}));

const SkeletonLoader = ({ height }) => {
  const cl = useStyles();
  return (
    <div className={cl.root}>
      <Skeleton
        animation="wave"
        style={{
          height: `${height}px`
        }}
      />
    </div>
  );
};

export default SkeletonLoader;
