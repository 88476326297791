import React, { useEffect, useRef, useState, Fragment } from 'react';
import { Button, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { setApiMetadata } from '../API';
// import {setApiMetadata} from '@zert-packages/actions/api';

const useStyles = makeStyles((theme) => ({
  rootInputCell: {
    background: '#EDEDED',
    padding: '10px 20px 10px 20px',
    borderTop: '1px solid #D3D3D3',
    marginBottom: 5,
    '@media (max-width: 900px)': {
      padding: '10px'
    }
  },
  rootInputCellWrapper: {
    display: 'flex'
  },
  wrapperInput: {
    width: '100%'
  },
  arrowUpImg: {
    color: '#647F86',
    transform: 'rotate(90deg)',
    cursor: 'pointer'
  },
  arrowDownImg: {
    color: '#647F86',
    transform: 'rotate(270deg)',
    cursor: 'pointer'
  },
  wrapperImgArrow: {
    width: 47,
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 900px)': {
      width: 35
    }
  },
  notesInput: {
    display: 'block',
    '& fieldset': {
      border: 'none'
    }
  },
  notesInputRoot: {
    background: '#ffffff',
    paddingLeft: 10,
    paddingRight: 10,
    '& div': {
      width: '100%'
    },
    '& input': {
      padding: '10px 5px'
    }
  },

  dropMenuButtonWrapperButtons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    padding: '10px 15px 20px 15px',
    gridColumnGap: '5%',
    '@media (max-width: 900px)': {
      gridColumnGap: '15px',
      padding: '10px 10px 15px 10px'
    },
    '& button': {
      background: '#ffffff',
      '&:hover': {
        background: '#ffffff'
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 0,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    background: '#ffffff',
    color: '#D3D3D3',
    fontWeight: 300
  },
  noteActiveInputWrapper: {
    '& $notesInputRoot': {
      padding: 0
    },
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15
    }
  }
}));

function InputCell({ valueProps, lastCell, resultCell, create, setNevCategory, nevCategory, firstCell, idBlock }) {
  const cl = useStyles();
  const inputRef = useRef();
  const [value, setValue] = useState('');
  const [active, setActive] = useState(true);
  const [cellElement, setCellElement, setShowMettaButton] = resultCell;

  const handleChangeNote = (event) => {
    if (event.target.value.includes('|')) {
      if (setNevCategory) {
        if (nevCategory) {
          setNevCategory((prev) => [
            {
              ...valueProps,
              initialValue: {
                value: ''
              }
            },
            ...prev
          ]);
        } else {
          setNevCategory([
            {
              ...valueProps,
              initialValue: {
                value: ''
              }
            }
          ]);
        }
      }

      return;
    }
    setValue(event.target.value);
  };

  const handleSave = () => {
    if (value.length < 1) {
      setActive(!active);
      setShowMettaButton(false);
      return;
    }
    const cellElementArray = [{ identifier: valueProps.identifier, value }, cellElement].flat();
    const findValue = cellElementArray.filter((el) => el.idSort === valueProps.idSort && el.value === value);

    if (!active) {
      setActive(!active);
      setShowMettaButton(false);
      setCellElement(cellElementArray);
      return setApiMetadata(cellElementArray, idBlock);
    }
    if (findValue.length === 0) {
      setApiMetadata(cellElementArray);
    }
  };

  const handleRemove = () => {
    const result = cellElement.filter((el) => el.idSort !== valueProps.idSort);
    setCellElement(result);
    setApiMetadata(result);
  };

  const handleActive = () => {
    setActive(!active);
    if (setShowMettaButton) {
      setShowMettaButton(false);
    }
  };

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  const handleSort = (ArrowUp) => {
    const findIndexResult = cellElement.findIndex((el) => el.idSort === valueProps.idSort);

    if (ArrowUp) {
      const result = array_move(cellElement, findIndexResult, findIndexResult - 1).map((el) => el);
      // setCellElement({...cellElement, values: result})
      setCellElement(result);
    } else {
      const result = array_move(cellElement, findIndexResult, findIndexResult + 1).map((el) => el);
      setCellElement(result);
      // setCellElement({...cellElement, values: result})
    }
  };

  useEffect(() => {
    if (valueProps) {
      if (valueProps.value) {
        if (valueProps.value.name && valueProps.value.name.defaultLabel) {
          setValue(valueProps.value.name.defaultLabel);
        } else {
          setValue(valueProps.value);
        }
      }
      if (create) {
        setActive(false);
      }
    }
  }, [cellElement]);



  const handleFocus = () => {
    inputRef.current.focus();
  };




  return (
    <>
      {valueProps && (
        <div className={cl.rootInputCell}>
          {active ? (
            <div className={cl.rootInputCellWrapper}>
              {!create && cellElement.length > 1 && (
                <div className={cl.wrapperImgArrow} style={{ justifyContent: lastCell ? 'end' : 'flex-start' }}>
                  {!firstCell && <ArrowBackIcon className={cl.arrowUpImg} onClick={() => handleSort(true)} />}
                  {!lastCell && <ArrowBackIcon className={cl.arrowDownImg} onClick={() => handleSort()} />}
                </div>
              )}
              <div className={cl.wrapperInput} onClick={handleActive}>
                <div className={cl.titleWrapper}>
                  {valueProps.identifier && <p>{valueProps.identifier}</p>}
                  {valueProps.testCategory && <p>{valueProps.testCategory}</p>}
                </div>
                <div className={cl.noteActiveInputWrapper}>
                  <TextField
                    classes={{
                      root: cl.notesInputRoot
                    }}
                    value={value}
                    className={cl.notesInput}
                    variant="outlined"
                    onChange={handleChangeNote}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className={cl.titleWrapper} onClick={handleFocus}>
                {valueProps.identifier && <p>{valueProps.identifier}</p>}
                {valueProps.testCategory && <p>{valueProps.testCategory}</p>}
              </div>
              <TextField
                classes={{
                  root: cl.notesInputRoot
                }}
                inputRef={inputRef}
                autoFocus
                value={value}
                className={cl.notesInput}
                variant="outlined"
                onChange={handleChangeNote}
              />
              <div className={cl.dropMenuButtonWrapperButtons}>
                <Button onClick={handleSave}>OK</Button>
                <Button onClick={handleActive}>Cancel</Button>
                <Button onClick={handleRemove}>remove</Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default InputCell;
