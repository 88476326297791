import { Dialog, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 500,
    height: 280,
    padding: 11,
    background: '#E2F2F6'
  },
  wrapperTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      cursor: 'pointer'
    }
  },
  title: {
    fontSize: '1.188rem',
    fontWeight: 700
  },
  wrapperUnderLabel: {
    marginTop: 10,
    marginBottom: 5,
    fontWeight: 700
  },
  templateInput: {
    height: 'Calc(100% - 25px)',
    width: '100%',
    '& .MuiInputBase-multiline.MuiInputBase-marginDense': {
      height: 'Calc(100% - 10px)',
      background: '#ffffff'
    },
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100% !important'
    }
  },
  wrapperButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 20
  },
  button: {
    color: '#ffffff',
    background: '#4db1d3',
    marginLeft: 10,
    minWidth: 80,
    border: '1px solid #4db1d3',
    outline: 'none !important',
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
      color: '#212529',
      border: '1px solid #4db1d3'
    },
    '&:focus': {
      backgroundColor: '#4db1d3'
    }
  }
}));

function DialogTemplate({ open, setOpen, text = null, setText }) {
  const cl = useStyles();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paperScrollPaper: cl.root
      }}
    >
      <div className={cl.wrapperTitle}>
        <span className={cl.title}>User initial page number settings</span>
        <CloseIcon onClick={handleClose} />
      </div>
      <span style={{ paddingLeft: 5 }}>
        <div className={cl.wrapperUnderLabel}>XSL template:</div>
        <TextField variant="outlined" multiline minRows={4} className={cl.templateInput} />
        <div className={cl.wrapperButton}>
          <Button className={cl.button} onClick={handleClose}>
            OK
          </Button>
          <Button className={cl.button} onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </span>
    </Dialog>
  );
}

export default DialogTemplate;
