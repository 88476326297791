import { Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { adobeWord } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 318,
    height: 248,
    padding: 11,
    background: '#E2F2F6'
  },
  wrapperButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 20
  },
  wrapperTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      cursor: 'pointer'
    }
  },
  titleMin: {
    fontSize: '1.5rem',
    fontWeight: 700
  },
  title: {
    fontWeight: 700
  },
  wrapperContent: {
    marginTop: 15,
    marginBottom: 'auto',
    display: 'grid',
    gridRowGap: 10
  },
  wrapperRowTitle: {
    display: 'grid',
    gridTemplateColumns: '70px 1fr',
    gridColumnGap: 10,
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    }
  },

  button: {
    color: '#ffffff',
    background: '#4db1d3',
    marginLeft: 10,
    minWidth: 80,
    border: '1px solid #4db1d3',
    outline: 'none !important',
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
      color: '#212529',
      border: '1px solid #4db1d3'
    },
    '&:focus': {
      backgroundColor: '#4db1d3'
    }
  }
}));

function DialogAddCondition({ values, title = '', setValues, edit }) {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState({
    type: 'Ancestor',
    format: ''
  });

  const handleClose = () => {
    setOpen(false);
  };

  const findFormat = () => {
    if (!values.format()) {
      return '';
    }
    const arr = [{ identifier: '*', type: 'BLOCK' }, { identifier: '*', type: 'TABLE' }, ...values.format()];
    const findIndex = arr.findIndex((el) => el.type === 'TABLE' && el.identifier !== '*');
    return array_move(arr, 1, findIndex).map((el) => `${adobeWord(el.type, true)}: ${el.identifier}`);
  };

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  const handleBlockTabs = (val, type) => {
    setValue((pre) => ({ ...pre, [type]: val }));
  };

  const saveCondition = () => {
    setValues(value);
    handleClose();
  };

  useEffect(() => {
    if (edit) {
      setValue(edit);
    } else {
      setValue((pre) => ({ ...pre, format: findFormat()[0] }));
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paperScrollPaper: cl.root
      }}
    >
      <div className={cl.wrapperTitle}>
        <span className={cl.titleMin}>{title}</span>
        <CloseIcon onClick={handleClose} />
      </div>
      <div className={cl.wrapperContent}>
        <div className={cl.wrapperRowTitle}>
          <span className={cl.title}>Type:</span>
          <ExpanderItem
            valueProps={value.type}
            propsLabel={['Ancestor', 'Parent']}
            type="type"
            setProps={handleBlockTabs}
          />
        </div>
        <div className={cl.wrapperRowTitle}>
          <span className={cl.title}> Format:</span>
          <ExpanderItem valueProps={value.format} propsLabel={findFormat()} type="format" setProps={handleBlockTabs} />
        </div>
      </div>

      <div className={cl.wrapperButton}>
        <Button className={cl.button} onClick={saveCondition}>
          OK
        </Button>
        <Button className={cl.button} onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}

export default DialogAddCondition;
