import { createAction, createReducer } from '@reduxjs/toolkit';

export const loadRoot = createAction('CORE: Load ROOT');
export const loadNodeChildren = createAction('CORE: Load Node');
export const loadNodeChildrenCut = createAction('CORE: Load Node CUT');
export const setNode = createAction('CORE: Select Node');
export const setCutNode = createAction('CORE: Select Cut Node');
export const setCopyContext = createAction('CORE: Set Copied Context');
export const setSelectedPath = createAction('CORE: Select Path');
export const loadNodeContent = createAction('CORE: Load Node Children');
export const loadingNodeContent = createAction('CORE: Loading Node Children');
export const loadNodeParents = createAction('CORE: Load Node Parents');
export const loadSearchResult = createAction('CORE: Search');
export const setSearchCondition = createAction('CORE: Set Search ');
export const prepareSearch = createAction('CORE: Prepare Search');
export const clearAndPrepareSearch = createAction('CORE: Clear and prepare Search ');
export const setSearchIgnoreLocalStorage = createAction('CORE: Set search ignore local storage');
export const removeSearch = createAction('CORE: Remove Search');
export const updateTreeNodes = createAction('Update Tree Nodes');
export const setListView = createAction('CORE: Set list view');

/* export const prepareSearch = (className, search) => ({
    type: ACTION_STORE_SEARCH,
    payload: {
        className,
        search,
    },
});

export const removeSearch = (className) => ({
    type: ACTION_REMOVE_SEARCH,
    payload: { className },
}); */

export const initialState = {
  root: null,
  nodes: {},
  selectedNode: null,
  isLoadingNodeContent: false,
  selectedNodeChildren: null,
  nodeParents: null,
  selectedPath: [],
  cutNode: null,
  searchConditions: {},
  ignoreSearchLocalStorage: false,
  lastSearchConditions: {},
  searchResult: null,
  copiedElements: null,
  updateTreeRecursively: null,
  listView: -1
};

export const EXPLORERReducers = createReducer(initialState, {
  [loadSearchResult]: (state, action) => {
    state.searchResult = action.payload;
  },
  [updateTreeNodes]: (state, action) => {
    state.updateTreeRecursively = { ...action.payload };
  },

  [prepareSearch]: (state, action) => {
    state.searchConditions = { ...state.searchConditions, [action.payload.className]: action.payload.search };
  },
  [clearAndPrepareSearch]: (state, action) => {
    state.searchConditions = action.payload;
  },
  [removeSearch]: (state, action) => {
    delete state.searchConditions[action.payload.className];
    state.searchConditions = { ...state.searchConditions };
  },
  [setCutNode]: (state, action) => {
    state.cutNode = action.payload;
  },
  [setCopyContext]: (state, action) => {
    state.copiedElements = action.payload;
  },
  [setSearchCondition]: (state, action) => {
    state.lastSearchConditions = action.payload;
  },
  [setSearchIgnoreLocalStorage]: (state, action) => {
    state.ignoreSearchLocalStorage = action.payload;
  },
  [loadRoot]: (state, action) => {
    state.root = action.payload;
  },
  [loadingNodeContent]: (state, action) => {
    state.isLoadingNodeContent = action.payload;
  },
  [loadNodeChildren]: (state, action) => {
    state.nodes = { ...state.nodes, [action.payload.ownerId]: action.payload.children };
  },

  [loadNodeChildrenCut]: (state, action) => {
    state.nodes = {
      ...state.nodes,
      [action.payload.ownerId]: action.payload.children,
      [action.payload.cutNode]: action.payload.childrenCutNode
    };
  },

  [setNode]: (state, action) => {
    state.selectedNode = action.payload;
  },
  [loadNodeContent]: (state, action) => {
    state.selectedNodeChildren = action.payload;
  },
  [loadNodeParents]: (state, action) => {
    state.nodeParents = action.payload;
  },
  [setSelectedPath]: (state, action) => {
    state.selectedPath = action.payload;
  },
  [setListView]: (state, action) => {
    state.listView = action.payload;
  }
});
