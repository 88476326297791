export const findLeftMenu = (selectedTab, stylesheet, setValues) => {
  if (selectedTab === 0) {
    return setValues(pre => ({
      ...pre,
      "leftMenu": stylesheet.blockSettings,
      selectedTabName: "blockSettings"
    }));
  }
  if (selectedTab === 1) {
    return setValues(pre => ({
      ...pre,
      "leftMenu": stylesheet.tableSettings,
      selectedTabName: "tableSettings"
    }));
  }
  if (selectedTab === 2) {
    return setValues(pre => ({
      ...pre,
      "leftMenu": stylesheet.imageSettings,
      selectedTabName: "imageSettings"
    }));
  }
  if (selectedTab === 3) {
    return setValues(pre => ({
      ...pre,
      "leftMenu": stylesheet.partSettings,
      selectedTabName: "partSettings"
    }));
  }
  if (selectedTab === 4) {
    return setValues(pre => ({
      ...pre,
      "leftMenu": stylesheet.paragraphSettings,
      selectedTabName: "paragraphSettings"
    }));
  }
  if (selectedTab === 5) {
    return setValues(pre => ({
      ...pre,
      "leftMenu": stylesheet.textSettings,
      selectedTabName: "textSettings"
    }));

  }
  setValues(pre => ({
    ...pre,
    "leftMenu": null,
    selectedTabName: null
  }));

};
