import { FormattedMessage } from 'react-intl';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { generateChecklistReport } from '@zert-packages/actions/checklistActions';
import generalRadioBtns from './generalRadioBtns';

export default function useCheckListStandartReports({ selectedNode, selectedValues, catalog }) {
  const caption = <FormattedMessage id="reports.checklist" defaultMessage="Checklist" />;
  const description = (
    <FormattedMessage
      id="checklist.report.description"
      defaultMessage="This report generates a list of all checklists located on the selected node."
    />
  );

  const dispatch = useDispatch();
  const index = 'checklists';
  const radiobtns = [];
  // eslint-disable-next-line no-use-before-define
  const dateSelectors = [];
  const checkBoxes = [
    {
      name: 'cb_include_matrix',
      label: <FormattedMessage id="reports.includeMatrix" defaultMessage="Include Matrix" />,
      checked: false
    },
    {
      name: 'cb_showImagesSeparatly',
      label: <FormattedMessage id="reports.showImagesSeparately" defaultMessage="Show images separately" />,
      checked: false
    }
  ];
  const selectors = [];

  const generateReport = (checkedItems) => {
    let selectedIds = [];
    if (selectedValues) {
      selectedIds = selectedValues.map((value) => value.versionId);
    }

    dispatch(
      generateChecklistReport(
        selectedIds[0],
        {
          name: caption,
          reportUUID: uuidv4()
        },
        1,
        checkedItems('cb_include_matrix'),
        checkedItems('cb_showImagesSeparatly'),
        false,
        selectedIds
      )
    );
  };

  return {
    index,
    generateReport,
    caption,
    description,
    radiobtns,
    dateSelectors,
    selectors,
    checkBoxes,
    key: 'checklist-report'
  };
}
