import React from 'react';
import './style.css';

class LabelRM extends React.Component {
  render() {
    const content = React.Children.toArray(this.props.children);
    return (
      <label className={`label-vaadin ${this.props.styleName}`}>
        {this.props.text}
        {content.length > 0 && content[0]}
      </label>
    );
  }
}

export default LabelRM;
