import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import withTile from '@zert-packages/components/ElementTile/shared/withTile';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { TileIcon } from '@zert-packages/components/ElementTile/tiles/AbstractTile';
import TaskContentOverviewTile from '@zert-packages/components/ElementTile/shared/TaskContentOverviewTile';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { fetchReviewJob } from '../TranslationJobPlugin/servicesMiddlewares';
import { getCommentStatusById, getCommentTypeById, getMemberStatusById, getReviewStatusById } from './API';

export const collapseCL = (element, dispatch) => {
  dispatch(fetchReviewJob(element.versionId));
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

export function ReviewJobContentOverviewTile({ element }) {
  const classes = useStyles();
  const CLM = useSelector((state) => state.CLM);
  const dispatch = useDispatch();
  const [reviewJob, setReviewJob] = useState(null);
  const [comments, setComments] = useState(null);
  useEffect(() => {
    if (CLM.reviewJob && CLM.reviewJob && CLM.reviewJob.versionId == element.versionId) {
      setReviewJob(CLM.reviewJob);
      setComments(CLM.comments);
    }
  }, [CLM.reviewJob]);

  if (!reviewJob) {
    return (
      <div className="togglable-info-header" style={{ display: 'flex' }}>
        <Loader />
      </div>
    );
  }

  if (comments == []) {
    return <div className="togglable-info-header" style={{ display: 'flex' }} />;
  }
  //   "past-entry"

  let content = null;
  if (comments) {
    content = comments.map((comment, index) => {
      return (
        <div key={index} className="current-entry" style={{ display: 'flex' }}>
          <div style={{ flexBasis: '18em' }}>{comment.dateChanged}</div>
          <div style={{ flexBasis: '30em', paddingLeft: '2px' }}>{comment.comment}</div>
          <div style={{ flexBasis: '10em', paddingLeft: '2px' }}>{getCommentStatusById(comment.commentStatus)}</div>
          <div className="hide_on_mobile" style={{ flexBasis: '10em', paddingLeft: '2px', wordBreak: 'break-word' }}>
            {comment.member.username}
          </div>
          <div className="hide_on_mobile" style={{ flexBasis: '12em', paddingLeft: '2px' }}>
            {getCommentTypeById(comment.commentType)}
          </div>
          <div className="hide_on_mobile" style={{ flex: '1', paddingLeft: '2px' }}>
            {comment.element.name}
          </div>
        </div>
      );
    });
  }

  return (
    <div style={{ width: '100%' }}>
      <div className="togglable-info-header">
        <FormattedMessage id="ReviewJob.Name" defaultMessage="Element" />
      </div>
      <div className="current-entry">
        {' '}
        {reviewJob.includeElementInfo.name}({getReviewStatusById(reviewJob.reviewStatus)})
      </div>

      <div className="togglable-info-header">
        <FormattedMessage id="ReviewJob.members" defaultMessage="Users" />
      </div>
      {reviewJob.revievers.map((element, index) => (
        <div className="current-entry">
          {' '}
          {element.username} -
          {element.active ? (
            <FormattedMessage id="ReviewJobStatus.Active" defaultMessage="Active" />
          ) : (
            <FormattedMessage id="ReviewJobStatus.NotActive" defaultMessage="Not Active" />
          )}
          ({getMemberStatusById(element.status)})
        </div>
      ))}

      <div className="togglable-info-header" style={{ display: 'flex' }}>
        <div style={{ flexBasis: '18em' }}>
          <FormattedMessage id="ReviewJob.ContentTable.Date" defaultMessage="Date" />
        </div>
        <div style={{ flexBasis: '30em' }}>
          <FormattedMessage id="ReviewJob.ContentTable.Comment" defaultMessage="Comment" />
        </div>
        <div style={{ flexBasis: '10em' }}>
          <FormattedMessage id="ReviewJob.ContentTable.Status" defaultMessage="Status" />
        </div>

        <div style={{ flexBasis: '10em' }}>
          <FormattedMessage id="ReviewJob.ContentTable.User" defaultMessage="User" />
        </div>
        <div className="hide_on_mobile" style={{ flexBasis: '12em' }}>
          <FormattedMessage id="ReviewJob.ContentTable.Type" defaultMessage="Type" />
        </div>
        <div className="hide_on_mobile" style={{ flex: '1' }}>
          <FormattedMessage id="ReviewJob.ContentTable.Regarding" defaultMessage="About" />
        </div>
      </div>
      {content}

      <div id="si_colaps" />
    </div>
  );
}
const mapStateToProps = (state) => ({
  reviewJob: state.reviewJob,
  myuser: state.myuser
});
// TileIcon, SafetyInspectionOverviewTile, TaskContentOverviewTile,

export default injectIntl(
  withRouter(
    connect(mapStateToProps)(withTile(TileIcon, ReviewJobContentOverviewTile, TaskContentOverviewTile, collapseCL))
  )
);
