import React, { useContext } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TabsContext } from '../../../../Stylesheet';
import { handlePageLayoutChildObject } from '../../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  wrapperCheckbox: {
    display: 'block',
    marginTop: -1
  },
  templateTitle: {
    fontWeight: '700',
    marginBottom: 0
  },
  templateInput: {
    height: 'Calc(100% - 245px)',
    width: '100%',
    '& .MuiInputBase-multiline.MuiInputBase-marginDense': {
      height: '100%'
    },
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100% !important'
    }
  },
  border: {
    background: '#C6C6C5',
    height: 1,
    width: '100%',
    marginTop: 10
  }
}));

function Front({ cover }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);

  const handleCheckbox = (event) => {
    const val = event.target.checked;
    const { name } = event.target;
    handlePageLayoutChildObject(val, name, 'front', values, setValues);
  };

  const handleContent = (e) => {
    handlePageLayoutChildObject(e.target.value, 'content', 'front', values, setValues);
  };

  return (
    <>
      <span className={cl.wrapperCheckbox}>
        <FormControlLabel
          control={<Checkbox onChange={handleCheckbox} checked={cover.front.visible} name="visible" />}
          label={<span>Use front page</span>}
        />
      </span>
      <p className={cl.templateTitle}>Content:</p>
      <TextField
        className={cl.templateInput}
        value={cover.front.content}
        onChange={handleContent}
        variant="outlined"
        multiline
        minRows={4}
      />
      <span className={cl.wrapperCheckbox}>
        <FormControlLabel
          control={
            <Checkbox
              checked={cover.front.insertBlankPage}
              onChange={handleCheckbox}
              name="insertBlankPage"
              disabled={!cover.front.visible}
            />
          }
          label={<span>Insert blank page</span>}
        />
      </span>
      <div className={cl.border} />
    </>
  );
}

export default Front;
