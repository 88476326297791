import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  scrollWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: '100%'
  },
  scrollContainer: {
    position: 'absolute',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto'
  }
});

export default function Scroll({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.scrollWrapper}>
      <div className={classes.scrollContainer}>{children}</div>
    </div>
  );
}
