import React from 'react';
import { Fade, Modal } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Loader from '@zert-packages/components/shared/Loader/Loader';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

function LoaderModal({ open, children }) {
  const classes = useStyles();
  return (
    <Modal className={classes.modal} open={open}>
      <Fade in={open}>
        <div>
          <Loader>{children}</Loader>
        </div>
      </Fade>
    </Modal>
  );
}

export default LoaderModal;
