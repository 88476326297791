import React from 'react'
import makeStyles from "@mui/styles/makeStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpanderArrowWrapper from "./ExpanderArrowWrapper";
import {handledListItem} from "clm/src/plugins/formatTemplatePlugin/utils/main";

const useStyles = makeStyles((theme) => ({
    rootSideMenu: {
        // height: 'Calc(100% - 20px)',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    wrapperList: {
        // height: 'Calc(100% - 20px)',
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '25px 1fr',
        paddingTop: 12,
        width: '100%',
        paddingRight: 5,
    },
    list: {
        paddingLeft: 0,
        '& li': {
            'list-style-type': 'none',
        },
    },
    arrowUp: {
        color: '#4db1d3',
        cursor: 'pointer',
        transform: 'rotate(90deg)'
    },
    arrowDown: {
        color: '#4db1d3',
        cursor: 'pointer',
        transform: 'rotate(270deg)'
    },
    wrapperEditor: {
        borderRight: '5px solid #E2F2F6',
    },
    editorIcon: {
        position: 'relative',
        display: 'flex',
        gap: 15,
        color: '#4db1d3',
        justifyContent: 'end',
        marginRight: 5,
        '& svg': {
            cursor: 'pointer',
        }
    },
    listLeftMenu: {
        padding: '2px 4px',
        cursor: 'pointer'
    },
    listLeftMenuSelected: {
        padding: '2px 4px',
        color: '#ffffff',
        background: '#4db1d3'
    },
}));


const SideMenu = ({list, indexSelectedList, setSelectedList = () => {}, expander, setExpander, addAction, deleteAction}) => {
    const cl = useStyles();


    return (<div style={{height: 'Calc(100% - 20px)'}}>
        <div className={cl.rootSideMenu}>
            <div className={cl.wrapperList}>
                <div>
                    <ArrowBackIcon
                        onClick={() => handledListItem(indexSelectedList, list, setSelectedList, false, true)}
                        className={cl.arrowUp}
                    />
                    <ArrowBackIcon
                        onClick={() => handledListItem(indexSelectedList, list, setSelectedList)}
                        className={cl.arrowDown}
                    />
                </div>
                <ul className={cl.list}>
                    {list.map((el, i) => <li
                        key={i}
                        className={indexSelectedList === i ? cl.listLeftMenuSelected : cl.listLeftMenu}
                        onClick={() => setSelectedList(i)}
                    >
                        {el.name}
                    </li>)}
                </ul>
            </div>
            <ExpanderArrowWrapper expander={expander} setExpander={setExpander}/>
        </div>
        <div className={cl.wrapperEditor}>
            <div className={cl.editorIcon}>
                {addAction && <AddIcon onClick={addAction}/>}
                {/*<CreateIcon/>*/}
                {deleteAction && <DeleteIcon onClick={deleteAction}/>}
            </div>
        </div>
    </div>)
}

export default SideMenu