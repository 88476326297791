import Dialog from '@mui/material/Dialog';
import React, {useState} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import EntryView from './EntryView';
import makeStyles from "@mui/styles/makeStyles";
import {initEntry} from "./data/initEntryData";
import {adobeName} from "./utils/utils";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: 11,
        background: '#E2F2F6'
    },
    wrapperButton: {
        display: 'flex',
        justifyContent: 'end',
        paddingRight: 20,
        marginTop: 20
    },
    label: {
        fontWeight: '700'
    },
    button: {
        color: '#ffffff',
        background: '#4db1d3',
        marginLeft: 10,
        minWidth: 80,
        border: '1px solid #4db1d3',
        outline: 'none !important',
        cursor: 'pointer',
        '&:hover': {
            background: 'none',
            color: '#212529',
            border: '1px solid #4db1d3'
        },
        '&:focus': {
            backgroundColor: '#4db1d3'
        }
    }
}))



function EntryDialog({handleClose, actionState, entry, setEntry, setIndex}) {
    const cl = useStyles();
    const [selectedEntry, setSelectedEntry] = useState(actionState.isRemove ? entry : initEntry)
    const disable =  !actionState.isRemove && adobeName(selectedEntry).length === 0

    const successHandler = () => {
        if(disable){
            return
        }
        handleClose();
        if (actionState.isRemove) {
            return setEntry(pre => ({
                ...pre,
                entries: pre.entries.filter((el, i) => i !== pre.selectedEntries),
                selectedEntries: pre.selectedEntries - 1
            }))
        }
        setEntry(pre => ({
            ...pre,
            entries: [...pre.entries, selectedEntry],
            selectedEntries: pre.entries.length
        }))
    };


    return (
        <Dialog open onClose={handleClose} fullWidth classes={{  paperScrollPaper: cl.root }}>
            <DialogTitle style={{paddingLeft: 0,}}>{actionState.isRemove ? `Remove entry?` : 'Add new entry?'}</DialogTitle>
            {actionState.isRemove ? <div className={cl.label}>{adobeName(selectedEntry)}</div> : <EntryView entry={selectedEntry} setEntry={setSelectedEntry}/>}

            <div className={cl.wrapperButton}>
                <Button className={cl.button} onClick={successHandler} disabled={disable}>
                    {actionState.isRemove ? 'REMOVE' : 'ADD'}
                </Button>
                <Button className={cl.button} onClick={handleClose}>
                    CANCEL
                </Button>
            </div>
        </Dialog>
    );
}

export default EntryDialog;
