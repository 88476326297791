import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Dialog } from "@mui/material";
import { useSelector } from "react-redux";
import getTranslation from "@zert-packages/utils/getTranslation.old";
import DropMenu from "../../../DropMenu";
import { storeBlockApi } from "../API";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 262,
    padding: "5px 10px",
    paddingBottom: 10,
    background: "#E2F2F6",
    minHeight: 168
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "flex-end",
    "& svg": {
      cursor: "pointer"
    }
  },
  wrapperList: {
    marginTop: 5,
    border: "1px solid lightgray",
    minHeight: 100,
    padding: 5
  },
  listItem: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#CECECE"
    }
  },
  listItemActive: {
    background: "blue",
    color: "#ffffff"
  },
  titleProperties: {
    margin: '5px 0px',
  }
}));

const TableFormatDialog = ({ block, setBlock, parentItemCreate }) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const { formatTemplate } = useSelector((state) => state);
  const blockItem = block.blockItem;
  const [format, setFormat] = useState({
    selectedFormat: null,
    blockItem: blockItem
  });
  const selectedIdentifier = format.selectedFormat?.identifier;
  const properties =  format.selectedFormat?.settings?.properties[0]
  const getFormat = blockItem?.elementInfo?.properties["zert:Format"];


  const handleClose = async () => {
    setOpen(false);
    if(getFormat === selectedIdentifier){
      return
    }
    setBlock(pre => ({ ...pre, blockItem:format.blockItem}));
    const items = parentItemCreate.items.reduce((acu, cur) => {
      if (cur.identifier === format.blockItem.identifier) {
        return [...acu, format.blockItem];
      }
      return [...acu, cur];
    }, [])
    const createItemData = {
      name: parentItemCreate.name,
      targetLocale: parentItemCreate.targetLocale,
      format: parentItemCreate.format,
      items
    }
     await storeBlockApi(parentItemCreate.versionId, parentItemCreate.targetLocale.language, createItemData);
  }

  //.elementInfo.properties['zert:Format']

  const findSelectedFormat = () => {

    if (!getFormat) {
      return;
    }
    const selectedFormat = formatTemplate.tableFormats.find(el => el.identifier === getFormat);
    if (!selectedFormat) {
      return;
    }
    setFormat(pre => ({ ...pre, selectedFormat }));
  };


  useEffect(() => {
    if (formatTemplate?.tableFormats) {
      findSelectedFormat();
      // setFormat(pre => ({...pre, selectedFormat: formatTemplate?.tableFormats[0]}))
    }
  }, []);

  const handleSelected = (el, ) => {
    if (selectedIdentifier === el.identifier) {
      return
    }

    setFormat(pre => ({ ...pre,
      selectedFormat: el,
      blockItem: {
      ...pre.blockItem,
        elementInfo: {
        ...pre.blockItem.elementInfo,
          properties: {
            ...pre.blockItem.elementInfo.properties,
            ["zert:Format"] :  el.identifier,
          }
        }
      }
    }));
  };

  const handleProperty = (val) => {

  }
   // if

    return (<Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paperScrollPaper: cl.root
      }}
    >
      <div className={cl.wrapperList}>
        {formatTemplate?.tableFormats.map((el, i) =>(
          <div key={i +  el.identifier}
               className={selectedIdentifier === el.identifier ? cl.listItemActive : cl.listItem}
               onClick={() => handleSelected(el,)}
          >
            <span key={i}>{getTranslation(el.label)}</span>
          </div>))}
      </div>
      <div className={cl.titleProperties}>{properties && getTranslation(properties.labelObject)}</div>

      {properties && <DropMenu
        dropItem={properties?.items.map(it => ({ ...it, name: getTranslation(it.labelObject),}))}
        setDropItem={handleProperty}
        />
      }
      {/*{format.selectedFormat?.settings.properties.map((el, i) => <div key={i}>*/}
      {/*  <DropMenu*/}
      {/*    selected={template[el?.identifier]}*/}
      {/*    dropItem={el?.items.map(it => ({ ...it, name: getTranslation(it.labelObject), index: i }))}*/}
      {/*    name={el?.identifier}*/}
      {/*    setDropItem={handleProperty}*/}
      {/*  />*/}
      {/*</div>)}}*/}

    </Dialog>);
};

export default TableFormatDialog;