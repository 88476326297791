import React, { useContext, useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TabsContext } from "../FormatTemplatePlugin";
import { adobeName } from "../utils/main";


const useStyles = makeStyles((theme) => ({
  wrapperInput: {
    display: "grid",
    gridTemplateColumns: "100px 1fr",
    marginBottom: 10,
    gridColumnGap: 10
  },
  wrapperLabel: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr"
  },
  label: {
    fontWeight: 900
  },
  checkboxLabel: {
    fontSize: 15,
    color: "#212529"
  }
}));

const arrayFontStyleData = [
  { name: "BOLD", checked: false },
  { name: "SUPERSCRIPT", checked: false },
  { name: "ITALIC", checked: false },
  { name: "SUBSCRIPT", checked: false },
  { name: "UNDERLINED", checked: false },
  { name: "STRIKETHROUGH", checked: false }
];


const FontStyle = ({disable}) => {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const checked = values?.templateList?.typeSettings?.fontStyle || [];



  const saveFontStyle = (res) => {
    setValues((prev) => ({
      ...prev,
      templateList: { ...prev.templateList, typeSettings: {
          ...prev.templateList.typeSettings,
          fontStyle: res
        }
      }
    }));
  }

  const handleCheckbox = (event, el) => {
    const val = event.target.checked;
    if (!val) {
      const res = checked.filter(it => it !== el.name)
      return saveFontStyle(res)
    }
    saveFontStyle([...checked, el.name])
  };

  const findChecked = (el) => {
    if (!checked || checked.length === 0) {
      return false;
    }
    const res = checked.find(it => it === el.name);
    return res ? true : false;
  };


  return (<div className={cl.wrapperInput}>
    <div className={cl.label}>Font Style:</div>
    <div className={cl.wrapperLabel}>
      {arrayFontStyleData.map((el, i) => (
        <div key={i}>
          <FormControlLabel
            disabled={disable}
            control={<Checkbox onChange={(e) =>
              handleCheckbox(e, el)} name={el.name} checked={findChecked(el)} />}
            label={<span className={cl.checkboxLabel}>{adobeName(el.name)}</span>}
          />
        </div>
      ))}
    </div>
  </div>);

};

export default FontStyle;