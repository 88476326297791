import getParameterAsInt from '@zert-packages/utils/getParameterAsInt';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TabsWithVersionController from '@zert-packages/components/common/TabsWithVersionController';
import useVersionManager from '@zert-packages/utils/useVersionManager';
import { getVersionInfo } from '@zert-packages/actions/coreReducers';
import get from 'lodash/get';
import { fetchTermReferences, fetchTerms } from './servicesMiddlewares';
import { resetTermsAction, turnOffCLMLoader, turnOnCLMLoader } from '../TranslationJobPlugin/tjReducers';
import TermsList from './TermsList';
import TermsListWithReferences from './TermsListWithReferences';
import { addTerm } from './API';
import { generatePreviewReport } from '../PreviewPlugin/servicesMiddlewares';
import { generateTermDBExport } from './serviceMiddleware';

export const formStyle = (theme) =>
  createStyles({
    mainContainer: {
      width: '100%',
      height: '100%'
    },
    rootBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '5px'
    },
    bodyContainer: {
      padding: '8px',
      height: 'calc(100% - 48px)',
      width: '100%',
      minHeight: '300px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',

      '@media (max-width: 900px)': {
        flexDirection: 'column'
      }
    },
    AppBar: {
      display: 'grid',
      gridTemplateColumns: '1fr auto auto',
      zIndex: '10',
      overflow: 'hidden',
      paddingRight: 8
    },
    formControl: {
      margin: theme.spacing(1),
      width: '100%'
    }
  });
const useStyles = makeStyles(formStyle);

function TermDBView(props) {
  const cl = useStyles();
  const CLM = useSelector((state) => state.CLM);
  const isLoading = useSelector((state) => state.CLM.isLoading);
  const {
    match: { params }
  } = props;
  const versionId = getParameterAsInt(params.versionId);
  const dispatch = useDispatch();
  const [terms, setTerms] = useState(null);
  const [addNew, setAddNew] = useState(null);
  const [scrollToItem, setScrollToItem] = useState(null);
  const [versionManager, versionInfo] = useVersionManager();
  const routeVersionId = get(props, 'match.params.versionId', null);

  useEffect(() => {
    if (routeVersionId === null) return;
    dispatch(resetTermsAction());
    setTerms(null);

    dispatch(getVersionInfo(routeVersionId));

    dispatch(turnOnCLMLoader());

    dispatch(fetchTerms(versionId));
    dispatch(fetchTermReferences());
  }, [routeVersionId, versionId]);

  useEffect(() => {
    if (CLM && CLM.termDB && !terms) {
      const arr = Object.keys(CLM.termDB)
        .map((item) => CLM.termDB[item])
        .sort((a, b) => a.name.localeCompare(b.name));

      setTerms(arr);
      if (arr && arr.length > 0) {
        setScrollToItem(arr[0].id);
      }
    } else if (CLM && CLM.termDB === null && terms) {
      setTerms(null);
    }
  }, [terms, CLM]);

  useEffect(() => {
    if (addNew) {
      setScrollToItem(addNew.id);
      setAddNew(null);
      dispatch(turnOffCLMLoader());
    }
  }, [addNew]);

  const handleAddNewTerm = async (e, value, id) => {
    e.stopPropagation();
    e.preventDefault();

    if (value && value.length > 0) {
      dispatch(turnOnCLMLoader());
      try {
        const term = await addTerm(versionId, value);
        setAddNew(term.right);
        setTerms((previos) => {
          return [...previos, term.right].sort((a, b) => a.name.localeCompare(b.name));
        });
        //  dispatch(turnOffCLMLoader());
      } catch (e) {}
    } else if (id) {
      try {
        setTerms((previos) => {
          return previos.filter((item) => item.id != id).sort((a, b) => a.name.localeCompare(b.name));
        });
        dispatch(turnOffCLMLoader());
      } catch (e) {}
    }

    //   dispatch(loadTermDb(null));
    //   dispatch(fetchTerms(versionId));
    //  setTerms(null);
  };

  const handleScrollToItem = (e, item) => {
    if (e != null) {
      e.stopPropagation();
      e.preventDefault();
    }
    setScrollToItem(item.id);
  };

  return (
    <div className={cl.mainContainer}>
      <TabsWithVersionController
        canCreateVersion={versionManager.canCreateNewVersion}
        canSaveVersion={false} // versionManager.canSaveVersion}
        canApproveVersion={versionManager.canApproveVersion}
        onCreateNewVersion={versionManager.createNewVersion}
        onApproveVersion={versionManager.approveVersion}
        onSaveVersion={() => false}
        canPreview
        onPreview={() => {
          dispatch(generatePreviewReport(versionInfo.info, -1));
        }}
        canExport
        onExport={() => {
          dispatch(generateTermDBExport(versionId));
        }}
        tabs={[]}
      />

      <div className={cl.bodyContainer}>
        <TermsList
          termsList={terms}
          handleAddNewTerm={handleAddNewTerm}
          handleScrollToItem={handleScrollToItem}
          versionId={versionId}
          versionManager={versionManager}
          scrollToItem={scrollToItem}
        />
        <TermsListWithReferences termsData={{ terms, versionId, scrollToItem, handleScrollToItem, versionManager }} />
      </div>
    </div>
  );
}

export default injectIntl(TermDBView);
