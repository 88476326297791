import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {Button, Checkbox, Dialog, FormControlLabel} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextFieldItem from "../../../TextFieldItem";
import handleValueState from "@zert-packages/utils/handleValueState";
import {findDisabled} from "../../helpers/main";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 500,
        padding: 11,
        background: "#E2F2F6",
        display: 'flex',
        justifyContent: 'space-between',
    },
    wrapperTitle: {
        marginBottom: 10,
        display: "flex",
        justifyContent: "space-between",
        textTransform: "uppercase",
        "& svg": {
            cursor: "pointer"
        }
    },
    title: {
        fontSize: "1.8rem",
        fontWeight: 700,
        textTransform: "capitalize"
    },
    container: {
        marginTop: 15,
        marginBottom: 10,
    },
    row: {
        display: 'flex',
        gap: 10,
    },
    label: {
        fontWeight: 700,
    },
    wrapperCheckbox: {
        display: 'grid',
        gridRowGap: 5,
        width: '100%',
        '& label': {
            marginBottom: 0,
        },
        '& .MuiCheckbox-root': {
            padding: 5,
        },
    },
    wrapperButton: {
        display: "flex",
        marginTop: 30,
        justifyContent: "end",
        gap: 10
    },
    button: {
        color: "#ffffff",
        background: "#4db1d3",
        minWidth: 80,
        border: "1px solid #4db1d3",
        outline: "none !important",
        cursor: "pointer",
        "&:hover": {
            background: "none",
            color: "#212529",
            border: "1px solid #4db1d3"
        },
        "&:focus": {
            backgroundColor: "#4db1d3"
        }
    }
}))

const CreateDuplicateDialog = ({values, handleSave}) => {
    const cl = useStyles();
    const [open, setOpen] = useState(true);
    const [duplicate, setDuplicate] = useState({
        item: values.treeItem,
        copyMetadata: false,
        replace: false,
    })



    const handleClose = async () => {
        setOpen(false);
    }

    const save = () => {
        handleClose()
        if(handleSave){
            handleSave(duplicate.item.elementInfo.name)
        }
    }

    const findDisable = () => {
        return duplicate.item.elementInfo.name === values.treeItem.elementInfo.name;
    }

    const handleName = (val, name) => {
        const res = {
            ...duplicate.item,
            elementInfo: {...duplicate.item.elementInfo, name: val}
        }
        handleValueState(res, name, setDuplicate)
    }

    const handleCheckbox = (e) => {
        const val = e.target.checked
        const name = e.target.name
        handleValueState(val, name, setDuplicate)
    }


    return (<Dialog
        open={open}
        onClose={handleClose}
        classes={{paperScrollPaper: cl.root}}
    >
        <div className={cl.wrapperTitle}>
            <span className={cl.title}>Create Duplicate </span>
            <CloseIcon onClick={handleClose}/>
        </div>
        <div className={cl.container}>
            <div className={cl.label}>
                Are you sure you want to duplicate the element? <br/>
                Reusing elements may be made more difficult, if you duplicate
            </div>
        </div>
        <div className={cl.row}>
            <div className={cl.label}>
                Name:
            </div>
            <div className={cl.wrapperCheckbox}>
                <TextFieldItem
                    values={duplicate.item.elementInfo.name}
                    name={"item"}
                    setValues={handleName}
                    autoFocus={true}
                />
                <FormControlLabel
                    control={<Checkbox
                        checked={duplicate.copyMetadata}
                        onChange={handleCheckbox}
                        name={'copyMetadata'}
                    />}
                    label="Copy metadata"
                />
                <FormControlLabel
                    control={<Checkbox
                        checked={duplicate.replace}
                        onChange={handleCheckbox}
                        name={'replace'}
                    />}
                    label="Replace existing element"
                />
            </div>
        </div>
        <div className={cl.wrapperButton}>
            <Button className={cl.button} disabled={findDisable()} onClick={save}>
                Ok
            </Button>
            <Button className={cl.button} onClick={handleClose}>
                Cancel
            </Button>
        </div>
    </Dialog>)
}

export default CreateDuplicateDialog
