import React, { Fragment, useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { DeleteForever, PlusOne } from '@mui/icons-material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%'
  }
}));

export function MetadataInput({ list, identifier, text, handleUpdate }) {
  const [metadata, setMetaData] = useState(list);
  const classes = useStyles();
  const handleChange = (id) => (event) => {
    const { value } = event.target;
    //  debugger;
    setMetaData((prevState) =>
      prevState.reduce((acu, cur, index) => {
        return index != id ? [...acu, cur] : [...acu, value];
      }, [])
    );
  };

  useEffect(() => {
    if (handleUpdate) {
      handleUpdate(metadata);
    }
  }, [metadata]);

  const handleDelete = (id) => (event) => {
    setMetaData((prevState) => [...prevState.filter((value, index) => index != id)]);
  };

  const handlePlus = (id) => (event) => {
    setMetaData((prevState) =>
      prevState.reduce((acu, cur, index) => {
        return index != id ? [...acu, cur] : [...acu, cur, cur];
      }, [])
    );
  };
  return <>
    {metadata.map((value, index) => (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="layout">{text} </InputLabel>
        <Input
          fullWidth
          id={`MetadataInput_${identifier}_${index}`}
          onChange={handleChange(index)}
          placeholder="Text"
          className={classes.formControl}
          value={value}
          variant="outlined"
          endAdornment={
            index == metadata.length - 1 ? (
              <InputAdornment position="start">
                <IconButton
                  aria-label="toggle layout details"
                  onClick={handlePlus(index)}
                  size="large">
                  <PlusOne />
                </IconButton>
              </InputAdornment>
            ) : (
              <InputAdornment position="start">
                <IconButton
                  aria-label="toggle layout details"
                  onClick={handleDelete(index)}
                  size="large">
                  <DeleteForever />
                </IconButton>
              </InputAdornment>
            )
          }
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
    ))}
  </>;
}
