import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { loadSafetyInspection } from '@zert-packages/actions/checklistActions';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import ChecklistsPage from 'rm/src/Plugins/SafetyInspectionPlugin/ChecklistsPage';
import withTile from '../shared/withTile';
import { TileIcon } from './AbstractTile';
import TaskContentOverviewTile from '../shared/TaskContentOverviewTile';

export const expandSafetyInspection = (element, dispatch) => {
  dispatch(loadSafetyInspection(element.versionId, false));
};

export function SafetyInspectionOverviewTile(props) {
  const safetyinspection = useSelector((state) => state.safetyinspection);

  const [checklists, setChecklists] = useState(null);

  useEffect(() => {
    if (
      !checklists &&
      safetyinspection &&
      safetyinspection.checkLists &&
      props.element.versionId == safetyinspection.versionId
    ) {
      setChecklists(safetyinspection.checkLists);
    }
  }, [safetyinspection]);

  if (!checklists && !safetyinspection) {
    return <Loader />;
  }

  return <ChecklistsPage checklists={checklists} />;
}

export default withTile(TileIcon, SafetyInspectionOverviewTile, TaskContentOverviewTile, expandSafetyInspection);
