import React, { Fragment, useEffect } from 'react';
import { TextField } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import CheckboxWithMemo from '@zert-packages/components/common/CheckboxWithMemo';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { downloadAllLayouts, downloadLayoutFilters } from '../../PreviewPlugin/servicesMiddlewares';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190
  }
}));

function PublishingSettingSummaryList({
  intl,
  multipleSelectedLanguage,
  fallbackLocale,
  selectedLayoutFilter,
  selectedLayout,
  selectedElementToPreview,
  languageSeparated
}) {
  const { activeLocales } = useSelector((state) => state);
  const { allLayouts, layoutFilters } = useSelector((state) => state.PREVIEW);
  const dispatch = useDispatch();

  const getLocales = () => {
    return activeLocales
      .filter((locale) => {
        console.log(`${locale.language}_${locale.country}`);
        console.log(multipleSelectedLanguage);
        return multipleSelectedLanguage.indexOf(`${locale.language}_${locale.country}`) > -1;
      })
      .map((locale) => locale.displayName)
      .join(', ');
  };

  useEffect(() => {
    dispatch(downloadAllLayouts());
    dispatch(downloadLayoutFilters());
  }, []);
  const classes = useStyles();

  const getFallbackLocales = () => {
    return activeLocales
      .filter((locale) => fallbackLocale == `${locale.language}_${locale.country}`)
      .map((locale) => locale.displayName)
      .join(', ');
  };
  const getLayoutsFilters = () => {
    const selectedLayoutFilterElement = layoutFilters.find((value) => selectedLayoutFilter == value.uuid);

    if ((!selectedLayoutFilterElement || selectedLayoutFilterElement.uuid == '-1') && intl) {
      return intl.formatMessage({
        id: 'defaultLayoutFilter',
        defaultMessage: 'Default layout filter'
      });
    }

    return selectedLayoutFilterElement.name;
  };

  const getLayouts = () => {
    const selectedLayoutElement = allLayouts.find(
      (value) => selectedLayout == value.versionId || selectedLayout == value.id
    );
    if (!selectedLayoutElement) {
      return '';
    }
    return `${selectedLayoutElement.name}(${selectedLayoutElement.label})`;
  };

  return (
    <form
      style={{
        display: 'flex',
        justifyContent: 'stretch',
        flexFlow: 'column nowrap'
        // backgroundColor: "rgb(224, 224, 224, 1)" //"rgb(220, 239, 246)"
      }}
    >
      <TextField
        id="version"
        label={<FormattedMessage id="SummaryPage.Version" defaultMessage="Version" />}
        className={classes.formControl}
        value={`${selectedElementToPreview.name}(${selectedElementToPreview.label})`}
        type="text"
        variant="outlined"
      />

      <TextField
        id="locales"
        label={<FormattedMessage id="SummaryPage.Locale" defaultMessage="Locales included in publication" />}
        className={classes.formControl}
        multiline
        value={getLocales()}
        type="text"
        variant="outlined"
      />
      <TextField
        id="locales"
        label={<FormattedMessage id="SummaryPage.FallbackLocale" defaultMessage="Fallback Locale" />}
        className={classes.formControl}
        value={getFallbackLocales()}
        type="text"
        variant="outlined"
      />

      <CheckboxWithMemo
        isChecked={languageSeparated}
        disabled
        label={
          <FormattedMessage
            id="PreviewWizardDialog.SummaryPage.LanguageSeparated"
            defaultMessage="Include all languages in the same publication. This requires that the publication contains elements that are language separated."
          />
        }
      />
      <TextField
        id="layout"
        label={<FormattedMessage id="PreviewWizardDialog.Summary.Layout " defaultMessage="Layout" />}
        className={classes.formControl}
        value={getLayouts()}
        type="text"
        variant="outlined"
      />
      <TextField
        id="layouFilter"
        label={<FormattedMessage id="PreviewWizardDialog.Summary.Layout.Filter" defaultMessage="Layout Filter" />}
        className={classes.formControl}
        value={getLayoutsFilters()}
        type="text"
        variant="outlined"
      />
    </form>
  );
}

export default injectIntl(PublishingSettingSummaryList);
