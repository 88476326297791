import React from "react";
import { makeStyles } from "@mui/styles";
import SkeletonLoading from "../SkeletonLoading";
import Button from "@mui/material/Button";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogColors from "@zert-packages/components/dialog/DialogColors";
import DialogColorRow from "../dialog/DialogColorRow";
import DialogHandleColumWidth from "../dialog/DialogHandleColumWidth";
import { findWidth } from "./helpers/findWidth";


const useStyles = makeStyles((theme) => ({
  rootHeader: {
    backgroundColor: "#F4F8FA",
    marginBottom: 10,
    position: 'sticky',
    top: 27,
    zIndex: 10,
  },
  bottomHeader: {
    fontSize: "10.8px",
    paddingTop: "3px",
    paddingBottom: "3px",
    outline: "none !important",
    textTransform: "inherit",
    "&:hover": {
      backgroundColor: "#15A3EF",
      color: "#ffffff"
    }

  }
}));

const TableHeaderMenu = ({ editor, disableButton, refProps, setTableState, tableState }) => {
  const cl = useStyles();




  const handleWid = (val) => {
    const { width, widthDefined, activeCellIndex } = val;
    const result = findWidth(+width, widthDefined, tableState.interest, activeCellIndex);
    setTableState(pre => ({ ...pre, interest: result.child }));
    const rows = refProps.current && refProps.current.querySelectorAll("tr");



    rows.forEach((row) => {
       row.querySelectorAll("td").forEach((td, i) => { // проходимо по кожному td
        const width = result.child[i].width;
        if (width === 0 && i !== activeCellIndex) {
          return;
        }
         td.style.width = width + "%"
      });
    });


    const resultValue = result.width === 1 ? '44px' : result.width + "%"
    editor.commands.setCellAttribute("style", resultValue);

  };

  const handleWidthCell = () => {
    const activeCellIndex =refProps.current.getElementsByClassName("active")[0].getAttribute("idx").split("-")[1];


    renderInExceptionRoot(DialogHandleColumWidth, {
      tableState: { ...tableState, activeCellIndex: +activeCellIndex },
      set: handleWid
    });
  };

  const handleColorTableRow = (val) => {
    if (!val) {
      return;
    }
    const activeCell = refProps.current.getElementsByClassName("active")[0];
    const findCell = activeCell.closest("tr");
    findCell.style.backgroundColor = val;
  };

  const handleColorTableCell = (val) => {
    editor.commands.setCellAttribute("backgroundColor", val);
  };


  const handleColorCell = () => {
    renderInExceptionRoot(DialogColorRow, {
      title: 'Cells properties',
      setColor: handleColorTableCell
    });
  };

  const handleColorRow = () => {
    renderInExceptionRoot(DialogColorRow, {
      title: 'Rows properties',
      row: true,
      setColor: handleColorTableRow,
    });
  };

  const mergeCells = () => {
    const activeCell = refProps.current.getElementsByClassName("selectedCell");
    editor.chain().focus().mergeCells().insertContent(activeCell[0].innerHTML).run();
  };


  if (!editor) {
    return <SkeletonLoading />;
  }

  return (<div className={cl.rootHeader}>
    <Button className={cl.bottomHeader} onClick={() => editor.commands.undo()} disabled={!editor.can().undo()}>
      go back
    </Button>
    <Button className={cl.bottomHeader} onClick={() => editor.commands.redo()} disabled={!editor.can().redo()}>
      go next
    </Button>
    <Button className={cl.bottomHeader} onClick={() => mergeCells()} disabled={!editor.can().mergeCells()}>
      Merge cells
    </Button>
    <Button className={cl.bottomHeader} onClick={() => editor.chain().focus().splitCell().run()}
            disabled={!editor.can().splitCell()}>
      Split cell
    </Button>
    <Button className={cl.bottomHeader} onClick={() => handleWidthCell()} disabled={disableButton}>
      Change column width
    </Button>
    <Button className={cl.bottomHeader} onClick={() => handleColorRow()} disabled={disableButton}>
      Row properties
    </Button>
    <Button className={cl.bottomHeader} onClick={() => handleColorCell()} disabled={disableButton}>
      Cells properties
    </Button>
  </div>);
};

export default TableHeaderMenu;