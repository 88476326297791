import React from 'react';
import TreeSelect from 'rc-tree-select';
import { FormattedMessage } from 'react-intl';
import './TreeSelector.css';

function isLeaf(value) {
  if (!value) {
    return false;
  }
  let queues = [this.state.gData];
  while (queues.length) {
    // BFS
    const item = queues.shift();
    if (item.value === value) {
      if (!item.children) {
        return true;
      }
      return false;
    }
    if (item.children) {
      queues = queues.concat(item.children);
    }
  }
  return false;
}

function findPath(value, data) {
  const sel = [];

  function loop(selected, children) {
    for (let i = 0; i < children.length; i++) {
      const item = children[i];
      if (selected === item.value) {
        sel.push(item);
        return;
      }
      if (item.children) {
        loop(selected, item.children, item);
        if (sel.length) {
          sel.push(item);
          return;
        }
      }
    }
  }

  loop(value, data);
  return sel;
}

export default class TreeSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tsOpen: false,
      visible: false,
      searchValue: '',
      value: '',
      lv: {
        value: '',
        label: ''
      },
      multipleValue: this.props.multipleValue,
      simpleSearchValue: '',
      simpleTreeData: [],
      treeDataSimpleMode: {},
      gData: this.props.gData
    };
  }

  componentDidMount() {
    const multiValue = [];
    this.props.multipleValue.map((value) => {
      const path = findPath(value.value, this.state.gData);
      const pathLabel = path
        .map((i) => i.label)
        .reverse()
        .join(' > ');
      let catalogVersionId = -1;
      let uuid = null;
      if (path.length) {
        catalogVersionId = path[0].catalogVersionId;
        uuid = path[0].key;
      }
      multiValue.push({
        value: value.value,
        label: pathLabel,
        uuid,
        catalogVersionId
      });
    });
    this.setState({ multipleValue: multiValue });
  }

  onClick = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  onChange = (value, ...rest) => {
    console.log('onChange', value, ...rest);
    this.setState({ value });
  };

  onChangeChildren = (...args) => {
    console.log('onChangeChildren', ...args);
    const value = args[0];
    const pre = value ? this.state.value : undefined;
    this.setState({ value: isLeaf(value) ? value : pre });
  };

  onMultipleChange = (values) => {
    const multiValue = [];
    values.map((value) => {
      const path = findPath(value.value, this.state.gData);
      const pathLabel = path
        .map((i) => i.label)
        .reverse()
        .join(' > ');
      let catalogVersionId = -1;
      let uuid = null;
      if (path.length) {
        uuid = path[0].key;
        catalogVersionId = path[0].catalogVersionId;
      }
      multiValue.push({
        value: value.value,
        label: pathLabel,
        uuid,
        catalogVersionId
      });
    });
    this.setState({ multipleValue: multiValue });
    this.props.assignChagedValues(multiValue);
  };

  onSelect = () => {
    // use onChange instead
  };

  onDropdownVisibleChange = (visible, info) => {
    console.log(visible, this.state.value, info);
    if (Array.isArray(this.state.value) && this.state.value.length > 1 && this.state.value.length < 3) {
      window.alert('please select more than two item or less than one item.');
      return false;
    }
    return true;
  };

  filterTreeNode = (input, child) => {
    return String(child.props.title).indexOf(input) === 0;
  };

  render() {
    return (
      <TreeSelect
        style={{ width: '100%' }}
        transitionName="rc-tree-select-dropdown-slide-up"
        choiceTransitionName="rc-tree-select-selection__choice-zoom"
        dropdownStyle={{
          maxHeight: 400,
          overflow: 'auto',
          backgroundColor: '#f4f8fa',
          zIndex: 1600
        }}
        placeholder={
          <i>
            <FormattedMessage id="catalogtree.nodeselector" defaultMessage="Select placement" />
          </i>
        }
        searchPlaceholder="please search"
        multiple
        showSearch
        allowClear
        treeLine
        value={this.state.multipleValue}
        labelInValue
        treeData={this.props.gData}
        treeNodeFilterProp="title"
        filterTreeNode={false}
        onChange={this.onMultipleChange}
        // onChange={this.onChangeLV}
        onSelect={this.onSelect}
      />
    );
  }
}
