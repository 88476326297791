import React from 'react';
import ActionableTableStable from '@zert-packages/components/shared/ActionableTable/ActionableTable.stable';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import GroupIcon from '@mui/icons-material/Group';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { FormattedMessage } from 'react-intl';
import dialogCustom from '@zert-packages/components/common/dialogCustom';
import DeleteConfirmDialog from '@zert-packages/components/shared/DeleteConfirmDialog';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import UserSuggestBox from '@zert-packages/components/shared/UserSuggestBoxNew/UserSuggestBox';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import SendInvitationDialog from './SendInvintationDialog';
import AddProjectMemberDialog from './AddProjectMemberDialog';

const columns = [
  {
    identifier: 'name',
    label: <FormattedMessage id="RMP.Participants.TableHeadLabel.Name" defaultMessage="Name" />,
    sortable: true
  },
  {
    identifier: 'login',
    label: <FormattedMessage id="RMP.Participants.TableHeadLabel.Login" defaultMessage="Login" />,
    sortable: true
  },
  {
    identifier: 'email',
    label: <FormattedMessage id="RMP.Participants.TableHeadLabel.Email" defaultMessage="Email" />,
    sortable: true
  },
  {
    identifier: 'function',
    label: <FormattedMessage id="RMP.Participants.TableHeadLabel.Function" defaultMessage="Function" />,
    sortable: true
  },
  {
    identifier: 'active',
    label: <FormattedMessage id="RMP.Participants.TableHeadLabel.Active" defaultMessage="Active" />,
    style: {
      width: '30px',
      textAlign: 'center'
    }
  }
];

const makeRows = (list) =>
  list.map((item) => ({
    id: item.username,
    name: item.name,
    login: item.username,
    email: item.email,
    function: item.function,
    active: item.active ? (
      <RadioButtonCheckedIcon htmlColor="#0f305b" />
    ) : (
      <RadioButtonUncheckedIcon htmlColor="#0f305b" />
    )
  }));

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateRows: '1fr 56px',
    gridGap: 10,
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  taskAdministrationContainer: {
    borderTop: '1px solid rgba(0, 0, 0, 0.1)'
  }
});

function MembersTable({
  roundRobin,
  onChangeRoundRobin,
  disabledDeletingYourself = false,
  members = [],
  addMember,
  updateMember,
  removeMember,
  withInvitation,
  disabledActions = false
}) {
  const cl = useStyles();
  const getMember = (userName) => members.find((mem) => mem.username === userName);
  const myUser = useSelector((state) => state.myuser);
  const isDeletingYourself = (userName) => disabledDeletingYourself && userName === _.get(myUser, 'userName', null);
  const onAdd = () => {
    dialogCustom(AddProjectMemberDialog, {
      isAdding: true,
      onConfirm: (member) => {
        if (members.find((m) => m.username === member.username)) return;
        addMember(member);
      }
    });
  };
  const onEdit = (userName) => {
    dialogCustom(AddProjectMemberDialog, {
      state: getMember(userName),
      isAdding: false,
      onConfirm: updateMember
    });
  };
  const handleSendInvitation = () => {
    dialogCustom(SendInvitationDialog, {
      listOfUsers: members
    });
  };
  const handleRemoveMember = (userName) => {
    dialogCustom(DeleteConfirmDialog, { onDelete: () => removeMember(userName) });
  };
  const actions = [
    {
      icon: AddIcon,
      disabled: () => disabledActions,
      onClick: onAdd
    },
    {
      icon: EditIcon,
      disabled: (userName) => !userName || disabledActions,
      onClick: onEdit
    },
    {
      icon: RemoveIcon,
      disabled: (userName) => !userName || disabledActions || isDeletingYourself(userName),
      onClick: handleRemoveMember
    }
  ];

  if (withInvitation) {
    actions.push({
      icon: GroupIcon,
      disabled: () => disabledActions,
      onClick: handleSendInvitation
    });
  }

  if (!onChangeRoundRobin) {
    return <ActionableTableStable columns={columns} rows={makeRows(members)} actions={actions} />;
  }

  return (
    <div className={cl.root}>
      <ActionableTableStable columns={columns} rows={makeRows(members)} actions={actions} />
      <div className={cl.taskAdministrationContainer}>
        <UserSuggestBox
          disabled={disabledActions}
          onChange={onChangeRoundRobin}
          getUsers={getUsersQuery}
          getUserId={(user) => user.username}
          getUserLabel={(user) => user.name}
          initialValue={roundRobin}
          label={
            <FormattedMessage id="RMP.MembersTable.TaskAdministration.Title" defaultMessage="Task administrator" />
          }
        />
      </div>
    </div>
  );
}

export default MembersTable;
