import React, { memo } from 'react';

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import ExpansionWrapperCriteria from '../common/ExpansionWrapperCriteria';

const StatusSearchCriteria = memo(
  ({ foundCriteria, updateCriteria, onChangeExpansionOfCriteria, classNameOfCriteria, isEverythingDisabled }) => {
    const onChangeStatusValue = (event) => {
      const newValues = foundCriteria.values.map((item) => {
        if (item.key === 'Status') {
          return {
            ...item,
            value: event.target.value
          };
        }
        return item;
      });
      updateCriteria(classNameOfCriteria, newValues);
    };

    const handleOnChangeExpansion = (event, isExpanded) => {
      onChangeExpansionOfCriteria(isExpanded, classNameOfCriteria);
    };

    return (
      <ExpansionWrapperCriteria
        title={<FormattedMessage id="statusCriteriaCounter.statusSearch" defaultMessage="Status" />}
        handleOnChangeExpansion={handleOnChangeExpansion}
        expanded={!!foundCriteria}
      >
        {!!foundCriteria && (
          <FormControl variant="standard" fullWidth disabled={isEverythingDisabled}>
            <InputLabel>
              <FormattedMessage id="statusCriteriaCounter.status" defaultMessage="Status" />
            </InputLabel>
            <Select
              variant="standard"
              value={foundCriteria.values.find((item) => item.key === 'Status').value}
              onChange={onChangeStatusValue}
            >
              <MenuItem value="workingcopy">
                <FormattedMessage id="statusCriteriaCounter.workingCopy" defaultMessage="Working copy" />
              </MenuItem>
              <MenuItem value="checkedin">
                <FormattedMessage id="statusCriteriaCounter.checkedIn" defaultMessage="Checked In" />
              </MenuItem>
              <MenuItem value="checkedout">
                <FormattedMessage id="statusCriteriaCounter.checkedOut" defaultMessage="Checked Out" />
              </MenuItem>
              <MenuItem value="approved">
                <FormattedMessage id="statusCriteriaCounter.approved" defaultMessage="Approved" />
              </MenuItem>
              <MenuItem value="archived">
                <FormattedMessage id="statusCriteriaCounter.archived" defaultMessage="Archived" />
              </MenuItem>
              <MenuItem value="notarchived">
                <FormattedMessage id="statusCriteriaCounter.notArchived" defaultMessage="Not archived" />
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </ExpansionWrapperCriteria>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps.foundCriteria, nextProps.foundCriteria)
);

export default StatusSearchCriteria;
