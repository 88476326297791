export default function addDefaultLanguages(label) {
  if (!label.locales) {
    label.locales = [];
  }
  if (!label.translations) {
    label.translations = [];
  }
  if (!label.locales.includes('en')) {
    if (label.locales.indexOf('sv') != -1) {
      label.translations.push(label.translations[label.locales.indexOf('sv')]);
    } else {
      label.translations.push('');
    }
    label.locales.push('en');
  }
  if (!label.locales.includes('sv')) {
    if (label.locales.indexOf('en') != -1) {
      label.translations.push(label.translations[label.locales.indexOf('en')]);
    } else {
      label.translations.push('');
    }
    label.locales.push('sv');
  }
  return label;
}
