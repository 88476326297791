import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Structure from './Structure';
import Formats from './Formats';

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    minHeight: 18,
    marginBottom: 15,
    '& .MuiTab-root': {
      padding: '0px 4px 0px 3px',
      minWidth: 'fit-content',
      minHeight: 18,
      background: '#EBEBEB',
      textTransform: 'capitalize'
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#ffffff',
      background: '#4db1d3',
      outline: 'none'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left',
      background: '#E3F2F7'
    }
  }
}));

function IndexTab() {
  const cl = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeSelectedTab = (e, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Tabs
        indicatorColor=""
        className={cl.tabsRoot}
        value={selectedTab}
        onChange={handleChangeSelectedTab}
        textColor="primary"
        variant="standard"
        scrollButtons="auto"
        centered
      >
        <Tab label="Structure" />
        <Tab label="Formats" />
      </Tabs>
      {selectedTab === 0 && <Structure />}
      {selectedTab === 1 && <Formats />}
    </div>
  );
}

export default IndexTab;
