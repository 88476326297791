import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { CONTAINER } from '../side-bar/SIDE_BAR_CONTS';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: 'calc(var(--vh, 1vh) * 100)',
    background: 'rgba(0, 0, 0, 0.4)',
    transition: 'all 0.5s ease',
    position: 'absolute',
    top: '0',
    left: CONTAINER.maxWidth,
    zIndex: '100000',
    animation: '$move 0.5s ease'
  },
  '@keyframes move': {
    '0%': {
      left: 0
    },
    '100%': {
      left: CONTAINER.maxWidth
    }
  }
});
function Backdrop(props) {
  return <div {...props} className={useStyles().root} />;
}
export default Backdrop;
