import React, {useEffect, useState} from 'react'
import {makeStyles} from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import TextFieldItem from "@zert-packages/components/TextFieldItem";
import {TextFieldWithLanguageSwitchMemo} from "@zert-packages/components/common/TextFieldWithLangaugeSwitch";
import CheckboxSettings from "./CheckboxSettings";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import {adobeLanguage} from "../../../utils/adobeLanguage";
import mandatoryData from '../../../data/mandatoryData.json'
import getCLMPlugins from "../../../../Environment";
import TypeSelected from "./TypeSelected";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 414,
        padding: 11,
        background: "#E2F2F6"
    },
    wrapperTitle: {
        marginBottom: 10,
        display: "flex",
        justifyContent: "space-between",
        textTransform: "uppercase",
        "& svg": {
            cursor: "pointer"
        }
    },
    title: {
        fontSize: "1.8rem",
        fontWeight: 700,
        textTransform: "capitalize"
    },
    wrapperInfo: {
        display: "grid",
        gridTemplateColumns: "42px 1fr",
        gridColumnGap: 10
    },
    icon: {
        fontSize: 42,
        color: "#4db1d3"
    },
    container: {
        display: 'grid',
        gridRowGap: 5,
    },
    label: {
        fontWeight: '700',
    },
    row: {
        display: 'grid',
        gridTemplateColumns: '150px 1fr',
        gridColumnGap: 5,
        '& input': {
            height: 27.5,
            boxSizing: 'border-box',
        },
    },
    wrapperButton: {
        display: "flex",
        marginTop: 30,
        justifyContent: "end",
        gap: 10
    },
    button: {
        color: "#ffffff",
        background: "#4db1d3",
        minWidth: 80,
        border: "1px solid #4db1d3",
        outline: "none !important",
        cursor: "pointer",
        "&:hover": {
            background: "none",
            color: "#212529",
            border: "1px solid #4db1d3"
        },
        "&:focus": {
            backgroundColor: "#4db1d3"
        }
    },
}));

const style = {
    display: "flex",
    flexDirection: "row-reverse"
};

const appliesData = [{name: 'Version', val: 0}, {name: 'Element', val: 1}]



const MetaDataDialog = ({selected,metaData, setMetadata, add}) => {
    const cl = useStyles();
    const [open, setOpen] = useState(true);
    const [meta, setMeta] = useState({
        identifier: '',
        inheritable: false,
        copiedToNewVersions: false,
        once: false,
        group: false,
        security: false,
        mode: 0,
        mandatory: mandatoryData[0],
        unique: false,
        plugins: null,
        type: 'Text',
        names: [{
                "name": "",
                "locale": {
                    "language": "en",
                    "country": "",
                    "variant": "",
                    "displayName": "англійська"
                }
            }]
    })


    const findDisabled = () => {
        const findNames  = meta.names.find(el => el.name.length === 0)
        return !!(findNames || meta.identifier.length === 0);

    }
    const disableButton = findDisabled()
    const handleClose = () => {
        setOpen(false);
    };

    const handleMeta = (val, name) => {
        setMeta(pre => ({...pre, [name]: val}))
    }

    const handleLanguage = (val, name) => {
        const res = val.default.map((el, i) => ({...el, name: val.translations[i]}))
        handleMeta(res, name)
    }

    const handleApplies = (val, name) => {
        handleMeta(val.val, name)
    }



    const handleSave = () => {
        handleClose()
        if(add){
            return  setMetadata(pre => ({
                ...pre,
                selectedElement: pre.meta.structure.metadataDefinitions.length,
                meta: {...pre.meta, structure: {
                    ...pre.meta.structure, metadataDefinitions: [...pre.meta.structure.metadataDefinitions, meta]
                }}
            }))
        }
        const list = metaData.meta?.structure?.metadataDefinitions
        const metadataDefinitions = list.reduce((acu, cur, idx) => {
            if(idx === metaData.selectedElement){
                return [...acu, {...cur, ...meta}]
            }
            return [...acu, cur]
        }, [])
        setMetadata(pre => ({
            ...pre,
            meta: {...pre.meta, structure: {...pre.meta.structure, metadataDefinitions}}
        }))

    }

    useEffect(() => {
        if (selected) {
            setMeta(pre => ({...pre, ...selected,}))
        }
    }, []);



    return (<Dialog
        open={open}
        onClose={handleClose}
        classes={{
            paperScrollPaper: cl.root
        }}
    >
        <div className={cl.wrapperTitle}>
              <span className={cl.title}>
                {selected ? 'Edit' : 'Add'} metadata
              </span>
            <CloseIcon onClick={handleClose}/>
        </div>

        <div className={cl.container}>
            <div className={cl.row}>
                <div className={cl.label}>Identifier:</div>
                <TextFieldItem
                    values={meta.identifier}
                    name={"identifier"}
                    setValues={handleMeta}
                />
            </div>

            <div className={cl.row}>
                <div className={cl.label}>Name:</div>
                <TextFieldWithLanguageSwitchMemo
                    state={adobeLanguage(meta.names)}
                    disabled={false}
                    onChange={(val) => handleLanguage(val, 'names')}
                    style={style}
                />
            </div>

            <div className={cl.row}>
                <div className={cl.label}>Description:</div>
                <TextFieldWithLanguageSwitchMemo
                    state={meta.description}
                    disabled={false}
                    onChange={(val) => handleMeta(val, 'description')}
                    style={style}
                />
            </div>

            <div className={cl.row}>
                <div className={cl.label}>Applies to:</div>
                <ExpanderItem
                    propsLabel={appliesData}
                    valueProps={appliesData[meta?.mode]?.name}
                    type={'mode'}
                    setProps={handleApplies}
                    style={{
                        height: 27.5,
                        textTransform: 'none',
                    }}
                />
            </div>
            <div className={cl.row}>
                <div className={cl.label}>Type:</div>
                <TypeSelected meta={meta} setMeta={setMeta}/>
            </div>
            <div className={cl.row}>
                <div className={cl.label}>Mandatory:</div>
                <ExpanderItem
                    propsLabel={mandatoryData}
                    valueProps={meta.mandatory.name}
                    name={'mandatory'}
                    setProps={handleApplies}
                    style={{
                        height: 27.5,
                        textTransform: 'none',
                    }}
                />
            </div>
            <CheckboxSettings meta={meta} setMeta={setMeta}/>
        </div>

        <div className={cl.wrapperButton}>
            <Button  disabled={disableButton} className={cl.button} onClick={handleSave}>
                Ok
            </Button>
            <Button className={cl.button} onClick={handleClose}>
                Cancel
            </Button>
        </div>
    </Dialog>)
}

export default MetaDataDialog