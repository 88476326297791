import React, { Fragment, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TreeView from '@mui/lab/TreeView';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { TranslationStatusNode } from './TranslationStatusNode';
import TranslationLegend from './components/TranslationLegend';

const useForceUpdate = () => {
  const [, setIt] = useState(false);
  return () => setIt((it) => !it);
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: 264,
      flexGrow: 1
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
      paddingRight: '16px',
      top: '-10px',
      backgroundColor: 'white',
      position: 'sticky'
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1
    },
    greyDot: {
      width: '0px',
      height: '0px',
      margin: '3px',
      padding: '8px',
      display: 'flex',
      border: '1px solid transparent'
    }
  };
});

function TranslationStatusTree({ info }) {
  const forceUpdate = useForceUpdate();
  const [key, setKey] = useState(uuidv4());
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const activeLocales = useSelector((state) => state.activeLocales);

  useEffect(() => {
    const key = uuidv4();
    setKey(key);
    setExpanded([{ key }]);
    forceUpdate();
  }, [info]);

  return (
    <>
      <TranslationLegend />
      <div className={classes.labelRoot}>
        <Typography variant="body2" className={classes.labelText}>
          <FormattedMessage id="name" defaultMessage="Name" />
        </Typography>
        {activeLocales &&
          activeLocales.map((locale, index) => (
            <div className={classes.greyDot}>
              <Typography variant="caption" color="inherit">
                {activeLocales[index - 1] && activeLocales[index - 1].language == locale.language
                  ? locale.country
                  : locale.language}
              </Typography>
            </div>
          ))}
      </div>

      <TreeView
        className={classes.root}
        defaultExpanded={[key]}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        <TranslationStatusNode key={key} root info={info} nodeId={key} />
      </TreeView>
    </>
  );
}

export default TranslationStatusTree;
