import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import withSearch, { styles, controlStyles } from '../withSearch';

import SearchComponent from './SearchComponent';

class SizeSearch extends SearchComponent {
  constructor(props) {
    super(props);
    if (!this.state || !this.state.sizeType) {
      this.state = { sizeType: 'kB', sizeMin: 100, sizeMax: 200 };
    }

    this.handleChange = this.handleChange.bind(this);
    this.textInputSizeMin = React.createRef();
    this.textInputSizeMax = React.createRef();
  }

  handleChange = (name) => (event) => {
    const value = event.target.value;
    this.setStatus({ [name]: (value == null || value.length == 0)? 0 : value});
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    //  (this.props.expanded && this.textInputSizeMin.current) ? this.textInputSizeMin.current.blur() : this.textInputSizeMin.current.focus();
  }

  render() {
    const { classes } = this.props;
    //   this.props.handleChange(this.state);
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel htmlFor="sizeType">
            <FormattedMessage id="search.id.sizeType" defaultMessage="Size" />{' '}
          </InputLabel>
          <Select
            value={this.state.sizeType}
            onChange={(e) => {
              this.setStatus({ sizeType: e.target.value });
            }}
            size="small"
            inputProps={{
              name: 'search-sizeType',
              id: 'sizeType'
            }}
          >
            <MenuItem value="MB">
              <em>
                <FormattedMessage id="search.size.mb" defaultMessage="MB" />
              </em>
            </MenuItem>
            <MenuItem value="kB">
              <em>
                <FormattedMessage id="search.size.kb" defaultMessage="kB" />
              </em>
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          inputRef={this.textInputSizeMin}
          id="standard-name"
          label="Min size"
          value={this.state.sizeMin}
          onChange={this.handleChange('sizeMin')}
          type="number"
          placeholder="Min"
          fullWidth
          size="small"
          className={classes.formControl}
          variant="outlined"
        />

        <TextField
          inputRef={this.textInputSizeMax}
          id="sizeMax"
          label="Max size"
          value={this.state.sizeMax}
          onChange={this.handleChange('sizeMax')}
          type="number"
          placeholder="Max"
          fullWidth
          size="small"
          className={classes.formControl}
          variant="outlined"
        />
      </form>
    );
  }
}

SizeSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.size.header" defaultMessage="Search by size" />,
  index: 'size',
  className: 'se.zert.backend.search.SizeCriteria',
  default: {
    sizeType: 'MB',
    sizeMin: 0,
    sizeMax: 0
  }
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles)(SizeSearch), selectData))
);
