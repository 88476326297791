import React from 'react'
import makeStyles from "@mui/styles/makeStyles";
import {handleExpander} from "clm/src/plugins/formatTemplatePlugin/utils/main";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const useStyles = makeStyles((theme) => ({
    wrapperBorder: {
        height: '100%',
        position: 'relative',
    },
    border: {
      background: '#E2F2F6',
      width: 5,
      height: '100%',
    },
    wrapperArrow: {
         position: 'absolute',
        top: 'Calc(50% - 42px)',
        background: '#ffffff',
        cursor: 'pointer',
        height: 42,
        width: 11,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& svg': {
            display: 'block'
        },
        '& span': {
            height: 21,
            display: 'block'
        }
    },
}))

const ExpanderArrowWrapper = ({expander, setExpander}) => {
    const cl = useStyles();

    return (<div className={cl.wrapperBorder}>
      <div className={cl.border}></div>
      <div className={cl.wrapperArrow} style={{marginLeft: expander === 1 ? -5 : -2}}>
        {expander !== 1 && <span><ArrowLeftIcon onClick={(e) => handleExpander(expander, setExpander, "left")}/></span>}
        {expander !== 3 && <ArrowRightIcon onClick={(e) => handleExpander(expander, setExpander)}/>}
    </div>
    </div>)
}

export default ExpanderArrowWrapper