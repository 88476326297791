import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getActiveLocales,
  getFormatTemplate,
  getMetadataTemplates,
  getPublishingFilters,
  hasLicense,
  hasPluginPermits
} from '@zert-packages/actions/coreReducers';
import { getCatalogsForCompany } from '@zert-packages/actions/catalogActions';
import { getUser } from '@zert-packages/actions/actionplan';
import { getMyNews, getPlugins, loadMyLocale, API_ROOT_WAR, getMyNotifications } from '@zert-packages/actions/api';
import { getRouteByType } from './HeadBarRoutes';
import './HeadBar.css';

function HeadBarNew() {
  const containerRef = useRef();
  const { catalog, error } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!catalog) {
      dispatch(hasLicense());
      if (window.ISCLM) {
        dispatch(getFormatTemplate());
        dispatch(getPublishingFilters());
      }

      if (!window.ISCLM) {
        dispatch(getCatalogsForCompany());
      }
      dispatch(getActiveLocales(true));
      dispatch(getMetadataTemplates());

      if (
        document.location.href.endsWith(`${API_ROOT_WAR}/action-plan`) ||
        document.location.href.endsWith(API_ROOT_WAR)
      ) {
        dispatch(getUser(false));
      } else {
        dispatch(getUser(true));
      }
      dispatch(getMyNews(-1));

      dispatch(getMyNotifications());
      dispatch(loadMyLocale());
      dispatch(hasPluginPermits());
    }
  }, []);

  useEffect(() => {
    if (error) {
      throw error;
    }
  });


  return (
    <div className="HeadBar__Container" ref={containerRef} >
      {getPlugins().map(
        (plugin) =>
          plugin.routes &&
          plugin.routes.map((route, index, ) =>
            getRouteByType({
              route,
              plugin,
              index,
            })
          )
      )}
    </div>
  );
}

export default HeadBarNew;
