import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { generateActionPlanReport } from "@zert-packages/actions/searchActions";
import React from "react";

export default function useActionPlanReports({ selectedNode, selectedValues, catalog }) {
  const caption = <FormattedMessage id="reports.actionplan" defaultMessage="Action plan report" />;
  const description = (
    <FormattedMessage
      id="reports.actionplan.description"
      defaultMessage="This report provides an overview of items from the action plan."
    />
  );
  const dispatch = useDispatch();
  const { tasks } = useSelector(state => state);
  const { actionPlanPage }  = useSelector(state => state);
  const { userName } = useSelector(state => state.myuser);
  const index = 'checklists';
  const radiobtns = [];
  const dateSelectors = [];
  const checkBoxes = [
  ];
  const selectors = [];
  var filterUser;
  if (actionPlanPage.activeUserType === 0) {
    filterUser = userName;
  } else if (actionPlanPage.activeUserType === 1) {
    filterUser = "*";
  } else {
    filterUser = actionPlanPage.activeUser.userName;
  }
  const generateReport = (checkedItems) => {
    const selectedIds =  selectedValues && selectedValues.length > 0 ?
      selectedValues.map((value) => value.versionId) :
      tasks.map((value) => value.versionId);

    dispatch(
      generateActionPlanReport(
        {
          name: caption,
          reportUUID: uuidv4()
        },
        filterUser,
        actionPlanPage.activeMimeType,
        selectedIds,
        tasks.length
      )
    );
  };

  return { index, generateReport, caption, description, radiobtns, dateSelectors, selectors, checkBoxes };
}