import React, { Fragment } from 'react';
import { getBlockPos, getBlockPosMulti, calculateColorCoverage } from '@zert-packages/actions/overviewCoverage';

class MatrixSquare extends React.Component {
  render() {
    if (this.props.selected && !this.props.isNotSignificantValue) {
      return (
        <div
          className="matrix-square"
          style={{
            backgroundColor: this.props.value.color
          }}
        >
          <div
            style={{
              backgroundColor: 'black',
              width: '4px',
              paddingTop: '3px',
              paddingRight: '3px',
              height: '4px',
              borderRadius: '50%'
            }}
          />
        </div>
      );
    }

    return (
      <div
        className="matrix-square"
        style={{ backgroundColor: this.props.isNotSignificantValue ? '#ABC1C7' : this.props.value.color }}
      />
    );
  }
}

export default class MatrixInlineCatalogView extends React.Component {
  constructor(props) {
    super(props);
    this.isSelected = this.isSelected.bind(this);
    this.state = {
      matrixValues: [],
      rows: []
    };
    this.onChange = this.onChange.bind(this);
  }

  clickMatrix(selection) {
    this.setState({
      selection
    });
    this.props.cell.selection.position = selection.position;
    //  this.forceUpdate();
    // component.forceUpdate();
  }

  onChange(e) {
    if (e.target.value != -1) {
      this.props.cell.selection.position[parseInt(e.target.getAttribute('data-id'))] = {
        index: parseInt(e.target.value),
        name: this.props.column.matrix.dimensions[parseInt(e.target.getAttribute('data-id'))].identifier
      };
      this.setState({
        selection: { ...this.props.cell.selection }
      });
    } else {
      this.props.cell.selection.position = [];
    }
  }

  isSelected(column, position1, position2) {
    const identifier1 = column.matrix.dimensions[0].identifier;
    const identifier2 = column.matrix.dimensions[1].identifier;
    let result = false;

    this.props.overview.overview.map((ov) => {
      if (ov.matrixIndentifier == this.props.id) {
        ov.selection.map((selectt) => {
          if (
            selectt.selection[1].left == identifier2 &&
            selectt.selection[1].right == position2 &&
            selectt.selection[0].left == identifier1 &&
            selectt.selection[0].right == position1
          ) {
            result = true;
          }
          if (
            selectt.selection[1].left == identifier1 &&
            selectt.selection[1].right == position2 &&
            selectt.selection[0].left == identifier2 &&
            selectt.selection[0].right == position1
          ) {
            result = true;
          }
        });
      }
    });

    return result;
  }

  render() {
    // this.initMatrixValues();
    //  this.state.cell.selection.dimensions[0];

    if (this.props.column.matrix.dimensions.length == 2) {
      const coverage = calculateColorCoverage(this.props.column.matrix);
      const dimensionY = this.props.column.matrix.dimensions[1].identifier;
      var dimensionX = this.props.column.matrix.dimensions[0].identifier;
      var isRed = false;
      var isYellow = false;
      let stateselectorY = 1;
      var stateselectorX = 0;

      if (
        this.props.cell != null &&
        this.props.cell.selection != null &&
        this.props.cell.selection != undefined &&
        this.props.cell.selection.position.length >= 2
      ) {
        stateselectorY = dimensionY == this.props.cell.selection.position[1].name ? 0 : 1;
        stateselectorX = dimensionX == this.props.cell.selection.position[0].name ? 1 : 0;
      }

      for (let j = 0; j < this.props.column.matrix.dimensions[1].values.length; j++) {
        const columns = [];
        for (var i = 0; i < this.props.column.matrix.dimensions[0].values.length; i++) {
          columns[i] = (
            <MatrixSquare
              isNotSignificantValue={false}
              selected={this.isSelected(this.props.column, i, j)}
              value={this.props.column.matrix.valueMaps[0].values[getBlockPos(this.props.column.matrix, j, i)]}
            />
          );
        }
        this.state.rows[j] = <div className="matrix-row">{columns}</div>;
      }
      // var columns = rows[0].props.chidren;
      // columns[0].selected = true;
      isRed = isRed && !this.props.cell.notSignificantValue;
      isYellow = isYellow && !this.props.cell.notSignificantValue;

      return (
        <div
          className="matrix-area"
          style={{
            borderColor: isRed ? '#FB9281' : isYellow ? '#F0C770' : '#E1EAEE',
            width: `${this.state.rows.length * 10 + 4}px`
          }}
        >
          {this.state.rows}
        </div>
      );
    }

    //    positionHistory
    //  "value": {
    //  "class": "se.zert.rm.web.plugin.template.cell.server.rest.pojo.ClientMatrixCell",
    // "columnIdentifier"
    if (this.props.column.matrix.dimensions.length == 1) {
      // if this.props.residual)

      var dimensionX = this.props.column.matrix.dimensions[0].identifier;
      var isRed = false;
      var isYellow = false;
      var stateselectorX = 0;
      var index = -1;

      var value = null;
      if (this.props.cell !== undefined && this.props.cell.selection.position.length >= 1) {
        //     var coverage = this.calculateColorCoverage();
        index = this.props.cell.selection.position[stateselectorX].index;
        value = this.props.column.matrix.dimensions[0].values[this.props.cell.selection.position[stateselectorX].index];
        //   isRed = value.color === coverage.redColor;
        //   isYellow = !isRed && !(value.color === coverage.greenColor);
        //  columns[i] =  <div> </div>
      }
      //    this.state.rows[j] = <div className="matrix-row">{columns}</div>;
      // var columns = rows[0].props.chidren;
      // columns[0].selected = true;
      isRed = isRed && !this.props.cell.notSignificantValue;
      isYellow = isYellow && !this.props.cell.notSignificantValue;
      if (!this.props.isEditor) {
        return <div>{value != null ? value.text.defaultLabel : '-'}</div>;
      }
      return (
        <select
          defaultValue={index}
          data-id={id}
          className="form-control animated"
          onChange={(e) => {
            this.onChange(e);
          }}
        >
          <option value={-1} key={-1}>
            -
          </option>
          {this.props.column.matrix.dimensions[0].values.map((value, index) => {
            return (
              <option value={index} key={index}>
                {value.text.defaultLabel}
              </option>
            );
          })}
        </select>
      );
    }

    if (this.props.column.matrix.dimensions.length > 2) {
      var isRed = isRed && !this.props.cell.notSignificantValue;
      var isYellow = isYellow && !this.props.cell.notSignificantValue;
      const combos = [];
      let map = this.props.column.matrix.defaultValueMap;
      if (this.props.column.matrix.valueMaps != null) {
        const mapNew = this.props.column.matrix.valueMaps.find((mapmin) => {
          return mapmin.filter == null;
        });
        if (mapNew != null) {
          map = mapNew;
        }
      }

      if (this.props.isEditor) {
        for (var i = 0; i < this.props.column.matrix.dimensions.length; i++) {
          var value = null;
          var index = -1;

          if (this.props.cell !== undefined && this.props.cell.selection.position.length > i) {
            if (this.props.cell.selection.position[i]) {
              index = this.props.cell.selection.position[i].index;
              value = this.props.column.matrix.dimensions[i].values[index];
            }
          }

          if (this.props.isEditor) {
            var id = i;
            combos.push(
              <>
                <div>{this.props.column.matrix.dimensions[i].name.defaultLabel} </div>
                <select
                  defaultValue={index}
                  key={id}
                  data-id={id}
                  className="form-control animated"
                  onChange={(e) => {
                    this.onChange(e);
                  }}
                >
                  <option value={-1} key={-1}>
                    -
                  </option>
                  {this.props.column.matrix.dimensions[i].values.map((value, index) => {
                    return (
                      <option value={index} key={index}>
                        {value.text.defaultLabel}
                      </option>
                    );
                  })}
                </select>
              </>
            );
          }
        }
      } else {
        var value =
          this.props.cell !== undefined && this.props.cell.selection.position.length >= 2
            ? map.values[getBlockPosMulti(this.props.column.matrix, this.props.cell.selection.position)]
            : null;
        combos.push(
          <div style={{ whiteSpace: 'nowrap' }}>{value != null ? value.abbreviation.defaultLabel : '-'}</div>
        );
      }

      return <>{combos}</>;
    }
  }
}
