import { GetEnv } from '@zert-packages/react/EnvProvider';
import { PortalTheme } from '@zert-packages/react/themes/Themes';
import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import axios from '@zert-packages/utils/axios';
import { loadMyLocale } from '@zert-packages/actions/api';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '@zert-packages/actions/actionplan';
import { fetchSetRoute } from '@zert-packages/actions/coreReducers';
import { useHistory } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import SplashScreen from './SplashScreen';

const MY_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';
const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  formType: 0,

  user: null
  //  uuid : uuidv5('ZertAuth', MY_NAMESPACE),
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CREATE_AUTH': {
      const { uuid } = action.payload;

      return {
        ...state,

        uuid
      };
    }

    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,

        user: { ...user }
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user: { ...user },
        error: null
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        error: null,
        user: { ...user }
      };
    }
    case 'UPDATE_AVATAR': {
      const { avatar } = action.payload;

      return {
        ...state,
        user: { ...state.user, avatar }
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  getLoginMethod: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve()
});

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const dispatchR = useDispatch();
  const { oldRoute } = useSelector((state) => state);
  const history = useHistory();
  const portal = GetEnv('REACT_APP_PORTAL');

  const getLoginMethod = async (email) => {
    return axios
      .get(`${process.env.REACT_APP_API_ROOT_CALLS}/auth/getLoginMethod?user=${email}`)
      .then((r) => r.data)
      .catch((e) => null);
  }

  const login = async (email, password, object) => {
    // const response =

    //  await axios.post('/dashboard/auth/authorise', { userName: email, password, uuid : state.uuid });
    const response = await axios.post(
      `${process.env.REACT_APP_API_ROOT_CALLS}/auth/authorise`,
      object || { userName: email, password }
    );
    const { accessToken } = response.data;
    setSession(accessToken);
    const user = await axios.get(`${process.env.REACT_APP_API_ROOT_CALLS}/auth/me`);

    //   await axios.post(process.env.REACT_APP_API_ROOT_CALLS +'/board/auth/accept', { code, uuid : state.uuid });

    dispatch({
      type: 'LOGIN',
      payload: {
        user: { ...user.data }
      }
    });
    dispatchR(loadMyLocale());
    dispatch(getUser(false));
    if (oldRoute) {
      dispatch(fetchSetRoute(null));
      history.push(oldRoute);
    }
    location.reload();
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get(`${process.env.REACT_APP_API_ROOT_CALLS}/auth/me`);
          const user = response.data;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: { ...user }
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={PortalTheme(portal)}>
        <AuthContext.Provider
          value={{
            ...state,
            method: 'JWT',
            login,
            getLoginMethod
          }}
        >
          {children}
        </AuthContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default AuthContext;
