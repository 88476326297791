import { createAction, createReducer } from '@reduxjs/toolkit';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';

export const getPreviewList = createAction('Preview: Load Preview List');
export const startPreviewList = createAction('Preview: Preview List loading');
export const loadLayoutsList = createAction('Preview: Load Layouts List');
export const setSelectedLayout = createAction('Preview: Selected layout');
export const addMoreLayouts = createAction('Preview: AddMore layouts');
export const setSelectedLanguage = createAction('Preview: Selected language');
export const setSelectedLayoutFilter = createAction('Preview: Selected Layout Filter');
export const stopPreviewList = createAction('Preview: Preview List stop');
export const setLayoutFilters = createAction('Preview: Load Layout Filters');
export const setLayoutSettings = createAction('Preview: Set Layout Settings');
export const setCompareWithVersions = createAction('Preview: Set CompareWithVersions');
export const setCompareWithVersionId = createAction('Preview: Set CompareWithVersion Id');
export const setLayoutProperties = createAction('Preview: Set Layout Properties');
export const setMultipleLanguage = createAction('Preview: Multiple Language');
export const setFallbackLocale = createAction('Preview: FallbackLocale');
export const setLanguageSeparated = createAction('Preview: LanguageSepared');
export const setSelectedPreviewJOBElement = createAction('Preview: select element to preview');
export const setMetaData = createAction('Preview: set MetaData for Preview JOB');
export const setPreviewPublishingFilter = createAction('Preview: set publishing filter');
export const setPreviewPublishingFilters = createAction('Preview: set publishing filters');
export const setAllLayouts = createAction('Preview: set all layouts');
export const generatingMessage = createAction('Preview: generationMessage');

const defaultLayoutFilter = {
  uuid: '-1',
  name: <FormattedMessage id="defaultLayoutFilter" defaultMessage="Default layout filter" />
};

const parseJSON = (response, defaultval) => {
  if (response) {
    try {
      return JSON.parse(response);
    } catch (e) {}
  }
  return defaultval || {};
};

const parseBool = (response, defaultval) => {
  if (response) {
    try {
      return response == 'true';
    } catch (e) {}
  }
  return defaultval;
};

const checkLocales = () => {
  const vals = parseJSON(localStorage.getItem('multipleSelectedLanguage'), []);
  const selectedLocale = localStorage.getItem('selectedLanguage');
  if (selectedLocale && vals.indexOf(selectedLocale) == -1) {
    vals.push(selectedLocale);
  }
  return vals;
};

export const initialState = {
  previewList: null,
  loading: false,
  layouts: null,
  allLayouts: [],
  compareWithVersions: null,
  compareWithVersionId: -1,
  metadata: [],
  selectedElementToPreview: null,
  selectedLayout: localStorage.getItem('selectedLayout'),
  fallbackLocale: localStorage.getItem('fallbackLocale'),
  selectedLanguage: localStorage.getItem('selectedLanguage'),
  layoutFilters: [defaultLayoutFilter],
  stopPreview: true,
  previewPublishingFilters: [],
  loadingMessage: {},
  layoutSettings: {},
  languageSeparated: parseBool(localStorage.getItem('languageSeparated'), false),
  multipleSelectedLanguage: checkLocales(),
  layoutProperties: localStorage.getItem('layoutProperties') ? parseJSON(localStorage.getItem('layoutProperties')) : {},
  selectedLayoutFilter:
    localStorage.getItem('selectedLayoutFilter') == null ? '-1' : localStorage.getItem('selectedLayoutFilter')
};

export const PreviewReducer = createReducer(initialState, {
  [setPreviewPublishingFilters]: (state, action) => {
    state.previewPublishingFilters = action.payload ? action.payload : [];
  },
  [setPreviewPublishingFilter]: (state, action) => {
    const filter = state.previewPublishingFilters.find((item) => action.payload.filter.identifier == item.identifier);
    const filterIndex = state.previewPublishingFilters.findIndex(
      (item) => action.payload.filter.identifier == item.identifier
    );
    if (filterIndex != -1) {
      state.previewPublishingFilters = state.previewPublishingFilters.map((filter, index) =>
        index == filterIndex ? action.payload.value : filter
      );
    } else {
      state.previewPublishingFilters = [...state.previewPublishingFilters, action.payload.value];
    }
  },
  [generatingMessage]: (state, action) => {
    // state.loadingMessage = action.payload;
    const id = action.payload.uuid;
    state.loadingMessage = { ...state.loadingMessage, [id]: action.payload.message };
  },

  [setAllLayouts]: (state, action) => {
    state.allLayouts = action.payload;
  },

  [addMoreLayouts]: (state, action) => {
    const layout = state.layouts.find((layout) => layout.versionId == action.payload.versionId);
    if (!layout) {
      action.payload.extended = true;
      state.layouts = [...state.layouts, action.payload];
    }
  },
  [setMetaData]: (state, action) => {
    state.metadata = action.payload;
  },
  [setCompareWithVersions]: (state, action) => {
    state.compareWithVersions = action.payload;
  },
  [setCompareWithVersionId]: (state, action) => {
    state.compareWithVersionId = action.payload;
  },
  [setSelectedPreviewJOBElement]: (state, action) => {
    state.selectedElementToPreview = action.payload;
  },
  [setLayoutProperties]: (state, action) => {
    state.layoutProperties = { ...state.layoutProperties, [action.payload.layoutVersionId]: action.payload.properties };
    try {
      localStorage.setItem('layoutProperties', JSON.stringify(state.layoutProperties));
    } catch (e) {}
  },
  [setLanguageSeparated]: (state, action) => {
    state.languageSeparated = action.payload;
    try {
      localStorage.setItem('languageSeparated', JSON.stringify(state.languageSeparated));
    } catch (e) {}
  },
  [setMultipleLanguage]: (state, action) => {
    state.multipleSelectedLanguage = action.payload;
    if (
      state.multipleSelectedLanguage.length > 0 &&
      state.multipleSelectedLanguage.indexOf(state.selectedLanguage) == -1
    ) {
      state.selectedLanguage = state.multipleSelectedLanguage[0];
      localStorage.setItem('selectedLanguage', state.selectedLanguage);
    }

    try {
      localStorage.setItem('multipleSelectedLanguage', JSON.stringify(state.multipleSelectedLanguage));
    } catch (e) {}
  },
  [setSelectedLayoutFilter]: (state, action) => {
    localStorage.setItem('selectedLayoutFilter', action.payload);
    state.selectedLayoutFilter = action.payload;
  },
  [setLayoutSettings]: (state, action) => {
    state.layoutSettings = { ...state.layoutSettings, [action.payload.versionId]: action.payload.settings };
  },

  [setLayoutFilters]: (state, action) => {
    state.layoutFilters = [defaultLayoutFilter, ...action.payload];
  },
  [getPreviewList]: (state, action) => {
    state.previewList = action.payload;
  },
  [loadLayoutsList]: (state, action) => {
    state.layouts = action.payload;
  },
  [setSelectedLanguage]: (state, action) => {
    localStorage.setItem('selectedLanguage', action.payload);
    state.selectedLanguage = action.payload;
    if (state.selectedLanguage != null && state.multipleSelectedLanguage.indexOf(state.selectedLanguage) == -1) {
      state.multipleSelectedLanguage = [...state.multipleSelectedLanguage, state.selectedLanguage];

      try {
        localStorage.setItem('multipleSelectedLanguage', JSON.stringify(state.multipleSelectedLanguage));
      } catch (e) {}
    }
  },

  [setFallbackLocale]: (state, action) => {
    localStorage.setItem('fallbackLocale', action.payload);
    state.fallbackLocale = action.payload;
  },
  [setSelectedLayout]: (state, action) => {
    localStorage.setItem('selectedLayout', action.payload);
    state.selectedLayout = action.payload;
  },
  [stopPreviewList]: (state, action) => {
    state.stopPreview = action.payload;
  },
  [startPreviewList]: (state, action) => {
    state.loading = action.payload;
  }
});
