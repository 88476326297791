export const createFilterConditionRequest = (filterConditions) => {
    return filterConditions.map(condition => {
        return {
            condition: condition.condition.identifier,
            childConditions: condition.childConditions.map(childCondition => {
                return {
                    condition: childCondition.condition.identifier,
                    filters: childCondition.filters.map(filterCondition => {
                        return {
                            filterType: filterCondition.filterType,
                            filterIdentifier: filterCondition.category.identifier,
                            filterItemIdentifier: filterCondition.selectedCategoryItem.identifier
                        }
                    })
                }
            })
        }
    })
}