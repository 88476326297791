import { getForcedMetaData, getOptionalMetaData, getReferences, getTermAPI } from './API';
import {
  loadSoftwareTextsForced,
  loadSoftwareTextsOptional,
  loadTermDb,
  loadTermReferences,
  turnOffCLMLoader,
  turnOnCLMLoader
} from '../TranslationJobPlugin/tjReducers';

export const fetchTerms = (versionId, callback) => (dispatch) => {
  dispatch(turnOnCLMLoader());
  getTermAPI(versionId)
    .then((res) => {
      dispatch(loadTermDb(res));
    })
    .then(() => {
      dispatch(turnOffCLMLoader());
    });
};

export const fetchSTReferences = (versionId) => async (dispatch) => {
  dispatch(turnOnCLMLoader());
  const refs = await getReferences();
  dispatch(loadTermReferences(refs));

  const optional = await getOptionalMetaData(versionId);
  const forcedMetadata = await getForcedMetaData(versionId);

  dispatch(loadSoftwareTextsOptional(optional));
  dispatch(loadSoftwareTextsForced(forcedMetadata));

  dispatch(turnOffCLMLoader());
};

export const addTerm = () => async (dispatch) => {
  dispatch(turnOnCLMLoader());
  addTerm()
    .then((res) => {
      dispatch(loadTermReferences(res));
    })
    .then(() => {});
};
