export const gridBlock = (expander) => {
  if (expander === 1) {
    return '7px 1px 1fr';
  }
  if (expander === 2) {
    return '1fr 1px 5fr';
  }
  if (expander === 3) {
    return '1fr 1px';
  }
};
