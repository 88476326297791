import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => (
    {
        item: {
            padding: '8px 5px',
            height: 40,
            overflow: "auto",
            border: '1px solid #c1c7d0f2',
        },
        selectedCell: {
            verticalAlign: 'top',
        },
        field: {
            margin: 0,
            '& .MuiInputBase-root': {
                padding: '5px 7px',
                background: '#DDECF0',
            }
        },
        wrapperAddIcon: {
            display: 'flex',
            justifyContent: 'end',
            color: '#188EC7',
            position: 'relative',
            top: -3,
            left: 5,
            '& svg': {
                fontSize: '1.57rem',
            },
            '& button': {
                padding: 3,
                '&:focus': {
                    outline: 'none',
                }
            },
        },
        wrapperImg: {
            textAlign: 'center',
            '& img': {
                width: '100%',
                maxHeight: 91,
                maxWidth: 128,
                objectFit: 'contain',
            },
        },
        wrapperColor: {
            display: 'flex',
            gap: 5,
            marginBottom: 5,
            position: 'relative',
            maxWidth: 'fit-content',
            marginLeft: 'auto',
            marginRight: 10,
        },
        selectedColor: {
            width: 21,
            height: 21,
            border: '1px solid #188ec7',
            borderRadius: 5,

        },
        wrapperIcon: {
          display: 'flex',
          justifyContent: 'end',
        },
        editIcon: {
            zIndex: "10",
            padding: 2,
            width: 20,
            height: 20,
            marginTop: 2,
        }
    }
))


export default useStyles