import React, {useEffect} from 'react'
import {makeStyles} from "@mui/styles";
import {useSelector} from "react-redux";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import getTranslation from "@zert-packages/utils/getTranslation.old";

const useStyles = makeStyles((theme) => ({
    rootTableFormat: {

    },
    row: {
        display: 'grid',
        gridTemplateColumns: '50px 1fr'
    },
    label: {
        fontWeight: 700
    },
}))


const TableFormat = ({createBlock, setCreateBlock}) => {
    const cl = useStyles();
    const {formatTemplate} = useSelector((state) => state)
    // block

    
    const handleFormat = (val, name) => {
        setCreateBlock(pre => ({...pre, [name]: val}))
    }

    useEffect(() => {
        if(formatTemplate?.tableFormats ){
            const res = formatTemplate?.tableFormats[0]
            handleFormat({...res, name: getTranslation(res.label)}, 'tableFormat')
        }
    }, [formatTemplate.tableFormats])

    
    return (<div className={cl.rootTableFormat}>

            <div>
                {createBlock.tableFormat  && <ExpanderItem
                    valueProps={createBlock.tableFormat.name}
                    propsLabel={formatTemplate.tableFormats.map(el => ({...el, name: getTranslation(el.label)}))}
                    type={'format'}
                    setProps={handleFormat}
                />}
            </div>
    </div>)
}

export default TableFormat