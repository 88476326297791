export const noClass = 'O';
export const definePEDClass = ({ category, substance, substanceClass, temperature, pressure, volume }) => {
  if (category == 'pressure.vessels') {
    if (substance == 'gas') {
      if (substanceClass == '1a') {
        if (volume > 1 && pressure > 0.5) {
          if (pressure * volume > 50 && pressure * volume <= 200) {
            return 'B';
          }
          if (pressure * volume > 200) {
            return 'A';
          }
          return noClass;
        }
        if (volume > 0.1 && volume <= 1 && pressure > 200 && pressure * volume > 50) {
          return 'A';
        }
        return noClass;
      }
      if (substanceClass == '2a') {
        if (volume > 1 && pressure > 0.5) {
          if (pressure * volume > 50 && pressure * volume <= 200) {
            return noClass;
          }
          if (pressure * volume > 200 && pressure * volume <= 1000) {
            return 'B';
          }
          if (pressure * volume > 1000) {
            return 'A';
          }
          return noClass;
        }
        if (volume > 0.1 && volume <= 1 && pressure > 1000 && pressure * volume > 50) {
          return 'A';
        }
      }
      return noClass;
    }
    if (substance == 'liquid') {
      if (substanceClass == '1a') {
        if (volume > 1 && pressure > 10) {
          if (pressure * volume > 200 && pressure * volume <= 2000) {
            return 'B';
          }
          if (pressure * volume > 2000) {
            return 'A';
          }
          return noClass;
        }
        if (volume > 1 && pressure <= 10 && pressure > 0.5 && pressure * volume <= 2000 && pressure * volume > 200) {
          return 'B';
        }
        if (volume > 0.1 && volume <= 1 && pressure > 500 && pressure * volume > 50) {
          return 'B';
        }
        return noClass;
      }
      if (substanceClass == '2a' && volume > 10 && pressure > 500 && pressure * volume > 10000) {
        return 'B';
      }
      return noClass;
    }
  } else if (category == 'pipelines') {
    if (substance == 'gas') {
      if (substanceClass == '1a' && pressure > 0.5 && pressure * volume >= 12.5 && volume > 25) {
        if (volume > 25 && volume <= 100) {
          if (pressure * volume < 1000) {
            return 'B';
          }
        }
        return 'A';
      }
      if (substanceClass == '2a') {
        if (temperature > 350) {
          return 'A';
        }
        if (pressure > 0.5) {
          if (volume > 100 && volume <= 250) {
            if (pressure * volume > 3500) {
              return 'B';
            }
          }
          if (volume > 250) {
            if (pressure * volume > 3500 && pressure * volume < 5000) {
              return 'B';
            }
            if (pressure * volume > 5000) {
              return 'A';
            }
          }
        }
        return noClass;
      }
      return noClass;
    }
    if (substance == 'liquid') {
      if (substanceClass == '1a' && volume > 25) {
        if (pressure > 0.5 && pressure <= 10 && volume * pressure > 2000) {
          return 'B';
        }
        if (pressure > 10 && volume * pressure > 2000) {
          return 'A';
        }
      } else if (substanceClass == '2a' && temperature <= 65) {
        return noClass;
      } else if (substanceClass == '2a' && volume > 200) {
        if (pressure > 10 && volume * pressure > 5000) {
          return 'B';
        }
        return noClass;
      }
      return noClass;
    }
  } else if (category == 'tank') {
    if (substanceClass == '2a' && temperature > 65 && volume > 50000) {
      return 'B';
    }
    if (substanceClass == '1a' && volume > 5000 && volume <= 10000) {
      return 'B';
    }
    if (substanceClass == '1a' && volume > 10000) {
      return 'A';
    }
    return noClass;
  } else if (category == 'vacuum.pipes') {
    if (substanceClass == '2a' && temperature > 65 && volume > 50000 && volume <= 500000) {
      return 'B';
    }
    if (substanceClass == '2a' && temperature > 65 && volume > 500000) {
      return 'A';
    }
    if (substanceClass == '1a' && volume > 5000) {
      return 'A';
    }
    if (substanceClass == '1a' && volume > 1000 && temperature <= 5000) {
      return 'B';
    }
    return noClass;
  } else if (category == 'boiler') {
    if (temperature > 110 && volume > 5) {
      return 'A';
    }
    if (temperature > 65 && temperature <= 110 && volume > 100) {
      return 'B';
    }
  }
  return noClass;
};
