import { handleActiveSelection } from "./main";
import { defaultPhrase } from "../data/defaultPhrase";
import { Modifier } from "draft-js";


let imgKeyIndex = []

const addImg = (idx) => {
    imgKeyIndex.push(idx)
    return [{
        "key": Math.random().toFixed(4) + idx,
        "text": " ",
        "type": "atomic",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [{"offset": 0, "length": 1, "key": imgKeyIndex.length - 1, }],
        "data": {}
    }]
}

const adobeImg = (img, ) => {
    return {...defaultPhrase.img, data: {...defaultPhrase.img.data,width: img.width,  height: img.height,  src: img.imageVersionId,}}
}

const imgArray  = []



const recursionFindChild = (val, idx) => {
    if(!val.children[0].value){
        return recursionFindChild(val.children[0], idx)
    }
    return {
        ...defaultPhrase.blocks[0],
        key:  Math.random().toFixed(4) + idx,
        text:  val.children[0].value,
        inlineStyleRanges : [
            {
                "offset": 0,
                "style": val.template.identifier.toUpperCase()
            }
        ]
    }
}

export const converterPhrase = ( val) => {
    if (!val || !val.elements) {
        return
    }
    //val.elements.length > 0 &&
    const childElements =  val.elements &&  val.elements.reduce((acu, cur, idx) => {
        if(cur.image){
            // return acu
            imgArray.push(adobeImg(cur))
            return  [...acu, ...addImg(idx)]
        }
        if(cur.value){
            return [...acu, {
                ...defaultPhrase.blocks[0],
                key:  Math.random().toFixed(4) + idx,
                text: cur.value,
                inlineStyleRanges : [
                    // {
                    //     "offset": 0,
                    //     "length": 10,
                    //     "style": "FLOAT"
                    // }
                ]

            }]
        }
        if(cur.children &&  cur.template){
            return [...acu,  recursionFindChild(cur)]
        }
        return [...acu, {

            ...defaultPhrase.blocks[0],
            key:  Math.random().toFixed(4) + idx,
        }]
    }, [])
    if(!childElements && childElements.length === 0){
        return;
    }
    return {
        "blocks": [
            ...childElements
        ],
        "entityMap": { ...imgArray }
    }
}




export const  addInsertText = (text, editorValue, EditorState) => {
    const currentContent = editorValue.getCurrentContent();
    const currentSelection = editorValue.getSelection();

    const newContent = Modifier.replaceText(
        currentContent,
        currentSelection,
        text
    );

    const newEditorState = EditorState.push(
        editorValue,
        newContent,
        "insert-characters"
    );
    return EditorState.forceSelection(
        newEditorState,
        newContent.getSelectionAfter()
    );
};