import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { duplicateRiskAnalysesApi } from '@zert-packages/plugins/ExplorerPlugin/API';

import { showSnackbarError } from '@zert-packages/components/Snackbars';
import getTranslation from '@zert-packages/translations/getTranslation';
import { fetchRiskAssessmentTemplates } from '@zert-packages/actions/riskassesmentActions';
import { fetchRiskRowTemplateByVersionId } from 'rm/src/Plugins/AdministrationPlugin/components/RiskRowTemplates/api';

const getDefaultState = (element) => ({
  name: '',
  versionId: -1,
  removeMeasures: false,
  removeResponsible: true,
  removeDeadline: true,
  removeParticipants: true,
  removeSessions: true,
  templateVersionId: -1
});

const useStyles = makeStyles({
  DialogContent: {
    minWidth: 320,
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexFlow: 'nowrap column',
    '& > *': {
      marginBottom: 10
    }
  }
});

function DuplicateRiskRows({ unmountComponent, onFinish, cellsMoved, columnsToFill, riskRowTemplateId }) {
  const snackbar = useSnackbar();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const cl = useStyles();
  const [template, setTemplate] = useState(null);
  const updateStateField = (field, value) =>
    setState((prev) => {
      return { ...prev, [field]: value };
    });

  useEffect(() => {
    setLoading(true);
    fetchRiskRowTemplateByVersionId(riskRowTemplateId)
      .then((r) => {
        setTemplate(r);
        setLoading(false);
      })
      .catch((e) => {
        showSnackbarError(snackbar, e.message);
      });
  }, []);

  const onDuplicate = () => {
    //   setLoading(true);
    // columnsToFill.filter()
    //    Object.keys(state).
    /* const newCells = cellsMoved.filter((cell) => {
           return    Object.keys(state).find(key => state[key] == cell.columnIdentifier)
          }
      ).map((cell)=>({
        ...cell,
          columnIdentifier :  Object.keys(state).find(key => state[key] == cell.columnIdentifier )
      })); */
    // console.log(newCells);
    unmountComponent();
    onFinish(state);
  };
  // const onDuplicateDisabled = `${state.name}`.trim().length === 0;
  const disabled = loading;

  return (
    <Dialog open onClose={unmountComponent} fullWidth>
      <DialogTitle>
        <FormattedMessage id="DuplicationRiskRowDialogs.Title" defaultMessage="Choose columns movements" />
      </DialogTitle>
      <DialogContent className={cl.DialogContent}>
        {columnsToFill.map((item) => {
          const cellFound = state[item.identifier];
          return (
            <FormControl>
              <InputLabel>{getTranslation(item.name)}</InputLabel>
              <Select
                disabled={disabled}
                onChange={(e) => updateStateField(item.identifier, e.target.value)}
                value={cellFound}
                autoWidth
                input={<Input shrink />}
              >
                {template &&
                  cellsMoved
                    .filter((cell) => {
                      const column = template.columns.find((column) => column.identifier == cell.columnIdentifier);
                      if (!column) {
                        return false;
                      }
                      return column.class == item.class;
                    })
                    .map((cell) => {
                      const column = template.columns.find((column) => column.identifier == cell.columnIdentifier);
                      return (
                        <MenuItem value={cell.columnIdentifier} index={cell.columnIdentifier}>
                          {getTranslation(column.name)}
                        </MenuItem>
                      );
                    })}
                <MenuItem value={null}>--</MenuItem>
              </Select>
            </FormControl>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={loading && <CircularProgress size={24} />}
          onClick={onDuplicate}
          color="primary"
          variant="outlined"
          disabled={loading}
        >
          <FormattedMessage
            id="DuplicationDialogs.DuplicationDefaultDialog.DuplicateButton"
            defaultMessage="Duplicate"
          />{' '}
        </Button>
        <Button onClick={unmountComponent} color="primary" disabled={loading}>
          {' '}
          <FormattedMessage id="DuplicationRiskRowDialogs.CancelButton" defaultMessage="Cancel" />{' '}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateRiskRows;
