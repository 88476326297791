export const defaultSelectedRangeData = {
  lastSelectedIndex: -1,
  loaderBlockItems: false,
  selectedBlockChild: null,
  parentSelectedLvl: null,
  blockParentSelectedLvl: false,
  copyRangeBlock: null,
  browseBlock: [],
}

export const defaultValueData = {
  blockTreeItemLeftMenu: false,
  parentSelectedLvlTrue: false,
  treeItemDelete: null,
  copyBlock: {
    blockItems: null,
    blockItemsParent: null,
  },
  stepperKey: [],
  stepperKeyIndex: -1,
  blockClickCreateItemsBlock: false,
  tableImg: null,
  changesEditor: false,
  phraseEditorValue: "",
  socket: [],
}