import React, { Fragment, useEffect, useState } from 'react';
import zxcvbn from 'zxcvbn';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '@mui/material';

function ChangePasswordFields({ user, isError, setIsError, passwordChanged }) {
  const [strength, setStrength] = useState(0);
  const [minStrength, setMinStrength] = useState(2);

  const intl = useIntl();

  useEffect(() => {
    if (user.password && user.password.length > 0) {
      setStrength(zxcvbn(user.password).score);
      validatePassword();
    }
  }, [user.password]);

  useEffect(() => {
    if (user.password && user.password.length > 0 && user.confirmPassword && user.confirmPassword.length > 0) {
      setStrength(zxcvbn(user.password).score);
      validatePassword();
    }
  }, [user.confirmPassword]);

  const validatePassword = () => {
    let errorMessage;
    if (user.password !== user.confirmPassword)
      errorMessage = intl.formatMessage({
        id: 'ChangePasswordFields.passworddoesnotmatch',
        defaultMessage: 'Password does not match'
      });
    if (user.password.length < user.minPasswordLength)
      errorMessage = `${intl.formatMessage({
        id: 'ChangePasswordFields.passwordtoshort',
        defaultMessage: 'Password is too short'
      })}\n`;
    if (user.uppercasePassword && !/[A-Z]/.test(user.password))
      errorMessage = `${intl.formatMessage({
        id: 'ChangePasswordFields.passwordnotuppercase',
        defaultMessage: 'Password must contain at least one capital letter'
      })}\n`;
    if (user.numericPassword && !/\d/.test(user.password))
      errorMessage = `${intl.formatMessage({
        id: 'ChangePasswordFields.passwordnonumber',
        defaultMessage: 'Password must contain at least one number'
      })}\n`;
    if (
      user.specialCharPassword &&
      !/[\!\"\@\#\£\¤\$\%\&\/\{\(\[\)\]\=\}\?\+\\\´\`\^\*\'\.\,\:\;\-\_]/.test(user.password)
    )
      errorMessage = `${intl.formatMessage({
        id: 'ChangePasswordFields.passwordnospecialchar',
        defaultMessage: 'Password must contain at least one special character'
      })}\n`;
    if (zxcvbn(user.password).score < minStrength)
      errorMessage = `${intl.formatMessage({
        id: 'ChangePasswordFields.passwordtoweak',
        defaultMessage: 'Password is too weak'
      })}\n`;

    const newIsError = { ...isError };
    newIsError.password = errorMessage;
    setIsError(newIsError);
  };

  const strengthClass = ['strength-meter mt-2', user.password && user.password.length > 0 ? '' : 'hide']
    .join(' ')
    .trim();

  return (
    <>
      <div style={{ margin: '0em 0em 1em 0em' }}>
        <span className="d-block form-hint">
          <FormattedMessage
            id="ChangePasswordFields.legend"
            defaultMessage="To conform with our Strong Password policy, you are required to use a sufficiently strong password."
          />
        </span>
        <span className="d-block form-hint nested">
          <FormattedMessage
            id="ChangePasswordFields.length"
            defaultMessage="Your password must be at least {thresholdLength} characters long."
            values={{ thresholdLength: user.minPasswordLength }}
          />
        </span>
        <span className="d-block form-hint nested">
          {user.uppercasePassword && (
            <FormattedMessage
              id="ChangePasswordFields.isuppercasepassword"
              defaultMessage="Your password must contain at least one capital letter."
            />
          )}
        </span>
        <span className="d-block form-hint nested">
          {user.numericPassword && (
            <FormattedMessage
              id="ChangePasswordFields.isnumericpassword"
              defaultMessage="Your password must contain at least one digit."
            />
          )}
        </span>
        <span className="d-block form-hint nested">
          {user.specialCharPassword && (
            <FormattedMessage
              id="ChangePasswordFields.isspecialcharpassword"
              defaultMessage="Your password must contain at least one special character."
            />
          )}
        </span>
      </div>

      <TextField
        value={user.password ? user.password : ''}
        onChange={(evt) => passwordChanged(evt)}
        label={intl.formatMessage({ id: 'ChangePasswordFields.password', defaultMessage: 'Password' })}
        error={Boolean(isError.password)}
        id="password"
        type="password"
        fullWidth
        required
        autoComplete="new-password"
      />
      <TextField
        value={user.confirmPassword ? user.confirmPassword : ''}
        onChange={(evt) => passwordChanged(evt)}
        label={intl.formatMessage({ id: 'ChangePasswordFields.confirmPassword', defaultMessage: 'Confirm password' })}
        error={Boolean(isError.password)}
        helperText={isError.password}
        id="confirmPassword"
        type="password"
        fullWidth
        required
        autoComplete="new-password"
      />

      <div className={strengthClass}>
        <div className="strength-meter-fill" data-strength={strength} />
      </div>
    </>
  );
}

export default ChangePasswordFields;
