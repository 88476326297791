import React, { useState } from 'react';
import { Table, TableBody, TableCell as _TableCell, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';
import cn from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import _ from 'lodash';
import Button from '@mui/material/Button';

const TableCell = withStyles((theme) => ({
  head: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main || '#5d5d5d',
    fontWeight: 'bold'
  }
}))(_TableCell);

const useStyles = makeStyles( (theme)  => ({
   mainContainer: ({isTextButtons}) =>({
      display: 'grid',
      gridTemplateColumns: `1fr ${isTextButtons ? '150px' : '50px'}`,
      height: '100%',
      overflow: 'hidden',
      '@media (max-width: 700px)': {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '75px 1fr'
      }
    }),
    tableContainer: {
      overflow: 'auto',
      height: '100%'
    },
    actionsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      alignContent: 'center',
      flexFlow: 'column nowrap',
      overflowY: 'auto',
      overflowX: 'hidden',
      '@media (max-width: 700px)': {
        order: '-1',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        overflowY: 'initial',
        overflowX: 'auto'
      }
    },
    row: {
      cursor: 'pointer'
    },
    sortableHeadCellContainer: {
      display: 'grid',
      gridTemplateColumns: '24px 1fr',
      gridGap: 2,
      justifyContent: 'flex-start',
      cursor: 'pointer'
    },
    verticalCenter: {
      display: 'flex',
      alignItems: 'center'
    },
    TableCellWithSorting: {
      paddingLeft: 46
    }
    /*  contrastCell: {
    background: 'rgba(0, 0, 0, 0.1)',
  }, */
  }));

const sortOrderEnum = {
  asc: 'asc',
  desc: 'desc'
};

const sortArray = (array, orderBy, order) => {
  if (orderBy === null) return array;
  const sortedArray = _.sortBy(array, getSortingValue);
  if (order === sortOrderEnum.asc) return sortedArray;
  return sortedArray.reverse();
};

function ActionableEditableTable({
  columns,
  rows,
  actions = [],
  selectedRowId,
  setSelectedRowId,
  tableProps,
  isTextButtons
}) {
  const cl = useStyles({isTextButtons});

  const [order, setOrder] = useState(sortOrderEnum.asc);
  const [orderBy, setOrderBy] = useState(null);
  const sortedRows = sortArray(rows, orderBy, order);
  const [editingRow, setEditingRow] = useState({ row: -1, column: null });
  const handleClickOnEditor = (dataKey, id) => {
    setEditingRow({ row: id, column: dataKey });
  };

  const handleSort = (dataKey) => {
    if (dataKey === orderBy && order === sortOrderEnum.asc) {
      setOrder(sortOrderEnum.asc);
      setOrderBy(null);
      return;
    }
    const isDesc = orderBy === dataKey && order === sortOrderEnum.desc;
    setOrder(isDesc ? sortOrderEnum.asc : sortOrderEnum.desc);
    setOrderBy(dataKey);
  };
  //  const withUUID = (callback) => (...args) => callback(uuid, ...args);

  return (
    <div className={cl.mainContainer}>
      <div className={cl.tableContainer}>
        <Table stickyHeader {...tableProps}>
          <TableHead>
            <TableRow>
              {columns.map((cell, index) =>
                cell.sortable ? (
                  <TableCell
                    key={index}
                    className={cn(cl.tableHeadCell, cl.flexContainer)}
                    onClick={() => handleSort(cell.identifier)}
                    style={cell.style}
                  >
                    <div className={cl.sortableHeadCellContainer}>
                      <span className={cl.verticalCenter}>
                        {orderBy === cell.identifier &&
                          (order === sortOrderEnum.asc ? <ExpandMoreIcon /> : <ExpandLessIcon />)}
                      </span>
                      <span className={cl.verticalCenter}>{cell.label}</span>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell style={cell.style} key={index}>
                    {cell.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedRows.map((row, index) => {
              return (
                <TableRow
                  hover
                  onClick={() => setSelectedRowId(row.id)}
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  selected={selectedRowId === row.id}
                  className={cl.row}
                >
                  {columns.map((column) => {
                    const Editor = column.editor;
                    return row.id === editingRow.row &&
                      column.identifier == editingRow.column.identifier &&
                      column.editor ? (
                      <Editor
                        fullWidth
                        style={column.style}
                        value={getDisplayValue(row, column.identifier)}
                        onChange={column.onChange ? column.onChange(row.id) : null}
                      />
                    ) : (
                      <TableCell
                        className={cn({
                          [cl.TableCellWithSorting]: column.sortable,
                          [cl.contrastCell]: index % 2 === 0
                        })}
                        style={column.style}
                        onClick={() => handleClickOnEditor(column, row.id)}
                        key={column.identifier}
                      >
                        {column.displayValue ? column.displayValue(row.id) : getDisplayValue(row, column.identifier)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className={cl.actionsContainer}>
        {actions &&
          actions.map((action, index) => {
            return action.name ? (
              <Button key={index} disabled={action.disabled} onClick={(e) => action.onClick(selectedRowId, e)}>
                {action.name}
              </Button>
            ) : (
              <IconButton
                key={index}
                disabled={action.disabled}
                onClick={(e) => action.onClick(selectedRowId, e)}
                size="large">
                <action.icon />
              </IconButton>
            );
          })}
      </div>
    </div>
  );
}

export default ActionableEditableTable;

const getDisplayValue = (row, identifier) => {
  const directCall = () => _.get(row, `[${identifier}]`, 'NaN');
  return _.get(row, `[${identifier}].displayValue`, directCall());
};

const getSortingValue = (value) => _.get(value, `sortingValue`, value);
