import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { findNode } from "../findNode";
import { getCheckboxes, getSelectors } from "./SafetyInspectionReportOptions";
import {
  generateSafetyInspectionReport,
  generateSingleSafetyInspectionReport
} from "@zert-packages/actions/checklistActions";
import { v4 as uuidv4 } from 'uuid';
import generalRadioBtns from "../generalRadioBtns";
import { generalDateSelectors } from "../dateSelectors";

export default function useSafetyInspectionReport({ selectedNode, selectedValues, catalog, questionaireTemplates }) {
  const isSingle = selectedValues && selectedValues.length === 1;

  const caption = <FormattedMessage id="reports.SafetyInspection.Name" defaultMessage="Safety Inspection" />;
  const description = (
    <FormattedMessage
      id="safetyinspection.report.description"
      defaultMessage="This report provides an overview of different response categories over a period of time and with possible actions."
    />
  );
  const index = 'safetyinspection-overview';
  const dispatch = useDispatch();
  const radiobtns = catalog ? generalRadioBtns : [];
  // eslint-disable-next-line no-use-before-define
  const dateSelectors = generalDateSelectors;
  const useDate = !isSingle;
  const selectors = !isSingle ? getSelectors() :[];
  // eslint-disable-next-line no-use-before-define
  const checkBoxes = !isSingle ? getCheckboxes() :[];

  if (catalog) {
    checkBoxes.push({
      name: 'cb_include_archived',
      section: 'scope',
      label: <FormattedMessage id="reports.safetyinspection.showArchied" defaultMessage="Show archived templates" />,
      checked: false
    })
  }

  const multiSelectors =
    questionaireTemplates && catalog
      ? [
        {
          name: 'si_templates',
          section: 'templates',
          label: <FormattedMessage id="reports.templates" defaultMessage="Templates" />,
          selected: questionaireTemplates,
          options: questionaireTemplates,
          filterFn: (getItems) => (v) => (!v?.archivedAt || getItems('cb_include_archived'))
        }
      ]
      : [];

  const generateReport = (checkedItems) => {
    /* report,
            this.props.catalog ? this.props.catalog.info.versionId : -1,
            this.props.selected ? this.props.selected.split('^')[0] : 'none',
            this.state.checkedItems.get('reports.subnode'),
            this.state.checkedItems.get('cb_separateImages'),
            this.state.checkedItems.get('cb_include_archived'),
            0,
            !this.props.selectedValues ? [] : this.props.selectedValues */
    if (isSingle) {
      dispatch(
        generateSingleSafetyInspectionReport(selectedValues[0].versionId, {
          name: caption,
          reportUUID: uuidv4()
        })
      );
    } else {
      const selectedTemplates = checkedItems('si_templates') || questionaireTemplates;
      const reportSettings = {
        statusDiagram:  checkedItems('cb_status_diagram'),
        statusTable: checkedItems('cb_status_table'),
        versionIds: selectedValues.map(v => v.versionId),
        includeMode: checkedItems('si_includeMode'),
        categories: checkedItems('cb_unmet_categories'),
        identifiedMeasures: checkedItems('cb_identified_measures'),
        sortColumn: checkedItems('si_sortColumn'),
        from: useDate ? checkedItems('reports.from') : null,
        to: useDate ? checkedItems('reports.to') : null,
        templates: selectedTemplates ? selectedTemplates.map(v => v.id) : null
      };

      dispatch(
        generateSafetyInspectionReport(-1, {
            name: caption,
            reportUUID: uuidv4()
          },
          catalog ? catalog.info.versionId : -1,
          findNode(catalog, selectedNode),
          catalog ? checkedItems('reports.subnode') : 'node',
          JSON.stringify(reportSettings)
        )
      );
    }

  };

  return {
    index,
    generateReport,
    caption,
    description,
    radiobtns,
    dateSelectors,
    selectors,
    checkBoxes,
    multiSelectors,
    useDate,
    key: 'safetyinspection-overview'
  };
}
