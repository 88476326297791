import React, { useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';

function AddComment({ unmountComponent, onConfirm }) {
  const [name, setName] = useState('');
  const handleSave = () => {
    unmountComponent();
    onConfirm(name);
  };

  return (
    <Dialog open onClose={unmountComponent} fullWidth>
      <DialogTitle>
        <FormattedMessage id="AddComment.Title" defaultMessage="Add comment" />
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          multiline
          rows="10"
          value={name}
          onChange={(event) => setName(event.target.value)}
          label={<FormattedMessage id="AddComment.title.name.label" defaultMessage="Comment" />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={unmountComponent} color="primary">
          <FormattedMessage id="AddComment.closeButton" defaultMessage="Cancel" />
        </Button>
        <Button onClick={handleSave} color="primary" variant="outlined">
          <FormattedMessage id="AddComment.saveButton" defaultMessage="Save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default injectIntl(AddComment);
