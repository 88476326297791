import { FormattedMessage, injectIntl } from 'react-intl';
import React, { Fragment, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useElementOpen } from '@zert-packages/components/ElementTile/utils/useElementOpen';
import EnhancedTable from '@zert-packages/components/EnhancedTable/EnhancedTable';
import { useDefaultActions } from '@zert-packages/components/EnhancedTable/helpers/useDefaultActions';

import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import { useDetailsSorter } from './helpers/useDetailsSorter';

export const columns = [
  {
    identifier: 'version',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.Version" defaultMessage="Version" />
  },
  {
    identifier: 'purpose',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.Purpose" defaultMessage="Purpose" />
  },
  {
    identifier: 'id',
    numeric: true,
    disablePadding: false,
    align: 'right',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.Id" defaultMessage="Id" />
  },
  {
    identifier: 'stylesheet',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.StylesSheet" defaultMessage="Stylesheet" />
  },
  {
    identifier: 'publishedByM',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.PublishedBy" defaultMessage="PublishedBy" />
  },
  {
    identifier: 'publishedAt',
    numeric: false,
    disablePadding: false,
    align: 'left',
    style: 'nowrapclass',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.PublishedAt" defaultMessage="PublishedAt" />
  },
  {
    identifier: 'updatedAt',
    numeric: false,
    disablePadding: false,
    align: 'left',
    style: 'nowrapclass',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.UpdatedAt" defaultMessage="UpdatedAt" />
  }
];

const PUBLISH_ACTIONS_TYPES = {
  edit: 'edit'
};

function PublishingDetailsTable({ model, maxHeight, loading, selectionModel, intl }) {
  const { open } = useElementOpen();
  const { getSorter } = useDetailsSorter();
  const dispatch = useDispatch();
  const getCustomFilter = (element, name) => {
    switch (name) {
      case 'name':
        return element.elementInfo.name;
      case 'mimeType':
        const plugin = getPluginByMimeType(element.elementInfo.mimeType);
        if (intl && plugin && plugin.trkey) {
          return intl.formatMessage({ id: plugin.trkey, defaultMessage: plugin.defaultMessage });
        }
        return element.elementInfo.mimeType;
      case 'publishedByM':
        if (element.publishedBy) {
          return element.publishedBy.displayName;
        }
        return '';
      case 'stylesheet':
        if (element.layout) {
          return `${element.layout.name}(${element.layout.label})`;
        }
        return '';

      case 'comments':
        return element.comments.length;

      // case "comments" :
      //     return element.commentsCount > 0  ? element.commentsCount : "";
      default:
        return null;
    }
  };

  const columnHandler = () => {
    const _handler = {
      get(target, key) {
        if (key == 'customComparator') {
          return getSorter(target.identifier);
        }
        if (key == 'customClickHandler' && target.identifier == 'nameNew') {
          return (row, column, event) => {
            open(row.elementInfo, column, event);
          };
        }
        if (key == 'customClickHandler' && target.identifier != 'nameNew') {
          return null;
        }
        return target[key];
      }
    };
    return _handler;
  };

  const columnModel = columns ? columns.map((column) => new Proxy(column, columnHandler(column))) : [];

  return (
    <EnhancedTable
      rows={model}
      identifier="publishingId"
      columns={columnModel}
      maxHeight={maxHeight}
      actions={useDefaultActions}
      sortingKey="updatedAt"
      loading={loading}
      getCustomFilter={getCustomFilter}
      sortingOrder="desc"
      selectionModel={selectionModel}
      doNotShowToolbar
      doNotShowSelect
      selectOnlyOne
    />
  );
}

export default injectIntl(PublishingDetailsTable);

// export default injectIntl(memo(ElementTileTable));
