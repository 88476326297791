export const addColumnData = [
  {
    width: {
      value: 12,
      unit: 'POINTS'
    },
    disable: true,
    content: 'default-disable',
    userContent: null,
    align: 'LEFT',
    alignLast: 'LEFT',
    verticalAlign: 'TOP',
    fillBefore: 'NONE',
    fillAfter: 'NONE',
    fontFamily: 'zert-category-heading',
    fontSize: {
      value: 10,
      unit: 'POINTS'
    },
    fontStyle: [],
    color: null,
    backgroundColor: null,
    spaceLeft: {
      value: 0,
      unit: 'POINTS'
    },
    spaceRight: {
      value: 0,
      unit: 'POINTS'
    },
    spaceTop: {
      value: 0,
      unit: 'POINTS'
    },
    spaceBottom: {
      value: 0,
      unit: 'POINTS'
    },
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    }
  },
  {
    width: {
      value: 12,
      unit: 'POINTS'
    },
    content: 'PARAGRAPH_TEXT',
    userContent: null,
    align: 'LEFT',
    alignLast: 'LEFT',
    verticalAlign: 'TOP',
    fillBefore: 'NONE',
    fillAfter: 'NONE',
    fontFamily: 'zert-category-heading',
    fontSize: {
      value: 10,
      unit: 'POINTS'
    },
    fontStyle: [],
    color: null,
    backgroundColor: null,
    spaceLeft: {
      value: 0,
      unit: 'POINTS'
    },
    spaceRight: {
      value: 0,
      unit: 'POINTS'
    },
    spaceTop: {
      value: 0,
      unit: 'POINTS'
    },
    spaceBottom: {
      value: 0,
      unit: 'POINTS'
    },
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    }
  },
  {
    width: {
      value: 10,
      unit: 'MILLIMETER'
    },
    content: 'PAGE_NUMBER',
    userContent: null,
    align: 'RIGHT',
    alignLast: 'LEFT',
    verticalAlign: 'TOP',
    fillBefore: 'NONE',
    fillAfter: 'NONE',
    fontFamily: 'zert-category-heading',
    fontSize: {
      value: 10,
      unit: 'POINTS'
    },
    fontStyle: [],
    color: null,
    backgroundColor: null,
    spaceLeft: {
      value: 0,
      unit: 'POINTS'
    },
    spaceRight: {
      value: 0,
      unit: 'POINTS'
    },
    spaceTop: {
      value: 0,
      unit: 'POINTS'
    },
    spaceBottom: {
      value: 0,
      unit: 'POINTS'
    },
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    }
  },
  {
    width: {
      value: 39.5,
      unit: 'MILLIMETER'
    },
    content: 'USER_DEFINED',
    userContent: '',
    align: 'LEFT',
    alignLast: 'LEFT',
    verticalAlign: 'TOP',
    fillBefore: 'NONE',
    fillAfter: 'NONE',
    fontFamily: 'zert-category-heading',
    fontSize: {
      value: 10,
      unit: 'POINTS'
    },
    fontStyle: [],
    color: null,
    backgroundColor: null,
    spaceLeft: {
      value: 0,
      unit: 'POINTS'
    },
    spaceRight: {
      value: 0,
      unit: 'POINTS'
    },
    spaceTop: {
      value: 0,
      unit: 'POINTS'
    },
    spaceBottom: {
      value: 0,
      unit: 'POINTS'
    },
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    }
  },
  {
    width: null,
    content: 'PARAGRAPH_NUMBER_AND_TEXT',
    userContent: null,
    align: 'LEFT',
    alignLast: 'LEFT',
    verticalAlign: 'TOP',
    fillBefore: 'NONE',
    fillAfter: 'NONE',
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    spaceLeft: {
      value: 0,
      unit: 'POINTS'
    },
    spaceRight: {
      value: 0,
      unit: 'POINTS'
    },
    spaceTop: {
      value: 0,
      unit: 'POINTS'
    },
    spaceBottom: {
      value: 0,
      unit: 'POINTS'
    },
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    }
  },
  {
    width: null,
    content: 'PAGE_LAYOUT_NUMBER',
    userContent: null,
    align: 'LEFT',
    alignLast: 'LEFT',
    verticalAlign: 'TOP',
    fillBefore: 'NONE',
    fillAfter: 'NONE',
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    spaceLeft: {
      value: 0,
      unit: 'POINTS'
    },
    spaceRight: {
      value: 0,
      unit: 'POINTS'
    },
    spaceTop: {
      value: 0,
      unit: 'POINTS'
    },
    spaceBottom: {
      value: 0,
      unit: 'POINTS'
    },
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    }
  },
  {
    width: null,
    content: 'PARAGRAPH_NUMBER',
    userContent: null,
    align: 'LEFT',
    alignLast: 'LEFT',
    verticalAlign: 'TOP',
    fillBefore: 'NONE',
    fillAfter: 'NONE',
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    spaceLeft: {
      value: 0,
      unit: 'POINTS'
    },
    spaceRight: {
      value: 0,
      unit: 'POINTS'
    },
    spaceTop: {
      value: 0,
      unit: 'POINTS'
    },
    spaceBottom: {
      value: 0,
      unit: 'POINTS'
    },
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    }
  },
  {
    width: null,
    content: 'PAGE_LAYOUT_AND_PAGE_NUMBER',
    userContent: null,
    align: 'LEFT',
    alignLast: 'LEFT',
    verticalAlign: 'TOP',
    fillBefore: 'NONE',
    fillAfter: 'NONE',
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    spaceLeft: {
      value: 0,
      unit: 'POINTS'
    },
    spaceRight: {
      value: 0,
      unit: 'POINTS'
    },
    spaceTop: {
      value: 0,
      unit: 'POINTS'
    },
    spaceBottom: {
      value: 0,
      unit: 'POINTS'
    },
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    }
  }
];
