import CatalogCard from '../CatalogElements/CatalogCard';
import React, { useEffect, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { updateCounters, findCounted } from '@zert-packages/actions/catalogActions';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import getTranslation from '@zert-packages/utils/getTranslation.new';

function CatalogMetrics({ catalog, selected }) {
  const loadingCounters = useSelector((state) => state.loadingCounters);
  const countersData = useSelector((state) => state.countersData);
  const [metrics, setMetrics] = useState([]);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const toggle = () => {
    setShow(!show);
  };

 /* useEffect(() => {
    if (catalog && selected) {
      dispatch(updateCounters(catalog.info.versionId, selected.split('^')[0]));
    }
  }, []);*/

  useEffect(() => {
    if (catalog && countersData && countersData.nodeuuid == selected.split('^')[0]) {
      setMetrics(catalog.counters.map((counter, index) => {
        if (counter.clientCounterType == 'OVERVIEW' && countersData[index]) {
          return (
              <div data-intro-id="CatalogExplorerPage.Counter1">
                <MetricsBars
                    caption={counter.label.defaultLabel}
                    first_label="TOTAL"
                    second_label="SCORE"
                    first_value="48"
                    second_value="5"
                    bars={[]}
                />
              </div>
          );
        }
        if (counter.clientCounterType == 'COUNT' && countersData[index]) {
          const value = countersData[index].left;
          const counterData = countersData[index];
          const id = index;
          return (
              <div data-intro-id="CatalogExplorerPage.Counter1">
                <MetricsProgress
                    caption={getTranslation(counter.label)}
                    first_label="COUNT"
                    onClick={(e) => {
                      dispatch(findCounted(selected.split('^')[0], catalog.info.id, id, counter));
                    }}
                    counter={counterData}
                    index={index}
                    second_label=""
                    value={value}
                />
              </div>
          );
        }
        if (counter.clientCounterType == 'COMPARE' && countersData[index]) {
          const {left} = countersData[index];
          const {right} = countersData[index];

          return (
              <div data-intro-id="CatalogExplorerPage.Counter1" >
                <MetricsDoubleProgress
                    caption={counter.label.defaultLabel}
                    first_label={counter.labelCompareLeft.defaultLabel}
                    second_label={counter.labelCompareRight.defaultLabel}
                    first_value={left}
                    second_value={right}
                />
              </div>
          );
        }

      }));
    } else {
      setMetrics(
          [<FormattedMessage
              id="catalog.metrics.notsetup"
              defaultMessage="No Counters & Overview configured for this node."
          />]
      );
    }
  }, [countersData]);

  useEffect(() => {
    if (!selected || !show) {
      return;
    }





     dispatch(updateCounters(catalog.info.versionId, selected.split('^')[0]));

  }, [selected, show]);

  MetricsBars.propTypes = {
    caption: PropTypes.string.isRequired,
    first_label: PropTypes.string,
    second_label: PropTypes.string,
    first_value: PropTypes.number,
    second_value: PropTypes.number,
    bars: PropTypes.array
  };

  function MetricsBars({ caption, first_label, second_label, first_value, second_value, bars }) {
    return (
      <div className="metrics_item">
        <div className="metrics_item_caption">{caption}</div>
        <div className="previleges_caption">
          <div id="componentBARs">
            <div className="rrRiskText">{first_value}</div>
            <div className="rrRiskBars">
              <div className="barGray" style={{ height: '25%' }} />
              <div className="barGray" style={{ height: '75%' }} />
              <div className="barGray" style={{ height: '15%' }} />
              <div className="barYellow" style={{ height: '95%' }} />
              <div className="barRed" style={{ height: '35%' }} />
              <div className="barGray" style={{ height: '15%' }} />
              <div className="barGray" style={{ height: '15%' }} />
              <div className="barGray" style={{ height: '55%' }} />
              <div className="barGray" style={{ height: '45%' }} />
              <div className="barGray" style={{ height: '5%' }} />
            </div>
            <div className="rrRiskScore">{second_value}</div>
          </div>
        </div>
        {/* <div style={{padding: "3px 0 0 0"}}>
                <span className="metrics_graph_legend_left">{first_label}</span>
                <span className="metrics_graph_legend_right">{second_label}</span>
              </div> */}
      </div>
    );
  }

  MetricsProgress.propTypes = {
    caption: PropTypes.string.isRequired,
    first_label: PropTypes.string,
    second_label: PropTypes.string,
    value: PropTypes.number,
    onClick: PropTypes.object
  };

  function MetricsProgress({ caption, first_label, second_label, value, onClick }) {
    return (
      <div className="metrics_item" onClick={onClick}>
        <div className="metrics_item_caption">{caption}</div>
        <div className="previleges_caption">
          <div className="rrProgres">
            <div className="progrText">{value}</div>
            <div className="progrGray">
              <div className="progrGreen" style={{ width: `${value / 1}%` }}>
                <div className="progrLoader" />
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{padding: "3px 0 0 0"}}>
                <span className="metrics_graph_legend_left">{first_label}</span>
                <span className="metrics_graph_legend_right">{second_label}</span>
              </div> */}
      </div>
    );
  }

  MetricsDoubleProgress.propTypes = {
    caption: PropTypes.string.isRequired,
    first_label: PropTypes.string,
    second_label: PropTypes.string,
    first_value: PropTypes.number,
    second_value: PropTypes.number
  };

  function MetricsDoubleProgress({ caption, first_label, second_label, first_value, second_value }) {
    const perc = first_value + second_value > 0 ? (first_value / (first_value + second_value)) * 100 : 0;
    return (
      <div className="metrics_item">
        <div className="metrics_item_caption">{caption}</div>
        <div className="previleges_caption">
          <div id="componentProgres" className="rrProgres acProgres" style={{ padding: '8px 0 0 0' }}>
            <div className="progrText">{first_value}</div>
            <div className="completedGray">
              <div className="progresColor1" style={{ flexBasis: `${perc}%` }}>
                <div className="progrLoader" />
              </div>
              <div className="progresColor2" />
              <div className="progresColor3" />
            </div>
            <div className="progrText">{second_value}</div>
          </div>
        </div>
        {/* <div style={{padding: "3px 0 0 0"}}>
                <span className="metrics_graph_legend_left">{first_label}</span>
                <span className="metrics_graph_legend_right">{second_label}</span>
              </div> */}
      </div>
    );
  }



  return (
    <CatalogCard
      show={show}
      handleShow={toggle}
      title={<FormattedMessage id="catalog.metrics.caption" defaultMessage="Metrics" />}
      content={
        <div id="userPrivileges">
          {loadingCounters && (
            <Loader loaderAction={LoaderAction.None}>
              <FormattedMessage id="catalog.metrics.loading" defaultMessage="Calculating metrics" />
            </Loader>
          )}
          {!loadingCounters && countersData&& selected && countersData.nodeuuid == selected.split('^')[0] && metrics}
        </div>
      }
    />
  );
}

export default CatalogMetrics;
