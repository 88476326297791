export const ROUTE_TYPES = {
  SIMPLE: 'SIMPLE',
  WITH_BACK_AND_LINK_NEW: 'WITH_BACK_AND_LINK_NEW',
  WITH_BACK_AND_NEW_SIDE_BAR: 'WITH_BACK_AND_NEW_SIDE_BAR',
  ELEMENT: 'ELEMENT',
  NEWS: 'NEWS',
  NOTIFICATION: 'NOTIFICATION',
  USERS_IN_COMPANY: 'USERS_IN_COMPANY',
  GROUPS_IN_COMPANY: 'GROUPS_IN_COMPANY',
  USER_EDIT_COMPANY: 'USER_EDIT_COMPANY',
  COMPANY: 'COMPANY',
  PROVISIONING: 'PROVISIONING',
  CATALOG: 'CATALOG',
  SOFTTEXT: 'SOFTTEXT'
};
