import React from 'react';
import './style.css';
import SvgIcon from '@zert-packages/icons/SvgIcon/index';

class ButtonRM extends React.Component {
  render() {
    const content = React.Children.toArray(this.props.children);
    return (
      <button
        className={`btn-vaadin ${this.props.styleName}`}
        onClick={this.props.whenClick}
        disabled={this.props.disabled}
      >
        <span className="bg-overlay" />
        {this.props.iconName !== undefined && <SvgIcon icon={this.props.iconName} fill={this.props.fillName} />}
        {this.props.text !== undefined && (
          <span className={`text-data ${this.props.colorName}`}>
            {this.props.text} {content.length > 0 && content[0]}
          </span>
        )}
      </button>
    );
  }
}

export default ButtonRM;
