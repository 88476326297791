import React, { useCallback, useState } from 'react';
import { Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';

const StyledTooltip = withStyles({
  tooltip: {
    fontSize: '1rem'
  }
})(Tooltip);

function SlowTooltip({ delayEnter = 1000, children, title }) {
  const [showing, setShowing] = useState(false);
  const changeShowingStatus = useCallback(
    _.debounce((value) => {
      if (value) setShowing(value);
    }, delayEnter),
    [delayEnter]
  );

  const onMouseEnter = () => changeShowingStatus(true);
  const onMouseLeave = () => {
    changeShowingStatus(false);
    setShowing(false);
  };
  return (
    <StyledTooltip
      open={showing}
      title={title}
      timeout={0}
      TransitionProps={{
        timeout: 0
      }}
    >
      {React.cloneElement(children, {
        onMouseEnter,
        onMouseLeave
      })}
    </StyledTooltip>
  );
}

export default SlowTooltip;
