import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { loadMatrixes, loadMatrixTemplates, storeMatrixConfiguration } from '@zert-packages/actions/coreReducers';
import IconButton from '@mui/material/IconButton';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Delete from '@mui/icons-material/Delete';
import FormField from '@zert-packages/components/shared/FormElements/FormField';
import { controlStyles } from '@zert-packages/components/shared/FormElements/StylesHelper';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SelectField from '@zert-packages/components/shared/FormElements/SelectField';

class MatrixSelectRow extends React.Component {
  constructor(props) {
    super(props);
    const indexSV = this.props.matrix.locales.indexOf('sv');
    const indexEn = this.props.matrix.locales.indexOf('en');
    this.state = {
      name: this.props.matrix.translations[indexSV],
      nameSV: this.props.matrix.translations[indexEn]
    };
    this.fieldStateChanged = (field, fieldvalue) => (state) =>
      this.setState({
        [field]: state.errors.length === 0,
        [fieldvalue]: state.value
      });

    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.nameChanged = this.nameChanged.bind(this);
    this.translationChanged = this.translationChanged.bind(this);
  }

  nameChanged(name) {
    this.setState({ name: name.value });

    const index = this.props.matrix.locales.indexOf('sv');
    if (index > -1) {
      this.props.matrix.translations[index] = name.value;
    } else {
      this.props.matrix.locales.push('sv');
      this.props.matrix.translations.push(name.value);
    }
  }

  translationChanged(name) {
    this.setState({ nameSV: name.value });
    const index = this.props.matrix.locales.indexOf('en');
    if (index > -1) {
      this.props.matrix.translations[index] = name.value;
    } else {
      this.props.matrix.locales.push('en');
      this.props.matrix.translations.push(name.value);
    }
  }

  handleMultiSelectChange(value) {
    this.props.matrix.elementId = value.value;
    const { matrixes } = this.props;
    const matrix = matrixes.find((matrix) => {
      return matrix.id === value.value;
    });
    if (matrix) {
      const indexSV = this.props.matrix.locales.indexOf('sv');
      const indexEn = this.props.matrix.locales.indexOf('en');

      this.setState({ name: matrix.name });

      this.setState({ nameSV: matrix.name });
      this.props.matrix.translations.length = 0;
      this.props.matrix.translations.push(matrix.name);
      this.props.matrix.translations.push(matrix.name);
      this.props.matrix.locales.length = 0;
      this.props.matrix.locales.push('en');
      this.props.matrix.locales.push('sv');
    }
  }

  componentDidMount() {}

  render() {
    const { id, matrix, classes, intl, matrixes, configuration } = this.props;
    const matrixesValues = [];
    let selected = -1;
    if (matrixes) {
      matrixes.map((matrix) => {
        matrixesValues.push({ value: matrix.id, label: matrix.name });
        if (this.props.matrix.elementId === matrix.id) {
          selected = matrix.id;
        }
      });
    }
    return (
      <tr className="notSelectedTableRow">
        <td>
          <div>
            {id + 1}
            {id > 0 && (
              <IconButton
                aria-label="Up"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.props.matrixUp(id);
                }}
                size="large"
              >
                <ArrowUpward
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.props.matrixUp(id);
                  }}
                />
              </IconButton>
            )}
            {id < configuration.length - 1 && (
              <IconButton
                aria-label="Down"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.props.matrixDown(id);
                }}
                size="large"
              >
                <ArrowDownward
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.props.matrixDown(id);
                  }}
                />
              </IconButton>
            )}
            <IconButton aria-label="Delete" size="large">
              <Delete
                onClick={(e) => {
                  this.props.matrixDelete(id);
                }}
              />
            </IconButton>
          </div>
        </td>
        <td>
          <div>
            {' '}
            <SelectField
              key={`field${matrix.elementId}multi`}
              value={selected}
              label={intl.formatMessage({ id: 'settings.companyeditor.matrixSelectMatrix', defaultMessage: 'Matrix' })}
              placeholder={intl.formatMessage({
                id: 'settings.companyeditor.placeholder.matrixSelectMatrix',
                defaultMessage: 'Select matrix'
              })}
              onStateChanged={this.handleMultiSelectChange}
              values={matrixesValues}
            />
          </div>
        </td>
        <td>
          <div>
            <FormField
              type="text"
              key={`field${matrix.elementId}name`}
              hideLabel
              value={this.state.name}
              label={intl.formatMessage({ id: 'settings.companyeditor.matrixName', defaultMessage: 'Matrix name' })}
              placeholder={intl.formatMessage({
                id: 'settings.companyeditor.matrixName',
                defaultMessage: 'Matrix name'
              })}
              onStateChanged={this.nameChanged}
              required
            />
          </div>
        </td>
        <td>
          <div>
            {' '}
            <FormField
              type="text"
              key={`field${matrix.elementId}namesv`}
              hideLabel
              value={this.state.nameSV}
              label={intl.formatMessage({
                id: 'settings.companyeditor.matrixTranslation',
                defaultMessage: 'Matrix translation'
              })}
              placeholder={intl.formatMessage({
                id: 'settings.companyeditor.matrixTranslation',
                defaultMessage: 'Matrix translation'
              })}
              onStateChanged={this.translationChanged}
              required
            />
          </div>
        </td>
      </tr>
    );
  }
}

class MatrixSelectDialog extends React.Component {
  constructor(props) {
    super(props);

    this.matrixUp = this.matrixUp.bind(this);
    this.matrixDown = this.matrixDown.bind(this);
    this.matrixDelete = this.matrixDelete.bind(this);
    this.matrixAdd = this.matrixAdd.bind(this);
    this.storeMatrixes = this.storeMatrixes.bind(this);
    this.state = { templateId: null };
  }

  matrixUp(id) {
    const element = this.props.configuration.splice(id, 1)[0];
    this.props.configuration.splice(id - 1, 0, element);
    this.forceUpdate();
  }

  matrixDelete(id) {
    this.props.configuration.splice(id, 1);

    this.forceUpdate();
  }

  matrixAdd(e) {
    const matrix = {
      elementId: -1,
      defaultLabel: '',
      translations: ['', ''],
      locales: ['en', 'sv']
    };
    this.props.configuration.push(matrix);
    this.forceUpdate();
  }

  matrixDown(id) {
    const element = this.props.configuration.splice(id, 1)[0];
    this.props.configuration.splice(id + 1, 0, element);
    // configuration.splice(id , 0,
    this.forceUpdate();
  }

  componentDidMount() {
    const { dispatch, templateId, companyId } = this.props;
    if (templateId != this.state.templateId) {
      this.setState({ templateId }, () => {
        dispatch(loadMatrixTemplates(companyId, templateId));
        dispatch(loadMatrixes(companyId));
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, templateId, companyId } = this.props;
    if (templateId != this.state.templateId) {
      this.setState({ templateId }, () => {
        dispatch(loadMatrixTemplates(companyId, templateId));
        dispatch(loadMatrixes(companyId));
      });
    }
  }

  storeMatrixes() {
    const { templateId, companyId, configuration } = this.props;
    async function storeMatrixesAsync(companyId, templateId, configurations) {
      await storeMatrixConfiguration(companyId, templateId, configurations);
    }
    storeMatrixesAsync(companyId, templateId, configuration);
    this.props.template.properties.templatesCount = this.props.configuration.length;
    this.props.storeMatrixes();
  }

  render() {
    const { intl, matrixes, classes, template } = this.props;

    return (
      <Dialog open onClose={this.props.closeMatrix} fullWidth maxWidth="lg">
        <DialogTitle>
          <FormattedMessage
            id="settings.companyeditor.matrixSelected"
            defaultMessage="Select Matrixes {template}"
            values={{ template: template.name }}
          />
        </DialogTitle>
        <DialogContent>
          <div className="form-group px-3 pb-2">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <Button variant="outlined" color="primary" onClick={this.matrixAdd}>
                <FormattedMessage id="settings.companyeditor.addNew" defaultMessage="Add new" />
              </Button>
            </div>

            <div id="machines_info" />
            <table className="settingsTable">
              <thead>
                <tr className="infoTableHeader">
                  <th scope="col">
                    <FormattedMessage id="settings.companyeditor.matrixId" defaultMessage="Matrix Id" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="settings.companyeditor.matrix" defaultMessage="Matrix" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="settings.companyeditor.matrixNameSv" defaultMessage="Label SV" />
                  </th>
                  <th scope="col">
                    <FormattedMessage id="settings.companyeditor.matrixNameEn" defaultMessage="Label EN" />
                  </th>
                </tr>
              </thead>
              <tbody className="tbody">
                {(this.props.loadingMatrixes || this.props.loadingMatrixesConfiguration) && (
                  <Loader displayText={false} />
                )}
                {!(this.props.loadingMatrixes || this.props.loadingMatrixesConfiguration) &&
                  this.props.configuration &&
                  this.props.configuration.map((selectedMatrix, index) => {
                    return (
                      <MatrixSelectRow
                        intl={intl}
                        matrixDelete={this.matrixDelete}
                        matrixUp={this.matrixUp}
                        matrixDown={this.matrixDown}
                        configuration={this.props.configuration}
                        matrixes={matrixes}
                        classes={classes}
                        matrix={selectedMatrix}
                        id={index}
                        key={`action_${selectedMatrix.elementId}`}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeMatrix} color="primary">
            <FormattedMessage id="MatrixSelectDialog.closeButton" defaultMessage="Cancel" />
          </Button>
          <Button onClick={this.storeMatrixes} color="primary" variant="outlined" autoFocus>
            <FormattedMessage id="MatrixSelectDialog.saveButton" defaultMessage="Save" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  configuration: state.configuration,
  matrixes: state.matrixes,
  loadingMatrixes: state.loadingMatrixes,
  loadingMatrixesConfiguration: state.loadingMatrixesConfiguration
});

export default withStyles(controlStyles)(injectIntl(connect(mapStateToProps)(MatrixSelectDialog)));
