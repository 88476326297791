export const anchoredTopicData = {
  class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientAnchoredTopicBlockSettingsTemplate',
  identifier: 'Anchored topic',
  label: 'Anchored topic',
  leftFlow: true,
  flowWidth: {
    value: 48,
    unit: 'PERCENT'
  },
  gap: {
    value: 4,
    unit: 'PERCENT'
  },
  anchoredFormats: [],
  fullWidthFormats: [],
  marginLeft: {
    value: 0,
    unit: 'MILLIMETER'
  },
  marginRight: {
    value: 0,
    unit: 'MILLIMETER'
  },
  separatedBlock: false
};
