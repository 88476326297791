import { Button, Popper } from '@mui/material';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export interface ConfirmPopperProps {
  open: boolean;
  anchorEl: any;
  confirmText?: React.ReactNode;
  confirmAction: () => void;
  cancelText?: React.ReactNode;
  cancelAction: () => void;
}

function ConfirmPopper({ open, anchorEl, confirmText, confirmAction, cancelText, cancelAction }: ConfirmPopperProps) {
  const handleClickAway = () => {
    if (open) {
      cancelAction();
    }
  };

  const handlePopperClick = (event) => {
    event.stopPropagation();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Popper open={open} anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: 1 }}>
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          p={1}
          bgcolor="background.paper"
          boxShadow={3}
          borderRadius={1}
          onClick={handlePopperClick}
        >
          <Button onClick={confirmAction} variant="contained" color="primary" size="small">
            {confirmText || <FormattedMessage id="save" defaultMessage="Save" />}
          </Button>
          <Button onClick={cancelAction} variant="outlined" color="secondary" size="small">
            {cancelText || <FormattedMessage id="cancel" defaultMessage="Cancel" />}
          </Button>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
}

export default ConfirmPopper;
