import React, { useEffect, useState } from "react";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import {adobeWord, handleTableParents } from "../../../utils/helpers";
import SelectedColor from "../../../SelectedColor";
import TemplateItemRow from "../../../TemplateItemRow";
import useStyles from "./useStyles";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogColors from "../../../dialog/DialogColors";


const BorderStyle = ({selectedBorder,setSelectedBorder, selectedLine, findBorder,table, setTable, typeParents, type, values, setValues, disabled}) => {
  const cl = useStyles();
  const dropMenuStyle = ["None", "Solid", "Double", "Groove", "Ridge", "Inset", "Outset", "Dotted"];



  const handleBorderDropMenu = (val) => {
    const res = { ...selectedBorder, style: val.toUpperCase() };
    setSelectedBorder(res)
    if (setTable) {
      return setTable(res, findBorder(), type, values, setValues);
    }
    return handleTableParents(res, findBorder(), typeParents, type, values, setValues);
  };

  const handleColorBorder = (val) => {
    const res = { ...selectedBorder, color: val };
    setSelectedBorder({ ...selectedBorder, color: val })

    if (setTable) {
      return setTable(res, findBorder(), type, values, setValues);
    }
    return handleTableParents(res, findBorder(), typeParents, type, values, setValues);
  };

  const handleOpenDialogBorder = (type) => {
    renderInExceptionRoot(DialogColors, {
      color: table[type],
      title: "Select Color",
      setText: handleColorBorder,
      type
    });
  };

  const handleTexBorder = (val) => {
    const res = { ...selectedBorder, width: { ...selectedBorder.width, value: +val } };
    setSelectedBorder(res)
    if (setTable) {
      return setTable(res, findBorder(), type, values, setValues);
    }
    return handleTableParents(res, findBorder(), typeParents, type, values, setValues);
  };

  const handleBorderMenu = (dropMenu, name, val) => {
    const res = { ...selectedBorder, width: { unit: dropMenu, value: +val } };
    setSelectedBorder(res)
    if (setTable) {
      return setTable(res, findBorder(), type, values, setValues);
    }
    return handleTableParents(res, findBorder(), typeParents, type, values, setValues);
  };




  return(<div className={cl.wrapperBorderStyle}>
    <div className={cl.wrapperStyle}>
      <p className={cl.title}>Style</p>
      <ExpanderItem
        valueProps={adobeWord(selectedBorder.style, true)}
        propsLabel={dropMenuStyle}
        setProps={handleBorderDropMenu}
        type="type"
        disabled={selectedLine === 0 || disabled}
      />
    </div>
    <SelectedColor
      label="Color:"
      type="color"
      setValueProps={handleColorBorder}
      handleOpenDialog={handleOpenDialogBorder}
      valueProps={selectedBorder.color}
      style={{ grid: "50px 30px 1fr 25px" }}
      disabled={selectedLine === 0 || disabled}
    />
    <TemplateItemRow
      label="Width:"
      type="provisionalDistanceBetweenStarts"
      value={selectedBorder.width}
      grid="50px 1fr 55px"
      disabled={selectedLine === 0 || disabled}
      setValueProps={handleTexBorder}
      setDropMenu={handleBorderMenu}
    />
  </div>)
}

export default BorderStyle
