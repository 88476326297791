import React, { useContext } from "react";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogHandleImg from "./dialog/DialogHandleImg/DialogHandleImg";
import { EditorState } from "draft-js";
import { PhraseContext } from "./PhraseEditor";
import QrCodeIcon from "@mui/icons-material/QrCode";
import BlockIcon from "@zert-packages/muiadopticons/BlockIcon";

export const API_ROOT_CALLS = process.env.REACT_APP_API_ROOT_CALLS;

function mediaBlockRenderer(block) {

  if (block.getType() === "atomic") {
    return {
      component: Media,
      editable: false
    };
  }
  return null;
}

const Media = (props) => {
  const { values, setValues } = useContext(PhraseContext);
  let data = {};
  let type = {};

  if (props && props.block) {
    try {
      const entityKey = props?.block?.getEntityAt(0);

      if (entityKey !== null && entityKey !== undefined) {
        const entity = props.contentState.getEntity(entityKey);

        if (entity) {
          data = entity.getData();
          type = entity.getType();
        } else {

        }
      } else {

      }
    } catch (e) {
      console.error("Error:", e.message);
      const res = EditorState.moveFocusToEnd(values.editorState);
      setValues((pre) => ({ ...pre, editorState: res }));
    }
  }

  if (type === "image") {
    return <Image data={data} values={values} setValues={setValues} />;
  }

  return null;
};

const findStyle = (el) => {
  if (typeof (el) === "string" && !el.includes("%")) {
    return +el;
  }
  return el;
};

const Image = ({ data, values, setValues }) => {
  const accessToken = window.localStorage.getItem("accessToken");

  const handleImg = () => {
    renderInExceptionRoot(DialogHandleImg, { data, values, setValues });
  };
  if (data.svg) {
    return (<span style={data.style}><BlockIcon color={data.color}
                       svg={data.src}
                       height={data.width} width={data.height} /></span> );
  }
  return <img
    onClick={handleImg}
    style={{ width: findStyle(data.width), height: findStyle(data.height) }}
    src={`${API_ROOT_CALLS}/image/getImageById/${data.src}?jwtToken=${accessToken}`}
    alt="img" />;
};


export default mediaBlockRenderer;