import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 300,
    minHeight: 400,
    background: "#E2F2F6",
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    padding: 10,
  },
  wrapperTitle: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700,
    textTransform: "capitalize"
  },
  wrapperWord: {
    border: '1px solid #4db1d3',
  },
  activeItem: {
    padding: 5,
    color: "#ffffff",
    background: "#4db1d3",
  },
  item: {
    padding: 5,
    cursor: "pointer"
  },
  wrapperButton: {
    display: "flex",
    gap: 10,
    marginTop: 30,
    justifyContent: "end"
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#4db1d3",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

const SelectAliasDialog = ({alias, addNewAlias}) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [selectedIdx, setSelectedIdx] = useState(-1)

  const handleClose = () => {
    setOpen(false);
  };

  const selectedItem = (i) => {
    setSelectedIdx(i)
  }

  const handleSave = () => {
    addNewAlias(alias.aliasList[selectedIdx], 'values')
    handleClose()
  }


  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>Select alias...</span>
      <CloseIcon onClick={handleClose} />
    </div>
    <div className={cl.wrapperWord}>
      {alias.aliasList.map((el, i) => <div
        onClick={() => selectedItem(i)}
        className={i === selectedIdx ? cl.activeItem: cl.item}
        key={i}
      >
        {el.value}
      </div>)}
    </div>
    <div className={cl.wrapperButton}>
      <Button disabled={selectedIdx < 0} className={cl.button} onClick={handleSave}>
        OK
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>
  </Dialog>);
};

export default SelectAliasDialog;