import CheckboxWithMemo from '@zert-packages/components/common/CheckboxWithMemo';
import { FormattedMessage } from 'react-intl';
import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import NameSearch from './SearchConditions/NameSearch';
import ContextSearch from './SearchConditions/ContextSearch';
import IdSearch from './SearchConditions/IdSearch';
import VersionSearch from './SearchConditions/VersionSearch';
import StatusSearchPanel from './SearchConditions/Status';
import MimeTypeSearch from './SearchConditions/MimeTypeSearch';
import DateSearch from './SearchConditions/DateSearch';
import NodeSearch from './SearchConditions/NodeSearch';
import TemplateSearch from './SearchConditions/TemplateSearch';
import RiskAnalisesSearch from './SearchConditions/RiskAnalisesSearch';
import ChecklistSearch from './SearchConditions/ChecklistSearch';
import LocalSearch from './SearchConditions/LocaleSearch';
import ReferencedSearch from './SearchConditions/ReferencedSearch';
import TranslatableSearch from './SearchConditions/TranslatableSearch';
import TranslationLocked from './SearchConditions/TranslationLocked';
import UserSearch from './SearchConditions/UserSearch';
import BrokenReferenceSearch from './SearchConditions/BrokenReferenceSearch';
import MetadataTemplatesSearch from './SearchConditions/MetadataTemplatesSearch';
import InsideElementSearch from './SearchConditions/InsideElementSearch';
import BlockSearch from './SearchConditions/BlockSearch';
import TableSearch from './SearchConditions/TableSearch';
import SparepartSearch from './SearchConditions/SparepartSearch';
import PublishingFiltersSearch from './SearchConditions/PublishingFiltersSearch';
import SizeSearch from './SearchConditions/SizeSearch';
import ExtensionSearch from './SearchConditions/ExtensionSearch';
import { setSearchIgnoreLocalStorage } from "@zert-packages/plugins/ExplorerPlugin/explorerReducers";


function SearchPane({ startSearchOnSubmit, isAllCompanies, setAllCompanies ,hide,metadataOnly=false,
                    retrieveBaseCriteria: retrieveBaseCriteria = () => [],
                    ignoreLocalStorage = false}) {
  const { myuser, formatTemplate, catalog, publishingFilters } = useSelector((state) => state);
  const dispatch = useDispatch();
  const oldIgnoreLocalState = useSelector((state) => state.CORE.ignoreSearchLocalStorage)
  if (oldIgnoreLocalState != ignoreLocalStorage)
    dispatch(setSearchIgnoreLocalStorage(ignoreLocalStorage));

  return (
    <div style={{ padding: '10px' }}>
      {myuser && !hide && myuser.role === 'administrator' && (
        <CheckboxWithMemo
          isChecked={isAllCompanies}
          label={<FormattedMessage id="search.id.allCompanies" defaultMessage="Search in all companies" />}
          onChange={(isChecked) => {
            setAllCompanies(isChecked);
          }}
        />
      )}
        {!metadataOnly &&
        <div data-intro-id="Search.NameSearch">
            <NameSearch hide={hide} startSearchOnSubmit={startSearchOnSubmit}/>
        </div>
        }


        {metadataOnly &&
        <MetadataTemplatesSearch startSearchOnSubmit={startSearchOnSubmit} />
        }
        {!hide &&
            <div>
                <SizeSearch startSearchOnSubmit={startSearchOnSubmit} />
                <ExtensionSearch startSearchOnSubmit={startSearchOnSubmit} />
                <ContextSearch startSearchOnSubmit={startSearchOnSubmit} />
                <IdSearch startSearchOnSubmit={startSearchOnSubmit} />
                <VersionSearch startSearchOnSubmit={startSearchOnSubmit} />
                <StatusSearchPanel startSearchOnSubmit={startSearchOnSubmit} />
                <MimeTypeSearch startSearchOnSubmit={startSearchOnSubmit} />
                <DateSearch startSearchOnSubmit={startSearchOnSubmit} />
                {!window.ISCLM && catalog && <NodeSearch startSearchOnSubmit={startSearchOnSubmit} />}
                {!window.ISCLM && <TemplateSearch startSearchOnSubmit={startSearchOnSubmit} />}

                {!window.ISCLM && <RiskAnalisesSearch startSearchOnSubmit={startSearchOnSubmit} />}
                {!window.ISCLM && <ChecklistSearch startSearchOnSubmit={startSearchOnSubmit} />}
                {window.ISCLM && <LocalSearch startSearchOnSubmit={startSearchOnSubmit} />}
                <ReferencedSearch startSearchOnSubmit={startSearchOnSubmit} />
                {window.ISCLM && <TranslatableSearch startSearchOnSubmit={startSearchOnSubmit} />}
                {window.ISCLM && <TranslationLocked startSearchOnSubmit={startSearchOnSubmit} />}
                <UserSearch startSearchOnSubmit={startSearchOnSubmit} />
                {window.ISCLM && <BrokenReferenceSearch startSearchOnSubmit={startSearchOnSubmit} />}
                <MetadataTemplatesSearch startSearchOnSubmit={startSearchOnSubmit} />
                <InsideElementSearch startSearchOnSubmit={startSearchOnSubmit} />
                {formatTemplate && formatTemplate.blockFormats && <BlockSearch startSearchOnSubmit={startSearchOnSubmit} />}
                {formatTemplate && formatTemplate.tableFormats && <TableSearch startSearchOnSubmit={startSearchOnSubmit} />}
                {formatTemplate && formatTemplate.partFormats && <SparepartSearch startSearchOnSubmit={startSearchOnSubmit} />}
                {publishingFilters && <PublishingFiltersSearch startSearchOnSubmit={startSearchOnSubmit} />}
            </div>
        }
    </div>
  );
}

export default memo(
  SearchPane,
  selectiveMemoComparison((p) => p.isAllCompanies)
);
