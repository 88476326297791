import Dialog from '@mui/material/Dialog';
import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';

const type = ['Ellips', 'Rectangle'];

const CreateHotspotDialog = ({ dialog, setDialog }) => {
  const [value, setValue] = React.useState({
    type: type[0]
  });
  const [inputValue, setInputValue] = React.useState('');
  const handleChangeFrom = async (event) => {
    // setAgeFrom(event.target.value)
  };


  return (
    <Dialog
      open={dialog}
      onClose={() => {
        setDialog({ open: false, type: null });
      }}
      fullWidth
    >
      <DialogTitle sx={{fontSize: '20px'}}>New hotspot</DialogTitle>
      <DialogContent fullWidth>
        <TextField
          fullWidth
          defaultValue={3}
          type="number"
          label="Position"
          inputProps={{ style: { paddingLeft: '8px' } }}
          onChange={(event) => {
            handleChangeFrom(event);
          }}
          disabled={true}
        />

        <Autocomplete
          defaultValue={value}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="controllable-states-demo"
          options={type}
          renderInput={(params) => <TextField fullWidth {...params} label="Type" />}
          disabled={true}
        />

        <TextField
          fullWidth
          defaultValue={21}
          type="number"
          label="Width"
          inputProps={{ style: { paddingLeft: '8px' } }}
          onChange={(event) => {
            handleChangeFrom(event);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
           setDialog({
             open: false,
             type: null
           })
          }}
          variant="contained"
          color="primary"
        >
          OK
        </Button>
        <Button
          onClick={(e) => {
            setDialog({
              open: false,
              type: null
            })
          }}
          variant="contained"
          color="primary"
        >
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateHotspotDialog;
