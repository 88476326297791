import React from 'react'
import { Table, TableBody, TableRow } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import SkeletonLoader from "@zert-packages/components/SkeletonLoader";
import makeStyles from "@mui/styles/makeStyles";
import { handleValue } from "../../../../BlockPlugin/utils/main";

const useStyles = makeStyles((theme) => ({
  cell: (props) =>({
    fontFamily: props.selectedAvailableFontFamily,
    cursor: 'pointer',
    fontSize: props.fontSizeSymbol,
    '&:hover': {
      background: '#01A1C7',
      color: '#ffffff',
    },
  }),
  ActiveCell: (props) =>({
    fontFamily: props.selectedAvailableFontFamily,
  }),
  wrapperSymbol: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}))


const SymbolTable = ({symbol, setSymbol}) => {
  const {font, selectedFontArial, selectedAvailableFontFamily, fontSizeSymbol} =symbol
  const cl = useStyles(
    {
      selectedAvailableFontFamily,
      fontSizeSymbol
    });

  const handleSymbol = (props) => {
    const res = props
    console.log(props);
    // handleValue(res, 'selectedSymbol', setSymbol)
  }



  if(!selectedFontArial){
    return  <SkeletonLoader height={300} style={{ marginLeft: 5 }} />
  }
  const getCellsColumns = (start, to) => {
    const array = [];
    for (let c = start; c < to && c <= '\uFFFF'.charCodeAt(0); c++) {
      const char = String.fromCharCode(c);
      array.push(
        <TableCell
          onClick={() => handleSymbol({ char, c })}
          className={cl.cell}
          hover
          key={`cellId${char}`}
        >
          <div className={cl.wrapperSymbol}>{char}</div>
        </TableCell>
      );
    }
    return array;
  };




  const getCellRows = () => {
    const rows = [];

    for (let c = selectedFontArial.startAt.charCodeAt(0) / 13; c < selectedFontArial.endAt.charCodeAt(0) / 13; c++) {
      rows.push(
        <TableRow
          role="checkbox"
          tabIndex={-1}
          key={`row_${c}`}
        >
          {/*<div className={cl.wrapperSymbol}></div>*/}
            {getCellsColumns(c * 13, (c + 1) * 13)}

        </TableRow>
      );
    }
    return rows;
  };

  return(<div>
    <Table>
      <TableBody>{getCellRows()}</TableBody>
    </Table>
  </div>)
}



export default SymbolTable