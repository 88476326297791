import React, { useState, Fragment } from 'react';
import { findPath } from '@zert-packages/actions/catalogActions';

import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Typography, Avatar, Divider, IconButton } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MoreVert, Favorite, Share, ExpandMore } from '@mui/icons-material';

import { getCardStatus, getTileElementStatus, getTileStatus } from '../utils/getTileStatus';
import OriginLink from '../../common/OriginLink';
import ActionMenuNew from '../../shared/ItemCard/ActionMenu.new';
import { defaultKey, getFillFunction } from "@zert-packages/utils/fillTemplateString";

const fillPath = getFillFunction(defaultKey);

const tileListStyles = makeStyles((theme) => ({
  transform: 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const tileListStylesExpand = makeStyles((theme) => ({
  transform: 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

function ImageTileIcon({ selectElement, element, isSelected }) {
  const selected = isSelected();
  const [coverError, setCoverError] = useState(false);
  const accessToken = window.localStorage.getItem('accessToken');
  const onImgError = () => setCoverError(true);
  const Icon = getPluginByMimeType(element.mimeType).icon;
  if (coverError)
    return (
      <div
        className="icon-box"
        onClick={selectElement}
        title={selected ? 'Click to deselect element' : 'Click to select element'}
      >
        <Icon style={{ height: '180px', width: '180px', fill: selected ? '#ffb951' : 'lightgray' }} />
      </div>
    );

  return (
    <CardMedia
      onClick={selectElement}
      component="img"
      height="180"
      style={{ backgroundColor: selected ? '#ffb951' : 'transparent' }}
      image={`/zert/common/getPreviewById/${element.versionId}?jwtToken=${accessToken}`}
      alt={selected ? 'Click to deselect element' : 'Click to select element'}
      onError={onImgError}
    />
  );
}

export default function withCard(expandFunction, openFunction) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.openElement = this.openElement.bind(this);
      this.selectElement = this.selectElement.bind(this);
      this.treedotsClass = 'MuiSvgIcon-root';
      this.treedotsClassSpan = 'rowMenu';
      this.treedotsClassDiv = 'dotsmenu';
      this.dropdownClassDiv = 'dropdown-menu';
      this.dropdownItemClassDiv = 'dropdown-item';
      this.isSelected = this.isSelected.bind(this);
      this.state = {
        showCL: false,
        checklists: [],
        isSelected: false,
        raoverview: null,
        uncorrectanswers: null
      };
      this.expand = this.expand.bind(this);
    }

    isSelected() {
      return this.state.isSelected;
    }

    expand(e) {
      if (!this.state.showCL && !this.state.raoverview && !this.state.project) {
        this.setState({ loadingoverview: true });
        expandFunction && expandFunction(this.props.element, this.props.dispatch);
      }
      this.setState({ showCL: !this.state.showCL });
      e.preventDefault();
      e.stopPropagation();
    }

    componentDidUpdate(prevProps) {
      if (this.props.catalog && this.props.element.metadataList && !this.state.location2) {
        const location2 = [];
        this.props.element.metadataList.values.map((metadata) => {
          this.props.catalog.map((catalog) => {
            if (catalog && metadata.identifier === 'posid') {
              const meta = `${metadata.value.value}`;
              const placement = findPath(meta, catalog, false);
              location2.push(placement);
            }
          });
        });
        this.setState({ location2 });
      }
    }

    openElement(e) {
      const plugin = getPluginByMimeType(this.props.element.mimeType);
      if (this.props.openFunction) {
        this.props.openFunction(this.props.element, this.props.dispatch);
      } else if (this.props.externalOpen && this.props.externalObject && openFunction) {
        openFunction(this.props.element, this.props.dispatch, this.props.externalOpen, this.props.externalObject);
      } else if (openFunction) {
        openFunction(this.props.element, this.props.dispatch);
      } else if (this.props.element.approvedAt && plugin.approvepath) {
        this.props.history.push(
          getPluginByMimeType(this.props.element.mimeType).approvepath.replace(
            ':versionId',
            this.props.element.versionId
          )
        );
      } else if (e.ctrlKey) {
        window.open(
          fillPath(this.props.element, getPluginByMimeType(this.props.element.mimeType).openpath),
          '_blank'
        );
      } else {
        this.props.history.push(
          fillPath(this.props.element, getPluginByMimeType(this.props.element.mimeType).openpath)
        );
      }
    }

    selectElement(e) {
      if (this.props.selectElement) {
        this.props.selectElement(this.props.element.versionId, this.props.element);
      }
      this.setState({ isSelected: !this.state.isSelected });
      e.preventDefault();
      e.stopPropagation();
    }

    render() {
      let show = {};
      let rotate = 'rotate(180deg)';
      let toggleable = 'none';
      const { element, myuser } = this.props;
      //   let status = getTileStatus(element, myUser);

      if (this.state.showCL) {
        rotate = 'rotate(0deg)';
        toggleable = 'flex';
      }

      const cardBackground = this.state.isSelected ? { backgroundColor: '#fce9be' } : {};

      if (this.props.drawCuted) {
        show = { opacity: '0.4' };
      }

      const location2 = [];
      if (this.props.catalog && this.props.element.metadataList) {
        this.props.element.metadataList.values.map((metadata) => {
          this.props.catalog.map((catalog) => {
            if (catalog && metadata.identifier === 'posid') {
              const meta = `${metadata.value.value}`;
              const placement = findPath(meta, catalog, false);
              if (placement && placement.length > 0) {
                location2.push(placement);
              }
            }
          });
        });
      }

      const { parents } = this.props.element;
      return (
        <Card sx={{ maxWidth: 345 }}>
          <CardHeader
            avatar={
              <Avatar
                className={`info-card ${getTileElementStatus(element, myuser)}`}
                variant="rounded"
                aria-label="rounded"
              >
                {getCardStatus(element, myuser)}
              </Avatar>
            }
            action={
              /* <IconButton aria-label="settings">
                                <MoreVert />
                            </IconButton> */
              <ActionMenuNew
                performAction={this.props.performAction}
                visible="true"
                actions={this.props.actions}
                position="initial"
                isArchived={this.props.element.archivedAt !== null}
                isApproved={this.props.element.approvedAt !== null}
                hasVersions={this.props.element.label !== '1.1'}
                small
              />
            }
            title={this.props.element.name}
            subheader={this.props.element.changedAt}
          />
          <ImageTileIcon element={this.props.element} selectElement={this.selectElement} isSelected={this.isSelected} />
          <CardContent>
            <OriginLink key={`OriginLink${this.props.element.versionId}`} versionId={this.props.element.versionId} />
          </CardContent>
        </Card>
        /* <div className="itemrow-wrapper">
                    <div id="itemrow" className="itemrow" style={show}>
                        <div id="left_side" style={{ flex: '1', height: '100%'}} onClick={this.openElement}>
                            <div className="item-card" style={cardBackground}  >
                                <TileIcon element={this.props.element} selectElement={this.selectElement}
                                          isSelected={this.isSelected}/>
                                <div style={{ flex: '1', height: 'fit-content', padding: '0px 5px'}}>

                                    <div className="si_for_mobile" style={{ display: 'flex' }}>
                                        <div style={{display: 'flex', flexBasis: '100%', alignItems: 'center', flexWrap: 'wrap'}}>
                                            <div className="element_name card-title-container">
                                                <h3 data-searchable-element="1" className="card-title card-title--dark">
                                                    {this.props.element.name}
                                                </h3>
                                            </div>

                                            <div className={expandFunction ? 'expand-wrapper' : 'mimetype-wrapper'}
                                                 onClick={this.expand}>
                                                {expandFunction &&
                                                <div className="expand-button-container" style={{ transform: rotate }}>
                                                    <ButtonExpand/>
                                                </div>}
                                            </div>

                                            {this.props.actions && <MediaQuery query="(max-width: 769px)">
                                                <div className="task-action-button">
                                                    <ActionMenuNew performAction={this.props.performAction} visible='true'
                                                                   actions={this.props.actions} position="initial"
                                                                   isArchived={this.props.element.archivedAt !== null}
                                                                   isApproved={this.props.element.approvedAt !== null}
                                                                   hasVersions={this.props.element.label !== "1.1"}
                                                                   small={true}/>
                                                </div>
                                            </MediaQuery>}

                                            <div className="status_wrapper" style={{marginLeft: 'auto'}}>
                                                {this.props.showInTileOverview && <ContentOverview {...this.props} />}
                                                {this.props.element && <div style={{display: 'flex', marginLeft: 'auto', paddingLeft: '10px'}}>
                                                    <LabelRM text={getTileStatus( element, myuser)} styleName={getTileElementStatus(element, myuser)} />
                                                </div>}
                                            </div>

                                            {this.props.actions  && <MediaQuery query="(min-width: 769px)">
                                                <div className="task-action-button">
                                                    <ActionMenuNew performAction={this.props.performAction} visible='true'
                                                                   actions={this.props.actions} position="initial"
                                                                   isArchived={this.props.element.archivedAt !== null}
                                                                   isApproved={this.props.element.approvedAt !== null}
                                                                   hasVersions={this.props.element.label !== "1.1"}
                                                                   small={true}/>
                                                </div>
                                            </MediaQuery>}
                                        </div>
                                    </div>

                                    <div className="last-modified-container" style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        <h5 className="task-title">
                                            {getPluginByMimeType(this.props.element.mimeType).name}
                                        </h5>

                                        {(this.props.element && this.props.element.properties &&
                                            this.props.element.properties.templateName) && <SlowTooltip title={this.props.element.properties.templateName}>
                                            <h5 className="info">
                                                {this.props.element.properties.templateName}
                                            </h5></SlowTooltip>}

                                        {(this.props.element && this.props.element.properties &&
                                            this.props.element.properties["zert:CurrentActivityResource"]) && <SlowTooltip title={this.props.element.properties["zert:CurrentActivityResource"]}>
                                            <h5 className="info">
                                                {this.props.element.properties["zert:CurrentActivityResource"]}
                                            </h5></SlowTooltip>}

                                        {(this.props.element && this.props.element.properties &&
                                            this.props.element.properties.companyName) && <h5 className="info">
                                            {this.props.element.properties.companyName}
                                        </h5>}
                                    </div>
                                    <LastModifiedLabel element={this.props.element} intl={this.props.intl}/>
                                </div>

                                {(location2 && location2.length !== 0)&& <div style={{
                                    width: '100%',
                                    position: 'relative',
                                }}>
                                    <div style={{
                                        display: 'grid',
                                        gridTemplateColumns: '70px 1fr',
                                        gridTemplateRows: '1fr',
                                        gridTemplateAreas: '. .',
                                        alignItems: 'center',
                                    }}>

                                        <h5 className="info" style={{marginLeft: '0'}}>
                                            <FormattedMessage id="tile.catalog.placement" defaultMessage="Placement:" />
                                        </h5>
                                        <BreadCrumb itemLocation={location2}/>
                                    </div>
                                </div>}

                                {(parents && parents.length !== 0) && <div style={{
                                    width: '100%',
                                    position: 'relative',
                                }}>
                                    <div style={{
                                        display: 'grid',
                                        gridTemplateColumns: '70px 1fr',
                                        gridTemplateRows: '1fr',
                                        gridTemplateAreas: '. .',
                                    }}>
                                        <h5 className="info" style={{marginLeft: '0'}}>
                                            <FormattedMessage id="tile.element.placement" defaultMessage="Origin:" />
                                        </h5>
                                        <BreadCrumbParents itemLocation={parents}/>
                                    </div>
                                </div>}

                                {this.props.showInlineOverview && this.state.showCL &&
                                <RiskOverview {...this.props}  key = {"riskOverview" +this.props.element.versionId} showCL={this.state.showCL}/>}
                                {(this.props.element && this.props.element.properties != null) && this.props.element.properties.activityId &&
                                <div style={{
                                    width: '100%',
                                    position: 'relative',
                                }}>
                                    <div style={{
                                        display: 'grid',
                                        gridTemplateColumns: '70px 1fr',
                                        gridTemplateRows: '1fr',
                                        gridTemplateAreas: '. .',
                                    }}>
                                        <h5 className="info" style={{marginLeft: '0'}}>
                                            <FormattedMessage id={"tile.project.placement"} defaultMessage={"Section:"} />
                                        </h5>
                                        <span style={{fontSize: "0.8rem", opacity: "0.5"}}>{this.props.element.properties.activityId}</span>
                                    </div>
                                </div>}
                            </div>
                            <div style={{
                                flexBasis: "100%",
                                height: "0"
                            }}></div>
                            <div style={{
                                width: "100%",
                                backgroundColor: '#fbfcfd',
                            }}>
                                {(this.state.showCL && !this.props.showInTileOverview) && <ContentOverview key = {"ContentOverview" + this.props.element.versionId} {...this.props} />}
                            </div>
                        </div>
                        {!this.props.showInlineOverview && this.state.showCL &&
                        <RiskOverview  key = {"riskOverview" +this.props.element.versionId} {...this.props} showCL={this.state.showCL}/>}
                    </div>
                </div> */
      );
    }
  };
}
