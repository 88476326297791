import React, { useContext, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { TabsContext } from '../../../../Stylesheet';
import TemplateItemRow from '../../../../TemplateItemRow';
import { adobeDropMenu, adobeWord, handleColum } from '../../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 10
  },
  wrapperTextField: {
    display: 'grid',
    gridTemplateColumns: '100px 300px 1fr 55px',
    gridColumnGap: 10,
    alignItems: 'center'
  },
  wrapperDropMenu: {
    display: 'grid',
    gridTemplateColumns: '195px 1fr ',
    gridColumnGap: 10
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    marginBottom: 5
  },
  noteActiveInputWrapper: {
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  notesInput: {
    margin: 0,
    width: '100%'
  }
}));

function Column({ colum, index, indexFormat, columData, disable }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const data = {
    horizontalAlignment: [{ name: 'Left' }, { name: 'Right' }, { name: 'Center' }, { name: 'Justify' }],
    horizontalAlignmentDropMenu: [{ name: 'Top' }, { name: 'Middle' }, { name: 'Bottom' }],
    fillDropMenu: [{ name: 'None' }, { name: 'Dots' }, { name: 'Line' }]
  };
  const [selectedWidth, setSelectedWidth] = useState('User defined');
  const setDropMenuFirst = (val) => {
    setSelectedWidth(val);
    if (val === 'Expand') {
      handleColum(12, index, indexFormat, 'width', values, setValues, 'value');
      setSelectedWidth('Expand');
    }
  };

  const setValueProps = (val, name) => {
    handleColum(val, index, indexFormat, name, values, setValues, 'value');
  };
  const setDropMenu = (valDropMenu, name, val) => {
    handleColum(
      {
        value: val,
        unit: valDropMenu
      },
      index,
      indexFormat,
      name,
      values,
      setValues
    );
  };

  const handleDropMenu = (val, name) => {
    if (val.name) {
      return handleColum(val.name, index, indexFormat, name, values, setValues);
    }
    handleColum(val, index, indexFormat, name, values, setValues);
  };

  const handleValue = (e) => {
    const val = e.target.value;
    const { name } = e.target;
    handleColum(val, index, indexFormat, name, values, setValues);
  };

  const findSelectedWidth = () => {
    if (!colum) return;
    if (!colum.width) {
      handleColum(
        {
          value: 12,
          unit: 'POINTS'
        },
        index,
        indexFormat,
        'width',
        values,
        setValues
      );
      return setSelectedWidth('Expand');
    }
    if (colum.width && +colum.width.value === 12) {
      return setSelectedWidth('Expand');
    }
    setSelectedWidth('User defined');
  };

  useEffect(() => {
    findSelectedWidth();
  }, [index, colum && colum.width]);

  return (
    <div className={cl.root}>
      {colum && (
        <>
          {colum.width && colum.width.value && (
            <TemplateItemRow
              label="Width:"
              type="width"
              value={{
                value: colum.width.value,
                unit: adobeDropMenu(colum.width.unit)
              }}
              dropMenuFirst={{
                label: ['Expand', 'User defined'],
                selected: selectedWidth
              }}
              grid="195px 150px 1fr 55px"
              type="width"
              setDropMenuFirst={setDropMenuFirst}
              setDropMenu={setDropMenu}
              setValueProps={setValueProps}
              disabled={selectedWidth === 'Expand' || disable}
              disableMenuFirst={disable}
            />
          )}
          <div className={cl.wrapperDropMenu}>
            <div className={cl.title}>Content:</div>
            <ExpanderItem
              valueProps={adobeWord(colum.content, true).replace('_', ' ')}
              propsLabel={columData}
              type="content"
              setProps={handleDropMenu}
              disabled={disable}
            />
          </div>
          <div className={cl.title} />
          <TextField
            value={colum.userContent ? colum.userContent : ''}
            onChange={handleValue}
            multiline
            minRows={4}
            name="userContent"
            className={cl.notesInput}
            variant="outlined"
            disabled={disable}
          />
          <div className={cl.wrapperDropMenu}>
            <div className={cl.title}>Horizontal alignment:</div>
            <div>
              <ExpanderItem
                valueProps={adobeWord(colum.align, true)}
                propsLabel={data.horizontalAlignment}
                type="align"
                setProps={handleDropMenu}
                disabled={disable}
              />
            </div>
          </div>
          <div className={cl.wrapperDropMenu}>
            <div className={cl.title}> Horizontal alignment (last):</div>
            <div>
              <ExpanderItem
                valueProps={adobeWord(colum.alignLast, true)}
                propsLabel={data.horizontalAlignment}
                type="alignLast"
                setProps={handleDropMenu}
                disabled={disable}
              />
            </div>
          </div>
          <div className={cl.wrapperDropMenu}>
            <div className={cl.title}> Vertical alignment:</div>
            <div>
              <ExpanderItem
                valueProps={adobeWord(colum.verticalAlign, true)}
                propsLabel={data.horizontalAlignmentDropMenu}
                type="verticalAlign"
                setProps={handleDropMenu}
                disabled={disable}
              />
            </div>
          </div>
          <div className={cl.wrapperDropMenu}>
            <div className={cl.title}>Fill before:</div>
            <div>
              <ExpanderItem
                valueProps={adobeWord(colum.fillBefore, true)}
                propsLabel={data.fillDropMenu}
                type="fillBefore"
                setProps={handleDropMenu}
                disabled={disable}
              />
            </div>
          </div>
          <div className={cl.wrapperDropMenu}>
            <div className={cl.title}>Fill after:</div>
            <div>
              <ExpanderItem
                valueProps={adobeWord(colum.fillAfter, true)}
                propsLabel={data.fillDropMenu}
                type="fillAfter"
                setProps={handleDropMenu}
                disabled={disable}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Column;
