import React, { useContext } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TabsContext } from "../../Stylesheet";
import Loading from "../../Loading";
import { handleSingleLevelObject } from "../../utils/helpers";
import Characters from "./Characters";

const useStyles = makeStyles((theme) => ({
  wrapperCheckboxItem: {
    marginBottom: 0
  }
}));

function Hyphenation() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);

  const handleCheckbox = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    handleSingleLevelObject(val, name, "hyphenation", values, setValues, true);
  };

  return (
    <>
      {values.stylesheet ?
        <>
          <FormControlLabel
            className={cl.wrapperCheckboxItem}
            control={<Checkbox onChange={handleCheckbox} checked={values.stylesheet.hyphenation} name="hyphenation" />}
            label={<span>Use hyphenation</span>}
          />
          <Characters />
        </>
        : (
          <Loading />
        )}
    </>
  );
}

export default Hyphenation;
