import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    rootHeader: {
        position: 'relative',
        zIndex: 10,
    },
    td: {
        cursor: 'pointer',
        height: 12,
        border: '1px solid rgba(193, 199, 208, 0.95)',
        minWidth: 11,
    },
    clear: {
        zIndex: "10",
        right: 3,
        position: 'relative',
        height: 16,
        width: 16,
        backgroundColor: '#59C1DB',
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 3,
        fontSize: 15,
        fontWeight: 900,
        animation: 'fade-in 0.5s',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#FF0000',
        }
    },
    wrapperContent: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        paddingBottom: 4,
    },
    wrapperAdd: {
        position: 'relative',
        '& div': {
            top: 15,
            left: -5,
        },
    }
}))

export default  useStyles