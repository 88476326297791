import React, { useContext,} from "react";
import { NodeViewWrapper } from "@tiptap/react";
import { getPluginByMimeTypeIcon } from "@zert-packages/utils/getPluginByMimeType";
import BlockIcon from "@zert-packages/muiadopticons/BlockIcon";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";


export default props => {
  const getProps = props.node.attrs;
  const { setValues } = useContext(PanelContext);


  const handleImageLoad = () => {
    setValues(pre => ({...pre, tableImg: getProps.imgUrl }))
  };


  return (
    <NodeViewWrapper className="react-component" style={{ pointerEvents: "none" }}>
      <div
        className="content"
        style={{
          display: "flex",
          gap: 2.5,
          flexFlow: "wrap",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            top: 1,
            height: 15
          }}
        >
          <BlockIcon svg={getPluginByMimeTypeIcon(getProps.type, true)} />
        </div>
        <div>{getProps.name}</div>
        <div> [{getProps.label}]</div>
        {getProps.imgUrl && <img src={getProps.imgUrl} alt="img" onLoad={handleImageLoad} />}
      </div>
    </NodeViewWrapper>
  );
}
