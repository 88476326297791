import React, {useEffect, useRef, useState} from 'react'
import makeStyles from "@mui/styles/makeStyles";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import {FormattedMessage} from "react-intl";
import SaveIcon from "@mui/icons-material/Save";
import {useDispatch, useSelector} from "react-redux";
import {getSideBarMenu} from "../../InfoElement/helpers/getSideBarMenu";
import BurgerMenu from "./BurgerMenu";
import {findSizeMenu} from "../helpers/findSizeMenu";
import {handleActionChildBlockHeader} from "@zert-packages/actions/commonReducer";

const useStyles = makeStyles((theme) => ({
    editorBlockHead: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '70px auto 110px',
        alignItems: 'center',
        columnGap: 7,
    },
    title: {
        display: '-webkit-box',
        overflow: 'hidden',
        maxWidth: 70,
        maxHeight: 20,
        textOverflow: 'ellipsis',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 1,
    },
    action: {
        display: 'flex',
        gap: 5,
    },
    actionItem: {
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        cursor: 'pointer',
    },
    wrapperStepper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    step:{
        display: 'flex',
        alignItems: 'center',
    }
}))

const dataSizeTabs = {
    width: null,
    size: 100
}

const EditorBlockHead = () => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const {block} = useSelector((state) => state);
    const selectedItem = block?.selectedItem
    const [sizeTabs, setSizeTabs] = useState(dataSizeTabs);
    const ref = useRef(null);
    const menu = getSideBarMenu(selectedItem)



    const handleResize = () => {
        const width = ref.current.offsetWidth;
        const size = findSizeMenu(width)


        setSizeTabs((pre) => ({
            ...pre,
            width,
            size
        }));
    }


     // useEffect(() => {
     //        if (!ref.current) return
     //        handleResize();
     //
     //        window.addEventListener('resize', handleResize);
     //        return () => {
     //            window.removeEventListener('resize', handleResize);
     //        };
     //    }, [ref.current]);


    useEffect(() => {
        const element = ref.current;
        if (!element) return;

        handleResize();

        const resizeObserver = new ResizeObserver(() => {
            handleResize();
        });

        resizeObserver.observe(element);

        return () => {
            resizeObserver.unobserve(element);
            resizeObserver.disconnect();
        };
    }, [ref.current]);





    const handelAction = (type) => {
        dispatch(handleActionChildBlockHeader(type))
    }

    if (!selectedItem) {
        return <div></div>
    }

    return (
        <div style={{  width:  '100%'}}>
            <div className={cl.editorBlockHead} >

                <div className={cl.title}>
                    {selectedItem.elementInfo?.name}
                </div>

                <div className={cl.action} >
                    {menu.slice(0, sizeTabs.size).map((el, i) => <div
                        key={i}
                        className={cl.actionItem}
                        onClick={(e) =>handelAction(el.type)}
                    >
                        {el?.icon} {el.name}
                    </div>)}

                    {sizeTabs.size < 0 && (
                        <div style={{marginLeft: 5}}>
                            <BurgerMenu
                                dropMenu={menu.slice(sizeTabs.size)}
                                action={handelAction}
                            />
                        </div>
                    )}
                </div>


                <div className={cl.wrapperStepper}>
                    <div className={cl.actionItem}>
                        <UndoIcon/>
                        <FormattedMessage id="ElementTile.Actions.Undo" defaultMessage="Undo"/>
                    </div>
                    <div className={cl.actionItem}>
                        <RedoIcon/>
                        <FormattedMessage id="ElementTile.Actions.Redo" defaultMessage="Redo"/>
                    </div>
                </div>
            </div>
            <div id='test' style={{width: '100%'}} ref={ref}></div>
        </div>
    )
}

export default EditorBlockHead