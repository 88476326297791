import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function NameEditingDialog({
  onConfirm,
  dialogName: DialogName,
  confirmButtonText: ConfirmButtonText,
  cancelButtonText: CancelButtonText,
  content: Content,
  name: initialName = '',
  unmountComponent
}) {
  const [name, setName] = useState(initialName);
  const handleConfirm = () => {
    onConfirm(name);
    unmountComponent();
  };
  return (
    <Dialog open onClose={unmountComponent} fullWidth>
      <DialogTitle>
        {DialogName || <FormattedMessage id="NameEditingDialog.Title" defaultMessage="Name editing" />}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
          label={<FormattedMessage id="NameEditingDialog.placeholder" defaultMessage="Name" />}
        />
        {Content && Content}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary" variant="outlined">
          {ConfirmButtonText || <FormattedMessage id="NameEditingDialog.Confirm" defaultMessage="Confirm" />}
        </Button>
        <Button onClick={unmountComponent} color="primary">
          {CancelButtonText || <FormattedMessage id="NameEditingDialog.Cancel" defaultMessage="Cancel" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NameEditingDialog;
