import React, { useContext, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TabsContext } from '../../Stylesheet';
import GroupBlock from '../blockTabs/pageLayout/GroupBlock';
import { handleTableParents, handleTabsValue } from '../../utils/helpers';
import Decorations from './decorations/Decorations';
import Font from './Font';
import TextFlow from './TextFlow';
import TemplateItemRow from '../../TemplateItemRow';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 10
  },
  title: {
    fontSize: '1rem',
    fontWeight: '700'
  },
  templateInput: {
    margin: 0,
    width: '100%'
  },
  noteActiveInputWrapper: {
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15,
      background: '#ffffff'
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    },
    '& .MuiFormControl-marginDense': {
      width: '100%'
    }
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10
  },
  wrapperRowBlock: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gridColumnGap: 10
  },
  rowInput: {
    margin: 0,
    width: '100%',
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15,
      background: '#ffffff'
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  rowCountWrapper: {
    display: 'grid',
    gridTemplateColumns: '140px 1fr',
    gridColumnGap: 10
  }
}));

function EvenRows({ table }) {
  const { values, setValues } = useContext(TabsContext);
  const cl = useStyles();
  const [disabled, setDisabled] = useState(false);
  const [disableEvenRow, setDisableEvenRow] = useState(false);

  const evenRow = table.evenRow && table.evenRow;

  const spacingProps = [
    { label: 'Left:', value: evenRow.paddingLeft, type: 'paddingLeft' },
    { label: 'Right:', value: evenRow.paddingRight, type: 'paddingRight' },
    { label: 'Top:', value: evenRow.paddingTop, type: 'paddingTop' },
    { label: 'Bottom:', value: evenRow.paddingBottom, type: 'paddingBottom' }
  ];

  const handleValuesSpacing = (val, name) => {
    const res = { ...evenRow[name], value: +val };
    handleTableParents(res, name, 'tableSettings', 'headerRow', values, setValues);
  };
  const handleDropMenuSpacing = (dropMenu, name, val) => {
    const res = { unit: dropMenu, value: +val };
    handleTableParents(res, name, 'tableSettings', 'headerRow', values, setValues);
  };

  const handleSettingsRows = (e) => {
    setDisabled(e.target.checked);
  };

  const handleRowHeight = (val, name) => {
    const res = { unit: table.emptyRowHeight.unit, value: +val };
    handleTabsValue(res, name, 'tableSettings', values, setValues);
  };
  const setDropMenuRowHeight = (dropMenu, name, val) => {
    const res = { unit: dropMenu, value: +val };
    handleTabsValue(res, name, 'tableSettings', values, setValues);
  };

  return (
    <>
      {evenRow && (
        <div className={cl.root}>
          <FormControlLabel
            control={<Checkbox onChange={handleSettingsRows} checked={disabled} />}
            label={<span>Use the same settings as for odd rows</span>}
          />

          <div className={cl.wrapperRow}>
            <Font table={evenRow} type="evenRow" disabled={disabled} />
            <TextFlow table={evenRow} type="evenRow" disabled={disabled} />
          </div>

          <div className={cl.wrapperRow}>
            <Decorations table={evenRow} type="evenRow" disabled={disabled} />
            <GroupBlock
              label="Spacing to contents"
              valueProps={spacingProps}
              style={{ height: 'Calc(100% - 26px)', gridAutoRows: 'min-content' }}
              setValueProps={handleValuesSpacing}
              setDropMenu={handleDropMenuSpacing}
              disabled={disabled}
            />
          </div>
          <FormControlLabel
            control={<Checkbox onChange={(e) => setDisableEvenRow(e.target.checked)} checked={disableEvenRow} />}
            label={<span>Insert empty even row if table ends with odd row</span>}
          />

          <TemplateItemRow
            label=""
            type="emptyRowHeight"
            value={table.emptyRowHeight}
            grid="1fr 55px"
            setValueProps={handleRowHeight}
            setDropMenu={setDropMenuRowHeight}
            disabled={!disableEvenRow}
            styleDropMenu={{ top: -50 }}
          />
        </div>
      )}
    </>
  );
}

export default EvenRows;
