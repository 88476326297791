import React, { useEffect, useState } from 'react';
import dialogCustom from '@zert-packages/components/common/dialogCustom';
import DeleteConfirmDialog from '@zert-packages/components/shared/DeleteConfirmDialog';
import { FormattedMessage } from 'react-intl';
import { createCompany, SELECT_COMPANY, removeCompany } from '@zert-packages/actions/coreReducers';
import { API_ROOT_WAR } from '@zert-packages/actions/api';
import SearchableTable from '@zert-packages/components/shared/SearchableTable/SearchableTable';
import { IconButton, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import EditNameDialog from '@zert-packages/components/common/dialogs/EditNameDialog';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { useHistory } from 'react-router-dom';

const columns = [
  {
    label: '',
    identifier: 'open',
    isFilterable: false,
    isSortable: false,
    isAction: true,
    width: '2.5%'
  },
  {
    label: '',
    identifier: 'edit',
    isFilterable: false,
    isSortable: false,
    isAction: true,
    width: '2.5%'
  },
  {
    label: '',
    identifier: 'delete',
    isFilterable: false,
    isSortable: false,
    isAction: true,
    width: '2.5%'
  },

  {
    label: <FormattedMessage id="settings.companyList.name" defaultMessage="Name" />,
    identifier: 'fullName',
    isFilterable: true,
    isSortable: true,
    isAction: false,
    width: '73%'
  },
  {
    label: <FormattedMessage id="settings.companyList.numberOfUsers" defaultMessage="Numbers of users" />,
    identifier: 'numberOfUsers',
    isFilterable: true,
    isSortable: true,
    isAction: false,
    width: '15%'
  },
  {
    label: <FormattedMessage id="settings.companyList.active" defaultMessage="Active" />,
    identifier: 'active',
    isFilterable: true,
    isSortable: true,
    isAction: false,
    width: '5%'
  }
];

function CompanyNewList(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedCompanyId } = useSelector((state) => state);
  const [selectedRow, setSelectedRow] = useState();
  const { companieslist: companiesList = [], newcompany: newCompany, loading } = useSelector((state) => state);
  const handleCreate = () => {
    dialogCustom(EditNameDialog, {
      name: '',
      isAdding: true,
      onSave: (companyName) => {
        dispatch(createCompany(companyName, 'zert', 'sv_SE'));
      }
    });
  };
  const handleOpen = (companyId) => {
    dispatch({
      type: SELECT_COMPANY,
      payload: { companyId }
    });
    history.push(`${API_ROOT_WAR}/settings/userlist/${companyId}`);
  };

  const handleEdit = (companyId) => {
    dispatch({
      type: SELECT_COMPANY,
      payload: { companyId }
    });
    history.push(`${API_ROOT_WAR}/settings/companyeditor/${companyId}`);
  };

  const handleDelete = (companyId) => {
    dialogCustom(DeleteConfirmDialog, {
      onDelete: () => {
        dispatch(removeCompany(companyId));
      }
    });
  };

  const rows = createRows(
    companiesList.sort((a, b) => {
      if (a.properties.fullname.toLowerCase() < b.properties.fullname.toLowerCase()) {
        return -1;
      }
      if (a.properties.fullname.toLowerCase() > b.properties.fullname.toLowerCase()) {
        return 1;
      }
      return 0;
    }),
    {
      open: handleOpen,
      edit: handleEdit,
      delete: handleDelete
    }
  );
  useEffect(() => {
    if (newCompany) {
      history.push(`${API_ROOT_WAR}/settings/companyeditor/${newCompany.companyId}`);
    }
    if (selectedCompanyId) {
      setSelectedRow(selectedCompanyId);
    }
  }, [newCompany, selectedCompanyId]);
  if (loading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        height: '100%',
        maxHeight: '90%',
        margin: '10px'
      }}
    >
      <SearchableTable rows={rows} columns={columns} initialSelectedRow={selectedRow} initialPagination={25} />
      <div>
        <Button variant="outlined" color="primary" onClick={handleCreate}>
          <FormattedMessage id="settings.companylist.btn.new" defaultMessage="New Company" />
        </Button>
      </div>
    </div>
  );
}

export default CompanyNewList;

function createRows(data, handles) {
  return data.map((item) => {
    const itemId = item.elementId;
    return {
      open: (
        <IconButton size="small" onClick={() => handles.open(itemId)}>
          <VisibilityIcon fontSize="inherit" />
        </IconButton>
      ),
      edit: (
        <IconButton onClick={() => handles.edit(itemId)} size="small">
          <EditIcon fontSize="inherit" />
        </IconButton>
      ),
      delete: (
        <IconButton onClick={() => handles.delete(itemId)} size="small">
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      ),
      id: itemId,
      fullName: item.properties.fullname,
      numberOfUsers: item.properties.number,
      active:
        item.properties.active === 'true' ? (
          <FormattedMessage id="settings.tablelistCompany.active" defaultMessage="Active" />
        ) : (
          <FormattedMessage id="settings.tablelistCompany.disabled" defaultMessage="Disabled" />
        )
    };
  });
}
