import React, { createContext, memo, useEffect, useState } from 'react';
import TreeView from '@mui/lab/TreeView';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ExplorerTreeNode } from './ExplorerTreeNodeNew';
import { getChildNodes, getRootNode } from './servicesMidleware';
import { setSelectedPath } from './explorerReducers';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: 264,
      flexGrow: 1
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
      paddingRight: '16px',
      top: '-10px',
      backgroundColor: 'white',
      position: 'sticky'
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1
    },
    greyDot: {
      width: '0px',
      height: '0px',
      margin: '3px',
      padding: '8px',
      display: 'flex',
      border: '1px solid transparent'
    }
  };
});
export const TreeContext = createContext(null);

function ExplorerTree() {
  const [key, setKey] = useState();
  const [keyG, setKeyG] = useState(uuidv4());
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [children, setChildren] = useState(null);
  const dispatch = useDispatch();
  const CORE = useSelector((state) => state.CORE);
  const [selected, setSelected] = useState(CORE.selectedNode);
  const [expanded, setExpanded] = useState(CORE.selectedPath);

  useEffect(() => {
    if (!CORE.root) {
      setLoading(true);
      dispatch(getRootNode());
    } else if (!(CORE.root.id in CORE.nodes)) {
      setLoading(true);
      dispatch(getChildNodes(CORE.root.id));
    } else {
      setInfo(CORE.root);
      setChildren(CORE.nodes[CORE.root.id]);
      setKey(CORE.root.id);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (CORE.root && !(CORE.root.id in CORE.nodes)) {
      setLoading(true);
      setInfo(CORE.root);
      setKey(CORE.root.id);
      dispatch(getChildNodes(CORE.root.id));
      setExpanded([...expanded, CORE.root.id]);
      dispatch(setSelectedPath([...expanded, CORE.root.id]));
    }
  }, [CORE.root]);

  useEffect(() => {
    if (CORE.root && CORE.root.id in CORE.nodes && CORE.root) {
      setChildren(CORE.nodes[CORE.root.id]);

      setKey(CORE.root.id);
      // setKeyG(uuidv4());

      setLoading(false);
    }
    /* } else if (CORE.root && CORE.root.id in CORE.nodes) {
            setLoading(true);

            setChildren(CORE.nodes[CORE.root.id]);
            setKey(CORE.root.id );
            setLoading(false);
        } */
  }, [CORE.nodes]);
  useEffect(() => {
    if (CORE.root && CORE.root.id in CORE.nodes && CORE.root) {
      setChildren(CORE.nodes[CORE.root.id]);

      setKey(CORE.root.id);
      setKeyG(uuidv4());

      setLoading(false);
    }
    /* } else if (CORE.root && CORE.root.id in CORE.nodes) {
             setLoading(true);

             setChildren(CORE.nodes[CORE.root.id]);
             setKey(CORE.root.id );
             setLoading(false);
         } */
  }, [CORE.updateTreeRecursively]);

  useEffect(() => {
    if (CORE.root && CORE.selectedPath && CORE.selectedPath.length === 0) {
      toggleExpanded([...expanded, CORE.root.id]);
    } else if (CORE.root && CORE.selectedPath) {
      toggleExpanded(CORE.selectedPath);
    }
  }, [CORE.selectedPath]);

  const toggleExpanded = (expanded) => {
    setExpanded([CORE.root.id, ...expanded]);
    dispatch(setSelectedPath(expanded));
  };

  return (
    <TreeContext.Provider
      value={{
        toggleExpanded,
        expanded: CORE.selectedPath,
        setSelected,
        selected: CORE.selectedNode && CORE.selectedNode.id
      }}
    >
      {(loading || !info) && <Loader displayText={false} />}
      {!loading && info && (
        <TreeView
          className={classes.root}
          key={keyG}
          defaultExpanded={[...CORE.selectedPath, key].map(String)}
          selected={selected ? selected.toString() : ''}
          expanded={expanded.map(String)}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          <ExplorerTreeNode key={key} info={info} nodeId={`${key}`} readyChildren={children} />
        </TreeView>
      )}
    </TreeContext.Provider>
  );
}

export default memo(ExplorerTree);
