import { v4 as uuidv4 } from "uuid";
import { findDefaultSize } from "./findLength";

const data = {
  arrow: {
    "scaleX": 1,
    "scaleY": 1,
    "editor": true,
    "type": "Arrow",
    line: 2
  },
  box: {
    "type": "Rektangel",
    "cornerRadius": 0,
    "editor": true
  }
};
const dataClass = "se.zert.clm.rest.image.pojo.ClientShapeItem";

export const findLineType = (type) => {
  if (type === "DOTTED") {
    return { lineType: "PRICKAD", dash: [2, 5] };
  }
  if (type === "DASHED") {
    return { lineType: "PRICKAD", dash: [5, 5] };
  }
  if (type === "DOTDASH") {
    return { lineType: "PRICKAD", dash: [2, 5, 10] };
  }
  return { lineType: type, dash: [] };
};

const findStrokeType = (type) => {
  if (type === "PRICKAD") {
    return "DOTTED";
  }
  if (type === "PRICKAD") {
    return "DASHED";
  }
  if (type === "PRICKAD") {
    return "DOTDASH";
  }
  return type;
};


export const adobeAreas = (areas) => {
  return areas.reduce((acu, cur) => {
    if (cur.shape === "ARROW") {
      const strokeColor = cur.strokeColor;
      const adobeShape = {
        ...cur,
        id: cur.uuid,
        "x": cur.x,
        "y": cur.y,
        height: 20,
        "points": [0, 0, (cur.width + (cur.width * 0.2)), 0],
        lineColor: `rgba(${strokeColor.red}, ${strokeColor.green}, ${strokeColor.blue}, 1)`,
        rotate: cur.angle,
        ...findLineType(cur.strokeType),
        ...data.arrow,
        ...findDefaultSize(cur)
      };
      return [...acu, adobeShape];
    }
    // if (cur.shape === "BOX" || cur.shape === "OVAL") {
    const strokeColor = cur.strokeColor;
    const fillColor = cur.fillColor;
    const adobeShape = {
      ...cur,
      id: cur.uuid,
      "x": cur.x,
      "y": cur.y,
      height: cur.height,
      width: cur.width,
      strokeColor: `rgba(${strokeColor.red}, ${strokeColor.green}, ${strokeColor.blue}, 1)`,
      fillColor: `rgba(${fillColor.red}, ${fillColor.green}, ${fillColor.blue}, ${cur.transparency})`,
      ...data.box,
      ...findLineType(cur.strokeType),
      ...findDefaultSize(cur),
      strokeWidth: cur.stroke || 1,
      typeForm: cur.shape,
      cornerRadius: cur.shape === "OVAL"  ? 50 : 0,
      opacity: 1,
      rotate: cur.angle,
    };
    return [...acu, adobeShape];
    // }
    // return [...acu, cur];
  }, []);
};

const adobeColorApi = (color) => {
  const colorArray = color && color.replace("rgba(", "").replace(")", "").split(", ");
  if (!colorArray) {
    return color;
  }
  return {
    "cmyk": false,
    "alpha": 255,
    red: parseFloat(colorArray[0]),
    green: parseFloat(colorArray[1]),
    blue: parseFloat(colorArray[2])
  };
};


export const adobeCanvas = (canvas) => {
  return canvas.reduce((acu, adobeShape) => {
    if (adobeShape.type && adobeShape.type.toLowerCase() === "ARROW".toLowerCase()) {
      const strokeColorArray = adobeShape.lineColor.replace("rgba(", "").replace(")", "").split(", ");
      const strokeColor = {
        red: parseFloat(strokeColorArray[0]),
        green: parseFloat(strokeColorArray[1]),
        blue: parseFloat(strokeColorArray[2])
      };
      const el = {
        ...adobeShape,
        class: dataClass,
        uuid: adobeShape.id,
        shape: "ARROW",
        strokeColor: strokeColor,
        angle: +adobeShape.rotate,
        strokeType: findStrokeType(adobeShape.lineType),
        fillColor: {cmyk: false, red: 0, green: 0, blue: 0, alpha: 255},
        ...findDefaultSize(adobeShape)
      };
      return [...acu, el];
    }
    if (isNaN(adobeShape.width)) {
      return acu;
    }
    if (adobeShape.type && adobeShape.type.toLowerCase() === "Rektangel".toLowerCase()) {
      const strokeColor = adobeColorApi(adobeShape.strokeColor);
      const fillColor = adobeColorApi(adobeShape.fillColor);

      const el = {
        ...adobeShape,
        class: dataClass,
        uuid: adobeShape.id,
        strokeColor: strokeColor,
        strokeType: adobeShape.lineType.toUpperCase(),
        transparency: +adobeShape.transparency,
        shape: adobeShape.typeForm.toUpperCase(),
        ...findDefaultSize(adobeShape),
      };
      if (adobeShape.fillColor) {
        el.fillColor = fillColor;
      }
      return [...acu, el];
    }
    return [...acu, {
      class: dataClass,
      ...adobeShape,
      ...findDefaultSize(adobeShape)
    }];
  }, []);
};

export const adobePhraseCanvas = (items) => {
  return items.map(el => {
    if (el.phrase) {
      return {
        ...el,
        type: "group",
        label: "[1.1]",
        text: el.phrase.name,
        cornerRadius: 0,
        strokeWidth: 1,
        stroke: "black",
        dash: [],
        id: el.phrase.id,
        ...findDefaultSize(el),
        editor: true
      };
    }
    if (el.shape === "RECTANGLE") {
      return {
        ...el,
        id: uuidv4(),
        "type": "Rektangel",
        text: el.position,
        strokeWidth: 1,
        stroke: "black",
        cornerRadius: 0,
        dash: [],
        ...findDefaultSize(el),
        editor: true,
        align: 'center',
      };
    }
    return {
      ...el,
      id: uuidv4(),
      type: "Ellips",
      text: el.position,
      strokeWidth: 1,
      stroke: "black",
      cornerRadius: 50,
      dash: [],
      align: 'center',
      ...findDefaultSize(el)
    };
  });
};


