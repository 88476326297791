import React, { useState,  useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog } from "@mui/material";
import DropMenu from "@zert-packages/components/DropMenu";
import { v4 as uuidv4 } from "uuid";

import FieldDialog from "../FieldDialog";
import RowDialog from "../RowDialog";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 414,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700
  },
  wrapper: {
    display: "grid",
    gridRowGap: 10
  },


  wrapperButton: {
    display: "flex",
    marginTop: 30
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

const dropMenu = [
  {
    name: "Ellips"
  },
  {
    name: "Rektangel"
  }
];

const DialogHotspot = ({ setValues , el, values, action }) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [uuid] = useState(uuidv4());
  const findPosition = () => {
    if(!values || !values.item){
      return 1
    }
    const total = values.item.reduce((acu , cur) => {
      if(cur.position && cur.position > acu){
        return  cur.position
      }
      return acu
    }, 0)
    return total +1
  }
  const [hotspot, setHotspot] = useState({
    position: findPosition() ,
    type: "Ellips",
    width: 100,
    id: uuid
  });




  const handleType = (el, name) => {
    setHotspot(pre => ({ ...pre, [name]: el.name }));
  };

  const handleClose = () => {
    setOpen(false);
  };


  const adobeType = () => {
    if(hotspot.type === 'Ellips'){
      return  "ELLIPSE"
    }
    return "RECTANGLE"
  }


  const handleSave = () => {
    const size = +hotspot.width
    setValues(pre => ({
      ...pre,
      addImgPhraseType: hotspot.type,
      phraseContent: { ...hotspot,
        class: "se.zert.clm.rest.image.pojo.ClientHotspotAreaItem",
        angle: 0,
        align: 'center',
        stroke: 'black',
        text: hotspot.position,
        shape: adobeType(),
        height: size,
        width: size,
        defaultWidth: size,
        defaultHeight: size,
        cornerRadius: hotspot.type === "Ellips" ? 50 : 0,

      }
    }));
    if(action){
      action()
    }
    handleClose();
  };

  useEffect(() => {
    if(el){
      setHotspot(pre => ({...pre,
        ...el,
        position: el.text,
      }))
    }
  }, [el])

  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>Ny hotspot</span>
      <CloseIcon onClick={handleClose} />
    </div>
    <div className={cl.wrapper}>
      <RowDialog title={"Position"}>
        <FieldDialog
          val={hotspot.position}
          setValue={setHotspot}
          name={"position"}
          setDropItem={handleType}
        />
      </RowDialog>
      <RowDialog title={"Type"}>
        <DropMenu
          dropItem={dropMenu}
          selected={hotspot.type}
          name={"type"}
          setDropItem={handleType}
        />
      </RowDialog>
      <RowDialog title={"Width"}>
        <FieldDialog
          val={hotspot.width}
          setValue={setHotspot}
          name={"width"}
          setDropItem={handleType}
        />
      </RowDialog>
    </div>
    <div className={cl.wrapperButton}>
      <Button className={cl.button} onClick={handleSave}>
        OK
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>
  </Dialog>);
};

export default DialogHotspot;




