import { PortalProvider } from '@zert-packages/utils/PortalProvider';
import React from 'react';
import './index.css';
import { composeWithDevTools } from 'redux-devtools-extension';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { SnackbarProvider, useSnackbar } from 'notistack';
import 'intersection-observer';
import { commonReducer, initialState } from '@zert-packages/actions';
import { pushStore, getStore, pushPlugins, setTilesFunc } from '@zert-packages/actions/api';
import { App } from '@zert-packages/components/Content/App';
import { EXPLORERReducers } from '@zert-packages/plugins/ExplorerPlugin/explorerReducers';
import getPlugins, { actionPlanPlugin } from '@zert-packages/plugins/Environment';
import { AuthProvider } from '@zert-packages/plugins/auth/JWTAuthContext';
import getElementByMimeType from '@zert-packages/components/ElementTile/utils/getTileByMimeType';
import withClearCache from '@zert-packages/components/Content/ClearCache';
import { LanguageProvider } from '@zert-packages//utils/LanguageProvider';
import { EnvProvider } from '@zert-packages/react/EnvProvider';
import { createRoot } from 'react-dom/client';
import { PedReducers } from 'Plugins/PEDOBjectPlugin/pedReducers';
import { DeviationReducers } from 'Plugins/DeviationPlugin/deviationReducers';
import { QUESTIONNAIREReducer } from 'Plugins/SafetyInspectionPlugin/questionnaireReducers';
import { RMPReducer } from 'Plugins/RMPPlugin/redux/rmpReducer';
import { projectReducer } from 'Plugins/ProjectPlugin/projectActions';
import registerServiceWorker from './registerServiceWorker';
import getRMPlugins from './Plugins/Environment';
import packageJson from '../package.json';
import { hooksInMiddleware } from "@zert-packages/utils/SnackBarsNotistack";

require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/locale-data/sv');
require('@formatjs/intl-pluralrules/locale-data/en');

const context = require.context('./translations/', true, /\/([a-z]{2})\.json$/);


function CreateHooksInMiddleware() {
  hooksInMiddleware.snackbar = useSnackbar();
  return null;
}

const loggerMiddleware = createLogger();

function configureStore(preloadedState) {
  return createStore(
    (state, action) => {
      return {
        ...projectReducer(commonReducer(state, action), action),
        CORE: EXPLORERReducers(state.CORE, action),
        RMP: RMPReducer(state.RMP, action),
        QUESTIONNAIRE: QUESTIONNAIREReducer(state.QUESTIONNAIRE, action),
        DEVIATION: DeviationReducers(state.DEVIATION, action),
        PED: PedReducers(state.PED, action)
      };
    },
    preloadedState,
    composeWithDevTools(applyMiddleware(thunkMiddleware.withExtraArgument(hooksInMiddleware), loggerMiddleware))
  );
}

pushPlugins([...getPlugins(), ...getRMPlugins(), actionPlanPlugin]);
pushStore(configureStore(initialState));

setTilesFunc(getElementByMimeType);

// const history = createHistory();
// const historyMiddleware = routerMiddleware(loggerMiddleware);
// const createStoreWithMiddleware = applyMiddleware(thunkMiddleware, historyMiddleware)(createStore);
// const store = createStoreWithMiddleware(rootReducer);

/* const store = configureStore({
  reducer: commonReducer,
}); */

const element = document.getElementById('ipl-progress-indicator');
element.parentNode.removeChild(element);

/* window.onerror = onError(function (msg, url, line, col, error) {
//  console.log("error g");
  console.log(error);
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={getStore()}>
          <LanguageProvider>
            <SnackbarProvider maxSnack={3}>
              <CreateHooksInMiddleware/>
              <ErrorGlobalFallbackComponent errorState={error} />
            </SnackbarProvider>
          </LanguageProvider>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
    , s
  );
  return true;
}); */

function ClearCacheComponent({ children }) {
  return children;
}

const ClearCache = withClearCache(ClearCacheComponent, packageJson);

const container = document.getElementById('react');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={getStore()}>
      <EnvProvider>
        <ClearCache />
        <PortalProvider>
          <LanguageProvider translationContext={context}>
            <SnackbarProvider maxSnack={5}>
              <AuthProvider>
                <CreateHooksInMiddleware />
                <App />
              </AuthProvider>
            </SnackbarProvider>
          </LanguageProvider>
        </PortalProvider>
      </EnvProvider>
    </Provider>
  </BrowserRouter>
);

registerServiceWorker(hooksInMiddleware);
