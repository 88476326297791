import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import SettingsField from '@zert-packages/components/SettingsComponents/SetttingField';
import { updateLayoutProperties } from '../servicesMiddlewares';

function LayoutSettingsPaper({ layoutVersionId, settings, readOnly }) {
  const { layoutProperties } = useSelector((state) => state.PREVIEW);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLayoutProperties(layoutVersionId, settings, null));
  }, [layoutVersionId, settings]);

  if (!layoutProperties[layoutVersionId]) {
    return <></>;
  }

  const handleChange = (name) => (event) => {
    const index = layoutProperties[layoutVersionId].findIndex((value) => value && value.property.identifier == name);
    const value = layoutProperties[layoutVersionId].find((value) => value && value.property.identifier == name);
    // layoutProperties[layoutVersionId].splice(index, 1);

    const newValue = {
      identifier: value.property.identifier
    };
    if (event.target) {
      newValue.value = event.target.value;
    } else {
      newValue.value = event;
    }
    dispatch(updateLayoutProperties(layoutVersionId, settings, newValue));

    //  setValues([...values, value]);
  };

  return (
    <Paper>
      <Typography component="h3" gutterBottom>
        <FormattedMessage id="Preview.GeneralTable.LayoutSettings" defaultMessage="Settings" />
        <br />
      </Typography>
      {settings.map((setting) => (
        <SettingsField
          values={layoutProperties[layoutVersionId]}
          readOnly={readOnly}
          handleChange={handleChange}
          settings={setting}
        />
      ))}
    </Paper>
  );
}

export default LayoutSettingsPaper;
