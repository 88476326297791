import React, {useContext} from 'react';
import {Button} from "@mui/material";
import {OPEN_PART, REMOVE_PART_ACTIONS} from "../../utils/ELEMENT_TILE_CONTS";
import {SparePartContext} from "../../store/SparePartContext";

const PartActions = () => {
    const {values, setValues} = useContext(SparePartContext)

    const removePart = () => {
        setValues({
            ...values,
            selectedElement: null,
            parts: values.parts.filter(part => part.identifier !== values.selectedElement.identifier).map((part, idx) => ({...part, position: idx + 1})),
            isDataChanged: true
        })
    }

    return (
        <>
            <Button key={OPEN_PART.id} startIcon={OPEN_PART.icon} onClick={() => {
                setValues({
                    ...values,
                    currentViewedElement: values.selectedElement.part,
                    currentElementVersionId: values.selectedElement.elementInfo.versionId,
                    selectedElementLanguage: values.selectedElement.elementInfo.locale,
                    elementType: values.selectedElement?.elementInfo?.mimeType,
                    viewedElements: [...values.viewedElements, values.selectedElement.part],
                    partImages: values.selectedElement.part.imageItems,
                    parts: values.selectedElement.part.partItems,
                    partVersionId: values.selectedElement.part.element.versionId,
                    selectedPart: null,
                    selectedElement: null
                })
            }}>
                {OPEN_PART.name}
            </Button>
            <Button key={REMOVE_PART_ACTIONS.id} startIcon={REMOVE_PART_ACTIONS.icon} onClick={removePart}>
                {REMOVE_PART_ACTIONS.name}
            </Button>
        </>
    );
};

export default PartActions;