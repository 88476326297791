import { useState, useEffect } from "react";

const useElementDimensions = (ref) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const getElementDimensions = () => {
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect();
      setDimensions({ width, height });
    }
  };

  useEffect(() => {
    if (ref.current) {
      const resizeObserver = new ResizeObserver(getElementDimensions);
      resizeObserver.observe(ref.current);
      return () => {
        if (ref.current && resizeObserver) {
          resizeObserver.unobserve(ref.current);
        }
      };
    }
  }, [ref]);


  return { width: dimensions.width, height: dimensions.height };
};


export default useElementDimensions;