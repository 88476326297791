import makeStyles from '@mui/styles/makeStyles';

import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage } from 'react-intl';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { loadCompareWithVersions } from '../API';

const useStyles = makeStyles({
  DialogContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    '& > *': {
      marginTop: 10
    }
  }
});

function ShowMoreLayouts({ unmountComponent, selectedLayoutElement: selectedLayoutInitial, onConfirm }) {
  const cl = useStyles();
  const [moreLayouts, setMoreLayouts] = useState([]);
  const [selectedLayout, setSelectedLayout] = useState(selectedLayoutInitial);
  const handleConfirm = () => {
    unmountComponent();
    onConfirm(selectedLayout);
  };

  useEffect(() => {
    async function loadLayouts() {
      const result = await loadCompareWithVersions(selectedLayoutInitial.id);
      setMoreLayouts(result);
    }
    loadLayouts();
  }, []);

  const confirmDisabled = selectedLayout == null;

  return (
    <Dialog open onClose={unmountComponent} fullWidth>
      <DialogTitle>
        <FormattedMessage id="ShowmMoreLayouts.Title" defaultMessage="Select another layout" />
      </DialogTitle>
      <DialogContent className={cl.DialogContent}>
        {moreLayouts && (
          <FormControl>
            <InputLabel>
              <FormattedMessage id="Layout.Version" defaultMessage="Select Layout" />
            </InputLabel>
            <Select
              onChange={(event) => {
                setSelectedLayout(event.target.value);
              }}
              value={selectedLayout}
              autoWidth
              input={<Input shrink />}
            >
              {moreLayouts.map((item, index) => (
                <MenuItem value={item} index={index}>
                  {item.name}({item.label})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={confirmDisabled} onClick={handleConfirm} color="primary" variant="outlined">
          <FormattedMessage id="ShowMoreLayoutsDialog.Confirm" defaultMessage="Confirm" />
        </Button>
        <Button onClick={unmountComponent} color="primary">
          <FormattedMessage id="ShowMoreLayoutsDialog.Cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default ShowMoreLayouts;
