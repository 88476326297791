import { Checkbox, FormControlLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useEffect, useState } from "react";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import SelectedColor from "../../../../SelectedColor";
import GroupBlock from "../../pageLayout/GroupBlock";
import { TabsContext } from "../../../../Stylesheet";
import { adobeDropMenu, handleColum } from "../../../../utils/helpers";
import TemplateItemRow from "../../../../TemplateItemRow";
import DialogColors from "../../../../dialog/DialogColors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridRowGap: 10
  },
  wrapperDropMenu: {
    display: "grid",
    gridTemplateColumns: "195px 1fr ",
    gridColumnGap: 10
  },
  title: {
    fontSize: "1rem",
    fontWeight: 700,
    marginBottom: 5
  },
  noteActiveInputWrapper: {
    "& input": {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15
    },
    "& textarea": {
      overflow: "hidden"
    },
    "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  notesInput: {
    margin: 0,
    width: "100%"
  },
  wrapperTextField: {
    display: "grid",
    gridTemplateColumns: "195px 300px 1fr 55px",
    gridColumnGap: 10,
    alignItems: "center"
  },
  wrapperTextFieldHeight: {
    display: "grid",
    gridTemplateColumns: "195px 300px 1fr 55px 30px",
    gridColumnGap: 10,
    alignItems: "center"
  },
  wrapperCheckbox: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr ",
    maxWidth: 500
  },
  wrapperLineHeight: {
    display: "grid",
    gridTemplateColumns: "1fr 25px",
    gridColumnGap: 10,
    alignItems: "center",
    marginBottom: 40
  }
}));

function Format({ colum, index, indexFormat, disable }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const data = {
    fontStyle: [
      { name: "Bold" },
      { name: "Subscript" },
      { name: "Uppercase" },
      { name: "Superscript" },
      { name: "Underlined" },
      { name: "Lowercase" },
      { name: "italic" },
      { name: "Strikethrough" },
      { name: "Verbatim" }
    ],
    lineHeight: ["Default", "Single", "At least", "Fixed"]
  };
  const spaceProps = colum && [
    { label: "Left:", value: colum.spaceLeft && colum.spaceLeft, type: "spaceLeft" },
    { label: "Right:", value: colum.spaceRight && colum.spaceRight, type: "spaceRight" },
    { label: "Top:", value: colum.spaceTop && colum.spaceTop, type: "spaceTop" },
    { label: "Bottom:", value: colum.spaceBottom && colum.spaceBottom, type: "spaceBottom" }
  ];
  const [selectedWidth, setSelectedWidth] = useState("User defined");

  const handleDropMenu = (val, name) => {
    if (val.name) {
      return handleColum(val.name, index, indexFormat, name, values, setValues);
    }
    handleColum(val, index, indexFormat, name, values, setValues);
  };

  const adoptFont = (font) => {
    if (font.includes("heading")) {
      return "heading";
    }
    return font;
  };

  const setDropMenu = (valDropMenu, name, val) => {
    handleColum(
      {
        value: val,
        unit: valDropMenu
      },
      index,
      indexFormat,
      name,
      values,
      setValues
    );
  };

  const setValueProps = (val, name) => {
    handleColum(val, index, indexFormat, name, values, setValues, "value");
  };

  const findCheckbox = (el) => {
    return colum.fontStyle.map((it) => it.toLowerCase()).find((it) => it === el.toLowerCase());
  };

  const handleCheckbox = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    if (val) {
      const result = [...colum.fontStyle, name.toUpperCase()];
      return handleColum(result, index, indexFormat, "fontStyle", values, setValues);
    }
    const result = colum.fontStyle.filter((el) => el !== name.toUpperCase());
    return handleColum(result, index, indexFormat, "fontStyle", values, setValues);
  };

  const handleSelectedColor = (val, name) => {
    handleDropMenu(val, name);
  };

  const handleOpenDialog = (type) => {
    renderInExceptionRoot(DialogColors, {
      color: colum[type],
      title: "Select Color",
      setText: handleSelectedColor,
      type
    });
  };


  console.log("colum", colum);
  const findSelectedWidth = () => {
    if (colum.fontSize && colum.fontSize.value && +colum.fontSize.value === 12) {
      return setSelectedWidth("Default");
    }
    setSelectedWidth("User defined");
  };

  const setDropMenuFirst = (val) => {
    setSelectedWidth(val);
  };

  useEffect(() => {
    findSelectedWidth();
  }, [index]);

  return (
    <div className={cl.root}>
      {colum && (
        <>
          <div className={cl.wrapperDropMenu}>
            <div className={cl.title}>Font family:</div>
            <ExpanderItem
              valueProps={adoptFont(colum.fontFamily)}
              propsLabel={data.fontFamily}
              type="content"
              setProps={handleDropMenu}
              disabled={disable}
            />
          </div>
          <TemplateItemRow
            label="Font size:"
            type="fontSize"
            value={colum.fontSize ? {
              value: colum.fontSize.value,
              unit: adobeDropMenu(colum.fontSize.unit)
            } : {
              "value": 10,
              unit: adobeDropMenu("POINTS")
            }}
            dropMenuFirst={{
              label: ["Default", "User defined"],
              selected: selectedWidth
            }}
            disabled={selectedWidth === "Default" || disable}
            setDropMenuFirst={setDropMenuFirst}
            grid="195px 150px 1fr 55px"
            setDropMenu={setDropMenu}
            setValueProps={setValueProps}
            disableMenuFirst={disable}
          />

          <div className={cl.wrapperDropMenu}>
            <div className={cl.title}>Font style:</div>
            <div className={cl.wrapperCheckbox}>
              {data.fontStyle.map((el, i) => (
                <div key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCheckbox}
                        checked={findCheckbox(el.name)}
                        name={el.name}
                        disabled={disable}
                      />
                    }
                    label={<span>{el.name}</span>}
                  />
                </div>
              ))}
            </div>
          </div>
          <SelectedColor
            label="Color:"
            type="color"
            setValueProps={handleDropMenu}
            handleOpenDialog={handleOpenDialog}
            valueProps={colum.color}
            style={{ grid: "195px 30px 1fr 25px" }}
            disabled={disable}
          />
          <SelectedColor
            label="Background color:"
            type="backgroundColor"
            setValueProps={handleDropMenu}
            handleOpenDialog={handleOpenDialog}
            valueProps={colum.backgroundColor}
            style={{ grid: "195px 30px 1fr 25px" }}
            disabled={disable}
          />
          <div className={cl.wrapperDropMenu}>
            <div className={cl.title}>Spacing to contents:</div>
            <div>
              <GroupBlock
                label=""
                valueProps={spaceProps}
                style={{ height: "Calc(100% - 26px)", gridAutoRows: "min-content" }}
                setValueProps={setValueProps}
                setDropMenu={setDropMenu}
                disabled={disable}
              />
            </div>
          </div>

          <div className={cl.wrapperLineHeight}>
            <TemplateItemRow
              label="Line height:"
              type="fontSize"
              value={colum.lineHeight.defaultValue.value}
              dropMenuFirst={{
                label: data.lineHeight,
                selected: colum.lineHeight.defaultValue.type
              }}
              grid="195px 150px 1fr 55px"
              setDropMenu={setDropMenu}
              setValueProps={setValueProps}
              disabled={disable}
              disableMenuFirst={disable}
            />
            <InsertDriveFileIcon style={{ color: "gray", cursor: "pointer" }} />
          </div>
          <div />
        </>
      )}
    </div>
  );
}

export default Format;
