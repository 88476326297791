export function getDefaultNode() {
  return {
    uuid: null,
    imageName: '',
    metadata: [
      {
        value: '',
        type: 1,
        identifier: 'posid'
      }
    ],
    assignments: [],
    children: [],
    properties: {},
    label: {
      locales: ['en', 'sv'],
      translations: ['New node', 'Ny nod'],
      defaultString: 'New node',
      text: '',
      empty: false
    }
  };
}

export function getDefaultRole() {
  return {
    identifier: '',
    label: {
      locales: ['en', 'sv'],
      translations: ['New role', 'Ny roll'],
      defaultString: '',
      text: '',
      empty: false
    },
    group: false
  };
}

export function getDefaultCounter() {
  return {
    search: {
      criterias: []
    },
    searchCompareWidth: null,
    clientCounterType: 'COUNT',
    label: {
      locales: ['en', 'sv'],
      translations: ['New counter', 'Ny räknare'],
      defaultString: '',
      text: '',
      empty: false
    },
    labelCompareLeft: null,
    labelCompareRight: null
  };
}
