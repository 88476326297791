const makeValidProjectJSON = ({
  name,
  description,
  members,
  demands,
  phase,
  responsiblePerson,
  nodeStatus,
  customDocumentation,
  roundRobin = null
}) => ({
  name,
  description,
  demands,
  members,
  phase,
  responsiblePerson,
  nodeStatus,
  customDocumentation,
  roundRobin
});

export default makeValidProjectJSON;
