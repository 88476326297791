import React, { useState, useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TabsContext } from "../../Stylesheet";
import Loading from "../../Loading";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FiltersItem from "./FiltersItem";
import { handleStylesheetParent } from "../../utils/helpers";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogAddFilter from "../../dialog/DialogAddFilter";

const useStyles = makeStyles((theme) => ({
  wrapperList: {
    display: "grid",
    gridTemplateColumns: "1fr 25px",
    gridColumnGap: 5,
    height: "100%"
  },
  listWrapper: {
    border: "1px solid #C6C6C5",
    borderRadius: 10,
    overflow: "hidden",
    height: "Calc(100% - 34px)"
  },
  listItem: {
    padding: "2px 4px",
    cursor: "pointer"
  },
  listItemActive: {
    padding: "2px 4px",
    cursor: "pointer",
    background: "#4db1d3",
    color: "#ffffff"
  },
  arrowImg: {
    position: "relative",
    top: 5
  },
  wrapperEditor: {
    color: "#4db1d3",
    "& svg": {
      cursor: "pointer"
    }
  }
}));

function ContentFilters() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [selected, setSelected] = useState(-1);

  const contentFilters = values.stylesheet?.contentFilters;


  const handleSelected = (i) => {
    setSelected(i);
  };

  const deleteFilters = () => {
    const res = contentFilters.filter((el, i) => i !== selected)
    setSelected(-1)
    handleStylesheetParent(res, "contentFilters",  setValues);
  }
  
  const addFilter = () => {
    renderInExceptionRoot(DialogAddFilter, {contentFilters, setValues})
  }



  return (
    <>
      {values.stylesheet ?
        <div className={cl.wrapperList}>
          <div className={cl.listWrapper}>
            {values.stylesheet.contentFilters.map((el, i) => (
              <div key={i} className={selected === i ? cl.listItemActive : cl.listItem}
                   onClick={() => handleSelected(i)}>
                <FiltersItem el={el} cl={cl} />
              </div>
            ))}
          </div>
          <div className={cl.wrapperEditor}>
            <AddIcon onClick={addFilter} />
            <DeleteIcon onClick={deleteFilters}/>
          </div>
        </div>
        : <Loading />}
    </>
  );
}

export default ContentFilters;
