import Tooltip from "@mui/material/Tooltip";
import withStyles from '@mui/styles/withStyles';


export const checkIfInRect = (koords, rect) => {
    return (koords.top >= rect.top - 1) && (koords.bottom <= rect.bottom + 1)
        && (koords.left >= rect.left - 1) && (koords.right <= rect.right + 1)
}

export const checkKoords = (cell, s, cur) => {

    if (cell) {
        const top = s.top < cur.top ? s.top : cur.top;
        const bottom = s.top < cur.top ? cur.top : s.top;
        const left = s.left < cur.left ? s.left : cur.left;
        const right = s.left < cur.left ? cur.left : s.left;
        const square = {left: left, right: right, top: top, bottom: bottom}


        return (square.top > cell.top && square.left > cell.left && square.top < cell.bottom && square.left < cell.right)
            || (square.top > cell.top && square.top < cell.bottom && square.left < cell.left && square.right > cell.right)
            || (square.top > cell.top && square.right > cell.left && square.top < cell.bottom && square.left < cell.left)
            || (square.top < cell.top && square.bottom > cell.bottom && square.left < cell.right && square.right > cell.right)
            || (square.top < cell.top && square.bottom > cell.top && square.left < cell.right && square.right > cell.right)
            || (square.top < cell.top && square.bottom > cell.top && square.left < cell.left && square.right > cell.left)
            || (square.top < cell.top && square.bottom > cell.bottom && square.left > cell.left && square.right < cell.right)
            || (square.top < cell.top && square.bottom > cell.top && square.left > cell.left && square.right < cell.right)
    } else {
        return false
    }
}

export const checkKoords2 = (cell, top, left, right, bottom) => {

    if (cell) {
        const top2 = top + 1
        const left2 = left + 1
        const right2 = right - 1
        const bottom2 = bottom - 1


        return (top <= cell.top && bottom >= cell.bottom && right >= cell.right && left <= cell.left)
            || (top2 < cell.bottom && top2 > cell.top && right2 > cell.left && right2 < cell.right)
            || (top2 < cell.bottom && top2 > cell.top && left2 > cell.left && left2 < cell.right)
            || (bottom2 > cell.top && bottom2 < cell.bottom && right2 > cell.left && right2 < cell.right)
            || (bottom2 > cell.top && bottom2 < cell.bottom && left2 > cell.left && left2 < cell.right)

    } else {
        return false
    }
}


export const findMaxKoords = (arr, type, mark) => {

    return arr.length ? arr.reduce((prev, current) => {
            return mark === "<" ? prev?.koords?.[type] < current?.koords?.[type] ? prev : current
                : prev?.koords?.[type] > current?.koords?.[type] ? prev : current
        }, {}
    ) : null
}

export const handleUpdateData = (tableData, colls, rowsCount, setSelected, setValues) => {
    let firstSel = false;

    return tableData.reduce((ac, cur) => {

        const newRow = cur.val.reduce((ac1, cur1) => {
            if (cur1.selected && !firstSel) {
                firstSel = true
                const res = {...cur1, colspan: colls, rowspan: rowsCount, selected: false}
                setValues(pre => ({...pre, tableDefault: ({
                        ...pre.tableDefault,
                            internalSpanmap: [
                                ...pre.tableDefault.internalSpanmap,
                                {
                                    test: true,
                                    keyPosition: {
                                        row: res.posX,
                                        column: res.posY,
                                    },
                                    "actualPosition": {
                                        row: res.rowspan,
                                        column: res.colspan
                                    }
                                }
                            ]
                        })}))
                console.log('res', res)
                setSelected([res])
                return [...ac1, res]
            } else if (cur1.selected) {
                return ac1
            } else {
                return [...ac1, {...cur1, selected: false}]
            }
        }, [])

        return [...ac, {...cur, val: newRow}]
    }, [])
}


export const findMaxBorder = (arr, type, mark) => {

    return arr.length ? arr.reduce((prev, current) => {
            return mark === "<" ? prev?.[type] < current?.[type] ? prev : current
                : prev?.[type] > current?.[type] ? prev : current
        }, {}
    ) : null
}

export const handleBorderRect = (selected) => {
    const top = findMaxKoords(selected, "top", "<")
    const bottom = findMaxKoords(selected, "bottom", ">")
    const right = findMaxKoords(selected, "right", ">")
    const left = findMaxKoords(selected, "left", "<")

    return {
        top: top?.koords.top || 0,
        left: left?.koords.left || 0,
        right: right?.koords.right || 0,
        bottom: bottom?.koords.bottom || 0,

    }
}

export const LightTooltip = withStyles((theme) => ({
    tooltip: {
        border: `1px solid grey`,
        backgroundColor: 'rgba(251,236,136, 0.9)',
        color: "black",
        boxShadow: `1px 2px 15px grey`,
        fontWeight: "bold",
        fontSize: '15px',
    },
}))(Tooltip);