import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import getLang from '@zert-packages/utils/getLang';
import { v4 as uuidv4 } from 'uuid';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import HoverWithSnackbarLoaderDialog from '@zert-packages/components/common/dialogs/HoverWithSnackbarLoaderDialog';
import { showSnackbarError } from '@zert-packages/components/Snackbars';
import { tasksLoaderActionPlanOff, tasksLoaderActionPlanOn } from './commonReducer';
import {
  FETCH_ACTION_PLAN_BEGIN,
  FETCH_ACTION_PLAN_SUCCESS,
  FETCH_ACTION_PLAN_MYJOB_SUCCESS,
  FETCH_ACTION_PLAN_WORKLFOW_SUCCES,
  FETCH_ACTION_PLAN_WORKLFOW_BEGIN,
  FETCH_TASK_PERFROM_BEGIN,
  FETCH_TASK_PERFROM_SUCCESS,
  FETCH_TASK_PERFROM_DONE,
  ACTION_PLAN_TASK_COUNT,
  MY_USER_FETCH,
  MY_USER_FETCH_START,
  MY_TASKS_ACTION,
  ACTION_PLAN_PAGE,
  ACTION_PLAN_TASK_PLANNED_COUNT
} from './actions';
import { handlePost, handleQuery, fetchRA_Error, fetchPerformMeasureError, handlePostParamAsString } from './api';

import { checkReportStatus } from './checklistActions';

const fetchMyUserDone = (user) => ({
  type: MY_USER_FETCH,
  payload: { user }
});

const fetchMyUserStart = () => ({
  type: MY_USER_FETCH_START,
  payload: {}
});

const fetch_ACTION_PLAN_MYJOB_SUCCESS = (elements) => ({
  type: FETCH_ACTION_PLAN_MYJOB_SUCCESS,
  payload: { elements }
});

const fetch_ACTION_PLAN_SUCCESS = (tasks) => ({
  type: FETCH_ACTION_PLAN_SUCCESS,
  payload: { tasks }
});

const fetch_MY_TASKS = (tasks) => ({
  type: MY_TASKS_ACTION,
  payload: { tasks }
});

const fetch_ACTION_PLAN_BEGIN = () => ({
  type: FETCH_ACTION_PLAN_BEGIN,
  payload: {}
});

const fetch_WORKLFOW_SUCCES = (workflowtemplate) => ({
  type: FETCH_ACTION_PLAN_WORKLFOW_SUCCES,
  payload: { workflowtemplate }
});

const fetch_WORKLFOW_BEGIN = () => ({
  type: FETCH_ACTION_PLAN_WORKLFOW_BEGIN,
  payload: {}
});

const fetch_TASK_PERFROM_BEGIN = () => ({
  type: FETCH_TASK_PERFROM_BEGIN,
  payload: {}
});

const fetch_TASK_PERFROM_SUCCESS = (task) => ({
  type: FETCH_TASK_PERFROM_SUCCESS,
  payload: { task }
});

const fetch_TASK_PERFROM_DONE = () => ({
  type: FETCH_TASK_PERFROM_DONE,
  payload: {}
});

const fetch_ACTION_PLAN_TASK_COUNT = (count) => ({
  type: ACTION_PLAN_TASK_COUNT,
  payload: { count }
});

const fetch_ACTION_PLAN_TASK_PLANNED_COUNT = (count) => ({
  type: ACTION_PLAN_TASK_PLANNED_COUNT,
  payload: { count }
});

export const fetch_ACTION_PLAN_PAGE = (page) => ({
  type: ACTION_PLAN_PAGE,
  payload: page
});

export function getUsersQuery(query) {
  return handleQuery(`/actionplan-react/findUsers/${query}/${-1}`);
}

export function loadWorkflowTemplate(workflowtemplateid) {
  return function (dispatch) {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    handleQuery(`/actionplan-react/getWorkflowTemplate/${locale}/${workflowtemplateid}`)
      .then((workflowtemplate) => {
        dispatch(fetch_WORKLFOW_SUCCES(workflowtemplate));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetch_WORKLFOW_BEGIN());
  };
}

export function performTask(taskId, nextTransition) {
  return function (dispatch) {
    handlePost(`/actionplan-react/performTask/${taskId}/${nextTransition}`, {})
      .then((task) => {
        dispatch(fetch_TASK_PERFROM_SUCCESS(task));
        dispatch(fetch_TASK_PERFROM_DONE());
      })
      .catch((error) => {
        dispatch(fetchPerformMeasureError(error));
      });
    return dispatch(fetch_TASK_PERFROM_BEGIN());
  };
}

export function getInterval(amount, workflowAgainUnit) {
  return (
    <FormattedMessage
      id="actionplan.repeatevery"
      defaultMessage="Every {workflowStart} {unit}"
      values={{
        workflowStart: amount,
        unit: getIntervalLabel(workflowAgainUnit)
      }}
    />
  );
}

export function getIntervalLabel(value) {
  if (value == 'd') {
    return <FormattedMessage id="working.days" defaultMessage="Working days" />;
  }
  if (value == 'w') {
    return <FormattedMessage id="working.weeks" defaultMessage="Weeks" />;
  }
  if (value == 'M') {
    return <FormattedMessage id="working.months" defaultMessage="Months" />;
  }
  if (value == 'y') {
    return <FormattedMessage id="working.year" defaultMessage="Years" />;
  }
}

export function getElementDate(task, intl) {
  return (
    <span>
      <FormattedMessage id="page.actionplan.lastEdit" defaultMessage="'Last edit " />{' '}
      {getDeadlineLabel(task.changedAt, intl)}
    </span>
  );
}

export function getDeadlineLabel(date_str, intl) {
  const today = moment().endOf('day');
  const tomorrow = moment().add(1, 'day').endOf('day');
  const yesterday = moment().add(-1, 'day').endOf('day');
  const date = moment(date_str);
  const days = date.diff(moment().endOf('day'), 'days');
  const formattedDays = (
    <div>
      <FormattedMessage id="deadline.days" defaultMessage="days" />,
      <FormattedMessage id="deadline.months" defaultMessage="months" />,
      <FormattedMessage id="deadline.years" defaultMessage="years" />,
      <FormattedMessage id="deadline.year" defaultMessage="year" />,
      <FormattedMessage id="deadline.month" defaultMessage="month" />,
      <FormattedMessage id="deadline.day" defaultMessage="day" />,
      <FormattedMessage id="deadline.ago" defaultMessage="ago" />,
      <FormattedMessage id="deadline.in" defaultMessage="in" />
    </div>
  );
  if (date < today && date >= yesterday) return <FormattedMessage id="deadline.duetoday" defaultMessage="Due today" />;
  if (date < tomorrow && date >= today)
    return <FormattedMessage id="deadline.duetommorow" defaultMessage="Due tomorrow" />;
  if (days < 30) {
    let fromNow = date.fromNow();
    fromNow = fromNow
      .replace(
        'days',
        intl.formatMessage({
          id: 'deadline.days',
          defaultMessage: 'days'
        })
      )
      .replace(
        'months',
        intl.formatMessage({
          id: 'deadline.months',
          defaultMessage: 'months'
        })
      )
      .replace(
        'years',
        intl.formatMessage({
          id: 'deadline.years',
          defaultMessage: 'years'
        })
      )
      .replace(
        'a year',
        intl.formatMessage({
          id: 'deadline.year',
          defaultMessage: 'year'
        })
      )
      .replace(
        'a month',
        intl.formatMessage({
          id: 'deadline.month',
          defaultMessage: 'month'
        })
      )
      .replace(
        'a day',
        intl.formatMessage({
          id: 'deadline.day',
          defaultMessage: 'day'
        })
      )
      .replace(
        'ago',
        intl.formatMessage({
          id: 'deadline.ago',
          defaultMessage: 'ago'
        })
      )
      .replace(
        'in',
        intl.formatMessage({
          id: 'deadline.in',
          defaultMessage: 'in'
        })
      );
    return fromNow;
  }
  return date_str === '' ? (
    <FormattedMessage id="deadline.notsetuped" defaultMessage="No deadline" />
  ) : (
    <span>{date.format('YYYY-MM-DD')}</span>
  );
}

export function loadActionPlan(sceduled, mimetype, mine) {
  return function (dispatch) {
    dispatch(tasksLoaderActionPlanOn());
    const locale =
      document.getElementsByTagName('html')[0].getAttribute('lang') != null &&
      document.getElementsByTagName('html')[0].getAttribute('lang').length > 0
        ? document.getElementsByTagName('html')[0].getAttribute('lang')
        : 'sv';
    handlePostParamAsString(`/actionplan-react/synctasks/${locale}/${sceduled ? '*' : 'all'}/${mimetype}`, mine)
      .then((mytasks) => {
        dispatch(tasksLoaderActionPlanOff());
        dispatch(fetch_ACTION_PLAN_SUCCESS(mytasks.left));
        if (sceduled && mytasks) {
          dispatch(fetch_ACTION_PLAN_TASK_COUNT(mytasks.left.length));
          dispatch(fetch_ACTION_PLAN_TASK_PLANNED_COUNT(mytasks.right));
          dispatch(fetch_MY_TASKS(mytasks.left));
        }

        return handleQuery('/actionplan-react/getNextThingsToDo');
      })
      .then((myelements) => {
        dispatch(fetch_ACTION_PLAN_MYJOB_SUCCESS(myelements));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetch_ACTION_PLAN_BEGIN());
  };
}

export function getUser(actioPlan) {
  return function (dispatch) {
    handleQuery('/common/myUserName')
      .then((user) => {
        dispatch(fetchMyUserDone(user));
        if (actioPlan) {
          dispatch(loadActionPlan(true, '*', user.userName));
        }
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchMyUserStart());
  };
}

export const delegateTasks = (userLogin, tasksIds, message, successfulCallback) =>
  handlePost(`/actionplan-react/delegate`, {
    message,
    versionIds: tasksIds,
    user: userLogin
  });

export const getTaskMetaData = (versionId) =>
  handleQuery(`/actionplan-react/getTaskMetaData/${getLang()}/${versionId}`);

export const informUser = (userLogin, versionIds, message) =>
  handlePost(`/actionplan-react/inform/${encodeURI(userLogin)}`, {
    left: versionIds,
    right: message
  });

export const informUsers = (userLogins, versionId, message) =>
  handlePost(`/actionplan-react/inform`, {
    userLogins,
    versionId,
    message
  });

export const performTaskAPI = (taskId, nextTransition) =>
  handlePost(`/actionplan-react/performTask/${taskId}/${nextTransition}`, {});

export const getWorkflowTemplateAPI = (workflowTemplateId) =>
  handleQuery(`/actionplan-react/getWorkflowTemplate/${getLang()}/${workflowTemplateId}`);

export const removeElement = (element, finishCallback) => (dispatch, getState, hooks) => {
  const report = {
    name: <FormattedMessage id="ActionPlan.DeleteElementReport" defaultMessage="Deletion report" />,
    uuid: uuidv4()
  };
  const freezeScreenOff = renderInExceptionRoot(HoverWithSnackbarLoaderDialog);
  const onFinish = () => {
    freezeScreenOff();
    finishCallback();
  };
  handlePost(`/explorer/remove/${report.uuid}/${-1}/true`, [element.id])
    .then((r) => {
      freezeScreenOff();
      checkReportStatus({
        report,
        onFinish,
        generatedReport: r,
        mimeType: element.mimeType
      });
    })
    .catch((e) => {
      showSnackbarError(
        hooks.snackbar,
        <FormattedMessage id="ActionPlan.Actions.RemoveElement" defaultMessage="You cannot remove this element" />
      );
    });
};
