import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { loadIndex } from '../TranslationJobPlugin/tjReducers';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 11,
    background: '#E2F2F6'
  },
  wrapperButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 20,
  },
  button: {
    color: '#ffffff',
    background: '#4db1d3',
    marginLeft: 10,
    minWidth: 80,
    border: '1px solid #4db1d3',
    outline: 'none !important',
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
      color: '#212529',
      border: '1px solid #4db1d3'
    },
    '&:focus': {
      backgroundColor: '#4db1d3'
    }
  }
}))

function CategoryDialog({ handleClose, actionState }) {
  const cl = useStyles();
  const CLM = useSelector((state) => state.CLM);
  const dispatch = useDispatch();
  const [index, setIndex] = useState(CLM.index);
  const [action, setAction] = useState('NONE');

  const [category, setCategory] = useState(
    actionState.isEdit && actionState.selectedElement >= 0 ? CLM.index.categories[actionState.selectedElement] : ''
  );

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleStateChange = () => {
    if (actionState.isEdit) {
      setIndex((prevState) => ({
        ...prevState,
        categories: prevState.categories.map((item, idx) => {
          if (idx === actionState.selectedElement) {
            return category;
          }
          return item;
        })
      }));
    } else if (actionState.isRemove) {
      setIndex((prevState) => ({
        ...prevState,
        categories: prevState.categories.filter((el, idx) => idx !== actionState.selectedElement)
      }));
    } else {
      setIndex((prevState) => ({
        ...prevState,
        categories: [...prevState.categories, category]
      }));
    }

    setAction('MODIFY');
  };

  useEffect(() => {
    if (action === 'MODIFY') {
      dispatch(loadIndex(index));
      handleClose();
    }
  }, [action]);

  return (
      <Dialog open onClose={handleClose} fullWidth classes={{paperScrollPaper: cl.root}}>
        <DialogTitle>
          {actionState.isRemove
              ? `Remove \'${index.categories[actionState.selectedElement]}\' category?`
              : actionState.isEdit
                  ? `Edit \'${index.categories[actionState.selectedElement]}\' category?`
                  : 'Add new category?'}
        </DialogTitle>
        {!actionState.isRemove && (
            <DialogContent>
              <TextField fullWidth label="Category" variant="outlined" onChange={handleChangeCategory}
                         value={category}/>
            </DialogContent>
        )}
        <div className={cl.wrapperButton}>
          <Button className={cl.button} onClick={handleStateChange}>
            {actionState.isRemove ? 'REMOVE' : actionState.isEdit ? 'EDIT' : 'ADD'}
          </Button>
          <Button className={cl.button} onClick={handleClose}>
            CANCEL
          </Button>
        </div>
      </Dialog>
  );
}

export default CategoryDialog;
