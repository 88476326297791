import React, { memo } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import ExpansionWrapperCriteria from '../common/ExpansionWrapperCriteria';

const rootStyles = {
  display: 'grid',
  gridGap: '10px',
  gridTemplateColumns: '1fr 1fr',
  width: '100%'
};

const NameSearchCriteria = memo(
  ({ foundCriteria, updateCriteria, onChangeExpansionOfCriteria, classNameOfCriteria, isEverythingDisabled }) => {
    const onChangeState = (field, value) => {
      const newValues = foundCriteria.values.map((item) => {
        if (item.key === field) {
          return {
            ...item,
            value
          };
        }
        return item;
      });
      updateCriteria(classNameOfCriteria, newValues);
    };

    const handleOnChangeExpansion = (event, isExpanded) => {
      onChangeExpansionOfCriteria(isExpanded, classNameOfCriteria);
    };
    return (
      <ExpansionWrapperCriteria
        title={<FormattedMessage id="nameCriteriaCounter.name" defaultMessage="Name" />}
        handleOnChangeExpansion={handleOnChangeExpansion}
        expanded={!!foundCriteria}
      >
        {!!foundCriteria && (
          <div style={rootStyles}>
            <FormControl variant="standard" fullWidth disabled={isEverythingDisabled}>
              <InputLabel>
                <FormattedMessage id="nameCriteriaCounter.name" defaultMessage="Name" />
              </InputLabel>
              <Select
                variant="standard"
                value={foundCriteria.values.find((item) => item.key === 'Type').value}
                onChange={(event) => onChangeState('Type', event.target.value)}
              >
                <MenuItem value="standard">
                  <FormattedMessage id="nameCriteriaCounter.byText" defaultMessage="By text" />
                </MenuItem>
                <MenuItem value="fulltext">
                  <FormattedMessage id="nameCriteriaCounter.byFullText" defaultMessage="By full text" />
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              disabled={isEverythingDisabled}
              label={<FormattedMessage id="nameCriteriaCounter.name" defaultMessage="Name" />}
              margin="dense"
              variant="outlined"
              value={foundCriteria.values.find((item) => item.key === 'Name').value}
              onChange={(event) => onChangeState('Name', event.target.value)}
            />
          </div>
        )}
      </ExpansionWrapperCriteria>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps.foundCriteria, nextProps.foundCriteria)
);

export default NameSearchCriteria;
