import findNode from './findNode';

export default function findNodeInProject(project, uuid) {
  for (let i = 0; i < project.activities.length; i += 1) {
    const currentChild = project.activities[i];

    // Search in the current child
    const result = findNode(currentChild, uuid);

    // Return the result if the node has been found
    if (result != null) {
      return result;
    }
  }
  return null;
}
