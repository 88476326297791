import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { TabsContext } from '../../Stylesheet';
import Loading from '../../Loading';
import TemplateItemRow from '../../TemplateItemRow';
import { adobeColor, adobeRgba, handleIndex, handleTableSingleLevel } from "../../utils/helpers";
import { fontData } from '../../data/fontData';
import SelectedColor from '../../SelectedColor';
import DialogColors from '../../dialog/DialogColors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 10
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '130px 1fr',
    gridColumnGap: 10
  },
  title: {
    fontWeight: 700
  },
  wrapperCheckbox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr ',
    maxWidth: 500
  }
}));

function Formats() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const formats = values.stylesheet && values.stylesheet.indexSettings && values.stylesheet.indexSettings[0];
  const [selectedStructure, setSelectedStructure] = useState({
    name: null
  });
  const structureItem = values.stylesheet && values.stylesheet.indexSettings && values.stylesheet.indexSettings[0];
  const selectedStructureItem = selectedStructure.name && structureItem[selectedStructure.name];
  const [selectedContentLimits, setSelectedContentLimits] = useState('User defined');
  const [color, setColor] = useState({
    color: '#ffffff',
    load: false,
  })


  const handleFormat = (val) => {
    setSelectedStructure((pre) => ({ ...pre, name: val }));
  };

  const handleValuesBlock = (val, name) => {
    const result = {
      ...selectedStructureItem[name],
      unit: selectedStructureItem.fontSize ? selectedStructureItem.fontSize.unit : 'POINTS',
      value: +val
    };
    handleIndex(result, name, 'indexSettings', selectedStructure.name, values, setValues);
  };

  const handleSelectedDropMenu = (valDropMenu, name, val) => {
    const result = { unit: valDropMenu, value: +val };
    handleIndex(result, name, 'indexSettings', selectedStructure.name, values, setValues);
  };
  const setDropMenuFirst = (val, name) => {
    if (val === 'Default') {
      const result = { unit: 'pt', value: 12 };
      handleIndex(result, name, 'indexSettings', selectedStructure.name, values, setValues);
    }
    setSelectedContentLimits(val);
  };
  const handleColor = (val, name) => {
    setColor(pre => ({ ...pre, color:  val}))
    handleIndex(adobeRgba(val), name, 'indexSettings', selectedStructure.name, values, setValues);
  };

  const findCheckbox = (el) => {
    return selectedStructureItem.fontStyle.map((it) => it.toLowerCase()).find((it) => it === el.toLowerCase());
  };
  const handleCheckboxFontStyle = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    if (val) {
      const result = [...selectedStructureItem.fontStyle, name.toUpperCase()];
      return handleIndex(result, 'fontStyle', 'indexSettings', selectedStructure.name, values, setValues);
    }
    const result = selectedStructureItem.fontStyle.filter((el) => el !== name.toUpperCase());
    handleIndex(result, 'fontStyle', 'indexSettings', selectedStructure.name, values, setValues);
  };

  const handleOpenDialog = (type) => {
    renderInExceptionRoot(DialogColors, {
      color: selectedStructureItem[type],
      title: 'Select Color',
      setText: handleColor,
      type
    });
  };

  useEffect(() => {
    if (!selectedStructure.name && structureItem) {
      setSelectedStructure((pre) => ({
        ...pre,
        name: Object.keys(structureItem).slice(0, Object.keys(structureItem).length - 2)[0]
      }));
    }
  }, [selectedStructure.name, structureItem]);

  useEffect(() => {
    if (selectedStructureItem && !selectedStructureItem.fontSize) {
      setSelectedContentLimits('Default');
    }
  }, [selectedStructureItem]);

  useEffect(() => {
    if(!color.load && selectedStructureItem?.color){
      setColor(pre => ({
        ...pre,
        color: adobeColor(selectedStructureItem.color),
        load: true}))
    }
  }, [selectedStructureItem?.color])



  return (
    <div>
      {structureItem && selectedStructure.name ? (
        <div className={cl.root}>
          <ExpanderItem
            valueProps={selectedStructure.name}
            propsLabel={Object.keys(structureItem).slice(0, Object.keys(structureItem).length - 2)}
            setProps={handleFormat}
          />
          <div className={cl.wrapperRow}>
            <span className={cl.title}> Font family:</span>
            <ExpanderItem
              valueProps={formats.category.fontFamily}
              type={'category'}
            />
          </div>
          <TemplateItemRow
            label="Font size:"
            type="fontSize"
            value={{
              value: selectedStructureItem.fontSize ? selectedStructureItem.fontSize.value : '12',
              unit: selectedStructureItem.fontSize ? selectedStructureItem.fontSize.unit : 'POINTS'
            }}
            grid="130px 150px 1fr 55px"
            dropMenuFirst={{
              label: ['Default', 'User defined'],
              selected: selectedContentLimits
            }}
            setDropMenu={handleSelectedDropMenu}
            setValueProps={handleValuesBlock}
            setDropMenuFirst={setDropMenuFirst}
            disabled={selectedContentLimits === 'Default'}
          />
          <div className={cl.wrapperRow}>
            <span className={cl.title}>Font style:</span>
            <div className={cl.wrapperCheckbox}>
              {fontData.map((el, i) => (
                <div key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox onChange={handleCheckboxFontStyle} checked={findCheckbox(el.name)} name={el.name} />
                    }
                    label={<span>{el.name}</span>}
                  />
                </div>
              ))}
            </div>
          </div>
          <SelectedColor
            label="Color:"
            type="color"
            setValueProps={handleColor}
            handleOpenDialog={handleOpenDialog}
            style={{ grid: '130px 30px 1fr 25px' }}
            valueProps={color.color}
          />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Formats;
