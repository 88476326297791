import React, { useEffect, useState } from 'react';
import { AppBar, Tabs, Tab } from '@mui/material';
import TabPanel from '@zert-packages/components/common/TabPanel';
import './MailSettings.css';
import dialogCustom from '@zert-packages/components/common/dialogCustom';
import SaveConfirmDialog from '@zert-packages/components/common/dialogs/SaveConfirmDialog';
import { FormattedMessage } from 'react-intl';
import ModalCenter from '@zert-packages/components/common/ModalCenter';
import { mailSettings, storeMailSettings } from '@zert-packages/actions/coreReducers';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { useSelector } from 'react-redux';
import validateMainSettings from './helpers/validateMainSettings';
import { ContextProvider } from './common/ContextProvider';
import FilterPage from './FilterPage';
import ServerPage from './ServerPage';
import GeneralPage from './GeneralPage';

const roles = {
  TASK_ADMINISTRATOR: 'task-administrator',
  ADMINISTRATOR: 'administrator'
};

function MailSettingsPage() {
  const userRole = useSelector((state) => state.myuser.role);
  const [defaultData, setDefaultData] = useState(null);
  const [mainData, setMainData] = useState(null);
  const [errors, setErrors] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isStoring, setIsStoring] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  useEffect(() => {
    if (!defaultData && !isLoading && !isError) {
      setIsLoading(true);

      async function loadSettings() {
        try {
          const data = await mailSettings();
          setDefaultData(data);
          setMainData(data);
        } catch (e) {
          setIsError(true);
        }

        setIsLoading(false);
      }

      loadSettings();
    }
  });

  const handleUpdateGeneral = (parentField, fieldToUpdate, value) => {
    setIsSomethingChanged(true);
    setMainData((prev) => ({
      ...prev,
      general: {
        ...prev.general,
        [parentField]: {
          ...prev.general[parentField],
          [fieldToUpdate]: value
        }
      }
    }));
  };

  const handleUpdateServer = (fieldToUpdate, value) => {
    setIsSomethingChanged(true);
    setMainData((prev) => ({
      ...prev,
      server: {
        ...prev.server,
        [fieldToUpdate]: value
      }
    }));
  };

  const updateTaskType = (parentField, mimeType, field, value) => {
    setIsSomethingChanged(true);
    setMainData((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        [parentField]: {
          ...prev.filter[parentField],
          taskTypes: prev.filter[parentField].taskTypes.map((task) => {
            if (task.mimeType === mimeType) {
              return {
                ...task,
                settings: {
                  ...task.settings,
                  [field]: value
                }
              };
            }
            return task;
          })
        }
      }
    }));
  };

  const updateOtherSettings = (parentField, fieldToUpdate, value) => {
    setIsSomethingChanged(true);
    setMainData((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        [parentField]: {
          ...prev.filter[parentField],
          [fieldToUpdate]: value
        }
      }
    }));
  };

  const handleChangeSelectedTab = (event, newSelectedTab) => {
    function callbackClickYes() {
      const isSuccessful = handleSave();
      if (isSuccessful) setSelectedTab(newSelectedTab);
    }

    function callbackClickNo() {
      handleCancel();
      setSelectedTab(newSelectedTab);
    }

    if (isSomethingChanged) {
      dialogCustom(SaveConfirmDialog, {
        callbackClickYes,
        callbackClickNo
      });
    } else {
      setSelectedTab(newSelectedTab);
    }
  };

  const handleSave = () => {
    const newErrors = validateMainSettings(mainData, selectedTab);
    const isEverythingValid = Object.keys(newErrors).length === 0;
    if (isEverythingValid) {
      setDefaultData({ ...mainData });
      setIsSomethingChanged(false);
      setIsStoring(true);
      async function store() {
        const list = await storeMailSettings(mainData);
        setIsStoring(false);
        return list;
      }
      try {
        store();
      } catch (e) {
        setIsStoring(false);
      }
    }
    setErrors(newErrors);
    return isEverythingValid;
  };

  const handleCancel = () => {
    setIsSomethingChanged(false);
    setErrors({});
    setMainData({ ...defaultData });
  };
  const valuesForMailSettingsContext = {
    defaultData,
    mainData,
    errors,

    userRole,
    roles,

    handleUpdateGeneral,
    handleUpdateServer,
    handleUpdateFilter: {
      updateTaskType,
      updateOtherSettings
    },

    handleSave,
    handleCancel
  };
  if (isLoading || !defaultData) {
    return (
      <Loader>
        <FormattedMessage id="mailSettings.loading" defaultMessage="Settings" />
      </Loader>
    );
  }

  return (
    <ContextProvider values={valuesForMailSettingsContext}>
      <ModalCenter open={isStoring}>
        <Loader displayText={false} />
      </ModalCenter>
      <div className="mail-settings__container">
        <AppBar position="static" color="default">
          <Tabs
            value={selectedTab}
            onChange={handleChangeSelectedTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={<FormattedMessage id="MailSettings.TabPanel.General" defaultMessage="General" />} value={0} />
            {userRole === roles.ADMINISTRATOR && (
              <Tab label={<FormattedMessage id="MailSettings.TabPanel.Server" defaultMessage="Server" />} value={1} />
            )}
            <Tab
              label={<FormattedMessage id="MailSettings.TabPanel.MailFilters" defaultMessage="Mail filters" />}
              value={2}
            />
          </Tabs>
        </AppBar>
        <TabPanel index={0} value={selectedTab}>
          <GeneralPage />
        </TabPanel>
        <TabPanel index={1} value={selectedTab}>
          <ServerPage />
        </TabPanel>
        <TabPanel index={2} value={selectedTab}>
          <FilterPage />
        </TabPanel>
      </div>
    </ContextProvider>
  );
}

export default MailSettingsPage;
