import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import UserRoleSuggestBox from './UserRoleSuggestBox';
import TextFieldWithLanguageSwitch from '@zert-packages/components/common/TextFieldWithLangaugeSwitch';

import './Roles.css';
import { FormattedMessage } from 'react-intl';

function EditRoleDialog({ state: initialState, handleClose, saveRole }) {
  const [state, setState] = useState({ ...initialState });
  const handleChangeState = (field, value) =>
    setState((prev) => ({
      ...prev,
      [field]: value
    }));

  const handleConfirm = () => {
    saveRole({
      ...state
    });
    handleClose();
  };
  return (
    <Dialog fullWidth onClose={handleClose} open>
      <DialogTitle>
        <FormattedMessage id="editRoleDialog.addEditRole" defaultMessage="Add/Edit role" />
      </DialogTitle>
      <DialogContent>
        <div className="dialog-edit__container">
          <div className="captionLabel">
            <FormattedMessage id="editRoleDialog.identifier" defaultMessage="Identifier" />
            <span className="required">*</span>
          </div>
          <TextField
            margin="dense"
            variant="outlined"
            value={state.identifier}
            onChange={(event) => handleChangeState('identifier', event.target.value)}
          />

          <div className="captionLabel">
            <FormattedMessage id="editRoleDialog.name" defaultMessage="Name" />
          </div>
          <TextFieldWithLanguageSwitch
            state={state.label}
            onChange={(newLabel) => handleChangeState('label', newLabel)}
          />
        </div>

        <UserRoleSuggestBox
          onSelect={(user) => handleChangeState('user', user)}
          initialValue={(state.user && state.user.fullname) || ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="editRoleDialog.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="outlined"
          autoFocus
          disabled={!state.identifier || state.identifier.length <= 0}
        >
          <FormattedMessage id="editRoleDialog.confirm" defaultMessage="Confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditRoleDialog;
