import * as React from 'react';
import EnhancedTable from '@zert-packages/components/EnhancedTable/EnhancedTable';
import useSelectionModel from '@zert-packages/components/EnhancedTable/useSelectionModel';
import { FormattedMessage, useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { descendingComparator } from '@zert-packages/components/ElementTile/utils/descendingComparator';
import { useAppDispatch, useAppSelector } from '@zert-packages/react/hooks/hooks';
import { loadLog } from '../../actions/reducers';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    whiteSpace: 'pre'
  }
});

export const logSorter = (identifier) => {
  switch (identifier) {
    case 'date':
      return (a, b, orderBy, sortDirection) => {
        const bValue = b[orderBy].props.children[0].props.children + b[orderBy].props.children[1].props.children;
        const aValue = a[orderBy].props.children[0].props.children + a[orderBy].props.children[1].props.children;

        if (bValue < aValue) {
          return -1;
        }
        if (bValue > aValue) {
          return 1;
        }
        return 0;
      };
    default:
      return (a, b, orderBy, sortDirection) => {
        return descendingComparator(a, b, orderBy, sortDirection);
      };
  }
};

export const actions = ({}) => {
  const performActionHandler = (selected) => (actionId) => {};

  const elementActions = [];

  const toolbarActions = [];

  const getMenuActions = (row) => [];

  return { elementActions, toolbarActions, getMenuActions, performActionHandler };
};

export const columns = [
  {
    identifier: 'action',
    numeric: false,
    disablePadding: false,
    component: 'th',
    scope: 'row',
    isFilterable: true,
    label: <FormattedMessage id="log.table.event" defaultMessage="Event" />
  },
  {
    identifier: 'actionDetailed',
    numeric: false,
    disablePadding: false,
    component: 'th',
    scope: 'row',
    isFilterable: true,
    label: <FormattedMessage id="log.table.info" defaultMessage="Info" />
  },
  {
    identifier: 'date',
    numeric: false,
    disablePadding: false,
    component: 'th',
    scope: 'row',
    isFilterable: true,
    label: <FormattedMessage id="log.table.date" defaultMessage="Date" />
  }
];

export const handler = (element, intl) => {
  const getEventMessage = (event) => {
    if (event == 'ADDED_DOCUMENT_ON_NODE') {
      return intl.formatMessage({
        id: 'log.event.document.addedinpublication',
        defaultMessage: 'Added document inside publication'
      });
    }
    if (event == 'DELETED_DOCUMENT_ON_NODE') {
      return intl.formatMessage({
        id: 'log.event.document.deletedinpublication',
        defaultMessage: 'Deleted document inside publication'
      });
    }
    if (event == 'UPDATE_DOCUMENT_ON_NODE') {
      return intl.formatMessage({
        id: 'log.event.document.updatedinpublication',
        defaultMessage: 'Updated document inside publication'
      });
    }
    if (event == 'ADD_DOCUMENT_ON_MIRC') {
      return intl.formatMessage({ id: 'log.event.document.addedtomisc', defaultMessage: 'Added document to misc' });
    }
    if (event == 'DELETED_DOCUMENT_ON_MIRC') {
      return intl.formatMessage({ id: 'log.event.document.deleted', defaultMessage: 'Deleted document in misc' });
    }
    if (event == 'UPDATE_DOCUMENT_ON_MIRTH') {
      return intl.formatMessage({ id: 'log.event.document.updated', defaultMessage: 'Updated document in misc' });
    }
    if (event == 'CLEARED_DOCUMENT_ON_MIRTH') {
      return intl.formatMessage({ id: 'log.event.document.cleared', defaultMessage: 'Cleared document in misc' });
    }
    if (event == 'APPROVED_DOCUMENT_ON_MIRTH') {
      return intl.formatMessage({ id: 'log.event.document.approved', defaultMessage: 'Approved document in misc' });
    }
    if (event == 'CREATED_SPARE_PART') {
      return intl.formatMessage({ id: 'log.event.sparepart.create', defaultMessage: 'Created spare part' });
    }
    if (event == 'UPDATED_SPARE_PART') {
      return intl.formatMessage({ id: 'log.event.sparepart.updated', defaultMessage: 'Updated spare part' });
    }
    if (event == 'NEW_BOOK_ATTACHED') {
      return intl.formatMessage({ id: 'log.event.addnew.publishing', defaultMessage: 'Added new publishing' });
    }
    if (event == 'BOOK_UPDATED') {
      return intl.formatMessage({ id: 'log.event.update.publishing', defaultMessage: 'Updated publishing' });
    }

    return intl.formatMessage({ id: 'log.event.unknown', defaultMessage: 'Unknown' });
  };

  const _handler = {
    get(target, key) {
      if (key === 'action') {
        return getEventMessage(target.action);
      }
      if (key === 'actionDetailed') {
        return target.actionDetailed.map((action) => {
          return `${action}\n`;
        });
      }
      if (key === 'date') {
        return (
          <>
            <span>{target.date.split('T')[0]}</span>
            <span style={{ display: 'none' }}>{target.date.split('T')[1]}</span>
          </>
        );
      }
      return target[key];
    }
  };
  return _handler;
};

export const columnHandler = (column) => {
  const _handler = {
    get(target, key) {
      if (key === 'customComparator') {
        return logSorter(target.identifier);
      }

      return target[key];
    }
  };
  return _handler;
};

function Log() {
  const log = useAppSelector((state) => state.log);
  const { id }: any = useParams();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const model = log ? log.map((element) => new Proxy(element, handler(element, intl))) : [];
  const columnModel = columns ? columns.map((column) => new Proxy(column, columnHandler(column))) : [];
  const selectionModel = useSelectionModel(model, false, 'versionId');

  const cl = useStyles();
  useEffect(() => {
    dispatch(loadLog(id));
  }, [id]);

  return (
    <div className={cl.root}>
      <div className="product-header">
        <Typography variant="h1">
          <FormattedMessage id="header.machine.log" defaultMessage="Log" />
        </Typography>
      </div>

      <EnhancedTable
        rows={model}
        identifier="uuid"
        columns={columnModel}
        sortingKey="date"
        selectionModel={selectionModel}
        sortingOrder="desc"
        showElementActions={false}
        doNotShowSelect
        doNotShowToolbar={false}
        actions={actions}
        detailComponent={null}
        customSelectionCallback={null}
        selectOnlyOne
        maxHeight="100%"
        otherActions={null}
        loading={false}
        getCustomFilter={null}
        bradCrumbComponent={null}
      />
    </div>
  );
}

export default Log;
