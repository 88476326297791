import React from 'react';
import './style.css';

class Spacer extends React.Component {
  render() {
    return <div className="spacer" sizename={this.props.sizeName} />;
  }
}

export default Spacer;
