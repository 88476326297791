import { useState, useCallback } from 'react';
import Cookies from 'js-cookie';

export default function useCookie(name, defaultValue, expires?, path?) {
  const [value, setValue] = useState(() => {
    const cookie = Cookies.get(name, path ? { path } : undefined);

    if (cookie !== undefined) {
      try {
        return JSON.parse(cookie);
      } catch (error) {
        return defaultValue;
      }
    }

    if (expires && path) {
      Cookies.set(name, JSON.stringify(defaultValue), { expires, path });
    } else if (expires) {
      Cookies.set(name, JSON.stringify(defaultValue), { expires });
    } else if (path) {
      Cookies.set(name, JSON.stringify(defaultValue), { path });
    } else {
      Cookies.set(name, JSON.stringify(defaultValue));
    }

    return defaultValue;
  });

  const updateCookie = useCallback(
    (newValue) => {
      const newValueString = JSON.stringify(newValue);
      if (expires && path) {
        Cookies.set(name, newValueString, { expires, path });
      } else if (expires) {
        Cookies.set(name, newValueString, { expires });
      } else if (path) {
        Cookies.set(name, newValueString, { path });
      } else {
        Cookies.set(name, newValueString);
      }
      setValue(newValue);
    },
    [name, expires, path]
  );

  const deleteCookie = useCallback(() => {
    Cookies.remove(name, path ? { path } : undefined);
    setValue(defaultValue);
  }, [name, path, defaultValue]);

  return [value, updateCookie, deleteCookie];
}
