import { restoreState } from '@zert-packages/actions/commonReducer';
import React, { useEffect, useState } from 'react';
import './App.css';
import Helmet from 'react-helmet';
import HeadBar from '@zert-packages/components/HeadBar/HeadBarNew';
import ContentCage from '@zert-packages/components/Content/ContentCage';
import { IntroductionProvider } from '@zert-packages/components/common/Introduction';
import {
  getCatalogs,
  getPlugins,
  getPluginsOverview,
  handlePost,
  handleQuery,
  handleText,
  loadMyLocale,
  registerToken
} from '@zert-packages/actions/api';
import LicensePage from '@zert-packages/components/LicensePage';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector, useDispatch } from 'react-redux';
import ZertLoaderCenter from '@zert-packages/components/shared/ZertLoaderCenter';
import GuestGuard from '@zert-packages/plugins/auth/GuestGuard';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import LoginView from '@zert-packages/plugins/auth/LoginView';
import AuthGuard from '@zert-packages/plugins/auth/AuthGuard';
import { ViewportProvider } from '@zert-packages/utils/ViewportProvider';
import { PortalTheme } from '@zert-packages/react/themes/Themes';
import SideBar from '@zert-packages/components/side-bar/SideBar';
import axios from '@zert-packages/utils/axios';
import { Announcement, NotificationImportant, Settings, Logout } from '@mui/icons-material';
import ErrorBoundary from '@zert-packages/components/ErrorBoundary/ErrorBoundary';
import useGoogleAnalytics from '@zert-packages/react/hooks/useGoogleAnalytics';
import { GetEnv } from '@zert-packages/react/EnvProvider';
import useTitle from '@zert-packages/react/hooks/useTitle';
import { useNewRoutes } from './useNewRoutes';
import {getAllShortcutsForUser} from "@zert-packages/actions/shortcutActions";


const INSTANCE_TOKEN = 'INSTANCE_TOKEN';

require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/locale-data/sv');
require('@formatjs/intl-pluralrules/locale-data/en');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
}));

const useProfileBox = () => {
  const { mynews, notificationList } = useSelector((state) => state);
  const profileBox = [];
  if (notificationList && notificationList.length > 0 && notificationList[0].left) {
    profileBox.push({
      to: `/notification/${notificationList[0].left}`,
      name: <FormattedMessage id="sidebar.profile.notifications" defaultMessage="Notifications" />,
      icon: NotificationImportant,
      number: notificationList.length
    });
  }
  if (mynews && mynews.left) {
    profileBox.push({
      to: `/news/${mynews.left}`,
      name: <FormattedMessage id="sidebar.profile.news" defaultMessage="News" />,
      icon: Announcement,
      number: mynews.right
    });
  }

  profileBox.push({
    to: '/settings/personal',
    name: <FormattedMessage id="sidebar.profile.settings" defaultMessage="Settings" />,
    icon: Settings
  });

  profileBox.push({
    to: '/logout',
    onClick: () => {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
      window.location.href = '/login';
      document.location.reload();
    },
    name: <FormattedMessage id="sidebar.profile.logout" defaultMessage="Logout" />,
    icon: Logout,
    onClickOnly: true
  });

  return profileBox;
};

export function App() {
  const { mylocale, catalogList, task_count, mynews, notificationList, myuser, permits, scrollHeader, myLanguage } = useSelector(
    (state) => state
  );
  const [locale, setLocale] = useState('sv');
  const [loadingLicense, setLoadingLicense] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [companySelected, setCompanySelected] = useState(false);
  const [loadCompanies, setLoadCompanies] = useState(true);
  const [hasLicense, setLicense] = useState(null);
  const shortcuts = useSelector(state => state.shortcuts);
  const history = useHistory();
  const location = useLocation();

  const findHeader = () => {
    const getHeader = document.getElementById('HeadBar');
    if(!getHeader ||  location.pathname.includes('/about-zert') ){
      return false
    }
    const computedStyle = window.getComputedStyle(getHeader);
    const visibility = computedStyle.getPropertyValue('visibility');
    return !!visibility;
    // const url =  window.location.pathname
    // return url.includes('block') || url.includes('softwaretexts') || url.includes('table');
  }




  const headBar = findHeader()
  const dispatch = useDispatch();
  const profileBox = useProfileBox();
  const portal = GetEnv('REACT_APP_PORTAL');



  const queryParams = new URLSearchParams(location.search);
  const sync = queryParams.get('sync');
  const [shortcutsHasBeenFetched, setShortcutsHasBeenFetched] = useState(false);

  useGoogleAnalytics();

  useTitle(getPlugins().flatMap((plugin) => plugin.routes));

  useEffect(() => {
    if (!shortcutsHasBeenFetched && companySelected && hasLicense && !loadCompanies && locale) {
      dispatch(getAllShortcutsForUser());
      setShortcutsHasBeenFetched(true); // Mark the effect as having run
    }
  }, [companySelected, hasLicense, loadCompanies, locale, shortcutsHasBeenFetched]);

  useEffect(() => {
    const hasCompanySkoghall = companies.some((company) => company.displayName === 'Stora Enso Skoghall AB');
    const usernameStartWithZert = myuser?.userName.toLowerCase().startsWith('zert');
    const isInGroupExternalUsers = myuser?.groups.some(group => group.displayName === 'Externa användare');
    const userIsAdmin = myuser?.role === 'administrator';
    if (hasCompanySkoghall && (usernameStartWithZert === false && isInGroupExternalUsers === false && userIsAdmin === false)) {
      redirectToOldRm();
    }
  }, [companies, myuser]);

  const redirectToOldRm = () => {
    window.location.href = 'https://old.zertrm.se/';
  };

  useEffect(() => {
    if (!sync) {
      return;
    }
    const savedState = localStorage.getItem(sync);
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      dispatch(restoreState(parsedState));
      localStorage.removeItem(sync);
    }
    history.replace(location.pathname);
  }, [sync]);



  useEffect(() => {
    if (myuser == null && !loadingLicense) {
      setLoadingLicense(true);
      setCompanySelected(false);
      handleQuery('/common/getMyOtherCompanyList')
        .then((result) => {
          setCompanies(result);
          setCompanySelected(result.length <= 1);

          return handleText('/common/hasLicenseAgreement');
        })
        .then((result) => {
          setLicense(result);
          return handleText('/common/getProduct', true);
        })
        .then((result) => {
          window.ISCLM = result && result.includes('CLM');

          return handleText('/common/loadMyLocale');
        })
        .then((result) => {
          dispatch(loadMyLocale());
          setLocale(result);
          setLoadingLicense(false);
          setLoadCompanies(false);
          if (localStorage.getItem(INSTANCE_TOKEN)) {
            registerToken(localStorage.getItem(INSTANCE_TOKEN));
          }
        })
        .catch((error) => {
          setLocale('en');
          window.ISCLM = false;
          setLoadingLicense(false);
          setLoadCompanies(false);
        });
    }

    //  this.loadFromServer(this.state.pageSize);
  }, [mylocale]);

  const handleClick = (companyId) => {
    handlePost('/auth/setMyCompany', { companyId, token: localStorage.getItem('accessToken') }).then((result) => {
      setCompanySelected(true);
      setLoadCompanies(false);
    });
  };

  const companyToChoose = companies && companies.length > 0 && !companySelected;
  const newRoutes = useNewRoutes();




  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={PortalTheme(portal)}>
        <ViewportProvider>
          <Switch>
            <Route
              exact
              path="/login"
              key="login"
              render={(props) => {
                return (
                  <GuestGuard>
                    <LoginView {...props} />
                  </GuestGuard>
                );
              }}
            />
            <Route
              render={(props) => {
                return (
                  <AuthGuard>
                    <ErrorBoundary>
                      <IntroductionProvider>
                        <div className="App">
                          {companyToChoose && companies.length > 1 && (
                            <div className="companyList">
                              <FormattedMessage id="select.company" defaultMessage="Continue with selected company" />
                              <Divider />
                              <List component="nav" aria-label="secondary mailbox folders">
                                {companies.map((company) => {
                                  return (
                                    <ListItem
                                      button
                                      key={company.elementId}
                                      onClick={(e) => handleClick(company.elementId)}
                                    >
                                      <ListItemText primary={company.displayName} />
                                    </ListItem>
                                  );
                                })}
                              </List>
                            </div>
                          )}

                          {!companyToChoose && hasLicense && !loadCompanies && hasLicense && locale && (
                            <SideBar
                              showingHeadBar={headBar}
                              headBar={myLanguage}
                              mainList={[...getPluginsOverview(permits), ...getCatalogs(catalogList)]}
                              numberMainList={task_count}
                              numberBottomList={mynews && mynews.right > 0 ? mynews.right : 0}
                              additionalList={profileBox}
                              createNewList={newRoutes}
                              sidebarShortcuts={shortcuts ? shortcuts.filter(shortcut => shortcut.displayLocations && shortcut.displayLocations.includes('sidebar') ) : []}
                            />
                          )}
                          {!companyToChoose && hasLicense == false && !loadCompanies && <LicensePage />}
                          {(loadCompanies || loadingLicense || !locale) && !companyToChoose && <ZertLoaderCenter />}
                          {!companyToChoose && hasLicense && !loadCompanies && locale && (
                            <div className="vertical-group">
                              <Helmet htmlAttributes={{ lang: locale }} />
                              <HeadBar  />
                              <ContentCage />
                            </div>
                          )}
                        </div>
                        <div id="modal-root" />
                      </IntroductionProvider>
                    </ErrorBoundary>
                  </AuthGuard>
                );
              }}
            />
          </Switch>
        </ViewportProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
