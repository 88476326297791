import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { Fragment, memo, useEffect, useState } from 'react';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import MediaQuery from 'react-responsive';
import useDimensions from '@zert-packages/utils/useDimensions';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Image, List, TableChartOutlined } from '@mui/icons-material';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import Grid from '@mui/material/Grid';
import { setListView as setListViewAction } from '@zert-packages/plugins/ExplorerPlugin/explorerReducers';
import ElementTile from './ElementTile';
import ElementTileTable, { columns } from './ElementTileTable';
import { useElementSorter } from './utils/useElementSorter';
import { stableSort } from './utils/stableSort';
import Sorting from '../common/Sorting/Sorting.new';
import EnhancedTableToolbar from '../EnhancedTable/EnhancedTableToolbar';
import useElementActions from './useElementActions';
import useTableFilter from '../shared/SearchableTable/useTableFilter';
import { getTileElementStatusMessage } from './utils/getTileStatus';
import { TASK_ACTIONS_TYPES } from './shared/ELEMENT_TILE_CONTS';
import AbstractCardTile from './shared/AbstractCardTile';

const TileListStyles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '50%',
      display: 'flex',
      overflow: 'auto',
      flexFlow: 'column nowrap',
      padding: '5px 10px'
    },
    rootG: {
      width: '100%',
      height: '50%',
      overflow: 'auto',
      padding: '5px 10px',
      [theme.breakpoints.down('lg')]: {
        padding: '2px 5px'
      }
    },
    rootTab: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '48px 1fr',
      gridGap: 6,
      paddingTop: 2,
      overflow: 'hidden',
      height: '100%',
      width: '100%'
    },

    contentContainer: {
      padding: '10px 15px',
      width: '100%',
      height: '200px',
      overflowY: 'auto',
      overflowX: 'hidden'
    }
  });

export const useStyles = makeStyles(TileListStyles);

function ElementList({
  explorer_infos = [],
  setActiveElement,
  hideToolBar,
  loading,
  columnsNew,
  showCut,
  showFileUpload,
  showCreateLink,
  reloadElement,
  bradCrumbComponent,
  intl,
  fullPage = true,
  orderBy = 'nameNew',
  order = 'asc',
  maxHeight
}) {
  const [ref, { x, y, width, height }] = useDimensions({ liveMeasure: true });
  const listView = useSelector((state) => state.CORE.listView);
  const dispatch = useDispatch();
  const setListView = (value) => {
    dispatch(setListViewAction(value));
  };

  const TABLE_VIEW = {
    icon: <TableChartOutlined />,
    id: TASK_ACTIONS_TYPES.tableView,
    name: <FormattedMessage id="ElementTile.Actions.TableView" defaultMessage="View as Table" />,
    performAction: () => {
      setListView(1);
    }
  };

  const TILE_VIEW = {
    icon: <List />,
    id: TASK_ACTIONS_TYPES.listView,
    name: <FormattedMessage id="ElementTile.Actions.List" defaultMessage="View as tiles" />,
    performAction: () => {
      setListView(0);
    }
  };

  const ICON_VIEW = {
    icon: <Image />,
    id: TASK_ACTIONS_TYPES.listView,
    name: <FormattedMessage id="ElementTile.Actions.Icons" defaultMessage="View as icons" />,
    performAction: () => {
      setListView(2);
    }
  };

  return (
    <div className={fullPage === true ? 'search-cage' : ''} ref={ref}>
      <MediaQuery query="(min-width: 1024px)">
        {(matches) => {
          if ((!matches && listView == -1) || listView == 0) {
            return (
              <ExplorerInfoList
                explorer_infos={explorer_infos}
                maxHeight={maxHeight}
                loading={loading}
                otherActions={[TABLE_VIEW, ICON_VIEW]}
                orderBy={orderBy}
                order={order}
                columnsNew={columnsNew}
                showCut={showCut}
                showFileUpload={showFileUpload}
                showCreateLink={showCreateLink}
                reloadElement={reloadElement}
                bradCrumbComponent={bradCrumbComponent}
                intl={intl}
                setActiveElement={setActiveElement}
                hideToolBar={hideToolBar}
              />
            );
          }
          if (listView == 2) {
            return (
              <ExplorerIconList
                explorer_infos={explorer_infos}
                maxHeight={maxHeight}
                loading={loading}
                otherActions={[TABLE_VIEW, TILE_VIEW]}
                orderBy={orderBy}
                order={order}
                columnsNew={columnsNew}
                showCut={showCut}
                showFileUpload={showFileUpload}
                showCreateLink={showCreateLink}
                reloadElement={reloadElement}
                bradCrumbComponent={bradCrumbComponent}
                intl={intl}
                setActiveElement={setActiveElement}
                hideToolBar={hideToolBar}
              />
            );
          }
          return (
            <ElementTileTable
              elements={explorer_infos}
              loading={loading}
              maxHeight={maxHeight}
              otherActions={[TILE_VIEW, ICON_VIEW]}
              columnsNew={columnsNew}
              orderBy={orderBy}
              order={order}
              bradCrumbComponent={bradCrumbComponent}
              showCut={showCut}
              showFileUpload={showFileUpload}
              showCreateLink={showCreateLink}
              reloadElement={reloadElement}
              setActiveElement={setActiveElement}
              hideToolBar={hideToolBar}
            />
          );
        }}
      </MediaQuery>
    </div>
  );
}

export default injectIntl(ElementList);

const ExplorerInfoWithMemo = memo(
  ({ explorer_infos = [], selectElement, showCut, showFileUpload, showCreateLink, reloadElement, overviewActions }) =>
    explorer_infos &&
    explorer_infos.map((task, index) => {
      return (
        <ElementTile
          hideActions={false}
          key={index}
          element={task}
          overviewActions={overviewActions}
          reloadElement={reloadElement}
          showCut={showCut}
          showFileUpload={showFileUpload}
          showCreateLink={showCreateLink}
          selectElement={selectElement}
        />
      );
    }),
  selectiveMemoComparison(
    (p) => p.explorer_infos,
    (a) => a.sorting,
    (b) => b.isAscending
  )
);

const CardInfoWithMemo = memo(
  ({ explorer_infos = [], selectElement, showCut, showFileUpload, showCreateLink, reloadElement, overviewActions }) =>
    explorer_infos &&
    explorer_infos.map((task, index) => {
      return (
        <Grid item xs={12} sm={6} md={4} xl={2}>
          <AbstractCardTile
            hideActions={false}
            key={index}
            element={task}
            overviewActions={overviewActions}
            reloadElement={reloadElement}
            showCut={showCut}
            showFileUpload={showFileUpload}
            showCreateLink={showCreateLink}
            selectElement={selectElement}
          />
        </Grid>
      );
    }),
  selectiveMemoComparison(
    (p) => p.explorer_infos,
    (a) => a.sorting,
    (b) => b.isAscending
  )
);

function ExplorerInfoList({
  explorer_infos = [],
  otherActions,
  columnsNew,
  loading,
  showCut,
  showFileUpload,
  showCreateLink,
  hideToolBar,
  search,
  reloadElement,
  bradCrumbComponent,
  intl,
  orderBy: orderby,
  order: orderprop,
  setActiveElement
}) {
  const cl = useStyles();
  const [{ orderBy, order }, setState] = useState({ orderBy: orderby, order: orderprop });
  const { myuser: myUser } = useSelector((state) => state);
  const handleSortingChange = (event) => {
    setState((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const { getSorter } = useElementSorter(intl);

  useEffect(() => {
    if (search) {
      updateFilterValue(search);
    }
  }, [search]);

  function getComparator(order, orderBy) {
    const customComparator = getSorter(orderBy);

    return order === 'desc'
      ? (a, b) => customComparator(a, b, orderBy, order)
      : (a, b) => -customComparator(a, b, orderBy, order);
  }

  const getCustomFilter = (element, name) => {
    switch (name) {
      case 'nameNew':
        return element.name;
      case 'deadline':
        if (element.properties == null || !element.properties['zert:CurrentActivityDeadline']) {
          return null;
        }
        return element.properties['zert:CurrentActivityDeadline'].split(' ')[0];
      case 'mimeTypeNew':
        const plugin = getPluginByMimeType(element.mimeType);
        if (intl && plugin && plugin.trkey) {
          return intl.formatMessage({ id: plugin.trkey, defaultMessage: plugin.defaultMessage });
        }
        return element.mimeType;
      case 'status':
        return getTileElementStatusMessage(element, myUser, intl);
      default:
        return null;
    }
  };

  const [selected, setSelected] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const { filterValue, filteredRows, updateFilterValue } = useTableFilter(
    explorer_infos || [],
    columnsNew ? columns : columnsNew,
    getCustomFilter
  );
  const [isShowingFilter, setIsShowingFilter] = useState(false);

  if (loading) {
    return <Loader />;
  }

  const sorted = stableSort(filteredRows, getComparator(order, orderBy));

  return (
    <>
      <EnhancedTableToolbar
        selected={selectedRows}
        actions={useElementActions}
        filterValue={filterValue}
        updateFilterValue={updateFilterValue}
        isShowingFilter={isShowingFilter}
        setIsShowingFilter={setIsShowingFilter}
        showElementActions
        overviewActions
        sorting={<Sorting handleSortingChange={handleSortingChange} orderBy={orderBy} order={order} />}
        reloadElement={reloadElement}
        bradCrumbComponent={bradCrumbComponent}
        showCut={showCut}
        showFileUpload={showFileUpload}
        showCreateLink={showCreateLink}
        otherActions={otherActions}
        setActiveElement={setActiveElement}
        hideToolBar={hideToolBar}
      />

      <div
        className={cl.root}
        style={{ height: isShowingFilter && filterValue ? 'calc(100% - 136px)' : 'calc(100% - 84px)' }}
      >
        {explorer_infos && (
          <ExplorerInfoWithMemo
            showCut={showCut}
            showFileUpload={showFileUpload}
            showCreateLink={showCreateLink}
            explorer_infos={sorted}
            reloadElement={reloadElement}
            overviewActions
            sorting={orderBy}
            isAscending={order === 'asc'}
          />
        )}
      </div>
    </>
  );
}

function ExplorerIconList({
  explorer_infos = [],
  otherActions,
  columnsNew,
  loading,
  showCut,
  showFileUpload,
  showCreateLink,
  hideToolBar,
  reloadElement,
  bradCrumbComponent,
  intl,
  setActiveElement,
  orderBy: orderby,
  order: orderprop
}) {
  const cl = useStyles();
  const [{ orderBy, order }, setState] = useState({ orderBy: orderby, order: orderprop });
  const { myuser: myUser } = useSelector((state) => state);
  const handleSortingChange = (event) => {
    setState((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const { getSorter } = useElementSorter(intl);

  function getComparator(order, orderBy) {
    const customComparator = getSorter(orderBy);

    return order === 'desc'
      ? (a, b) => customComparator(a, b, orderBy, order)
      : (a, b) => -customComparator(a, b, orderBy, order);
  }

  const getCustomFilter = (element, name) => {
    switch (name) {
      case 'nameNew':
        return element.name;
      case 'deadline':
        if (element.properties == null) return null;
        return element.properties['zert:CurrentActivityDeadline'];
      case 'mimeTypeNew':
        const plugin = getPluginByMimeType(element.mimeType);
        if (intl && plugin && plugin.trkey) {
          return intl.formatMessage({ id: plugin.trkey, defaultMessage: plugin.defaultMessage });
        }
        return element.mimeType;
      case 'status':
        return getTileElementStatusMessage(element, myUser, intl);
      default:
        return null;
    }
  };

  const [selected, setSelected] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const { filterValue, filteredRows, updateFilterValue } = useTableFilter(
    explorer_infos || [],
    columnsNew ? columns : columnsNew,
    getCustomFilter
  );
  const [isShowingFilter, setIsShowingFilter] = useState(false);

  if (loading) {
    return <Loader />;
  }

  const sorted = stableSort(filteredRows, getComparator(order, orderBy));

  return (
    <>
      <EnhancedTableToolbar
        selected={selectedRows}
        actions={useElementActions}
        filterValue={filterValue}
        updateFilterValue={updateFilterValue}
        isShowingFilter={isShowingFilter}
        setIsShowingFilter={setIsShowingFilter}
        showElementActions
        overviewActions
        sorting={<Sorting handleSortingChange={handleSortingChange} orderBy={orderBy} order={order} />}
        reloadElement={reloadElement}
        bradCrumbComponent={bradCrumbComponent}
        showCut={showCut}
        showFileUpload={{ showFileUpload }}
        showCreateLink={{ showCreateLink }}
        otherActions={otherActions}
        setActiveElement={setActiveElement}
        hideToolBar={hideToolBar}
      />

      <Grid
        container
        spacing={2}
        className={cl.rootG}
        style={{ height: isShowingFilter && filterValue ? 'calc(100% - 136px)' : 'calc(100% - 84px)' }}
      >
        {explorer_infos && (
          <CardInfoWithMemo
            showCut={showCut}
            showFileUpload={showFileUpload}
            showCreateLink={showCreateLink}
            explorer_infos={sorted}
            reloadElement={reloadElement}
            overviewActions
            sorting={orderBy}
            isAscending={order === 'asc'}
          />
        )}
      </Grid>
    </>
  );
}
