import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import BreadCrumbs from "./BreadCrumbs";
import Filter from "./Filter";
import ExplorerView from "@zert-packages/plugins/ExplorerPlugin/ExplorerView";
import {Dialog} from "@mui/material";


const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 700,
  },
  wrapperFilter: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    marginTop: 10,
  },
  border: {
    height: 1,
    background: '#8B8B8B',
    margin: '5px 0px',
  }
}));

const dataBreadCrumbs = [{name: 'Test'}, {name: 'Test 2'}]


const BrowseTab = ({browse, setBrowse, setActiveElement}) => {
  const cl = useStyles();
  const hideToolBar = true;
  return(
      <div style={{overflowY: "auto", minHeight: "548px"}}>
        <BreadCrumbs items={dataBreadCrumbs}  setBrowse={setBrowse}/>
        <div className={cl.wrapperFilter}>
          <div className={cl.label}>Elements</div>
          <Filter browse={browse} setBrowse={setBrowse}/>
        </div>
        <div className={cl.border}>
          <div>
            <ExplorerView filter={browse.selectedFilter} setActiveElement={setActiveElement}
                          hideToolBar={hideToolBar} hideGlob={true}/>
          </div>
        </div>
      </div>
  )
}

export default BrowseTab