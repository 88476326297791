import React from 'react';

import { useRouteMatch } from 'react-router-dom';
import useRiskAssessmentConfig from './useRiskAssessmentConfig';
import useChecklistConfig from './useChecklistConfig';
import useProjectConfig from './useProjectConfig';
import useSafetyInspectionConfig from './useSafetyInspectionConfig';

const API_ROOT_WAR = process.env.REACT_APP_API_ROOT_WAR;
const riskAnal = {
  openpath: `${API_ROOT_WAR}/risk-assesment/:versionId`
};

const projectPlugin = {
  openpath: `${API_ROOT_WAR}/project/:versionId`
};

const safetyInspectionPlugin = {
  openpath: `${API_ROOT_WAR}/safetyinspection/:versionId`
};
function useElementSearchConfig() {
  const riskAssessment = useRiskAssessmentConfig();
  const checklist = useChecklistConfig();
  const project = useProjectConfig();
  const safetyInspection = useSafetyInspectionConfig();
  const showRiskAssessment = useRouteMatch(riskAnal.openpath);
  const showChecklist = useRouteMatch('/checklist/:versionId/:categoryId/:questionId');
  const showProject = useRouteMatch(projectPlugin.openpath);
  const showSafetyInspection = useRouteMatch(safetyInspectionPlugin.openpath);
  if (showRiskAssessment) return riskAssessment;
  if (showChecklist) return checklist;
  if (showProject) return project;
  if (showSafetyInspection) return safetyInspection;
}

export default useElementSearchConfig;
