import { getReferences, getTermAPI } from './API';
import { loadTermDb, loadTermReferences, turnOffCLMLoader, turnOnCLMLoader } from '../TranslationJobPlugin/tjReducers';

export const fetchTerms = (versionId, callback) => (dispatch) => {
  dispatch(turnOnCLMLoader());
  getTermAPI(versionId)
    .then((res) => {
      dispatch(loadTermDb(res));
    })
    .then(() => {
      dispatch(turnOffCLMLoader());
    });
};

export const fetchTermReferences = () => (dispatch) => {
  dispatch(turnOnCLMLoader());
  getReferences()
    .then((res) => {
      dispatch(loadTermReferences(res));
    })
    .then(() => {
      dispatch(turnOffCLMLoader());
    });
};

export const addTerm = () => async (dispatch) => {
  dispatch(turnOnCLMLoader());
  addTerm()
    .then((res) => {
      dispatch(loadTermReferences(res));
    })
    .then(() => {});
};
