import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { FormattedMessage } from 'react-intl';
import Chip from '@mui/material/Chip';
import { connect } from 'react-redux';
import withSearch, { styles, controlStyles } from '../withSearch';

import SearchComponent from './SearchComponent';
import { getLocaleByLanguageCode, reduceToExitings, toLocaleString } from './helpers/getLocaleByLanguageCode';

function getStyles(name, that) {
  return {
    fontWeight:
      !that.state.Locales || that.state.Locales.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
 /* getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }*/
};

class LocaleSearch extends SearchComponent {
  constructor(props) {
    super(props);
    /*   if (!this.state || !this.state.MimeType) {
             this.state = { MimeType: [] };
           } */
    this.handleChange = this.handleChange.bind(this);
    this.textInput = React.createRef();
  }

  handleChange = (event) => {
    this.setStatus({ Locale: event.target.value });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    /*  if (this.textInput &&  this.textInput.current) {
            (this.props.expanded && this.textInput && this.textInput.current) ? this.textInput.current.blur() : this.textInput.current.focus();
        } */
  }

  render() {
    //   this.props.handleChange(this.state);
    const { classes } = this.props;

    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        {this.props.locales && (
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="select-multiple-chip">
              <FormattedMessage id="search.locales" defaultMessage="Locales" />
            </InputLabel>
            <Select
              autoFocus
              inputRef={this.textInput}
              multiple
              size="small"
              value={this.state.Locale ? reduceToExitings(this.state.Locale, this.props.locales) : []}
              onChange={this.handleChange}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={getLocaleByLanguageCode(value, this.props.locales).displayName}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {this.props.locales &&
                this.props.locales.map((locale) => (
                  <MenuItem
                    key={toLocaleString(locale)}
                    value={toLocaleString(locale)}
                    style={getStyles(toLocaleString(locale), this)}
                  >
                    {locale.displayName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </form>
    );
  }
}

LocaleSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.localeSearch.header" defaultMessage="Search by locale" />,
  index: 'element-language',
  className: 'se.zert.backend.search.LanguageCriteria',
  default: {
    Locale: []
  }
};

const mapStateToProps = (state) => ({ locales: state.activeLocales });

export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles, { withTheme: true })(LocaleSearch), selectData))
);
