export const reportMockUp = {
  catalogVersionId: -1,
  nodeUUID: 'none',
  includeSubNodes: false,
  versionId: -1,
  reportNumber: -1,
  scope: '',
  riskanalisesscope: '',
  isPrintReport: false,
  pageBreakBeforeNodes: false,
  chartTypes: -1,
  includeArchived: false,
  includeStatistics: false,
  selectedNodeUUID: '',
  includeMatrix: false,
  showImagesSeparatly: false,
  locale: 'sv_SE',
  object: ''
};
