import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  variablesRoot: {
    flexGrow: 1
  }
}));

function VariablesPane({}) {
  const cl = useStyles();

  return <div className={`${cl.variablesRoot}`}>NOT IMPLEMENTED</div>;
}
export default VariablesPane;
