import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
    // height: 345,
    height: "fit-content",
    padding: 11,
    background: "#E2F2F6"
  },

  wrapperTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15,
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.188rem",
    fontWeight: 700
  },
  wrapperButton: {
    display: "flex",
    justifyContent: "end",
    paddingRight: 20,
    marginTop: 15
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  },
  wrapperValue: {
    display: "grid",
    gridTemplateColumns: "1fr 25px",
    marginBottom: 10
  },
  listWrapper: {
    border: "1px solid #BDBDBC",
    margin: "0px 10px",
    borderRadius: 5,
    overflow: "auto",
    minHeight: 200,
    maxHeight: 200
  },
  wrapperEditor: {
    color: "#4db1d3",
    "& svg": {
      cursor: "pointer"
    }
  }
}))

const DialogHeightTable = ({formats}) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const saveItem = () => {
    handleClose();
  };

  const handleOpenDialog = (type) => {

  }

  const deleteFormat = () => {

  }


  return(<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>Edit values</span>
      <CloseIcon onClick={handleClose} />
    </div>

    <div className={cl.wrapperValue}>
      <div className={cl.listWrapper}>
          {formats && formats.map((el, i) => (
            <div
              key={i}
              // style={{
              //   color: el.deprecated ? "#B1B0AF" : selectedSettings === i ? "#ffffff" : "#212529"
              // }}
              // onClick={() => handleSelectedTab(i)}
              // className={selectedSettings === i ? cl.wrapperTitleSelected : cl.wrapperTitle}
            >
              {el.identifier && el.identifier}
            </div>
          ))}

      </div>
      <div className={cl.wrapperEditor}>
        <AddIcon  onClick={() => handleOpenDialog("Add")} />
        <CreateIcon  onClick={() =>  handleOpenDialog("edit")} />
        <DeleteIcon  onClick={deleteFormat} />
      </div>
    </div>

    <div className={cl.wrapperButton}>
      <Button  className={cl.button} onClick={() => saveItem()}>
        OK
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>

  </Dialog>)
}

export default  DialogHeightTable