import React, {useContext} from 'react';
import {Button} from "@mui/material";
import {OPEN_IMAGE, REMOVE_IMAGE_ACTIONS} from "../../utils/ELEMENT_TILE_CONTS";
import {SparePartContext} from "../../store/SparePartContext";

const ImageActions = () => {
    const {values, setValues} = useContext(SparePartContext)

    const removeImageFromPart = () => {
        setValues({
            ...values,
            partImages: values.partImages.filter(partImage => partImage.identifier !== values.selectedElement.identifier),
            selectedElement: null,
            isDataChanged: true
        })
    }

    return (
        <>
            <Button key={OPEN_IMAGE.id} startIcon={OPEN_IMAGE.icon} onClick={() => {
                setValues({
                    ...values,
                    currentViewedElement: values.selectedElement,
                    currentElementVersionId: values.selectedElement.elementInfo.versionId,
                    selectedElementLanguage: values.selectedElement.elementInfo.locale,
                    elementType: values.selectedElement?.elementInfo?.mimeType,
                    viewedElements: [...values.viewedElements, values.selectedElement]
                })
            }}>
                {OPEN_IMAGE.name}
            </Button>
            <Button key={REMOVE_IMAGE_ACTIONS.id} startIcon={REMOVE_IMAGE_ACTIONS.icon} onClick={removeImageFromPart}>
                {REMOVE_IMAGE_ACTIONS.name}
            </Button>
        </>
    );
};

export default ImageActions;