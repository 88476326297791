import {fetchRA_Error, handlePost, handlePostFile, handleQuery} from '@zert-packages/actions/api';
import {getMultipleValueOneCatalog, getMultipleValueOneCatalogSelected} from "@zert-packages/utils/getMultipleValue";
import {fetchRABasisUpload_Begin} from "@zert-packages/actions/riskassesmentActions";
import {findElements, placeElementNew} from "@zert-packages/actions/catalogActions";

export const initDOCAPI = (state) =>{
    return handlePost('/document' + `/init/`, state)
};

export const storeDOCAPI = (id, state) => handlePost('/document' + `/store/${id}`, state);

export const loadDOCPI = (id) => handleQuery('/document' + `/load/${id}`);


export function uploadFile(filedata, catalog, selectedNode) {
    return function (dispatch) {
        handlePostFile('/document/uploadDocument', filedata)
            .then((res) => {
                return placeElementNew(res.versionId, getMultipleValueOneCatalogSelected(catalog, selectedNode));
            })
            .then((res) => {
                dispatch(findElements(selectedNode.split("^")[1], catalog.info.id));
            })
            .catch((error) => dispatch(fetchRA_Error(error)));
        return dispatch(fetchRABasisUpload_Begin(catalog.info.id));
    };
}