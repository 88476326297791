import React from 'react';
import { FormattedMessage } from 'react-intl';

export const steps = [
  {
    id: 'risk-identification',
    trkey: 'environment.step.identification',
    defaultMessage: 'Risk assesment',
    message: <FormattedMessage id="environment.step.identification" defaultMessage="Risk assesment" />,
    description: (
      <FormattedMessage
        id="environment.step.identification.description"
        defaultMessage="Start by determining the boundaries of the risk assessment and which different cases will be risk assessed in the description field. Then identify all risks, create separate risk rows for each risk. Analyze the risks and set risk values on each risk row. Once all the risks are identified and valued, you can proceed to the next step to manage your risks. "
      />
    )
  },
  {
    id: 'measures',
    trkey: 'environment.step.measures',
    defaultMessage: 'Risk treatment',
    message: <FormattedMessage id="environment.step.measures" defaultMessage="Risk treatment" />,
    description: (
      <FormattedMessage
        id="environment.step.measures.description"
        defaultMessage="In this step you can add one or more measure to the risks. Evaluate if the measure is sufficient to lower the risk value to an acceptable level. Once all the necessary measures have been added you can proceed to the next step, then the respective measure is sent out to those responsible. "
      />
    )
  },
  {
    id: 'follow-up',
    trkey: 'environment.step.followup',
    defaultMessage: 'Follow-up',
    message: <FormattedMessage id="environment.step.followup" defaultMessage="Follow-up" />,
    description: (
      <FormattedMessage
        id="environment.step.followup.description"
        defaultMessage="In this step you can work with the established measures. To add more risks or edit the risks go back to the previous step. When all measures are completed you can finalize the risk assessment. After the risk assessment is finalized you can open it again to continue the work. "
      />
    )
  },
  {
    id: 'close',
    trkey: 'environment.step.close',
    defaultMessage: 'Finalized',
    message: <FormattedMessage id="environment.step.close" defaultMessage="Finalized" />,
    description: (
      <FormattedMessage
        id="environment.step.close.description"
        defaultMessage="This risk assessment is finalized, all measures are completed and an approved version of the risk assessment have been created. If you want to revise the risk assessment you can choose to open it again, a new version of the risk assessment will be created and the risk management can continue. "
      />
    )
  }
];
