export const adobeLanguage = (language) => {
    if (!language || language.length === 0) {
        return {
            defaultLabel: "",
            locales: ["en", "sv"],
            translations: ["", ""]
        }
    }

    return language.reduce((acu, cur) => {
        return {
            ...acu,
            default: language,
            locales: [...acu.locales, cur.locale.language],
            translations: [...acu.translations, cur.name]
        }
    }, {
        defaultLabel: "",
        locales: [],
        translations: []
    })
}