import smartScrollIntoViewCenter from '@zert-packages/utils/smartScrollIntoViewCenter';

export function onElementHighlight(node, disableScroll) {
  if (!node) return;
  node.style.backgroundColor = 'rgba(200,255,19,0.5)';

  if (disableScroll) return;

  smartScrollIntoViewCenter(node);
}

export function offElementHighlight(node) {
  if (!node) return;
  node.style.backgroundColor = '';
}
