import React, { memo } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getSteps } from '../../Environment';
import ExpansionWrapperCriteria from '../common/ExpansionWrapperCriteria';

const rootStyles = {
  display: 'grid',
  gridGap: '10px',
  gridTemplateColumns: '1fr 1fr',
  width: '100%'
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  numberOfPeriodContainer: {
    display: 'grid',
    margin: '10px'
  }
});

const useStyles = makeStyles(styles);

const RiskAssesmentSearchCriteria = memo(
  ({ foundCriteria, updateCriteria, onChangeExpansionOfCriteria, classNameOfCriteria, isEverythingDisabled, intl }) => {
    const onChangeState = (field, value) => {
      const newValues = foundCriteria.values.map((item) => {
        if (item.key === field) {
          return {
            ...item,
            value
          };
        }
        return item;
      });
      updateCriteria(classNameOfCriteria, newValues);
    };

    const findValue = (key) => {
      const value = foundCriteria.values.find((item) => item.key === key);
      return value ? value.value : null;
    };
    const classes = useStyles();

    const handleOnChangeExpansion = (event, isExpanded) => {
      onChangeExpansionOfCriteria(isExpanded, classNameOfCriteria);
    };
    return (
      <ExpansionWrapperCriteria
        title={<FormattedMessage id="riskAssessmentCriteriaCounter.template" defaultMessage="In risk assessments" />}
        handleOnChangeExpansion={handleOnChangeExpansion}
        expanded={!!foundCriteria}
      >
        {!!foundCriteria && (
          <div style={rootStyles}>
            <FormControl variant="standard" className={classes.formControl} fullWidth>
              <InputLabel htmlFor="step.id">
                <FormattedMessage id="search.step.id" defaultMessage="Step id" />{' '}
              </InputLabel>
              <Select
                variant="standard"
                disabled={isEverythingDisabled}
                value={findValue('step')}
                onChange={(e) => {
                  onChangeState('step', e.target.value);
                }}
                inputProps={{
                  name: 'search-step-id',
                  id: 'step.id'
                }}
              >
                {getSteps(intl).map((label, index) => {
                  return (
                    <MenuItem value={label.id}>
                      <em>{label.tr_message}</em>
                    </MenuItem>
                  );
                })}
                <MenuItem value={null}>
                  <em>
                    <FormattedMessage id="step.none" defaultMessage="All" />
                  </em>
                </MenuItem>
              </Select>
            </FormControl>

            <div className={classes.numberOfPeriodContainer}>
              <TextField
                disabled={isEverythingDisabled}
                style={{ width: '100%' }}
                key="more_than_risk"
                id="more_than_risk"
                label={<FormattedMessage id="search.riskrow.morethan" defaultMessage="Has more than risks" />}
                className={classes.spinField}
                value={findValue('countGT')}
                onChange={(event) => onChangeState('countGT', event.target.value)}
                type="number"
                variant="outlined"
              />
            </div>
            <div className={classes.numberOfPeriodContainer}>
              <TextField
                disabled={isEverythingDisabled}
                style={{ width: '100%' }}
                r
                key="less_than_risk"
                id="less_than_risk"
                label={<FormattedMessage id="search.riskrow.lessthan" defaultMessage="Has less than risks" />}
                className={classes.spinField}
                value={findValue('countLESS')}
                onChange={(event) => onChangeState('countLESS', event.target.value)}
                type="number"
                variant="outlined"
              />
            </div>

            <div className={classes.numberOfPeriodContainer}>
              <TextField
                disabled={isEverythingDisabled}
                style={{ width: '100%' }}
                id="more_than_measures"
                key="more_than_measures"
                label={<FormattedMessage id="search.measures.morethan" defaultMessage="Has more than measures" />}
                className={classes.spinField}
                value={findValue('countGTM')}
                onChange={(event) => onChangeState('countGTM', event.target.value)}
                type="number"
                variant="outlined"
              />
            </div>
            <div className={classes.numberOfPeriodContainer}>
              <TextField
                disabled={isEverythingDisabled}
                style={{ width: '100%' }}
                id="less_than_measures"
                key="less_than_measures"
                label={<FormattedMessage id="search.measures.lessthan" defaultMessage="Has less than measures" />}
                className={classes.spinField}
                value={findValue('countLESSM')}
                onChange={(event) => onChangeState('countLESSM', event.target.value)}
                type="number"
                variant="outlined"
              />
            </div>
          </div>
        )}
      </ExpansionWrapperCriteria>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps.foundCriteria, nextProps.foundCriteria)
);

export default injectIntl(RiskAssesmentSearchCriteria);
