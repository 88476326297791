import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import makeStyles from "@mui/styles/makeStyles";


const useStyles = makeStyles((theme) => ({
  wrapperList: {
    display: "grid",
    gridTemplateColumns: "1fr 25px",
    gridColumnGap: 5,
    height: "100%"
  },
  wrapperEditor: {
    color: "#4db1d3",
    "& svg": {
      cursor: "pointer"
    }
  },
  listWrapper: {
    border: '1px solid #C6C6C5',
    background: '#ffffff',
    borderRadius: 10,
    overflow: 'hidden',
    height: 'Calc(100% - 34px)'
  },
  listItem: {
    padding: '2px 4px',
    cursor: 'pointer'
  },
  listItemActive: {
    padding: '2px 4px',
    cursor: 'pointer',
    background: '#4db1d3',
    color: '#ffffff'
  },
  arrowImg: {
    position: 'relative',
    top: 5
  }
}))

const ListSelected = ({list=[]}) => {
  const cl = useStyles();
  const [selected, setSelected] = useState(-1);

  const addList = () => {

  }

  const editList = () => {

  }

  const deleteList = () => {

  }

  const handleSelected = (i) => {
    setSelected(i);
  };


  return(
    <div className={cl.wrapperList}>
      <div className={cl.listWrapper}>
        {list.length > 0 && list.map((el, i) => (
          <div key={i} className={selected === i ? cl.listItemActive : cl.listItem} onClick={() => handleSelected(i)}>
            {el.name}
          </div>
        ))}
      </div>
      <div className={cl.wrapperEditor}>
        <AddIcon onClick={addList} />
        <EditIcon onClick={editList} />
        <DeleteIcon onClick={deleteList}/>
      </div>
    </div>
  )
}

export default ListSelected