import getTranslation from './getTranslation.old';
import createParentsListNode from './createParentsListNode';

export const getStringPathOfNodeOneCatalog = (catalog, nodeUUID) => {
  const pathNodesList = createParentsListNode(catalog.rootNode, nodeUUID);

  if (pathNodesList.length > 0) {
    return `${getTranslation(catalog.rootNode.label)}${pathNodesList.reduce((accum, node) => {
      return `${accum} > ${getTranslation(node.label)}`;
    }, '')}`;
  }
  return null;
};

const getStringPathOfNode = (catalogs, nodeUUID) => {
  if (!catalogs) return null;

  const availableCatalogs = catalogs.filter((v) => v !== null && v !== '');

  for (const catalog of availableCatalogs) {
    const pathNodesList = createParentsListNode(catalog.rootNode, nodeUUID);

    if (pathNodesList.length > 0) {
      return `${getTranslation(catalog.rootNode.label)}${pathNodesList.reduce((accum, node) => {
        return `${accum} > ${getTranslation(node.label)}`;
      }, '')}`;
    }
  }
  return null;
};

export default getStringPathOfNode;
