import React, { useState, Fragment, useMemo, useEffect, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { findMetaDefinitionByIdentifier } from '@zert-packages/plugins/ExplorerPlugin/SearchConditions/MetadataEditor/helpers/findMetaDefinitionByIdentifier';
import { findMetaCaptionByIdentifier } from '@zert-packages/plugins/ExplorerPlugin/SearchConditions/MetadataEditor/helpers/findMetaCaptionByIdentifier';
import { getLabelByDefinition } from '@zert-packages/plugins/ExplorerPlugin/SearchConditions/MetadataEditor/helpers/getLabelByDefinition';
import ActionableTable from '@zert-packages/components/shared/ActionableTable/ActionTable.new';
import ConfirmationDialog from '@zert-packages/components/common/dialogs/ConfirmationDialog';
import MetadataEditorDialog from './MetadataEditorDialog';

const tableColumns = [
  {
    identifier: 'identifier',
    label: <FormattedMessage id="MetadataTable.AlternativesTable.Name" defaultMessage="Name" />
  },
  {
    identifier: 'comment',
    label: <FormattedMessage id="MetadataTable.AlternativesTable.CommentCaption" defaultMessage="Comment caption" />
  },
  {
    identifier: 'value',
    label: <FormattedMessage id="MetadataTable.AlternativesTable.Value" defaultMessage="Value" />
  }
];

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '10px',
    height: '100%',
    width: '100%'
  },
  dependsContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'auto',
    '& > *': {
      marginTop: 10
    }
  }
});

const defaultSelectedMeta = null;

function MetadataCommentTable({ list, setList }) {
  const cl = useStyles();
  const rootRef = useRef();
  const metadataTemplates = useSelector((state) => state.metadataTemplates);

  const [selectedMeta, setSelectedMeta] = useState(defaultSelectedMeta);

  const updateMeta = (valueCallback) =>
    setList((prev, stateAction) => {
      prev = [...(typeof valueCallback === 'function' ? valueCallback(prev) : valueCallback)];
      if (stateAction) {
        stateAction(prev);
      }
      return prev;
    });

  const addMetadataValue = (filter = null) =>
    renderInExceptionRoot(MetadataEditorDialog, {
      onConfirm: (newMeta) => {
        updateMeta((p) => [
          ...p,
          {
            ...newMeta
          }
        ]);
        setSelectedMeta(selectedMeta + 1);
      }
    });

  const editMetadataValue = () =>
    renderInExceptionRoot(MetadataEditorDialog, {
      metadata: list[selectedMeta],
      onConfirm: (newMeta) => {
        updateMeta((p) => p.map((element, index) => (index == selectedMeta ? newMeta : element)));
      }
    });

  const removeMetadataValue = () =>
    renderInExceptionRoot(ConfirmationDialog, {
      onConfirm: () => {
        updateMeta((p) => p.filter((element, index) => index != selectedMeta));
      }
    });

  const actions = [
    {
      icon: AddIcon,
      onClick: addMetadataValue,
      disabled: false
    },
    {
      icon: EditIcon,
      onClick: editMetadataValue,
      disabled: false
    },
    {
      icon: DeleteIcon,
      onClick: removeMetadataValue,
      disabled: false
    }
  ];

  const getRows = () => {
    if (!list || !metadataTemplates) {
      return [];
    }
    return (
      list &&
      list.map((meta, index) => {
        const definition = findMetaDefinitionByIdentifier(meta.identifier, metadataTemplates);
        return {
          id: index,
          identifier: definition && findMetaCaptionByIdentifier(definition.names),
          value: getLabelByDefinition(meta.value, definition),
          comment: meta.comment
        };
      })
    );
  };

  const getMetadataById = (identifier) => {
    return list && list.find((meta) => identifier == meta.identifier);
  };

  return (
    <div className={cl.root} ref={rootRef}>
      <ActionableTable
        actions={actions}
        columns={tableColumns}
        rows={getRows()}
        selectedRowId={selectedMeta}
        setSelectedRowId={setSelectedMeta}
      />
    </div>
  );
}

export default MetadataCommentTable;
