import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import withSearch, { styles, controlStyles } from '../withSearch';

import SearchComponent from './SearchComponent';
import MetadataTable from './MetadataEditor/MetadataTable';

function getStyles(name, that) {
  return {
    fontWeight:
      !that.state.MimeType || that.state.MimeType.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
 /* getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }*/
};

class MetadataTemplatesSearch extends SearchComponent {
  constructor(props) {
    super(props);
    this.setList = this.setList.bind(this);
    if (!this.state.Metadata) {
      this.state.Metadata = [];
    }
  }

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  setList = (func) => {
    func(this.state, (state) => {
      this.setStatus(state);
    });
  };

  render() {
    //   this.props.handleChange(this.state);
    const { classes } = this.props;
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        <MetadataTable setList={this.setList} list={this.state.Metadata} />
      </form>
    );
  }
}

MetadataTemplatesSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.metadata.header" defaultMessage="Search by metadata" />,
  index: 'metadata',
  className: 'se.zert.backend.search.MetadataCriteria',
  default: {
    Metadata: []
  }
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles, { withTheme: true })(MetadataTemplatesSearch), selectData))
);
