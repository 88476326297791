import { updateDecorator } from "./decorator";
import { addInsertText } from "./converterPhrase";
import { CompositeDecorator, convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { v4 as uuidv4 } from "uuid";
import { defaultImage } from "../data/defaultPhrase";

export const handleValue = (value, name, set) => {
  set(prev => ({
    ...prev,
    [name]: value
  }));
};


export const findStyleImg = (el, editor) => {
  if(el?.format?.style === 'WARNING'){
    const entityMap = { ...editor.entityMap}
    for (let i = Object.keys(entityMap).length; i > 0; i--) {
      entityMap[i] = entityMap[i - 1];
    }
    entityMap[0] = defaultImage.entityMap
    const blocksAddImg = editor.blocks.reduce((acu, cur) => {
      if(cur.type === "atomic"){
        const res = {...cur,  entityRanges: [{ ...cur.entityRanges[0], key: cur.entityRanges[0].key +1 }]}
        return  [...acu, res]
      }
      return [...acu, cur]
    }, [])
    return {
      blocks: [defaultImage.blocks, ...blocksAddImg],
      entityMap,
    }
  }
  return editor
}

// if
export const adobeJsonEditor = (value, name, setValues, convertFromRaw, editorState, compositeDecorator, vue,) => {
  const rawContent = convertFromRaw(value);
  const state = editorState.createWithContent(rawContent, compositeDecorator);

  if (!vue) {
    return handleValue(state, name, setValues);
  }
  const addFocusEnd = editorState.moveFocusToEnd(state);
  handleValue(addFocusEnd, name, setValues);
  return  addFocusEnd
};

const handleString = (st, val, idx) => {
  return val.slice(0, idx) + st + val.slice(idx);
};

export const handleActiveSelection = (val, editorBlockState, selection) => {
  return editorBlockState.reduce((acu, cur) => {
    if (cur.key === selection.anchorKey) {
      return [...acu, { ...cur, text: handleString(val, cur.text, selection.anchorOffset) }];
    }
    return [...acu, cur];
  }, []);
};


export  const addAliasPhrase = (val, values,setValues, EditorState) => {
  const word = {word: val, aliasDecorator: true}
  const alias =[
    ...values.aliasDecorator,
    ...updateDecorator([word])
  ]

  const editorState = addInsertText(' '+ val, values.editorState, EditorState);
  setValues(pre => ({ ...pre,
    editorState,
    aliasDecorator: alias,
    aliasDecoratorWord: [...pre.aliasDecoratorWord, word]
  }));
}

export const editAliasPhrase = (props, values, text, setValues) => {
  const word = {word: text, aliasDecorator: true}
  const alias =[
    ...values.aliasDecorator,
    ...updateDecorator([word])
  ]
  //.decoratedText
  const currentContent = values.editorState.getCurrentContent();
  const contentStateJSON = convertToRaw(currentContent);
  const key =  props.offsetKey.slice(0,  props.offsetKey.length- 4)
  const uuid = uuidv4();
  const adobeAtomic = contentStateJSON.blocks.reduce((acu, cur, idx) => {
    if(cur.key === key){
      return [...acu,
        {
          ...cur,
          key: cur.key + uuid,
          text: cur.text.replace(new RegExp(props.decoratedText, 'g'), text),
        }
      ];
    }
    return [...acu, { ...cur, key: cur.key + uuid }];
  }, [])

  const rawContent = convertFromRaw({
    entityMap: contentStateJSON.entityMap,
    blocks: adobeAtomic
  });
  const state = EditorState.createWithContent(rawContent);
  const resultState = EditorState.moveFocusToEnd(state)
  setValues(pre => ({ ...pre,
    editorState: resultState,
    aliasDecorator: alias,
    aliasDecoratorWord: [...pre.aliasDecoratorWord, word]
  }));
}

