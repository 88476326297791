import { handlePostResultAsString, handlePostToApi, handleQuery } from "@zert-packages/actions/api";



export const getTreeApi = (versionId) => {
  return handleQuery(`/common/getParents/${versionId}`);
};

export const getApiTree = (versionId, locale) => {
  return handleQuery(`/common/getContentItemsTreeNode/${versionId}/${locale}`);
};

export const getApiGroupsList = (companyId) => {
  return handleQuery(`/admin/getGroupsList/${companyId}`);
};

export const saveFormatTemplate = (versionId, data) => {
  return handlePostToApi(`/formattemplate/store/${versionId}`, data);
}

export const createFormatTemplate = (data) => {
  return handlePostResultAsString(`/formattemplate/init`, data)
}



