import { AppBar, Button, DialogActions, Typography } from '@mui/material';
import getParameterAsInt from '@zert-packages/utils/getParameterAsInt';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoaderModal from '@zert-packages/components/shared/LoaderModal';
import React, { Fragment, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TextField from '@mui/material/TextField';
import { getVersionInfo } from '@zert-packages/actions/coreReducers';
import _ from 'lodash';
import ModalCenter from '@zert-packages/components/common/ModalCenter';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import PrintManager from './PrintManager';
import TranslationJobContentTable from './TranslationJobContentTable';
import isReadOnly from '@zert-packages/utils/isReadOnly';
import ElementTile from '@zert-packages/components/ElementTile/ElementTile';
import WorkflowStageButtons from './WorkflowStageButtons';
import { allowImport, allowUpload } from './helpers/checkTranslationJobStatus';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import { resetTJAction, turnOnCLMLoader } from './tjReducers';
import { fetchTranslationJob, saveTJ } from './servicesMiddlewares';

export const formStyle = (theme) =>
  createStyles({
    mainContainer: {
      width: '100%',
      height: '100%',
      display: 'grid',
      gridGap: '10px',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '48px 1fr',
      overflow: 'hidden'
    },
    rootBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '5px'
    },
    bodyContainer: {
      padding: '10px 10px 0px 10px',
      height: '100%',
      width: '100%',
      overflowX: 'hidden',
      overflowY: 'auto'
    },
    AppBar: {
      display: 'grid',
      gridTemplateColumns: '1fr auto auto',
      zIndex: '10',
      overflow: 'hidden',
      paddingRight: 8
    },
    formControl: {
      margin: theme.spacing(1),
      width: '100%'
    }
  });
const useStyles = makeStyles(formStyle);

function TranslationJobView(props) {
  const cl = useStyles();
  const CLM = useSelector((state) => state.CLM);
  const isLoading = useSelector((state) => state.CLM.isLoading);
  const { versionInfo, myuser } = useSelector((state) => state);
  const {
    match: { params }
  } = props;
  const dispatch = useDispatch();
  const [changed, setChanged] = useState(false);

  const [translationJobTemplate, setTranslationJobTemplate] = useState();
  const [translationJob, setTranslationJob] = useState({
    name: '',
    sequenceNumber: '',
    description: '',
    customFieldsProperties: []
  });

  const handleChange = (name) => (event) => {
    setChanged(true);
    setTranslationJob({ ...translationJob, [name]: event.target.value });
  };

  const handleChangeName = (name) => (event) => {
    setChanged(true);
    const name = event.target.value;
    setTranslationJob({ ...translationJob, name });
  };

  const handleCustomFieldChange = (field) => (event) => {
    setChanged(true);
    const value = translationJob.customFieldsProperties.find((value) => value.left == `custom-field-${field}`);

    if (value) {
      value.right = event.target.value;
    } else {
      translationJob.customFieldsProperties.push({ left: `custom-field-${field}`, right: event.target.value });
    }

    setTranslationJob({ ...translationJob, customFieldsProperties: [...translationJob.customFieldsProperties] });
  };
  const versionId = getParameterAsInt(params.versionId);

  useEffect(() => {
    dispatch(resetTJAction());
    dispatch(turnOnCLMLoader());

    dispatch(getVersionInfo(versionId));
    dispatch(fetchTranslationJob(versionId));
  }, [versionId]);

  useEffect(() => {
    if (CLM.translationJob) {
      setTranslationJobTemplate(CLM.translationJobTemplate);
      setTranslationJob({
        name: CLM.translationJob.name,
        sequenceNumber: CLM.translationJob.sequenceNumber,
        description: CLM.translationJob.description,
        customFieldsProperties: CLM.translationJob.customFieldsProperties.map((tuple) => ({
          left: tuple.left,
          right: tuple.right
        }))
      });
    } else {
      setTranslationJob(null);
      setTranslationJobTemplate(null);
    }
  }, [CLM.translationJob]);

  if (!translationJob || !versionInfo || _.get(CLM, 'translationJob.info.versionId', -1) !== versionId) {
    return (
      <ModalCenter open>
        <Loader />
      </ModalCenter>
    );
  }

  return (
    <div className={cl.mainContainer}>
      <AppBar position="static" color="default" className={cl.AppBar}>
        <div className={cl.rootBar}>
          <WorkflowStageButtons tj={translationJob} />
        </div>
        <div className={cl.rootBar}>
          {changed && (
            <Button
              onClick={() => dispatch(saveTJ(CLM.translationJob.info.versionId, translationJob))}
              disabled={!changed}
              color="primary"
            >
              <FormattedMessage id="TranslationJobSave.Save" defaultMessage="Save" />
            </Button>
          )}
          <PrintManager />
        </div>
      </AppBar>
      <div className={cl.bodyContainer}>
        <TextField
          id="number"
          key="trJobNumber"
          label={<FormattedMessage id="TranslationJobView.Number" defaultMessage="Number" />}
          className={cl.formControl}
          value={translationJob.sequenceNumber}
          onChange={handleChange('sequenceNumber')}
          type="text"
          fullWidth
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
        />
        <TextField
          id="name"
          key="trJobName"
          label={<FormattedMessage id="TranslationJobView.name" defaultMessage="Name" />}
          InputProps={{
            readOnly: isReadOnly(versionInfo, myuser)
          }}
          className={cl.formControl}
          value={translationJob.name.replace(`${translationJob.sequenceNumber} `, '')}
          onChange={handleChangeName('name')}
          type="text"
          fullWidth
          variant="outlined"
        />
        <TextField
          id="description"
          key="trJobDesc"
          label={<FormattedMessage id="TranslationJobView.description" defaultMessage="Description" />}
          InputProps={{
            readOnly: isReadOnly(versionInfo, myuser)
          }}
          className={cl.formControl}
          value={translationJob.description}
          onChange={handleChange('description')}
          type="text"
          fullWidth
          multiline
          maxRows={8}
          rows={4}
          variant="outlined"
        />
        {CLM.translationJob.elements.map((element, index) => (
          <ElementTile hideActions element={element} />
        ))}
        <TextField
          id="sourceLanguage"
          key="trJobSourceLanaguage"
          label={<FormattedMessage id="TranslationJobView.Source" defaultMessage="Source" />}
          InputProps={{
            readOnly: true
          }}
          className={cl.formControl}
          value={CLM.translationJob.sourceLanguage.displayName}
          type="text"
          fullWidth
          variant="outlined"
        />
        <TextField
          id="sourceLanguage"
          key="trJobSourceElement"
          label={<FormattedMessage id="TranslationJobView.Elements" defaultMessage="Elements" />}
          InputProps={{
            readOnly: true
          }}
          className={cl.formControl}
          value={CLM.translationJob.mimeTypes.map((mimeType) =>
            getPluginByMimeType(mimeType).trkey
              ? props.intl.formatMessage({
                  id: getPluginByMimeType(mimeType).trkey,
                  defaultMessage: getPluginByMimeType(mimeType).defaultMessage
                })
              : getPluginByMimeType(mimeType).defaultMessage
          )}
          type="text"
          fullWidth
          variant="outlined"
        />
        {CLM.tjwft &&
          CLM.tjwft.customFileds.map((field) => {
            const value = translationJob.customFieldsProperties.find((value) => value.left == `custom-field-${field}`);

            return (
              <TextField
                key={field}
                label={field}
                InputProps={{
                  readOnly: true
                }}
                className={cl.formControl}
                onChange={handleCustomFieldChange(field)}
                value={value ? value.right : ''}
                type="text"
                fullWidth
                variant="outlined"
              />
            );
          })}
        <TranslationJobContentTable
          sourceLocale={CLM.translationJob.sourceLanguage}
          name={CLM.translationJob.sequenceNumber}
          allowUpload={!isReadOnly(versionInfo, myuser) && allowUpload(translationJobTemplate, CLM.translationJob)}
          allowImport={!isReadOnly(versionInfo, myuser) && allowImport(translationJobTemplate, CLM.translationJob)}
          allowCancel={!isReadOnly(versionInfo, myuser)}
          versionId={CLM.translationJob.info.versionId}
          list={CLM.translationJob.translationContent}
        />
      </div>
      <LoaderModal open={isLoading}>
        <FormattedMessage id="CLM.TranslationJOBView.Loading" defaultMessage="Loading..." />
      </LoaderModal>
    </div>
  );
}

export default injectIntl(TranslationJobView);
