import React, {useEffect, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import {useDispatch} from "react-redux";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';




const useStyles = makeStyles((theme) => ({
    root : {

    }
}))

const AddButtonComp = ({handle, label, Icon}) =>{

    const dispatch = useDispatch();

    const [state, setState] = useState(null);
    const cl = useStyles()

    useEffect(()=>{

    },[])

    return (
        <Button variant="outlined"
                onClick={handle}
                startIcon={<Icon/>}>
            {label}
        </Button>
    )
}

export default AddButtonComp