import React, { useEffect, useState } from "react";
import { Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { getTranslation } from '@zert-packages/utils';
import InputItem from '../InputItem';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createFormatTemplate } from "../API";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 350,
    height: 158,
    padding: 11,
    background: '#E2F2F6'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  wrapperTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      cursor: 'pointer'
    }
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 700
  },
  wrapperInput: {
    paddingRight: 20,
    marginTop: 10
  },
  wrapperButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 20
  },
  wrapperText: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  button: {
    color: '#ffffff',
    background: '#4db1d3',
    marginLeft: 10,
    minWidth: 80,
    border: '1px solid #4db1d3',
    outline: 'none !important',
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
      color: '#212529',
      border: '1px solid #4db1d3'
    },
    '&:focus': {
      backgroundColor: '#4db1d3'
    }
  }
}));

function DialogList({ open, setOpen, text = null, save }) {
  const cl = useStyles();
  const [valueProps, setValueProps] = useState('');
  const { mylocale } = useSelector((state) => state);
  const { versionId } = useParams();

  const handleClose = () => {
    setOpen(false);
    setValueProps('');
  };

  const handleValueProps = (val) => {
    setValueProps(val);
  };

  const findIdentifier = () => {
    if (text && text.type === 'shortcut') {
      return 'shortcut';
    }
    return false;
  };

  const handleIndex = () => {
    const findIndex = text.selectedListItem - 1;
    if (findIndex < 0) {
      return 0;
    }
    return  findIndex
  };




  const handleSave = async () => {
    handleClose();
    if(save){
      save()
    }
    if (text) {
      if (text.type === 'add') {
        if (text.leftMenu) {
          text.setLeftMenu((prev) => [
            {
              label: {
                defaultLabel: valueProps
              }
            },
            ...prev
          ]);
        } else {
          text.setLeftMenu([
            {
              label: {
                defaultLabel: valueProps
              }
            }
          ]);
        }
        const res = await createFormatTemplate({
          locale: mylocale,
          parentVersionId: versionId,
        })
        console.log('res', res);
        text.setValues((prev) => ({
          ...prev,
          templateList: {
            label: {
              defaultLabel: valueProps
            }
          }
        }));
      }
      if (text.type === 'edit') {
        const getLabel = text.leftMenu[text.selectedListItem];
        if (getLabel.label && getLabel.label.locales) {
          const editItemMenu = text.leftMenu.reduce((acu, cur) => {
            if (cur.label.defaultLabel === text.leftMenu[text.selectedListItem].label.defaultLabel) {
              return [
                ...acu,
                {
                  ...cur,
                  label: {
                    ...cur.label,
                    translations: cur.label.translations.map((el) =>
                      el === getTranslation(getLabel.label) ? valueProps : el
                    )
                  }
                }
              ];
            }
            return [...acu, cur];
          }, []);
          text.setLeftMenu(editItemMenu);
          text.setValues((prev) => ({
            ...prev,
            templateList: {
              ...prev.templateList,
              label: {
                ...prev.templateList.label,
                translations: prev.templateList.label.translations.map((el) =>
                  el === getTranslation(getLabel.label) ? valueProps : el
                )
              }
            }
          }));
        } else {
          const editItemMenu = text.leftMenu.map((el) =>
            el.label.defaultLabel === text.leftMenu[text.selectedListItem].label.defaultLabel
              ? {
                  ...el,
                  label: {
                    defaultLabel: valueProps,
                    ...el.defaultLabel
                  }
                }
              : el
          );
          text.setLeftMenu(editItemMenu);
          text.setValues((prev) => ({
            ...prev,
            templateList: {
              ...prev.templateList,
              label: {
                defaultLabel: valueProps
              }
            }
          }));
        }
      }
      if (text.type === 'delete') {
        const handleSelectedItem = text.leftMenu.filter(
          (el) => el.label.defaultLabel !== text.leftMenu[text.selectedListItem].label.defaultLabel
        );
        text.setValues((prev) => ({
          ...prev,
          templateList: handleSelectedItem[handleIndex()]
        }));
        text.setLeftMenu(handleSelectedItem);
        text.setSelectedListItem(pre => pre - 1)
      }
    }
  };



  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paperScrollPaper: cl.root
      }}
    >
      {text && (
        <div className={cl.wrapper}>
          <div className={cl.wrapperTitle}>
            <span className={cl.title}>{text.label && text.label}</span>
            <CloseIcon onClick={handleClose} />
          </div>
          {text.id !== 2 && (
            <div className={cl.wrapperInput}>
              <InputItem
                label={text.InputItemLabel ? text.InputItemLabel : 'Identifier:'}
                // style='auto 1fr'
                focus
                valueProps={text.valueLabel && text.valueLabel}
                setValueProps={handleValueProps}
                Identifier={() => findIdentifier()}
              />
            </div>
          )}

          {text.id === 2 && (
            <div className={cl.wrapperText}>
              {text.text && <div>{text.text}</div>}
              {text.textTwo && <div>{text.textTwo}</div>}
            </div>
          )}

          <div className={cl.wrapperButton}>
            <Button className={cl.button} onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={text.id === 0 && valueProps.length === 0} className={cl.button} onClick={handleSave}>
              OK
            </Button>

          </div>
        </div>
      )}
    </Dialog>
  );
}

export default DialogList;
