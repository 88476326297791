import { createAction, createReducer } from '@reduxjs/toolkit';

export const getTemplates = createAction('Safety Inspection: Load templates List');
export const getQUESTTemplate = createAction('Safety Inspection: Load QUESTIONAIRE TEMPLATE');
export const getRRTemplate = createAction('Safety Inspection: Load RR TEMPLATE');
export const getMEASUREWFTTemplate = createAction('Safety Inspection: Load MEASURE WFT TEMPLATE');
export const getMEASURETemplate = createAction('Safety Inspection: Load MEASURE TEMPLATE');
export const getWFTTemplate = createAction('Safety Inspection: Load WFT TEMPLATE');

export const initialState = {
  templates: null,
  measureWFTTemplate: null,
  rrTemplate: null,
  measureTemplate: null,
  wftTemplate: null,
  questTemplate: null
};

export const QUESTIONNAIREReducer = createReducer(initialState, {
  [getTemplates]: (state, action) => {
    state.templates = action.payload;
  },
  [getQUESTTemplate]: (state, action) => {
    state.questTemplate = action.payload;
  },
  [getRRTemplate]: (state, action) => {
    state.rrTemplate = action.payload;
  },
  [getMEASURETemplate]: (state, action) => {
    state.measureTemplate = action.payload;
  },
  [getWFTTemplate]: (state, action) => {
    state.wftTemplate = action.payload;
  },
  [getMEASUREWFTTemplate]: (state, action) => {
    state.measureWFTTemplate = action.payload;
  }
});
