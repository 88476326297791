import Reports from '@zert-packages/components/common/Reports';
import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import { loadActionPlan } from '@zert-packages/actions/actionplan';
import { NavigateNext } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexFlow: 'row nowrap',
    padding: '0 10px',
    '& > *': {
      marginLeft: 10
    },
    '& button': {
      paddingLeft: 5,
      paddingRight: 5
    }
  }
});

const VersionController = memo(
  ({
    onSaveVersion,
    onApproveVersion,
    onCreateNewVersion,
    onPreview,
    onReport,
    onExport,
    canCreateVersion,
    canSaveVersion,
    canApproveVersion,
    canPreview,
    canReport,
    canExport,
    canShowNextTask,
    versionId,
    whyDisabledText
  }) => {
    const { mytasks } = useSelector((state) => state);
    /* const intl = useIntl(); */
    const history = useHistory();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const getMyTask = () => {
      if (!mytasks && !loading) {
        setLoading(true);
        dispatch(loadActionPlan(true, '*', 'false'));
        return null;
      }
      if (!mytasks) {
        return null;
      }
      return mytasks.find((task) => !task.visited);
    };

    useEffect(() => {
      if (mytasks && versionId) {
        const task = mytasks.find((task) => task.versionId == versionId);
        if (task) {
          task.visited = true;
        }
      }
    }, [mytasks, versionId]);

    const openTask = () => {
      history.push(getPluginByMimeType(getMyTask().mimeType).openpath.replace(':versionId', getMyTask().versionId));
    };

    return (
      <div className={useStyles().root}>
        {canSaveVersion && (
          <VersionPanelButton
            onClick={onSaveVersion}
            children={<FormattedMessage id="TabsWithVersionController.Button.Save" defaultMessage="Save" />}
          />
        )}
        <ApproveButton
          onClick={onApproveVersion}
          canApproveVersion={canApproveVersion}
          whyDisabledText={whyDisabledText}
          children={<FormattedMessage id="TabsWithVersionController.Button.Approve" defaultMessage="Approve" />}
        />
        {canCreateVersion && (
          <VersionPanelButton
            onClick={onCreateNewVersion}
            children={
              <FormattedMessage
                id="TabsWithVersionController.Button.CreateVersion"
                defaultMessage="Create new version"
              />
            }
          />
        )}
        {canPreview && (
          <VersionPanelButton
            onClick={onPreview}
            children={<FormattedMessage id="TabsWithVersionController.Button.Preview" defaultMessage="Preview" />}
          />
        )}
        {canExport && (
          <VersionPanelButton
            onClick={onExport}
            children={<FormattedMessage id="TabsWithVersionController.Button.Export" defaultMessage="Export" />}
          />
        )}
        {canReport && onReport?.reports && <Reports reports={onReport.reports} printReport={onReport.printReport} />}

        {canShowNextTask && getMyTask() && (
          <VersionPanelButton
            onClick={openTask}
            endIcon={<NavigateNext />}
            children={<FormattedMessage id="TabsWithVersionController.Button.NextTask" defaultMessage="Next" />}
          />
        )}
      </div>
    );
  }
);

export default VersionController;

function VersionPanelButton(props) {
  return <Button {...props} color="primary" variant="text" style={{ marginLeft: '5px' }} />;
}

function ApproveButton({ whyDisabledText, canApproveVersion, ...otherProps }) {
  const tooltipText = whyDisabledText && !canApproveVersion ? whyDisabledText : "";

  return (
    <Tooltip title={tooltipText}>
      <span style={{ margin: 0 }}>
        <Button
          {...otherProps}
          color="primary"
          variant="text"
          style={{ marginLeft: '5px' }}
          disabled={!canApproveVersion}
        />
      </span>
    </Tooltip>
  );
}
