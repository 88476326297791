import React, { memo } from 'react';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import { TextField } from '@mui/material';

function TextFieldNumberWithMemo(props) {
  return <TextField type="number" {...props} />;
}

export default memo(
  TextFieldNumberWithMemo,
  selectiveMemoComparison(
    (p) => p.disabled,
    (p) => p.value
  )
);
