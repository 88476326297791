import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { fetchRA_Error, handlePostToApi, handleQuery } from '@zert-packages/actions/api';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import UserSuggestBox from '@zert-packages/components/shared/UserSuggestBoxNew/UserSuggestBox';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { showSnackbarChangesSaved } from '@zert-packages/components/Snackbars';
import { FormattedMessage } from 'react-intl';

const projectRoles = [
  { label: 'None', value: null },
  {
    label: <FormattedMessage id="newpage.project.project.owner" defaultMessage="Project Owner" />,
    value: 'project-leader'
  },
  {
    label: <FormattedMessage id="newpage.project.projectmanager" defaultMessage="Project Manager" />,
    value: 'project-manager'
  },
  { label: 'Safety Inspection Owner', value: 'si-owner' }
];

const WorkflowsTab = ({ companyId }) => {
  const [settings, setSettings] = useState([]);
  const [roles, setRoles] = useState([]); // State for roles
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  // Fetch settings and roles
  useEffect(() => {
    setLoading(true);
    setError(null);

    // Fetch settings and roles concurrently
    Promise.all([
      handleQuery(`/admin/template-participant-settings?companyId=${companyId}`),
      handleQuery(`/catalog/getDetailedCatalogs/${companyId}`)
    ])
      .then(([settingsData, catalogs]) => {
        if (!Array.isArray(settingsData)) {
          throw new Error('Unexpected response format for settings');
        }

        // Extract and transform roles from catalogs
        const fetchedRoles = catalogs.flatMap((catalog) =>
          catalog.roles.map((role) => ({
            label: role.label.defaultLabel,
            value: role.identifier,
            catalog: catalog.name // Assuming the catalog has a `name` property
          }))
        );

        setSettings(settingsData);
        setRoles(fetchedRoles);
      })
      .catch((err) => {
        setError(err.message || 'Failed to fetch data');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companyId]);

  const handleProjectRoleChange = (templateElementId, participantId, value) => {
    setSettings((prevSettings) =>
      prevSettings.map((setting) =>
        setting.templateElementId === templateElementId && setting.participantId === participantId
          ? { ...setting, projectRole: value }
          : setting
      )
    );
  };

  const handleRoleChange = (templateElementId, participantId, value) => {
    setSettings((prevSettings) =>
      prevSettings.map((setting) =>
        setting.templateElementId === templateElementId && setting.participantId === participantId
          ? { ...setting, role: value } // Assigning the role identifier
          : setting
      )
    );
  };

  const handleUserChange = (selectedUser, setting) => {
    const userProperties = {
      guid: selectedUser?.guid || '',
      active: selectedUser?.active || '',
      email: selectedUser?.email || '',
      fullname: selectedUser?.fullname || '',
      role: selectedUser?.role || '',
      username: selectedUser?.userName || ''
    };

    const updatedUser = {
      ...selectedUser,
      properties: userProperties
    };

    // Update participant in the settings with the selected user's data
    setSettings((prevSettings) =>
      prevSettings.map((s) =>
        s.templateElementId === setting.templateElementId && s.participantId === setting.participantId
          ? { ...s, participant: updatedUser }
          : s
      )
    );
  };

  const handleSave = () => {
    handlePostToApi(`/admin/template-participant-settings?companyId=${companyId}`, settings)
      .then(() => {
        showSnackbarChangesSaved(snackbar);
      })
      .catch((error) => {
        dispatch(fetchRA_Error('Failed to save settings: ' + error.message));
      });
  };

  // Group settings by templateElementId
  const groupedSettings = settings.reduce((acc, setting) => {
    const { templateElementId, templateName } = setting;
    if (!acc[templateElementId]) {
      acc[templateElementId] = { templateName, participants: [] };
    }
    acc[templateElementId].participants.push(setting);
    return acc;
  }, {});

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <Box sx={{ maxHeight: '90vh', overflowY: 'auto', padding: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}
      >
        <Box sx={{ flex: '0 0 75%', maxWidth: '75%' }}>
          <Typography variant="h4" gutterBottom>
            Workflows - Participant Settings
          </Typography>
          <Typography variant="body1" paragraph>
            For each participant below, you can select a default role and user. If a role is selected, the system will
            first try to assign a new task to a user with this role in the organization. If this fails, the task will be
            assigned to the selected user.
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{
            alignSelf: 'flex-start',
            ml: 2
          }}
        >
          Save
        </Button>
      </Box>

      {/* Render grouped settings */}
      {Object.entries(groupedSettings).map(([templateElementId, { templateName, participants }]) => (
        <Box key={templateElementId} mt={3}>
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            {templateName}
          </Typography>
          <Box>
            {participants.map((setting, index) => (
              <Box key={setting.participantId} display="flex" alignItems="center" mb={2} gap={2}>
                <Typography variant="subtitle1" sx={{ minWidth: 120 }}>
                  {setting.participantName}:
                </Typography>

                {/* Project Role Dropdown */}
                <FormControl variant="outlined" size="small" sx={{ minWidth: 180 }}>
                  <InputLabel>Project Role</InputLabel>
                  <Select
                    value={setting.projectRole || ''}
                    onChange={(e) =>
                      handleProjectRoleChange(setting.templateElementId, setting.participantId, e.target.value)
                    }
                    label="Project Role"
                  >
                    {projectRoles.map((role) => (
                      <MenuItem key={role.value} value={role.value}>
                        {role.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Role Dropdown */}
                <FormControl variant="outlined" size="small" sx={{ minWidth: 180 }}>
                  <InputLabel>Role</InputLabel>
                  <Select
                    value={setting.role || ''}
                    onChange={(e) => handleRoleChange(setting.templateElementId, setting.participantId, e.target.value)}
                    label="Role"
                    renderValue={(selected) => roles.find((role) => role.value === selected)?.label || 'Select a role'}
                  >
                    {/* Add a hardcoded "None" option */}
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    {roles.reduce((listItems, role) => {
                      // Push the catalog header as ListSubheader
                      if (!listItems.some((item) => item.key === role.catalog)) {
                        listItems.push(<ListSubheader key={role.catalog}>{role.catalog}</ListSubheader>);
                      }

                      // Push the MenuItem for each role
                      listItems.push(
                        <MenuItem key={role.value} value={role.value}>
                          {role.label}
                        </MenuItem>
                      );

                      return listItems;
                    }, [])}
                  </Select>
                </FormControl>

                {/* Participant selection */}
                <UserSuggestBox
                  autoFocus
                  onChange={(value) => handleUserChange(value, setting)}
                  getUsers={getUsersQuery} // Function to fetch users based on search input
                  getUserId={(user) => user.username}
                  getUserLabel={(user) => user.name}
                  initialValue={setting.participant ? setting.participant.displayName : ''}
                  label={'Participant'}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default WorkflowsTab;
