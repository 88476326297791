import React, { useRef, createContext, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { Layer, Stage } from "react-konva";
import { dataCanvas } from "./dataCanvas";
import CanvasItem from "./CanvasItem";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import useElementDimensions from "@zert-packages/utils/useElementDimensions";



const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative"
  },
  wrapperContainerCanvas: (props) => ({
    width: props.width,
    height: props.height,
    position: "absolute",
    top: 0
  })
}));


export const CanvasContext = createContext(null);

const WrapperCanvas = ({ children, values, setValues, hotspots, context, el }) => {
  const ref = useRef(null);
  const { width, height } = useElementDimensions(ref);
  const cl = useStyles({ width, height });
  const { values: contextValues, setValues: setContextValues } = useContext(context || PanelContext);


  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setValues(pre => ({ ...pre, "selectedId": null }));
    }
  };

  const findCanvas = () => {
    if (!contextValues.addImgPhraseType) {
      return;
    }
    const content = contextValues.phraseContent;

    if (contextValues.addImgPhraseType === "group") {
      setValues(pre => ({ ...pre, item: [...pre.item, { ...dataCanvas()[0], ...content }] }));
      return setContextValues(pre => ({ ...pre, addImgPhraseType: null, phraseContent: "" }));
    }

    const findHotspot = values.item.find(el => el.id === content.id);
    if (findHotspot) {
      const res = values.item.reduce((acu, cur) => {
        if (cur.id === content.id) {
          return [...acu, {
            ...content
          }];
        }
        return [...acu, cur];
      }, []);
      setValues(pre => ({ ...pre, item: res }));
      return setContextValues(pre => ({ ...pre, addImgPhraseType: null, phraseContent: "" }));
    }

    if (contextValues.addImgPhraseType === "Ellips") {
      setValues(pre => ({ ...pre, item: [...pre.item, { ...dataCanvas()[1], ...content }] }));
      return setContextValues(pre => ({ ...pre, addImgPhraseType: null, phraseContent: "" }));
    }

    if (contextValues.addImgPhraseType === "Rektangel") {
      setValues(pre => ({
        ...pre, item: [...pre.item, {
          ...dataCanvas()[2],
          ...content,
          text: content.position, width: content.width,
          height: content.height ? content.height : content.width,
          id: content.id
        }]
      }));
      return setContextValues(pre => ({ ...pre, addImgPhraseType: null, phraseContent: "" }));
    }
    if (contextValues.addImgPhraseType === "arrow") {
      setValues(pre => ({
        ...pre, item: [...pre.item, {
          ...content,
          points: [0, 0, (content.height + (content.height * 0.2)), 0],
          ...dataCanvas()[3]
        }]
      }));
      return setContextValues(pre => ({ ...pre, addImgPhraseType: null, phraseContent: "" }));
    }
  };


  const disableHotspot = (item) => {
    if(!hotspots.hideHotspots){
      return item
    }
    return  item.filter(el => !el.class.includes('Hotspot'))
  }
  

  useEffect(() => {
    findCanvas();
  }, [contextValues.addImgPhraseType, contextValues.phraseContent]);

  useEffect(() => {
    if(contextValues.selectedPart) {
      setValues(pre => ({ ...pre, selectedItem:
            disableHotspot(values.item).find(point => contextValues.selectedPart.position === point.position) }));
    }
  }, [contextValues.selectedPart])

  return (<div className={cl.root} ref={ref} onClick={() => {
    setContextValues({...contextValues, selectedElement: el, selectedPart: null})
    setValues({...values})
  }}>
    <CanvasContext.Provider value={{ values, setValues }}>
      {children}
      <div className={cl.wrapperContainerCanvas}>
        <Stage
          // scaleX={1}
          // scaleY={1}
          width={width}
          height={height}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
        >
          <Layer>
            {values.item?.length > 0 && disableHotspot(values.item).map((el, i) => (
              <CanvasItem
                key={i}
                idx={i}
                ref={ref}
                el={el}
                wrapper={{ width, height }}
                hotspots={hotspots}
                context={context}
              />
            ))}
          </Layer>
        </Stage>
      </div>
    </CanvasContext.Provider>

  </div>);
};

export default WrapperCanvas;