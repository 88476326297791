import React, { useState } from "react";
import { AppBar, Tabs, Tab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import VersionController from "./VersionController";


const useStyles = makeStyles({
  appBar: {
    position: "sticky",
    top: 0,
    zIndex: 10,
    height: 48
  },
  wrapper: {
    background: "#F5F5F5"
  },
  container: {
    display: "grid",
    gridTemplateColumns: "1fr auto"
  },
  tabsRoot: {
    alignItems: "center",
    "& .MuiTabs-flexContainer": {
      gap: 15
    }
  }

});

function TabsWithVersionController({
                                     tabs,
                                     tabIndex,
                                     onChangeTabIndex,
                                     onSaveVersion,
                                     onApproveVersion,
                                     onCreateNewVersion,
                                     onPreview,
                                     onReport,
                                     onExport,
                                     canCreateVersion,
                                     canSaveVersion,
                                     canApproveVersion,
                                     canPreview,
                                     canReport,
                                     canExport,
                                     canShowNextTask,
                                     versionId,
                                     findBlock,
                                     setValues,
                                     whyDisabledText
                                   }) {
  const cl = useStyles();
  const [tabsVales, setTabsVales] = useState({
    find: false
  });

  return (
    <AppBar position="static" color="default" className={cl.appBar}>
      <div className={cl.wrapper}>
        <div className={cl.container}>
          <Tabs
            className={cl.tabsRoot}
            value={tabIndex}
            variant="scrollable"
            scrollButtons
            indicatorColor="primary"
            textColor="primary"
            allowScrollButtonsMobile
            onChange={onChangeTabIndex}
          >
            {tabs.map((tab, index) => (
              <Tab {...tab} key={index} />
            ))}
          </Tabs>
          <VersionController
            {...{
              onSaveVersion,
              onApproveVersion,
              onCreateNewVersion,
              onPreview,
              onReport,
              onExport,
              canCreateVersion,
              canSaveVersion,
              canApproveVersion,
              canPreview,
              canReport,
              canExport,
              canShowNextTask,
              versionId,
              whyDisabledText
            }}
          />
        </div>
      </div>
      {findBlock && tabsVales.find && { ...findBlock, props: { ...findBlock.props, setTabsVales } }}
    </AppBar>
  );
}

export default TabsWithVersionController;
