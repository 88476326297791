import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';

function HiddenView({ data, setData }) {
  const [details, setDetails] = useState(data);

  useEffect(() => {
    setData(details);
  }, [details]);

  useEffect(() => {
    setDetails(data);
  }, [data]);

  const onTextFieldChange = (event) => {
    setDetails((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  return (
    <div>
      <TextField
        fullWidth
        variant="outlined"
        label="Value:"
        value={details.value}
        name="value"
        onChange={onTextFieldChange}
      />
    </div>
  );
}

export default HiddenView;
