import React, { useContext } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import BoxWithTitle from '@zert-packages/components/shared/BoxWithTitle';
import CheckboxWithMemo from '@zert-packages/components/common/CheckboxWithMemo';
import './MailSettings.css';
import { FormattedMessage } from 'react-intl';
import Accordion from '@zert-packages/components/shared/Accordion';
import MenuItem from '@mui/material/MenuItem';
import SlowTooltip from '@zert-packages/components/SlowTooltip';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import SaveActions from './common/CancelSavePanel';
import { MailSettingsContext } from './common/ContextProvider';

/*
const taskTypesValues = [
  {
    'value': 'application/zert-translation-job',
    'label': <FormattedMessage id="MailSettings.FilterPage.translationJob" defaultMessage="Translation Job"/>,
  },
  {
    'value': 'application/zert-deviation',
    'label': <FormattedMessage id="MailSettings.FilterPage.deviation" defaultMessage="Deviation"/>,
  },
  {
    'value': 'application/zert-review-job',
    'label': <FormattedMessage id="MailSettings.FilterPage.reviewJob" defaultMessage="Review Job"/>,
  },
  {
    'value': 'application/zert-questionnaire-answer',
    'label': <FormattedMessage id="MailSettings.FilterPage.questionnaireAnswer" defaultMessage="Questionnaire"/>,
  },
  {
    'value': 'application/zert-task',
    'label': <FormattedMessage id="MailSettings.FilterPage.zertTask" defaultMessage="Task"/>,
  },
  {
    'value': 'application/zert-delayedtask',
    'label': <FormattedMessage id="MailSettings.FilterPage.delayedTask" defaultMessage="Delayed task"/>,
  },
  {
    'value': 'application/zert-plan-ahead-task',
    'label': <FormattedMessage id="MailSettings.FilterPage.planAheadTask" defaultMessage="Plan ahead task"/>,
  },
  {
    'value': 'application/zert-measure',
    'label': <FormattedMessage id="MailSettings.FilterPage.measure" defaultMessage="Measure"/>,
  },
  {
    'value': 'application/zert-project-document',
    'label': <FormattedMessage id="MailSettings.FilterPage.projectDocument" defaultMessage="Project document"/>,
  },
  {
    'value': 'application/zert-safety-inspection',
    'label': <FormattedMessage id="MailSettings.FilterPage.SI" defaultMessage="Safety inspection"/>,
  },
];
*/

function FilterPage() {
  const { mainData, handleUpdateFilter, handleSave, handleCancel, userRole, roles } = useContext(MailSettingsContext);
  const { filter } = mainData;

  const updateTaskTypePerson = (mimeType, field, value) =>
    handleUpdateFilter.updateTaskType('personal', mimeType, field, value);

  const updateTaskTypeCompany = (mimeType, field, value) =>
    handleUpdateFilter.updateTaskType('company', mimeType, field, value);

  const updateOtherSettingsPersonal = (fieldToUpdate, value) =>
    handleUpdateFilter.updateOtherSettings('personal', fieldToUpdate, value);

  const updateOtherSettingsCompany = (fieldToUpdate, value) =>
    handleUpdateFilter.updateOtherSettings('company', fieldToUpdate, value);

  return (
    <div className="mail-settings-filter__root-container">
      <div className="mail-settings-filter__body-container">
        <BoxWithTitle
          title={<FormattedMessage id="MailSettings.FilterPage.Personal.title" defaultMessage="Personal" />}
        >
          <Accordion
            title={<FormattedMessage id="MailSettings.FilterPage.TaskTypes.title" defaultMessage="Task Types" />}
          >
            <TaskTypes rows={filter.personal.taskTypes} onChange={updateTaskTypePerson} />
          </Accordion>
          <OtherSettings values={generateOtherSettings(filter, 'personal')} onChange={updateOtherSettingsPersonal} />
        </BoxWithTitle>

        {(userRole === roles.ADMINISTRATOR || userRole === roles.TASK_ADMINISTRATOR) && (
          <BoxWithTitle
            title={<FormattedMessage id="MailSettings.FilterPage.Company.title" defaultMessage="Company" />}
          >
            <Accordion
              title={<FormattedMessage id="MailSettings.FilterPage.TaskTypes.title" defaultMessage="Task Types" />}
            >
              <TaskTypes rows={filter.company.taskTypes} onChange={updateTaskTypeCompany} />
            </Accordion>
            <OtherSettings values={generateOtherSettings(filter, 'company')} onChange={updateOtherSettingsCompany} />
          </BoxWithTitle>
        )}
      </div>
      <SaveActions onSave={handleSave} onCancel={handleCancel} />
    </div>
  );
}

function TaskTypes({ rows, onChange }) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage id="MailSettings.FilterPage.TaskTypes.taskType.title" defaultMessage="Task Type" />
          </TableCell>

          <TableCell align="right">
            <FormattedMessage id="MailSettings.FilterPage.Channel.title" defaultMessage="Channel" />
          </TableCell>

          <TableCell align="right">
            <FormattedMessage
              id="MailSettings.FilterPage.TaskTypes.TaskFinished.title"
              defaultMessage="Task finished"
            />
          </TableCell>

          <TableCell align="right">
            <FormattedMessage
              id="MailSettings.FilterPage.TaskTypes.TaskAssigned.title"
              defaultMessage="Task assigned to you"
            />
          </TableCell>
          <TableCell align="right">
            <FormattedMessage
              id="MailSettings.FilterPage.TaskTypes.InformationAboutTasks.title"
              defaultMessage="Information about tasks"
            />
          </TableCell>
          <TableCell align="right">
            <FormattedMessage id="MailSettings.FilterPage.TaskTypes.Reminder.title" defaultMessage="Reminder" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.mimeType}>
            <TableCell component="th" scope="row">
              {getPluginByMimeType(row.mimeType).name}
            </TableCell>
            <TableCell align="right">
              <Select
                value={row.settings.push}
                className="form-control animated"
                onChange={(e) => {
                  onChange(row.mimeType, 'push', e.target.value);
                }}
              >
                <MenuItem value={false}>
                  <SlowTooltip title="Mail">
                    <Typography variant="body1" noWrap>
                      <FormattedMessage id="MailSettings.FilterPage.mail" defaultMessage="Mail" />
                    </Typography>
                  </SlowTooltip>
                </MenuItem>
                <MenuItem value>
                  <SlowTooltip title="Push">
                    <Typography variant="body1" noWrap>
                      <FormattedMessage id="MailSettings.FilterPage.push" defaultMessage="Push" />
                    </Typography>
                  </SlowTooltip>
                </MenuItem>
              </Select>
            </TableCell>
            <TableCell align="right">
              <CheckboxWithMemo
                isChecked={row.settings.finished}
                onChange={(isChecked) => onChange(row.mimeType, 'finished', isChecked)}
              />
            </TableCell>
            <TableCell align="right">
              <CheckboxWithMemo
                isChecked={row.settings.assigned}
                onChange={(isChecked) => onChange(row.mimeType, 'assigned', isChecked)}
              />
            </TableCell>
            <TableCell align="right">
              <CheckboxWithMemo
                isChecked={row.settings.information}
                onChange={(isChecked) => onChange(row.mimeType, 'information', isChecked)}
              />
            </TableCell>
            <TableCell align="right">
              <CheckboxWithMemo
                isChecked={row.settings.reminder}
                onChange={(isChecked) => onChange(row.mimeType, 'reminder', isChecked)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function OtherSettings({ values, onChange }) {
  return (
    <div className="mail-settings-filter__other-container">
      <CheckboxWithMemo
        isChecked={values.publishingExpired}
        onChange={(isChecked) => onChange('publishingExpired', isChecked)}
        label={
          <FormattedMessage id="MailSettings.FilterPage.PublishingExpired.label" defaultMessage="Publishing expired" />
        }
      />
      <CheckboxWithMemo
        isChecked={values.commentOnPublishing}
        onChange={(isChecked) => onChange('commentOnPublishing', isChecked)}
        label={
          <FormattedMessage
            id="MailSettings.FilterPage.CommentAdded.label"
            defaultMessage="Comment added on publishing"
          />
        }
      />
      <CheckboxWithMemo
        isChecked={values.oneNewPublishingToRead}
        onChange={(isChecked) => onChange('oneNewPublishingToRead', isChecked)}
        label={
          <FormattedMessage
            id="MailSettings.FilterPage.PublishingToRead.label"
            defaultMessage="You have a new publishing to read"
          />
        }
      />
    </div>
  );
}
export default FilterPage;

function generateOtherSettings(filter, field) {
  return {
    publishingExpired: filter[field].publishingExpired,
    commentOnPublishing: filter[field].commentOnPublishing,
    oneNewPublishingToRead: filter[field].oneNewPublishingToRead
  };
}
