import React, {useContext} from 'react';
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PhaseContext } from "./SoftwareTextsView";
import { filterItems} from "./helpers/filterItems";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  rootSearch: {
    width: '100%',
    '& .MuiInputBase-root': {
      paddingRight: 0,
    },
    '& .MuiInputBase-input': {
      paddingTop: 4,
      paddingBottom: 4,
    }
  },
  closeIcon: {
    cursor: 'pointer',
  },
}))

const SearchTerms = ({terms}) => {
  const cl = useStyles();
  const { values, setValues } = useContext(PhaseContext)

  const handleSearch = (e) => {
    const val = e.target.value
    const res = filterItems(val, terms)
    setValues(pre => ({...pre, 'searchValue': val, filteredStates: res}))
  }

  const handleClose = () => {
    setValues(pre => ({...pre, 'searchValue': '', filteredStates: terms}))
  }

  return(<div>
    <TextField
      className={cl.rootSearch}
      value={values.searchValue}
      onChange={handleSearch}
      InputProps={{
        endAdornment: <>
          {values.searchValue.length > 0 && <InputAdornment position="start" id="standard-basic" className={cl.closeIcon}>
            <CloseIcon onClick={handleClose} />
          </InputAdornment>}
        </>
      }}
    />
  </div>)
}

export default SearchTerms