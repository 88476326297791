export const listData = {
  class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientListBlockSettingsTemplate',
  identifier: 'list-bullet',
  label: 'Lista (Punkter)',
  type: 'DASH',
  selectImage: 'None',
  color: null,
  prefix: '',
  suffix: '',
  provisionalDistanceBetweenStarts: 12,
  provisionalDistanceBetweenStartsLengthUnit: 'POINTS',
  provisionalLabelSeparation: 2,
  provisionalLabelSeparationLengthUnit: 'POINTS',
  orphans: 2,
  widows: 2,
  marginLeft: {
    value: 0,
    unit: 'POINTS'
  },
  marginRight: {
    value: 0,
    unit: 'POINTS'
  },
  bulletSize: 'NORMAL',
  separatedBlock: false
};
