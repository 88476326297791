import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tabs, Tab } from "@mui/material";
import getTranslation from "@zert-packages/utils/getTranslation.old";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SkeletonLoading from "./Tree/SkeletonLoading";
import DialogList from "./dialog/DialogList";
import { findListLef } from "./utils/findListItem";
import { gridBlock } from "./utils/helpersBlock";
import BlockTabs from "./tabs/BlockTabs";
import useStyles from "./styles";
import TableTabs from "./tabs/TableTabs";
import ImageTabs from "./tabs/ImageTabs";
import PartTabs from "./tabs/PartTabs";
import ParagraphTabs from "./tabs/ParagraphTabs";
import TextTabs from "./tabs/TextTabs";
import HiddenFormatsTabs from "./tabs/hiddenFormats/HiddenFormatsTabs";
import { findLabel, handledListItem, handleExpander } from "./utils/main";
import FormatTemplateWrapper from "./FormatTemplateWrapper";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogHandleFormat from "./dialog/dialogHandleFormat/DialogHandleFormat";

export const TabsContext = createContext(null);

const FormatTemplateTabs = () => {
  const cl = useStyles();
  const nameTabs = ["Block", "Table", "Image", "Part", "Paragraph", "Text", "Hidden formats"];
  const [selectedTab, setSelectTab] = useState(0);
  const [leftMenu, setLeftMenu] = useState(null);
  const { formatTemplate } = useSelector((state) => state);
  const [selectedListItem, setSelectedListItem] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [propsDialog, setPropsDialog] = useState(null);
  const [expander, setExpander] = useState(2);
  const [values, setValues] = useState({
    disabled: false,
    templateList: null,
    selectedReferences: 0,
    addReferences: null,
    groups: null,
  });
  const disable = !leftMenu || leftMenu.length === 0


  const handleChangeSelectedTab = (event, newValue) => {
    setSelectTab(newValue);
    setExpander(2);
  };

  const findTemplateList = (template, name) => {
    if (template) {
      setValues((prev) => ({ ...prev, templateList:  template[0] || template, typeList: name }));
    }
  };

  const handleSelectedListItem = (el, idx) => {
    setSelectedListItem(idx);
    setValues((prev) => ({ ...prev, templateList: el }));
  };

  const handleOpenDialog = (label) => {
    if (label.type !== "add" && !leftMenu) {
      return;
    }
    setOpenDialog(true);
    setPropsDialog(label);
  };
  const handleFormat = () => {
    renderInExceptionRoot(DialogHandleFormat,
      { label: "Add format", values, setValues, setLeftMenu, leftMenu, setSelectedListItem }
    );
  };


  const findReferences = () => {
    const referencesFind = Object.values(formatTemplate)
      .filter((el) => el && el.length > 0 && Array.isArray(el))
      .flat()
      .filter((el) => el.references)
      .map((el) => el.references)
      .flat();
    const result =
      referencesFind &&
      referencesFind
        .reduce((acu, cur) => {
          const findActiveTemplateList =
            values.templateList.references &&
            values.templateList.references.find((item) => item.identifier === cur.identifier);
          if (!acu.find((item) => item.identifier === cur.identifier) && !findActiveTemplateList) {
            return [...acu, cur];
          }
          return acu;
        }, [])
        .sort((a, b) => a.type.localeCompare(b.type));
    if (result) {
      setValues((prev) => ({ ...prev, addReferences: result }));
    }
  };

  useEffect(() => {
    if (formatTemplate) {
      findListLef(setSelectedListItem, selectedTab, formatTemplate, findTemplateList, setLeftMenu);
    }
  }, [formatTemplate, selectedTab]);

  useEffect(() => {
    if (formatTemplate && values.templateList) {
      findReferences();
    }
  }, [formatTemplate, values.templateList]);
  

  return (
    <TabsContext.Provider value={{ values, setValues }}>
      <FormatTemplateWrapper selectedListItem={selectedListItem} selectedTab={selectedTab} leftMenu={leftMenu}>
        <div className={cl.root}>
          <Tabs
            indicatorColor=""
            className={cl.tabsRoot}
            value={selectedTab}
            onChange={handleChangeSelectedTab}
            textColor="primary"
            variant="standard"
            scrollButtons="auto"
            centered
          >
            {nameTabs.map((el, i) => (
              <Tab label={el} key={i} />
            ))}
          </Tabs>
          <DialogList open={openDialog} setOpen={setOpenDialog} text={propsDialog} />
          {!formatTemplate ? (
            <SkeletonLoading />
          ) : (
            <div
              className={cl.wrapperRoot}
              style={{ gridTemplateColumns: gridBlock(expander) }}>
              <div>
                <div className={cl.wrapperLeftMenu}>
                  {values.templateList?.identifier && expander !== 1 && (
                    <div className={cl.underLeftMenu}>
                      <div>
                        <ArrowBackIcon className={cl.arrowUp}
                                       onClick={() => handledListItem(selectedListItem, leftMenu, setSelectedListItem, setValues, true)} />
                        <ArrowBackIcon className={cl.arrowDown}
                                       onClick={() => handledListItem(selectedListItem, leftMenu, setSelectedListItem, setValues)} />
                      </div>
                      <div style={{ height: "100%" }}>
                        {leftMenu && leftMenu.map((el, i) => (
                          <div
                            onClick={() => handleSelectedListItem(el, i)}
                            className={selectedListItem === i ? cl.listLeftMenuSelected : cl.listLeftMenu}
                            key={i}
                          >
                            {findLabel(el)}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                {selectedTab !== 6 &&
                  <div className={cl.wrapperEdditor} style={{ left: expander === 1 ? 3 : 0 }}>
                    {expander !== 1 && <>
                      <AddIcon onClick={handleFormat}
                      />
                      <CreateIcon
                        onClick={() =>
                          handleOpenDialog({
                            label: "Edit format",
                            id: 1,
                            valueLabel: values.templateList.label && values.templateList.label.locales
                              ? getTranslation(values.templateList.label)
                              : values.templateList.label.defaultLabel,
                            type: "edit",
                            setValues,
                            setLeftMenu,
                            leftMenu,
                            selectedListItem
                          })
                        }
                      />
                      <DeleteIcon
                        onClick={() => handleOpenDialog({
                          label: "Warning",
                          text: "Are you sure?",
                          textTwo: "This template can be in use",
                          id: 2,
                          type: "delete",
                          setValues,
                          setLeftMenu,
                          leftMenu,
                          selectedListItem,
                          setSelectedListItem
                        })}
                      />
                    </>}
                  </div>}
              </div>
              <div>
                <div className={cl.wrapperArrow}
                     style={{
                       marginLeft: expander === 1 ? -5 : -9
                     }}
                >
                <span>{expander !== 1 &&
                  <ArrowLeftIcon onClick={(e) => handleExpander(expander, setExpander, "left")} />}</span>
                  {expander !== 3 && <ArrowRightIcon onClick={(e) => handleExpander(expander, setExpander)} />}
                </div>
              </div>
              <div
                className={cl.wrapperRightBlock}
                style={{
                  display: expander === 3 ? "none" : "block"
                }}
              >

                {values.templateList && (
                  <>
                    {selectedTab === 0 && <BlockTabs disable={disable}/>}
                    {selectedTab === 1 && <TableTabs disable={disable}/>}
                    {selectedTab === 2 && <ImageTabs disable={disable}/>}
                    {selectedTab === 3 && <PartTabs disable={disable}/>}
                    {selectedTab === 4 && <ParagraphTabs disable={disable}/>}
                    {selectedTab === 5 && <TextTabs disable={disable}/>}
                    {/*{selectedTab ===  && <MetadataInformationTabs />}*/}
                    {selectedTab === 6 && <HiddenFormatsTabs />}
                  </>
                )}
                {/*{selectedTab === 6 && <LinkClassesTabs />}*/}

              </div>
            </div>
          )}
        </div>
      </FormatTemplateWrapper>
    </TabsContext.Provider>
  );
};

export default FormatTemplateTabs;
