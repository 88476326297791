import React, { useState } from 'react';
import { Dialog, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 350,
    padding: 11,
    background: '#E2F2F6'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  wrapperTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    '& svg': {
      cursor: 'pointer'
    }
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 700
  },
  wrapperInput: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr 25px',
    marginBottom: 10,
    gridColumnGap: 10,
    alignItems: 'center'
  },
  label: {
    fontWeight: 900
  },
  wrapperText: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  notesInput: {
    margin: 0,
    width: '100%'
  },
  wrapperButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 20,
    marginTop: 'auto',
  },
  button: {
    color: '#ffffff',
    background: '#4db1d3',
    marginLeft: 10,
    minWidth: 80,
    border: '1px solid #4db1d3',
    outline: 'none !important',
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
      color: '#212529',
      border: '1px solid #4db1d3'
    },
    '&:focus': {
      backgroundColor: '#4db1d3'
    }
  }
}));

const DialogShortCut = ({ setValues }) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState('')
  const [keyCode, setKeyCode] = useState('')

  const handleClose = () => {
    setOpen(false);
  };

  const handleValue = (event) => {
    const val = event.target.value;
    setValue(val);
  };
  const handleKey = (event) => {
    setKeyCode(event.keyCode)
  }

  const save = () => {
    handleClose()
    if(value.length === 0){
      return
    }
    setValues(pre => ({...pre, templateList: {
      ...pre.templateList,
        shortcut: { ...pre.templateList.shortcut, keyCode },
    }}))
  }


  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapper}>
      <div className={cl.wrapperTitle}>
        <span className={cl.title}>Configure shortcut</span>
        <CloseIcon onClick={handleClose} />
      </div>

        <div className={cl.wrapperInput}>
          <div className={cl.label}>Binding</div>
          <TextField
            inputProps={{ maxLength: 1 }}
            value={value}
            className={cl.notesInput}
            variant="outlined"
             onChange={handleValue}
            onKeyDown={handleKey}
            multiline
          />
        </div>
      {/*  Binding*/}


      <div className={cl.wrapperButton}>
        <Button className={cl.button} onClick={save}>
          ok
        </Button>
        <Button className={cl.button} onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </div>

  </Dialog>)
}

export default DialogShortCut