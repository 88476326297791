import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import CheckboxWithMemo from '@zert-packages/components/common/CheckboxWithMemo';
import Input from '@mui/material/Input';
import MultiSelectField2 from '@zert-packages/components/shared/FormElements/MultiSelectField2';
import {
  downloadLayoutFilters,
  downloadLayouts,
  downloadLayoutSettings,
  updateSelectedLocale
} from '../../PreviewPlugin/servicesMiddlewares';
import {
  addMoreLayouts,
  setFallbackLocale,
  setLanguageSeparated,
  setMultipleLanguage,
  setSelectedLayout,
  setSelectedLayoutFilter,
  setSelectedPreviewJOBElement
} from '../../PreviewPlugin/previewReducers';
import { loadCompareWithVersions } from '../../PreviewPlugin/API';
import SelectField from '@zert-packages/components/shared/FormElements/SelectField';
import LayoutSettingsPaper from '../../PreviewPlugin/components/LayoutSettingsPaper';
import PublishingFilterPaper from '../../PreviewPlugin/components/PublishingFilterPaper';
import ShowMoreLayouts from '../../PreviewPlugin/components/ShowMoreLayouts';
import Loader from '@zert-packages/components/shared/Loader/Loader';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(1) / 4
  },
  fontWeightRegular: {
    fontWeight: theme.typography.fontWeightRegular
  },
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium
  }
}));

export function GeneralSettingsPage({ readOnlyPublihedElement }) {
  const { layouts, selectedLayout, layoutFilters, selectedLayoutFilter, layoutSettings, selectedElementToPreview } =
    useSelector((state) => state.PREVIEW);
  const { previewPublishingFilters } = useSelector((state) => state.PREVIEW);
  const { activeLocales } = useSelector((state) => state);
  const [selectedLayoutElement, setSelectedLayoutElement] = useState(null);
  const { publishingFilters } = useSelector((state) => state);

  const dispatch = useDispatch();
  const classes = useStyles();
  const [moreElements, setMoreElements] = useState();

  const { multipleSelectedLanguage, languageSeparated, fallbackLocale } = useSelector((state) => state.PREVIEW);
  useEffect(() => {
    async function loadElements() {
      const result = await loadCompareWithVersions(selectedElementToPreview.id);
      setMoreElements(result);
      if (result.find((item) => item.versionId === selectedElementToPreview.versionId)) {
        dispatch(
          setSelectedPreviewJOBElement(result.find((item) => item.versionId === selectedElementToPreview.versionId))
        );
      }
    }
    loadElements();
  }, []);

  useEffect(() => {
    dispatch(downloadLayouts());
    dispatch(downloadLayoutFilters());
    dispatch(updateSelectedLocale());
  }, []);

  useEffect(() => {
    if (layouts && selectedLayout)
      setSelectedLayoutElement(layouts.find((layout) => layout.versionId === selectedLayout));
  }, [selectedLayout, layouts]);

  useEffect(() => {
    if (selectedLayout) {
      dispatch(downloadLayoutSettings(selectedLayout));
    }
  }, [selectedLayout]);

  const localeChanged = (state) => {
    dispatch(setMultipleLanguage(state));
  };

  const fallbackLocaleChanged = (state) => {
    dispatch(setFallbackLocale(state.value));
  };

  const updateLanguageSeparated = (state) => {
    dispatch(setLanguageSeparated(state));
  };

  if (!layouts || !activeLocales) {
    return <Loader displayText={false} />;
  }

  const layoutFilterChange = (state) => {
    dispatch(setSelectedLayoutFilter(state.value));
  };

  const layoutChanged = (e) => {
    dispatch(setSelectedLayout(e.target.value));
  };

  const changeElementToPreview = (event) => {
    dispatch(setSelectedPreviewJOBElement(event.target.value));
  };

  const showMoreLayout = () => {
    renderInExceptionRoot(ShowMoreLayouts, {
      selectedLayoutElement: selectedLayoutElement,

      onConfirm: (selectedLayout) => {
        dispatch(addMoreLayouts(selectedLayout));
        dispatch(setSelectedLayout(selectedLayout.versionId));
      }
    });
  };

  return (
    <form
      style={{
        display: 'flex',
        justifyContent: 'stretch',
        flexFlow: 'column nowrap'
        // backgroundColor: "rgb(224, 224, 224, 1)" //"rgb(220, 239, 246)"
      }}
    >
      {moreElements && (
        <FormControl disabled={readOnlyPublihedElement}>
          <InputLabel>
            <FormattedMessage id="Publish.Version" defaultMessage="Select Version to Publish" />
          </InputLabel>
          <Select onChange={changeElementToPreview} value={selectedElementToPreview} autoWidth input={<Input shrink />}>
            {moreElements.map((item, index) => (
              <MenuItem value={item} index={index}>
                {item.name}({item.label})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <MultiSelectField2
        options={activeLocales}
        onStateChanged={localeChanged}
        value={multipleSelectedLanguage}
        valLabel="extendedDisplayName"
        takeValue
        propName="extendedDisplayName"
        propId="value"
        label={
          <FormattedMessage
            id="PreviewWizardDialog.LanguagesPage.Locale"
            defaultMessage="Locales included in publication"
          />
        }
      />

      <SelectField
        values={activeLocales}
        onStateChanged={fallbackLocaleChanged}
        value={fallbackLocale}
        caption={
          <FormattedMessage id="PreviewWizardDialog.LanguagesPage.FallbackLocale" defaultMessage="Fallback Locale" />
        }
        valLabel="extendedDisplayName"
        fieldId="value"
        label="displayName"
      />
      <CheckboxWithMemo
        isChecked={languageSeparated}
        onChange={(isChecked) => updateLanguageSeparated(isChecked)}
        label={
          <FormattedMessage
            id="PreviewWizardDialog.LanguagesPage.LanguageSeparated"
            defaultMessage="Include all languages in the same publication. This requires that the publication contains elements that are language separated."
          />
        }
      />

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="layout">
          <FormattedMessage id="PreviewWizardDialog.Select.Layout" defaultMessage="Layouts" />{' '}
        </InputLabel>
        <Select
          value={selectedLayout}
          onChange={layoutChanged}
          inputProps={{
            name: 'layout',
            id: 'layout'
          }}
          endAdornment={
            selectedLayoutElement ? (
              <InputAdornment position="start">
                <IconButton aria-label="toggle layout details" onClick={showMoreLayout} size="large">
                  <MoreHorizIcon />
                </IconButton>
              </InputAdornment>
            ) : null
          }
        >
          {layouts.map((value) => (
            <MenuItem key={value.versionId} value={value.versionId}>
              <em>
                {value.name} {value.extended ? `(${value.label})` : ''}
              </em>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SelectField
        values={layoutFilters}
        onStateChanged={layoutFilterChange}
        value={selectedLayoutFilter}
        caption={<FormattedMessage id="PreviewWizardDialog.Select.layoutFilters" defaultMessage="Layout Filters" />}
        valLabel="name"
        valVal="uuid"
      />
      {selectedLayout &&
        layoutSettings &&
        layoutSettings[selectedLayout] &&
        layoutSettings[selectedLayout].properties.length > 0 && (
          <LayoutSettingsPaper layoutVersionId={selectedLayout} settings={layoutSettings[selectedLayout].properties} />
        )}
      {publishingFilters && publishingFilters.length > 0 && (
        <PublishingFilterPaper previewPublishingFilters={previewPublishingFilters} />
      )}
    </form>
  );
}
