export default function hasPermit(plugin, permits) {
  return (
    (permits[plugin.readpermit] && permits[plugin.readpermit] === true) ||
    (permits[plugin.editpermit] && permits[plugin.editpermit] === true)
  );
}

export const hasReadPermit = (plugin, permits) => {
  return permits[plugin.readpermit] && permits[plugin.readpermit] === true;
};

export const hasEditPermit = (plugin, permits) => {
  return permits[plugin.editpermit] && permits[plugin.editpermit] === true;
};

export const hasCreatePermit = (plugin, permits) => {
  return permits[plugin.newpermit] && permits[plugin.newpermit] === true;
};
