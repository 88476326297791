import { FormattedMessage } from 'react-intl';
import React from 'react';

const radioButtons = [
  {
    name: 'reports.subnode',
    value: 'node',
    label: <FormattedMessage id="reports.node" defaultMessage="Marked Node" />,
    section: 'scope'
  },

  {
    name: 'reports.subnode',
    value: 'subnodes',
    label: <FormattedMessage id="reports.riskassesment.subnode" defaultMessage="Marked Node and subnodes" />,
    section: 'scope'
  }
];

export default radioButtons;
