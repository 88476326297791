import {handlePost, handleQuery} from "@zert-packages/actions/api";

export const getAllShortcutsForUser = () => {
  return async (dispatch) => {
    try {
      handleQuery('/actionplan-react/getAllShortcutsForUser')
        .then((shortcuts) => dispatch({ type: 'SET_SHORTCUTS', payload: shortcuts })
        );
    } catch (error) {
      console.error('Error fetching shortcuts:', error);
    }
  };
};

export const addShortcut = (newShortcut) => {
    return async (dispatch) => {
    try {
      handlePost('/admin/addShortcut/', JSON.stringify(newShortcut))
        .then((resultShortcut) => dispatch({ type: 'ADD_SHORTCUT', payload: resultShortcut })
        );
    } catch (error) {
      console.error('Error adding shortcut:', error);
    }
  };
};

export const removeShortcut = (shortcutToRemove) => {
    return async (dispatch) => {
    try {
      handlePost('/admin/removeShortcut/', JSON.stringify(shortcutToRemove))
        .then(() => dispatch({ type: 'REMOVE_SHORTCUT', payload: shortcutToRemove })
        );
    } catch (error) {
      console.error('Error removing shortcut:', error);
    }
  };
};

export const editShortcut = (shortcutToEdit) => {
  return async (dispatch) => {
    try {
      handlePost('/admin/addShortcut/', JSON.stringify(shortcutToEdit))
        .then((resultShortcut) => dispatch({ type: 'EDIT_SHORTCUT', payload: resultShortcut })
        );
    } catch (error) {
      console.error('Error adding shortcut:', error);
    }
  };
};

export const changeSortValueOfShortcuts = (shortcuts) => {
  return async (dispatch) => {
    try {
      handlePost('/admin/addShortcuts/', JSON.stringify(shortcuts))
        .then((resultShortcuts) => dispatch({ type: 'EDIT_SHORTCUTS', payload: resultShortcuts })
        );
    } catch (error) {
      console.error('Error adding shortcut:', error);
    }
  };
};

