import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.8rem'
  },
  label: {
    margin: '0',
    '@media (max-width: 769px)': {
      display: 'none'
    }
  },
  select: {
    border: 'none',
    // color: '#FFFFFF',
    backgroundColor: 'white',
    cursor: 'pointer',
    marginBottom: '0',
    fontSize: '1rem'
  },
  option: {
    color: '#0F3943'
  }
});

export default function VersionBox({}) {
  const classes = useStyles();
  const history = useHistory();
  const { versionInfo } = useSelector((state) => state);

  const onChange = (item) => {
    const selectedValue = versionInfo.versions.find((version) => version.versionId == item.target.value);
    if (selectedValue) {
      const link = getPluginByMimeType(selectedValue.mimeType).openpath.replace(':versionId', selectedValue.versionId);
      history.push(link);
    }
  };
  if (!versionInfo) return null;
  return (
    <div className={`${classes.container} HeadBar__MethodInfoContainer`}>
      {/*<label className={classes.label} htmlFor="version-selector">*/}
      {/*  <FormattedMessage id="headbar.versionbox.label" defaultMessage="Version:" />*/}
      {/*</label>*/}
      <Select
        id="version-selector"
        // defaultValue={versionInfo.info.versionId}
        value={versionInfo.info.versionId}
        onChange={onChange}
        className={`${classes.select} animated`}
      >
        {versionInfo.versions.map((item, index) => (
          <MenuItem  className={classes.option} value={item.versionId} key={index}>
            {item.label}
          </MenuItem >
        ))}
      </Select>
    </div>
  );
}
