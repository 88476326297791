import React, { memo } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import ExpansionWrapperCriteria from '../common/ExpansionWrapperCriteria';

const rootStyles = {
  display: 'grid',
  gridGap: '10px',
  gridTemplateColumns: '1fr',
  width: '100%'
};
const rootItem = {
  display: 'grid',
  gridGap: '10px',
  gridTemplateColumns: '0.5fr 1fr 1fr'
};

const DateSearchCriteria = memo(
  ({ foundCriteria, updateCriteria, onChangeExpansionOfCriteria, classNameOfCriteria, isEverythingDisabled }) => {
    const onChangeState = (field, value) => {
      const newValues = getValuesFromFoundCriteria(foundCriteria.values);
      newValues[field] = value;
      updateCriteria(classNameOfCriteria, formatValuesForCriteria(newValues));
    };

    const handleOnChangeExpansion = (event, isExpanded) => {
      onChangeExpansionOfCriteria(isExpanded, classNameOfCriteria);
    };

    let values;
    if (foundCriteria) {
      values = getValuesFromFoundCriteria(foundCriteria.values);
    }
    return (
      <ExpansionWrapperCriteria
        title={<FormattedMessage id="dateCriteriaCounter.date" defaultMessage="Date" />}
        handleOnChangeExpansion={handleOnChangeExpansion}
        expanded={!!foundCriteria}
      >
        {!!foundCriteria && (
          <div style={rootStyles}>
            <div style={rootItem}>
              <FormControlLabel
                disabled={isEverythingDisabled}
                value="in_the_last"
                control={
                  <Radio
                    color="primary"
                    checked={values.Type === 'after'}
                    onChange={(event) => onChangeState('Type', event.target.value)}
                    value="after"
                    name="Type"
                    label={<FormattedMessage id="dateCriteriaCounter.inTheLast" defaultMessage="In the last" />}
                  />
                }
                label={<FormattedMessage id="dateCriteriaCounter.inTheLast" defaultMessage="In the last" />}
              />

              <TextField
                label={<FormattedMessage id="dateCriteriaCounter.numberOf" defaultMessage="Number of" />}
                placeholder={<FormattedMessage id="dateCriteriaCounter.numberOf" defaultMessage="Number of" />}
                disabled={values.Type !== 'after' || isEverythingDisabled}
                value={values.AfterValue}
                onChange={(event) => onChangeState('AfterValue', event.target.value)}
                type="number"
                variant="outlined"
              />

              <FormControl variant="standard">
                <InputLabel>
                  <FormattedMessage id="dateCriteriaCounter.period" defaultMessage="Period" />
                </InputLabel>
                <Select
                  variant="standard"
                  value={values.AfterUnit}
                  disabled={values.Type !== 'after' || isEverythingDisabled}
                  onChange={(event) => onChangeState('AfterUnit', event.target.value)}
                >
                  <MenuItem value="minutes">
                    <FormattedMessage id="dateCriteriaCounter.minutes" defaultMessage="Minutes" />
                  </MenuItem>
                  <MenuItem value="hours">
                    <FormattedMessage id="dateCriteriaCounter.hours" defaultMessage="Hours" />
                  </MenuItem>
                  <MenuItem value="days">
                    <FormattedMessage id="dateCriteriaCounter.days" defaultMessage="Days" />
                  </MenuItem>
                  <MenuItem value="months">
                    <FormattedMessage id="dateCriteriaCounter.months" defaultMessage="Months" />
                  </MenuItem>
                  <MenuItem value="years">
                    <FormattedMessage id="dateCriteriaCounter.years" defaultMessage="Years" />
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            <div style={rootItem}>
              <FormControlLabel
                value="before_last"
                control={
                  <Radio
                    color="primary"
                    checked={values.Type === 'before' || isEverythingDisabled}
                    onChange={(event) => onChangeState('Type', event.target.value)}
                    value="before"
                    name={<FormattedMessage id="dateCriteriaCounter.beforeLast" defaultMessage="Before last" />}
                    label={<FormattedMessage id="dateCriteriaCounter.beforeLast" defaultMessage="Before last" />}
                  />
                }
                label={<FormattedMessage id="dateCriteriaCounter.beforeLast" defaultMessage="Before last" />}
              />

              <TextField
                label={<FormattedMessage id="dateCriteriaCounter.numberOf" defaultMessage="Number of" />}
                placeholder={<FormattedMessage id="dateCriteriaCounter.numberOf" defaultMessage="Number of" />}
                disabled={values.Type !== 'before' || isEverythingDisabled}
                value={values.BeforeValue}
                onChange={(event) => onChangeState('BeforeValue', event.target.value)}
                type="number"
                variant="outlined"
              />

              <FormControl variant="standard" disabled={isEverythingDisabled}>
                <InputLabel>
                  <FormattedMessage id="dateCriteriaCounter.period" defaultMessage="Period" />
                </InputLabel>
                <Select
                  variant="standard"
                  value={values.BeforeUnit}
                  disabled={values.Type !== 'before'}
                  onChange={(event) => onChangeState('BeforeUnit', event.target.value)}
                >
                  <MenuItem value="minutes">
                    <FormattedMessage id="dateCriteriaCounter.minutes" defaultMessage="Minutes" />
                  </MenuItem>
                  <MenuItem value="hours">
                    <FormattedMessage id="dateCriteriaCounter.hours" defaultMessage="Hours" />
                  </MenuItem>
                  <MenuItem value="days">
                    <FormattedMessage id="dateCriteriaCounter.days" defaultMessage="Days" />
                  </MenuItem>
                  <MenuItem value="months">
                    <FormattedMessage id="dateCriteriaCounter.months" defaultMessage="Months" />
                  </MenuItem>
                  <MenuItem value="years">
                    <FormattedMessage id="dateCriteriaCounter.years" defaultMessage="Years" />
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            <div style={rootItem}>
              <FormControlLabel
                disabled={isEverythingDisabled}
                value="between"
                control={
                  <Radio
                    color="primary"
                    checked={values.Type === 'between'}
                    onChange={(event) => onChangeState('Type', event.target.value)}
                    value="between"
                    name="Type"
                    label={<FormattedMessage id="dateCriteriaCounter.between" defaultMessage="Between" />}
                  />
                }
                label={<FormattedMessage id="dateCriteriaCounter.between" defaultMessage="Between" />}
              />

              <DatePicker
                disabled={values.Type !== 'between' || isEverythingDisabled}
                margin="normal"
                id="date-picker-dialog"
                label={<FormattedMessage id="datepicker.from" defaultMessage="From" />}
                invalidDateMessage={<FormattedMessage id="datepicker.invalid" defaultMessage="Invalid date format" />}
                maxDateMessage={
                  <FormattedMessage
                    id="datepicker.maxDateMessage"
                    defaultMessage="Date should not be after maximal date"
                  />
                }
                minDateMessage={
                  <FormattedMessage
                    id="datepicker.minDateMessage"
                    defaultMessage="Date should not be before minimal date"
                  />
                }
                value={values.BetweenDate1}
                onChange={(value) => onChangeState('BetweenDate1', value)}
                renderInput={(props) => <TextField variant="standard" {...props} />}
              />

              <DatePicker
                disabled={values.Type !== 'between' || isEverythingDisabled}
                margin="normal"
                id="date-picker-dialog"
                label={<FormattedMessage id="datepicker.to" defaultMessage="To" />}
                invalidDateMessage={<FormattedMessage id="datepicker.invalid" defaultMessage="Invalid date format" />}
                maxDateMessage={
                  <FormattedMessage
                    id="datepicker.maxDateMessage"
                    defaultMessage="Date should not be after maximal date"
                  />
                }
                minDateMessage={
                  <FormattedMessage
                    id="datepicker.minDateMessage"
                    defaultMessage="Date should not be before minimal date"
                  />
                }
                value={values.BetweenDate2}
                onChange={(value) => onChangeState('BetweenDate2', value)}
                renderInput={(props) => <TextField variant="standard" {...props} />}
              />
            </div>
          </div>
        )}
      </ExpansionWrapperCriteria>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps.foundCriteria, nextProps.foundCriteria)
);

export default DateSearchCriteria;

function formatValuesForCriteria(values) {
  const newValues = [
    {
      key: 'Status',
      value: values.Status
    }
  ];

  if (values.Type === 'after') {
    newValues.push({
      key: 'Type',
      value: values.Type
    });
    newValues.push({
      key: 'AfterUnit',
      value: values.AfterUnit
    });
    newValues.push({
      key: 'AfterValue',
      value: values.AfterValue
    });
  }

  if (values.Type === 'before') {
    newValues.push({
      key: 'Type',
      value: values.Type
    });
    newValues.push({
      key: 'BeforeUnit',
      value: values.BeforeUnit
    });
    newValues.push({
      key: 'BeforeValue',
      value: values.BeforeValue
    });
  }

  if (values.Type === 'between') {
    newValues.push({
      key: 'Type',
      value: values.Type
    });
    newValues.push({
      key: 'BetweenDate1',
      value: values.BetweenDate1
    });
    newValues.push({
      key: 'BetweenDate2',
      value: values.BetweenDate2
    });
  }

  return newValues;
}

function getValuesFromFoundCriteria(values) {
  const state = {
    Status: 'changed',
    Type: 'before',
    AfterUnit: 'days',
    BeforeUnit: 'days',
    AfterValue: 1,
    BeforeValue: 1,
    BetweenDate1: new Date(),
    BetweenDate2: new Date()
  };
  values.forEach((item) => {
    state[item.key] = item.value;
  });
  return state;
}
