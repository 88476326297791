import React, { useState, useRef } from 'react';
import ActionableTable from '@zert-packages/components/shared/ActionableTable/ActionTable.new';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import { v4 as uuidv4 } from 'uuid';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import ConfirmationDialog from '@zert-packages/components/common/dialogs/ConfirmationDialog';
import ElementSearchingDialog from '@zert-packages/components/ElementSearchingDialog';
import dialogCustom from '@zert-packages/components/common/dialogCustom';

const tableColumns = [
  {
    identifier: 'name',
    label: <FormattedMessage id="ElementSearchTable.Name" defaultMessage="Name" />
  },
  {
    identifier: 'label',
    label: <FormattedMessage id="ElementSearchTable.Label" defaultMessage="Label" />
  }
];

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '10px',
    height: '100%',
    width: '100%'
  },
  dependsContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'auto',
    '& > *': {
      marginTop: 10
    }
  }
});

const defaultSelected = null;

function ElementSearchTable({ list, setList }) {
  const cl = useStyles();
  const rootRef = useRef();

  const [selected, setSelected] = useState(defaultSelected);

  const updateValues = (valueCallback) =>
    setList((prev, stateAction) => {
      prev = {
        ...prev,
        InElementVersions: typeof valueCallback === 'function' ? valueCallback(prev.InElementVersions) : valueCallback
      };
      if (stateAction) {
        stateAction(prev);
      }
      return prev;
    });

  const addElement = (filter = null) =>
    dialogCustom(ElementSearchingDialog, {
      onConfirm: (element) => {
        delete element.properties;
        const state = {
          element,
          name: element.name,
          uuid: uuidv4()
        };
        updateValues((p) => [
          ...p,
          {
            ...element
          }
        ]);
        setSelected(selected + 1);
      }
    });

  const removeValue = () =>
    renderInExceptionRoot(ConfirmationDialog, {
      onConfirm: () => {
        updateValues((p) => {
          const newP = [...p];
          newP.splice(selected, 1);
          return newP;
        });
        // setSelectedMeta(defaultSelectedMeta);
      }
    });

  const actions = [
    {
      icon: AddIcon,
      onClick: addElement,
      disabled: false
    },

    {
      icon: DeleteIcon,
      onClick: removeValue,
      disabled: false
    }
  ];

  const getRows = () => {
    if (!list) {
      return [];
    }
    return (
      list &&
      list.map((element, index) => {
        return {
          id: index,
          name: element.name,
          label: element.label
        };
      })
    );
  };

  return (
    <div className={cl.root} ref={rootRef}>
      <ActionableTable
        isTextButtons={false}
        actions={actions}
        columns={tableColumns}
        rows={getRows()}
        selectedRowId={selected}
        setSelectedRowId={setSelected}
      />
    </div>
  );
}

export default ElementSearchTable;
