import React, { Fragment } from 'react';
import './style.css';
import DeadlineEditor from './DeadlineEditor';

export default function DeadlineList ({deadlinemappings, task=null,
                                        disabled=false, onModification =null}) {
    const deadlines = deadlinemappings.map((deadline) => {
      return <DeadlineEditor
        deadline={deadline}
        key={deadline.identifier}
        task={task}
        disabled={disabled}
        onModification={onModification}
      />;
    });
    return <>{deadlines}</>;
}
