import { useEffect, useState } from 'react';

export default function useTableFilter(initialRows, columns, getCustomFilter) {
  const [filterValue, setFilterValue] = useState('');
  const [filteredRows, setFilteredRows] = useState(initialRows);
  const [localFilterValue, setLocalFilterValue] = useState('');

  function updateFilterValue(newFilterValue) {
    setLocalFilterValue(newFilterValue);
  }

  useEffect(() => {
    const filterValueIsNotEmpty = localFilterValue.trim().length !== 0;
    if (filterValueIsNotEmpty) {
      const identifiers = getIdentifiers(columns);
      const newFilteredRows = initialRows.filter((row) => {
        let isIncludedValue = false;
        identifiers.forEach((identifier) => {
          const valueOfItem =
            getCustomFilter && getCustomFilter(row, identifier) ? getCustomFilter(row, identifier) : row[identifier];
          if (valueOfItem === null) return;
          if (isIncludedValue) return;
          if (valueOfItem === undefined) return;
          isIncludedValue = valueOfItem.toString().toLowerCase().includes(localFilterValue.toString().toLowerCase());
        });
        return isIncludedValue;
      });
      setFilteredRows(newFilteredRows);
    } else {
      setFilteredRows(initialRows);
    }
    setFilterValue(localFilterValue);
  }, [localFilterValue, initialRows, columns]);

  return {
    filterValue,
    filteredRows,
    updateFilterValue
  };
}

function getIdentifiers(columns) {
  const keys = [];
  columns?.forEach((column) => {
    if (column.isFilterable) {
      keys.push(column.identifier);
    }
  });
  return keys;
}
