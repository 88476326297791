import axios from 'axios';
import { useSnackbar } from "notistack";
import { errorSnackBar } from "./SnackBarsNotistack";
const axiosInstance = axios.create();



axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
      return Promise.reject((error.response && error.response.data) || "Something went wrong");



    if (error?.response?.status === 401) {
      localStorage.removeItem('accessToken')
      delete axios.defaults.headers.common.Authorization
         Promise.reject((error.response && error.response.data) || "Authentication required");
      // return window.location.href ='/login'
    }


    if (error?.response?.status === 501) {
        return Promise.reject((error.response && error.response.data) || "Something went wrong");
    }
    if (error?.response?.data?.message) {
       errorSnackBar(error?.response?.data?.message)
    } else if (typeof error?.response?.data === 'string') {
        errorSnackBar(error?.response?.data)
    }
    return errorSnackBar("Something went wrong")

    //  return Promise.reject((error.response && error.response.data) || "Something went wrong");
    // throw error;
   //  return  error


  }
);

export default axiosInstance;
