import { makeStyles } from "@mui/styles";

const useStyles  = makeStyles((theme) => ({
  title: {
    fontWeight: '700',
    marginBottom: 5
  },
  loyaltyBlock: {
    height: 75,
    width: 75,
    border: '2px solid #000000',
    position: 'relative',
    overflow: 'hidden'
  },
  borderYLeft: {
    background: '#BFBFBF',
    position: 'absolute',
    height: 10,
    width: 2,
    left: 7
  },
  borderYRight: {
    background: '#BFBFBF',
    position: 'absolute',
    height: 10,
    width: 2,
    right: 7
  },
  borderXLeft: {
    background: '#BFBFBF',
    position: 'absolute',
    height: 2,
    width: 8,
    top: 10
  },
  borderXRight: {
    background: '#BFBFBF',
    position: 'absolute',
    height: 2,
    width: 8,
    top: 10,
    right: 0
  },
  borderBottomYLeft: {
    background: '#BFBFBF',
    position: 'absolute',
    height: 10,
    width: 2,
    left: 7,
    bottom: 0
  },
  borderBottomYRight: {
    background: '#BFBFBF',
    position: 'absolute',
    height: 2,
    width: 5.9,
    bottom: 10
  },
  borderBottomXLeft: {
    background: '#BFBFBF',
    position: 'absolute',
    height: 10,
    width: 2,
    right: 7,
    bottom: 0
  },
  borderBottomXRight: {
    background: '#BFBFBF',
    position: 'absolute',
    height: 2,
    width: 7,
    bottom: 10,
    right: 0
  },
  line: {
    position: 'absolute',
    left: 10,
    width: 51,
    top: 10
  },
  lineY: {
    position: 'absolute',
    height: 47,
    width: 2,
    top: 12,
    left: 7
  },
  lineYRight: {
    position: 'absolute',
    top: 13,
    width: 2,
    height: 46,
    right: 7
  },
  lineBottom: {
    position: 'absolute',
    left: 10,
    width: 51,
    height: 2,
    bottom: 10
  },
  activeLine: {
    position: 'absolute',
    top: 6,
    left: 2,
    width: 65,
    height: 10
  },
  activeYLine: {
    position: 'absolute',
    top: 3,
    left: 3,
    width: 10,
    height: 65
  },
  activeYLineRight: {
    position: 'absolute',
    top: 3,
    right: 3,
    width: 10,
    height: 65
  },

  activeLineBottom: {
    position: 'absolute',
    bottom: 6,
    left: 2,
    width: 65,
    height: 10
  },
  activeLineCenterX: {
    position: 'absolute',
    bottom: 30,
    left: 2,
    width: 65,
    height: 10,
    zIndex: 1
  },
  activeLineCenterY: {
    position: 'absolute',
    top: 3,
    right: 32,
    width: 10,
    height: 65,
    zIndex: 1
  },
  loyaltyBlockCenter: {
    background: '#808080',
    position: 'absolute',
    height: 30,
    width: 30,
    left: 19,
    top: 21
  },
  loyaltyBlockCenterY: {
    position: 'absolute',
    height: 47,
    width: 2,
    left: 14,
    bottom: -8
  },
  loyaltyBlockCenterX: {
    position: 'absolute',
    height: 2,
    width: 53,
    top: 14,
    left: -10
  },
  borderBlock: {
    padding: '10px',
    border: '1px solid #C6C6C5',
    display: 'grid',
    gridRowGap: 10
  },
  wrapperLoyaltyBlock: {
    display: 'grid',
    gridTemplateColumns: '130px 75px 1fr',
    gridColumnGap: 10,
    marginTop: 10
  },
  wrapperStyle: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
    gridColumnGap: 10
  },
  wrapperBorderStyle: {
    display: 'grid',
    gridRowGap: 10
  }
}));

export default  useStyles