const enum Portal {
  RM = 'RM',
  CLM = 'CLM',
  MyNorden = 'MyNorden',
  Modig = 'Modig',
  LogMax = 'LogMax'
}

export const isEqual = (srcPortal: string, trgPortal: string) => {
  return srcPortal.toLowerCase() === trgPortal.toLowerCase();
};

export default Portal;
