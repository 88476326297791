import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function CancelConfirmDialog({ onCancel, handleClose }) {
  const handleBack = () => handleClose();
  const handleCancel = () => {
    onCancel();
    handleClose();
  };
  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>
        <FormattedMessage id="CancelConfirmDialog.title" defaultMessage="Cancel dialog" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage
            id="CancelConfirmDialog.description"
            defaultMessage="Are you sure that you want to cancel?"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleBack} color="primary">
          <FormattedMessage id="CancelConfirmDialog.buttonBack" defaultMessage="Back" />
        </Button>
        <Button onClick={handleCancel} color="primary" variant="outlined" autoFocus>
          <FormattedMessage id="CancelConfirmDialog.buttonCancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelConfirmDialog;
