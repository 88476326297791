import React from 'react';
import { AccordionSummary, AccordionDetails, Accordion, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  }
}));

function ExpansionWrapperCriteria({ children, expanded, handleOnChangeExpansion, title }) {
  const classes = useStyles();
  return (
    <Accordion expanded={expanded} onChange={handleOnChangeExpansion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

export default ExpansionWrapperCriteria;
