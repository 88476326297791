import React, { useContext, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { TabsContext } from '../../Stylesheet';
import { adobeWord, handleTableSingleLevel } from '../../utils/helpers';
import TemplateItemRow from '../../TemplateItemRow';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1rem',
    fontWeight: '700',
    marginBottom: 5
  },
  borderBlock: {
    border: '1px solid #C6C6C5',
    padding: '5px 10px',
    display: 'grid',
    gridRowGap: 10
  },
  borderBlockRow: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gridColumnGap: 10
  }
}));

function InlineImages() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const image = values.stylesheet.imageSettings[values.selectedLeftMenu];
  const [selectedContentLimits, setSelectedContentLimits] = useState('User defined');
  const [inline, setInline] = useState({
    inlineWidth: 'User defined',
    inlineHeight: 'User defined'
  });
  const findKeepRatio = (str) => {
    if (str === 'False') {
      return 'No';
    }
    if (str === 'True') {
      return 'Yes';
    }
    return str;
  };

  const handleExpanderItem = (val, name) => {
    handleTableSingleLevel(val, name, 'imageSettings', values, setValues);
  };

  const handleDropMenu = (valDropMenu, name, val) => {
    const result = { unit: valDropMenu, value: +val };
    handleTableSingleLevel(result, name, 'imageSettings', values, setValues);
  };

  const handleValues = (val, name) => {
    const result = { ...image[name], unit: image[name] ? image[name].unit : 'pt', value: +val };
    handleTableSingleLevel(result, name, 'imageSettings', values, setValues);
  };
  const setDropMenuFirst = (val, name) => {
    if (val === 'Default') {
      const result = { unit: 'pt', value: 12 };
      handleTableSingleLevel(result, name, 'imageSettings', values, setValues);
    }
    setSelectedContentLimits(val);
  };

  const setDropMenuFirstInline = (val, name) => {
    if (val === 'Default') {
      const result = { unit: 'pt', value: 12 };
      handleTableSingleLevel(result, name, 'imageSettings', values, setValues);
    }
    setInline((prev) => ({ ...prev, [name]: val }));
  };

  const findInline = () => {
    if (!image.inlineWidth && !image.inlineHeight) {
      return setInline({ inlineWidth: 'Default', inlineHeight: 'Default' });
    }
    if (!image.inlineWidth) {
      return setInline((pre) => ({ ...pre, inlineWidth: 'Default' }));
    }
    if (!image.inlineHeight) {
      setInline((pre) => ({ ...pre, inlineHeight: 'Default' }));
    }
  };

  useEffect(() => {
    findInline();
  }, [image]);

  return (
    <div>
      <p className={cl.title}>InlineImages</p>
      <div className={cl.borderBlock}>
        <TemplateItemRow
          label="Width:"
          type="inlineWidth"
          value={{
            value: image.inlineWidth ? image.inlineWidth.value : '12',
            unit: image.inlineWidth ? image.inlineWidth.unit : 'POINTS'
          }}
          dropMenuFirst={{
            label: ['Default', 'User defined'],
            selected: inline.inlineWidth
          }}
          grid="100px 150px 1fr 55px"
          setDropMenuFirst={setDropMenuFirstInline}
          setValueProps={handleValues}
          setDropMenu={handleDropMenu}
          disabled={inline.inlineWidth === 'Default'}
        />

        <TemplateItemRow
          label="Height:"
          type="inlineHeight"
          value={{
            value: image.inlineHeight ? image.inlineHeight.value : '12',
            unit: image.inlineHeight ? image.inlineHeight.unit : 'POINTS'
          }}
          dropMenuFirst={{
            label: ['Default', 'User defined'],
            selected: inline.inlineHeight
          }}
          grid="100px 150px 1fr 55px"
          setDropMenuFirst={setDropMenuFirstInline}
          setValueProps={handleValues}
          setDropMenu={handleDropMenu}
          disabled={inline.inlineHeight === 'Default'}
        />
        <div className={cl.borderBlockRow}>
          <p className={cl.title}>Keep ratio:</p>
          <ExpanderItem
            valueProps={findKeepRatio(adobeWord(image.inlineKeepRatio, true))}
            propsLabel={['None', 'Yes', 'Default']}
            setProps={handleExpanderItem}
            type="inlineKeepRatio"
          />
        </div>
        <div className={cl.borderBlockRow}>
          <p className={cl.title}>Vertical align:</p>
          <ExpanderItem
            valueProps={adobeWord(image.inlineVerticalAlign, true)}
            propsLabel={['Default', 'Top', 'Middle', 'Bottom', 'Relative']}
            setProps={handleExpanderItem}
            type="inlineVerticalAlign"
          />
        </div>
        <TemplateItemRow
          label={' '}
          type="inlineVerticalAlignDistance"
          value={{
            value: image.inlineVerticalAlignDistance ? image.inlineVerticalAlignDistance.value : '12',
            unit: image.inlineVerticalAlignDistance ? image.inlineVerticalAlignDistance.unit : 'POINTS'
          }}
          dropMenuFirst={{
            label: ['Default', 'User defined'],
            selected: selectedContentLimits
          }}
          grid="100px 150px 1fr 55px"
          setDropMenuFirst={setDropMenuFirst}
          setValueProps={handleValues}
          setDropMenu={handleDropMenu}
          // disabled={(findInlineVertical()) || (selectedContentLimits === 'Default')}
          disabled={selectedContentLimits === 'Default'}
        />
      </div>
    </div>
  );
}

export default InlineImages;
