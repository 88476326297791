import SelectField from '@zert-packages/components/shared/FormElements/SelectField';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import MultiSelectField2 from '@zert-packages/components/shared/FormElements/MultiSelectField2';
import CheckboxWithMemo from '@zert-packages/components/common/CheckboxWithMemo';
import { setFallbackLocale, setLanguageSeparated, setMultipleLanguage, setSelectedLanguage } from '../previewReducers';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190
  },

  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(1) / 4
  },
  fontWeightRegular: {
    fontWeight: theme.typography.fontWeightRegular
  },
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium
  }
}));

export function LanguagesPage() {
  const { activeLocales } = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  const { multipleSelectedLanguage, languageSeparated, fallbackLocale } = useSelector((state) => state.PREVIEW);

  const localeChanged = (state) => {
    dispatch(setMultipleLanguage(state));
  };

  const fallbackLocaleChanged = (state) => {
    dispatch(setFallbackLocale(state.value));
  };

  const updateLanguageSeparated = (state) => {
    dispatch(setLanguageSeparated(state));
  };

  return (
    <form
      style={{
        display: 'flex',
        justifyContent: 'stretch',
        flexFlow: 'column nowrap'
        // backgroundColor: "rgb(224, 224, 224, 1)" //"rgb(220, 239, 246)"
      }}
    >
      <MultiSelectField2
        options={activeLocales}
        onStateChanged={localeChanged}
        value={multipleSelectedLanguage}
        valLabel="extendedDisplayName"
        takeValue
        propName="extendedDisplayName"
        propId="value"
        label={
          <FormattedMessage
            id="PreviewWizardDialog.LanguagesPage.Locale"
            defaultMessage="Locales included in publication"
          />
        }
      />

      <SelectField
        values={activeLocales}
        onStateChanged={fallbackLocaleChanged}
        value={fallbackLocale}
        caption={
          <FormattedMessage id="PreviewWizardDialog.LanguagesPage.FallbackLocale" defaultMessage="Fallback Locale" />
        }
        valLabel="extendedDisplayName"
        fieldId="value"
        label="displayName"
      />
      <CheckboxWithMemo
        isChecked={languageSeparated}
        onChange={(isChecked) => updateLanguageSeparated(isChecked)}
        label={
          <FormattedMessage
            id="PreviewWizardDialog.LanguagesPage.LanguageSeparated"
            defaultMessage="Include all languages in the same publication. This requires that the publication contains elements that are language separated."
          />
        }
      />
    </form>
  );
}
