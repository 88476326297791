import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { handleTableParents } from '../../utils/helpers';
import { TabsContext } from '../../Stylesheet';
import { fontData } from '../../data/fontData';
import TemplateItemRow from '../../TemplateItemRow';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700
  },
  borderBlock: {
    border: '1px solid #C6C6C5',
    padding: 10,
    display: 'grid',
    gridRowGap: 10
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gridColumnGap: 10
  },
  wrapperCheckbox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr ',
    maxWidth: 500
  }
}));

function Font({ table, type = 'headerRow', disabled, typeParents = 'tableSettings', setValueProps }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [selectedContentLimits, setSelectedContentLimits] = useState('User defined');

  const findCheckbox = (el) => {
    return table.fontStyle.map((it) => it.toLowerCase()).find((it) => it === el.toLowerCase());
  };

  const handleCheckboxFontStyle = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    if (val) {
      const result = [...table.fontStyle, name.toUpperCase()];
      if (setValueProps) {
        return setValueProps(result, 'fontStyle');
      }
      return handleTableParents(result, 'fontStyle', typeParents, type, values, setValues);
    }
    const result = table.fontStyle.filter((el) => el !== name.toUpperCase());
    if (setValueProps) {
      return setValueProps(result, 'fontStyle');
    }
    handleTableParents(result, 'fontStyle', typeParents, type, values, setValues);
  };

  const setDropMenuFirst = (val, name) => {
    if (val === 'Default') {
      const result = { unit: 'pt', value: 12 };
      if (setValueProps) {
        setValueProps(result, name);
      } else {
        handleTableParents(result, name, typeParents, type, values, setValues);
      }
    }
    setSelectedContentLimits(val);
  };
  const handleSelectedDropMenu = (valDropMenu, name, val) => {
    const result = { unit: valDropMenu, value: +val };
    if (setValueProps) {
      return setValueProps(result, name);
    }
    handleTableParents(result, name, typeParents, type, values, setValues);
  };

  const handleValuesBlock = (val, name) => {
    const result = { ...table.fontSize, unit: table.fontSize ? table.fontSize.unit : 'pt', value: +val };
    if (setValueProps) {
      return setValueProps(result, name);
    }
    handleTableParents(result, name, typeParents, type, values, setValues);
  };

  useEffect(() => {
    if (!table.fontSize) {
      setSelectedContentLimits('Default');
    }
  }, [table]);

  return (
    <div>
      <span className={cl.title}>Font</span>
      <div className={cl.borderBlock}>
        <div className={cl.wrapperRow}>
          <span className={cl.title}> Font family:</span>
          <ExpanderItem valueProps={table.fontFamily} type="type" disabled={disabled} />
        </div>
        <div>
          <TemplateItemRow
            label="Font size:"
            type="fontSize"
            value={{
              value: table.fontSize ? table.fontSize.value : '12',
              unit: table.fontSize ? table.fontSize.unit : 'POINTS'
            }}
            grid="100px 150px 1fr 55px"
            dropMenuFirst={{
              label: ['Default', 'User defined'],
              selected: selectedContentLimits
            }}
            setDropMenu={handleSelectedDropMenu}
            setValueProps={handleValuesBlock}
            setDropMenuFirst={setDropMenuFirst}
            disabled={selectedContentLimits === 'Default' || disabled}
            disableMenuFirst={disabled}
          />
        </div>

        <div className={cl.wrapperRow}>
          <span className={cl.title}>Font style:</span>
          <div className={cl.wrapperCheckbox}>
            {fontData.map((el, i) => (
              <div key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxFontStyle}
                      checked={findCheckbox(el.name)}
                      name={el.name}
                      disabled={disabled}
                    />
                  }
                  label={<span>{el.name}</span>}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Font;
