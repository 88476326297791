import React from "react";
import ElementTileTable, { columns } from "@zert-packages/components/ElementTile/ElementTileTable";

/*
* Hacky solution for avoiding typescript error when using ElementTileTable
* directly in TabMeasures. Better solution would be to try to fix the cause of
* the errors.
* */
export function MeasureTable({measures, loading}) {

  return (
    <ElementTileTable
      elements={measures}
      columnsNew={columns}
      loading={loading}
      bradCrumbComponent={null}
      customFilter={null}
      showCut={false}
      showElementActions={false}
      doNotShowSelect
      doNotShowToolbar
      orderBy="date"
      order="asc"
    />
  )
}