import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { API_ROOT_WAR } from '@zert-packages/actions/api';
import CatalogExplorerPage from './CatalogExplorerPage';

export default function CatalogExplorerRoutes() {
  const catalog = useSelector((state) => state.catalog);
  if (!catalog) return null;
  return catalog.map((_catalog, index) => (
    <Route exact path={`${API_ROOT_WAR}/catalog${index}`}>
      <CatalogExplorerPage catalogIndex={index} />
    </Route>
  ));
}
