export const topicData = {
  class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientTopicBlockSettingsTemplate',
  identifier: 'Topic',
  label: 'Topic',
  spaceBefore: {
    name: 'before',
    conditionality: 'DISCARD',
    value: {
      value: 5,
      unit: 'POINTS'
    },
    minimum: {
      value: 4.767,
      unit: 'MILLIMETER'
    },
    maximum: {
      value: 5.959,
      unit: 'MILLIMETER'
    },
    precedence: 'P2'
  },
  spaceAfter: {
    name: 'after',
    conditionality: 'DISCARD',
    value: {
      value: 7,
      unit: 'POINTS'
    },
    minimum: {
      value: 4.767,
      unit: 'MILLIMETER'
    },
    maximum: {
      value: 5.959,
      unit: 'MILLIMETER'
    },
    precedence: 'P2'
  },
  marginLeft: {
    value: 0,
    unit: 'MILLIMETER'
  },
  marginRight: {
    value: 0,
    unit: 'MILLIMETER'
  },
  separatedBlock: false
};
