import { FormattedMessage } from "react-intl";
import React from "react";

const defaultIncludeLabel = <FormattedMessage id="reports.safetyinspection.includeMode" defaultMessage="Include" />;

export function getSelectors(includeLabel = defaultIncludeLabel) {
  return [
    {
      name: "si_includeMode",
      label: includeLabel,
      section: "scope",
      options: [
        {
          label: <FormattedMessage id="reports.safetyinspection.includeMode.planned" defaultMessage="Planned" />,
          value: "PLANNED"
        },
        {
          label: <FormattedMessage id="reports.safetyinspection.includeMode.performed" defaultMessage="Performed" />,
          value: "PERFORMED"
        },
        {
          label: <FormattedMessage id="reports.safetyinspection.includeMode.both"
                                   defaultMessage="Planned & performed" />,
          value: "PLANNED_AND_PERFORMED"
        }
      ]
    }, {
      name: "si_sortColumn",
      label: <FormattedMessage id="reports.safetyinspection.sortCol" defaultMessage="Sort by" />,
      section: "order",
      options: [
        {
          label: <FormattedMessage id="reports.safetyinspection.sortCol.name" defaultMessage="Name" />,
          value: "NAME"
        },
        {
          label: <FormattedMessage id="reports.safetyinspection.sortCol.responsible" defaultMessage="Responsible" />,
          value: "RESPONSIBLE"
        },
        {
          label: <FormattedMessage id="reports.safetyinspection.sortCol.planned" defaultMessage="Planned" />,
          value: "PLANNED"
        },
        {
          label: <FormattedMessage id="reports.safetyinspection.sortCol.performed" defaultMessage="Performed" />,
          value: "PERFORMED"
        },
        {
          label: <FormattedMessage id="reports.safetyinspection.sortCol.placement" defaultMessage="Placement" />,
          value: "PLACEMENT"
        },
        {
          label: <FormattedMessage id="reports.safetyinspection.sortCol.id" defaultMessage="Id" />,
          value: "ID"
        }
      ]
    }
  ];
}

export function getCheckboxes() {
  return [
    {
      name: "cb_status_diagram",
      label: <FormattedMessage id="reports.safetyinspection.statusDiagram" defaultMessage="Status diagram" />,
      checked: true
    },
    {
      name: "cb_status_table",
      label: <FormattedMessage id="reports.safetyinspection.statusTable" defaultMessage="Status table" />,
      checked: true
    },
    {
      name: "cb_unmet_categories",
      label: <FormattedMessage id="reports.safetyinspection.unmetDiagram"
                               defaultMessage="Diagram over unmet categories" />,
      checked: true
    },
    {
      name: "cb_identified_measures",
      label: <FormattedMessage id="reports.safetyinspection.idMeasures" defaultMessage="Identified measures" />,
      checked: true
    }
  ];
}