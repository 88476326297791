import tinycolor from "tinycolor2";

export const findFormat = (width, height) => {
  const w = (+width).toFixed(0);
  const h = (+height).toFixed(0);

  if (+w === 297 && +h === 420) {
    return "A3";
  }
  if (+w === 210 && +h === 297) {
    return "A4";
  }
  if (+w === 148 && +h === 210) {
    return "A5";
  }
  if (+w === 216 && +h === 279) {
    return "LETTER";
  }
  if (+w === 216 && +h === 356) {
    return "LEGAL";
  }
  return "USER";
};

export const findOrientation = (width, height) => {
  const w = (+width).toFixed(1);
  const h = (+height).toFixed(1);
  if (w > h) {
    return "Landscape";
  }
  return "Portrait";
};

export const gridBlock = (expander) => {
  if (expander === 1) {
    return "7px 1px 1fr";
  }
  if (expander === 2) {
    return "250px 1px 5fr";
  }
  if (expander === 3) {
    return "1fr 1px";
  }
};

export const adobeWord = (word, oneWord) => {
  if (!word) {
    return;
  }
  if (oneWord) {
    return word.slice(0, 1) + word.slice(1).toLocaleLowerCase().replace("_", " ");
  }
  return word.slice(0, 1).toLocaleLowerCase() + word.slice(1).toLocaleLowerCase().replace("_", " ");
};

export const adobeDropMenu = (menu) => {
  if (menu === "CENTIMETER") {
    return "cm";
  }
  if (menu === "POINTS") {
    return "pt";
  }
  if (menu === "PIXELS" || menu === "PIXEL") {
    return "px";
  }
  if (menu === "MILLIMETER" || menu === "PERCENT" || menu === "Ems") {
    return "mm";
  }
  return menu.toLocaleLowerCase();
};

export const adobeFont = (font) => {
  if (font) {
    return font.split("-").pop();
  }
};

export const adobeDropMenuUpperCase = (menu) => {
  if (menu === "pt") {
    return "POINTS";
  }
  if (menu === "px" || menu === "PIXEL") {
    return "PIXEL";
  }
  if (menu === "mm") {
    return "MILLIMETER";
  }
  return menu.toUpperCase();
};

export const handleStylesheet = (name, value, setValues) => {
  setValues((prev) => ({
    ...prev,
    [name]: value
  }));
};

export const handleStylesheetParent = (val, name,  setValues) => {
  setValues(pre => ({
    ...pre, stylesheet: {
      ...pre.stylesheet,
      [name]: val
    }
  }));
}

export const handlePageStyle = (orientation, format, values, setValues) => {
  const resultType = values.stylesheet.blockSettings.reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      return [
        ...acu,
        {
          ...cur,
          orientation,
          format
        }
      ];
    }
    return [...acu, cur];
  }, []);
  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      blockSettings: [...resultType]
    }
  };
  setValues(result);
};

export const handlePageLayout = (val, name, values, setValues, valDropMenu, dropMenuName) => {
  const handleActiveType = values.stylesheet.blockSettings.reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      if (valDropMenu) {
        if (dropMenuName) {
          return [...acu, { ...cur, [name]: { ...cur[name], value: val, [dropMenuName]: valDropMenu } }];
        }
        return [...acu, { ...cur, [name]: { ...cur[name], value: val, unit: valDropMenu } }];
      }
      return [...acu, { ...cur, [name]: { ...cur[name], value: val } }];
    }
    return [...acu, cur];
  }, []);

  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      blockSettings: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handlePageLayoutDropMenu = (val, name, values, setValues, nameObject, valDropMenu) => {
  const handleActiveType = values.stylesheet.blockSettings.reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      if (valDropMenu) {
        return [
          ...acu,
          {
            ...cur,
            [name]: {
              ...cur[name],
              [nameObject]: {
                ...cur[name][nameObject],
                value: val,
                unit: valDropMenu
              }
            }
          }
        ];
      }
      return [
        ...acu,
        {
          ...cur,
          [name]: {
            ...cur[name],
            [nameObject]: {
              ...cur[name][nameObject],
              value: val
            }
          }
        }
      ];
    }
    return [...acu, cur];
  }, []);

  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      blockSettings: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handleTopicDropMenu = (val, name, values, setValues, dropMenuName) => {
  const handleActiveType = values.stylesheet.blockSettings.reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      return [...acu, { ...cur, [name]: { ...cur[name], [dropMenuName]: val } }];
    }
    return [...acu, cur];
  }, []);

  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      blockSettings: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handlePageLayoutSingleLevel = (val, name, values, setValues) => {
  const handleActiveType = values.stylesheet.blockSettings.reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      return [...acu, { ...cur, [name]: val }];
    }
    return [...acu, cur];
  }, []);

  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      blockSettings: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handleAddFormat = (val, name, values, setValues) => {
  const handleActiveType = values.stylesheet.blockSettings.reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      return [...acu, { ...cur, [name]: [val, ...cur[name]] }];
    }
    return [...acu, cur];
  }, []);
  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      blockSettings: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handleColum = (val, index, indexFormat, name, values, setValues, type) => {
  const findColum = values.stylesheet.blockSettings.reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      const findColum = cur.paragraphFormats.reduce((acuColum, curColum, idxColum) => {
        if (idxColum === indexFormat) {
          const activeColum = curColum.columns.reduce((acuActiveColum, curActiveColum, idxActiveColum) => {
            if (idxActiveColum === index) {
              if (type && type === "value") {
                return [
                  ...acuActiveColum,
                  {
                    ...curActiveColum,
                    [name]: { ...curActiveColum[name], value: val }
                  }
                ];
              }
              return [...acuActiveColum, { ...curActiveColum, [name]: val }];
            }
            return [...acuActiveColum, curActiveColum];
          }, []);
          return [...acuColum, { ...curColum, columns: activeColum }];
        }
        return [...acuColum, curColum];
      }, []);
      return [...acu, { ...cur, paragraphFormats: findColum }];
    }
    return [...acu, cur];
  }, []);

  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      blockSettings: [...findColum]
    }
  };
  setValues(result);
};

export const handleTypeBlock = (val, values, setValues) => {
  const findColum = values.stylesheet.blockSettings.reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      return [...acu, { ...val, label: cur.label }];
    }
    return [...acu, cur];
  }, []);

  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      blockSettings: [...findColum]
    }
  };
  setValues(result);
};

export const handleColumType = (val, indexFormat, values, setValues, deleteColum) => {
  const findColum = values.stylesheet.blockSettings.reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      const findColum = cur.paragraphFormats.reduce((acuColum, curColum, idxColum) => {
        if (idxColum === indexFormat) {
          if (deleteColum) {
            return [...acuColum, { ...curColum, columns: val }];
          }
          return [
            ...acuColum,
            {
              ...curColum,
              columns: [val, ...curColum.columns.filter((el) => !el.disable)]
            }
          ];
        }
        return [...acuColum, curColum];
      }, []);
      return [...acu, { ...cur, paragraphFormats: findColum }];
    }
    return [...acu, cur];
  }, []);

  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      blockSettings: [...findColum]
    }
  };
  setValues(result);
};

export const handlePageLayoutChildObject = (val, name, nameChild, values, setValues, ObjectChild, valDropMenu) => {
  const handleActiveType = values.stylesheet.blockSettings.reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      if (ObjectChild) {
        if (valDropMenu) {
          return [
            ...acu,
            {
              ...cur,
              [nameChild]: {
                ...cur[nameChild],
                [name]: { ...cur[nameChild][name], value: val, unit: valDropMenu }
              }
            }
          ];
        }
        return [
          ...acu,
          {
            ...cur,
            [nameChild]: {
              ...cur[nameChild],
              [name]: { ...cur[nameChild][name], value: val }
            }
          }
        ];
      }
      return [
        ...acu,
        {
          ...cur,
          [nameChild]: {
            ...cur[nameChild],
            [name]: val
          }
        }
      ];
    }
    return [...acu, cur];
  }, []);

  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      blockSettings: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handleHeadingObject = (val, name, objectName, values, setValues, type) => {
  const handleActiveType = values.stylesheet.blockSettings.reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      if (type && type === "value") {
        return [
          ...acu,
          {
            ...cur,
            [objectName]: {
              ...cur[objectName],
              [name]: { ...cur[objectName][name], value: val }
            }
          }
        ];
      }
      return [
        ...acu,
        {
          ...cur,
          [objectName]: {
            ...cur[objectName],
            [name]: val
          }
        }
      ];
    }
    return [...acu, cur];
  }, []);

  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      blockSettings: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handleHeadingAfter = (val, name, values, setValues, type) => {
  const handleActiveType = values.stylesheet.blockSettings.reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      if (type && type === "value") {
        return [
          ...acu,
          {
            ...cur,
            heading: {
              ...cur.heading,
              spaceAfter: {
                ...cur.heading.spaceAfter,
                [name]: { ...cur.heading.spaceAfter[name], value: val }
              }
            }
          }
        ];
      }

      return [
        ...acu,
        {
          ...cur,
          heading: {
            ...cur.heading,
            spaceAfter: {
              ...cur.heading.spaceAfter,
              [name]: val
            }
          }
        }
      ];
    }
    return [...acu, cur];
  }, []);

  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      blockSettings: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handleHeadingLabel = (val, name, nameObject, values, setValues) => {
  const handleActiveType = values.stylesheet.blockSettings.reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      return [
        ...acu,
        {
          ...cur,
          heading: {
            ...cur.heading,
            [nameObject]: {
              ...cur.heading[nameObject],
              [name]: val
            }
          }
        }
      ];
    }
    return [...acu, cur];
  }, []);

  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      blockSettings: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handleTableSingleLevel = (val, name, parents, values, setValues, type, valDropMenu) => {
  const handleActiveType = values.stylesheet[parents].reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      if (type && type === "value") {
        return [
          ...acu,
          {
            ...cur,
            [name]: {
              ...cur[name],
              value: +val
            }
          }
        ];
      }
      if (type && type === "drop") {
        return [
          ...acu,
          {
            ...cur,
            [name]: {
              ...cur[name],
              value: +val,
              unit: valDropMenu
            }
          }
        ];
      }
      return [...acu, { ...cur, [name]: val }];
    }
    return [...acu, cur];
  }, []);

  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      [parents]: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handleType = (val, parents, values, setValues) => {
  const findColum = values.stylesheet[parents].reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      return [...acu, { ...val, label: cur.label }];
    }
    return [...acu, cur];
  }, []);

  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      [parents]: [...findColum]
    }
  };
  setValues(result);
};

export const handleTableParents = (val, name, parents, tableParents, values, setValues) => {
  const handleActiveType = values.stylesheet[parents].reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      return [
        ...acu,
        {
          ...cur,
          [tableParents]: {
            ...cur[tableParents],
            [name]: val
          }
        }
      ];
    }
    return [...acu, cur];
  }, []);

  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      [parents]: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handleTabsValue = (val, name, parents, values, setValues) => {
  const handleActiveType = values.stylesheet[parents].reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      return [...acu, { ...cur, [name]: val }];
    }
    return [...acu, cur];
  }, []);
  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      [parents]: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handleTabsColumn = (val, name, parents, index, values, setValues) => {
  const handleActiveType = values.stylesheet[parents].reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      const result = cur.columns.reduce((acuColumns, curColumns, i) => {
        if (i === index) {
          return [...acuColumns, { ...curColumns, [name]: val }];
        }
        return [...acuColumns, curColumns];
      }, []);
      return [...acu, { ...cur, columns: result }];
    }
    return [...acu, cur];
  }, []);
  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      [parents]: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handleObjectStylesheet = (val, name, parents, values, setValues) => {
  setValues(pre => ({
    ...pre, stylesheet: {
      ...pre.stylesheet,
      [parents]: {
        ...pre.stylesheet[parents], [name]: val
      }
    }
  }));
};



export const handleParagraphType = (val, parents, index, values, setValues) => {
  const handleActiveType = values.stylesheet[parents].reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      const result = cur.templates.reduce((acuColumns, curColumns, i) => {
        if (i === index) {
          return [...acuColumns, val];
        }
        return [...acuColumns, curColumns];
      }, []);
      return [...acu, { ...cur, templates: result }];
    }
    return [...acu, cur];
  }, []);
  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      [parents]: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handleParagraphValue = (val, name, parents, index, values, setValues, childName) => {
  const handleActiveType = values.stylesheet[parents].reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      const result = cur.templates.reduce((acuColumns, curColumns, i) => {
        if (i === index) {
          if (childName) {
            return [...acuColumns, { ...curColumns, [name]: { ...curColumns[name], [childName]: val } }];
          }
          return [...acuColumns, { ...curColumns, [name]: val }];
        }
        return [...acuColumns, curColumns];
      }, []);
      return [...acu, { ...cur, templates: result }];
    }
    return [...acu, cur];
  }, []);
  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      [parents]: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handleIndex = (val, name, parents, selectedItem, values, setValues, allChild) => {
  const handleActiveType = values.stylesheet[parents].reduce((acu, cur, idx) => {
    if (idx === values.selectedLeftMenu) {
      if (allChild) {
        return [...acu, { ...cur, [name]: val }];
      }
      return [...acu, { ...cur, [selectedItem]: { ...cur[selectedItem], [name]: val } }];
    }
    return [...acu, cur];
  }, []);
  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      [parents]: [...handleActiveType]
    }
  };
  setValues(result);
};

export const handleXSLIncludes = (val, parents, values, setValues) => {
  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      [parents]: val
    }
  };
  setValues(result);
};

export const handleSingleLevelObject = (val, name, parents, values, setValues, itemParents) => {
  const handleActiveType = itemParents ? val : { ...values.stylesheet[parents], [name]: val };
  const result = {
    ...values,
    stylesheet: {
      ...values.stylesheet,
      [parents]: handleActiveType
    }
  };
  setValues(result);
};


export const adobeColor = (val) => {
  if (typeof val === "string" || !val) {
    return val;
  }
  const { red, green, blue, alpha } = val;
  const alphaValue = alpha / 255;
  return `rgba(${red}, ${green}, ${blue}, ${alphaValue})`;
};

export const adobeRgba = (val) => {
  // if(typeof val === 'string' && val.includes('rgba')){
  //   return val
  // }
  const res = convertStringToRGBA(val);
  return {
    "cmyk": false,
    "red": res.r,
    "green": res.g,
    "blue": res.b,
    "alpha": res.a
  };
};

const convertStringToRGBA = (colorString) => {
  return tinycolor(colorString).toRgb();
};
