import { injectIntl } from 'react-intl';
import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { API_ROOT } from '@zert-packages/actions/api';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useDispatch, useSelector } from 'react-redux';

export const formStyle = (theme) =>
  createStyles({
    mainContainer: {
      width: '100%',
      height: '100%'
    },
    rootBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '5px'
    },
    bodyContainer: {
      padding: '8px',
      height: 'calc(100% - 52px)',
      width: '100%',
      minHeight: '300px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',

      '@media (max-width: 900px)': {
        flexDirection: 'column'
      }
    },
    AppBar: {
      display: 'grid',
      gridTemplateColumns: '1fr auto auto',
      zIndex: '10',
      overflow: 'hidden',
      paddingRight: 8
    },
    formControl: {
      margin: theme.spacing(1),
      width: '100%'
    },
    element: {
      // position: 'absolute',
      // opacity: .5,
      // borderRadius: 50,
    },
    newElement: {
      position: 'absolute',
      backgroundColor: '#ADD8E6',
      borderRadius: 50,
      '&:hover': {
        background: 'green',
        visibility: 'visible',
        opacity: 0.5,
        transition: 'opacity'
      }
    },
    tabs: {
      // backgroundColor: '#ADD8E6',
      height: '10px',
      '&.MuiTabs-root': {
        minHeight: '21px'
        // backgroundColor: "orange"
      }
    },
    tab: {
      backgroundColor: '#ADD8E6',
      '&.MuiTab-root': {
        minHeight: 0,
        lineHeight: '0.75'
      }
    },
    wrapperTest: {
      // '& div': {
      //     transform: 'none !important',
      // }
    }
  });
const useStyles = makeStyles(formStyle);

function ImageCard({
  versionImage,
  selectItem,
  color,
  handleSelectedItem,
  moveNewHotspot,
  setMoveNewHotspot,
  newImages
}) {
  const cl = useStyles();
  const accessToken = window.localStorage.getItem('accessToken');
  const CLM = useSelector((state) => state.CLM);
  const dispatch = useDispatch();
  const [chosenTab, setChosenTab] = useState(0);
  const [images, setImages] = useState([]);
  const [selectElement, setSelectElement] = useState({
    angle: 0,
    class: 'se.zert.clm.rest.image.pojo.ClientHotspotAreaItem',
    height: 21,
    position: 0,
    shape: 'ELLIPSE',
    width: 21,
    x: 0,
    y: 0
  });
  const ref = useRef(null);
  const [maxElement, setMaxElement] = useState(0);
  const [data, setData] = useState([]);
  console.log('DATA', data);

  const addNewImage = () => {
    if (newImages.src) {
      setImages([
        ...images,
        {
          name: 'new image',
          src: newImages.src,
          imageItems: versionImage,
          elements: null
        }
      ]);
      setChosenTab(chosenTab + 1);
      setMoveNewHotspot(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setMoveNewHotspot(false);
    setChosenTab(newValue);
  };

  // const onDelete = (id) => {
  //     setComponents(components.filter((component) => component.key !== id));
  // };

  const addNewElementsToImage = (x, y, pos) => {
    if (images[chosenTab].elements) {
      setImages((prev) =>
        prev.map((item, idx) =>
          idx === chosenTab
            ? {
                ...item,
                elements: [
                  ...item.elements,
                  {
                    angle: 0,
                    class: 'se.zert.clm.rest.image.pojo.ClientHotspotAreaItem',
                    height: 21,
                    position: pos,
                    shape: 'ELLIPSE',
                    width: 21,
                    x: x,
                    y: y
                  }
                ]
              }
            : item
        )
      );
    } else {
      setImages((prev) =>
        prev.map((item, idx) =>
          idx === chosenTab
            ? {
                ...item,
                elements: [
                  {
                    angle: 0,
                    class: 'se.zert.clm.rest.image.pojo.ClientHotspotAreaItem',
                    height: 21,
                    position: pos,
                    shape: 'ELLIPSE',
                    width: 21,
                    x: x,
                    y: y
                  }
                ]
              }
            : item
        )
      );
    }
  };

  useEffect(() => {
    setData(CLM.partsGroup);
    setImages([
      {
        name: versionImage.image.name,
        src: `${API_ROOT}/image/getImageById/${versionImage.elementInfo.versionId}?jwtToken=${accessToken}`,
        imageItems: versionImage,
        elements: versionImage.image.elements
      }
    ]);
  }, [versionImage.elementInfo.versionId]);

  useEffect(() => {
    addNewImage();
  }, [newImages]);

  useEffect(() => {
    if (moveNewHotspot && images) {
      addNewElementsToImage(0, 0, 26);
    }
  }, [moveNewHotspot]);

  // console.log('IMAGE', images[chosenTab])

  const trackPos = (e, data, position) => {
    const arrayImage =
      images &&
      images.reduce((acu, cur, idx) => {
        if (idx === chosenTab) {
          const findElements = cur.elements.map((el, i) =>
            el.position === position
              ? {
                  class: 'se.zert.clm.rest.image.pojo.ClientHotspotAreaItem',
                  angle: 0,
                  height: 21,
                  position: el.position,
                  shape: 'ELLIPSE',
                  width: 21,
                  x: data.x,
                  y: data.y
                  // ...selectElement
                }
              : el
          );
          return [...acu, { ...cur, elements: findElements }];
        }
        return [...acu, cur];
      }, []);
    setImages(arrayImage);
  };

  return (
    <Paper>
      {images && (
        <TabContext value={chosenTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs className={cl.tabs} onChange={handleTabChange}>
              {images.map((item, index) => (
                <Tab className={cl.tab} label={item.name} value={index} />
              ))}
            </Tabs>
          </Box>
          {images.map((item, index) => (
            <TabPanel value={index}>
              <div
                style={{
                  position: 'relative',
                  width: versionImage.image.width,
                  height: versionImage.image.height
                }}
              >
                <div style={{ position: 'absolute' }}>
                  <img src={item.src} />
                </div>
                {item.elements &&
                  item.elements.map((item) => (
                    <Draggable
                      disabled={!moveNewHotspot}
                      onStop={(e, data) => trackPos(e, data, item.position)} // disabled={moveNewHotspot}
                      position={{ x: item.x, y: item.y }}
                      bounds={{
                        left: 0,
                        top: 0,
                        right: versionImage.image.width,
                        bottom: versionImage.image.height
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: item.position === selectItem ? color : '#ADD8E6',
                          width: item.width,
                          height: item.height,
                          borderRadius: 50,
                          position: 'absolute'
                          // left: item.x,
                          // top: item.y
                        }}
                        onClick={() => handleSelectedItem(item.position)}
                        ref={ref}
                      >
                        {item.position}
                      </div>
                    </Draggable>
                  ))}
              </div>
            </TabPanel>
          ))}
        </TabContext>
      )}
    </Paper>
  );
}

export default injectIntl(ImageCard);
