import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import './style.css';
import sv_locale from 'date-fns/locale/sv';
// the locale you want
registerLocale('sv', sv_locale);

class DatePickerCustomInput extends React.Component {
  getLabel(date_str) {
    const today = moment().endOf('day');
    const tomorrow = moment().add(1, 'day').endOf('day');
    const yesterday = moment().add(-1, 'day').endOf('day');
    const date = moment(date_str);

    if (date < today && date > yesterday)
      return <FormattedMessage id="deadline.duetoday.picker" defaultMessage="Today" />;
    if (date < tomorrow && date > today)
      return <FormattedMessage id="deadline.duetommorow.picker" defaultMessage="Tomorrow" />;
    return this.props.value === '' || this.props.value.indexOf('Invalid') >= 0 ? (
      <FormattedMessage id="deadline.notsetuped.emptypicker" defaultMessage="Select" />
    ) : (
      this.props.value
    );
  }

  render() {
    return (
      <button className="datepickerbutton" onClick={this.props.onClick}>
        {this.getLabel(this.props.value)}
      </button>
    );
  }
}

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
};

export default class DeadlinePicker extends React.Component {
  constructor(props) {
    super(props);
    let date = moment(this.props.deadline);
    date = date.toDate();

    this.state = {
      startDate: this.props.deadline ? date : undefined
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.setState({
      startDate: date
    });
    this.props.onChangeCallback(date);
  }

  //    locale = "sv"
  render() {
    if (this.props.readonly) {
      return <DatePickerCustomInput value={moment(this.props.deadline).format('YYYY-MM-DD')} />;
    }
    return (
      <DatePicker
        selected={this.state.startDate}
        onChange={this.handleChange}
        dateFormat="yyyy-MM-dd"
        locale="sv"
        customInput={<DatePickerCustomInput />}
      />
    );
  }
}
