import React, {useEffect, useState} from "react";
import { makeStyles } from "@mui/styles";
import SearchWrapper from "./SearchWrapper";
import ExplorerView from "@zert-packages/plugins/ExplorerPlugin/ExplorerView";
import {useDispatch, useSelector} from "react-redux";
import useTableFilter from "@zert-packages/components/shared/SearchableTable/useTableFilter";
import {columns} from "@zert-packages/components/ElementTile/ElementTileTable";
import {getPluginByMimeType} from "@zert-packages/utils/getPluginByMimeType";
import {getTileElementStatusMessage} from "@zert-packages/components/ElementTile/utils/getTileStatus";
import TileList from "@zert-packages/components/ElementTile/TileList";
import {useIntl, FormattedMessage} from "react-intl";
import OriginLink from "@zert-packages/components/common/OriginLink";
import SearchView from "@zert-packages/plugins/ExplorerPlugin/SearchView";
import SearchPane from "@zert-packages/plugins/ExplorerPlugin/SearchPane";
import {searchElements, updateSearchElements} from "@zert-packages/plugins/ExplorerPlugin/servicesMidleware";
import {TASK_ACTIONS_TYPES} from "@zert-packages/components/ElementTile/shared/ELEMENT_TILE_CONTS";
import {Button} from "@mui/material";
import {checkFilter} from "./BrowseDialog";
import Filter from "./Filter";
import Paper from "@mui/material/Paper";


const useStyles = makeStyles((theme) => ({
    border: {
        // height: 1,
        // background: '#8B8B8B',
        margin: '5px 0px',
        maxHeight: "500px",
        overflowY: "auto"
    },
}));


export const col = [
    {isFilterable: true, identifier:'nameNew'},
    {isFilterable: true, identifier:'mimeTypeNew'},
    {isFilterable: true, identifier:'owner'},
    {isFilterable: true, identifier:'date'},
    {isFilterable: true, identifier:'label'},
    {isFilterable: true, identifier:'status'},
    {isFilterable: true, identifier:'id'},
]

const SearchTab = ({browse, setBrowse, setActiveElement,columnsNew, hideToolBar}) => {
  const cl = useStyles();
  const dispatch = useDispatch();

  const[search, setSearch] = useState('')
    const CORE = useSelector((state) => state.CORE);
    const { searchConditions } = useSelector((state) => state.CORE);
    const { searchResult, isLoadingNodeContent } = CORE;
    const [isAllCompanies, setAllCompanies] = useState(false);
    const [searchHappens, setSearchhappens] = useState(false);
    const intl = useIntl();

    const explorer_infos = searchResult ? checkFilter(browse.selectedFilter, searchResult.result) : [];


    //mimeType
    // :
    // "application/zert-phrase"

    let searchMessage = <FormattedMessage id="search.list" defaultMessage="Search result" />;
    const { myuser: myUser } = useSelector((state) => state);

    const getCustomFilter = (element, name) => {
        switch (name) {
            case 'nameNew':
                return element.name;
            case 'deadline':
                if (element.properties == null) return null;
                return element.properties['zert:CurrentActivityDeadline'];
            case 'mimeTypeNew':
                const plugin = getPluginByMimeType(element.mimeType);
                if (intl && plugin && plugin.trkey) {
                    return intl.formatMessage({ id: plugin.trkey, defaultMessage: plugin.defaultMessage });
                }
                return element.mimeType;
            // case 'status':
            //     return getTileElementStatusMessage(element, myUser, intl);
            default:
                return null;
        }
    };

    const startSearchOnSubmit = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        setSearchhappens(true)
        dispatch(searchElements(isAllCompanies));
    };



    const { filterValue, filteredRows, updateFilterValue } = useTableFilter(
        CORE.selectedNodeChildren || [],
        // columnsNew ? columns : columnsNew,
        col,
        getCustomFilter
    );


    const handleSearch = () =>{
        if(browse?.searchValue) {
            updateFilterValue(browse?.searchValue)
        }
    }

    const reloadSearchOnSubmit = ({ actionType, onFinishState }) => {
        if (actionType === TASK_ACTIONS_TYPES.duplicate) {
            const duplicatedElement = onFinishState;
            dispatch(updateSearchElements({ count: searchResult.count + 1, result: [duplicatedElement, ...explorer_infos] }));
            return;
        }
        dispatch(searchElements(isAllCompanies));
    };

  return(
      <div style={{overflowY: "auto", minHeight: "548px"}}>
        {/*<SearchWrapper browse={browse} setBrowse={setBrowse} search={search} setSearch={setSearch} handleSearch={handleSearch}/>*/}
        <div className={cl.border}>
          <div style={{display: "grid", gridTemplateColumns: "1fr 0.1fr", alignItems: "center"}}>
              <div>
                  <SearchPane
                      key="searchPane"
                      hide={true}
                      isAllCompanies={isAllCompanies}
                      setAllCompanies={(isChecked) => {
                          setAllCompanies(isChecked);
                      }}
                      startSearchOnSubmit={startSearchOnSubmit}
                  />
                  <div  style={{ padding: '10px' }}>
                      <Paper elevation={2} style={{ padding: '10px' }}>
                          <Filter browse={browse} setBrowse={setBrowse}/>
                      </Paper>
                  </div>

              </div>
          </div>

            <div>
                <TileList
                    explorer_infos={explorer_infos}
                    loading={isLoadingNodeContent}
                    columnsNew={columnsNew}
                    hideToolBar={true}
                    reloadElement={reloadSearchOnSubmit}
                    showCut={false}
                    fullPage={true}
                    bradCrumbComponent={searchMessage}
                    setActiveElement={setActiveElement}
                />
            </div>
          {/*<SearchView setActiveElement={setActiveElement} searchProp={searchProp} hide={true}/>*/}
        </div>
      </div>
  )
}

export default SearchTab