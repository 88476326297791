import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import getLang from '@zert-packages/utils/getLang';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import InlineDeviationEditor, {
  submitHasAllMandatoryData
} from '@zert-packages/components/common/FormEditor/InlineDeviationEditor';
import { loadDeviationTemplates } from './serviceMidleware';
import { createDeviation, getTemplatesBundle, initDeviationAPI } from './API';

const useStyles = makeStyles({
  DialogContent: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'stretch',
    padding: 20,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto'
  }
});

function CreateNewSubmitDialog({ handleClose, onCreate, parentVersionId }) {
  const cl = useStyles();
  const dispatch = useDispatch();
  const { templates } = useSelector((state) => state.DEVIATION);
  const { myuser } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    templateId: -1,
    anonymous: false,
    template: null,
    deviation: null
  });

  const updateStateByField = (field, value) =>
    setState((prev) => ({
      ...prev,
      [field]: value
    }));

  const loadDeviationTemplate = (templateId, callback) => {
    setIsLoading(true);
    const locale = getLang();
    // handleQuery('/api/deviation-react/getAllTemplates/' + locale + '/' + templateId)
    getTemplatesBundle(templateId)
      .then((res) => {
        callback({
          template: res.deviationTemplate,
          deviation: createDeviation(res.deviationTemplate, null)
        });
        setIsLoading(false);
      })
      .catch((e) => {
        throw new Error(e);
      });
  };

  const updateDeviationTemplate = (e) => {
    const templateId = e.target.value;
    loadDeviationTemplate(templateId, (entries) => {
      setState((prev) => ({
        ...prev,
        ...entries,
        templateId
      }));
    });
  };

  const handleOnCreate = () => {
    setIsLoading(true);
    const APIState = {
      ...state.deviation,
      anonymous: state.anonymous,
      parentVersionId: parentVersionId
    };
    initDeviationAPI(state.templateId, APIState)
      .then((res) => {
        onCreate(res);
        setIsLoading(false);
        handleClose();
      })
      .catch((e) => {
        setIsLoading(false);
        throw new Error(e);
      });
  };

  useEffect(() => {
    dispatch(loadDeviationTemplates());
  }, []);

  useEffect(() => {
    const firstTemplateId = _.get((templates || [])[0], 'versionId', -1);
    if (state.templateId === -1 && firstTemplateId !== -1) {
      loadDeviationTemplate(firstTemplateId, (entries) => {
        setState((prev) => ({
          ...prev,
          ...entries,
          templateId: firstTemplateId
        }));
      });
    }
  }, templates);

  const onUpdate = (deviation) => {
    setState((prev) => ({
      ...prev,
      deviation: { ...deviation }
    }));
  };

  return (
    <Dialog fullWidth maxWidth="md" open onClose={handleClose}>
      <DialogTitle>
        <FormattedMessage id="RMP.CreateNewSubmitDialog.DialogTitle" defaultMessage="New submit" />
      </DialogTitle>

      <DialogContent className={cl.DialogContent}>
        {isLoading || !templates ? (
          <Loader />
        ) : (
          <>
            <FormControl variant="standard">
              <InputLabel style={{ color: '#abc1c7' }}>
                <FormattedMessage id="RMP.CreateNewSubmitDialog.TemplateLabel" defaultMessage="Template" />
              </InputLabel>
              <Select variant="standard" value={state.templateId} onChange={updateDeviationTemplate}>
                {templates.map(({ name, versionId }) => (
                  <MenuItem key={versionId} value={versionId}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              style={{
                marginTop: '10px',
                marginBottom: '-22px',
                color: '#abc1c7'
              }}
              control={
                <Checkbox
                  checked={state.anonymous}
                  onChange={(e) => updateStateByField('anonymous', e.target.checked)}
                  color="primary"
                />
              }
              label={
                <FormattedMessage id="RMP.CreateNewSubmitDialog.AnonymousCheckboxLabel" defaultMessage="Anonymous" />
              }
            />
            {state.template && (
              <InlineDeviationEditor
                owner
                user={myuser}
                onUpdate={onUpdate}
                task={{ taskAdmin: true, currentActivity: { id: 'create' } }}
                deviation={state.deviation}
                deviationtemplate={state.template}
              />
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        {!isLoading &&
          state.template &&
          state.deviation &&
          submitHasAllMandatoryData(state.template, state.deviation, state.template.columnActivityDisplay, {
            taskAdmin: true,
            currentActivity: { id: state.template.startActivityId }
          }) && (
            <Fragment>
              <Button onClick={handleOnCreate} color="primary" variant="outlined">
                <FormattedMessage id="RMP.CreateNewSubmitDialog.Confirm" defaultMessage="Create" />
              </Button>

              <Button onClick={handleClose} color="primary" autoFocus>
                <FormattedMessage id="RMP.CreateNewSubmitDialog.Cancel" defaultMessage="Cancel" />
              </Button>
            </Fragment>
          )}
      </DialogActions>
    </Dialog>
  );
}

export default CreateNewSubmitDialog;
