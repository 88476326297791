import { loadIndex, turnOffCLMLoader, turnOnCLMLoader } from '../TranslationJobPlugin/tjReducers';
import { getIndex } from './API';

export const fetchIndex = (versionId, callback) => (dispatch) => {
  dispatch(turnOnCLMLoader());
  getIndex(versionId)
    .then((res) => {
      dispatch(loadIndex(res));
    })
    .then(() => {
      dispatch(turnOffCLMLoader());
    });
};

// export const addIndex = () => async (dispatch) => {
//     dispatch(turnOnCLMLoader());
//     addTerm().then((res) => {
//         dispatch(loadTermReferences(res));
//     }).then(() => {
//
//     })
//
// };
