import React, { memo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FUNCTION_LABELS } from '../CONSTS';
import { sendInvitation } from '../redux/membersMiddlewares';

const useStyles = makeStyles({
  dialogContent: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '3fr 1fr',
    gridGap: 10,
    overflow: 'hidden',
    padding: 10,
    maxHeight: '100%'
  },
  tableContainer: {
    overflow: 'auto',
    height: '100%',
    width: '100%'
  }
});

function SendInvitationDialog({ handleClose, listOfUsers, onSend }) {
  const cl = useStyles();
  const dispatch = useDispatch();
  const [listOfInvitedUsers, setListOfInvitedUsers] = useState([]);
  const [invitationText, setInvitationText] = useState('');

  const handleChangeInvitedUsers = (invited, userEmail) => {
    if (invited) setListOfInvitedUsers((prev) => [...prev, userEmail]);
    else setListOfInvitedUsers((prev) => prev.filter((e) => e !== userEmail));
  };

  const handleConfirm = () => {
    const members = listOfUsers.filter((user) => listOfInvitedUsers.includes(user.email));
    dispatch(sendInvitation(members, invitationText));
    handleClose();
  };

  const confirmBtnDisabled = invitationText.trim().length === 0 || listOfInvitedUsers.length === 0;
  return (
    <Dialog fullWidth fullScreen maxWidth="xl" open onClose={handleClose}>
      <DialogTitle>
        <FormattedMessage id="RMP.Members.SendInvitationDialog.Title" defaultMessage="Sending invitation" />
      </DialogTitle>
      <DialogContent className={cl.dialogContent}>
        <div className={cl.tableContainer}>
          <Table stickyHeader padding="checkbox">
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="RMP.Members.SendInvitationDialog.TableHeadCell.Send" defaultMessage="Send" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="RMP.Members.SendInvitationDialog.TableHeadCell.Name" defaultMessage="Name" />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="RMP.Members.SendInvitationDialog.TableHeadCell.Username"
                    defaultMessage="Username"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="RMP.Members.SendInvitationDialog.TableHeadCell.Email" defaultMessage="Email" />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="RMP.Members.SendInvitationDialog.TableHeadCell.Function"
                    defaultMessage="Function"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listOfUsers.map((user) => (
                <TableBodyRowWithMemo
                  key={user.username}
                  handleChangeInvitedUsers={handleChangeInvitedUsers}
                  user={user}
                  invited={listOfInvitedUsers.includes(user.email)}
                />
              ))}
            </TableBody>
          </Table>
        </div>
        <TextField
          fullWidth
          label={<FormattedMessage id="RMP.Members.SendInvitationDialog.Message" defaultMessage="Invitation text" />}
          multiline
          rows="8"
          value={invitationText}
          onChange={(e) => setInvitationText(e.target.value)}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={confirmBtnDisabled} onClick={handleConfirm} color="primary" autoFocus variant="outlined">
          <FormattedMessage id="RMP.Members.SendInvitationDialog.SendInvitation" defaultMessage="Send invitation" />
        </Button>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="RMP.Members.SendInvitationDialog.Cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SendInvitationDialog;

const TableBodyRowWithMemo = memo(
  ({ handleChangeInvitedUsers, invited, user }) => (
    <TableRow>
      <TableCell>
        <Checkbox
          disabled={user.email === null}
          checked={invited}
          onChange={(e) => handleChangeInvitedUsers(e.currentTarget.checked, user.email)}
          color="primary"
        />
      </TableCell>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.username}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{FUNCTION_LABELS[user.function]}</TableCell>
    </TableRow>
  ),
  (prevProps, nextProps) => prevProps.invited === nextProps.invited && prevProps.user === nextProps.user
);
