import { FormattedMessage } from 'react-intl';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { generateChecklistReport } from '@zert-packages/actions/checklistActions';
import generalRadioBtns from './generalRadioBtns';

export default function useCheckListPrintReports({ selectedNode, selectedValues, catalog }) {
  const caption = <FormattedMessage id="reports.print.checklist" defaultMessage="Print Checklist" />;

  const dispatch = useDispatch();
  const index = 'checklists';
  const radiobtns = generalRadioBtns;
  // eslint-disable-next-line no-use-before-define
  const dateSelectors = [];
  const checkBoxes = [
    {
      name: 'cb_include_matrix',
      label: <FormattedMessage id="reports.includeMatrix" defaultMessage="Include Matrix" />,
      checked: false
    },
    {
      name: 'cb_showImagesSeparatly',
      label: <FormattedMessage id="reports.showImagesSeparately" defaultMessage="Show images separately" />,
      checked: false
    }
  ];
  const selectors = [];

  const generateReport = (checkedItems) => {
    let selectedIds = [];
    if (selectedValues) {
      selectedIds = selectedValues.map((value) => value.versionId);
    }

    dispatch(
      generateChecklistReport(
        selectedIds[0],
        {
          name: caption,
          reportUUID: uuidv4()
        },
        1,
        checkedItems('cb_include_matrix'),
        checkedItems('cb_showImagesSeparatly'),
        true
      )
    );
  };

  return { index, generateReport, caption, radiobtns, dateSelectors, selectors, checkBoxes, key: 'checklist-print' };
}
