const makeValidSubNode = ({
  name,
  description,
  uuid,
  documents,
  basises,
  responsiblePerson,
  supplier,
  contractorPL,
  nodeStatus,
  documentationPositions,
  demandsStatusSimplified,
  demands
}) => ({
  name,
  description,
  uuid,
  documents,
  basises,
  responsiblePerson,
  supplier,
  contractorPL,
  nodeStatus,
  documentationPositions,
  demandsStatusSimplified,
  demands
});

export default makeValidSubNode;
