import Dialog from '@mui/material/Dialog';
import React, {useState} from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';

const type = ['Ellips', 'Rectangle'];

const EditHotspotDialog = ({dialog, setDialog, hotspotToEdit}) => {
    const [hotspotData, setHotspotData] = useState(hotspotToEdit)
    return (
        <Dialog
            open={dialog}
            onClose={() => {
                setDialog({open: false, type: null});
            }}
            fullWidth
        >
            <DialogTitle sx={{fontSize: '20px'}}>Edit hotspot</DialogTitle>
            <DialogContent fullWidth>
                <TextField
                    fullWidth
                    type="number"
                    label="Position"
                    inputProps={{style: {paddingLeft: '8px'}}}
                    value={hotspotData.position}
                    onChange={(event) => {
                        setHotspotData(hotspot => ({
                            ...hotspot,
                            position: event.target.value
                        }));
                    }}
                />

                <Autocomplete
                    value={hotspotData.type}
                    onChange={(event, newValue) => {
                        setHotspotData(hotspot => ({
                            ...hotspot,
                            type: newValue
                        }));
                    }}
                    id="controllable-states-demo"
                    options={type}
                    renderInput={(params) => <TextField fullWidth {...params} label="Type"/>}
                />

                <TextField
                    fullWidth
                    type="number"
                    label="Width"
                    inputProps={{style: {paddingLeft: '8px'}}}
                    value={hotspotData.width}
                    onChange={(event) => {
                        setHotspotData(hotspot => ({
                            ...hotspot,
                            width: event.target.value
                        }));
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={(e) => {
                        setDialog({
                            open: false,
                            type: null
                        })
                    }}
                    variant="contained"
                    color="primary"
                >
                    OK
                </Button>
                <Button
                    onClick={(e) => {
                        setDialog({
                            open: false,
                            type: null
                        })
                    }}
                    variant="contained"
                    color="primary"
                >
                    CANCEL
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditHotspotDialog;
