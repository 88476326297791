import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
  DialogContent: {
    minWidth: 320,
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexFlow: 'nowrap column',
    '& > *': {
      marginBottom: 10
    }
  }
});

function DuplicationDefaultDialog({
  unmountComponent,
  onDuplicate,
  customDialogContent,
  onDuplicateDisabled = false,
  loading = false
}) {
  const cl = useStyles();
  return (
    <Dialog open onClose={unmountComponent} fullWidth>
      <DialogTitle>
        <FormattedMessage id="DuplicationDialogs.DuplicationDefaultDialog.Title" defaultMessage="Duplication" />
      </DialogTitle>
      <DialogContent className={cl.DialogContent}>{customDialogContent}</DialogContent>
      <DialogActions>
        <Button
          startIcon={loading && <CircularProgress size={24} />}
          onClick={onDuplicate}
          color="primary"
          variant="outlined"
          disabled={onDuplicateDisabled || loading}
        >
          <FormattedMessage
            id="DuplicationDialogs.DuplicationDefaultDialog.DuplicateButton"
            defaultMessage="Duplicate"
          />
        </Button>
        <Button onClick={unmountComponent} color="primary" disabled={loading}>
          <FormattedMessage id="DuplicationDialogs.DuplicationDefaultDialog.CancelButton" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DuplicationDefaultDialog;
