import React, { useContext, useEffect, useState } from 'react';
import { getParentInfos } from '@zert-packages/actions/explorerActions';
import { v4 as uuidv4 } from 'uuid';
import { getPluginByMimeTypeIcon } from '@zert-packages/utils/getPluginByMimeType';
import { TreeContext } from './ParentTree';

function ParentTreeNode({ node, isRoot, path, latest }) {
  const [visible, setVisible] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [wasSelected, setWasSelected] = useState(false);
  const [children, setChildren] = useState(null);
  const [key, setKey] = useState(uuidv4());

  useEffect(() => {
    if (isRoot) {
      setExpanded(true);
      setVisible(true);
      setWasSelected(true);
      expand(null);
    }
  }, [node]);

  const expand = async () => {
    const parents = await getParentInfos(node.versionId);

    const newParents = Object.keys(parents).flatMap((key) =>
      latest ? (parents[key].length > 0 ? parents[key][0] : []) : parents[key]
    );

    setChildren(newParents);
  };

  const toggle = async (e) => {
    setVisible(!visible);
    setWasSelected(true);
    setExpanded(!expanded);
    setSelectedNodeId(key);
    if (children == null) {
      expand();
    }
  };
  let className = '';
  if (children == null || children.length > 0) {
    className = 'togglable';
    if (visible || (!wasSelected && path && path.indexOf(node.name) != -1)) {
      className += ' togglable-down';
    } else {
      className += ' togglable-up';
    }
  }

  if (isRoot) {
    className = 'togglable togglable-down';
  }

  let style = {};
  if (visible == false && (wasSelected || (path && path.indexOf(node.name) == -1))) {
    if (!isRoot) {
      style = { display: 'none' };
    }
  }

  const { selectNodeId, setSelectedNodeId } = useContext(TreeContext);

  // const Icon = getPluginByMimeTypeIcon(node.mimeType);
  // style={{color : (key == selectNodeId) ? "#1BA1C5" : "black"}}}

  return (
    <div style={{ padding: '0 0 0 10px' }}>
      <input
        type="radio"
        name="catalog_tree"
        checked={key == selectNodeId}
        id={key}
        value={node.name}
        style={{ display: 'none' }}
        onClick={toggle}
      />

      <label
        htmlFor={key}
        className={`folder noselect ${className}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '1rem',
          cursor: node.disabled ? 'not-allowed' : 'pointer',
          color: 'inherit'
        }}
      >
        <div className="icon-box" style={{ padding: '0px 3px 0 0' }}>
          {getPluginByMimeTypeIcon(node.mimeType)}
        </div>
        {node.name}
      </label>

      <ul style={style} className="noDecoration">
        {children &&
          children.map((child, index) => {
            child.parent = node;
            child.siblings = children;
            return (
              <li>
                <ParentTreeNode
                  node={child}
                  latest={latest}

                  /* selectNodeId={selectNodeId}
                   */
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default ParentTreeNode;
