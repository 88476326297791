export const findListLef = (setSelectedListItem, selectedTab, formatTemplate, findTemplateList, setLeftMenu) => {
  setSelectedListItem(0);
  if (selectedTab === 0 && formatTemplate.blockFormats) {
    findTemplateList(formatTemplate.blockFormats, 'blockFormats');
    return setLeftMenu(formatTemplate.blockFormats);
  }
  if (selectedTab === 1 && formatTemplate.tableFormats) {
    findTemplateList(formatTemplate.tableFormats, 'tableFormats');
    return setLeftMenu(formatTemplate.tableFormats);
  }
  if (selectedTab === 2 && formatTemplate.imageFormats) {
    findTemplateList(formatTemplate.imageFormats, 'imageFormats');
    return setLeftMenu(formatTemplate.imageFormats);
  }
  if (selectedTab === 3 && formatTemplate.partFormats) {
    findTemplateList(formatTemplate.partFormats, 'partFormats');
    return setLeftMenu(formatTemplate.partFormats);
  }
  if (selectedTab === 4 && formatTemplate.paragraphFormats) {
    findTemplateList(formatTemplate.paragraphFormats, 'paragraphFormats');
    return setLeftMenu(formatTemplate.paragraphFormats);
  }

  if (selectedTab === 5 && formatTemplate.textFormats) {
    findTemplateList(formatTemplate.textFormats, 'textFormats');
    return setLeftMenu(formatTemplate.textFormats);
  }

  // if (selectedTab === 6 && formatTemplate.metadataInfos.length > 0) {
  //   findTemplateList(formatTemplate.metadataInfos);
  //   return setLeftMenu(formatTemplate.metadataInfos);
  // }
  // if (selectedTab === 7) {
  //   findTemplateList([]);
  //   return setLeftMenu([]);
  // }
  const hiddenFormats = formatTemplate.hiddenFormats
  if (selectedTab === 6 && hiddenFormats) {
    const adobeHiddenFormats = Array.isArray(hiddenFormats) ? hiddenFormats : [hiddenFormats]
    findTemplateList(adobeHiddenFormats, 'hiddenFormats');
    return setLeftMenu(adobeHiddenFormats);
  }
  setLeftMenu([])
  setLeftMenu(null)
};
