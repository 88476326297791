import get from 'lodash/get';

const getTranslationCore = (label, locale) => {
  const locales = get(label, 'locales', []) || [];
  if (locales.length === 0) return '';

  const translations = get(label, 'translations', []) || [];
  if (translations.length === 0) return '';

  const localeIndex = locales.find((l) => l === locale);

  if (get(translations, `[${localeIndex}]`, '').trim()) {
    return translations[localeIndex];
  }
  const firstNotEmpty = translations.find((t) => t.trim().length !== 0);
  return firstNotEmpty || '';
};

export default getTranslationCore;
