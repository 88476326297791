import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { generateXLSRiskAnalisesReport } from '@zert-packages/actions/riskassesmentActions';
import { generateXLSChecklistsReport, loadQuestinaireTemplates } from '@zert-packages/actions/checklistActions';
import { generateXLSMeasureReport } from '@zert-packages/actions/measureActions';
import { generateXLSDeviationsReport } from '@zert-packages/actions/deviationActions';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import generalRadioBtns from './generalRadioBtns';
import { findNode } from './findNode';

export const XLS_REPORT_TYPES = {
  RISK_ASSESSMENT: 0,
  CHECKLIST: 1,
  SUBMITS: 2,
  MEASURES: 3
};

const getColumns = (intl, type) => {
  const allCols = [
    {
      id: '__zert_name__',
      name: intl.formatMessage({
        id: 'xls.columns.name',
        defaultMessage: 'Name'
      }),
      types: [XLS_REPORT_TYPES.RISK_ASSESSMENT, XLS_REPORT_TYPES.CHECKLIST]
    },
    {
      id: '__zert_element_id__',
      name: intl.formatMessage({
        id: 'xls.columns.report.id',
        defaultMessage: 'ID'
      }),
      selected: true,
      types: [
        XLS_REPORT_TYPES.RISK_ASSESSMENT,
        XLS_REPORT_TYPES.CHECKLIST,
        XLS_REPORT_TYPES.MEASURES,
        XLS_REPORT_TYPES.SUBMITS
      ]
    },
    {
      id: '__zert_created_by__',
      name: intl.formatMessage({
        id: 'xls.columns.report.CreatedBy',
        defaultMessage: 'Created by'
      }),
      types: [XLS_REPORT_TYPES.RISK_ASSESSMENT, XLS_REPORT_TYPES.CHECKLIST]
    },
    {
      id: '__zert_created_at__',
      name: intl.formatMessage({
        id: 'xls.columns.report.CreatedAt',
        defaultMessage: 'Created at'
      }),
      type: 'date',
      types: [XLS_REPORT_TYPES.RISK_ASSESSMENT, XLS_REPORT_TYPES.CHECKLIST],
      selected: true
    },
    {
      id: '__zert_changed_at__',
      name: intl.formatMessage({
        id: 'xls.columns.report.ChangedAt',
        defaultMessage: 'Changed at'
      }),
      type: 'date',
      types: [
        XLS_REPORT_TYPES.RISK_ASSESSMENT,
        XLS_REPORT_TYPES.CHECKLIST,
        XLS_REPORT_TYPES.MEASURES,
        XLS_REPORT_TYPES.SUBMITS
      ]
    },
    {
      id: '__zert_completed_date__',
      name: intl.formatMessage({
        id: 'xls.columns.report.CompletedAt',
        defaultMessage: 'Completed at'
      }),
      type: 'date',
      types: [XLS_REPORT_TYPES.SUBMITS]
    },
    {
      id: '__zert_version__',
      name: intl.formatMessage({
        id: 'xls.columns.report.Version',
        defaultMessage: 'Version'
      }),
      type: 'integer',
      selected: true,
      types: [XLS_REPORT_TYPES.RISK_ASSESSMENT, XLS_REPORT_TYPES.CHECKLIST]
    },
    {
      id: '__zert_status__',
      name: intl.formatMessage({
        id: 'xls.columns.report.Status',
        defaultMessage: 'Status'
      }),
      selected: true,
      types: [XLS_REPORT_TYPES.RISK_ASSESSMENT, XLS_REPORT_TYPES.CHECKLIST]
    },
    {
      id: '__zert_number_of_risk_rows__',
      name: intl.formatMessage({
        id: 'xls.columns.report.riskRows',
        defaultMessage: 'Number of riskrows'
      }),
      type: 'integer',
      selected: true,
      types: [XLS_REPORT_TYPES.RISK_ASSESSMENT, XLS_REPORT_TYPES.CHECKLIST]
    },
    {
      id: '__zert_number_of_measures__',
      name: intl.formatMessage({
        id: 'xls.columns.report.measures',
        defaultMessage: 'Number of Measures'
      }),
      type: 'integer',
      selected: true,
      types: [XLS_REPORT_TYPES.RISK_ASSESSMENT, XLS_REPORT_TYPES.CHECKLIST]
    },
    {
      id: '__zert_catalog_placement__',
      name: intl.formatMessage({
        id: 'xls.columns.report.catalog',
        defaultMessage: 'Catalog placement'
      }),
      types: [
        XLS_REPORT_TYPES.RISK_ASSESSMENT,
        XLS_REPORT_TYPES.CHECKLIST,
        XLS_REPORT_TYPES.MEASURES,
        XLS_REPORT_TYPES.SUBMITS
      ]
    },
    {
      id: '__zert_project__',
      name: intl.formatMessage({
        id: 'xls.columns.report.project',
        defaultMessage: 'Project'
      }),
      types: [
        XLS_REPORT_TYPES.RISK_ASSESSMENT,
        XLS_REPORT_TYPES.CHECKLIST,
        XLS_REPORT_TYPES.MEASURES,
        XLS_REPORT_TYPES.SUBMITS
      ]
    },
    {
      id: '__zert_template__',
      name: intl.formatMessage({
        id: 'xls.columns.report.template',
        defaultMessage: 'Template'
      }),
      types: [
        XLS_REPORT_TYPES.RISK_ASSESSMENT,
        XLS_REPORT_TYPES.CHECKLIST,
        XLS_REPORT_TYPES.MEASURES,
        XLS_REPORT_TYPES.SUBMITS
      ]
    },
    {
      id: '__zert_current_activity__',
      name: intl.formatMessage({
        id: 'xls.columns.report.currentActivity',
        defaultMessage: 'Current activity'
      }),
      types: [XLS_REPORT_TYPES.MEASURES, XLS_REPORT_TYPES.SUBMITS],
      selected: true
    },
    {
      id: '__zert_current_owner__',
      name: intl.formatMessage({
        id: 'xls.columns.report.currentOwner',
        defaultMessage: 'Current owner'
      }),
      types: [XLS_REPORT_TYPES.MEASURES, XLS_REPORT_TYPES.SUBMITS],
      selected: true
    },
    {
      id: '__zert_text__',
      name: intl.formatMessage({
        id: 'xls.columns.report.text',
        defaultMessage: 'Text'
      }),
      types: [XLS_REPORT_TYPES.MEASURES],
      selected: true
    },
    {
      id: '__zert_deadline__',
      name: intl.formatMessage({
        id: 'xls.columns.report.deadline',
        defaultMessage: 'Deadline'
      }),
      types: [XLS_REPORT_TYPES.MEASURES],
      selected: true
    },
    {
      id: '__origin_type__',
      name: intl.formatMessage({
        id: 'xls.columns.report.originType',
        defaultMessage: 'Origin Type'
      }),
      types: [XLS_REPORT_TYPES.MEASURES],
      selected: true
    },
    {
      id: '__origin_id__',
      name: intl.formatMessage({
        id: 'xls.columns.report.originId',
        defaultMessage: 'Origin Id'
      }),
      types: [XLS_REPORT_TYPES.MEASURES]
    },
    {
      id: '__origin_template__',
      name: intl.formatMessage({
        id: 'xls.columns.report.originTemplate',
        defaultMessage: 'Origin Template'
      }),
      types: [XLS_REPORT_TYPES.MEASURES]
    },
    {
      id: '__update_risk_row_participants__',
      name: intl.formatMessage({
        id: 'xls.columns.report.riskrowparticipants',
        defaultMessage: 'Update risk row participants'
      }),
      types: [XLS_REPORT_TYPES.MEASURES]
    },
    {
      id: '__responsible__',
      name: intl.formatMessage({
        id: 'xls.columns.report.responsible',
        defaultMessage: 'Responsible'
      }),
      types: [XLS_REPORT_TYPES.MEASURES],
      selected: true
    },
    {
      id: '__zert_reporter__',
      name: intl.formatMessage({
        id: 'xls.columns.report.reporter',
        defaultMessage: 'Reporter'
      }),
      types: [XLS_REPORT_TYPES.SUBMITS],
      selected: true
    }
  ];
  return allCols.filter((c) => c.types.includes(type));
};

const dateSelectors = [
  {
    name: 'reports.from',
    section: 'time',
    label: <FormattedMessage id="reports.safetyinspection.start_frm" defaultMessage="Date from" />
  },
  {
    name: 'reports.to',
    section: 'time',
    label: <FormattedMessage id="reports.safetyinspection.date_to" defaultMessage="Date to" />
  }
];

function columnToClientTemplateColumn(column) {
  return {
    identifier: column.id,
    type: column.type ? column.type : 'text',
    name: column.name
  };
}

function TemplateSelector() {
  return (
    <FormControl>
      <InputLabel>{formattedMessages.questionnaireTemplate}</InputLabel>

      <Select value={state.idtemplate} onChange={onChangeQuestionnaireTemplate}>
        {templates.map((template, index) => (
          <MenuItem key={index} value={template.versionId}>
            {template.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default function useSummaryXLSReports({ selectedNode, selectedValues, catalog, id }) {
  const intl = useIntl();
  const getReportCaption = () => {
    switch (id) {
      case XLS_REPORT_TYPES.RISK_ASSESSMENT:
        return (
          <FormattedMessage id="xls.reassessment.report" defaultMessage="Export risk assessments to spreadsheet" />
        );
      case XLS_REPORT_TYPES.CHECKLIST:
        return <FormattedMessage id="xls.checklist.report" defaultMessage="Export checklist to spreadsheet" />;

      case XLS_REPORT_TYPES.SUBMITS:
        return <FormattedMessage id="xls.deviation.report" defaultMessage="Export submits to spreadsheet" />;
      case XLS_REPORT_TYPES.MEASURES:
        return <FormattedMessage id="xls.measures.report" defaultMessage="Export measures to spreadsheet" />;

      default:
        return (
          <FormattedMessage id="xls.reassessment.report" defaultMessage="Export risk assessments to spreadsheet" />
        );
    }
  };
  const caption = getReportCaption();
  const getReportColumns = () => getColumns(intl, id);

  const columns = getReportColumns();

  const multiSelectors = columns
    ? [
      {
        name: 'included_columns',
        section: 'columns',
        label: <FormattedMessage id="reports.xls.columns" defaultMessage="Columns" />,
        selected: columns.filter((c) => c.selected),
        options: columns
      }
    ]
    : [];

  const getReportDescription = () => {
    switch (id) {
      case XLS_REPORT_TYPES.RISK_ASSESSMENT:
        return (
          <FormattedMessage
            id="xls.reassessment.reportdescription"
            defaultMessage="This report generates selected risk assessments in an .xls file."
          />
        );
      case XLS_REPORT_TYPES.CHECKLIST:
        return (
          <FormattedMessage
            id="xls.checklist.reportdescription"
            defaultMessage="This report generates selected checklists in an .xls file."
          />
        );

      case XLS_REPORT_TYPES.SUBMITS:
        return (
          <FormattedMessage
            id="xls.deviation.reportdescription"
            defaultMessage="This report generates a list of selected submits in an .xls file."
          />
        );
      case XLS_REPORT_TYPES.MEASURES:
        return (
          <FormattedMessage
            id="xls.measures.reportdescription"
            defaultMessage="This report generates selected measures in an .xls file."
          />
        );

      default:
        return null;
    }
  };
  const description = getReportDescription();

  const index = 'riskassessment';
  const dispatch = useDispatch();

  const { questionairetemplates } = useSelector((state) => state);
  useEffect(() => {
    dispatch(loadQuestinaireTemplates(true, false));
  }, []);
  const useRatiobtns = catalog && (!selectedValues.length || selectedValues.length === 0);
  const radiobtns = useRatiobtns ? generalRadioBtns : [];

  // eslint-disable-next-line no-use-before-define

  const selectors =
    id === XLS_REPORT_TYPES.CHECKLIST && questionairetemplates
      ? [
        {
          name: 'template',
          label: <FormattedMessage id="Checklist.Template" defaultMessage="Template" />,
          section: 'templates',
          selected: -1,
          options: questionairetemplates.map((template) => ({ value: template.id, label: template.name }))
        }
      ]
      : [];
  // eslint-disable-next-line no-use-before-define
  const checkBoxes = useRatiobtns
    ? [
      {
        name: 'cb_include_all',
        section: 'scope',
        label: <FormattedMessage id="reports.riskassesment.includeAl" defaultMessage="Include all" />,
        checked: false
      }
    ]
    : [];

  const getSelectedColumns = (checkedItems) => {
    const selectedColumns = checkedItems('included_columns');
    return selectedColumns ? selectedColumns.map((v) => columnToClientTemplateColumn(v)) : [];
  };

  const generateReport = (checkedItems) => {
    const selectedColumns = getSelectedColumns(checkedItems);
    const catalogVersionId = useRatiobtns ? catalog.info.versionId : -1;
    const nodeUUID = useRatiobtns ? findNode(catalog, selectedNode) : selectedNode || 'none';
    const scope = useRatiobtns ? checkedItems('reports.subnode') : 'elements';
    const includeAll = useRatiobtns ? checkedItems('cb_include_all') : false;
    const versionIds = !useRatiobtns
      ? selectedValues.map((v) => (catalog ? v.versionId.versionId : v.versionId))
      : null;
    if (id === XLS_REPORT_TYPES.RISK_ASSESSMENT) {
      dispatch(
        generateXLSRiskAnalisesReport(
          {
            name: 'Risk Assessments',
            reportUUID: uuidv4()
          },
          catalogVersionId,
          nodeUUID,
          scope,
          includeAll,
          checkedItems('reports.from'),
          checkedItems('reports.to'),
          selectedColumns,
          versionIds
        )
      );
    } else if (id === XLS_REPORT_TYPES.CHECKLIST) {
      dispatch(
        generateXLSChecklistsReport(
          {
            name: 'Checklists',
            reportUUID: uuidv4()
          },
          catalogVersionId,
          nodeUUID,
          scope,
          includeAll,
          checkedItems('reports.from'),
          checkedItems('reports.to'),
          checkedItems('template'),
          selectedColumns,
          versionIds
        )
      );
    } else if (id === XLS_REPORT_TYPES.SUBMITS) {
      dispatch(
        generateXLSDeviationsReport(
          {
            name: 'Deviations',
            reportUUID: uuidv4()
          },
          catalogVersionId,
          nodeUUID,
          scope,
          includeAll,
          checkedItems('reports.from'),
          checkedItems('reports.to'),
          selectedColumns,
          versionIds,
          'ALL'
        )
      );
    } else if (id === XLS_REPORT_TYPES.MEASURES) {
      dispatch(
        generateXLSMeasureReport(
          {
            name: 'Measure',
            reportUUID: uuidv4()
          },
          catalogVersionId,
          nodeUUID,
          scope,
          includeAll,
          checkedItems('reports.from'),
          checkedItems('reports.to'),
          selectedColumns,
          versionIds
        )
      );
    }
  };

  return {
    index,
    generateReport,
    caption,
    description,
    radiobtns,
    dateSelectors,
    selectors,
    multiSelectors,
    checkBoxes
  };
}
