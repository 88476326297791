import React, { Fragment } from 'react';
import ButtonWithMenus from '@zert-packages/components/ButtonWithMenus';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { generatePrintReport } from './servicesMiddlewares';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

function PrintManager() {
  const cl = useStyles();
  const dispatch = useDispatch();
  const createCreateReport = () => () => dispatch(generatePrintReport());
  return (
    <div className={cl.root}>
      <ButtonWithMenus
        ButtonLabel={<FormattedMessage id="CLMPrintManager.PrintButton" defaultMessage="Print" />}
        menuItems={[
          {
            children: <FormattedMessage id="CLM.PrintManager.TranslationCosts" defaultMessage="Translation costs" />,
            onClick: createCreateReport()
          }
        ]}
      />
    </div>
  );
}

export default PrintManager;
