import Typography from '@mui/material/Typography';
import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  TextField
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import omit from 'lodash.omit';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { FormattedMessage, useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CheckboxWithMemoAlt } from '@zert-packages/components/common/CheckboxWithMemo';
import SelectField from '@zert-packages/components/shared/FormElements/SelectField';
import Box from '@mui/material/Box';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import useReports from './utils/useReports';
import { createInitalTypeMap, defaultTypes, filterAndSortTypeMap } from "./ReportCategories";
import MultipleSelectNative from "../../../shared/FormElements/MultiSelectFieldNative";
import TableOptionDialog from "./TableOptionDialog";

const useStyles = makeStyles({
  DialogContent: {
    minWidth: 320,
    width: '100%',
    overflow: 'auto',
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '2fr 5fr',

    flexFlow: 'nowrap column',
    '& > *': {
      marginBottom: 10
    }
  }
});

const selectors = [
  { name: "radiobtns", valueKey: "value" },
  { name: "checkBoxes", valueKey: "checked" },
  {
    name: "selectors",
    valueFn: (selected) => {
      return selected.options && selected.options[0] && selected.options[0].value
        ? selected.options[0].value
        : undefined;
    }
  },
  { name: "multiSelectors", valueKey: "selected" },
  { name: "textFields", valueKey: "value" },
  { name: "dateSelectors", valueKey: "value" },
  { name: "tableSelectors", valueKey: "value"},
  { name: "buttons", valueKey: "value"},
  { name: "fixedComponents"}
];

function PluginList({ reports, selectedIndex, setSelectedIndex, setPluginIndex}) {
  const { mylocale } = useSelector((state) => state);

  const [isOpen, setIsOpen] = useState({});
  const [groupedReports, setGroupedReports] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    const newGroupedReports = reports.reduce((result, report) => {
      const { category } = report;
      if (!result.reports[category]) {
        result.reports[category] = [];
      }
      result.reports[category].push({report: report, i: result.i});
      result.i++;
      return result;
    }, {reports: {}, i: 0});

    for (const reportsKey in newGroupedReports.reports) {
      newGroupedReports.reports[reportsKey] = [...newGroupedReports.reports[reportsKey]].sort((a, b) => {
        const aCaption = intl.formatMessage({ id: a.report.caption.props.id });
        const bCaption = intl.formatMessage({ id: b.report.caption.props.id });

        return aCaption.localeCompare(bCaption, mylocale);
      });
    }

    setGroupedReports(newGroupedReports.reports);
  }, []);

  const handleCategoryClick = (category) => {
    setIsOpen({ ...isOpen, [category]: !isOpen[category] });
  };

  const getTranslation = (key) => {
    if (key === 'working') {
      return <FormattedMessage defaultMessage="Working" id="ReportDialog.working" />;
    } else if (key === 'summary') {
      return <FormattedMessage defaultMessage="Summary report" id="ReportDialog.summary" />;
    } else if (key === 'export') {
      return <FormattedMessage defaultMessage="Export" id="ReportDialog.export" />;
    } else if (key === 'other') {
      return <FormattedMessage defaultMessage="Other" id="ReportDialog.other" />;
    }
    return 'unknown';
  };

  const createListItem = (report, index, offset, pluginIndex) => {
    const isSelected = selectedIndex === index + offset;
    return (
      <ListItemButton
        className=""
        key={`plugin_${index + offset}`}
        style={{
          backgroundColor: isSelected ? '#fce9be' : 'transparent',
          padding: '0 0 0 20px',
          color: isSelected ? 'black' : 'inherit',
          fontWeight: isSelected ? '600' : 'initial',
          cursor: 'pointer',
          marginBottom: '0.5rem'
        }}
        onClick={(e) => {
          setPluginIndex(pluginIndex);
          setSelectedIndex(index + offset);
        }}
      >
        <ListItemText>{report.caption}</ListItemText>
      </ListItemButton>
    );
  };

  if (Array.isArray(groupedReports)) {
    return <List>{groupedReports.map(
      (r, i) => createListItem(r.report, i, 0, r.i))}</List>;
  }

  let indexOffset = 0;
  const indexOffsets = {};
  for (const reportsKey in groupedReports) {
    indexOffsets[reportsKey] = indexOffset;
    indexOffset += groupedReports[reportsKey].length;
    if (!(reportsKey in isOpen)) {
      setIsOpen({ ...isOpen, [reportsKey]: true });
    }
  }
  const subLists = [];
  for (const reportsKey in groupedReports) {
    subLists.push(
      <React.Fragment key={reportsKey}>
        <ListItemButton onClick={() => handleCategoryClick(reportsKey)}>
          {isOpen[reportsKey] ? <ExpandLess /> : <ExpandMore />}
          <ListItemText
            key={`category_${reportsKey}`}
            style={{
              backgroundColor: 'transparent',
              padding: '0 0 0 20px',
              cursor: 'pointer',
              marginBottom: '0.5rem'
            }}
          >
            {getTranslation(reportsKey)}
          </ListItemText>
        </ListItemButton>
        <Collapse in={isOpen[reportsKey]} timeout="auto" unmountOnExit>
          {groupedReports[reportsKey].map(
            (r, i) => createListItem(r.report, i, indexOffsets[reportsKey], r.i)
          )}
        </Collapse>
      </React.Fragment>
    );
  }

  return <List>{subLists}</List>;
}

function ReportDialog({ unmountComponent, selectedNode, selectedValues, catalogIndex }) {
  const cl = useStyles();
  //    const [selectedMultiselectors, setSelectedMultislectors] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});

  const { reports } = useReports({ selectedNode, selectedValues, catalogIndex });
  const [pluginIndex, setPluginIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [fieldInfoMessages, setFieldInfoMessages] = useState({});

  const [UUID, setUUID] = useState(uuidv4());

  const dropInfoMessage = (name) => {
    setFieldInfoMessages(omit(fieldInfoMessages, name));
  }

  const handleTextFieldChange = (name, validationFunc, errMess) => {
    return !validationFunc ? handleChange(name) : (event) => {
      const value = event.target.value;
      const error = validationFunc ? !validationFunc(value) : false;
      if (error) {
        if (errMess) {
          setFieldInfoMessages({ ...fieldInfoMessages, errMess });
        }
      } else {
        if (fieldInfoMessages[name]) {
          dropInfoMessage(name);
        }
        setItems(name, value);
      }
    }
  };

  const handleChange = (name) => (value) => {
    setUUID(uuidv4());
    if (name == 'ra_templates') {
      const valueNew = value.value ? value.value : value.target ? value.target.value : value;
      setCheckedItems((prevState) => {
        delete prevState.rrGroupButton;
        return { ...prevState, [name]: valueNew };
      });
    } else {
      const valueNew = value.value ? value.value : value.target ? value.target.value : value;
      setCheckedItems((prevState) => ({ ...prevState, [name]: valueNew }));
    }
  };

  const handleCheckboxChange = (name) => (value) => {
    setUUID(uuidv4());
    setCheckedItems((prevState) => ({ ...prevState, [name]: value}));
  };

  const setItems = (index, value) => {
    setCheckedItems((prevState) => ({ ...prevState, [index]: value }));
  };
  const getItems = (index) => {
    if (checkedItems[index] !== undefined) {
      return checkedItems[index];
    }
    if (reports[pluginIndex].dateSelectors) {
      const selected = reports[pluginIndex].dateSelectors.find((dates) => dates.name == index);
      if (selected && selected.value) {
        return selected.value;
      }
      if (selected && selected.name == 'reports.from') {
        return moment().clone().startOf('month').format('YYYY-MM-DD hh:mm:00');
      }
      if (selected && selected.name == 'reports.to') {
        return moment().clone().endOf('month').format('YYYY-MM-DD hh:mm:00');
      }
    }
    for (const s of selectors) {
      if (s.name !== "dateSelectors" && s.name in reports[pluginIndex]) {
        const selected = reports[pluginIndex][s.name].find((v) => v.name == index);
        if (selected && s.valueFn) {
          return s.valueFn(selected);
        } else if (selected && s.valueKey) {
          return selected[s.valueKey];
        }
      }
    }
  };

  const getCheckboxState = (checkBox) => {
    if (checkedItems[checkBox.name] !== undefined) {
      return checkedItems[checkBox.name];
    } else if (checkBox["checked"] !== undefined) {
      return checkBox["checked"];
    }
    return false;
  };

  const generateReport = () => {
    reports[pluginIndex].generateReport(getItems, setItems);
    unmountComponent();
  };

  return (
    <Dialog open onClose={unmountComponent} fullWidth maxWidth="lg">
      <DialogTitle>
        <FormattedMessage id="ReportDialog.Title" defaultMessage="Report" />
      </DialogTitle>
      <DialogContent className={cl.DialogContent}>
        <div
          style={{
            flexBasis: '15rem',
            backgroundColor: '#f4f8fa',
            paddingTop: '10px'
          }}
        >
          <PluginList
            reports={reports}
            setPluginIndex={setPluginIndex}
            setSelectedIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
          />
        </div>
        <div
          style={{
            flex: '1',
            paddingTop: '10px',
            paddingLeft: '15px'
          }}
        >
          <div
            style={{
              minHeight: 'calc(100% - 50px)',
              overflowY: 'scroll'
            }}
          >
            {reports[pluginIndex] && reports[pluginIndex].caption && <Typography variant="h1">{reports[pluginIndex].caption}</Typography>}
            {reports[pluginIndex] && reports[pluginIndex].description && <Typography>{reports[pluginIndex].description}</Typography>}
            {reports[pluginIndex] && <ReportOptions
                report={reports[pluginIndex]}
                checkedItems={checkedItems}
                handleChange={handleChange}
                handleCheckboxChange={handleCheckboxChange}
                getCheckboxState={getCheckboxState}
                getItems={getItems}
                selectedNode={selectedNode}
                UUID={UUID}
                handleTextFieldChange={handleTextFieldChange}
              />}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        {reports[pluginIndex] && !reports[pluginIndex].noPrint &&
          <LoadingButton  color="primary" variant="outlined" onClick={generateReport}
            loading={!!reports[pluginIndex]?.disabled}>
            <FormattedMessage id="ReportDialog.Generate" defaultMessage="Generate" />
          </LoadingButton >}
        <Button onClick={unmountComponent} color="primary">
          <FormattedMessage id="ReportDialog.CancelButton" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
      <p>{!!reports[pluginIndex]?.disabled}</p>
    </Dialog>
  );
}

function ReportOptions({report, handleChange, getCheckboxState, handleCheckboxChange,
                         checkedItems, getItems, selectedNode, UUID, handleTextFieldChange}) {
  const [groupedOptions, setGroupedOptions] = useState(null);
  const [openDialogs, setOpenDialogs] = useState({})

  const groupReport = (r) => {
    const baseGroups = r.categories
      ? {...defaultTypes, ...r.categories}
      : defaultTypes;
    const baseGrouping = createInitalTypeMap(baseGroups, selectors);

    for (const selectorType of selectors.map(s => s.name)) {
      if (r[selectorType]) {
        for (const item of r[selectorType]) {
          const key = (item.section && baseGrouping[item.section]) ? item.section : 'other';
          baseGrouping[key].selectorLists[selectorType].push(item);
          baseGrouping[key].containsElements = true;
        }
      }
    }
    // @ts-ignore
    setGroupedOptions(filterAndSortTypeMap(baseGrouping));
  };

  useEffect(() => {
    groupReport(report);
  }, []);

  useEffect(() => {
    groupReport(report);
  }, [report]);


  return (
    <>
      {groupedOptions && groupedOptions.map(group => {
        return (<React.Fragment key={`${report.index}_${group.key}`}>
          {groupedOptions.length > 1 && <Typography variant="h3">{group && group.label}</Typography>}
          {group &&
            group.selectorLists?.radiobtns &&
            group.selectorLists.radiobtns.length > 0 && (
              <FormControl component="fieldset">
                {/*   <FormLabel component="legend">labelPlacement</FormLabel> */}
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue={group.selectorLists.radiobtns[0].value}
                  onChange={handleChange(group.selectorLists.radiobtns[0].name)}
                >
                  {group.selectorLists.radiobtns.map((radiobtn) => (
                    <FormControlLabel
                      value={radiobtn.value}
                      key = {radiobtn.name + "_" + radiobtn.value}
                      control={<Radio color="primary" />}
                      label={radiobtn.label}
                      labelPlacement="end"
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          <Box
            component="div"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" }
            }}
            noValidate
            margin="dense"
            autoComplete="off"
            key={`${report.index}_${group.key}-dateSelectors`}
          >
            {(selectedNode || report.useDate) &&
              group &&
              group.selectorLists?.dateSelectors &&
              group.selectorLists.dateSelectors.map((dates) => (
                <DatePicker
                  disableToolbar
                  variant="inline"
                  margin="normal"
                  id={`${report.index}_${dates.name}`}
                  label={dates.label}
                  invalidDateMessage={
                    <FormattedMessage id="datepicker.invalid" defaultMessage="Invalid date format" />
                  }
                  maxDateMessage={
                    <FormattedMessage
                      id="datepicker.maxDateMessage"
                      defaultMessage="Date should not be after maximal date"
                    />
                  }
                  minDateMessage={
                    <FormattedMessage
                      id="datepicker.minDateMessage"
                      defaultMessage="Date should not be before minimal date"
                    />
                  }
                  key={`${report.index}_${dates.name}`}
                  value={
                    checkedItems[dates.name] != null
                      ? checkedItems[dates.name]
                      : dates.name == "reports.from"
                        ? moment().clone().startOf("month").format("YYYY-MM-DD hh:mm:00")
                        : moment().clone().endOf("month").format("YYYY-MM-DD hh:mm:00")
                  }
                  onChange={handleChange(dates.name)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  renderInput={(props) => <TextField label={"margin=\"normal\""} margin="normal" {...props} />}
                />
              ))}
          </Box>
          <div
            style={{ display: "flex", padding: "0 6px",
              flexDirection: "column" }}
            key={`${report.index}_${group.key}-checkboxSelectors`}
          >
            {group &&
              group.selectorLists?.checkBoxes &&
              group.selectorLists.checkBoxes.map((cb) => (
                <CheckboxWithMemoAlt
                  name={cb.name}
                  key={`${report.index}_${cb.name}`}
                  label={cb.label}
                  isChecked={getCheckboxState(cb)}
                  onChange={handleCheckboxChange(cb.name)}
                />
              ))}
          </div>
          <Box
            component="div"
            sx={{
              "& .MuiFormControl-root": { m: 1 }
            }}
            noValidate
            margin="dense"
            autoComplete="off"
            key={`${report.index}_${group.key}-selectSelectors`}
          >
            {group &&
              group.selectorLists?.selectors &&
              group.selectorLists.selectors.map((selector) => {
                const key = `${report.index}_${selector.name}`;
                return (<SelectField
                  caption={selector.label}
                  value={checkedItems[selector.name] ? checkedItems[selector.name] : selector.options[0].value}
                  key={key}
                  fieldId={key}
                  values={selector.options}
                  onStateChanged={handleChange(selector.name)}
                />);
              })}
          </Box>
          <div style={{ display: "flex", padding: "0 6px", flexDirection: "column" }} key={`${report.index}_${group.key}-multiSelectors`}>
            {group &&
              group.selectorLists?.multiSelectors &&
              group.selectorLists.multiSelectors.map((selector) => {
                const selected = selector.filterFn
                  ? selector.selected.filter(selector.filterFn(getItems))
                  : selector.selected;
                const options = selector.filterFn
                  ? selector.options.filter(selector.filterFn(getItems))
                  : selector.options;
                return (<MultipleSelectNative
                  caption={selector.label}
                  value={selected}
                  key={`${report.index}_${selector.name}`}
                  options={options}
                  onStateChanged={handleChange(selector.name)}
                  useAll={true}
                  useNone={true}
                />);
              })}
          </div>

          <div style={{ display: "flex", padding: "0 6px", flexDirection: "column" }} key={`${report.index}_${group.key}-textFieldsSelectors`}>
            {group &&
              group.selectorLists?.textFields &&
              group.selectorLists.textFields.map((field) => (
                <TextField
                  label={field.label}
                  error={field.errorFunc ? field.errorFunc(field.selected) : false}
                  value={checkedItems[field.name] ? checkedItems[field.name] : field.value}
                  type={field.type}
                  key={`${report.index}_${field.name}`}
                  onChange={handleTextFieldChange(
                    field.name, field?.validationFunc, field?.errMess
                  )}
                />
              ))}
          </div>

          <div style={{ display: "flex", padding: "0 6px", flexDirection: "column" }} key={`${report.index}_${group.key}-buttonsSelectors`}>
            {group &&
              group.selectorLists?.buttons &&
              group.selectorLists.buttons.map((selector) => {
                const Component = selector.component;
                return <Component selected={checkedItems} onConfirm={handleChange(selector.name)} />;
              })}
          </div>
          <div style={{ display: "flex", padding: "0 6px", flexDirection: "column" }} key={`${report.index}_${group.key}-tableSelectors`}>
            {group &&
              group.selectorLists?.tableSelectors &&
              group.selectorLists.tableSelectors.map((selector) => {
                return(
                  <Fragment key={`${selector.name}`}>
                    <Button
                      onClick={() => {
                        setOpenDialogs(prev => ({
                          ...prev,
                          [selector.name]: true}));
                      }}
                      key={`${selector.name}-button`}
                    >
                      {selector.label}
                    </Button>
                    <TableOptionDialog
                      open={openDialogs[selector.name]}
                      onClose={() => {
                        setOpenDialogs(prev => ({
                          ...prev,
                          [selector.name]: false}));
                      }}
                      value={checkedItems[selector.name]
                        ? checkedItems[selector.name] : selector.value}
                      caption={selector.caption}
                      title={selector.title}
                      key={`${selector.name}-dialog`}
                      columns={selector.columns}
                      hasDefault={selector.hasDefault}
                      useDefaultLabel={selector.useDefaultLabel}
                      onStateChanged={handleChange(selector.name)}
                    />
                  </Fragment>);
              })}
          </div>
          <div style={{ display: "flex", padding: "0 6px", flexDirection: "column" }} key={`${report.index}_${group.key}-fixedSelectors`}>
            {group &&
              group.selectorLists?.fixedComponents &&
              group.selectorLists.fixedComponents}
          </div>
        </React.Fragment>);
      })}

    </>
  );
}

export default ReportDialog;
