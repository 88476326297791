import { showSnackbarChangesSaved, showSnackbarError } from '@zert-packages/components/Snackbars';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { createInProgressSnack } from '@zert-packages/components/common/snackBar';
import { addElementsToFolderApi } from '@zert-packages/plugins/ExplorerPlugin/API';
import { reportMockUp } from '@zert-packages/utils/reportMockUp';
import { placeElementNew } from './catalogActions';
import {
  handlePost,
  handlePostFile,
  handleQuery,
  fetchRA_Error,
  fetchSTORE_Error,
  fetchElementStored,
  fetchElementStoreStart,
  fetchPerformMeasureError,
  fetchElementCreated,
  fetchElementCreate
} from './api';
import {
  FETCH_DEVIATION_TEMPLATES_SUCCESS,
  FETCH_DEVIATION_TEMPLATE_BEGIN,
  FETCH_DEVIATION_TEMPLATE_SUCCESS,
  FETCH_DEVIATION_TEMPLATES_BEGIN,
  FETCH_DEVIATION_TEMPLATE_WORKFLOW_SUCCESS,
  FETCH_DEVIATION_SUCCESS,
  FETCH_DEVIATION_BEGIN,
  FETCH_DEVIATION_PERFORMED,
  FETCH_DEVIATION_PERFORM
} from './actions';
import {
  fetchRA_WorkFlowTemplate_Success,
  fetchRA_MeasureTemplate_Success,
  fetchRA_Template_Success,
  fetchRABasisUpload_Begin,
  fetchRABasisUpload,
  fetchRABasisUpload_DONE
} from './riskassesmentActions';
import { checkReportStatus } from './checklistActions';

const fetch_DEVIATION_SUCCESS = (deviation) => ({
  type: FETCH_DEVIATION_SUCCESS,
  payload: { deviation }
});

const fetch_DEVIATION_BEGIN = (deviation) => ({
  type: FETCH_DEVIATION_BEGIN,
  payload: {}
});

const fetch_DEVIATION_TEMPLATES_SUCCESS = (templates) => ({
  type: FETCH_DEVIATION_TEMPLATES_SUCCESS,
  payload: { templates }
});

const fetch_DEVIATION_TEMPLATE_BEGIN = () => ({
  type: FETCH_DEVIATION_TEMPLATE_BEGIN,
  payload: {}
});

const fetch_DEVIATION_TEMPLATE_SUCCESS = (template) => ({
  type: FETCH_DEVIATION_TEMPLATE_SUCCESS,
  payload: { template }
});

const fetch_DEVIATION_TEMPLATES_BEGIN = () => ({
  type: FETCH_DEVIATION_TEMPLATES_BEGIN,
  payload: {}
});

const fetchDeviationPerformed = (deviation) => ({
  type: FETCH_DEVIATION_PERFORMED,
  payload: { deviation }
});

const fetchPerformDeviation = () => ({
  type: FETCH_DEVIATION_PERFORM,
  payload: {}
});

const fetch_DEVIATION_TEMPLATE_WORKLFOW_SUCCES = (workflowtemplate) => ({
  type: FETCH_DEVIATION_TEMPLATE_WORKFLOW_SUCCESS,
  payload: { workflowtemplate }
});

export function storeDeviation(id, data) {
  return function (dispatch, getState, hooks) {
    handlePost(`/deviation-react/initDeviation/${id}`, data)
      .then((id) => {
        showSnackbarChangesSaved(hooks.snackbar);
        dispatch(fetchElementStored(id));
      })
      .catch((error) => dispatch(fetchSTORE_Error(error)));
    return dispatch(fetchElementStoreStart());
  };
}

export function initDeviation(id, data, placement1, folderId, versionId, activityId) {
  let idElement = -1;
  return function (dispatch) {
    handlePost(`/deviation-react/initDeviation/${id}`, data)
      .then((info) => {
        idElement = info;
        if (folderId) {
          return addElementsToFolderApi(folderId, [idElement]);
        }
        return null;
      })
      .then(() => {
        return placeElementNew(idElement, placement1);
      })
      .then(() => {
        dispatch(fetchElementCreated(idElement, versionId, activityId));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchElementCreate());
  };
}

export function performDeviation(taskId, nextTransition) {
  return function (dispatch) {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    handleQuery(`/actionplan-react/performDeviation/${locale}/${taskId}/${nextTransition}`)
      .then((deviation) => {
        dispatch(fetchDeviationPerformed(deviation));
      })
      .catch((error) => {
        dispatch(fetchPerformMeasureError(error));
      });
    return dispatch(fetchPerformDeviation());
  };
}

export function createDeviation(deviationtemplate, olddeviation) {
  const deviation = {
    cells: [],
    class: 'se.zert.rm.rest.deviation.pojo.ClientDeviation',
    changed: false,
    template: { versionId: deviationtemplate.versionId },
    reporter: null,
    anonymous: false,
    elementId: olddeviation ? olddeviation.elementId : -1,
    riskRows: olddeviation ? olddeviation.riskRows : []
  };
  deviationtemplate.columns.map((col) => {
    let value = null;
    let cellIndentifier = 'se.zert.rm.rest.template.cell.pojo.';
    if (col.class.includes('IntegerColumn')) {
      cellIndentifier += 'ClientIntegerCell';
      value = 0;
    } else if (col.class.includes('UserColumn')) {
      cellIndentifier += 'ClientUserCell';
    } else if (col.class.includes('MultiSelectColumn')) {
      cellIndentifier += 'ClientMultiSelectCell';
      value = [];
    } else if (col.class.includes('SelectColumn')) {
      cellIndentifier += 'ClientSelectCell';
    } else if (col.class.includes('TextColumn')) {
      cellIndentifier += 'ClientTextCell';
    } else if (col.class.includes('ElementColumn')) {
      cellIndentifier += 'ClientElementCell';
      value = [];
    } else if (col.class.includes('DateColumn')) {
      cellIndentifier += 'ClientDateCell';
    } else {
      return;
    }
    if (olddeviation) {
      const mycell = olddeviation.cells.find((cell) => cell.columnIdentifier == col.identifier);
      if (mycell != null) {
        value = mycell.value;
      }
    }

    const cell = {
      class: cellIndentifier,
      columnIdentifier: col.identifier,
      changed: false,
      editable: true,
      value
    };
    deviation.cells.push(cell);
  });
  return deviation;
}

export function loadDeviationTemplates() {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');

  return function (dispatch) {
    handleQuery('/deviation-react/getAllTemplates/')
      .then((templates) => {
        dispatch(fetch_DEVIATION_TEMPLATES_SUCCESS(templates));
        if (templates != null && templates[0] && templates[0].versionId) {
          return handleQuery(`/deviation-react/getDeviationTemplate/${locale}/${templates[0].versionId}`);
        }
        return null;
      })
      .then((templateBundle) => {
        if (templateBundle) {
          dispatch(fetchRA_WorkFlowTemplate_Success(templateBundle.measuresWorkflowProcess));
          dispatch(fetchRA_MeasureTemplate_Success(templateBundle.measureTemplate));
          dispatch(fetchRA_Template_Success(templateBundle.riskRowTemplate));
          dispatch(fetch_DEVIATION_TEMPLATE_WORKLFOW_SUCCES(templateBundle.workflowProcess));
          dispatch(fetch_DEVIATION_TEMPLATE_SUCCESS(templateBundle.deviationTemplate));
        }
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetch_DEVIATION_TEMPLATES_BEGIN());
  };
}

export function uploadBasis(filedata, idElement) {
  return function (dispatch) {
    handlePostFile('/deviation-react/uploadFile', filedata)
      .then((basis) => {
        dispatch(fetchRABasisUpload(basis));
        dispatch(fetchRABasisUpload_DONE());
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchRABasisUpload_Begin(idElement));
  };
}

export function loadDeviation(deviationId) {
  return function (dispatch) {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    handleQuery(`/deviation-react/getDeviation/${locale}/${deviationId}`)
      .then((deviation) => {
        dispatch(fetch_DEVIATION_SUCCESS(deviation));
        return handleQuery(`/deviation-react/getDeviationTemplate/${locale}/${deviation.deviation.template.versionId}`);
      })
      .then((templateBundle) => {
        dispatch(fetchRA_WorkFlowTemplate_Success(templateBundle.measuresWorkflowProcess));
        dispatch(fetchRA_MeasureTemplate_Success(templateBundle.measureTemplate));
        dispatch(fetchRA_Template_Success(templateBundle.riskRowTemplate));
        dispatch(fetch_DEVIATION_TEMPLATE_WORKLFOW_SUCCES(templateBundle.workflowProcess));
        dispatch(fetch_DEVIATION_TEMPLATE_SUCCESS(templateBundle.deviationTemplate));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetch_DEVIATION_BEGIN());
  };
}

export function clearDeviation() {
  return function (dispatch) {
    dispatch(fetch_DEVIATION_TEMPLATE_WORKLFOW_SUCCES(null));
    dispatch(fetch_DEVIATION_SUCCESS(null));
    dispatch(fetch_DEVIATION_TEMPLATE_SUCCESS(null));
  };
}

export function clearDeviationTemplate() {
  return function (dispatch) {
    dispatch(fetch_DEVIATION_TEMPLATE_SUCCESS(null));
  };
}

export function loadDeviationTemplate(idTemplate) {
  return function (dispatch) {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    handleQuery(`/deviation-react/getDeviationTemplate/${locale}/${idTemplate}`)
      .then((templateBundle) => {
        dispatch(fetchRA_WorkFlowTemplate_Success(templateBundle.measuresWorkflowProcess));
        dispatch(fetchRA_MeasureTemplate_Success(templateBundle.measureTemplate));
        dispatch(fetchRA_Template_Success(templateBundle.riskRowTemplate));
        dispatch(fetch_DEVIATION_TEMPLATE_WORKLFOW_SUCCES(templateBundle.workflowProcess));
        dispatch(fetch_DEVIATION_TEMPLATE_SUCCESS(templateBundle.deviationTemplate));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetch_DEVIATION_TEMPLATE_BEGIN());
  };
}

export const generateDeviationReport =
  (report, catalogVersionId, nodeUUID, scope, data) =>
  (dispatch, getState, { snackbar }) => {
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="DeviationOverviewReport.GeneratingLabel" defaultMessage="...generating report" />
    );

    handlePost(
      `/deviation-react/generateDeviationReport/${report.reportUUID}/${catalogVersionId}/${scope}/${nodeUUID}`,
      data
    )
      .then((callback) => {
        checkReportStatus(callback, snackbar, generatingSnackbar, report, 'pdf');
      })
      .catch((error) => {
        showSnackbarError(snackbar, error);
      });
  };

export const generateXLSDeviationsReport =
  (report, catalogVersionId, nodeUUID, scope, includeAll, startDate, endDate, clientColumns, versionIds, includeMode) =>
    (dispatch, getState, { snackbar }) => {
      const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
      const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="Submits.GeneratingLabel.spreadsheet" defaultMessage="...Exporting submits to SpreadSheet" />
      );

      handlePost(`/report-react/generateReport/ ${report.reportUUID}/exportDeviationXLS`, {
        ...reportMockUp,
        catalogVersionId,
        nodeUUID: nodeUUID || 'none',
        scope,

        includeArchived: includeAll,
        locale,
        object: JSON.stringify({
          reportSettings: {
            startDate,
            endDate,
            templateElementId: -1,
            clientColumns
          },
          versionIds,
          includeMode
        })
      })
      .then((callback) => {
        checkReportStatus(callback, snackbar, generatingSnackbar, report, 'xls', null, null, 'export');
      })

      .catch((error) => {
        showSnackbarError(snackbar, error);
      });
  };
