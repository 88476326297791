export function getOverview(cells, positionHistory, residual, template) {
  const matrixCells = [];
  template.columns.filter((col) => {
    if (col.class.includes('MatrixColumn')) {
      const cell = getValue(col.identifier, cells, positionHistory, residual);
      if (cell != null) {
        matrixCells.push(cell);
      }
    }
  });
  return matrixCells;
}

export function calculateColorCoverage(matrix) {
  let numberMax = 0;

  let redColor = '';
  var numberMin = 0;
  var greenColor = '';
  let map = matrix.defaultValueMap;
  if (matrix.valueMaps != null) {
    const mapNew = matrix.valueMaps.find((mapmin) => {
      return mapmin.filter == null;
    });
    if (mapNew != null) {
      map = mapNew;
    }
  }
  //  var jmax =  matrix.dimensions.length > 1 ?  matrix.dimensions[1].values.length : 1;
  for (var i = 0; i < map.values.length; i++) {
    var value = map.values[i];
    if (value.number > numberMax) {
      redColor = value.color;
      numberMax = value.number;
    }
  }
  var numberMin = numberMax;
  var greenColor = '';
  for (var i = 0; i < map.values.length; i++) {
    var value = map.values[i];
    if (value.number <= numberMin) {
      greenColor = value.color;
      numberMin = value.number;
    }
  }

  return { redColor, greenColor, numberMin, numberMax };
}

export function getPositionByIdentifier(dimensionIdentifier, position) {
  return position.find((pos) => {
    return pos === undefined ? undefined : dimensionIdentifier == pos.name;
  });
}

export function getValue(columnidentifier, cells, positionHistory, residual) {
  if (!residual) {
    for (let j = 0; j < cells.length; j++) {
      if (cells[j].columnIdentifier === columnidentifier) {
        return cells[j];
      }
    }
  } else {
    let history = null;
    for (let j = 0; j < positionHistory.length; j++) {
      if (positionHistory[j].key == columnidentifier) {
        history = positionHistory[j];
      }
    }
    if (history == null) {
      for (let j = 0; j < cells.length; j++) {
        if (cells[j].columnIdentifier === columnidentifier) {
          const result = cells[j];
          const cell = {
            class: 'se.zert.rm.rest.template.cell.pojo.ClientMatrixCell',
            columnIdentifier: columnidentifier,
            changed: false,
            editable: true,
            selection: {
              position: []
            },
            notSignificantValue: false
          };
          // var selection = Object.assign({}, cell.selection);

          history = {
            key: columnidentifier,
            matrixCell: cell
          };

          return history.matrixCell;
        }
      }
    } else {
      return history.matrixCell;
    }
  }
  return undefined;
}

export function getBlockPosMulti(matrix, position) {
  let result = 1;
  //  var map =  matrix.valueMaps.length > 0 ?  matrix.valueMaps[0] :
  //   matrix.defaultValueMap;

  let map = matrix.defaultValueMap;
  if (matrix.valueMaps != null) {
    const mapNew = matrix.valueMaps.find((mapmin) => {
      return mapmin.filter == null;
    });
    if (mapNew != null) {
      map = mapNew;
    }
  }

  if (position && position.length == map.dimentionSize.length) {
    map.dimentionSize.forEach(function (size) {
      result *= size;
    });
    let blockSize = result;
    let blockPos = 0;
    // console.log(matrix);

    for (let i = 0; i < map.dimentionSize.length; i++) {
      blockSize /= map.dimentionSize[i];
      const pos = getPositionByIdentifier(map.dimentionIdentifier[i], position);

      if (pos) {
        const { index } = pos;
        blockPos += index * blockSize;
      }
    }

    return blockPos;
  }
  return -1;
}

export function getBlockPos(matrix, dxPos, dyPos) {
  let result = 1;
  let map = matrix.defaultValueMap;
  if (matrix.valueMaps != null) {
    const mapNew = matrix.valueMaps.find((mapmin) => {
      return mapmin.filter == null;
    });
    if (mapNew != null) {
      map = mapNew;
    }
  }

  map.dimentionSize.forEach(function (size) {
    result *= size;
  });
  let blockSize = result;
  let blockPos = 0;
  // console.log(matrix);

  for (let i = 0; i < map.dimentionSize.length; i++) {
    blockSize /= map.dimentionSize[i];
    const index = i == 0 ? dxPos : dyPos;
    blockPos += index * blockSize;
  }

  return blockPos;
}

export function calculateCellOverview(cell, matrix, column) {
  let overview = null;
  if (matrix.dimensions.length == 2) {
    overview = calculateCellOverview2Dimension(cell, matrix, column);
  } else if (matrix.dimensions.length > 2) {
    overview = calculateCellOverviewMultiDimension(cell, matrix, column);
  } else {
    overview = calculateCellOverview1Dimension(cell, matrix, column);
  }
  return overview;
}

export function calculateRowOverview(cells, positionHistory, residual, template) {
  const matrixCells = getOverview(cells, positionHistory, residual, template);
  const cellOverviews = [];

  matrixCells.map((cell) => {
    let matrix = null;
    let column = null;
    const matrixes = template.columns.map((col) => {
      if (col.identifier == cell.columnIdentifier) {
        matrix = col.matrix;
        column = col;
      }
    });
    cellOverviews.push(calculateCellOverview(cell, matrix, column));
  });
  let maxOverview = null;
  let score = 0;
  cellOverviews.map((overview) => {
    if (maxOverview == null || overview.score > maxOverview.score) {
      maxOverview = overview;
    }
    score = overview.score + score;
  });
  if (maxOverview != null) {
    maxOverview.score = score;
  }
  return maxOverview;
}

export function calculateCellOverviewMultiDimension(cell, matrix, col) {
  // var dimensionX = matrix.dimensions[0].identifier;
  let isRed = false;
  let isYellow = false;
  const stateselectorX = 0;
  const index = -1;
  let score = 0;
  const coverage = calculateMultiColorCoverage(matrix);
  let map = matrix.defaultValueMap;
  if (matrix.valueMaps != null) {
    const mapNew = matrix.valueMaps.find((mapmin) => {
      return mapmin.filter == null;
    });
    if (mapNew != null) {
      map = mapNew;
    }
  }
  var value = null;
  if (
    cell !== undefined &&
    cell.selection != null &&
    cell.selection.position != null &&
    cell.selection.position.length == map.dimentionSize.length
  ) {
    //  index = cell.selection.position[stateselectorX].index;
    //   value = map.values[cell.selection.position[stateselectorX].index];
    const pos = getBlockPosMulti(matrix, cell.selection.position);
    //  alert(pos);
    var value = map.values[pos];

    isRed = value.color === coverage.redColor;
    isYellow = !isRed && !(value.color === coverage.greenColor);
    score = value.number;
  }
  isRed = isRed && !cell.notSignificantValue;
  isYellow = isYellow && !cell.notSignificantValue;

  return {
    isRed,
    isYellow,
    score,
    notSignificantValue: cell && cell.notSignificantValue,
    columnIdentifier: cell && cell.columnIdentifier,
    cell,
    col
  };
}

export function calculateCellOverview1Dimension(cell, matrix, col) {
  const dimensionX = matrix.dimensions[0].identifier;
  let isRed = false;
  let isYellow = false;
  const stateselectorX = 0;
  let index = -1;
  let score = 0;
  const coverage = calculateColorCoverage(matrix);
  let map = matrix.defaultValueMap;
  if (matrix.valueMaps != null) {
    const mapNew = matrix.valueMaps.find((mapmin) => {
      return mapmin.filter == null;
    });
    if (mapNew != null) {
      map = mapNew;
    }
  }
  let value = null;
  if (cell !== undefined && cell.selection.position.length == 1) {
    index = cell.selection.position[stateselectorX].index;
    value = map.values[cell.selection.position[stateselectorX].index];
    isRed = value.color === coverage.redColor;
    isYellow = !isRed && !(value.color === coverage.greenColor);
    score = value.number;
  }
  isRed = isRed && !cell.notSignificantValue;
  isYellow = isYellow && !cell.notSignificantValue;

  return {
    isRed,
    isYellow,
    score,
    notSignificantValue: cell.notSignificantValue,
    columnIdentifier: cell.columnIdentifier,
    cell,
    col
  };
}

export function calculateCellOverview2Dimension(cell, matrix, col) {
  const dimensionY = matrix.dimensions[1].identifier;
  const dimensionX = matrix.dimensions[0].identifier;

  const coverage = calculateColorCoverage(matrix);
  let isRed = false;
  let isGreen = false;
  let stateselectorY = 1;
  let stateselectorX = 0;
  let score = 0;

  let map = matrix.defaultValueMap;
  if (matrix.valueMaps != null) {
    const mapNew = matrix.valueMaps.find((mapmin) => {
      return mapmin.filter == null;
    });
    if (mapNew != null) {
      map = mapNew;
    }
  }

  if (cell != null && cell.selection != null && cell.selection != undefined && cell.selection.position.length >= 2) {
    stateselectorY = dimensionY == cell.selection.position[1].name ? 0 : 1;
    stateselectorX = dimensionX == cell.selection.position[0].name ? 1 : 0;
  }

  for (let j = 0; j < matrix.dimensions[1].values.length; j++) {
    for (let i = 0; i < matrix.dimensions[0].values.length; i++) {
      if (
        cell !== undefined &&
        cell.selection.position.length >= 2 &&
        cell.selection.position[stateselectorY].index === j &&
        cell.selection.position[stateselectorX].index === i
      ) {
        const value = map.values[getBlockPos(matrix, j, i)];
        isRed = value.color === coverage.redColor;
        isGreen = value.color === coverage.greenColor;
        score = value.number;
      }
    }
  }

  // var columns = rows[0].props.chidren;
  // columns[0].selected = true;
  isRed = isRed && !(cell && cell.notSignificantValue);
  //  isYellow = isYellow && !cell.notSignificantValue;

  return {
    isRed,
    isYellow: !isRed && !isGreen && !(cell && cell.notSignificantValue),
    score,
    notSignificantValue: cell && cell.notSignificantValue,
    columnIdentifier: cell && cell.columnIdentifier,
    cell,
    col
  };

  // if ((MatrixCell)cell).getColor().getGreen()
}

export function calculateMultiColorCoverage(matrix) {
  let numberMax = 0;

  let redColor = '';
  var numberMin = 0;
  var greenColor = '';
  let map = matrix.defaultValueMap;
  if (matrix.valueMaps != null) {
    const mapNew = matrix.valueMaps.find((mapmin) => {
      return mapmin.filter == null;
    });
    if (mapNew != null) {
      map = mapNew;
    }
  }
  map.values.map((value) => {
    if (value.number > numberMax) {
      redColor = value.color;
      numberMax = value.number;
    }
  });

  var numberMin = numberMax;
  var greenColor = '';
  map.values.map((value) => {
    if (value.number <= numberMin) {
      greenColor = value.color;
      numberMin = value.number;
    }
  });

  return { redColor, greenColor, numberMin, numberMax };
}
