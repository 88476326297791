import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { adobeColor, adobeRgba, adobeWord, handleTableSingleLevel } from "../../utils/helpers";
import { TabsContext } from '../../Stylesheet';
import TemplateItemRow from '../../TemplateItemRow';
import SelectedColor from '../../SelectedColor';
import DialogColors from '../../dialog/DialogColors';
import { fontData } from '../../data/fontData';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1rem',
    fontWeight: '700',
    marginBottom: 5
  },
  borderBlock: {
    border: '1px solid #C6C6C5',
    padding: '5px 10px',
    display: 'grid',
    gridRowGap: 10,
    paddingBottom: 15
  },
  borderBlockRow: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gridColumnGap: 10
  },
  wrapperCheckbox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr ',
    maxWidth: 500
  }
}));

function LabelBlock() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const image = values.stylesheet.imageSettings[values.selectedLeftMenu];
  const [inline, setInline] = useState('User defined');
  const [color, setColor] = useState({
    backgroundColor: '#ffffff',
    load: false,
  })


  const handleExpanderItem = (val, name) => {
    handleTableSingleLevel(val.toUpperCase(), name, 'imageSettings', values, setValues);
  };

  const handleValues = (val, name) => {
    const result = { ...image[name], unit: image[name] ? image[name].unit : 'pt', value: +val };
    handleTableSingleLevel(result, name, 'imageSettings', values, setValues);
  };
  const setDropMenuFirstInline = (val, name) => {
    if (val === 'Default') {
      const result = { unit: 'pt', value: 6 };
      handleTableSingleLevel(result, name, 'imageSettings', values, setValues);
    }
    setInline(val);
  };

  const handleDropMenu = (valDropMenu, name, val) => {
    const result = { unit: valDropMenu, value: +val };
    handleTableSingleLevel(result, name, 'imageSettings', values, setValues);
  };

  const handleColor = (val, name) => {
    setColor(pre => ({ ...pre, backgroundColor:  val}))
    handleTableSingleLevel(adobeRgba(val), name, 'imageSettings', values, setValues);
  };

  const handleOpenDialog = (type) => {
    renderInExceptionRoot(DialogColors, {
      color: image[type],
      title: 'Select Color',
      setText: handleColor,
      type
    });
  };

  const findCheckbox = (el) => {
    return image.labelFontStyle.map((it) => it.toLowerCase()).find((it) => it === el.toLowerCase());
  };

  const handleCheckboxFontStyle = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    if (val) {
      const result = [...image.labelFontStyle, name.toUpperCase()];
      return handleTableSingleLevel(result, 'fontStyle', 'imageSettings', values, setValues);
    }
    const result = image.labelFontStyle.filter((el) => el !== name.toUpperCase());
    handleTableSingleLevel(result, 'labelFontStyle', 'imageSettings', values, setValues);
  };

  useEffect(() => {
    if(!color.load && image.labelColor){
      setColor(pre => ({
        ...pre,
        backgroundColor: adobeColor(image.labelColor),
        load: true}))
    }
  }, [image.labelColor])


  return (
    <div>
      <p className={cl.title}>Label</p>
      <div className={cl.borderBlock}>
        <div className={cl.borderBlockRow}>
          <p className={cl.title}>Content:</p>
          <ExpanderItem
            valueProps={adobeWord(image.imageLabel, true).replace('_', '')}
            propsLabel={['None', 'Name', 'VersionId']}
            setProps={handleExpanderItem}
            type="imageLabel"
          />
        </div>

        <div className={cl.borderBlockRow}>
          <p className={cl.title}>Position:</p>
          <ExpanderItem
            valueProps={adobeWord(image.labelPosition, true)}
            propsLabel={['Top', 'Bottom', 'Left', 'Right']}
            setProps={handleExpanderItem}
            type="labelPosition"
          />
        </div>

        <div className={cl.borderBlockRow}>
          <p className={cl.title}> Font family:</p>
          <ExpanderItem
            valueProps={adobeWord(image.labelFontFamily, true)}
            setProps={handleExpanderItem}
            type="labelPosition"
          />
        </div>
        <TemplateItemRow
          label="Font size:"
          type="labelFontSize"
          dropMenuFirst={{
            label: ['Default', 'User defined'],
            selected: inline
          }}
          value={{
            value: image.labelFontSize ? image.labelFontSize.value : '6',
            unit: image.labelFontSize ? image.labelFontSize.unit : 'POINTS'
          }}
          grid="100px 150px 1fr 55px"
          setValueProps={handleValues}
          setDropMenuFirst={setDropMenuFirstInline}
          setDropMenu={handleDropMenu}
          disabled={inline === 'Default'}
        />
        <div className={cl.borderBlockRow}>
          <span className={cl.title}>Font style:</span>
          <div className={cl.wrapperCheckbox}>
            {fontData.map((el, i) => (
              <div key={i}>
                <FormControlLabel
                  control={
                    <Checkbox onChange={handleCheckboxFontStyle} checked={findCheckbox(el.name)} name={el.name} />
                  }
                  label={<span>{el.name}</span>}
                />
              </div>
            ))}
          </div>
        </div>
        <SelectedColor
          label="Color:"
          type="labelColor"
          setValueProps={handleColor}
          style={{ grid: '100px 30px 1fr 25px' }}
          handleOpenDialog={handleOpenDialog}
          valueProps={color.backgroundColor}
        />
      </div>
    </div>
  );
}

export default LabelBlock;
