import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getTileElementStatusMessage } from '@zert-packages/components/ElementTile/utils/getTileStatus';
import { generateChecklistReport, generateSingleSafetyInspectionReport } from '@zert-packages/actions/checklistActions';
import { useAppSelector } from '@zert-packages/react/hooks/hooks';
import { useCallback } from 'react';
import Reports from '@zert-packages/components/common/Reports';

const checklistReportBase = {
  id: 'checklist.printchecklist',
  reportUUID: uuidv4(),
  diagramId: 0,
  includeMatrix: false,
  showImagesSeparatly: false,
  isPrint: false
};

const REPORT_TYPES = {
  CURRENT_CHECKLIST: 1,
  SI_OVERVIEW: 2
};

export default function SafetyInspectionReports({checklist, safetyInspectionId}){
  const dispatch = useDispatch();
  const myuser = useAppSelector((state) => state.myuser);
  const intl = useIntl();

  const printReport = useCallback((reportInfo) => {
    const report = { ...reportInfo.report };
    if (reportInfo.type === REPORT_TYPES.CURRENT_CHECKLIST) {
      report.elementName = checklist.name;
      report.elementName = getTileElementStatusMessage(checklist, myuser, intl);
      dispatch(
        generateChecklistReport(
          safetyInspectionId,
          report,
          report.diagramId,
          report.includeMatrix,
          report.showImagesSeparatly,
          report.isPrint
        )
      );
    } else if (reportInfo.type === REPORT_TYPES.SI_OVERVIEW) {
      dispatch(generateSingleSafetyInspectionReport(safetyInspectionId, report));
    }
    return;
  }, [checklist, myuser, intl, safetyInspectionId]);

  const safetyInspectionReportName = <FormattedMessage
    id='safetyinspectionsummary.report'
    defaultMessage='Safety Inspection report'
  />;

  const reports = checklist?.versionId
    ? [{
        name: <FormattedMessage
          id={'si.report.checklist'}
          defaultMessage={'Checklist report'}
        />,
        report: checklistReportBase,
        type: REPORT_TYPES.CURRENT_CHECKLIST,
        reportIndex: 0
      },
      {
        name: safetyInspectionReportName,
        report: {
          name: safetyInspectionReportName,
          reportUUID: uuidv4()
        },
        type: REPORT_TYPES.SI_OVERVIEW,
        reportIndex: 1
      }] : [];

  if (reports.length > 0) {
    // @ts-ignore
    return <Reports openByHover={true} defaultReport={reports[0]}
                    printReport={printReport} reports={reports} />;
  } else {
    return <></>;
  }
}