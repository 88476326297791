import React, { memo } from 'react';
import { TextField } from '@mui/material';
import addDefaultLanguages from '@zert-packages/utils/addDefaultLanguages';
import withLanguageSwitch from '@zert-packages/utils/withLanguageSwitch';
import getDefaultLabel from '@zert-packages/utils/getDefaultLabel';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';

function TextFieldWithLanguageSwitchValue({
  value: initialState = getDefaultLabel(),

  onChange,
  languageSwitcher,
  indexOfTranslation,
  findIndexOfLanguages,
  style,
  ...other
}) {
  const state = addDefaultLanguages(initialState);
  findIndexOfLanguages(state.locales);
  const handleOnChange = (event) => {
    state.translations[indexOfTranslation] = event.target.value;
    onChange({ ...state });
  };

  const initialStyle = {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
    alignItems: 'center',
    width: '100%'
  };
  return (
    <div style={{ ...initialStyle, ...style }}>
      {languageSwitcher}
      <TextField
        margin="dense"
        variant="outlined"
        value={state.translations[indexOfTranslation]}
        onChange={handleOnChange}
        {...other}
      />
    </div>
  );
}

export default withLanguageSwitch(TextFieldWithLanguageSwitchValue);

export const TextFieldWithLanguageSwitchMemo = memo(
  withLanguageSwitch(TextFieldWithLanguageSwitchValue),
  selectiveMemoComparison(
    (p) => p.disabled,
    (p) => p.state,
    (p) => p.disabled
  )
);
