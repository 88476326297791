import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import createStyles from "@mui/styles/createStyles";
import { makeStyles } from "@mui/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import getAllPlugins from "@zert-packages/utils/getAllPlugins";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 482,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700
  },
  wrapperButton: {
    marginTop: 10,
    display: "flex",
    justifyContent: "end",
    paddingRight: 20
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

function MimeTypeDialog({ handleClose, successHandler }) {
  const cl = useStyles();
  const [chosenPlugin, setChosenPlugin] = useState(null);

  const pluginChooseHandler = (plugin) => {
    setChosenPlugin(plugin);
  };

  const acceptHandler = () => {
    successHandler(`application/zert-${chosenPlugin.mimetype}`);
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open classes={{ paperScrollPaper: cl.root }}>
      <div className={cl.wrapperTitle}>
        <span className={cl.title}>Add mime-type</span>
        <CloseIcon onClick={handleClose} />
      </div>

      <DialogContent style={{ height: "20rem", width: "100%", background: "#f2f2f2", padding: 4 }}>
        <List>
          {getAllPlugins().map((plugin) => {
            if (plugin.mimetype) {
              return (
                <ListItem
                  key={plugin.id}
                  component="div"
                  disablePadding
                  style={{ height: "20px", textTransform: "capitalize", width: "100%", marginBottom: 8 }}
                >
                  <ListItemText
                    disableTypography
                    style={chosenPlugin !== null && chosenPlugin.id === plugin.id
                      ? { background: "#01A1C7" }
                      : {cursor: 'pointer'}}
                    button
                    selected={chosenPlugin !== null && chosenPlugin.id === plugin.id}
                    onClick={(event) => pluginChooseHandler(plugin)}
                    primary={
                      <Typography
                        style={
                          chosenPlugin !== null && chosenPlugin.id === plugin.id
                            ? {
                              fontSize: "1.2em",
                              color: "#ffffff"
                            }
                            : {
                              fontSize: "1.2em",
                              color: "black"
                            }
                        }
                      >
                        {plugin.title.text.name}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            }
          })}
        </List>
      </DialogContent>

      <div className={cl.wrapperButton}>
        <Button
          disabled={chosenPlugin === null}
          onClick={acceptHandler}
          className={cl.button}
        >
          OK
        </Button>
        <Button
          onClick={handleClose}
          className={cl.button}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}

export default MimeTypeDialog;
