import get from 'lodash/get';
import getLang from './getLang';

const _getTranslation = (label, langIdentifier) => {
  const locales = get(label, 'locales', []) || [];
  if (locales.length === 0) return '';

  const translations = get(label, 'translations', []) || [];
  if (translations.length === 0) return '';

  const localeKey = locales.find((l) => l === langIdentifier);

  if (get(translations, `[${localeKey}]`, '').trim()) {
    return translations[localeKey];
  }
  const localeIndex = locales.findIndex((l) => l === langIdentifier);
  if (get(translations, `[${localeIndex}]`,'').trim()) {
    return translations[localeIndex];
  }
  const firstNotEmpty = translations.find((t) => t.trim().length !== 0);
  return firstNotEmpty || '';
};

export const getTranslationByIdentifier = (label, identifier) => _getTranslation(label, identifier);

const getTranslation = (label) => {
  const langIdentifier = getLang();
  return _getTranslation(label, langIdentifier);
};

export default getTranslation;

export const langIdentifiers = {
  en: 'en',
  sv: 'sv'
};
