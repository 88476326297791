const createParentsListNode = (node, nodeUUID) => {
  for (const child of node.children) {
    if (child.uuid === nodeUUID) return [child];
    const foundChild = createParentsListNode(child, nodeUUID);
    if (foundChild) return [child, ...foundChild];
  }
  return [];
};

export default createParentsListNode;
