import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import SkeletonLoader from "../../SkeletonLoader";
import { handleValue } from "../../../../BlockPlugin/utils/main";

const useStyles = makeStyles((theme) => ({
  wrapperList: {
    display: "grid",
    gridTemplateColumns: "repeat(20, 1fr)",
    gridColumnGap: 5
  },
  cell: (props) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: props.selectedAvailableFontFamily,
    fontSize: props.fontSizeSymbol,
    cursor: "pointer"
  }),
  selectedCell: {
    cursor: "not-allowed",
    background: '#01A1C7',
    color: '#ffffff'
  }
}));

const UnicodeList = ({ symbol, setSymbol }) => {
  const { selectedAvailableFontFamily, fontSizeSymbol } = symbol;
  const cl = useStyles({ selectedAvailableFontFamily, fontSizeSymbol });

  const handleSymbol = (el, selected) => {
    if(selected){
      return
    }
    // handleValue(el, "selectedSymbol", setSymbol);
    handleValue(false, false, setSymbol, {
      selectedSymbol: el,
      filterUnicodeValue: el.unicodeNumber,
    });
  };

  //{
  //     "code": "1",
  //     "unicodeName": "NAME_31",
  //     "unicodeNumber": "31"
  // }

  if (symbol.unicodeSymbolMap.length === 0) {
    return <SkeletonLoader height={300} style={{ marginLeft: 5 }} />;
  }

  return (<div className={cl.wrapperList}>
    {symbol.unicodeSymbolMap.map((el, i) =>
      (<div
        key={i}
        className={`
         ${cl.cell}
         ${el.unicodeNumber === symbol?.selectedSymbol?.unicodeNumber ? cl.selectedCell : ""}
         `}
        onClick={() => handleSymbol(el, el.unicodeNumber === symbol?.selectedSymbol?.unicodeNumber)}
      >
        {el.code}
      </div>))}
  </div>);
};

export default UnicodeList;