import { LinearProgress } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getDeadlineLabel } from '@zert-packages/actions/actionplan';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { findAllBasisProject } from '@zert-packages/utils';
import useDimensions from '@zert-packages/utils/useDimensions';
import LabelRM from '@zert-packages/components/shared/Label';
import ElementTileTable from '@zert-packages/components/ElementTile/ElementTileTable';
import withTile from '@zert-packages/components/ElementTile/shared/withTile';
import { TileIcon } from '@zert-packages/components/ElementTile/tiles/AbstractTile';
import { loadProject } from './projectActions';

export const expandProject = (element, dispatch) => {
  dispatch(loadProject(element.versionId));
};

export function ProjectOverviewTile(props) {
  const project = useSelector((state) => state.project);

  const [myProject, setMyProject] = useState(null);

  const intl = useIntl();

  useEffect(() => {
    if (myProject == null && project && project.info.versionId == props.element.versionId) {
      setMyProject(project);
    }
  }, [project]);

  const showStart = myProject && myProject.start;
  const showStop = myProject && myProject.stop;

  if (myProject == null) {
    return (
      <div className="togglable-info-header" style={{ display: 'flex' }}>
        <Loader />
      </div>
    );
  }

  return (
    <div
      id="right_side"
      style={{
        flex: '1',
        height: '100%',
        width: '100%',
        transition: 'flex 0s linear, height 0s step-start',
        transitionTimingFunction: 'linear, step-end'
      }}
    >
      <div>
        <div style={{ color: '#84A6AF', fontSize: '14px', fontWeight: '500', padding: '0 0 0 25px' }} />
        <div style={{ display: 'flex', padding: '0 0 0 25px' }}>
          {showStart && (
            <LabelRM key="startMessage" text={getDeadlineLabel(myProject.start, intl)} styleName="warning" />
          )}
          {showStop && <LabelRM key="stopMessage" text={getDeadlineLabel(myProject.stop, intl)} styleName="warning" />}
        </div>
      </div>
    </div>
  );
}

export function ProjectContentOverviewTile(props) {
  const project = useSelector((state) => state.project);

  const [projectOverview, setProjectOverview] = useState(null);
  const [basises, setbBsises] = useState(null);

  const { height } = useDimensions({ liveMeasure: true });
  const intl = useIntl();

  useEffect(() => {
    let newProjectOverview = [];

    if (projectOverview == null && project != null && project.info.versionId == props.element.versionId) {
      newProjectOverview = project.activities.map((activity) => {
        let bublik = 'circle-mark';

        if (activity.status == 'initiated' || activity.status == 'waiting-for-information') {
          bublik = 'circle-mark all-bad';
        }
        if (activity.status == 'approved' || activity.status == 'finished') {
          bublik = 'circle-mark all-done';
        }

        return (
          <li>
            <div className={bublik}>
              <div className="circle-half-left" />
              <div className="circle-half-right" />
            </div>
          </li>
        );
      });
      let newBasises = [];
      findAllBasisProject(project, newBasises);
      setProjectOverview(newProjectOverview);
      newBasises = newBasises.map((basis) => {
        let newObject = { ...basis.element };
        if (basis.element.versionId == -1 || basis.element.mimeType == 'application/zert-none') {
          newObject = {
            name: basis.document,
            mimeType: 'application/zert-paper',
            properties: {
              uuid: basis.uuid,
              templateName: basis.comment,
              activityId: basis.activityId
            },
            changedAt: '-',
            createdAt: '-'
          };
        }
        const projectBasis = {
          comment: basis.comment,
          cover: basis.cover,
          document: basis.document,
          name: basis.name,
          parentUUID: basis.parentUUID,
          uuid: basis.uuid,
          type: basis.type
        };
        newObject = { ...newObject, projectBasis };
        return newObject;
      });
      setbBsises(newBasises);
    }
  }, [props]);

  const columns = [
    {
      identifier: 'nameNew',
      numeric: false,
      disablePadding: false,
      component: 'th',
      scope: 'row',
      isFilterable: true,
      label: <FormattedMessage id="Element.TileTable.Name" defaultMessage="Name" />
    },
    {
      identifier: 'mimeTypeNew',
      numeric: false,
      disablePadding: false,
      align: 'left',
      isFilterable: true,
      label: <FormattedMessage id="Element.TileTable.Type" defaultMessage="Type" />
    },
    {
      identifier: 'comment',
      numeric: false,
      disablePadding: false,
      align: 'left',
      isFilterable: true,
      label: <FormattedMessage id="Element.TileTable.Comment" defaultMessage="Comment" />
    },
    {
      identifier: 'status',
      numeric: false,
      disablePadding: false,
      align: 'left',
      isFilterable: true,
      label: <FormattedMessage id="Element.TileTable.Status" defaultMessage="Status" />
    },
    {
      identifier: 'id',
      numeric: true,
      disablePadding: false,
      align: 'right',
      isFilterable: true,
      label: <FormattedMessage id="Element.TileTable.Id" defaultMessage="Id" />
    }
  ];

  const extendHandler = (target, key) => {
    if (key === 'comment') {
      return target.projectBasis && target.projectBasis.comment ? target.projectBasis.comment : '';
    }
    return target[key];
  };

  if (!projectOverview) {
    return (
      <div className="togglable-info-header" style={{ padding: '1em 0' }}>
        <LinearProgress />
      </div>
    );
  }

  return (
    <>
      <ul className="circle-navigation" style={{ maxWidth: '0', padding: '1em 0' }}>
        {projectOverview}
      </ul>
      <div>
        <ElementTileTable
          elements={basises}
          columnsNew={columns}
          extendHandler={extendHandler}
          maxHeight={height}
          loading={!basises}
          bradCrumbComponent={null}
          showCut={false}
          showElementActions={false}
          doNotShowSelect
          doNotShowToolbar
          intl={intl}
          reloadElement={null}
          pagination={10}
          orderBy="date"
          order="asc"
        />
      </div>
    </>
  );
}

export default withTile(TileIcon, ProjectOverviewTile, ProjectContentOverviewTile, expandProject);
