import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';
import { getAllTemplates } from '@zert-packages/actions/api';
import SearchComponent from './SearchComponent';
import withSearch, { styles, controlStyles } from '../withSearch';

function getStyles(name, that) {
  return {
    fontWeight:
      !that.state.Templates || that.state.Templates.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
 /* getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }*/
};

class TemplateSearchPanel extends SearchComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.getTemplateById = this.getTemplateById.bind(this);
  }

  componentDidMount() {
    if (!this.props.allTemplates) {
      this.props.dispatch(getAllTemplates());
    }
  }

  handleChange = (event) => {
    this.setStatus({ Templates: event.target.value });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  getTemplateById(id) {
    const template = this.props.allTemplates && this.props.allTemplates.find((template) => template.id == id);
    if (template) {
      return template;
    }
  }

  render() {
    //  this.props.handleChange(this.state);
    const { classes } = this.props;
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        {this.props.allTemplates && (
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="select-multiple-chip">
              <FormattedMessage id="search.templates" defaultMessage="Templates" />
            </InputLabel>
            <Select
              multiple
              size="small"
              value={this.state.Templates ? this.state.Templates : []}
              onChange={this.handleChange}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={this.getTemplateById(value) && this.getTemplateById(value).name}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {this.props.allTemplates &&
                this.props.allTemplates.map((template) => (
                  <MenuItem key={template.id} value={template.id} style={getStyles(template.id, this)}>
                    {template.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </form>
    );
  }
}

TemplateSearchPanel.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.template.header" defaultMessage="Search by template" />,
  index: 'search-by-template',
  className: 'se.zert.rm.search.TemplatesCriteria',

  default: {
    Templates: []
  }
};
const mapStateToProps = (state) => ({
  allTemplates: state.allTemplates
});
export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles, { withTheme: true })(TemplateSearchPanel), selectData))
);
