import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import MaxLength from "./MaxLength";
import TextFormats from "./TextFormats";
import DropMenuStyle from "./DropMenuStyle";
import SkeletonLoader from "../SkeletonLoader";
import { useDispatch, useSelector } from "react-redux";
import getTranslation from "@zert-packages/utils/getTranslation.old";
import { FormattedMessage } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import { uploadImageFile } from "../../servicesMiddlewares";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogAddAlias from "../dialog/DialogHandleImg/DialogAddAlias";
import { addInsertText } from "../../utils/converterPhrase";
import { EditorState, Modifier } from "draft-js";
import { PhraseContext } from "../PhraseEditor";
import SymbolSelectDialog from "../../SymbolDialog";
import { updateDecorator } from "../../utils/decorator";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import TooltipCustom from "@zert-packages/components/TooltipCustom";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import { addAliasPhrase } from "../../utils/main";
import SymbolDialog from "../dialog/SymbolDialog/SymbolDialog";
import { addSymbol, findHeader } from "../../utils/actionPhraseEditor";
import block from "react-color/lib/components/block/Block";
import {handleBlock} from "@zert-packages/actions/commonReducer";
import {values} from "lodash";


const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 10,
    paddingRight: 10,
    border: `1px solid grey`,
    borderRadius: "12px 12px 0px 0px",
    minHeight: 40,
    display: "flex",
    alignItems: "center",
    "& button:focus": {
      outline: "none"
    }

  },
  wrapperDropMenu: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "fit-content"
  },
  wrapper: {
    display: "flex",
    gap: 10,
    width: "100%",
    alignItems: "center",
    padding: "5px 0px",
    flexWrap: "nowrap"
  },
  icon: {
    cursor: "pointer",
    "&:hover": {
      color: "#4DB1D3"
    }
  },
  iconBlur: {
    opacity: "0.5",
    cursor: "not-allowed"
  }
}));

const dataInsert = [
  {
    name: <FormattedMessage id="PhraseEditor.Head.Actions.Alias" defaultMessage="Alias" />,
    type: "Alias"
  },
  {
    name: <FormattedMessage id="PhraseEditor.Head.Actions.CrossReference" defaultMessage="Cross reference" />,
    type: "Cross reference"
  },
  {
    name: <FormattedMessage id="PhraseEditor.Head.Actions.ExistingImage" defaultMessage="Existing image" />,
    type: "Existing image"
  },
  {
    name: <FormattedMessage id="PhraseEditor.Head.Actions.NewImage" defaultMessage="New image" />,
    type: "New image"
  },
  {
    name: "Symbol",
    type: "Symbol"
  }
];

const Header = () => {
  const cl = useStyles();
  const [header, setHeader] = useState({
    textFormatButton: null,
    textFormatItem: null
  });
  const { formatTemplate, block } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { values, setValues } = useContext(PhraseContext);
  const dataButton = ["bold", "italic"];
  const undoStackDisable = values.editorState.getUndoStack().size === 0;
  const redoStackDisable = values.editorState.getRedoStack().size === 0;
  const actionType = block?.phraseEditorActionType



  const adobeDropMenu = (list) => {
    return list.map(el => ({ name: getTranslation(el.label), style: el.identifier }));
  };

  const addAlias = (val) => {
    addAliasPhrase(val, values, setValues, EditorState);
  };

  const addUnicode = (val) => {
    addSymbol(val.code, values, setValues, EditorState);
  };

  const handleInsert = (type) => {
    if (type === "New image") {
      const uuid = uuidv4();
      dispatch(uploadImageFile(uuid));
    }
    if (type === "Alias") {
      renderInExceptionRoot(DialogAddAlias, { addAlias, values });
    }
    if (type === "Symbol") {
      renderInExceptionRoot(SymbolDialog, { addUnicode });
    }

  };

  const handleUndo = () => {
    const editorState = EditorState.undo(values.editorState);
    setValues(pre => ({ ...pre, editorState }));
  };

  const handleRedo = () => {
    const editorState = EditorState.redo(values.editorState);
    setValues(pre => ({ ...pre, editorState }));
  };

  const handleEditor = (e) => {
    e.preventDefault()
  };



  useEffect(() => {
    if (formatTemplate?.textFormats) {
      findHeader(formatTemplate, dataButton, setHeader);
    }
  }, [formatTemplate && formatTemplate.textFormats]);




  useEffect(() => {
    if(actionType?.length > 0){
      if(actionType === 'undo'){
        handleUndo()
      }
      if(actionType === 'redo'){
        handleRedo()
      }
      dispatch(handleBlock('', 'phraseEditorActionType'))
    }
  }, [actionType]);

  useEffect(() => {
    if(values.editorState){
      dispatch(handleBlock(values.editorState, 'editorState'))
    }
  }, [values.editorState]);


  // console.log('values', values)

  return (<div className={cl.root} style={{display: block ? 'none' : 'block'}}>
    {header.textFormatItem
      ? <div className={cl.wrapper}>
        <TooltipCustom title={"Undo"}>
          <UndoIcon
            onClick={() => handleUndo()}
            className={undoStackDisable ? cl.iconBlur : cl.icon}
          />
        </TooltipCustom>
        <TooltipCustom title={"Redo"}>
          <RedoIcon
            onClick={() => handleRedo()}
            className={redoStackDisable ? cl.iconBlur : cl.icon}
          />
        </TooltipCustom>
        {header.textFormatButton && header.textFormatButton.map((el, i) =>
          <TextFormats key={i} type={el.comboValue.toUpperCase()} />)}
        {header.textFormatItem &&
          <div className={cl.wrapperDropMenu} onClick={(e) => handleEditor(e)} onMouseDown={(e) => e.preventDefault()}>
            <DropMenuStyle
              label={<FormattedMessage id="ElementTile.PhraseEditor.Header.TextFormats"
                                       defaultMessage="Text formats" />}
              dropMenu={adobeDropMenu(header.textFormatItem)}
            />
          </div>}
        <DropMenuStyle
          label={<FormattedMessage id="ElementTile.PhraseEditor.Header.Insert" defaultMessage="Insert" />}
          dropMenu={dataInsert}
          handle={handleInsert}
        />
        {/*<div className={cl.wrapperDropMenu}><MaxLength /></div>*/}
      </div> : <SkeletonLoader />}
  </div>);
};
export default Header;