import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from '@mui/material';
import { handleText, API_ROOT } from '@zert-packages/actions/api';
import { getLicense } from '@zert-packages/actions/coreReducers';

class LicensePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getLicense());
  }

  componentDidUpdate(prevProps) {}

  onClick(e) {
    handleText('/common/acceptLicense').then(() => {
      document.location.reload(true);
    });
  }

  render() {
    if (this.props.licenseLoading || !this.props.license) {
      return (
        <Loader>
          <FormattedMessage id="license.loading" defaultMessage="Loading license..." />
        </Loader>
      );
    }
    const style = {
      listStyleType: 'none',
      padding: '5px',
      flexDirection: 'column',
      height: 'calc(var(--vh, 1vh) * 100 - 150px)',
      overflow: 'auto'
    };

    return (
      <div className="main" style={{ margin: '20px' }}>
        <div className="caption" />
        <div
          className="workfield"
          style={style}
          dangerouslySetInnerHTML={{ __html: this.props.license ? this.props.license : '' }}
        />
        <div
          style={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            position: 'sticky',
            bottom: '-25px',
            backgroundColor: '#fff'
          }}
        >
          <div className="createNewButtons">
            {this.props.readOnly ? (
              <Button onClick={this.props.hideLicenseAgreement} color="primary" variant="outlined">
                <FormattedMessage id="LicencePage.GoBackButton" defaultMessage="Hide License Agreement" />
              </Button>
            ) : (
              <>
                <Button color="primary" onClick={this.onClick}>
                  <FormattedMessage id="licensepage.confirm" defaultMessage="Accept license" />
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    window.location.assign(`${API_ROOT}logout`);
                  }}
                >
                  <FormattedMessage id="licensepage.cancel.button" defaultMessage="Cancel" />
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  license: state.license,
  licenseLoading: state.licenseLoading
});

export default injectIntl(withRouter(connect(mapStateToProps)(LicensePage)));
