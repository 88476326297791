import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import GroupBlock from '../blockTabs/pageLayout/GroupBlock';
import { TabsContext } from '../../Stylesheet';
import { adobeWord, handleTableSingleLevel } from '../../utils/helpers';
import SpacingTemplate from '../blockTabs/topic/SpacingTemplate';
import TemplateItemRow from '../../TemplateItemRow';
import InlineImages from './InlineImages';
import LabelBlock from './LabelBlock';
import { renderInExceptionRoot } from "@zert-packages/utils";
import DialogHeightTable from "../dialog/DialogHeightTable";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 10
  },
  borderBlock: {
    border: '1px solid #C6C6C5',
    padding: '5px 10px',
    display: 'grid',
    gridRowGap: 10
  },
  title: {
    fontSize: '1rem',
    fontWeight: '700',
    marginBottom: 5
  },
  wrapperLineHeight: {
    display: 'grid',
    gridTemplateColumns: '1fr 25px',
    alignItems: 'center',
    gridColumnGap: 10
  }
}));

function LeftImageBlock({ image }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const spacingProps = [
    { label: 'Left:', value: image.paddingLeft, type: 'paddingLeft' },
    { label: 'Right:', value: image.paddingRight, type: 'paddingRight' },
    { label: 'Top:', value: image.paddingTop, type: 'paddingTop' },
    { label: 'Bottom:', value: image.paddingBottom, type: 'paddingBottom' }
  ];

  const handleValuesSpacing = (val, name) => {
    handleTableSingleLevel(val, name, 'imageSettings', values, setValues, 'value');
  };

  const handleDropMenuSpacing = (valDropMenu, name, val) => {
    handleTableSingleLevel(val, name, 'imageSettings', values, setValues, 'drop', valDropMenu);
  };
  const handleSpacing = (val, name) => {
    const res = {
      ...image[name],
      value: {
        ...image[name].value,
        unit: image[name].value ? image[name].value.unit : 'pt',
        value: +val
      }
    };
    handleTableSingleLevel(res, name, 'imageSettings', values, setValues);
  };

  const handleSpacingMenu = (valDrop, name, val) => {
    const res = { ...image[name], value: { unit: valDrop, value: +val } };
    handleTableSingleLevel(res, name, 'imageSettings', values, setValues);
  };

  const handleSpacingBefore = (val, name, nameObject) => {
    const res = { ...image[name], [nameObject]: val };
    handleTableSingleLevel(res, name, 'imageSettings', values, setValues);
  };

  // spaceBefore
  const handleValuePropsDialogBefore = (val, name) => {
    const res = {
      ...image.spaceBefore,
      [name]: {
        unit: image.spaceBefore[name] ? image.spaceBefore[name].unit : 'pt',
        value: +val
      }
    };
    handleTableSingleLevel(res, 'spaceBefore', 'imageSettings', values, setValues);
  };

  const setDropMenuPropsDialogBefore = (valDrop, name, val) => {
    const res = {
      ...image.spaceBefore,
      [name]: {
        unit: valDrop,
        value: +val
      }
    };
    handleTableSingleLevel(res, 'spaceBefore', 'imageSettings', values, setValues);
  };

  const setDropMenuPrecedenceBefore = (val) => {
    const res = { ...image.spaceBefore, precedence: val.name };
    handleTableSingleLevel(res, 'spaceBefore', 'imageSettings', values, setValues);
  };

  // spaceAfter
  const handleValuePropsDialogAfter = (val, name) => {
    const res = {
      ...image.spaceAfter,
      [name]: {
        unit: image.spaceAfter[name] ? image.spaceAfter[name].unit : 'pt',
        value: +val
      }
    };
    handleTableSingleLevel(res, 'spaceAfter', 'imageSettings', values, setValues);
  };

  const setDropMenuPropsDialogAfter = (valDrop, name, val) => {
    const res = {
      ...image.spaceAfter,
      [name]: {
        unit: valDrop,
        value: +val
      }
    };
    handleTableSingleLevel(res, 'spaceAfter', 'imageSettings', values, setValues);
  };

  const setDropMenuPrecedenceAfter = (val) => {
    const res = { ...image.spaceAfter, precedence: val.name };
    handleTableSingleLevel(res, 'spaceAfter', 'imageSettings', values, setValues);
  };

  const findDisable = () => {
    const lineHeight = adobeWord(image.lineHeight.defaultValue.type, true);
    if (lineHeight === 'Default' || lineHeight === 'Single') {
      return true;
    }
  };

  const handleMenuFirst = (val, name) => {
    const res = {
      ...image.lineHeight,
      defaultValue: {
        ...image.lineHeight.defaultValue,
        type: val.toUpperCase()
      }
    };
    handleTableSingleLevel(res, name, 'imageSettings', values, setValues);
  };

  const setValueLineHeight = (val, name) => {
    const res = {
      ...image.lineHeight,
      defaultValue: {
        ...image.lineHeight.defaultValue,
        value: { value: +val, unit: image.lineHeight.defaultValue.value.unit }
      }
    };
    handleTableSingleLevel(res, name, 'imageSettings', values, setValues);
  };

  const handleLineHeightMenu = (valDropMenu, name, val) => {
    const res = {
      ...image.lineHeight,
      defaultValue: {
        ...image.lineHeight.defaultValue,
        value: { value: +val, unit: valDropMenu }
      }
    };
    handleTableSingleLevel(res, name, 'imageSettings', values, setValues);
  };

  const openHeightDialog = () => {
    renderInExceptionRoot(DialogHeightTable, {})
  }



  return (
    <div className={cl.root}>
      <div>
        <p className={cl.title}>Text flow</p>
        <div className={cl.borderBlock}>
          <div className={cl.wrapperLineHeight}>
            <TemplateItemRow
              label="Line height:"
              type="lineHeight"
              value={image.lineHeight.defaultValue.value}
              dropMenuFirst={{
                label: ['Default', 'Single', 'At least', 'Fixed'],
                selected: image.lineHeight.defaultValue.type
              }}
              grid="155px 150px 1fr 55px"
              setDropMenuFirst={handleMenuFirst}
              setDropMenu={handleLineHeightMenu}
              setValueProps={setValueLineHeight}
              disabled={findDisable()}
            />

            <InsertDriveFileIcon style={{ color: 'gray', cursor: 'pointer' }} onClick={openHeightDialog} />
          </div>
          <SpacingTemplate
            title="Space before:"
            type="spaceBefore"
            valueProps={image.spaceBefore}
            grid="155px 150px 1fr 55px"
            setValueProps={handleSpacing}
            setDropMenu={handleSpacingMenu}
            handleBefore={handleSpacingBefore}
            setValuePropsDialog={handleValuePropsDialogBefore}
            setDropMenuPropsDialog={setDropMenuPropsDialogBefore}
            setDropMenuPrecedence={setDropMenuPrecedenceBefore}
            // disabled={adobeWord(image.spaceBefore.conditionality, true)  === 'Default'}
          />
          <SpacingTemplate
            title="Space after:"
            type="spaceAfter"
            valueProps={image.spaceAfter}
            grid="155px 150px 1fr 55px"
            setValueProps={handleSpacing}
            setDropMenu={handleSpacingMenu}
            handleBefore={handleSpacingBefore}
            setValuePropsDialog={handleValuePropsDialogAfter}
            setDropMenuPropsDialog={setDropMenuPropsDialogAfter}
            setDropMenuPrecedence={setDropMenuPrecedenceAfter}
            // disabled={adobeWord(image.spaceAfter.conditionality, true)  === 'Default'}
          />
        </div>
      </div>
      <GroupBlock
        label="Spacing to contents"
        valueProps={spacingProps}
        style={{ height: 'Calc(100% - 26px)', gridAutoRows: 'min-content' }}
        setValueProps={handleValuesSpacing}
        setDropMenu={handleDropMenuSpacing}
      />
      <InlineImages />
      <LabelBlock />
    </div>
  );
}

export default LeftImageBlock;
