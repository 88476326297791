import { useSelector } from 'react-redux';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import { FormattedMessage } from 'react-intl';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';

import OriginLink from '@zert-packages/components/common/OriginLink';
import CatalogInlineEditor from '@zert-packages/components/shared/CatalogPlacement/CatalogInlineEditor';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    flexWrap: 'wrap',
    padding: '10px',
    margin: '10px'
  },
  margin: {
    margin: '10px'
  }
}));

export default function OriginView() {
  const classes = useStyles();
  const { versionInfo } = useSelector((state) => state);
  if (!versionInfo) {
    return (
      <Loader>
        <FormattedMessage id="OriginView.Loading" defaultMessage="Origin" />
      </Loader>
    );
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <OriginLink versionId={versionInfo.info.versionId} />
        {versionInfo.methodName && (
          <Grid item xs={6}>
            <div className="captionLabel" style={{marginLeft: "10px"}}>
              <FormattedMessage id="OriginView.Method" defaultMessage="User changed" />
            </div>
            <FormControl variant="standard" fullWidth className={classes.margin}>
              <Input id="standard-adornment-amount" value={versionInfo.methodName} />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
          <div className="captionLabel" style={{marginLeft: "10px"}}>
            <FormattedMessage id="OriginView.Id" defaultMessage="Id" />
          </div>
          <FormControl variant="standard" fullWidth className={classes.margin}>
            <Input id="standard-adornment-amount" value={versionInfo.info.id} />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
          <div className="captionLabel" style={{marginLeft: "10px"}}>
            <FormattedMessage id="OriginView.VersionId" defaultMessage="VersionId" />
          </div>
          <FormControl variant="standard" fullWidth className={classes.margin}>
            <Input id="standard-adornment-amount" value={versionInfo.info.versionId} />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
          <div className="captionLabel" style={{marginLeft: "10px"}}>
            <FormattedMessage id="OriginView.DateCreated" defaultMessage="Date created" />
          </div>
          <FormControl variant="standard" fullWidth className={classes.margin}>
            <Input id="standard-adornment-amount" value={versionInfo.info.createdAt} />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
          <div className="captionLabel" style={{marginLeft: "10px"}}>
            <FormattedMessage id="OriginView.UserCreated" defaultMessage="User created" />
          </div>
          <FormControl variant="standard" fullWidth className={classes.margin}>
            <Input id="standard-adornment-amount" value={versionInfo.info.createdBy} />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
          <div className="captionLabel" style={{marginLeft: "10px"}}>
            <FormattedMessage id="OriginView.DateChanged" defaultMessage="Date changed" />
          </div>
          <FormControl variant="standard" fullWidth className={classes.margin}>
            <Input id="standard-adornment-amount" value={versionInfo.info.changedAt} />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
          <div className="captionLabel" style={{marginLeft: "10px"}}>
          <FormattedMessage id="OriginView.UserChanged" defaultMessage="User changed" />
          </div>
          <FormControl variant="standard" fullWidth className={classes.margin}>
            <Input id="standard-adornment-amount" value={versionInfo.info.changedBy} />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={3} xl={6}>
          <div className="captionLabel" style={{marginLeft: "10px"}}>
            <FormattedMessage
              id="OriginView.Placement"
              defaultMessage="Placement"
            />
          </div>
          <div style={{marginTop: "10px"}}>
          <CatalogInlineEditor
              versionId={versionInfo.info.versionId}
              labelText={""}
              avoidReload={true}
              styleName={"dark"}
          />
          </div>
        </Grid>
      </Grid>
      {/*    <NextElementButton/> */}
    </div>
  );
}
