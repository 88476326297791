import { LinearProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useEnv } from '@zert-packages/react/EnvProvider';

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  return momLatestDateTime.isAfter(momCurrentDateTime);
};

function withClearCache(Component, packageJson) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);
    const { env, setEnv } = useEnv();

    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_ROOT_WAR}/env.json`)
        .then((response) => response.json())
        .then((fetchedEnv) => {
          if (fetchedEnv != null) {
            setEnv(fetchedEnv);
          }

          return fetch(`${process.env.REACT_APP_API_ROOT_WAR}/meta.json`);
        })
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        })
        .catch((e) => {
          setIsLatestBuildDate(true);
          setEnv(process.env);
        });
    }, []);

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload(true);
    };

    if (!env) {
      return <LinearProgress />;
    }

    return <>{isLatestBuildDate ? <Component {...props} /> : null}</>;
  }

  return ClearCacheComponent;
}

export default withClearCache;
