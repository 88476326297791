import { useSelector } from 'react-redux';
import { useContext, useEffect, useRef } from 'react';
import { IntroductionContext } from '../../Introduction';
import buildIsMatched from '../utils/buildIsMatched';
import countSessionsInJSON from '../utils/countSessionsInJSON';
import countParticipantsInJSON from '../utils/countParticipantsInJSON';
import countBasis from '../utils/countBasis';

function useRightPanel() {
  const { expandEverything, setExpandEverything } = useContext(IntroductionContext);
  const callback = useRef();
  useEffect(() => {
    if (expandEverything && callback.current) {
      setTimeout(() => {
        callback.current();
      }, 350);
    }
  }, [expandEverything]);

  const startSearching = () => {
    if (expandEverything && callback.current) {
      callback.current();
    } else {
      setExpandEverything(true);
    }
  };
  const setCallback = (_callback) => (callback.current = _callback);
  return {
    startSearching,
    setCallback,
    callback: callback.current
  };
}

const riskRowCellClasses = ['se.zert.rm.rest.template.cell.pojo.ClientTextCell'];

const matchLabel = (isMatched, node) => isMatched(node.defaultLabel);

const matchRiskRow = (riskRow, isMatched, counter) => {
  riskRow.cells.forEach((cell) => {
    riskRowCellClasses.forEach((c) => {
      if (cell.class.includes(c) && isMatched(cell.value)) counter.rootNode++;
    });
  });
};
const matchMeasures = (measures, isMatched, counter) => {
  measures.forEach((measure) => {
    if (isMatched(measure.measure)) counter.rootNode++;
  });
};

function matchRootNode(node, isMatched, counter) {
  node.children.forEach((n) => matchRootNode(n, isMatched, counter));
  node.cachedRiskRows.forEach((riskRow) => {
    matchRiskRow(riskRow, isMatched, counter);
    matchMeasures(riskRow.measures, isMatched, counter);
    if (isMatched(`${riskRow.status.id}`)) counter.rootNode++;
  });

  if (matchLabel(isMatched, node.label)) counter.rootNode++;
  if (isMatched(node.description)) counter.rootNode++;
}

export default function useRiskAssessmentConfig() {
  const { riskassesment } = useSelector((state) => state);
  const { setSearchTab } = useContext(IntroductionContext);
  const rightPanel = useRightPanel();

  function countMatchedElementsJSON(searchValue) {
    const counter = {
      description: 0,
      rootNode: 0,
      participants: 0,
      sessions: 0,
      basis: 0
    };
    const isMatched = buildIsMatched(searchValue);
    if (isMatched(riskassesment.description)) counter.description++;
    matchRootNode(riskassesment.rootNode, isMatched, counter);
    countParticipantsInJSON(riskassesment.participants, isMatched, counter);
    countSessionsInJSON(riskassesment.sessions, isMatched, counter);
    countBasis(riskassesment.basises, isMatched, counter);
    return counter;
  }

  return {
    countMatchedElementsJSON,
    searchSettings: {
      dataset: ['editor']
    },
    readyToSearch: riskassesment !== null,
    containers: [
      {
        counterField: 'rootNode',
        id: 'right_panel',
        startSearching: (callback) => {
          rightPanel.setCallback(callback);
          rightPanel.startSearching();
        }
      },
      {
        counterField: 'description',
        id: 'left_panel',
        startSearching: (callback) => {
          setSearchTab(4);
          setTimeout(() => {
            callback();
          }, 500);
        }
      },
      {
        counterField: 'participants',
        id: 'left_panel',
        startSearching: (callback) => {
          setSearchTab(1);
          setTimeout(() => {
            callback();
          }, 500);
        }
      },
      {
        counterField: 'basis',
        id: 'leftpanel_tab',
        startSearching: (callback) => {
          setSearchTab(3);
          setTimeout(() => {
            callback();
          }, 500);
        }
      },
      {
        counterField: 'sessions',
        id: 'left_panel',
        startSearching: (callback) => {
          setSearchTab(2);
          setTimeout(() => {
            callback();
          }, 500);
        }
      }
    ]
  };
}
