import React, { useContext, useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TreeItem from '@mui/lab/TreeItem';
import cn from 'classnames';
import { CatalogContext } from '../common/CatalogProvider';
import * as treeMethods from '../helpers/treeMethods';
import './TreeEditor.sass';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:focus > $content': {
      backgroundColor: `var(--tree-view-bg-color, none)`,
      color: 'var(--tree-view-color)'
    }
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: 'inherit'
    },
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: '14px'
  },
  expanded: {
    paddingBottom: '-4px'
  },
  label: {
    fontWeight: 'inherit',
    color: 'inherit'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  selectedLabelRoot: {
    backgroundColor: theme.palette.grey[300]
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelText: {
    fontSize: '15px',
    fontWeight: 'inherit',
    flexGrow: 1,
    overflow: 'hidden'
  }
}));

function CatalogTreeNode({
  root,
  amountOfChildren,
  index,
  labelText,
  labelIcon: LabelIcon,
  labelInfo,
  color,
  bgColor,
  nodeId,
  nodePath,
  ...other
}) {
  const {
    globalTree,
    handleRenderTree,

    selectNodeById,
    selectedNode,
    selectedParentNode,
    setSelectedParentNode
  } = useContext(CatalogContext);
  const classes = useTreeItemStyles();
  const isAllowedDropHere = checkIsAllowedDropHere(selectedNode, nodePath);

  const [onDragBefore, setOnDragBefore] = useState(false);
  const [onDragInside, setOnDragInside] = useState(false);
  const [onDragAfter, setOnDragAfter] = useState(false);

  function resetAllDrag() {
    setOnDragBefore(false);
    setOnDragInside(false);
    setOnDragAfter(false);
    handleRenderTree();
  }

  const handleSelectNodeById = () => selectNodeById(nodeId);
  const isSelected = selectedNode !== null && selectedNode.uuid === nodeId;

  const treeItemRef = useRef();
  const treeItemContainerRef = useRef();
  const labelRootRef = useRef();

  useEffect(() => {
    selectCurrentNode(selectedParentNode, nodeId, treeItemRef, treeItemContainerRef, setSelectedParentNode);
  });

  /// / START DRAG
  const handleOnDragStart = (event) => {
    event.dataTransfer.setData('text/html', null);
    event.stopPropagation();
    selectNodeById(nodeId);
  };

  const handleOnDragEnd = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  /// / DRAG BEFORE
  const onDragOverBefore = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOnDragBefore(true);
  };
  const onDragLeaveBefore = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOnDragBefore(false);
  };

  const handleOnDropBefore = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!checkIsValidDropHere(selectedNode, nodeId, isAllowedDropHere)) return resetAllDrag();
    const { foundNode, parentNode } = findCurrentAndParentNode(globalTree.rootNode, nodeId);
    treeMethods.findNodeAndApplyMethod(globalTree.rootNode, selectedNode, treeMethods.removeNode);
    treeMethods.addNodeBefore(parentNode, foundNode, selectedNode);
    resetAllDrag();
  };

  /// / DRAG INSIDE
  const onDragOverInside = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (selectedNode.uuid === nodeId) return;
    setOnDragInside(true);
  };

  const onDragLeaveInside = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOnDragInside(false);
  };

  const handleOnDropInside = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!checkIsValidDropHere(selectedNode, nodeId, isAllowedDropHere)) return resetAllDrag();

    const parentNode = treeMethods.findNodeById(globalTree.rootNode, nodeId);
    treeMethods.findNodeAndApplyMethod(globalTree.rootNode, selectedNode, treeMethods.removeNode);
    treeMethods.addNodeInTheEnd(globalTree.rootNode, parentNode, selectedNode);
    setSelectedParentNode(parentNode);
    resetAllDrag();
  };

  /// / DRAG AFTER
  const onDragOverAfter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOnDragAfter(true);
  };

  const onDragLeaveAfter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOnDragAfter(false);
  };

  const handleOnDropAfter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!checkIsValidDropHere(selectedNode, nodeId, isAllowedDropHere)) return resetAllDrag();

    const { foundNode, parentNode } = findCurrentAndParentNode(globalTree.rootNode, nodeId);

    treeMethods.findNodeAndApplyMethod(globalTree.rootNode, selectedNode, treeMethods.removeNode);
    treeMethods.addNodeAfter(parentNode, foundNode, selectedNode);
    resetAllDrag();
  };

  return (
    <div>
      {!root && index === 0 && (
        <OutSideLine
          hover={onDragBefore}
          onDragOver={onDragOverBefore}
          onDragLeave={onDragLeaveBefore}
          onDrop={handleOnDropBefore}
        />
      )}

      <TreeItem
        style={{
          transition: ' background-color 0.3s ease-in-out',
          background: isAllowedDropHere && onDragInside ? 'rgba(0, 0, 0, 0.3)' : 'inherit'
        }}
        ref={treeItemRef}
        label={
          <div ref={treeItemContainerRef}>
            <div
              onDragOver={onDragOverInside}
              onDragLeave={onDragLeaveInside}
              onDrop={handleOnDropInside}
              draggable="true"
              onDragStart={handleOnDragStart}
              onDragEnd={handleOnDragEnd}
              ref={labelRootRef}
              className={cn(classes.labelRoot, { [classes.selectedLabelRoot]: isSelected })}
              onClick={handleSelectNodeById}
            >
              <LabelIcon color="inherit" className={classes.labelIcon} />
              <Typography variant="body2" className={classes.labelText}>
                {labelText}
              </Typography>
            </div>
          </div>
        }
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          group: classes.group,
          label: classes.label
        }}
        nodeId={nodeId}
        {...other}
      />
      {!root && (
        <OutSideLine
          hover={onDragAfter}
          onDragOver={onDragOverAfter}
          onDragLeave={onDragLeaveAfter}
          onDrop={handleOnDropAfter}
        />
      )}
    </div>
  );
}

// OTHER COMPONENTS

function OutSideLine(props) {
  const { hover, isDragging, ...other } = props;
  const className = cn({
    'drop-line': true,
    'drop-line--hover': hover
  });
  return <div className={className} {...other} />;
}

// HELPERS

function checkIsValidDropHere(selectedNode, nodeId, isAllowedDropHere) {
  const theSameId = selectedNode.uuid === nodeId;
  return !theSameId || !isAllowedDropHere;
}

function checkIsAllowedDropHere(selectedNode, nodePath) {
  let isAllowedDropHere = true;
  if (selectedNode && typeof nodePath !== 'undefined') {
    isAllowedDropHere = !nodePath.includes(selectedNode.uuid);
  }
  return isAllowedDropHere;
}

function findCurrentAndParentNode(rootNode, nodeId) {
  const foundNode = treeMethods.findNodeById(rootNode, nodeId);
  const parentNode = treeMethods.findParentNode(rootNode, foundNode);
  return {
    foundNode,
    parentNode
  };
}

function selectCurrentNode(selectedParentNode, nodeId, treeItemRef, treeItemContainerRef, setSelectedParentNode) {
  if (selectedParentNode !== null) {
    if (selectedParentNode.uuid === nodeId) {
      const indexOfList = 1;
      const shouldClick = treeItemRef.current.children[indexOfList];
      if (typeof shouldClick === 'undefined') {
        treeItemContainerRef.current.click();
        setSelectedParentNode(null);
      }
    }
  }
}

export default CatalogTreeNode;
