import React from 'react'
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  indexText: {
    textAlign: 'center',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      color: "#59C1DB",
    },
  },
}))

const IndexText = () => {
  const cl = useStyles();

  return(<div className={cl.indexText}>
    Open element view
  </div>)
}

export default IndexText