import React from 'react';
import getTranslation from '@zert-packages/utils/getTranslation.old';
import TreeSelector from '../TreeSelector/TreeSelector';
import { CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";

const filterNulls = (array => {
  if (array !=null) {
    return array.reduce((total, x, index) =>  total + "^"+ ((x && x.rootNode) ? x.rootNode.uuid : index), "key")
  } else {
    return "";
  }
});

class CatalogPlacement extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.assignChagedValues = this.assignChagedValues.bind(this);
    this.state = {
      multipleValue: this.props.multipleValue,
      gData: []
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.catalog) return;
    if (
      prevProps.catalog !== this.props.catalog ||
      prevProps.loading !== this.props.loading ||
      filterNulls(prevProps.catalog) !== filterNulls(this.props.catalog)
    ) {
      this.setState({ gData: this.populateCatalog(this.props.catalog, this.props.loading)});
    }
  }

  populateCatalog(catalogArray, loading) {
    const gData = [];
    catalogArray.map((catalog) => {
      if (
        catalog &&
        catalog &&
        catalog.rootNode &&
        catalog &&
        catalog.rootNode.metadata &&
        catalog.rootNode.metadata.length > 0
      ) {
        gData.push({
          label: `${getTranslation(catalog.rootNode.label)}`,
          value: `${catalog.rootNode.metadata[0].value}`,
          key: `${catalog.rootNode.uuid}`,
          catalogVersionId: catalog.info.versionId,
          expanded: true,
          disabled: false,
          children: _loop(catalog.rootNode)
        });

        function _loop(node) {
          const children = [];
          node.children.map((child) => {
            if (child.metadata && child.metadata.length > 0)
              children.push({
                label: `${getTranslation(child.label)}`,
                value: `${child.metadata[0].value}`,
                catalogVersionId: catalog.info.versionId,
                key: `${child.uuid}`,
                disabled: child.metadata[0].value == '',
                expanded: true,
                children: _loop(child)
              });
            if (child.children.length != 0) {
              child.children.map((_child) => {
                return _loop(_child);
              });
            } else {
              return '[]';
            }
          });
          return children;
        }
      }
    });
    if (loading) {
      gData.push({
        title:<div><CircularProgress size={'1rem'}/> <FormattedMessage defaultMessage={'Loading catalogs...'} id={'catalogPlacement.loading'}/> </div>,
        value: 'loading',
        key: 'loading',
      })
    }
    return gData;
  }

  onChange(e) {
    this.setState({ placement: e.target.value });
  }

  assignChagedValues(data) {
    this.setState({multipleValue : data});
    this.props.assignChagedValues(data);
  }

  render() {
    return (
      <div>
        {this.props.catalog && (
          <TreeSelector
            key={filterNulls(this.state.multipleValue)}
            assignChagedValues={this.assignChagedValues}
            gData={this.populateCatalog(this.props.catalog, this.props.loading)}
            multipleValue={this.state.multipleValue}
          />
        )}
      </div>
    );
  }
}

export default CatalogPlacement;
