import makeStyles from '@mui/styles/makeStyles';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import { verifyPasswordPost } from '@zert-packages/actions/measureActions';
import { formStyle } from './StylesHelper';

const useStyles = makeStyles(formStyle);

export function ConfirmPasswordField({ passwordApproved, setPasswordApproved }) {
  const [password, setPassword] = React.useState('');
  const [checkingPassword, setCheckingPassword] = React.useState(false);

  let timer;
  const handleChange = (prop) => (event) => {
    //    setValues({...values, [prop]: event.target.value});
    setPassword(event.target.value);

    if (timer) {
      clearInterval(timer);
      clearTimeout(timer);
    }
    const { value } = event.target;
    setCheckingPassword(true);
    timer = setInterval(() => checkPassword(value), 1000);
  };

  const checkPassword = (password) => {
    clearInterval(timer);

    async function hasPasswordAsync(password) {
      setCheckingPassword(true);
      try {
        const result = await verifyPasswordPost(password);
        if (result) {
          setPasswordApproved(true);
        } else {
          setPasswordApproved(false);
        }
        setCheckingPassword(false);
      } catch (e) {
        setCheckingPassword(false);
      }
    }

    hasPasswordAsync(password);
  };

  const classes = useStyles();

  return (
    <FormControl required className={classes.formControl} error={!passwordApproved && password != ''}>
      <InputLabel htmlFor="passwordField">
        <FormattedMessage id="formelements.passwordText" defaultMessage="Confirm password" />
      </InputLabel>
      <Input
        id="passwordField"
        value={password}
        onChange={handleChange('password')}
        aria-describedby="passwordField"
        fullWidth
        type="password"
        autoComplete="none"
        endAdornment={
          <>
            {passwordApproved && (
              <InputAdornment position="end">
                <IconButton size="small">
                  <CircleCheckedFilled style={{ color: 'green' }} />
                </IconButton>
              </InputAdornment>
            )}
            {checkingPassword && (
              <InputAdornment position="end">
                <CircularProgress className={classes.progress} size={20} />
              </InputAdornment>
            )}
          </>
        }
      />

      {!passwordApproved && password != '' && (
        <FormHelperText id="passwordField">
          <FormattedMessage id="formelements.passwordfield" defaultMessage="Password did not match" />
        </FormHelperText>
      )}
    </FormControl>
  );
}
