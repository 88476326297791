import React, { createContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ParentTreeNode from './ParentTreeNode';

export const TreeContext = createContext(null);
const useForceUpdate = () => {
  const [, setIt] = useState(false);
  return () => setIt((it) => !it);
};

function ParentTree({ info }) {
  const [selectNodeId, setSelectedNodeId] = useState(false);
  const forceUpdate = useForceUpdate();
  const [key, setKey] = useState(uuidv4());
  const value = {
    selectNodeId,
    setSelectedNodeId
  };
  useEffect(() => {
    setKey(uuidv4());
    forceUpdate();
  }, [info]);
  return (
    <TreeContext.Provider value={value}>
      <ParentTreeNode isRoot key={key} node={info} firstNode="true" latest />
    </TreeContext.Provider>
  );
}

export default ParentTree;
