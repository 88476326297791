import { createAction, createReducer } from '@reduxjs/toolkit';

export const loadTJAction = createAction('CLM: Load TJ');
export const loadReviewJobAction = createAction('CLM: Load Review JOB');
export const loadReviewJobActionComments = createAction('CLM: Load Review JOB comments');

export const loadTJActionWFT = createAction('CLM: Load TJ WFT');
export const resetTJAction = createAction('CLM: RESET TJ');
export const resetTermsAction = createAction('CLM: RESET TermDb');
export const turnOnCLMLoader = createAction('CLM: Global loader is on');
export const turnOffCLMLoader = createAction('CLM: Global loader is off');

export const turnOnCLMLoaderWFT = createAction('CLM: WFT loader is on');
export const turnOffCLMLoaderWFT = createAction('CLM: WFT loader is off');
export const loadTranslationJobTemplate = createAction('CLM: Load translationJob Template');
export const createNewImage = createAction('CLM: New image reference');
export const loadTermDb = createAction('CLM: Load TermDB');
export const loadIndex = createAction('CLM: Load Index');
export const loadTermReferences = createAction('CLM: Load Term references');
export const loadSoftwareTexts = createAction('CLM: Load Software Texts');
export const loadSoftwareTextsOptional = createAction('CLM: Load Software Texts Optional');
export const loadSoftwareTextsForced = createAction('CLM: Load Software Texts Forced');

export const loadParts = createAction('CLM: Load Part');
export const loadPartsGroup = createAction('CLM: Load Parts Group');
export const loadPartsImage = createAction('CLM: Load Parts Image');
export const loadIFSMachine = createAction('CLM: Load IFS machine');
export const loadLayout = createAction('CLM: Load Layout');

export const initialState = {
  translationJob: null,
  translationJobTemplate: null,
  reviewJob: null,
  comments: null,
  isLoading: false,
  isLoadingWFT: false,
  newImage: null,
  uuidNewImage: null,
  termDB: null,
  index: null,
  softwareTexts: null,
  termReferences: null,
  forcedMetadata: null,
  optionalMetadata: null,
  parts: null,
  partsGroup: null,
  partImage: null,
  IFSMachine: null
};

export const CLMReducer = createReducer(initialState, {
  [loadTJAction]: (state, action) => {
    state.translationJob = action.payload;
  },
  [loadTranslationJobTemplate]: (state, action) => {
    state.translationJobTemplate = action.payload;
  },
  [createNewImage]: (state, action) => {
    state.newImage = action.payload.info;
    state.uuidNewImage = action.payload.uuid;
  },
  [resetTJAction]: (state, action) => {
    state.translationJob = null;
  },
  [resetTermsAction]: (state, action) => {
    state.termDB = null;
  },
  [turnOnCLMLoader]: (state) => {
    state.isLoading = true;
  },
  [loadTJActionWFT]: (state, action) => {
    state.tjwft = action.payload;
  },
  [loadReviewJobAction]: (state, action) => {
    state.reviewJob = action.payload;
  },
  [loadReviewJobActionComments]: (state, action) => {
    state.comments = action.payload;
  },
  [turnOffCLMLoader]: (state) => {
    state.isLoading = false;
  },
  [turnOnCLMLoaderWFT]: (state) => {
    state.isLoadingWFT = false;
  },
  [turnOffCLMLoaderWFT]: (state) => {
    state.isLoadingWFT = false;
  },
  [loadTermDb]: (state, action) => {
    state.termDB = action.payload;
  },
  [loadLayout]: (state, action) => {
    state.layout = action.payload;
  },
  [loadIndex]: (state, action) => {
    state.index = action.payload;
  },
  [loadSoftwareTexts]: (state, action) => {
    state.softwareTexts = action.payload;
  },
  [loadSoftwareTextsOptional]: (state, action) => {
    state.loadSoftwareTextsOptional = action.payload;
  },
  [loadTermReferences]: (state, action) => {
    state.termReferences = action.payload;
  },
  [loadParts]: (state, action) => {
    state.parts = action.payload;
  },
  [loadPartsGroup]: (state, action) => {
    state.partsGroup = action.payload;
  },
  [loadPartsImage]: (state, action) => {
    state.partsImage = action.payload;
  },
  [loadIFSMachine]: (state, action) => {
    state.IFSMachine = action.payload;
  }
});
