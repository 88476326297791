import React, { memo, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FormattedMessage } from 'react-intl';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import get from 'lodash/get';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import { handlePostTextAsParam } from '@zert-packages/actions/api';
import SlowTooltip from './SlowTooltip';
import { getCriterias } from "@zert-packages/plugins/ExplorerPlugin/helpers/getCriterias";
import IconButton from "@mui/material/IconButton";
import { searchApi } from "@zert-packages/plugins/ExplorerPlugin/API";

const formattedMessages = {
  dialogTitle: <FormattedMessage id="ElementSearchingDialog.DialogTitle" defaultMessage="Find Object" />,
  dialogActionBack: <FormattedMessage id="ElementSearchingDialog.DialogActionBack" defaultMessage="Back" />,
  dialogActionConfirm: <FormattedMessage id="ElementSearchingDialog.DialogActionConfirm" defaultMessage="Confirm" />,
  textFieldLabel: (
    <FormattedMessage id="ElementSearchingDialog.TextFieldLabel" defaultMessage="Search for name or id here" />
  )
};

const StyledListItem = withStyles({
  root: {
    cursor: 'pointer',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
  },
  selected: {
    background: 'inherit'
  }
})(ListItem);

const useStyles = makeStyles({
  DialogContent: {
    display: 'grid',
    height: 'calc(var(--vh, 1vh) * 100)',
    width: '100%',
    gridTemplateRows: '50px 1fr',
    gridGap: 10,
    overflow: 'hidden'
  },
  List: {
    overflowY: 'auto'
  }
});

function ElementSearchingDialog({ customFetch, handleClose, onConfirm, multiSelect = false }) {
  const cl = useStyles();

  const [searchValue, setSearchValue] = useState('');
  const onChangeSearchValue = (e) => setSearchValue(e.target.value);

  const [loading, setLoading] = useState(false);
  const [foundElements, setFoundElements] = useState([]);
  const [selectedElements, setSelectedElements] = useState(multiSelect ? [] : '');

  const selectElement = (isSelected, id) => {
    if (isSelected) setSelectedElements('');
    else setSelectedElements(id);
  };

  const selectElementMulti = (isSelected, id) => {
    if (isSelected) setSelectedElements((prev) => prev.filter((elementId) => elementId !== id));
    else setSelectedElements((prev) => [...prev, id]);
  };

  const onKeyPressSearchField = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setLoading(true);
      const search =
        (customFetch && customFetch(searchValue)) || handlePostTextAsParam('/search/searchByText', searchValue);
      search
        .then((res) => {
          setLoading(false);
          setFoundElements(filterSaveVersionIds(res.result));
        })
        .catch((e) => {
          throw new Error(e);
        });
    }
  };

  const handleOnConfirm = () => {
    let confirmState = null;
    if (multiSelect) {
      confirmState = selectedElements.map((selectedElementId) =>
        foundElements.find((foundElement) => selectedElementId === foundElement.versionId)
      );
    } else {
      confirmState = foundElements.find((e) => e.versionId === selectedElements);
    }

    onConfirm(confirmState);
    handleClose();
  };

  const checkIsSelectedElement = (id) => (multiSelect ? selectedElements.includes(id) : selectedElements === id);

  const createOnSelect = (isSelected, id) => () =>
    multiSelect ? selectElementMulti(isSelected, id) : selectElement(isSelected, id);

  const canShowConfirm = () => (multiSelect ? selectedElements.length > 0 : selectedElements !== '');
  return (
    <Dialog fullWidth onClose={handleClose} open>
      <DialogTitle>{formattedMessages.dialogTitle}</DialogTitle>
      <DialogContent className={cl.DialogContent}>
        <TextField
          value={searchValue}
          label={formattedMessages.textFieldLabel}
          onChange={onChangeSearchValue}
          onKeyPress={onKeyPressSearchField}
        />
        {loading ? (
          <Loader />
        ) : (
          <List className={cl.List}>
            {foundElements.map((element) => {
              return (
                <ListElement
                  mimeType={element.mimeType || 'no-mime-type'}
                  versionId={element.versionId}
                  selectedFn={checkIsSelectedElement}
                  name={element.name}
                  elementId={element.id}
                  key={element.versionId}
                  onClickFn={createOnSelect}
                  versionLabel={element.label}
                />
              );
            })}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        {canShowConfirm() && (
          <Button onClick={handleOnConfirm} color="primary" variant="outlined">
            {formattedMessages.dialogActionConfirm}
          </Button>
        )}
        <Button onClick={handleClose} color="primary" variant="outlined" autoFocus>
          {formattedMessages.dialogActionBack}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ElementSearchingDialog;

function createVersionsSearchCriteria(elementId) {
  const searchConditions = {
    'se.zert.backend.search.VersionCriteria': {
      className: 'se.zert.backend.search.VersionCriteria',
      expanded: true,
      values: [
        {
          key: 'expanded',
          value: true
        },
        {
          key: 'Filter',
          value: 'all'
        }
      ]
    },
    'se.zert.backend.search.IdCriteria': {
      className: 'se.zert.backend.search.IdCriteria',
      expanded: true,
      values: [
        {
          key: 'expanded',
          value: true
        },
        {
          key: 'Type',
          value: 'element'
        },
        {
          key: 'Id',
          value: elementId
        }
      ]
    }};
  return getCriterias(searchConditions, false);
}

const ListElement = ({ mimeType, name, selectedFn, onClickFn, elementId, versionId, versionLabel }) => {
    const [loading, setLoading] = useState(true);
    const [versions, setVersions] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const selected = selectedFn(versionId);
    const onClick = onClickFn(selected, versionId);

    useEffect(() => {
      if (expanded) {
        searchApi(createVersionsSearchCriteria(elementId)).then(m => {
          if (m && m.result) {
            setVersions(m.result.toSorted((a, b) => b.versionId - a.versionId));
          }
          setLoading(false);
        });
      }
    }, [expanded]);

    return (
      <>
        <ListElementItem
          mimeType={mimeType}
          selected={selected}
          onClick={onClick}
          expanded={expanded}
          key = {versionId.toString()}
          setExpanded={setExpanded}
          name={name}
          versionLabel={expanded ? versionLabel : null}
        />
        {expanded && loading &&
          <StyledListItem>
            <Loader />
          </StyledListItem>}
        {expanded && !loading &&
          versions.map((version) => {
            if (versionId === version.versionId) return ;
            const localSelected = selectedFn(version.versionId);
            const localOnClick = onClickFn(localSelected, version.versionId);
            return (<div style={{marginLeft: '35px'}}>
              <ListElementItem
                mimeType={mimeType}
                selected={localSelected}
                onClick={localOnClick}
                expanded={expanded}
                setExpanded={setExpanded}
                name={name}
                key={`${versionId}.${version.versionId}`}
                versionLabel={version.label}
                showExpanded={false}
              />
            </div>
          )})}
      </>
    );
  };

const ListElementItem = memo(({mimeType, selected, onClick, name, expanded, setExpanded, showExpanded=true,
                          versionLabel = null}) => {
  const Icon = getPluginByMimeType(mimeType).icon;
  return ( <StyledListItem disableGutters>
    {expanded
      ? (showExpanded && <IconButton onClick={() => setExpanded(false)}>
        <KeyboardArrowUpIcon />
      </IconButton>)
      : (showExpanded && <IconButton onClick={() => setExpanded(true)}>
        <KeyboardArrowDownIcon />
      </IconButton>)
    }
    <ListItemIcon onClick={onClick} style={{minWidth: '30px'}}>
      <div
        className="icon-box"
        style={{ fontSize: '1.1rem', padding: '0px 3px 0 0', height: '30px',
          color: selected ? '#ffb951' : 'darkgray',
          verticalAlign: 'middle', marginRight: '10px'
      }}
      >
        {versionLabel !== null
          ? (<div className="version-label" style={{fontSize: '1.4rem', paddingTop:'0px'}}>
            {`(${versionLabel})`}
            </div>)
          : <Icon style={{ height: '30px', width: '30px', fill: selected ? '#ffb951' : 'lightgray' }} />}
      </div>
    </ListItemIcon>
    <ListItemText
      onClick={onClick}
      primaryTypographyProps={{
        letterSpacing: '1px',
        fontSize: '1.1rem',
        noWrap: true,
        component: 'div',
        margin:'5px',
        userSelect: 'none'
      }}
    >
      <SlowTooltip title={name}>
            <span style={{ color: selected ? '#ffb951' : 'inherit' }}>
              {name}
            </span>
      </SlowTooltip>
    </ListItemText>
  </StyledListItem>);
},
  selectiveMemoComparison(
  (p) => p.name,
    (p) => p.selected,
    (p) => p.expanded
)
);

const filterSaveVersionIds = (list) =>
  list.reduce((accum, current) => {
    const hasThatVersionId = !!accum.find((i) => get(i, 'versionId', -1) === get(current, 'versionId'));
    if (hasThatVersionId) return accum;
    return [...accum, current];
  }, []);
