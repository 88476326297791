import React, { memo } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import ExpansionWrapperCriteria from '../common/ExpansionWrapperCriteria';

const rootStyles = {
  display: 'grid',
  gridGap: '10px',
  gridTemplateColumns: '1fr 1fr',
  width: '100%'
};

const IdSearchCriteria = memo(
  ({ foundCriteria, updateCriteria, onChangeExpansionOfCriteria, classNameOfCriteria, isEverythingDisabled }) => {
    const onChangeState = (field, value) => {
      const newValues = foundCriteria.values.map((item) =>
        item.key === field
          ? {
            ...item,
            value
          }
          : item
      );
      updateCriteria(classNameOfCriteria, newValues);
    };

    const handleOnChangeExpansion = (event, isExpanded) => {
      onChangeExpansionOfCriteria(isExpanded, classNameOfCriteria);
    };

    return (
      <ExpansionWrapperCriteria
        title={<FormattedMessage id="idCriteriaCounter.id" defaultMessage="Id" />}
        handleOnChangeExpansion={handleOnChangeExpansion}
        expanded={!!foundCriteria}
      >
        {!!foundCriteria && (
          <div style={rootStyles}>
            <FormControl variant="standard" disabled={isEverythingDisabled}>
              <InputLabel>
                <FormattedMessage id="idCriteriaCounter.type" defaultMessage="Type" />
              </InputLabel>
              <Select
                variant="standard"
                value={foundCriteria.values.find((item) => item.key === 'Type').value}
                onChange={(event) => onChangeState('Type', event.target.value)}
              >
                <MenuItem value="element">
                  <FormattedMessage id="idCriteriaCounter.objectId" defaultMessage="Object id" />
                </MenuItem>
                <MenuItem value="version">
                  <FormattedMessage id="idCriteriaCounter.objectVersionId" defaultMessage="Object version id" />
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              disabled={isEverythingDisabled}
              label="Id"
              margin="dense"
              variant="outlined"
              value={foundCriteria.values.find((item) => item.key === 'Id').value}
              onChange={(event) => onChangeState('Id', event.target.value)}
            />
          </div>
        )}
      </ExpansionWrapperCriteria>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps.foundCriteria, nextProps.foundCriteria)
);

export default IdSearchCriteria;
