import React, { memo, useEffect, useMemo, useState } from 'react';
import { handlePostParamAsString } from '@zert-packages/actions/api';
import getLang from '@zert-packages/utils/getLang';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import makeStyles from '@mui/styles/makeStyles';
import { Button, List } from '@mui/material';
import { ListItem } from 'semantic-ui-react';
import ElementTile from '@zert-packages/components/ElementTile/ElementTile';
import { FormattedMessage, injectIntl } from 'react-intl';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import DelegateeTasksParametersDialog from '@zert-packages/components/common/dialogs/DelegateeTasksParametersDialog';
import { delegateTasks } from '@zert-packages/actions/actionplan';
import { useSnackbar } from 'notistack';
import { showSnackbarChangesSaved, showSnackbarError } from '@zert-packages/components/Snackbars';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    padding: '10px',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '30px 1fr',
    gridGap: 6,
    overflow: 'hidden'
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 6
    }
  },
  List: {
    overflow: 'auto'
  }
});

function UserActionPlan({ userName }) {
  const cl = useStyles();
  const [tasks, setTasks] = useState(null);
  const [selectedTasks, setSelectedTasks] = useState({});
  const snackbar = useSnackbar();
  const fetchTasks = () => {
    handlePostParamAsString(`/actionplan-react/synctasks/${getLang()}/*/*`, userName).then((newTasks) =>
      setTasks(newTasks.left)
    );
  };

  useEffect(() => fetchTasks(), []);

  const onSuccess = () => {
    fetchTasks();
    setSelectedTasks({});
  };

  const handleDelegate = (ids) => {
    renderInExceptionRoot(DelegateeTasksParametersDialog, {
      onConfirm: (login, message) => {
        setTasks(null);
        delegateTasks(login, ids, message)
          .then(() => {
            showSnackbarChangesSaved(snackbar);
            onSuccess();
          })
          .catch((e) => {
            showSnackbarError(snackbar, e.message);
          });
      }
    });
  };

  const onDelegateAll = () => handleDelegate(tasks.map((t) => t.versionId));
  const onDelegateSelected = () => handleDelegate(Object.keys(selectedTasks));
  const selectElement = (versionId, info) => {
    if (selectedTasks.hasOwnProperty(versionId)) {
      setSelectedTasks((prev) => {
        const copy = { ...prev };
        delete copy[versionId];
        return copy;
      });
      return;
    }

    setSelectedTasks((prev) => ({
      ...prev,
      [versionId]: info
    }));
  };

  const disabledDelegateSelected = useMemo(() => Object.keys(selectedTasks).length === 0, [selectedTasks]);

  if (tasks === null) return <Loader />;
  if (tasks.length === 0) return <NoTasks />;
  return (
    <div className={cl.root}>
      <div className={cl.actionsContainer}>
        <Button onClick={onDelegateAll} color="primary" variant="outlined">
          <FormattedMessage id="Administration.UserActionPlan.DelegateAll" defaultMessage="Delegate all" />
        </Button>
        <Button disabled={disabledDelegateSelected} onClick={onDelegateSelected} color="primary" variant="outlined">
          <FormattedMessage id="Administration.UserActionPlan.DelegateSelected" defaultMessage="Delegate selected" />
        </Button>
      </div>
      <List className={cl.List}>
        {tasks.map((task) => (
          <ListItemElement key={task.id} task={task} onSuccess={onSuccess} selectElement={selectElement} />
        ))}
      </List>
    </div>
  );
}

const ListItemElement = memo(
  ({ task, onSuccess, selectElement }) => {
    return (
      <ListItem key={task.id}>
        <ElementTile key={task.id} reloadElement={onSuccess} element={task} selectElement={selectElement} />
      </ListItem>
    );
  },
  selectiveMemoComparison((p) => p.task)
);

export default injectIntl(UserActionPlan);

function NoTasks() {
  return (
    <div
      style={{
        fontSize: '18px',
        color: '#666',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px'
      }}
    >
      <FormattedMessage id="Administration.UserActionPlan.NoTasks" defaultMessage="It seams like there're no tasks" />
    </div>
  );
}
