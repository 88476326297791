import createStyles from '@mui/styles/createStyles';

export const formStyle = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    DialogContent: {
      display: 'flex',
      flexFlow: 'column nowrap',
      '& > *': {
        marginTop: 10
      }
    },
    formControl: {
      margin: theme.spacing(1),
      width: '95%'
    },
    selectEmpty: {
      marginTop: '5px'
    },
    fontWeightRegular: {
      fontWeight: theme.typography.fontWeightRegular
    },
    fontWeightMedium: {
      fontWeight: theme.typography.fontWeightMedium
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 150
    },

    chips: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    chip: {
      margin: theme.spacing(1) / 4
    },
    noLabel: {
      marginTop: theme.spacing(1) * 3
    },

    spinField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 100
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },

    dense: {
      marginTop: 0
    },
    menu: {
      width: 150
    }
  });

export const controlStyles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150
  },
  selectEmpty: {
    marginTop: '5px'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150
  },

  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(1) / 4
  },
  noLabel: {
    marginTop: theme.spacing(1) * 3
  },

  spinField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 100
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  dense: {
    marginTop: 0
  },
  menu: {
    width: 150
  }
});
export const styles = (theme) => ({
  root: {},
  expanded: {
    margin: 0
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  details: {
    display: 'flex',
    padding: '0 1em 1em'
  }
});
