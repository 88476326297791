import React, { memo } from 'react';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import ExpansionWrapperCriteria from '../common/ExpansionWrapperCriteria';

const rootStyles = {
  display: 'grid',
  gridGap: '10px',
  gridTemplateColumns: '1fr 1fr',
  width: '100%'
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  numberOfPeriodContainer: {
    display: 'grid',
    margin: '10px'
  }
});

const useStyles = makeStyles(styles);

const ChecklistSearchCriteria = memo(
  ({ foundCriteria, updateCriteria, onChangeExpansionOfCriteria, classNameOfCriteria, isEverythingDisabled }) => {
    const onChangeState = (field, value) => {
      const newValues = foundCriteria.values.map((item) => {
        if (item.key === field) {
          return {
            ...item,
            value
          };
        }
        return item;
      });
      updateCriteria(classNameOfCriteria, newValues);
    };

    const findValue = (key) => {
      const value = foundCriteria.values.find((item) => item.key === key);
      return value ? value.value : null;
    };
    const classes = useStyles();

    const handleOnChangeExpansion = (event, isExpanded) => {
      onChangeExpansionOfCriteria(isExpanded, classNameOfCriteria);
    };
    return (
      <ExpansionWrapperCriteria
        title={<FormattedMessage id="checklistCriteriaCounter.context" defaultMessage="In checklist" />}
        handleOnChangeExpansion={handleOnChangeExpansion}
        expanded={!!foundCriteria}
      >
        {!!foundCriteria && (
          <div style={rootStyles}>
            <FormControlLabel
              value={findValue('noAnswers')}
              disabled={isEverythingDisabled}
              className={classes.formControl}
              fullWidth
              key="noAnswers_fctl"
              control={
                <Checkbox
                  color="primary"
                  checked={findValue('noAnswers') == 'true'}
                  onChange={(e) => {
                    onChangeState('noAnswers', `${e.target.checked}`);
                  }}
                  value={findValue('noAnswers')}
                  name="No answers"
                  aria-label={<FormattedMessage id="search.rm.noAnswers" defaultMessage="Contains no answers" />}
                  label={<FormattedMessage id="search.rm.noAnswers" defaultMessage="Contains no answers" />}
                />
              }
              label={<FormattedMessage id="search.rm.noAnswers" defaultMessage="Contains no answers" />}
              labelPlacement="end"
            />
            <FormControlLabel
              value={findValue('incorrect')}
              disabled={isEverythingDisabled}
              className={classes.formControl}
              fullWidth
              key="incorrect_fctl"
              control={
                <Checkbox
                  color="primary"
                  checked={findValue('incorrect') == 'true'}
                  onChange={(e) => {
                    onChangeState('incorrect', `${e.target.checked}`);
                  }}
                  value={findValue('incorrect')}
                  name="Incorrect"
                  aria-label={<FormattedMessage id="search.rm.incorrect" defaultMessage="Contains incorrect answers" />}
                  label={<FormattedMessage id="search.rm.incorrect" defaultMessage="Contains incorrect answers" />}
                />
              }
              label={<FormattedMessage id="search.rm.incorrect" defaultMessage="Contains incorrect answers" />}
              labelPlacement="end"
            />
            <div className={classes.numberOfPeriodContainer}>
              <TextField
                disabled={isEverythingDisabled}
                style={{ width: '100%' }}
                key="more_than_risk"
                id="more_than_risk"
                label={<FormattedMessage id="search.riskrow.morethan" defaultMessage="Has more than risks" />}
                className={classes.spinField}
                value={findValue('countGT')}
                onChange={(event) => onChangeState('countGT', event.target.value)}
                type="number"
                variant="outlined"
              />
            </div>
            <div className={classes.numberOfPeriodContainer}>
              <TextField
                disabled={isEverythingDisabled}
                style={{ width: '100%' }}
                key="less_than_risk"
                id="less_than_risk"
                label={<FormattedMessage id="search.riskrow.lessthan" defaultMessage="Has less than risks" />}
                className={classes.spinField}
                value={findValue('countLESS')}
                onChange={(event) => onChangeState('countLESS', event.target.value)}
                type="number"
                variant="outlined"
              />
            </div>

            <div className={classes.numberOfPeriodContainer}>
              <TextField
                disabled={isEverythingDisabled}
                style={{ width: '100%' }}
                id="more_than_measures"
                key="more_than_measures"
                label={<FormattedMessage id="search.measures.morethan" defaultMessage="Has more than measures" />}
                className={classes.spinField}
                value={findValue('countGTM')}
                onChange={(event) => onChangeState('countGTM', event.target.value)}
                type="number"
                variant="outlined"
              />
            </div>
            <div className={classes.numberOfPeriodContainer}>
              <TextField
                disabled={isEverythingDisabled}
                style={{ width: '100%' }}
                id="less_than_measures"
                key="less_than_measures"
                label={<FormattedMessage id="search.measures.lessthan" defaultMessage="Has less than measures" />}
                className={classes.spinField}
                value={findValue('countLESSM')}
                onChange={(event) => onChangeState('countLESSM', event.target.value)}
                type="number"
                variant="outlined"
              />
            </div>
          </div>
        )}
      </ExpansionWrapperCriteria>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps.foundCriteria, nextProps.foundCriteria)
);

export default ChecklistSearchCriteria;
