export const findShowLabel = (active, selectedItem, mimeType) => {
    if (mimeType === "application/zert-phrase" && selectedItem) {
        return !active
    }
    return !selectedItem;
}


export const findShow = (selectedItem, mimeType, active, it) => {
   if(!it.val){
       return false
   }

    if (mimeType === "application/zert-phrase") {
        return !!(active && selectedItem);
    }
    return selectedItem
}

