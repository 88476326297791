import {
  API_ROOT,
  handlePost,
  handlePostFile,
  handlePostParamAsString,
  handlePostTextAsParam, handlePostToApi,
  handleQuery
} from '@zert-packages/actions/api';

import getLang from '@zert-packages/utils/getLang';

export const isSymbolFormat = (format) => {
  if (format.settings.properties) {
    const isSymbol = format.settings.properties.find((settings) => settings.identifier == 'isSymbol');
    return !!(isSymbol && isSymbol.value == 'true');
  }
  return false;
};

export const parseFontFamily = (format) => {
  if (format.settings.properties) {
    const family = format.settings.properties.find((settings) => settings.identifier == 'font-family');
    return family ? family.value : null;
  }
  return null;
};

export function uploadImage(filedata) {
  return handlePostFile(`/image/uploadImageFile`, filedata);
}

export function handleSavePhrase(versionId, phrase) {
  return handlePost(`/phrase/store/${versionId}`, phrase);
}

export function createImage(md5, name) {
  const locale = getLang();
  return handlePost(`/image/createImage/${locale}`, { imageName: name, imagePreview: md5 });
}

export function getDoubles(md5) {
  return handlePostTextAsParam(`/image/getDoubles`, md5);
}

export const loadNewImageReference = (versionId) => {
  return handleQuery(`/part/loadNewImageReference/${versionId}`, false);
};

export const loadPart = (versionId) => {
  return handleQuery(`/part/load/${versionId}`, false);
};

export const loadAsItem = (versionId) => {
  return handleQuery(`/part/loadAsItem/${versionId}`, false);
};

export const initPart = (parentVersionId) => {
  return handleQuery(`/part/init/${parentVersionId}`, false);
};

export const storePart = (versionId, partData) => {
  return handlePostToApi(`/part/store/${versionId}`, partData);
};

export const storePartItem = (versionId, partData) => {
  return handlePostToApi(`/part/storePartItem/${versionId}`, partData);
};

export const load = (versionId, checkout, loadItems) => {
  return handleQuery(`/part/load/${versionId}/${checkout}/${loadItems}`, false);
};

export const loadImage = (versionId, accessToken) => {
  return handleQuery(`${API_ROOT}/image/getImageById/${versionId}?jwtToken=${accessToken}`, false);
};

export const getImageById = (versionId) => {
  return handleQuery(`/image/getImageById/${versionId}`, false); //
};

export const getUnicodeMap = (fontFamily) => {
  return handlePostParamAsString('/common/getUnicodeMap', fontFamily);
};

export const getFontFamily = (fontFamily) => {
  return handlePostParamAsString('/common/getFontFamily', fontFamily);
};
