import React, { useContext, useEffect, useState } from "react";
import DialogColors from '@zert-packages/components/dialog/DialogColors';
import makeStyles from '@mui/styles/makeStyles';
import SelectedColor from '../../SelectedColor';
import { TabsContext } from '../../Stylesheet';
import {
  adobeColor,
  adobeDropMenu,
  adobeRgba,
  handlePageLayout,
  handlePageLayoutSingleLevel
} from "../../utils/helpers";
import TemplateItemRow from '../../TemplateItemRow';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 700,
    fontSize: '1rem',
    marginTop: 5
  },
  wrapperIndent: {
    border: '1px solid #C6C6C5',
    display: 'grid',
    padding: '7px 10px',
    gridRowGap: '10px'
  }
}));

function Group() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const group = values.stylesheet.blockSettings[values.selectedLeftMenu];
  const [color, setColor] = useState({
    backgroundColor: '#ffffff',
    load: false,
  })


  const handleSelectedColor = (val) => {
    setColor(pre => ({ ...pre, backgroundColor:  val}))
    handlePageLayoutSingleLevel(adobeRgba(val), 'backgroundColor', values, setValues);
  };

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleIndent = (val, name) => {
    handlePageLayout(val, name, values, setValues);
  };

  const handleDropMenu = (valDropMenu, name, val) => {
    handlePageLayout(val, name, values, setValues, valDropMenu);
  };

  useEffect(() => {
    if(!color.load && group.backgroundColor){
      setColor(pre => ({
        ...pre,
        backgroundColor: adobeColor(group.backgroundColor),
        load: true}))
    }
  }, [group.backgroundColor])

  return (
    <>
      {group && group.class.includes('Group') && (
        <div>
          <DialogColors
            open={openDialog}
            setOpen={setOpenDialog}
            setText={handleSelectedColor}
            text={{ label: 'Select Color' }}
            type="backgroundColor"
          />
          <SelectedColor
            label="Background color:"
            type="backgroundColor"
            setValueProps={handleSelectedColor}
            handleOpenDialog={handleOpenDialog}
            valueProps={color.backgroundColor}
            adobeRgba={true}
            // valueProps={adobeColor(group.backgroundColor)}
          />
          <div className={cl.label}>Indent</div>
          <div className={cl.wrapperIndent}>
            <TemplateItemRow
              label="Left:"
              type="marginLeft"
              value={{
                value: group.marginLeft.value,
                unit: adobeDropMenu(group.marginLeft.unit)
              }}
              grid="121px 1fr 55px"
              setDropMenu={handleDropMenu}
              setValueProps={handleIndent}
            />
            <TemplateItemRow
              label="Right:"
              type="marginRight"
              value={{
                value: group.marginRight.value,
                unit: adobeDropMenu(group.marginRight.unit)
              }}
              grid="121px 1fr 55px"
              setDropMenu={handleDropMenu}
              setValueProps={handleIndent}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Group;
