import { Checkbox, FormControlLabel, Tab, Tabs, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { adobeWord, handleParagraphType, handleParagraphValue, handleTabsValue } from '../../utils/helpers';
import DialogAddCondition from '../../dialog/DialogAddCondition';
import Loading from '../../Loading';
import { TabsContext } from '../../Stylesheet';
import { templatesData } from '../../data/paragraph/templatesData';
import IndentSpacing from './IndentSpacing';
import Font from './Font';
import TextFlow from '../image/TextFlow';
import BulletsNumbers from './BulletsNumbers';
import Label from './label/Label';
import { markerData } from '../../data/paragraph/markerData';
import { userDefinedData } from '../../data/paragraph/userDefinedData';
import Decorations from "./Decorations";

const useStyles = makeStyles((theme) => ({
  wrapperDropMenu: {
    display: 'grid',
    gridTemplateColumns: '1fr 100px 1fr',
    gridColumnGap: 10,
    color: '#4db1d3',
    alignItems: 'center',
    marginBottom: 10
  },
  wrapperIcons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    cursor: 'pointer'
  },
  tabsRoot: {
    minHeight: 18,
    marginBottom: 15,
    '& .MuiTab-root': {
      padding: '0px 4px 0px 3px',
      minWidth: 'fit-content',
      minHeight: 18,
      background: '#EBEBEB',
      textTransform: 'capitalize'
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#ffffff',
      background: '#4db1d3',
      outline: 'none'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left',
      background: '#E3F2F7'
    }
  },
  activeIcons: {
    color: '#4db1d3',
    cursor: 'pointer'
  },
  disableIcons: {
    color: '#C6C6C5',
    cursor: 'default'
  },
  wrapperTemplate: {
    height: 'Calc(100% - 88px)',
    display: 'grid',
    gridTemplateRows: '1fr',
    gridRowGap: 10
  },
  templateInput: {
    height: '100%',
    width: '100%',
    marginBottom: 0,
    marginTop: 0,
    '& .MuiInputBase-multiline.MuiInputBase-marginDense': {
      height: '100%'
    },
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100% !important'
    }
  },
  title: {
    fontWeight: 700
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gridColumnGap: 10
  }
}));

function Paragraph() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const paragraph = values.stylesheet.paragraphSettings[values.selectedLeftMenu];
  const tabs = ['Indent & Spacing', 'Font', 'Text flow', 'Decorations', 'Bullets/Numbers', 'Label'];
  const [selectedTab, setSelectedTab] = useState(0);
  const [templatesIdx, setTemplatesIdx] = useState(0);
  const templates = paragraph.templates[templatesIdx];
  const templatesType = templates && templates.type && adobeWord(templates.type, true).replace('_', ' ');

  const findReferences = () => {
    return values.addReferences.filter((el) => el.type === 'BLOCK' || el.type === 'TABLE');
  };

  const findTemplatesSelected = (title) => {
    if (!title.condition) {
      return 'Default';
    }
    return `${title.condition.mimeType.split('-').pop()}/${title.condition.format}`;
  };

  const handleChangeSelectedTab = (e, newValue) => {
    setSelectedTab(newValue);
  };

  const addCondition = (val) => {
    const res = {
      ...templatesData,
      condition: {
        format: val.format.split(': ').pop(),
        mimeType: `application/zert-${val.format.split(': ').shift().toLowerCase()}`
      }
    };
    handleTabsValue([...paragraph.templates, res], 'templates', 'paragraphSettings', values, setValues);
  };

  const handleParagraph = (val, type, index) => {
    setTemplatesIdx(index);
  };

  const openConditionDialog = () => {
    renderInExceptionRoot(DialogAddCondition, {
      title: 'Add condition',
      values: {
        format: findReferences
      },
      setValues: addCondition
    });
  };
  const editParagraph = () => {
    renderInExceptionRoot(DialogAddCondition, {
      title: 'Edit condition',
      values: {
        format: findReferences
      },
      edit: {
        type: 'Ancestor',
        format: `${templates.condition.mimeType.split('-')[1].toUpperCase()}:${templates.condition.format}`
      },
      setValues: editCondition
    });
  };

  const editCondition = (val) => {
    const res = {
      type: val.type,
      format: val.format.split(':')[1].trim(),
      mimeType: `application/zert-${val.format.split(':')[0].toLowerCase()}`
    };
    handleParagraphValue(res, 'condition', 'paragraphSettings', templatesIdx, values, setValues);
  };

  const deleteParagraph = () => {
    if (findTemplatesSelected(templates) === 'Default') {
      return;
    }
    const res = paragraph.templates.filter((el, i) => i !== templatesIdx);
    handleTabsValue(res, 'templates', 'paragraphSettings', values, setValues);
  };

  const handleXSL = (e) => {
    const val = e.target.value;
    handleParagraphValue(val, 'template', 'paragraphSettings', templatesIdx, values, setValues);
  };

  const handleMarker = (e) => {
    const val = e.target.checked;
    handleParagraphValue(val, 'marker', 'paragraphSettings', templatesIdx, values, setValues);
  };

  const handleBlockTabs = (val) => {
    if (val === templatesType) {
      return;
    }
    if (val === 'Normal') {
      handleParagraphType(templatesData, 'paragraphSettings', templatesIdx, values, setValues);
    }
    if (val === 'Marker only') {
      handleParagraphType(markerData, 'paragraphSettings', templatesIdx, values, setValues);
    }
    if (val === 'User defined') {
      handleParagraphType(userDefinedData, 'paragraphSettings', templatesIdx, values, setValues);
    }
  };


  return (
    <>
      {templates && paragraph && paragraph.class.includes('ParagraphSettings') ? (
        <>
          <div className={cl.wrapperDropMenu}>
            <ExpanderItem
              valueProps={findTemplatesSelected(templates)}
              propsLabel={paragraph.templates.map((el) => findTemplatesSelected(el))}
              setProps={handleParagraph}
            />

            <div className={cl.wrapperIcons}>
              <AddIcon onClick={openConditionDialog} />
              <span
                onClick={editParagraph}
                className={findTemplatesSelected(templates) === 'Default' ? cl.disableIcons : cl.activeIcons}
              >
                <EditIcon />
              </span>
              <span
                onClick={deleteParagraph}
                className={findTemplatesSelected(templates) === 'Default' ? cl.disableIcons : cl.activeIcons}
              >
                <DeleteIcon />
              </span>
            </div>

            <ExpanderItem
              valueProps={templatesType}
              propsLabel={['Normal', 'Marker only', 'User defined']}
              setProps={handleBlockTabs}
            />
          </div>

          {templatesType === 'Normal' && (
            <>
              <Tabs
                indicatorColor=""
                className={cl.tabsRoot}
                value={selectedTab}
                onChange={handleChangeSelectedTab}
                textColor="primary"
                variant="standard"
                scrollButtons="auto"
                centered
              >
                {tabs.map((el, i) => (
                  <Tab label={el} key={i} />
                ))}
              </Tabs>
              {templates && (
                <>
                  {selectedTab === 0 && <IndentSpacing paragraph={templates} templatesIdx={templatesIdx} />}
                  {selectedTab === 1 && <Font paragraph={templates} templatesIdx={templatesIdx} />}
                  {selectedTab === 2 && <TextFlow paragraph={templates} templatesIdx={templatesIdx} />}
                  {selectedTab === 3  && <Decorations paragraph={templates} templatesIdx={templatesIdx}/>}
                  {selectedTab === 4 && <BulletsNumbers paragraph={templates} templatesIdx={templatesIdx} />}
                  {selectedTab === 5 && <Label paragraph={templates} templatesIdx={templatesIdx} />}
                </>
              )}
            </>
          )}
          {templatesType === 'User defined' && (
            <div className={cl.wrapperTemplate}>
              <div className={cl.wrapperRow}>
                <p className={cl.title}> Template:</p>
                <TextField
                  className={cl.templateInput}
                  value={templates.template}
                  variant="outlined"
                  multiline
                  minRows={4}
                  onChange={handleXSL}
                />
              </div>
              <div className={cl.wrapperRow}>
                <span />
                <FormControlLabel
                  control={<Checkbox onChange={handleMarker} checked={templates.marker} />}
                  label={<span>Marker</span>}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Paragraph;
