import React, {useContext, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ImgTypeWrapper from "../../ImagePlugin/ImgTypeWrapper";
import {SparePartContext} from "../store/SparePartContext";

const PartImagesTabs = ({partImages}) => {
    const {values, setValues} = useContext(SparePartContext)
    const [tabValue, setTabValue] = useState(0)

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setValues(prev => ({
            ...prev,
            selectedElement: partImages[newValue],
            selectedPart: null
        }))
    };

    useEffect(() => {
        if(!values.partImages[tabValue]) {
            setTabValue(tabValue - 1)
        }
    }, [values.partImages])

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange}>
                    {partImages.map((partImage, idx) => <Tab label={partImage.elementInfo?.name || partImage.image?.info?.name} key={idx} />)}
                </Tabs>
            </Box>

            {partImages.map((partImage, idx) => <div
                    hidden={tabValue !== idx}
                    id={`tabpanel-${idx}`}
                >
                    {tabValue === idx && (<ImgTypeWrapper el={partImage} context={SparePartContext} disableHeader={true}/>)}
                </div>
            )}
        </Box>
    );
};

export default PartImagesTabs;