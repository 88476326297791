import React, {useContext, useState} from 'react';
import Box from "@mui/material/Box";
import {Button, Menu} from "@mui/material";
import Divider from "@mui/material/Divider";
import {CHECKOUT_PART_ACTION, LOCK_REFERENCE_ACTION, REMOVE_WORKING_COPY_ACTION} from "../utils/ELEMENT_TILE_CONTS";
import MenuItem from "@mui/material/MenuItem";
import TranslationActions from "./toolbarActions/TranslationActions";
import CopyingActions from "./toolbarActions/CopyingActions";
import UndoActions from "./toolbarActions/UndoActions";
import NewElementActions from "./toolbarActions/NewElementActions";
import ImageActions from "./toolbarActions/ImageActions";
import MoveActions from "./toolbarActions/MoveActions";
import PartActions from "./toolbarActions/PartActions";
import DownloadImageAction from "./toolbarActions/DownloadImageActions";
import {SparePartContext} from "../store/SparePartContext";

const SparePartToolbar = ({selectedElement}) => {
    const {values} = useContext(SparePartContext)
    const [openMoreMenu, setOpenMoreMenu] = useState()

    return (
        <>
            <Box component={"nav"} sx={{padding: '10px 20px', display: 'flex', columnGap: '10px', alignItems: 'center'}}>
                    {values && values.elementType === "application/zert-image" && <>
                        <Divider orientation={"vertical"} sx={{height: '15px'}}/>
                        <DownloadImageAction/>
                    </>}
                    <TranslationActions/>
                    <Divider orientation={"vertical"} sx={{height: '15px'}}/>
                    <CopyingActions/>
                    <Divider orientation={"vertical"} sx={{height: '15px'}}/>
                    <UndoActions/>
                    <Divider orientation={"vertical"} sx={{height: '15px'}}/>
                    <NewElementActions/>
                    {selectedElement && selectedElement?.elementInfo?.mimeType === "application/zert-image" && <>
                        <Divider orientation={"vertical"} sx={{height: '15px'}}/>
                        <ImageActions/>
                    </>}
                    {selectedElement && selectedElement?.elementInfo?.mimeType === "application/zert-part" && <>
                        <Divider orientation={"vertical"} sx={{height: '15px'}}/>
                        <MoveActions/>
                        <Divider/>
                        <PartActions/>
                    </>}
                    <Divider orientation={"vertical"} sx={{height: '15px'}}/>
                    <Button onClick={(event) => setOpenMoreMenu(event.currentTarget)}>
                        More
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={openMoreMenu}
                        open={!!openMoreMenu}
                        onClose={() => setOpenMoreMenu(null)}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem disableRipple sx={{color: 'rgb(24, 142, 199)'}}>
                            <span style={{marginRight: '10px'}}>{CHECKOUT_PART_ACTION.icon}</span>
                            {CHECKOUT_PART_ACTION.name}
                        </MenuItem>
                        <MenuItem disableRipple sx={{color: 'rgb(24, 142, 199)'}}>
                             <span style={{marginRight: '10px'}}>{LOCK_REFERENCE_ACTION.icon}</span>
                            {LOCK_REFERENCE_ACTION.name}
                        </MenuItem>
                        <MenuItem disableRipple sx={{color: 'rgb(24, 142, 199)'}}>
                             <span style={{marginRight: '10px'}}>{REMOVE_WORKING_COPY_ACTION.icon}</span>
                            {REMOVE_WORKING_COPY_ACTION.name}
                        </MenuItem>
                    </Menu>
                </Box>
            <Divider/>
        </>
    );
};

export default SparePartToolbar;