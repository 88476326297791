import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import Delete from '@mui/icons-material/Delete';
import { imagePath } from '@zert-packages/actions/api';
import PhraseWrapper from '../PhrasePlugin/PhraseWrapper';
import { loadTermDb } from '../TranslationJobPlugin/tjReducers';
import { fetchTerms } from './servicesMiddlewares';
import { removeTermSpec } from './API';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '4px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    margin: '2px'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em 1em 0em 1em'
  }
}));

function ReferenceView({ reference, versionId, termId, setTermData }) {
  const cl = useStyles();
  //  const dispatch = useDispatch();
  const isPhrase = reference.right.mimeType.includes('phrase');
  const isImage = reference.right.mimeType.includes('image');
  const CLM = useSelector((state) => state.CLM);
  const locateRefName = (identifier) => {
    return CLM.termReferences.find((item) => item && item.identifier == identifier).label.text;
  };

  const handleDeleteTerm = async (e, id) => {
    e.persist();

    const termData = await removeTermSpec(versionId, termId, reference.right.id);
    setTermData(termData);
    // dispatch(loadTermDb(null));
    // dispatch(fetchTerms(versionId));
  };
  const accessToken = window.localStorage.getItem('accessToken');
  return (
    <div className={cl.root}>
      <div className={cl.header}>
        <Typography variant="h6">{locateRefName(reference.left)}</Typography>
        <IconButton size="small" onClick={(e) => handleDeleteTerm(e, reference.id)}>
          <Delete />
        </IconButton>
      </div>
      {isPhrase && <PhraseWrapper versionId={reference.right.versionId} />}
      {isImage && <img src={`${imagePath}${reference.right.versionId}?jwtToken=${accessToken}`} />}
    </div>
  );
}

export default ReferenceView;
