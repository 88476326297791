import { handleQuery } from '@zert-packages/actions/api';
import getLang from '@zert-packages/utils/getLang';

export const fetchRiskRowTemplates = () => handleQuery(`/riskrowtemplate/riskRowTemplatesOverview`);

export const fetchMeasureTemplate = () => handleQuery(`/measure/measureTemplatesOverview`);

export const fetchWorklfowTemplates = () => handleQuery(`/riskrowtemplate/workflowOverview`);

export const fetchOverviewMatrixes = () => handleQuery(`/riskrowtemplate/matrixesOverview`);

export const fetchRiskRowTemplateByVersionId = (versionId) =>
  handleQuery(`/riskrowtemplate/riskRowTemplateByVersionId/${getLang()}/${versionId}`);
