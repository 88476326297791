import { usePortal } from '@zert-packages/utils/PortalProvider';
import React from 'react';

function Logo({ filename = 'logo.png', ...props }) {
  const portal = usePortal();

  if (portal?.logos?.png) {
    return <img alt="Logo" src={`/${process.env.PUBLIC_URL}resources${portal.logos.png}`} {...props} />;
  }

  return <img alt="Logo" src={`/${process.env.PUBLIC_URL}app/${filename}`} {...props} />;
}

export default Logo;
