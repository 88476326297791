import getTranslation from "@zert-packages/utils/getTranslation.old";
import tinycolor from "tinycolor2";

export const handledListItem = (selectedListItem, leftMenu, setSelectedListItem, setValues, ArrowUp) => {
  const res = {
    index: 0,
    templateList: {}
  };
  if (ArrowUp) {
    const index = selectedListItem - 1;
    if (0 > index) {
      return;
    }
    res.index = index;
    res.templateList = leftMenu[index];
  } else {
    const index = selectedListItem + 1;
    if (index + 1 > leftMenu?.length) {
      return;
    }
    res.index = index;
    res.templateList = leftMenu[index];
  }
  setSelectedListItem(res.index);
  if(setValues){
    setValues((prev) => ({ ...prev, templateList: res.templateList }));
  }

};

export const handleExpander = (expander, setExpander, arrow) => {
  if (arrow) {
    if (expander === 2) {
     return  setExpander(1);
    }
    if (expander === 3) {
      return setExpander(2);
    }
  } else {
    if (expander === 1) {
      return  setExpander(2);
    }

    if (expander === 2) {
      return setExpander(3);
    }
  }
};

export const findLabel = (el) => {
  if (el.label) {
    return el.label.locales && el.label.locales
      ? getTranslation(el.label)
      : el.label.defaultLabel;
  }
  if (el.identifier) {
    return el.identifier;
  }
};

export const convertKey = (keyCode) => {
  if( typeof(keyCode) !== 'number'){
    return  keyCode
  }
  if(!keyCode){
    return
  }
 return  String.fromCharCode(keyCode)
}

export  const adobeName = (name) => {
  if (name.length < 1 || !name) {
    return name
  }
  return name.slice(0, 1) + name.slice(1).toLowerCase();
};



export const adobeColor = ( val) => {
  const {red, green, blue, alpha} = val
  const alphaValue = alpha / 255;
  return `rgba(${red}, ${green}, ${blue}, ${alphaValue})`;
}


export const adobeRgba = (val) => {
  // if(typeof val === 'string' && val.includes('rgba')){
  //   return val
  // }
  const res = convertStringToRGBA(val);
  return  {
        "cmyk": false,
    "red": res.r,
    "green": res.g,
    "blue": res.b,
    "alpha": res.a
  }
}


const convertStringToRGBA = (colorString) => {
  return tinycolor(colorString).toRgb();
};
