import getParameterAsInt from '@zert-packages/utils/getParameterAsInt';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import InfoElementWrapper from '@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper';
import toolbar from './image/toolbar.png';
import approve from './image/approve-icon-green.png';
import PhraseWrapper from './PhraseWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    transition: '1s',
    // 1s width

    gridColumnGap: '25px',
    background: '#E2F2F6',
    minHeight: '100vh',
    padding: '0px 15px',
    '@media  (max-width: 900px)': {
      gridTemplateColumns: '1fr'
    }
  },
  wrapperEditor: {
    background: '#FFFFFD',
    boxShadow: '5px 5px 5px rgb(0 0 0 / 10%)',
    display: 'flex',
    transition: '.5s'
  },
  containerEditor: {
    border: '1px solid rgba(145, 145, 145, 0.25)',
    paddingTop: 3,
    borderRadius: 2,
    borderLeft: '6px solid rgba(119, 173, 72, 0.5)',
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    width: '100%'
  },
  containerEditorInfo: {
    marginLeft: 3
  },
  containerEditorText: {
    fontSize: 10,
    color: '#919191',
    lineHeight: '10px'
  },
  containerEditorInfoTitle: {
    display: 'flex',
    alignItems: 'end'
  },
  containerEditorNumber: {
    fontSize: 10,
    color: '#919191',
    lineHeight: '10px',
    margin: '0px 2px'
  },
  arrowLeft: {
    position: 'relative',
    top: 15,
    fontSize: 24,
    transform: 'rotate(90deg)',
    cursor: 'pointer'
  },
  arrowRight: {
    position: 'relative',
    top: 15,
    fontSize: 24,
    transform: 'rotate(270deg)',
    cursor: 'pointer'
  },
  wrapperArrowLeftExpanded: {
    position: 'relative',
    width: 25,
    '& $arrowLeft': {
      transform: 'rotate(270deg)'
    },
    '& $arrowRight': {
      transform: 'rotate(90deg)'
    }
  },
  wrapperArrowText: {
    transform: 'rotate(270deg)',
    position: 'absolute',
    top: 62,
    right: -18
  },
  wrapperArrowTextRight: {
    transform: 'rotate(90deg)',
    position: 'absolute',
    top: 132,
    right: -88,
    width: 200
  },
  wrapperArrowLeft: {
    width: 15,
    '& $arrowLeft': {
      right: 4
    },
    '& $arrowRight': {
      right: 4
    }
  }
}));

class PhraseView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      versionId: -1
    };
  }

  getParameterAsInt(param) {
    return param ? (Number.isInteger(param) ? param : parseInt(param)) : 0;
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    if (params.versionId !== '-1') {
      this.setState({
        versionId: getParameterAsInt(params.versionId)
      });
    }
  }

  /* componentDidUpdate(prevProps) {
        const { match: { params } } = this.props;
        var versionId = getParameterAsInt(params.versionId);
       if (versionId != this.state.versionId && this.state.versionId != -1) {

            this.setState({
                versionId: getParameterAsInt(params.versionId),
            });
        }

    } */

  render() {
    if (!this.state.versionId || this.state.versionId < 0) {
      return <div className="content-cage" />;
    }

    return (
      <div className="content-cage">
        <InfoElementWrapper>
         <PhraseWrapper versionId={this.state.versionId} />
        </InfoElementWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  news: state.news,
  mynews: state.mynews
});

export default withRouter(connect(mapStateToProps)(PhraseView));
