import React, { useContext, useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import DialogColors from '@zert-packages/components/dialog/DialogColors';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { TabsContext } from '../../../../Stylesheet';
import {
  adobeColor,
  adobeDropMenuUpperCase, adobeRgba,
  adobeWord,
  handlePageLayoutChildObject,
  handlePageLayoutSingleLevel
} from "../../../../utils/helpers";
import SelectedColor from '../../../../SelectedColor';

const useStyles = makeStyles((theme) => ({
  rootRow: {
    display: 'grid',
    gridRowGap: 10,
    border: '1px solid #C6C6C5',
    padding: '7px 10px'
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
    gridColumnGap: 10,
    alignItems: 'center'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    marginBottom: 5
  }
}));

function Background() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const background = values.stylesheet.blockSettings[values.selectedLeftMenu];
  const [color, setColor] = useState({
    backgroundColor: '#ffffff',
    load: false,
  })
  const dropMenuDataImage = [{ name: 'None' }, { name: 'Include' }, { name: 'User defined' }];

  const dropMenuHorizontalPosition = [
    { name: 'None' },
    { name: 'Left' },
    { name: 'Center' },
    { name: 'Right' },
    { name: 'Justify' }
  ];

  const handleSelectedColor = (val) => {
    setColor(pre => ({ ...pre, backgroundColor:  val}))
    handlePageLayoutSingleLevel(adobeRgba(val), 'backgroundColor', values, setValues);
  };

  const handleExpander = (val, name) => {
    handlePageLayoutChildObject(adobeDropMenuUpperCase(val.name), 'type', name, values, setValues);
  };

  const handleSelectedDropMenuAnother = (val, type) => {
    handlePageLayoutSingleLevel(adobeDropMenuUpperCase(val.name), type, values, setValues);
  };

  const handleExpanderImg = (val, name) => {
    handlePageLayoutChildObject(val, 'include', name, values, setValues);
  };

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const includedFind = () => {
    if (!values.stylesheet) {
      return;
    }
    return Object.keys(values.stylesheet.includedElements).map((el) => el.split('[')[1].replace(/['\]',]/g, ''));
  };

  useEffect(() => {
    if(!color.load && background.backgroundColor){
      setColor(pre => ({
        ...pre,
        backgroundColor: adobeColor(background.backgroundColor),
        load: true}))
    }
  }, [background.backgroundColor])


  return (
    <div
      style={{
        height: 'Calc(100% - 123px)'
      }}
    >
      <DialogColors
        open={openDialog}
        setOpen={setOpenDialog}
        setText={handleSelectedColor}
        text={{ label: 'Select Color' }}
      />
      <SelectedColor
        label="Background color:"
        setValueProps={handleSelectedColor}
        handleOpenDialog={handleOpenDialog}
        valueProps={color.backgroundColor}
        style={{ grid: '121px 30px 1fr 25px' }}
        type="backgroundColor"
      />
      <p className={cl.title}>Text flow</p>
      <div className={cl.rootRow}>
        <div className={cl.wrapperRow}>
          <div className={cl.title}>Image:</div>
          <ExpanderItem
            valueProps={adobeWord(background.backgroundImage.type, true)}
            propsLabel={dropMenuDataImage}
            setProps={handleExpander}
            type="backgroundImage"
          />
        </div>
        {adobeWord(background.backgroundImage.type, true) !== 'None' && (
          <div className={cl.wrapperRow}>
            <div className={cl.title} />
            <ExpanderItem
              valueProps={background.backgroundImage.include}
              propsLabel={includedFind()}
              setProps={handleExpanderImg}
              type="backgroundImage"
              key={background.backgroundImage.include}
            />
          </div>
        )}
        <div className={cl.wrapperRow}>
          <div className={cl.title}>Horizontal position:</div>
          <ExpanderItem
            valueProps={adobeWord(background.backgroundPositionHorizontal, true)}
            propsLabel={dropMenuHorizontalPosition}
            setProps={handleSelectedDropMenuAnother}
            type="backgroundPositionHorizontal"
            disabled={adobeWord(background.backgroundImage.type, true) === 'None'}
          />
        </div>
        <div className={cl.wrapperRow}>
          <div className={cl.title}>Vertical position:</div>
          <ExpanderItem
            valueProps={adobeWord(background.backgroundPositionVertical, true)}
            propsLabel={dropMenuHorizontalPosition}
            setProps={handleSelectedDropMenuAnother}
            type="backgroundPositionVertical"
            disabled={adobeWord(background.backgroundImage.type, true) === 'None'}
          />
        </div>
      </div>
    </div>
  );
}

export default Background;
