import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TableCell from '@mui/material/TableCell';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import isReadOnly from '@zert-packages/utils/isReadOnly';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { getFontFamily, getUnicodeMap } from './API';

const calcNumDisplayableSymbols = (font) => {
  let totalSymbols = 0;
  for (let c = 0; c < '\uFFFF'; c++) {
    if (font != null && !font.canNotDisplay.indexOf(c) >= 0 && !font.isWhitespace.indexOf(c) >= 0) {
      totalSymbols++;
    }
  }
  return totalSymbols;
};

export const formStyle = (theme) =>
  createStyles({
    selectedCell: {
      backgroundColor: `rgba(5, 54, 75, 0.08)`
    }
  });
const useStyles = makeStyles(formStyle);

function SymbolSelectDialog({ unmountComponent, onConfirm, fontFamily }) {
  const handleConfirm = () => {
    unmountComponent();
    if (onConfirm) {
      onConfirm(selectedSymbol);
    }
  };
  const handleCancel = () => {
    unmountComponent();
  };
  const classes = useStyles();
  const [font, setFont] = useState();
  const [fontMap, setFontMap] = useState();
  const [area, setArea] = useState();
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const loadFontFamily = async () => {
    const font = await getFontFamily(fontFamily);
    const fontMap = await getUnicodeMap(fontFamily);
    setFont(font);
    setFontMap(fontMap);
    setArea(fontMap.unicodeMap[0]);
  };

  console.log('area', area);

  useEffect(() => {
    loadFontFamily();
  }, []);

  const getCellsColumns = (start, to) => {
    const array = [];
    for (let c = start; c < to && c < '\uFFFF'.charCodeAt(0); c++) {
      const char = String.fromCharCode(c);
      if (
        font != null &&
        (!font.canNotDisplay || !font.canNotDisplay.indexOf(char) >= 0) &&
        (!font.isWhitespace || !font.isWhitespace.indexOf(char) >= 0)
      ) {
        array.push(
          <TableCell
            hover
            className={cn(char === selectedSymbol ? classes.selectedCell : '')}
            selected={char === selectedSymbol}
            onClick={() => {
              console.log('c', char, c);
              setSelectedSymbol(char);
            }}
            // style={column.style}
            key={`cellId${String.fromCharCode(c)}`}
          >
            {String.fromCharCode(c)}
          </TableCell>
        );
      }
    }
    return array;
  };

  const getCellRows = () => {
    const rows = [];
    for (let c = area.startAt.charCodeAt(0) / 13; c < area.endAt.charCodeAt(0) / 13; c++) {
      rows.push(
        <TableRow
          role="checkbox"
          tabIndex={-1}
          key={`row_${c}`}
          /*  selected={selectedRowId === row.id} */
          //  className={cl.row}
        >
          {getCellsColumns(c * 13, (c + 1) * 13)}
        </TableRow>
      );
    }
    return rows;
  };
  // console.log('area', area);
  // console.log('selectedSymbol', selectedSymbol);
  return (
    <Dialog open onClose={unmountComponent}>
      <DialogTitle>
        <FormattedMessage id="SymbolSelectDialog.title" defaultMessage="Select symbol" />
      </DialogTitle>
      <DialogContent>
        {selectedSymbol && (
          <TextField
            fullWidth
            value={selectedSymbol}
            InputProps={{
              readOnly: true
            }}
          />
        )}
        {fontMap && area && (
          <FormControl fullWidth>
            <InputLabel htmlFor="area">
              <FormattedMessage id="symbol.dialog.area" defaultMessage="Area" />{' '}
            </InputLabel>
            <Select
              autoFocus
              value={area}
              onChange={(e) => {
                setArea(e.target.value);
              }}
              inputProps={{
                name: 'area',
                id: 'area'
              }}
            >
              {fontMap.unicodeMap.map((fonArea) => (
                <MenuItem value={fonArea}>
                  <em>{fonArea.name}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {area && (
          <Table stickyHeader>
            <TableBody>{getCellRows()}</TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary" disabled={!selectedSymbol}>
          <FormattedMessage id="SymbolSelectDialog.buttonBack" defaultMessage="Select" />
        </Button>
        <Button onClick={handleCancel} color="primary" variant="outlined" autoFocus>
          <FormattedMessage id="SymbolSelectDialog.buttonCancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SymbolSelectDialog;
