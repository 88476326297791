import { Provider, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { CloseButton, showSnackbarError } from '@zert-packages/components/Snackbars';
import React, { Fragment } from 'react';
import getLang from '@zert-packages/utils/getLang';

import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';

import { getStore, handleQuery, handleQueryFileAsResult } from '@zert-packages/actions/api';
import { Button, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PreviewWizardDialog from './PreviewWizardDialog/PreviewWizardDialog';
import {
  generatingMessage,
  getPreviewList,
  loadLayoutsList,
  setAllLayouts,
  setCompareWithVersionId,
  setCompareWithVersions,
  setFallbackLocale,
  setLayoutFilters,
  setLayoutProperties,
  setLayoutSettings,
  setMultipleLanguage,
  setSelectedLanguage,
  startPreviewList,
  stopPreviewList
} from './previewReducers';
import {
  getSettingsTemplateForLayout,
  loadAllLayouts,
  loadCompareWithVersions,
  loadLayoutFilters,
  loadLayouts,
  loadPreviewList,
  previewOnExternalServer
} from './API';

let timer = null;
export const startPreviewCatch = () => (dispatch) => {
  clearInterval(timer);
  dispatch(stopPreviewList(false));
  dispatch(startPreviewList(true));
  // timer = setInterval(() => {
  //  dispatch(fetchPreviewList())
  // }, 1000);
  dispatch(fetchPreviewList());
};

export const stopPreview = () => (dispatch) => {
  dispatch(stopPreviewList(true));
  clearInterval(timer);
};

const counter = 1;

export const downloadLayouts =
  () =>
  async (dispatch, getState, { snackbar }) => {
    const { PREVIEW } = getState();
    if (!PREVIEW.layouts) {
      const layouts = await loadLayouts();
      dispatch(loadLayoutsList(layouts));
    }
  };

export const downloadAllLayouts =
  () =>
  async (dispatch, getState, { snackbar }) => {
    const { allLayouts } = getState().PREVIEW;
    if (!allLayouts || !allLayouts.length) {
      const layouts = await loadAllLayouts();
      dispatch(setAllLayouts(layouts));
    }
  };

const generatePreviewMockUp = () => ({
  name: <FormattedMessage id="RMP.GenerateReportMockUp.Name" defaultMessage="RMP Report" />,
  uuid: uuidv4()
});

export const preview =
  (element, compare, showLanguages, publishingId) =>
  async (dispatch, getState, { snackbar }) => {
    const generatedReport = generatePreviewMockUp();
    /* const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="CLM.Publishing.Snackbar.GeneratingLabel"
                          defaultMessage="...generating preview"/>,
                              generatedReport.uuid
    ); */

    const onClose = () => {
      //    debugger
      handleQuery(`/report-react/updatePreviewProgressStatus/${generatedReport.uuid}/true`);
    };
    const generatingSnackbar = createInProgressSnack(snackbar, null, onClose, generatedReport.uuid);

    const {
      compareWithVersionId,
      selectedLayout,
      metadata,
      previewPublishingFilters,
      layoutProperties,
      multipleSelectedLanguage,
      languageSeparated,
      selectedLayoutFilter,
      fallbackLocale,
      layoutSettings,
      selectedLanguage
    } = getState().PREVIEW;

    const reportSettings = {
      versionId: element.versionId,
      compareWithVersionId: compare ? compareWithVersionId : -1,
      layoutVersionId: selectedLayout,
      layoutSettings: {
        template: layoutSettings[selectedLayout],
        settings: layoutProperties[selectedLayout]
      },
      separatedLanguages: showLanguages ? languageSeparated : false,
      fallbackLanguage: fallbackLocale,
      languages: showLanguages ? multipleSelectedLanguage : [selectedLanguage],
      layoutFilterUUID: selectedLayoutFilter,
      publishingId: publishingId || -1,
      clientFilters: previewPublishingFilters,
      metadataCommentSettings: !compare ? [] : metadata
    };
    previewOnExternalServer(generatedReport.uuid, reportSettings)
      .then((res) => {
        console.log(res);
        //        checkReportStatus(res, snackbar, generatingSnackbar, null, 'pdf');
        checkPreviewStatus(res, snackbar, generatingSnackbar, generatedReport, 'pdf', null, element, dispatch);
      })
      .catch((e) => {
        snackbar.closeSnackbar(generatingSnackbar);
        showSnackbarError(snackbar, e.message);
      });
  };
export const downloadLayoutFilters =
  () =>
  async (dispatch, getState, { snackbar }) => {
    // const PREVIEW = getState().PREVIEW;

    const layoutFilters = await loadLayoutFilters();
    dispatch(setLayoutFilters(layoutFilters));
  };
export const convertSettingsToProperties = (settings) => {
  return settings
    .map((setting) => createPropertyFromSetting(setting.property, null, settings))
    .filter((setting) => setting != null);
};

export const setExistingPropertyFromSetting = (layoutVersionId, settings) => (dispatch) => {
  dispatch(setLayoutProperties({ layoutVersionId, properties: convertSettingsToProperties(settings) }));
};

export const createPropertyFromSetting = (property, newvalue, existingProperties) => {
  let newValue = null;

  if (newvalue && newvalue.identifier == property.identifier) {
    newValue = newvalue.value;
  }

  if (existingProperties && !newValue) {
    const prop = existingProperties.find(
      (existing) =>
        existing && existing.property.identifier == property.identifier && property.class == existing.property.class
    );
    if (prop) {
      return {
        class: prop.class,
        property: prop.property,
        value: prop.value
      };
    }
  }

  if (property.class == 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text') {
    return {
      class: 'se.zert.backend.rest.common.pojo.ClientSettings$TextSetting',
      property,
      value: newValue || property.value
    };
  }
  if (property.class == 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Number') {
    return {
      class: 'se.zert.backend.rest.common.pojo.ClientSettings$NumberSetting',
      property,
      value: newValue || property.value
    };
  }
  if (property.class == 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Choice') {
    const selected = property.items.filter((item) => item.selected);

    return {
      class: 'se.zert.backend.rest.common.pojo.ClientSettings$ChoiceSetting',
      property,
      value: newValue || selected.map((sel) => sel.value)
    };
  }
  if (property.class == 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Date') {
    return {
      class: 'se.zert.backend.rest.common.pojo.ClientSettings$DateSetting',
      property,
      value: newValue || property.value
    };
  }
  if (property.class == 'se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Hidden') {
    return {
      class: 'se.zert.backend.rest.common.pojo.ClientSettings$HiddenSetting',
      property,
      value: newValue || property.value
    };
  }
  return null;
};

export const updateLayoutProperties = (layoutVersionId, settings, newvalue) => (dispatch, getState) => {
  const { layoutProperties } = getState().PREVIEW;

  const properties = settings
    .map((setting) => createPropertyFromSetting(setting, newvalue, layoutProperties[layoutVersionId]))
    .filter((setting) => setting != null);
  dispatch(setLayoutProperties({ layoutVersionId, properties }));
};

const findLocale = (activeLocales, localeCode) => {
  return activeLocales.find((locale) => locale.value == localeCode);
};

const findNearestLocale = (activeLocales, localeCode) => {
  const code = localeCode.split('_')[0];
  return activeLocales.find((locale) => locale.language == code);
};

export const updateSelectedLocale = () => (dispatch, getState) => {
  const { selectedLanguage, multipleSelectedLanguage, fallbackLocale } = getState().PREVIEW;
  const { mylocale, activeLocales } = getState();
  const myLocale = !mylocale || mylocale == 'default' ? getLang() : mylocale;
  if (!selectedLanguage || !findLocale(activeLocales, selectedLanguage)) {
    if (findNearestLocale(activeLocales, myLocale)) {
      dispatch(setSelectedLanguage(findNearestLocale(activeLocales, myLocale).value));
    } else {
      dispatch(setSelectedLanguage(activeLocales[0].value));
    }
  }

  if (!fallbackLocale || !findLocale(activeLocales, fallbackLocale)) {
    if (findNearestLocale(activeLocales, myLocale)) {
      dispatch(setFallbackLocale(findNearestLocale(activeLocales, myLocale).value));
    } else {
      dispatch(setFallbackLocale(activeLocales[0].value));
    }
  }

  const newLocales = multipleSelectedLanguage.filter((locale) => findLocale(activeLocales, locale));
  if (newLocales.length != multipleSelectedLanguage.length) {
    dispatch(setMultipleLanguage(newLocales));
  }
};

export const downloadCompareWithVersionId =
  (element, showMe) =>
  async (dispatch, getState, { snackbar }) => {
    const { compareWithVersionId, compareWithVersions } = getState().PREVIEW;

    if (compareWithVersionId != element.versionId) {
      const versions = await loadCompareWithVersions(element.id);
      const versionsUpdated = versions.map((version) => ({ ...version, nameLb: `${version.name}(${version.label})` }));
      dispatch(
        setCompareWithVersions(
          showMe
            ? versionsUpdated
            : [
                versions
                  .filter((version) => version.versionId != element.versionId)
                  .map((version) => ({
                    ...version,
                    nameLb: `${version.name}(${version.label})`
                  })),
                {
                  versionId: -1,
                  id: -1,
                  label: '--'
                }
              ]
        )
      );
      dispatch(setCompareWithVersionId(showMe && versions.length ? versions[versions.length - 1].versionId : -1));
    }
  };

export const downloadLayoutSettings =
  (versionId) =>
  async (dispatch, getState, { snackbar }) => {
    const { PREVIEW } = getState();

    if (!(versionId in PREVIEW.layoutSettings)) {
      const settings = await getSettingsTemplateForLayout(versionId);
      dispatch(setLayoutSettings({ versionId, settings }));
    }
  };

const saveData = (function () {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  return function (blob, fileName, isOpen, mymeType) {
    // var// json = JSON.stringify(data),
    // blob = new Blob([json], {type: "octet/stream"}),
    const url = window.URL.createObjectURL(new Blob([blob], { type: mymeType }));
    //   url = window.URL.createObjectURL(blob);
    a.href = url;
    isOpen ? a.removeAttribute('download') : (a.download = fileName);
    a.target = '_blank';
    a.click();
    // window.open(url);
    // window.URL.revokeObjectURL(url);
  };
})();
export const openPreview = '/report-react/openPreviewFile/';
export const downloadPreview =
  (isOpen, uuid, name) =>
  async (dispatch, getState, { snackbar }) => {
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="CLM.PrintManager.Snackbar.Preview" defaultMessage="...downloading Preview" />,
      null,
      uuid
    );
    try {
      const blob = await handleQueryFileAsResult(openPreview + uuid);
      saveData(blob, `${name}.pdf`, isOpen, 'application/pdf');
      snackbar.closeSnackbar(generatingSnackbar);
    } catch (e) {
      snackbar.closeSnackbar(generatingSnackbar);
      showSnackbarError(snackbar, e.message);
    }
  };

export const generatePreviewReport =
  (element, publishingId) =>
  (dispatch, getState, { snackbar }) => {
    renderInExceptionRoot(PreviewWizardDialog, {
      element: element,

      onConfirm: (simplified) => {
        dispatch(preview(element, !simplified, !simplified, -1));
      }
    });
  };

export const createOpenPreviewSnack = (dispacth, element, snackbar, reportUUID, mimeType) => {
  const onClickOpenReport = (isOpen) => {
    dispacth(downloadPreview(isOpen, reportUUID, element.name));
  };
  return snackbar.enqueueSnackbar(
    <FormattedMessage
      id="Preview.SnackBar.ReportHasBeenGenerated.Label"
      defaultMessage="Your preview has been generated"
    />,
    {
      variant: 'info',
      persist: true,
      action: (key) => (
        <>
          <Button
            size="small"
            variant="outlined"
            style={{ color: 'white', border: '1px solid white' }}
            onClick={() => onClickOpenReport(true)}
          >
            {' '}
            <FormattedMessage id="Preview.SnackBar.PreviewHasBeenGenerated.OpenButton" defaultMessage="Open" />{' '}
          </Button>
          <Button
            size="small"
            variant="outlined"
            style={{ color: 'white', border: '1px solid white', marginLeft: '0.25em' }}
            onClick={() => {
              onClickOpenReport(false);
              snackbar.closeSnackbar(key);
            }}
          >
            {' '}
            <FormattedMessage
              id="Preview.SnackBar.PreviewHasBeenGenerated.DownloadButton"
              defaultMessage="Download"
            />{' '}
          </Button>
          <CloseButton onClick={() => snackbar.closeSnackbar(key)} />
        </>
      )
    }
  );
};

const WhiteCircularProgress = withStyles({
  root: {
    marginRight: 10
  },
  colorPrimary: {
    color: 'white'
  }
})(CircularProgress);

export const createInProgressSnack = (snackbar, message, onClose, uuid) => {
  return snackbar.enqueueSnackbar(
    <Provider store={getStore()}>
      {' '}
      <SnackBarMessage uuid={uuid} />{' '}
    </Provider>,
    {
      variant: 'info',
      persist: true,
      /* TransitionComponent={state.Transition}, */
      /*  TransitionProps : {appear : false, enter: false, exit : false}, */
      /* TransitionComponent : <Fade in/>, */
      transitionDuration: { appear: 0, enter: 0, exit: 0 },
      action: (key) => (
        <>
          <WhiteCircularProgress size={20} />
          <CloseButton
            onClick={() => {
              if (onClose) onClose();
              snackbar.closeSnackbar(key);
            }}
          />
        </>
      )
    }
  );
};

function SnackBarMessage({ uuid }) {
  const loadingMessage = useSelector((state) => state.PREVIEW.loadingMessage);

  return <div>{loadingMessage[uuid]}</div>;
}

export const checkPreviewStatus = (
  report,
  snackbar,
  generatingSnackbar,
  generatedReport,
  mimeType,
  callback,
  element,
  dispatch
) => {
  handleQuery(`/report-react/updatePreviewProgressStatus/${report.callbackId}/false`)
    .then((newReport) => {
      if (newReport !== null && newReport.active) {
        let newSnackBar = generatingSnackbar;
        if (!generatingSnackbar) {
          //  snackbar.closeSnackbar(generatingSnackbar);
          const onClose = () => {
            //     debugger
            handleQuery(`/report-react/updatePreviewProgressStatus/${generatedReport.uuid}/true`);
          };

          newSnackBar = createInProgressSnack(snackbar, null, onClose, report.callbackId);
        }
        if (newReport.message) {
          dispatch(generatingMessage({ message: newReport.message, uuid: report.callbackId }));
        }
        checkPreviewStatus(newReport, snackbar, newSnackBar, generatedReport, mimeType, callback, element, dispatch);
      } else {
        snackbar.closeSnackbar(generatingSnackbar);
        if (newReport !== null && newReport.clientException) {
          showSnackbarError(snackbar, newReport.clientException);
        } else if (mimeType) {
          createOpenPreviewSnack(dispatch, element, snackbar, generatedReport.uuid, mimeType, element, dispatch);
        } else if (callback && typeof callback === 'function') callback();
      }
    })
    .catch((e) => {
      snackbar.closeSnackbar(generatingSnackbar);
      showSnackbarError(snackbar, e);
      if (callback && typeof callback === 'function') callback();
    });
};

export const fetchPreviewList =
  () =>
  async (dispatch, getState, { snackbar }) => {
    const { stopPreview } = getState().PREVIEW;

    clearInterval(timer);
    if (!stopPreview) {
      const previewList = await loadPreviewList();
      dispatch(getPreviewList(previewList));
      dispatch(startPreviewList(false));
      timer = setInterval(() => {
        dispatch(fetchPreviewList());
      }, 1000);
    }
  };
