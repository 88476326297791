import React from "react";
import {makeStyles} from "@mui/styles";
import {useSelector} from "react-redux";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import getTranslation from "@zert-packages/utils/getTranslation.old";

const useStyles = makeStyles((theme) => ({
    wrapperExpander: {
        marginBottom: 10,
        display: 'grid',
        gridTemplateColumns: "auto 1fr",
        gridColumnGap: 10,
    },
    label: {
        fontWeight: '700'
    },
    wrapperFormat: {
        '& button': {
            textTransform: 'none',
        },
    }
}))

const BlockFormat = () => {
    const cl = useStyles();
    const {formatTemplate} = useSelector((state) => state)
    const adobeBlockFormat = formatTemplate && formatTemplate.blockFormats.map(el => ({
        ...el,
        name: getTranslation(el.label)
    }))

    //     getTranslation(item.label
    return (<div className={cl.wrapperExpander}>
        <div className={cl.label}>Block format</div>
        {adobeBlockFormat && <div className={cl.wrapperFormat}>
            <ExpanderItem
                valueProps={adobeBlockFormat[0].name}
                propsLabel={adobeBlockFormat}
            />
        </div>}
    </div>)

}

export default BlockFormat