import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@mui/styles";
import RowDialog from "./RowDialog";
import FieldDialog from "./FieldDialog";
import DropMenu from "@zert-packages/components/DropMenu";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogColors from "@zert-packages/components/dialog/DialogColors";
import tinycolor from "tinycolor2";
import SelectedColor from "../../StylesheetPlugin/SelectedColor";
import { findLineType } from "../utils/findAreas";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 414,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700
  },
  wrapper: {
    display: "grid",
    gridRowGap: 10
  },
  wrapperButton: {
    display: "flex",
    marginTop: 30
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}))

const dropLineType = [
  {name: 'DOTTED', val: 'PRICKAD'},
  {name: 'SOLID', val: 'SOLID'},
  {name: 'DASHED', val: 'STRECKAD' },
  {name: 'DOTDASH', val: 'PUNKT STRECKAD'},
]



const DialogArrow = ({ setValues , el, action }) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [uuid] = useState(uuidv4());
  const [arrow, setArrow] = useState({
    id: uuid,
    height: 100,
    line: 2,
    rotate: 0,
    lineType: dropLineType[1],
    lineColor: '#000000',
    dash: el?.dash ||  [2, 5],
  })

  const adobeRgba = (val) => {
    if(val.includes('rgba')){
      return val
    }
    const res = convertStringToRGBA(val);
    return  `rgba(${res.r}, ${res.g}, ${res.b}, ${res.a})`
  }


  const convertStringToRGBA = (colorString) => {
    return tinycolor(colorString).toRgb();
  };



  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if(action){
      action()
    }
    setValues(pre => ({
      ...pre,
      addImgPhraseType: 'arrow',
      phraseContent: {
        ...arrow,
        ...findLineType(arrow.lineType.name),
        lineColor: adobeRgba(arrow.lineColor),
      }
    }));
    handleClose();
  };

  const handleForm = (el, name) => {
    setArrow(pre => ({ ...pre, [name]: el }));
  };

  const handleSelectedColor = (val, name) => {
    setArrow(pre => ({ ...pre, [name]: val }));
  }

  const handleOpenDialog = () => {
    renderInExceptionRoot(DialogColors, {
      text:{ label: 'Line Color' },
      type:'lineColor',
      setText: handleSelectedColor
    })
  }

  useEffect(() => {
    if(el){
      setArrow(pre => ({ ...pre,
       ...el,
        lineType: dropLineType.find(it => it.val === el.lineType)
      }));
    }
  }, [el])



  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>Edit Arrow</span>
      <CloseIcon onClick={handleClose} />
    </div>
    <div className={cl.wrapper}>
      <RowDialog title={"Lengh:"} style={{gridTemplateColumns: "90px 1fr",}}>
        <FieldDialog
          val={arrow.height}
          setValue={setArrow}
          name={"height"}
        />
      </RowDialog>
      <RowDialog title={"Line:"} style={{gridTemplateColumns: "90px 1fr",}}>
        <FieldDialog
          val={arrow.line}
          setValue={setArrow}
          name={"line"}
        />
      </RowDialog>
      <RowDialog title={"Rotate:"} style={{gridTemplateColumns: "90px 1fr",}}>
        <FieldDialog
          val={arrow.rotate}
          setValue={setArrow}
          name={"rotate"}
        />
      </RowDialog>
      <RowDialog title={"Line type:"} style={{gridTemplateColumns: "90px 1fr",}}>
        <DropMenu
          dropItem={dropLineType}
          selected={ arrow.lineType}
          /*selected={arrow.lineType === "PRICKAD" ?  'DOTTED' : arrow.lineType }*/
          name={"lineType"}
          setDropItem={handleForm}
        />
      </RowDialog>
      <SelectedColor
        style={{ grid: '82px 30px 1fr 25px' }}
        label="Line color:"
        type="lineColor"
        setValueProps={handleSelectedColor}
        handleOpenDialog={handleOpenDialog}
        valueProps={arrow.lineColor}
      />
    </div>
    <div className={cl.wrapperButton}>
      <Button className={cl.button} onClick={handleSave}>
        OK
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>
  </Dialog>);
};

export default DialogArrow;