import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import { getTranslation } from "@zert-packages/utils";
import TranslatableIcons from "../../TranslatableIcons";

const useStyles = makeStyles((theme) => ({
  wrapperRowContent: {
    display: "grid",
    gridColumnGap: 10,
    alignItems: "center"
  },
  title: {
    fontSize: "1rem",
    fontWeight: 700,
    marginBottom: 0
  },
  notesInput: {
    margin: 0,
    width: "100%",
    "& input": {
      paddingTop: 5.2,
      paddingBottom: 5.2
    }
  }
}));

function Translatable({ selected, title = "", typeParents, setSelected, style = "110", disabled, styles = {} }) {
  const cl = useStyles();
  const [type, setType] = useState(null);
  const propsLabel = ["Text", "Translatable text", "Include"];
  const include = [
    "attention: Attention!",
    "figure: Figure",
    "function: Function",
    "note: Note!",
    "page: Page",
    "part-name: Part name",
    "part-number: Part number",
    "pieces: Qty",
    "position: Pos",
    "recomended: Rec",
    "table: Table",
    "warning: Warning!",
    "wear-and-tear: W&amp;T"
  ];
  const [loading, setLoading] = useState(false);

  const handleContentLabel = (e) => {
    const val = e.target.value;
    setSelected(handleContent(val, type), typeParents);
  };

  const handleTypeInclude = (val, reset) => {
    setSelected(handleContent(val, type), typeParents);
  };

  const findTitleType = (type) => {
    if (type) {
      if (type === "Include") {
        return { type: "include", values: selected.include, expander: "include" };
      }
      if (type === "Translatable text") {
        return {
          type: "defaultLabel",
          values: selected.text.defaultLabel ? getTranslation(selected.text) : "",
          expander: "Translatable text"
        };
      }
      if (type === "Text") {
        return {
          type: "untranslatableText",
          values: selected.untranslatableText ? selected.untranslatableText : "",
          expander: "Text"
        };
      }
    }
    if (selected) {
      if (selected.include.length > 0) {
        return { type: "include", values: selected.include, expander: "include" };
      }
      if (selected.text.defaultLabel && selected.text.defaultLabel.length > 0) {
        return { type: "defaultLabel", values: selected.text.defaultLabel, expander: "Translatable text" };
      }
      if (selected.untranslatableText.length > 0) {
        return { type: "untranslatableText", values: selected.untranslatableText, expander: "Text" };
      }
    }
    return { values: "", type: "defaultLabel", expander: "Translatable text" };
  };

  const handleContent = (val, type, reset) => {
    if (type === "Text") {
      return { ...selected, untranslatableText: val };
    }

    if (type === "include") {
      return { ...selected, include: val };
    }
    if (type === "Translatable text" || type === "defaultLabel") {
      return {
        ...selected,
        text: {
          ...selected.text,
          defaultLabel: val
        }
      };
    }
    if (type === "Include" || type === "untranslatableText") {
      return { ...selected, untranslatableText: val };
    }
  };

  const handleType = (val) => {
    setType(val);
    setLoading(true);
    if (val === "Text") {
      const res = { ...selected, type: "UNTRANSLATABLE_TEXT" };
      return setSelected(res, typeParents);
    }
    const res = { ...selected, type: val.replace(" ", "_").toUpperCase() };

    setSelected(res, typeParents);
  };

  const handleLng = (lng) => {
    const res = {
      ...selected,
      text: {
        ...selected.text,
        defaultLabel: lng
      }
    };
    setSelected(res, typeParents);
  };

  useEffect(() => {
    setType(findTitleType().expander);
  }, []);

  return (
    <>
      {type && (
        <div
          style={{
            gridTemplateColumns: type && type === "Translatable text" ? `${style}px 180px 1fr 25px` : `${style}px 180px 1fr`,
            ...styles
          }}
          className={cl.wrapperRowContent}
        >
          <p className={cl.title}>{title}</p>
          <ExpanderItem valueProps={type} propsLabel={propsLabel} setProps={handleType} disabled={disabled} />

          {type === "Include" || type === "include" ? (
            <ExpanderItem
              valueProps={findTitleType(loading ? type : false).values}
              propsLabel={include}
              setProps={handleTypeInclude}
              disabled={disabled}
            />
          ) : (
            <TextField
              value={findTitleType(loading ? type : false).values}
              className={cl.notesInput}
              variant="outlined"
              onChange={handleContentLabel}
              disabled={disabled}
            />
          )}
          {type === "Translatable text" && (
            <TranslatableIcons switchLanguages={selected.text} disabled={disabled} setSwitchLanguages={handleLng} />
          )}
        </div>
      )}
    </>
  );
}

export default Translatable;
