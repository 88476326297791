import React, { useEffect, useState } from 'react';
import TreeItem from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslationInfo } from '@zert-packages/actions/explorerActions';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { toLocaleString } from '@zert-packages/plugins/ExplorerPlugin/SearchConditions/helpers/getLocaleByLanguageCode';
import SvgIcon from '@zert-packages/icons/SvgIcon/SvgIcon';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import TranslationStatusFlag from './components/TranslationStatusFlag';

export const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)'
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent'
    }
  },
  xStyle: {
    margin: '-8px',
    paddingLeft: '4px'
  },
  extraXStyle: {
    margin: '-12px',
    paddingLeft: '8px'
  },

  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: theme.spacing(2),
    '& $content': {
      paddingLeft: theme.spacing(2)
    }
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0)
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1
  },
  greyDot: {
    width: '0px',
    height: '0px',
    margin: '3px',
    padding: '8px',
    display: 'flex',
    border: '1px solid grey',
    backgroundColor: 'rgb(128, 128, 128)'
  }
}));

export function TranslationStatusNode(props) {
  const classes = useTreeItemStyles();
  const { info, root, nodeId, latest, labelInfo, color, bgColor, ...other } = props;
  const [children, setChildren] = useState(null);
  const [translations, setTranslations] = useState(null);
  const activeLocales = useSelector((state) => state.activeLocales);

  const expand = async () => {
    const node = await getTranslationInfo(info.versionId, !root);

    // const newParents = Object.keys(parents).flatMap((key) => (latest ? (parents[key].length > 0 ? parents[key][0] :[]): parents[key]));
    const newParents2 = node.children.map((info) => ({ ...info, nodeId: uuidv4() }));
    if (root) {
      setTranslations(node.translations);
    }
    setChildren(newParents2);

    // setExpanded(expanded => {setChildren(newParents2); return [...expanded, {key : nodeId}]})
  };

  useEffect(() => {
    toggle(null);
    if (!root) {
      setTranslations(info.translations);
    }
  }, [info]);

  const toggle = async (e) => {
    if (children == null) {
      expand();
    }
  };

  const getTranslationStatus = (locale) => {
    if (!translations) {
      return <TranslationStatusFlag type="UNTRANSLATED" />;
    }
    const status = translations[toLocaleString(locale)];
    const translationStatus = status ? status.type : 'UNTRANSLATED';
    return <TranslationStatusFlag type={translationStatus} />;
  };

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {/* <LabelIcon color="inherit" className={classes.labelIcon} /> */}
          <SvgIcon
            width={24}
            height={24}
            icon={getPluginByMimeType(info.mimeType).icon}
            className={classes.labelIcon}
            fill="grey"
          />
          <Typography variant="body2" className={classes.labelText}>
            {info.name}
          </Typography>
          {activeLocales && activeLocales.map((locale) => getTranslationStatus(locale))}
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      nodeId={nodeId}
      onClick={toggle}
      onLabel={toggle}
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label
      }}
      {...other}
    >
      {/*      <LoadingNode  key = {"loading" + nodeId} nodeId = {"nnn" + nodeId} labelIcon={MailIcon}/> */}
      {children && children.map((info) => <TranslationStatusNode key={info.nodeId} info={info} nodeId={info.nodeId} />)}
    </TreeItem>
  );
}

/*
StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};
*/
