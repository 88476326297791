import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setMetaData } from '../previewReducers';
import MetadataCommentsTable from '../components/MetadataCommentsTable';

export function MetadataCommentPage() {
  const { metadata } = useSelector((state) => state.PREVIEW);
  const dispatch = useDispatch();
  const setMetadata = (func) => {
    func(metadata, (result) => dispatch(setMetaData(result)));
  };

  return <MetadataCommentsTable setList={setMetadata} list={metadata} />;
}
