import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { FormattedMessage } from 'react-intl';
import ActionMenuNew from '@zert-packages/components/shared/ItemCard/ActionMenu.new';
import useStyles from '../helpers/useStyles';
import { fetchMetaData, removePublishingComment } from '../servicesMiddlewares';
import PublishingSettingSummaryList from './PublishingSettingSummaryList';
import PublishingFilterPaper from '../../PreviewPlugin/components/PublishingFilterPaper';
import ReadOnlySettingsPaper from '../../PreviewPlugin/components/ReadOnlySettingsPaper';

function PublishingComment({ comment, publishing, unMountParent }) {
  const cl = useStyles();
  const matches = comment.user.fullname.match(/\b(\w)/g); // ['J','S','O','N']
  const acronym = matches.join('').substring(0, 2); // JSON
  const dispatch = useDispatch();

  const deleteStaff = () => () => {
    dispatch(removePublishingComment(publishing, comment, unMountParent));
    /// /    handleCopy(props.riskrow, iscut);
  };

  const actions = [
    {
      id: 'PublishingComment.Delete',
      name: <FormattedMessage id="PublishingComment.Delete" defaultMessage="Delete" />,
      performAction: deleteStaff()
    }
  ];

  return (
    <Card>
      <CardHeader
        className={cl.card}
        avatar={
          <Avatar aria-label="recipe" className={cl.avatar}>
            {acronym}
          </Avatar>
        }
        action={unMountParent ? <ActionMenuNew visible="true" actions={actions} position="initial" small /> : null}
        title={comment.user.fullname}
        subheader={comment.date}
      />
      {/* <CardMedia
                    className={classes.media}
                    image="/static/images/cards/paella.jpg"
                    title="Paella dish"
                /> */}
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {comment.comment}
        </Typography>
      </CardContent>
    </Card>
  );
}

function PublishingDetails({ selectedElement, unMountParent }) {
  const cl = useStyles();
  const { metadataBundle } = useSelector((state) => state.PUBLISHING);
  const [metaData, setMetaData] = useState(null);
  const { publishingFilters } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMetaData(selectedElement.elementInfo.versionId));
  }, [selectedElement]);
  useEffect(() => {
    if (metadataBundle && selectedElement.elementInfo.versionId === metadataBundle.versionId) {
      setMetaData(metadataBundle.metaData);
    }
  }, [metadataBundle]);

  const getLatestPublishing = () => {
    return selectedElement;
  };

  const getLatestPublishingSettings = () => {
    console.log(selectedElement);
    return selectedElement.settings;
  };

  return (
    <div className={cl.root}>
      <Grid container spacing={3}>
        <Grid container spacing={1} direction="column" item xs={6} alignItems="stretch">
          <Grid item sm>
            <Paper className={cl.paper}>
              <Typography component="h3" gutterBottom>
                <FormattedMessage id="PublishingTableDetails.purposes" defaultMessage="Purposes" />
              </Typography>
              <Typography className={cl.title} variant="body2" component="p">
                {selectedElement.purpose}
              </Typography>
            </Paper>
          </Grid>
          <Grid item sm>
            <Paper className={cl.paper}>
              <Typography component="h3" gutterBottom>
                <FormattedMessage id="PublishingTableDetails.metadata" defaultMessage="Metadata" />
                <br />
              </Typography>

              <Typography className={cl.title} variant="body2" component="p">
                {metaData &&
                  metaData.values.map((value) => (
                    <Typography>
                      {' '}
                      <b>{value.template.names[0].name}</b> : {value.value.value}
                    </Typography>
                  ))}
              </Typography>
            </Paper>
          </Grid>
          {getLatestPublishing() && (
            <Grid item sm>
              <Paper className={cl.paper}>
                <Typography component="h3" gutterBottom>
                  <FormattedMessage id="PublishingTableDetails.Layout" defaultMessage="Layout" />
                  <br />
                </Typography>

                <PublishingSettingSummaryList
                  multipleSelectedLanguage={getLatestPublishingSettings().languages}
                  fallbackLocale={getLatestPublishingSettings().fallbackLanguage}
                  selectedLayoutFilter={getLatestPublishingSettings().layoutFilterUUID}
                  selectedLayout={getLatestPublishingSettings().layoutVersionId}
                  selectedElementToPreview={getLatestPublishing().elementInfo}
                  languageSeparated={getLatestPublishingSettings().separatedLanguages}
                />
              </Paper>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={1} direction="column" item xs={6}>
          {publishingFilters &&
            publishingFilters.length > 0 &&
            getLatestPublishingSettings().clientFilters &&
            getLatestPublishingSettings().clientFilters.length > 0 && (
              <Grid item sm>
                <PublishingFilterPaper
                  previewPublishingFilters={getLatestPublishingSettings().clientFilters}
                  readOnly
                />
              </Grid>
            )}
          {publishingFilters &&
            publishingFilters.length > 0 &&
            getLatestPublishingSettings().clientFilters &&
            getLatestPublishingSettings().clientFilters.length > 0 && (
              <Grid item sm>
                <ReadOnlySettingsPaper settings={getLatestPublishingSettings().layoutSettings.settings} />
              </Grid>
            )}
          {selectedElement.comments.map((comment) => (
            <Grid item xl>
              {' '}
              <PublishingComment publishing={selectedElement} unMountParent={unMountParent} comment={comment} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}

export default PublishingDetails;
