import React, { Fragment, useContext, useEffect } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import VerticalLinearStepper, {
  getStepContent,
  PreviewMobileStepper,
  PreviewWizardContext,
  PreviewWizardProvider
} from './PreviewWizardStepper';
import { GeneralSettingsPage } from './GeneralSettingsPage';
import { LanguagesPage } from './LanguagesPage';
import { MetadataCommentPage } from './MetadataCommentPage';
import { setSelectedPreviewJOBElement } from '../previewReducers';
import { ComparePage } from './ComparePage';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1
  }
});
function PreviewDialogContext({}) {
  const { activeStep, handleNext, handleBack } = useContext(PreviewWizardContext);

  switch (activeStep) {
    case 0:
      return <GeneralSettingsPage />;
    case 1:
      return <LanguagesPage />;
    case 2:
      return <ComparePage />;
    case 3:
      return <MetadataCommentPage />;
    default:
      return <div />;
  }
}

function PreviewWizardDialog({ onConfirm, element, unmountComponent }) {
  const dispatch = useDispatch();
  const { extended, activeStep } = useContext(PreviewWizardContext);
  useEffect(() => {
    dispatch(setSelectedPreviewJOBElement(element));
  }, element);
  const handleConfirm = (simplified) => {
    unmountComponent();
    onConfirm(simplified);
  };
  return (
    <Dialog open onClose={unmountComponent} fullWidth maxWidth={extended ? 'md' : 'sm'}>
      <DialogTitle>
        <FormattedMessage id="PreviewWizard.Title" defaultMessage="Preview" />
      </DialogTitle>
      <DialogContent>
        {extended && (
          <Grid container spacing={3}>
            <Grid item xl={4}>
              <VerticalLinearStepper />
            </Grid>
            <Grid item xl={8}>
              <PreviewDialogContext />
            </Grid>
          </Grid>
        )}
        {!extended && (
          <>
            <Typography>{getStepContent(activeStep)}</Typography>
            <PreviewDialogContext />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={unmountComponent} size="small">
          <FormattedMessage id="PreviewWizardDialog.Preview.Cancel" defaultMessage="Cancel" />
        </Button>
        {!extended && (
          <Button onClick={(e) => handleConfirm(true)} size="small" variant="outlined">
            <FormattedMessage id="PreviewWizardDialog.Preview.Finish" defaultMessage="Start preview" />
          </Button>
        )}
        {extended && <PreviewMobileStepper handleConfirm={handleConfirm} />}
      </DialogActions>
    </Dialog>
  );
}

function PreviewWizardContextWrapper({ onConfirm, element, unmountComponent }) {
  return (
    <PreviewWizardProvider>
      <PreviewWizardDialog onConfirm={onConfirm} element={element} unmountComponent={unmountComponent} />
    </PreviewWizardProvider>
  );
}

export default PreviewWizardContextWrapper;
