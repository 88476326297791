import React, { useContext,  useState } from "react";
import { getTranslation } from "@zert-packages/utils";
import FlipToBackIcon from "@mui/icons-material/FlipToBack";
import { Checkbox, FormControlLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TabsContext } from "../FormatTemplatePlugin";
import InputItem from "../InputItem";
import SelectedBlock from "../SelectedBlock";
import BlockList from "../BlockList";
import { handleItemLabel, handleItemShortcut, handleItemStyleLabel } from "../utils/handleInputItem";
import ExpanderItem from "../ExpanderItem";
import DialogList from "../dialog/DialogList";
import { convertKey } from "../utils/main";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogShortCut from "../dialog/DialogShortCut";
import WrapperLabelInputItem from "../component/WrapperLabelInputItem";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  checkboxRoot: {},
  checkboxLabel: {
    fontSize: 15,
    color: "#212529"
  },
  wrapperEdditor: {
    color: "#4db1d3",
    textAlign: "end",
    "& svg": {
      cursor: "pointer"
    }
  },
  icons: {
    marginLeft: 15
  },
  withLanguage: {
    display: "flex",
    flexDirection: "row-reverse",
    "& .MuiFormControl-root": {
      width: "100%"
    }
  },
}));


function BlockTabs({disable}) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [propsDialog, setPropsDialog] = useState(null);
  const { formatTemplate } = useSelector((state) => state);

  const handleValueIdentifier = (value) => {
    setValues((prev) => ({
      ...prev, templateList: {
        ...prev.templateList,
        "identifier": value, "comboValue": value
      }
    }));
  };

  const handleValueShortcut = (val, Identifier) => {
    handleItemShortcut(Identifier, val, setValues);
  };



  const handleCheckbox = (event) => {
    setValues((prev) => ({
      ...prev, templateList: {
        ...prev.templateList,
        collectLanguageSeparatedChildren: event.target.checked,
      }
    }));
  };
  
  const findStyleDropMenu = () => {
    if(!formatTemplate || !formatTemplate.blockStylesMap){
      return []
    }
   return Object.entries(formatTemplate.blockStylesMap).map(el => ({id : el[0], name: el[1]}))
  }


  // if
  return (
    <div>
      <DialogList open={openDialog} setOpen={setOpenDialog} text={propsDialog} />
      <InputItem
        label="Identifier:"
        valueProps={values.templateList.identifier && values.templateList.identifier}
        disabled={disable}
        Identifier="identifier"
        setValueProps={handleValueIdentifier}
      />
      <WrapperLabelInputItem disable={disable}/>
      <InputItem
        label="Shortcut:"
        valueProps={values.templateList.shortcut && convertKey(values.templateList.shortcut.keyCode)}
        setValueProps={handleValueShortcut}
        Identifier="shortcut"
        disabled={true}
        icons={
          <FlipToBackIcon
            style={{ cursor: "pointer", color: "gray" }}
            onClick={() => !disable && renderInExceptionRoot(DialogShortCut, { setValues })}
          />
        }
      />
      <div style={{ maxWidth: "Calc(100% - 35px)" }}>
        <ExpanderItem
          disable={disable}
          label="Style:"
          valueProps={values.templateList?.style && values.templateList?.style.replace(/['_']/g, ' ')}
          style={{ height: 34 }}
          propsLabel={findStyleDropMenu()}
        />
      </div>
      <WrapperLabelInputItem title={"Style Label:"} name={"styleLabel"} disable={disable} />
      <BlockList
        disable={disable}
        label="Settings:"
        formats={
          values.templateList.settings &&
          values.templateList.settings.properties.length > 0 &&
          values.templateList.settings.properties &&
          values.templateList.settings.properties
        }
      />
      <SelectedBlock
        disable={disable}
        label="References:"
        formats={values.templateList.references && values.templateList.references}
        setOpenDialog={setOpenDialog}

      />
      <div>
        {/*collectLanguageSeparatedChildren*/}
        <FormControlLabel
          disabled={disable}
          control={
            <Checkbox
              disable={disable}
              onChange={handleCheckbox}
              name="languageChildren"
              checked={values.templateList.collectLanguageSeparatedChildren}
            />
          }
          label={<span className={cl.checkboxLabel}>Collect language separated children</span>}
        />
      </div>
    </div>
  );
}

export default BlockTabs;
