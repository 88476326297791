import React, { useContext } from 'react';

import TreeView from '@mui/lab/TreeView';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FolderIcon from '@mui/icons-material/Folder';

import getTranslation from '@zert-packages/utils/getTranslation.old';
import CatalogTreeNode from './CatalogTreeNode';
import CatalogTreeActionsPanel from './CatalogTreeActionsPanel';

import { CatalogContext } from '../common/CatalogProvider';
import './TreeEditor.sass';

function CatalogTree() {
  const { treeForRendering } = useContext(CatalogContext);
  const childrenOfNodeToRender = makeNode(treeForRendering.rootNode.children, treeForRendering.rootNode.uuid);

  return (
    <div className="tree_root__container">
      <div className="tree__container">
        <TreeView
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpanded={[treeForRendering.rootNode.uuid]}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          <CatalogTreeNode
            root
            nodeId={treeForRendering.rootNode.uuid}
            labelText={getTranslation(treeForRendering.rootNode.label)}
            labelIcon={FolderIcon}
          >
            {childrenOfNodeToRender}
          </CatalogTreeNode>
        </TreeView>
      </div>
      <CatalogTreeActionsPanel />
    </div>
  );
}

function makeNode(children, nodePath) {
  const items = [];
  const amountOfChildren = children.length - 1;
  children.forEach((child, index) => {
    let item;
    const newNodePath = nodePath + child.uuid;

    if (child.children.length !== 0) {
      item = (
        <CatalogTreeNode
          nodePath={nodePath}
          key={child.uuid}
          nodeId={child.uuid}
          labelText={getTranslation(child.label)}
          labelIcon={FolderIcon}
          index={index}
          amountOfChildren={amountOfChildren}
        >
          {makeNode(child.children, newNodePath)}
        </CatalogTreeNode>
      );
    } else {
      item = (
        <CatalogTreeNode
          nodePath={nodePath}
          index={index}
          key={child.uuid}
          nodeId={child.uuid}
          labelText={getTranslation(child.label)}
          labelIcon={FolderIcon}
          amountOfChildren={0}
        />
      );
    }
    items.push(item);
  });

  return items;
}

export default CatalogTree;
