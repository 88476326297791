import React, { useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TabsContext } from "../../../Stylesheet";

const useStyles = makeStyles((theme) => ({
  wrapperMirrored: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridColumnGap: 10,
    marginLeft: "auto",
    marginRight: "11%",
    "& $layout": {
      marginLeft: "0px",
      marginRight: "0px"
    }
  },
  rootBlock: {
    marginLeft: "auto",
    position: "relative",
    overflow: "hidden"
  },
  layout: {
    border: "2px solid #AAAAAA",
    marginLeft: "auto",
    marginRight: "11%",
    maxWidth: 500,
    maxHeight: 210
  },
  layoutWrapper: {
    display: "flex"
  },
  layoutCell: {
    border: "2px solid #AAAAAA",
    height: "100%",
    width: "100%"
  }
}));

function LayoutModel() {
  const cl = useStyles();
  const { values } = useContext(TabsContext);
  const number = values.stylesheet.blockSettings[values.selectedLeftMenu];

  if (!values.stylePageLayout || !number) {
    return;
  }

  return (
    <div>
      {number.pageLayout === "MIRRORED" || number.pageLayout === "Mirrored" ? (
        <div className={cl.wrapperMirrored}>
          <LayoutBlock style={values.stylePageLayout} number={number} />
          <LayoutBlock style={values.stylePageLayout} number={number} />
        </div>
      ) : (
        <LayoutBlock style={values.stylePageLayout} number={number} />
      )}
    </div>
  );
}

export default LayoutModel;

function LayoutBlock({ style, number }) {
  const cl = useStyles();
  const findHeight = () => {
    return `Calc(100%  - ${number.header.visible ? +number.header.height.value / 2 : 0}px - ${
      number.header.visible ? +number.header.spacing.value / 2 : 0
    }px
        - ${number.footer.visible ? +number.footer.height.value / 2 : 0}px - ${
      number.footer.visible ? +number.footer.spacing.value / 2 : 0
    }px )`;
  };

  const findWidth = (type) => {
    if (type === "header" && number.header.precedence) {
      return `100%`;
    }
    if(type !== "header" && number.footer.precedence){
      return `100%`;
    }
    return `Calc(100%  - ${number.leftEdge.visible ? +number.leftEdge.width.value / 2 : 0}px - ${
      number.leftEdge.visible ? +number.leftEdge.spacing.value / 2 : 0
    }px
        - ${number.rightEdge.visible ? +number.rightEdge.width.value / 2 : 0}px - ${
      number.rightEdge.visible ? +number.rightEdge.spacing.value / 2 : 0
    }px )`;
  };

  const findMargin = (type) => {
    return `Calc(${number[type].visible ? +number[type].width.value / 2 : 0}px 
    + ${number[type].visible ? +number[type].spacing.value / 2 : 0}px 
    )`;
  };


  return (
    <div className={cl.rootBlock}>
      <div className={cl.layout} style={{ ...style, padding: "5px 5px 5px 5.5px" }}>
        {number.header.visible && (
          <div
            className="header"
            style={{
              width: findWidth("header"),
              height: +number.header.height.value / 2,
              marginBottom: +number.header.spacing.value / 2,
              background: "#AAAAAA",
              position: "relative",
              marginLeft: +number.header.precedence ? 0 : findMargin("leftEdge"),
              marginRight: +number.header.precedence ? 0 : findMargin("rightEdge")
            }}
          />
        )}

        <div style={{ height: findHeight(), width: "100%", display: "flex" }} className="container1">
          {number.leftEdge.visible && (
            <div
              style={{
                background: "#AAAAAA",
                width: +number.leftEdge.width.value / 2,
                marginRight: +number.leftEdge.spacing.value / 2,
                height: "100%"
              }}
            />
          )}

          <div
            className={cl.layoutWrapper}
            style={{
              display: "grid",
              gridTemplateColumns: style.gridTemplateColumns,
              gridColumnGap: style.gridColumnGap,
              height: "Calc(100%)",
              width: "100%"
            }}
          >
            {Array.from({ length: +number.columnCount }, (_, i) => i).map((el, i) => (
              <div className={cl.layoutCell} key={i} />
            ))}
          </div>
          {number.rightEdge.visible && (
            <div
              style={{
                background: "#AAAAAA",
                width: +number.rightEdge.width.value / 2,
                marginLeft: +number.rightEdge.spacing.value / 2,
                height: "100%"
              }}
            />
          )}
        </div>

        {number.footer.visible && (
          <div
            style={{
              width: findWidth(),
              marginLeft: +number.footer.precedence ? 0 : findMargin("leftEdge"),
              marginRight: +number.footer.precedence ? 0 : findMargin("rightEdge"),
              height: +number.footer.height.value / 2,
              background: "#AAAAAA",
              position: "relative",
              marginTop: +number.footer.spacing.value / 2
            }}
          />
        )}
      </div>
    </div>
  );
}
