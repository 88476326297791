import React, {useEffect} from 'react';

const useSelectionModel = (rows, selectOnlyOne, identifier, selectItem) => {
  const [selected, setSelected] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleClick = (event, name, column) => {
    if (column && column.customClickHandler) {
      const row = rows.find((row) => name == row[identifier]);
      column.customClickHandler(row, column);
      return;
    }

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectOnlyOne) {
      newSelected = [name];
    } else if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelectedRows(rows.filter((row) => newSelected.indexOf(row[identifier]) > -1));
    setSelected(newSelected);
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      const newSelectedRows = rows.filter((row) => selectedRows.find((value) => row[identifier] == value[identifier]));
      if (newSelectedRows.length != selectedRows.length) {
        setSelected(newSelectedRows.map((newRow) => newRow[identifier]));
        setSelectedRows(newSelectedRows);
      }
    }
    // setSelectedRows([]);
    // setSelected([]);
  }, [rows]);

  useEffect(() => {
    if (selectItem) {
      setSelected([selectItem]);
    }
  }, [selectItem]);

  return { selected, setSelected, selectedRows, setSelectedRows, handleClick };
};

export default useSelectionModel;
