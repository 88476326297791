import React, { useState } from 'react';
import { Accordion as AccordionMUI, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Accordion({ title, children, defaultExpanded = false }) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  return (
    <AccordionMUI expanded={isExpanded} onChange={(event, value) => setIsExpanded(value)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{isExpanded && children}</AccordionDetails>
    </AccordionMUI>
  );
}

export default Accordion;
