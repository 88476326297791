import React, {useEffect, useMemo, useState} from "react";
import {getBlockApi} from "../../../../BlockPlugin/API";
import PhraseEditor from "../../../../PhrasePlugin/components/PhraseEditor";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import {
    findBorderTd,
    findDeleteHover,
    findHoverY,
    handleColorCell,
    handleSaveElement
} from "../../helpers/main";
import {adobeColor} from "../../../../StylesheetPlugin/utils/helpers";
import AddIcon from '@mui/icons-material/Add';
import AddToolbarDialog from "../dialog/AddToolbarDialog/AddToolbarDialog";
import CellLabel from "../CellLabel";
import BlockWrapper from "../../../../BlockPlugin/BlockWrapper";
import PaletteIcon from "@mui/icons-material/Palette";
import AbstractWrapper from "@zert-packages/components/InfoElement/components/AbstractWrapper";
import CustomTable, {TableContext} from "../../CustomTable";
import {useDispatch, useSelector} from "react-redux";
import {TextFieldItem} from "@zert-packages/components";
import useStyles from './style'
import ImgTypeWrapper from "../../../../ImagePlugin/ImgTypeWrapper";
import {findShow, findShowLabel} from "./utils";
import {handleBlock} from "@zert-packages/actions/commonReducer";



const RowItem = ({
                     it,
                     el,
                     selected,
                     row,
                     hoverTable,
                     onMouseDown,
                     parentRef,
                     values,
                     setSelected,
                     tableData,
                     setValues,
                     setTableData
                 }) => {
    const cl = useStyles()
    const dispatch = useDispatch();
    const [sel, setSel] = useState(false);
    const [block, setBlock] = useState(null)
    const [active, setActive] = useState(false)
    const mimeType = it?.val?.elementInfo?.mimeType
    const deleteHover = findDeleteHover(row, hoverTable, it)
    const selectedItem = selected?.length === 1 && selected[0].id === it.id
    const selectedBlock = mimeType === "application/zert-block" && selectedItem
    const {myLanguage, activeLocales,} = useSelector((state) => state);
    const [img, setImg] = useState({
        blockItem: null,
    })
    const [name, setName] = useState('')
    const [openToolbarDialog, setOpenToolbarDialog] = useState(false)
    const [actionTypeTable, setActionTypeTable] = useState('')
    const approvedAt = it?.val?.elementInfo?.approvedAt


    const handleGetBlock = async () => {
        const res = await getBlockApi(it?.val?.elementInfo.versionId, it?.val?.elementInfo.locale).catch(e => console.log(e));
        if (res) {
            setBlock(res);
        }
    }

    const handleName = (val) => {
        setName(val)
    }


    const handleActive = () => {
        setActive(prev => !prev)
    }

    const findColor = () => {
        const colorRow = el.colorRow
        const colorCol = it.colorCol
        if (colorCol) {
            return adobeColor(colorCol)
        }
        if (colorRow) {
            return adobeColor(colorRow)
        }
    }

    const addElement = () => {
        setOpenToolbarDialog(true)
        // renderInExceptionRoot(AddToolbarDialog, {values, it, tableData, setValues, setTableData})
    }

    const handleMouse = (e) => {
        if (selectedItem) {
            return
        }
        onMouseDown(e)
    }

    const handlePhrase = (valueEditor) => {
        setValues(pre => ({...pre, phraseEditor: valueEditor}))
    }

    const handleElement = (typeSave, type, item, snackbar) => {
        handleSaveElement(typeSave, type, it, snackbar, values, activeLocales, myLanguage, img, name, setActionTypeTable, setSelected, setTableData)
    }

    const findSelectedTableValue = () => {
        if(!it.val){
            return    dispatch(handleBlock(null, 'selectedItem'))
        }
        if(!active && (mimeType === "application/zert-phrase")){
           return  dispatch(handleBlock(null, 'selectedItem'))
        }

        dispatch(handleBlock(it.val, 'selectedItem'))
    }


    useEffect(() => {
        if (
            row.hover
            || selected?.find(el => el.id === it.id)
            || hoverTable.cell.index === it.posY
            || findHoverY(hoverTable.cell.index, it)
            || hoverTable.selectedCell.index === it.posY
            || findHoverY(hoverTable.selectedCell.index, it)
            || hoverTable.selectedAll
        ) {
            setSel(true);
        } else {
            setSel(false);
        }
    }, [it, row.hover, hoverTable.cell, hoverTable.selectedCell, hoverTable.selectedAll])

    useEffect(() => {
        if (mimeType === "application/zert-block" && selectedItem) {
            handleGetBlock().catch(e => console.log(e))
        }
        if (!selectedItem && active) {
            setActive(false)
        }
        if (selectedItem && mimeType === "application/zert-image") {
            setImg(pre => ({...pre, blockItem: it.val}))
            // dispatch(handleBlock(it.val, 'selectedItem'))
        }

        if (selectedItem && mimeType !== 'application/zert-table' && mimeType !== 'application/zert-phrase' && it?.val?.elementInfo?.name) {
            setName(it.val.elementInfo.name)
        }
    }, [selectedItem])


    useEffect(() => {
        if(selectedItem){
            findSelectedTableValue()
        }
    }, [selectedItem, active]);



    useEffect(() => {
        if (selectedBlock && parentRef.current && it?.koords?.left) {
            parentRef.current.scrollBy({
                left: (it.posY * 90),
                behavior: 'smooth'
            });
        }
    }, [selectedBlock])

    const tdStyle = useMemo(() => ({
        background: findColor(),
        ...findBorderTd(sel, deleteHover, row.hoverAddTop, row.hoverAddBottom, false, hoverTable.columnLeft.index === it.posY, hoverTable.columnRight.index === it.posY),
    }), [findColor, findBorderTd, sel, deleteHover, row, hoverTable, it.posY]);


    // selectedCell
    return (
        <td id={it.id}
            key={it.id}
            onMouseDown={handleMouse}
            rowSpan={it.rowspan}
            colSpan={it.colspan}
            className={`${cl.item}  ${selectedItem ? cl.selectedCell : ''}`}
            style={tdStyle}
        >
            <div>
                {openToolbarDialog && <AddToolbarDialog
                    values={values}
                    it={it}
                    tableData={tableData}
                    setValues={setValues}
                    setTableData={setTableData}
                    setOpenToolbarDialog={setOpenToolbarDialog}
                    setSelected={setSelected}
                />}
                {selectedItem && <div>
                    <div className={cl.wrapperColor}
                         onClick={() => handleColorCell(tableData, selected, values, setValues, setTableData, setSelected)}>
                        <div>Cell color</div>
                        <div className={cl.selectedColor}
                             style={{background: it.colorCol ? adobeColor(it.colorCol) : ''}}></div>
                        <div style={{cursor: 'pointer', color: 'rgb(24, 142, 199)',}}>
                            <PaletteIcon/>
                        </div>
                    </div>
                </div>}
                {selectedItem && !it.val && <div className={cl.wrapperAddIcon} onClick={addElement}>
                    <IconButton>
                        <AddIcon name={'blockMouseDown'}/>
                    </IconButton>
                </div>}

                <div style={{zIndex: "110", transition: "0.3s", overflow: "hidden"}}>
                    {selectedItem && findShow(selectedItem, mimeType, active, it) &&
                        <div style={{minWidth: 425}}><AbstractWrapper
                            id={it.val?.elementInfo?.versionId}
                            info={it.val?.elementInfo}
                            el={it.val}
                            saveOnlyItem={handleElement}
                            context={TableContext}
                        /></div>}

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: '1fr 21px',
                        gridColumnGap: 5,
                    }}>
                        {<div style={{display: selectedItem && active ? 'block' : 'none'}}>
                            <PhraseEditor
                                el={it.val}
                                vue={active ? true : false}
                                setPhrase={handlePhrase}
                            />
                        </div>}
                        {findShowLabel(active, selectedItem, mimeType) && <CellLabel it={it}/>}

                        {selectedItem && (mimeType === "application/zert-phrase") && <div
                            name={'blockMouseDown'}
                            onClick={handleActive}
                            className={cl.wrapperIcon}
                        >
                            <IconButton className={cl.editIcon} name={'blockMouseDown'}>
                                <EditIcon name={'blockMouseDown'}/>
                            </IconButton>
                        </div>
                        }
                    </div>

                    {selectedItem && (
                            mimeType === "application/zert-block"
                            || mimeType === "application/zert-image"
                            || mimeType === "application/zert-index"
                        )
                        && <TextFieldItem
                            values={name}
                            error={name.length === 0}
                            setValues={handleName}
                            style={{marginTop: 10}}
                        />
                    }


                    {mimeType === "application/zert-image" && <div style={{marginTop: 5}}>
                        <ImgTypeWrapper
                            setBlock={setImg}
                            el={it?.val}
                            vue={!selectedItem || approvedAt}
                        />
                    </div>}
                    {selectedItem && mimeType === "application/zert-table" && <div>
                        <CustomTable
                            versionId={it?.val?.elementInfo.currentVersionId}
                            vue={!selectedItem || approvedAt}
                            actionTypeProps={actionTypeTable}
                        />
                    </div>}
                    {(mimeType === "application/zert-block") && !active && (!selected?.[0]?.id === it?.id) &&
                        <p>
                            {it?.val?.elementInfo?.properties?.["zert:Format"]}
                        </p>
                    }
                    {selectedBlock && <div style={{minWidth: 469, marginTop: 5}}>
                        <BlockWrapper
                            table={true}
                            block={block}
                            idBlock={it?.val?.elementInfo?.versionId}
                        />
                    </div>}
                    {/*<ItemRoot block={items}/>*/}
                </div>
            </div>
        </td>
    )
}

export default RowItem