import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { selectiveMemoComparison } from '@zert-packages/react';
import cn from 'classnames';
import NotificationSquareNumber from '../../shared/NotificationSquareNumber';
import Shortcut from "@zert-packages/plugins/ShortcutsPlugin/Shortcut";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const useStyles = makeStyles((theme) => ({
  list: {
    listStyle: 'none',
    transition: 'all .5s ease',
    width: '100%'
  },
  item: {
    borderBottom: 'solid 1px rgba(27, 161, 197, .05)',
    userSelect: 'none',
    cursor: 'pointer'
  },
  itemText: {
    gridArea: '1 / 3'
  },
  NavLink: {
    color: 'white',
    fontFamily: `'Open Sans', sans-serif`,
    fontSize: '1.1rem',
    textDecoration: 'none',
    display: 'grid',
    gridTemplateColumns: '30px 15px 1fr 50px',
    padding: '8px 20px',
    transition: 'all 0.5s ease',
    '&:hover': {
      textDecoration: 'underline',
      color: '#1BA1C5',
      gridTemplateColumns: '30px 25px 1fr 50px'
    },
    [theme.breakpoints.down('tablet')]: {
      fontSize: '0.9rem'
    }
  },
  NavLinkActive: {
    gridTemplateColumns: '30px 25px 1fr 50px',
    color: '#1ba1c5'
  },
  NavLinkOnlyIcons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10
  },
  itemIconWrapper: {
    color: '#235a69',
    transition: 'color 1s ease',
    display: 'flex',
    margin: 'auto'
  },
  itemIconWrapperActive: {
    color: '#1BA1C5'
  }
}));

function ListCore({ list = [], onlyIcons = false, onClick, number, sidebarShortcuts = []}) {
  const cl = useStyles();

  return (
    <div className={cl.list}>
      {list.map((item, index) => (
        <LinkItem
          onlyIcons={onlyIcons}
          {...item}
          key={item.to}
          onClick={item.onClick ? item.onClick : onClick}
          number={item.number ? item.number : index == 0 ? number : 0}
        />
      ))}
      {sidebarShortcuts.map((shortcut) => <SidebarShortcut shortcut={shortcut} onlyIcons={onlyIcons}/>)}
    </div>
  );
}

export default ListCore;

const LinkItem = selectiveMemoComparison(({ onlyIcons, name, icon: Icon, to, onClick, number, onClickOnly }) => {
  const cl = useStyles();
  const isActiveLink = useRouteMatch(to);
  const NavLinkClassName = cn({
    [cl.NavLink]: true,
    [cl.NavLinkFirst]: !onlyIcons && number > 0,
    [cl.NavLinkOnlyIcons]: onlyIcons
  });

  const itemIconWrapperClassName = cn({
    [cl.itemIconWrapper]: true,
    [cl.itemIconWrapperActive]: isActiveLink
  });

  return (
    <li className={cl.item}>
      {onClickOnly ? (
        <div className={NavLinkClassName} activeClassName={cl.NavLinkActive} onClick={onClick}>
          <div className={itemIconWrapperClassName}>
            <Icon />
          </div>
          <span className={cl.itemText}>{onlyIcons || name}</span>
          {number > 0 && !onlyIcons && <NotificationSquareNumber number={number} />}
        </div>
      ) : (
        <NavLink to={to} className={NavLinkClassName} activeClassName={cl.NavLinkActive} onClick={onClick}>
          <div className={itemIconWrapperClassName}>
            <Icon />
          </div>
          <span className={cl.itemText}>{onlyIcons || name}</span>
          {number > 0 && !onlyIcons && <NotificationSquareNumber number={number} />}
        </NavLink>
      )}
    </li>
  );
})(
  (p) => p.label,
  (p) => p.icon,
  (p) => p.to,
  (p) => p.onlyIcons,
  (p) => p.number
);

const SidebarShortcut = ({shortcut, onlyIcons}) => {
  const cl = useStyles();
  const NavLinkClassName = cn({
    [cl.NavLink]: true,
    [cl.NavLinkFirst]: !onlyIcons,
    [cl.NavLinkOnlyIcons]: onlyIcons
  });

  const itemIconWrapperClassName = cn({
    [cl.itemIconWrapper]: true
  });

  return (
    <li className={cl.item}>
      {
        <div className={NavLinkClassName}>
          <div className={itemIconWrapperClassName}>
            <OpenInNewIcon/>
          </div>
          <div></div>
          <Shortcut identifier={shortcut.identifier} url={shortcut.url} id={shortcut.id} versionId={shortcut.versionId}
                    name={shortcut.name} mimeType={shortcut.mimeType}>
            <div>
              {!onlyIcons && <span className={cl.itemText}>{shortcut.name}</span>}
            </div>
          </Shortcut>
        </div>
      }
    </li>
  );
}
