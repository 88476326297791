import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";
import ImageCard from "./ImageCard";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 414,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperImg: {
    display: "flex",
    gap: 10,
    flexDirection: "column"
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#4db1d3",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

export function DoubleDialog({ unmountComponent, onConfirm, onCancel, list, }) {
  const cl = useStyles();
  const handleConfirm = (info) => () => {
    onConfirm(info);
    unmountComponent();
  };
  const handleCancel = () => {
    onCancel();
    unmountComponent();
  };

  return (
    <Dialog
      open
      // onClose={unmountComponent}
      onClose={handleCancel}
      classes={{
        paperScrollPaper: cl.root
      }}
    >
      <DialogTitle>
        <FormattedMessage id="DoubleDialog.Title" defaultMessage="There is existing images in the system" />
      </DialogTitle>
      <DialogContent>
        <div className={cl.wrapperImg}>
          {list.map((info) => (
            <ImageCard info={info.info} handleClick={handleConfirm(info)} />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button className={cl.button} onClick={handleCancel} color="primary" variant="outlined" autoFocus>
          <FormattedMessage id="ResizeImageDialog.UseNewOne" defaultMessage="Use New One" />
        </Button>
        <Button className={cl.button} onClick={handleCancel} color="primary" variant="outlined" autoFocus>
          <FormattedMessage id="ResizeImageDialog.buttonCancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
