import { handlePost } from '@zert-packages/actions/api';

export const loadColumnsAPI = async (selected, templates) => {
  const ids =
    selected && 'ra_templates' in selected && selected.ra_templates.length ? selected.ra_templates : templates;
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return handlePost(
    `/riskanalises/getMatrixColumnValues/${locale}`,
    ids.map((template) => template.id)
  );
};

export const loadColumnsWithFnAPI = async (selectedFn, templates) => {
  const raTemplates = selectedFn('ra_templates');
  const ids = raTemplates && raTemplates.length ? raTemplates : templates;
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return handlePost(
    `/riskanalises/getMatrixColumnValues/${locale}`,
    ids.map((template) => template.id)
  );
};
