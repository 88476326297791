import React, {useEffect, useState} from 'react';
import {createArr} from "../../ResizedTable";
import {findBorderTd} from "../../helpers/main";
import useStyles from './style'
import HeaderCellController from "./HeaderCellController";


const HeadTable = ({
                       schema,
                       hoverTable,
                       setHoverTable,
                       selectedTableAll,
                       removeColumn,
                       tableData,
                       setSelected,
                       addColumn,
                       values
                   }) => {
    const cl = useStyles()
    const [headerCells, setHeaderCells] = useState([]);
    const columns = schema?.columns
    const createHeaderCell = createArr(schema.columns)
    const  tableDefault = values.tableDefault

    const handleHover = (val, type) => {
        setHoverTable(pre => ({...pre, [type]: val}))
    }


    const findWidth = () => {

        if(!tableDefault){
            return
        }
        const findTotal = tableDefault.columns.reduce((acu, cur) => {
            if(cur.widthDefined){
                return {width: acu.width + cur.width, total: acu.total + 1}
            }
            return acu
        }, {width: 0, total: 0})
        const w = 100 - findTotal.width
        const res =  w / (columns - findTotal.total)

        return (res + "").includes('.') ? res.toFixed(2) : res
    }


    const createHeaderCells = () => {
        const res = tableDefault.columns.map(el => {
            if(el.widthDefined){
                return el
            }
            return  {...el, width: +findWidth()}
        })
        setHeaderCells(res)
    }




    useEffect(() => {
        if (columns > 0) {
            createHeaderCells()
        }
    }, [columns])


    return (<thead className={cl.rootHeader}>
    <tr>
        <td style={{minWidth: 11}}></td>
        <td></td>
        {createHeaderCell.map((column, i, arr) =>
            <HeaderCellController
                key={i}
                headerCells={headerCells}
                setHeaderCells={setHeaderCells}
                index={i}
                hoverTable={hoverTable}
                setHoverTable={setHoverTable}
                removeColumn={removeColumn}
                isLastItem={i === arr.length - 1}
                addColumn={addColumn}
            />
        )}
    </tr>

    <tr>
        <td></td>
        <td
            onClick={selectedTableAll}
            className={cl.td}
            style={findBorderTd(hoverTable.all || hoverTable.selectedAll, false, false, false, true)}
            onMouseEnter={() => handleHover(true, 'all')}
            onMouseLeave={() => handleHover(false, 'all')}
        >
        </td>
        {createArr(schema.columns).map((column, i) =>
            <HeadCell
                key={i}
                index={i}
                hoverTable={hoverTable}
                setHoverTable={setHoverTable}
                tableData={tableData}
                setSelected={setSelected}
                schema={schema}
            />
        )}
    </tr>
    </thead>)
}

export default HeadTable;


const HeadCell = ({index, hoverTable, setHoverTable, tableData, setSelected, schema}) => {
    const cl = useStyles()
    const selectedCell = hoverTable.selectedCell.index === index
    const hoverTd = hoverTable.all || hoverTable.cell.index === index || selectedCell || hoverTable.selectedAll
    const deleteColumn = hoverTable.deleteColumn.index === index


    const handleSelected = () => {
        if (schema.columns === 1) {
            return setHoverTable(pre => ({...pre, selectedAll: false,}))
        }

        setHoverTable(pre => ({...pre, selectedCell: {index}, selectedAll: false,}))
        const res = tableData.reduce((acc, cur) => {
            const val = cur.val[index]
            if (val && val.posY === index) {
                return [...acc, cur.val[index]]
            }
            return acc
        }, [])
        setSelected(res)
    }
    const handleHover = (val, type) => {
        if (schema.columns === 1) {
            if (hoverTable.cell) {
                setHoverTable(pre => ({...pre, [type]: false}))
            }
            return
        }
        setHoverTable(pre => ({...pre, [type]: val}))
    }

    return (<td
        id={`column${index}`}
        onClick={handleSelected}
        className={cl.td}
        onMouseEnter={() => handleHover({index}, 'cell')}
        onMouseLeave={() => handleHover(false, 'cell')}
        style={findBorderTd(hoverTd, deleteColumn, false, false, true, hoverTable.columnLeft.index === index, hoverTable.columnRight.index === index)}
    ></td>)
}