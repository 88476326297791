import moveItemInArray from '@zert-packages/utils/moveItemInArray';

//= ==================================== ADD =====================================//

export function addNodeInTheEnd(rootNode, parentNode, newNode) {
  function addInTheEndMethod(rootNode, parentNode) {
    parentNode.children = [...parentNode.children, ...[newNode]];
    return rootNode;
  }

  if (rootNode.uuid === parentNode.uuid) {
    parentNode.children = [...parentNode.children, ...[newNode]];
  } else {
    findNodeAndApplyMethod(rootNode, parentNode, addInTheEndMethod);
  }
}

export function addNode(initialRootNode, newNode, position) {
  const rootNode = { ...initialRootNode };
  rootNode.children.splice(position, 0, newNode);
  return rootNode;
}

export function addNodeAfter(rootNode, selectedNode, newNode) {
  const parentNode = findParentNode(rootNode, selectedNode);
  const position = getPositionOfNodeInChildren(parentNode, selectedNode);
  return addNode(parentNode, newNode, position + 1);
}

export function addNodeBefore(rootNode, selectedNode, newNode) {
  const parentNode = findParentNode(rootNode, selectedNode);
  const position = getPositionOfNodeInChildren(parentNode, selectedNode);
  addNode(parentNode, newNode, position);
}

export const addNodeChildren = (node, selectedNode, newNodeChildren) => {
  if (node.uuid === selectedNode.uuid) {
    node.children = [...node.children, ...newNodeChildren];
    return node;
  }
  node.children = node.children.map((child) => addNodeChildren(child, selectedNode, newNodeChildren));
  return node;
};

//= ==================================== UPDATE =====================================//

export function updateIdentifier(rootNode, previousIdentifier, newIdentifier) {
  function update(rootNode, previousIdentifier, newIdentifier) {
    rootNode.assignments = rootNode.assignments.map((assignment) => {
      if (assignment.identifier === previousIdentifier) {
        return {
          ...assignment,
          identifier: newIdentifier
        };
      }
      return assignment;
    });
    if (rootNode.children) {
      rootNode.children.forEach((node) => {
        update(node, previousIdentifier, newIdentifier);
      });
    }
  }

  update(rootNode, previousIdentifier, newIdentifier);
}

//= ==================================== REMOVE =====================================//

export function removeNode(rootNode, selectedNode) {
  rootNode.children = [...rootNode.children.filter((node) => node.uuid !== selectedNode.uuid)];
  return rootNode;
}

//= ==================================== MOVE =====================================//

export const moveNodeUp = (rootNode, selectedNode) => {
  rootNode.children = moveItemInArray(rootNode.children, 'uuid', selectedNode.uuid, -1);
  return rootNode;
};

export const moveNodeDown = (rootNode, selectedNode) => {
  rootNode.children = moveItemInArray(rootNode.children, 'uuid', selectedNode.uuid, 1);
  return rootNode;
};

export function moveNodeInside(rootNode, selectedNode, setSelectedParentNode) {
  const nodeBefore = findNodeBefore(rootNode, selectedNode);
  if (nodeBefore === null || typeof nodeBefore === 'undefined') return rootNode;
  findNodeAndApplyMethod(rootNode, selectedNode, removeNode);
  addNodeInTheEnd(rootNode, nodeBefore, selectedNode);
  setSelectedParentNode(nodeBefore);
}

export function moveNodeOutside(rootNode, selectedNode) {
  const parentNode = findParentNode(rootNode, selectedNode);
  if (parentNode === null) return;
  if (parentNode.uuid === rootNode.uuid) return rootNode;

  const treeWithoutSelectedNode = findNodeAndApplyMethod(rootNode, selectedNode, removeNode);

  const indexOfParentNode = rootNode.children.findIndex((node) => node.uuid === parentNode.uuid);
  const position = indexOfParentNode + 1;
  const handleAddNode = (rootNode) => addNode(rootNode, selectedNode, position);

  return findNodeAndApplyMethod(treeWithoutSelectedNode, parentNode, handleAddNode);
}

//= ==================================== HELPERS =====================================//

export function findNodeAndApplyMethod(rootNode, selectedNode, method) {
  const indexOfSelectedNode = rootNode.children.findIndex((node) => node.uuid === selectedNode.uuid);
  const noFoundIndex = -1;
  if (indexOfSelectedNode === noFoundIndex) {
    rootNode.children = rootNode.children.map((node) => {
      return findNodeAndApplyMethod(node, selectedNode, method);
    });
  } else {
    rootNode = method(rootNode, selectedNode);
  }
  return rootNode;
}

export function findNodeById(node, nodeId) {
  let result = null;
  if (nodeId === node.uuid) return node;
  if (node.children) {
    node.children.some((node) => (result = findNodeById(node, nodeId)));
  }
  return result;
}

export function findPath(rootNode, selectedNode, path = '') {
  if (selectedNode === null) return [];
  let nodePath = '';

  function makeSearchPath(rootNode, selectedNode, path) {
    if (rootNode.uuid === selectedNode.uuid) nodePath = `${path}${selectedNode.uuid}`;
    path += `${rootNode.uuid}/`;
    if (rootNode.children) {
      rootNode.children.forEach((node) => {
        makeSearchPath(node, selectedNode, path);
      });
    }
  }

  makeSearchPath(rootNode, selectedNode, path);
  return nodePath.split('/');
}

export function findNodeBefore(rootNode, selectedNode) {
  let nodeBefore = null;

  function findNodeBeforeMethod(rootNode, selectedNode) {
    const isNodeInTheSameLevel = rootNode.children.some((node) => node.uuid === selectedNode.uuid);
    if (isNodeInTheSameLevel) {
      const indexOfSelectedNode = rootNode.children.findIndex((node) => node.uuid === selectedNode.uuid);
      nodeBefore = rootNode.children[indexOfSelectedNode - 1];
    }
    return rootNode;
  }

  findNodeAndApplyMethod(rootNode, selectedNode, findNodeBeforeMethod);
  return nodeBefore;
}

export function findParentNode(rootNode, selectedNode) {
  let parentNode = null;
  const findParentId = (rootNode, selectedNode) => {
    const isSelectedNodeIncluded = rootNode.children.some((node) => node.uuid === selectedNode.uuid);
    if (isSelectedNodeIncluded) parentNode = rootNode;
    return rootNode;
  };
  findNodeAndApplyMethod(rootNode, selectedNode, findParentId);
  return parentNode;
}

export const getPositionOfNodeInChildren = (rootNode, selectedNode) => {
  return rootNode.children.findIndex((currentNode) => currentNode.uuid === selectedNode.uuid);
};
