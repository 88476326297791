import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { selectiveMemoComparison } from '@zert-packages/react';

const useStyles = makeStyles({
  root: {
    color: '#1BA1C5',
    fontSize: '1rem',
    border: '1px solid #1BA1C5',
    padding: '2px',
    borderRadius: '4px',
    width: 42,
    height: 20,
    gridArea: '1 / 4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.5s ease'
  }
});
function NotificationSquareNumber({ number }) {
  return <div className={useStyles().root}>{number}</div>;
}

export default selectiveMemoComparison(NotificationSquareNumber)((p) => p.number);
