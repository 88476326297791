import React, { useEffect, useState } from 'react';

import useDimensions from '@zert-packages/utils/useDimensions';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import Box from '@mui/material/Box';
import { KeyboardArrowRight } from '@mui/icons-material';
import useSelectionModel from '@zert-packages/components/EnhancedTable/useSelectionModel';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import PublishingDetailsTable from './PublishingDetailsTable';
import { getPublishingTypeTranslation } from './helpers/getPublishingType';
import PublishingDetails from './components/PublishingDetails';

import { loadPublishings } from './API';

function EditPublishing({
  publishing,
  selectedPublishingId,
  onConfirm,
  createNew,
  deletePublishing,
  addComment,
  intl,
  unmountComponent
}) {
  const [ref, { x, y, width, height }] = useDimensions({ liveMeasure: true });
  const [selectedPublishing, setSelectedPublishing] = useState(null);
  const [publishings, setPublishings] = useState(null);
  const getSelectedPublishingAsync = async (elementId) => {
    const result = await loadPublishings(elementId);
    setPublishings(result);
    // selectionModel.handleClick(null, publishing.publishings[0].publishingId, null);
  };
  useEffect(() => {
    getSelectedPublishingAsync(publishing.info ? publishing.info.id : publishing.elementInfo.id);
  }, [publishing, selectedPublishingId]);

  useEffect(() => {
    if (publishings) {
      let selected = null;
      if (selectedPublishingId) {
        selected = publishings.find((publication) => publication.publishingId == selectedPublishingId);
      } else {
        selected = publishings.find(
          (publication) => publication.publishingId == publishing.publishings[0].publishingId
        );
      }

      if (selected) {
        selectionModel.handleClick(null, selected.publishingId, null);
        setSelectedPublishing(selected);
        // setSelectedPublishing(publishing.publishings[0]);
      } else {
        selectionModel.handleClick(null, publishings[0].publishingId, null);
        setSelectedPublishing(publishings[0]);
      }
    }
  }, [publishings]);

  const handler = () => {
    const _handler = {
      get(target, key) {
        if (key == 'updatedAt') {
          if (target && target.properties) {
            return target.properties['zert:PublishingUpdateAt'];
          }
          return '';
        }
        if (key == 'stylesheet') {
          if (target && target.layout) {
            return target.layout.name;
          }
          return '';
        }
        if (key == 'publishedBy') {
          return target.publishedBy;
        }
        if (key == 'publishedByM') {
          if (target.publishedBy) {
            return target.publishedBy.displayName;
          }
        } else if (key == 'publishedAt') {
          if (target && target.publishedAt) {
            return target.publishedAt;
          }
          return '';
        } else if (key == 'version') {
          return target.elementInfo.label;
        } else if (key == 'media') {
          return getPublishingTypeTranslation(target.publishedElementItemType, intl);
        } else if (key == 'id') {
          return target.elementInfo.versionId;
        } else if (key == 'mimeType') {
          return getPluginByMimeType(target.info.mimeType).name;
        } else if (key == 'comments') {
          return target[key];
        } else if (key == 'properties') {
          return target[key];
        } else if (key == 'elementInfo') {
          return target[key];
        } else if (typeof target[key] === 'object' && target[key] !== null) {
          return new Proxy(target[key], _handler);
        } else {
          return target[key];
        }
      }
    };
    return _handler;
  };
  const model = publishings ? publishings.map((element) => new Proxy(element, handler(element))) : [];

  const selectionModel = useSelectionModel(model, true, 'publishingId');

  useEffect(() => {
    if (selectionModel.selected && publishings) {
      setSelectedPublishing(getSelectedPublishing(selectionModel.selected));
    }
  }, [selectionModel.selected]);

  /* const dispatch = useDispatch();

    const {publishingList, loading} = useSelector(state => state.PUBLISHING); */
  const handleFinish = () => {
    onConfirm(selectedPublishing, unmountComponent);
  };
  const getSelectedPublishing = (newselection) => {
    const find = publishings.find((publsihing) => newselection.indexOf(publsihing.publishingId) > -1);
    return find;
  };

  const handleCreate = () => {
    unmountComponent();
    createNew(selectedPublishing, unmountComponent);
  };

  const handleDelete = () => {
    /* const unMount = publishings.publishings.length == 1;
        if (unMount) {
            unmountComponent();
        } */
    deletePublishing(selectedPublishing, publishings, unmountComponent);
  };

  const handleAddComment = () => {
    /* const unMount = publishings.publishings.length == 1;
         if (unMount) {
             unmountComponent();
         } */
    addComment(selectedPublishing, unmountComponent);
  };
  return (
    <Dialog open onClose={unmountComponent} fullWidth maxWidth="md">
      <DialogTitle>
        <FormattedMessage id="PublishWizard.EditTitle" defaultMessage="Edit Publishing" />
      </DialogTitle>
      <DialogContent>
        {publishings && publishings.length > 1 && (
          <Box margin={1} ref={ref} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <PublishingDetailsTable model={model} selectionModel={selectionModel} maxHeight={height} loading={false} />
          </Box>
        )}
        {selectedPublishing && (
          <Box margin={1} style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#fafafa' }}>
            <PublishingDetails selectedElement={selectedPublishing} unMountParent={unmountComponent} />
          </Box>
        )}

        {/* <PublishingTable elements = {publishingList} maxHeight =  {height} loading = {loading}/> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={unmountComponent} size="small">
          <FormattedMessage id="PublishingEdit.Cancel" defaultMessage="Cancel" />
        </Button>

        <Button size="small" onClick={handleCreate}>
          <FormattedMessage id="PublishingEdit.Create" defaultMessage="Create new" />
        </Button>

        <Button size="small" onClick={handleDelete}>
          <FormattedMessage id="PublishingEdit.Delete" defaultMessage="Delete" />
        </Button>

        <Button size="small" onClick={handleAddComment}>
          <FormattedMessage id="PublishingEdit.AddComment" defaultMessage="Add Comment" />
        </Button>

        <Button size="small" variant="outlined" onClick={handleFinish}>
          <FormattedMessage id="PublishingEdit.Republish" defaultMessage="Republish" />
          <KeyboardArrowRight />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default injectIntl(EditPublishing);
