import getStringPathOfNode, { getStringPathOfNodeOneCatalog } from './getStringPathOfNode';

export const getMultipleValueOneCatalog = (catalog) => {
  const selectedCatalog = localStorage.getItem('selected');
  if (typeof selectedCatalog === 'string') {
    const nodeUUID = selectedCatalog.split('^')[1];
    return [
      {
        value: nodeUUID,
        label: getStringPathOfNodeOneCatalog(catalog, nodeUUID) || ''
      }
    ];
  }
  return [];
};

export const getMultipleValueOneCatalogSelected = (catalog, selected) => {

  if (typeof selected === 'string') {
    const nodeUUID = selected.split('^')[1];
    return [
      {
        value: nodeUUID,
        label: getStringPathOfNodeOneCatalog(catalog, nodeUUID) || ''
      }
    ];
  }
  return [];
};

const getMultipleValue = (catalog) => {
  const selectedCatalog = localStorage.getItem('selected');
  if (typeof selectedCatalog === 'string') {
    const nodeUUID = selectedCatalog.split('^')[1];
    return [
      {
        value: nodeUUID,
        label: getStringPathOfNode(catalog, nodeUUID) || ''
      }
    ];
  }
  return [];
};

export default getMultipleValue;
