import './formElements.css';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Select from 'react-select';

class MultiSelectField extends Component {
  // initialize state
  state = {
    value: this.props.value,
    dirty: false,
    errors: []
  };

  componentDidUpdate(prevProps) {
    if (this.props.value != null && this.state.value == null) {
      this.setState({ value: this.props.value, stayOpen: true, removeSelected: true });
    }
  }

  hasChanged = (value) => {
    // e.preventDefault();

    const { label, required = false, intl, validator = (f) => f, onStateChanged = (f) => f } = this.props;

    this.setState({ value }, () => onStateChanged(this.state));
  };

  render() {
    const { value, dirty, errors, removeSelected, stayOpen } = this.state;
    const { options, label, fieldId, placeholder, children, hideLabel } = this.props;

    const hasErrors = errors.length > 0;
    const controlClass = [
      hideLabel ? 'form-control2' : 'form-control',
      dirty ? (hasErrors ? 'is-invalid' : 'is-valid') : ''
    ]
      .join(' ')
      .trim();
    const multi = !(this.props.isMulti != null && this.props.isMulti == false);
    return (
      <div className="form-group px-3 pb-2" style={{ minWidth: hideLabel && '200px' }}>
        {!hideLabel && (
          <div className="d-flex flex-row justify-content-between align-items-center">
            <label htmlFor={fieldId} className="control-label">
              {label}
            </label>
            {/** Render the first error if there are any errors * */}
            {hasErrors && <div className="error form-hint font-weight-bold text-right m-0 mb-2">{errors[0]}</div>}
          </div>
        )}
        {/* Render the children nodes passed to component */}
        {children}
        {/* <input type={type} className={controlClass} id={fieldId} placeholder={placeholder} value={value} onChange={this.hasChanged} autoComplete="off"/> */}
        <Select
          className={controlClass}
          id={fieldId}
          closeOnSelect={stayOpen}
          disabled={false}
          isMulti={multi}
          onChange={this.hasChanged}
          options={options}
          placeholder={placeholder}
          removeSelected={removeSelected}
          simpleValue
          value={value}
        />
      </div>
    );
  }
}

MultiSelectField.propTypes = {
  label: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  children: PropTypes.node,
  validator: PropTypes.func,
  hideLabel: PropTypes.bool,
  onStateChanged: PropTypes.func
};

export default injectIntl(MultiSelectField);
