import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  commentsRoot: {
    flexGrow: 1
  }
}));

function CommentsPane({}) {
  const cl = useStyles();
  return <div className={`${cl.commentsRoot}`}>NOT IMPLEMENTED</div>;
}
export default CommentsPane;
