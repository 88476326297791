import

{ checkinApi, checkoutApi } from "../API";
import { handleValue } from "./main";

export const handleCheckin = ( versionId, setCheckin) => {
  try {
      checkinApi(versionId);
      if(setCheckin){
        return handleValue(null, "checkin", setCheckin);
      }

  } catch (e) {
    console.log("Error Checkin!!!", e.message);
  }
};

export const handleCheckout = (versionId) => {
  try {
    // const id = values.treeItem.elementInfo.versionId;
    return checkoutApi(versionId);
  } catch (e) {
    console.log("Error Checkout!!!", e.message);
  }
};