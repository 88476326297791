import React, { Fragment } from 'react';
import AutoGrowInput from '@zert-packages/components/shared/AutoGrowInput/AutoGrowInput';
import { initProject, loadProjectTemplates } from './projectActions';
import { API_ROOT_WAR, elementCreatedAndLoaded, loadHelp, moveAfterDelivery } from '@zert-packages/actions/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import ButtonRM from '@zert-packages/components/shared/Button';
import CatalogPlacement from '@zert-packages/components/shared/CatalogPlacement/CatalogPlacement';
import { FormattedMessage } from 'react-intl';
import CancelButton from '@zert-packages/components/shared/CallToActionButton/CancelButton';
import UserSuggestBox from '@zert-packages/components/shared/UserSuggestBoxNew/UserSuggestBox';
import DeadlinePicker from '@zert-packages/components/shared/DeadlinePicker/DeadlinePicker';
import { getOverviewSettings } from '@zert-packages/actions/coreReducers';
import Scroll from '@zert-packages/components/shared/Scroll';
import getMultipleValue from '@zert-packages/utils/getMultipleValue';
import { getUsersQuery } from '@zert-packages/actions/actionplan';

class TemplateSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <select
        style={{
          color: '#0E3944',
          fontWeight: '500',
          background: 'none',
          border: 'none',
          width: '100%',
          margin: '6px 0 0 -4px'
        }}
        list_id="5"
        onChange={(e) => {
          this.props.onChange(e);
        }}
        value={this.props.selectedValue}
      >
        <option key={-1} value={-1}>
          -
        </option>
        {this.props.questionairetemplates.map((template, index) => {
          return (
            <option key={index} value={template.versionId}>
              {template.name}
            </option>
          );
        })}
      </select>
    );
  }
}

class NewProjectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idtemplate: -1,
      name: '',
      id: '',
      description: null,
      help: null,
      multipleValue: getMultipleValue(this.props.catalog),
      multipleValue2: [],
      responsibleText: null
    };
    this.onClick = this.onClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectorChange = this.selectorChange.bind(this);
    this.handleChangeDesc = this.handleChangeDesc.bind(this);
    this.assignChagedValues = this.assignChagedValues.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleIdChange = this.handleIdChange.bind(this);
    this.onChangeDateCallback = this.onChangeDateCallback.bind(this);
  }

  onChange(fieldName, user) {
    if (user && user.name) {
      this.state[fieldName] = user;
      this.setState(this.state);
    }
  }

  assignChagedValues(data) {
    this.state.multipleValue = data;
  }

  componentDidMount() {
    if (!this.props.catalogpermits['zert-project']) {
      this.props.dispatch(getOverviewSettings('zert-project'));
    }
    const {
      match: { params }
    } = this.props;
    if (!this.props.questionairetemplates) {
      this.props.dispatch(loadProjectTemplates());
    }
    if (!this.props.helpText.project) {
      this.props.dispatch(loadHelp('project'));
    } else {
      this.setState({
        help: this.props.helpText.project
      });
    }

    if (localStorage.getItem('selectedLabel') != null) {
      this.setState({
        multipleValue:
          localStorage.getItem('selectedLabel') == null ? [] : [{ value: localStorage.getItem('selectedLabel') }]
      });
    }

    if (localStorage.getItem('selectedLabel2') != null) {
      this.setState({
        multipleValue2:
          localStorage.getItem('selectedLabel2') == null ? [] : [{ value: localStorage.getItem('selectedLabel2') }]
      });
    }

    this.setState({
      versionId: params.versionId,
      activityId: params.activityid
    });
    const url = window.location.href;
    const parameters = new URLSearchParams(url.split('?')[1]);
    const templateVersionId = parameters.get('template-version-id');
    if (templateVersionId) {
      this.setState({
        idtemplate: templateVersionId
      });
    }
  }

  onClick(e) {
    if (this.state.name == null || this.state.name.length == 0) {
      return;
    }

    const project = {
      name: this.state.name,
      description: this.state.description,
      projectId: this.state.id,
      start: this.state.start && this.state.start,
      stop: this.state.stop && this.state.stop,
      manager: this.state.manager && this.state.manager,
      leader: this.state.leader && this.state.leader,
      responsible: this.state.responsible && this.state.responsible,
      responsibleText: this.state.responsibleText
    };

    this.props.dispatch(
      initProject(
        this.state.idtemplate,
        project,
        this.state.multipleValue,
        this.props.storeInExplorer && this.props.selectedTreeNode && this.props.selectedTreeNode
          ? this.props.selectedTreeNode[this.props.selectedTreeNode.length - 1]
          : null,
        this.state.versionId,
        this.state.activityId
      )
    );
  }

  selectorChange(e) {
    this.setState({ idtemplate: e.target.value });
  }

  componentDidUpdate(prevProps) {
    const basisinpath = window.location.href.indexOf('basis') >= 0;
    if (this.props.newElementCreated && !basisinpath) {
      this.props.history.push(`${API_ROOT_WAR}/project/${this.props.newversionid}`);
      this.props.dispatch(elementCreatedAndLoaded());
    } else if (this.props.newElementCreated) {
      moveAfterDelivery(this.state.versionId, this.props.history, this.props.dispatch, this.state.activityId);
    } else if (this.state.help == null && this.props.helpText.project != null) {
      this.setState({ help: this.props.helpText.project });
    }
  }

  handleChangeCustomer(user) {
    if (user && user.name && (!this.state.responsible || user.name != this.state.responsible.name)) {
      this.state.responsible = user;
      this.state.responsibleText = null;
      this.setState(this.state, (prevState) => {});
    }
    if (user && user != '' && !user.name && (!this.state.responsibleText || user != this.state.responsibleText)) {
      this.state.responsible = null;
      this.state.responsibleText = user;
      this.setState(this.state, (prevState) => {});
    }
  }

  handleChange(e) {
    this.setState({ name: e.target.value });
  }

  handleIdChange(e) {
    this.setState({ id: e.target.value });
  }

  handleChangeDesc(e) {
    this.setState({ description: e.target.value });
  }

  onChangeDateCallback(fieldName, date) {
    this.state[fieldName] = date;
    this.setState(this.state);
    // this.props.measure.deadline = date;
  }

  render() {
    if (this.props.error != null) {
      return (
        <div>
          <FormattedMessage
            id="newpage.project.error"
            defaultMessage="Error!, {message}"
            values={{ message: this.props.error.message }}
          />
        </div>
      );
    }

    if (this.props.loadingTemplates || this.props.questionairetemplates == null) {
      return (
        <Loader>
          <FormattedMessage id="newpage.project.loadtemplates" defaultMessage="Project templates" />
        </Loader>
      );
    }

    if (this.props.creatingNewElement) {
      return (
        <Loader loaderAction={LoaderAction.Creating}>
          <FormattedMessage id="newpage.project.createproject" defaultMessage="New Project" />
        </Loader>
      );
    }
    let catalogAllowedContinue = true;
    if (this.props.catalogpermits != null && this.props.catalogpermits['zert-project']) {
      const object = this.props.catalogpermits['zert-project'];
      const setuped1 = !object.catalogElementId1 || (object.catalogElementId1 && this.state.multipleValue.length > 0);

      if (object.catalogStatus == 'any') {
        if (this.state.multipleValue.length == 0) {
          catalogAllowedContinue = false;
        }
      }
      if (object.catalogStatus == 'and') {
        if (!setuped1 && object.catalogElementId1) {
          catalogAllowedContinue = false;
        }
      }
      if (object.catalogStatus == 'or') {
        if (!setuped1) {
          catalogAllowedContinue = false;
        }
      }
    }

    return (
      <Scroll>
        <div className="workfield" style={{ height: 'unset' }}>
          <div
            className="left_panel"
            style={{
              flex: '1',
              backgroundColor: '#F4F8FA',
              padding: '0 0 0 24px',
              display: 'flex',
              flexDirection: 'column',
              minHeight: 'calc(var(--vh, 1vh) * 100 - 56px)'
            }}
          >
            <div
              className="editMember"
              style={{
                backgroundColor: '#F4F8FA',
                height: 'auto',
                boxShadow: 'none',
                flex: '10'
              }}
            >
              <div className="captionLabel">
                <FormattedMessage id="newpage.project.projecttitle" defaultMessage="Project Title" />
                <span className="required">*</span>
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <input
                  autoFocus={!this.state.name}
                  type="text"
                  value={this.state.name}
                  style={{
                    color: '#0E3944',
                    fontSize: '20px',
                    fontWeight: 500,
                    border: 'none',
                    width: '100%',
                    background: 'transparent'
                  }}
                  onChange={this.handleChange}
                />
              </div>

              <div className="captionLabel">
                <FormattedMessage id="newpage.project.projectid" defaultMessage="Project ID" />
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <input
                  type="text"
                  value={this.state.id}
                  style={{
                    color: '#0E3944',
                    fontSize: '20px',
                    fontWeight: 500,
                    border: 'none',
                    width: '100%',
                    background: 'transparent'
                  }}
                  onChange={this.handleIdChange}
                />
              </div>

              {this.props.catalog && (
                <>
                  <div className="captionLabel">
                    <FormattedMessage id="newpage.project.placement" defaultMessage="Placement in catalog" />
                  </div>
                  <div className="edit" style={{ background: 'none' }}>
                    <CatalogPlacement
                      assignChagedValues={this.assignChagedValues}
                      multipleValue={this.state.multipleValue}
                      catalog={this.props.catalog}
                      loading={this.props.loadingCatalog}
                    />
                  </div>
                </>
              )}

              <div className="captionLabel">
                <FormattedMessage id="newpage.project.projecttemplate" defaultMessage="Project Template" />
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <TemplateSelector
                  questionairetemplates={this.props.questionairetemplates}
                  onChange={this.selectorChange}
                  selectedValue={this.state.idtemplate}
                />
              </div>

              <div className="captionLabel">
                <FormattedMessage id="newpage.project.project.owner" defaultMessage="Project Owner" />
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <UserSuggestBox
                  readonly={this.props.readonly}
                  onChange={(e) => {
                    this.onChange('leader', e);
                  }}
                  getUsers={getUsersQuery}
                  getUserId={(user) => user.username}
                  getUserLabel={(user) => user.name}
                  initialValue={this.state.leader && this.state.leader.name}
                  label={<FormattedMessage id="DelegateTasksSettingsDialog.UserLabel" defaultMessage="User" />}
                />
              </div>

              <div className="captionLabel">
                <FormattedMessage id="newpage.project.projectmanager" defaultMessage="Project Manager" />
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <UserSuggestBox
                  readonly={this.props.readonly}
                  onChange={(e) => {
                    this.onChange('manager', e);
                  }}
                  getUsers={getUsersQuery}
                  getUserId={(user) => user.username}
                  getUserLabel={(user) => user.name}
                  initialValue={this.state.manager && this.state.manager.name}
                  label={<FormattedMessage id="DelegateTasksSettingsDialog.UserLabel" defaultMessage="User" />}
                />
              </div>

              <div className="captionLabel">
                <FormattedMessage id="newpage.project.responsible" defaultMessage="Customer" />
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <UserSuggestBox
                  readonly={this.props.readonly}
                  onChange={(e) => {
                    this.handleChangeCustomer(e);
                  }}
                  getUsers={getUsersQuery}
                  getUserId={(user) => user.username}
                  getUserLabel={(user) => user.name}
                  initialValue={this.state.responsible && this.state.responsible.name}
                  label={<FormattedMessage id="DelegateTasksSettingsDialog.UserLabel" defaultMessage="User" />}
                />
              </div>

              <div className="captionLabel">
                <FormattedMessage id="newpage.project.start" defaultMessage="Start date" />
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <DeadlinePicker
                  deadline={this.state.start}
                  onChangeCallback={(e) => this.onChangeDateCallback('start', e)}
                />
              </div>

              <div className="captionLabel">
                <FormattedMessage id="newpage.project.stop" defaultMessage="Stop date" />
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <DeadlinePicker
                  deadline={this.state.stop}
                  onChangeCallback={(e) => {
                    this.onChangeDateCallback('stop', e);
                  }}
                />
              </div>

              <div className="captionLabel">
                <FormattedMessage id="newpage.project.additionaldesciption" defaultMessage="Additional Description" />
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <AutoGrowInput defaultValue="" onChangeCallback={this.handleChangeDesc} />
              </div>
            </div>

            <div
              style={{
                flex: '1',
                justifyContent: 'flex-end',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div className="createNewButtons">
                <ButtonRM
                  styleName="primary"
                  whenClick={this.onClick}
                  disabled={this.state.name === null || this.state.name.length === 0 || !catalogAllowedContinue}
                  text=""
                >
                  <FormattedMessage id="newproject.create" defaultMessage="Create" />
                </ButtonRM>
                <CancelButton />
              </div>
            </div>
          </div>

          <div
            id="right_panel"
            style={{
              flex: '1',
              padding: '24px'
            }}
          >
            {this.state.help && (
              <div
                className="checklistDesc"
                dangerouslySetInnerHTML={{ __html: this.state.help ? this.state.help : '' }}
              />
            )}
          </div>
        </div>
      </Scroll>
    );
  }
}

const mapStateToProps = (state) => ({
  helpText: state.helpText,
  loadingTemplates: state.loadingTemplates,
  questionairetemplates: state.projecttemplates,
  creatingNewElement: state.creatingNewElement,
  newElementCreated: state.newElementCreated,
  newversionid: state.newversionid,
  catalog: state.catalog,
  selectedTreeNode: state.CORE.selectedPath,
  catalogpermits: state.catalogpermits,
  loadingCatalog: Object.values(state.loadingCatalogs).reduce((a, v) => a || v, false)
});

export default withRouter(connect(mapStateToProps)(NewProjectPage));
