export const findMetaDefinitionByIdentifier = (identifier, metadataTemplates) => {
  if (!identifier || !metadataTemplates) {
    return null;
  }
  for (let i = 0; i < metadataTemplates.templates.length; i++) {
    for (let j = 0; j < metadataTemplates.templates[i].metadataDefinitions.length; j++) {
      if (metadataTemplates.templates[i].metadataDefinitions[j].identifier == identifier) {
        return metadataTemplates.templates[i].metadataDefinitions[j];
      }
    }
  }
  return null;
};
