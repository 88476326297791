import React, { useState, useEffect, Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';

import 'react-datepicker/dist/react-datepicker.css';
import {initDOCAPI} from "@zert-packages/plugins/DocumentPlugin/API";
import validator from 'validator';




const useStyles = makeStyles({
  DialogContent: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'stretch',
    padding: 20,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto'
  }
});
function CreateNewDocument({ handleClose, onCreate }) {
  const cl = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [showUrlValidation, setShowUrlValidation] = useState(false);
  const [validUrl, setValidUrl] = useState(false);
  const [link, setLink] = useState('');
  const [name, setName] = useState('');
  const handleChange = (event) => {
    const { value } = event.target;
    setName(value);
  };

  const handleUrlBlur = () => {
    setShowUrlValidation(true);
  };

  const handleChangeLink = (event) => {
    const { value } = event.target;
    setLink(value);
    setValidUrl(
        validator.isURL(value, {
          protocols: ['http', 'https'],
          require_protocol: true,
          require_valid_protocol: true
        }));
  };

  const handleOnCreate = () => {
    setIsLoading(true);

    initDOCAPI({ name, url : link, link : true })
      .then((res) => {
        onCreate(res);
        setIsLoading(false);
        handleClose();
      })
      .catch((e) => {
        setIsLoading(false);
        throw new Error(e);
      });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={handleClose}>
      <DialogTitle>
        <FormattedMessage id="Document.CreateNewDialog.DialogTitle" defaultMessage="New Link" />
      </DialogTitle>

      <DialogContent className={cl.DialogContent}>
        <TextField
          id="standard-pressure"
          label={<FormattedMessage id="Document.Name" defaultMessage="Name" />}
          value={name}
          onChange={handleChange}
          fullWidth
          variant="outlined"
        />
        <TextField
            id="standard-pressure"
            label={<FormattedMessage id="Document.Link" defaultMessage="Link" />}
            value={link}
            onChange={handleChangeLink}
            fullWidth
            variant="outlined"
            onBlur={handleUrlBlur}
            error={showUrlValidation && link !== '' && !validUrl}
            helperText={showUrlValidation && link !== '' && !validUrl ?
                <FormattedMessage id="ShortcutsPage.invalidUrl" defaultMessage="Invalid URL" /> : ''}
        />
      </DialogContent>

      <DialogActions>
        <>
          <Button onClick={handleOnCreate}  color="primary" disabled={!(name && name.length > 0 && link && link.length > 0 && validUrl)} variant="outlined">
            <FormattedMessage id="Document.CreateNewDialog.Confirm" defaultMessage="Create" />
          </Button>

          <Button onClick={handleClose} color="primary" autoFocus>
            <FormattedMessage id="Document.CreateNewDialog.Cancel" defaultMessage="Cancel" />
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
}

export default CreateNewDocument;
