import { handleQuery } from '@zert-packages/actions/api';
import { createOpenReportSnack } from '@zert-packages/components/common/snackBar';

export const checkReportStatus = (
  report,
  snackbar,
  generatingSnackbar,
  generatedReport,
  mimeType,
  callback,
  callbackArg,
  reportName
) => {
  handleQuery(`/report-react/updateProgressStatus/${report.callbackId}/false`).then((newReport) => {
    if (newReport !== null && newReport.active) {
      checkReportStatus(
        newReport,
        snackbar,
        generatingSnackbar,
        generatedReport,
        mimeType,
        callback,
        callbackArg,
        reportName
      );
    } else {
      snackbar.closeSnackbar(generatingSnackbar);
      if (mimeType) {
        createOpenReportSnack(
          snackbar,
          generatedReport.reportUUID ? generatedReport.reportUUID : report.callbackId,
          mimeType,
          reportName
        );
      } else if (callback && typeof callback === 'function') callback(callbackArg);
      //  createOpenReportSnack(snackbar, generatedReport.uuid, mimeType);
    }
  });
};
