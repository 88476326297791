import DisabledElementMode from '@zert-packages/components/common/DisabledElementMode';
import TabsWithVersionController from '@zert-packages/components/common/TabsWithVersionController';
import Scroll from '@zert-packages/components/shared/Scroll';
import getParameterAsInt from '@zert-packages/utils/getParameterAsInt';
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import ModalCenter from '@zert-packages/components/common/ModalCenter';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import useVersionManager from '@zert-packages/utils/useVersionManager';
import { TextField } from '@mui/material';
import { getVersionInfo } from '@zert-packages/actions/coreReducers';
import { definePEDClass } from '@zert-packages/utils/definePEDClass';
import { resetPED } from './pedReducers';
import { fetchPEDView, storePEDView } from './serviceMidleware';
import PEDWrapper from './PEDWrapper';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '48px 1fr',
    gridGap: 6,
    paddingTop: 2,
    overflow: 'hidden',
    height: '100%',
    width: '100%'
  },
  contentContainer: {
    padding: '10px 15px',
    width: '100%',
    height: '100%',
    overflow: 'true',
    borderSizing: 'border-box'
  }
});

function PEDView(props) {
  const cl = useStyles();

  const PED = useSelector((state) => state.PED);

  const {
    match: { params }
  } = props;
  const dispatch = useDispatch();
  const [changed, setChanged] = useState(false);
  const [ped, setPed] = useState(null);
  const [name, setName] = useState(null);
  const [versionManager, versionInfo] = useVersionManager();
  const versionId = getParameterAsInt(params.versionId);
  const handleChange = (event) => {
    const { value } = event.target;
    setName(value);
  };

  useEffect(() => {
    if (PED.ped) {
      setPed({ ...PED.ped });
      setName(PED.ped.name);
    } else {
      setPed(null);
      setName(null);
    }
  }, [PED.ped]);

  useEffect(() => {
    dispatch(resetPED());

    dispatch(getVersionInfo(versionId));
    dispatch(fetchPEDView(versionId));
  }, [versionId]);

  if (!ped || !versionInfo || _.get(PED, 'ped.info.versionId', -1) !== versionId) {
    return (
      <ModalCenter open>
        <Loader>
          <FormattedMessage id="PED.MainLoader" defaultMessage="Classification AFS 2017:3" />
        </Loader>
      </ModalCenter>
    );
  }

  return (
    <DisabledElementMode>
      <div className={cl.root}>
        <TabsWithVersionController
          tabs={[]}
          tabIndex={0}
          onChangeTabIndex={() => {}}
          onSaveVersion={() => {
            // myDeviation.riskRows = riskRows;
            dispatch(storePEDView(versionId, { ...ped, name, classG: definePEDClass(ped) }));
          }}
          canApproveVersion={versionManager.canApproveVersion}
          canSaveVersion={versionManager.canSaveVersion}
          canCreateVersion={versionManager.canCreateNewVersion}
          onCreateNewVersion={versionManager.createNewVersion}
          onApproveVersion={versionManager.approveVersion}
        />

        <div className={cl.contentContainer}>
          <TextField
            id="standard-pressure"
            label={<FormattedMessage id="PEDView.Name" defaultMessage="Name" />}
            value={name}
            onChange={handleChange}
            placeholder="Name"
            fullWidth
            variant="outlined"
          />
          <Scroll>
            <PEDWrapper key={`pec${versionId}`} object={{ ...ped, onChange: setPed }} />
          </Scroll>
        </div>
      </div>
    </DisabledElementMode>
  );
}

export default withRouter(PEDView);
