import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { useElementOpen } from '../../ElementTile/utils/useElementOpen';

const useStyles = makeStyles((theme) => ({
  openRootImg: {
    display: 'flex',
    animation: 'fade-in 0.5s'
  },
  openImg: {
    width: 14,
    marginLeft: 2,
    cursor: 'pointer'
  }
}));

function OpenNewWindows({ el, blockClick, style = {} }) {
  const cl = useStyles();
  const { open } = useElementOpen();

  const intl = useIntl();

  const handleOpen = async (e) => {
    e.stopPropagation()
    if (el.elementInfo && !blockClick) {
      await open({ ...el, mimeType: el.elementInfo.mimeType, versionId: el.elementInfo.versionId });
    }
  };

  return (
    <div className={cl.openRootImg} style={style}>
      <Tooltip
        title={intl.formatMessage({ id: 'OpenNewWindow', defaultMessage: 'Open in new window' })}
        placement="right"
      >
        <OpenInNewIcon className={cl.openImg} onClick={(e) => handleOpen(e)} />
      </Tooltip>
    </div>
  );
}

export default OpenNewWindows;
