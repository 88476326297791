import React, { memo } from 'react';
import { TextField } from '@mui/material';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';

const TextFieldWithMemo = memo(
  ({ value, onChange, disabled = false, error, ...other }) => (
    <TextField
      error={error}
      disabled={disabled}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      fullWidth
      margin="normal"
      {...other}
    />
  ),
  selectiveMemoComparison(
    (p) => p.value,
    (p) => p.error,
    (p) => p.disabled
  )
);

export default TextFieldWithMemo;
