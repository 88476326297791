import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import dialogCustom from '@zert-packages/components/common/dialogCustom';
import CancelConfirmDialog from '@zert-packages/components/common/dialogs/CancelConfirmDialog';
import { FormattedMessage } from 'react-intl';

const styles = {
  container: {
    width: '100%',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'flex-end',
    gridGap: '10px',
    padding: '10px'
  }
};

SaveActions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

function SaveActions({ onCancel, onSave }) {
  const handleConfirmCancel = () => dialogCustom(CancelConfirmDialog, { onCancel });

  return (
    <div style={styles.container}>
      <Button color="primary" onClick={handleConfirmCancel}>
        <FormattedMessage id="CancelSavePanel.cancel" defaultMessage="Cancel" />
      </Button>
      <Button variant="outlined" color="primary" onClick={onSave}>
        <FormattedMessage id="CancelSavePanel.save" defaultMessage="Save" />
      </Button>
    </div>
  );
}

export default SaveActions;
