import React, { useMemo, useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import getClientDemands from 'rm/src/Plugins/RMPPlugin/utils/getClientDemands';
import MultiSelectField2 from '@zert-packages/components/shared/FormElements/MultiSelectField2';
import SelectField from '@zert-packages/components/shared/FormElements/SelectField';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import Grid from '@mui/material/Grid';
import PublishingFilterPaper from '../PreviewPlugin/components/PublishingFilterPaper';
import {
  setCompareWithVersionId,
  setFallbackLocale,
  setLanguageSeparated,
  setMultipleLanguage,
  setSelectedLanguage,
  setSelectedLayout,
  setSelectedLayoutFilter
} from '../PreviewPlugin/previewReducers';
import { PreviewWizardContext } from '../PreviewPlugin/PreviewWizardDialog/PreviewWizardStepper';
import { downloadCompareWithVersionId } from '../PreviewPlugin/servicesMiddlewares';

const useStyles = makeStyles({
  DialogContent: {
    width: '100%',
    height: '600px',
    display: 'grid',
    gridTemplateRows: '48px 1fr'
  },
  bodyContainer: {
    overflow: 'auto'
  }
});

export function AloHTMLPublishingDialog({ unmountComponent, selectElement, onConfirm }) {
  const cl = useStyles();
  const { activeLocales } = useSelector((state) => state);
  const { publishingFilters } = useSelector((state) => state);
  const { multipleSelectedLanguage, languageSeparated, fallbackLocale } = useSelector((state) => state.PREVIEW);
  const { compareWithVersions, compareWithVersionId } = useSelector((state) => state.PREVIEW);
  const { previewPublishingFilters } = useSelector((state) => state.PREVIEW);
  const dispatch = useDispatch();
  const classes = useStyles();
  const withUnmountComponent = (callback) => () => {
    unmountComponent();
    if (callback) callback();
  };

  const handleCancel = () => unmountComponent();
  const handleConfirm = () => {
    onConfirm(compareWithVersionId, multipleSelectedLanguage, fallbackLocale, previewPublishingFilters);
    unmountComponent();
    //  handleClose();
  };

  const localeChanged = (state) => {
    dispatch(setMultipleLanguage(state));
  };

  const layoutFilterChange = (state) => {
    dispatch(setSelectedLayoutFilter(state.value));
  };

  const layoutChanged = (e) => {
    dispatch(setSelectedLayout(e.target.value));
  };

  const fallbackLocaleChanged = (state) => {
    dispatch(setFallbackLocale(state.value));
  };

  const updateLanguageSeparated = (state) => {
    dispatch(setLanguageSeparated(state));
  };

  useEffect(() => {
    dispatch(setCompareWithVersionId(-1));
    dispatch(downloadCompareWithVersionId(selectElement, true));
  }, selectElement);
  const versionIdChanged = (state) => {
    dispatch(setCompareWithVersionId(state.value));
  };
  /*    const versionIdChanged = (state) => {
        dispatch(setCompareWithVersionId(state.value))
    };
    if (compareWithVersions == null) {
        return <ZertLoader/>
    } */

  // const sortedDemands = useMemo(() => getClientDemands(project.demands), [project.demands]);
  //  const showConfirm = state.name.length > 0;
  return (
    <Dialog open onClose={unmountComponent} fullWidth>
      <DialogTitle>
        <FormattedMessage id="ExportHtmlAlo.Title" defaultMessage="Export" />
      </DialogTitle>
      <DialogContent className={cl.DialogContent}>
        <Grid container spacing={1} direction="row">
          <Grid item xs={12} md={12}>
            {compareWithVersions == null || compareWithVersionId == -1 ? (
              <Loader />
            ) : (
              <SelectField
                values={compareWithVersions}
                onStateChanged={versionIdChanged}
                value={compareWithVersionId}
                caption={<FormattedMessage id="ExportHtml.VersionID" defaultMessage="Select version id to publish" />}
                valLabel="nameLb"
                valVal="versionId"
                fieldId="versionId"
                label="nameLb"
              />
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <MultiSelectField2
              options={activeLocales}
              onStateChanged={localeChanged}
              value={multipleSelectedLanguage}
              valLabel="extendedDisplayName"
              takeValue
              propName="extendedDisplayName"
              propId="value"
              label={<FormattedMessage id="ExportHtml.Locale" defaultMessage="Locales included in publication" />}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <SelectField
              values={activeLocales}
              onStateChanged={fallbackLocaleChanged}
              value={fallbackLocale}
              caption={
                <FormattedMessage id="ExportHtml.LanguagesPage.FallbackLocale" defaultMessage="Fallback Locale" />
              }
              valLabel="extendedDisplayName"
              fieldId="value"
              label="displayName"
            />
          </Grid>
          {publishingFilters && publishingFilters.length > 0 && (
            <Grid item xs={12} md={12}>
              {' '}
              <PublishingFilterPaper previewPublishingFilters={previewPublishingFilters} />{' '}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary" variant="outlined">
          <FormattedMessage id="ExportHtml.Confirm" defaultMessage="Confirm" />
        </Button>
        <Button onClick={handleCancel} color="primary">
          <FormattedMessage id="ExportHtml.Cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
