import {
  loadParts,
  loadPartsGroup,
  loadPartsImage,
  turnOffCLMLoader,
  turnOnCLMLoader
} from '../TranslationJobPlugin/tjReducers';
import { loadPart, load, loadImage } from './API';

export const fetchPart = (versionId, callback) => (dispatch) => {
  dispatch(turnOnCLMLoader());
  loadPart(versionId)
    .then((res) => {
      dispatch(loadPartsImage(res.imageItems))
      dispatch(loadParts(res.partItems));
    })
    .then(() => {
      dispatch(turnOffCLMLoader());
    });
};

export const fetchPartGroup = (parentElementId, checkout, loadItems, callback) => (dispatch) => {
  dispatch(turnOnCLMLoader());
  load(parentElementId, checkout, loadItems)
    .then((res) => {
      dispatch(loadPartsGroup(res));
    })
    .then(() => {
      dispatch(turnOffCLMLoader());
    });
};

export const fetchPartImage = (versionId, accessToken) => (dispatch) => {
  dispatch(turnOnCLMLoader());
  loadImage(versionId, accessToken)
    .then((res) => {
      dispatch(loadPartsImage(res));
    })
    .then(() => {
      dispatch(turnOffCLMLoader());
    });
};
