import React, { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { SketchPicker } from 'react-color';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 350,
    minHeight: 585,
    padding: 11,
    background: '#E2F2F6'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  wrapperTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      cursor: 'pointer'
    }
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 700
  },
  text: {
    fontWeight: '700',
    fontSize: 14,
    marginBottom: 10,
  },
  pickerWrapper: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'center'
  },

  wrapperButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 20,
    marginTop: 20
  },
  button: {
    color: '#ffffff',
    background: '#4db1d3',
    marginLeft: 10,
    minWidth: 80,
    border: '1px solid #4db1d3',
    outline: 'none !important',
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
      color: '#212529',
      border: '1px solid #4db1d3'
    },
    '&:focus': {
      backgroundColor: '#4db1d3'
    }
  }
}));

function DialogColors({ open = true, setOpen =  () =>{}, text, setText, type, color='#ccc', rgb}) {
  const cl = useStyles();
  const [selectedColor, setSelectedColor] = useState(color);
  const [openDialog, setOpenDialog] = useState(true)

  const colors = [
    '#D0021B',
    '#F5A623',
    '#F8E71C',
    '#8B572A',
    '#7ED321',
    '#417505',
    '#BD10E0',
    '#9013FE',
    '#4A90E2',
    '#50E3C2',
    '#B8E986',
    '#000000',
    '#4A4A4A',
    '#9B9B9B',
    '#4c4f56',
    '#0048ba',
    '#1b1404',
    '#7cb0a1',
    '#b0bf1a',
    '#7cb9e8',
    '#c9ffe5',
    '#714693',
    '#b284be',
    '#00308f',
    '#72a0c1',
    '#d4c4a8',
    '#af002a',
    '#fafafa',
    '#f5e9d3',
    '#93dfb8',
    '#f0f8ff',
    '#84de02',
    '#e32636',
    '#c46210',
    '#0076a3',
    '#efdecd',
    '#9de5ff',
    '#a4c639',
    '#f2f3f4',
    '#cd9575',
    '#665d1e',
    '#915c83',
    '#841b2d',
    '#faebd7',
    '#e0b646',
    '#008000',
    '#dfbe6f',
    '#4fa83d',
    '#af4d43',
    '#8db600',
    '#fbceb1',
    '#fffeec',
    '#014b43',
    '#5fa777',
    '#edf5f5',
    '#110c6c',
    '#d0ff14',
    '#433e37',
    '#4b5320',
    '#21abcd',
    '#2a2630',
    '#da6304',
    '#fae7b5',
    '#ffe135'
  ];

  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false)
  };

  const handleChange = (color) => {
    if(rgb){
      return   setSelectedColor(color.rgb, type);
    }
    setSelectedColor(color.hex, type);
  };

  const handleSaveColorApi = () => {
    text.setValues((prev) => ({
      ...prev,
      templateList: {
        ...prev.templateList,
        typeSettings: {
          ...prev.templateList.typeSettings,
          [text.type]: selectedColor
        }
      }
    }));
  };

  const handleSaveColor = () => {
    setOpen(false);
    setOpenDialog(false)
    if (setText) {
      return setText(selectedColor, type );
    }
    if(text.setValues){
      handleSaveColorApi();
    }
  };



  useEffect(() => {
    if (text && text.color) {
      setSelectedColor(text.color);
    }
  }, [text]);

  return (
    <Dialog
      open={open && openDialog}
      onClose={handleClose}
      classes={{
        paperScrollPaper: cl.root
      }}
    >
      {text && (
        <div className={cl.wrapper}>
          <div className={cl.wrapperTitle}>
            <span className={cl.title}>{text.label && text.label}</span>
            <CloseIcon onClick={handleClose} />
          </div>
          <span className={cl.text}>{text.text && text.text}</span>
          <div className={cl.pickerWrapper}>
            <SketchPicker
              presetColors={colors}
              color={selectedColor}
              onChangeComplete={handleChange}
              disableAlpha
            />
          </div>

          <div className={cl.wrapperButton}>
            <Button className={cl.button} onClick={handleSaveColor}>
              OK
            </Button>
            <Button className={cl.button} onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
}

export default DialogColors;
