import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Tree from "@zert-packages/components/InfoElement/Structure/Tree";
import {getApiTree} from "@zert-packages/components/InfoElement/Structure/API";
import translateable from "@zert-packages/components/InfoElement/LanguageFilters/image/translateable-icon-colored.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import DropMenu from "@zert-packages/components/DropMenu";
import Divider from "@mui/material/Divider";
import {SparePartContext} from "../../store/SparePartContext";

const useStyles = makeStyles((theme) => ({
  rootExpanderElementProperties: {
    flexGrow: 1
  },
  rootAccordion: {
    borderBottomLeftRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
    borderBottom: '1px solid #E2F2F6',
    height: '100%',
    '& .MuiCollapse-wrapper, .MuiAccordionDetails-root, #panel1a-content': {
      height: '100%'
    },
    '& .MuiCollapse-root': {
      height: '100%'
    }
  },
  dropMenuButton: {
    width: '100%',
    justifyContent: 'space-between',
    border: '1px solid #DCE3E5',
    marginBottom: 15,
    color: '#8B8B8B',
    fontWeight: '400',
    paddingBottom: 2,
    paddingTop: 2,
    background: '#ffffff',
    paddingRight: 15,
    borderRadius: 0,
    outline: 'none !important',
    '&:hover': {
      background: '#ffffff'
    },
    '& span:nth-child(2)': {
      display: 'none'
    }
  },
  menuLinkSiteWrapper: {
    borderRadius: 0,
    // width: 470,
    width: 'fit-content',
    minWidth: 315,
    maxWidth: 'Calc(100% - 40px)',
    marginTop: 40,
    border: '1px solid #DCE3E5',
    boxShadow: 'none',
    '@media (max-width: 900px)': {
      marginTop: 45,
      width: 'Calc(100% - 151px)'
    }
  },
  AccordionDetailsWrapper: {
    height: '100%',
    minHeight: '300px',
    padding: '8px 0px !important',
    overflow: 'auto'
  },
  rootAccordionDetails: {
    width: '100%',
    // height: '100%'
  },
  wrapperLanguage: {
    padding: '0px 16px'
  },
  wrapperTree: (props) => ({
    borderTop: '1px solid #e8ebec',
    // position: 'absolute',
    paddingTop: 7,
    height: 'calc(100% - 111px)',
    width: '100%',
    '& .MuiTreeItem-content': (props) => ({
      cursor: props.blockClick ? 'not-allowed' : '',
      '&:hover': {
        backgroundColor: props.blockClick ? '#ffffff' : ''
      }
    })
  }),
  inputWrapper: {
    display: "grid",
    gridTemplateColumns: "120px 1fr",
    alignItems: 'center',
    "& > p": {
      marginBottom: '0px'
    }
  },
}));

const Structure = ({ expanded, setExpanded, context }) => {
  const cl = useStyles();
  const { myLanguage } = useSelector((state) => state);
  const { partFormats, imageFormats } = useSelector((state) => state.formatTemplate);
  const { values, setValues } = useContext(context);
  const { versionId } = useParams();
  const [availableFormats, setAvailableFormats] = useState([])

  useEffect(() => {
    if(values.currentViewedElement) {
      if(values.elementType === 'application/zert-image' && imageFormats) {
        setAvailableFormats(imageFormats)
      } else if(partFormats) {
        setAvailableFormats(partFormats)
      }
    }
  }, [values, partFormats, imageFormats])

  const handleChange = () => {
    setExpanded({ ...expanded, expandedPanelOne: !expanded.expandedPanelOne });
  };

  const getTree = useCallback(async () => {
    console.log('here')
    return getApiTree(values.currentElementVersionId || versionId, myLanguage?.value);
  }, [values.currentElementVersionId]);

  const handleChangeTranslatable = (val) => {
    setValues(prev => ({
      ...prev,
      currentViewedElement: {
        ...prev.currentViewedElement,
        element: {
          ...prev.currentViewedElement.element,
          translatable: val.target.checked
        }
      }
    }))
  }

  return (
    <div className={expanded.expandedPanelOne ? cl.rootExpanderElementProperties : ''}>
      <Accordion
        expanded={expanded.expandedPanelOne}
        onChange={() => handleChange('panel1')}
        style={{
          height: expanded.expandedPanelOne ? 'Calc(100% - 46px) !important' : ''
        }}
        className={cl.rootAccordion}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          Structure
        </AccordionSummary>
        <AccordionDetails className={cl.AccordionDetailsWrapper}>
          <Box sx={{padding: '10px'}}>
            <Box sx={{display: 'flex', alignItems: 'center', columnGap: '10px'}}>
              <Typography>Language: </Typography>
              <Select
                  style={{ width: '150px' }}
                  value={values.selectedElementLanguage}
                  onChange={value => {
                    setValues(prev => ({...prev, selectedElementLanguage: value.target.value}))
                  }}
                  inputProps={{
                    name: 'sorting',
                    id: 'sorting-by'
                  }}
              >
                {values.elementTranslations.map(translation => {
                  return <MenuItem value={`${translation.language}_${translation.country}`}>
                    {translation.displayName}
                  </MenuItem>
                })}
              </Select>
            </Box>
            <FormControlLabel
                control={
                  <Checkbox
                      disabled={values.elementType === "application/zert-image"}
                      onChange={handleChangeTranslatable}
                      checked={values.currentViewedElement?.element?.translatable}
                      className={cl.checkboxRoot}
                  />
                }
                label={
                  <span className={cl.checkboxLabel}>
                  <img src={translateable} alt="translateable" /> Translatable
                  </span>}
            />
          </Box>
          <div className={cl.rootAccordionDetails}>
            <div className={cl.wrapperTree}>
              <Tree getRootNode={() => getTree()} structureMain context={SparePartContext}/>
            </div>
          </div>

          <Divider/>

          <Box sx={{display: 'flex', flexDirection: 'column', padding: "10px"}}>
            {values.elementType !== "application/zert-image" && (
                <div className={cl.inputWrapper}>
                  <p>Article number:</p>
                  <TextField
                      id="outlined-helperText"
                      label="Article number"
                      inputProps={{style: {paddingLeft: '8px'}}}
                      value={values.currentViewedElement?.articleNumber}
                      onChange={(e) => {
                        setValues(prev => ({
                          ...prev,
                          currentViewedElement: {
                            ...prev.currentViewedElement,
                            articleNumber: e.target.value
                          }
                        }))
                      }}
                  />
                </div>
            )}

            <div className={cl.inputWrapper}>
              <p>Format:</p>
              {availableFormats && <DropMenu
                  selected={values.currentViewedElement?.format}
                  dropItem={availableFormats.map(el => ({...el, name: el.identifier}))}
                  setDropItem={(el) => setValues(part => {
                    return {
                      ...part,
                      currentViewedElement: {
                        ...part.currentViewedElement,
                        format: el.identifier
                      }
                    }
                  })}
              />}
            </div>

            <div className={cl.inputWrapper}>
              <p>Name:</p>
              <TextField
                  id="outlined-helperText"
                  label="Name"
                  inputProps={{ style: { paddingLeft: '8px' }}}
                  value={values.currentViewedElement?.name}
                  onChange={(e) => {
                    setValues(prev => ({
                      ...prev,
                      currentViewedElement: {
                        ...prev.currentViewedElement,
                        name: e.target.value
                      }
                    }))
                  }}
              />
            </div>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
export default Structure;
