import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import React from 'react';
import { createInProgressSnack } from '@zert-packages/components/common/snackBar';
import { handlePost } from '@zert-packages/actions/api';
import { reportMockUp } from '@zert-packages/utils/reportMockUp';
import { showSnackbarError } from '@zert-packages/components/Snackbars';
import { checkReportStatus } from '@zert-packages/plugins/ReportPlugin/servicesMidleware';
import generateRMPReportMockUp from '@zert-packages/plugins/ReportPlugin/generateRMPReportMockUp';
import { generalCheckBoxes } from './checkboxes';
import generalRadioBtns from './generalRadioBtns';
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';

export const DEMAND_PROJECT_REPORT_TYPES = {
  TREE: 0,
  DETAILS: 1,
  REMAINING_THINGS: 2,
  CATALOG: 3
};

export const generatePrintReport =
  (reportType, versionId) =>
    (dispatch, getState, { snackbar }) => {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    const generatedReport = generateRMPReportMockUp();
      const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.PrintManager.Snackbar.GeneratingLabel" defaultMessage="...generating report" />
    );

      handlePost(`/report-react/generateReport/${generatedReport.uuid}/projectPlanning`, {
        ...reportMockUp,
        versionId,

        reportNum: reportType,
        scope: 'all',
        locale,
        reportNumber: reportType
      })
      //    generateReportAPI(viewId, project.versionId, generatedReport.uuid, reportType, 'all')
        .then((callback) => {
          checkReportStatus(callback, snackbar, generatingSnackbar, generatedReport, 'pdf');
      })

        .catch((error) => {
          showSnackbarError(snackbar, error);
        });
    };

export default function useDemandProjectReports({ selectedNode, selectedValues, catalog, id }) {
  const getReportCaption = () => {
    switch (id) {
      case DEMAND_PROJECT_REPORT_TYPES.TREE:
        return <FormattedMessage id="demandproject.TREE_TYPE" defaultMessage="Tree report" />;
      case DEMAND_PROJECT_REPORT_TYPES.DETAILS:
        return <FormattedMessage id="demandproject.DETAILS_TYPE" defaultMessage="Details project report" />;
      case DEMAND_PROJECT_REPORT_TYPES.REMAINING_THINGS:
        return <FormattedMessage id="demandproject.REMAINING_THINGS_TYPE" defaultMessage="REMAINING THINGS" />;
      case DEMAND_PROJECT_REPORT_TYPES.CATALOG:
        return <FormattedMessage id="demandproject.CATALOG" defaultMessage="Demand project" />;

      default:
        return <FormattedMessage id="demandproject.TREE_TYPE" defaultMessage="Tree report" />;
    }
  };
  const caption = getReportCaption();

  const getKey = () => {
    switch (id) {
      case DEMAND_PROJECT_REPORT_TYPES.TREE:
        return 'tree-report';
      case DEMAND_PROJECT_REPORT_TYPES.DETAILS:
        return 'details-report';
      case DEMAND_PROJECT_REPORT_TYPES.REMAINING_THINGS:
        return 'projects-remaining-things-report';
      case DEMAND_PROJECT_REPORT_TYPES.CATALOG:
        return 'projects-catalog-report';

      default:
        return 'tree-report';
    }
  };

  const index = 'project';
  if (id === DEMAND_PROJECT_REPORT_TYPES.CATALOG) {
    return {
      index, caption, key: getKey(), noPrint:true,
      fixedComponents: [
        <Typography variant="body1" gutterBottom>
          <FormattedMessage
            id={"report.catalog.demandProjectInfo"}
            defaultMessage={"Demand project reports can only be printed from" +
              " the demand project in question. "}
          />
          <Link href={'/rmp-overview'} >
            <FormattedMessage
              id={"report.catalog.demandProjectLink"}
              defaultMessage={"Press here to go to the demand project view."}
            />
          </Link>
        </Typography>]
    };
  }
  const dispatch = useDispatch();

  const radiobtns = generalRadioBtns;
  // eslint-disable-next-line no-use-before-define
  const dateSelectors = [];
  const selectors = [];
  // eslint-disable-next-line no-use-before-define
  const checkBoxes = generalCheckBoxes;

  const generateReport = (checkedItems) => {
    /* report,
            this.props.catalog ? this.props.catalog.info.versionId : -1,
            this.props.selected ? this.props.selected.split('^')[0] : 'none',
            this.state.checkedItems.get('reports.subnode'),
            this.state.checkedItems.get('cb_separateImages'),
            this.state.checkedItems.get('cb_include_archived'),
            0,
            !this.props.selectedValues ? [] : this.props.selectedValues */
    let selectedIds = [];
    if (selectedValues) {
      selectedIds = selectedValues.map((value) => value.versionId);
    }

    dispatch(generatePrintReport(id, selectedIds[0]));
  };

  return { index, generateReport, caption, radiobtns, dateSelectors, selectors, checkBoxes, key: getKey() };
}
