import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import { dataAddFilter } from "../data/dataAddFilter";
import { handleStylesheetParent } from "../utils/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 350,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      cursor: "pointer"
    }
  },
  titleMain: {
    fontSize: "1.5rem",
    fontWeight: 700,
    marginBottom: 10
  },
  title: {
    fontWeight: 700
  },
  wrapperContent: {
    display: "grid",
    gridRowGap: 10
  },
  wrapperInput: {
    "& input": {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 10,
      background: "#ffffff",
      borderRadius: 5
    },
    "& textarea": {
      overflow: "hidden"
    },
    "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  input: {
    margin: 0,
    width: "100%",
    maxWidth: 200
  },

  wrapperButton: {
    marginTop: 15,
    display: "flex",
    justifyContent: "end",
    paddingRight: 20
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

const dataFilter = ["external-image-filter", "internal-image-filter"];

const dataState = {
  selected: dataFilter[0],
  command: "",
  extensions: "",
  target: "",
  dpi: "74",
  cropPdf: "false"
};

const DialogAddFilter = ({ contentFilters, setValues }) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [filter, setFilter] = useState(dataState);

  const handleClose = () => {
    setOpen(false);
  };

  const handlingFilter = (val, name) => {
    if (name === "selected") {
      return setFilter(pre => ({ ...dataState, [name]: val }));
    }
    setFilter(pre => ({ ...pre, [name]: val }));
  };

  const handlingSetting = (e) => {
    const val = e.target.value;
    const name = e.target.name;
    handlingFilter(val, name);
  };

  const saveCondition = () => {
    if (filter.selected === "external-image-filter") {
      const externalFilter = dataAddFilter[0];
      const res = {
        ...externalFilter, settings: {
          ...externalFilter.settings,
          settings: externalFilter.settings.settings.map((el, i) => {
            if (el.property.identifier === "command") {
              return { ...el, value: filter.command };
            }
            if (el.property.identifier === "extensions") {
              return { ...el, value: filter.extensions };
            }
            return el;
          })
        }
      };
      handleStylesheetParent([...contentFilters, res], "contentFilters", setValues);
      return handleClose();
    }
    const internalFilter = dataAddFilter[1];
    const res = {
      ...internalFilter, settings: {
        ...internalFilter.settings,
        settings: internalFilter.settings.settings.map((el, i) => {
            if (el.property.identifier === "target-extension") {
              return { ...el, value: filter.target };
            }
            if (el.property.identifier === "extensions") {
              return { ...el, value: filter.extensions };
            }
            if (el.property.identifier === "dpi") {
              return { ...el, value: filter.dpi };
            }
            if (el.property.identifier === "cropPdf") {
              return { ...el, value: filter.cropPdf };
            }
            return el;
          })
      }
    };
    handleStylesheetParent([...contentFilters, res], "contentFilters", setValues);
    handleClose();
  };


  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.titleMain}>Add Content Filter</span>
      <CloseIcon onClick={handleClose} />
    </div>

    <div className={cl.wrapperContent}>
      <div>
        <div className={cl.title}>Filter:</div>
        <ExpanderItem
          valueProps={filter.selected}
          propsLabel={dataFilter}
          type="selected"
          setProps={handlingFilter}
        />
      </div>
      <div className={cl.title} style={{ marginTop: "15px" }}>Settings:</div>
      {filter.selected === "external-image-filter"
        ? <>
          <div>
            <div className={cl.title}> Command:</div>
            <div className={cl.wrapperInput}>
              <TextField
                value={filter.command}
                name={"command"}
                variant="outlined"
                className={cl.input}
                onChange={handlingSetting}
              />
            </div>
          </div>
          <div>
            <div className={cl.title}>Extensions:</div>
            <div className={cl.wrapperInput}>
              <TextField
                value={filter.extensions}
                name={"extensions"}
                variant="outlined"
                className={cl.input}
                onChange={handlingSetting}
              />
            </div>
          </div>
        </>
        : <>
          <div>
            <div className={cl.title}> Target Extension:</div>
            <div className={cl.wrapperInput}>
              <TextField
                value={filter.target}
                name={"target"}
                variant="outlined"
                className={cl.input}
                onChange={handlingSetting}
              />
            </div>
          </div>
          <div>
            <div className={cl.title}> Extensions:</div>
            <div className={cl.wrapperInput}>
              <TextField
                value={filter.extensions}
                name={"extensions"}
                variant="outlined"
                className={cl.input}
                onChange={handlingSetting}
              />
            </div>
          </div>
          <div>
            <div className={cl.title}> DPI:</div>
            <div className={cl.wrapperInput}>
              <TextField
                value={filter.dpi}
                name={"dpi"}
                variant="outlined"
                className={cl.input}
                onChange={handlingSetting}
              />
            </div>
          </div>
          <div>
            <div className={cl.title}> Crop pdf:</div>
            <div className={cl.wrapperInput}>
              <TextField
                value={filter.cropPdf}
                name={"cropPdf"}
                variant="outlined"
                className={cl.input}
                onChange={handlingSetting}
              />
            </div>
          </div>
        </>
      }

    </div>

    <div className={cl.wrapperButton}>
      <Button className={cl.button} onClick={saveCondition}>
        OK
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>
  </Dialog>);
};

export default DialogAddFilter;