import React, { useContext, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import Tooltip from "@mui/material/Tooltip";
import { handleValue } from "../../../utils/main";
import TextFieldItem from "@zert-packages/components/TextFieldItem";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

const useStyles = makeStyles((theme) => ({
  rootBlock: {
    marginTop: 10,
    display: "grid",
    gridTemplateColumns: "1fr 20px",
    gridColumnGap: 10,
    overflow: "auto",
    height: 203
  },
  wrapperBlock: {
    border: "1px solid #4db1d3",
  },
  wrapperItem: {
    display: "grid",
    gridTemplateColumns: '1fr 3fr',
    cursor: "pointer"
  },
  activeItem: {
    background: "#4db1d3",
    color: '#ffffff',
  },
  wrapperEditor: {
    color: "#4db1d3",
    "& svg": {
      cursor: "pointer"
    }
  },
  border: {
    borderBottom: "1px solid #4db1d3",
  },
  label: {
    padding: 5,
    height: 27,
  },
  textField: {
    width: 'Calc(100% - 10px)'
  },
  activeTextField: {
    border: '1px solid #ffffff'
  },
  containerItem: {
    marginRight: 8,
    marginTop: 5,
    display: 'grid',
    gridRowGap: 5,
  },
  wrapperTextField: {
    display: "grid",
    gridTemplateColumns: '1fr 18px',
    gridColumnGap: 5,
  },
  wrapperImg: {
    cursor: "pointer",
    margin: 'auto',
  },
}));

const dataEditor = [
  {icons: <AddIcon/>, title: 'Add'},
  {icons: <DeleteIcon/>, title: 'Remove',},
  {icons: <SystemUpdateAltIcon />, title: 'Load all metadata'},
]


const SelectedBlockMetaData = ({ browse, setBrowse,  }) => {
  const cl = useStyles();

  const handleSelected = (i) => {
    handleValue(i, 'selectedMetaData', setBrowse);
  }
  const handleValueList = (val , idx) => {
    console.log('1', val , idx);
  }



  return (<div className={cl.rootBlock}>
    <div className={cl.wrapperBlock}>
      <div className={cl.wrapperItem}>
        <div className={`${cl.label} ${cl.border}`}>Name</div>
        <div className={`${cl.label} ${cl.border}`}>Value</div>
      </div>

      <div className={cl.containerItem}>
        {browse.listMetaData.length > 0 && browse.listMetaData.map((el , i) => <div
          key={i}
          className={`${cl.wrapperItem} ${browse.selectedMetaData === i && cl.activeItem}`}
          onClick={() => handleSelected(i)}
        >
          <div className={cl.label}>{el.name}</div>
          <div className={cl.wrapperTextField}>
            <TextFieldItem
              className={`${cl.textField} ${browse.selectedMetaData === i ? cl.activeTextField : ''}`}
              values={el.values}
              autoFocus={browse.selectedMetaData === i}
              setValues={handleValueList}
              name={i}
            />
            <div className={cl.wrapperImg} style={{color:  browse.selectedMetaData === i ? "#ffffff" :"#4db1d3"}}>
              <ModeEditOutlineIcon  />
            </div>
          </div>
        </div>)}
      </div>
    </div>

    <div className={cl.wrapperEditor}>
      {dataEditor.map((el, i) => <Tooltip
        key={i}
        title={el.title}
      >
        {el.icons}
      </Tooltip>)}
    </div>
  </div>);
};

export default SelectedBlockMetaData;