export const findSizeMenu = (width) => {

    if(width <= 800){
        return  -5
    }

    if(width  <= 900 ){
        return  -3
    }

    if(width  <= 987 ){
        return  -2
    }

    if(width < 1000){
        return  100
    }
    return  100
}
