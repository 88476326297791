import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { useViewport } from '@zert-packages/utils/ViewportProvider';
import { uploadImageFile } from '../PhrasePlugin/servicesMiddlewares';
import { addTermImage, addTermSpec } from './API';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:first-child)': {
      marginRight: '1em'
    }
  },
  rootMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *:not(:first-child)': {
      marginTop: '1em'
    }
  },
  buttonGroup: {
    height: '100%',
    maxHeight: '48px'
  }
}));

export default function AddReferenceButtons({ versionId, termId, setTermData, isImage }) {
  const cl = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const CLM = useSelector((state) => state.CLM);
  const [options, setOptions] = useState(null);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const [uuid, setUUID] = useState(null);
  const { newImage, uuidNewImage } = useSelector((state) => state.CLM);

  const { isMobile } = useViewport();

  useEffect(() => {
    if (uuid && uuidNewImage == uuid) {
      setUUID(null);
      if (isImage) {
        try {
          async function anyAddTermImage() {
            const termSpec = await addTermImage(
              versionId,
              termId,
              options[selectedIndex].identifier,
              newImage.imageVersionId
            );
            setTermData(termSpec);
          }

          anyAddTermImage();

          //    setValue('')
        } catch (err) {}
      }
    }
  }, [newImage]);

  const handleClick = async (e) => {
    e.persist();
    if (isImage) {
      const uuid = uuidv4();
      setUUID(uuid);
      dispatch(uploadImageFile(uuid));
    }
    if (!isImage && value != null && value.length > 0) {
      try {
        const termSpec = await addTermSpec(versionId, termId, options[selectedIndex].identifier, value);
        setTermData(termSpec);
        //    setValue('')
      } catch (err) {}
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleChangeTextField = (e) => {
    e.persist();

    setValue(e.target.value);
  };

  useEffect(() => {
    if (CLM && CLM.termReferences) {
      setOptions(CLM.termReferences.filter((ref) => ref != null));
    }
  }, [CLM]);

  console.log('1111',selectedIndex, options, )
  return (
    <>
      {options && (
        <div className={isMobile ? cl.rootMobile : cl.root}>
          {!isImage && (
            <TextField
              id="standard-basic"
              label={<FormattedMessage id="CLM.AddReference.TextInput" defaultMessage="New term specification" />}
              onChange={handleChangeTextField}
              margin="0"
              value={value}
              size="small"
            />
          )}
          {!isImage && (
            <ButtonGroup variant="contained" color="primary" ref={anchorRef} className={cl.buttonGroup}>
              <Button onClick={handleClick}>
                <FormattedMessage
                  id="CLM.AddReference.ButtonPopupAdd"
                  defaultMessage="Add {option}"
                  values={{ option: options.length > 0 ?  options[selectedIndex].label.text : '' }}
                />{' '}
              </Button>
              <Button
                color="primary"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
          )}

          {isImage && (
            <ButtonGroup variant="contained" color="primary" ref={anchorRef} className={cl.buttonGroup}>
              <Button onClick={handleClick}>
                <FormattedMessage
                  id="CLM.AddReference.ButtonPopupUpload"
                  defaultMessage="Upload {option}"
                  values={{ option: options.length > 0 ? options[selectedIndex]?.label?.text : '' }}
                />{' '}
              </Button>
              <Button
                color="primary"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
          )}
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem
                          key={option.identifier}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option.label.text}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
    </>
  );
}
