import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  border: {
    border: '1px solid #C6C6C5',
    height: 285,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  borderBlockCenter: {
    border: '1px solid #C6C6C5',
    width: 180,
    height: 237,
    padding: 10,
    position: 'relative',
    overflow: 'hidden'
  },
  itemLine: {
    background: '#C8C8C8',
    height: 8
  },
  itemLineCenter: {
    background: '#808080',
    height: 8,
    '&:nth-child(1n)': {
      width: '75%'
    },
    '&:nth-child(3n)': {
      width: '57%'
    }
  },
  wrapperBlock: {
    display: 'grid',
    gridRowGap: 10,
    marginBottom: 10
  }
}));

function Preview({ paragraph }) {
  const cl = useStyles();

  return (
    <div className={cl.border}>
      <div className={cl.borderBlockCenter}>
        <div className={cl.wrapperBlock}>
          <div className={cl.itemLine} />
          <div className={cl.itemLine} />
          <div className={cl.itemLine} />
        </div>

        <div
          className={cl.wrapperBlock}
          style={{
            marginTop:
              paragraph.spaceBefore.value && paragraph.spaceBefore.value.value
                ? paragraph.spaceBefore.value.value / 2
                : 10,
            marginBottom:
              paragraph.spaceAfter.value && paragraph.spaceAfter.value.value
                ? paragraph.spaceAfter.value.value / 2
                : 10,
            paddingLeft: paragraph.marginLeft && paragraph.marginLeft.value ? paragraph.marginLeft.value / 2 : '',
            paddingRight: paragraph.marginRight && paragraph.marginRight.value ? paragraph.marginRight.value / 2 : ''
          }}
        >
          <div className={cl.itemLineCenter} />
          <div
            className={cl.itemLineCenter}
            style={{
              width: '90%'
            }}
          />
          <div className={cl.itemLineCenter} />
        </div>

        <div className={cl.wrapperBlock}>
          <div className={cl.itemLine} />
          <div className={cl.itemLine} />
          <div className={cl.itemLine} />
        </div>
      </div>
    </div>
  );
}

export default Preview;
