import React, {useEffect, useState} from 'react';
import {getPlugins} from "@zert-packages/actions/api";
import ListItem from "./ListItem";
import BlockIcon from "@zert-packages/muiadopticons/BlockIcon";
import QrCodeIcon from '@mui/icons-material/QrCode';
import {setCreateNewElementPatel} from "@zert-packages/actions/commonReducer";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";


const CreateList = ({setCreate}) => {
    const [list, setList] = useState([]);
    const  history = useHistory();
    const dispatch = useDispatch();

    const addNewElement = (createType) => {
        // dispatch(setCreateNewElementPatel(createType))
        history.push(`/create-new-${createType}`)
    }


    useEffect(() => {
        const res = getPlugins().filter((el) => el.isCreateNewRoot)
            .sort((a, b) => a.sort - b.sort)
            .reduce((acu, cur) => {
                return [...acu, cur];
            }, [])
        setList(res);
    }, [])


    return (<div>
        {list?.length > 0 && list.map((el, i) => <ListItem
            key={i}
            el={el}
            icon={<BlockIcon
                color={'#fff'}
                svg={el.icon}
                height={20}
                width={20}
                hoverColor="#02A1C8"
            />}
            component={<span>{el.name}</span>}
            onClick={addNewElement}
        />)}

    </div>)
}

export default CreateList
