import React, { useState, useEffect } from 'react';
import { Table, TablePagination } from '@mui/material';
import SmartFilter from '../SmartFilter';
import TableHead from './TableHead';
import TableBody from './TableBody';
import usePagination from './usePagination';
import useTableFilter from './useTableFilter';
import useTableSort from './useTableSort';
import './SearchableTable.css';
import { FormattedMessage } from 'react-intl';

function SearchableTable({ columns, rows, initialSelectedRow, initialPagination }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const handleSelectRow = (selectedRowId) => setSelectedRow(selectedRowId);

  const { currentPage, rowsPerPage, updateCurrentPage, updateRowsPerPage, createPage } =
    usePagination(initialPagination);

  const { filterValue, filteredRows, updateFilterValue } = useTableFilter(rows, columns);
  const { sortValues, sortedRows, updateSortValues } = useTableSort(filteredRows);

  useEffect(() => {
    updateCurrentPage(0);
    rowsPerPage && updateRowsPerPage(rowsPerPage);
    if (initialSelectedRow) {
      const index = sortedRows.findIndex((row) => row.id === initialSelectedRow);
      const page = Math.floor(index / rowsPerPage);
      setSelectedRow(initialSelectedRow);
      if (page > 0) {
        updateCurrentPage(page);
      }
    }
  }, [filterValue, rowsPerPage]);
  return (
    <div className="searchable-table__main-container">
      <SmartFilter value={filterValue} onChange={updateFilterValue} />
      <div className="searchable-table__table-wrapper">
        <Table stickyHeader size="small">
          <colgroup>
            {columns.map((column) => (
              <col key={column.identifier} width={column.width ? column.width : 'auto'} />
            ))}
          </colgroup>
          <TableHead columns={columns} sortValues={sortValues} updateSortValues={updateSortValues} />
          <TableBody
            columns={columns}
            rows={createPage(sortedRows)}
            selectedRow={selectedRow}
            handleSelectRow={handleSelectRow}
          />
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={sortedRows.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={<FormattedMessage id="UserList.rowsperpage" defaultMessage="Rows per page:" />}
        labelDisplayedRows={({ from, to, count }) => {
          return (
            <FormattedMessage
              id="UserList.countoftotal"
              defaultMessage="{from} - {to} of {count}"
              values={{ from, to, count }}
            />
          );
        }}
        page={currentPage}
        backIconButtonProps={{
          'aria-label': 'previous page'
        }}
        nextIconButtonProps={{
          'aria-label': 'next page'
        }}
        sx={{
          ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
            "margin-top": "1em",
            "margin-bottom": "1em"
          },
          ".MuiTablePagination-select": {
            "padding-bottom": 0
          }
        }}
        onPageChange={(event, newCurrentPage) => updateCurrentPage(newCurrentPage)}
        onRowsPerPageChange={(event) => updateRowsPerPage(+event.target.value)}
      />
    </div>
  );
}

export default SearchableTable;
