import {v4 as uuidv4} from "uuid";
import {createArr} from "../ResizedTable";
import {adobeSpanMapNew} from "../../../PhrasePlugin/components/table/helpers/main";

const arrTest = (n, val) => Array.from({length: n}, (value, index) => val ? val : index);

export function isInInterval(start, end, number, ) {

    if (!number) {
        return false
    }

    if (number >= start && number <= end) {
        return true;
    } else {
        return false;
    }
}


export const handleSetData = (data2, setSchema, setTableData, setValues) => {

    const newItem = {
        val: "",
        rowspan: 1,
        colspan: 1,
        posX: 0,
        posY: 0
    }

    const data = data2 ? data2 : {columnCount: 3, rowCount: 3}
    const internalContent = data?.internalContent;
    const internalSpanmap = adobeSpanMapNew(data?.internalSpanmap)


    let arr = []
    for (let i = 0; i < data.rowCount; i++) {
        const newRowVal = arrTest(data.columnCount, {...newItem})
        const newRow = {
            id: uuidv4(),
            val: newRowVal.map((it, ind) => ({...it, posY: ind, posX: i, id: uuidv4()}))
        }
        arr.push(newRow)
    }

    setSchema({columns: data.columnCount, rows: data.rowCount})

    // if(internalSpanmap?.length>0) {
    //     for (let i = 0; i < internalSpanmap?.length; i++) {
    //         const colRow = internalSpanmap[i]
    //         const index = arr[colRow?.TablePositionX].val.findIndex(el => el.posY === colRow?.TablePositionY)
    //
    //         arr[colRow?.TablePositionX].val[index].rowspan = colRow.row
    //         arr[colRow?.TablePositionX].val[index].colspan = colRow.col
    //         arr[colRow?.TablePositionX].val.splice((index || 0) + 1, (colRow.col || 0) - 1)
    //
    //         if (colRow.row > 1) {
    //             console.log('arr',i, arr)
    //             console.log('columnCount', data.columnCount)
    //             console.log('colRow', colRow)
    //             for (let j = 1; j < colRow.row; j++) {
    //                 arr[(colRow?.TablePositionX || 0) + j].val.splice((colRow?.TablePositionY || 0), colRow.col)
    //                 arr[(colRow?.TablePositionX || 0) + j].val.splice((colRow?.TablePositionY || 0), colRow.col)
    //             }
    //         }
    //     }
    // }


    const adobeY = (el) => {
        return el.TablePositionY + el.col - 1
    }

    const adobeX = (el) => {
        return el.TablePositionX + el.row - 1
    }


    if (internalSpanmap?.length > 0) {

        const res = arr.reduce((acu, cur, i) => {
            // const findRowSpan = internalSpanmap.find(el => el.row > 1 && (el.TablePositionX + el.row - 1) === i);
            // const findRowSpan = internalSpanmap.find(el => el.row > 1 && (el.TablePositionX + el.row - 1) === i);
            const findRowSpan = internalSpanmap.find(el =>
                el.row > 1
                && (isInInterval(el.TablePositionX, adobeX(el), i))
            );


            if (internalSpanmap.find(el => el.TablePositionX === i) || findRowSpan) {
                let totalColSpan = 1
                const val = cur.val.reduce((acuCol, curCol, idx) => {
                    const findSpanmapCol = internalSpanmap.find(el => el.TablePositionX === i && el.TablePositionY === idx);


                    const findRowSpanCol = internalSpanmap.find(el =>
                            el.row > 1
                            && (isInInterval(el.TablePositionX, adobeX(el), curCol.posX))
                            && (isInInterval(el.TablePositionY, adobeY(el), curCol.posY) || curCol.posY === el.TablePositionY)
                    );


                    const findCol = () => {
                        if (findRowSpanCol.col > 1 && findRowSpanCol.TablePositionX === curCol.posX) {
                            return false
                        }
                        return true
                    }

                    if (findSpanmapCol) {
                        totalColSpan = findSpanmapCol.col
                        return [...acuCol, {...curCol, colspan: findSpanmapCol.col, rowspan: findSpanmapCol.row}]
                    }

                    if (findRowSpanCol && findCol()) {
                        return acuCol
                    }

                    if (totalColSpan > 1) {
                        totalColSpan = totalColSpan - 1
                        return acuCol
                    }

                    return [...acuCol, curCol]
                }, [])
                return [...acu, {...cur, val}]
            }

            return [...acu, cur]
        }, [])

        arr = res
    }

    if (internalContent && internalContent.length > 0) {
        for (let i = 0; i < internalContent.length; i++) {
            const val = internalContent[i]
            if (arr[val.clientTablePositionRequest.row] && arr[val.clientTablePositionRequest.row]?.val[val.clientTablePositionRequest.column]) {
                arr[val.clientTablePositionRequest.row].val[val.clientTablePositionRequest.column].val = val.clientItem
                arr[val.clientTablePositionRequest.row].val[val.clientTablePositionRequest.column].default = val.clientItem
            }
        }
    }
    // .right
    const cellsBackgroundColors = data?.cellsBackgroundColors
    const rowsBackgroundColors = data?.rowsBackgroundColors

    if (cellsBackgroundColors?.length > 0 || rowsBackgroundColors?.length > 0) {
        const res = arr.reduce((acc, cur, i) => {
            const val = cur.val.reduce((acuCol, curCol, idx) => {
                if (cellsBackgroundColors?.length > 0) {
                    const findCellsColor = cellsBackgroundColors.find(el => el.clientTablePositionRequest.row === i
                        && el.clientTablePositionRequest.column === idx)
                    if (findCellsColor) {
                        return [...acuCol, {...curCol, colorCol: findCellsColor.clientColorRequest}]
                    }
                }
                return [...acuCol, curCol]
            }, [])

            if (rowsBackgroundColors?.length > 0) {
                const findRowColor = rowsBackgroundColors.find(el => el.row === i)
                if (findRowColor) {
                    return [...acc, {...cur, val, colorRow: findRowColor.clientColorRequest}]
                }
            }

            return [...acc, {...cur, val}]
        }, [])
        arr = res
    }
    setTableData(arr)
    setValues(pre => ({...pre, tableDefault: data2}))
}


const newItem = {
    val: "",
    rowspan: 1,
    colspan: 1,
}


export const cellsBackgroundColorsTest = (pre, index) => {
    const rowsBackgroundColors = pre.tableDefault.cellsBackgroundColors
    if (rowsBackgroundColors.length === 0
        || pre.tableDefault.rowCount - 1 === index
        || !rowsBackgroundColors.find(el => el.clientTablePositionRequest.row >= index)) {
        return rowsBackgroundColors
    }

    return rowsBackgroundColors.map(el => {
        if (el.clientTablePositionRequest.row >= index) {
            return {
                ...el, clientTablePositionRequest: {
                    ...el.clientTablePositionRequest,
                    row: el.clientTablePositionRequest.row + 1
                }
            }
        }
        return el
    })
}

const findRowsBackgroundColors = (pre, index, isLastItem) => {
    const rowsBackgroundColors = pre.tableDefault.rowsBackgroundColors

    if (rowsBackgroundColors.length === 0
        // || pre.tableDefault.rowCount === index + 1
        || isLastItem
        || !rowsBackgroundColors.find(el => el.row >= index)) {
        return rowsBackgroundColors
    }

    return rowsBackgroundColors.map(el => {
        if (el.row >= index) {
            return {...el, row: el.row + 1}
        }
        return el
    })
}


export const addRow = (setSchema, schema, setTableData, index, type, setValues, isLastItem, setSelected) => {
    // 'row'
    setSchema(prev => ({...prev, rows: prev.rows + 1}))
    setValues(pre => ({
        ...pre,
        tableDefault: {
            ...pre.tableDefault, rowCount: pre.tableDefault.rowCount + 1,
            rowsBackgroundColors: findRowsBackgroundColors(pre, index, isLastItem),
            cellsBackgroundColors: findCellsBackgroundColors(pre, index, 'row')
        }
    }))
    let columCalc = null
    let stopAddRowSpan = false


    const newRow = (val, i) => {

        if (!val || !val.colspan) {
            const newRowVal = createArr(schema.columns, {...newItem})
            return {
                id: uuidv4(),
                val: newRowVal.map(it => ({...it, id: uuidv4()}))
            }
        }

        const finRowSpan = val.row.reduce((acu, cur) => {
            if (cur.rowspan > 1) {
                return {val: acu.val + cur.colspan, columns: acu.columns + 1}
            }
            return acu
        }, {val: 0, columns: 0})

        if (finRowSpan.columns > 1) {
            const newRowVal = createArr(schema.columns - finRowSpan.val, {...newItem})
            return {
                id: uuidv4(),
                val: newRowVal.map(it => ({...it, id: uuidv4()}))
            }
        }


        if (val.posX === index || !isInInterval(val.posX + 1, val.rowspan, index)) {
            const newRowVal = createArr(schema.columns, {...newItem})
            return {
                id: uuidv4(),
                val: newRowVal.map(it => ({...it, id: uuidv4()}))
            }
        }
        const columns = val.colspan

        const newRowVal = createArr(schema.columns - columns, {...newItem})
        columCalc = schema.columns
        stopAddRowSpan = true
        return {
            id: uuidv4(),
            val: newRowVal.map(it => ({...it, id: uuidv4()}))
        }
    }

    const adobeColum = (row) => {
        if (stopAddRowSpan) {
            return row
        }
        return {
            ...row,
            val: row.val.reduce((acc, cur) => {
                if (cur.rowspan > 1) {
                    return [...acc, {...cur, rowspan: cur.rowspan + 1}]
                }
                return [...acc, cur]
            }, [])
        }
    }


    // .colspan
    const add = (table) => {
        const res = table.reduce((acu, cur, idx) => {
            const val = cur.val.reduce((calcAcu, calcCur) => {
                if (!calcAcu.colspan) {
                    return calcCur
                }
                if (calcCur.rowspan > 1 && calcCur.colspan >= calcAcu.colspan) {
                    return {...calcCur, row: cur.val}
                }
                return calcAcu
            }, {})


            if (val.rowspan > 1) {
                columCalc = val
            }

            if (idx === index) {
                if (type === 'hoverAddTop') {
                    return [...acu, newRow(columCalc, idx), cur]
                }
                return [...acu, cur, newRow(columCalc, idx)]
            }
            return [...acu, adobeColum(cur)]
        }, [])
        console.log('res', addNexPos(res))
        return addNexPos(res)
    }

    setTableData(prev => add(prev));
    setSelected([])
};


export const removeRowGlobal = (id, setSchema, schema, tableData, setTableData, setSelected, setValues) => {


    setValues(pre => ({
        ...pre, tableDefault: ({
            ...pre.tableDefault,
            rowCount: pre.tableDefault.rowCount - 1,
        })
    }))

    let index = 0;
    const row = tableData.find((el, ind) => {
        if (el.id === id) {
            index = ind
            return el
        }
    })

    if (row.val.some(el => el.rowspan > 1)) {
        for (let i = 0; i < row.val.length; i++) {
            if (row.val[i].rowspan > 1) {
                for (let j = 1; j < row.val[i].rowspan; j++) {
                    const newItems = createArr(row.val[i].colspan).map(el => ({...newItem, id: uuidv4(),}))
                    tableData[index + j].val.splice(i, 0, ...newItems)
                }
            }
        }
    }

    if (row.val.length < schema.columns) {
        let rowsLength = 1
        for (let i = index - 1; i >= 0; i--) {
            rowsLength++
            if (tableData[i].val.some(el => el.rowspan >= rowsLength)) {
                tableData[i].val = tableData[i].val.reduce((ac, cur) => {
                    if (cur.rowspan >= rowsLength) {
                        return [...ac, {...cur, rowspan: cur.rowspan - 1}]
                    } else {
                        return [...ac, cur]
                    }
                }, [])
            }
        }
    }

    setSchema(prev => ({
        ...prev,
        rows: prev.rows - 1
    }))
    setSelected([])
    const updatedData = tableData.filter((row, ind) => row.id !== id);
    setTableData(updatedData);
};

const addNexPos = (tableData) => {
    // return table.map((el, i) => ({
    //     ...el,
    //     val: el.val.map((it, idx) => ({...it, posX: i, posY: idx}))
    // }))

    const rows = tableData.length;
    const cols = tableData.reduce((max, row) => Math.max(max, row.val.length), 0);

    let positionMatrix = Array.from({length: rows}, () => Array(cols).fill(null));

    for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
        let cells = tableData[rowIndex].val;

        for (let colIndex = 0, posX = 0; colIndex < cells.length; colIndex++, posX++) {
            while (positionMatrix[rowIndex][posX]) {
                posX++;
            }

            const cell = cells[colIndex];
            const rowspan = cell.rowspan || 1;
            const colspan = cell.colspan || 1;

            for (let i = 0; i < rowspan; i++) {
                for (let j = 0; j < colspan; j++) {
                    positionMatrix[rowIndex + i][posX + j] = true;
                }
            }

            cell.posY = posX
            cell.posX = rowIndex;
        }
    }

    return tableData;
}

const findCellsBackgroundColors = (pre, index, name,) => {
    const cellsBackgroundColors = pre.tableDefault.cellsBackgroundColors
    if (cellsBackgroundColors.length === 0
        || pre.tableDefault.rowCount - 1 === index
        || !cellsBackgroundColors.find(el => el.clientTablePositionRequest[name] >= index)) {
        return cellsBackgroundColors
    }
    return cellsBackgroundColors.map(el => {
        if (el.clientTablePositionRequest[name] >= index) {
            return {
                ...el, clientTablePositionRequest: {
                    ...el.clientTablePositionRequest,
                    [name]: el.clientTablePositionRequest[name] + 1
                }
            }
        }
        return el
    })
}


export const addColumn = (setSchema, setTableData, index, type, setValues, setSelected) => {
    setValues(pre => ({
        ...pre, tableDefault: ({
            ...pre.tableDefault,
            columnCount: pre.tableDefault.columnCount + 1,
            cellsBackgroundColors: findCellsBackgroundColors(pre, index, 'column'),
            columns: [...pre.tableDefault.columns, {width: 0, widthDefined: false}]
        })
    }))


    setSchema(prev => ({
        ...prev,
        columns: prev.columns + 1
    }))
    setSelected([])

    let row = {
        rowspan: 0,
        posY: 0,
    }
    const findSelectedCell = (curCol) => {
        if (row.rowspan > 0) {
            row.rowspan = row.rowspan - 1
            return true
        }
        if (curCol === 1) {
            return false
        }
        return (curCol.colspan - 1) + curCol.posY === index
    }

    const add = (prev) => {
        const res = prev.reduce((acu, cur, i) => {
            const val = cur.val.length === 0
                ? [{...newItem, id: uuidv4()}]
                : cur.val.reduce((acuCol, curCol, idx) => {


                    if (curCol.posY === index || findSelectedCell(curCol)) {
                        if (curCol.rowspan > 1) {
                            row = {
                                rowspan: curCol.rowspan - 1,
                                posY: curCol.posY - 1,
                            }
                        }
                        const newColumn = (index) => {
                            return {
                                ...newItem,
                                id: uuidv4(),
                                posY: index,
                                posX: i,
                            }
                        }

                        if (type === 'columnLeft') {
                            return [...acuCol, newColumn(idx), curCol]
                        }
                        return [...acuCol, curCol, newColumn(idx + 1)]
                    }
                    if (curCol.colspan > 1 && isInInterval(curCol.posY, curCol.posY + curCol.colspan - 1, index)) {
                        return [...acuCol, {...curCol, colspan: curCol.colspan + 1}]
                    }

                    return [...acuCol, curCol]
                }, [])


            return [...acu, {...cur, val}]
        }, [])
        return addNexPos(res)
    }

    setTableData(prev => add(prev))
};

export const removeColumnGlobal = (index, setSchema, schema, tableData, setTableData, setValues, setSelected) => {
    setSchema(prev => ({
        ...prev,
        columns: prev.columns - 1
    }))

    setValues(pre => ({
        ...pre, tableDefault: ({
            ...pre.tableDefault,
            columnCount: pre.tableDefault.columnCount - 1,
            columns: pre.tableDefault.columns.filter((el, i) => index !== i)
        })
    }))


    const updatedData = tableData.reduce((ac, cur, ind) => {

        if (cur.val.some(el => el.colspan > 1)) {

            let check = false
            const val = cur.val.reduce((ac1, cur1) => {
                if (!check && cur1.colspan > 1) {
                    check = true
                    if (cur1?.rowspan > 1) {
                        for (let i = 1; i < cur1?.rowspan; i++)
                            tableData[ind + i].val.splice(index, 0, {...newItem, id: uuidv4(),})
                    }
                    return [...ac1, {...cur1, colspan: cur1.colspan - 1}]
                } else {
                    return [...ac1, cur1]
                }
            }, [])
            return [...ac, {...cur, val: val}]
        } else if (cur.val.some(el => (el.rowspan > 1))) {
            const val = cur.val.reduce((ac1, cur1) => {
                if ((cur1?.rowspan > 1) && (cur1.colspan === 1)) {
                    for (let i = 1; i < cur1?.rowspan; i++) {
                        tableData[ind + i].val.splice(index, 0, {...newItem, id: uuidv4(),})
                    }
                    return ac1
                }
                return [...ac1, cur1]
            }, [])
            return [...ac, {...cur, val: val}]
        } else if (cur.val.length === schema.columns) {
            return [...ac, {...cur, val: cur.val.filter((el, ind) => ind !== index)}]
        } else {
            cur.val.pop()
            return [...ac, cur]
        }
    }, [])
    setSelected([])
    setTableData(updatedData);
};


export const updateCellValueGlobal = (rowId, itId, value, name, setTableData) => {
    setTableData(prev => (
        prev.reduce((ac, cur) => {
            if (cur.id === rowId) {
                const newVal = cur.val.map(it => ({...it, [name]: it.id === itId ? value : it[name]}))

                return [...ac, {...cur, val: newVal}]
            } else {
                return [...ac, cur]
            }
        }, [])
    ))

};

export const handleUnselectCells = (setTableData) => {
    setTableData(prev => [...prev.reduce((ac, cur) => {

        const val = cur.val.reduce((ac1, cur1) => {
            return [...ac1, {...cur1, selected: false}]
        }, [])
        return [...ac, {...cur, val: val}]

    }, [])])
}


export const setRowsKoords = (tableData) => {
    return tableData.reduce((ac, cur, ind) => {
        const divElementLeft = document.getElementById(cur.id);
        const koords = divElementLeft.getBoundingClientRect()

        return [...ac, {koords: koords, id: cur.id, index: ind}]
    }, [])
}