import React, {useEffect, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import {checkKoords, checkKoords2, findMaxKoords} from "../helpers/reusedMethods";




const useStyles = makeStyles((theme) => ({
    root : {
        position: "fixed",
        top:0,
        left:0,
        width: "100vw",
        height:"100vh",
        border: "4px solid #388bff",
        zIndex:"5",
        '& div': {
            position: "fixed",
            zIndex:"-1",
            border: "2px solid #388bff",
            backgroundColor: "rgba(43,132,201,0.2)",
            transition: "0.2s"
        }
    },
    root2 : {
        position: "absolute",
        width: "100%",
        height:"100%",
        border: "4px solid #388bff",
        zIndex:"5",
        '& div': {
            position: "fixed",
            zIndex:"-1",
            border: "2px solid #388bff",
            backgroundColor: "rgba(43,132,201,0.2)",
            transition: "0.2s"
        }
    }
}))

const RectangleComp = ({ cellsKoord,setIsMouseDown,handleStop, recStartOrigin}) =>{


    const [rectKoords, setRectKoords] = useState({top:0, left:0, width:0, height:0})
    const [rectKoordsGlob, setRectKoordsGlob] = useState({top:0, left:0, right:0, bottom:0})
    const[curVal, setCurVal] = useState(null)

    const cl = useStyles()

    const mouseOverTable = (e) =>{
         setCurVal({top:e.clientY , left: e.clientX })
    }

    useEffect(() =>{
        if(curVal && recStartOrigin ) {
            drawRect(cellsKoord, recStartOrigin, curVal)
        }
    },[recStartOrigin,curVal ])

    const drawRect = (cells, startG, curG) =>{
        const start = {...startG}
        const curval = {...curG}

        const selected = cells.reduce((ac, cur) => {

            const isSelected = checkKoords(cur.koords, start, curval)
            return isSelected ? [...ac, cur] : ac
        }, [])
        const top = findMaxKoords(selected, "top", "<")
        const bottom = findMaxKoords(selected, "bottom", ">")
        const right = findMaxKoords(selected, "right", ">")
        const left = findMaxKoords(selected, "left", "<")

        const selectedSec = cells.reduce((ac, cur) => {
            const isSelected = checkKoords2(cur.koords, top?.koords.top, left?.koords.left,
                right?.koords.right, bottom?.koords.bottom)
            return isSelected ? [...ac, cur] : ac
        }, [])

        const top2 = findMaxKoords(selectedSec, "top", "<")
        const bottom2 = findMaxKoords(selectedSec, "bottom", ">")
        const right2 = findMaxKoords(selectedSec, "right", ">")
        const left2 = findMaxKoords(selectedSec, "left", "<")

        const rect = {
            top: top2?.koords.top || 0,
            left: left2?.koords.left || 0,
            width: ((right2?.koords.right || 0) - (left2?.koords.left || 0)) || 0,
            height: ((bottom2?.koords.bottom || 0) - (top2?.koords.top || 0)) || 0,
        }

        const rectGlob = {
            top: top2?.koords.top || 0,
            left: left2?.koords.left || 0,
            right: right2?.koords.right || 0,
            bottom: bottom2?.koords.bottom || 0,
        }

        setRectKoordsGlob(rectGlob)
        setRectKoords(rect)
    }

    const handleStopRender = () =>{
        setIsMouseDown(false)
        handleStop(cellsKoord,  rectKoordsGlob)
    }

    window.onmouseup = () => {
        setIsMouseDown(false)
    }

    return (
        <div className={cl.root}
             onMouseEnter={mouseOverTable}
             onMouseMove={mouseOverTable}
             onMouseUp={handleStopRender}
             // onMouseOut={handleStopRender}
             onMouseLeave={handleStopRender}
        >
            <div style={{
                width:`${rectKoords.width}px`,
                height: `${rectKoords.height}px`,
                top: `${rectKoords.top}px`,
                left:`${rectKoords.left}px`,
            }}
            />
        </div>

    )
}

export default RectangleComp