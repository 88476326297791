import React, { useContext, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import TemplateItemRow from '../../TemplateItemRow';
import { handleSingleLevelObject } from '../../utils/helpers';
import { TabsContext } from '../../Stylesheet';
import { fontData } from '../../data/fontData';
import SelectedColor from '../../SelectedColor';
import DialogColors from '../../dialog/DialogColors';
import TextFieldItem from '../../TextFieldItem';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 10
  },
  wrapperDropMenu: {
    marginBottom: 10
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '130px 1fr',
    gridColumnGap: 10
  },
  title: {
    fontWeight: 700
  },
  wrapperCheckbox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr ',
    maxWidth: 500
  },
  wrapperTemplate: {
    height: 'Calc(100% - 88px)'
  },
  templateInput: {
    height: '100%',
    width: '100%',
    marginBottom: 0,
    marginTop: 0,
    '& .MuiInputBase-multiline.MuiInputBase-marginDense': {
      height: '100%'
    },
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100% !important'
    }
  },
  wrapperCheckboxItem: {
    marginBottom: 0
  }
}));

function TabItem({ references, type }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [selectedFirstMenu, setSelectedFirstMenu] = useState({
    fontSize: 'User defined'
  });

  const findCheckbox = (el) => {
    return references.fontStyle.map((it) => it.toLowerCase()).find((it) => it === el.toLowerCase());
  };

  const handleValuesBlock = (val, name) => {
    const result = {
      ...references.fontSize,
      unit: references.fontSize ? references.fontSize.unit : 'pt',
      value: +val
    };
    handleSingleLevelObject(result, name, type, values, setValues);
  };

  const handleSelectedDropMenu = (valDropMenu, name, val) => {
    const result = { unit: valDropMenu, value: +val };
    handleSingleLevelObject(result, name, type, values, setValues);
  };

  const setDropMenuFirst = (val, name) => {
    if (val === 'Default') {
      const result = { unit: 'pt', value: 12 };
      handleSingleLevelObject(result, name, type, values, setValues);
    }
    setSelectedFirstMenu((pre) => ({ ...pre, [name]: val }));
  };

  const handleCheckboxFontStyle = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    if (val) {
      const result = [...references.fontStyle, name.toUpperCase()];
      return handleSingleLevelObject(result, 'fontStyle', type, values, setValues);
    }
    const result = references.fontStyle.filter((el) => el !== name.toUpperCase());
    handleSingleLevelObject(result, 'fontStyle', type, values, setValues);
  };
  const handleColor = (val, name) => {
    handleSingleLevelObject(val, name, type, values, setValues);
  };
  const handleOpenDialog = (type) => {
    renderInExceptionRoot(DialogColors, {
      color: references[type],
      title: 'Select Color',
      setText: handleColor,
      type
    });
  };
  const handleCheckbox = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    handleSingleLevelObject(val, name, type, values, setValues);
  };

  return (
    <div className={cl.root}>
      <div className={cl.wrapperRow}>
        <span className={cl.title}> Font family:</span>
        <ExpanderItem valueProps={references.fontFamily} type="type" />
      </div>
      <TemplateItemRow
        label="Font size:"
        type="fontSize"
        value={{
          value: references.fontSize ? references.fontSize.value : '12',
          unit: references.fontSize ? references.fontSize.unit : 'POINTS'
        }}
        grid="130px 150px 1fr 55px"
        dropMenuFirst={{
          label: ['Default', 'User defined'],
          selected: selectedFirstMenu.fontSize
        }}
        setDropMenu={handleSelectedDropMenu}
        setValueProps={handleValuesBlock}
        setDropMenuFirst={setDropMenuFirst}
        disabled={selectedFirstMenu.fontSize === 'Default'}
      />
      <div className={cl.wrapperRow}>
        <span className={cl.title}>Font style:</span>
        <div className={cl.wrapperCheckbox}>
          {fontData.map((el, i) => (
            <div key={i}>
              <FormControlLabel
                control={<Checkbox onChange={handleCheckboxFontStyle} checked={findCheckbox(el.name)} name={el.name} />}
                label={<span>{el.name}</span>}
              />
            </div>
          ))}
        </div>
      </div>
      <SelectedColor
        label="Color:"
        type="color"
        setValueProps={handleColor}
        handleOpenDialog={handleOpenDialog}
        style={{ grid: '130px 30px 1fr 25px' }}
        valueProps={references.color}
      />
      <div className={cl.wrapperRow}>
        <span className={cl.title}>Prefix:</span>
        <TextFieldItem values={references.prefix} name="prefix" setProps={handleColor} />
      </div>
      <div className={cl.wrapperRow}>
        <span className={cl.title}>Suffix:</span>
        <TextFieldItem values={references.suffix} name="suffix" setProps={handleColor} />
      </div>

      {references.includeHeadingNumbering !== undefined && (
        <div className={cl.wrapperRow}>
          <div />
          <FormControlLabel
            className={cl.wrapperCheckboxItem}
            control={
              <Checkbox
                onChange={handleCheckbox}
                checked={references.includeHeadingNumbering}
                name="includeHeadingNumbering"
              />
            }
            label={<span>Include heading numbering</span>}
          />
        </div>
      )}

      {references.includeLabelNumbering !== undefined && (
        <div className={cl.wrapperRow}>
          <div />
          <FormControlLabel
            className={cl.wrapperCheckboxItem}
            control={
              <Checkbox
                onChange={handleCheckbox}
                checked={references.includeLabelNumbering}
                name="includeLabelNumbering"
              />
            }
            label={<span>Include label numbering</span>}
          />
        </div>
      )}
    </div>
  );
}

export default TabItem;
