import React, { lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Reviews,
  ViewCompact,
  PestControl,
  Translate,
  Wysiwyg,
  Settings,
  Folder, MoveToInbox
} from '@mui/icons-material';
import { getPluginByMimeType,  } from "@zert-packages/utils/getPluginByMimeType";
import { ROUTE_TYPES } from '@zert-packages/react/ROUTE_TYPES';
import { getPlugins } from '@zert-packages/actions/api';
import getElementByMimeType from '@zert-packages/components/ElementTile/utils/getTileByMimeType';
import SettingsView from './AdministrationPlugin/SettingsView';
import PreviewOverview from './PreviewPlugin/PreviewOverview';
import PhraseView from './PhrasePlugin/PhraseView';
import ImageView from './ImagePlugin/ImageView';
import TableView from './TablePlugin/TableView'
import TranslationJobView from './TranslationJobPlugin/TranslationJobView';
import TermDBView from './TermDBPlugin/TermDBView';
import PartView from './PartPlugin/PartView';
import TranslationJobTile from './TranslationJobPlugin/TranslationJobTile';
import ReviewJobTile from './ReviewJobPlugin/ReviewJobTile';
import SoftwareTextsView from './SoftwareTextPlugin/SoftwareTextsView';
import ImageTile from './ImagePlugin/ImageTile';
import IndexView from './IndexPlugin/IndexView';
import GroupView from './PartPlugin/GroupView';
import BlockView from './BlockPlugin/BlockView';
import LayoutView from './LayoutPlugin/LayoutView';
import FormatTemplatePlugin from './formatTemplatePlugin/FormatTemplatePlugin';
import StylesheetPlugin from './StylesheetPlugin/Stylesheet';
import block from "../image/block.svg";
import table from "../image/table.svg";
import images from "../image/image.svg";
import index from "../image/index.svg";
import part from "../image/spare-parts.svg";
import phrase from "../image/praze.svg"
import MetaDataView from "./MetaData/MetaDataView";
import Table from "./PhrasePlugin/components/table/Table";
import AddView from "./AddElementPlugin/AddView";


const API_ROOT_WAR = process.env.REACT_APP_API_ROOT_WAR;


export const phrasePlugin = {
  icon:  phrase,
  editor: true,
  sort: 0,
  isCreateNew: true,
  isCreateNewRoot: true,
  allowPublishing: true,
  trkey: 'environment.title.phrase',
  defaultMessage: 'Phrase',
  mimeType: 'phrase',
  name: <FormattedMessage id="environment.title.phrase" defaultMessage="Phrase" />,
  newpath: '/phrase_new',
  newpermit: 'zert-phrase/create-phrase',
  readpermit: 'zert-phrase/read-phrase',
  editpermit: 'zert-phrase/edit-phrase',
  openpath: `${API_ROOT_WAR}/phrase/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/create-new-phrase',
      component: lazy(() => import('./AddElementPlugin/AddView')),
      title: <FormattedMessage id="headbar.title.CreateNewPhrase" defaultMessage="Create new phrase" />
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/phrase/:versionId',
      component: PhraseView
    }
  ]
};


export const sparepartsPlugin = {
  icon: Settings,
  sort: 7,
  isCreateNewRoot: true,
  trkey: 'environment.title.part',
  isCreateNew: true,
  allowPublishing: true,
  defaultMessage: 'Part',
  mimeType: 'part',
  name: <FormattedMessage id="environment.title.part" defaultMessage="Part" />,
  newpath: '/part_new',
  newpermit: 'zert-part/create-part',
  readpermit: 'zert-part/read-part',
  editpermit: 'zert-part/edit-part',
  openpath: `${API_ROOT_WAR}/part/:versionId`,

  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/create-new-part',
      component: lazy(() => import('./AddElementPlugin/AddView')),
      title: <FormattedMessage id="headbar.title.part" defaultMessage="Create new Part" />
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/part/:versionId',
      component: PartView
    }
  ]
};

export const blockPlugin = {
  icon: block,
  sort: 1,
  // icon: Settings,
  editor: true,
  trkey: 'environment.title.block',
  isCreateNew: true,
  isCreateNewRoot: true,
  allowPublishing: true,
  defaultMessage: 'Block',
  mimeType: 'block',
  isTask: false,
  name: <FormattedMessage id="environment.title.block" defaultMessage="Block" className="clip" />,
  newpath: '/block_new',
  newpermit: 'zert-block/create-block',
  readpermit: 'zert-block/read-block',
  editpermit: 'zert-block/edit-block',

  openpath: `${API_ROOT_WAR}/block/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/create-new-block',
      component: lazy(() => import('./AddElementPlugin/AddView')),
      title: <FormattedMessage id="headbar.title.CreateNewBlock" defaultMessage="Create new block" />
    },
    {
      type: ROUTE_TYPES.SOFTTEXT,
      path: '/block/:versionId',
      component: BlockView
    },
  ]
};

export const stylesheetPlugin = {
  icon: ViewCompact,
  trkey: 'environment.title.stylesheet',
  isCreateNew: true,
  allowPublishing: false,
  defaultMessage: 'Stylesheet',
  mimeType: 'stylesheet',
  isTask: false,
  name: <FormattedMessage id="environment.title.stylesheet" defaultMessage="Stylesheet" className="clip" />,
  newpath: '/stylesheet_new',
  newpermit: 'zert-stylesheet/create-stylesheet',
  readpermit: 'zert-stylesheet/read-stylesheet',
  editpermit: 'zert-stylesheet/edit-stylesheet',
  openpath: `${API_ROOT_WAR}/stylesheet/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/stylesheet/:versionId',
      component: StylesheetPlugin
    }
  ]
};

export const layoutPlugin = {
  icon: Settings,
  trkey: 'environment.title.layoutTemplate',
  isCreateNew: true,
  allowPublishing: false,
  defaultMessage: 'Layout',
  mimeType: 'report',
  isTask: false,
  name: <FormattedMessage id="environment.title.layout" defaultMessage="Layout" className="clip" />,
  newpath: '/layout_new',
  newpermit: 'zert-layout/create-layout',
  readpermit: 'zert-layout/read-layout',
  editpermit: 'zert-layout/edit-layout',

  openpath: `${API_ROOT_WAR}/layout/:versionId`,

  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/layout/:versionId',
      component: LayoutView
    }
  ]
};




export const formatTemplatePlugin = {
  icon: Settings,
  trkey: 'environment.title.formatTemplate',
  isCreateNew: true,
  allowPublishing: false,
  defaultMessage: 'Format template',
  mimeType: 'format-template',
  isTask: false,
  name: <FormattedMessage id="environment.title.formatTemplate" defaultMessage="Format template" className="clip" />,
  newpath: '/formatTemplate_new',
  newpermit: 'zert-format-template/create-format-template',
  readpermit: 'zert-format-template/read-format-template',
  editpermit: 'zert-format-template/edit-format-template',
  openpath: `${API_ROOT_WAR}/format-template/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/format-template/:versionId',
      component: FormatTemplatePlugin
    }
  ]
};

export const tablePlugin = {
  icon: table,
  editor: true,
  sort: 2,
  isCreateNew: true,
  isCreateNewRoot: true,
  allowPublishing: true,
  trkey: 'environment.title.table',
  defaultMessage: 'Table',
  mimeType: 'table',
  name: <FormattedMessage id="environment.title.table" defaultMessage="Table" />,
  newpath: '/table_new',
  newpermit: 'zert-table/create-table',
  readpermit: 'zert-table/read-table',
  editpermit: 'zert-table/edit-table',
  openpath: `${API_ROOT_WAR}/table/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/create-new-table',
      component: lazy(() => import('./AddElementPlugin/AddView')),
      title: <FormattedMessage id="headbar.title.CreateNewPhrase" defaultMessage="Create new Table" />
    },
    {
      // ROUTE_TYPES.SIMPLE,
      type: ROUTE_TYPES.SOFTTEXT,
      path: '/table/:versionId',
      component: TableView
      // component: Table
    }
  ]
}

export const indexPlugin = {
  icon: index,
  editor: true,
  isCreateNewRoot: true,
  sort: 5,
  trkey: 'environment.title.index',
  defaultMessage: 'Index',
  mimeType: 'index',
  isTask: false,
  name: <FormattedMessage id="environment.title.index" defaultMessage="Index" />,
  newpath: '/index_new',
  newpermit: 'zert-index/create-index',
  readpermit: 'zert-index/read-index',
  editpermit: 'zert-index/edit-index',
  openpath: `${API_ROOT_WAR}/index/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/create-new-index',
      component: lazy(() => import('./AddElementPlugin/AddView')),
      title: <FormattedMessage id="headbar.title.CreateNewIndex" defaultMessage="Create new index" />
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/index/:versionId',
      component: IndexView
    }
  ]
};
export const imagePlugin = {
  icon: images,
  editor: true,
  sort: 4,
  isCreateNew: true,
  isCreateNewRoot: true,
  allowPublishing: true,
  trkey: 'environment.title.image',
  defaultMessage: 'Image',
  mimeType: 'image',
  name: <FormattedMessage id="environment.title.image" defaultMessage="Image" />,
  newpath: '/image_new',
  newpermit: 'zert-image/create-image',
  readpermit: 'zert-part/read-image',
  editpermit: 'zert-riskanalysis/edit-image',
  openpath: `${API_ROOT_WAR}/image/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/create-new-image',
      component: lazy(() => import('./AddElementPlugin/AddView')),
      title: <FormattedMessage id="headbar.title.CreateNewImage" defaultMessage="Create new Image" />
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/image/:versionId',
      component: ImageView
    }
  ]
};

export const sofwtareTextsPlugin = {
  icon: Wysiwyg,
  isCreateNew: true,
  sort: 8,
  isCreateNewRoot: true,
  allowPublishing: true,
  trkey: 'environment.title.softwaretexts',
  defaultMessage: 'Software Texts',
  mimeType: 'softwaretexts',
  name: <FormattedMessage id="environment.title.softwaretexts" defaultMessage="Software Texts" />,
  newpath: '/softwareTexts_new',
  newpermit: 'zert-softwaretexts/create-softwaretexts',
  readpermit: 'zert-softwaretexts/read-softwaretexts',
  editpermit: 'zert-softwaretexts/edit-softwaretexts',
  openpath: `${API_ROOT_WAR}/softwaretexts/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/create-new-softwaretexts',
      component: lazy(() => import('./AddElementPlugin/AddView')),
      title: <FormattedMessage id="headbar.title.CreateNewSoftwareTexts" defaultMessage="Create new software texts" />
    },
    // {
    //   type: ROUTE_TYPES.SIMPLE,
    //   path: '/softwaretexts_new',
    //   component: SoftwareTextsView,
    //   title: <FormattedMessage id="headbar.title.softwaretexts" defaultMessage="Create new Software texts" />
    // },
    {
      type: ROUTE_TYPES.SOFTTEXT,
      path: '/softwaretexts/:versionId',
      component: SoftwareTextsView
    }
  ]
};

export const termDBPlugin = {
  sort: 10,
  icon: PestControl,
  isCreateNew: true,
  isCreateNewRoot: true,
  allowPublishing: true,
  trkey: 'environment.title.termdb',
  defaultMessage: 'TermDB',
  mimeType: 'terminology-database',
  name: <FormattedMessage id="environment.title.termdb" defaultMessage="TermDB" />,
  newpath: '/termdb_new',
  newpermit: 'zert-termdb/create-termdb',
  readpermit: 'zert-termdb/read-termdb',
  editpermit: 'zert-termdb/edit-termdb',
  openpath: `${API_ROOT_WAR}/termDB/:versionId`,

  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/create-new-terminology-database',
      component: lazy(() => import('./AddElementPlugin/AddView')),
      title: <FormattedMessage id="headbar.title.CreateNewTermdb" defaultMessage="Create new termdb" />
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/phrase_new',
      component: TermDBView,
      title: <FormattedMessage id="headbar.title.createnewtermdb" defaultMessage="Create new termdb" />
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/termDB/:versionId',
      component: TermDBView
    }
  ]
};

export const partPlugin = {
  icon: part,
  editor: true,
  sort: 6,
  isCreateNew: true,
  allowPublishing: true,
  trkey: 'environment.title.part_info',
  defaultMessage: 'Part_info',
  mimeType: 'part-info',
  name: <FormattedMessage id="environment.title.part_info" defaultMessage="Part_info" />,
  newpath: '/part_info_new_',
  newpermit: 'zert-part_info/create-part_info',
  readpermit: 'zert-part_info/read-part_info',
  editpermit: 'zert-part_info/edit-part_info',
  openpath: `${API_ROOT_WAR}/part_info/:versionId/:checkout/:loadItems`,

  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/part_info/:versionId/:checkout/:loadItems',
      component: GroupView
    }
  ]
};

export const reviewJobPlugin = {
  icon: Reviews,
  isCreateNew: true,
  trkey: 'environment.title.reviewJob',
  defaultMessage: 'Review Job',
  mimeType: 'review-job',
  isTask: true,
  name: <FormattedMessage id="environment.title.reviewJob" defaultMessage="Review Job" />,
  openpath: `${API_ROOT_WAR}/reviewJob/:versionId`,
  newpermit: 'zert-review-job/create-job',
  readpermit: 'zert-review-job/read-job',
  editpermit: 'zert-review-job/edit-job'
  /* routes: [
     {
       type: ROUTE_TYPES.ELEMENT,
       path: '/reviewJob/:versionId',
       component: TranslationJobView,

     },
   ] */
};
export const translationJobPlugin = {
  icon: Translate,
  trkey: 'environment.title.translationJob',
  defaultMessage: 'Translation Job',
  mimeType: 'translation-job',
  isTask: true,
  name: <FormattedMessage id="environment.title.translationJob" defaultMessage="Translation Job" />,
  openpath: `${API_ROOT_WAR}/translationJob/:versionId`,
  newpermit: 'zert-translation-job/create-translation-job',
  readpermit: 'zert-translation-job/read-translation-job',
  editpermit: 'zert-translation-job/edit-translation-job',
  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/translationJob/:versionId',
      component: TranslationJobView
    }
  ]
};

export const administrationPlugin = {
  icon: Settings,
  trkey: 'environment.title.administration',
  // mimeType: 'administration',
  defaultMessage: 'Administration ',
  name: <FormattedMessage id="environment.title.administration" defaultMessage="Administration" />,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/settings/companylist',
      component: SettingsView,
      title: <FormattedMessage id="headbar.title.companylist" defaultMessage="Company list" />
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/settings/statistics',
      component: SettingsView,
      title: <FormattedMessage id="headbar.title.settings.statistics" defaultMessage="Statistics" />
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/settings/personal',
      component: SettingsView,
      title: <FormattedMessage id="headbar.title.settings.personal" defaultMessage="Personal" />
    },
    {
      type: ROUTE_TYPES.USERS_IN_COMPANY,
      path: '/settings/userlist/:companyid',
      component: SettingsView
    },
    {
      type: ROUTE_TYPES.USER_EDIT_COMPANY,
      path: '/settings/usereditor/:companyid/:userid',
      component: SettingsView
    },
    {
      type: ROUTE_TYPES.COMPANY,
      path: '/settings/companyeditor/:companyid',
      component: SettingsView
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/settings/maillist',
      component: SettingsView,
      title: <FormattedMessage id="headbar.title.maillist" defaultMessage="Mail list" />
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/settings/task-management',
      component: SettingsView,
      title: <FormattedMessage id="headbar.title.taskManagement" defaultMessage="Task Management" />
    }
  ]
};

export const actionAddElementPlugin = {
  defaultMessage: 'Create new',
  trkey: 'environment.title.addElement',
  icon: MoveToInbox,
  name: <FormattedMessage id="environment.title.addElement" defaultMessage="Create new"/>,
  routes: [
    {
      type:  ROUTE_TYPES.SIMPLE,
      path: '/create-new',
      component: lazy(() => import('./AddElementPlugin/AddView')),
      title: <FormattedMessage id="environment.title.addElement" defaultMessage="Create new"/>,
    },
  ]
}

export const maintainPlugin = {
  icon: Folder,
  trkey: 'environment.title.maintain',
  // mimeType: 'Maintain',
  defaultMessage: 'Maintain',
  order: 4,
  name: <FormattedMessage id="environment.title.maintain" defaultMessage="Maintain" />,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/previewList',
      component: PreviewOverview,
      //  isOverview : true,EE
      title: <FormattedMessage id="headbar.title.previewList" defaultMessage="Preview List" />
    },
    {
      type: ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR,
      path: '/publishingList',
      order: 4,
      component: lazy(() => import('./PublishingPlugin/PublishingOverview')),
      isOverview: true,
      title: <FormattedMessage id="headbar.title.publishingList" defaultMessage="Publishing List" />
    }
  ]
};

export const plugins = [
  reviewJobPlugin,
  termDBPlugin,
  partPlugin,
  translationJobPlugin,
  blockPlugin,
  formatTemplatePlugin,
  phrasePlugin,
  tablePlugin,
  imagePlugin,
  indexPlugin,
  administrationPlugin,
  maintainPlugin,
  sofwtareTextsPlugin,
  sparepartsPlugin,
  stylesheetPlugin,
  layoutPlugin,
  actionAddElementPlugin
];

export default function getCLMPlugins() {
  return plugins;
}

export function getAllTaskPluginsAndWildCard() {
  const taskplugins = plugins.filter((plugin) => plugin.isTask);
  const taskDefinitions = taskplugins.map((plugin, index) => {
    return {
      id: index + 1,
      title: {
        text: getPluginByMimeType(plugin.mimeType).name
      },
      mimeType: plugin.mimeType
    };
  });
  taskDefinitions.push({
    id: 0,
    title: {
      text: <FormattedMessage id="environment.title.allmimetypes" defaultMessage="All" />
    },
    mimeType: '*'
  });
  return taskDefinitions;
}
export function getCreateNewPlugins() {
  return getPlugins().filter((plugin) => plugin.isCreateNew);
}

export function getTileElementByMimeType(props) {
  if (props.element.mimeType == 'application/zert-translation-job')
    return <TranslationJobTile {...props} showInTileOverview showInlineOverview />;
  if (props.element.mimeType == 'application/zert-image')
    return <ImageTile {...props} showInTileOverview showInlineOverview />;
  if (props.element.mimeType == 'application/zert-review-job')
    return <ReviewJobTile {...props} showInTileOverview showInlineOverview />;
  return getElementByMimeType(props);
}

export function getPluginTranslatableName(intl, plugin) {
  const resultstep = plugins.find((step) => {
    return plugin.trkey === step.trkey;
  });

  if (resultstep && resultstep.trkey) {
    return intl.formatMessage({
      id: resultstep.trkey,
      defaultMessage: resultstep.defaultMessage
    });
  }

  return '';
}
