import React, { useEffect, useState } from "react";
import { Checkbox, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import DropMenu from "@zert-packages/components/DropMenu";
import InputItem from "../../InputItem";
import settingsData from "../../data/settingsData";
import useStyles from "./stylesSetting";
import { TextFieldWithLanguageSwitchMemo } from "@zert-packages/components/common/TextFieldWithLangaugeSwitch";
import ListItems from "./component/ListItems";

const style = {
  display: "flex",
  flexDirection: "row-reverse"
};

function DialogSettings({ label = null,  selectedSettings, setValues, indexSelected , addSetting,  typeSettings='settings',handleSettingDialog}) {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const settingTypeData = ["Hidden", "Text", "Number", "Choice", "Date"];
  const [settings, setSetting] = useState({
    selectedType: "",
    selected: selectedSettings
  });
  const type = settings.selectedType;
  const selected = settings.selected;
  const classData = "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$";


  const handleSetting = (val, name) => {
    const className = `${classData}${val}`;
    const res = {
      class: className
    };
    if (val === "Choice" && !settings.selected.items ) {
      res.items = [];
    }
    setSetting(prevState => ({
      ...prevState,
      selected: {
        ...prevState.selected,
        ...res
      },
      [name]: val
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleSaveSupportedFormats = () => {
    if(addSetting){
      return setValues(pre => ({
        ...pre, templateList: {
          ...pre.templateList, [typeSettings]:[...pre.templateList[typeSettings], selected]
        }
      }))
    }
    setValues(pre => ({
      ...pre, templateList: {
        ...pre.templateList, [typeSettings]:
         pre.templateList[typeSettings].reduce((acu, cur, idx) => {
            if (idx === indexSelected) {
              return [...acu, selected];
            }
            return [...acu, cur];
          }, [])
      }
    }));
  }


  const handleSave = () => {
    handleClose();
    if(typeSettings === 'supportedFormats'){
      return handleSaveSupportedFormats()
    }
    if(handleSettingDialog){
      return handleSettingDialog(selected)
    }
    if(addSetting){
      return setValues(pre => ({
        ...pre, templateList: {
          ...pre.templateList, [typeSettings]: {
            ...pre.templateList[typeSettings],
            properties: [...pre.templateList[typeSettings].properties, selected]
          }
        }
      }));
    }
    setValues(pre => ({
      ...pre, templateList: {
        ...pre.templateList, [typeSettings]: {
          ...pre.templateList[typeSettings],
          properties: pre.templateList[typeSettings].properties.reduce((acu, cur, idx) => {
            if (idx === indexSelected) {
              return [...acu, selected];
            }
            return [...acu, cur];
          }, [])
        }
      }
    }));
  };

  const handleCheckbox = (e) => {
    const name = e.target.name;
    handleValue(e.target.checked, name);
  };

  const adobeMultiselect = (type) => {
    if (type === true) {
      return "Yes";
    }
    return "No";
  };
  const adobeMultiselectSave = (type) => {
    if (type === "Yes") {
      return true;
    }
    return false;
  };


  const handleMultiselect = (val) => {
    const res = adobeMultiselectSave(val);
    setSetting(pre => ({
      ...pre,
      selected: { ...pre.selected, allowMultiselect: res }
    }));
  };

  const handleValue = (val, name) => {
    setSetting(pre => ({
      ...pre,
      selected: { ...pre.selected, [name]: val }
    }));
  };


  const updateName = (value) => {
    handleValue(value, "labelObject");
  };

  useEffect(() => {
    if (selectedSettings) {
      setSetting(pre => ({...pre, "selectedType": selectedSettings.class.split("$")[1]}))
    }
  }, [selectedSettings]);

  useEffect(() => {
    setSetting(pre => ({
      ...pre,
      selected: { ...pre.selected, ...settingsData[type] }
    }));
  }, [type]);


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paperScrollPaper: cl.root }}
    >
      <div className={cl.wrapperTitle}>
        <span className={cl.title}>{label && label}</span>
        <CloseIcon onClick={handleClose} />
      </div>
      <div className={cl.wrapper}>
        <div className={cl.row}>
          <div className={cl.label}>Type:</div>
          <DropMenu
            selected={settings.selectedType}
            dropItem={settingTypeData}
            setDropItem={handleSetting}
            name={"selectedType"}
          />
        </div>
        <InputItem
          label={"Identifier"}
          classStyle={cl.row}
          valueProps={selected?.identifier}
          setValueProps={handleValue}
          Identifier="identifier"
        />
        {type === "Choice" && <div className={cl.row}>
          <div className={cl.label}>Multi Select:</div>
          <DropMenu
            selected={adobeMultiselect(selected.allowMultiselect)}
            dropItem={["Yes", "No"]}
            setDropItem={handleMultiselect}
            name={"allowMultiselect"}
          />
        </div>}

        {(type === "Text" || type === "Hidden" || type === "Number") && <InputItem
          classStyle={cl.row}
          label={"Value"}
          valueProps={selected?.value}
          setValueProps={handleValue}
          Identifier="value"
        />}
        {type === "Number" && <>
          <InputItem
            classStyle={cl.row}
            label={"Min"}
            valueProps={selected?.min}
            setValueProps={handleValue}
            Identifier="min"
            type={"number"}
          />
          <InputItem
            classStyle={cl.row}
            label={"Max"}
            valueProps={selected?.max}
            setValueProps={handleValue}
            Identifier="max"
            type={"number"}
          />
        </>}
        {(type === "Text" || type === "Number" || type === "Choice" || type === "Date") &&
          <div className={cl.wrapperLanguage}>
            <div className={cl.label}>Label:</div>
            <TextFieldWithLanguageSwitchMemo
              state={selected.labelObject || {
                defaultLabel: "",
                locales: ["en", "sv"],
                translations: ["", ""]
              }}
              disabled={false}
              onChange={updateName}
              style={style}
            />
          </div>}
        {(type === "Text") && <div className={cl.row}>
          <div></div>
          <div className={cl.wrapperCheckbox}>
            <Checkbox
              onChange={(e) => handleCheckbox(e)}
              checked={selected.translatable}
              name="translatable" />
            <div>Translatable</div>
          </div>

        </div>}
        {type === "Choice" && <div className={cl.row}>
          <div className={cl.label}>Items:</div>
          <ListItems selected={selected} setSetting={setSetting} />
        </div>}
        {type === "Date" && <div className={cl.row}>
          <div className={cl.label}>Contains Time:</div>
          <DropMenu
            selected={adobeMultiselect(selected.timeContained)}
            dropItem={["Yes", "No"]}
            setDropItem={handleMultiselect}
            name={"timeContained"}
          />
        </div>}

      </div>
      <div className={cl.wrapperButton}>
        <Button
          disabled={!selected?.identifier || selected?.identifier.length === 0}
          className={cl.button}
          onClick={handleSave}
        >
          OK
        </Button>
        <Button className={cl.button} onClick={handleClose}>
          Cancel
        </Button>
      </div>

    </Dialog>
  );
}

export default DialogSettings;
