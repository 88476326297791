import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TabsContext } from '../../../Stylesheet';
import { adobeDropMenu, handlePageLayout, handlePageLayoutDropMenu, handleTopicDropMenu } from '../../../utils/helpers';
import SpacingTemplate from './SpacingTemplate';
import TemplateItemRow from '../../../TemplateItemRow';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10
  },
  label: {
    fontWeight: 700,
    fontSize: '1rem',
    marginTop: 5
  },
  wrapperIndent: {
    border: '1px solid #C6C6C5',
    display: 'grid',
    padding: '7px 10px',
    gridRowGap: '10px'
  },
  wrapper: {
    border: '1px solid #C6C6C5',
    padding: '7px 10px',
    display: 'grid',
    gridRowGap: 10
  }
}));

function Topic() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const topic = values.stylesheet.blockSettings[values.selectedLeftMenu];

  const handleIndent = (val, name) => {
    handlePageLayout(val, name, values, setValues);
  };

  const handleDropMenu = (valDropMenu, name, val) => {
    handlePageLayout(val, name, values, setValues, valDropMenu);
  };

  const handleBefore = (val, name, valDropMenu) => {
    handleTopicDropMenu(val, name, values, setValues, valDropMenu);
  };

  const handleSpacing = (valDropMenu, name) => {
    handlePageLayoutDropMenu(valDropMenu, name, values, setValues, 'value');
  };

  const handleChildObject = (valDropMenu, name, val) => {
    handlePageLayoutDropMenu(val, name, values, setValues, 'value', valDropMenu);
  };

  return (
    <>
      {topic && topic.class.includes('Topic') && !topic.class.includes('AnchoredTopic') && (
        <div className={cl.root}>
          <div>
            <div className={cl.label}>Spacing</div>
            <div className={cl.wrapper}>
              <SpacingTemplate
                title="Before:"
                type="spaceBefore"
                valueProps={topic.spaceBefore}
                setValueProps={handleSpacing}
                setDropMenu={handleChildObject}
                handleBefore={handleBefore}
              />
              <SpacingTemplate
                title="After:"
                type="spaceAfter"
                valueProps={topic.spaceAfter}
                setValueProps={handleSpacing}
                setDropMenu={handleChildObject}
                handleBefore={handleBefore}
              />
            </div>
          </div>

          <div>
            <div className={cl.label}>Indent</div>
            <div className={cl.wrapperIndent}>
              <TemplateItemRow
                label="Left:"
                type="marginLeft"
                value={{
                  value: topic.marginLeft.value,
                  unit: adobeDropMenu(topic.marginLeft.unit)
                }}
                grid="121px 1fr 55px"
                setDropMenu={handleDropMenu}
                setValueProps={handleIndent}
              />
              <TemplateItemRow
                label="Right:"
                type="marginRight"
                value={{
                  value: topic.marginRight.value,
                  unit: adobeDropMenu(topic.marginRight.unit)
                }}
                grid="121px 1fr 55px"
                setDropMenu={handleDropMenu}
                setValueProps={handleIndent}
              />
            </div>
          </div>
          <div />
        </div>
      )}
    </>
  );
}

export default Topic;
