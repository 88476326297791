import React, {useEffect, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import {Button, Dialog} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {fetchIndex} from "clm/src/plugins/IndexPlugin/servicesMiddlewares";
import {getIndex} from "clm/src/plugins/IndexPlugin/API";
import {getVersion} from "../API";
import TextFieldItem from "../../../TextFieldItem";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 414,
        padding: 11,
        background: "#E2F2F6"
    },
    wrapperTitle: {
        marginBottom: 10,
        display: "flex",
        justifyContent: "space-between",
        textTransform: "uppercase",
        "& svg": {
            cursor: "pointer"
        }
    },
    title: {
        fontSize: "1.8rem",
        fontWeight: 700,
        textTransform: "capitalize"
    },
    label: {
        fontWeight: 700,
        marginBottom: 5,
    },
    wrapperTree: {
        paddingLeft: 5,
        minHeight: 300,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        paddingBottom: 10,
        borderRadius: 5,
    },
    wrapperButton: {
        display: "flex",
        marginTop: 30,
        justifyContent: "end",
        gap: 10
    },
    button: {
        color: "#ffffff",
        background: "#4db1d3",
        minWidth: 80,
        border: "1px solid #4db1d3",
        outline: "none !important",
        cursor: "pointer",
        "&:hover": {
            background: "none",
            color: "#212529",
            border: "1px solid #4db1d3"
        },
        "&:focus": {
            backgroundColor: "#4db1d3"
        }
    }
}));

const LockReferenceDialog = ({values, addReference}) => {
    const cl = useStyles();
    const [open, setOpen] = useState(true);
    const [reference, setReference] = useState({
        comment: '',
        change: false,
    })
    const versionId = values.treeItem.elementInfo.versionId

    // console.log('treeItem', values.treeItem)
    // console.log('value', reference)

    const handleClose = () => {
        setOpen(false)
    };

    const getInfo = async () => {
        const res = await getVersion(versionId)
    }

    const handleReference = (val, name) => {
        setReference(pre => ({...pre, [name]: val,}))
    }

    const handleComment = (val, name) => {
        setReference(pre => ({...pre, [name]: val, change: true}))
    }

    const handleSave = async () => {
        if(reference.change){
            addReference(reference.comment)
        }
        handleClose()
    }

    useEffect(() => {
        if (versionId) {
            getInfo()
        }
    }, [versionId])

    useEffect(() => {
        if (values?.treeItem?.itemComment) {
            handleReference(values.treeItem.itemComment, 'comment')
        }
    }, [values.treeItem]);

    return (<Dialog
            open={open}
            onClose={handleClose}
            classes={{
                paperScrollPaper: cl.root
            }}
        >
            <div className={cl.wrapperTitle}>
                <span className={cl.title}>Lock reference to</span>
                <CloseIcon onClick={handleClose}/>
            </div>

            <div>

            </div>

            <div className={cl.label}>Select the version you want to refer to:</div>
            <div className={cl.wrapperTree}>

            </div>

            <div className={cl.label}>Comment:</div>
            <TextFieldItem
                values={reference.comment}
                name={"comment"}
                setValues={handleComment}
                multiline={true}
                row={3}
            />

            <div className={cl.wrapperButton}>
                <Button className={cl.button} onClick={handleClose}>
                    Cancel
                </Button>
                <Button className={cl.button} onClick={handleSave}>
                    OK
                </Button>
            </div>
        </Dialog>
    )
}

export default LockReferenceDialog
