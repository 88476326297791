import React from 'react';
import { FormattedMessage } from 'react-intl';

export default class CancelButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { x: 0, y: 0 };
  }

  render() {
    return (
      <button
        className="textButtonBlue"
        style={{ flex: 'initial', padding: '1em 2em' }}
        onClick={(e) => {
          window.history.back();
        }}
      >
        <FormattedMessage id="newpage.cancel.button" defaultMessage="Cancel" />
      </button>
    );
  }
}
