import React from 'react'
import {TextField} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {createInProgressSnack} from "@zert-packages/components/common/snackBar";
import {FormattedMessage} from "react-intl";
import {createImage, getDoubles, loadNewImageReference, uploadImage} from "../../../../../PhrasePlugin/API";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import {DoubleDialog} from "../../../../../PhrasePlugin/DoubleDialog";
import {createNewImage} from "../../../../../TranslationJobPlugin/tjReducers";
import {showSnackbarError} from "@zert-packages/components/Snackbars";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {v4 as uuidv4} from "uuid";
import {API_ROOT_CALLS} from "../../../../../ImagePlugin/API";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& input': {
            minHeight: 'fit-content',
        },
    },
    label: {
        fontWeight: '700',
    }
}));

const CreateImage = ({createBlock, setCreateBlock}) => {
    const cl = useStyles();
    const dispatch = useDispatch();
    const snackbar = useSnackbar();
    const uuid = uuidv4();
    const token = localStorage.getItem("accessToken");
    const getImgId =createBlock?.imageRoot?.info.image.info.versionId

    // el.elementInfo.versionId

    const getImg = (val) => {
        setCreateBlock(pre => ({...pre, imageRoot: val}))
    }

    const addImg = (e) =>  {

        const { files } = e.target;
        const file = files[0];
        const fd = new FormData();

        if (!file) return;
        fd.append("file", file, file.name);
        e.target.value = null;
        const generatingSnackbar = createInProgressSnack(
            snackbar,
            <FormattedMessage id="CLM.UploadingFile.Image" defaultMessage="Uploading new image" />
        );

        let value = null
        let right = null
        const dimensions = {}

        const loadImage = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = function(event) {
                    const imgElement = new Image();
                    imgElement.onload = function() {
                        dimensions.width = imgElement.width;
                        dimensions.height = imgElement.height;
                        resolve(imgElement);
                    };
                    imgElement.src = event.target.result;
                };
                reader.readAsDataURL(file);
            }).then();
        };
        loadImage(file).then((imgElement) => {

            uploadImage(fd)
                .then((res) => {
                    // snackbar.closeSnackbar(generatingSnackbar);
                    value = res.left;
                    // showSnackbarChangesSaved(snackbar, <FormattedMessage id="CLM.PrintManager.Snackbar.UploadFiled" defaultMessage="uploaded succesefully"/>);
                    right = value
                    return getDoubles(res.right);
                })
                .then((similar) => {
                    if (similar.length > 0) {
                        renderInExceptionRoot(DoubleDialog, {
                            dimensions: dimensions,
                            list: similar.map(el => ({...el, info: el})),
                            onConfirm: async (info) => {
                                snackbar.closeSnackbar(generatingSnackbar);
                                const reference = await loadNewImageReference(info.info.versionId);
                                const img = { uuid, info: reference}
                                if (getImg) {
                                    return getImg({ ...img, right });
                                }
                                dispatch(createNewImage(img))
                            },
                            onCancel: async () => {
                                const newImage = await createImage(value, file.name, dimensions);
                                const newRef = await loadNewImageReference(newImage.info.versionId);
                                const img = { uuid, info: newRef };
                                snackbar.closeSnackbar(generatingSnackbar);
                                if (getImg) {
                                    return getImg({...img, right })
                                }
                                dispatch(createNewImage(img));
                            }
                        });
                    } else {
                        return createImage(value, file.name, dimensions);
                    }
                })
                .then((info) => {
                    if (info) {
                        return loadNewImageReference(info.versionId || info.info.versionId);
                    }
                })
                .then((info) => {
                    if (info) {
                        snackbar.closeSnackbar(generatingSnackbar);
                        const img = { uuid, info };
                        if (getImg) {
                            return  getImg({ ...img, right });
                        }
                        dispatch(createNewImage(img));
                    }
                })
                .catch((e) => {
                    snackbar.closeSnackbar(generatingSnackbar);
                    showSnackbarError(snackbar, e.message);
                });
        });
    };

    return(<div>
        <div>
            <div className={cl.label}>File:</div>
            <TextField
                type={'file'}
                className={cl.root}
                onChange={addImg}
            />
        </div>
        <div>
            <div className={cl.label}>Preview:</div>
            {getImgId && <img className={cl.img}
                  src={API_ROOT_CALLS + `/image/getImagePreviewById/${getImgId}?jwtToken=${token}`}
                  alt="img"/>}
        </div>
    </div>)
}

export default CreateImage