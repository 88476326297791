import React, { Component } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Chip from '@mui/material/Chip';
import withStyles from '@mui/styles/withStyles';
import { formStyle } from './StylesHelper';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
 /* getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }*/
};
class MultiSelectField2 extends Component {
  constructor(props) {
    super(props);
    const propName = this.props.propName ? this.props.propName : 'name';
    const propId = this.props.propId ? this.props.propId : 'id';
    const { takeValue } = this.props;
    this.state = {
      propName,
      propId,
      selected: this.props.value.map((value) => (takeValue ? value : value[propId]))
    };
    this.handleChange = this.handleChange.bind(this);
    this.findOption = this.findOption.bind(this);
    this.getStyles = this.getStyles.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const newSelected = this.props.value.map((value) => (this.props.takeValue ? value : value[this.state.propId]));
      this.setState({ selected: newSelected });
    }
  }

  handleChange = (event) => {
    this.setState({ selected: event.target.value }, () => {
      if (this.props.onStateChanged) {
        if (this.props.takeValue) {
          this.props.onStateChanged(event.target.value);
        } else {
          const result = this.state.selected.map((value) => {
            return this.findOption(value);
          });
          this.props.onStateChanged(result);
        }
      }
    });
  };

  findOption(value) {
    return this.props.options.find((option) => option[this.state.propId] == value);
  }

  getStyles(option) {
    const { classes } = this.props;
    return this.state.selected.indexOf(option) === -1 ? classes.fontWeightRegular : classes.fontWeightMedium;
  }

  render() {
    //   this.props.handleChange(this.state);
    const { classes, label, options } = this.props;
    return (
      <form style={{ width: '100%' }}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel htmlFor="select-multiple-chip">{label}</InputLabel>
          <Select
            multiple
            value={this.state.selected}
            onChange={this.handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => {
                  if (this.findOption(value)) {
                    return (
                      <Chip key={value} label={this.findOption(value)[this.state.propName]} className={classes.chip} />
                    );
                  }
                })}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {options.map((option) => (
              <MenuItem
                key={option[this.state.propName]}
                value={option[this.state.propId]}
                className={this.getStyles(option)}
              >
                {option[this.state.propName]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>
    );
  }
}

export default withStyles(formStyle)(MultiSelectField2);
