import { addInsertText } from "./converterPhrase";

export const findHeader = (formatTemplate, dataButton, setHeader) => {
  const find = formatTemplate.textFormats.reduce((acu, cur) => {
    const findButton = dataButton.find(el => el === cur.identifier);
    if (findButton) {
      return { ...acu, textFormatButton: [...acu.textFormatButton, cur] };
    }
    return { ...acu, textFormatItem: [...acu.textFormatItem, cur] };
  }, { textFormatButton: [], textFormatItem: [] });
  setHeader(pre => ({
    ...pre,
    textFormatButton: find.textFormatButton.length > 0 ? find.textFormatButton : null,
    textFormatItem: find.textFormatItem.length > 0 ? find.textFormatItem : null
  }));
}

export const addSymbol = (val, values,setValues, EditorStateProps) => {
  const editorState = addInsertText( val, values.editorState, EditorStateProps)
  setValues(pre => ({ ...pre, editorState }));
}