import { useRouteMatch } from 'react-router-dom';

const API_ROOT_WAR = process.env.REACT_APP_API_ROOT_WAR;
const riskAnal = {
  openpath: `${API_ROOT_WAR}/risk-assesment/:versionId`
};

const projectPlugin = {
  openpath: `${API_ROOT_WAR}/project/:versionId`
};

const safetyInspectionPlugin = {
  openpath: `${API_ROOT_WAR}/safetyinspection/:versionId`
};

export default function useShowElementSearcher() {
  const riskAssessment = useRouteMatch(riskAnal.openpath);
  const checklist = useRouteMatch('/checklist/:versionId/:categoryId/:questionId');
  const project = useRouteMatch(projectPlugin.openpath);
  const safetyInspection = useRouteMatch(safetyInspectionPlugin.openpath);
  return riskAssessment || checklist || project || safetyInspection;
}
