import SelectedColor from "../../../SelectedColor";
import React, { useEffect, useState } from "react";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogColors from "../../../dialog/DialogColors";
import { adobeColor, adobeRgba, handleTableParents } from "../../../utils/helpers";


const ColorSelected = ({ table, setTable, type, values, setValues, typeParents, disabled }) => {
  const [color, setColor] = useState({
    color: "#ffffff",
    backgroundColor: "#ffffff",
    load: false
  });


  const handleOpenDialog = (type) => {
    renderInExceptionRoot(DialogColors, {
      color: table[type],
      title: "Select Color",
      setText: handleColor,
      type
    });
  };

  const handleColor = (val, name) => {
    setColor(pre => ({ ...pre, [name] : val}))
    if (setTable) {
      return setTable(adobeRgba(val), name, type, values, setValues);
    }
    handleTableParents(adobeRgba(val), name, typeParents, type, values, setValues);
  };


  useEffect(() => {
    if (!color.load) {
      const res = {};
      if (table.color) {
        res.color = adobeColor(table.color)
      }
      if (table.backgroundColor) {
        res.backgroundColor = adobeColor(table.backgroundColor)
      }
      setColor(pre => ({ ...pre, ...res, load: true }));
    }
  }, [table.color, table.backgroundColor]);



  return (<>
    <SelectedColor
      label="Color:"
      type="color"
      setValueProps={handleColor}
      handleOpenDialog={handleOpenDialog}
      valueProps={color.color}
      disabled={disabled}
    />
    <SelectedColor
      label="Background color:"
      type="backgroundColor"
      setValueProps={handleColor}
      handleOpenDialog={handleOpenDialog}
      valueProps={color.backgroundColor}
      disabled={disabled}
    />
  </>);
};

export default ColorSelected;