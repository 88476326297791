import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { PhraseContext } from "./PhraseEditor";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogAddAlias from "./dialog/DialogHandleImg/DialogAddAlias";
import { addAliasPhrase, editAliasPhrase } from "../utils/main";
import { convertToRaw, EditorState } from "draft-js";


const useStyles = makeStyles((theme) => ({
  aliasText: {
    backgroundColor: '#CCFFCC',
    cursor: 'pointer',
  },
}))

const AliasEditor = (props) => {
  const cl = useStyles();
  const { values, setValues } = useContext(PhraseContext);

  const addAlias = (val) => {
    if(val.trim() === props.decoratedText ){
      return
    }
   editAliasPhrase(props, values, val, setValues)
  }

  const handleLabel = () => {
    renderInExceptionRoot(DialogAddAlias, {
      values,
      addAlias,
      propsText: props,
    })
  }

  return(<span onClick={handleLabel}>
    <span className={cl.aliasText}>{props.children}</span>
  </span>)
}

export default AliasEditor