import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { hasUser } from '@zert-packages/actions/coreReducers';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import { formStyle } from './StylesHelper';

const useStyles = makeStyles(formStyle);

export function EmailField({ values, setValues, required, errors, setErrors, readOnly }) {
  const [emailError, setEmailError] = React.useState(false);

  const emailRegexp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [emailApproved, setEmailApproved] = React.useState(true);
  const [checkingUser, setCheckingUser] = React.useState(false);

  let timer;
  const handleChange = (prop) => (event) => {
    //    setValues({...values, [prop]: event.target.value});
    const expr = emailRegexp.test(event.target.value);
    if (expr) {
      setEmailError(false);
      if (setErrors) {
        setErrors({
          ...errors,
          mail: null
        });
      }
    } else {
      setEmailError(true);
      if (setErrors) {
        setErrors({
          ...errors,
          mail: <FormattedMessage id="settings.newuserdialog.invalidemail" defaultMessage="Invalid email" />
        });
      }
    }
    setValues({
      ...values,
      [prop]: event.target.value
    });
    if (expr) {
      if (timer) {
        clearInterval(timer);
        clearTimeout(timer);
      }
      const { value } = event.target;
      setCheckingUser(true);
      timer = setInterval(() => checkEmail(value), 1000);
    }
  };

  const checkEmail = (email) => {
    clearInterval(timer);

    async function hasUserAsync(email) {
      setCheckingUser(true);
      try {
        const user = await hasUser(email);
        setEmailApproved(user == false || values.initialEmail == email);
        setCheckingUser(false);
        setErrors({
          ...errors,
          mail: null,
          userError:
            user == false || values.initialEmail == email ? null : (
              <FormattedMessage id="settings.newuserdialog.notUnique" defaultMessage="User already exists" />
            )
        });
        // return user;
      } catch (e) {
        setEmailApproved(true);
        setErrors({
          ...errors,
          userError: null
        });
        setCheckingUser(false);
      }
    }

    hasUserAsync(email);
  };

  const classes = useStyles();

  const formAccepted = !checkingUser && !emailError && emailApproved && values.usermail != '';
  return (
    <FormControl
      required
      className={classes.formControl}
      error={emailError || (!emailApproved && values.usermail != '')}
    >
      <InputLabel htmlFor="newuserfield">
        <FormattedMessage id="settings.newuserdialog.email" defaultMessage="User email" />
      </InputLabel>
      <Input
        id="newuserfield"
        value={values.usermail}
        onChange={handleChange('usermail')}
        aria-describedby="newuserfield"
        fullWidth
        readOnly = {readOnly}
        autoComplete="none"
        endAdornment={
          <>
            {!checkingUser && !emailError && emailApproved && values.usermail != '' && (
              <InputAdornment position="end">
                <IconButton size="large">
                  <CircleCheckedFilled style={{ color: 'green' }} />
                </IconButton>
              </InputAdornment>
            )}
            {checkingUser && (
              <InputAdornment position="end">
                <CircularProgress className={classes.progress} />
              </InputAdornment>
            )}
          </>
        }
      />
      {emailError && (
        <FormHelperText id="usermail">
          <FormattedMessage id="settings.newuserdialog.invalidemail" defaultMessage="Invalid email" />
        </FormHelperText>
      )}
      {!checkingUser && !emailError && !emailApproved && values.usermail != '' && (
        <FormHelperText id="emailField">
          <FormattedMessage id="settings.newuserdialog.notUnique" defaultMessage="User already exists" />
        </FormHelperText>
      )}
    </FormControl>
  );
}
