import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import withStyles from '@mui/styles/withStyles';
import { controlStyles } from '@zert-packages/plugins/ExplorerPlugin/withSearch';

class SelectField extends Component {
  state = {
    value: this.props.value,
    dirty: false,
    errors: []
  };

  componentDidUpdate(prevProps) {
    if (this.props.value != null && this.state.value == null) {
      this.setState({ value: this.props.value });
    }
  }

  hasChanged = (value) => {
    // e.preventDefault();

    const { onStateChanged = (f) => f } = this.props;
    // const {  } = this.props;
    this.setState({ value }, () => onStateChanged(this.state));
  };

  render() {
    const { onStateChanged = (f) => f } = this.props;
    const { classes } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.inputLabel} htmlFor="multiselect">
          {this.props.caption}{' '}
        </InputLabel>
        <Select
          className={classes.select}
          value={this.state.value}
          onChange={(e) => {
            this.setState({ value: e.target.value }, () => {
              onStateChanged(this.state);
            });
          }}
          inputProps={{
            name: 'multiselect',
            id: 'multiselect'
          }}
        >
          {this.props.values.map((value) => (
            <MenuItem key={value} value={this.props.valVal ? value[this.props.valVal] : value.value}>
              <em>{this.props.valLabel ? value[this.props.valLabel] : value.label}</em>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

SelectField.propTypes = {
  label: PropTypes.string,//.isRequired,
  fieldId: PropTypes.string.isRequired,
  /*  placeholder: PropTypes.string.isRequired, */
  values: PropTypes.array.isRequired,
  required: PropTypes.bool,
  children: PropTypes.node,
  validator: PropTypes.func,
  hideLabel: PropTypes.bool,
  onStateChanged: PropTypes.func,
  valVal: PropTypes.string,
  valLabel: PropTypes.string
};

export default injectIntl(withStyles(controlStyles)(SelectField));
