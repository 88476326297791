import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getTranslation from '@zert-packages/utils/getTranslation.old';
import buildIsMatched from '../utils/buildIsMatched';
import countSessionsInJSON from '../utils/countSessionsInJSON';
import countParticipantsInJSON from '../utils/countParticipantsInJSON';
import { IntroductionContext } from '../../Introduction';
import countBasis from '../utils/countBasis';

function countQuestions(categories, isMatched, counter) {
  categories.forEach((category) => {
    category.questions.forEach((question) => {
      const text = getTranslation(question.caption);
      if (isMatched(text)) counter[`${category.id},${question.id}`]++;
    });
  });
}

function countRows(rows, isMatched, counter) {
  const isCellMatched = (cell) => isMatched(cell.value) && cell.class.includes('ClientTextCell');
  Object.entries(rows).forEach(([key, value]) => {
    value.forEach((risk) => {
      risk.cells.forEach((cell) => {
        if (isCellMatched(cell)) counter[key]++;
      });
      risk.measures.forEach((measure) => {
        if (isMatched(measure.measure)) counter[key]++;
        measure.cells.forEach((cell) => {
          if (isCellMatched(cell)) counter[key]++;
        });
      });
    });
  });
}

function getQuestionsCounters(categories) {
  const questionsCounters = {};
  categories.forEach((category) => {
    category.questions.forEach((question) => {
      questionsCounters[`${category.id},${question.id}`] = 0;
    });
  });
  return questionsCounters;
}

function useChecklistConfig() {
  const {
    checklist,
    questionairetemplate: questionnaireTemplate,
    storing,
    updating_status
  } = useSelector((state) => state);
  const history = useHistory();
  const { setSearchTab } = useContext(IntroductionContext);

  function countMatchedElementsJSON(searchValue) {
    const counter = {
      ...getQuestionsCounters(questionnaireTemplate.categories),
      description: 0,
      participants: 0,
      sessions: 0,
      basis: 0
    };
    const isMatched = buildIsMatched(searchValue);
    if (isMatched(checklist.description)) counter.description++;
    countQuestions(questionnaireTemplate.categories, isMatched, counter);
    countRows(checklist.rows, isMatched, counter);
    countParticipantsInJSON(checklist.participants, isMatched, counter);
    countSessionsInJSON(checklist.sessions, isMatched, counter);
    countBasis(checklist.basises, isMatched, counter);
    return counter;
  }

  function getQuestionsContainer() {
    if (!checklist) return [];
    if (!questionnaireTemplate) return [];
    const questionsContainer = [];
    questionnaireTemplate.categories.forEach((category) => {
      category.questions.forEach((question) => {
        const pathname = `/checklist/${checklist.info.currentVersionId}/${category.id}/${question.id}`;
        questionsContainer.push({
          disabledScroll: false,
          counterField: `${category.id},${question.id}`,
          id: 'checklist-content',
          startSearching: (callback) => {
            setSearchTab(0);
            history.push({
              pathname
            });
            setTimeout(() => {
              callback();
            }, 500);
          }
        });
      });
    });
    return questionsContainer;
  }

  return {
    readyToSearch: checklist !== null && questionnaireTemplate !== null,
    actionsDisabled: storing || updating_status,
    countMatchedElementsJSON,
    searchSettings: {
      dataset: ['contents']
    },
    containers: [
      ...getQuestionsContainer(),
      {
        counterField: 'description',
        id: 'checklist-group',
        startSearching: (callback) => {
          setSearchTab(1);
          setTimeout(() => {
            callback();
          }, 500);
        }
      },
      {
        counterField: 'participants',
        id: 'checklist-group',
        startSearching: (callback) => {
          setSearchTab(2);
          setTimeout(() => {
            callback();
          }, 500);
        }
      },
      {
        counterField: 'sessions',
        id: 'checklist-group',
        startSearching: (callback) => {
          setSearchTab(3);
          setTimeout(() => {
            callback();
          }, 500);
        }
      },
      {
        counterField: 'basis',
        id: 'checklist-group',
        startSearching: (callback) => {
          setSearchTab(4);
          setTimeout(() => {
            callback();
          }, 500);
        }
      }
    ]
  };
}

export default useChecklistConfig;
