import React, { useContext } from "react";
import { TabsContext } from "../FormatTemplatePlugin";
import { TextFieldWithLanguageSwitchMemo } from "@zert-packages/components/common/TextFieldWithLangaugeSwitch";
import { handleItemLabel } from "../utils/handleInputItem";
import makeStyles from "@mui/styles/makeStyles";

const style = {
  display: "flex",
  flexDirection: "row-reverse"
};

const useStyles = makeStyles((theme) => ({
  wrapperLanguage: {
    display: "grid",
    gridTemplateColumns: "100px 1fr",
    gridColumnGap: 10,
    "& button": {
      padding: 9,
      position: "relative",
      bottom: 4
    },
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginBottom: 10
    }
  },
  title: {
    fontWeight: 900
  }
}))

const WrapperLabelInputItem = ({title='Label:', name ='label', disable}) => {
  const cl = useStyles();
  const { values, setValues } = useContext( TabsContext);

  const updateName = (value) => {
    setValues((prev) => ({
      ...prev,
      templateList: { ...prev.templateList, [name]: value}
    }));
  };



  return(
    <div className={cl.wrapperLanguage}>
      <div className={cl.title}>{title}</div>
    <TextFieldWithLanguageSwitchMemo
      disabled={disable}
      onChange={updateName}
      state={values.templateList[name]
        ? values.templateList[name]
        : {
          defaultLabel: "",
          locales: ["en", "sv"],
          translations: ["", ""]
        }
      }
      style={style}
    />
    </div>
  )
}

export default WrapperLabelInputItem