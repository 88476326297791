import React, { useState, useRef } from 'react';
import ActionableTable from '@zert-packages/components/shared/ActionableTable/ActionTable.new';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import ConfirmationDialog from '@zert-packages/components/common/dialogs/ConfirmationDialog';
import MetadataEditorDialog from './MetadataEditorDialog';
import { findMetaCaptionByIdentifier } from './helpers/findMetaCaptionByIdentifier';
import { findMetaDefinitionByIdentifier } from './helpers/findMetaDefinitionByIdentifier';
import { getLabelByDefinition } from './helpers/getLabelByDefinition';

const tableColumns = [
  {
    identifier: 'identifier',
    label: <FormattedMessage id="MetadataTable.AlternativesTable.Name" defaultMessage="Name" />
  },
  {
    identifier: 'value',
    label: <FormattedMessage id="MetadataTable.AlternativesTable.Value" defaultMessage="Value" />
  }
];

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '10px',
    height: '100%',
    width: '100%'
  },
  dependsContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'auto',
    '& > *': {
      marginTop: 10
    }
  }
});

const defaultSelectedMeta = null;

function MetadataTable({ list, setList }) {
  const cl = useStyles();
  const rootRef = useRef();
  const metadataTemplates = useSelector((state) => state.metadataTemplates);

  const snackbar = useSnackbar();

  const [selectedMeta, setSelectedMeta] = useState(defaultSelectedMeta);

  const updateMeta = (valueCallback) =>
    setList((prev, stateAction) => {
      prev = {
        ...prev,
        Metadata: typeof valueCallback === 'function' ? valueCallback(prev.Metadata) : valueCallback
      };
      if (stateAction) {
        stateAction(prev);
      }
      return prev;
    });

  const addMetadataValue = (filter = null) =>
    renderInExceptionRoot(MetadataEditorDialog, {
      onConfirm: (newMeta) => {
        updateMeta((p) => [
          ...p,
          {
            ...newMeta
          }
        ]);
        setSelectedMeta(selectedMeta + 1);
      }
    });

  const editMetadataValue = () =>
    renderInExceptionRoot(MetadataEditorDialog, {
      metadata: list[selectedMeta],
      onConfirm: (newMeta) => {
        updateMeta((p) => p.map((element, index) => (index == selectedMeta ? newMeta : element)));
      }
    });

  const removeMetadataValue = () =>
    renderInExceptionRoot(ConfirmationDialog, {
      onConfirm: () => {
        updateMeta((p) => p.filter((element, index) => index != selectedMeta));
        // setSelectedMeta(defaultSelectedMeta);
      }
    });

  const actions = [
    {
      icon: AddIcon,
      onClick: addMetadataValue,
      disabled: false
    },
    {
      icon: EditIcon,
      onClick: editMetadataValue,
      disabled: false
    },
    {
      icon: DeleteIcon,
      onClick: removeMetadataValue,
      disabled: false
    }
  ];

  const getRows = () => {
    if (!list || !metadataTemplates) {
      return [];
    }
    return (
      list &&
      list.map((meta, index) => {
        const definition = findMetaDefinitionByIdentifier(meta.identifier, metadataTemplates);
        return {
          id: index,
          identifier: definition && findMetaCaptionByIdentifier(definition.names),
          value: getLabelByDefinition(meta.value, definition)
        };
      })
    );
  };

  const getMetadataById = (identifier) => {
    return list && list.find((meta) => identifier == meta.identifier);
  };

  return (
    <div className={cl.root} ref={rootRef}>
      <ActionableTable
        isTextButtons = {false}
        actions={actions}
        columns={tableColumns}
        rows={getRows()}
        selectedRowId={selectedMeta}
        setSelectedRowId={setSelectedMeta}
      />
    </div>
  );
}

export default MetadataTable;
