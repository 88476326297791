import { FormattedMessage, injectIntl } from 'react-intl';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import useSelectionModel from '@zert-packages/components/EnhancedTable/useSelectionModel';
import { getPluginByMimeType, getPluginByMimeTypeIcon } from '@zert-packages/utils/getPluginByMimeType';
import EnhancedTable from '../EnhancedTable/EnhancedTable';
import { getTileElementStatus, getTileElementStatusMessage, getTileStatus } from './utils/getTileStatus';
import LabelRM from '../shared/Label';
import { useElementSorter } from './utils/useElementSorter';
import { useElementOpen } from './utils/useElementOpen';
import useElementActions from './useElementActions';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '50%',
    display: 'flex',
    overflow: 'auto',
    flexFlow: 'column nowrap'
  },
  rootTab: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '48px 1fr',
    gridGap: 6,
    paddingTop: 2,
    overflow: 'hidden',
    height: '100%',
    width: '100%'
  },
  contentContainer: {
    padding: '10px 15px',
    width: '100%',
    height: '200px',
    overflowY: 'auto',
    overflowX: 'hidden'
  }
});

export const columns = [
  {
    identifier: 'nameNew',
    numeric: false,
    disablePadding: false,
    component: 'th',
    scope: 'row',
    isFilterable: true,
    label: <FormattedMessage id="Element.TileTable.Name" defaultMessage="Name" />
  },
  {
    identifier: 'mimeTypeNew',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="Element.TileTable.Type" defaultMessage="Type" />
  },
  {
    identifier: 'label',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="Element.TileTable.Version" defaultMessage="Version" />
  },
  {
    identifier: 'status',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="Element.TileTable.Status" defaultMessage="Status" />
  },
  {
    identifier: 'id',
    numeric: true,
    disablePadding: false,
    align: 'right',
    isFilterable: true,
    label: <FormattedMessage id="Element.TileTable.Id" defaultMessage="Id" />
  }
];

function ModalElementTileTable({ elements, maxHeight, loading, intl, reloadElement, otherActions }) {
  const myUser = useSelector((state) => state.myuser);

  const { open } = useElementOpen();
  const { getSorter } = useElementSorter(intl);
  const getCustomFilter = (element, name) => {
    switch (name) {
      case 'nameNew':
        return element.name;
      case 'deadline':
        if (element.properties == null) return null;
        return element.properties['zert:CurrentActivityDeadline'];
      case 'mimeTypeNew':
        const plugin = getPluginByMimeType(element.mimeType);
        if (intl && plugin && plugin.trkey) {
          return intl.formatMessage({ id: plugin.trkey, defaultMessage: plugin.defaultMessage });
        }
        return element.mimeType;
      case 'status':
        return getTileElementStatusMessage(element, myUser, intl);
      default:
        return null;
    }
  };

  const columnHandler = () => {
    const _handler = {
      get(target, key) {
        if (key == 'customComparator') {
          return getSorter(target.identifier);
        }
        if (key == 'customClickHandler' && target.identifier == 'nameNew') {
          return (row, column, event) => {
            open(row, column, event);
          };
        }
        if (key == 'customClickHandler' && target.identifier != 'nameNew') {
          return null;
        }
        return target[key];
      }
    };
    return _handler;
  };

  const handler = () => {
    const _handler = {
      get(target, key) {
        if (key == 'nameNew') {
          return (
            <label style={{ display: 'flex', alignItems: 'center', margin: 'unset' }}>
              <div className="icon-box" style={{ padding: '0px 5px 0 0', color: '#1BA1C5' }}>
                {getPluginByMimeTypeIcon(target.mimeType)}
              </div>
              {target.name}
            </label>
          );
        }
        if (key == 'deadline') {
          return target.properties ? target.properties['zert:CurrentActivityDeadline'] : null;
        }
        if (key == 'mimeTypeNew') {
          return getPluginByMimeType(target.mimeType).name;
        }
        if (key == 'status') {
          return (
            <LabelRM text={getTileStatus(target, myUser)} styleName={`${getTileElementStatus(target, myUser)} plain`} />
          );
        }
        if (typeof target[key] === 'object' && target[key] !== null) {
          return new Proxy(target[key], _handler);
        }
        return target[key];
      }
    };
    return _handler;
  };
  const model = elements ? elements.map((element) => new Proxy(element, handler(element))) : [];
  const columnModel = columns ? columns.map((column) => new Proxy(column, columnHandler(column))) : [];
  const selectionModel = useSelectionModel(model, false, 'versionId');

  return (
    <EnhancedTable
      rows={model}
      identifier="versionId"
      columns={columnModel}
      maxHeight={maxHeight}
      actions={useElementActions}
      loading={loading}
      getCustomFilter={getCustomFilter}
      reloadElement={reloadElement}
      showElementActions={false}
      doNotShowSelect
      doNotShowToolbar
      selectionModel={selectionModel}
      otherActions={otherActions}
    />
  );
}

export default injectIntl(
  memo(
    ({ elements, maxHeight, loading, intl, reloadElement, otherActions }) => (
      <ModalElementTileTable
        loading={loading}
        intl={intl}
        otherActions={otherActions}
        reloadElement={reloadElement}
        elements={elements}
        maxHeight={maxHeight}
      />
    ),
    selectiveMemoComparison(
      (p) => p.elements,
      (a) => a.loading,
      (c) => c.maxHeight
    )
  )
);

// export default injectIntl(memo(ElementTileTable));
