import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import React from 'react';

import { v4 as uuidv4 } from 'uuid';
import { generateActionPlanReport } from './projectActions';
import generalRadioBtns from "@zert-packages/components/common/dialogs/ReportDialogs/utils/generalRadioBtns";
import { generalCheckBoxes } from '@zert-packages/components/common/dialogs/ReportDialogs/utils/checkboxes';

export default function useProjectReports({ selectedNode, selectedValues, catalog}) {
  const projectReport = useProjectReporting({ selectedNode, selectedValues, catalog, id: 0 });
  const projectreport1 = useProjectReporting({ selectedNode, selectedValues, catalog, id: 1 });
  const projectReport2 = useProjectReporting({ selectedNode, selectedValues, catalog, id: 2 });
  const projectReport3 = useProjectReporting({ selectedNode, selectedValues, catalog, id: 3 });
  const projectReport4 = useProjectReporting({ selectedNode, selectedValues, catalog, id: 4 });

  return [projectReport, projectreport1, projectReport2, projectReport3, projectReport4]
}

function useProjectReporting({ selectedNode, selectedValues, catalog, id }) {
  const getReportCaption = () => {
    switch (id) {
      case 0:
        return <FormattedMessage id="project.ActivityReport" defaultMessage="Activity report" />;
      case 1:
        return <FormattedMessage id="project.Risk assessment" defaultMessage="Risk assessment report" />;
      case 2:
        return <FormattedMessage id="project.measurements_report" defaultMessage="Measure report" />;
      case 3:
        return <FormattedMessage id="project.TemplateReport" defaultMessage="Action plan report" />;
      case 4:
        return <FormattedMessage id="project.OverviewReport" defaultMessage="Overview Report" />;
      default:
        return <FormattedMessage id="project.ActivityReport" defaultMessage="Activity report" />;
    }
  };
  const caption = getReportCaption();

  const getKey = () => {
    switch (id) {
      case 0:
        return 'project-report';
      case 1:
        return 'project-risk-analysis-report';
      case 2:
        return 'project-measurements-report';
      case 3:
        return 'project-action-plan';
      case 4:
        return 'project-overview-report';

      default:
        return 'project-report';
    }
  };

  const index = 'project';
  const dispatch = useDispatch();

  const radiobtns = generalRadioBtns;
  // eslint-disable-next-line no-use-before-define
  const dateSelectors = [];
  const selectors = [];
  // eslint-disable-next-line no-use-before-define
  const checkBoxes = generalCheckBoxes;

  const generateReport = (checkedItems) => {
    /* report,
            this.props.catalog ? this.props.catalog.info.versionId : -1,
            this.props.selected ? this.props.selected.split('^')[0] : 'none',
            this.state.checkedItems.get('reports.subnode'),
            this.state.checkedItems.get('cb_separateImages'),
            this.state.checkedItems.get('cb_include_archived'),
            0,
            !this.props.selectedValues ? [] : this.props.selectedValues */
    let selectedIds = [];
    if (selectedValues) {
      selectedIds = selectedValues.map((value) => value.versionId);
    }

    switch (id) {
      case 0: {
        dispatch(
          generateActionPlanReport(
            {
              name: caption,
              reportUUID: uuidv4()
            },
            selectedIds[0],
            'projectActivity'
          )
        );
        break;
      }
      case 1: {
        dispatch(
          generateActionPlanReport(
            {
              name: caption,
              reportUUID: uuidv4()
            },
            selectedIds[0],
            'projectRiskAnalysis'
          )
        );
        break;
      }
      case 2: {
        dispatch(
          generateActionPlanReport(
            {
              name: caption,
              reportUUID: uuidv4()
            },
            selectedIds[0],
            'projectMeasurements'
          )
        );
        break;
      }
      case 3: {
        dispatch(
          generateActionPlanReport(
            {
              name: caption,
              reportUUID: uuidv4()
            },
            selectedIds[0],
            'projectActionPlan'
          )
        );
        break;
      }
      case 4: {
        dispatch(
          generateActionPlanReport(
            {
              name: caption,
              reportUUID: uuidv4()
            },
            selectedIds[0],
            'projectReport'
          )
        );
        break;
      }

      default: {
        dispatch(
          generateActionPlanReport(
            {
              name: caption,
              reportUUID: uuidv4()
            },
            selectedIds[0],
            'projectActivity'
          )
        );
        break;
      }
    }
  };

  return { index, generateReport, caption, radiobtns, dateSelectors, selectors, checkBoxes, key: getKey(id) };
}
