import React from 'react';
import { Input, InputAdornment, IconButton, Button, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FormattedMessage } from 'react-intl';

const styles = {
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-end'
  },
  messageContainer: {
    marginTop: '10px',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    // background: 'rgb(242,243,245)',
    padding: '5px'
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    margin: '0'
  }
};

function SmartFilter({ onChange, value, clear, pos, messagePos }) {
  return (
    <>
      <div style={styles.container}>
        <Input
          className={pos}
          onChange={(event) => onChange(event.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" size="large">
                {' '}
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      {value && value.length !== 0 && (
        <div style={styles.messageContainer} className={messagePos}>
          <Typography variant="button" gutterBottom style={styles.message}>
            <FormattedMessage
              id="SmartFilter.SearchIsActive"
              defaultMessage="The search is active, clear the search input to see all settings again."
            />
          </Typography>
          <Button
            color="primary"
            onClick={() => {
              onChange('');
              if (clear) {
                clear();
              }
            }}
          >
            <FormattedMessage id="SmartFilter.ClearSearch" defaultMessage="Clear search" />
          </Button>
        </div>
      )}
    </>
  );
}

export default SmartFilter;
