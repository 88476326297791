import { handlePost, handleQuery } from '@zert-packages/actions/api';

export function loadWorkflowTemplate(versionId) {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return handleQuery(`/actionplan-react/getTaskWorkflowTemplate/${versionId}/${locale}`);
}

export function getTask(versionId) {
  const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  return handleQuery(`/actionplan-react/getTask/${versionId}/${locale}`);
}

export function updateParticipant({ versionId, participant, userName }) {
  return handlePost(`/actionplan-react/updateParticipant/${versionId}`, { userName, participant });
}

export function updateDeadline({ versionId, deadlineName, date }) {
  return handlePost(`/actionplan-react/updateDeadline/${versionId}`, { date, deadlineName });
}
