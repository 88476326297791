import React, { useEffect } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';

import useDimensions from '@zert-packages/utils/useDimensions';
import { startPreviewCatch, stopPreview } from './servicesMiddlewares';
import PreviewTable from './PreviewTable';

function PreviewOverview() {
  const [ref, { x, y, width, height }] = useDimensions({ liveMeasure: true });
  const dispatch = useDispatch();
  const { previewList, loading } = useSelector((state) => state.PREVIEW);
  useEffect(() => {
    dispatch(startPreviewCatch());
    return () => dispatch(stopPreview());
  }, []);
  return (
    <div className="content-cage" ref={ref}>
      <PreviewTable elements={previewList} maxHeight={height} loading={loading} />
    </div>
  );
}

export default PreviewOverview;
