export default function findAllBasis(activity, basises) {
  for (let i = 0; i < activity.activities.length; i += 1) {
    const currentChild = activity.activities[i];
    currentChild.basises.map((basis) => {
      basises.push(basis);
      basis.activityId = currentChild.name;
    });
    // basises = basises.concat(currentChild.basises);
    findAllBasis(currentChild, basises);
    // Search in the current child
  }
  // basises;
}
