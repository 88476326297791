import {createInProgressSnack} from "@zert-packages/components/common/snackBar";
import {FormattedMessage} from "react-intl";
import React from "react";
import {
    createBlockApi,
    createIndexApi,
    createPartApi,
    createPhraseApi,
    createPhraseTable,
    createSoftwareTextsApi,
    createTableApi,
    createTermDbApi,
    storeBlockApi,
    storeIndexApi,
    storePartApi,
    storeSoftwareTextsApi,
    storeTermApi,
    tableStore
} from "@zert-packages/components/InfoElement/components/API";
import {savePhrase} from "@zert-packages/components/InfoElement/helpers/ToolBarAction";
import {v4 as uuidv4} from "uuid";
import {tableData} from "../../../helpers/data/tableData";


export const createPhrase = async (snackbar, mylocale, parentVersionId,) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.CreatePhrase" defaultMessage="Create phrase..."/>
    );
    generatingSnackbar;

    const initPhrase = await createPhraseApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId
        });

    if (!initPhrase || !initPhrase.elementInfo) {
        return snackbar.closeSnackbar(generatingSnackbar);
    }
    const newPhrase = await createPhraseTable(initPhrase.elementInfo.versionId)
    if (!newPhrase) {
        return snackbar.closeSnackbar(generatingSnackbar);
    }

    snackbar.closeSnackbar(generatingSnackbar);
    return newPhrase
}

export const createTypeBlock = async (snackbar, mylocale, parentVersionId, createRoot) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.CreateBlock" defaultMessage="Create block..."/>
    );
    generatingSnackbar;

    const initBlock = await createBlockApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId,
            isTable: !createRoot,
        });

    if (!initBlock || !initBlock.elementInfo) {
        return snackbar.closeSnackbar(generatingSnackbar);
    }

    snackbar.closeSnackbar(generatingSnackbar)
    return initBlock
}

export const createTypeTableItem = async (snackbar, myLocale, parentVersionId, setBlock,) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.CreateTable" defaultMessage="Create Table..."/>
    );
    generatingSnackbar;
    const initTable = await createTableApi(
        {
            locale: myLocale,
            parentVersionId: parentVersionId,
            isTable: true,
        });

    snackbar.closeSnackbar(generatingSnackbar);
    return initTable
    // const newTable = await storeBlockApi(initTable.elementInfo.versionId, myLocale, initTable)
    // console.log('newTable', newTable)
}

export const createIndex = async (snackbar, mylocale, parentVersionId,) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.CreateIndex" defaultMessage="Create Index..."/>
    );
    generatingSnackbar;
    const initIndex = await createIndexApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId
        });
    snackbar.closeSnackbar(generatingSnackbar);
    return initIndex
}

export const createSoftwareTexts = async (snackbar, mylocale, parentVersionId, setBlock) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.softwareText" defaultMessage="Create software text..."/>
    );
    generatingSnackbar
    const res = await createSoftwareTextsApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId
        }
    )
    snackbar.closeSnackbar(generatingSnackbar);
    return res
}


export const createTerminologyDatabase = async (snackbar, mylocale, parentVersionId) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.softwareText" defaultMessage="Create terminology database.."/>
    );
    generatingSnackbar
    const res = await createTermDbApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId
        }
    )
    snackbar.closeSnackbar(generatingSnackbar)
    return res
}

export const createPart = async (snackbar, mylocale, parentVersionId, setBlock) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.softwareText" defaultMessage="Create Part ..."/>
    );
    generatingSnackbar
    const res = await createPartApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId
        }, parentVersionId
    )
    snackbar.closeSnackbar(generatingSnackbar);
    return res
}

export const addNewCellTable = (tableData, it, newValue, setSelected) => {
    const res = tableData.reduce((acc, cur, i) => {
        if (i === it.posX) {
            const val = cur.val.reduce((accCell, curCell, idx) => {
                if (idx === it.posY) {
                    const cell = {...curCell, val: newValue, default: newValue, id: uuidv4()}
                    setSelected([cell])
                    return [...accCell, cell]
                }
                return [...accCell, curCell]
            }, [])
            return [...acc, {...cur, val}]
        }
        return [...acc, cur]
    }, [])
    return res
}

const saveTableNewElement = async (values, selectedItems, it, setValues, setTableData, mylocale, setSelected) => {

    const table = {
        ...values.tableDefault, internalContent: [...values.tableDefault.internalContent,
            {
                clientItem: selectedItems,
                clientTablePositionRequest: {
                    "row": it.posX,
                    "column": it.posY,
                },
            }
        ]
    }
    setValues(pre => ({...pre, tableDefault: table}))
    setTableData(pre => addNewCellTable(pre, it, selectedItems, setSelected))
    const res = await tableStore(table.elementInfo.versionId, mylocale, table,)
    return res
}


const saveIndex = async (values, selectedItems, name, it, setValues, setTableData, mylocale, createRoot, type, history) => {
    const store = await storeIndexApi(selectedItems.elementInfo.versionId)
    if (createRoot) {
        handleRouter(adobeName(store, name), type, history)
    } else {
        await saveTableNewElement(values, adobeName(store, name), it, setValues, setTableData, mylocale)
    }

}


const adobeName = (selectedItems, name) => {
    return {
        ...selectedItems, name, elementInfo: {
            ...selectedItems.elementInfo, name: name,
        }
    }
}


export const handleActionToolBar = async (typeSave, type, snackbar, blockCreate, setBlockCreate, activeLocales, myLanguage,
                                          selectedItems, values, it, setValues, setTableData, img, name, createBlock, setOpenToolbarDialog, setSelected, createRoot, history) => {
    const mylocale = myLanguage.value

    if (typeSave === "Cancel") {
        return setBlockCreate(pre => ({...pre, createType: false,}))
    }

    if (typeSave === "Save") {
        const generatingSnackbar = createInProgressSnack(
            snackbar,
            <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..."/>
        );

        if (type === "zert-phrase") {
            const contentPhrase = blockCreate.phraseEditor.phrase
            const treeItem = {...selectedItems, onlyTableSave: true,}
            await savePhrase({
                ...blockCreate,
                treeItem
            }, activeLocales, mylocale, blockCreate, false, false, false, `${selectedItems.elementInfo.versionId}`, myLanguage)
            if (createRoot) {
                handleRouter(adobeName(selectedItems, contentPhrase), type, history)
            } else {
                await saveTableNewElement(values, adobeName(selectedItems, contentPhrase), it, setValues, setTableData, mylocale, setSelected)
            }
        }
        if (type === "zert-image" && !createRoot) {
            console.log('=================')
            await saveTableNewElement(values, img, it, setValues, setTableData, mylocale, setSelected)
        }
        if (type === "zert-index") {
            await saveIndex(values, selectedItems, name, it, setValues, setTableData, mylocale, createRoot, type, history)
        }

        if (type === "zert-block") {
            const newBlockItem = await storeBlockApi(selectedItems.elementInfo.versionId, mylocale, {
                ...adobeName(selectedItems, name),
                items: []
            })
            console.log('createRoot 111', createRoot)
            if (createRoot) {
                handleRouter(adobeName(newBlockItem, name), type, history)
            } else {
                await saveTableNewElement(values, adobeName(newBlockItem, name), it, setValues, setTableData, mylocale, setSelected)
            }
        }

        if (type === "zert-table") {
            const table = {
                ...createBlock.table,
                elementInfo: {
                    ...createBlock.table.elementInfo,
                    "versionId": selectedItems.elementInfo.versionId,
                    "currentVersionId": selectedItems.elementInfo.versionId
                }
            }
            // const versionId = createRoot ? 1 : table.elementInfo.versionId

            const newTableItem = await tableStore(table.elementInfo.versionId, mylocale, table)
            if (createRoot) {
                handleRouter(newTableItem, type, history)
            } else {
                await saveTableNewElement(values, newTableItem, it, setValues, setTableData, mylocale, setSelected)
            }
        }
        if (type === "zert-softwaretexts") {

            await storeSoftwareTextsApi(adobeName(selectedItems, name), selectedItems.elementInfo.versionId)
            handleRouter(selectedItems, type, history)
        }

        if (type === "zert-part" && createRoot) {
            const res = await storePartApi(adobeName(selectedItems, name), selectedItems.element.versionId)
            handleRouter(res, type, history)
        }
        if (type === "zert-terminology-database") {
            const res = await storeTermApi(adobeName(selectedItems, name), selectedItems.elementInfo.versionId)
            handleRouter(res, type, history)
        }
        snackbar.closeSnackbar(generatingSnackbar);
        if (setOpenToolbarDialog) {
            setOpenToolbarDialog(false)
        }
    }
    return
}

export const handleRouter = (el, type, history) => {
    const id = el.elementInfo.currentVersionId
    let url
    if (type === "zert-phrase") {
        url = `/phrase/${id}`
    }
    if (type === "zert-table") {
        url = `/table/${id}`
    }
    if (type === "zert-softwaretexts") {
        url = `/softwaretexts/${id}`
    }
    if (type === "zert-terminology-database") {
        url = `/termDB/${id}`
    }
    if (type === 'zert-block') {
        url = `/block/${id}`
    }
    if (type === "zert-index") {
        url = `/index/${id}`
    }
    if (type === "zert-part") {
        url = `/part/${id}`
    }
    history.push(url)
}

const typeValidation = {
    "zert-index": true,
    "zert-softwaretexts": true,
    "zert-terminology-database": true,
    "zert-table": true,
    "zert-block": true,
    "zert-part": true,
}

export const voidValidation = (createBlock, type, contentPhrase, name, createRoot, img) => {

    const findNameLength = name.length === 0

    if (createBlock.loadingCreate) {
        return true
    }

    if (type === "zert-phrase" && contentPhrase.length === 0) {
        return true
    }

    if(createRoot){
        if (type === "zert-image"  && (findNameLength || !createBlock.imageRoot)) {
            return true
        }
    } else {
        if(type === "zert-image"  && img?.blockItem?.elementInfo?.name.length === 0){
            return  true
        }
    }

    if(typeValidation[type] && findNameLength){
        return true
    }

    return false
}


export const createInitData = (type, setSelectedItems) => {
   const mimeType = `application/${type}`
    const data = {
        elementInfo: {
            locale: "sv_SE",
            name: "",
            mimeType,
            properties:
                {
                    "zert:Format": "normal",
                    "zert:MimeInheritance": mimeType,
                    "zert-temporary-element": "false"
                }
        }
    }
    if (type === "zert-table") {
        return setSelectedItems(tableData)
    }
    setSelectedItems(data)
}




