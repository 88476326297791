import React, { useState } from 'react';
import './style.css';
import TextField from '@mui/material/TextField';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { formStyle } from '../../shared/FormElements/StylesHelper';

const useStyles = makeStyles(formStyle);
function IntervalEditor({ interval: defaultInterval, onChange, disabled=false }) {
  const [interval, setInterval] = useState(defaultInterval);
  const classes = useStyles();
  const onChangeUnit = (e) => {
    setInterval({
      amount: e.target.value,
      unit: interval.unit
    });
    onChange(interval.unit, e.target.value);
  };

  const onChangeInterval = (e) => {
    setInterval({ amount: interval.amount, unit: e.target.value });
    onChange(e.target.value, interval.amount);
  };

  return (
    <div className="intervalSelector" style={{ background: 'none' }}>
      <TextField
        type="number"
        value={interval.amount}
        label={<FormattedMessage id="IntervalEditor.Amount" defaultMessage="Amount" />}
        InputProps={{ inputProps: { min: 1 } }}
        fullWidth
        disabled={disabled}
        className={classes.formControl}
        variant="outlined"
        onChange={onChangeUnit}
      />
      <FormControl className={classes.formControl} disabled={disabled}>
        <InputLabel htmlFor="multiselect">
          <FormattedMessage id="IntervalEditor.Unit" defaultMessage="Unit" />{' '}
        </InputLabel>
        <Select
          value={interval.unit}
          onChange={onChangeInterval}
          inputProps={{
            name: 'select',
            id: 'select'
          }}
        >
          <MenuItem key="d" value="d">
            <em>
              <FormattedMessage id="interval.days" defaultMessage="Working days" />
            </em>
          </MenuItem>
          <MenuItem key="w" value="w">
            <em>
              <FormattedMessage id="interval.weeks" defaultMessage="Weeks" />
            </em>
          </MenuItem>
          <MenuItem key="M" value="M">
            <em>
              <FormattedMessage id="interval.month" defaultMessage="Month" />
            </em>
          </MenuItem>
          <MenuItem key="y" value="y">
            <em>
              <FormattedMessage id="interval.years" defaultMessage="Year" />
            </em>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default IntervalEditor;
