import React, { useEffect, useState } from 'react';
import { Avatar, CircularProgress, Fade, FormControl, Grid, Input, InputLabel, Paper, Popper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import './Roles.css';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  avatar: {
    width: '25px',
    height: '25px'
  }
});

export default function UserRoleSuggestBox({ initialValue, onSelect, name, label }) {
  const [value, setValue] = useState(initialValue);
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValue(initialValue); // Sync value with initialValue
  }, [initialValue]);

  const handleOpenOptions = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleCloseOptions = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleChange = async (event) => {
    const currentValue = event.target.value;
    setValue(currentValue);
    if (currentValue.length > 3) {
      setIsLoading(true);
      getUsersQuery(currentValue).then((users) => {
        setOptions(users);
        setIsLoading(false);
      });
    }
  };

  const handleSelectUser = (user) => {
    if (user === null) {
      setValue('');
      onSelect(null); // Pass null for the "-" option
    } else {
      setValue(user.fullname);
      onSelect(user);
    }
    setOptions([]);
    handleCloseOptions();
  };

  const handleOnBlur = () => setTimeout(() => handleCloseOptions(), 150);

  // Always include "-" as the first option
  const displayedOptions = [{ id: 'clear', fullname: '-' }, ...options];

  return (
    <FormControl variant="standard" fullWidth onBlur={handleOnBlur}>
      <InputLabel>
        <FormattedMessage id="UserRoleSuggestBox.Label" defaultMessage="Select User" />
      </InputLabel>
      <Input
        onFocus={handleOpenOptions}
        value={value || ''}
        onChange={handleChange}
        endAdornment={isLoading ? <CircularProgress color="inherit" size={20} /> : null}
      />
      <OptionsContainer canShow={isOpen && !isLoading && !!displayedOptions.length} anchorEl={anchorEl}>
        {displayedOptions.map((option) => (
          <Option
            key={option.id}
            option={option}
            classes={classes}
            handleSelectUser={() => handleSelectUser(option.id === 'clear' ? null : option)}
          />
        ))}
      </OptionsContainer>
    </FormControl>
  );
}

function OptionsContainer({ anchorEl, children, canShow }) {
  return (
    <Popper open={canShow} anchorEl={anchorEl} style={{ zIndex: 2000 }}>
      <Fade in={canShow}>
        <Paper
          style={{
            width: anchorEl ? anchorEl.clientWidth : undefined,
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '300px',
            padding: '10px',
            zIndex: 2000
          }}
        >
          {children}
        </Paper>
      </Fade>
    </Popper>
  );
}

function Option({ option, handleSelectUser, classes }) {
  const isClearOption = option.id === 'clear';

  return (
    <div className="UserSuggestBox__Option" onClick={() => handleSelectUser(isClearOption ? null : option)}>
      <Grid container spacing={1}>
        <Grid item>
          <Avatar className={classes.avatar} sizes="20px">
            {isClearOption ? '-' : option.name?.[0] || option.fullname?.[0]}
          </Avatar>
        </Grid>
        <Grid item xs>
          {isClearOption ? '-' : option.name || option.fullname}
        </Grid>
      </Grid>
    </div>
  );
}
