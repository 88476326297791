import React from 'react';
import svLocale from 'date-fns/locale/sv';
import enLocale from 'date-fns/locale/en-US';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { PortalTheme } from '@zert-packages/react/themes/Themes';
import { getStore } from '@zert-packages/actions/api';
import { createRoot } from 'react-dom/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PortalProvider } from '@zert-packages/utils/PortalProvider';
import { LanguageProvider } from './LanguageProvider';
import getLang from './getLang';

export default function renderInExceptionRoot(Component, props = {}) {
  const language = getLang();
  const modalRoot = document.getElementById('exception-root');
  const lastNode = getLastNode(modalRoot);
  const root = createRoot(lastNode);
  const unmountComponent = () => {
    root.unmount();
  };
  root.render(
    <Provider store={getStore()}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={language === 'sv' ? svLocale : enLocale}>
          <PortalProvider>
            <LanguageProvider>
              <SnackbarProvider maxSnack={5}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={PortalTheme()}>
                    <div>
                      <Component unmountComponent={unmountComponent} {...props} />
                    </div>
                  </ThemeProvider>
                </StyledEngineProvider>
              </SnackbarProvider>
            </LanguageProvider>
          </PortalProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </Provider>
  );
  return unmountComponent;
}

function getLastNode(rootNode) {
  if (rootNode.hasChildNodes()) {
    const lastChildOfRootNode = rootNode.lastChild;
    return getLastNode(lastChildOfRootNode);
  }
  return rootNode;
}
