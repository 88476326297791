import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import withSearch, { styles, controlStyles } from '../withSearch';

import SearchComponent from './SearchComponent';

class ExtensionSearchPanel extends SearchComponent {
  constructor(props) {
    super(props);
    if (!this.state || !this.state.extension) {
      this.state = {
        extension: ''
      };
    }
    this.textInput = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (name) => (event) => {
    this.setStatus({ [name]: event.target.value });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    this.props.expanded && this.textInput.current ? this.textInput.current.blur() : this.textInput.current.focus();
  }

  render() {
    //  this.props.handleChange(this.state);
    const { classes } = this.props;
    return (
      <form onSubmit={this.onSubmit}>
        <TextField
          autoFocus
          inputRef={this.textInput}
          id="standard-extension"
          label="Extension"
          size="small"
          className={classes.formControl}
          value={this.state.extension}
          onChange={this.handleChange('extension')}
          type="search"
          fullWidth
          variant="outlined"
        />
      </form>
    );
  }
}

ExtensionSearchPanel.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.extension.header" defaultMessage="Search by extension" />,
  index: 'search-extension',
  className: 'se.zert.backend.search.ExtensionCriteria',

  default: {extension : ""}
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles)(ExtensionSearchPanel), selectData))
);
