import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { adobeWord, handleTabsColumn, handleTabsValue } from '../../utils/helpers';
import { TabsContext } from '../../Stylesheet';
import TemplateItemRow from '../../TemplateItemRow';
import Translatable from './Translatable';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10
  },
  title: {
    fontSize: '1rem',
    fontWeight: '700',
    marginBottom: 0
  },
  borderBlock: {
    border: '1px solid #C6C6C5',
    padding: '5px 10px',
    display: 'grid',
    gridRowGap: 10,
    marginTop: 5
  },
  columnWrapper: {
    display: 'grid',
    gridColumnGap: 5,
    paddingBottom: 5,
    overflowX: 'auto'
  },
  columnItem: {
    minWidth: 92,
    cursor: 'pointer',
    border: '2px solid #C6C6C5',
    padding: 5,
    display: 'flex',
    alignItems: 'center'
  },
  activeColumnItem: {
    minWidth: 92,
    border: '2px solid #4db1d3',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    cursor: 'default'
  },
  wrapperRowTitle: {
    display: 'grid',
    gridTemplateColumns: '110px 1fr',
    gridColumnGap: 10,
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    }
  },
  wrapperIcons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    maxWidth: 100,
    marginLeft: 'auto',
    marginBottom: 10
  },
  rightImg: {
    transform: 'rotate(180deg)'
  }
}));

function Columns({ part }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [selectedColumn, setSelectedColumn] = useState(0);
  const content = ['Position', 'Part number', 'Quantity', 'Page', 'Name', 'Function', 'After sales', 'User defined'];
  const selected = part[selectedColumn];
  const columnLength = part.length;
  const [disabled, setDisabled] = useState({
    width: 'User defined',
    spaceTop: 'User defined',
    fontSize: 'User defined'
  });

  const handleExpander = (val, name) => {
    handleTabsColumn(val, name, 'partSettings', selectedColumn, values, setValues);
  };

  const handleAlign = (val, name) => {
    handleTabsColumn(val.toUpperCase(), name, 'partSettings', selectedColumn, values, setValues);
  };

  const handleCheckbox = (e) => {
    const res = e.target.checked;
    const { name } = e.target;
    handleTabsColumn(res, name, 'partSettings', selectedColumn, values, setValues);
  };

  const findSelectedColum = (type) => {
    if (type === 'right') {
      if (selectedColumn === columnLength - 1 || columnLength === 0) {
        return false;
      }
      return columnLength > 0;
    }
    if (type === 'left') {
      if (selectedColumn === 0 || columnLength === 0) {
        return false;
      }
      return columnLength > 0;
    }
  };

  const handleSelectedRight = () => {
    if (selectedColumn === 0 || columnLength === 0) {
      return;
    }
    setSelectedColumn(selectedColumn - 1);
  };

  const handleSelectedLeft = () => {
    if (selectedColumn === columnLength - 1 || columnLength === 0) {
      return;
    }
    setSelectedColumn(selectedColumn + 1);
  };

  const deleteColumn = () => {
    if (columnLength === 0) {
      return;
    }
    const result = part.filter((el, i) => i !== selectedColumn);
    handleTabsValue(result, 'columns', 'partSettings', values, setValues);
  };

  const addColumn = () => {
    const add = [part[selectedColumn], ...part];
    handleTabsValue(arrayMove(add, 0, selectedColumn), 'columns', 'partSettings', values, setValues);
  };

  function arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  const handleValues = (val, name) => {
    const result = { ...selected[name], unit: selected[name] ? selected[name].unit : 'pt', value: +val };
    handleTabsColumn(result, name, 'partSettings', selectedColumn, values, setValues);
  };

  const handleDropMenu = (valDropMenu, name, val) => {
    const result = { unit: valDropMenu, value: +val };
    handleTabsColumn(result, name, 'partSettings', selectedColumn, values, setValues);
  };

  const setDropMenuFirstInline = (val, name) => {
    if (val === 'Default') {
      const result = { unit: 'pt', value: 12 };
      handleTabsColumn(result, name, 'partSettings', selectedColumn, values, setValues);
    }
    setDisabled((pre) => ({ ...pre, [name]: val }));
  };

  useEffect(() => {
    if (selected) {
      if (!selected.width) {
        setDisabled((pre) => ({ ...pre, width: 'Default' }));
      } else if (disabled.width === 'Default') {
        setDisabled((pre) => ({ ...pre, width: 'User defined' }));
      }

      if (!selected.spaceTop) {
        setDisabled((pre) => ({ ...pre, spaceTop: 'Default' }));
      } else if (disabled.spaceTop === 'Default') {
        setDisabled((pre) => ({ ...pre, spaceTop: 'User defined' }));
      }
      if (!selected.fontSize) {
        setDisabled((pre) => ({ ...pre, fontSize: 'Default' }));
      } else if (disabled.fontSize === 'Default') {
        setDisabled((pre) => ({ ...pre, fontSize: 'User defined' }));
      }
    }
  }, [selectedColumn]);

  return (
    <div className={cl.root}>
      <p className={cl.title}>Columns</p>
      <div className={cl.borderBlock}>
        <div
          className={cl.columnWrapper}
          style={{ gridTemplateColumns: Array.from({ length: part.length }, (_, i) => '1fr').join(' ') }}
        >
          {part.map((el, i) => (
            <div
              key={i}
              onClick={() => setSelectedColumn(i)}
              className={selectedColumn === i ? cl.activeColumnItem : cl.columnItem}
            >
              {el.content.length > 0 ? el.content : 'Position'}
            </div>
          ))}
        </div>

        <div className={cl.wrapperIcons}>
          <ArrowBackIosNewIcon
            style={{
              color: findSelectedColum('left') ? '#4db1d3' : '#C6C6C5',
              cursor: findSelectedColum('left') ? 'pointer' : 'default'
            }}
            onClick={handleSelectedRight}
          />
          <AddIcon
            style={{
              color: columnLength > 0 ? '#4db1d3' : '#C6C6C5',
              cursor: columnLength > 0 ? 'pointer' : 'default'
            }}
            onClick={addColumn}
          />
          <DeleteIcon
            style={{
              color: columnLength !== 0 ? '#4db1d3' : '#C6C6C5',
              cursor: columnLength !== 0 ? 'pointer' : 'default'
            }}
            onClick={deleteColumn}
          />
          <ArrowBackIosNewIcon
            className={cl.rightImg}
            style={{
              color: findSelectedColum('right') ? '#4db1d3' : '#C6C6C5',
              cursor: findSelectedColum('right') ? 'pointer' : 'default'
            }}
            onClick={handleSelectedLeft}
          />
        </div>
        <TemplateItemRow
          label="Width:"
          type="width"
          dropMenuFirst={{
            label: ['Default', 'User defined'],
            selected: disabled.width
          }}
          value={{
            value: selected.width ? selected.width.value : '12',
            unit: selected.width ? selected.width.unit : 'POINTS'
          }}
          grid="110px 150px 1fr 55px"
          setValueProps={handleValues}
          setDropMenuFirst={setDropMenuFirstInline}
          setDropMenu={handleDropMenu}
          disabled={disabled.width === 'Default'}
        />
        <div className={cl.wrapperRowTitle}>
          <p className={cl.title}> Align title:</p>
          <ExpanderItem
            valueProps={selected.titleAlign === 'INHERIT' ? 'Default' : adobeWord(selected.titleAlign, true)}
            propsLabel={['Default', 'Left', 'Right', 'Center']}
            type="titleAlign"
            setProps={handleAlign}
          />
        </div>

        <Translatable selected={selected} selectedColumn={selectedColumn} />

        <div className={cl.wrapperRowTitle}>
          <p className={cl.title}>Align content:</p>
          <ExpanderItem
            valueProps={selected.contentAlign === 'INHERIT' ? 'Default' : adobeWord(selected.contentAlign, true)}
            propsLabel={['Default', 'Left', 'Right', 'Center']}
            type="contentAlign"
            setProps={handleAlign}
          />
        </div>

        <div className={cl.wrapperRowTitle}>
          <p className={cl.title}>Content:</p>
          <div>
            <ExpanderItem
              valueProps={selected.content.length > 0 ? selected.content : 'Position'}
              propsLabel={content}
              type="content"
              setProps={handleExpander}
            />
            <div>
              <FormControlLabel
                control={<Checkbox onChange={handleCheckbox} checked={selected.separated} name="separated" />}
                label="Separated"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox onChange={handleCheckbox} checked={selected.languageSeparated} name="languageSeparated" />
                }
                label="Language separated"
              />
            </div>
            <div>
              <FormControlLabel
                control={<Checkbox onChange={handleCheckbox} checked={selected.startFromLeft} name="startFromLeft" />}
                label="Start from left"
              />
            </div>
          </div>
        </div>

        <TemplateItemRow
          label="Space top:"
          type="spaceTop"
          dropMenuFirst={{
            label: ['Default', 'User defined'],
            selected: disabled.spaceTop
          }}
          value={{
            value: selected.spaceTop ? selected.spaceTop.value : '12',
            unit: selected.spaceTop ? selected.spaceTop.unit : 'POINTS'
          }}
          grid="110px 150px 1fr 55px"
          setValueProps={handleValues}
          setDropMenuFirst={setDropMenuFirstInline}
          setDropMenu={handleDropMenu}
          disabled={disabled.spaceTop === 'Default'}
        />

        <TemplateItemRow
          label="Font size:"
          type="fontSize"
          dropMenuFirst={{
            label: ['Default', 'User defined'],
            selected: disabled.fontSize
          }}
          value={{
            value: selected.fontSize ? selected.fontSize.value : '12',
            unit: selected.fontSize ? selected.fontSize.unit : 'POINTS'
          }}
          grid="110px 150px 1fr 55px"
          setValueProps={handleValues}
          setDropMenuFirst={setDropMenuFirstInline}
          setDropMenu={handleDropMenu}
          disabled={disabled.fontSize === 'Default'}
        />
      </div>
    </div>
  );
}

export default Columns;
