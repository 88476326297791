import React, { useEffect, useState, Fragment } from 'react';
import { Button, Menu, MenuItem, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { setApiMetadata } from '../API';

const useStyles = makeStyles((theme) => ({
  dropMenuButton: {
    width: '100%',
    justifyContent: 'space-between',
    border: '1px solid #DCE3E5',
    marginBottom: 10,
    color: '#8B8B8B',
    fontWeight: '400',
    paddingBottom: 2,
    paddingTop: 2,
    background: '#ffffff',
    paddingRight: 15,
    borderRadius: 0,
    outline: 'none !important',
    '&:hover': {
      background: '#ffffff'
    },
    '& span:nth-child(2)': {
      display: 'none'
    }
  },
  menuLinkSiteWrapper: {
    borderRadius: 0,
    width: 470,
    marginTop: 40,
    border: '1px solid #DCE3E5',
    boxShadow: 'none',
    '@media (max-width: 900px)': {
      marginTop: 45
    },
    maxWidth: 'Calc(100% - 40px)'
  },
  disable: {
    color: '#D3D3D3',
    cursor: 'default'
  },

  buttonSave: {
    width: 45,
    border: '1px solid #D3D3D3',
    display: 'block',
    marginLeft: 'auto',
    marginBottom: 10
  }
}));

function DropMenuEnums({ itemProps, setShowMettaButton, setCellElement, cellElement }) {
  const cl = useStyles();
  const [open, setOpen] = useState(null);
  const [titleDropMenu, setTitleDropMenu] = useState(null);

  const handleopen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCategory = (item, disable) => {
    if (disable) {
      return;
    }
    setTitleDropMenu(item.identifier);
    setOpen(null);
  };

  useEffect(() => {
    if (itemProps && itemProps.enums && itemProps.enums.length > 0) {
      if (itemProps.enums[0].identifier) {
        setTitleDropMenu(itemProps.enums[0].identifier);
      }
    }
  }, [itemProps]);

  const handleSave = () => {
    setShowMettaButton(false);
    const cellElementArray = [{ identifier: itemProps.identifier, value: titleDropMenu }, cellElement].flat();
    setCellElement(cellElementArray);
    setApiMetadata(cellElementArray);
  };

  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleopen} className={cl.dropMenuButton}>
        {titleDropMenu && titleDropMenu} <ArrowDropDownIcon style={{ color: open ? '#8B8B8B' : '#224952' }} />
      </Button>
      <Menu
        anchorEl={open}
        open={Boolean(open)}
        onClose={() => setOpen(null)}
        classes={{
          paper: cl.menuLinkSiteWrapper
        }}
      >
        {itemProps &&
          itemProps.enums &&
          itemProps.enums.length > 0 &&
          itemProps.enums.map((el, i) => (
            <MenuItem
              key={i}
              onClick={() => handleCategory(el, titleDropMenu === el.identifier)}
              className={titleDropMenu === el.identifier ? cl.disable : ''}
            >
              {el.identifier && el.identifier}
            </MenuItem>
          ))}
      </Menu>
      <Button onClick={handleSave} className={cl.buttonSave}>
        OK
      </Button>
    </>
  );
}

export default DropMenuEnums;
