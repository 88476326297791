import React, { memo, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getPluginByMimeType, getPluginByMimeTypeIcon } from '@zert-packages/utils/getPluginByMimeType';
import EnhancedTable from '@zert-packages/components/EnhancedTable/EnhancedTable';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import { useDefaultActions } from '@zert-packages/components/EnhancedTable/helpers/useDefaultActions';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
import { EditAttributes } from '@mui/icons-material';
import useSelectionModel from './utils/useSelectionModel';
import { turnOnCLMLoader } from '../TranslationJobPlugin/tjReducers';
import { fetchPartGroup } from './servicesMiddlewares';
import { AudienceSwitcher } from '../PublishingPlugin/components/AudienceSwitcher';
import { usePublishingSorter } from '../PublishingPlugin/helpers/usePublishingSorter';
import useElementActions from './utils/useElementActions';

const useStyles = makeStyles((theme) => ({
  summary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    margin: '6px',
    '& > *': {
      marginRight: '1em'
    }
  },
  actionsMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '6px',
    '& > *': {
      marginTop: '1em'
    }
  },
  heading: {
    color: '#c46e78'
  }
}));

export const columns = [
  {
    identifier: 'icon',
    numeric: false,
    disablePadding: false,
    component: 'th',
    scope: 'row'
    // isFilterable: true,
    // label: <FormattedMessage id="PublishingTable.TileTable.Icon." defaultMessage=""/>
  },
  {
    identifier: 'position',
    numeric: false,
    disablePadding: false,
    component: 'th',
    scope: 'row',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.Pos." defaultMessage="Pos." />
  },
  {
    identifier: 'partNum',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.Part number" defaultMessage="Part number" />
  },
  {
    identifier: 'name',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.Name" defaultMessage="Name" />
  },
  {
    identifier: 'recommended',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.Recommended" defaultMessage="Recommended" />
  },
  {
    identifier: 'wearAndTear',
    numeric: true,
    disablePadding: false,
    align: 'right',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.Wear and tear" defaultMessage="Wear and tear" />
  },
  {
    identifier: 'quantity',
    numeric: true,
    disablePadding: false,
    align: 'right',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.Quantity" defaultMessage="Quantity" />
  }
];
const PUBLISH_ACTIONS_TYPES = {
  edit: 'edit'
};
export const usePublishingTableActions = ({ workflowTemplate, reloadElement, selected, overviewActions, showCut }) => {
  const EDIT_PUBLISH_ACTIONS = {
    icon: <EditAttributes />,
    id: PUBLISH_ACTIONS_TYPES.edit,
    name: <FormattedMessage id="Publish.Actions.EditPublishing" defaultMessage="Edit Publishing" />
  };

  const dispatch = useDispatch();

  const editPublication = (selected) => {
    // dispatch(editPublishing(selected));
  };

  const getMenuActions = (row) => [EDIT_PUBLISH_ACTIONS];

  const toolbarActions = [...(selected.length === 1 ? [EDIT_PUBLISH_ACTIONS] : [])];

  const elementActions = [...(selected.length === 1 ? [EDIT_PUBLISH_ACTIONS] : [])];

  const performActionHandler = (selected) => (actionId) => {
    if (actionId === PUBLISH_ACTIONS_TYPES.edit) return editPublication(selected[0]);
  };

  return {
    elementActions,
    toolbarActions,
    getMenuActions,
    performActionHandler
  };
};

function PartGroupTable({ elements, maxHeight, loading, intl, selectItem, handleSelectedItem, setDialog }) {
  const cl = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { getSorter } = usePublishingSorter();

  const getCustomFilter = (element, name) => {
    switch (name) {
      case 'name':
        return element.info.name;
      case 'mimeType':
        const plugin = getPluginByMimeType(element.info.mimeType);
        if (intl && plugin && plugin.trkey) {
          return intl.formatMessage({ id: plugin.trkey, defaultMessage: plugin.defaultMessage });
        }
        return element.info.mimeType;
      default:
        return null;
    }
  };

  const columnHandler = () => {
    const _handler = {
      get(target, key) {
        if (key == 'customComparator') {
          return getSorter(target.identifier);
        }
        if (key == 'customClickHandler' && target.identifier != 'nameNew') {
          return null;
        }
        return target[key];
      }
    };
    return _handler;
  };

  const handler = () => {
    const _handler = {
      get(target, key) {
        if (key === 'name') {
          return (
            <label style={{ display: 'flex', alignItems: 'center', margin: 'unset' }}>
              <Icon style={{ color: '#1ba1c5', padding: '0px 3px 0 0' }} className="icon-box">
                {target.elementInfo &&
                  target.elementInfo.mimeType &&
                  getPluginByMimeTypeIcon(target.elementInfo.mimeType)}
              </Icon>
              {target.part.formatSettings.settings[0].value && target.part.formatSettings.settings[0].value}
            </label>
          );
        }
        if (key === 'icon') {
          return (
            <Icon
              style={{ backgroundColor: 'green', opacity: 0.5, padding: '0px 3px 0 0', width: 11, height: 11 }}
              className="icon-box"
            />
          );
        }
        if (key === 'partNum') {
          return target.part.articleNumber;
        }
        if (key === 'recommended' || key === 'wearAndTear') {
          return target.settings.settings[0].value;
        }
        return target[key];
      }
    };
    return _handler;
  };

  const model = elements ? elements.partItems.map((element) => new Proxy(element, handler(element))) : [];
  const columnModel = columns ? columns.map((column) => new Proxy(column, columnHandler(column))) : [];
  const selectionModel = useSelectionModel(model, true, 'position', selectItem);

  useEffect(() => {
    if (selectionModel.selected[0]) {
      handleSelectedItem(selectionModel.selected[0]);
    }
  }, [selectionModel.selected[0]]);

  return (
    <EnhancedTable
      rows={model}
      identifier="position"
      columns={columnModel}
      // detailComponent ={PublishingDetailsWrapper}
      sorting={<AudienceSwitcher />}
      maxHeight={maxHeight}
      actions={useElementActions}
      setDialog={setDialog}
      sortingKey="position"
      loading={loading}
      getCustomFilter={getCustomFilter}
      sortingOrder="asc"
      selectionModel={selectionModel}
    />
  );
}

// export default injectIntl(memo(({elements, maxHeight, loading, intl, selectItem, handleSelectedItem, checkout}) =>
//         <PartGroupTable loading={loading} intl={intl}
//                         elements={elements}
//                         maxHeight={maxHeight}
//                         selectItem={selectItem}
//                         handleSelectedItem={handleSelectedItem}
//                         checkout={checkout}
//         />,
//     selectiveMemoComparison(p => p.elements, a => a.loading, c => c.maxHeight)),
// );

export default injectIntl(PartGroupTable);
