import { Checkbox, Dialog, FormControlLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import { adobeDropMenu, handlePageLayoutDropMenu, handleTopicDropMenu } from "../utils/helpers";
import TemplateItemRow from "../TemplateItemRow";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
    // height: 345,
    height: "fit-content",
    padding: 11,
    background: "#E2F2F6"
  },

  wrapperTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15,
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.188rem",
    fontWeight: 700
  },
  wrapperButton: {
    display: "flex",
    justifyContent: "end",
    paddingRight: 20,
    marginTop: 15
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  },
  checkboxRoot: {
    marginBottom: 0,
    marginRight: 0
  },
  checkboxLabel: {
    fontSize: "1rem"
  },
  notesInput: {
    margin: 0,
    width: "100%"
  },
  wrapperBlock: {
    margin: "auto opx",
    "& input": {
      background: "#ffffff"
    },
    "& .MuiButtonBase-root:focus": {
      background: "#ffffff"
    }
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "120px 1fr",
    gridColumnGap: 10,
    marginBottom: 10,
    alignItems: "center"
  },
  wrapperDropMenu: {
    display: "grid",
    gridTemplateColumns: "1fr 55px",
    gridColumnGap: 10
  }
}));

function DialogAdvanced({
                          open,
                          setOpen,
                          context,
                          disable,
                          type,
                          setValuePropsDialog,
                          setDropMenuPropsDialog,
                          setDropMenuPrecedence
                        }) {
  const cl = useStyles();
  const { values, setValues, valuesContext } = context;
  const [checkboxState, setCheckboxState] = useState({
    checkedA: false,
    checkedB: false,
    checkedC: false
  });

  const dropMenuRating = [
    { name: "Default" },
    { name: "0" },
    { name: "1" },
    { name: "2" },
    { name: "3" },
    { name: "4" },
    { name: "5" },
    { name: "6" },
    { name: "7" },
    { name: "8" },
    { name: "9" },
    { name: "Force" }
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const saveItem = () => {
    handleClose();
  };
  const handleDropMenu = (val) => {
    handleTopicDropMenu(val.name, type, valuesContext, setValues, "precedence");
  };

  const handleCheckbox = (event) => {
    const val = event.target.checked;
    const { name } = event.target;
    setCheckboxState((pre) => ({ ...pre, [name]: val }));
  };

  const handleSpacing = (valDropMenu, name) => {
    handlePageLayoutDropMenu(valDropMenu, type, valuesContext, setValues, name);
  };

  const handleChildObject = (valDropMenu, name, val) => {
    handlePageLayoutDropMenu(val, type, valuesContext, setValues, name, valDropMenu);
  };


  useEffect(() => {
    const res = {};
    if (values.minimum) {
      res.checkedA = true;
    }
    if (values.maximum) {
      res.checkedB = true;
    }
    if (values.precedence) {
      res.checkedC = true;
    }
    setCheckboxState(pre => ({ ...pre, ...res }));
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paperScrollPaper: cl.root
      }}
    >
      <div>
        <div className={cl.wrapperTitle}>
          <span className={cl.title}>Advanced</span>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={cl.wrapperBlock}>
          <div className={cl.wrapper}>
            <FormControlLabel
              className={cl.checkboxRoot}
              control={
                <Checkbox
                  onChange={handleCheckbox}
                  checked={checkboxState.checkedA}
                  name="checkedA"
                  disabled={disable}
                />
              }
              label={<span className={cl.checkboxLabel}>Minimum:</span>}
            />
            <TemplateItemRow
              label=""
              value={{
                value: values.minimum ? values.minimum.value : 0,
                unit: values.minimum ? adobeDropMenu(values.minimum.unit) : "pt"
              }}
              grid="1fr 55px"
              setValueProps={setValuePropsDialog || handleSpacing}
              setDropMenu={setDropMenuPropsDialog || handleChildObject}
              type="minimum"
              disabled={disable || !checkboxState.checkedA}
            />
          </div>
          <div className={cl.wrapper}>
            <FormControlLabel
              className={cl.checkboxRoot}
              control={
                <Checkbox
                  onChange={handleCheckbox}
                  checked={checkboxState.checkedB}
                  name="checkedB"
                  disabled={disable}
                />
              }
              label={<span className={cl.checkboxLabel}>Maximum:</span>}
            />
            <TemplateItemRow
              label=""
              type="maximum"
              value={{
                value: values.maximum ? values.maximum.value : 0,
                unit: values.maximum ? adobeDropMenu(values.maximum.unit) : "pt"
              }}
              grid="1fr 55px"
              disabled={disable || !checkboxState.checkedB}
              setValueProps={setValuePropsDialog || handleSpacing}
              setDropMenu={setDropMenuPropsDialog || handleChildObject}
            />
          </div>
          <div className={cl.wrapper}>
            <FormControlLabel
              className={cl.checkboxRoot}
              control={
                <Checkbox
                  onChange={handleCheckbox}
                  checked={checkboxState.checkedC}
                  name="checkedC"
                  disabled={disable}
                />
              }
              label={<span className={cl.checkboxLabel}>Precedence:</span>}
            />
            <ExpanderItem
              valueProps={values.precedence ? values.precedence.replace("P", "") : values.precedence}
              propsLabel={dropMenuRating}
              type="precedence"
              setProps={setDropMenuPrecedence || handleDropMenu}
              disabled={disable || !checkboxState.checkedC}
            />
          </div>
        </div>
      </div>

      <div className={cl.wrapperButton}>
        <Button className={cl.button} onClick={saveItem}>
          OK
        </Button>
        <Button className={cl.button} onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}

export default DialogAdvanced;
