import getParameterAsInt from '@zert-packages/utils/getParameterAsInt';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Tab } from '@mui/material';
import addDefaultLanguages from '@zert-packages/utils/addDefaultLanguages';
import cn from 'classnames';
import './CatalogView.sass';
import { approveVersionAsync, createVersion, getVersionInfo } from '@zert-packages/actions/coreReducers';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogById, storeCatalog } from '@zert-packages/actions/catalogActions';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import { FormattedMessage } from 'react-intl';
import useVersionOld from '@zert-packages/utils/useVersion.stable';
import TabPanel from '@zert-packages/components/common/TabPanel';
import TabsWithVersionPanelOld from '@zert-packages/components/common/TabsWithVersionPanel.old';
import ModalCenter from '@zert-packages/components/common/ModalCenter';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import DisabledElementMode from '@zert-packages/components/common/DisabledElementMode';
import { findNodeById } from './helpers/treeMethods';
import CatalogProvider, { CatalogContext } from './common/CatalogProvider';
import Counters from './Counters';
import TreeNodeContextEditor from './TreeNodeContextEditor';
import CatalogTree from './TreeEditor';

function CatalogView(props) {
  const history = useHistory();
  const catalog = useSelector((state) => state.selectedCatalog);
  const versionInfo = useSelector((state) => state.versionInfo);
  const [globalTree, setGlobalTree] = useState(null);
  const [treeForRendering, setTreeForRendering] = useState(null);
  const handleRenderTree = () => {
    if (globalTree !== null) {
      setTreeForRendering({ ...globalTree });
    }
  };
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedParentNode, setSelectedParentNode] = useState(null);
  const debounceHandleRenderTree = _.debounce((globalTree) => handleRenderTree(globalTree), 3000);
  const [isStoring, setIsStoring] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const selectNodeById = (nodeId) => {
    const foundNode = findNodeById(treeForRendering.rootNode, nodeId);
    addDefaultLanguages(foundNode.label);
    setSelectedNode(foundNode);
  };
  const propsdispatch = useDispatch();
  const {
    match: { params }
  } = props;

  const [currentTab, setCurrentTab] = useState(0);
  const handleOnChangeTab = (event, newValue) => setCurrentTab(newValue);

  const { setVersionState, isEverythingDisabled, canSaveVersion, canApprovalVersion, canCreateVersion } =
    useVersionOld();
  useEffect(() => {
    const versionId = getParameterAsInt(params.versionId, -100);
    const companyId = getParameterAsInt(params.companyId, -100);
    if (
      catalog &&
      catalog.info &&
      +versionId === +catalog.info.versionId &&
      versionInfo &&
      versionInfo.info &&
      +versionInfo.info.versionId === +versionId
    ) {
      setVersionState(versionInfo);
    }

    if (!isLoading && (!catalog || !catalog.info || (catalog && catalog.info && versionId != catalog.info.versionId))) {
      setIsLoading(true);
      propsdispatch(getVersionInfo(versionId));
      propsdispatch(getCatalogById(companyId, versionId));
    }
    if (isLoading && catalog && catalog.info && versionId == catalog.info.versionId) {
      setGlobalTree(catalog);
      setTreeForRendering(catalog);
      setIsLoading(false);
    } else if (catalog && !treeForRendering) {
      setGlobalTree(catalog);
      setTreeForRendering(catalog);
    }
  });

  function saveVersion() {
    const catalogToSend = { ...globalTree };
    delete catalogToSend.info;

    _saveVersion(catalogToSend);
  }

  async function _saveVersion(catalogToSend) {
    setIsStoring(true);
    try {
      await storeCatalog(catalogToSend, globalTree.info.versionId);
      setIsStoring(false);
    } catch (err) {
      setIsStoring(false);
    }
  }

  function applyVersion() {
    async function approveVersion() {
      const catalogToSend = { ...globalTree };
      delete catalogToSend.info;
      await _saveVersion(catalogToSend);

      setIsApproving(true);
      try {
        await approveVersionAsync(-100, catalog.info.versionId);
        propsdispatch(getVersionInfo(catalog.info.versionId));
      } catch (e) {}
      setIsApproving(false);
    }

    approveVersion();
  }

  function createNewVersion() {
    async function _createNewVersion() {
      setIsCreating(true);
      try {
        const newVersion = await createVersion(-100, catalog.info.versionId);
        history.push(getPluginByMimeType(newVersion.mimeType).openpath.replace(':versionId', newVersion.versionId));
      } catch (e) {}
      setIsCreating(false);
    }

    _createNewVersion();
  }

  const valuesForProvider = {
    selectedNode,
    setSelectedNode,
    selectedParentNode,
    setSelectedParentNode,
    selectNodeById,

    globalTree,
    treeForRendering,
    handleRenderTree,
    debounceHandleRenderTree,

    isEverythingDisabled,
    canSaveVersion,
    canApprovalVersion,
    canCreateVersion,

    saveVersion,
    applyVersion,
    createNewVersion
  };

  return (
    <DisabledElementMode>
      <div className="template-cage">
        {(isLoading || !globalTree) && (
          <Loader>
            <FormattedMessage id="catalogView.loading" defaultMessage="Loading catalog" />
          </Loader>
        )}
        <ModalCenter open={isCreating}>
          <Loader loaderAction={LoaderAction.Creating}>
            <FormattedMessage id="CatalogView.CreatingNewVersion" defaultMessage="Creating new version" />
          </Loader>
        </ModalCenter>
        <ModalCenter open={isStoring}>
          <Loader loaderAction={LoaderAction.Storing}>
            <FormattedMessage id="CatalogView.StoringText" defaultMessage="Storing catalog" />
          </Loader>
        </ModalCenter>
        <ModalCenter open={isApproving}>
          <Loader loaderAction={LoaderAction.Approving}>
            <FormattedMessage id="CatalogView.ApprovingText" defaultMessage="Approving catalog" />
          </Loader>
        </ModalCenter>
        {!isLoading && globalTree && treeForRendering && treeForRendering.rootNode && (
          <div className="catalog_root__container">
            <CatalogProvider value={valuesForProvider}>
              <TabsWithVersionPanelOld value={currentTab} onChange={handleOnChangeTab} context={CatalogContext}>
                <Tab label={<FormattedMessage id="structure.tab.treeview" defaultMessage="TREEVIEW" />} />
                <Tab label={<FormattedMessage id="structure.tab.counters" defaultMessage="COUNTERS" />} />
              </TabsWithVersionPanelOld>

              <div className={cn('catalog_root__body')}>
                <TabPanel value={currentTab} index={0}>
                  <div className={cn('tab', 'tab--n0')}>
                    <CatalogTree />
                    {selectedNode !== null && <TreeNodeContextEditor />}
                  </div>
                </TabPanel>

                <TabPanel value={currentTab} index={1}>
                  <Counters />
                </TabPanel>
              </div>
            </CatalogProvider>
          </div>
        )}
      </div>
    </DisabledElementMode>
  );
}

export default CatalogView;
