import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { format as FNSFormat } from 'date-fns';
import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment';
import getTranslation from '@zert-packages/utils/getTranslation.new';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import TaskMembersEditor from '@zert-packages/components/common/Task/TaskMembersEditor';
import DeadlineList from '@zert-packages/components/common/Task/DeadlineList';
import RichTextEditor from '@zert-packages/components/shared/RichTextEdit/RichTextEditor';
import IntervalEditor from '@zert-packages/components/common/Task/IntervalEditor';
import ModalCenter from '@zert-packages/components/common/ModalCenter';
import { initSafetyInspectionAPI } from './API';
import { loadQuestinaireTemplate, loadQuestinaireTemplates } from './serviceMidleware';

const formattedMessages = {
  dialogTitle: (
    <FormattedMessage
      id="RMP.DetailView.CreateSafetyInspectionDialog.DialogTitle"
      defaultMessage="Creating your safety inspection"
    />
  ),
  confirm: <FormattedMessage id="RMP.DetailView.CreateSafetyInspectionDialog.Confirm" defaultMessage="Create" />,
  cancel: <FormattedMessage id="RMP.DetailView.CreateSafetyInspectionDialog.Cancel" defaultMessage="Cancel" />,
  checklistTitle: <FormattedMessage id="RMP.CreateSafetyInspectionDialog.ChecklistTitle" defaultMessage="Title" />,
  locationOfElement: <FormattedMessage id="RMP.CreateSafetyInspectionDialog.LocationLabel" defaultMessage="Location" />,
  questionnaireTemplate: (
    <FormattedMessage
      id="RMP.CreateSafetyInspectionDialog.QuestionnaireTemplateLabel"
      defaultMessage="Questionnaire Template"
    />
  ),
  placementLabel: <FormattedMessage id="RMP.CreateSafetyInspectionDialog.PlacementLabel" defaultMessage="Placement" />,
  descriptionLabel: (
    <FormattedMessage id="RMP.CreateSafetyInspectionDialog.DescriptionLabel" defaultMessage="Description" />
  )
};

const useStyles = makeStyles({
  DialogContent: {
    display: 'grid',
    /*  gridTemplateRows: '50px 58px 38px 370px', */
    alignItems: 'flex-start',
    gridGap: 10,
    padding: 20,
    paddingTop: 0,
    height: '100%',
    width: '100%',
    overflow: 'visible'
  },

  DialogWrapper: {
    padding: 0,
    width: '100%',
    overflowY: 'auto'
  },
  fieldContainerWithLabel: {
    padding: 4,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
  }
});
function CreateNewSafetyInspectionDialog({ handleClose, onCreate, location }) {
  const cl = useStyles();
  const dispatch = useDispatch();
  const { templates, wftTemplate } = useSelector((state) => state.QUESTIONNAIRE);
  const [creating, setCreating] = useState(false);
  const catalog = useSelector((state) => state.catalog);

  useEffect(() => {
    dispatch(loadQuestinaireTemplates(false, true));
  }, []);

  const [state, setState] = useState({
    idtemplate: -1,
    name: '',
    description: '',
    participantMappings: null,
    remindDeadlineMappings: null,
    earlieststart: {
      amount: 3,
      unit: 'd'
    }
  });

  const updateStateByField = (field, value) =>
    setState((prev) => ({
      ...prev,
      [field]: value
    }));

  const onChangeQuestionnaireTemplate = (e) => {
    setSelectedTemplate(e.target.value);
  };

  const onChangeRichEdit = (editorState) => {
    updateStateByField('description', stateToHTML(editorState.getCurrentContent()));
  };
  const handleOnCreate = () => {
    setCreating(true);

    const safetyInspectionSummary = {
      safetyInspection: {
        name: state.name,
        description: state.description,
        earliestStartInterval: state.earlieststart
      },
      remindDeadlineMappings: state.remindDeadlineMappings,
      participantMappings: state.participantMappings
    };

    initSafetyInspectionAPI(state.idtemplate, safetyInspectionSummary)
      .then((res) => {
        onCreate(res);
        setCreating(false);
        handleClose();
      })
      .catch((e) => {
        setCreating(false);
        handleClose();
      });
  };

  const onEarliestStartChange = (unit, amount) => {
    updateStateByField('earlieststart', { unit, amount });
  };

  useEffect(() => {
    dispatch(loadQuestinaireTemplates(false, true));
  }, []);

  const setSelectedTemplate = (value) => {
    const todayDate = FNSFormat(new Date(), 'yyyy-MM-dd');
    const template = templates.find((template) => value === template.versionId);
    if (template) {
      setState((prev) => ({
        ...prev,
        idtemplate: value,
        name: `${template.name} ${todayDate}`,
        description: template && template.description ? getTranslation(template.description) : ''
      }));
    }
  };

  useEffect(() => {
    if (templates && templates.length > 0) {
      setSelectedTemplate(templates[0].versionId);
    }
  }, [templates]);

  useEffect(() => {
    if (state.idtemplate > 0) {
      dispatch(loadQuestinaireTemplate(state.idtemplate));
    }
  }, [state.idtemplate]);

  useEffect(() => {
    if (wftTemplate) {
      updateStateByField(
        'participantMappings',
        wftTemplate.participants.map((participant) => ({
          workflowParticipant: participant,
          user: null
        }))
      );

      updateStateByField(
        'remindDeadlineMappings',
        wftTemplate.deadlines.map((deadline) => ({
          identifier: deadline.id,
          name: deadline.name,
          interval: {
            amount: 7,
            unit: 'w'
          },
          deadline: moment().add(1, 'day').endOf('day'),
          mode: 'enter'
        }))
      );
    }
  }, [wftTemplate]);

  if (creating) {
    return (
      <ModalCenter open>
        <Loader loaderAction={LoaderAction.Creating} />
      </ModalCenter>
    );
  }
  return (
    <Dialog fullScreen open onClose={handleClose}>
      <DialogTitle>{formattedMessages.dialogTitle}</DialogTitle>
      <DialogContent className={cl.DialogWrapper}>
        {templates === null || catalog === null || creating ? (
          <Loader />
        ) : (
          <div className={cl.DialogContent}>
            <TextField
              variant="standard"
              value={state.name}
              onChange={(e) => updateStateByField('name', e.target.value)}
              label={formattedMessages.checklistTitle}
            />
            {location && <TextField variant="standard" value={location} label={formattedMessages.locationOfElement} />}
            <FormControl variant="standard">
              <InputLabel>{formattedMessages.questionnaireTemplate}</InputLabel>

              <Select variant="standard" value={state.idtemplate} onChange={onChangeQuestionnaireTemplate}>
                {templates.map((template, index) => (
                  <MenuItem key={index} value={template.versionId}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {wftTemplate != null && state.participantMappings != null && state.participantMappings.length > 0 && (
              <TaskMembersEditor
                key={`TaskMembersEditor${wftTemplate.versionId}`}
                participantMappings={state.participantMappings}
              />
            )}
            {wftTemplate != null && state.remindDeadlineMappings != null && state.remindDeadlineMappings.length > 0 && (
              <DeadlineList
                key={`DeadlineList${wftTemplate.versionId}`}
                deadlinemappings={state.remindDeadlineMappings}
              />
            )}

            <div className="captionLabel">
              <FormattedMessage
                id="newsafetyinspection.earlieststart"
                defaultMessage=" Earliest start date(before deadline)"
              />
            </div>
            <IntervalEditor interval={state.earlieststart} onChange={onEarliestStartChange} />

            <div className={cl.fieldContainerWithLabel}>
              <Typography color="textSecondary" variant="subtitle1">
                {formattedMessages.descriptionLabel}
              </Typography>
              <RichTextEditor key={`${state.idtemplate}`} text={state.description} onChange={onChangeRichEdit} />
            </div>
            {/*     <RichTextEditWithLanguageSwitch
            state={state.description}
            onChange={(_state) => updateStateByField('description', _state)}
          /> */}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={state.idtemplate === -1} onClick={handleOnCreate} color="primary" variant="outlined">
          {formattedMessages.confirm}
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          {formattedMessages.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateNewSafetyInspectionDialog;
