import React, { useContext, useEffect } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import LanguageIcon from '@mui/icons-material/Language';
import { TabsContext } from '../../../../../Stylesheet';
import {
  adobeRgba,
  adobeWord,
  handleHeadingAfter,
  handleHeadingLabel,
  handleHeadingObject
} from "../../../../../utils/helpers";
import TemplateItemRow from '../../../../../TemplateItemRow';
import SelectedColor from '../../../../../SelectedColor';
import DialogColors from '../../../../../dialog/DialogColors';
import SpacingTemplate from '../../../topic/SpacingTemplate';
import DialogEditLabel from '../../dialog/DialogEditLabel';
import { fontData } from '../../../../../data/fontData';

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapper: {
    border: '1px solid #C6C6C5',
    padding: 5,
    display: 'grid',
    gridRowGap: 10,
    paddingBottom: 10
  },
  checkbox: {
    marginBottom: 0
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr 1fr',
    gridColumnGap: 10,
    alignItems: 'center'
  },
  wrapperDropRow: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gridColumnGap: 10,
    alignItems: 'center'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    marginBottom: 5
  },
  notesInput: {
    margin: 0,
    width: '100%',
    '& input': {
      paddingTop: 5.2,
      paddingBottom: 5.2
    }
  },
  wrapperCheckbox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr ',
    maxWidth: 500
  },
  checkboxFont: {
    marginBottom: 0
  },
  checkboxLabel: {},
  wrapperRowContent: {
    display: 'grid',
    gridTemplateColumns: '90px 150px 1fr 25px',
    gridColumnGap: 10,
    alignItems: 'center'
  },
  wrapperDropMenu: {
    display: 'grid',
    gridTemplateColumns: '90px 1fr ',
    gridColumnGap: 10
  }
}));

function Heading({color, setColor}) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const defaultWidth = {
    value: 12,
    unit: 'POINTS'
  };
  const headind = values.stylesheet.blockSettings[values.selectedLeftMenu].heading;

  //headind.color

  const data = {
    // fontStyle: [{name: 'Bold'}, {name: 'Subscript'}, {name: 'Uppercase'}, {name: 'Superscript'}, {name: 'Underlined'},
    //     {name: 'Lowercase'}, {name: 'italic'}, {name: 'Strikethrough'}, {name: 'Verbatim'},],
    contentDropMenu: ['Text', 'Translatable text', 'Include']
  };

  const handleDropMenu = (val, name) => {
    setColor(pre => ({...pre, [name]: val}))
    handleHeadingObject(adobeRgba(val), name, 'heading', values, setValues);
  };

  const handleContent = (val, name) => {
    const res = { ...headind.content, [name]: val };
    handleHeadingObject(res, 'content', 'heading', values, setValues);
  };

  const handleContentLabel = (e) => {
    const result = {
      ...headind.content,
      text: {
        ...headind.content.text,
        defaultLabel: e.target.value
      }
    };
    handleHeadingLabel(result, 'text', 'content', values, setValues);
  };

  const handleOpenDialog = (type) => {
    renderInExceptionRoot(DialogColors, {
      color: headind[type],
      title: 'Select Color',
      setText: handleDropMenu,
      type
    });
  };

  const handleHeading = (e) => {
    const val = e.target.checked;
    handleHeadingObject(val, 'visible', 'heading', values, setValues);
  };

  const setValuePropsRow = (val, name) => {
    handleHeadingObject(val, name, 'heading', values, setValues, 'value');
  };

  const setDropMenuPropsRow = (valDropMenu, name, val) => {
    handleHeadingObject(
      {
        value: val,
        unit: valDropMenu
      },
      name,
      'heading',
      values,
      setValues
    );
  };

  const findCheckbox = (el) => {
    return headind.fontStyle.map((it) => it.toLowerCase()).find((it) => it === el.toLowerCase());
  };

  const handleCheckboxFontStyle = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    if (val) {
      const result = [...headind.fontStyle, name.toUpperCase()];
      return handleHeadingObject(result, 'fontStyle', 'heading', values, setValues);
    }
    const result = headind.fontStyle.filter((el) => el !== name.toUpperCase());
    handleHeadingObject(result, 'fontStyle', 'heading', values, setValues);
  };

  const findSelectedWidth = () => {
    if (!headind.fontSize) {
      handleHeadingObject(defaultWidth, 'fontSize', 'heading', values, setValues);
    }
  };

  const handleSpacing = (val, name) => {
    handleHeadingAfter(val, name, values, setValues, 'value');
  };

  const handleSpacingMenu = (valDropMenu, name, val) => {
    handleHeadingAfter(
      {
        value: val,
        unit: valDropMenu
      },
      'value',
      values,
      setValues
    );
  };
  const handleSpacingBefore = (valDropMenu, val, name) => {
    handleHeadingAfter(valDropMenu, name, values, setValues);
  };

  const handleValuePropsDialog = (val, name) => {
    if (!headind.spaceAfter[name]) {
      return handleHeadingAfter(
        {
          value: val,
          unit: 'pt'
        },
        name,
        values,
        setValues
      );
    }
    handleHeadingAfter(val, name, values, setValues, 'value');
  };

  const setDropMenuPropsDialog = (valDropMenu, name, val) => {
    handleHeadingAfter(
      {
        value: val,
        unit: valDropMenu
      },
      name,
      values,
      setValues
    );
  };

  const setDropMenuPrecedence = (valDropMenu, name) => {
    handleHeadingAfter(valDropMenu.name, name, values, setValues);
  };

  const handleLabel = () => {
    const { text } = headind.content;
    renderInExceptionRoot(DialogEditLabel, { values, setValues, text, headind });
  };

  useEffect(() => {
    findSelectedWidth();
  }, [headind]);

  return (
    <div className={cl.root}>
      {headind && (
        <>
          <p className={cl.title}>Heading</p>
          <div className={cl.wrapper}>
            <div className={cl.wrapperRow}>
              <div />
              <FormControlLabel
                className={cl.checkbox}
                control={<Checkbox onChange={handleHeading} checked={headind.visible} />}
                label={<span className={cl.checkboxLabel}>Use heading</span>}
              />
            </div>

            <div className={cl.wrapperRowContent}>
              <div className={cl.title}>Content:</div>
              <ExpanderItem
                valueProps={adobeWord(headind.content.type, true)}
                propsLabel={data.contentDropMenu}
                setProps={handleContent}
                type="type"
              />
              <TextField
                value={headind.content.text.defaultLabel}
                className={cl.notesInput}
                variant="outlined"
                onChange={handleContentLabel}
              />
              <LanguageIcon onClick={handleLabel} style={{ color: 'grey', cursor: 'pointer' }} />
            </div>
            <div className={cl.wrapperDropMenu}>
              <div className={cl.title}>Font family:</div>
              <ExpanderItem valueProps={headind.fontFamily} setProps={handleDropMenu} type="fontFamily" />
            </div>
            <TemplateItemRow
              label="Font size:"
              type="fontSize"
              value={headind.fontSize ? headind.fontSize : defaultWidth}
              grid="90px 1fr 55px"
              setDropMenu={setDropMenuPropsRow}
              setValueProps={setValuePropsRow}
            />
            <div className={cl.wrapperDropMenu}>
              <div className={cl.title}>Font style:</div>
              <div className={cl.wrapperCheckbox}>
                {fontData.map((el, i) => (
                  <div key={i}>
                    <FormControlLabel
                      control={
                        <Checkbox onChange={handleCheckboxFontStyle} checked={findCheckbox(el.name)} name={el.name} />
                      }
                      label={<span>{el.name}</span>}
                    />
                  </div>
                ))}
              </div>
            </div>
            <SelectedColor
              label="Color:"
              type="color"
              setValueProps={handleDropMenu}
              handleOpenDialog={handleOpenDialog}
              valueProps={color.color}
              style={{ grid: '90px 30px 1fr 25px' }}
            />
            <SpacingTemplate
              title="After:"
              type="value"
              valueProps={headind.spaceAfter}
              grid="90px 150px 1fr  55px"
              setValueProps={handleSpacing}
              setDropMenu={handleSpacingMenu}
              handleBefore={handleSpacingBefore}
              setValuePropsDialog={handleValuePropsDialog}
              setDropMenuPropsDialog={setDropMenuPropsDialog}
              setDropMenuPrecedence={setDropMenuPrecedence}
            />
            <TemplateItemRow
              label="Margin left:"
              type="marginLeft"
              value={headind.marginLeft}
              grid="90px 1fr 55px"
              setDropMenu={setDropMenuPropsRow}
              setValueProps={setValuePropsRow}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Heading;
