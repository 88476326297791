import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextChanger from '../dialogs/TextChanger';

export const formStyle = (theme) =>
  createStyles({
    divRoot: {
      display: 'flex'
      /*  height:'25rem',
          border:'1px solid lightgrey',
          padding:4 */
    },
    divButtons: {
      width: '5%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    divContentContainer: {
      border: '1px solid lightgrey',
      width: '95%',
      height: '20rem'
    },
    divContentContainerNoBorder: {
      width: '95%',
      height: '100%'
    },
    listStyle: {
      '&.MuiTypography-body1': {
        fontSize: '1em'
      }
    }
  });
const useStyles = makeStyles(formStyle);

const initState = {
  languageDialog: false
};

function TextView({ data, setData }) {
  const [details, setDetails] = useState(data);

  const checkBoxChanger = (event) => {
    setDetails((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked
    }));
  };

  const textFieldChangeHandler = (event) => {
    setDetails((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };
  useEffect(() => {
    setDetails(data);
  }, [data]);

  useEffect(() => {
    setData(details);
  }, [details]);

  return (
    <div>
      <TextField
        fullWidth
        variant="outlined"
        label="Value:"
        value={details.value}
        name="value"
        onChange={textFieldChangeHandler}
      />
      <TextChanger data={details} setData={setDetails} />
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={details.translatable} onChange={checkBoxChanger} />}
          label="Translatable"
          name="translatable"
        />
      </FormGroup>
    </div>
  );
}

export default TextView;
