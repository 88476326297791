import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getTileElementStatusMessage } from '@zert-packages/components/ElementTile/utils/getTileStatus';
import ElementTileTable from '@zert-packages/components/ElementTile/ElementTileTable';
import { useSelector } from 'react-redux';
import useDimensions from '@zert-packages/utils/useDimensions';

export function ChecklistsPage({ checklists, isBlock }) {
  const myUser = useSelector((state) => state.myuser);

  const { height } = useDimensions({ liveMeasure: true });
  const intl = useIntl();

  const getCustomFilter = (element, name) => {
    switch (name) {
      case 'nameNew':
        return element.name === 'Current' ? (
          <FormattedMessage id="safetyinspection.overview.current" defaultMessage="Current" />
        ) : (
          element.name
        );
      case 'status':
        return getTileElementStatusMessage(element, myUser, intl);
      default:
        return null;
    }
  };

  const checklistComparator = (a, b) => {
    if (a.name === 'Current') {
      return -1;
    } else if (b.name === 'Current') {
      return 1;
    }
    const aDate = Date.parse(a.name);
    const bDate = Date.parse(b.name);
    if (aDate > bDate) {
      return -1;
    } else if (aDate < bDate) {
      return 1;
    }
    const aCreated = Date.parse(a.createdAt);
    const bCreated = Date.parse(b.createdAt);
    if (aCreated > bCreated) {
      return -1;
    } else if (bCreated > aCreated) {
      return 1;
    }
    return 0;
  };

  const columns = [
    {
      identifier: 'nameNew',
      numeric: false,
      disablePadding: false,
      scope: 'row',
      isFilterable: true,
      customComparator: checklistComparator,
      label: <FormattedMessage id="safetyinspection.overview.record" defaultMessage="RECORD" />
    },
    {
      identifier: 'owner',
      numeric: false,
      disablePadding: false,
      align: 'left',
      isFilterable: true,
      label: <FormattedMessage id="safetyinspection.overview.owner" defaultMessage="OWNER" />
    },
    {
      identifier: 'status',
      numeric: false,
      disablePadding: false,
      align: 'left',
      isFilterable: true,
      label: <FormattedMessage id="safetyinspection.overview.status" defaultMessage="STATUS" />
    },
    {
      identifier: 'date',
      numeric: false,
      disablePadding: false,
      align: 'left',
      style: 'nowrapclass',
      isFilterable: true,
      label: <FormattedMessage id="safetyinspection.overview.created" defaultMessage="CREATED" />
    }
  ];

  return (
    <ElementTileTable
      elements={checklists}
      columnsNew={columns}
      loading={!checklists}
      bradCrumbComponent={null}
      maxHeight={height}
      customFilter={getCustomFilter}
      showCut={false}
      showElementActions={false}
      doNotShowSelect
      doNotShowToolbar
      orderBy="nameNew"
      order="desc"
    />
  );
}

export default ChecklistsPage;
