export default function getSearchContainer(container, callback) {
  container.startSearching(() => {
    getNode(container.id, callback);
  });
}

function getNode(id, callback) {
  const node = document.getElementById(id);
  if (node) return callback(node);
  setTimeout(() => getNode(id, callback), 250);
}
