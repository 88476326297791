import React from 'react';
import { TextField } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  noteActiveInputWrapper: {
    width: '100%',
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15,
      textTransform: 'lowercase',
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  notesInput: {
    margin: 0,
    width: '100%',
  }
}));

function TextFieldItem({ values = '', name, setValues, type = 'text', autoFocus, error, style={}, multiline, row, onBlur, disabled }) {
  const cl = useStyles();

  const handleChangeNote = (event) => {
    const val = event.target.value;


    if (setValues) {
      setValues(val, name);
    }
  };
const handleBlur = () => {
    if(onBlur){
      onBlur()
    }
}

  return (
    <div className={cl.noteActiveInputWrapper}>
      <TextField
        style={style}
        error={error}
        autoFocus={autoFocus}
        type={type}
        value={values}
        className={cl.notesInput}
        multiline={multiline}
        rows={row}
        variant="outlined"
        onChange={handleChangeNote}
        onBlur={handleBlur}
        disabled={disabled}
      />
    </div>
  );
}

export default TextFieldItem;
