import React, { useEffect, useState } from "react";
import { getCriterias } from "@zert-packages/plugins/ExplorerPlugin/helpers/getCriterias";
import { searchApi } from "@zert-packages/plugins/ExplorerPlugin/API";
import { MeasureTable } from "./MeasureTable";

function createSearchCriteria(versionId) {
  const searchConditions = {
    'se.zert.backend.search.ElementTypeCriteria': {
      className: 'se.zert.backend.search.ElementTypeCriteria',
      expanded: true,
      values: [
        {
          key: 'MimeType',
          value: ['application/zert-measure']
        },
        {
          key: 'expanded',
          value: true
        }
      ]
    },
    'se.zert.backend.search.ElementCriteria': {
      className: 'se.zert.backend.search.ElementCriteria',
      expanded: true,
      values: [
        {
          key: 'expanded',
          value: true
        },
        {
          key: 'InElementVersions',
          value: [
            {
              versionId: versionId,
            }
          ]
        }]}
  }
  return getCriterias(searchConditions, false);
}

interface MeasuresType {
  count: number,
  limit: number,
  result: Array<any>
}

export function TabMeasures({versionId}) {

  const [measures, setMeasures] = useState<MeasuresType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const explorer_infos = measures ? measures.result : [];

  useEffect(() => {
    const criteria = createSearchCriteria(versionId);
    searchApi(criteria).then(m => {
      setMeasures(m);
      setLoading(false);
    });
  }, []);

  return (
    <MeasureTable
      measures={explorer_infos}
      loading={loading}
    />)
}