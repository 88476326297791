import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TemplateItemRow from '../../../TemplateItemRow';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: '1px solid #C6C6C5',
    padding: '7px 10px 20px',

    display: 'grid',
    gridRowGap: 10
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    marginBottom: 5
  },
  wrapperCheckbox: {
    display: 'block',
    marginTop: -1,
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    }
  },
  wrapperGrid: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr 55px',
    gridColumnGap: 10,
    alignItems: 'center'
  }
}));

function GroupBlock({
  valueProps,
  setValueProps,
  setDropMenu,
  checked,
  style,
  styleRow,
  label,
  styleDropMenu,
  setCheckbox,
  disabled
}) {
  const cl = useStyles();
  const handleCheckbox = (event) => {
    if (setCheckbox) {
      setCheckbox(event.target.checked);
    }
  };

  return (
    <div>
      {label && <p className={cl.title}>{label}</p>}
      <div className={cl.wrapper} style={style && style}>
        {valueProps &&
          valueProps.map((el, i) => (
            <TemplateItemRow
              key={i}
              label={el.label}
              type={el.type}
              value={el.value}
              setValueProps={setValueProps}
              setDropMenu={setDropMenu}
              grid={styleRow || '100px 1fr 55px'}
              styleDropMenu={styleDropMenu}
              disabled={disabled}
            />
          ))}
        {checked !== undefined && (
          <span className={cl.wrapperCheckbox}>
            <div className={cl.wrapperGrid}>
              <div />
              <div>
                <FormControlLabel
                  control={<Checkbox onChange={handleCheckbox} checked={checked.value} />}
                  label={<span>{checked.label}</span>}
                />
              </div>
            </div>
          </span>
        )}
      </div>
    </div>
  );
}

export default GroupBlock;
