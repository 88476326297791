export const textData = {
  class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientNormalTextSettingsTemplate',
  identifier: 'bold',
  label: 'Fet',
  fontFamily: 'default',
  fontSize: null,
  fontStyle: ['BOLD'],
  color: null,
  backgroundColor: null
};

export const userTextData = {
  class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientUserDefinedTextSettingsTemplate',
  identifier: 'italic',
  label: 'Kursiv',
  template: ''
};
