import React from 'react';
import { Box, LinearProgress } from '@mui/material';

function SplashScreen() {
  return (
    <Box>
      <LinearProgress />
    </Box>
  );
}

export default SplashScreen;
