import React, { useState } from 'react';
import { sortTiles } from '@zert-packages/components/ElementTile/utils/sortTiles';
import Sorting from '@zert-packages/components/common/Sorting/Sorting';
import ElementTile from '@zert-packages/components/ElementTile/ElementTile';
import CatalogCard from './CatalogCard';

function CatalogElements({ filterValue, cataloginfos, object, label, selectElement, drawCuted }) {
  const [{ sorting, isAscending }, setState] = useState({
    sorting: 'name',
    isAscending: 1
  });

  const findDocuments = () => {
    const documents = [];
    if (cataloginfos)
      cataloginfos.map((info, index) => {
        if (info.mimeType === '__item-group__' && info.name === `application/zert-${object}`) {
          info.children.map((document) => {
            if (
              filterValue == null ||
              filterValue.length == 0 ||
              (document.name != null && document.name.toLowerCase().includes(filterValue.toLowerCase()))
            )
              documents.push(document);
          });
        }
      });
    return documents;
  };

  const handleSortingChange = (event) => {
    const { value } = event.target;
    const { name } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const documents = findDocuments();

  if (documents.length > 0) {
    return (
      <CatalogCard
        title={
          <span>
            {label} ({documents.length})
          </span>
        }
        action={<Sorting handleSortingChange={handleSortingChange} sorting={sorting} isAscending={isAscending} />}
        content={
          <div className="catalog-objects-wrapper">
            {documents &&
              sortTiles(documents, sorting, isAscending).map((document) => {
                return (
                  <ElementTile
                    overviewActions
                    hideActions
                    selectFunction
                    element={document}
                    selectElement={selectElement}
                    drawCuted={drawCuted(document)}
                  />
                );
              })}
          </div>
        }
      />
    );
  }
  return <div />;
}

export default CatalogElements;
