import { descendingComparator } from './descendingComparator';

export function sortTiles(tasks, sortBy, direction) {
  if (tasks) {
    tasks.sort((a, b) => {
      if (a.mimeType.includes('folder') && !b.mimeType.includes('folder')) {
        return ~direction;
      }
      if (!a.mimeType.includes('folder') && b.mimeType.includes('folder')) {
        return direction;
      }

      if (
        a.mimeType.includes('search-folder') &&
        !b.mimeType.includes('search-folder') &&
        b.mimeType.includes('folder')
      ) {
        return direction;
      }
      if (
        b.mimeType.includes('search-folder') &&
        !a.mimeType.includes('search-folder') &&
        a.mimeType.includes('folder')
      ) {
        return ~direction;
      }

      if (sortBy === 'changedAt') {
        if (a.changedAt !== null && b.changedAt !== null && a.changedAt > b.changedAt) {
          return direction;
        }
        if (a.changedAt !== null && b.changedAt !== null && a.changedAt < b.changedAt) {
          return ~direction;
        }
      }

      if (sortBy === 'createdAt') {
        if (a.createdAt !== null && b.createdAt !== null && a.createdAt > b.createdAt) {
          return direction;
        }
        if (a.createdAt !== null && b.createdAt !== null && a.createdAt < b.createdAt) {
          return ~direction;
        }
      }

      if (sortBy === 'changedBCy') {
        if (a.changedBy !== null && b.changedBy !== null && a.changedBy > b.changedBy) {
          return direction;
        }
        if (a.changedBy !== null && b.changedBy !== null && a.changedBy < b.changedBy) {
          return ~direction;
        }
      }

      if (sortBy === 'createdBy') {
        if (a.createdBy !== null && b.createdBy !== null && a.createdBy > b.createdBy) {
          return direction;
        }
        if (a.createdBy !== null && b.createdBy !== null && a.createdBy < b.createdBy) {
          return ~direction;
        }
      }

      if (sortBy === 'id') {
        if (a.id !== null && b.id !== null && a.id > b.id) {
          return direction;
        }
        if (a.id !== null && b.id !== null && a.id < b.id) {
          return ~direction;
        }
      }

      if (sortBy === 'mimeType') {
        if (a.mimeType !== null && b.mimeType !== null && a.mimeType > b.mimeType) {
          return direction;
        }
        if (a.mimeType !== null && b.mimeType !== null && a.mimeType < b.mimeType) {
          return ~direction;
        }
      }

      if (sortBy === 'name') {
        const comparator = descendingComparator(a, b, 'name', direction);
        return direction * comparator;
      }
      return 0;
    });
    return tasks
  }
  return []
}
