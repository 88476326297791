import React, { useEffect, useState } from 'react';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { useSelector } from 'react-redux';
import MatrixInlineCatalogView from '../../common/RiskAssesmentEdit/MatrixInlineCatalogView';

function TileMatrixOverview(props) {
  const raoverview = useSelector((state) => state.raoverview);
  const riskrowtemplate = useSelector((state) => state.riskrowtemplate);
  const loadingoverview = useSelector((state) => state.loadingoverview);

  const [RAOverview, setRAOverview] = useState(null);
  const [riskrowTemplate, setRiskrowTemplate] = useState(null);

  const getValue = (columnidentifier) => {
    const cell = {
      class: 'se.zert.rm.rest.template.cell.pojo.ClientMatrixCell',
      columnIdentifier: columnidentifier,
      changed: false,
      editable: true,
      selection: {
        position: []
      },
      notSignificantValue: false
    };
    return cell;
  };

  useEffect(() => {
    if (!RAOverview && raoverview && raoverview.info.versionId == props.element.versionId) {
      setRAOverview(raoverview);
    }

    if (!riskrowTemplate && riskrowtemplate && riskrowtemplate.versionId == props.rrtemplateid) {
      setRiskrowTemplate(riskrowtemplate);
    }
  }, []);

  if (!RAOverview) {
    return (
      <div className="row container-fluid matrixes-row">
        {loadingoverview && loadingoverview === props.element.versionId && <Loader />}
      </div>
    );
  }

  if (RAOverview && riskrowTemplate) {
    return (
      <div className="row container-fluid matrixes-row">
        {riskrowTemplate.columns.map((col) => {
          if (col.class.includes('MatrixColumn')) {
            return (
              <>
                {col.matrix.dimensions.length != 2 && (
                  <label htmlFor={col.identifier} className="col-form-label" style={{ paddingLeft: '10px' }}>
                    {col.name.defaultLabel}
                  </label>
                )}
                <div
                  className="col-sm-xz matrix-area-label"
                  key={`${RAOverview.info.id}_matrix_${col.identifier}`}
                  style={{ paddingRight: '10px' }}
                >
                  <div>
                    <MatrixInlineCatalogView
                      key={RAOverview.info.id + col.identifier}
                      id={col.identifier}
                      column={col}
                      cell={getValue(col.identifier)}
                      isEditor
                      residual={false}
                      overview={RAOverview}
                    />
                  </div>
                  {col.matrix.dimensions.length == 2 && (
                    <label htmlFor={col.identifier} className="col-form-label" style={{ paddingLeft: '10px' }}>
                      {col.name.defaultLabel}
                    </label>
                  )}
                </div>
              </>
            );
          }
        })}
      </div>
    );
  }

  return <div className="row container-fluid matrixes-row" />;
}

export default TileMatrixOverview;
