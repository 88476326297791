import React, { Fragment } from 'react';
import { loadRiskAssesmentTemplates, initRiskAssesment } from '@zert-packages/actions/riskassesmentActions';
import { getOverviewSettings } from '@zert-packages/actions/coreReducers';
import { elementCreatedAndLoaded, loadHelp, API_ROOT_WAR, moveAfterDelivery } from '@zert-packages/actions/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import ButtonRM from '@zert-packages/components/shared/Button';
import CancelButton from '@zert-packages/components/shared/CallToActionButton/CancelButton';
import CatalogPlacement from '@zert-packages/components/shared/CatalogPlacement/CatalogPlacement';
import { FormattedMessage } from 'react-intl';
import getTranslation from '@zert-packages/utils/getTranslation.old';
import Scroll from '@zert-packages/components/shared/Scroll';
import getMultipleValue from '@zert-packages/utils/getMultipleValue';
import RichTextEditor from '@zert-packages/components/shared/RichTextEdit/RichTextEditor';
import { stateToHTML } from 'draft-js-export-html';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { TextField } from '@mui/material';
import DOMPurify from "dompurify";

class TemplateSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <select
        style={{
          color: '#0E3944',
          fontWeight: '500',
          background: 'none',
          border: 'none',
          width: '100%',
          margin: '6px 0 0 -4px'
        }}
        list_id="5"
        onChange={(e) => {
          this.props.onChange(e);
        }}
        value={this.props.selectedValue}
      >
        {this.props.rrtemplates.map((template, index) => {
          return (
            <option key={index} value={template.versionId}>
              {template.name}
            </option>
          );
        })}
      </select>
    );
  }
}

class NewRiskAssessmentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idtemplate: -1,
      name: '',
      description: null,
      defaultDescription: '',
      multipleValue: getMultipleValue(this.props.catalog),
      restricted: false,
      help: null
    };
    this.onClick = this.onClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectorChange = this.selectorChange.bind(this);
    this.handleChangeDesc = this.handleChangeDesc.bind(this);
    this.assignChagedValues = this.assignChagedValues.bind(this);
    this.setSelectedTemplate = this.setSelectedTemplate.bind(this);
  }

  assignChagedValues(data) {
    this.setState({ multipleValue: data });
    this.forceUpdate();
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.props.dispatch(loadRiskAssesmentTemplates());

    if (!this.props.catalogpermits['zert-risk-analysis']) {
      this.props.dispatch(getOverviewSettings('zert-risk-analysis'));
    }

    if (!this.props.helpText.riskassesment) {
      this.props.dispatch(loadHelp('riskassesment'));
      this.setState({
        versionId: params.versionId,
        activityId: params.activityid
      });
    } else {
      this.setState({
        help: this.props.helpText.riskassesment,
        versionId: params.versionId,
        activityId: params.activityid
      });
    }
    // if (!this.props.catalogPlacement) {
    //  this.props.dispatch(getCatalog());
    // }
  }

  onClick(e) {
    if (this.state.name == null || this.state.name.length === 0) {
      return;
    }
    if (this.state.idtemplate === -1) {
      this.setState({
        idtemplate: this.props.rrtemplates[0].versionId,
        defaultDescription:
          this.props.rrtemplates[0] && this.props.rrtemplates[0].description
            ? getTranslation(this.props.rrtemplates[0].description)
            : ''
      });
    }

    const riskAnal = {};

    riskAnal.name = this.state.name;
    riskAnal.description = this.state.description === null ? this.state.defaultDescription : this.state.description;
    riskAnal.restricted = this.state.restricted;
    this.props.dispatch(
      initRiskAssesment(
        this.state.idtemplate === -1 ? this.props.rrtemplates[0].versionId : this.state.idtemplate,
        riskAnal,
        this.state.multipleValue,

        this.props.storeInExplorer && this.props.selectedTreeNode ? this.props.selectedTreeNode.id : null,
        this.state.versionId,
        this.state.activityId
      )
    );
  }

  selectorChange(e) {
    this.setSelectedTemplate(e.target.value)
  }

  setSelectedTemplate(templateVersionId) {
    if (!this.props.rrtemplates) {
      return;
    }

    const template = this.props.rrtemplates.find((template) => Number(templateVersionId) === template.versionId);
    this.setState({
      idtemplate: templateVersionId,
      defaultDescription: template && template.description ? getTranslation(template.description) : '',
      instruction: template && template.instruction ? getTranslation(template.instruction) : ''
    });
  }

  componentDidUpdate(prevProps) {
    const basisinpath = window.location.href.indexOf('basis') >= 0;
    if (this.props.newElementCreated && !basisinpath) {
      this.props.history.push(`${API_ROOT_WAR}/risk-assesment/${this.props.newversionid}`);
      this.props.dispatch(elementCreatedAndLoaded());
    } else if (this.props.newElementCreated) {
      moveAfterDelivery(this.state.versionId, this.props.history, this.props.dispatch, this.state.activityId);
    } else if (this.state.help == null && this.props.helpText.riskassesment != null) {
      this.setState({ help: this.props.helpText.riskassesment });
    }
    if (this.props.rrtemplates != null && prevProps.rrtemplates == null && this.props.rrtemplates.length > 0) {
      const url = window.location.href;
      const parameters = new URLSearchParams(url.split('?')[1]);
      const urlTemplateVersionId = parameters.get('template-version-id');
      if (urlTemplateVersionId) {
        this.setSelectedTemplate(urlTemplateVersionId);
      } else {
        const firstTemplateVersionId = this.props.rrtemplates[0].versionId;
        this.setSelectedTemplate(firstTemplateVersionId);
      }
    }
  }

  handleChange(e) {
    this.setState({ name: e.target.value });
  }

  handleChangeDesc(e) {
    const html = stateToHTML(e.getCurrentContent());

    this.setState({ description: html });
  }

  render() {
    if (this.props.error != null) {
      return (
        <div>
          <FormattedMessage
            id="newpage.riskassesment.error"
            defaultMessage="Error loading risk assesment methods! {message}"
            values={{ message: this.props.error.message }}
          />
        </div>
      );
    }

    if (this.props.rrtemplates == null) {
      return (
        <Loader>
          <FormattedMessage id="newpage.riskassesment.loadingmethods" defaultMessage="Risk assessment methods" />
        </Loader>
      );
    }

    if (this.props.creatingNewElement) {
      return (
        <Loader loaderAction={LoaderAction.Creating}>
          <FormattedMessage id="newpage.riskassesment.creatingnew" defaultMessage="New risk assessment" />
        </Loader>
      );
    }

    let catalogAllowedContinue = true;
    if (this.props.catalogpermits != null && this.props.catalogpermits['zert-risk-analysis']) {
      const object = this.props.catalogpermits['zert-risk-analysis'];
      const setuped1 = !object.catalogElementId1 || (object.catalogElementId1 && this.state.multipleValue.length > 0);

      if (object.catalogStatus === 'any') {
        if (this.state.multipleValue.length === 0) {
          catalogAllowedContinue = false;
        }
      }
      /*  if (object.catalogStatus == 'and') {
          if (!setuped1 && object.catalogElementId1 || !setuped2 && object.catalogElementId2) {
            catalogAllowedContinue = false;
          }
        } */
      if (object.catalogStatus === 'or') {
        if (!setuped1) {
          catalogAllowedContinue = false;
        }
      }
    }

    return (
      <Scroll>
        <div className="workfield" style={{ height: 'unset' }}>
          <div
            className="left_panel"
            style={{
              flex: '1',
              backgroundColor: '#F4F8FA',
              padding: '0 0 0 24px',
              display: 'flex',
              flexDirection: 'column',
              minHeight: 'calc(var(--vh, 1vh) * 100 - 56px)'
            }}
          >
            <div
              className="editMember"
              style={{
                backgroundColor: '#F4F8FA',
                height: 'auto',
                boxShadow: 'none',
                flex: '10'
              }}
            >
              <div className="captionLabel">
                <FormattedMessage id="newpage.riskassesment.title" defaultMessage="Risk Assesment Title" />
                <span className="required">*</span>
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <TextField variant="standard" fullWidth value={this.state.name} onChange={this.handleChange} />
              </div>

              {this.props.catalog && (
                <>
                  <div className="captionLabel">
                    <FormattedMessage
                      id="newpage.riskassesment.placement"
                      defaultMessage="Placement in {catalog}"
                      values={{ catalog: this.props.catalog.name }}
                    />
                  </div>
                  <div className="edit" style={{ background: 'none' }}>
                    <CatalogPlacement
                      assignChagedValues={this.assignChagedValues}
                      multipleValue={this.state.multipleValue}
                      catalog={this.props.catalog}
                      loading={this.props.loadingCatalog}
                    />
                  </div>
                </>
              )}

              <div className="captionLabel">
                <FormattedMessage id="newpage.riskassesment.template" defaultMessage="Risk Assesment Template" />
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <TemplateSelector rrtemplates={this.props.rrtemplates} onChange={this.selectorChange}
                                  selectedValue={this.state.idtemplate}/>
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <FormControlLabel
                  control={
                    <Switch
                      value={!this.state.restricted}
                      checked={this.state.restricted}
                      onChange={(event) => {
                        this.setState({ restricted: event.target.value === 'true' });
                      }}
                    />
                  }
                  label={<FormattedMessage id="NewRiskAssementPage.Restricted" defaultMessage="Restricted" />}
                />
              </div>
              <div className="captionLabel">
                <FormattedMessage id="newpage.riskassesment.description" defaultMessage="Additional Description" />
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <RichTextEditor
                  key={`autogrowInput${this.state.idtemplate}`}
                  text={this.state.defaultDescription}
                  onChange={this.handleChangeDesc}
                  onBlur={this.onDescBlur}
                />
              </div>
            </div>

            <div
              style={{
                flex: '1',
                justifyContent: 'flex-end',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div className="createNewButtons">
                <ButtonRM
                  styleName="primary"
                  whenClick={this.onClick}
                  disabled={this.state.name === null || this.state.name.length === 0 || !catalogAllowedContinue}
                  text=""
                >
                  <FormattedMessage id="newpage.riskassesment.create" defaultMessage="Create" />
                </ButtonRM>
                <CancelButton />
              </div>
            </div>
          </div>

          <div
            id="right_panel"
            style={{
              flex: '1',
              padding: '24px'
            }}
          >
            <div
              className="checklistDesc"
              dangerouslySetInnerHTML={{ __html: this.state.help ? this.state.help : '' }}
            />
            {this.state.instruction && (
              <div className="checklistDesc">
                <div className="checklistNewDescHeader">
                  <FormattedMessage id="newpage.riskassesment.instruction" defaultMessage="Instruction" />
                </div>
                <div
                  className="checklistNewDescSubHeader"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(this.state.instruction)
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Scroll>
    );
  }
}

const mapStateToProps = (state) => ({
  helpText: state.helpText,
  loading: state.loading,
  creatingNewElement: state.creatingNewElement,
  newElementCreated: state.newElementCreated,
  catalog: state.catalog,
  newversionid: state.newversionid,
  rrtemplates: state.rrtemplates,
  selectedTreeNode: state.CORE.selectedNode,
  catalogpermits: state.catalogpermits,
  loadingCatalog: Object.values(state.loadingCatalogs)
    .reduce((a, v) => a || v, false)
});

export default withRouter(connect(mapStateToProps)(NewRiskAssessmentPage));
