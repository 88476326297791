import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as React from 'react';
import ConfirmPopper from '@zert-packages/components/shared/ConfirmPopper.tsx';

export interface EditableTypographyProps {
  onSave: (value: any) => void;
  disabled: boolean;
}

function InputBaseWithChildren({
  text,
  children,
  ...props
}: InputBaseProps & {
  text: string;
  children?: React.ReactNode;
}) {
  const spanRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef(null);
  const [width, setWidth] = useState<string | number>(0);

  useLayoutEffect(() => {
    if (spanRef.current) {
      setWidth(
        spanRef.current.getBoundingClientRect().width === 0 ? '' : spanRef.current.getBoundingClientRect().width + 10
      );
    }
  }, [spanRef.current && spanRef.current.getBoundingClientRect().width]);

  return (
    <>
      <InputBase
        {...props}
        className=""
        value={text}
        inputRef={inputRef}
        inputProps={{ className: props.className }}
        style={{ width, marginRight: typeof width === 'string' ? 10 : '' }}
      />
      <span ref={spanRef} {...props} style={{ visibility: 'hidden', position: 'absolute', top: 0 }}>
        {inputRef.current && inputRef.current.value}
      </span>
    </>
  );
}

function EditableTypography({ onSave, disabled, ...props }: EditableTypographyProps & TypographyProps) {
  const [initialText, setInitialText] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let value = '';
    if (props.children) {
      if (typeof props.children === 'string' || typeof props.children === 'number') {
        value = props.children.toString();
        setText(value);
        setInitialText(value);
      }
    }
  }, []);

  const value = props.children || '';

  const onChange = (value: string) => {
    if (value === text) {
      return;
    }
    setText(value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleClick = (event) => {
    if (event.type === 'click' && !disabled) {
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
      setOpen(true);
    }
  };

  const handleSave = () => {
    onSave(text);
    setInitialText(text);
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCancel = () => {
    setOpen(false);
    setAnchorEl(null);
    setText(initialText);
  };

  return (
    <>
      <ConfirmPopper open={open} anchorEl={anchorEl} confirmAction={handleSave} cancelAction={handleCancel} />
      <Typography
        {...props}
        children={value}
        component={InputBaseWithChildren}
        disabled={disabled}
        text={text}
        onChange={handleChange}
        onClick={handleClick}
        color={open ? '#01A1C7' : props.color}
        sx={{
          '&.Mui-disabled': {
            '-webkit-text-fill-color': 'rgb(75, 75, 75)', // or any color you want
            opacity: 1 // Override default opacity for disabled state
          },
          textDecoration: open ? 'underline' : 'none',
          '&:hover': {
            textDecoration: 'underline'
          }
        }}
      />
    </>
  );
}

export default EditableTypography;
