import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl  } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Select } from '@mui/material';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { fetchRiskAssessmentTemplates } from '@zert-packages/actions/riskassesmentActions';
import { duplicateRiskAnalysesApi } from '@zert-packages/plugins/ExplorerPlugin/API';
import CheckboxWithMemo from '@zert-packages/components/common/CheckboxWithMemo';
import { showSnackbarError } from '@zert-packages/components/Snackbars';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import DuplicateRiskRows from '@zert-packages/components/ElementTile/DuplicationDialogs/dialogs/DuplicateRiskRows';
import { handleQuery } from '@zert-packages/actions/api';
import getLang from '@zert-packages/utils/getLang';
import NameTextFieldWithMemo from '../components/NameTextFieldWithMemo';
import DuplicationDefaultDialog from '../components/DuplicationDefaultDialog';

const getDefaultState = (element) => ({
  name: element.name,
  versionId: element.versionId,
  removeMeasures: false,
  removeResponsible: true,
  removeDeadline: true,
  removeParticipants: true,
  removeSessions: true,
  templateVersionId: -1
});

const getRiskRowTemplateByRAVersionId = (versionId) =>
  handleQuery(`/riskrowtemplate/riskRowTemplateByRiskAnalysisId/${getLang()}/${versionId}`);

const getRiskRowTemplateByVersionId = (versionId) =>
  handleQuery(`/riskrowtemplate/riskRowTemplateByVersionId/${getLang()}/${versionId}`);

function RiskAnalyses({ unmountComponent, onFinish, element }) {
  const snackbar = useSnackbar();
  const [state, setState] = useState(getDefaultState(element));
  const [template, setTemplate] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [loading, setLoading] = useState(false);
  const [RATemplates, setRATemplates] = useState(null);
  const updateStateField = async (field, value) => {
    setState((prev) => ({
      ...prev,
      [field]: value
    }));
    if (field == 'templateVersionId' && value != -1) {
      setLoading(true);
      const template = await getRiskRowTemplateByVersionId(value);
      setSelectedTemplate(template);
      setLoading(false);
    }
  };
  useEffect(() => {
    // fetchRiskAssessmentTemplates()
    getRiskRowTemplateByRAVersionId(state.versionId)
      .then((template) => {
        setTemplate(template);
        return updateStateField('templateVersionId', template.versionId);
      }).then(r => {
        return fetchRiskAssessmentTemplates();
      })
      .then((r) => {
        setRATemplates([{ id: -1, versionId: -1, name: '-' }, ...r]);
      })
      .catch((e) => {
        showSnackbarError(snackbar, e.message);
      });
  }, []);

  const onDuplicate = () => {
    const cellsMoved = [];

    if (template && template.versionId != state.templateVersionId) {
      template.columns.map((column) => {
        cellsMoved.push({ columnIdentifier: column.identifier });
      });

      renderInExceptionRoot(DuplicateRiskRows, {
        columnsToFill: selectedTemplate.columns,
        cellsMoved,
        riskRowTemplateId: template.versionId,
        onFinish: async (cellsMoved) => {
          setLoading(true);

          duplicateRiskAnalysesApi({ ...state, cellsMoved, snackbar })
            .then((res) => {
              setLoading(false);
              unmountComponent();
              onFinish(res);
            })
            .catch((e) => {
              showSnackbarError(snackbar, e.message);
              setLoading(false);
            });
        }
      });
    } else {
      setLoading(true);

      duplicateRiskAnalysesApi({ ...state, snackbar })
        .then((res) => {
          setLoading(false);
          unmountComponent();
          onFinish(res);
        })
        .catch((e) => {
          showSnackbarError(snackbar, e.message);
          setLoading(false);
        });
    }
  };
  const onDuplicateDisabled = `${state.name}`.trim().length === 0;
  const disabled = loading || !RATemplates || !template || (!selectedTemplate && state.templateVersionId != -1);
  const intl = useIntl()
  return (
    <DuplicationDefaultDialog
      unmountComponent={unmountComponent}
      onDuplicate={onDuplicate}
      onDuplicateDisabled={onDuplicateDisabled}
      loading={loading || !RATemplates}
      customDialogContent={
        <>
          <NameTextFieldWithMemo
            autoFocus={!disabled}
            disabled={disabled}
            defaultValue={intl.formatMessage(
              {id: "RA.duplicate.copyof", defaultMessage:"Copy of {name}"},
              {name: state.name.toLocaleString()}
            )}
            onChange={(e) => updateStateField('name', e.target.value)}
          />
          {RATemplates && (
            <FormControl>
              <InputLabel>
                <FormattedMessage id="DuplicationDialogs.RiskAnalyses.Template" defaultMessage="Template" />
              </InputLabel>
              <Select
                disabled={disabled}
                onChange={(e) => updateStateField('templateVersionId', e.target.value)}
                value={state.templateVersionId}
                autoWidth
                input={<Input shrink />}
              >
                {RATemplates.map((item) => (
                  <MenuItem value={item.versionId} index={item.versionId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <CheckboxWithMemo
            disabled={disabled}
            label={
              <FormattedMessage id="DuplicationDialogs.RiskAnalyses.RemoveMeasures" defaultMessage="Remove measures" />
            }
            isChecked={state.removeMeasures}
            onChange={(checked) => updateStateField('removeMeasures', checked)}
          />
          <CheckboxWithMemo
            disabled={disabled}
            label={
              <FormattedMessage
                id="DuplicationDialogs.RiskAnalyses.RemoveResponsible"
                defaultMessage="Remove responsible persons"
              />
            }
            isChecked={state.removeResponsible}
            onChange={(checked) => updateStateField('removeResponsible', checked)}
          />
          <CheckboxWithMemo
            disabled={disabled}
            label={
              <FormattedMessage id="DuplicationDialogs.RiskAnalyses.RemoveDeadline" defaultMessage="Remove deadlines" />
            }
            isChecked={state.removeDeadline}
            onChange={(checked) => updateStateField('removeDeadline', checked)}
          />
          <CheckboxWithMemo
            disabled={disabled}
            label={
              <FormattedMessage
                id="DuplicationDialogs.RiskAnalyses.RemoveParticipant"
                defaultMessage="Remove participants"
              />
            }
            isChecked={state.removeParticipants}
            onChange={(checked) => updateStateField('removeParticipants', checked)}
          />
          <CheckboxWithMemo
            disabled={disabled}
            label={
              <FormattedMessage id="DuplicationDialogs.RiskAnalyses.RemoveSessions" defaultMessage="Remove sessions" />
            }
            isChecked={state.removeSessions}
            onChange={(checked) => updateStateField('removeSessions', checked)}
          />
        </>
      }
    />
  );
}

export default RiskAnalyses;
