import makeStyles from '@mui/styles/makeStyles';
import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import MatrixInlineView from '@zert-packages/components//common/RiskAssesmentEdit/MatrixInlineView';
import { getStatusIcon } from '@zert-packages/components/common/RiskAssesmentEdit/helpers/getStatusIcon';
import { getRiskRowAsync } from '@zert-packages/actions/measureActions';
import { calculateRowOverview } from '@zert-packages/actions/overviewCoverage';
import { handleQuery } from '@zert-packages/actions/api';
import cn from 'classnames';
import { ElementContentOverviewTile, OverviewTile, TileIcon } from './AbstractTile';
import withTile from '../shared/withTile';

const BACKGROUND_COLOR = '#dadada';

const useStyles = makeStyles({
  '@keyframes opacityAnimation': {
    '0%': {
      opacity: 0.6
    },
    '50%': {
      opacity: 0.85
    },
    '100%': {
      opacity: 0.6
    }
  },
  root: {
    height: 50,
    marginBottom: 5,
    boxSizing: 'border-box',
    padding: '8px 2px',
    animation: `$opacityAnimation 2s infinite`,
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 2fr 1fr'
  },
  bar: {
    background: BACKGROUND_COLOR,
    borderRadius: 3
  },
  riskRow: {},
  measureRow: {
    alignItems: 'flex-start',
    paddingLeft: 4
  },
  matrix: {
    width: '50px',
    height: '50px',
    borderRadius: 2,
    background: BACKGROUND_COLOR
  },
  measures: {
    height: '100%',
    alignItems: 'center'
  },
  measureStatus: {
    width: 20,
    height: 20,
    marginBottom: 5
  },
  measure: {
    width: 40,
    height: 20,
    marginBottom: 5
  }
});

const LoadingRiskRowTile = memo(
  forwardRef((props, ref) => {
    const cl = useStyles();
    return (
      <div ref={ref} className={cl.root}>
        <div className={cl.riskRow}>
          <div className={cl.matrix} />
        </div>
        <div className={cl.measures}>
          <div className={cn(cl.bar, cl.measureStatus)} />
          <div className={cn(cl.bar, cl.measure)} />
        </div>
        <div className={cl.measureRow}>
          <div className={cl.matrix} />
        </div>
      </div>
    );
  })
);

const observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
};

function RiskRowTile(props) {
  const [riskRow, setRiskRow] = useState(null);
  const [templateBundle, setTemplateBundle] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [canMount, setCanMount] = useState(false);
  const replacerRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, observerOptions);
    observer.observe(replacerRef.current);
    return () => observer.disconnect();
  }, []);

  const observerCallback = ([entry]) => {
    if (entry.intersectionRatio > 0.1) {
      setCanMount(true);
    }
  };

  useEffect(() => {
    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    getRiskRowAsync(props.element.id)
      .then((result) => {
        setRiskRow(result);
        return handleQuery(`/measure/getAllTemplates/${locale}/${result.status.versionId}`);
      })
      .then((result) => {
        setTemplateBundle(result);
        setLoaded(true);
      });
  }, []);

  if (!riskRow || !templateBundle || !loaded) {
    return <LoadingRiskRowTile ref={replacerRef} />;
  }

  const overview = calculateRowOverview(riskRow.cells, riskRow.positionHistory, false, templateBundle.riskRowTemplate);

  const residual = calculateRowOverview(riskRow.cells, riskRow.positionHistory, true, templateBundle.riskRowTemplate);

  return (
    <div className="risk_row_container risk_row_tile">
      <div className="risk_row_container_two">
        <div>
          {overview != null && (
            <MatrixInlineView
              id={overview.col.identifier}
              column={overview.col}
              riskrow={riskRow}
              isRed={overview.isRed}
              isYellow={overview.isYellow}
              isGrey={!overview.isRed && !overview.isYellow}
              cell={overview.cell}
            />
          )}
        </div>
      </div>

      <div className="step_indent_container">
        <div>
          {riskRow.measures.map((measure) => {
            return getStatusIcon(measure, templateBundle.measuresWorkflowProcess);
          })}
        </div>
        <div className="measure_caption">
          <FormattedMessage
            id="riskrowinline.measure"
            defaultMessage="{count} measure{predicate}"
            values={{
              count:
                riskRow.measures.length === 0 ? (
                  <FormattedMessage id="riskrowinline.count" defaultMessage="No" />
                ) : (
                  riskRow.measures.length
                ),
              predicate:
                riskRow.measures.length === 1 ? (
                  ''
                ) : (
                  <FormattedMessage id="riskrowinline.predicate" defaultMessage="s" />
                )
            }}
          />
        </div>
      </div>

      <div className="step_indent_container_two">
        <div>
          {residual && riskRow.measures.length > 0 && (
            <MatrixInlineView
              id={residual.col.identifier}
              column={residual.col}
              riskrow={riskRow}
              isRed={residual.isRed}
              isYellow={residual.isYellow}
              isGrey={!residual.isRed && !residual.isYellow}
              cell={residual.cell}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default withTile(TileIcon, OverviewTile, ElementContentOverviewTile, null, null, RiskRowTile);
