import React, { memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '1.1rem',
    [theme.breakpoints.down('tablet')]: {
      fontSize: '1rem'
    },
    color: 'white',
    padding: '2px 0',
    fontFamily: `'Open Sans', sans-serif`,
    transition: 'all 0.5s ease',
    transformOrigin: '50% 100%',
    userSelect: 'none',
    gridArea: '1 / 3',
    wordBreak: 'break-word'
  },
  rootShow: {
    transform: 'scale(1)'
  }
}));

function UserNameLabel({ name }) {
  return <div className={useStyles().root}>{name}</div>;
}

export default memo(UserNameLabel);
