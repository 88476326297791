import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  Button
} from "@mui/material";
import SearchPane from "@zert-packages/plugins/ExplorerPlugin/SearchPane";
import { useSnackbar } from "notistack";
import { showSnackbarError, showSnackbarInfo } from "@zert-packages/components/Snackbars";
import {
  createSearchFolderAPI,
  getSearchFolderApi,
  updateSearchFolderAPI
} from "@zert-packages/plugins/ExplorerPlugin/API";
import Loader from "@zert-packages/components/shared/Loader/Loader";
import { useSelector } from "react-redux";
import { getCriterias } from "@zert-packages/plugins/ExplorerPlugin/helpers/getCriterias";

function EditSearchFolderDialog({ unmountComponent, id, initFunction,
                                  originalName}) {
  const [name, setName] = useState(originalName);
  const [loading, setLoading] = useState(true);
  const snackbar = useSnackbar();
  const { searchConditions } = useSelector(state => state.CORE);

  const intl = useIntl();
  const nameFieldId = "EditSearchFolderDialog.NameField";
  const nameFieldDefault = "Search folder name";
  const savingMess = intl.formatMessage({
    id:"EditSearchFolderDialog.savingMess",
    defaultMessage: "Saving search folder"})
  console.log("rendering search folder edit");

  useEffect(() => {
    const queryRes = initFunction(id).then(() => setLoading(false));
  },[])

  const handleConfirm = () => {
    showSnackbarInfo(snackbar, savingMess);
    const searchCriteria = getCriterias(searchConditions, false);
    console.log(searchCriteria);
    try {
      updateSearchFolderAPI(id, name, searchCriteria).then(
        r => {
          showSnackbarInfo(snackbar, "Search folder saved!");
          return r;
        }
      ).then(r => unmountComponent());
    } catch (e) {
      showSnackbarError(snackbar, e.message);
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" open onClose={unmountComponent}>
      <DialogTitle>
        <FormattedMessage id="EditSearchFolderDialog.Title" defaultMessage="Edit search folder" />
      </DialogTitle>
      <DialogContent>
        <div style={{padding: "10px"}}>
        <TextField id="outlined-basic"
                   label={intl.formatMessage({id: nameFieldId,
                     defaultMessage: nameFieldDefault})}
                   variant="outlined"
                   value={name}
                   onChange={e => setName(e.target.value)}
                   fullWidth
        />
        </div>
        {loading
          ? <Loader />
          : <SearchPane startSearchOnSubmit={false}
                        isAllCompanies={false}
                        setAllCompanies={e => {}}
                        ignoreLocalStorage={true}
          />}

      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} disabled={false} color="primary" variant="outlined">
          <FormattedMessage id="EditSearchFolderDialog.Confirm" defaultMessage="Confirm" />
        </Button>
        <Button onClick={unmountComponent} color="primary">
          <FormattedMessage id="EditSearchFolderDialog.Cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditSearchFolderDialog;