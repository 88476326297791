export const getCriterias = (searchConditions, isAllCompanies) => {
  const criterias = [];
  for (const [key, value] of Object.entries(searchConditions)) {
    if (value.expanded && value.values.length > 0) {
      var newValues = [];
      value.values.map((value) => {
        if (Array.isArray(value.value)) {
          value.value.map((val, index) => {
            newValues.push({
              key: `${value.key}[${index}]`,
              value: val
            });
          });
          newValues.push({
            key: `${value.key}Count`,
            value: value.value.length
          });
        } else {
          newValues.push(value);
        }
      });
      criterias.push({
        className: key,
        values: newValues
      });
    }
  }
  const search = {
    criterias,
    allCompanies: isAllCompanies
  };
  return search;
};
