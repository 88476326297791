import { saveFormatTemplate } from "../API";


export const saveTemplate = async (versionId, formatTemplate, values, selectedListItem, selectedTab, leftMenu) => {
  const templateName = values.typeList;
  // console.log("data", { values, formatTemplate, templateName, selectedListItem, selectedTab })

  const templateList = templateName === "hiddenFormats"
    ? values.templateList
    : leftMenu.reduce((acu, cur, idx) => {
      if (idx === selectedListItem) {
        return [...acu, values.templateList];
      }
      return [...acu, cur];
    }, []);

  const data = { ...formatTemplate, [templateName]: templateList };
  const res = await saveFormatTemplate(versionId, data);
  return res;
};