import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function DeleteConfirmDialog({ open, onDelete, handleClose, customTitle = '', customMessage = '' }) {
  const handleCancel = () => handleClose();
  const handleConfirm = () => {
    onDelete();
    handleClose();
  };
  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>
        {customTitle !== '' ? (
          customTitle
        ) : (
          <FormattedMessage id="deleteconfirm.dialog.title" defaultMessage="Delete Dialog" />
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {customMessage !== '' ? (
            customMessage
          ) : (
            <FormattedMessage
              id="deleteconfirm.dialog.areyousur"
              defaultMessage="Are you sure that you want to delete this?"
            />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          <FormattedMessage id="deleteconfirm.dialog.confirm" defaultMessage="Confirm" />
        </Button>
        <Button onClick={handleCancel} color="primary">
          <FormattedMessage id="deleteconfirm.dialog.cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmDialog;
