import { FormattedMessage } from 'react-intl';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { generateDeviationReport } from '@zert-packages/actions/deviationActions';
import generalRadioBtns from './generalRadioBtns';
import { generalDateSelectors } from './dateSelectors';

export default function useDeviationReport({ selectedNode, selectedValues, catalog }) {
  const caption = <FormattedMessage id="reports.submit" defaultMessage="Submit Overview" />;

  const index = 'deviation';
  const dispatch = useDispatch();

  const description = (
    <FormattedMessage
      id="submits.report.description"
      defaultMessage="This report generates a list of all submits located on the selected node."
    />
  );

  const radiobtns = generalRadioBtns;
  // eslint-disable-next-line no-use-before-define
  const dateSelectors = generalDateSelectors;

  const selectors = [
    {
      name: 'includeModeDev',
      label: <FormattedMessage id="reports.deviation.includeMode" defaultMessage="Include" />,

      selected: 'CREATED_BETWEEN',
      section: 'time',
      options: [
        {
          value: 'CREATED_BETWEEN',

          label: <FormattedMessage id="reports.deviation.created_between" defaultMessage="Created between" />
        },
        {
          value: 'CLOSED_BETWEEN',
          label: <FormattedMessage id="reports.deviation.closed_between" defaultMessage="Closed between" />
        },
        {
          value: 'ACTIVE_AND_CLOSED_BETWEEN',
          label: (
            <FormattedMessage id="reports.deviation.active_closed_between" defaultMessage="ACTIVE AND CLOSED BETWEEN" />
          )
        }
      ]
    },
    {
      name: 'sortBy',
      label: <FormattedMessage id="reports.deviation.sortBy" defaultMessage="Sort by" />,
      section: 'order',
      selected: 'ID',
      options: [
        {
          value: 'ID',

          label: <FormattedMessage id="reports.deviation.sortBy.Id" defaultMessage="Id" />
        },
        {
          value: 'NAME',
          label: <FormattedMessage id="reports.deviation.sortBy.Name" defaultMessage="Name" />
        },
        {
          value: 'CREATED_AT',
          label: <FormattedMessage id="reports.deviation.sortBy.CREATED_AT" defaultMessage="Created At" />
        },
        {
          value: 'STATUS',
          label: <FormattedMessage id="reports.deviation.sortBy.STATUS" defaultMessage="Status" />
        },
        {
          value: 'CURRENT_OWNER',
          label: <FormattedMessage id="reports.deviation.sortBy.CURRENT_OWNER" defaultMessage="Current owner" />
        }
      ]
    }
  ];

  const checkBoxes = [
    {
      name: 'cb_include_archived',
      label: <FormattedMessage id="reports.includeArchied" defaultMessage="Include archived" />,
      checked: false,
      section: 'scope'
    },
    {
      name: 'cb_include_table',
      label: <FormattedMessage id="reports.includeTable" defaultMessage="Include table" />,
      checked: false
    },
    {
      name: 'cb_include_placement',
      label: <FormattedMessage id="reports.includeDiagram" defaultMessage="Include status diagram for placement" />,
      checked: true
    },
    {
      name: 'cb_include_responsible',
      label: (
        <FormattedMessage
          id="reports.includeResponsible"
          defaultMessage="Include status diagram for responsible person"
        />
      ),
      checked: true
    }
  ];

  const generateReport = (checkedItems) => {
    let selectedIds = [];
    if (selectedValues) {
      selectedIds = selectedValues.map((value) => value.versionId);
    }

    const settings = {
      from: checkedItems('reports.from'),
      to: checkedItems('reports.to'),
      includeMode: checkedItems('includeModeDev'),
      includePlacement: checkedItems('cb_include_placement'),
      includeResponsible: checkedItems('cb_include_responsible'),
      includeTable: checkedItems('cb_include_table'),

      sortColumn: checkedItems('sortBy'),
      templates: [],
      versionIds: selectedIds
    };
    dispatch(
      generateDeviationReport(
        {
          name: caption,
          reportUUID: uuidv4()
        },
        catalog && catalog.info ? catalog.info.versionId : -1,
        selectedNode || 'none',
        checkedItems('reports.subnode'),
        settings
      )
    );
  };

  return {
    index,
    generateReport,
    caption,
    description,
    radiobtns,
    dateSelectors,
    selectors,
    checkBoxes,
    key: 'deviation-report'
  };
}
