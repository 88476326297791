export default function moveItemInArray(initialArray, fieldOfId, selectedId, position) {
  const currentArray = [...initialArray];
  let indexOfCurrentItem;

  if (fieldOfId !== 'index') {
    indexOfCurrentItem = currentArray.findIndex((item) => item[fieldOfId] === selectedId);
  } else {
    indexOfCurrentItem = selectedId;
  }

  const cannotMoveUp = indexOfCurrentItem === 0 && position === -1;
  if (cannotMoveUp) return initialArray;

  const lengthOfArray = initialArray.length - 1;

  const cannotMoveDown = lengthOfArray === indexOfCurrentItem && position === 1;
  if (cannotMoveDown) return initialArray;

  const selectedItem = currentArray.splice(indexOfCurrentItem, 1)[0];
  currentArray.splice(indexOfCurrentItem + position, 0, selectedItem);

  return currentArray;
}
