import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";
import SettingDialog from "./dialogs/SettingDialog";
import { loadLayout } from "../TranslationJobPlugin/tjReducers";
import MimeTypeDialog from "./dialogs/MimeTypeDialog";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogSettings from "../formatTemplatePlugin/dialog/dialogSettings/DialogSettings";
import { LayoutContext } from "./LayoutView";
import { getTranslation } from "@zert-packages/utils";

export const formStyle = (theme) =>
  createStyles({
    divRoot: {
      display: "flex",
      height: "25rem"
    },
    divButtons: {
      width: 35,
      marginLeft: 5,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      "& button": {
        padding: 9
      }
    },
    divContentContainer: {
      border: "1px solid lightgrey",
      width: "95%",
      height: "100%",
      padding: 5
    },
    listStyle: {
      "&.MuiTypography-body1": {
        fontSize: "1em"
      }
    },
    activeRow: {
      background: "#01A1C7",
      "& span": {
        color: "#ffffff"
      }
    },
    rowItem: {
      cursor: "pointer"
    },
    listItem: {
      padding: '0.5px 16px',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    }
  });

const useStyles = makeStyles(formStyle);

const actionData = {
  selectedType: null,
  openTypeDialog: false,
  openSettingTypeDialog: false,
  chosenSettingType: null
};

function SettingTab() {
  const classes = useStyles();
  const CLM = useSelector((state) => state.CLM);
  const [action, setAction] = useState(actionData);
  const [layout, setLayout] = useState(CLM.layout);
  const dispatch = useDispatch();
  const { values, setValues } = useContext(LayoutContext);


  const getMimeTypesNameValue = (el) => {
    const data = el.split("-");
    return data[1];
  };

  const getTypesNameValue = (el) => {
    const data = el.split("$");
    return data[1];
  };

  const handleListItemClick = (event, value) => {
    if (action.selectedType !== null && action.selectedType === value) {
      setAction((prevState) => ({
        ...prevState,
        selectedType: null
      }));
    } else {
      setAction((prevState) => ({
        ...prevState,
        selectedType: value
      }));
    }
  };

  const openTypeDialog = () => {
    setAction((prevState) => ({
      ...prevState,
      openTypeDialog: !action.openTypeDialog
    }));
  };


  console.log("values", { action, values, layout });


  const handleSettingDialog = (val) => {
    const properties = values.template.properties || [];
    setValues(pre => ({
      ...pre,
      template: { properties: [...properties, val] }
    }));
  };

  const editSetting = (val) => {
    setValues(pre => ({
      ...pre,
      template: {
        properties: pre.template.properties.reduce((acu, cur, idx) => {
          if (idx === values.indexSettings) {
            return [...acu, val];
          }
          return [...acu, cur];
        }, [])
      }
    }));
  };


  const openSettingTypeDialog = (isEdit) => {

    if (isEdit) {
      return renderInExceptionRoot(DialogSettings, {
        label: "Edit property",
        formats: values.template.properties,
        selectedSettings: values.template.properties[values.indexSettings],
        indexSelected: values.indexSettings,
        setValues,
        handleSettingDialog: editSetting
      });
    }

    renderInExceptionRoot(DialogSettings, {
      label: "Add property",
      selectedSettings: {
        class: "se.zert.backend.rest.common.pojo.ClientSettingsTemplate$Text"
      },
      addSetting: true,
      setValues,
      handleSettingDialog
    });
  };

  const removeMimeType = () => {
    setValues((prevState) => ({
      ...prevState,
      supportedMimeTypes: prevState.supportedMimeTypes.filter((el) => el !== action.selectedType)
    }));
  };

  const successTypeHandler = (value) => {
    setValues((prevState) => ({
      ...prevState,
      supportedMimeTypes: [...prevState.supportedMimeTypes, value]
    }));
  };


  const choseSettingType = (el, idx) => {
    if (action.chosenSettingType !== null && action.chosenSettingType === el) {
      setValues(pre => ({ ...pre, indexSettings: -1 }));
      setAction((prevState) => ({
        ...prevState,
        chosenSettingType: null
      }));
    } else {
      setAction((prevState) => ({
        ...prevState,
        chosenSettingType: el
      }));
      setValues(pre => ({ ...pre, indexSettings: idx }));
    }
  };

  const deleteSettingHandler = () => {
    setValues((prevState) => ({
      ...prevState,
      template: {
        properties: layout.template.properties.filter((el) => el !== action.chosenSettingType)
      }
    }));
  };

  const successHandlerData = (el, isNew) => {
    if (isNew) {
      setLayout((prevState) => ({
        ...prevState,
        template: {
          properties: [...prevState.template.properties, el]
        }
      }));
    } else {
      setLayout((prevState) => ({
        ...prevState,
        template: {
          properties: prevState.template.properties.map((item) => {
            if (item.identifier === el.identifier) {
              return el;
            }
            return item;
          })
        }
      }));
    }
  };

  useEffect(() => {
    dispatch(loadLayout(layout));
  }, [layout]);


  return (
    <div>
      <Typography variant="h6" tyle={{ marginTop: 5 }}>Settings:</Typography>
      <div className={classes.divRoot}>
        <div className={classes.divContentContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.template.properties.map((el, idx) => {
                return (
                  <TableRow
                    key={idx}
                    onClick={() => choseSettingType(el, idx)}
                    className={(action.chosenSettingType === el) ? classes.activeRow : classes.rowItem}
                  >
                    <TableCell>
                      <span>{el.labelObject ? getTranslation(el.labelObject) : el.identifier}</span>
                    </TableCell>
                    <TableCell><span>{getTypesNameValue(el.class)}</span></TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <div className={classes.divButtons}>
          <Tooltip title="add">
            <IconButton aria-label="add" size="large" color="primary">
              <AddIcon fontSize="inherit" onClick={() => openSettingTypeDialog(false)} />
            </IconButton>
          </Tooltip>
          <Tooltip title="edit">
            <IconButton aria-label="edit" size="large" color="primary" disabled={action.chosenSettingType === null}>
              <EditIcon fontSize="inherit" onClick={() => openSettingTypeDialog(true)} />
            </IconButton>
          </Tooltip>
          <Tooltip title="delete">
            <IconButton aria-label="delete" size="large" color="primary" disabled={action.chosenSettingType === null}>
              <DeleteIcon fontSize="inherit" onClick={deleteSettingHandler} />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <Typography variant="h6" style={{ marginTop: 10 }}>Element types where the layout is used:</Typography>
      <div className={classes.divRoot}>
        <div className={classes.divContentContainer}>
          <List>
            {values.supportedMimeTypes.map((el, idx) => {
              return (
                <ListItem
                  key={idx}
                  component="div"
                  disablePadding
                  className={classes.listItem}
                  style={{  textTransform: "capitalize", width: "100%" }}
                >
                  <ListItemText
                    disableTypography
                    style={action.selectedType !== null && action.selectedType === el ? { background: "#01A1C7" } : {}}
                    button
                    selected={action.selectedType !== null && action.selectedType === el}
                    onClick={(event) => handleListItemClick(event, el)}
                    primary={
                      <Typography
                        style={
                          action.selectedType !== null && action.selectedType === el
                            ? {
                              fontSize: "1em",
                              color: "#ffffff"
                            }
                            : {
                              fontSize: "1em",
                              color: "black"
                            }
                        }
                      >
                        {getMimeTypesNameValue(el)}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
        <div className={classes.divButtons}>
          <Tooltip title="add">
            <IconButton aria-label="add" size="large" color="primary">
              <AddIcon fontSize="inherit" onClick={openTypeDialog} removeMimeType />
            </IconButton>
          </Tooltip>
          <Tooltip title="delete">
            <IconButton aria-label="delete" size="large" color="primary" disabled={action.selectedType === null}>
              <DeleteIcon fontSize="inherit" onClick={removeMimeType} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {action.openTypeDialog && <MimeTypeDialog handleClose={openTypeDialog} successHandler={successTypeHandler} />}
      {action.openSettingTypeDialog && (
        <SettingDialog
          handleClose={openSettingTypeDialog}
          successHandler={successHandlerData}
          el={
            action.chosenSettingType === null
              ? null
              : layout.template.properties.find((el) => el === action.chosenSettingType)
          }
        />
      )}
    </div>
  );
}

export default SettingTab;
