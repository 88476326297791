import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog} from "@mui/material";
import {addNewTranslations, getActiveLocalesWithSort, getTranslations} from "../../BlockPlugin/API";
import Autocomplete from "@mui/material/Autocomplete";
import {DeleteSharp} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {FormattedMessage} from "react-intl";
import AddButtonComp from "./AddButtonComp";
import AddIcon from "@mui/icons-material/Add";
import {createPhraseApi} from "@zert-packages/components/InfoElement/components/API";
import {addTermSpec, initTermDb, storeTerm} from "../API";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 262,
    padding: 5,
    paddingBottom: 10,
    background: "#E2F2F6",
    minHeight: 168,
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "flex-end",
    "& svg": {
      cursor: "pointer"
    }
  },
  wrapper: {

  },
  wrapperList: {
    marginTop: 5,
    border: '1px solid lightgray',
    minHeight: 100,
    padding: 5,
  },
  togetherTitle: {
    fontWeight: 500,
    margin: '5px 0px 5px 2px',
  },
  listItem: {
    cursor: "pointer",
    '&:hover':{
      backgroundColor: '#CECECE',
    }
  },
}));

const ConfirmDeleteDialog = ({approveDelete, item}) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [text, setText] = useState('')




  const handleClose = () => {
    setOpen(false);
  };


  const handleDelete = async () =>{

      await approveDelete(item)
      handleClose()

  }


  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapper}>
      <div className={cl.wrapperTitle}>
        <CloseIcon onClick={handleClose} />
      </div>
      <div className={cl.wrapperList}>
        <div className={cl.wrapperTitle}>
          <FormattedMessage id="CLM.ConfirmDeleteDialog.title" defaultMessage="Delete phrase"/>
        </div>

        <div>
          <Typography variant={'h4'} color={"primary"}>
            <div style={{display: "flex", alignItems: "center"}}>
              <div>
                <FormattedMessage id="CLM.ConfirmDeleteDialog.confirmDelete" defaultMessage="Confirm deleting phrase"/>
              </div>
              <div style={{marginLeft: "15px", display: "flex", alignItems: "center"}}>
                <div >
                  {`" ${item.name}"`}
                </div>
              </div>

            </div>

          </Typography>
        </div>
      </div>

      <div>

        <div style={{margin: "15px 0 0"}}>
          <AddButtonComp Icon={AddIcon} handle={handleDelete}
                         label={<FormattedMessage id="CLM.ConfirmDeleteDialog.Delete"
                                                  defaultMessage="Delete"/>}/>
        </div>

      </div>

    </div>
  </Dialog>);
};

export default ConfirmDeleteDialog;