import { FormattedMessage } from 'react-intl';
import React from 'react';
import { getCriteriaValue } from './getCriteriaValue';

export const analizeIfSearch = ({ criterias }) => {
  let message = null;

  if (criterias.length === 0) {
    message = (
      <FormattedMessage
        id="SearchView.SearchValidator.NoCriterion"
        defaultMessage="Please provide at least one criterion"
      />
    );
  }

  const byNameValue = getCriteriaValue(criterias, 'se.zert.backend.search.NameCriteria', 'Name');
  if (typeof byNameValue !== 'undefined' && (byNameValue == null || byNameValue.trim().length === 0)) {
    message = (
        <FormattedMessage
            id="SearchView.SearchValidator.EmptyText"
            defaultMessage="Search by text: the field cannot be empty!"
        />
    );
  }

  const size = getCriteriaValue(criterias, 'se.zert.backend.search.SizeCriteria', 'sizeMax');
  const sizeMin = getCriteriaValue(criterias, 'se.zert.backend.search.SizeCriteria', 'sizeMin');
  if (typeof size !== 'undefined' && size === 0 || typeof sizeMin !== 'undefined' && sizeMin === 0) {
    message = (
        <FormattedMessage
            id="SearchView.SearchValidator.Size"
            defaultMessage="Search by text: size should be bigger then 0"
        />
    );
  }



  const byContextValue = getCriteriaValue(criterias, 'se.zert.backend.search.InsideContextElementCriteria', 'Context');
  if (typeof byContextValue !== 'undefined' &&  (byContextValue == null || byContextValue.trim().length === 0)) {
    message = (
      <FormattedMessage
        id="SearchView.SearchValidator.EmptyContext"
        defaultMessage="Search by context: the field cannot be empty!"
      />
    );
  }


  const byLocaleValue = getCriteriaValue(criterias, 'se.zert.backend.search.LanguageCriteria', 'LocaleCount');
  if (typeof byLocaleValue !== 'undefined' && (byLocaleValue == null ||  byLocaleValue.length == 0)) {
    message = (
      <FormattedMessage
        id="SearchView.SearchValidator.EmptyLocale"
        defaultMessage="Search by locale: the field cannot be empty!"
      />
    );
  }

  const versionCriteria = criterias.find(
    (criterion) => criterion.className === 'se.zert.backend.search.VersionCriteria'
  );

  if (versionCriteria && byLocaleValue) {
    versionCriteria.values.push({ key: 'isLanguageIncluded', value: 'true' });
  }

  const byIdValue = getCriteriaValue(criterias, 'se.zert.backend.search.IdCriteria', 'Id');
  if (typeof byIdValue !== 'undefined' && byIdValue.trim().length === 0) {


    message = (
        <FormattedMessage
            id="SearchView.SearchValidator.EmptyId"
            defaultMessage="Search by id: the field cannot be empty!"
        />
    );
  }

  const byElementCountValue = getCriteriaValue(
    criterias,
    'se.zert.backend.search.ElementTypeCriteria',
    'MimeTypeCount'
  );
  if (typeof byElementCountValue !== 'undefined' && byElementCountValue === 0) {
    message = (
      <FormattedMessage
        id="SearchView.SearchValidator.NoSelectedElement"
        defaultMessage="Search by element: You must select at least 1 element"
      />
    );
  }
  const extension = getCriteriaValue(
      criterias,
      'se.zert.backend.search.ExtensionCriteria',
      'extension'
  );

  if (typeof extension !== 'undefined' && (extension == null || extension.trim().length === 0)) {


    message = (
        <FormattedMessage
            id="SearchView.SearchValidator.Extension"
            defaultMessage="Search by Extension: the field cannot be empty!"
        />
    );
  }





  //  console.log(JSON.stringify(searchCriteria));
  return message;
};
