import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import cn from 'classnames';
import allowNullPropTypes from './helpers/allowNullPropTypes';
import './ActionableTable.old.sass';

ActionableTableOld.defaultProps = {
  controlButtons: {
    add: true,
    edit: true,
    delete: true,
    up: true,
    down: true
  },
  disabled: false,
  style: {}
};

ActionableTableOld.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,

  handleSelectRow: PropTypes.func.isRequired,
  selectedRowId: allowNullPropTypes(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onUp: PropTypes.func,
  onDown: PropTypes.func,

  style: PropTypes.object
};

function ActionableTableOld({
  columns,
  rows,

  handleSelectRow,
  selectedRowId,

  onDelete,
  onUp,
  onDown,
  onEdit,
  onAdd,

  disabled,
  controlButtons,

  style
}) {
  const buttonDisabled = selectedRowId === null || disabled;
  return (
    <div className="actionable-table__container" style={style}>
      <div className="actionable-table__table--container">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((cell, index) => (
                <TableCell style={cell.style} key={index}>
                  {cell.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row) => {
              const isSelected = selectedRowId === row.id;
              return (
                <TableRow
                  onClick={() => handleSelectRow(row.id)}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className={cn('table__row', { 'table__row--selected': isSelected })}
                >
                  {columns.map((cell) => (
                    <TableCell style={cell.style} key={cell.identifier}>
                      {row[cell.identifier]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>

      <div className="actionable-table__control-panel-container">
        {(controlButtons.add || typeof controlButtons.add === 'undefined') && (
          <IconButton disabled={disabled} onClick={onAdd} aria-label="add" size="large">
            <AddIcon />
          </IconButton>
        )}
        {(controlButtons.edit || typeof controlButtons.edit === 'undefined') && (
          <IconButton disabled={buttonDisabled} onClick={onEdit} aria-label="edit" size="large">
            <EditIcon />
          </IconButton>
        )}
        {(controlButtons.delete || typeof controlButtons.delete === 'undefined') && (
          <IconButton
            disabled={buttonDisabled}
            onClick={onDelete}
            aria-label="delete"
            size="large">
            <DeleteIcon />
          </IconButton>
        )}
        {(controlButtons.up || typeof controlButtons.up === 'undefined') && (
          <IconButton disabled={buttonDisabled} onClick={onUp} aria-label="up" size="large">
            <ArrowUpwardIcon />
          </IconButton>
        )}
        {(controlButtons.down || typeof controlButtons.down === 'undefined') && (
          <IconButton disabled={buttonDisabled} onClick={onDown} aria-label="down" size="large">
            <ArrowDownwardIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
}

export default ActionableTableOld;
