import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TabsItem from './TabsItem';

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    minHeight: 18,
    '& .MuiTab-root': {
      padding: '0px 4px 0px 3px',
      minWidth: 'fit-content',
      minHeight: 18,
      background: '#EBEBEB'
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#ffffff',
      background: '#4db1d3',
      outline: 'none'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left',
      background: '#E3F2F7'
    }
  }
}));

function Label({ paragraph, templatesIdx }) {
  const cl = useStyles();
  const tabs = ['Prefix', 'Left', 'Right', 'Top', 'Bottom'];
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeSelectedTab = (e, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Tabs
        indicatorColor=""
        className={cl.tabsRoot}
        value={selectedTab}
        onChange={handleChangeSelectedTab}
        textColor="primary"
        variant="standard"
        scrollButtons="auto"
        centered
      >
        {tabs.map((el, i) => (
          <Tab label={el} key={i} />
        ))}
      </Tabs>
      {selectedTab === 0 && paragraph.paragraphPrefixLabel && (
        <TabsItem type="paragraphPrefixLabel" value={paragraph.paragraphPrefixLabel} templatesIdx={templatesIdx} />
      )}
      {selectedTab === 1 && paragraph.paragraphLeftLabel && (
        <TabsItem type="paragraphLeftLabel" value={paragraph.paragraphLeftLabel} templatesIdx={templatesIdx} />
      )}
      {selectedTab === 2 && paragraph.paragraphRightLabel && (
        <TabsItem type="paragraphRightLabel" value={paragraph.paragraphRightLabel} templatesIdx={templatesIdx} />
      )}
      {selectedTab === 3 && paragraph.paragraphTopLabel && (
        <TabsItem type="paragraphTopLabel" value={paragraph.paragraphTopLabel} templatesIdx={templatesIdx} />
      )}
      {selectedTab === 4 && paragraph.paragraphBottomLabel && (
        <TabsItem type="paragraphBottomLabel" value={paragraph.paragraphBottomLabel} templatesIdx={templatesIdx} />
      )}
    </>
  );
}

export default Label;
