import React, { Fragment, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import ConfirmPasswordDialog from '@zert-packages/components/common/PasswordConfirmDialog';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { getStatusById } from './TranslationJobContentTable';
import { loadTranslationJobWFT, performMineTJ } from './servicesMiddlewares';

function WorkflowStageButtons({ tj }) {
  const CLM = useSelector((state) => state.CLM);
  const workflowtemplate = CLM.tjwft;
  const [performedTransition, setPerformedTransition] = useState();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);

  const performTJ = async (transition) => {
    setLoading(true);
    setPerformedTransition(transition);
    dispatch(
      performMineTJ(CLM.translationJob.info.versionId, tj, transition, () => {
        setLoading(false);
      })
    );
  };

  useEffect(() => {
    dispatch(loadTranslationJobWFT(CLM.translationJob.info.versionId));
  }, [CLM.translationJob]);

  const onWorkFlowActivityDone = async (transition) => {
    if (transition.customCallBacks && transition.customCallBacks.length > 0) {
      const callback = transition.customCallBacks.find((callback) =>
        callback.left.startsWith('zert:CustomCallback[CheckTranslationStatus')
      );
      if (callback) {
        const filteredContent = CLM.translationJob.translationContent.filter((content) => content.localeStatus == 1);
        if (filteredContent.length > 0) {
          renderInExceptionRoot(UntranslartedContent, {
            untranslatedContent: filteredContent
          });
          return;
        }
      }
    }
    if (transition.passwordRequired) {
      renderInExceptionRoot(ConfirmPasswordDialog, {
        onConfirm: async () => {
          await performTJ(transition);
        },
        contextText: (
          <p>
            <FormattedMessage
              id="workflow.stageButtons.userPassword"
              defaultMessage="You need to enter your password to perform the action {action} on the following tasks."
              values={{ action: transition.name }}
            />
          </p>
        ),
        confirmButtonText: (
          <p>
            <FormattedMessage id="workflow.stageButtons.confirm.ok" defaultMessage="Ok" />
          </p>
        ),
        titleText: (
          <p>
            <FormattedMessage id="workflow.stageButtons.passwordConfirm" defaultMessage="Confirm password" />
          </p>
        ),
        cancelButtonText: (
          <p>
            <FormattedMessage id="workflow.stageButtons.confirm.cancel" defaultMessage="Cancel" />
          </p>
        )
      });
    } else if (transition.ownerPasswordRequired) {
      renderInExceptionRoot(ConfirmPasswordDialog, {
        onConfirm: async () => {
          await performTJ(transition);
        },
        contextText: (
          <p>
            <FormattedMessage
              id="workflow.stageButtons.taskOwnerPassword"
              defaultMessage="The task owner, {taskOwner} need to enter the password to perform the action {action} on the following tasks."
              values={{
                taskOwner: transition.name,
                action: transition != null && transition.name
              }}
            />
          </p>
        ),
        confirmButtonText: (
          <p>
            <FormattedMessage id="workflow.stageButtons.confirm.ok" defaultMessage="Ok" />
          </p>
        ),
        titleText: (
          <p>
            <FormattedMessage id="workflow.stageButtons.passwordConfirm" defaultMessage="Confirm password" />
          </p>
        ),
        cancelButtonText: (
          <p>
            <FormattedMessage id="workflow.stageButtons.confirm.cancel" defaultMessage="Cancel" />
          </p>
        )
      });
    } else {
      await performTJ(transition);
    }
  };

  return (
    <>
      {/* {loading && <CircularProgress color="inherit" size={20} style={{margin : "5px"}}/>} */}
      {workflowtemplate &&
        workflowtemplate.process.transitions
          .filter((t, index) => t.from.id === CLM.translationJob.currentActivity.id)
          .map((t, index) => {
            return (
              <Button
                onClick={(e) => {
                  onWorkFlowActivityDone(t);
                }}
                startIcon={
                  loading &&
                  performedTransition &&
                  performedTransition.id === t.id && (
                    <CircularProgress color="inherit" size={20} style={{ margin: '5px' }} />
                  )
                }
                color="primary"
                variant={index === 0 ? 'outlined' : 'text'}
              >
                {t.name}
              </Button>
            );
          })}
    </>
  );
}

const useStyles = makeStyles({
  DialogContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    '& > *': {
      marginTop: 10
    }
  }
});

function UntranslartedContent({ unmountComponent, untranslatedContent }) {
  const cl = useStyles();
  const handleConfirm = () => {
    unmountComponent();
  };
  return (
    <Dialog open onClose={unmountComponent} fullWidth>
      <DialogTitle>
        <FormattedMessage id="NotTranslatedContent.Title" defaultMessage="Not translated content" />
      </DialogTitle>
      <DialogContent className={cl.DialogContent}>
        <Typography>
          <FormattedMessage
            id="NotTranslatedContent.List"
            defaultMessage="Following locales are not translated either not canceled"
          />
        </Typography>
        {untranslatedContent.map((translation, index) => {
          return (
            <div key={index} className="current-entry" style={{ display: 'flex' }}>
              <div style={{ flexBasis: '12em' }}>{translation.locale.displayName}</div>
              <div style={{ flexBasis: '20em' }}>{getStatusById(translation.localeStatus).name}</div>
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary" variant="outlined">
          <FormattedMessage id="NotTranslatedContent.List.OK" defaultMessage="Got It" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WorkflowStageButtons;
