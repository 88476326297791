import React, {useContext} from 'react';
import {Button} from "@mui/material";
import {DOWNLOAD_ACTION} from "../../utils/ELEMENT_TILE_CONTS";
import {SparePartContext} from "../../store/SparePartContext";
import {getImageContent} from "../../../ImagePlugin/API";

const DownloadImageAction = () => {
    const {values, setValues} = useContext(SparePartContext)

    const downloadImage = async () => {
        const res = await getImageContent(values.currentViewedElement.elementInfo.versionId);
        const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers["content-type"] }));
        const link = document.createElement("a");
        link.href = url;
        link.download = res.headers["content-disposition"].split("=")[1].replace(/(\.[^.]+)\1+/g, "$1");
        link.click();
        link.remove();
    }

    return (
        <>
            <Button key={DOWNLOAD_ACTION.id} startIcon={DOWNLOAD_ACTION.icon}
                    onClick={downloadImage}>
                {DOWNLOAD_ACTION.name}
            </Button>
        </>
    );
};

export default DownloadImageAction;