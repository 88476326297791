import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { FormattedMessage, injectIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SearchComponent from './SearchComponent';
import withSearch, { styles, controlStyles } from '../withSearch';

import { getSteps } from '../../Environment';

const componentStyles = {
  numberOfPeriodContainer: {
    display: 'grid',
    margin: '10px'
  }
};

class RiskAnalisesSearch extends SearchComponent {
  constructor(props) {
    super(props);
    if  (!this.state.step) {
      this.state = {
        step: 'risk-identification',
        countGT: -1,
        countLESS: -1,
        countGTM: -1,
        countLESSM: -1
      };
    }
    this.handleChange = this.handleChange.bind(this);
    this.textInput = React.createRef();
  }

  handleChange = (name) => (event) => {
    const value = event.target.value;
    this.setStatus({ [name]: value});
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    this.props.expanded && this.textInput.current ? this.textInput.current.blur() : this.textInput.current.focus();
  }

  render() {
    const { classes } = this.props;
    // this.props.handleChange(this.state);
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel htmlFor="step.id">
            <FormattedMessage id="search.step.id" defaultMessage="Step id" />{' '}
          </InputLabel>
          <Select
            autoFocus
            size="small"
            inputRef={this.textInput}
            value={this.state.step}
            onChange={(e) => {
              this.setStatus({ step: e.target.value });
            }}
            inputProps={{
              name: 'search-step-id',
              id: 'step.id'
            }}
          >
            {getSteps(this.props.intl).map((label, index) => {
              return (
                <MenuItem value={label.id}>
                  <em>{label.tr_message}</em>
                </MenuItem>
              );
            })}
            <MenuItem value="all">
              <em>
                <FormattedMessage id="step.none" defaultMessage="All" />
              </em>
            </MenuItem>
          </Select>
        </FormControl>
        <div style={componentStyles.numberOfPeriodContainer}>
          <TextField
              size="small"
            style={{ width: '100%' }}
            id="standard-in_the_last"
            label={<FormattedMessage id="search.riskrow.morethan" defaultMessage="Has more than risks" />}
            className={classes.spinField}
            value={this.state.countGT}
            onChange={this.handleChange('countGT')}
            type="number"
            variant="outlined"
          />
        </div>
        <div style={componentStyles.numberOfPeriodContainer}>
          <TextField
              size="small"
            style={{ width: '100%' }}
            id="standard-in_the_last"
            label={<FormattedMessage id="search.riskrow.lessthan" defaultMessage="Has less than risks" />}
            className={classes.spinField}
            value={this.state.countLESS}
            onChange={this.handleChange('countLESS')}
            type="number"
            variant="outlined"
          />
        </div>

        <div style={componentStyles.numberOfPeriodContainer}>
          <TextField
            style={{ width: '100%' }}
            size="small"
            id="standard-in_the_last"
            label={<FormattedMessage id="search.measures.morethan" defaultMessage="Has more than measures" />}
            className={classes.spinField}
            value={this.state.countGTM}
            onChange={this.handleChange('countGTM')}
            type="number"
            variant="outlined"
          />
        </div>
        <div style={componentStyles.numberOfPeriodContainer}>
          <TextField
            style={{ width: '100%' }}
            id="standard-in_the_last"
            label={<FormattedMessage id="search.measures.lessthan" defaultMessage="Has less than measures" />}
            className={classes.spinField}
            value={this.state.countLESSM}
            onChange={this.handleChange('countLESSM')}
            type="number"
            variant="outlined"
          />
        </div>
      </form>
    );
  }
}

RiskAnalisesSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.context.riskassessments" defaultMessage="Search in risk assessments" />,
  index: 'riskanalisis-criteria',
  className: 'se.zert.backend.search.RiskAnalisisCriteria',
  default: {
    countGT: -1,
    step: 'risk-identification',
    countLESS: -1,
    countGTM: -1,
    countLESSM: -1
  }
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(
  injectIntl(withStyles(styles)(withSearch(withStyles(controlStyles)(RiskAnalisesSearch), selectData)))
);
