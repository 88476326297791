import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { generateRiskAnalisesReport } from '@zert-packages/actions/riskassesmentActions';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { generalCheckBoxes } from './checkboxes';
import generalRadioBtns from './generalRadioBtns';

export default function useMeasureReport({ selectedNode, selectedValues, catalog }) {
  const caption = <FormattedMessage id="reports.measures" defaultMessage="Measurements" />;
  const description = (
    <FormattedMessage
      id="measures.report.description"
      defaultMessage="This report generates a list of all measures placed on the selected node."
    />
  );

  const index = 'measurements';
  const dispatch = useDispatch();

  const radiobtns = generalRadioBtns;
  // eslint-disable-next-line no-use-before-define
  const dateSelectors = [];
  const selectors = [];
  // eslint-disable-next-line no-use-before-define
  const checkBoxes = generalCheckBoxes;

  const generateReport = (checkedItems) => {
    /* report,
            this.props.catalog ? this.props.catalog.info.versionId : -1,
            this.props.selected ? this.props.selected.split('^')[0] : 'none',
            this.state.checkedItems.get('reports.subnode'),
            this.state.checkedItems.get('cb_separateImages'),
            this.state.checkedItems.get('cb_include_archived'),
            0,
            !this.props.selectedValues ? [] : this.props.selectedValues */
    let selectedIds = [];
    if (selectedValues) {
      selectedIds = selectedValues.map((value) => value.versionId);
    }

    dispatch(
      generateRiskAnalisesReport(
        {
          name: caption,
          reportUUID: uuidv4()
        },
        catalog ? catalog.info.versionId : -1,
        selectedNode || 'none',
        checkedItems('reports.subnode'),
        checkedItems('cb_separateImages'),
        checkedItems('cb_include_archived'),
        2,
        selectedIds
      )
    );
  };

  return { index, generateReport, caption, description, radiobtns, dateSelectors, selectors, checkBoxes };
}
