import { handlePost, handleQuery } from '@zert-packages/actions/api';

export const getApiTemplates = (id) => {
  if(!id){
    return
  }
  //  return handleQuery('/common/getMetadata/196815');
   return handleQuery(`/common/getMetadata/${id}`);
};

export const setApiMetadata = (value, id) => {
  if(!id){
    return
  }
  // return  console.log('setApiMetadata', value);
  const adopterValue = value.map((el) => ({
    identifier: el.identifier,
    value: el.value.value ? el.value.value : el.value
  }));
  return handlePost(`/common/setMetadata/${id}`, adopterValue);
};
