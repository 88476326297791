export const getLocaleByLanguageCode = (code, locales) => {
  return locales.find((locale) => code === `${locale.language}_${locale.country}`);
};

export const reduceToExitings = (codes, locales) => {
  return codes.filter((locale) => typeof getLocaleByLanguageCode(locale, locales) !== 'undefined');
};

export const toLocaleString = (locale) => {
  if (!locale.country) {
    return locale.language;
  }
  return `${locale.language}_${locale.country}`;
};
