import React, { useContext, useState } from 'react';

import { TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import makeStyles from '@mui/styles/makeStyles';
import CheckboxWithMemo from '@zert-packages/components/common/CheckboxWithMemo';
import { PublishingWizardContext } from './PublishingWizardStepper';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190
  }
}));

export function PurposePage({ readOnly }) {
  const values = useContext(PublishingWizardContext);
  return <PurposePaper {...values} readOnly={readOnly} />;
}

export function PurposePaper({
  readOnly,
  allowComments,
  notifyMeOnExpire,
  notifyMeOneNewComment,
  validUntil,
  purpose,
  setPurpose
}) {
  const classes = useStyles();

  const onTextField = (fieldName) => (event) => {
    if (event && event.target && event.target.value && fieldName) {
      const text = event.target.value;
      if (setPurpose) {
        setPurpose((prevState) => {
          return {
            ...prevState,
            [fieldName]: text
          };
        });
      }
    }
  };

  const onDateField = (fieldName) => (date) => {
    if (setPurpose) {
      setPurpose((prevState) => ({
        ...prevState,
        [fieldName]: date
      }));
    }
  };

  const onCheckBoxChange = (fieldName) => (isChecked) => {
    if (setPurpose) {
      setPurpose((prevState) => ({
        ...prevState,
        [fieldName]: isChecked
      }));
    }
  };

  return (
    <form
      style={{
        display: 'flex',
        justifyContent: 'stretch',
        flexFlow: 'column nowrap'
        // backgroundColor: "rgb(224, 224, 224, 1)" //"rgb(220, 239, 246)"
      }}
    >
      <TextField
        id="purpose"
        multiline
        minRows="7"
        label={<FormattedMessage id="PurposePage.Purpose" disabled={readOnly} defaultMessage="Purpose" />}
        className={classes.formControl}
        value={purpose}
        onChange={onTextField('purpose')}
        type="text"
        variant="outlined"
      />

      <DatePicker
        disableToolbar
        disabled={readOnly}
        variant="inline"
        margin="normal"
        ampm={false}
        id="validUntil"
        label={<FormattedMessage id="PurposePage.ValidUntil" multiline rows="7" defaultMessage="Valid until" />}
        invalidDateMessage={<FormattedMessage id="datepicker.invalid" defaultMessage="Invalid date format" />}
        maxDateMessage={
          <FormattedMessage id="datepicker.maxDateMessage" defaultMessage="Date should not be after maximal date" />
        }
        minDateMessage={
          <FormattedMessage id="datepicker.minDateMessage" defaultMessage="Date should not be before minimal date" />
        }
        value={validUntil}
        onChange={onDateField('validUntil')}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        renderInput={(props) => <TextField {...props} />}
      />

      <CheckboxWithMemo
        isChecked={allowComments}
        disabled={readOnly}
        onChange={onCheckBoxChange('allowComments')}
        label={<FormattedMessage id="PurposePage.AllowComments" defaultMessage="Allow comments" />}
      />
      <CheckboxWithMemo
        isChecked={notifyMeOnExpire}
        disabled={readOnly}
        onChange={onCheckBoxChange('notifyMeOnExpire')}
        label={<FormattedMessage id="PurposePage.NotifyMeOnExpire" defaultMessage="Notify Me On Expire" />}
      />
      <CheckboxWithMemo
        isChecked={notifyMeOneNewComment}
        disabled={readOnly}
        onChange={onCheckBoxChange('notifyMeOneNewComment')}
        label={<FormattedMessage id="PurposePage.NotifyMeOnNewComment" defaultMessage="notify Me On New Comment" />}
      />
    </form>
  );
}
