import React, { useContext, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Page from './tabs/Page';
import Front from './tabs/Front';
import Back from './tabs/Back';
import BackStrip from './tabs/BackStrip';
import { TabsContext } from '../../../Stylesheet';

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    minHeight: 18,
    marginBottom: 15,
    '& .MuiTab-root': {
      padding: '0px 4px 0px 3px',
      minWidth: 'fit-content',
      minHeight: 18,
      background: '#EBEBEB',
      textTransform: 'capitalize'
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#ffffff',
      background: '#4db1d3',
      outline: 'none'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left',
      background: '#E3F2F7'
    }
  }
}));

function Cover() {
  const cl = useStyles();
  const tabs = ['Page', 'Front', 'Back', 'Backstrip'];
  const [selectedTab, setSelectedTab] = useState(0);
  const { values } = useContext(TabsContext);
  const cover = values.stylesheet.blockSettings[values.selectedLeftMenu];

  const handleChangeSelectedTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      {cover && cover.class.includes('Cover') && (
        <>
          {tabs && (
            <Tabs
              indicatorColor=""
              className={cl.tabsRoot}
              value={selectedTab}
              onChange={handleChangeSelectedTab}
              textColor="primary"
              variant="standard"
              scrollButtons="auto"
              centered
            >
              {tabs.map((el, i) => (
                <Tab label={el} key={i} />
              ))}
            </Tabs>
          )}
          {selectedTab === 0 && <Page cover={cover} />}
          {selectedTab === 1 && <Front cover={cover} />}
          {selectedTab === 2 && <Back cover={cover} />}
          {selectedTab === 3 && <BackStrip cover={cover} />}
        </>
      )}
    </>
  );
}

export default Cover;
