import React, { Fragment } from 'react';
import Select from 'react-select';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import { FormattedMessage } from 'react-intl';
import AutoGrowInput from '../../shared/AutoGrowInput/AutoGrowInput';
import DeadlinePicker from '../../shared/DeadlinePicker/DeadlinePicker';
import InlineElementEditor from './InlineElementEditor';
import UserSuggestBox from '../../shared/UserSuggestBoxNew/UserSuggestBox';
import Spacer from '../../shared/Spacer';

export const submitHasAllMandatoryData = (template, deviation, columnActivityDisplay, clientTask) => {
  try {
    if (!template || !deviation) return true;

    for (const col of template.columns) {
      if (shouldDisplayColumn(col, template, deviation) && columnIsMandatory(columnActivityDisplay, col, clientTask)) {
        const cell = findCellByIdentifier(deviation.cells, col.identifier);
        if (isCellValueEmpty(cell)) {
          return false; // Return false immediately if any mandatory field is empty
        }
      }
    }

    return true;
  } catch (e) {
    return true;
  }
};

export const columnIsMandatory = (columnActivityDisplay, col, clientTask) => {
  try {
    const taskStatus = columnActivityDisplay[`${col.identifier}-${clientTask.currentActivity.id}`];
    if (!taskStatus) return false;

    const [nonAdminStatus, adminStatus] = taskStatus.split('-');
    return clientTask.taskAdmin ? adminStatus === 'mandatory' : nonAdminStatus === 'mandatory';
  } catch (e) {
    return false;
  }
};

const findCellByIdentifier = (cells, columnIdentifier) => {
  return cells.find((cell) => cell.columnIdentifier === columnIdentifier);
};

const isCellValueEmpty = (cell) => {
  return !cell || cell.value === null || cell.value === '' || (Array.isArray(cell.value) && cell.value.length === 0);
};

export const columnIsHidden = (columnActivityDisplay, col, clientTask) => {
  try {
    const taskme =
      columnActivityDisplay[`${col.identifier}-${clientTask.currentActivity.id}`].split('-')[
        clientTask.taskAdmin ? 1 : 0
      ];
    return taskme == 'hidden';
  } catch (e) {
    return false;
  }
};

const shouldDisplayColumn = (col, deviationtemplate, deviation) => {
  if (!col || !deviationtemplate || !deviation) {
    return false;
  }

  if (col.depends) {
    const dependentColumn = deviationtemplate.columns.find((c) => c.identifier === col.depends);
    const dependentCell = deviation.cells.find((cell) => cell.columnIdentifier === col.depends);

    if (!dependentColumn || !dependentCell || !dependentCell.value) {
      return false;
    }

    if (!shouldDisplayColumn(dependentColumn, deviationtemplate, deviation)) {
      return false;
    }

    if (dependentColumn.class.includes('MultiSelectColumn')) {
      const dependsAltValues = Array.isArray(col.dependsValue) ? col.dependsValue : [col.dependsValue];
      const multiSelectValues = dependentCell.value;

      const allValuesMatch = dependsAltValues.every((alt) => multiSelectValues.includes(alt));
      if (!allValuesMatch) {
        return false;
      }
    } else if (dependentColumn.class.includes('SelectColumn')) {
      const dependsAltValue = col.dependsValue;
      const selectValue = dependentCell.value;

      if (selectValue !== dependsAltValue) {
        return false;
      }
    }
  }

  return true;
};

export default class InlineDeviationEditor extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const cells = this.props.deviationtemplate.columns.map((col) => {
      const isMandatory = columnIsMandatory(this.props.deviationtemplate.columnActivityDisplay, col, this.props.task);
      const isHidden = columnIsHidden(this.props.deviationtemplate.columnActivityDisplay, col, this.props.task);

      if (!isHidden && shouldDisplayColumn(col, this.props.deviationtemplate, this.props.deviation)) {
        if (col.class.includes('IntegerColumn')) {
          return (
            <Fragment key={col.identifier}>
              <Spacer />
              <div className="riskform">
                <InlineDeviationIntegerEditorSelect
                  column={col}
                  isMandatory={isMandatory}
                  onUpdate={this.props.onUpdate}
                  deviation={this.props.deviation}
                  template={this.props.deviationtemplate}
                />
              </div>
            </Fragment>
          );
        }
        if (col.class.includes('UserColumn')) {
          return (
            <Fragment key={col.identifier}>
              <Spacer />
              <div className="riskform">
                <InlineDeviationUserEditorSelect
                  column={col}
                  onUpdate={this.props.onUpdate}
                  isMandatory={isMandatory}
                  deviation={this.props.deviation}
                  template={this.props.deviationtemplate}
                />
              </div>
            </Fragment>
          );
        }
        if (col.class.includes('MultiSelectColumn')) {
          return (
            <Fragment key={col.identifier}>
              <Spacer />
              <div className="riskform">
                <InlineMultiDeviationEditorSelect
                  column={col}
                  isMandatory={isMandatory}
                  onUpdate={this.props.onUpdate}
                  deviation={this.props.deviation}
                  template={this.props.deviationtemplate}
                />
              </div>
            </Fragment>
          );
        }
        if (col.class.includes('SelectColumn')) {
          return (
            <Fragment key={col.identifier}>
              <Spacer />
              <div className="riskform">
                <InlineDeviationEditorSelect
                  column={col}
                  onUpdate={this.props.onUpdate}
                  isMandatory={isMandatory}
                  deviation={this.props.deviation}
                  template={this.props.deviationtemplate}
                />
              </div>
            </Fragment>
          );
        }
        if (col.class.includes('TextColumn')) {
          return (
            <Fragment key={col.identifier}>
              <Spacer />
              <div className="riskform" key={col.identifier}>
                <InlineDeviationEditorText
                  column={col}
                  isMandatory={isMandatory}
                  onUpdate={this.props.onUpdate}
                  deviation={this.props.deviation}
                  template={this.props.deviationtemplate}
                />
              </div>
            </Fragment>
          );
        }
        if (col.class.includes('ElementColumn')) {
          return (
            <Fragment key={col.identifier}>
              <Spacer />
              <div className="riskform">
                <InlineElementEditor
                  column={col}
                  isMandatory={isMandatory}
                  onUpdate={this.props.onUpdate}
                  deviation={this.props.deviation}
                  template={this.props.deviationtemplate}
                />
              </div>
            </Fragment>
          );
        }
        if (col.class.includes('DateColumn')) {
          return (
            <Fragment key={col.identifier}>
              <Spacer />
              <div className="riskform">
                <InlineDeviationDateEditor
                  column={col}
                  isMandatory={isMandatory}
                  onUpdate={this.props.onUpdate}
                  deviation={this.props.deviation}
                  template={this.props.deviationtemplate}
                />
              </div>
            </Fragment>
          );
        }
      }

      return null;
    });

    return <>{cells}</>;
  }
}

class InlineDeviationDateEditor extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onChangeDateCallback = this.onChangeDateCallback.bind(this);
  }

  onChange(e) {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });
    this.props.deviation.changed = true;
    cell.value = e.target.value;
    this.props.onUpdate(this.props.deviation);
  }

  onChangeDateCallback(date) {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });

    cell.value = date;
  }

  render() {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });
    // if (cell.value) {
    const { value } = cell;
    // }
    return (
      <div className="row-wrapper">
        <span className="captionDeviation">
          {this.props.column.name.defaultLabel + ' ' + (this.props.isMandatory ? '*' : '')}
        </span>
        <DeadlinePicker deadline={value} onChangeCallback={this.onChangeDateCallback} />
      </div>
    );
  }
}

class InlineDeviationEditorText extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });
    this.props.deviation.changed = true;
    cell.value = e.target.value;
    this.props.onUpdate(this.props.deviation);
  }

  render() {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });

    const { value } = cell;

    return (
      <div className="row-wrapper">
        <AutoGrowInput
          defaultValue={value}
          placeholder={`${this.props.column.name.defaultLabel} ${this.props.isMandatory ? '*' : ''}`}
          onChangeCallback={this.onChange}
        />
      </div>
    );
  }
}

class InlineDeviationUserEditorSelect extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeUser = this.onChangeUser.bind(this);
  }

  onChangeUser(e) {
    if (e && e.name) {
      const condition = this.props.column.identifier;
      const cell = this.props.deviation.cells.find(function (cell) {
        if (cell.columnIdentifier === condition) {
          return true;
        }
      });
      this.props.deviation.changed = true;
      cell.value = e;
      this.props.onUpdate(this.props.deviation);
    }
  }

  render() {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });

    const { value } = cell;

    return (
      <div className="row-wrapper">
        <label htmlFor={this.props.column.identifier} className="captionDeviation">
          {`${this.props.column.name.defaultLabel} ${this.props.isMandatory ? '*' : ''}`}
        </label>
        <div>
          <UserSuggestBox
            readonly={this.props.readonly}
            onChange={this.onChangeUser}
            getUsers={getUsersQuery}
            getUserId={(user) => user.username}
            getUserLabel={(user) => user.name}
            initialValue={value && value.name}
            label={<FormattedMessage id="DelegateTasksSettingsDialog.UserLabel" defaultMessage="User" />}
          />
        </div>
      </div>
    );
  }
}

class InlineDeviationIntegerEditorSelect extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });
    this.number = React.createRef();
    const { value } = cell;
    this.state = { value };
  }

  onChange(e) {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });
    this.props.deviation.changed = true;
    cell.value = e.target.value;
    this.setState({ value: cell.value });
    this.props.onUpdate(this.props.deviation);
  }

  render() {
    return (
      <div className="row-wrapper">
        <label htmlFor={this.props.column.identifier} className="captionDeviation">
          {`${this.props.column.name.defaultLabel} ${this.props.isMandatory ? '*' : ''}`}
        </label>
        <div>
          <input type="number" ref={this.number} onChange={this.onChange} min="1" value={this.state.value} />
        </div>
      </div>
    );
  }
}

class InlineMultiDeviationEditorSelect extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);

    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find((cell) => cell.columnIdentifier === condition);

    let multiSelectValue = [];
    if (cell && Array.isArray(cell.value)) {
      multiSelectValue = cell.value
        .map((val) => {
          const alternative = this.props.column.alternatives.find((alt) => alt.value === val);
          return alternative ? { value: alternative.value, label: alternative.text.defaultLabel } : null;
        })
        .filter(Boolean);
    }

    this.state = { value: multiSelectValue };
  }

  handleSelectChange(selectedOptions) {
    const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];

    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find((cell) => cell.columnIdentifier === condition);

    if (cell) {
      cell.value = selectedValues;
    }

    this.setState({ value: selectedOptions || [] });
    this.props.onUpdate(this.props.deviation);
  }

  render() {
    const alternatives = this.props.column.alternatives.map((alt) => ({
      value: alt.value,
      label: alt.text.defaultLabel
    }));

    return (
      <div className="row-wrapper">
        <label htmlFor={this.props.column.identifier} className="captionDeviation">
          {`${this.props.column.name.defaultLabel} ${this.props.isMandatory ? '*' : ''}`}
        </label>
        <div>
          <Select
            closeOnSelect={false}
            disabled={false}
            isMulti
            onChange={this.handleSelectChange}
            options={alternatives}
            placeholder="Select alternatives"
            value={this.state.value}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </div>
      </div>
    );
  }
}

class InlineDeviationEditorSelect extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    if (e.target.value != -1) {
      this.props.deviation.changed = true;
      const condition = this.props.column.identifier;
      const cell = this.props.deviation.cells.find(function (cell) {
        if (cell.columnIdentifier === condition) {
          return true;
        }
      });
      cell.value = e.target.value;
      this.props.onUpdate(this.props.deviation);
    }
  }

  render() {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });

    const { value } = cell;

    return (
      <div className="row-wrapper">
        <label htmlFor={this.props.column.identifier} className="captionDeviation">
          {`${this.props.column.name.defaultLabel} ${this.props.isMandatory ? '*' : ''}`}
        </label>
        <div>
          <select
            id={this.props.column.identifier}
            defaultValue={value}
            onChange={this.onChange}
            className="form-control animated"
          >
            {this.props.column.alternatives.map((l) => {
              return (
                <option value={l.value} key={l.value}>
                  {l.text.defaultLabel}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }
}
