import React, { useContext, useEffect, useRef, useState } from "react";
import { Paper } from "@mui/material";

import { makeStyles } from "@mui/styles";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import CheckIcon from "@mui/icons-material/Check";
import TranslateIcon from "@mui/icons-material/GTranslate";
import TimelineIcon from "@mui/icons-material/Timeline";
import { PhraseContext } from "./PhraseEditor";
import { addInsertText } from "../utils/converterPhrase";
import { compositeDecorator } from "../utils/decorator";
import { useRoveFocus } from "@zert-packages/utils";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  header: {
    color: "#515151",
    display: "grid",
    gridTemplateColumns: "50px 80px 25px 40px  1fr",
    marginBottom: 5
  },
  item: {
    display: "grid",
    gridTemplateColumns: "25px 25px 80px 25px 40px  1fr",
    paddingLeft: 5,
    "&:hover": {
      cursor: "pointer",
      background: "#01A1C7",
      color: "#ffffff"
    },
    "&:focus-visible": {
      outline: "none",
      cursor: "pointer",
      background: "#01A1C7",
      color: "#ffffff"
    }
  },
  icon: {
    color: "#8BBA63"
  },
  title: {
    color: "#515151",
    fontWeight: "bold"
  },
  viewAll: {
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      background: "#01A1C7",
      color: "#ffffff"
    }
  }
}));


const TranslatorDropBlock = ({ focus, setDialogAddWord }) => {
  const cl = useStyles();
  const { values, setValues } = useContext(PhraseContext);
  const [blockLength, setBlockLength] = useState(10);
  const translator = values.translator.slice(0, blockLength);
  const { currentFocus, addTranslateEnter } = useRoveFocus(translator.length, focus, false,false,false,);

  const contentToDb = convertToRaw(values.editorState.getCurrentContent());



  const addTranslator = (index) => {

    const idx = index ? index : currentFocus;
    if (!translator || idx < 0) {
      return;
    }
    const activeWord = translator[idx].name;
    const result = {
      "blocks": [{
        "key":  Math.random().toFixed(4),
        "text": activeWord,
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
      }], "entityMap": {}
    };
    const rawContent = convertFromRaw(result);
    const res = EditorState.createWithContent(rawContent, compositeDecorator);
    setValues(pre => ({ ...pre, editorState: res, translator: false }));
  };

  const showAll = () => {
    setBlockLength(values.translator.length);
  };

  useEffect(() => {
    const activeWord = translator[currentFocus]
    if(setDialogAddWord && activeWord){
      setDialogAddWord(pre=>({...pre,selectedWord: activeWord}))
    }
  }, [setDialogAddWord, currentFocus])

  useEffect(() => {
    if (addTranslateEnter) {
      addTranslator();
    }
  }, [currentFocus, addTranslateEnter]);

  return (<Paper className={cl.root}>
    {/*<p style={{ marginTop: 0 }}><span className={cl.title}>Search terms: </span>test</p>*/}
    <div className={cl.header}>
      <span>Version</span>
      <span>Match</span>
      <TranslateIcon />
      <TimelineIcon />
      <span>Suggestion</span>
    </div>

    {translator.slice(0, blockLength).map((el, i) => <ItemRow
      key={i}
      el={el}
      index={i}
      values={values}
      focus={currentFocus === i}
      set={addTranslator} />)}
    {(blockLength <= values.translator.length) && blockLength !== values.translator.length &&
      <div className={cl.viewAll} onClick={showAll}>
        View all {values.translator.length} offers
      </div>}

  </Paper>);
};

export default TranslatorDropBlock;


const ItemRow = ({ el, focus, set, index, values }) => {
  const cl = useStyles();
  const ref = useRef(null);
  const beginsWith = el.name.split(" ")[0].toLowerCase().includes(values.newContent.split(" ")[0].toLowerCase());

  useEffect(() => {
    if (focus) {
      ref.current.focus();
    }
  }, [focus]);


  return (<div className={cl.item} tabIndex={focus ? 0 : -1} ref={ref} onClick={() => {
    set(index);
  }}>
    <div> {el.label}</div>
    <CheckIcon className={cl.icon} />
    <div>{beginsWith ? "Starting  with" : "Contains"}</div>
    <div>0</div>
    <div>0</div>
    <div>{el.name}</div>
  </div>);
};
