import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FormattedMessage } from 'react-intl';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import ActionableTable from '@zert-packages/components/shared/Accordion';

class ModuleEditorTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkBoxes: null,
      everyone: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEveryOne = this.handleChangeEveryOne.bind(this);
  }

  handleChangeEveryOne = (name) => (event) => {
    const { permits } = this.props;
    this.setState({ everyone: !(event.target.value === 'true') });
    this.props.permits.hasEveryOne = !(event.target.value === 'true');
    if (this.props.handleChange) {
      this.props.handleChange(event);
    }
  };

  handleChange = (name) => (event) => {
    const { permits } = this.props;
    this.state.checkBoxes[name] = !(event.target.value == 'true');
    this.setState({ ...this.state.checkBoxes });
    const found = permits.groups.find((permit) => permit == name);

    if (found && event.target.value == 'true') {
      permits.groups.splice(permits.groups.indexOf(name), 1);
    } else if (!found && event.target.value == 'false') {
      permits.groups.push(name);
    }
    if (this.props.handleChange) {
      this.props.handleChange(event);
    }
  };

  componentDidMount() {
    const { groupslist, permits } = this.props;
    let checkBoxes = {};
    groupslist.map((group) => {
      const found = permits.groups.find((permit) => permit == `${group.elementId}`);
      checkBoxes = {
        ...checkBoxes,
        [`${group.elementId}`]: found != null
      };
    });
    this.setState({
      checkBoxes,
      everyone: permits.hasEveryOne
    });
  }

  render() {
    const rotate = 'rotate(0deg)';
    const { template, intl, groupslist, action } = this.props;
    const { checkBoxes } = this.state;
    return (
      <tr className="notSelectedTableRow">
        <td>
          <div style={{width: '10vw'}}>
            {intl.formatMessage({
              id: action.actionName.props.id,
              defaultMessage: action.actionName.props.defaultMessage
            })}
          </div>
        </td>
        <td>
          <div>
            {checkBoxes && (
              <Checkbox
                checked={this.state.everyone}
                value={this.state.everyone}
                onChange={this.handleChangeEveryOne('everyOne')}
                color="primary"
              />
            )}
          </div>
        </td>
        {groupslist.map((group) => (
          <td>
            <div>
              {checkBoxes && (
                <Checkbox
                  checked={checkBoxes[`${group.elementId}`]}
                  value={checkBoxes[`${group.elementId}`]}
                  onChange={this.handleChange(`${group.elementId}`)}
                  color="primary"
                />
              )}
            </div>
          </td>
        ))}
      </tr>
    );
  }
}

export default class ModuleEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      defaultTemplate: -1,
      expanded: false
    };

    this.handleDefault = this.handleDefault.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    if (this.props.handleChange) {
      this.props.handleChange(event);
    }
  };

  handleDefault = (name) => (event) => {
    this.setState({ defaultTemplate: event.target.value });
    if (this.props.handleChange) {
      this.props.handleChange(event);
    }
  };

  filterPermit(action, permits) {
    if (!permits) {
      return {
        permit: action.actionIdentifier,
        hasEveryOne: false,
        groups: []
      };
    }
    let permit = permits.permits.find((permit) => action.actionIdentifier == permit.permit);
    if (!permit) {
      permit = {
        permit: action.actionIdentifier,
        hasEveryOne: false,
        groups: []
      };
      permits.permits.push(permit);
    }
    return permit;
  }

  render() {
    const { intl, module, groupslist } = this.props;
    return (
      <ActionableTable
        title={intl.formatMessage({
          id: this.props.module.name.props.id,
          defaultMessage: this.props.module.name.props.defaultMessage
        })}
      >
        <table className="settingsTable">
          <thead>
            <tr className="infoTableHeader">
              <th scope="col">
                <FormattedMessage id="settings.companyeditor.permissionName" defaultMessage="Permission name" />
              </th>
              <th scope="col">
                <FormattedMessage id="settings.companyeditor.everyone" defaultMessage="Every one" />
              </th>
              {groupslist.map((group) => (
                <th scope="col">{group.name}</th>
              ))}
            </tr>
          </thead>
          <tbody className="tbody">
            {this.props.loading && <Loader displayText={false} />}
            {module &&
              module.actions &&
              module.actions.map((action, index) => {
                return (
                  <ModuleEditorTableRow
                    handleChange={this.handleChange}
                    permits={this.filterPermit(action, this.props.permits)}
                    groupslist={groupslist}
                    intl={intl}
                    action={action}
                    key={`action_${action.actionIdentifier}`}
                  />
                );
              })}
          </tbody>
        </table>
      </ActionableTable>
    );
  }
}
