import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { matrixImageNoRoot, handleQueryBlobAsResult } from '@zert-packages/actions/api';
import ImageFromBlob from '../../shared/ImageFromBlob';

export function PreviewImageDialog({ unmountComponent, onConfirm, onCancel, id }) {
  const [blob, setBlob] = useState(null);
  const [imgError, setImgError] = useState(false);

  useEffect(() => {
    const accessToken = window.localStorage.getItem('accessToken');
    const query = `${matrixImageNoRoot}${id}?jwtToken=${accessToken}`;
    handleQueryBlobAsResult(query)
      .then((result) => {
        // @ts-ignore
        setBlob(result);
      })
      .catch(error => {
        setImgError(true);
      });
  }, [id]);

  const handleConfirm = (info) => () => {
    unmountComponent();
    // onConfirm(info);
  };

  const handleCancel = () => {
    unmountComponent();
    // onCancel();
  };

  return (
    <Dialog open onClose={unmountComponent}>
      <DialogTitle>
        <FormattedMessage id="PreviewImageDialog.Title" defaultMessage="Matrix presentation Image" />
      </DialogTitle>
      <DialogContent>
        <ImageFromBlob blob={blob} error={imgError}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={unmountComponent} color="primary" variant="outlined" autoFocus>
          <FormattedMessage id="PreviewImageDialog.buttonCancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
