import findAllBasis from './findAllBasis';

export default function findAllBasisProject(project, basises) {
  project.basises.map((basis) => {
    // basis.info = project.name;
    basises.push(basis);
  });

  // Search in the current child

  for (let i = 0; i < project.activities.length; i += 1) {
    const currentChild = project.activities[i];
    currentChild.basises.map((basis) => {
      // basis.info = currentChild.name;
      basises.push(basis);
      basis.activityId = currentChild.name;
    });
    // basises =    basises.concat(currentChild.basises);
    findAllBasis(currentChild, basises);
    // Search in the current child
  }
}
