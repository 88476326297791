import { handlePost } from '@zert-packages/actions/api';

export const storeLocalesFilters = (idOwner, idItem, itemUUID, localeFilterType, locales) => {
  return handlePost(`/infologic/storeLocalesFilters/${idOwner}/${idItem}/${itemUUID}/${localeFilterType}`, locales);
};

export const storePublishingFilters = (idOwner, idItem, itemUUID, element) => {
  return handlePost(`/infologic/storePublishingFilters/${idOwner}/${idItem}/${itemUUID}`, element);
};

export const storePublishingConditions = (idOwner, idItem, itemUUID, element) => {
  return handlePost(`/infologic/storePublishingConditions/${idOwner}/${idItem}/${itemUUID}`, element);
};
