import React, { useContext } from "react";
import GroupBlock from "../blockTabs/pageLayout/GroupBlock";
import { TabsContext } from "../../Stylesheet";
import { handleParagraphValue } from "../../utils/helpers";

const Decorations = ({paragraph, templatesIdx}) => {
  const { values, setValues } = useContext(TabsContext);

  const spacingProps = [
    { label: 'Left:', value: paragraph.paddingLeft, type: 'paddingLeft' },
    { label: 'Right:', value: paragraph.paddingRight, type: 'paddingRight' },
    { label: 'Top:', value: paragraph.paddingTop, type: 'paddingTop' },
    { label: 'Bottom:', value: paragraph.paddingBottom, type: 'paddingBottom' }
  ];


  const handleValuesSpacing = (val, name) => {
    const res = { ...paragraph[name], value: +val };
    handleParagraphValue(res, name, 'paragraphSettings', templatesIdx, values, setValues);
  }

  const handleDropMenuSpacing = (valDropMenu, name, val) => {
    const res = { ...paragraph[name], unit: valDropMenu, value: +val };
    handleParagraphValue(res, name, 'paragraphSettings', templatesIdx, values, setValues);
  }

  return(<div>
  {/*  <Decorations disabled={!value.visible} type={type} table={value} setTable={handleValue} />*/}
    <GroupBlock
      label="Spacing to contents"
      valueProps={spacingProps}
      style={{ height: 'Calc(100% - 26px)', gridAutoRows: 'min-content' }}
      // disabled={!value.visible}
      setValueProps={handleValuesSpacing}
      setDropMenu={handleDropMenuSpacing}
    />
  </div>
  )
}

export default Decorations