import React, { useContext, useState } from 'react';
import { getTranslation } from '@zert-packages/utils';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import DialogAddReferences from '@zert-packages/components/dialog/DialogAddReferences';
import { TabsContext } from '../FormatTemplatePlugin';
import InputItem from '../InputItem';
import SelectedBlock from '../SelectedBlock';
import BlockList from '../BlockList';
import { handleInputItem, handleItemLabel, handleItemShortcut } from '../utils/handleInputItem';
import DialogList from '../dialog/DialogList';
import WrapperLabelInputItem from "../component/WrapperLabelInputItem";
import { convertKey } from "../utils/main";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogShortCut from "../dialog/DialogShortCut";

function TableTabs({disable}) {
  const { values, setValues } = useContext(TabsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogReferences, setOpenDialogReferences] = useState(false);
  const [propsDialog, setPropsDialog] = useState(null);

  const handleValue = (val, Identifier) => {
    handleInputItem(Identifier, val, setValues);
  };

  const handleValueLabel = (val, Identifier) => {
    handleItemLabel(Identifier, val, setValues);
  };

  const handleValueShortcut = (val, Identifier) => {
    handleItemShortcut(Identifier, val, setValues);
  };

  const handleOpenDialog = (label) => {
    setOpenDialog(true);
    setPropsDialog(label);
  };

  return (
    <div>
      <DialogList open={openDialog} setOpen={setOpenDialog} text={propsDialog} />
      <DialogAddReferences open={openDialogReferences} setOpen={setOpenDialogReferences} text={propsDialog} />
      <InputItem
        label="Identifier:"
        valueProps={values.templateList.identifier && values.templateList.identifier}
        disabled={disable}
        Identifier="identifier"
        setValueProps={handleValue}
        // style="100px 1fr"
      />
      <WrapperLabelInputItem  disable={disable}/>
      <InputItem
        label="Shortcut:"
        valueProps={values.templateList.shortcut && convertKey(values.templateList.shortcut.keyCode)}
        setValueProps={handleValueShortcut}
        Identifier="shortcut"
        disabled={true}
        icons={
          <FlipToBackIcon
            style={{ cursor: "pointer", color: "gray" }}
            onClick={() => !disable &&renderInExceptionRoot(DialogShortCut, { setValues })}
          />
        }
      />
      <BlockList
        disable={disable}
        label="Settings:"
        formats={
          values.templateList.settings &&
          values.templateList.settings.properties.length > 0 &&
          values.templateList.settings.properties &&
          values.templateList.settings.properties
        }
      />
      <SelectedBlock
        disable={disable}
        label="References:"
        formats={values.templateList.references && values.templateList.references}
      />
    </div>
  );
}

export default TableTabs;
