export function createUnicodeMap(unicodeRange) {
  const unicodeMap = [];
  for (let i = unicodeRange.startAt.codePointAt(0); i <= unicodeRange.endAt.codePointAt(0); i++) {
    const character = String.fromCodePoint(i);
    const unicodeName = getUnicodeName(character);
    const unicodeNumber = i.toString(16).toUpperCase();
    unicodeMap.push({ code: character, unicodeName, unicodeNumber });
  }
  return unicodeMap;
}

function getUnicodeName(character) {
  return `NAME_${character.codePointAt(0).toString(16).toUpperCase()}`;
}


