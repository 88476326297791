import React, { useEffect, useState, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { containsMyMeasure } from '@zert-packages/utils/containsMyMeasure';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import { canLoad } from '@zert-packages/actions/coreReducers';
import Loader from '@zert-packages/components/shared/Loader/Loader';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  messageContainer: {
    position: 'absolute',
    right: 10,
    top: 60,
    zIndex: 1000
  },
  message: {
    padding: '2px 8px',
    background: 'rgb(51,51,51, 0.75)',
    color: 'rgb(245,245,245)',
    cursor: 'pointer',
    userSelect: 'none',
    borderRadius: 5
  }
});

function DisabledElementMode({ children, containerStyle = {} }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [restricted, setRestricted] = useState(false);
  const [isClickedClose, setIsClickedClose] = useState(false);
  const { versionInfo, permits, myuser, measure_riskrow } = useSelector((state) => state);
  const message = useRef();
  async function hasPermit() {
    const userIsPermittedToLoad = await canLoad(versionInfo.info.versionId);
    setRestricted(!userIsPermittedToLoad);
    setLoading(false);
  }
  async function setMode() {
    //   setLoading(true);

    if (versionInfo.info.currentVersionId !== versionInfo.info.versionId) {
      message.current = (
        <FormattedMessage
          id="DisabledElementMode.ApprovedChange"
          defaultMessage="Approved, change to the last version to make changes"
        />
      );
      setOpen(true);

      return;
    }
    if (versionInfo.info.approvedAt) {
      message.current = (
        <FormattedMessage
          id="DisabledElementMode.ApprovedCreate"
          defaultMessage="Approved, create a new version to make changes"
        />
      );
      setOpen(true);
      return;
    }

    const plugin = getPluginByMimeType(versionInfo.info.mimeType);

    if (plugin.mimeType !== 'risk-row' && plugin.mimeType !== 'measure') {
      if (!hasPermit(plugin, permits)) {
        message.current = (
          <FormattedMessage id="DisabledElementMode.Permission" defaultMessage="You dont have permission to modify" />
        );
        setOpen(true);
        return;
      }
    } else if (measure_riskrow && !containsMyMeasure(myuser, measure_riskrow)) {
      message.current = (
        <FormattedMessage id="DisabledElementMode.Permission" defaultMessage="You dont have permission to modify" />
      );
      setOpen(true);

      /*  let origin = await getOrigin(versionInfo.info.versionId);
        for(let i = 0;  i < origin.length; i++) {
          if (origin.mimeType == 'application/zert-checklist' || origin.mimeType == 'application/zert-risk-analysis') {
            const canLoad = await canLoad(origin.versionId);
            setRestricted(canLoad);
          }
        } */

      return;
    }

    if ((!open && versionInfo.info.approvedAt) || versionInfo.info.archivedAt) {
      message.current = (
        <FormattedMessage id="DisabledElementMode.ReadOnly" defaultMessage="Read only you can`t change this element!" />
      );
      setOpen(true);
      return;
    }

    if (versionInfo.checkedOutBy && myuser && myuser.userName !== versionInfo.checkedOutBy) {
      message.current = (
        <FormattedMessage
          id="DisabledElementMode.Checkout"
          defaultMessage="The object can’t be edited since {name} is already working on it."
          values={{ name: versionInfo.checkedOutBy }}
        />
      );
      setOpen(true);
      return;
    }
    setOpen(false);
  }

  useEffect(() => {
    if (versionInfo && versionInfo.info && !isClickedClose) setMode();
  });

  useEffect(() => {
    if (versionInfo && versionInfo.info) hasPermit();
  }, [versionInfo]);

  useEffect(() => {
    setIsClickedClose(false);
  }, [versionInfo && versionInfo.info.versionId]);

  const handleClose = () => {
    setOpen(false);
    setIsClickedClose(true);
  };

  return (
    <div className={classes.container} style={containerStyle}>
      {loading && (
        <Loader>
          <FormattedMessage id="DisabledElement.loading" defaultMessage="Loading element permission..." />
        </Loader>
      )}
      {!restricted && !loading && children}

      {restricted && !loading && (
        <FormattedMessage
          id="riskasssesment.restricted"
          defaultMessage="You are not authorized for the riskassesment. The owner of the riskassesment has set limited access to it"
        />
      )}
      {open && (
        <div onClick={handleClose} className={classes.messageContainer}>
          <div className={classes.message}>{message.current}</div>
        </div>
      )}
    </div>
  );
}

export default DisabledElementMode;
