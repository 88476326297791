import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {useSelector} from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import CategoryDialog from './CategoryDialog';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    rootCategories: {
        display: 'grid',
        gridTemplateColumns: '1fr 15px',
        gridColumnGap: 5,
        padding: 5,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        marginTop: 5,
        height: '100%'
    },
    editorIcon: {
        position: 'relative',
        display: 'grid',
        gap: 15,
        color: '#4db1d3',
        height: 'min-content',
        '& svg': {
            cursor: 'pointer',
        }
    },
    listLeftMenu: {
        padding: '2px 4px',
        cursor: 'pointer'
    },
    listLeftMenuSelected: {
        padding: '2px 4px',
        color: '#ffffff',
        background: '#4db1d3'
    },
}))

const initActionState = {
    isOpen: false,
    isEdit: false,
    isRemove: false,

};

function CategoriesTab() {
    const cl = useStyles();
    const [open, setOpen] = useState(false);
    const CLM = useSelector((state) => state.CLM);
    const [actionState, setActionState] = useState(
        {...initActionState,  selectedElement: -1}
    );

    const handlerOpenDialog = (action) => {
        if(actionState.selectedElement === -1 && action !== 0){
            return
        }
        setActionState((prevState) => ({
            ...prevState,
            isEdit: action === 1,
            isRemove: action === 2,
            isOpen: true
        }));
    };


    const handlerCloseDialog = () => {
        setActionState(initActionState);
    };

    const handleListItemClick = (event, index) => {
        setActionState((prevState) => ({
            ...prevState,
            selectedElement: index
        }));
    };



    return (
        <div className={cl.rootCategories}>
            <List component="nav" aria-label="main mailbox folders">
                {CLM.index.categories &&
                    CLM.index.categories.map((el, idx) => <li
                        key={idx}
                        className={actionState.selectedElement === idx ? cl.listLeftMenuSelected : cl.listLeftMenu}
                        onClick={(event) => handleListItemClick(event, idx)}
                    >
                        {el}
                    </li>)}
            </List>

            <div className={cl.editorIcon}>
                <AddIcon  onClick={(event) => handlerOpenDialog(0)}/>
                <EditIcon onClick={(event) => handlerOpenDialog(1)}/>
                <DeleteIcon onClick={(event) => handlerOpenDialog(2)}/>
            </div>
            {actionState.isOpen && <CategoryDialog handleClose={handlerCloseDialog} actionState={actionState}/>}
        </div>

    );
}

export default CategoriesTab;
