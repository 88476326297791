import React, { Fragment } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { fetchSetRoute } from '@zert-packages/actions/coreReducers';
import useAuth from './useAuth';

function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const  history  = useHistory();
  if (!isAuthenticated) {
    // console.log(history.location.pathname);
    if (history) {
      dispatch(fetchSetRoute(history.location.pathname));
    }

    return <Redirect to="/login" />;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
