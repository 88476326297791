import React, {useContext, useEffect, useState} from "react";
import InfoElementWrapper, {
  PanelContext
} from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import Loader from "@zert-packages/components/shared/Loader/Loader";
import { useParams } from "react-router-dom";
import CustomTable from "./resizedTable/CustomTable";




const TableView = () => {

  //  const tableApiResult = await getTableApi(el.elementInfo.currentVersionId);

  return (
    <InfoElementWrapper nameShow={false}>
      <div>
        <TableWrapper />
        <div style={{margin: 50}}></div>
      </div>
    </InfoElementWrapper>
  );
}

export default TableView


export const TableWrapper = () => {
  const { values, setValues } = useContext(PanelContext);
  const el = values?.parentBlock && { ...values.parentBlock, elementInfo: values.parentBlock.info }
  const { versionId } = useParams();

  if (!el ||  +el.info.versionId !== +versionId) {
    return (
      <span>
        <Loader displayText={false} />
      </span>
    );
  }

  // TextFieldItem

  return (
      <div style={{marginTop: 5}}>
        <CustomTable versionId={versionId}/>
  </div>
  )
}