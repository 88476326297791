import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { Dialog } from "@mui/material";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import TextFieldItem from "../TextFieldItem";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 414,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700,
    textTransform: "capitalize"
  },
  wrapperText: {
    fontWeight: 700,
    marginBottom: 10,
  },
  wrapperButton: {
    display: "flex",
    marginTop: 30,
    justifyContent: "end",
    gap: 10
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

const ImportTextDialog = ({ type }) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [values, setValues] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const importText = () => {
    handleClose();
  };

  const handleValue = (val) => {
    setValues(val);
  };


  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}><FormattedMessage id="Block.ElementMainTile.Title.InsertText"
                                                    defaultMessage="Insert text" /></span>
      <CloseIcon onClick={handleClose} />
    </div>
    <div className={cl.wrapperText}>
      <FormattedMessage id="Block.ElementMainTile.Title.InsertText.TextEnter" defaultMessage="Enter or paste text in the text field and click on Import." />
    </div>
    <TextFieldItem values={values} setValues={handleValue} multiline={true} row={11} />
    <div className={cl.wrapperButton}>
      <Button className={cl.button} onClick={importText}>
        Import
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>
  </Dialog>);
};

export default ImportTextDialog;