import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { TabsContext } from '../../Stylesheet';
import { adobeWord, handleTableParents } from '../../utils/helpers';
import TemplateItemRow from '../../TemplateItemRow';
import SpacingTemplate from '../blockTabs/topic/SpacingTemplate';
import DialogHeightTable from "../dialog/DialogHeightTable";
import { renderInExceptionRoot } from "@zert-packages/utils";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700
  },
  borderBlock: {
    border: '1px solid #C6C6C5',
    padding: 10,
    '& div(:first-child)': {
      marginBottom: 10
    },

    height: 'Calc(100% - 22px)',
    '& div': {
      marginBottom: 0
    }
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '155px 1fr',
    gridColumnGap: 10
  },
  wrapperLineHeight: {
    display: 'grid',
    gridTemplateColumns: '1fr 25px',
    gridColumnGap: 10,
    alignItems: 'center',
    marginBottom: 40
  }
}));

function TextFlow({ table, type = 'headerRow', typeParents = 'tableSettings', disabled, setProps }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const horizontalDropData = ['None', 'Left', 'Center', 'Right', 'Inherit'];
  const verticalDropData = ['Top', 'Middle', 'Bottom'];
  const lineHeight = adobeWord(table.lineHeight.defaultValue.type, true);

  const findDisable = () => {
    if (lineHeight === 'Default' || disabled) {
      return true;
    }
    if (lineHeight === 'Single') {
      return true;
    }
    return false;
  };

  const handleAlignment = (val, name) => {
    if (setProps) {
      return setProps(val, name);
    }
    handleTableParents(val, name, typeParents, type, values, setValues);
  };

  const handleMenuFirst = (val, name) => {
    const res = {
      ...table.lineHeight,
      defaultValue: {
        ...table.lineHeight.defaultValue,
        type: val.toUpperCase()
      }
    };
    if (setProps) {
      return setProps(res, name);
    }
    handleTableParents(res, name, typeParents, type, values, setValues);
  };

  const setValueLineHeight = (val, name) => {
    const res = {
      ...table.lineHeight,
      defaultValue: {
        ...table.lineHeight.defaultValue,
        value: { value: +val, unit: table.lineHeight.defaultValue.value.unit }
      }
    };
    if (setProps) {
      return setProps(res, name);
    }
    handleTableParents(res, name, typeParents, type, values, setValues);
  };
  const handleLineHeightMenu = (valDropMenu, name, val) => {
    const res = {
      ...table.lineHeight,
      defaultValue: {
        ...table.lineHeight.defaultValue,
        value: { value: +val, unit: valDropMenu }
      }
    };
    if (setProps) {
      return setProps(res, name);
    }
    handleTableParents(res, name, typeParents, type, values, setValues);
  };

  const handleSpacing = (val, name) => {
    const res = { ...table[name], value: { ...table[name].value, value: +val } };
    handleTableParents(res, name, typeParents, type, values, setValues);
  };

  const handleSpacingMenu = (valDrop, name, val) => {
    const res = { ...table[name], value: { unit: valDrop, value: +val } };
    handleTableParents(res, name, typeParents, type, values, setValues);
  };
  const handleSpacingBefore = (val, name, nameObject) => {
    const res = { ...table[name], [nameObject]: val };
    handleTableParents(res, name, typeParents, type, values, setValues);
  };

  const handleValuePropsDialogBefore = (val, name) => {
    const res = {
      ...table.spaceBefore,
      [name]: {
        unit: table.spaceBefore[name] ? table.spaceBefore[name].unit : 'pt',
        value: +val
      }
    };
    handleTableParents(res, 'spaceBefore', typeParents, type, values, setValues);
  };

  const setDropMenuPropsDialogBefore = (valDrop, name, val) => {
    const res = {
      ...table.spaceBefore,
      [name]: {
        unit: valDrop,
        value: +val
      }
    };
    handleTableParents(res, 'spaceBefore', typeParents, type, values, setValues);
  };

  const setDropMenuPrecedenceBefore = (val) => {
    const res = { ...table.spaceBefore, precedence: val.name };
    handleTableParents(res, 'spaceBefore', typeParents, type, values, setValues);
  };

  const handleValuePropsDialogAfter = (val, name) => {
    const res = {
      ...table.spaceAfter,
      [name]: {
        unit: table.spaceBefore[name] ? table.spaceBefore[name].unit : 'pt',
        value: +val
      }
    };
    handleTableParents(res, 'spaceAfter', typeParents, type, values, setValues);
  };
  const setDropMenuPropsDialogAfter = (valDrop, name, val) => {
    const res = {
      ...table.spaceAfter,
      [name]: {
        unit: valDrop,
        value: +val
      }
    };
    handleTableParents(res, 'spaceAfter', typeParents, type, values, setValues);
  };

  const setDropMenuPrecedenceAfter = (val) => {
    const res = { ...table.spaceAfter, precedence: val.name };
    handleTableParents(res, 'spaceAfter', typeParents, type, values, setValues);
  };

  const openHeightDialog = () => {
    renderInExceptionRoot(DialogHeightTable, {})
  }



  return (
    <div>
      <span className={cl.title}>Text</span>
      <div className={cl.borderBlock}>
        <div className={cl.wrapperRow}>
          <span className={cl.title}>Horizontal alignment:</span>
          <ExpanderItem
            valueProps={adobeWord(table.horizontalAlign, true)}
            propsLabel={horizontalDropData}
            type="horizontalAlign"
            setProps={handleAlignment}
            disabled={disabled}
          />
        </div>
        <div style={{ marginBottom: 10 }} />
        <div className={cl.wrapperRow}>
          <span className={cl.title}>Vertical alignment:</span>
          <ExpanderItem
            valueProps={adobeWord(table.verticalAlign, true)}
            propsLabel={verticalDropData}
            type="verticalAlign"
            setProps={handleAlignment}
            disabled={disabled}
          />
        </div>
        <div style={{ marginBottom: 10 }} />
        <div className={cl.wrapperLineHeight}>
          <TemplateItemRow
            label="Line height:"
            type="lineHeight"
            value={table.lineHeight.defaultValue.value}
            dropMenuFirst={{
              label: ['Default', 'Single', 'At least', 'Fixed'],
              selected: table.lineHeight.defaultValue.type
            }}
            grid="155px 150px 1fr 55px"
            setDropMenuFirst={handleMenuFirst}
            setDropMenu={handleLineHeightMenu}
            setValueProps={setValueLineHeight}
            disabled={findDisable()}
            disableMenuFirst={disabled}
          />
          <InsertDriveFileIcon style={{ color: 'gray', cursor: 'pointer' }} onClick={openHeightDialog} />
        </div>
        <div style={{ marginBottom: 10 }} />
        {table.spaceBefore && (
          <SpacingTemplate
            title="Space before:"
            type="spaceBefore"
            valueProps={table.spaceBefore}
            grid="155px 150px 1fr 55px"
            setValueProps={handleSpacing}
            setDropMenu={handleSpacingMenu}
            handleBefore={handleSpacingBefore}
            setValuePropsDialog={handleValuePropsDialogBefore}
            setDropMenuPropsDialog={setDropMenuPropsDialogBefore}
            setDropMenuPrecedence={setDropMenuPrecedenceBefore}
            disabled={disabled}
          />
        )}
        <div style={{ marginBottom: 10 }} />
        {table.spaceAfter && (
          <SpacingTemplate
            title="Space after:"
            type="spaceAfter"
            valueProps={table.spaceAfter}
            grid="155px 150px 1fr 55px"
            setValueProps={handleSpacing}
            setDropMenu={handleSpacingMenu}
            handleBefore={handleSpacingBefore}
            setValuePropsDialog={handleValuePropsDialogAfter}
            setDropMenuPropsDialog={setDropMenuPropsDialogAfter}
            setDropMenuPrecedence={setDropMenuPrecedenceAfter}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
}

export default TextFlow;
