import { createInProgressSnack, createOpenReportSnack } from "@zert-packages/components/common/snackBar";
import { FormattedMessage } from "react-intl";
import { showSnackbarError } from "@zert-packages/components/Snackbars";
import React from "react";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import { createImage, getDoubles, loadNewImageReference, uploadImage } from "./API";
import { DoubleDialog } from "./DoubleDialog";
import { createNewImage } from "../TranslationJobPlugin/tjReducers";


export const uploadImageFile = (uuid, getImg) => (dispatch, getState, { snackbar }) => {
  const onChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.target;
    const file = files[0];
    const fd = new FormData();

    if (!file) return;
    fd.append("file", file, file.name);
    e.target.value = null;
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="CLM.UploadingFile.Image" defaultMessage="Uploading new image" />
    );

    let value = null
    let right = null
    const dimensions = {}

    const loadImage = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function(event) {
          const imgElement = new Image();
          imgElement.onload = function() {
            dimensions.width = imgElement.width;
            dimensions.height = imgElement.height;
            resolve(imgElement);
          };
          imgElement.src = event.target.result;
        };
        reader.readAsDataURL(file);
      }).then();
    };
    loadImage(file).then((imgElement) => {
      // document.body.appendChild(imgElement);

      uploadImage(fd)
        .then((res) => {
          // snackbar.closeSnackbar(generatingSnackbar);
          value = res.left;
          // showSnackbarChangesSaved(snackbar, <FormattedMessage id="CLM.PrintManager.Snackbar.UploadFiled" defaultMessage="uploaded succesefully"/>);
          right = value
          return getDoubles(res.right);
        })
        .then((similar) => {
          if (similar.length > 0) {
            renderInExceptionRoot(DoubleDialog, {
              dimensions: dimensions,
              list: similar.map(el => ({...el, info: el})),
              onConfirm: async (info) => {
                snackbar.closeSnackbar(generatingSnackbar);
                const reference = await loadNewImageReference(info.info.versionId);
                const img = { uuid, info: reference}
                if (getImg) {
                  return getImg({ ...img, right });
                }
                dispatch(createNewImage(img))
              },
              onCancel: async () => {
                const newImage = await createImage(value, file.name, dimensions);
                const newRef = await loadNewImageReference(newImage.info.versionId);
                const img = { uuid, info: newRef };
                snackbar.closeSnackbar(generatingSnackbar);
                if (getImg) {
                  return getImg({...img, right })
                }
                dispatch(createNewImage(img));
              }
            });
          } else {
            return createImage(value, file.name, dimensions);
          }
        })
        .then((info) => {
          if (info) {
            return loadNewImageReference(info.versionId || info.info.versionId);
          }
        })
        .then((info) => {
          if (info) {
            snackbar.closeSnackbar(generatingSnackbar);
            const img = { uuid, info };
            if (getImg) {
              return  getImg({ ...img, right });
            }
            dispatch(createNewImage(img));
          }
        })
        .catch((e) => {
          snackbar.closeSnackbar(generatingSnackbar);
          showSnackbarError(snackbar, e.message);
        });
    });

  };

  const a = document.createElement("input");
  a.type = "file";
  document.body.appendChild(a);
  // a.style = "visibility: hidden";
  a.style = "display:none";
  a.click();
  a.onchange = (e) => {
    onChange(e);
  };
};
