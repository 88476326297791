import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogAddMetaData from "./DialogAddMetaData";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 12,
    backgroundColor: "#ffffff",
    height: 392
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 40px",
    height: "100%"
  },
  wrapperBlock: {
    display: "grid",
    gridTemplateColumns: "200px 1fr",
    gridColumnGap: 10,
    margin: 5,
  },
  header: {
    border: 'solid 1px #d7d9d8',
    height: 'fit-content',
  },
  wrapperEditor: {
    color: '#4db1d3',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
    '& svg': {
      cursor: 'pointer',
      display: 'block'

    }
  },
}));




const TabsMetaData = () => {
  const cl = useStyles();
  const [metaData, setMetaData] =useState([])

  const AddMetaData = () => {
    renderInExceptionRoot(DialogAddMetaData, {})
  }

  const removeMetaData = () => {

  }

  return (<div className={cl.root}>
    <div className={cl.wrapper}>
      <div className={cl.header}>
        <div className={cl.wrapperBlock}>
          <div>Name</div>
          <div>Value</div>
        </div>
      </div>
      {metaData.length > 0 && metaData.map((el, i) => <div key={i} className={cl.wrapperBlock}>
          <div>{el.name}</div>
          <div>{el.value}</div>
      </div>)}
      <div className={cl.wrapperEditor}>
        <AddIcon onClick={AddMetaData} />
        <DeleteIcon onClick={removeMetaData} />
      </div>
    </div>
    <div>

    </div>
  </div>);
};

export default TabsMetaData;