import TableCell from "@tiptap/extension-table-cell";
import React from "react";
import {getShortImageApi} from "../../../../BlockPlugin/API";


const findImg = async (data) => {
    if (!data) {
        return
    }
    const promises = data.internalContentAdobe.map(async (el) => {

        if (el.elementInfo.mimeType.split("/").pop() === 'zert-image') {
            const res = await getShortImageApi(el.elementInfo.versionId);

            return {...el, 'imgApi': res};
        }
        return el;
    });
    const result = await Promise.all(promises);

    return {...data, internalContentAdobe: result}
}

export const createTable = async (tableApiResult, setTable, setContentDefault, vue,) => {
    if (!tableApiResult) {
        return;
    }


    const getTableItem = await findImg(tableApiResult ? {
        ...tableApiResult,
        rowCount: tableApiResult.rowCount > 3 && vue ? 3 : tableApiResult.rowCount,
        internalSpanmap: adobeSpanMap(tableApiResult.internalSpanmap),
        internalContentAdobe: Object.values(tableApiResult.internalContent).map((el, i) => (
            {
                ...el,
                TablePositionX: +Object.keys(tableApiResult.internalContent)[i].match(/\d+/g).slice(0, 1),
                TablePositionY: +Object.keys(tableApiResult.internalContent)[i].match(/\d+/g).slice(1)
            })),
        cellsBackgroundColorsAdobe: tableApiResult.cellsBackgroundColors ? Object.values(tableApiResult.cellsBackgroundColors).map((el, i) => (
            {
                ...el,
                TablePositionX: +Object.keys(tableApiResult.cellsBackgroundColors)[i].match(/\d+/g).slice(0, 1),
                TablePositionY: +Object.keys(tableApiResult.cellsBackgroundColors)[i].match(/\d+/g).slice(1)
            })) : null
    } : null);

    setTable(getTableItem);
    let cellItemAcu = getTableItem.internalSpanmap;
    let margeStop = false;
    let arr = [];
    let cellTotal = 0;

    const table = `<table>
            <tbody>${
        Array.from({length: getTableItem.rowCount}, (_, i) => i).reduce((acu, el, i) => {


            return (acu + `<tr key={${i}}  >
                        ${Array.from({length: getTableItem.columnCount}, (_, i) => i).reduce((acum, it, index) => {


                const findRow = arr.find(el => el.TablePositionX <= i && el.TablePositionY === index);
                if (findRow) {
                    if (findRow.y > 1) {
                        arr = arr.reduce((acuArr, curArr) => {
                            if (curArr.TablePositionX === i) {
                                const acumRow = curArr.y === 1 ? 1 : curArr.y - 1;
                                return [...acuArr, {...curArr, y: acumRow}];
                            }
                            return [...acuArr, curArr];
                        }, []);
                        if (findRow.x > 1) {
                            cellTotal = findRow.x;
                            margeStop = true;
                        }
                        return acum;
                    }
                }
                if (margeStop) {
                    margeStop = false;
                    return acum;
                }

                if (cellTotal > 2) {
                    cellTotal = cellTotal - 1;
                    return acum;
                }

                const findTableSpan = cellItemAcu && cellItemAcu.find(el => el.TablePositionX === i && el.TablePositionY === index);

                const findWidth = getTableItem.internalColumns[index].width;


                const findTableCellResult = findTableCell(getTableItem.internalContentAdobe, i, index)
                if (findTableSpan) {
                    const findActiveCell = cellItemAcu.find(el => el.id === findTableSpan.id);
                    if (findActiveCell.row > 1) {
                        arr.push({
                            x: findActiveCell.col,
                            y: findActiveCell.row,
                            TablePositionX: findActiveCell.TablePositionX + 1,
                            TablePositionY: findActiveCell.TablePositionY
                        });
                    }
                    if (findActiveCell.col > 1) {
                        cellTotal = findActiveCell.col;
                        margeStop = true;
                    }


                    return (acum + `<td
                              style="${findWidth > 0 ? findWidth + "%" : ""}"
                              backgroundColor="${findTableCellColor(getTableItem.cellsBackgroundColorsAdobe, i, index)}"
                              id="${findTableCellId(getTableItem.internalContentAdobe, i, index)}"
                              
                              idx="${i + "-" + index}"
                                colSpan="${findActiveCell.col}"
                                rowSpan="${findActiveCell.row}"
                                key="${index}">
                                  ${findTableCellResult}
                            </td>`);
                }


                margeStop = false;
                return (acum + `<td
                                idx="${i + "-" + index}"
                                style="${findWidth > 0 ? findWidth + "%" : ""}"
                                backgroundColor="${findTableCellColor(getTableItem.cellsBackgroundColorsAdobe, i, index)}"
                                id="${findTableCellId(getTableItem.internalContentAdobe, i, index)}"
                                key="${index}">
                                ${findTableCellResult}
                            </td>`);
            }, "")}
                    </tr>`);
        }, "")
    }</tbody>
             </table>`;
    setContentDefault(table);

    // editor.commands.setContent(table);
};

export const handeValue = (name, value, set) => {
    set(pre => ({...pre, [name]: value}));
};

export const CustomTableCell = () => {
    return TableCell.extend({
        addAttributes() {
            return {
                ...this.parent?.(),
                idx: {
                    default: null,
                    renderHTML: attributes => {
                        return {
                            idx: `${attributes.idx}`
                        };
                    }
                },
                id: {
                    default: null,
                    renderHTML: attributes => {
                        return {
                            id: `${attributes.id}`
                        };
                    }
                },
                style: {
                    default: null,
                    renderHTML: attributes => {
                        return {
                            style: `width: ${attributes.style}`
                        };
                    }
                },
                backgroundColor: {
                    default: null,
                    renderHTML: attributes => {
                        return {
                            style: `background-color: ${attributes.backgroundColor ? attributes.backgroundColor : ""}`,
                            class: "cell"
                        };
                    }
                }
            };
        }
    });
};


export const findTableCell = (parents, indexX, indexY) => {
    // <!--            <img  src="${res.imgApi.config.url}" alt="img-main" /> -->
    // ReactDomServer.renderToString(<div>p</div>);

    const res = parents.find(el => el.TablePositionX === indexX && el.TablePositionY === indexY);

    if (res) {
        if (res.imgApi) {
            return (
                `<div>
         <react-component 
         imgUrl=${res.imgApi.config.url}
         type=${res.elementInfo.mimeType}
         name=${res.elementInfo.name}
         label=${res.elementInfo.label}
        />
        </div>`
            );
        }
        return (
            `<div>
        <react-component 
         type=${res.elementInfo.mimeType}
         name=${res.elementInfo.name}
         label=${res.elementInfo.label}
        />
        
        </div>`
        );
    }
    return "";
};


export const findTableCellId = (parents, indexX, indexY) => {
    const res = parents.find(el => el.TablePositionX === indexX && el.TablePositionY === indexY);
    if (res) {
        return `${res.identifier}`;
    }
    return 0
};

const findTableCellColor = (parents, indexX, indexY) => {
    const res = parents.find(el => el.TablePositionX === indexX && el.TablePositionY === indexY);
    if (res) {
        return `rgba(${res.red}, ${res.green}, ${res.blue}, ${res.alpha})`;
    }
    return "";
};

export const adobeSpanMap = (val) => {
    if (!val) {
        return;
    }
    return Object.entries(val).map(([key, value], index) => {

        return {
            id: index,
            TablePositionX: +key.match(/\d+/g).slice(0, 1),
            TablePositionY: +key.match(/\d+/g).slice(1),
            ...value
        };
    });
};

export const adobeSpanMapNew = (val) => {

    if (!val || val.length === 0) {
        return val
    }
  // [
  //     {
  //         "id": 0,
  //         "TablePositionX": 0,
  //         "TablePositionY": 1,
  //         "row": 2,
  //         "col": 1
  //     }
  // ]

    return val.map((el, index) => ({
        ...el,
        id: index,
        "TablePositionX": el?.keyPosition.row,
        "TablePositionY": el?.keyPosition.column,
        "row": el?.actualPosition.row,
        "col": el?.actualPosition.column,
    }))
}

export const findInterest = (cell, cl) => {
    if (!cell || !cell.width || cell.width === 0) {
        return <span></span>;
    }
    return (<span className={cl.interest}>{cell.width}%</span>);
};

export const findDecorator = (inputString) => {
    const regex = /<alias\s([^>]+)/g;
    const resultArray = [];
    const matches = inputString.match(regex);

    if (matches) {
        matches.forEach(match => {
            const nameMatch = match.match(/<alias\s([^>]+)/);
            if (nameMatch) {
                const word = nameMatch[1].trim();
                resultArray.push({word, aliasDecorator: true});
            }
        });
    }
    return resultArray
}
