import React, {useState} from "react";
import BoxItem from "./BoxItem";
import {conditionOptions, getFilterCondition} from "../../PublicationFilters";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import {usePublishingFilterStyles} from "./styles";

const CategoryRoot = ({conditionIdx, setFilterConditions, condition, categoryItems}) => {
    const [openConditionMenu, setOpenConditionMenu] = useState(false);
    const cl = usePublishingFilterStyles({open: openConditionMenu})

    return (
        <div className={cl.root}>
            <div className={cl.valBox} onClick={() => setOpenConditionMenu(prev => !prev)}>
                <div>
                    {condition.condition.label}
                </div>
                <div className={openConditionMenu ? cl.optionBoxOpen : cl.optionBoxHidden}>
                    <div onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setOpenConditionMenu(prev => !prev)
                        setFilterConditions(prev => {
                            prev[conditionIdx].condition = conditionOptions[1]
                            return prev
                        })
                    }}>
                        AND
                    </div>
                    <div onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setOpenConditionMenu(prev => !prev)
                        setFilterConditions(prev => {
                            prev[conditionIdx].condition = conditionOptions[0]
                            return prev
                        })
                    }}>
                        OR
                    </div>
                </div>
            </div>
            <div style={{borderLeft: "1px solid grey", marginLeft: "10px"}}>
                {condition.childConditions.map((childCondition, childConditionIdx) => {
                    return <div className={cl.boxRoot}>
                        <div className={cl.valBox} onClick={() => setFilterConditions(prev => {
                            const childs = prev[conditionIdx].childConditions
                            childs[childConditionIdx].conditionPopupOpen = !childs[childConditionIdx].conditionPopupOpen
                            prev[conditionIdx].childConditions = childs
                            return [...prev]
                        })}>
                            <div>
                                {childCondition.condition.label}
                            </div>
                            <div className={childCondition.conditionPopupOpen ? cl.optionBoxOpen : cl.optionBoxHidden}>
                                <div onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setFilterConditions(prev => {
                                        const childs = prev[conditionIdx].childConditions
                                        childs[childConditionIdx].conditionPopupOpen = false
                                        childs[childConditionIdx].condition = conditionOptions[1]
                                        prev[conditionIdx].childConditions = childs
                                        return [...prev]
                                    })
                                }}>
                                    AND
                                </div>
                                <div onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setFilterConditions(prev => {
                                        const childs = prev[conditionIdx].childConditions
                                        childs[childConditionIdx].conditionPopupOpen = false
                                        childs[childConditionIdx].condition = conditionOptions[0]
                                        prev[conditionIdx].childConditions = childs
                                        return [...prev]
                                    })
                                }}>
                                    OR
                                </div>
                            </div>
                        </div>
                        {childCondition?.filters?.map((childElements, idx) => {
                            return <BoxItem key={idx} childCondition={childElements}
                                            changeChildData={(childCondition) => {
                                                setFilterConditions(prev => {
                                                    const childs = prev[conditionIdx].childConditions[childConditionIdx].filters
                                                    childs[idx] = childCondition
                                                    prev[conditionIdx].childConditions[childConditionIdx].filters = childs
                                                    return [...prev]
                                                })
                                            }}
                                            categoryItems={categoryItems}
                                            deleteChildCondition={() => {
                                                setFilterConditions(prev => {
                                                    const childs = prev[conditionIdx].childConditions[childConditionIdx].filters
                                                    childs.splice(idx, 1)
                                                    prev[conditionIdx].childConditions[childConditionIdx].filters = childs
                                                    return [...prev]
                                                })
                                            }}
                            />
                        })}
                        <Box sx={{
                            display: 'flex',
                            justifyItems: 'space-between',
                            flexDirection: {xs: 'column', md: 'row'}
                        }}>
                            {categoryItems && categoryItems.length > 0 && <Button variant={"contained"} onClick={() => {
                                setFilterConditions(prev => {
                                    const childs = prev[conditionIdx].childConditions[childConditionIdx].filters
                                    childs.push(getFilterCondition(null, categoryItems[0], null))
                                    prev[conditionIdx].childConditions[childConditionIdx].filters = childs
                                    return [...prev]
                                })
                            }} sx={{margin: '10px'}}>+ Filter</Button>}
                            <Button variant={"contained"} onClick={() => {
                                setFilterConditions(prev => {
                                    const childs = prev[conditionIdx].childConditions
                                    childs.splice(childConditionIdx, 1)
                                    prev[conditionIdx].childConditions = childs

                                    if(prev[conditionIdx].childConditions.length === 0) {
                                        return []
                                    }

                                    return [...prev]
                                })
                            }} sx={{margin: '10px'}}>Delete group</Button>
                        </Box>
                    </div>
                })}
            </div>
        </div>
    )
}

export default CategoryRoot