import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { fontData } from '../../data/fontData';
import { adobeFont, handleParagraphValue } from '../../utils/helpers';
import { TabsContext } from '../../Stylesheet';
import TemplateItemRow from '../../TemplateItemRow';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 10
  },
  title: {
    fontSize: '1rem',
    fontWeight: '700',
    marginBottom: 0
  },
  wrapperRowTitle: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gridColumnGap: 10,
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    }
  },
  wrapperCheckbox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr ',
    maxWidth: 500
  }
}));

function Font({ paragraph, templatesIdx }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [firstMenu, setFistMenu] = useState('User defined');

  const findCheckbox = (el) => {
    return paragraph.fontStyle.map((it) => it.toLowerCase()).find((it) => it === el.toLowerCase());
  };

  const handleCheckboxFontStyle = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    if (val) {
      const result = [...paragraph.fontStyle, name.toUpperCase()];
      return handleParagraphValue(result, 'fontStyle', 'paragraphSettings', templatesIdx, values, setValues);
    }
    const result = paragraph.fontStyle.filter((el) => el !== name.toUpperCase());
    handleParagraphValue(result, 'fontStyle', 'paragraphSettings', templatesIdx, values, setValues);
  };

  const handleValues = (val, name) => {
    const result = { ...paragraph[name], unit: paragraph[name] ? paragraph[name].unit : 'pt', value: +val };
    handleParagraphValue(result, name, 'paragraphSettings', templatesIdx, values, setValues);
  };

  const handleDropMenu = (valDropMenu, name, val) => {
    const result = { unit: valDropMenu, value: +val };
    handleParagraphValue(result, name, 'paragraphSettings', templatesIdx, values, setValues);
  };

  const setDropMenuFirst = (val, name) => {
    if (val === 'Default') {
      const result = { unit: 'pt', value: 12 };
      handleParagraphValue(result, name, 'paragraphSettings', templatesIdx, values, setValues);
    }
    setFistMenu(val);
  };

  const handleFont = (val, name) => {
    handleParagraphValue(val, name, 'paragraphSettings', templatesIdx, values, setValues);
  }

  useEffect(() => {
    if (!paragraph.fontSize) {
      setFistMenu('Default');
    }
  }, [paragraph]);





  return (
    <div className={cl.root}>
      <div className={cl.wrapperRowTitle}>
        <p className={cl.title}>Font family:</p>
        <ExpanderItem
          valueProps={adobeFont(paragraph.fontFamily, true)}
          type="fontFamily"
          propsLabel={values.stylesheet?.availFonts ? ['Default', ... values.stylesheet.availFonts] : ['Default']}
          setProps={handleFont}
        />
      </div>

      <TemplateItemRow
        label="Font size:"
        type="fontSize"
        dropMenuFirst={{
          label: ['Default', 'User defined'],
          selected: firstMenu
        }}
        value={{
          value: paragraph.fontSize ? paragraph.fontSize.value : 12,
          unit: paragraph.fontSize ? paragraph.fontSize.unit : 'pt'
        }}
        grid="100px 150px 1fr 55px"
        setValueProps={handleValues}
        setDropMenuFirst={setDropMenuFirst}
        setDropMenu={handleDropMenu}
        disabled={firstMenu === 'Default'}
      />

      <div className={cl.wrapperRowTitle}>
        <p className={cl.title}>Font style:</p>
        <div className={cl.wrapperCheckbox}>
          {fontData.map((el, i) => (
            <div key={i}>
              <FormControlLabel
                control={<Checkbox onChange={handleCheckboxFontStyle} checked={findCheckbox(el.name)} name={el.name} />}
                label={<span>{el.name}</span>}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Font;
