import React from 'react';

export default class BreadCrumb extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    if (this.props.handleClick) {
      this.props.handleClick(e);
    }
  }

  render() {
    let locationItem;
    let locationClass;
    if (!this.props.itemLocation) {
      locationItem = 'empty';
      locationClass = 'hide';
    } else {
      locationItem = this.props.itemLocation.map((item, index) => (
        <li key={index}>
          <a>{item.substr(item.lastIndexOf('>') !== -1 ? item.lastIndexOf('>') + 2 : 0, item.length)}</a>
        </li>
      ));
      locationClass = 'breadcrumb-koma-group';
    }
    return (
      <div className={`breadcrumb-vaadin ${this.props.styleName}`} onClick={this.handleClick}>
        {this.props.text !== undefined && <h4 className="title">{this.props.text}</h4>}
        <ul className={locationClass}>{locationItem}</ul>
      </div>
    );
  }
}
