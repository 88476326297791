import React, { useState, Fragment, useMemo, useEffect, useRef } from 'react';

import ActionableTable from '@zert-packages/components/shared/ActionableTable/ActionTable.new';

import { FormattedMessage } from 'react-intl';

import { useSnackbar } from 'notistack';

import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { toLocaleString } from '@zert-packages/plugins/ExplorerPlugin/SearchConditions/helpers/getLocaleByLanguageCode';
import { cancelTJ, downloadTranslationFiles, uploadTJFile, validateTJ } from './servicesMiddlewares';

const tableColumns = [
  {
    identifier: 'language',
    label: <FormattedMessage id="TranslationJob.ContentTable.Language" defaultMessage="Language" />
  },
  {
    identifier: 'status',
    label: <FormattedMessage id="TranslationJob.ContentTable.Status" defaultMessage="Status" />
  },
  {
    identifier: 'words',
    label: <FormattedMessage id="TranslationJob.ContentTable.Words" defaultMessage="Words" />
  },
  {
    identifier: 'elements',
    label: <FormattedMessage id="TranslationJob.ContentTable.Elements" defaultMessage="Elements" />
  },
  {
    identifier: 'alreadyExported',
    label: <FormattedMessage id="TranslationJob.ContentTable.AlreadyExported" defaultMessage="Already exported" />
  },
  {
    identifier: 'alreadyTranslated',
    label: <FormattedMessage id="TranslationJob.ContentTable.AlreadyTranslated" defaultMessage="Already translated" />
  },
  {
    identifier: 'missingTranslations',
    label: (
      <FormattedMessage id="TranslationJob.ContentTable.MissingTranslations" defaultMessage="Missing Source Language" />
    )
  }
];

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '10px',
    height: '300px',
    width: '100%'
  },
  dependsContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'auto',
    '& > *': {
      marginTop: 10
    }
  }
});
export const statuses = [
  {
    id: 0,
    name: <FormattedMessage id="TranslationJob.Status.NotActivated" defaultMessage="Not activated" />
  },
  {
    id: 1,
    name: <FormattedMessage id="TranslationJob.Status.Active" defaultMessage="Active" />
  },
  {
    id: 2,
    name: <FormattedMessage id="TranslationJob.Status.COMPLETED" defaultMessage="COMPLETED" />
  },
  {
    id: 3,
    name: <FormattedMessage id="TranslationJob.Status.CANCELED" defaultMessage="CANCELED" />
  },
  {
    id: 4,
    name: (
      <FormattedMessage id="TranslationJob.Status.NO_TRANSLATABLE_CONTENT" defaultMessage="NO TRANSLATABLE CONTENT" />
    )
  }
];

export const getStatusById = (id) => {
  const found = statuses.find((status) => id == status.id);
  if (!found) {
    return statuses[0];
  }
  return found;
};
const defaultSelectedRow = null;

function TranslationJobContentTable({ sourceLocale, name, list, versionId, allowUpload, allowImport, allowCancel }) {
  const cl = useStyles();
  const rootRef = useRef();
  const snackbar = useSnackbar();
  const [selectedRow, setSelectedRow] = useState(0);
  const dispatch = useDispatch();
  const importCallback = () => {
    dispatch(validateTJ(versionId, list[selectedRow].locale));
  };

  const uploadCallback = () => {
    dispatch(uploadTJFile(versionId, list[selectedRow].locale));
  };

  const downloadSourceCallback = () => {
    dispatch(downloadTranslationFiles(name, versionId, list[selectedRow].locale, false, sourceLocale));
  };

  const downloadAllSourcesCallback = () => {
    dispatch(downloadTranslationFiles(name, versionId, null, false, sourceLocale, true));
  };

  const download = () => {
    dispatch(downloadTranslationFiles(name, versionId, list[selectedRow].locale, true, sourceLocale));
  };

  const cancelCallback = () => {
    dispatch(cancelTJ(versionId, list[selectedRow].locale));
  };

  const actions = [
    {
      onClick: cancelCallback,
      disabled: !allowCancel || (list[selectedRow].localeStatus && list[selectedRow].localeStatus != statuses[1].id),
      name: <FormattedMessage id="TranslationJobContent.Cancel" defaultMessage="Cancel" />
    },
    {
      onClick: uploadCallback,

      disabled: !allowUpload || (list[selectedRow].localeStatus && list[selectedRow].localeStatus != statuses[1].id),
      name: <FormattedMessage id="TranslationJobContent.Upload" defaultMessage="Upload" />
    },
    {
      // onClick: addMetadataValue,
      onClick: importCallback,
      disabled:
        !allowImport ||
        (list[selectedRow].localeStatus &&
          list[selectedRow].localeStatus != statuses[1].id &&
          list[selectedRow].hasTranslatedContent),
      name: <FormattedMessage id="TranslationJobContent.Import" defaultMessage="Import" />
    },
    {
      onClick: downloadSourceCallback,
      disabled:
        list[selectedRow].localeStatus &&
        list[selectedRow].localeStatus != statuses[1].id &&
        list[selectedRow].localeStatus != statuses[2].id,
      name: <FormattedMessage id="TranslationJobContent.Download.Source" defaultMessage="Download source" />
    },
    {
      // onClick: addMetadataValue,
      disabled: true,
      name: <FormattedMessage id="TranslationJobContent.Complete" defaultMessage="Preview" />
    },
    {
      onClick: download,
      disabled:
        list[selectedRow].localeStatus &&
        list[selectedRow].localeStatus != statuses[1].id &&
        list[selectedRow].localeStatus != statuses[2].id,
      name: <FormattedMessage id="TranslationJobContent.Download" defaultMessage="Download" />
    },
    {
      onClick: downloadAllSourcesCallback,
      disabled: false,
      name: <FormattedMessage id="TranslationJobContent.DownloadAll" defaultMessage="Download All" />
    }
  ];

  const getRows = () => {
    if (!list) {
      return [];
    }

    // ? <FormattedMessage id = "TranslationContentTable.MissingContent.Yes" defaultMesage ="Yes" /> :<FormattedMessage id = "TranslationContentTable.MissingContent.No" defaultMesage ="No" />,
    return (
      list &&
      list.map((translationContent, index) => {
        return {
          id: index,
          language: `${translationContent.locale.displayName} - ${toLocaleString(translationContent.locale)}`,
          status: getStatusById(translationContent.localeStatus).name,
          words: translationContent.wordCount,
          elements: translationContent.elementCount,
          alreadyExported: translationContent.alreadyExportedCount,
          alreadyTranslated: translationContent.alreadyTranslatedCount,
          missingTranslations: translationContent.sourceLanguageNotFoundCount
        };
      })
    );
  };

  return (
    <div className={cl.root} ref={rootRef}>
      <ActionableTable
        actions={actions}
        isTextButtons
        columns={tableColumns}
        rows={getRows()}
        selectedRowId={selectedRow}
        setSelectedRowId={setSelectedRow}
      />
    </div>
  );
}

export default TranslationJobContentTable;
