import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ListCore from './ListCore';

const useStyles = makeStyles({
  root: {}
});

function MainList({ list, onlyIcons, onClick, number, sidebarShortcuts }) {
  const cl = useStyles();
  return (
    <div className={cl.root}>
      <ListCore list={list} onlyIcons={onlyIcons} onClick={onClick} number={number} sidebarShortcuts={sidebarShortcuts} />
    </div>
  );
}

export default MainList;
