import SelectField from '@zert-packages/components/shared/FormElements/SelectField';
import { FormattedMessage } from 'react-intl';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { DialogContent, FormControlLabel, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Switch from '@mui/material/Switch';
import {
  downloadLayoutFilters,
  downloadLayouts,
  downloadLayoutSettings,
  updateLayoutProperties,
  updateSelectedLocale
} from '../servicesMiddlewares';
import { addMoreLayouts, setSelectedLanguage, setSelectedLayout, setSelectedLayoutFilter } from '../previewReducers';
import { PreviewWizardContext } from './PreviewWizardStepper';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import ShowMoreLayouts from '../components/ShowMoreLayouts';
import LayoutSettingsPaper from '../components/LayoutSettingsPaper';
import PublishingFilterPaper from '../components/PublishingFilterPaper';
import Loader from '@zert-packages/components/shared/Loader/Loader';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190
  }
}));

export function GeneralSettingsPage() {
  const { layouts, selectedLayout, selectedLanguage, layoutFilters, selectedLayoutFilter, layoutSettings } =
    useSelector((state) => state.PREVIEW);
  const { activeLocales } = useSelector((state) => state);
  const [selectedLayoutElement, setSelectedLayoutElement] = useState(null);
  const { publishingFilters } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { extended, setExtended } = useContext(PreviewWizardContext);
  const classes = useStyles();
  useEffect(() => {
    dispatch(downloadLayouts());
    dispatch(downloadLayoutFilters());
    dispatch(updateSelectedLocale());
  }, []);

  useEffect(() => {
    if (layouts && selectedLayout)
      setSelectedLayoutElement(layouts.find((layout) => layout.versionId == selectedLayout));
  }, [selectedLayout, layouts]);

  useEffect(() => {
    if (selectedLayout) {
      dispatch(downloadLayoutSettings(selectedLayout));
    }
  }, [selectedLayout]);

  if (!layouts || !activeLocales) {
    return <Loader displayText={false} />;
  }
  const localeChanged = (state) => {
    dispatch(setSelectedLanguage(state.value));
  };

  const layoutFilterChange = (state) => {
    dispatch(setSelectedLayoutFilter(state.value));
  };

  const layoutChanged = (e) => {
    dispatch(setSelectedLayout(e.target.value));
  };

  const showMoreLayout = () => {
    renderInExceptionRoot(ShowMoreLayouts, {
      selectedLayoutElement,

      onConfirm: (selectedLayout) => {
        dispatch(addMoreLayouts(selectedLayout));
        dispatch(setSelectedLayout(selectedLayout.versionId));
      }
    });
  };

  return (
    <form
      style={{
        display: 'flex',
        justifyContent: 'stretch',
        flexFlow: 'column nowrap'
        // backgroundColor: "rgb(224, 224, 224, 1)" //"rgb(220, 239, 246)"
      }}
    >
      <FormControlLabel
        control={<Switch tabIndex="-1" size="small" checked={extended} color="primary" />}
        label={
          <FormattedMessage
            id="PreviewWizardDialog.Switch.ShowAdvancedOptions"
            defaultMessage="Show advanced options"
          />
        }
        onChange={(e) => setExtended(!extended)}
        checked={extended}
      />

      <SelectField
        values={activeLocales}
        onStateChanged={localeChanged}
        value={selectedLanguage}
        caption={<FormattedMessage id="PreviewWizardDialog.Select.Locale" defaultMessage="Locale" />}
        valLabel="extendedDisplayName"
        fieldId="value"
        label="displayName"
      />

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="layout">
          <FormattedMessage id="PreviewWizardDialog.Select.Layout" defaultMessage="Layouts" />{' '}
        </InputLabel>
        <Select
          value={selectedLayout}
          onChange={layoutChanged}
          inputProps={{
            name: 'layout',
            id: 'layout'
          }}
          endAdornment={
            selectedLayoutElement ? (
              <InputAdornment position="start">
                <IconButton aria-label="toggle layout details" onClick={showMoreLayout} size="large">
                  <MoreHorizIcon />
                </IconButton>
              </InputAdornment>
            ) : null
          }
        >
          {layouts.map((value) => (
            <MenuItem key={value.versionId} value={value.versionId}>
              <em>
                {value.name} {value.extended ? `(${value.label})` : ''}
              </em>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SelectField
        values={layoutFilters}
        onStateChanged={layoutFilterChange}
        value={selectedLayoutFilter}
        caption={<FormattedMessage id="PreviewWizardDialog.Select.layoutFilters" defaultMessage="Layout Filters" />}
        valLabel="name"
        valVal="uuid"
      />
      {selectedLayout &&
        layoutSettings &&
        layoutSettings[selectedLayout] &&
        layoutSettings[selectedLayout].properties.length > 0 && (
          <LayoutSettingsPaper layoutVersionId={selectedLayout} settings={layoutSettings[selectedLayout].properties} />
        )}
      {publishingFilters && publishingFilters.length > 0 && <PublishingFilterPaper />}
    </form>
  );
}
