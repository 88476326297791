import React, { memo } from 'react';
import { TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';

function NameTextFieldWithMemo(props) {
  return (
    <TextField label={<FormattedMessage id="DuplicationDialog.NameInputLabel" defaultMessage="Name" />} {...props} />
  );
}

export default memo(
  NameTextFieldWithMemo,
  // eslint-disable-next-line no-undef
  selectiveMemoComparison(
    (p) => p.value,
    (p) => p.disabled
  )
);
