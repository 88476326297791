import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import React, { useContext, useEffect } from 'react';
import TemplateItemRow from '../../../TemplateItemRow';
import { findFormat, findOrientation, handlePageLayout, handlePageStyle } from '../../../utils/helpers';
import { TabsContext } from '../../../Stylesheet';
import handlePortrait from '../../../utils/handlePortrait';
import Landscape from '../../../utils/Landscape';

const useStyles = makeStyles((theme) => ({
  rootRow: {
    display: 'grid',
    gridRowGap: 10,
    border: '1px solid #C6C6C5',
    padding: '7px 10px'
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '100px 200px 55px',
    gridColumnGap: 10,
    alignItems: 'center'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    marginBottom: 5
  }
}));

function PageFormat() {
  const cl = useStyles();
  const data = {
    dropFormat: ['A3', 'A4', 'A5', 'Letter', 'Legal', 'User'],
    selectedFormat: 'A3',
    selectedOrientation: 'Portrait',
    Orientation: ['Portrait', 'Landscape']
  };
  const { values, setValues } = useContext(TabsContext);
  const page = values.stylesheet.blockSettings[values.selectedLeftMenu];

  const handleValue = (val, name) => {
    const value = val > 10000 ? 10000 : val;

    handlePageLayout(+value, name, values, setValues);
  };

  const handleDropMenu = (valDropMenu, name, val) => {
    handlePageLayout(val, name, values, setValues, valDropMenu);
  };

  const handleStyle = (width, height, valuesWidth, valuesHeight, valDropMenu, pageFormat) => {
    const resultStylePageLayout = {
      ...values.stylePageLayout,
      width,
      height
    };
    const resultType = values.stylesheet.blockSettings.reduce((acu, cur, idx) => {
      if (idx === values.selectedLeftMenu) {
        return [
          ...acu,
          {
            ...cur,
            orientation: valDropMenu,
            format: pageFormat,
            pageWidth: { ...cur.pageWidth, value: valuesWidth },
            pageHeight: { ...cur.pageHeight, value: valuesHeight }
          }
        ];
      }
      return [...acu, cur];
    }, []);
    const result = {
      ...values,
      stylePageLayout: resultStylePageLayout,
      stylesheet: {
        ...values.stylesheet,
        blockSettings: [...resultType]
      }
    };
    setValues(result);
  };

  const handleSelectedDropMenu = (valDropMenu) => {
    if (valDropMenu === 'Portrait') {
      return handlePortrait(page.format, handleStyle, valDropMenu);
    }
    return Landscape(page.format, handleStyle, valDropMenu);
  };

  const handleFormat = (val) => {
    if (page.orientation === 'Portrait') {
      return handlePortrait(val, handleStyle, page.orientation);
    }
    return Landscape(val, handleStyle, page.orientation);
  };

  useEffect(() => {
    if (!page.orientation) {
      const orientation = findOrientation(page.pageWidth.value, page.pageHeight.value);
      const format = findFormat(page.pageWidth.value, page.pageHeight.value);
      handlePageStyle(orientation, format, values, setValues);
    }
  }, [page]);

  return (
    <div>
      <p className={cl.title}>Page format</p>
      <div className={cl.rootRow}>
        <div className={cl.wrapperRow}>
          <div className={cl.title}>Format:</div>
          <ExpanderItem
            valueProps={page.format}
            propsLabel={data.dropFormat}
            type="format"
            setProps={handleFormat}
            key={page.format}
          />
        </div>
        <TemplateItemRow
          label="Width"
          value={page.pageWidth}
          type="pageWidth"
          grid="100px 1fr 55px"
          setValueProps={handleValue}
          setDropMenu={handleDropMenu}
        />
        <TemplateItemRow
          label="Height:"
          value={page.pageHeight}
          type="pageHeight"
          grid="100px 1fr 55px"
          setValueProps={handleValue}
          setDropMenu={handleDropMenu}
        />
        <div className={cl.wrapperRow}>
          <div className={cl.title}>Orientation:</div>
          <ExpanderItem
            valueProps={page.orientation}
            propsLabel={data.Orientation}
            setProps={handleSelectedDropMenu}
            type="orientation"
            key={page.orientation}
          />
        </div>
      </div>
    </div>
  );
}

export default PageFormat;
