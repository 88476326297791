import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 482,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700
  },
  row: {
    display: "grid",
    gridTemplateColumns: "100px 1fr"
  },
  wrapperCheckbox: {
    display: 'flex',
    marginLeft: -9,
    marginTop: -9,
    alignItems: 'center',
  },
  rowTranslation: {
    display: "grid",
    gridTemplateColumns: "70px 1fr 25px",
    gridColumnGap: 10
  },
  label: {
    fontWeight: 900
  },
  wrapper: {
    display: "grid",
    gridRowGap: 10
  },
  wrapperButton: {
    marginTop: 10,
    display: "flex",
    justifyContent: "end",
    paddingRight: 20
  },
  wrapperInput: {
    display: "grid",
    gridTemplateColumns: "100px 1fr",
    marginBottom: 10,
    gridColumnGap: 10,
    alignItems: "center"
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  },
  wrapperLanguage: {
    display: "grid",
    gridTemplateColumns: "100px 1fr",
    "& button": {
      padding: 9,
      position: "relative",
      bottom: 4
    },
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginBottom: 0
    }
  },
}));

export default useStyles