export const handleInputItem = (name, value, setValues) => {
  setValues((prev) => ({
    ...prev,
    templateList: { ...prev.templateList, [name]: value }
  }));
};

export const handleItemLabel = (name, value, setValues) => {
  setValues((prev) => ({
    ...prev,
    templateList: { ...prev.templateList, [name]: { ...prev.templateList.label, defaultLabel: value } }
  }));
};

export const handleItemShortcut = (name, value, setValues) => {
  setValues((prev) => ({
    ...prev,
    templateList: { ...prev.templateList, [name]: { ...prev.templateList.shortcut, modifiers: value } }
  }));
};

export const handleItemStyleLabel = (name, value, setValues) => {
  setValues((prev) => ({
    ...prev,
    templateList: { ...prev.templateList, [name]: { ...prev.templateList.styleLabel, defaultLabel: value } }
  }));
};

export const handleItemColor = (name, value, setValues) => {
  setValues((prev) => ({
    ...prev,
    templateList: {
      ...prev.templateList,
      typeSettings: {
        ...prev.templateList.typeSettings,
        [name]: value
      }
    }
  }));
};
