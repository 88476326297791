import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 109px)'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '50px  1fr',
    gridColumnGap: 10,
    alignItems: 'center'
  },
  wrapperCheckbox: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: 10,
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    }
  },
  blockWrapper: {
    display: 'grid',
    gridTemplateColumns: '200px 1fr',
    height: 'Calc(100% - 183px)',
    marginTop: '1rem',
    gridColumnGap: 10,
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingBottom: 0
  },
  selectedBlock: {
    border: '1px solid #C6C6C5'
  },
  wrapperEditor: {
    color: '#4db1d3',
    maxWidth: 190,
    textAlign: 'end',
    marginTop: 5,
    '& svg': {
      marginLeft: 5,
      cursor: 'pointer'
    }
  },
  tabsRoot: {
    minHeight: 18,
    marginBottom: 15,
    '& .MuiTab-root': {
      padding: '0px 4px 0px 3px',
      minWidth: 'fit-content',
      minHeight: 18,
      background: '#EBEBEB'
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#ffffff',
      background: '#4db1d3',
      outline: 'none'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left',
      background: '#E3F2F7'
    }
  },
  templateInput: {
    width: '100%'
  },
  rightImg: {
    transform: 'rotate(180deg)'
  },
  wrapperIcons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    maxWidth: 100,
    marginLeft: 'auto',
    marginBottom: 10
  },
  listItem: {
    padding: '2px 4px',
    cursor: 'pointer'
  },
  listItemActive: {
    padding: '2px 4px',
    color: '#ffffff',
    background: '#4db1d3'
  },
  columnWrapper: {
    display: 'grid',
    gridColumnGap: 5,
    marginBottom: 10
  },
  columnItem: {
    cursor: 'pointer',
    border: '1px solid #C6C6C5',
    padding: 5,
    display: 'flex',
    alignItems: 'center'
  }
}));

export default useStyles;
