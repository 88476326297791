import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import SelectField from '@zert-packages/components/shared/FormElements/SelectField';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { downloadCompareWithVersionId } from '../servicesMiddlewares';
import { setCompareWithVersionId, setMultipleLanguage } from '../previewReducers';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190
  }
}));

export function ComparePage() {
  const { selectedElementToPreview, compareWithVersions, compareWithVersionId } = useSelector((state) => state.PREVIEW);
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(downloadCompareWithVersionId(selectedElementToPreview));
  }, selectedElementToPreview);
  const versionIdChanged = (state) => {
    dispatch(setCompareWithVersionId(state.value));
  };
  if (compareWithVersions == null) {
    return <Loader displayText={false} />;
  }
  return (
    <form
      style={{
        display: 'flex',
        justifyContent: 'stretch',
        flexFlow: 'column nowrap'
        // backgroundColor: "rgb(224, 224, 224, 1)" //"rgb(220, 239, 246)"
      }}
    >
      <TextField
        key="compareWithVersionID"
        label={<FormattedMessage id="PreviewWizardDialog.ComparePage.Version" defaultMessage="Preview versionId" />}
        InputProps={{
          readOnly: true
        }}
        className={classes.formControl}
        value={selectedElementToPreview.label}
        type="text"
        fullWidth
        variant="outlined"
      />
      <SelectField
        values={compareWithVersions}
        onStateChanged={versionIdChanged}
        value={compareWithVersionId}
        caption={
          <FormattedMessage
            id="PreviewWizardDialog.ComparePage.CompareVersionID"
            defaultMessage="Compare with VersionId"
          />
        }
        valLabel="label"
        valVal="versionId"
        fieldId="versionId"
        label="label"
      />
    </form>
  );
}
