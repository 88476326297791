import React from 'react';
import {Button} from "@mui/material";
import {REDO_ACTION, UNDO_ACTION} from "../../utils/ELEMENT_TILE_CONTS";

const UndoActions = () => {
    return (
        <>
            <Button key={UNDO_ACTION.id} startIcon={UNDO_ACTION.icon}>
                {UNDO_ACTION.name}
            </Button>
            <Button key={REDO_ACTION.id} startIcon={REDO_ACTION.icon}>
                {REDO_ACTION.name}
            </Button>
        </>
    );
};

export default UndoActions;