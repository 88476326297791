import { FormattedMessage } from 'react-intl';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const removeElementsMockUp = () => ({
  name: <FormattedMessage id="Explorer.Elements.MockUp" defaultMessage="Remove elements" />,
  uuid: uuidv4()
});

export default removeElementsMockUp;
