import { createInProgressSnack } from '@zert-packages/components/common/snackBar';
import { showSnackbarError } from '@zert-packages/components/Snackbars';
import { reportMockUp } from '@zert-packages/utils/reportMockUp';
import { checkReportStatus } from '@zert-packages/actions/checklistActions';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import {
  handlePost,
  handlePostResultAsString,
  handleQuery,
  handlePostTextAsParam,
  fetchReportError,
  fetchRA_Error,
  fetchReportStart,
  fetchReportStatus,
  reportDone
} from './api';
import { getChildNodes, getNodeContent, REDUCER_CHILD_FOLDER_START_CREATE } from './explorerActions';

const ACTION_REDUCER_SEARCH_START = 'ACTION_REDUCER_SEARCH_START';
const ACTION_REDUCER_SEARCH = 'ACTION_REDUCER_SEARCH';

const REDUCER_SEARCH_START = () => ({
  type: ACTION_REDUCER_SEARCH_START,
  payload: {}
});

const REDUCER_SEARCH = (search) => ({
  type: ACTION_REDUCER_SEARCH,
  payload: { search }
});

export function searchByText(search) {
  return function (dispatch) {
    handlePostTextAsParam('/search/searchByText', search)
      .then((result) => {
        dispatch(REDUCER_SEARCH(result));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_SEARCH_START());
  };
}
const REDUCER_CLEAR_SEARCH = 'REDUCER_CLEAR_SEARCH';
export const clearSearch = () => ({
  type: REDUCER_CLEAR_SEARCH,
  payload: {}
});

export function searchElements(search) {
  return function (dispatch) {
    handlePost('/search/search', search)
      .then((result) => {
        dispatch(REDUCER_SEARCH(result));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_SEARCH_START());
  };
}
export function createSearchFolder(loadingContentNodeId, folderName, search) {
  return function (dispatch) {
    handlePostResultAsString(`/search/createSearchFolder/${loadingContentNodeId}`, {
      name: folderName,
      clientSearch: search
    })
      .then(() => {
        dispatch(getChildNodes(loadingContentNodeId));
        dispatch(getNodeContent(loadingContentNodeId));
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(REDUCER_CHILD_FOLDER_START_CREATE(loadingContentNodeId));
  };
}

export function remove(report, search, elementsid) {
  return function (dispatch) {
    // var reportUUID = reportUUID;
    // var report = {reportUUID : reportUUID};
    const ids = [];
    elementsid.forEach((value, key, map) => {
      ids.push(value.id);
    });
    handlePost(`/explorer/remove/${report.reportUUID}/${-1}/true`, ids)
      .then((result) => {
        if (result != null && result.active) {
          dispatch(fetchReportStatus(result));
          dispatch(updateProgressStatus(result, report, search));
        } else {
          report.done = true;
          dispatch(fetchReportStatus(result));
          dispatch(reportDone(report));
          dispatch(searchElements(search));
        }
      })
      .catch((error) => dispatch(fetchRA_Error(error)));
    return dispatch(fetchReportStart(report));
  };
}

function updateProgressStatus(callback, report, search) {
  return function (dispatch) {
    handleQuery(`/report-react/updateProgressStatus/${callback.callbackId}/false`)
      .then((result) => {
        if (result != null && result.active) {
          dispatch(fetchReportStatus(result));
          dispatch(updateProgressStatus(result, report, search));
        } else {
          report.done = true;
          dispatch(fetchReportStatus(result));
          dispatch(reportDone(report));

          dispatch(searchElements(search));
        }
      })
      .catch((error) => {
        dispatch(fetchReportError(error));
      });
    return fetchReportStatus(callback);
  };
}

export const generateSearchReport =
  (report, searchCriteria, versionIds, n, columns) =>
    (dispatch, getState, { snackbar }) => {
      const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
      const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="ChecklistOverview.GeneratingLabel" defaultMessage="...generating report" />
      );
      const newReport = {
        ...report,
        reportUUID: uuidv4()
      };

      handlePost(`/report-react/generateReport/${newReport.reportUUID}/generateSearchReport`, {
        ...reportMockUp,

        object: JSON.stringify({ versionIds, clientSearch: searchCriteria, nResults: n, columns })
      })
        .then((callback) => {
          checkReportStatus(callback, snackbar, generatingSnackbar, newReport, 'pdf');
        })

        .catch((error) => {
          showSnackbarError(snackbar, error);
        });
    };

export const generateActionPlanReport =
  (report, user, mime, versionIds, n) =>
    (dispatch, getState, { snackbar }) => {
      const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
      const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="ChecklistOverview.GeneratingLabel" defaultMessage="...generating report" />
      );
      const newReport = {
        ...report,
        reportUUID: uuidv4()
      };

      handlePost(`/report-react/generateReport/${newReport.reportUUID}/generateActionPlanReport`, {
        ...reportMockUp,
        locale,
        object: JSON.stringify({ versionIds, typeMimeNr: mime, user, nResults: n })
      })
        .then((callback) => {
          checkReportStatus(callback, snackbar, generatingSnackbar, newReport, 'pdf');
        })

        .catch((error) => {
          showSnackbarError(snackbar, error);
        });
    };

export function searchReducer(state, action) {
  switch (action.type) {
    case ACTION_REDUCER_SEARCH_START:
      return {
        ...state,
        loadingSearch: true,
        search: null
      };

    case ACTION_REDUCER_SEARCH:
      return {
        ...state,
        search: action.payload.search,
        loadingSearch: false
      };

    case REDUCER_CLEAR_SEARCH:
      return {
        ...state,
        search: null,
        loadingSearch: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
