import React, { useContext, useEffect, useState } from "react";
import { TabsContext } from "../FormatTemplatePlugin";
import InputItem from "../InputItem";
import { getTranslation } from "@zert-packages/utils";
import { handleInputItem, handleItemColor, handleItemLabel, handleItemShortcut } from "../utils/handleInputItem";
import FlipToBackIcon from "@mui/icons-material/FlipToBack";
import DialogColors from "@zert-packages/components/dialog/DialogColors";
import DialogList from "../dialog/DialogList";
import ExpanderItem from "../ExpanderItem";
import makeStyles from "@mui/styles/makeStyles";
import { adobeColor, adobeName, adobeRgba, convertKey } from "../utils/main";
import FontStyle from "../component/FontStyle";
import PaletteIcon from "@mui/icons-material/Palette";
import BlockList from "../BlockList";
import WrapperLabelInputItem from "../component/WrapperLabelInputItem";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogShortCut from "../dialog/DialogShortCut";
import SelectedColor from "../../StylesheetPlugin/SelectedColor";

const useStyles = makeStyles((theme) => ({
  wrapperExpander: {
    maxWidth: "Calc(100% - 35px)"
  },
  wrapperColor: {
    display: "grid",
    gridTemplateColumns: "100px  25px  1fr 25px",
    gridColumnGap: 10,
    marginBottom: 10,
  },
}))

const TextTabs = ({disable}) => {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [propsDialog, setPropsDialog] = useState(null);
  const [openDialogShortcut, setDialogShortcut] = useState(false);
  const [color, setColor] = useState({
    fontColor: "#ffffff",
    bgcolor: "#ffffff",
    load: false,
  })

  const fontColor =  values?.templateList?.typeSettings?.fontColor;
  const bgColor =  values?.templateList?.typeSettings?.bgcolor;


  const handleOpenDialogColor = ( name) => {
    renderInExceptionRoot(DialogColors, {
      text:{ label: `Color `},
      type: name,
      setText: handleSelectedColor
    })
  }


  const handleValue = (val, Identifier) => {
    handleInputItem(Identifier, val, setValues);
  };


  const handleValueShortcut = (val, Identifier) => {
    handleItemShortcut(Identifier, val, setValues);
  };



  const handleSelectedColor = (val, name) => {
    setColor(pre => ({...pre, [name]: val, load: true}))
    handleItemColor(name, adobeRgba(val), setValues);
  }


  const findColord = () => {
    if(color.load){
      return
    }
    if(fontColor && bgColor){
      setColor(pre => ({
        ...pre,
        fontColor: adobeColor(fontColor),
        bgcolor: adobeColor(bgColor),
        load: true}))
      return
    }
    if(fontColor){
      setColor(pre => ({...pre, fontColor: adobeColor(fontColor)}))
    }
    if(bgColor){
      setColor(pre => ({...pre, bgcolor: adobeColor(bgColor)}))
    }
  }


  useEffect(() => {
    findColord()
  }, [fontColor, bgColor])


  return(<div>
    <DialogColors open={openDialog} setOpen={setOpenDialog} text={propsDialog} />
    <DialogList open={openDialogShortcut} setOpen={setDialogShortcut} text={propsDialog} />
    <InputItem
      label="Identifier:"
      valueProps={values.templateList.identifier && values.templateList.identifier}
      disabled={disable}
      Identifier="identifier"
      setValueProps={handleValue}

    />
    <WrapperLabelInputItem disabled={disable} />
    <InputItem
      label="Shortcut:"
      valueProps={values.templateList.shortcut && convertKey(values.templateList.shortcut.keyCode)}
      setValueProps={handleValueShortcut}
      Identifier="shortcut"
      disabled={true}
      icons={
        <FlipToBackIcon
          style={{ cursor: "pointer", color: "gray" }}
          onClick={() => !disable && renderInExceptionRoot(DialogShortCut, { setValues })}
        />
      }
    />
    <div className={cl.wrapperExpander}>
    <ExpanderItem
      disable={disable}
      label="Font Family:"
      style={{height: 34}}
      valueProps={
        values.templateList.typeSettings &&
        values.templateList.typeSettings.fontFamily &&
        adobeName(values.templateList.typeSettings.fontFamily)
      }
      propsLabel={["Default", "Inherit", "Sans", "Serif", "Mono"]}
      type="fontFamily"
    />
    </div>
    <FontStyle disable={disable}/>
    <div className={cl.wrapperExpander}>
      <ExpanderItem
        label="Font Size:"
        style={{height: 34}}
        valueProps={
          values.templateList.typeSettings &&
          values.templateList.typeSettings.fontSize &&
          values.templateList.typeSettings.fontSize
        }
        disabled={disable}
        propsLabel={["Default", "XX Small", "X Small", "Small", "Medium", "Large", "X Large", "XX Large"]}
        type="fontSize"
      />
    </div>

    <div style={{marginBottom: 10}}>
      <SelectedColor
        style={{ grid: '100px 30px 1fr 25px' }}
        label="Font color:"
        type="fontColor"
        setValueProps={handleSelectedColor}
        handleOpenDialog={handleOpenDialogColor}
        valueProps={color.fontColor}
        disabled={disable}
      />
    </div>
    <div style={{marginBottom: 10}}>
      <SelectedColor
        style={{ grid: '100px 30px 1fr 25px' }}
        label="BG color:"
        type="bgcolor"
        setValueProps={handleSelectedColor}
        handleOpenDialog={handleOpenDialogColor}
        valueProps={color.bgcolor}
        disabled={disable}
      />
    </div>


    <BlockList
      disable={disable}
      label="Item settings:"
      formats={
        values.templateList.settings &&
        values.templateList.settings.properties.length > 0 &&
        values.templateList.settings.properties &&
        values.templateList.settings.properties
      }
    />
  </div>)
}

export default TextTabs