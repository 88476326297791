import React from 'react';
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { selectiveMemoComparison } from '@zert-packages/react';

export const ARROW_TOGGLE_VARIANTS = {
  leftRight: 'leftRight',
  rightLeft: 'rightLeft',
  topBottom: 'topBottom',
  bottomTop: 'bottomTop'
};

const useStyles = makeStyles({
  Icon: {
    transition: 'all 0.3s ease'
  },
  IconToggled: {
    transform: 'rotate(180deg)'
  }
});

function ArrowToggleButton({
  toggled = false,
  IconButtonProps,
  onToggle,
  IconProps,
  iconColor = '#666',
  disabled = false,
  variant = ARROW_TOGGLE_VARIANTS.leftRight
}) {
  const cl = useStyles();
  const IconRootClassNames = cn({
    [cl.Icon]: true,
    [cl.IconToggled]: toggled
  });
  const _IconProps = {
    ...IconProps,
    htmlColor: iconColor
  };
  return (
    <IconButton disabled={disabled} onClick={onToggle} {...IconButtonProps} size="small">
      {variant === ARROW_TOGGLE_VARIANTS.leftRight && (
        <ChevronRightIcon
          classes={{
            root: IconRootClassNames
          }}
          {..._IconProps}
        />
      )}

      {variant === ARROW_TOGGLE_VARIANTS.rightLeft && (
        <ChevronLeftIcon
          classes={{
            root: IconRootClassNames
          }}
          {..._IconProps}
        />
      )}

      {variant === ARROW_TOGGLE_VARIANTS.topBottom && (
        <ExpandMoreIcon
          classes={{
            root: IconRootClassNames
          }}
          {..._IconProps}
        />
      )}

      {variant === ARROW_TOGGLE_VARIANTS.bottomTop && (
        <ExpandLessIcon
          classes={{
            root: IconRootClassNames
          }}
          {..._IconProps}
        />
      )}
    </IconButton>
  );
}

export default selectiveMemoComparison(ArrowToggleButton)(
  (p) => p.disabled,
  (p) => p.toggled
);
