import React from 'react';
import { FormattedMessage } from 'react-intl';

const TooltipLocale = {
  back: <FormattedMessage id="IntroductionToolTip.Back" defaultMessage="Back" />,
  close: <FormattedMessage id="IntroductionToolTip.Close" defaultMessage="Close" />,
  last: <FormattedMessage id="IntroductionToolTip.Last" defaultMessage="Last" />,
  next: <FormattedMessage id="IntroductionToolTip.Next" defaultMessage="Next" />,
  skip: <FormattedMessage id="IntroductionToolTip.Skip" defaultMessage="Skip" />
};

export default TooltipLocale;
