import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import withSearch, { styles, controlStyles } from '../withSearch';

import SearchComponent from './SearchComponent';

class ContextSearchPanel extends SearchComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.textInput = React.createRef();
  }

  handleChange = (name) => (event) => {
    this.setStatus({ [name]: event.target.value });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    this.props.expanded && this.textInput.current ? this.textInput.current.blur() : this.textInput.current.focus();
  }

  render() {
    const { classes } = this.props;
    // this.props.handleChange(this.state);
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        <TextField
          autoFocus
          inputRef={this.textInput}
          id="standard-name"
          size="small"
          label={<FormattedMessage id="search.context.header" defaultMessage="Search by context" />}
          className={classes.formControl}
          value={this.state.Context}
          onChange={this.handleChange('Context')}
          type="search"
          fullWidth
          variant="outlined"
        />
      </form>
    );
  }
}

ContextSearchPanel.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.context.header" defaultMessage="Search by context" />,
  index: 'search-context',
  className: 'se.zert.backend.search.InsideContextElementCriteria'
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles)(ContextSearchPanel), selectData))
);
