import React, { useContext } from 'react';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TabsContext } from '../../../../Stylesheet';
import { handlePageLayoutChildObject } from '../../../../utils/helpers';
import LayoutModel from '../LayoutModel';
import TemplateItemRow from '../../../../TemplateItemRow';

const useStyles = makeStyles((theme) => ({
  wrapperCheckbox: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: 10,
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    }
  },
  itemRowWrapper: {
    display: 'grid',
    gridRowGap: 10,
    marginTop: 10
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr 55px'
  },
  containerCheckbox: {
    marginLeft: 10,
    marginTop: -10
  },
  templateTitle: {
    fontWeight: '700',
    marginBottom: 0
  },
  templateInput: {
    height: 'Calc(100% - 512px)',
    width: '100%',
    '& .MuiInputBase-multiline.MuiInputBase-marginDense': {
      height: '100%'
    },
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100% !important'
    }
  },
  tabsRoot: {
    minHeight: 18,
    marginBottom: 15,
    '& .MuiTab-root': {
      padding: '0px 4px 0px 3px',
      minWidth: 'fit-content',
      minHeight: 18,
      background: '#EBEBEB'
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#ffffff',
      background: '#4db1d3',
      outline: 'none'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left',
      background: '#E3F2F7'
    }
  },
  blockInput: {
    height: 'Calc(100% - 343px)',
    width: '100%',
    '& .MuiInputBase-multiline.MuiInputBase-marginDense': {
      height: '100%'
    },
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100% !important'
    }
  },
  wrapperTextFieldRow: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gridColumnGap: '10px',
    '& input': {
      paddingTop: 5,
      paddingLeft: 15,
      paddingBottom: 5
    }
  }
}));

function RightEdge() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const { rightEdge } = values.stylesheet.blockSettings[values.selectedLeftMenu];

  const handleCheckbox = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    handlePageLayoutChildObject(val, name, 'rightEdge', values, setValues);
  };

  const setValueTemplate = (e) => {
    const val = e.target.value;
    const { name } = e.target;
    handlePageLayoutChildObject(val, name, 'rightEdge', values, setValues);
  };
  const setValueProps = (val, name) => {
    handlePageLayoutChildObject(val, name, 'rightEdge', values, setValues, true);
  };
  const setDropMenu = (valDropMenu, name, val) => {
    handlePageLayoutChildObject(val, name, 'rightEdge', values, setValues, true, valDropMenu);
  };

  const handleRorate = (e) => {
    const val = e.target.value;
    handlePageLayoutChildObject(val, 'rotate', 'rightEdge', values, setValues);
  };

  return (
    <div
      style={{
        height: 'Calc(100% - 123px)'
      }}
    >
      <div className={cl.wrapperCheckbox}>
        <FormControlLabel
          control={<Checkbox onChange={handleCheckbox} checked={rightEdge.visible} name="visible" />}
          label={<span>Use rightEdge</span>}
        />
      </div>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div className={cl.itemRowWrapper}>
            <TemplateItemRow
              label="Spacing:"
              type="spacing"
              value={rightEdge.spacing}
              grid="100px 1fr 55px"
              setValueProps={setValueProps}
              setDropMenu={setDropMenu}
              disabled={!rightEdge.visible}
            />
            <TemplateItemRow
              label="Width:"
              type="width"
              value={rightEdge.width}
              grid="100px 1fr 55px"
              setValueProps={setValueProps}
              setDropMenu={setDropMenu}
              disabled={!rightEdge.visible}
            />
            <div className={cl.wrapperTextFieldRow}>
              <div>
                <p className={cl.templateTitle} style={{ marginTop: 10 }}>
                  Rorate
                </p>
              </div>
              <TextField
                className={cl.templateInput}
                value={rightEdge.rotate}
                type="number"
                variant="outlined"
                disabled={!rightEdge.visible}
                onChange={handleRorate}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <LayoutModel />
        </Grid>
      </Grid>
      <div style={{ marginTop: '2rem' }} />
      <p className={cl.templateTitle}>Content for odd pages:</p>
      <TextField
        className={cl.templateInput}
        variant="outlined"
        multiline
        minRows={9}
        value={rightEdge.oddContent}
        onChange={setValueTemplate}
        name="oddContent"
        disabled={!rightEdge.visible}
      />
      <p className={cl.templateTitle} style={{ marginTop: 10 }}>
        Content for even pages:
      </p>
      <TextField
        className={cl.templateInput}
        variant="outlined"
        value={rightEdge.evenContent}
        onChange={setValueTemplate}
        name="evenContent"
        multiline
        minRows={9}
        disabled={!rightEdge.visible}
      />
    </div>
  );
}

export default RightEdge;
