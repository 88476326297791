import React from "react";
import {makeStyles} from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import MetaDataDialog from "./components/dialog/metaDataDialog/MetaDataDialog";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "grid",
        gridTemplateColumns: "1fr 25px",
        gridColumnGap: 10,
    },
    wrapperImg: {
        color: "#4db1d3",
        "& svg": {
            cursor: "pointer",
            marginBottom: 3.5,
        }
    },
    row: {
        padding: '2px 4px',
        display: 'grid',
        gap: 5,
        gridTemplateColumns: '147px 1fr',
    },

    listWrapper: {
        border: "1px solid #BDBDBC",
        borderRadius: 5,
        overflow: "auto",
        minHeight: 200,
        maxHeight: `100%`,
        paddingBottom: 15,
    },

    listLeftMenu: {
        cursor: 'pointer'
    },
    listSelected: {
        cursor: 'pointer',
        color: '#ffffff',
        background: '#4db1d3'
    },
}));

const applies = ['Version', 'Element']

const MetaDataTabs = ({metaData, setMetadata}) => {
    const cl = useStyles();
    const list = metaData.meta?.structure?.metadataDefinitions

    const saveList = (metadataDefinitions) => {
        setMetadata(pre => ({
            ...pre, selectedElement: -1,
            meta: {...pre.meta, structure: {...pre.meta.structure, metadataDefinitions}}
        }))
    }

    const deleteMetaData = () => {
        if (list.length === 0) {
            return
        }
        const res = list.filter((el, i) => i !== metaData.selectedElement)
        saveList(res)
    }
    const editMetaData = (add) => {
        const selected = add ? false : list[metaData.selectedElement]
        renderInExceptionRoot(MetaDataDialog, {selected,metaData, setMetadata, add})
    }

    const handleListItem = (event, index) => {
        setMetadata(pre => ({...pre, selectedElement: index}))
        if(index === metaData.selectedElement){
            editMetaData()
        }
        // renderInExceptionRoot(MetaDataDialog, {selected: list[index]})
    }






    return (<div className={cl.root}>
        <div className={cl.listWrapper}>
            <div className={cl.row}>
                <div>Identifier</div>
                <div>Applies to</div>
            </div>
            {list?.length > 0 && list.map((el, idx) => <div
                key={idx}
                onClick={(event) => handleListItem(event, idx)}
                className={`${cl.row} ${metaData.selectedElement === idx ? cl.listSelected : cl.listLeftMenu}`}>
                <div>
                    {el.identifier}
                </div>
                <div>
                    {applies[el.mode]}
                </div>
            </div>)}
        </div>

        <div className={cl.wrapperImg}>
            <AddIcon onClick={() => editMetaData(true)}/>
            <CreateIcon onClick={() => editMetaData()}/>
            <DeleteIcon onClick={deleteMetaData}/>
        </div>

    </div>);
};

export default MetaDataTabs;