import findParentNode from './findParentNode';

export default function findParentNodeInProject(project, searchNodeUuid) {
  for (let i = 0; i < project.activities.length; i += 1) {
    const currentChild = project.activities[i];
    if (searchNodeUuid === currentChild.uuid) {
      return null;
    }
  }

  for (let i = 0; i < project.activities.length; i += 1) {
    const foundParent = findParentNode(project.activities[i], searchNodeUuid);
    if (foundParent) {
      return foundParent;
    }
  }
  return null;
}
