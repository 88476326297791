import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import getTranslation from '@zert-packages/utils/getTranslation.old';
import { IntroductionContext } from '../../Introduction';
import buildIsMatched from '../utils/buildIsMatched';

function countCurrentChecklist(categories, isMatched, counter) {
  let _isMatched = false;
  categories.forEach((category) => {
    category.questions.forEach((question) => {
      const text = getTranslation(question.caption);
      if (isMatched(text)) _isMatched = true;
    });
  });

  if (_isMatched) counter.checklist_current++;
}

function countChecklist() {}

function getChecklistsCounters(checklists) {
  const counter = {};
  checklists.forEach((c, index) => {
    if (c.name !== 'Current') {
      counter[`checklist_${index}`] = 0;
    }
  });
  return counter;
}

function useSafetyInspectionConfig(props) {
  const { safetyinspection: safetyInspection, questionairetemplate: questionnaireTemplate } = useSelector(
    (state) => state
  );
  const { setSearchTab, searchTab } = useContext(IntroductionContext);

  function countMatchedElementsJSON(searchValue) {
    const counter = {
      content: 0,
      description: 0,
      checklist_current: 0,
      ...getChecklistsCounters(safetyInspection.checkLists)
    };
    const isMatched = buildIsMatched(searchValue);

    if (isMatched(safetyInspection.name)) counter.content++;
    if (isMatched(safetyInspection.description)) counter.description++;
    countCurrentChecklist(questionnaireTemplate.categories, isMatched, counter);
    return counter;
  }

  function getChecklistsContainers() {
    const containers = [];
    if (!safetyInspection) return containers;

    safetyInspection.checkLists.forEach((c, index) => {
      if (c.name !== 'Current') {
        containers.push({
          counterField: `checklist_${index}`,
          id: 'leftpanel_tab',
          counterMatcher: c.name,
          searchByCounter: true,
          startSearching: (callback) => {
            setSearchTab(3);
            setTimeout(() => {
              callback();
            }, 500);
          }
        });
      }
    });
    return containers;
  }

  return {
    readyToSearch: safetyInspection !== null && questionnaireTemplate !== null,
    countMatchedElementsJSON,
    searchSettings: {
      dataset: ['contents'],
      classNames: []
    },
    containers: [
      {
        counterField: 'content',
        id: 'leftpanel_tab',
        startSearching: (callback) => {
          setSearchTab(0);
          setTimeout(() => {
            callback();
          }, 500);
        }
      },
      {
        counterField: 'description',
        id: 'leftpanel_tab',
        startSearching: (callback) => {
          setSearchTab(1);
          setTimeout(() => {
            callback();
          }, 500);
        }
      },
      {
        counterField: 'checklist_current',
        searchByCounter: true,
        counterMatcher: 'Current',
        id: 'leftpanel_tab',
        startSearching: (callback) => {
          setSearchTab(3);
          setTimeout(() => {
            callback();
          }, 500);
        }
      },
      ...getChecklistsContainers()
    ]
  };
}

export default useSafetyInspectionConfig;
