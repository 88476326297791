import React from 'react'
import {makeStyles} from "@mui/styles";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import ReferenceTypeDialog from "../ReferenceTypeDialog";


const useStyles = makeStyles((theme) => ({
    row: {
        display: 'grid',
        gridTemplateColumns: '1fr 25px',
        gridColumnGap: 5,
        '& input': {
            height: 27.5,
            boxSizing: 'border-box',
        },
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '1.25rem',
    }
}))

const dataDropType = ['Text', 'Date', 'Integer', 'Float', 'Enumaration', 'Element Reference']

const TypeSelected = ({meta, setMeta}) => {
    const cl = useStyles();
    const selectedReference = meta.type === 'Element Reference'

    const handleType = (val, name) => {
        setMeta(pre => ({...pre, [name]: val}))
    }

    const handleReference = () => {
        if (!selectedReference) {
            return
        }
        renderInExceptionRoot(ReferenceTypeDialog, {meta, setMeta})
    }
    //  renderInExceptionRoot(MetaDataDialog, {meta, setMeta})

    return (<div className={cl.row}>
        <ExpanderItem
            propsLabel={dataDropType}
            valueProps={meta.type}
            type={'type'}
            setProps={handleType}
            style={{
                height: 27.5,
                textTransform: 'none',
            }}
        />
        <div className={cl.icon}
             style={{opacity: selectedReference ? 1 : 0.5, cursor: selectedReference ? 'pointer' : 'default'}}
             onClick={handleReference}
        >
            ...
        </div>
    </div>)
}

export default TypeSelected