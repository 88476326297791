import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import generalRadioBtns from "./generalRadioBtns";
import { generateSearchReport } from "@zert-packages/actions/searchActions";
import React from "react";
import { getCriterias } from "@zert-packages/plugins/ExplorerPlugin/helpers/getCriterias";
import { reduceValues } from "@zert-packages/plugins/ExplorerPlugin/API";
import { RA_REPORT_TYPES } from "@zert-packages/components/common/dialogs/ReportDialogs/utils/useRiskAnalisisReport";

export default function useSearchReports({ selectedNode, selectedValues, catalog }) {
  const caption = <FormattedMessage id="reports.search" defaultMessage="Search report" />;
  const description = (
    <FormattedMessage
      id="search.report.description"
      defaultMessage="This report gives an overview over the search result."
    />
  );

  const dispatch = useDispatch();
  const {result} = useSelector(state => state.CORE.searchResult);
  const {lastSearchConditions} = useSelector(state => state.CORE);
  const lastSearchCriteria = reduceValues(
    lastSearchConditions);
  const index = 'checklists';
  const radiobtns = [];
  // eslint-disable-next-line no-use-before-define
  const dateSelectors = [];
  const checkBoxes = [];
  const selectors = [];
  const columnOptions = [
    {name: <FormattedMessage id='reports.search.columns.name' defaultMessage='Name' />,
     id: 'name'},
    {name: <FormattedMessage id='reports.search.columns.type' defaultMessage='Type' />,
      id: 'type'},
    {name: <FormattedMessage id='reports.search.columns.version' defaultMessage='Version' />,
      id: 'version'},
    {name: <FormattedMessage id='reports.search.columns.created-by' defaultMessage='Created by' />,
      id: 'created-by'},
    {name: <FormattedMessage id='reports.search.columns.created-at' defaultMessage='Created at' />,
      id: 'created-at'},
    {name: <FormattedMessage id='reports.search.columns.status' defaultMessage='Status' />,
      id: 'status'},
    {name: <FormattedMessage id='reports.search.columns.id' defaultMessage='Id' />,
      id: 'id'},
  ];
  const multiSelectors = [
        {
          name: 'included_columns',
          label: <FormattedMessage id="reports.search.columns" defaultMessage="Columns" />,
          section: 'columns',
          selected: columnOptions,
          options: columnOptions
        }
      ];

  const generateReport = (checkedItems) => {
    const selectedIds = selectedValues.length ?
      selectedValues.map((value) => value.versionId) :
      result.map((value) => value.versionId);

    const checkedColumns = checkedItems('included_columns');

    dispatch(
      generateSearchReport(
        {
          name: caption,
          reportUUID: uuidv4()
        },
        lastSearchCriteria,
        selectedIds,
        result.length,
        checkedColumns.map(v => v.id)
      )
    );
  };

  return { index, generateReport, caption, description, radiobtns, multiSelectors, dateSelectors, selectors, checkBoxes };
}