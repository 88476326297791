import { handlePost, handlePostFile, handleQuery } from '@zert-packages/actions/api';
import getLang from '@zert-packages/utils/getLang';
import makeValidProjectJSON from './utils/makeValidProjectJSON';
import makeValidSubProjectSubNodeJSON from './utils/makeValidSubProjectSubNodeJSON';
import makeValidDemandProjectRA from './utils/makeValidDemandProjectRA';
import makeValidSubNode from './utils/makeValidSubNodeJSON';

const startQuery = '/demandProjects';

const createData = (left, right) => ({
  left,
  right
});

export function loadDemandProjectAPI(versionId, viewId) {
  return handlePost(`${startQuery}/loadProject/${versionId}`, viewId);
}

export function loadRAEntriesAPI(versionId, viewId) {
  return handlePost(`${startQuery}/loadRAEntries/${versionId}`, viewId);
}

export function loadMatrixCoverageAPI(rowHeight, versionId, viewId) {
  return handlePost(`${startQuery}/loadMatrixCoverage/${rowHeight}/${versionId}`, viewId);
}

export function createDemandProjectRAAPI(project, nodeUUID, viewId, demandRA) {
  return handlePost(
    `${startQuery}/createDemandProjectRA/${nodeUUID}/${project.versionId}`,
    createData(viewId, makeValidDemandProjectRA(demandRA))
  );
}

export function updateProjectRAAPI(project, nodeUUID, viewId, demandRA) {
  return handlePost(
    `${startQuery}/updateDemandProjectRA/${nodeUUID}/${project.versionId}`,
    createData(viewId, makeValidDemandProjectRA(demandRA))
  );
}

export function loadDemandTemplateAPI() {
  return handleQuery(`${startQuery}/loadDemandTemplate`);
}

export function loadProjectLogAPI(versionId, viewId) {
  return handlePost(`${startQuery}/loadLog/${versionId}`, viewId);
}

export function initDemandProjectAPI(subprojectNumber, disciplineNumber, clientProject) {
  return handlePost(`${startQuery}/initDemandProject/${disciplineNumber}/${subprojectNumber}`, clientProject);
}

export const storeProjectAPI = (viewId, project) =>
  handlePost(`${startQuery}/storeProject/${project.versionId}`, createData(viewId, makeValidProjectJSON(project)));

export const sendInvitationAPI = (viewId, members, customMessage, reportUUID) =>
  handlePost(`${startQuery}/sendInvitation/${reportUUID}`, {
    viewId,
    members,
    customMessage
  });

export const addStoreSubProjectAPI = (viewId, position, versionId, subProject) =>
  handlePost(
    `${startQuery}/addStoreSubproject/${position}/${versionId}`,
    createData(viewId, makeValidSubProjectSubNodeJSON(subProject))
  );

export const pasteSubProjectAPI = (viewId, versionId, isCut, pasteAfterSubProjectUUID, copiedCutSubProjectUUID) =>
  handlePost(
    `${startQuery}/pasteSubproject/${isCut}/${pasteAfterSubProjectUUID}/${versionId}`,
    createData(viewId, copiedCutSubProjectUUID)
  );

export const moveSubProjectAPI = (viewId, direction, versionId, subProjectUUID) =>
  handlePost(`${startQuery}/moveSubproject/${direction}/${versionId}`, createData(viewId, subProjectUUID));

export const removeSubProjectAPI = (viewId, versionId, subProjectUUID) =>
  handlePost(`${startQuery}/removeSubproject/${versionId}`, createData(viewId, subProjectUUID));

export const getSubProjectAPI = (viewId, versionId, uuid) =>
  handlePost(`${startQuery}/getSubproject/${uuid}/${versionId}`, viewId);

export const getDocument = (versionId) => handleQuery(`${startQuery}/getDocument/${versionId}`);

export const getDocumentExtended = (documentVersionId) =>
  handleQuery(`${startQuery}/getDocumentExtended/${documentVersionId}`);

export const removeDemandProjectRAAPI = (project, viewId, uuid) => {
  return handlePost(`${startQuery}/removeDemandProjectRA/${project.versionId}`, createData(viewId, uuid));
};

export const loadDemands = (type) => handleQuery(`/demandProjectsTemplates/loadDemands/${type}`);

export const loadDemandTemplate = () => handleQuery(`/demandProjectsTemplates/loadDemandTemplate`);

export const clearDemandTemplate = () => handleQuery(`/demandProjectsTemplates/clearDemandTemplate`);

export const createDemand = (demand) => {
  return handlePost(`/demandProjectsTemplates/createDemand`, demand);
};

export const editDemand = (demand) => {
  return handlePost(`/demandProjectsTemplates/editDemand`, demand);
};

export const addStoreSubNodeAPI = (viewId, versionId, parentUUID, siblingsUUID, subNode) =>
  handlePost(
    `${startQuery}/addStoreNode/${parentUUID}/${siblingsUUID}/${versionId}`,
    createData(viewId, makeValidSubNode(subNode))
  );

export const pasteSubNodeAPI = (viewId, parentUUID, pasteToNodeUUID, isCut, versionId, copiedCutSubNodeUUID) =>
  handlePost(
    `${startQuery}/pasteNode/${parentUUID}/${pasteToNodeUUID}/${isCut}/${versionId}`,
    createData(viewId, copiedCutSubNodeUUID)
  );

export const moveSubNodeAPI = (
  viewId,
  versionId,
  parentUUID,
  direction,
  moveIn,
  moveOut,
  subNodeUUID,
  parentOfParenUUID = 't'
) =>
  handlePost(
    `${startQuery}/moveNode/${direction}/${parentUUID}/${parentOfParenUUID}/${moveIn}/${moveOut}/${versionId}/`,
    createData(viewId, subNodeUUID)
  );

export const removeSubNode = (viewId, versionId, parentUUID, subNodeUUID) =>
  handlePost(`${startQuery}/removeNode/${parentUUID}/${versionId}`, createData(viewId, subNodeUUID));

export const getSubNodeAPI = (viewId, versionId, uuid) =>
  handlePost(`${startQuery}/getNode/${uuid}/${versionId}`, viewId);

export const addStoreDisciplineAPI = (viewId, versionId, position, discipline) =>
  handlePost(`${startQuery}/addStoreDiscipline/${position}/${versionId}`, createData(viewId, discipline));

export const moveDisciplineAPI = (viewId, versionId, direction, discipline) =>
  handlePost(`${startQuery}/moveDiscipline/${direction}/${versionId}`, createData(viewId, discipline));

export const removeDisciplineAPI = (viewId, versionId, disciplineId) =>
  handlePost(`${startQuery}/removeDiscipline/${versionId}`, createData(viewId, disciplineId));

export const uploadFileAPI = (file) => handlePostFile(`${startQuery}/uploadFile`, file);
export const uploadBasisAPI = (file) => handlePostFile(`${startQuery}/uploadBasis`, file);

export const createDemandProjectDocumentsAPI = (viewId, versionId, docs) =>
  handlePost(`${startQuery}/createDemandProjectDocuments/${versionId}`, createData(viewId, docs));

export const changeDocumentStatusAPI = (viewId, projectVersionId, documentVersionId, status, nodeUUID) =>
  handlePost(
    `${startQuery}/changeDocumentStatus/${status}/${nodeUUID}/${documentVersionId}`,
    createData(viewId, projectVersionId)
  );

export const changeDocumentDateAPI = (date, documentVersionId, nodeUUID, viewId, projectVersionId) =>
  handlePost(
    `${startQuery}/changeDocumentDate/${date}/${nodeUUID}/${documentVersionId}`,
    createData(viewId, projectVersionId)
  );

export const loadDemandCoverageAPI = (versionId, viewId) =>
  handlePost(`${startQuery}/loadDemandCoverage/${versionId}`, viewId);

export const initChecklistAPI = (idTemplate = -1, data) =>
  handlePost(`${startQuery}/initCheckList/${idTemplate}`, data);

export const changeDeadlineNodeAPI = (viewId, projectVersionId, nodeUUID, date, includedNode) =>
  handlePost(
    `${startQuery}/changeDeadlineNode/${nodeUUID}/${includedNode}/${date}`,
    createData(viewId, projectVersionId)
  );

export const uploadDocumentElementAPI = (nodeUUID, documentVersionId, viewId, projectVersionId, basis) =>
  handlePost(
    `${startQuery}/uploadDocumentElement/${nodeUUID}/${documentVersionId}/${projectVersionId}`,
    createData(viewId, basis)
  );

export const clearDocumentAPI = (nodeUUID, documentVersionId, viewId, projectVersionId) =>
  handlePost(`${startQuery}/clearDocument/${nodeUUID}/${documentVersionId}`, createData(viewId, projectVersionId));

export const updateProjectPhaseAPI = (viewId, projectVersionId, phase) =>
  handlePost(`${startQuery}/updateProjectPhase/${phase}`, createData(viewId, projectVersionId));

export const uploadDocumentBasisAPI = (viewId, projectVersionId, nodeUUID, documentVersionId, element) =>
  handlePost(
    `${startQuery}/uploadDocumentBasis/${nodeUUID}/${documentVersionId}/${projectVersionId}`,
    createData(viewId, element)
  );

export const generateReportAPI = (viewId, projectVersionId, reportUUID, reportType, treeScope) => {
  const locale = getLang();
  return handlePost(
    `${startQuery}/generateReport/${reportUUID}/${reportType}/${treeScope}/${locale}`,
    createData(viewId, projectVersionId)
  );
};

export const exportProjectAPI = (viewId, reportUUID, projectVersionId) => {
  const locale = getLang();
  return handlePost(`${startQuery}/generateReport/${reportUUID}/${locale}/${projectVersionId}`);
};

export const setProjectCellDemandsAPI = (viewId, projectVersionId, state) =>
  handlePost(`${startQuery}/setProjectCellDemands/${projectVersionId}`, createData(viewId, state));

export const initDeviationAPI = (templateVersionId, state) =>
  handlePost(`${startQuery}/initDeviation/${templateVersionId}`, state);

export const getOriginAPI = (nodeUUID, projectDocumentId) =>
  handlePost(`${startQuery}/getOrigin/${nodeUUID}/${projectDocumentId}`);

export const getUpdateStatusSummaryAPI = (projectDocumentId, viewId) =>
  handlePost(`${startQuery}/getUpdateStatusSummary/${projectDocumentId}`, viewId);

export const setStatusSummaryAPI = (projectDocumentId, viewId, state) => {
  return handlePost(`${startQuery}/setUpdateStatusSummary/${projectDocumentId}`, createData(viewId, state));
};
