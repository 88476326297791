import React, { useContext, useState } from "react";
import { Checkbox, FormControlLabel, Grid, TextField, Tab, Tabs } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TabsContext } from "../../../../Stylesheet";
import { handlePageLayoutChildObject } from "../../../../utils/helpers";
import LayoutModel from "../LayoutModel";
import TemplateItemRow from "../../../../TemplateItemRow";

const useStyles = makeStyles((theme) => ({
  wrapperCheckbox: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: 10,
    "& .MuiFormControlLabel-root": {
      marginBottom: 0
    }
  },
  itemRowWrapper: {
    display: "grid",
    gridRowGap: 10,
    marginTop: 10
  },
  wrapperRow: {
    display: "grid",
    gridTemplateColumns: "100px 1fr 55px"
  },
  containerCheckbox: {
    marginLeft: 10,
    marginTop: -10
  },
  templateTitle: {
    fontWeight: "700",
    marginBottom: 0
  },
  templateInput: {
    height: "Calc(100% - 540px)",
    width: "100%",
    "& .MuiInputBase-multiline.MuiInputBase-marginDense": {
      height: "100%"
    },
    "& .MuiOutlinedInput-inputMultiline": {
      height: "100% !important"
    }
  },
  tabsRoot: {
    minHeight: 18,
    marginBottom: 15,
    "& .MuiTab-root": {
      padding: "0px 4px 0px 3px",
      minWidth: "fit-content",
      minHeight: 18,
      background: "#EBEBEB"
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#ffffff",
      background: "#4db1d3",
      outline: "none"
    },
    "& .MuiTabs-centered": {
      justifyContent: "left",
      background: "#E3F2F7"
    }
  },
  blockInput: {
    height: "Calc(100% - 250px)",
    width: "100%",
    "& .MuiInputBase-multiline.MuiInputBase-marginDense": {
      height: "100%"
    },
    "& .MuiOutlinedInput-inputMultiline": {
      height: "100% !important"
    },
    '& .MuiInputBase-multiline': {
      height: '100%',
    },
    '& textarea': {
      height: '100% !important',
    },
  }
}));


function Header() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const { header } = values.stylesheet.blockSettings[values.selectedLeftMenu];
  const tabs = ["First page", "general"];
  const [selectedTab, setSelectedTab] = useState(0);

  const handleCheckbox = (e) => {
    const val = e.target.checked;
    const { name } = e.target;

    if (name === "firstDifferent" && selectedTab !== 0 && header.firstDifferent) {
      setSelectedTab(0);
    }
    handlePageLayoutChildObject(val, name, "header", values, setValues);
  };

  const setValueTemplate = (e) => {
    const val = e.target.value;
    const { name } = e.target;
    handlePageLayoutChildObject(val, name, "header", values, setValues);
  };

  const setValueProps = (val, name) => {
    handlePageLayoutChildObject(val, name, "header", values, setValues, true);
  };

  const setDropMenu = (valDropMenu, name, val) => {
    handlePageLayoutChildObject(val, name, "header", values, setValues, true, valDropMenu);
  };

  const handleChangeSelectedTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  if (!header) {
    return;
  }


  return (
    <div style={{
        height: "Calc(100% - 123px)"
      }} >
      <div className={cl.wrapperCheckbox}>
        <FormControlLabel
          control={<Checkbox onChange={handleCheckbox} checked={header.visible} name="visible" />}
          label={<span>Use header</span>}
        />
      </div>
      <div className={cl.wrapperCheckbox}>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={header.firstDifferent}
                onChange={handleCheckbox}
                name="firstDifferent"
                disabled={!header.visible}
              />
            }
            label={<span>Different header on first page</span>}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={header.firstDifferentInPageLayout}
                onChange={handleCheckbox}
                name="firstDifferentInPageLayout"
                disabled={!header.visible}
              />
            }
            label={<span>in page layout</span>}
          />
        </div>
      </div>

      {header.firstDifferent && (
        <Tabs
          indicatorColor=""
          value={selectedTab}
          className={cl.tabsRoot}
          onChange={handleChangeSelectedTab}
          textColor="primary"
          variant="standard"
          scrollButtons="auto"
          centered
        >
          {tabs.map((el, i) => (
            <Tab label={el} key={i} />
          ))}
        </Tabs>
      )}

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div className={cl.itemRowWrapper}>
            {selectedTab === 0 && <>
              <TemplateItemRow
                label="Spacing:"
                type={"firstSpacing"}
                value={header.firstSpacing}
                grid="100px 1fr 55px"
                setValueProps={setValueProps}
                setDropMenu={setDropMenu}
                disabled={!header.visible}
              />
              <TemplateItemRow
                label="Height:"
                type={"firstHeight"}
                value={header.firstHeight}
                grid="100px 1fr 55px"
                setValueProps={setValueProps}
                setDropMenu={setDropMenu}
                disabled={!header.visible}
              />
            </>}
            {selectedTab === 1 && <>
              <TemplateItemRow
                label="Spacing:"
                type={"spacing"}
                value={header.spacing}
                grid="100px 1fr 55px"
                setValueProps={setValueProps}
                setDropMenu={setDropMenu}
                disabled={!header.visible}
              />
              <TemplateItemRow
                label="Height:"
                type={"height"}
                value={header.height}
                grid="100px 1fr 55px"
                setValueProps={setValueProps}
                setDropMenu={setDropMenu}
                disabled={!header.visible}
              />
            </>}


            <div className={cl.wrapperRow}>
              <div />
              <div className={cl.containerCheckbox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckbox}
                      checked={header.precedence}
                      name="precedence"
                      disabled={!header.visible}
                    />
                  }
                  label={<span>Use entire page width</span>}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <LayoutModel />
        </Grid>
      </Grid>

      <div style={{ marginTop: "2rem" }} />
      {(selectedTab === 1 && header.firstDifferent) || !header.firstDifferent ? (
        <>
          <div>
            <p className={cl.templateTitle}>Content for odd pages:</p>
            <TextField
              value={header.oddContent}
              className={cl.templateInput}
              variant="outlined"
              multiline
              minRows={9}
              onChange={setValueTemplate}
              name="oddContent"
              disabled={!header.visible}
            />
          </div>
          <div>
            <p className={cl.templateTitle} style={{ marginTop: 10 }}>
              Content for even pages:
            </p>
            <TextField
              value={header.evenContent}
              className={cl.templateInput}
              variant="outlined"
              multiline
              minRows={9}
              onChange={setValueTemplate}
              name="evenContent"
              disabled={!header.visible}
            />
          </div>
        </>
      ) : (
        <>
          <p className={cl.templateTitle}>Content</p>
          <TextField
            value={header.firstContent}
            onChange={setValueTemplate}
            name={'firstContent'}
            className={cl.blockInput}
            variant="outlined"
            multiline
            minRows={4}
            disabled={!header.visible}
          />
        </>
      )}
    </div>
  );
}

export default Header;
