import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

const ZertTheme = () => {
  return createTheme({
    palette: {
      primary: {
        light: '#04A1C7',
        main: '#188ec7',
        dark: '#05364b',
        contrastText: '#fff'
      },
      secondary: {
        light: '#1898c7',
        main: '#05364b',
        dark: '#c70061',
        contrastText: '#fff'
      },
      error: {
        light: '#c73c3c',
        main: '#c7133e',
        dark: '#c70061',
        contrastText: '#fff'
      }
    },
    typography: {
      h1: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
        fontWeight: 500,
        fontSize: '1.5rem',
        color: '#404040'
      },
      h2: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
        fontWeight: 500,
        fontSize: '1.4rem',
        color: '#404040'
      },
      h3: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
        fontWeight: 500,
        fontSize: '1.3rem',
        color: '#404040'
      },
      h4: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
        fontWeight: 500,
        fontSize: '1.2rem',
        color: '#404040'
      },
      h5: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
        fontWeight: 500,
        fontSize: '1.1rem',
        color: '#404040'
      },
      h6: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
        fontWeight: 'bold',
        fontSize: '1rem',
        color: '#404040'
      },
      fontFamily: ['Open Sans', 'sans-serif'].join(',')
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        mobile: 0,
        tablet: 768,
        laptop: 1024,
        desktop: 1200
      }
    },
    components: {
      MuiTextField: {
        defaultProps: {
          size: 'small',
          margin: 'dense'
        }
      },
      MuiSelect: {
        defaultProps: {
          size: 'small'
        },
        styleOverrides: {
          select: {
            '&:focus': {
              backgroundColor: 'rgba(0, 0, 0, 0)'
            }
          }
        },
        variants: [
          {
            props: {
              variant: 'outlined'
            },
            style: {
              fieldset: {
                borderRadius: 0,
                borderTop: 'none',
                borderRight: 'none',
                borderLeft: 'none'
              }
            }
          }
        ]
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: 'Open Sans, sans-serif'
          }
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: {}
        }
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            '@media (max-width: 600px)': {
              padding: '6px'
            }
          }
        }
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&$completed': {
              color: '#188ec7'
            },
            '&$active': {
              color: '#188ec7'
            }
          },
          active: {},
          completed: {}
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            '&$expanded': {
              minHeight: 'unset'
            }
          },
          content: {
            '&$expanded': {
              margin: '0'
            }
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          body1: {
            fontFamily: 'Open Sans, sans-serif',
            fontSize: '0.9rem'
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&:before': {
              borderBottom: '1px solid #E1EAEE'
            },
            '&:after': {
              borderBottom: '2px solid #04A1C7'
            },
            '&&&&:hover:before': {
              borderBottom: '2px solid #04A1C7'
            }
          }
        }
      },
      MuiOutlinedInput: {
        defaultProps: {
          margin: 'dense'
        },
        styleOverrides: {
          root: {
            '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
              borderColor: '#04A1C7'
            }
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          colorPrimary: {
            '&$checked': {
              color: '#FAFAFA'
            }
          }
        }
      },
      MuiButton: {
        defaultProps: {
          color: 'primary'
        },
        styleOverrides: {
          root: {
            fontFamily: 'Open Sans, sans-serif'
          }
        }
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&:focus': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }
        }
      },
      MuiTable: {
        defaultProps: {
          size: 'small'
        }
      },
      MuiTableCell: {
        defaultProps: {
          size: 'small'
        },
        styleOverrides: {
          head: {
            backgroundColor: '#fafafa'
          }
        }
      },
      MuiInputLabel: {
        defaultProps: {
          margin: 'dense'
        }
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: '1em'
          },
          avatar: {
            marginRight: '1em'
          }
        }
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '1em'
          }
        }
      },
      MuiGrid: {
        defaultProps: {
          spacing: 0
        }
        /* styleOverrides: {
          root: {
            '& > .MuiGrid-item': {
              paddingLeft: '1em',
              paddingTop: '1em'
            }
          }
        } */
      }
    }
  });
};

export default ZertTheme();
