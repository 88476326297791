import React, { memo } from 'react';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import ExpansionWrapperCriteria from '../common/ExpansionWrapperCriteria';

const ContextSearchCriteria = memo(
  ({ foundCriteria, updateCriteria, onChangeExpansionOfCriteria, classNameOfCriteria, isEverythingDisabled }) => {
    const onChangeContext = (event) => {
      const { value } = event.target;
      const newValues = foundCriteria.values.map((item) =>
        item.key === 'Context'
          ? {
            ...item,
            value
          }
          : item
      );
      updateCriteria(classNameOfCriteria, newValues);
    };
    const handleOnChangeExpansion = (event, isExpanded) => {
      onChangeExpansionOfCriteria(isExpanded, classNameOfCriteria);
    };
    return (
      <ExpansionWrapperCriteria
        title={<FormattedMessage id="contextCriteriaCounter.context" defaultMessage="Context" />}
        handleOnChangeExpansion={handleOnChangeExpansion}
        expanded={!!foundCriteria}
      >
        {!!foundCriteria && (
          <TextField
            disabled={isEverythingDisabled}
            fullWidth
            label={<FormattedMessage id="contextCriteriaCounter.context" defaultMessage="Context" />}
            margin="dense"
            variant="outlined"
            value={foundCriteria.values.find((item) => item.key === 'Context').value}
            onChange={onChangeContext}
          />
        )}
      </ExpansionWrapperCriteria>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps.foundCriteria, nextProps.foundCriteria)
);

export default ContextSearchCriteria;
