import React from 'react';
import { makeStyles } from '@mui/styles';
import { ReactSVG } from 'react-svg';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    fill: props.color,
    width: props.width,
    height: props.height,
    color: props.color,
    cursor: props.hoverColor ? 'pointer' : '',
    '& svg': {
      width: '100%',
      height: '100%'
    },
    '&:hover': {
      fill: `${props.hoverColor}!important`,
      color: `${props.hoverColor}!important`,
      '& svg': {
        fill: `${props.hoverColor}!important`
      }
    }
  })
}));

function BlockIcon({ svg, width = 14, height = 14, color = '#505050', hoverColor }) {
  const cl = useStyles({ color, hoverColor, width, height });

  if (typeof svg === 'string') {
    return (
      <div className={cl.root}>
        <ReactSVG
          src={svg}
          beforeInjection={(svg) => {
            svg.setAttribute('fill', color);
          }}
          wrapper="span"
          className="svg-container"
        />
      </div>
    );
  }

  if (typeof svg === 'object' && svg?.$$typeof === Symbol.for('react.memo')) {
    const Icon = svg;
    return (
      <div className={cl.root}>
        <Icon />
      </div>
    );
  }

  return <div className={cl.root}>{svg && { ...svg, props: { ...svg.props, width, height } }}</div>;
}

export default BlockIcon;
