import React, {useState} from 'react'
import makeStyles from "@mui/styles/makeStyles";
import {Button, Checkbox, Dialog, FormControlLabel} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextFieldItem from "../../TextFieldItem";
import UpdateLatestVersion from "clm/src/plugins/AddElementPlugin/UpdateLatestVersion";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 500,
        minHeight: 585,
        padding: 11,
        background: '#E2F2F6'
    },
    wrapperTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        '& svg': {
            cursor: 'pointer'
        }
    },
    title: {
        fontSize: '1.8rem',
        fontWeight: 700
    },
    label: {
        fontWeight: 700,
    },
    border: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 5,
        height: 90,
        overflow: 'auto',
    },
    container: {
        display: 'grid',
        gridRowGap: 10,
    },
    wrapperButton: {
        display: 'flex',
        justifyContent: 'end',
        paddingRight: 20,
    },
    button: {
        color: '#ffffff',
        background: '#4db1d3',
        marginLeft: 10,
        minWidth: 80,
        border: '1px solid #4db1d3',
        outline: 'none !important',
        cursor: 'pointer',
        '&:hover': {
            background: 'none',
            color: '#212529',
            border: '1px solid #4db1d3'
        },
        '&:focus': {
            backgroundColor: '#4db1d3'
        }
    }
}))

const DialogUpdateReferences = () => {
    const cl = useStyles();
    const [open, setOpen] = useState(true)
    const [references, setReferences] = useState({
        comment: '',
        checkLockedReferences: false,
    })


    const handleClose = () => {
        setOpen(false)
    };

    const handleReferences = (val, name) => {
        setReferences(pre=> ({...pre, [name]: val}))
    }

    return (<Dialog
        open={open}
        onClose={handleClose}
        classes={{
            paperScrollPaper: cl.root
        }}
    >
        <div className={cl.wrapperTitle}>
            <span className={cl.title}>Update References</span>
            <CloseIcon onClick={handleClose}/>
        </div>

        <div className={cl.container}>
            <div>
                <div className={cl.label}>Select elements to update to latest version:</div>
                <UpdateLatestVersion />
            </div>
            <div>
                <div className={cl.label}>Current:</div>
                <div className={cl.border}></div>
            </div>
            <div>
                <div className={cl.label}>Update to:</div>
                <div className={cl.border}></div>
            </div>
            <div>
            <div className={cl.label}>Comment:</div>
                <TextFieldItem
                    values={references.comment}
                    name={"comment"}
                    setValues={handleReferences}
                    multiline={true}
                    row={3}
                />
            </div>
            <div>
                <FormControlLabel
                    label={<div>Update locked References</div>}
                    control={
                        <Checkbox
                            checked={references.checkLockedReferences}
                            onChange={(e) => handleReferences(e.target.checked, 'checkLockedReferences')}
                            // className={cl.checkboxRoot}
                        />
                    }
                />

            </div>

        </div>

        <div className={cl.wrapperButton}>
            <Button className={cl.button} onClick={handleClose}>
            Update recursively
            </Button>
            <Button className={cl.button} onClick={handleClose}>
                Update
            </Button>
            <Button className={cl.button} onClick={handleClose}>
                Cancel
            </Button>
        </div>
    </Dialog>)
}

export default DialogUpdateReferences