import { useEffect, useState } from 'react';
import { useViewport } from '@zert-packages/utils/ViewportProvider';

export const useHeightOffset = (element, ...props) => {
  const [heightOffset, setHeightOffset] = useState<number | null>(null);
  const { width, height } = useViewport();

  useEffect(() => {
    if (!element) {
      return;
    }

    setHeightOffset(calculateOffset(element));
  }, [element, width, height, ...props]);

  return heightOffset;
};

const calculateOffset = (element: Element | null) => {
  const elementPosition = element;

  let calcHeightOffset = 0;

  while (element && elementPosition) {
    if (
      element.previousElementSibling &&
      element.previousElementSibling.getBoundingClientRect().y < elementPosition.getBoundingClientRect().y
    ) {
      if (element.previousElementSibling.getBoundingClientRect().height <= elementPosition.getBoundingClientRect().y) {
        const computedStyles = window.getComputedStyle(element.previousElementSibling);
        const totalHeight =
          element.previousElementSibling.getBoundingClientRect().height +
          parseFloat(computedStyles.marginTop) +
          parseFloat(computedStyles.marginBottom) +
          parseFloat(computedStyles.borderTopWidth) +
          parseFloat(computedStyles.borderBottomWidth);

        calcHeightOffset += totalHeight;
      }
      element = element.previousElementSibling;
    } else {
      element = null;
    }
  }
  return calcHeightOffset;
};
