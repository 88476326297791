import React from 'react'
import { makeStyles } from "@mui/styles";
import DropMenu from "@zert-packages/components/DropMenu";
import handleValueState from "@zert-packages/utils/handleValueState";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    gap: 15,
  },
  label: {
    fontWeight: 700,
  },
}));

const dataFilter = ['All elements', 'Phrase']

const Filter = ({browse, setBrowse}) => {
  const cl = useStyles();

  const handleFilter = (val, name) => {
    handleValueState(val, name, setBrowse)
  }
  return(<div>
      <div className={cl.wrapper}>
          <div className={cl.label}>Filter:</div>
          <DropMenu
            selected={browse.selectedFilter}
            dropItem={browse.allFilters}
            name={'selectedFilter'}
            setDropItem={handleFilter}
          />
      </div>
  </div>)
}

export default Filter