import React from 'react';
import { Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  skeletonLoadingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    maxWidth: '400px'
  },
  skeleton: {
    margin: theme.spacing(0.5)
  },
  skeletonLoadingTextWrapper: {
    width: 'calc(100% - 82px)'
  }
}));

function SkeletonLoading() {
  const cl = useStyles();

  return (
    <div className={cl.skeletonLoadingWrapper}>
      <Skeleton variant="rectangular" width={16} height={16} className={cl.skeleton} />
      <Skeleton variant="rectangular" width={24} height={24} className={cl.skeleton} />
      <div className={cl.skeletonLoadingTextWrapper}>
        <Skeleton variant="text" />
        <Skeleton variant="text" width="50%" />
      </div>
      <Skeleton variant="rectangular" width={18} height={18} className={cl.skeleton} />
    </div>
  );
}

export default SkeletonLoading;
