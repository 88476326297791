import Typography from '@mui/material/Typography';
import React from 'react';
import { useTreeItemStyles } from '../TranslationStatusNode';
import TranslationStatusFlag from './TranslationStatusFlag';
import { getTranslationStatuses } from '../helpers/getTranslationStatuses';

function TranslationStatusLabel({ type }) {
  const classes = useTreeItemStyles();
  return (
    <div style={{ display: 'inline-flex' }}>
      <TranslationStatusFlag type={type} extraXStyle={classes.extraXStyle} />
      <Typography style={{ padding: '3px' }} variant="caption" color="inherit">
        {getTranslationStatuses[type]}
      </Typography>
    </div>
  );
}

export default TranslationStatusLabel;
