import React, { Fragment, useContext, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import MediaQuery from 'react-responsive';
import { PurposePage } from '../PublishWizardDialog/PurposePage';
import SummaryPage from '../PublishWizardDialog/SummaryPage';
import { setSelectedPreviewJOBElement } from '../../PreviewPlugin/previewReducers';
import {
  getStepContent,
  PreviewMobileStepper,
  PreviewWizardContext,
  PreviewWizardProvider
} from '../../PreviewPlugin/PreviewWizardDialog/PreviewWizardStepper';
import { AssertPublishingPage } from './AssertPublishingPage';

import AssertVerticalLinearStepper, {
  AssertPublishingWizardContext,
  AssertPublishingWizardProvider,
  PublishingMobileStepper
} from './PublishingWizardStepper';
import { LanguagesPage } from '../../PreviewPlugin/PreviewWizardDialog/LanguagesPage';
import { AssertFinishPage } from './AssertFinishPage';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1
  }
});
function PreviewDialogContext({ readOnlyPublihedElement }) {
  const { activeStep, handleNext, handleBack } = useContext(AssertPublishingWizardContext);

  switch (activeStep) {
    case 0:
      return <AssertPublishingPage readOnlyPublihedElement={readOnlyPublihedElement} />;
    case 1:
      return <PurposePage readOnly={false} />;
    case 2:
      return <LanguagesPage />;
    case 3:
      return <AssertFinishPage />;

    default:
      return <div />;
  }
}

function AssertWizardDialog({ onConfirm, element, context, publishingId, unmountComponent, unMountParent }) {
  const dispatch = useDispatch();
  const {
    activeStep,
    allowComments,
    notifyMeOnExpire,
    notifyMeOneNewComment,
    validUntil,
    purpose,
    setPurpose,
    from,
    to,
    splitLevel,
    elementId,
    documentId,
    name,
    versionId,
    serialNumber,
    productId
  } = useContext(AssertPublishingWizardContext);
  useEffect(() => {
    if (context) {
      setPurpose(context);
    }
  }, []);
  useEffect(() => {
    dispatch(setSelectedPreviewJOBElement(element));
  }, element);
  const handleConfirm = () => {
    unmountComponent();
    if (unMountParent) {
      unMountParent();
    }
    onConfirm(
      { from, to, splitLevel, elementId, documentId, name, versionId, serialNumber, productId },
      { allowComments, notifyMeOnExpire, notifyMeOneNewComment, validUntil, purpose }
    );
  };
  return (
    <Dialog open onClose={unmountComponent} fullWidth maxWidth="md">
      <DialogTitle>
        <FormattedMessage id="Publish.Assert.Cloud" defaultMessage="Prepare document to be publish in Assert" />
      </DialogTitle>
      <DialogContent>
        <MediaQuery query="(min-width: 1024px)">
          <Grid container spacing={3}>
            <Grid item xl={4}>
              <AssertVerticalLinearStepper />
            </Grid>
            <Grid item xl={8}>
              <PreviewDialogContext readOnlyPublihedElement={publishingId !== -1} />
            </Grid>
          </Grid>
        </MediaQuery>
        <MediaQuery query="(max-width: 1024px)">
          <>
            <Typography>{getStepContent(activeStep)}</Typography>
          </>
          <PreviewDialogContext readOnlyPublihedElement={publishingId !== -1} />
        </MediaQuery>
      </DialogContent>
      <DialogActions>
        <Button onClick={unmountComponent} size="small">
          <FormattedMessage id="AssertPublishingWizard.Cancel" defaultMessage="Cancel" />
        </Button>

        <PublishingMobileStepper handleConfirm={handleConfirm} />
      </DialogActions>
    </Dialog>
  );
}

function AssertPublishingWizardContextWrapper({
  onConfirm,
  selectElement,
  publishingId,
  context,
  unMountParent,
  unmountComponent
}) {
  return (
    <AssertPublishingWizardProvider>
      <AssertWizardDialog
        onConfirm={onConfirm}
        element={selectElement}
        publishingId={publishingId}
        context={context}
        unMountParent={unMountParent}
        unmountComponent={unmountComponent}
      />
    </AssertPublishingWizardProvider>
  );
}

export default AssertPublishingWizardContextWrapper;
