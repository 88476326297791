const getTranslationsFromContext = (translationContext, portal) => {
  const allFiles = translationContext.keys();
  const portalFiles = allFiles.filter((filePath) => filePath.includes(`translations/${portal}`));
  const newMessages = {};

  portalFiles.forEach((filePath) => {
    const langCode = filePath.match(/([a-z]{2})\.json$/)[1];
    newMessages[langCode] = translationContext(filePath);
  });

  return newMessages;
};

export default getTranslationsFromContext;
