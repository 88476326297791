import React from "react";
import {makeStyles} from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import ConnectionsDialog from "./components/dialog/connectionsDialog/ConnectionsDialog";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "grid",
        gridTemplateColumns: "1fr 25px",
        gridColumnGap: 10,
    },
    wrapperImg: {
        color: "#4db1d3",
        "& svg": {
            cursor: "pointer",
            marginBottom: 3.5,
        }
    },
    row: {
        padding: '2px 4px',
        display: 'grid',
        gap: 5,
        gridTemplateColumns: '147px 1fr',
    },

    listWrapper: {
        border: "1px solid #BDBDBC",
        borderRadius: 5,
        overflow: "auto",
        minHeight: 200,
        maxHeight: `100%`,
        paddingBottom: 15,
    },

    listLeftMenu: {
        cursor: 'pointer'
    },
    listSelected: {
        cursor: 'pointer',
        color: '#ffffff',
        background: '#4db1d3'
    },
}));

const ConnectionsTabs = ({metaData, setMetadata}) => {
    const cl = useStyles();
    const list = metaData.meta?.structure?.connections

    const deleteConnections = () => {
        if (list.length === 0) {
            return
        }
        const res = list.filter((el, i) => i !== metaData.selectedConnections)
        handleConnectionsList(res)
    }


    const editMConnections = (add) => {
        const selected = add ? false : list[metaData.selectedConnections]
        renderInExceptionRoot(ConnectionsDialog, {selected, metaData, handleConnectionsList, add})
    }

    const handleListItem = (event, index) => {
        setMetadata(pre => ({...pre, selectedConnections: index}))
        if (index === metaData.selectedConnections) {
            editMConnections()
        }
    }

    const handleConnectionsList = (val) => {
        setMetadata(pre => ({...pre, meta: {...pre.meta, structure: {...pre.meta.structure, connections: val}}}))
    }




    return (<div className={cl.root}>
        <div className={cl.listWrapper}>
            <div className={cl.row}>
                <div>Identifier</div>
                <div>Applies to</div>
            </div>
            {list?.length > 0 && list.map((el, idx) => <div
                 key={idx}
                onClick={(event) => handleListItem(event, idx)}
                className={`${cl.row} ${metaData.selectedConnections === idx ? cl.listSelected : cl.listLeftMenu}`}>
                <div>
                    {el.name}
                </div>
            </div>)}
        </div>

        <div className={cl.wrapperImg}>
            <AddIcon onClick={() => editMConnections(true)}/>
            <CreateIcon onClick={() => editMConnections()}/>
            <DeleteIcon onClick={deleteConnections}/>
        </div>
    </div>)
}

export default ConnectionsTabs