import makeStyles from "@mui/styles/makeStyles";

export const usePublishingFilterStyles = makeStyles(() => ({
    AddIcon: {
        display: 'flex',
        width: 45,
        justifyContent: 'center',
        border: '1px solid #D3D3D3',
        borderRadius: 5,
        height: 35,
        alignItems: 'center',
        cursor: 'pointer',
        margin: '0px 0px 10px 8px',
        '& svg': {
            width: '0.7em',
            height: '0.8em'
        }
    },
    title: {
        margin: '10px 0px',
        fontSize: 12
    },
    wrapperSelectCategory: {
        padding: '8px 16px 16px',
        background: '#EFF0ED',
        width: 'Calc(100% + 32px)',
        position: 'relative',
        left: -16,
        fontSize: 12,
        '& .MuiTab-textColorPrimary': {
            background: '#EBEBEB',
            padding: '5px 10px',
            minWidth: 'fit-content',
            minHeight: 26,
            height: 26,
            outline: 'none',
            '&:hover': {
                background: '#4db1d3 ',
                color: '#ffffff'
            }
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            background: '#4db1d3 ',
            color: '#ffffff'
        },
        '& .MuiTabs-centered': {
            justifyContent: 'left'
        },
        '& .MuiTabs-root': {
            minHeight: 26,
            marginBottom: 10
        },
        '& .PrivateTabIndicator-colorSecondary': {
            display: 'none'
        }
    },
    wrapperBoxItem: {
        padding: '8px 16px 16px',
        '& .MuiTab-textColorPrimary': {
            background: '#EBEBEB',
            padding: '5px 10px',
            minWidth: 'fit-content',
            minHeight: 26,
            height: 26,
            outline: 'none',
            '&:hover': {
                background: '#4db1d3 ',
                color: '#ffffff'
            }
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            background: '#4db1d3 ',
            color: '#ffffff'
        },
        '& .MuiTabs-centered': {
            justifyContent: 'left'
        },
        '& .MuiTabs-root': {
            minHeight: 26,
            marginBottom: 10
        },
        '& .PrivateTabIndicator-colorSecondary': {
            display: 'none'
        }
    },
    dropMenuButton: {
        width: '100%',
        justifyContent: 'space-between',
        border: '1px solid #DCE3E5',
        marginBottom: 10,
        color: '#8B8B8B',
        fontWeight: '400',
        paddingBottom: 2,
        paddingTop: 2,
        background: '#ffffff',
        paddingRight: 15,
        borderRadius: 0,
        outline: 'none !important',
        textTransform: 'none',
        '&:hover': {
            background: '#ffffff'
        },
        '& span:nth-child(2)': {
            display: 'none'
        },
        '&:focus': {
            backgroundColor: '#ffffff'
        }
    },
    menuLinkSiteWrapper: {
        borderRadius: 0,
        // width: 470,
        width: 'fit-content',
        minWidth: 315,
        maxWidth: 'Calc(100% - 40px)',
        // maxHeight: height ? `${height}px` : '100%',
        marginTop: 40,
        border: '1px solid #DCE3E5',
        boxShadow: 'none',
        '@media (max-width: 900px)': {
            marginTop: 45,
            width: 'Calc(100% - 151px)'
        }
    },
    // .MuiIconButton-root
    formControlRoot: {
        '& .MuiCheckbox-root': {
            padding: 5
        }
    },
    checkboxLabel: {
        fontSize: 13
    },
    wrapperButton: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: '20px',
        maxWidth: 244,
        marginTop: 5,
        '& button': {
            background: '#ffffff',
            border: '1px solid lightgray',
            borderRadius: 3,
            paddingTop: 3,
            paddingBottom: 3,
            fontSize: 13,
            cursor: 'pointer',
            width: '100%',
            outline: 'none !important',
            '&:hover': {
                background: '#ffffff'
            }
        }
    },
    wrapperCheckbox: {
        marginLeft: 4
    },
    borderTop: {
        borderTop: '1px solid lightgray',
        width: 'Calc(100% + 32px)',
        position: 'relative',
        left: -16,
        cursor: 'pointer'
    },
    filterSaveRoot: {
        // borderTop: '1px solid lightgray',
        // width: 'Calc(100% + 25px)',
        // position: 'relative',
        // left: -16,
        cursor: 'pointer'
    },
    filterSaveWrapper: {
        padding: 5,
        borderBottom: '1px solid lightgray',
        '& p': {
            fontSize: 12,
            marginBottom: 0
        }
    },
    filterSaveWrapperTitle: {
        fontSize: 10,
        color: 'lightgray'
    },
    boxRoot: {
        // height: "120px",
        border: "1px solid grey",
        marginLeft: "15px",
        margin: "5px 0",
        position: "relative",
        backgroundColor: "rgba(191, 186, 186, 0.5)"
    },
    boxTitleItem: {
        backgroundColor: "white",
        margin: "5px 15px",
        position: "relative",
        padding: '5px'
    },
    buttonBox: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr"
    },
    buttonItem: {
        textAlign: "center",
        transition: "0.3s",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(175, 195, 237, 0.7)",
        }
    },
    arrow: {
        position: "absolute",
        left: "-15px",
        top: "50%",
        width: "15px",
        border: "1px solid grey"
    },
    option: {
        padding: "5px",
        border: "3px solid red"
    },
    box: {
        height: "200px",
        borderLeft: "1px solid grey"
    },
    valBox: {
        padding: "2px 5px",
        width: props => props.width ? `${props.width}px` : "50px",
        border: "1px solid grey",
        position: "relative",
        margin: "5px",
        cursor: "pointer",
        backgroundColor: "white"
    },
    optionBoxHidden: {
        height: '0px',
        border: "",
        padding: "0 5px",
        overflowY: "hidden",
        transition: "0.3s",
        position: "absolute",
        width: "70px",
        // top: props => props.open ? '60px' : '0px',
        top: '30px',
        left: "0",
        zIndex: "10",
        backgroundColor: "white",
        cursor: "pointer"
    },
    optionBoxOpen: {
        height: 'max-content',
        border: "1px solid grey",
        padding: "0 5px",
        overflowY: "hidden",
        transition: "0.3s",
        position: "absolute",
        width: "70px",
        // top: props => props.open ? '60px' : '0px',
        top: '30px',
        left: "0",
        zIndex: "10",
        backgroundColor: "white",
        cursor: "pointer"
    },
    boxItem: {
        height: "50px",
        border: "1px solid grey",
        margin: "10px 0 0 10px"
    },
}));