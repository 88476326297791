import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog} from "@mui/material";
import {addNewTranslations, getActiveLocalesWithSort, getTranslations} from "../../BlockPlugin/API";
import Autocomplete from "@mui/material/Autocomplete";
import {DeleteSharp} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {FormattedMessage} from "react-intl";
import AddButtonComp from "./AddButtonComp";
import AddIcon from "@mui/icons-material/Add";
import {createPhraseApi} from "@zert-packages/components/InfoElement/components/API";
import {addTermSpec, initTermDb, storeTerm} from "../API";
import {useSnackbar} from "notistack";
import {createInProgressSnack} from "@zert-packages/components/common/snackBar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 262,
    padding: 5,
    paddingBottom: 10,
    background: "#E2F2F6",
    minHeight: 168,
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "flex-end",
    "& svg": {
      cursor: "pointer"
    }
  },
  wrapper: {

  },
  wrapperList: {
    marginTop: 5,
    border: '1px solid lightgray',
    minHeight: 100,
    padding: 5,
  },
  togetherTitle: {
    fontWeight: 500,
    margin: '5px 0px 5px 2px',
  },
  listItem: {
    cursor: "pointer",
    '&:hover':{
      backgroundColor: '#CECECE',
    }
  },
}));

const AddTempleteDialog = ({termList, versionId,versionInfo,rootVersionId, handlePhrase}) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [text, setText] = useState('')

  const [template, setTemplate] = React.useState(null);
  const[filteredList, setFilteredList] = useState(null);
  const snackbar = useSnackbar();

  const handleChange = (val) => {
    setTemplate(val)
  };


  useEffect(() =>{

  },[])

  const handleClose = () => {
    setOpen(false);
  };


  const handleCreateNewTemplate = async () =>{

    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.Loading" defaultMessage="Loading..." />
    );
    if(template) {

      const init = await initTermDb(
          {
            name: template.identifier,
            description: "",
          });


      const res = await addTermSpec(init?.metadataTemplateVersionId, versionId, template.identifier, text, versionInfo?.info?.locale)

      if (res) {
        // handlePhrase(rootVersionId)
        handlePhrase(res.info.versionId)
      }
      handleClose()
    }
    snackbar.closeSnackbar(generatingSnackbar)
  }

//  label:defaultLabel


  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapper}>
      <div className={cl.wrapperTitle}>
        <CloseIcon onClick={handleClose} />
      </div>
      <div className={cl.wrapperList}>
        <div className={cl.wrapperTitle}>
          <FormattedMessage id="CLM.AddTemplateDialog.title" defaultMessage="Add new template"/>
        </div>
        {termList?.length>0 &&
        <Autocomplete
            // key={filteredPunkts}
            className={cl.autoComplete}
            id="size-small-standard-multi_1"
            size="small"
            value={template}
            clearIcon={<DeleteSharp/>}
            options={ termList}
            getOptionLabel={(option) => option.label.defaultLabel }
            onChange={(event, value) => handleChange(value)}
            renderInput={(params) =>
                <TextField {...params}
                           label={<span className={cl.label}>
                               <FormattedMessage id="CLM.AddTemplateDialog.label" defaultMessage="Templates"/>
                           </span>}
                           variant="outlined"
                           size='small'/>}
        />
        }
        <div>
          <TextField value={text} onChange={(e) => setText(e.target.value)}/>
        </div>
      </div>

      <div>
        {termList?.length > 0 &&
        <div style={{margin: "15px 0 0"}}>
          <AddButtonComp Icon={AddIcon} handle={handleCreateNewTemplate}
                         label={<FormattedMessage id="CLM.AddTemplateDialog.AddTemplate"
                                                  defaultMessage="Add template"/>}/>
        </div>
        }
      </div>

    </div>
  </Dialog>);
};

export default AddTempleteDialog;