import React, {useContext, useEffect, useState} from 'react';
import {Button} from "@mui/material";
import {INSERT_ELEMENT_ACTION, NEW_IMAGE_ACTION, NEW_PART_ACTION} from "../../utils/ELEMENT_TILE_CONTS";
import {SparePartContext} from "../../store/SparePartContext";
import {useDispatch} from "react-redux";
import {createImage, getDoubles, loadNewImageReference, uploadImage} from "../../../PhrasePlugin/API";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import {DoubleDialog} from "../../../PhrasePlugin/DoubleDialog";
import {createNewImage} from "../../../TranslationJobPlugin/tjReducers";
import {v4 as uuidv4} from "uuid";
import OpenDialog from "../../OpenPartDialog";
import BrowseDialog from "../../../BlockPlugin/components/dialog/browse/BrowseDialog";
import {loadAsItem} from "../../API";

const NewElementActions = () => {
    const dispatch = useDispatch()
    const {values, setValues} = useContext(SparePartContext)
    const [dialog, setDialog] = useState({
        open: false,
        type: null
    })
    const [image, setImage] = useState()

    const saveNewImage = async ({name, imagePreview}) => {
        // const initImage = await initImgApi(
        //     {
        //         locale: mylocale,
        //         parentVersionId: values.partVersionId
        //     });

        // console.log(initImage)

        // const createImgItem = await createImgApi(values.partVersionId, mylocale, {
        //     imageName: name,
        //     imagePreview: imagePreview
        // });

        let base64Pattern = /^data:(image\/[a-zA-Z0-9+.-]+);base64,(.+)$/;
        let mimeType = imagePreview.match(base64Pattern)[1];

        let value = null
        let right = null
        const dimensions = {}
        const uuid = uuidv4();

        fetch(imagePreview)
            .then(res => res.blob())
            .then(blob => {
                const fd = new FormData()
                const file = new File([blob], "File name",{ type: mimeType })
                fd.append("file", file, name);

                uploadImage(fd)
                    .then((res) => {
                        // snackbar.closeSnackbar(generatingSnackbar);
                        value = res.left;
                        // showSnackbarChangesSaved(snackbar, <FormattedMessage id="CLM.PrintManager.Snackbar.UploadFiled" defaultMessage="uploaded succesefully"/>);
                        right = value
                        return getDoubles(res.right);
                    })
                    .then((similar) => {
                        if (similar.length > 0) {
                            renderInExceptionRoot(DoubleDialog, {
                                dimensions: dimensions,
                                list: similar.map(el => ({...el, info: el})),
                                onConfirm: async (info) => {
                                    const imgReference = await loadNewImageReference(info.info.versionId);
                                    setValues({...values, partImages: [...values.partImages, {...imgReference, elementInfo: imgReference.image.info}]})
                                },
                                onCancel: async () => {
                                    const newImage = await createImage(value, file.name, dimensions);
                                    const newRef = await loadNewImageReference(newImage.info.versionId);
                                    const img = { uuid, info: newRef };
                                    dispatch(createNewImage(img));
                                }
                            });
                        } else {
                            return createImage(value, file.name, dimensions);
                        }
                    })
                    .then((info) => {
                        if (info) {
                            return loadNewImageReference(info.versionId || info.info.versionId);
                        }
                    })
                    .then((info) => {
                        if (info) {
                            setValues({...values, partImages: [...values.partImages, {...info, elementInfo: info.image.info}]})
                        }
                    })
            })
    }

    useEffect(() => {
        if(image) {
            saveNewImage({name: image.name, imagePreview: image.src})
        }
    }, [image])

    const handleSelectPart = async (versionId) => {
        // loadPart(versionId).then(part => {
        //     setValues(prev => ({...prev, parts: [...prev.parts, part]}))
        // })

        loadAsItem(versionId).then(part => {
            setValues(prev => ({...prev, parts: [...prev.parts, {...part, position: prev.parts.length + 1}]}))
        })
    }

    const handleBrowse = () => {
        renderInExceptionRoot(BrowseDialog, {
            filteredArr: ['part'],
            onSelect: (val) => {
                handleSelectPart(val[0]?.versionId)
            }
        });
    };

    return (
        <>
            <Button key={NEW_IMAGE_ACTION.id} startIcon={NEW_IMAGE_ACTION.icon} onClick={() => setDialog({
                open: true, type: "newImage"
            })}>
                {NEW_IMAGE_ACTION.name}
            </Button>
            <Button key={NEW_PART_ACTION.id} startIcon={NEW_PART_ACTION.icon} onClick={() => setDialog({
                open: true, type: "newPart"
            })}>
                {NEW_PART_ACTION.name}
            </Button>
            <Button key={INSERT_ELEMENT_ACTION.id} startIcon={INSERT_ELEMENT_ACTION.icon} onClick={handleBrowse}>
                {INSERT_ELEMENT_ACTION.name}
            </Button>

            {dialog.open && (
                <OpenDialog
                    // setImages={image => setValues({...values, partImages: [...values.partImages, image]})}
                    setImages={setImage}
                    dialog={dialog}
                    setDialog={setDialog}
                />
            )}
        </>
    );
};

export default NewElementActions;