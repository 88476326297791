import {createInProgressSnack, createOpenReportSnack} from '@zert-packages/components/common/snackBar';
import { FormattedMessage } from 'react-intl';
import { showSnackbarError } from '@zert-packages/components/Snackbars';
import React from 'react';
import { checkReportStatus } from '@zert-packages/actions/checklistActions';
import { toLocaleString } from '@zert-packages/plugins/ExplorerPlugin/SearchConditions/helpers/getLocaleByLanguageCode';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { v4 as uuidv4 } from 'uuid';
import {exportSoftwareText, exportTermDB, loadSoftwareText} from './API';
import SofwtareTextsDialog from './SofwtareTextsDialog';
import {handleQuery} from "@zert-packages/actions/api";

export const generateTermDBExport =
  (versionId) =>
  (dispatch, getState, { snackbar }) => {


    const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
    const generatingSnackbar = createInProgressSnack(
      snackbar,
      <FormattedMessage id="TermDB.GeneratingLabelCommon" defaultMessage="...generating report" />
    );
    const newReport = {
      name: <FormattedMessage id="TermDB.GeneratingLabelCommon" defaultMessage="...generating report" />,
      reportUUID: uuidv4()
    };

    renderInExceptionRoot(SofwtareTextsDialog, {
      versionId,
      onConfirm: async (selectedCriteria, format) => {
        exportTermDB(
          versionId,
          selectedCriteria.map((item) => toLocaleString(item)),
          newReport.reportUUID,
          format
        )
          .then((callback) => {
            checkReportStatus(callback, snackbar, generatingSnackbar, newReport, format);
          })
          .catch((error) => {
            showSnackbarError(snackbar, error);
          });
      },
        onCancel: ()=> console.log("kk")
    });
  };


export const generateSoftwareText =  (versionId) =>
    async  (dispatch, getState, { snackbar }) =>{

        const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
        const type = await loadSoftwareText(versionId, locale);

        const generatingSnackbar = createInProgressSnack(
            snackbar,
            <FormattedMessage id="TermDB.GeneratingLabelCommon" defaultMessage="...generating report" />
        );
        const newReport = {
            // name: <FormattedMessage id="TermDB.GeneratingLabelCommon" defaultMessage="...generating report" />,
            name: 'report',
            reportUUID: uuidv4()
        };


        renderInExceptionRoot(SofwtareTextsDialog, {
            onConfirm: async (selectedCriteria, format, file)=>{

                const locales = selectedCriteria.map(el => `${el.value}`)
                const fd = new FormData();
                if(locales){
                    fd.append("selectedLocales", locales)
                }
                //aptFile
                if(file){
                    fd.append("aptFile", file[0])
                }
                const res  = await exportSoftwareText(versionId, locale,fd )
                //callbackId

                updateProgressStatus(res, snackbar, generatingSnackbar, newReport, format)
            },
            onCancel: ()=> {
                snackbar.closeSnackbar(generatingSnackbar);
            },
            type: type
        })
    }

//
// /zert/report-react/updateProgressStatus/{callbackId}/{cancel}
// Parameters


export const updateProgressStatus = (report, snackbar, generatingSnackbar, generatedReport, mimeType) => {
    // handleQuery(`/softwareText/updateProgressStatus/${report.callbackId}/false`)
    handleQuery(`/report-react/updateProgressStatus/${report.callbackId}/false`)
        .then((newReport) => {

            if (newReport !== null && newReport.active) {
                updateProgressStatus(newReport, snackbar, generatingSnackbar, generatedReport, mimeType);
            } else {
                generatedReport.reportUUID = report.callbackId;
                snackbar.closeSnackbar(generatingSnackbar);
                createOpenReportSnack(snackbar, generatedReport, mimeType);
            }
        })
        .catch((error) => {
            snackbar.closeSnackbar(generatingSnackbar);
            showSnackbarError(snackbar, error);
        });
};


//
// /zert/softwareText/updateProgressStatus/{callbackId}/{cancel}
// Parameters
