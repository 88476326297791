import React from 'react';

import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import RiskAnalisysTile from '../tiles/RiskAnalisysTile';
import ProjectPlanningTile from '../tiles/ProjectPlanningTile';
import ChecklistTile from '../tiles/ChecklistTile';
import TaskTile from '../tiles/TaskTile';
import DocumentTile from '../tiles/DocumentTile';
import PaperCopy from '../shared/PaperCopy';
import FolderTile from '../tiles/FolderTile';
import SafetyInspectionTile from '../tiles/SafetyInspectionTile';
// import ProjectTile from '../tiles/ProjectTile';
import AbstractTile from '../tiles/AbstractTile';
import RiskRowTile from '../tiles/RiskRowTile';
import PEDTile from '../tiles/PEDTile';
import ProjectDocumentTile from '../tiles/ProjectDocumentTile';
import WithCardFunc from '../shared/WithCardFunc';

export const getElementCardByMimeType = (props) => {
  return <WithCardFunc {...props} showInTileOverview />;
};

const getElementByMimeType = (props) => {
  const plugin = getPluginByMimeType(props.element.mimeType);
  if (plugin && plugin.tile) {
    const Tile = plugin.tile;
    return <Tile {...props} showBubbles />;
  }
  switch (props.element.mimeType) {
    case 'application/zert-risk-analysis':
      return <RiskAnalisysTile {...props} showInTileOverview />;
    case 'application/zert-ped-object':
      return <PEDTile {...props} />;
    case 'application/zert-risk-row':
      return <RiskRowTile {...props} showInTileOverview showInlineOverview />;
    case 'application/zert-projectplanning':
      return <ProjectPlanningTile {...props} />;
    case 'application/zert-checklist':
      return <ChecklistTile {...props} showBubbles />;
    case 'application/zert-measure':
      return <TaskTile {...props} showInTileOverview />;
    case 'application/zert-deviation':
      return <TaskTile {...props} showInTileOverview />;
    case 'application/zert-document':
      return <DocumentTile {...props} />;
    case 'application/zert-paper':
      return <PaperCopy {...props} />;
    case 'application/zert-folder':
      return <FolderTile {...props} />;
    case 'application/zert-search-folder':
      return <FolderTile {...props} />;
    case 'application/zert-safety-inspection':
      return <SafetyInspectionTile {...props} showInlineOverview showInTileOverview />;
    /*    case 'application/zert-project':
      return <ProjectTile {...props} showBubbles />; */
    case 'application/zert-project-document':
      return <ProjectDocumentTile {...props} showInlineOverview />;
    default:
      return <AbstractTile {...props} />;
  }
};

export default getElementByMimeType;
