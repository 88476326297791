import Portal, { isEqual } from '@zert-packages/react/interfaces/Portals';
import MyNordenTheme from '@zert-packages/react/themes/MyNordenTheme';
import ZertTheme from '@zert-packages/react/themes/ZertTheme';
import ModigTheme from '@zert-packages/react/themes/ModigTheme';
import LogMaxTheme from "./LogMaxTheme";

const getPortalTheme = (portal?) => {
  if (portal) {
    if (isEqual(portal, Portal.MyNorden)) {
      return MyNordenTheme;
    }
    if (isEqual(portal, Portal.Modig)) {
      return ModigTheme;
    }
    if (isEqual(portal, Portal.LogMax)) {
      return LogMaxTheme;
    }
    if (isEqual(portal, Portal.RM) || isEqual(portal, Portal.CLM)) {
      return ZertTheme;
    }
  }

  return ZertTheme;
};

export const PortalTheme = (portal?) => getPortalTheme(portal);
