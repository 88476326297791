import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { verifyPassword, verifyPasswordPost } from '@zert-packages/actions/measureActions';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';

const MIN_PASSWORD_LENGTH = 4;

const wrongPasswordFormatMessage = (
  <FormattedMessage id="VerifyPassword.WrongPasswordText" defaultMessage="Wrong password" />
);

function PasswordVerifierDialog({ unmountComponent, onConfirm, onCancel, title }) {
  const [password, setPassword] = useState('');
  const [verifying, setVerifying] = useState(false);
  const [error, setError] = useState(null);

  const onChangePasswordValue = (e) => {
    if (error) setError(null);
    setPassword(e.target.value);
  };

  const onKeyUp = (e) => {
    if (e.key === 'Enter') handleConfirm();
  };

  const handleConfirm = () => {
    if (password.length < MIN_PASSWORD_LENGTH) return;
    setVerifying(true);

      verifyPasswordPost(password)
      .then((allowed) => {
        if (!allowed) return setError(wrongPasswordFormatMessage);
        onConfirm();
        unmountComponent();
      })
      .then(() => setVerifying(false))
      .catch((e) => {
        throw new Error(e);
      });
  };

  function PasswordInput() {
    return (
      <TextField
        autoFocus
        fullWidth
        error={error}
        value={password}
        onChange={onChangePasswordValue}
        type="password"
        size="small"
        variant="outlined"
        helperText={error}
        onKeyUp={onKeyUp}
        label={<FormattedMessage id="VerifyPassword.TextField" defaultMessage="Password" />}
      />
    );
  }

  const handleOnCancel = () => {
    unmountComponent();
    if (onCancel) onCancel();
  };

  return (
    <Dialog maxWidth="sm" fullWidth open onClose={handleOnCancel}>
      <DialogTitle>
        {title || <FormattedMessage id="PasswordVerifier.DialogTitle" defaultMessage="Verify your password" />}
      </DialogTitle>

      <DialogContent>
        {verifying && <Loader loaderAction={LoaderAction.Verifying} />}

        {!verifying && <PasswordInput />}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleConfirm} disabled={password.length < MIN_PASSWORD_LENGTH} color="primary">
          <FormattedMessage id="VerifyPassword.Confirm" defaultMessage="Confirm" />
        </Button>

        <Button onClick={handleOnCancel} color="primary" variant="outlined">
          <FormattedMessage id="VerifyPassword.Cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PasswordVerifierDialog;
