import { CompositeDecorator } from "draft-js";
import ErrorSpelling from "../components/ErrorSpelling";
import AliasEditor from "../components/AliasEditor";


function findWithRegex1(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr = regex.exec(text);
  while (matchArr !== null) {
    const start = matchArr.index;
    callback(start, start + matchArr[0].length);
    matchArr = regex.exec(text);
  }
}





function hintStrategy(contentBlock, callback) {
  findWithRegex(/test/g, contentBlock, callback);
}


function errorSpellingStrategy(contentBlock, callback) {
  findWithRegex(/22/g, contentBlock, callback);
}




export const compositeDecorator = new CompositeDecorator([
  // {
  //   strategy: errorSpellingStrategy,
  //   component: AliasEditor,
  // },
]);



function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr = regex.exec(text);
  while (matchArr !== null) {
    const start = matchArr.index;
    callback(start, start + matchArr[0].length);
    matchArr = regex.exec(text);
  }
}




export const updateDecorator = (words) => {
  if (!words) {
    return;
  }
  const decorators = words.map((newWord) => {
   if(newWord.aliasDecorator){
     return {
       strategy: (contentBlock, callback) => {
         // const regex = new RegExp(`(?:^|\\b)${escapeRegExp(newWord.word)}(?:$|\\b)`, 'g');
         const regex = new RegExp(`(?:^|\\s)(${escapeRegExp( newWord.word)})(?=\\s|$)`, "g");
         findWithRegex(regex, contentBlock, callback);
       },
       component  : AliasEditor,
     }
   }
   return  {
      strategy: (contentBlock, callback) => {
        // const regex = new RegExp(`(?:^|\\b)${escapeRegExp(newWord.word)}(?:$|\\b)`, 'g');
        const regex = new RegExp(`(?:^|\\s)(${escapeRegExp(newWord.word)})(?=\\s|$)`, "g");
        findWithRegex(regex, contentBlock, callback);
      },
        component  : ErrorSpelling,
    }
  });

  return decorators;
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}