import {
  handlePostResultAsString,
  handlePostTextAsParam, handlePostToApi,
  handleQuery,
  handleQueryFileAsResultHeaders, handleQueryFileAsResultWithHeaders
} from "@zert-packages/actions/api";
import axios from "axios";
import axiosInstance from "@zert-packages/utils/axios";

export const API_ROOT_CALLS = process.env.REACT_APP_API_ROOT_CALLS;




export const  addNewTranslations =  async(versionId,locale, data) => {
  const res = await handlePostResultAsString(`/core/translation/${versionId}/${locale}`, data)
  return res
}

export const getActiveLocalesWithSort = () => {
  try {
    return handleQuery(`/core/getActiveLocalesWithSort?sort=true`);
  } catch (e) {
    return false;
  }
};

export const getBlockApi = (versionId, locale) => {
    ///${locale}
    return handleQuery(`/block/load/${versionId}/${locale}`);
};

export const getTranslations = (versionId) => {
  try {
    return handleQuery(`/core/getTranslations/${versionId}`);
  } catch (e) {
    return false;
  }
};

export const getTranslationPrhase = (versionId, locale) => {
  try {
    return handleQuery(`/phrase/loadFormatted/${versionId}/${locale}`);
  } catch (e) {
    return false;
  }
};


export const getShortImageApi = async (versionId) => {
  const token = localStorage.getItem("accessToken");
  try {
    return await axios.get(API_ROOT_CALLS + `/image/getImagePreviewById/${versionId}?jwtToken=${token}`, {
      responseType: "arraybuffer"
    });
  } catch (e) {
    return false;
  }
};


export const getImageContent = async (versionId) => {
  return handleQueryFileAsResultHeaders(`/image/getImageContentById/${versionId}`);
};


export const  getLoadSimilarApi =  async(excludeElementId = -1, locale, text) => {
  const res = await handlePostTextAsParam(`/phrase/loadSimilarNames/${excludeElementId}/${locale}`, text)
  return res
}
//sv
export const getLoadContext = async (excludeElementId = -1, locale, text) => {
  const res = await handlePostTextAsParam(`/phrase/loadSimilarContext/${excludeElementId}/${locale}`, text)
  return res
}

export const getCountChildApi= (versionId) => {
  return handleQuery(`/block/${versionId}/countChild`);
}

// /checkout
const tryCatchAsParam = async (query, data) => {
  try {
   return await axiosInstance
      .put(API_ROOT_CALLS + query, data, {
        headers: {
          'Content-Length': 0,
          'Content-Type': 'text/plain'
        }
      })
  } catch (e) {
    return console.log(e.message, 'Api ===============================')
  }

}


export const checkinApi = (versionId) => {
    return tryCatchAsParam(`/core/checkin/${versionId}`, {})
    // return handlePostTextAsParam(`/core/checkin/${versionId}`, {})
}


export const checkoutApi = (versionId) => {
    return tryCatchAsParam(`/core/checkout/${versionId}`, {})
    // return handlePostTextAsParam(`/core/checkout/${versionId}`, {})
}

export const getParentElementsCount =  (versionId) => {
  return  handleQuery(`/common/getParentElementsCount/${versionId}`);
}