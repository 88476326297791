const styleMap = {
  "UNDERLINED": {
    textDecoration: "underline"
  },
  "SUPERSCRIPT": {
    verticalAlign: "super",
    fontSize: "smaller"
  },
  "SUBSCRIPT": {
    verticalAlign: "sub",
    fontSize: "smaller"
  },
  "FLOAT": {
    background: "green",
    float: "left"
  }
};


export const createStyleMap = (list) => {
  // const findActiveStyle = list.reduce((acu, cur) => {
  //     const typeSettings = cur.typeSettings
  //     // if(cur.identifier === 'bold' || cur.identifier === 'italic' || findInlineStyleActive(typeSettings)){
  //     //     return [...acu]
  //     // }
  //     return [...acu, cur]
  // }, [])

  return adobeStyle(list);
};

const findInlineStyleActive = (style) => {
  return !(style.bgcolor || style.fontColor || style.fontStyle.length === 0);
};

const adobeStyle = (style) => {
  return style.reduce((acu, cur) => {
    return { ...styleMap, ...acu, [cur.identifier.toUpperCase()]: findInlineStyles(cur.typeSettings) };
  }, {});
};


const findInlineStyles = (style) => {
  const inlineStyles = {};
  if (style.bgcolor) {
    inlineStyles.background = `rgb(${style.bgcolor.red},${style.bgcolor.green},${style.bgcolor.blue},${style.bgcolor.alpha})`;
  }
  if (style.fontColor) {
    inlineStyles.color = `rgb(${style.fontColor.red},${style.fontColor.green},${style.fontColor.blue},${style.fontColor.alpha})`;
  }
  if (style.fontStyle.length > 0) {
    style.fontStyle.forEach(el => {
      if (el === "STRIKETHROUGH") {
        return inlineStyles.textDecoration = "line-through";
      }
      if (el === "BOLD") {
        return inlineStyles.fontWeight = "bold";
      }
      if (el === "ITALIC") {
        return inlineStyles.fontStyle = "italic";
      }
      if (el === "UNDERLINED") {
        return inlineStyles.textDecoration = "underline";
      }

      if (el === "SUB") {
        return inlineStyles.verticalalign = "sub";
      }
      if (el === "SUPERSCRIPT") {
        return inlineStyles.verticalalign = "super";
      }
    });
  }
  if (style.fontFamily) {
    if (style.fontFamily === "SANS") {
      inlineStyles.fontFamily = "Arial Unicode MS";
    }
    if (style.fontFamily === "INHERIT") {

    }
    if (style.fontFamily === "SERIF") {
      inlineStyles.fontFamily = "Times New Roman";
    }
    if (style.fontFamily === "MONO") {
      inlineStyles.fontFamily = "Curier New";
    }
  }
  if (style.fontSize) {
    inlineStyles.fontSize = style.fontSize.toLowerCase().replace("_", "-");
  }

  return inlineStyles;
};