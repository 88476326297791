import React, { Fragment } from 'react';
import { Button, CircularProgress, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';
import { addNewNotification, API_ROOT_WAR } from '@zert-packages/actions/api';

export function CloseButton({ onClick, htmlColor = 'white' }) {
  return (
    <IconButton size="small" onClick={onClick}>
      <CloseIcon htmlColor={htmlColor} />
    </IconButton>
  );
}

export const showSnackbarInfo = (snackbar, message) => {
  const snackbarKey = new Date().valueOf();
  snackbar.enqueueSnackbar(message, {
    key: snackbarKey,
    variant: 'info',
    persist: false,
    action: (
      <CloseButton
        onClick={() => {
          snackbar.closeSnackbar(snackbarKey);
        }}
      />
    )
  });
  return snackbarKey;
};

export const showSnackbarPushNotification = (snackbar, history, dispatch, message, link, notificationId, taskName) => {
  const onClickOpenElement = () => {
    // console.log(reportUUID);
    if (link) {
      history.push(API_ROOT_WAR + link.replace('/app', ''));
    } else {
      history.push(`${API_ROOT_WAR}/notification/${notificationId``}`);
    }

    snackbar.closeSnackbar(snackbarKey);
  };

  const onClickOpenNotification = () => {
    history.push(`${API_ROOT_WAR}/notification/${notificationId}`);
    snackbar.closeSnackbar(snackbarKey);
  };

  const snackbarKey = new Date().valueOf();
  dispatch(addNewNotification(notificationId));
  snackbar.enqueueSnackbar(message, {
    key: snackbarKey,
    variant: 'info',
    persist: false,
    action: (key) => (
      <>
        <Button
          size="small"
          variant="outlined"
          style={{
            color: 'white',
            border: '1px solid white'
          }}
          onClick={onClickOpenElement}
        >
          {taskName}
          {/* <FormattedMessage
                            id="SnackBar.OpenButton.Element"
                            defaultMessage=" {taskName}" values={{taskName : taskName}}
                        /> */}
        </Button>
        <Button
          size="small"
          variant="outlined"
          style={{
            marginLeft: '5px',
            color: 'white',
            border: '1px solid white'
          }}
          onClick={onClickOpenNotification}
        >
          Notification
          {/* <FormattedMessage
                            id="SnackBar.OpenButton.Element.showNotification"
                            defaultMessage="Notification"
                        /> */}
        </Button>
        <CloseButton
          onClick={() => {
            snackbar.closeSnackbar(snackbarKey);
          }}
        />
      </>
    )
  });
  return snackbarKey;
};

export const showSnackbarError = (snackbar, message) => {
  console.log(message);
  const snackbarKey = new Date().valueOf();
  snackbar.enqueueSnackbar(message && message.message ? message.message : message, {
    key: snackbarKey,
    variant: 'error',
    persist: false,
    action: (
      <CloseButton
        onClick={() => {
          snackbar.closeSnackbar(snackbarKey);
        }}
      />
    )
  });
  return snackbarKey;
};

export const showSnackbarChangesSaved = (snackbar, message) => {
  const snackbarKey = new Date().valueOf();
  snackbar.enqueueSnackbar(
    message || (
      <FormattedMessage
        id="components.snackbars.showSnackbarChangesSaved"
        defaultMessage="Your changes have been saved!"
      />
    ),
    {
      key: snackbarKey,
      autoHideDuration: 1500,
      variant: 'info',
      persist: false,
      action: (
        <CloseButton
          onClick={() => {
            snackbar.closeSnackbar(snackbarKey);
          }}
        />
      )
    }
  );
  return snackbarKey;
};

const WhiteCircularProgress = withStyles({
  root: {
    marginRight: 10
  },
  colorPrimary: {
    color: 'white'
  }
})(CircularProgress);

export const showSnackbarLoaderWithMessage = (snackbar, message) =>
  snackbar.enqueueSnackbar(
    message || <FormattedMessage id="HoverWithLoader.SnackbarMessage" defaultMessage="Loading..." />,
    {
      variant: 'info',
      persist: true,
      action: (key) => {
        return <WhiteCircularProgress size={20} />;
      }
    }
  );
