import React from 'react';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function MenuToggleButton({ onClick, IconButtonProps, IconProps }) {
  return (
    <IconButton onClick={onClick} {...IconButtonProps} size="small"
                style={{height : "27px", width: "27px", zIndex: 10000 }}>
      <MenuIcon {...IconProps} />
    </IconButton>
  );
}

export default MenuToggleButton;
