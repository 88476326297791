import makeStyles from '@mui/styles/makeStyles';
import Stepper from '@mui/material/Stepper';
import React, { createContext, useContext } from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { FormattedMessage } from 'react-intl';
import MobileStepper from '@mui/material/MobileStepper';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { DialogActions, useTheme } from '@mui/material';

export const PreviewWizardContext = createContext(null);

export function PreviewWizardProvider({ children }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [extended, setExtended] = React.useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const values = {
    activeStep,
    handleNext,
    handleBack,
    handleReset,
    extended,
    setExtended
  };
  return <PreviewWizardContext.Provider value={values}>{children}</PreviewWizardContext.Provider>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '300px'
  },
  rootMobileStepper: {
    maxWidth: 400,
    flexGrow: 1
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  }
}));

function getSteps() {
  return [
    <FormattedMessage id="PreviewWizard.ChooseLayoutStep" defaultMessage="Preview setting" />,
    <FormattedMessage id="PreviewWizard.ChooseLanguage" defaultMessage="Language" />,
    <FormattedMessage id="PreviewWizard.ChooseCompare" defaultMessage="Compare" />,
    <FormattedMessage id="PreviewWizard.ChooseMetadata" defaultMessage="Add Metadata As Comments" />
    /*        <FormattedMessage id = "PreviewWizard.ChooseTime"
                          defaultMessage = 'Finalize Preview'/>,
        <FormattedMessage id = "PreviewWizard.PreviewServerSettings"
                          defaultMessage = 'Preview Server settings'/> */
  ];
}

export function getStepContent(step) {
  switch (step) {
    case 0:
      return (
        <FormattedMessage
          id="PreviewWizardDialog.Steps.LayoutAndLanguage.Description"
          defaultMessage="Select the language you want to preview and the layout to use."
        />
      );
    case 1:
      return (
        <FormattedMessage
          id="PreviewWizardDialog.Steps.Language.Description"
          defaultMessage="Select the languages you want to include in the preview. The fallback language will be used if a translation into the target language is missing."
        />
      );
    case 2:
      return (
        <FormattedMessage
          id="PreviewWizardDialog.Steps.Compare.Description"
          defaultMessage="You can compare the selected version with another version of the element. This will give you change marks in the resulting publication."
        />
      );
    case 3:
      return (
        <FormattedMessage
          id="PreviewWizardDialog.Steps.Metadata.Description"
          defaultMessage="Select metadata as comments inside pdf-output"
        />
      );
    /*        case 4:
            return <FormattedMessage id = "PreviewWizardDialog.Steps.DateGeneration.Description"
                                     defaultMessage = 'Choose when the generation will be performed'/>;
        case 5:
            return <FormattedMessage id = "PreviewWizardDialog.Steps.Notification.Description"
                                     defaultMessage = 'Choose how notification should be sent'/>; */

    default:
      return 'Unknown step';
  }
}
export function PreviewMobileStepper({ handleConfirm }) {
  const classes = useStyles();
  const theme = useTheme();
  const { activeStep, handleNext, handleBack } = useContext(PreviewWizardContext);

  const handleFinish = (event) => {
    if (activeStep < getSteps().length - 1) {
      handleNext(event);
    } else {
      handleConfirm(false);
    }
  };
  return (
    <MobileStepper
      variant="dots"
      steps={getSteps().length}
      position="static"
      activeStep={activeStep}
      className={classes.rootMobileStepper}
      nextButton={
        <Button size="small" variant="outlined" onClick={handleFinish}>
          {activeStep === getSteps().length - 1 ? (
            <FormattedMessage id="PreviewWizardDialog.Steps.Preview.Finish" defaultMessage="Start preview" />
          ) : (
            <FormattedMessage id="PreviewWizardDialog.Steps.Preview.Next" defaultMessage="Next" />
          )}
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          <FormattedMessage id="PreviewWizardDialog.Steps.Preview.Back" defaultMessage="Back" />
        </Button>
      }
    />
  );
}

export default function VerticalLinearStepper() {
  const classes = useStyles();
  // const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const { activeStep, handleNext, handleBack } = useContext(PreviewWizardContext);

  /*  const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    }; */

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              {/* <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                    >
                                        {activeStep === steps.length - 1 ?

                                            <FormattedMessage id = "PreviewWizardDialog.Steps.Preview.Finish"
                                                              defaultMessage ='Finish'/> :
                                            <FormattedMessage id = "PreviewWizardDialog.Steps.Preview.Next"
                                                                                                             defaultMessage ='Next'/>}
                                    </Button>
                                </div>
                            </div> */}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {/* {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} className={classes.button}>
                        Reset
                    </Button>
                </Paper>
            )} */}
    </div>
  );
}
