import { useState } from 'react';
import { useSelector } from 'react-redux';
import hasPermit from './hasPermit';
import { getPluginByMimeType } from './getPluginByMimeType';
import isNotCheckedOutByMe from "./isNotCheckedOutByMe";

export default function useVersion() {
  const [isEverythingDisabled, setIsEverythingDisabled] = useState(false);
  const [canSaveVersion, setCanSaveVersion] = useState(true);
  const [canCreateVersion, setCanCreateVersion] = useState(true);
  const [canApprovalVersion, setCanApproveVersion] = useState(true);
  const { permits } = useSelector((state) => state);
  const myuser = useSelector((state) => state.myuser);

  function setVersionState(versionInfo) {
    const isLocked = myuser?.userName
      ? isNotCheckedOutByMe(versionInfo, myuser)
      : true;
    setCanCreateVersion(
      versionInfo.info.approvedAt &&
        !versionInfo.info.archivedAt &&
        versionInfo.info.versionId == versionInfo.versions[0].versionId
    );
    setCanSaveVersion(!versionInfo.info.approvedAt &&
      !versionInfo.info.archivedAt &&
      !isLocked
    );
    setCanApproveVersion(!versionInfo.info.approvedAt &&
      !versionInfo.info.archivedAt &&
      !isLocked
    );
    setIsEverythingDisabled(
      versionInfo.info.approvedAt ||
        versionInfo.info.archivedAt ||
        isLocked ||
        !hasPermit(getPluginByMimeType(versionInfo.info.mimeType), permits)
    );
  }

  return {
    setVersionState,
    isEverythingDisabled,
    canSaveVersion,
    canApprovalVersion,
    canCreateVersion
  };
}
