import React, { forwardRef, memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';

const BACKGROUND_COLOR = '#dadada';

const useStyles = makeStyles({
  '@keyframes opacityAnimation': {
    '0%': {
      opacity: 0.6
    },
    '50%': {
      opacity: 0.85
    },
    '100%': {
      opacity: 0.6
    }
  },
  root: {
    height: 86,
    marginBottom: 5,
    boxSizing: 'border-box',
    padding: '8px 10px',
    animation: `$opacityAnimation 2s infinite`,
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: 'auto 1fr auto'
  },
  bar: {
    background: BACKGROUND_COLOR,
    borderRadius: 3
  },
  barTitle: {
    width: '75%',
    height: 18,
    marginBottom: 8
  },
  barSubTitle: {
    width: '35%',
    height: 11,
    marginLeft: '-25px',
    marginBottom: 4
  },
  barSubTitle2: {
    width: '15%',
    height: 8,
    marginLeft: '-25px'
  },
  square: {
    width: '20px',
    height: '20px',
    borderRadius: 2,
    background: BACKGROUND_COLOR,
    marginRight: '0.5em'
  },
  responsibleBar: {
    width: 76,
    height: 10,
    marginLeft: 'auto'
  },
  actionsBar: {
    width: 15,
    height: 15,
    marginLeft: 'auto',
    marginBottom: 5
  },
  deadlineBar: {
    width: 76,
    height: 20,
    marginLeft: 'auto',
    marginBottom: 5
  },
  deadlineBarContainer: {
    height: '100%'
  }
});

const LoadingTile = memo(
  forwardRef((props, ref) => {
    const cl = useStyles();
    return (
      <div ref={ref} className={cl.root}>
        <div className={cl.square} />
        <div>
          <div className={cn(cl.bar, cl.barTitle)} />
          <div className={cn(cl.bar, cl.barSubTitle)} />
          <div className={cn(cl.bar, cl.barSubTitle2)} />
        </div>
        <div className={cl.deadlineBarContainer}>
          <div className={cn(cl.bar, cl.actionsBar)} />
          <div className={cn(cl.bar, cl.deadlineBar)} />
          <div className={cn(cl.bar, cl.responsibleBar)} />
        </div>
      </div>
    );
  })
);

export default LoadingTile;
