import handleValueState from "@zert-packages/utils/handleValueState";
import { addItemId } from "./main";

export  const pasteMainParent = (el,items, setValues, setValueBlock, setSelectedRange) => {

  setValueBlock(pre => ({...pre, items: [...pre.items, el]}))

  setValues(pre => ({
    ...pre,
    treeItem: el,
    pasteMainParent: null,
    stepperKeyIndex: pre.stepperKeyIndex + 1,
    stepperKey: [...pre.stepperKey, el]
  }))

  handleValueState(false, false, setSelectedRange, {
    parentSelectedLvl: items.length + 1
  })
}

export const copyMainParent = (el,items, setValues) => {
  const info = el.info
  const res = addItemId( {...el, items, elementInfo: info, identifier: info.id})
  return  handleValueState(res, 'copyMainParent', setValues)
}