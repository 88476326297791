import React from 'react';
import {Button} from "@mui/material";
import {
    CUT_ACTION,
    ELEMENT_ACTIONS,
    PASTE_ACTION
} from "@zert-packages/components/ElementTile/shared/ELEMENT_TILE_CONTS";

const CopyingActions = () => {
    return (
        <>
            <Button key={CUT_ACTION.id} startIcon={CUT_ACTION.icon}>
                {CUT_ACTION.name}
            </Button>
            <Button key={ELEMENT_ACTIONS[0].id} startIcon={ELEMENT_ACTIONS[0].icon}>
                {ELEMENT_ACTIONS[0].name}
            </Button>
            <Button key={PASTE_ACTION.id} startIcon={PASTE_ACTION.icon}>
                {PASTE_ACTION.name}
            </Button>
        </>
    );
};

export default CopyingActions;