import React from 'react'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    listItem: {
        display: 'flex',
        gap: 20,
        padding: '8px 20px',
        paddingLeft: 25,
        paddingBottom: 0,
        color: 'white',
        cursor: 'pointer',
    },
    iconList: {
        height: '18px',
        cursor: 'pointer',
    }
}))

const ListItem = ({icon, component, onClick = ()=>{}, el}) => {
    const cl = useStyles();

    const handleList = () => {
        if(el){
            onClick(el.mimeType)
        }
    }
    return(<div className={cl.listItem} onClick={handleList}>
            <span className={cl.iconList}>
                {icon}
            </span>
        {component}
    </div>)
}

export default ListItem