import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import ItemDialog from '../dialogs/ItemDialog';
import TextChanger from '../dialogs/TextChanger';

export const formStyle = (theme) =>
  createStyles({
    divRoot: {
      display: 'flex'
      /*  height:'25rem',
          border:'1px solid lightgrey',
          padding:4 */
    },
    divButtons: {
      width: '5%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    divContentContainer: {
      border: '1px solid lightgrey',
      width: '95%',
      height: '20rem'
    },
    divContentContainerNoBorder: {
      width: '95%',
      height: '100%'
    },
    listStyle: {
      '&.MuiTypography-body1': {
        fontSize: '1em'
      }
    }
  });
const useStyles = makeStyles(formStyle);

const initState = {
  chosenItem: null,
  languageDialog: false,
  itemDialog: false,
  itemMethod: 'ADD'
};

function ChoiceView({ data, setData }) {
  const classes = useStyles();
  const [choiceState, setChoiceState] = useState(initState);
  const [details, setDetails] = useState(data);

  const choseItem = (el) => {
    if (choiceState.chosenItem === el) {
      setChoiceState((prevState) => ({
        ...prevState,
        chosenItem: null
      }));
    } else {
      setChoiceState((prevState) => ({
        ...prevState,
        chosenItem: el
      }));
    }
  };

  const deleteItem = () => {
    setDetails((prevState) => ({
      ...prevState,
      items: prevState.items.filter((el, idx) => el !== choiceState.chosenItem)
    }));
    setChoiceState((prevState) => ({
      ...prevState,
      chosenItem: null
    }));
  };

  const openCloseItemDialog = (isEdit) => {
    setChoiceState((prevState) => ({
      ...prevState,
      itemDialog: !prevState.itemDialog,
      itemMethod: isEdit ? 'Edit' : 'Add',
      chosenItem: isEdit ? prevState.chosenItem : null
    }));
  };

  useEffect(() => {
    setData(details);
  }, [details]);

  useEffect(() => {
    setDetails(data);
  }, [data]);

  const onItemSaveHandler = (item) => {
    if (choiceState.itemMethod === 'Add') {
      setDetails((prevState) => ({
        ...prevState,
        items: [...prevState.items, item]
      }));
    } else if (choiceState.itemMethod === 'Edit') {
      setDetails((prevState) => ({
        ...prevState,
        items: details.items.map((el) => {
          if (el === choiceState.chosenItem) {
            return item;
          }
          return el;
        })
      }));
    }
  };

  return (
    <div>
      <TextChanger data={details} setData={setDetails} />
      <Typography align="center" variant="h6">
        Multi Select:
      </Typography>
      <Select
        labelId="demo-simple-select-label"
        fullWidth
        size="small"
        variant="outlined"
        id="demo-simple-select"
        value={details.allowMultiselect}
        onChange={(event) => {
          setDetails((prevState) => ({
            ...prevState,
            allowMultiselect: event.target.value
          }));
        }}
      >
        <MenuItem value>Yes</MenuItem>
        <MenuItem value={false}>No</MenuItem>
      </Select>
      <div>
        <Typography align="center" variant="h6">
          Items:
        </Typography>
        <div className={classes.divRoot}>
          <div className={classes.divContentContainer}>
            <List>
              {data.items &&
                data.items.map((el, idx) => {
                  return (
                    <ListItem
                      key={idx}
                      component="div"
                      disablePadding
                      style={{ height: '20px', textTransform: 'capitalize', width: '100%' }}
                    >
                      <ListItemText
                        disableTypography
                        style={
                          choiceState.chosenItem !== null && choiceState.chosenItem === el
                            ? { background: '#01A1C7' }
                            : {}
                        }
                        button
                        selected={choiceState.chosenItem !== null && choiceState.chosenItem === el}
                        onClick={(event) => choseItem(el)}
                        primary={
                          <Typography
                            style={
                              choiceState.chosenItem !== null && choiceState.chosenItem === el
                                ? {
                                    fontSize: '1em',
                                    color: '#ffffff'
                                  }
                                : {
                                    fontSize: '1em',
                                    color: 'black'
                                  }
                            }
                          >
                            {el.labelObject.translations[0]}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })}
            </List>
          </div>
          <div className={classes.divButtons}>
            <Tooltip title="add">
              <IconButton aria-label="add" size="large" color="primary" onClick={() => openCloseItemDialog(false)}>
                <AddIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="edit">
              <IconButton
                aria-label="edit"
                size="large"
                color="primary"
                disabled={choiceState.chosenItem === null}
                onClick={() => openCloseItemDialog(true)}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>

            <Tooltip title="delete">
              <IconButton
                aria-label="delete"
                size="large"
                color="primary"
                disabled={choiceState.chosenItem === null}
                onClick={deleteItem}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>

      {choiceState.itemDialog && (
        <ItemDialog
          onSave={onItemSaveHandler}
          handleClose={openCloseItemDialog}
          method={choiceState.itemMethod}
          data={choiceState.chosenItem}
        />
      )}
    </div>
  );
}

export default ChoiceView;
