import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import { approveVersionAsync, createVersion, getVersionInfo } from '@zert-packages/actions/coreReducers';
import { showSnackbarChangesSaved, showSnackbarError } from '@zert-packages/components/Snackbars';
import { useSnackbar } from 'notistack';
import HoverWithSnackbarLoaderDialog from '@zert-packages/components/common/dialogs/HoverWithSnackbarLoaderDialog';
import renderInExceptionRoot from './renderInExceptionRoot';
import hasPermit from './hasPermit';
import { getPluginByMimeType } from './getPluginByMimeType';
import isNotCheckedOutByMe from './isNotCheckedOutByMe'

const defaultCurrentVersionId = -1;
const defaultVersionInfo = null;
const defaultManager = {
  elementDisabled: true,
  canSaveVersion: false,
  canCreateNewVersion: false,
  canApproveVersion: false,
  createNewVersion: () => null,
  approveVersion: () => null,
  saveVersion: () => null,
  getApproveFunc: () => null
};
const defaultReturnState = [defaultManager, defaultVersionInfo];

const useVersionManager = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const snackbar = useSnackbar();
  const freezeScreen = () => renderInExceptionRoot(HoverWithSnackbarLoaderDialog);
  const versionInfo = useSelector((state) => state.versionInfo);
  const permits = useSelector((state) => state.permits);
  const myuser = useSelector(state => state.myuser);

  const [elementDisabled, setElementDisabled] = useState(false);

  const [canSaveVersion, setCanSaveVersion] = useState(true);
  const [canCreateNewVersion, setCanCreateNewVersion] = useState(true);
  const [canApproveVersion, setCanApproveVersion] = useState(true);

  const getCurrentVersionId = () => get(versionInfo, 'info.versionId', defaultCurrentVersionId);

  useEffect(() => {
    if (!versionInfo) return;

    setCanCreateNewVersion(
      versionInfo.info.approvedAt &&
        !versionInfo.info.archivedAt &&
        versionInfo.info.versionId === versionInfo.versions[0].versionId
    );

    const isLocked = myuser?.userName
      ? isNotCheckedOutByMe(versionInfo, myuser)
      : true;
    const canEdit = !versionInfo.info.approvedAt &&
      !versionInfo.info.archivedAt &&
      !isLocked;
    setCanSaveVersion(canEdit);
    setCanApproveVersion(canEdit);

    setElementDisabled(
      !!versionInfo.info.approvedAt ||
        !!versionInfo.info.archivedAt ||
        isLocked ||
        !hasPermit(getPluginByMimeType(versionInfo.info.mimeType), permits)
    );
  }, [versionInfo, myuser]);

  const createNewVersion = async () => {
    const currentVersionId = getCurrentVersionId();
    if (currentVersionId === defaultCurrentVersionId) return;
    const offFreezing = freezeScreen();
    try {
      const newVersion = await createVersion(-100, currentVersionId);
      history.push(getPluginByMimeType(newVersion.mimeType).openpath.replace(':versionId', newVersion.versionId));
      showSnackbarChangesSaved(snackbar);
    } catch (e) {
      showSnackbarError(snackbar, e.message);
    }
    offFreezing();
  };

  const getApproveFunc = (prepareApproveFunc = null) => {
    if (prepareApproveFunc != null && typeof prepareApproveFunc !== 'function') {
      throw new Error("prepareApproveFunc in getApproveFunc must be a function if set.")
    }
    return async () => {
      const currentVersionId = getCurrentVersionId();
      if (currentVersionId === defaultCurrentVersionId) return;
      const offFreezing = freezeScreen();
      try {
        if (prepareApproveFunc != null) {
          await prepareApproveFunc();
        }
        await approveVersionAsync(-100, currentVersionId);
        dispatch(getVersionInfo(currentVersionId));
        showSnackbarChangesSaved(snackbar);
      } catch (e) {
        showSnackbarError(snackbar, e.message);
      }
      offFreezing();
    };
  };

  const approveVersion = getApproveFunc();

  const saveVersion = async (storeFunc) => {
    const currentVersionId = getCurrentVersionId();
    if (currentVersionId === defaultCurrentVersionId) return;
    const offFreezing = freezeScreen();
    try {
      await storeFunc();
      showSnackbarChangesSaved(snackbar);
    } catch (e) {
      showSnackbarError(snackbar, e.message);
    }
    offFreezing();
  };

  if (!versionInfo) return defaultReturnState;

  const manager = {
    elementDisabled,
    canSaveVersion,
    canCreateNewVersion,
    canApproveVersion,
    createNewVersion,
    getApproveFunc,
    approveVersion,
    saveVersion
  };

  return [manager, versionInfo];
};

export default useVersionManager;
