import React, { useState, useContext, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TabsItem from "../../TabsItem";
import CheckBoxItem from "../../CheckBoxItem";
import Tree from "../../InfoElement/Structure/Tree";
import { getApiTree } from "../../InfoElement/Structure/API";
import { useSelector } from "react-redux";



const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10
  },
  wrapperText: {
    display: "grid",
    gridTemplateColumns: "100px 1fr",
    gridColumnGap: 10,
    fontWeight: 700,
    padding: "0px 5px"
  },
  wrapperTabsContent: {
    backgroundColor: "#ffffff",
    height: 224,
    overflowY: "auto",
    padding: 5
  }
}));

const dataTabs = [
  { name: "Information" },
  { name: "Phrase" },
  { name: "Included in" },
  { name: "Metadata" }
];


const TabsPhrase = ({ dialogAddWord, phrase }) => {
  const cl = useStyles();
  const { mylocale } = useSelector((state) => state);
  const [valueTabs, setValueTabs] = useState({
    selectedTab: 0,
    checked: false,
    checkedAlla: false,
    treeItem: null
  });


  const handleTab = (val) => {
    setValueTabs(pre => ({ ...pre, selectedTab: val }));
  };
  const handleChecked = (val, name) => {
    setValueTabs(pre => ({ ...pre, [name]: val }));
  };
  const selectedWord = dialogAddWord.selectedWord;


  const getTree = async () => {
   if(selectedWord){
     return await getApiTree(selectedWord.versionId, 'sv_SE', )
   }
  };

  useEffect(() => {
    getTree();
  }, [selectedWord]);



  return (
    <div className={cl.root}>
      <TabsItem
        selectedTab={valueTabs.selectedTab}
        tabs={dataTabs}
        setTab={handleTab}
      />
      <div className={cl.wrapperTabsContent}>
        {valueTabs.selectedTab === 0 && <>
          <div className={cl.wrapperText}>
            <span>Versions-id:</span>
            <span>{selectedWord?.versionId ? selectedWord.versionId : "-"}</span>
          </div>
          <div className={cl.wrapperText}>
            <span>Element id:</span>
            <span>{selectedWord?.id ? selectedWord.id : "-"}</span>
          </div>
          <div className={cl.wrapperText}>
            <span>Skapad av:</span>
            <span>
              {selectedWord?.createdBy ? selectedWord.createdBy : "-"}
            </span>
          </div>
          <div className={cl.wrapperText}>
            <span>Skapad:</span>
            <span>{selectedWord?.createdAt ? selectedWord.createdAt.split(" ")[0] : "-"}</span>
          </div>
          <div className={cl.wrapperText}>
            <span>Ändrad av:</span>
            <span>{selectedWord?.changedBy ? selectedWord.changedBy : "-"}</span>
          </div>
          <div className={cl.wrapperText}>
            <span>Ändrad:</span>
            <span>{selectedWord?.changedBy ? selectedWord.changedAt.split(" ")[0] : "-"}</span>
          </div>
          <div className={cl.wrapperText}>
            <span>Godkänd:</span>
            <span>{selectedWord?.approvedAt ? selectedWord.approvedAt.split(" ")[0] : "-"}</span>
          </div>
          <div className={cl.wrapperText}>
            <span>Godkänd av:</span>
            <span>{selectedWord?.approvedBy ? selectedWord.approvedBy : "-"}</span>
          </div>
        </>}
        {valueTabs.selectedTab === 1 &&
          <div>
            {selectedWord?.name}
          </div>
        }
        {valueTabs.selectedTab === 2 &&
          <div>
            {selectedWord?.versionId && <Tree
              // selectedWord={selectedWord.versionId}
              getRootNode={() => getTree()}
            />}
          </div>
        }
        {valueTabs.selectedTab === 3 && <div style={{ paddingLeft: 5 }}>
          <CheckBoxItem
            checked={valueTabs.checkedAlla}
            label={"Visa alla versioner"}
            setChecked={handleChecked}
            name={"checkedAlla"}
          /></div>}
      </div>

    </div>

  );
};

export default TabsPhrase;