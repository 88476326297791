import React from 'react';

const viewportContext = React.createContext({
  width: 1920,
  height: 1080,
  isTablet: false,
  isMobile: false
});

export function ViewportProvider({ children }) {
  // This is the exact same logic that we previously had in our hook

  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const [isTablet, setIsTablet] = React.useState(width < 768);
  const [isMobile, setIsMobile] = React.useState(width < 480);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setIsTablet(window.innerWidth < 768);
    setIsMobile(window.innerWidth < 480);

    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  };

  React.useEffect(() => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  /* Now we are dealing with a context instead of a Hook, so instead
     of returning the width and height we store the values in the
     value of the Provider */
  return <viewportContext.Provider value={{ width, height, isTablet, isMobile }}>{children}</viewportContext.Provider>;
}

/* Rewrite the "useViewport" hook to pull the width and height values
   out of the context instead of calculating them itself */
export const useViewport = () => {
  /* We can use the "useContext" Hook to acccess a context from within
     another Hook, remember, Hooks are composable! */
  const { width, height, isTablet, isMobile } = React.useContext(viewportContext);
  return { width, height, isTablet, isMobile };
};
