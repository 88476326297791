import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, {useContext, useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {useSelector} from 'react-redux';
import TextFieldItem from "@zert-packages/components/TextFieldItem";
import getTranslation from "@zert-packages/utils/getTranslation.old";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import {IndexContext} from "./IndexView";
import {adobeName} from "./utils/utils";



const useStyles = makeStyles((theme) => ({
    divFlex: {
        display: 'flex'
    },
    innerDiv: {
        width: '50%',
        padding: 4
    },
    wrapperEntries: {
        display: 'grid',
        gridRowGap: 10,
    },
    row: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: '10px'
    },
    wrapperLabel: {
        display: 'grid',
        gridTemplateColumns: '100px 1fr',
    },
    wrapperLabelPhonetic: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridColumnGap: 5,
    },
    label: {
      fontWeight: 700,
    },
}))

function EntryView({entry, setEntry}) {
    const cl = useStyles();
    const CLM = useSelector((state) => state.CLM);
    const {values, setValues} = useContext(IndexContext)
    const [alsoList, setAlsoList] = useState(null)
    const entries = values.entries


    const textFieldChangeHandler = (val, name) => {
        setEntry((prevState) => ({
            ...prevState,
            [name]: val
        }));
    };

    useEffect(() => {
        if(entries){
            const res = entries.filter((el , i)  => i !== values.selectedEntries)
            if(res.length > 0){
                setAlsoList(res)
            }

        }
    }, [entries, values.selectedEntries]);



    return (
        <div>
            <div className={cl.wrapperEntries}>
                <div className={cl.wrapperLabel}>
                    <div className={cl.label}>
                        Category:
                    </div>
                    <ExpanderItem
                        valueProps={ entry.category || 'None'}
                        propsLabel={['None', ...CLM.index.categories]}
                        type={'category'}
                        setProps={textFieldChangeHandler}
                    />
                </div>

                <div className={cl.row}>
                    <div className={cl.wrapperLabel}>
                        <div className={cl.label}>
                            Entry:
                        </div>
                        <TextFieldItem
                            values={entry.text}
                            name={"text"}
                            setValues={textFieldChangeHandler}
                        />
                    </div>
                    <div className={cl.wrapperLabelPhonetic}>
                        <div className={cl.label}>
                            Phonetic reading:
                        </div>
                        <TextFieldItem
                            values={entry.textPhoneticReading}
                            name={"textPhoneticReading"}
                            setValues={textFieldChangeHandler}
                        />
                    </div>
                </div>

                <div className={cl.row}>
                    <div className={cl.wrapperLabel}>
                        <div className={cl.label}>
                            1st key
                        </div>
                        <TextFieldItem
                            values={entry.key1}
                            name={"key1"}
                            setValues={textFieldChangeHandler}
                        />
                    </div>
                    <div className={cl.wrapperLabelPhonetic}>
                        <div className={cl.label}>
                            Phonetic reading:
                        </div>
                        <TextFieldItem
                            values={entry.key1PhoneticReading}
                            name={"key1PhoneticReading"}
                            setValues={textFieldChangeHandler}
                        />
                    </div>
                </div>

                <div className={cl.row}>
                    <div className={cl.wrapperLabel}>
                        <div className={cl.label}>
                            2nd key:
                        </div>
                        <TextFieldItem
                            values={entry.key2}
                            name={"key2"}
                            setValues={textFieldChangeHandler}
                        />
                    </div>
                    <div className={cl.wrapperLabelPhonetic}>
                        <div className={cl.label}>
                            Phonetic reading:
                        </div>
                        <TextFieldItem
                            values={entry.key2PhoneticReading}
                            name={"key2PhoneticReading"}
                            setValues={textFieldChangeHandler}
                        />
                    </div>
                </div>

                <div className={cl.wrapperLabel}>
                    <div className={cl.label}>
                        See also:
                    </div>
                    <ExpanderItem
                        valueProps={ entry.seeAlso ?  adobeName(entry.seeAlso): ''}
                        propsLabel={alsoList ? alsoList.map(el => ({...el, name: adobeName(el)})) : []}
                        type={'seeAlso'}
                        setProps={textFieldChangeHandler}
                    />
                </div>
            </div>

        </div>
    );
}

export default EntryView;
