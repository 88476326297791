export const handleStylesheetState = (val, name, values, setValues) => {
  setValues(pre => ({
    ...pre, stylesheet: {
      ...pre.stylesheet,
      [pre.selectedTabName]:  pre.stylesheet[pre.selectedTabName].reduce((acu, cur, idx) => {
        if(idx === pre.selectedLeftMenu){
          return [...acu, { ...cur, [name]: val}]
        }
        return [...acu, cur]
      }, [])
    }
  }));
};
