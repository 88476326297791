import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const defaultFunc = () => {};

function ConfirmationDialog({
  onConfirm = defaultFunc,
  onCancel = defaultFunc,
  unmountComponent,
  contextText = <FormattedMessage id="ConfirmationDialog.Text" defaultMessage="Are you sure?" />,
  titleText: TitleText,
  confirmButtonText: ConfirmButtonText,
  cancelButtonText: CancelButtonText
}) {
  const withUnmountComponent = (callback) => () => {
    unmountComponent();
    if (callback) callback();
  };
  return (
    <Dialog open onClose={withUnmountComponent(onCancel)}>
      <DialogTitle>
        {TitleText || <FormattedMessage id="ConfirmationDialog.Title" defaultMessage="Confirmation" />}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{contextText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={withUnmountComponent(onConfirm)} color="primary" variant="outlined">
          {ConfirmButtonText || <FormattedMessage id="ConfirmationDialog.Confirm" defaultMessage="Confirm" />}
        </Button>
        <Button onClick={withUnmountComponent(onCancel)} color="primary">
          {CancelButtonText || <FormattedMessage id="ConfirmationDialog.Cancel" defaultMessage="Cancel" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
