import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { TabsContext } from '../../Stylesheet';
import Group from './Group';
import { handlePageLayoutSingleLevel, handleStylesheet, handleTypeBlock } from '../../utils/helpers';
import Topic from './topic/Topic';
import ListTemplate from './listTemplate/ListTemplate';
import PageLayout from './pageLayout/PageLayout';
import Cover from './cover/Cover';
import { findSelectedDropMenuBlock } from '../../utils/findSelectedDropMenuBlock';
import AnchoredTopic from './anchoredTopic/AnchoredTopic';
import TableContents from './tableContents/TableContents';
import { publicationData } from '../../data/block/publicationData';
import { groupData } from '../../data/block/groupData';
import { topicData } from '../../data/block/topicData';
import { anchoredTopicData } from '../../data/block/anchoredTopicData';
import { listData } from '../../data/block/listData';
import { pageLayoutData } from '../../data/block/pageLayoutData';
import { tableOfContents } from '../../data/block/tableOfContents';
import { coverData } from '../../data/block/coverData';
import { userDefined } from '../../data/block/userDefined';
import SkeletonLoading from "../../SkeletonLoading";
import { handleStylesheetState } from "../../utils/handleStylesheet";

const useStyles = makeStyles((theme) => ({
  wrapperCheckbox: {
    display: 'block',
    marginTop: -1
  },
  templateTitle: {
    fontWeight: '700',
    marginBottom: 0
  },
  templateInput: {
    height: 'Calc(100% - 100px)',
    width: '100%',
    marginBottom: 0,
    '& .MuiInputBase-multiline.MuiInputBase-marginDense': {
      height: '100%'
    },
    "& textarea": {
      height: '100% !important'
    },
    '& .MuiInputBase-root': {
      height: '100%',
    },
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100% !important'
    }
  }
}));

function BlockTabs() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const dropMenu = [
    'Group',
    'Topic',
    'Anchored topic',
    'List',
    'Publication',
    'Page layout',
    'table of contents',
    'Cover',
    'External file',
    'User defined'
  ];
  const selectedItem = values.leftMenu && values.leftMenu[values.selectedLeftMenu]

  const handleBlockTabs = (val) => {
    if (val === values.selectedDropMenu) {
      return;
    }
    if (val === 'Publication') {
      handleTypeBlock(publicationData, values, setValues);
    }
    if (val === 'Group') {
      handleTypeBlock(groupData, values, setValues);
    }
    if (val === 'Topic') {
      handleTypeBlock(topicData, values, setValues);
    }
    if (val === 'Anchored topic') {
      handleTypeBlock(anchoredTopicData, values, setValues);
    }
    if (val === 'List') {
      handleTypeBlock(listData, values, setValues);
    }
    if (val === 'Page layout') {
      handleTypeBlock(pageLayoutData, values, setValues);
    }
    if (val === 'table of contents') {
      handleTypeBlock(tableOfContents, values, setValues);
    }
    if (val === 'Cover') {
      handleTypeBlock(coverData, values, setValues);
    }
    if (val === 'User defined') {
      handleTypeBlock(userDefined, values, setValues);
    }
    handleStylesheet('selectedDropMenu', val, setValues);
  };

  const handleCheckbox = (event) => {
    const res = event.target.checked;
    handleStylesheetState(res, 'separatedBlock', values, setValues)
  };

  const handleXSL = (e) => {
    const val = e.target.value;
    const name = e.target.name
    handleStylesheetState(val, name, values, setValues)
  };



  useEffect(() => {
    if (values.leftMenu) {
      findSelectedDropMenuBlock(values, setValues);
    }
  }, [values.leftMenu, values.selectedLeftMenu]);


  if(!selectedItem){
    return <SkeletonLoading />
  }


  return (
    <div style={{ height: '100%' }}>
      {values.selectedDropMenu && (
        <ExpanderItem valueProps={values.selectedDropMenu} propsLabel={dropMenu} setProps={handleBlockTabs} />
      )}

      <span className={cl.wrapperCheckbox}>
        <FormControlLabel
          control={<Checkbox
            onChange={handleCheckbox}
            checked={!!selectedItem?.separatedBlock}
          />}
          label={<span>Create this block as a separate file</span>}
        />
      </span>

      {values.selectedDropMenu && (
        <>
          {values.selectedDropMenu === 'Publication'  &&
            <>
              <p className={cl.templateTitle}>XSL template:</p>
              <TextField
                value={selectedItem.xsl || ''}
                className={cl.templateInput}
                variant="outlined"
                multiline
                minRows={10}
                onChange={handleXSL}
                name={'xsl'}
              />
            </>
          }
          { values.selectedDropMenu === 'User defined' &&
            <>
              <p className={cl.templateTitle}>XSL template:</p>
              <TextField
                value={selectedItem.template || ''}
                className={cl.templateInput}
                variant="outlined"
                multiline
                minRows={10}
                onChange={handleXSL}
                name={'template'}
              />
            </>
          }

          {values.selectedDropMenu === 'Page layout' && <PageLayout />}
          {values.selectedDropMenu === 'List' && <ListTemplate />}
          {values.selectedDropMenu === 'Group' && <Group />}
          {values.selectedDropMenu === 'Cover' && <Cover />}
          {values.selectedDropMenu === 'Topic' && <Topic />}
          {values.selectedDropMenu === 'Anchored topic' && <AnchoredTopic />}
          {values.selectedDropMenu === 'table of contents' && <TableContents />}
        </>
      )}
    </div>
  );
}

export default BlockTabs;
