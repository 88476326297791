import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const FiltersItem = ({ el, cl }) => {
  const settings = el.settings;
  const properties = settings.template.properties;
  const res = settings.settings.map((el) => el.value);



  if (el.identifier === "internal-image-filter") {
    return (
      <span>
         {`${el.identifier} `}
        {`(${res[1]})`}
        <ArrowRightAltIcon className={cl.arrowImg} />
        {`${res[0]}`};
        {` ${properties[2].identifier} = ${res[2]}; pdf = ${properties[3].value}`}
      </span>
    );
  }

  return (
    <span>
       {`${el.identifier} `}
      {`(${res[1]})`} <ArrowRightAltIcon className={cl.arrowImg} /> {`${res[0]}`}
      </span>
  );
};

export default FiltersItem;