import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 15px',
    height: '100%'
  },
  wrapperRoot: {
    display: 'grid',
    height: '100%',
    position: 'relative'
  },
  tabsRoot: {
    minHeight: 18,
    marginBottom: 12,
    '& .MuiTab-root': {
      padding: '0px 4px 0px 3px',
      minWidth: 'fit-content',
      minHeight: 18,
      background: '#EBEBEB',
      textTransform: 'capitalize'
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#ffffff',
      background: '#4db1d3',
      outline: 'none'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left',
      background: '#E3F2F7'
    }
  },
  wrapperLeftMenu: {
    borderRight: '5px solid #E2F2F6',
    overflowY: 'auto',
    position: 'absolute',
    width: '100%',
    // height: 'Calc(100% - 117px)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 5
  },
  arrowUp: {
    color: '#4db1d3',
    cursor: 'pointer',
    transform: 'rotate(90deg)'
  },
  arrowDown: {
    color: '#4db1d3',
    cursor: 'pointer',
    transform: 'rotate(270deg)'
  },
  listLeftMenu: {
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    cursor: 'pointer'
  },
  listLeftMenuSelected: {
    padding: '2px 4px',
    cursor: 'pointer',
    color: '#ffffff',
    background: '#4db1d3'
  },
  wrapperArrow: {
    position: 'absolute',
    background: '#ffffff',
    cursor: 'pointer',
    // marginTop: '50%',
    top: '40%',
    height: 42,
    width: 11,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& svg': {
      display: 'block'
    },
    '& span': {
      height: 21,
      display: 'block'
    }
  },
  wrapperRightBlock: {
    paddingLeft: 12,
    paddingRight: 12,
    overflowY: 'auto',
    maxHeight: 'Calc(100vh - 130px)',
    overflowX: 'hidden'
  }
}));

export default useStyles;
