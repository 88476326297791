import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { connect } from 'react-redux';
import withSearch, { styles, controlStyles } from '../withSearch';

import SearchComponent from './SearchComponent';

const componentStyles = {
  numberOfPeriodContainer: {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: '2fr 1fr'
  }
};

class DateSearch extends SearchComponent {
  constructor(props) {
    super(props);
    /*  status = Status.decode(properties.get("Status"));
    type = Type.decode(properties.get("Type"));
    
    if (type.equals(Type.BEFORE)) {
      beforeValue = Integer.valueOf(properties.get("BeforeValue"));
      beforeUnit = TimeUnit.decode(properties.get("BeforeUnit"));
    } else if (type.equals(Type.AFTER)) {
      afterValue = Integer.valueOf(properties.get("AfterValue"));
      afterUnit = TimeUnit.decode(properties.get("AfterUnit"));
    } else if (type.equals(Type.BETWEEN)) { 
      between1 = ZertToolkit.getDateTimeFormat().parse(properties.get("BetweenDate1"));
      between2 = ZertToolkit.getDateTimeFormat().parse(properties.get("BetweenDate2"));
    } */
    if (!this.state || !this.state.Type) {
      this.state = {
        name: '',
        Status: 'created',
        Type: 'before',
        AfterUnit: 'days',
        BeforeUnit: 'days',
        AfterValue: 1,
        BeforeValue: 1,
        BetweenDate1: new Date(),
        BetweenDate2: new Date()
      };
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (name) => (event) => {
    this.setStatus({ [name]: event.target.value });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  render() {
    const { classes } = this.props;
    //  this.props.handleChange(this.state);
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel htmlFor="date.type">
            <FormattedMessage id="search.date.type" defaultMessage="Type" />{' '}
          </InputLabel>
          <Select
              size="small"
            value={this.state.Status}
            onChange={(e) => {
              this.setStatus({ Status: e.target.value });
            }}
            inputProps={{
              name: 'search-date-type',
              id: 'date.type'
            }}
          >
            <MenuItem value="created">
              <em>
                <FormattedMessage id="search.date.created" defaultMessage="Created" />
              </em>
            </MenuItem>
            <MenuItem value="changed">
              <em>
                <FormattedMessage id="search.date.lastmodified" defaultMessage="Last modified" />
              </em>
            </MenuItem>
          </Select>
        </FormControl>

        <FormControlLabel
          value="in_the_last"
          className={classes.formControl}
          fullWidth
          control={
            <Radio
              color="primary"
              checked={this.state.Type === 'after'}
              onChange={(e) => {
                this.setStatus({ Type: e.target.value });
              }}
              value="after"
              name="Type"
              aria-label={<FormattedMessage id="search.date.inthelast" defaultMessage="In the last" />}
              label={<FormattedMessage id="search.date.inthelast" defaultMessage="In the last" />}
            />
          }
          label={<FormattedMessage id="search.date.inthelast" defaultMessage="In the last" />}
          labelPlacement="end"
        />

        <div style={componentStyles.numberOfPeriodContainer}>
          <TextField
            style={{ width: '100%' }}
            id="standard-in_the_last"
            size="small"
            label={<FormattedMessage id="search.date.numberof" defaultMessage="Number of" />}
            placeholder={<FormattedMessage id="search.date.numberof" defaultMessage="Number of" />}
            className={classes.spinField}
            disabled={this.state.Type !== 'after'}
            value={this.state.AfterValue}
            onChange={this.handleChange('AfterValue')}
            type="number"
            variant="outlined"
          />

          <FormControl>
            <InputLabel htmlFor="search.select.units.in_the_last">
              <FormattedMessage id="search.select.units.in_the_last" defaultMessage="Period" />{' '}
            </InputLabel>
            <Select
              value={this.state.AfterUnit}
              size="small"
              disabled={this.state.Type !== 'after'}
              onChange={(e) => {
                this.setStatus({ AfterUnit: e.target.value });
              }}
              inputProps={{
                name: 'search-date-type',
                id: 'search.select.units.in_the_last'
              }}
            >
              <MenuItem value="minutes">
                <em>
                  <FormattedMessage id="search.select.units.minute" defaultMessage="Minute" />
                </em>
              </MenuItem>
              <MenuItem value="hours">
                <em>
                  <FormattedMessage id="search.select.units.hour" defaultMessage="Hour" />
                </em>
              </MenuItem>
              <MenuItem value="days">
                <em>
                  <FormattedMessage id="search.select.units.day" defaultMessage="Day" />
                </em>
              </MenuItem>
              <MenuItem value="months">
                <em>
                  <FormattedMessage id="search.select.units.month" defaultMessage="Month" />
                </em>
              </MenuItem>
              <MenuItem value="years">
                <em>
                  <FormattedMessage id="search.select.units.year" defaultMessage="Year" />
                </em>
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <FormControlLabel
          value="before_last"
          className={classes.formControl}
          fullWidth
          control={
            <Radio
              color="primary"
              checked={this.state.Type === 'before'}
              onChange={(e) => {
                this.setStatus({ Type: e.target.value });
              }}
              value="before"
              name="Type"
              aria-label={<FormattedMessage id="search.date.beforelast" defaultMessage="Before last" />}
              label={<FormattedMessage id="search.date.beforelast" defaultMessage="Before last" />}
            />
          }
          label={<FormattedMessage id="search.date.beforelast" defaultMessage="Before last" />}
          labelPlacement="end"
        />
        <div style={componentStyles.numberOfPeriodContainer}>
          <TextField
            style={{ width: '100%' }}
            size="small"
            id="standard-before_last"
            label={<FormattedMessage id="search.date.numberof" defaultMessage="Number of" />}
            placeholder={<FormattedMessage id="search.date.numberof" defaultMessage="Number of" />}
            className={classes.spinField}
            value={this.state.BeforeValue}
            onChange={this.handleChange('BeforeValue')}
            type="number"
            variant="outlined"
            disabled={this.state.Type !== 'before'}
          />
          <FormControl>
            <InputLabel htmlFor="search.select.units.before_last">
              <FormattedMessage id="search.select.units.before_last" defaultMessage="Period" />{' '}
            </InputLabel>
            <Select
                size="small"
              value={this.state.BeforeUnit}
              disabled={this.state.Type !== 'before'}
              onChange={(e) => {
                this.setStatus({ BeforeUnit: e.target.value });
              }}
              inputProps={{
                name: 'search-date-type',
                id: 'search.select.units.before_last'
              }}
            >
              <MenuItem value="minutes">
                <em>
                  <FormattedMessage id="search.select.units.minute" defaultMessage="Minute" />
                </em>
              </MenuItem>
              <MenuItem value="hours">
                <em>
                  <FormattedMessage id="search.select.units.hour" defaultMessage="Hour" />
                </em>
              </MenuItem>
              <MenuItem value="days">
                <em>
                  <FormattedMessage id="search.select.units.day" defaultMessage="Day" />
                </em>
              </MenuItem>
              <MenuItem value="months">
                <em>
                  <FormattedMessage id="search.select.units.month" defaultMessage="Month" />
                </em>
              </MenuItem>
              <MenuItem value="years">
                <em>
                  <FormattedMessage id="search.select.units.year" defaultMessage="Year" />
                </em>
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <FormControlLabel
          value="between"
          className={classes.formControl}
          fullWidth
          control={
            <Radio
              color="primary"
              checked={this.state.Type === 'between'}
              onChange={(e) => {
                this.setStatus({ Type: e.target.value });
              }}
              value="between"
              name="Type"
              aria-label={<FormattedMessage id="search.date.between" defaultMessage="Between" />}
              label={<FormattedMessage id="search.date.between" defaultMessage="Between" />}
            />
          }
          label={<FormattedMessage id="search.date.between" defaultMessage="Between" />}
          labelPlacement="end"
        />

        <TextField
          fullWidth
          id="standard-from_date_between"
          size="small"
          label={<FormattedMessage id="search.date.from" defaultMessage="From" />}
          placeholder={<FormattedMessage id="search.date.from" defaultMessage="From" />}
          className={classes.formControl}
          value={this.state.BetweenDate1}
          onChange={this.handleChange('BetweenDate1')}
          type="date"
          variant="outlined"
          disabled={this.state.Type !== 'between'}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          fullWidth
          size="small"
          id="standard-to_date_between"
          InputLabelProps={{ shrink: true }}
          label={<FormattedMessage id="search.date.to" defaultMessage="To" />}
          placeholder={<FormattedMessage id="search.date.to" defaultMessage="To" />}
          className={classes.formControl}
          value={this.state.BetweenDate2}
          onChange={this.handleChange('BetweenDate2')}
          type="date"
          variant="outlined"
          disabled={this.state.Type !== 'between'}
        />
      </form>
    );
  }
}

DateSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.date.header" defaultMessage="Search by date" />,
  index: 'search-date',
  className: 'se.zert.backend.search.DateCriteria',
  default: {
    type: 'before'
  }
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles)(DateSearch), selectData))
);
