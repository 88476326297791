import React from 'react';
import { TableBody as MTableBody, TableRow, TableCell } from '@mui/material';

function TableBody({ rows, columns, selectedRow, handleSelectRow }) {
  return (
    <MTableBody>
      {rows.map((row) => (
        <TableRow key={row.id} onClick={() => handleSelectRow(row.id)} selected={selectedRow === row.id}>
          {columns.map((column) => (
            <TableCell key={column.identifier} size="small" padding="none">
              <div className="searchable-table__body-cell">
                {columns.isAction ? row[column.identifier] : row[column.identifier]}
              </div>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </MTableBody>
  );
}

export default TableBody;
