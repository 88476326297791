import { getFormatting } from './getFormatting';

export function getFormattingTemplates(searchFormat, formattingTemplate) {
  let formats = getFormatting(searchFormat, 'blockFormats', formattingTemplate);
  if (formats) {
    return formats;
  }
  formats = getFormatting(searchFormat, 'tableFormats', formattingTemplate);
  if (formats) {
    return formats;
  }
  formats = getFormatting(searchFormat, 'partFormats', formattingTemplate);
  if (formats) {
    return formats;
  }
  formats = getFormatting(searchFormat, 'imageFormats', formattingTemplate);
  if (formats) {
    return formats;
  }
  return null;
}
