import { Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import { addColumnData } from '../../../../data/addColumnData';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 500,
    height: 200,
    padding: 11,
    background: '#E2F2F6'
  },
  wrapperUnderLabel: {
    marginTop: 10,
    marginBottom: 5
  },

  wrapperTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      cursor: 'pointer'
    }
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 700
  },
  wrapperDropMenu: {
    marginBottom: 'auto',
    marginTop: '1rem'
  },
  wrapperButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 20
  },
  button: {
    color: '#ffffff',
    background: '#4db1d3',
    marginLeft: 10,
    minWidth: 80,
    border: '1px solid #4db1d3',
    outline: 'none !important',
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
      color: '#212529',
      border: '1px solid #4db1d3'
    },
    '&:focus': {
      backgroundColor: '#4db1d3'
    }
  }
}));

function DialogAddColumn({ dropMenu, handleAddColumn }) {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [column, setColumn] = useState('Paragraph number');

  const handleClose = () => {
    setOpen(false);
  };

  const saveItem = () => {
    const result = addColumnData.find((el) => el.content.replaceAll('_', ' ') === column.toUpperCase());
    if (handleAddColumn) {
      handleAddColumn(result);
    }
    setOpen(false);
  };

  const handleColumn = (item) => {
    setColumn(item);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paperScrollPaper: cl.root
      }}
    >
      <div className={cl.wrapperTitle}>
        <span className={cl.title}>Add column</span>
        <CloseIcon onClick={handleClose} />
      </div>
      <div className={cl.wrapperDropMenu}>
        <ExpanderItem valueProps={column} propsLabel={dropMenu} setProps={handleColumn} type="text" />
      </div>
      <div className={cl.wrapperButton}>
        <Button className={cl.button} onClick={saveItem}>
          OK
        </Button>
        <Button className={cl.button} onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}

export default DialogAddColumn;
