import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { formStyle } from './StylesHelper';

const useStyles = makeStyles(formStyle);
// onChange={props.handleChange(props.name)}
// onChange={props.handleChange && props.name && props.handleChange(props.name)}
export function TextField2(props) {
  const classes = useStyles();
  const handleChange = (prop) => (event) => {
    setValue(event.target.value);
    props.handleChange(prop, event);
  };
  const [value, setValue] = useState(props.value);

  return (
    <FormControl required={props.required} className={classes.formControl} aria-readonly={props.readOnly}>
      <InputLabel htmlFor={props.id} disabled={props.readOnly}>
        {props.label}
      </InputLabel>
      <Input
        disabled={props.readOnly}
        readOnly={props.readOnly}
        id={props.id}
        onChange={handleChange(props.id)}
        value={value}
        fullWidth
        aria-describedby={props.id}
      />
    </FormControl>
  );
}
