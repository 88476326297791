import React, {useEffect, useState} from 'react';
import AddToolbarDialog from "../TablePlugin/resizedTable/components/dialog/AddToolbarDialog/AddToolbarDialog";
import {makeStyles} from "@mui/styles";
import {useDispatch, useSelector} from "react-redux";
import {handleLanguage} from "@zert-packages/actions/commonReducer";
import {loadHelp} from "@zert-packages/actions/api";
import CancelButton from "@zert-packages/components/shared/CallToActionButton/CancelButton";
import ButtonRM from "@zert-packages/components/shared/Button";
import {FormattedMessage} from "react-intl";


const useStyles = makeStyles((theme) => ({
    rootAddView: {
        width: "100%",
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 24,
        minHeight: '100%',
        overflow: 'auto',
        paddingBottom: 50,
    },
    wrapperEditor: {
        backgroundColor: 'rgb(244, 248, 250)',
        padding: 24,
    },
    wrapperText: {
        padding: 24,
        backgroundColor: '#ffffff',
        boxShadow: 'inset 0 1px 0 0 #f4f8fa, inset 1px 0 0 0 #eff3f6, inset -1px 0 0 0 #eff3f6, inset 0 -1px 0 0 #eff3f6, 0 8px 8px 0 #f4f8fa'
    },
}))


const AddView = () => {
    const cl = useStyles();
    const [create, setCreate] = useState({
        createElement: false,
        createType: null,
        save: false,
    })
    const {myLanguage, activeLocales, createNewElementPatel} = useSelector((state) => state);
    const {mylocale, helpText} = useSelector((state) => state);
    const createType = create?.createType?.replaceAll('-', '_')
    const dispatch = useDispatch();
    const url =    window.location.pathname

    const getHelpMetaData = () => {
        dispatch(loadHelp(createType))
    }

    const handleCreate = () => {
        setCreate(pre => ({...pre, save: {} }))
    }


    useEffect(() => {
        if (activeLocales) {
            const findActiveLocal = activeLocales.find((el) => el.value === "sv_SE");
            if (findActiveLocal) {
                dispatch(handleLanguage(findActiveLocal));
            }
        }
    }, [activeLocales])

    useEffect(() => {
        setCreate(pre => ({...pre, createType: url.replace('/create-new-', '')}))
    }, [createNewElementPatel]);


    useEffect(() => {
        if (createType && mylocale) {
            getHelpMetaData()
        }
    }, [createType, mylocale]);


    return (<div className={cl.rootAddView}>
        <div className={cl.wrapperEditor}>
            {myLanguage  && createType && <AddToolbarDialog createRoot={create}/>}
            <div style={{  flex: '1', display: 'flex'}} >
                <div style={{display: 'flex'}}>
                    <ButtonRM
                        styleName="primary"
                        whenClick={handleCreate}
                        text=""
                    >
                        <FormattedMessage id="newproject.create" defaultMessage="Create"/>
                    </ButtonRM>
                    <CancelButton/>
                </div>
            </div>
        </div>
        {helpText[createType] &&
           <div className={cl.wrapperText}>
               <div className={cl.wrapperText} dangerouslySetInnerHTML={{__html: helpText[createType]}}/>
           </div>
        }
    </div>)
}

export default AddView
