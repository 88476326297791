export const fontData = [
  { name: 'Bold' },
  { name: 'Subscript' },
  { name: 'Uppercase' },
  { name: 'Superscript' },
  { name: 'Underlined' },
  { name: 'Lowercase' },
  { name: 'italic' },
  { name: 'Strikethrough' },
  { name: 'Verbatim' }
];
