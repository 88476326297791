import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import DropMenu from "@zert-packages/components/DropMenu";
import RowDialog from "./RowDialog";
import FieldDialog from "./FieldDialog";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogColors from "@zert-packages/components/dialog/DialogColors";
import { findBorder } from "./helpers/findBorder";
import tinycolor from "tinycolor2";
import SelectedColor from "../../StylesheetPlugin/SelectedColor";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 414,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700
  },
  wrapper: {
    display: "grid",
    gridRowGap: 10
  },
  wrapperButton: {
    display: "flex",
    marginTop: 30
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

const dropType = [
  { name: "Oval" },
  { name: "Box" }
];
const dropLineType = [
  { name: "PRICKAD" },
  { name: "SOLID" },
  { name: "STRECKAD" },
  { name: "PUNKT STRECKAD" }
];
const DialogNewForm = ({ setValues, el, edit, setTransform, action }) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [uuid] = useState(uuidv4());
  const [form, setForm] = useState({
    id: uuid,
    typeForm: "Box",
    width: 100,
    height: 100,
    lineType: "Solid",
    strokeColor: "#000000",
    fillColor: "#ffffff",
    strokeWidth: 1,
    transparency: 0
  });


  const handleClose = () => {
    setOpen(false);
  };

  const convertStringToRGBA = (colorString) => {
    return tinycolor(colorString).toRgb();
  };


  const handleSave = () => {
    const abobeCanvas = {
      ...form,
      width: +form.width,
      height: +form.height,
      editor: true,
      cornerRadius: form.typeForm === "Box" ? 0 : 50,
      dash: findBorder(form.lineType),
      opacity: form.transparency,
      strokeColor:  adobeRgba(form.strokeColor),
      fillColor: adobeRgba(form.fillColor),
    }
    setValues(pre => ({
      ...pre,
      addImgPhraseType: "Rektangel",
      phraseContent: abobeCanvas,
    }));
    if(setTransform){
      setTransform(pre => ({
        ...pre,
        width: +form.width,
        height: +form.height
      }));
    }
    if(action){
      action()
    }
     handleClose();
  };
  const handleForm = (el, name) => {
    setForm(pre => ({ ...pre, [name]: el.name }));
  };

  const handleSelectedColor = (val, name) => {
    if (val.r) {
      return setForm(pre => ({ ...pre, [name]: `rgba(${val.r}, ${val.g}, ${val.b}, ${val.a})` }));
    }
    setForm(pre => ({ ...pre, [name]: val }));
  };

  const adobeRgba = (val) => {
    if(val.includes('rgba')){
      return val
    }
    const res = convertStringToRGBA(val);
    return  `rgba(${res.r}, ${res.g}, ${res.b}, ${res.a})`
  }


  const handleOpenDialog = (type) => {
    renderInExceptionRoot(DialogColors, {
      text: { label: "Select Color" },
      type: type,
      color: form[type],
      rgb: true,
      setText: handleSelectedColor
    });
  };
  useEffect(() => {
    if (el) {
      setForm(pre => ({ ...pre, ...el}));
    }
  }, [el]);


  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>{edit ? "Edit form" : "New form"}</span>
      <CloseIcon onClick={handleClose} />
    </div>
    <div className={cl.wrapper}>
      <RowDialog title={"Type:"} style={{ gridTemplateColumns: "90px 1fr" }}>
        <DropMenu
          dropItem={dropType}
          selected={form.typeForm}
          name={"typeForm"}
          setDropItem={handleForm}
        />
      </RowDialog>
      <RowDialog title={"Width:"} style={{ gridTemplateColumns: "90px 1fr" }}>
        <FieldDialog
          val={form.width}
          setValue={setForm}
          name={"width"}
        />
      </RowDialog>
      <RowDialog title={"Height:"} style={{ gridTemplateColumns: "90px 1fr" }}>
        <FieldDialog
          val={form.height}
          setValue={setForm}
          name={"height"}
        />
      </RowDialog>
      <RowDialog title={"Line type:"} style={{ gridTemplateColumns: "90px 1fr" }}>
        <DropMenu
          dropItem={dropLineType}
          selected={form.lineType}
          name={"lineType"}
          setDropItem={handleForm}
        />
      </RowDialog>
      <SelectedColor
        style={{ grid: "82px 30px 1fr 25px" }}
        label="Stroke color:"
        type="strokeColor"
        setValueProps={handleSelectedColor}
        handleOpenDialog={handleOpenDialog}
        valueProps={form.strokeColor}
        rgb={true}
      />
      <SelectedColor
        style={{ grid: "82px 30px 1fr 25px" }}
        label="Fill color:"
        type="fillColor"
        setValueProps={handleSelectedColor}
        handleOpenDialog={handleOpenDialog}
        valueProps={form.fillColor}
        rgb={true}
      />

      <RowDialog title={"Stroke width:"} style={{ gridTemplateColumns: "90px 1fr" }}>
        <FieldDialog
          val={form.strokeWidth}
          setValue={setForm}
          name={"strokeWidth"}
        />
      </RowDialog>
      <RowDialog title={"Transparency"} style={{ gridTemplateColumns: "90px 1fr" }}>
        <FieldDialog
          val={form.transparency}
          setValue={setForm}
          name={"transparency"}
          transparency={true}
        />
      </RowDialog>
    </div>


    <div className={cl.wrapperButton}>
      <Button className={cl.button} onClick={handleSave}>
        OK
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>
  </Dialog>);
};

export default DialogNewForm;