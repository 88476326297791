import { usePortal } from '@zert-packages/utils/PortalProvider';
import React, { useEffect, useState, Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Container, Divider, Link, Typography, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import { useViewport } from '@zert-packages/utils/ViewportProvider';
import ChangePasswordFields from '@zert-packages/components/shared/FormElements/ChangePasswordFields';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { API_ROOT_CALLS } from '@zert-packages/actions/api';
import { PrimaryButton, SecondaryButton } from '@zert-packages/components/shared/CustomButtons/CustomButtons';
import axiosInstance from '@zert-packages/utils/axios';
import ConfirmationDialog from '@zert-packages/components/common/dialogs/ConfirmationDialog';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import JWTLogin from './JWTLogin';
import Logo from './Logo';
import Page from './Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gridTemplateRows: 'repeat(10, 1fr)',
    gridColumnGap: '0px',
    gridRowGap: '0px',
    minHeight: 'calc(var(--vh, 1vh) * 100)'
  },
  rootMobile: {
    backgroundColor: theme.palette.background.dark,
    display: 'grid',
    gridColumnGap: '0px',
    gridRowGap: '0px',
    minHeight: 'calc(var(--vh, 1vh) * 100)'
  },
  leftPanel: {
    gridArea: '1 / 1 / 11 / 3',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: '#F5F6FA',
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: '325px',
    zIndex: '1'
  },
  cardContainer: {
    alignSelf: 'center',
    width: '350px',
    padding: 0,
    webkitBoxShadow: '-16px 20px 74px -1px rgb(0 0 0 / 15%)',
    mozBoxShadow: '-16px 20px 74px -1px rgba(0,0,0,0.15)',
    boxShadow: '-16px 20px 74px -1px rgb(0 0 0 / 15%)',
    gridArea: '1 / 2 / 11 / 4',
    zIndex: '2'
  },
  cardContainerMobile: {
    width: '100%',
    height: '100%',
    gridArea: '1/-1',
    display: 'flex',
    padding: 0,
    zIndex: '2'
  },
  rightPanel: {
    gridArea: '1 / 3 / 11 / 11',
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 'unset'
  },
  banner: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  bannerChip: {
    marginRight: theme.spacing(2)
  },
  methodIcon: {
    height: 30,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  cardHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    background: `#FFFFFF url("${process.env.PUBLIC_URL}app/lock-icon.svg") no-repeat 7px 7px`,
    backgroundPosition: 'top 0px center',
    height: '72px',
    margin: '1em 0'
  },
  cardContent: {
    height: '100%',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  cardBody: {
    margin: 0,
    padding: theme.spacing(4)
  },
  cardAdditionalInfo: {
    padding: theme.spacing(4),
    backgroundColor: '#FAFAFA',
    color: '#738492',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '1em'
  },
  currentMethodIcon: {
    height: 40,
    '& > img': {
      width: 'auto',
      maxHeight: '100%'
    }
  }
}));
const useQuery = (props) => new URLSearchParams(props.location.search);
function LoginView(props) {
  const [isForgotPassword, setForgotPassword] = useState(false);
  const location = useQuery(props);
  const portal = usePortal();
  const backgroundUrl = portal?.background
    ? `url("${process.env.PUBLIC_URL}resources${portal.background}") no-repeat center center fixed`
    : `url("${process.env.PUBLIC_URL}app/bg.jpg") no-repeat center center fixed`;
  const classes = useStyles();
  const intl = useIntl();
  const { isTablet, isMobile } = useViewport();
  const token = location.get('token');
  // debugger;
  return (
    <Page
      className={isMobile ? classes.rootMobile : classes.root}
      title={intl.formatMessage({ id: 'LoginView.Title.Text', defaultMessage: 'Zert' })}
    >
      {!isTablet && (
        <Container className={classes.leftPanel}>
          <RouterLink to="/">
            <Logo style={{ paddingTop: '2em', paddingLeft: '2em', maxWidth: '250px' }} />
          </RouterLink>
          <div style={{ display: 'flex', padding: '0.5em 2em' }}>
            <Link
              target="_blank"
              href={intl.formatMessage({
                id: 'LoginView.Footer.Link',
                defaultMessage: 'https://www.zert.se/en/contact-us'
              })}
            >
              <Typography variant="body2" color="textSecondary">
                <FormattedMessage id="LoginView.Footer.Text" defaultMessage="Contact us | ©" />
              </Typography>
            </Link>
          </div>
        </Container>
      )}
      <Container className={isMobile ? classes.cardContainerMobile : classes.cardContainer} maxWidth="sm">
        <Card style={{ overflow: 'overlay' }}>
          <CardContent className={classes.cardContent}>
            {token && <ChangePassword token={token} isMobile={isMobile} />}
            {isForgotPassword && <ForgotPassword setForgotPassword={setForgotPassword} isMobile={isMobile} />}
            {!isForgotPassword && !token && <Login setForgotPassword={setForgotPassword} isMobile={isMobile} />}
          </CardContent>
        </Card>
      </Container>
      {!isMobile && (
        <Container
          className={classes.rightPanel}
          style={{
            gridArea: isTablet ? '1 / 1 / 11 / 11' : '1 / 3 / 11 / 11',
            background: backgroundUrl,
            backgroundSize: 'cover'
          }}
        />
      )}
    </Page>
  );
}

function ForgotPassword({ setForgotPassword, isMobile }) {
  const [email, setEmail] = useState('');
  const [isSent, setIsSent] = useState(false);

  const portal = usePortal();
  const backgroundUrl = portal
    ? `url("${process.env.PUBLIC_URL}resources${portal.background}") no-repeat center center fixed`
    : `url("${process.env.PUBLIC_URL}app/bg.jpg") no-repeat center center fixed`;
  const classes = useStyles();
  const intl = useIntl();

  const sendForgotPassword = () => {
    fetch(`${API_ROOT_CALLS}/auth/sendReminderPassword`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify({ userEmail: email }) // body data type must match "Content-Type" header
    }).then(
      () => {
        setIsSent(true);

        renderInExceptionRoot(ConfirmationDialog, {
          titleText: (
            <FormattedMessage
              id="ConfirmationDialog.PasswordSend.ConfirmTitle"
              defaultMessage="You have send request password change"
            />
          ),
          confirmButtonText: (
            <FormattedMessage id="ConfirmationDialog.PasswordSend.Confirm" defaultMessage="Back to login page" />
          ),
          contextText: (
            <FormattedMessage
              id="ConfirmationDialog.PasswordSend.Text"
              defaultMessage="Password change request has been send successfully. Check your e-mail box and follow instruction in the email"
            />
          ),
          onConfirm: () => {
            window.location = '/';
          }
          // setMyUser(passwordSettings);
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      {isMobile && (
        <RouterLink to="/">
          <Logo style={{ paddingTop: '2em', paddingLeft: '2em' }} />
        </RouterLink>
      )}
      {!isMobile && (
        <Box mb={3} className={classes.cardHeader}>
          <Typography variant="h3">
            <FormattedMessage id="Login.ForgotPassword.ForgotPassword" defaultMessage="Forgot password" />
          </Typography>
        </Box>
      )}
      <Box className={classes.cardBody} flexGrow={1} mt={3}>
        <TextField
          id="email"
          fullWidth
          variant="outlined"
          onChange={(evt) => setEmail(evt.target.value)}
          value={email}
          label={<FormattedMessage id="Login.ForgotPassword.Email" defaultMessage="Email" />}
        />
        <PrimaryButton fullWidth disabled={email === ''} onClick={sendForgotPassword}>
          <FormattedMessage id="Login.ForgotPassword.ChangePassword" defaultMessage="Send new password" />
        </PrimaryButton>
        {isSent && (
          <Typography variant="body">
            <FormattedMessage
              id="Login.ForgotPassword.Success"
              defaultMessage="A password reset link has been sent to {email}"
              values={{ email }}
            />
          </Typography>
        )}
      </Box>
      <Box my={3} style={{ marginBottom: 0 }}>
        <Divider />
      </Box>
      <Box my={3} className={classes.cardAdditionalInfo}>
        <SecondaryButton fullWidth onClick={() => setForgotPassword(false)}>
          <FormattedMessage id="Login.ForgotPassword.Back" defaultMessage="Back to login" />
        </SecondaryButton>
      </Box>
    </>
  );
}

function handlePost(query, data) {
  return (
    axiosInstance
      .put(API_ROOT_CALLS + query, data, {
        headers: {
          'Content-Length': 0,
          'Content-Type': 'application/json'
        },
        responseType: 'json'
      })
      // .then(handleErrors)
      .then((response) => {
        return response.data;
      })
  );
}

function ChangePassword({ token, isMobile }) {
  const [isError, setIsError] = useState({
    password: '',
    confirmPassword: ''
  });
  const [myUser, setMyUser] = useState({
    password: '',
    confirmPassword: ''
  });

  const portal = usePortal();
  const backgroundUrl = portal
    ? `url("${process.env.PUBLIC_URL}resources${portal.background}") no-repeat center center fixed`
    : `url("${process.env.PUBLIC_URL}app/bg.jpg") no-repeat center center fixed`;
  const classes = useStyles();

  useEffect(() => {
    fetch(`${API_ROOT_CALLS}/auth/getPasswordSettings/${token}`)
      .then((res) => res.json())
      .then(
        (passwordSettings) => {
          setMyUser(passwordSettings);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  useEffect(() => {
    if (myUser.invalid == 0) {
      window.location = '/';
    }
  }, [myUser]);

  const fieldStateChanged = (evt) => {
    //    debugger;
    const newUser = { ...myUser };
    newUser[evt.target.id] = evt.target.value;

    setMyUser(newUser);
  };

  const updatePassword = () => {
    handlePost('/auth/storePassword', { password: myUser.password, token }).then(
      () => {
        renderInExceptionRoot(ConfirmationDialog, {
          titleText: (
            <FormattedMessage
              id="ConfirmationDialog.PasswordUpdated.ConfirmTitle"
              defaultMessage="You have updated your password"
            />
          ),
          confirmButtonText: (
            <FormattedMessage id="ConfirmationDialog.PasswordUpdated.Confirm" defaultMessage="Back to login page" />
          ),
          contextText: (
            <FormattedMessage
              id="ConfirmationDialog.PasswordUpdated.Text"
              defaultMessage="Password successfully updated. Use it on login paage"
            />
          ),
          onConfirm: () => {
            window.location = '/';
          }
          // setMyUser(passwordSettings);
        });
      },
      (error) => {
        setIsError(error);
        // console.log(error);
      }
    );
  };

  if (!myUser.minPasswordLength) {
    return <Loader />;
  }

  const { password, confirmPassword } = isError;
  const isDisabled = Boolean(password) || Boolean(confirmPassword);

  return (
    <>
      {isMobile && (
        <RouterLink to="/">
          <Logo style={{ paddingTop: '2em', paddingLeft: '2em' }} />
        </RouterLink>
      )}
      {!isMobile && (
        <Box mb={3} className={classes.cardHeader}>
          <Typography variant="h3">
            <FormattedMessage id="Login.ChangePassword.ChangePassword" defaultMessage="Change password" />
          </Typography>
        </Box>
      )}
      <Box className={classes.cardBody} flexGrow={1} mt={3}>
        <ChangePasswordFields
          user={myUser}
          passwordChanged={fieldStateChanged}
          isError={isError}
          setIsError={setIsError}
        />
        <PrimaryButton fullWidth disabled={isDisabled} onClick={updatePassword}>
          <FormattedMessage id="Login.ChangePassword.SetPassword" defaultMessage="Set password" />
        </PrimaryButton>
      </Box>
      <Box my={3} style={{ marginBottom: 0 }}>
        <Divider />
      </Box>
      <Box my={3} className={classes.cardAdditionalInfo}>
        <SecondaryButton fullWidth onClick={() => (window.location = '/')}>
          <FormattedMessage id="Login.ChangePassword.Back" defaultMessage="Back to login" />
        </SecondaryButton>
      </Box>
    </>
  );
}

function Login({ setForgotPassword, isMobile }) {
  const portal = usePortal();
  const backgroundUrl = portal
    ? `url("${process.env.PUBLIC_URL}resources${portal.background}") no-repeat center center fixed`
    : `url("${process.env.PUBLIC_URL}app/bg.jpg") no-repeat center center fixed`;
  const classes = useStyles();

  return (
    <>
      {isMobile && (
        <RouterLink to="/">
          <Logo style={{ paddingTop: '2em', paddingLeft: '2em' }} />
        </RouterLink>
      )}
      {!isMobile && (
        <Box mb={3} className={classes.cardHeader}>
          <Typography variant="h3">
            <FormattedMessage id="LoginView.Header.Text" defaultMessage="Zert" />
          </Typography>
        </Box>
      )}
      <Box className={classes.cardBody} flexGrow={1} mt={3}>
        <JWTLogin />
      </Box>
      <Box my={3} style={{ marginBottom: 0 }}>
        <Divider />
      </Box>
      <Box my={3} className={classes.cardAdditionalInfo}>
        <Typography variant="h4" color="textSecondary">
          <FormattedMessage id="Login.View.Trouble" defaultMessage="Having trouble logging in?" />
        </Typography>

        <PrimaryButton fullWidth onClick={() => setForgotPassword(true)}>
          <FormattedMessage id="Login.Credentials.Forgot" defaultMessage="Forgot my password" />
        </PrimaryButton>
      </Box>
    </>
  );
}

export default LoginView;
