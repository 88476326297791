import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import withSearch, { styles, controlStyles } from '../withSearch';

import SearchComponent from './SearchComponent';

class ReferencedSearch extends SearchComponent {
  constructor(props) {
    super(props);
    if (!this.state || !this.state.Referenced) {
      this.state = { Referenced: true };
    }
    this.textInput = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  onChangeState = (field, value) => {
    this.setStatus({ Referenced: value });
  };

  handleChange = (name) => (event) => {
    this.setStatus({ [name]: event.target.value });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    this.props.expanded && this.textInput.current ? this.textInput.current.blur() : this.textInput.current.focus();
  }

  render() {
    const { classes } = this.props;
    //   this.props.handleChange(this.state);
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        <FormControlLabel
          autoFocus
          inputRef={this.textInput}
          value="Referenced"
          control={
            <Radio
              color="primary"
              checked={this.state.Referenced}
              onChange={(event) => this.onChangeState('Referenced', true)}
              value={this.state.Referenced}
              name="Referenced"
              label="Referenced"
            />
          }
          label={<FormattedMessage id="search.referenced" defaultMessage="Referenced" />}
        />
        <FormControlLabel
          value="Referenced"
          control={
            <Radio
              color="primary"
              checked={!this.state.Referenced}
              onChange={(event) => this.onChangeState('Referenced', false)}
              value={!this.state.Referenced}
              name="Not Referenced"
              label="Not Referenced"
            />
          }
          label={<FormattedMessage id="search.notreferenced" defaultMessage="Not referenced" />}
        />
      </form>
    );
  }
}

ReferencedSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.referenced.header" defaultMessage="Search if referenced" />,
  index: 'referenced',
  className: 'se.zert.backend.search.ReferencedCriteria',
  default: {
    Referenced: true
  }
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles)(ReferencedSearch), selectData))
);
