import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import DialogColors from "@zert-packages/components/dialog/DialogColors";
import { Dialog, TextField } from "@mui/material";
import PaletteIcon from "@mui/icons-material/Palette";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 400,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700
  },
  wrapperInput: {
    display: "grid",
    gridTemplateColumns: "130px 30px 1fr 25px",
    gridColumnGap: 10,
    alignItems: "center"
  },
  bgBlockSelected: {
    height: 26.63,
    width: "100%",
    border: "1px solid #000000",
    borderRadius: 5
  },
  noteActiveInputWrapper: {
    "& input": {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15
    },
    "& textarea": {
      overflow: "hidden"
    },
    "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  notesInput: {
    margin: 0,
    width: "100%"
  },
  wrapper: {
    display: "grid",
    gridRowGap: 5
  },
  wrapperDropMenu: {
    display: 'grid',
    gridTemplateColumns: '130px 1fr',
    gridColumnGap: 10,
    alignItems: 'center',
  },
  text: {
    fontWeight: 700,
    fontSize: 14,
    marginBottom: 0,
  },
  wrapperButton: {
    display: "flex",
    justifyContent: "end",
    paddingRight: 20,
    marginTop: 20,
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

const DialogColorRow = ({setColor, title, row}) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [background, setBackground] = useState('');

  const dataDrop = [{ name: "None" }, { name: "Auto" }, { name: "Always" }];

  const handleClose = () => {
    setOpen(false);
  };

  const handleColor = (val) => {
    setBackground(val)
  }


  const handleColorDialog = () => {
    renderInExceptionRoot(DialogColors, {
      text: {
        label: 'Select Color',
      },
      setText: handleColor
    })
  };

  const handleSaveColor = () => {
    setOpen(false)
    if(background.length > 0){
      setColor(background)
    }
  }


  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div>
      <div className={cl.wrapperTitle}>
        <span className={cl.title}>{title}</span>
        <CloseIcon onClick={handleClose} />
      </div>
      <div className={cl.wrapper}>
        {row && <div className={cl.wrapperDropMenu}>
          <p className={cl.text}>Keep with next:</p>
          <ExpanderItem propsLabel={dataDrop} valueProps={"None"} />
        </div>}
        <div className={cl.wrapperInput}>
          <p className={cl.text}>Background color:</p>
          <div className={cl.bgBlockSelected} style={{ backgroundColor: background }}></div>
          <div className={cl.noteActiveInputWrapper}>
            <TextField
               value={background}
              className={cl.notesInput}
              variant="outlined"
              onChange={(e) => setBackground(e.target.value)}
            />
          </div>
          <PaletteIcon
            onClick={() => handleColorDialog()}
            style={{ color: "gray", cursor: 'pointer' }}
          />
        </div>
      </div>
      <div className={cl.wrapperButton}>
        <Button className={cl.button} onClick={handleSaveColor}>
          OK
        </Button>
        <Button className={cl.button} onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </div>
  </Dialog>);
};

export default DialogColorRow;
