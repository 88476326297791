import getTranslation from '@zert-packages/utils/getTranslation.new';

export const getLabelByDefinition = (value, definition) => {
  if (definition && definition.enums) {
    const myEnum = definition.enums.find((val) => value === val.identifier);
    if (myEnum) {
      return getTranslation(myEnum.name);
    }
  }
  return value;
};
