export default function getTranslation(label) {
  const language = document.getElementsByTagName('html')[0].getAttribute('lang');
  if (label.locales === null) {
    return '';
  }
  if (!label.locales) {
    return label;
  }
  const foundLocaleIndex = label.locales.findIndex((locale) => locale === language);
  const hasCurrentLanguage = foundLocaleIndex !== -1;
  if (hasCurrentLanguage) {
    const foundTranslation = label.translations[foundLocaleIndex];
    const isFoundTranslationNotEmpty = foundTranslation.trim().length !== 0;
    if (isFoundTranslationNotEmpty) {
      return foundTranslation;
    }
  }

  return label.translations[0];
}
