import React, { useContext } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { handleParagraphValue } from '../../../utils/helpers';
import { TabsContext } from '../../../Stylesheet';
import Translatable from '../Translatable';
import Font from '../../table/Font';
import Decorations from '../../table/decorations/Decorations';
import GroupBlock from '../../blockTabs/pageLayout/GroupBlock';
import TextFlow from '../../table/TextFlow';
import TemplateItemRow from '../../../TemplateItemRow';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 10
  },
  wrapperRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10
  }
}));

function TabsItem({ value, type, templatesIdx }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);

  const spacingProps = [
    { label: 'Left:', value: value.paddingLeft, type: 'paddingLeft' },
    { label: 'Right:', value: value.paddingRight, type: 'paddingRight' },
    { label: 'Top:', value: value.paddingTop, type: 'paddingTop' },
    { label: 'Bottom:', value: value.paddingBottom, type: 'paddingBottom' }
  ];

  const handleNumbering = (val, name) => {
    handleParagraphValue(val, type, 'paragraphSettings', templatesIdx, values, setValues, name);
  };

  const handleSettingsRows = (e) => {
    handleParagraphValue(e.target.checked, type, 'paragraphSettings', templatesIdx, values, setValues, 'visible');
  };

  const handleValue = (val, name) => {
    handleParagraphValue(val, type, 'paragraphSettings', templatesIdx, values, setValues, name);
  };
  const handleValuesSpacing = (val, name) => {
    const res = { ...value[name], value: +val };
    handleParagraphValue(res, type, 'paragraphSettings', templatesIdx, values, setValues, name);
  };
  const handleDropMenuSpacing = (dropMenu, name, val) => {
    const res = { unit: dropMenu, value: +val };
    handleParagraphValue(res, type, 'paragraphSettings', templatesIdx, values, setValues, name);
  };

  return (
    <div className={cl.root}>
      <FormControlLabel
        control={<Checkbox onChange={handleSettingsRows} checked={value.visible} />}
        label={<span>Use label</span>}
      />
      {value.width && (
        <TemplateItemRow
          label="Width:"
          type="width"
          value={value.width}
          setValueProps={handleValuesSpacing}
          setDropMenu={handleDropMenuSpacing}
          disabled={!value.visible}
          grid="100px 1fr 55px"
        />
      )}
      <Translatable
        title="Content:"
        selected={value.content}
        typeParents="content"
        // style="100"
        setSelected={handleNumbering}
        disabled={!value.visible}
      />
      <Translatable
        title="for lists:"
        selected={value.listContent}
        typeParents="listContent"
        // style="100"
        setSelected={handleNumbering}
        disabled={!value.visible}
      />
      <div className={cl.wrapperRow}>
        <Font disabled={!value.visible} type={type} table={value} setValueProps={handleValue} />
        <TextFlow type={type} table={value} disabled={!value.visible} setProps={handleValue} />
      </div>
      <div className={cl.wrapperRow}>
        <Decorations disabled={!value.visible} type={type} table={value} setTable={handleValue} />
        <GroupBlock
          label="Spacing to contents"
          valueProps={spacingProps}
          style={{ height: 'Calc(100% - 26px)', gridAutoRows: 'min-content' }}
          disabled={!value.visible}
          setValueProps={handleValuesSpacing}
          setDropMenu={handleDropMenuSpacing}
        />
      </div>
    </div>
  );
}

export default TabsItem;
