import React, { useState, Fragment, useEffect, useRef } from "react";
import { Menu, MenuItem, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  dropMenuButton: {
    width: '100%',
    justifyContent: 'space-between',
    border: '1px solid #DCE3E5',
    color: '#8B8B8B',
    fontWeight: '400',
    paddingBottom: 2,
    paddingTop: 2,
    background: '#ffffff',
    paddingRight: 15,
    borderRadius: 0,
    textTransform: 'capitalize',
    outline: 'none !important',
    '&:hover': {
      background: '#ffffff'
    },
    '& span:nth-child(2)': {
      display: 'none'
    }
  },
  menuLinkSiteWrapper:  (props) => ({
    borderRadius: 0,
    // width: 470,
    width: props.width ? `${props.width}px` : '100%',
    maxHeight: props.height ? `${props.height}px` : '100%',

    border: '1px solid #DCE3E5',
    boxShadow: 'none',
  })
}));

function DropMenu({ selected='', dropItem, setDropItem, height, name, width, style,  }) {
  const [select, setSelect] = useState({
    width: null,
  })
  const cl = useStyles({  height, width: select.width, } );
  const [open, setOpen] = useState(null);

  const refRoot = useRef(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleSelectedLanguage = (el) => {
    if( (el.name && (el.name === selected?.name ))|| el === selected){
      return
    }
    setOpen(null);
    if(setDropItem){
      setDropItem(el, name);
    }
  };

  const handleResize = () => {
    if (refRoot.current) {
      setSelect({
        width: width ? width : refRoot.current.offsetWidth,
      })
    }
  };

  useEffect(() => {
    handleResize();
  }, [refRoot.current, width]);



  return (
    <>
      <Button
        ref={refRoot}
        onClick={handleOpen}
        className={cl.dropMenuButton}
        style={style}
      >
        { selected?.name ? selected.name: selected}
        <ArrowDropDownIcon />
      </Button>
      <Menu
        anchorEl={open}
        open={Boolean(open)}
        onClose={() => setOpen(null)}
        classes={{
          paper: cl.menuLinkSiteWrapper
        }}
      >
        {dropItem?.length > 0 && dropItem.map((el, i) => (
          <MenuItem key={i} onClick={() => handleSelectedLanguage(el)} disabled={!!el.disable}>
            {el.name  ? el.name :  el}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default DropMenu;
