import { loadIndex, loadLayout, turnOffCLMLoader, turnOnCLMLoader } from '../TranslationJobPlugin/tjReducers';
import { getLayout } from './API';

export const fetchLayout = (versionId, callback) => (dispatch) => {
  dispatch(turnOnCLMLoader());
  getLayout(versionId)
    .then((res) => {
      dispatch(loadLayout(res));
    })
    .then(() => {
      dispatch(turnOffCLMLoader());
    });
};
