import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import DeleteIcon from '@mui/icons-material/Delete';
import dialogCustom from '@zert-packages/components/common/dialogCustom';
import DeleteConfirmDialog from '@zert-packages/components/shared/DeleteConfirmDialog';
import { FormattedMessage } from 'react-intl';
import { CatalogContext } from '../common/CatalogProvider';
import * as treeMethods from '../helpers/treeMethods';
import { getDefaultNode } from '../assets/CONSTS';
import { addNodeChildren } from '../helpers/treeMethods';

const useStyles = makeStyles({
  button: {
    display: 'flex',
    justifyContent: 'flex-start'
  }
});

function CatalogTreeActionsPanel(props) {
  const classes = useStyles();
  const { selectedNode, globalTree, handleRenderTree, setSelectedNode, setSelectedParentNode, isEverythingDisabled } =
    useContext(CatalogContext);

  function addNodeBefore() {
    const newNode = getDefaultNode();
    newNode.uuid = uuidv4();
    newNode.metadata[0].value = uuidv4();
    treeMethods.addNodeBefore(globalTree.rootNode, selectedNode, newNode);
    handleRenderTree();
  }

  function addNodeAfter() {
    const newNode = getDefaultNode();
    newNode.uuid = uuidv4();
    newNode.metadata[0].value = uuidv4();
    treeMethods.addNodeAfter(globalTree.rootNode, selectedNode, newNode);
    handleRenderTree();
  }

  function moveNodeInside() {
    treeMethods.moveNodeInside(globalTree.rootNode, selectedNode, setSelectedParentNode);
    handleRenderTree();
  }

  function moveNodeOutside() {
    treeMethods.moveNodeOutside(globalTree.rootNode, selectedNode);
    handleRenderTree();
  }

  function handleRemoveNode() {
    function removeNode() {
      treeMethods.findNodeAndApplyMethod(globalTree.rootNode, selectedNode, treeMethods.removeNode);
      setSelectedNode(null);
      handleRenderTree();
    }

    dialogCustom(DeleteConfirmDialog, { onDelete: removeNode });
  }

  function moveNodeUp() {
    treeMethods.findNodeAndApplyMethod(globalTree.rootNode, selectedNode, treeMethods.moveNodeUp);
    handleRenderTree();
  }

  function moveNodeDown() {
    treeMethods.findNodeAndApplyMethod(globalTree.rootNode, selectedNode, treeMethods.moveNodeDown);
    handleRenderTree();
  }

  const addSubNode = () => {
    const newNode = getDefaultNode();
    newNode.uuid = uuidv4();
    newNode.metadata[0].value = uuidv4();
    addNodeChildren(globalTree.rootNode, selectedNode, [newNode]);
    handleRenderTree();
  };
  const isDisabled = isEverythingDisabled || selectedNode === null || selectedNode.uuid === globalTree.rootNode.uuid;

  return (
    <div className="tree-control-panel__container">
      <Button
        className={classes.button}
        size="small"
        startIcon={<AddIcon color="inherit" />}
        onClick={addSubNode}
        disabled={isEverythingDisabled || selectedNode === null}
      >
        <FormattedMessage id="structure.addsubnode" defaultMessage="Add sub-node" />
      </Button>
      <Button
        className={classes.button}
        size="small"
        startIcon={<AddIcon color="inherit" />}
        onClick={addNodeBefore}
        disabled={isDisabled}
      >
        <FormattedMessage id="structure.addnodebefore" defaultMessage="Add node before" />
      </Button>
      <Button
        className={classes.button}
        size="small"
        startIcon={<AddIcon color="inherit" />}
        onClick={addNodeAfter}
        disabled={isDisabled}
      >
        <FormattedMessage id="structure.addnodeafter" defaultMessage="Add node after" />
      </Button>

      <Button
        className={classes.button}
        size="small"
        startIcon={<ArrowUpwardIcon color="inherit" />}
        onClick={moveNodeUp}
        disabled={isDisabled}
      >
        <FormattedMessage id="structure.movenodeup" defaultMessage="Move node up" />
      </Button>
      <Button
        className={classes.button}
        size="small"
        startIcon={<ArrowDownwardIcon color="inherit" />}
        onClick={moveNodeDown}
        disabled={isDisabled}
      >
        <FormattedMessage id="structure.movenodedown" defaultMessage="Move node down" />
      </Button>

      <Button
        className={classes.button}
        size="small"
        startIcon={<FormatIndentDecreaseIcon color="inherit" />}
        onClick={moveNodeOutside}
        disabled={isDisabled}
      >
        <FormattedMessage id="structure.movenodeoutside" defaultMessage="Move node outside" />
      </Button>
      <Button
        className={classes.button}
        size="small"
        startIcon={<FormatIndentIncreaseIcon color="inherit" />}
        onClick={moveNodeInside}
        disabled={isDisabled}
      >
        <FormattedMessage id="structure.movenodeinside" defaultMessage="Move node inside" />
      </Button>

      <Button
        className={classes.button}
        size="small"
        startIcon={<DeleteIcon color="inherit" />}
        onClick={handleRemoveNode}
        disabled={isDisabled}
      >
        <FormattedMessage id="structure.removenode" defaultMessage="Remove node" />
      </Button>
    </div>
  );
}

export default CatalogTreeActionsPanel;
