import React from 'react';
import IdSearchCriteria from '../CountersSearchCriteria/IdSearchCriteria';
import StatusSearchCriteria from '../CountersSearchCriteria/StatusSearchCriteria';
import VersionSearchCriteria from '../CountersSearchCriteria/VersionSearchCriteria';
import ContextSearchCriteria from '../CountersSearchCriteria/ContextSearchCriteria';
import DateSearchCriteria from '../CountersSearchCriteria/DateSearchCriteria';
import ElementTypeSearchCriteria from '../CountersSearchCriteria/ElementTypeSearchCriteria';
import NameSearchCriteria from '../CountersSearchCriteria/NameSearchCriteria';
import RiskRowCriteria from '../CountersSearchCriteria/RiskRowCriteria';

import ChecklistSearchCriteria from '../CountersSearchCriteria/ChecklistSearchCriteria';

import RiskAssesmentSearchCriteria from '../CountersSearchCriteria/RiskAssementSearchCriteria';
import TemplateSearchCriteria from '../CountersSearchCriteria/TemplateSearchCriteria';

function CountersSearchContainer({ getCriteria, updateCriteria, onChangeExpansionOfCriteria, isEverythingDisabled }) {
  return (
    <>
      <NameSearchCriteria
        foundCriteria={getCriteria('se.zert.backend.search.NameCriteria')}
        classNameOfCriteria="se.zert.backend.search.NameCriteria"
        updateCriteria={updateCriteria}
        onChangeExpansionOfCriteria={onChangeExpansionOfCriteria}
        isEverythingDisabled={isEverythingDisabled}
      />
      <ContextSearchCriteria
        foundCriteria={getCriteria('se.zert.backend.search.InsideContextElementCriteria')}
        classNameOfCriteria="se.zert.backend.search.InsideContextElementCriteria"
        updateCriteria={updateCriteria}
        onChangeExpansionOfCriteria={onChangeExpansionOfCriteria}
        isEverythingDisabled={isEverythingDisabled}
      />
      <IdSearchCriteria
        foundCriteria={getCriteria('se.zert.backend.search.IdCriteria')}
        classNameOfCriteria="se.zert.backend.search.IdCriteria"
        updateCriteria={updateCriteria}
        onChangeExpansionOfCriteria={onChangeExpansionOfCriteria}
        isEverythingDisabled={isEverythingDisabled}
      />
      <VersionSearchCriteria
        foundCriteria={getCriteria('se.zert.backend.search.VersionCriteria')}
        classNameOfCriteria="se.zert.backend.search.VersionCriteria"
        updateCriteria={updateCriteria}
        onChangeExpansionOfCriteria={onChangeExpansionOfCriteria}
        isEverythingDisabled={isEverythingDisabled}
      />
      <StatusSearchCriteria
        foundCriteria={getCriteria('se.zert.backend.search.StatusCriteria')}
        classNameOfCriteria="se.zert.backend.search.StatusCriteria"
        updateCriteria={updateCriteria}
        onChangeExpansionOfCriteria={onChangeExpansionOfCriteria}
        isEverythingDisabled={isEverythingDisabled}
      />
      <DateSearchCriteria
        foundCriteria={getCriteria('se.zert.backend.search.DateCriteria')}
        classNameOfCriteria="se.zert.backend.search.DateCriteria"
        updateCriteria={updateCriteria}
        onChangeExpansionOfCriteria={onChangeExpansionOfCriteria}
        isEverythingDisabled={isEverythingDisabled}
      />
      <ElementTypeSearchCriteria
        foundCriteria={getCriteria('se.zert.backend.search.ElementTypeCriteria')}
        classNameOfCriteria="se.zert.backend.search.ElementTypeCriteria"
        updateCriteria={updateCriteria}
        onChangeExpansionOfCriteria={onChangeExpansionOfCriteria}
        isEverythingDisabled={isEverythingDisabled}
      />
      <RiskRowCriteria
        foundCriteria={getCriteria('se.zert.rm.search.RiskRowCriteria')}
        classNameOfCriteria="se.zert.rm.search.RiskRowCriteria"
        updateCriteria={updateCriteria}
        onChangeExpansionOfCriteria={onChangeExpansionOfCriteria}
        isEverythingDisabled={isEverythingDisabled}
      />
      <TemplateSearchCriteria
        foundCriteria={getCriteria('se.zert.rm.search.TemplatesCriteria')}
        classNameOfCriteria="se.zert.rm.search.TemplatesCriteria"
        updateCriteria={updateCriteria}
        onChangeExpansionOfCriteria={onChangeExpansionOfCriteria}
        isEverythingDisabled={isEverythingDisabled}
      />

      <ChecklistSearchCriteria
        foundCriteria={getCriteria('se.zert.rm.search.ChecklistCriteria')}
        classNameOfCriteria="se.zert.rm.search.ChecklistCriteria"
        updateCriteria={updateCriteria}
        onChangeExpansionOfCriteria={onChangeExpansionOfCriteria}
        isEverythingDisabled={isEverythingDisabled}
      />

      <RiskAssesmentSearchCriteria
        foundCriteria={getCriteria('se.zert.backend.search.RiskAnalisisCriteria')}
        classNameOfCriteria="se.zert.backend.search.RiskAnalisisCriteria"
        updateCriteria={updateCriteria}
        onChangeExpansionOfCriteria={onChangeExpansionOfCriteria}
        isEverythingDisabled={isEverythingDisabled}
      />
    </>
  );
}

export default CountersSearchContainer;
