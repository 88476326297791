import React, {useContext, useEffect, useState} from 'react';
import makeStyles from "@mui/styles/makeStyles";
import {useDispatch} from "react-redux";
import LangBlock from "./LangBlock";




const useStyles = makeStyles((theme) => ({
    root : {

    }
}))

const LanguagesWrapper = ({context, setSelectedRange,selectedRange , el}) =>{

    const dispatch = useDispatch();

    const { values, setValues } = useContext(context);
    const cl = useStyles()



    const check = (it) =>{
        const country = it?.country ? `_${it.country}`: ''
        return values?.selectedPhases?.items[0].elementInfo.locale !== `${it.language}${country}`
    }

    return (
        <div className={cl.root}>
            {values.languages.filter(it=> check(it)).map((it,i) =>(
                <LangBlock versionId={el?.elementInfo?.versionId}
                           newLang={it.newLang}
                           setSelectedRange={setSelectedRange}
                           selectedRange={selectedRange}
                           i={i}
                           lang={it} key={`${it.country}${i}`} context={context}/>
            ))
            }
        </div>
    );
};

export default LanguagesWrapper;