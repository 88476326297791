import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import TextField from '@mui/material/TextField';
import { findMetaDefinitionByIdentifier } from '@zert-packages/plugins/ExplorerPlugin/SearchConditions/MetadataEditor/helpers/findMetaDefinitionByIdentifier';
import { findMetadataStructureByIdentifier } from '@zert-packages/plugins/ExplorerPlugin/SearchConditions/MetadataEditor/helpers/findMetadataStructureByIdentifier';
import { findMetaCaptionByIdentifier } from '@zert-packages/plugins/ExplorerPlugin/SearchConditions/MetadataEditor/helpers/findMetaCaptionByIdentifier';
import getTranslation from '@zert-packages/utils/getTranslation.new';

const useStyles = makeStyles({
  DialogContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    '& > *': {
      marginTop: 10
    }
  }
});

function MetadataEditorDialog({ unmountComponent, metadata: metadataInitial, onConfirm }) {
  const cl = useStyles();
  const metadataTemplates = useSelector((state) => state.metadataTemplates);

  const [metadata, setMetadata] = useState(metadataInitial);
  const [metadataDefinition, setMetadataDefinition] = useState(
    metadataInitial && findMetaDefinitionByIdentifier(metadataInitial.identifier, metadataTemplates)
  );
  const [metadataStructure, setMetadataStructure] = useState(
    metadataInitial && findMetadataStructureByIdentifier(metadataInitial.identifier, metadataTemplates)
  );

  const updateMetadata = (key, value) =>
    setMetadata((prev) => ({
      ...prev,
      [key]: value
    }));

  const handleConfirm = () => {
    unmountComponent();
    onConfirm(metadata);
  };

  const confirmDisabled = metadata == null;

  return (
    <Dialog open onClose={unmountComponent} fullWidth>
      <DialogTitle>
        <FormattedMessage id="MetadataEditorDialog.Title" defaultMessage="Add metadata" />
      </DialogTitle>
      <DialogContent className={cl.DialogContent}>
        {metadataTemplates && (
          <FormControl>
            <InputLabel>
              <FormattedMessage id="MetadataEditor.Template" defaultMessage="Select template" />
            </InputLabel>
            <Select
              onChange={(event) => {
                setMetadataStructure(event.target.value);
              }}
              value={metadataStructure}
              autoWidth
              input={<Input shrink />}
            >
              {metadataTemplates.templates.map((item, index) => (
                <MenuItem value={item} index={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {metadataStructure && (
          <FormControl>
            <InputLabel>
              <FormattedMessage id="MetadataEditor.identifier" defaultMessage="Select identifier" />
            </InputLabel>
            <Select
              onChange={(event) => {
                setMetadata({ identifier: event.target.value.identifier });
                setMetadataDefinition(event.target.value);
              }}
              value={metadataDefinition}
              autoWidth
              input={<Input shrink />}
            >
              {metadataStructure.metadataDefinitions.map((item, index) => (
                <MenuItem value={item} index={index}>
                  {findMetaCaptionByIdentifier(item.names)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {metadataDefinition && (
          <div
            style={{ wordBreak: 'break-word', width: '100%' }}
            dangerouslySetInnerHTML={{ __html: getTranslation(metadataDefinition.description) }}
          />
        )}
        {metadataDefinition && !metadataDefinition.enums && (
          <TextField
            value={metadata.value}
            label={<FormattedMessage id="Metadata.Value" defaultMessage="Value" />}
            onChange={(e) => updateMetadata('value', e.target.value)}
          />
        )}

        {metadataDefinition && metadataDefinition.enums && (
          <FormControl>
            <InputLabel>
              <FormattedMessage id="MetadataEditor.select" defaultMessage="Select value" />
            </InputLabel>
            <Select
              onChange={(event) => {
                updateMetadata('value', event.target.value);
              }}
              value={metadata && metadata.value}
              autoWidth
              input={<Input shrink />}
            >
              {metadataDefinition.enums.map((item, index) => (
                <MenuItem value={item.identifier} index={index}>
                  {getTranslation(item.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {metadataDefinition && (
          <TextField
            value={metadata.comment}
            label={<FormattedMessage id="Metadata.Comment" defaultMessage="Comment" />}
            onChange={(e) => updateMetadata('comment', e.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={confirmDisabled} onClick={handleConfirm} color="primary" variant="outlined">
          <FormattedMessage id="MetadataEditorDialog.Confirm" defaultMessage="Confirm" />
        </Button>
        <Button onClick={unmountComponent} color="primary">
          <FormattedMessage id="MetadataEditorDialog.Cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default MetadataEditorDialog;
