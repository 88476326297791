import { memo } from 'react';

const compareFunc =
  (...compareFunctions) =>
    (prevProps, nextProps) => {
      for (let compareFuncIndex = 0; compareFuncIndex < compareFunctions.length; compareFuncIndex++) {
        const compareFunc = compareFunctions[compareFuncIndex];
        if (compareFunc(prevProps) !== compareFunc(nextProps)) return false;
      }
      return true;
    };

const selectiveMemoComparison =
  (Component) =>
    (...compareFunctions) =>
      memo(Component, compareFunc(...compareFunctions));

export default selectiveMemoComparison;
