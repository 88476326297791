import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import CatalogPlacement from '@zert-packages/components/shared/CatalogPlacement/CatalogPlacement';
import getMultipleValue from '@zert-packages/utils/getMultipleValue';
import withSearch, { styles, controlStyles } from '../withSearch';

import SearchComponent from './SearchComponent';

class NodeSearchPanel extends SearchComponent {
  constructor(props) {
    super(props);
    if (!this.state || !this.state.NodeUUID) {
      this.state = {
        ScopeType: 'node',
        NodeUUID: getMultipleValue(this.props.catalog)
      };
    }
    this.textInput = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (data) => {
    this.setStatus({ NodeUUID: data });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    this.props.expanded && this.textInput.current ? this.textInput.current.blur() : this.textInput.current.focus();
  }

  render() {
    //  this.props.handleChange(this.state);
    const { classes } = this.props;
    return (
      <form onSubmit={this.onSubmit}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel htmlFor="search-node">
            <FormattedMessage id="search.node.type" defaultMessage="Search in" />{' '}
          </InputLabel>
          <Select
            autoFocus
            size="small"
            inputRef={this.textInput}
            value={this.state.ScopeType}
            onChange={(e) => {
              this.setStatus({ ScopeType: e.target.value });
            }}
            inputProps={{
              name: 'search-method',
              id: 'search-node'
            }}
          >
            <MenuItem value="node">
              <em>
                <FormattedMessage id="search.node.insideNode" defaultMessage="In node" />
              </em>
            </MenuItem>
            <MenuItem value="node_and_subnode">
              <em>
                <FormattedMessage id="search.node.nodeAndSubNode" defaultMessage="Node and subnode" />
              </em>
            </MenuItem>
          </Select>
        </FormControl>

        <CatalogPlacement
          assignChagedValues={this.handleChange}
          multipleValue={this.state.NodeUUID}
          catalog={this.props.catalog}
          loading={this.props.loadingCatalog}
        />
      </form>
    );
  }
}

NodeSearchPanel.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.catalog.header" defaultMessage="Search inside catalog" />,
  index: 'search-nodes',
  className: 'se.zert.backend.search.CatalogCriteria',

  default: {
    NodeUUID: null,
    ScopeType: 'node'
  }
};
const mapStateToProps = (state) => ({
  catalog: state.catalog,
  loadingCatalog: Object.values(state.loadingCatalogs)
    .reduce((a, v) => a || v, false)
});
export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles)(NodeSearchPanel), selectData))
);
