import IconButton from '@mui/material/IconButton';
import React, {useRef, useState} from 'react';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage } from 'react-intl';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import makeStyles from '@mui/styles/makeStyles';
import {
  ArrowBack,
  CardGiftcardOutlined,
  Close,
  FeaturedPlayListOutlined,
  PermPhoneMsgOutlined,
  Slideshow
} from '@mui/icons-material';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import axiosInstance from '@zert-packages/utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useTimeout } from 'usehooks-ts';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { getAllRoutes } from '@zert-packages/actions/api';
import { updateWantToSeeIntro } from '@zert-packages/actions/commonReducer';
import Logo from '@zert-packages/plugins/auth/LoginView/Logo';
import { createPortal } from 'react-dom';

const options = [
  {
    identifier: 'start-intro',
    icon: <Slideshow />,
    label: <FormattedMessage id="start-intro" defaultMessage="Start intro" />
  },
  {
    identifier: 'helpText',
    icon: <FeaturedPlayListOutlined />,
    label: <FormattedMessage id="helpText" defaultMessage="Help text" />
  },
  {
    identifier: 'manual',
    icon: <CardGiftcardOutlined />,
    label: <FormattedMessage id="manual" defaultMessage="Manual" />
  },
  {
    identifier: 'contact-us',
    icon: <PermPhoneMsgOutlined />,
    label: <FormattedMessage id="contact-us" defaultMessage="Contact us" />
  }
];

const useStyles = makeStyles({
  help: {
    color: '#1BA1C5',
    marginRight: '20px',
    marginLeft: '5px'
  },
  helpSelected: {
    color: 'white',
    margin: '0px'
  },
  helpContainer: {
    position: 'absolute',
    zIndex: '999',
    top: '55px',
    left: '5%',
    border: '2px solid #1BA1C5',
    borderRadius: '10px',
    color: '#05364b',
    fontWeight: '800',
    backgroundColor: '#f4f8fa',
    width: '90vw',
    height: 'calc(90vh - 20px)',
    overflowY: 'auto',
    padding: '1em'
  },
  closeButton: {
    position: 'absolute',
    zIndex: '1000',
    top: '65px',
    right: '5vw'
  }
});

const saveData = (function () {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  return function (blob, fileName) {
    // var// json = JSON.stringify(data),
    // blob = new Blob([json], {type: "octet/stream"}),
    const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
    //   url = window.URL.createObjectURL(blob);
    // a.href = url;
    // a.download = fileName;
    window.open(url, '_blank');

    // window.open(url);
    // window.URL.revokeObjectURL(url);
  };
})();

function getRelativePos(id) {
  const elm = document.getElementById(id);
  if (!elm) {
    return { top: 0 };
  }
  const pPos = elm.parentNode.getBoundingClientRect(); // parent pos
  const cPos = elm.getBoundingClientRect(); // target pos
  const pos = {};

  pos.top = cPos.top - pPos.top + elm.parentNode.scrollTop;
  pos.right = cPos.right - pPos.right;
  pos.bottom = cPos.bottom - pPos.bottom;
  pos.left = cPos.left - pPos.left;

  return pos;
}

function HelpText({ helpText, handleClose }) {
  const cl = useStyles();
  const helpTextAnchor = useRef();

  const location = useLocation();
  const match = getAllRoutes()
    .map((route) => ({
      match: useRouteMatch({
        path: route.to,
        exact: true
      }),
      plugin: route.plugin,
      helpId: route.helpId ? route.helpId : route.plugin.helpId ? route.plugin.helpId : route.plugin.mimeType
    }))
    .find((route) => route.match != null && route.helpId != null);
  useTimeout(() => {
    if (helpTextAnchor && helpTextAnchor.current && helpText && match) {
      //  console.log(location);
      // console.log(versionId);
      // console.log(url);

      //  console.log(match);
      const anchorPos = getRelativePos(match.helpId);
      if (anchorPos) {
        helpTextAnchor.current.scrollTo({
          // top : 100,
          duration: 100,
          top: anchorPos.top,
          delay: 0,
          smooth: 'easeInOutQuart'
        });
      }
      // helpTextAnchor.current.focus();
    }
  }, 500);
  return (
    <div>
      <Button
          style={{
              float: 'right',
              color: 'black'
          }}
          className={cl.closeButton}
          size="small"
          endIcon={<Close color="inherit" />}
          onClick={handleClose}
      />
      <div className={cl.helpContainer} ref={helpTextAnchor}
           dangerouslySetInnerHTML={{ __html: helpText }} />
    </div>);
}

function HelpButton({
  logo,

  numberMainList = 0,
  mainList = [],
  createNewList = [],
  additionalList = [],
  onSelect = () => {},

}) {
  const { mylocale } = useSelector((state) => state);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const cl = useStyles();
  const [anchorElement, setAnchorElement] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [helpText, setHelpText] = useState(null);
  const [showHelp, setShowHelp] = useState(false);
  const handleMenuItemClick = async (event, index) => {
    setSelectedIndex(index);
    if (index == 'helpText' || index == 'contact-us') {
      onSelect(true);
      setShowHelp(true);
      if (index == 'helpText') {
        const html = await axiosInstance.get(`/help/whole_system_${mylocale}.html`);
        setHelpText(html.data);
      } else {
        const html = await axiosInstance.get(`/help/contact_${mylocale}.html`);
        setHelpText(html.data);
      }
    } else if (index == 'manual') {
      const pdf = await axiosInstance.get(`/help/manual-${mylocale}.pdf`, {
        responseType: 'arraybuffer'
      });
      saveData(pdf.data, 'manual.pdf');
    } else if (index == 'start-intro') {
      onSelect(false);
      setShowHelp(false);
      handleClose(event);
      setOpen(false);
      dispatch(updateWantToSeeIntro(false));
      setTimeout(() => {
        dispatch(updateWantToSeeIntro(true));
      }, 1000);
    } else {
      onSelect(false);
      setShowHelp(false);
      handleClose(event);
      setOpen(false);
    }
  };

  const handleToggle = (event) => {
    if (showHelp) {
      setShowHelp(false);
      onSelect(false);
    }
    setAnchorElement(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setAnchorElement(null);
    setOpen(false);
    setShowHelp(false);
    //  onSelect(false);
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
    }
  };



  return (
    <>
      {!showHelp && <IconButton
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          size="small"
          className={!showHelp ? cl.help : cl.helpSelected}
        >
          <HelpOutlineIcon />
        </IconButton>}
      <Popper
        open={open}
        placement="bottom-end"
        anchorEl={anchorElement}
        role={undefined}
        transition
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-end' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              {!showHelp && (
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.identifier}
                        selected={option.identifier === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, option.identifier)}
                      >
                        {option.icon}
                        <span style={{ marginLeft: '10px' }}>{option.label}</span>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              )}
              {showHelp && helpText &&
                  createPortal(<HelpText
                      helpText={helpText}
                      handleClose={handleClose}
                  />, document.body)}
              {showHelp && !helpText && <Loader />}
            </Paper>
          </Grow>
        )}
      </Popper>
      {!showHelp &&  (
        <Link to="/about-zert" className="logo">
          <Logo filename="sidebar-logo.png" />
        </Link>
      )}
    </>
  );
}

export default HelpButton;
