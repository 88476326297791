export const normalData = {
  class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientNormalTableSettingsTemplate',
  identifier: 'data-gridless',
  label: 'Data utan ram',
  spanAll: false,
  hideEmptyRows: false,
  hideEmptyColumns: false,
  paddingTop: {
    value: 0,
    unit: 'POINTS'
  },
  paddingBottom: {
    value: 0,
    unit: 'POINTS'
  },
  paddingLeft: {
    value: 0,
    unit: 'POINTS'
  },
  paddingRight: {
    value: 0,
    unit: 'POINTS'
  },
  backgroundColor: null,
  widowContentLimit: null,
  orphanContentLimit: null,
  headerRowCount: 0,
  evenSameAsOdd: true,
  differentLastRow: false,
  insertEmptyEven: false,
  headerRow: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientNormalTableRowTemplate',
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    horizontalAlign: 'LEFT',
    verticalAlign: 'MIDDLE',
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    },
    spaceBefore: {
      name: 'before',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    spaceAfter: {
      name: 'after',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    borderTop: {
      edge: 'top',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderBottom: {
      edge: 'bottom',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderLeft: {
      edge: 'left',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderRight: {
      edge: 'right',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderCenter: {
      edge: 'center',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderMiddle: {
      edge: 'middle',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    paddingTop: {
      value: 0,
      unit: 'POINTS'
    },
    paddingBottom: {
      value: 0,
      unit: 'POINTS'
    },
    paddingLeft: {
      value: 0,
      unit: 'POINTS'
    },
    paddingRight: {
      value: 0,
      unit: 'POINTS'
    }
  },
  oddRow: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientNormalTableRowTemplate',
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    horizontalAlign: 'LEFT',
    verticalAlign: 'MIDDLE',
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    },
    spaceBefore: {
      name: 'before',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    spaceAfter: {
      name: 'after',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    borderTop: {
      edge: 'top',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderBottom: {
      edge: 'bottom',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderLeft: {
      edge: 'left',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderRight: {
      edge: 'right',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderCenter: {
      edge: 'center',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderMiddle: {
      edge: 'middle',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    paddingTop: {
      value: 4,
      unit: 'POINTS'
    },
    paddingBottom: {
      value: 4,
      unit: 'POINTS'
    },
    paddingLeft: {
      value: 4,
      unit: 'POINTS'
    },
    paddingRight: {
      value: 4,
      unit: 'POINTS'
    }
  },
  evenRow: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientNormalTableRowTemplate',
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    horizontalAlign: 'LEFT',
    verticalAlign: 'TOP',
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    },
    spaceBefore: {
      name: 'before',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    spaceAfter: {
      name: 'after',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    borderTop: {
      edge: 'top',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderBottom: {
      edge: 'bottom',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderLeft: {
      edge: 'left',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderRight: {
      edge: 'right',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderCenter: {
      edge: 'center',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderMiddle: {
      edge: 'middle',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    paddingTop: {
      value: 0,
      unit: 'POINTS'
    },
    paddingBottom: {
      value: 0,
      unit: 'POINTS'
    },
    paddingLeft: {
      value: 0,
      unit: 'POINTS'
    },
    paddingRight: {
      value: 0,
      unit: 'POINTS'
    }
  },
  lastRow: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientNormalTableRowTemplate',
    fontFamily: 'default',
    fontSize: null,
    fontStyle: [],
    color: null,
    backgroundColor: null,
    horizontalAlign: 'LEFT',
    verticalAlign: 'TOP',
    lineHeight: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
      tagName: 'line-height',
      resetValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      defaultValue: {
        class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
        type: 'DEFAULT',
        value: {
          value: 12,
          unit: 'POINTS'
        }
      },
      valueCount: 0
    },
    spaceBefore: {
      name: 'before',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    spaceAfter: {
      name: 'after',
      conditionality: 'DISCARD',
      value: null,
      minimum: null,
      maximum: null,
      precedence: 'DEFAULT'
    },
    borderTop: {
      edge: 'top',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderBottom: {
      edge: 'bottom',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderLeft: {
      edge: 'left',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderRight: {
      edge: 'right',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderCenter: {
      edge: 'center',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    borderMiddle: {
      edge: 'middle',
      width: {
        value: 0,
        unit: 'POINTS'
      },
      style: 'NONE',
      color: {
        id: -1,
        metadataList: null,
        references: {},
        properties: {},
        parents: [],
        approvedAt: null,
        archivedAt: null,
        approvedBy: null,
        archivedBy: null,
        changedBy: null,
        changedAt: null,
        createdBy: null,
        createdAt: null,
        name: null,
        translatable: false,
        checkedOut: false,
        readOnly: false,
        checkoutByUser: null,
        published: false,
        locale: null,
        label: null,
        mimeType: null,
        versionId: -1,
        additionalInfo: null,
        companyId: 0,
        versionUUID: null,
        elementUUID: null,
        currentVersionId: -1,
        cmyk: false,
        blue: 0,
        green: 0,
        alpha: 255,
        red: 0
      }
    },
    paddingTop: {
      value: 0,
      unit: 'POINTS'
    },
    paddingBottom: {
      value: 0,
      unit: 'POINTS'
    },
    paddingLeft: {
      value: 0,
      unit: 'POINTS'
    },
    paddingRight: {
      value: 0,
      unit: 'POINTS'
    }
  },
  marginLeft: {
    value: 0,
    unit: 'POINTS'
  },
  marginRight: {
    value: 0,
    unit: 'POINTS'
  },
  emptyRowHeight: {
    value: 0,
    unit: 'POINTS'
  }
};
