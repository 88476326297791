import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog } from "@mui/material";
import Button from "@mui/material/Button";

import CloseIcon from "@mui/icons-material/Close";
import WrapperLabelInputItem from "./WrapperLabelInputItem";
import getTranslation from "@zert-packages/utils/getTranslation.old";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 482,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700
  },
  wrapperButton: {
    marginTop: 10,
    display: "flex",
    justifyContent: "end",
    paddingRight: 20
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));


const DialogHandleFormat = ({ label, values, setValues, setLeftMenu, leftMenu, setSelectedListItem }) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [labelValues, setLabelValues] = useState({
    defaultLabel: "",
    locales: ["en", "sv"],
    translations: ["", ""]
  });
  const labelValue = getTranslation(labelValues)


  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    handleClose();
    const res = {
      "identifier": labelValue,
      "comboValue": labelValue,
      "label": labelValues,
      "settings": {
        "properties": []
      },
      "shortcut": {
        "modifiers": 0,
        "keyCode": 0
      },
      "references": [],
      "style": "PLAIN",
      "styleLabel": {
        "defaultLabel": null,
        "locales": [
          "en",
          "sv"
        ],
        "translations": [
          "",
          ""
        ]
      },
      "collectLanguageSeparatedChildren": false,
      "identifierWithPrefix": `${labelValue}`,

    };
    setLeftMenu((prev) => [
      ...prev,
      res
    ]);
    setSelectedListItem(leftMenu.length);
    setValues((prev) => ({ ...prev, templateList: res }));
  };




  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{ paperScrollPaper: cl.root }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>{label && label}</span>
      <CloseIcon onClick={handleClose} />
    </div>
    <WrapperLabelInputItem
      labelValues={labelValues}
      setLabelValues={setLabelValues}
    />
    <div className={cl.wrapperButton}>
      <Button
        className={cl.button}
        onClick={handleSave}
        disabled={labelValue.length === 0}
      >
        OK
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>

  </Dialog>);
};

export default DialogHandleFormat;