import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import CatalogCard from './CatalogCard';

function CatalogUserPrivileges({ catalog, selected }) {
  const findAssignments = (catalog, selectedNode) => {
    let assignments = [];
    catalog.nodesList.map((node) => {
      if (selectedNode != null && node.metadata[0].value === selectedNode.split('^')[1]) {
        assignments = node.assignments.filter(assignment => assignmentRoleExists(assignment));
      }
    });
    return assignments;
  };

  const assignmentRoleExists = (assignment) => {
    for (let i = 0; i < catalog.roles.length; i++) {
      if (catalog.roles[i].identifier === assignment.identifier) {
        return true;
      }
    }
    return false;
  }

  let assgnmts = [];

  if (catalog) {
    assgnmts = findAssignments(catalog, localStorage.getItem('selected')).map((assigny, index) => {
      return (
        <div key={assigny.user && assigny.user.userName + index} className="previleges_item">
          <div className="previleges_item_name">
            {assigny.user == null ? (
              ''
            ) : (
              <>
                <span className="previleges_item_name_fullname">{assigny.user.fullname}</span>
                <span className="previleges_item_name_username">({assigny.user.userName})</span>
              </>
            )}
          </div>
          <div className="previleges_caption">
            <i className="fas fa-cog rotateDelayed" /> {assigny.identifier}
          </div>
        </div>
      );
    });
  }

  return (
    <CatalogCard
      title={<FormattedMessage id="catalog.summary.user.caption" defaultMessage="USER PRIVILEGES" />}
      content={
        <div data-intro-id="CatalogExplorerPage.ResponsiblePerson" id="userPrivileges">
          {assgnmts.length > 0 ? (
            assgnmts
          ) : (
            <FormattedMessage
              id="catalog.missing.user.caption"
              defaultMessage="No Roles & Responsibilities configured for this node."
            />
          )}
        </div>
      }
    />
  );
}

export default CatalogUserPrivileges;
