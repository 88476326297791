import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import makeStyles from '@mui/styles/makeStyles';
import { Button } from '@mui/material';
import { IntroductionContext } from '@zert-packages/components/common/Introduction';
import TileList from '@zert-packages/components/ElementTile/TileList';
import { TASK_ACTIONS_TYPES } from '@zert-packages/components/ElementTile/shared/ELEMENT_TILE_CONTS';
import { columns } from '@zert-packages/components/ElementTile/ElementTileTable';
import SearchPane from './SearchPane';
import { searchElements, updateSearchElements } from './servicesMidleware';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  tree: {
    position: 'absolute',
    top: '60px',
    right: '15px',
    fontSize: '1rem',
    color: '#1799c7'
  },

  buttons: {
    padding: '10px 10px',
    position: 'sticky',
    bottom: '0px',
    backgroundColor: '#f4f8fa',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  searchSpan: {
    color: '#cccccc',
    fontWeight: 'bold',
    padding: '0 0 0 20px'
  },
  searchSpanSelector: {
    color: '#1798c7',
    fontWeight: 'bold'
  },
  expand: {
    fontSize: '1rem',
    color: '#1799c7'
  },
  title: {
    flex: '1 1 100%'
  },
  searchPane: {
    overflow: 'scroll',

    height: '100%',
    flexBasis: '350px',
    backgroundColor: '#f4f8fa',
    overflowX: 'scroll !important',
    paddingTop: '10px'
  }
}));

function SearchView({hide, setActiveElement}) {

  const { search } = useContext(IntroductionContext);

  const dispatch = useDispatch();
  const { searchConditions } = useSelector((state) => state.CORE);

  const [isAllCompanies, setAllCompanies] = useState(false);

  const [searchHappens, setSearchhappens] = useState(false);

  const [showTree, setShowTree] = useState(true);
  const CORE = useSelector((state) => state.CORE);
  const { searchResult, isLoadingNodeContent } = CORE;

  const explorer_infos = searchResult ? searchResult.result : [];

  const classes = useStyles();

  const startSearchOnSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setSearchhappens(true)
    dispatch(searchElements(isAllCompanies));
  };

  const reloadSearchOnSubmit = ({ actionType, onFinishState }) => {
    if (actionType === TASK_ACTIONS_TYPES.duplicate) {
      const duplicatedElement = onFinishState;
      dispatch(updateSearchElements({ count: searchResult.count + 1, result: [duplicatedElement, ...explorer_infos] }));
      return;
    }
    dispatch(searchElements(isAllCompanies));
  };
  let searchMessage = <FormattedMessage id="search.list" defaultMessage="Search result" />;
  if (searchResult !== null && searchResult.result.length > 0 && searchResult.count > searchResult.limit) {
    searchMessage = (
      <FormattedMessage
        id="search.result.limited"
        defaultMessage="Search result: found {results} objects, result limited to {limit}"
        values={{ results: searchResult.count, limit: searchResult.limit }}
      />
    );
  } else if (searchResult !== null && searchResult.result.length > 0) {
    searchMessage = (
      <FormattedMessage
        id="search.result.unlimited"
        defaultMessage="Search result: found {results} objects"
        values={{ results: searchResult.count }}
      />
    );
  } else if ((searchResult == null || searchResult.result.length == 0 ) && searchHappens && !isLoadingNodeContent) {
    searchMessage = (
        <FormattedMessage
            id="search.result.notfound"
            defaultMessage="Search result: Nothing has been found yet"

        />
    );
  }

  const filteredColumns = columns.filter(column => column.identifier !== 'lastCompletedDate');
  const columnsNew =
    searchConditions && searchConditions['se.zert.backend.search.SizeCriteria']
      ? [
          ...filteredColumns,
          {
            identifier: 'csize',
            numeric: false,
            disablePadding: false,
            align: 'right',
            isFilterable: true,
            label: <FormattedMessage id="Element.TileTable.Size" defaultMessage="Size" />
          }
        ]
      : filteredColumns;

  return (
    <div className="catalog_for_mobile" >
      {showTree && (
        <div className={classes.searchPane}>
          <MediaQuery query="(max-width: 600px)">
            <div
              className={classes.tree}
              onClick={(e) => {
                setShowTree(false);
              }}
            >
              <FormattedMessage id="search.collapse" defaultMessage="Collapse" />
            </div>
          </MediaQuery>
          <SearchPane
            key="searchPane"
            hide={hide}
            isAllCompanies={isAllCompanies}
            setAllCompanies={(isChecked) => {
              setAllCompanies(isChecked);
            }}
            startSearchOnSubmit={startSearchOnSubmit}
          />
          <div className={classes.buttons}>
            <Button onClick={startSearchOnSubmit} color="primary" variant="outlined">
              <FormattedMessage id="search.start" defaultMessage="Search" />
            </Button>
          </div>
        </div>
      )}
      <div data-intro-id="Search.TopMenu" id="workingArea" style={{ overflowY: 'hidden', height: '100%' }}>
        <MediaQuery query="(max-width: 600px)">
          <div
            className="catalog_caption"
            onClick={(e) => {
              setShowTree(true);
            }}
          />
          {!showTree && (
            <span className={classes.expand}>
              {' '}
              <FormattedMessage id="search.expand" defaultMessage="Expand" />{' '}
            </span>
          )}
        </MediaQuery>
        <div
          className="catalog_bread_crumbs"
          onClick={(e) => {
            setShowTree(true);
          }}
        />
        <TileList
          explorer_infos={explorer_infos}
          loading={isLoadingNodeContent}
          columnsNew={columnsNew}
          reloadElement={reloadSearchOnSubmit}
          showCut={false}
          fullPage={true}
          bradCrumbComponent={searchMessage}
          setActiveElement={setActiveElement}
        />
      </div>
      { search?.component}
      { search?.startIntro()}
    </div>
  );
}

export default withRouter(SearchView);
