import React, { useState } from 'react';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import UserSuggestBox from '@zert-packages/components/shared/UserSuggestBoxNew/UserSuggestBox';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import _ from 'lodash';

export const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(1) / 4
  }
}));

function InformTasksParametersDialog({ unmountComponent, onConfirm }) {
  const [message, setMessage] = useState('');
  const [userLogin, setUserLogin] = useState([]);
  const [valueClean, setValueClean] = useState(null);

  const classes = useStyles();
  const handleConfirm = () => {
    unmountComponent();
    onConfirm(
      userLogin.map((user) => user.userName),
      message
    );
  };
  return (
    <Dialog fullWidth maxWidth="md" open onClose={unmountComponent}>
      <DialogTitle>
        <FormattedMessage id="InformTasksSettingsDialog.Title" defaultMessage="Inform" />
      </DialogTitle>
      <DialogContent>
        <div className={classes.chips}>
          {userLogin.map((value) => (
            <Chip
              key={value.userName}
              label={value.name}
              onDelete={() => {
                setUserLogin((prev) => [...prev.filter((item) => item.userName != value.userName)]);
              }}
              className={classes.chip}
            />
          ))}
        </div>
        <UserSuggestBox
          autoFocus
          onChange={(user) => {
            if (user) {
              setUserLogin((prev) => [...prev, user]);
              setValueClean(user);
            }
          }}
          getUsers={getUsersQuery}
          getUserId={(user) => user.username}
          getUserLabel={(user) => user.name}
          initialValue=""
          valueClean={valueClean}
          label={<FormattedMessage id="DelegateTasksSettingsDialog.UserLabel" defaultMessage="User" />}
        />
        <div style={{ margin: '10px 0' }} />
        <TextField
          label={<FormattedMessage id="InformTasksSettingsDialog.Message" defaultMessage="Message" />}
          variant="outlined"
          fullWidth
          multiline
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows="7"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} disabled={userLogin === null} color="primary" variant="outlined">
          <FormattedMessage id="InformTasksParametersDialog.Confirm" defaultMessage="Confirm" />
        </Button>
        <Button onClick={unmountComponent} color="primary">
          <FormattedMessage id="InformTasksParametersDialog.Cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InformTasksParametersDialog;
