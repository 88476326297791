import React from 'react';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import { RiskAssessmentOverviewTile } from '../tiles/RiskAnalisysTile';
// import TranslationJobTile, {TranslationContentOverviewTile} from "../tiles/TranslationJobTile";
// import ReviewJobTile, {ReviewJobContentOverviewTile} from "../tiles/ReviewJobTile";
import { ChecklistContentOverviewTile } from '../tiles/ChecklistTile';
import { SafetyInspectionOverviewTile } from '../tiles/SafetyInspectionTile';
// import { ProjectContentOverviewTile } from '../tiles/ProjectTile';
import { ElementContentOverviewTile } from '../tiles/AbstractTile';
import { ProjectDocumentOverviewTile } from '../tiles/ProjectDocumentTile';

const getOverviewByMimeType = (props) => {
  const plugin = getPluginByMimeType(props.element.mimeType);
  if (plugin && plugin.overviewTile) {
    const OverviewTile = plugin.overviewTile;
    return <OverviewTile {...props} />;
  }

  switch (props.element.mimeType) {
    case 'application/zert-risk-analysis':
      return <RiskAssessmentOverviewTile {...props} />;

    /*  case 'application/zert-translation-job':
            return <TranslationContentOverviewTile {...props}/>;
        case 'application/zert-review-job':
            return <ReviewJobContentOverviewTile {...props}/>; */
    case 'application/zert-projectplanning':
      return <ElementContentOverviewTile {...props} />;
    case 'application/zert-checklist':
      return <ChecklistContentOverviewTile {...props} />;
    case 'application/zert-measure':
      return <ElementContentOverviewTile {...props} />;
    case 'application/zert-deviation':
      return <ElementContentOverviewTile {...props} />;
    case 'application/zert-document':
      return <ElementContentOverviewTile {...props} />;
    case 'application/zert-paper':
      return <ElementContentOverviewTile {...props} />;
    case 'application/zert-safety-inspection':
      return <SafetyInspectionOverviewTile {...props} />;

    case 'application/zert-project-document':
      return <ProjectDocumentOverviewTile {...props} />;
    default:
      return null;
  }
};

export default getOverviewByMimeType;
