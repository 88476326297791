import { useState } from 'react';

export default function usePagination(initialRowsPerPage = 10) {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  function updateCurrentPage(newCurrentPage) {
    setCurrentPage(newCurrentPage);
  }

  function updateRowsPerPage(newRowsPerPage) {
    setRowsPerPage(newRowsPerPage);
  }

  function createPage(rows) {
    return rows.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
  }

  return {
    currentPage,
    rowsPerPage,

    updateCurrentPage,
    updateRowsPerPage,
    createPage
  };
}
