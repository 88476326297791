import { handlePost, handleQuery, handleText } from '@zert-packages/actions/api';

import { stableSort } from '@zert-packages/components/ElementTile/utils/stableSort';

const startQuery = '/preview';

export function loadPreviewList() {
  return handleQuery(`${startQuery}/getPublications`);
}

export function previewOnExternalServer(reportUUID, settings) {
  return handlePost(`${startQuery}/previewOnExternalServer/${reportUUID}`, settings);
}

export function getProgressStatus(reportUUID) {
  return handleQuery(`${startQuery}/getProgressStatus/${reportUUID}`);
}

export function loadLayouts() {
  return handleQuery(`${startQuery}/loadLayouts`);
}

export function loadAllLayouts() {
  return handleQuery(`${startQuery}/loadAllLayouts`);
}

export function loadCompareWithVersions(versionId) {
  return handleQuery(`${startQuery}/getCompareVersions/${versionId}`).then((result) => {
    return stableSort(result, (a, b) => {
      if (a.label == b.label) {
        return 0;
      }

      // Special sorting algorithm compares version labels:
      // 1.2.3 vs 4.23.7

      const parts1 = a.label.split('.');
      const parts2 = b.label.split('.');

      const size = Math.min(parts1.length, parts2.length);
      for (let i = 0; i < size; i++) {
        if (parts1[i] == parts2[i]) {
          // proceed to next point
          continue;
        }
        try {
          return parseInt(parts1[i]) - parseInt(parts2[i]);
        } catch (e) {
          return parts1[i].compareTo(parts2[i]);
        }
        /// jakso ne pryvesty do integera porivnujuts dovzyny v string
      }
      return parts1.length - parts2.length;
    });
  });
}

export function getSettingsTemplateForLayout(versionId) {
  return handleQuery(`${startQuery}/getSettingsTemplateForLayout/${versionId}`);
}

export function loadLayoutFilters() {
  return handleQuery(`${startQuery}/getLayoutFilters`);
}

export function getLink(uuid) {
  return handleText(`${startQuery}/getLink/${uuid}`);
}
