import React, { Fragment, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  /*getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }*/
};

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  chipContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignContent: 'center',
    alignItems: 'center',
    gridGap: '1px'
  }
}));

function LocalesSelectDialog({ unmountComponent, onConfirm, onCancel, locales }) {
  const [selectedCriteria, setSelectedCriteria] = useState([]);

  const handleOnCancel = () => {
    unmountComponent();
    if (onCancel) onCancel();
  };

  const handleConfirm = () => {
    unmountComponent();
    onConfirm(selectedCriteria);
  };
  const classes = useStyles();

  const onChangeCriteria = (event) => {
    const options = event.target.value;
    setSelectedCriteria(options);
  };

  /*    const handleOnChangeExpansion = (event, isExpanded) => {
        onChangeExpansionOfCriteria(isExpanded, classNameOfCriteria);
    }; */
  const handleDeleteChip = (event, value) => {
    event.stopPropagation();
    setSelectedCriteria(selectedCriteria.filter((item) => item.language !== value.languageCode));
  };

  return (
    <Dialog maxWidth="sm" fullWidth open onClose={onCancel}>
      <DialogTitle>
        <FormattedMessage id="LocalesSelectDialog.DialogTitle" defaultMessage="Select locales to preview the report" />
      </DialogTitle>

      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>
            <FormattedMessage id="LocalesSelectDialog.ChooseLocale" defaultMessage="Locales" />
          </InputLabel>
          <Select
            multiple
            value={selectedCriteria}
            onChange={onChangeCriteria}
            input={<Input />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value.language}
                    label={
                      <div className={classes.chipContainer}>
                        <span> {value.displayName}</span>
                        <IconButton
                          style={{ padding: '4px' }}
                          onClick={(event) => handleDeleteChip(event, value)}
                          size="large">
                          <DeleteIcon
                            style={{
                              width: '16px',
                              height: '20px'
                            }}
                          />
                        </IconButton>
                      </div>
                    }
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {locales.map((locale) => (
              <MenuItem key={locale.language} value={locale}>
                {locale.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleConfirm} disabled={selectedCriteria.length == 0} color="primary">
          <FormattedMessage id="LocalesSelectDialog.Confirm" defaultMessage="Ok" />
        </Button>

        <Button onClick={handleOnCancel} color="primary" variant="outlined">
          <FormattedMessage id="LocalesSelectDialog.Cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LocalesSelectDialog;
