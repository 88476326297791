import { injectIntl } from 'react-intl';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { expandFunction } from '../tiles/AbstractTile';
import withCard from './withCard';

const mapStateToProps = (state) => ({
  catalog: state.catalog,
  myuser: state.myuser
});

export default injectIntl(withRouter(connect(mapStateToProps)(withCard(expandFunction))));
