import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import Menu from '@mui/material/Menu';
import { Popper } from "@mui/material";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function Reports({ dataIntroId = '', reports, printReport,
                                  openByHover = false,
                                  defaultReport=null }) {
  const [anchor, setAnchor] = useState(null);
  const singleReport = reports.length === 1;
  const handleReport = (report) => {
    printReport(report, report.reportIndex);
    setAnchor(null);
  };

  if (singleReport) {
    return (
      <div>
        <Button
          color="primary"
          style={{ margin: '5px' }}
          onClick={() => handleReport(reports[0])}
          data-intro-id={dataIntroId}
        >
          <FormattedMessage id="riskassement.print.report" defaultMessage="Report" />
        </Button>
      </div>
    );
  }

  const menuItems = reports.map((report, index) => {
    return (
      <MenuItem
        key={index}
        onClick={(e) => {
          handleReport(report);
        }}
      >
        {report.name}
      </MenuItem>
    );
  });

  return (
    <div>
      {openByHover ?
        <>
          <Button
            aria-owns={anchor ? 'simple-menu' : undefined}
            id={'report-button'}
            color="primary"
            style={{ margin: '5px' }}
            onClick={defaultReport
              ? (e) => handleReport(defaultReport)
              : (e) => setAnchor(e.currentTarget)}
            onMouseEnter={(e) => setAnchor(e.currentTarget)}
            data-intro-id={dataIntroId}
          >
            <FormattedMessage id="riskassement.print.report" defaultMessage="Report" />
            <KeyboardArrowDownIcon/>
          </Button>
          <Popper open={Boolean(anchor)} anchorEl={anchor}>
            <Paper elevation={3}>
              <ClickAwayListener onClickAway={() => setAnchor(null)}>
              <MenuList id="simple-menu">
                {menuItems}
              </MenuList>
            </ClickAwayListener>
            </Paper>
          </Popper>
        </>
      :
        <>
          <Button
            aria-owns={anchor ? 'simple-menu' : undefined}
            aria-haspopup="true"
            color="primary"
            style={{ margin: '5px' }}
            onClick={(e) => setAnchor(e.currentTarget)}
            data-intro-id={dataIntroId}
          >
            <FormattedMessage id="riskassement.print.report" defaultMessage="Report" />
            <KeyboardArrowDownIcon/>
          </Button>
          <Menu id="simple-menu" anchorEl={anchor} keepMounted open={Boolean(anchor)} onClose={(e) => setAnchor(null)}>
            {menuItems}
          </Menu>
        </>
      }
    </div>
  );
}
