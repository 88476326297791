import React from 'react';
import useStyles from "./style";
import AddCellTable from "../AddCellTable";
import ClearIcon from "@mui/icons-material/Clear";
import WidthControllerCell from "./WidthControllerCell";


const HeaderCellController = ({
                                  index,
                                  hoverTable,
                                  setHoverTable,
                                  removeColumn,
                                  isLastItem,
                                  addColumn,
                                  headerCells,
                                  setHeaderCells,
                              }) => {
    const cl = useStyles()
    const headWidth = headerCells[index]


    const handleHover = (val, type) => {
        setHoverTable(pre => ({...pre, [type]: val}))
    }



    const handleWidth = (e) => {
        const val = e.target.value
        const adobeVal = +val === 0 ? 1 : val
        const remainingWidth = 100 - adobeVal
        // if(headerCells.find(el => el.widthDefined)){
        //     return
        // }
        if(remainingWidth > 0){
          const  res = headerCells.reduce((acu, cur, idx) => {
                if(idx === index){
                    return [...acu, {...cur, width: +adobeVal}]
                }
                return [...acu, {...cur, width: +(remainingWidth / (headerCells.length - 1)).toFixed(2)}]
            }, [])
           return  setHeaderCells(res)
        }

        const  res = headerCells.reduce((acu, cur, idx) => {
            const findRemainingTotal = 100 - (headerCells.length - 1)
            if(idx === index){
                return [...acu, {...cur, width: findRemainingTotal}]
            }
            return [...acu, {...cur, width: +(findRemainingTotal / (headerCells.length - 1))}]
        }, [])
        return  setHeaderCells(res)

    }



    return (<td style={{width: headWidth?.width ? `${headWidth.width}%` : '', minWidth: 90}}>
        <div className={cl.wrapperContent}>
            <div className={cl.wrapperAdd}>
                <AddCellTable
                    type={'columnLeft'}
                    hover={hoverTable}
                    setHover={handleHover}
                    addRowLocal={addColumn}
                    index={index}
                    addCell={true}
                />
            </div>
            <div>
                {/*hoverTable.selectedCell.index === index &&*/}
                {hoverTable.selectedCell.index === index
                    ? <div
                        className={cl.clear}
                        onClick={() => removeColumn(index)}
                        onMouseEnter={() => handleHover({index}, 'deleteColumn')}
                        onMouseLeave={() => handleHover(false, 'deleteColumn')}
                    >
                        <ClearIcon/>
                    </div>
                    : <WidthControllerCell headWidth={headWidth} handleWidth={handleWidth}/>}
            </div>
            <div className={cl.wrapperAdd}>
                {isLastItem && <AddCellTable
                    type={'columnRight'}
                    hover={hoverTable}
                    setHover={handleHover}
                    addRowLocal={addColumn}
                    index={index}
                    addCell={true}
                />}
            </div>
        </div>
    </td>)
}


export default HeaderCellController