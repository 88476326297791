export function shortcutReducer(state, action)  {
  let sortedShortcuts = [];
  switch (action.type) {
    case 'SET_SHORTCUTS':
      let offset = 1;
      let zeroHasBeenUsed = false;
      for (let shortcut of action.payload) {
        if (shortcut.sortValue === null || shortcut.sortValue === undefined) {
          shortcut.sortValue = getMaxSortValue(action.payload) + offset;
          offset++;
        } else if (shortcut.sortValue === 0 && !zeroHasBeenUsed) {
          zeroHasBeenUsed = true;
        } else if (shortcut.sortValue === 0 && zeroHasBeenUsed) {
          shortcut.sortValue = getMaxSortValue(action.payload) + offset;
          offset++;
        }
      }
      sortedShortcuts = sortShortcuts(action.payload)
      return {
        ...state,
        shortcuts: sortedShortcuts,
      };
    case 'ADD_SHORTCUT':
      const newShortcutsList = [...state.shortcuts, action.payload];
      sortedShortcuts = sortShortcuts(newShortcutsList)
      return {
        ...state,
        shortcuts: sortedShortcuts,
      };
    case 'REMOVE_SHORTCUT':
      const remainingShortcuts = state.shortcuts.filter((shortcut) => shortcut.identifier !== action.payload.identifier);
      sortedShortcuts = sortShortcuts(remainingShortcuts)
      return {
        ...state,
        shortcuts: sortedShortcuts,
      };
    case 'EDIT_SHORTCUT':
      const existingIndex = state.shortcuts.findIndex(
        (shortcut) => shortcut.identifier === action.payload.identifier
      );

      if (existingIndex !== -1) {
        const updatedShortcuts = [...state.shortcuts];
        updatedShortcuts[existingIndex] = action.payload;

        return {
          ...state,
          shortcuts: updatedShortcuts,
        };
      } else {
        return state;
      }
    case 'EDIT_SHORTCUTS':
      action.payload.forEach((updatedShortcut) => {
        const existingIndex = state.shortcuts.findIndex(
          (shortcut) => shortcut.identifier === updatedShortcut.identifier
        );

        if (existingIndex !== -1) {
          state.shortcuts[existingIndex] = updatedShortcut;
        }
      });

      sortedShortcuts = sortShortcuts(state.shortcuts);

      return {
        ...state,
        shortcuts: [...sortedShortcuts],
      };
    default:
      return state;
  }
}

function getMaxSortValue(shortcuts) {
  return Math.max(...shortcuts.map((shortcut) => shortcut.sortValue ?? 0));
}

function sortShortcuts(shortcuts) {
  return shortcuts.slice().sort((a, b) => {
    if (a.sortValue === null) return 1;
    if (b.sortValue === null) return -1;

    if (isNaN(a.sortValue)) return 1;
    if (isNaN(b.sortValue)) return -1;

    return a.sortValue - b.sortValue;
  });
}
