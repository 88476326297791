import React, { useEffect, useState } from 'react';
import GTranslateIcon from '@mui/icons-material/GTranslate';

function TranslatableIcons({ switchLanguages, disabled, setSwitchLanguages }) {
  const [isTranslation, setIsTranslation] = useState(true);
  const [languages, setLanguages] = useState(null);
  const language = document.getElementsByTagName('html')[0].getAttribute('lang');
  const [start, setStart] = useState(false);

  const indexOfTranslation = getIndexOfTranslation();

  function getIndexOfTranslation() {
    if (languages === null) return 0;
    if (languages.indexOfEnglish === -1) return 0;
    if (languages.indexOfSwedish === -1) return 0;

    if (language === 'sv') {
      return isTranslation ? languages.indexOfSwedish : languages.indexOfEnglish;
    }

    if (language === 'en') {
      return isTranslation ? languages.indexOfEnglish : languages.indexOfSwedish;
    }
  }

  const toggleTranslation = () => {
    if (switchLanguages && switchLanguages.locales) {
      const { indexOfEnglish, indexOfSwedish } = languages;
      if (indexOfEnglish === -1 || indexOfSwedish === -1) return;
      setIsTranslation((prev) => !prev);
      setStart(true);
    }
  };

  function checkIsAllowedUpdateLanguages(locales, languages) {
    if (languages === null) return true;
    const val1 = locales[languages.indexOfEnglish] !== 'en';
    const val2 = locales[languages.indexOfSwedish] !== 'sv';
    if (val1) return true;
    if (val2) return true;
    return false;
  }

  const findIndexOfLanguages = (locales) => {
    if (checkIsAllowedUpdateLanguages(locales, languages)) {
      const indexOfEnglish = locales.findIndex((language) => language === 'en');
      const indexOfSwedish = locales.findIndex((language) => language === 'sv');
      setLanguages({
        indexOfEnglish,
        indexOfSwedish
      });
    }
  };

  useEffect(() => {
    if (switchLanguages && switchLanguages.locales) {
      findIndexOfLanguages(switchLanguages.locales);
    }
  }, []);

  useEffect(() => {
    if (switchLanguages && switchLanguages.translations) {
      if (setSwitchLanguages && start) {
        setSwitchLanguages(switchLanguages.translations[indexOfTranslation]);
      }
    }
  }, [isTranslation, start]);

  return (
    <div>
      {' '}
      <GTranslateIcon
        onClick={toggleTranslation}
        style={{ color: isTranslation ? 'gray' : 'blue' }}
        disabled={disabled}
      />
    </div>
  );
}

export default TranslatableIcons;
