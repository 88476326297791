import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import {
  ARCHIVE_ACTION,
  CREATE_DOC,
  CREATE_PED,
  CUT_ACTION,
  DELETE_ACTION,
  ELEMENT_ACTIONS,
  PASTE_ACTION,
  REPORT_ACTIONS,
  UNARCHIVE_ACTION,
  TASK_ACTIONS_TYPES,
  UPLOAD_DOC,
  OPEN_ACTION
} from '@zert-packages/components/ElementTile/shared/ELEMENT_TILE_CONTS';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import {
  archiveElements,
  copyElements,
  findElements,
  findElementsSearch,
  pasteCopiedElements,
  placeElementNew,
  remove
} from '@zert-packages/actions/catalogActions';
import dialogCustom from '@zert-packages/components/common/dialogCustom';
import { getMultipleValueOneCatalogSelected } from '@zert-packages/utils/getMultipleValue';
import { duplicateCommon, generateReportDialog, rename } from '@zert-packages/plugins/ExplorerPlugin/servicesMidleware';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';

import openAttachment from '@zert-packages/utils/openAttachment';
import { uploadFile } from '@zert-packages/plugins/DocumentPlugin/API';
import CreateNewDocument from '@zert-packages/plugins/DocumentPlugin/CreateNewDocument';
import CreateNewPEDDialog from '../PEDOBjectPlugin/CreateNewPEDDialog';

const useElementActionsCatalog = ({ selected, selectedNode, catalogIndex, searchString }) => {
  const dispatch = useDispatch();
  const { copiedElements, isCuted } = useSelector((state) => state);
  const myUser = useSelector((state) => state.myuser);
  const [{ catalog }, setState] = useState({ catalog: null });
  const { permits, catalog: catalogList } = useSelector((state) => state);

  useEffect(() => {
    setState((prevState) => ({ catalog: catalogList[catalogIndex] }));
  }, [catalogList, catalogIndex]);

  const isAdmin = () => {
    const role = get(myUser, 'role', 'default');
    return {
      administrator: true,
      'task-administrator': true,
      default: false
    }[role];
  };

  const canArchive = () => {
    return permits['zert-administration/archive'] && permits['zert-administration/archive'] === 'true';
  };

  const canDelete = () => {
    return permits['zert-administration/delete'] && permits['zert-administration/delete'] === 'true';
  };

  const toolbarActions = [
    ...CREATE_PED,
    ...CREATE_DOC,
    ...UPLOAD_DOC,
    ...(selected && selected.length > 0 ? ELEMENT_ACTIONS : []),
    ...REPORT_ACTIONS,
    ...(selected && selected.length > 0 ? [CUT_ACTION] : []),
    ...(copiedElements && copiedElements.length > 0 ? [PASTE_ACTION] : []),
    ...(selected && selected.length > 0 && (isAdmin() || canArchive()) && !selected[0].archivedAt
      ? [ARCHIVE_ACTION]
      : []),
    ...(selected && selected.length > 0 && (isAdmin() || canArchive()) && selected[0].archivedAt
      ? [UNARCHIVE_ACTION]
      : []),
    ...(selected && selected.length > 0 && (isAdmin() || canDelete()) ? [DELETE_ACTION] : []),
    ...(selected.length > 0 ? [OPEN_ACTION] : [])
  ];

  const cloneMap = (source) => {
    return Array.from(source.values());
  };

  const deleteElement = (selected) => {
    const report = {
      name: <FormattedMessage id="delete.from.catalog" defaultMessage="Delete" />,
      reportUUID: uuidv4()
    };

    dispatch(
      remove(
        report,
        selectedNode.split('^')[1],
        cloneMap(selected),

        catalog.info.id
      )
    );
  };
  const openElement = (selected) => {
    if (selected[0].info.mimeType == 'application/zert-document') {
      openAttachment(selected[0].info);
    } else {
      window.open(
        getPluginByMimeType(selected[0].info.mimeType).openpath.replace(':versionId', selected.versionId),
        '_blank'
      );
    }
  };
  const performActionHandler = (selected) => (actionId, fileData, fileName) => {
    if (actionId == 'create_ped') {
      dialogCustom(CreateNewPEDDialog, {
        onCreate: async (res) => {
          await placeElementNew(res.versionId, getMultipleValueOneCatalogSelected(catalog, selectedNode));
          dispatch(findElements(selectedNode.split('^')[1], catalog.info.id));
        }
      });
    } else if (actionId == 'open') {
      openElement(selected);
    } else if (actionId == TASK_ACTIONS_TYPES.create_doc) {
      dialogCustom(CreateNewDocument, {
        onCreate: async (res) => {
          await placeElementNew(res.versionId, getMultipleValueOneCatalogSelected(catalog, selectedNode));
          dispatch(findElements(selectedNode.split('^')[1], catalog.info.id));
        }
      });
    } else if (actionId == TASK_ACTIONS_TYPES.upload_doc) {
      dispatch(uploadFile(fileData, catalog, selectedNode));
    } else if (actionId == 'delete') {
      deleteElement(selected);
    } else if (actionId == 'report') {
      dispatch(
        generateReportDialog(
          selected.map((value) => ({ versionId: value })),
          catalogIndex,
          selectedNode.split('^')[1]
        )
      );
    } else if (actionId == 'search') {
      //   this.setState({ searchString: searchString });
      dispatch(findElementsSearch(selectedNode.split('^')[1], searchString, catalog.info.id));
    } else if (actionId == 'refresh') {
      // this.setState({ searchString: '' });
      selected.clear();
      dispatch(findElements(selectedNode.split('^')[1], catalog.info.id));
    } else if (actionId == 'duplicate') {
      if (selected.length == 1) {
        dispatch(
          duplicateCommon(
            selected.map((value) => ({ versionId: value })),
            false,
            (e) => dispatch(findElements(selectedNode.split('^')[1], catalog.info.id))
          )
        );
      }
    } else if (actionId == 'rename') {
      if (selected.length == 1) {
        dispatch(
          rename(
            selected.map((value) => ({ versionId: value })),
            (e) => dispatch(findElements(selectedNode.split('^')[1], catalog.info.id))
          )
        );
      }
    } else if (actionId == 'copy') {
      if (selected.size != 0) {
        dispatch(copyElements(cloneMap(selected), false), catalog.info.id);
      }
    } else if (actionId == 'cut') {
      if (selected.size != 0) {
        dispatch(copyElements(cloneMap(selected), true, catalog.info.id));
      }
    } else if (actionId == 'archive') {
      if (selected.size != 0) {
        const report = {
          name: <FormattedMessage id="delete.from.catalog" defaultMessage="Delete" />,
          reportUUID: uuidv4()
        };

        dispatch(archiveElements(report, cloneMap(selected), selectedNode.split('^')[1], catalog.info.id));
      }
    } else if (actionId == 'paste') {
      dispatch(pasteCopiedElements(copiedElements, selectedNode.split('^')[1], isCuted, catalog.info.id));
    } else {
      alert(actionId);
    }
  };
  return { toolbarActions, performActionHandler };
};

export default useElementActionsCatalog;
