import { GetEnv } from '@zert-packages/react/EnvProvider';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { getMessages, loadMyLocale, pushMessages } from '@zert-packages/actions/api';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import getTranslationsFromContext from '@zert-packages/translations/getTranslationsFromContext';

const languageContext = React.createContext({
  language: 'en',
  textComponent: 'span'
});

export function LanguageProvider({ textComponent = React.Fragment, translationContext = undefined, children }) {
  const [messages, setMessages] = useState();
  const { mylocale } = useSelector((state) => state);
  const myuser = useSelector((state) => state.myuser);
  const { pathname } = useLocation();
  const portal = GetEnv('REACT_APP_PORTAL');

  const [language, setLanguage] = React.useState('en');

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (pathname === '/login') {
      setLanguage(/^sv\b/.test(navigator.language) ? 'sv' : 'en');
    } else if (myuser) {
      if (!mylocale) {
        dispatch(loadMyLocale());
      } else if (mylocale === 'shouldUseBrowserLanguage') {
        const browserLanguage = /^sv\b/.test(navigator.language) ? 'sv' : 'en';
        setLanguage(browserLanguage);
      } else if (mylocale !== language) {
        setLanguage(mylocale);
      }
    }
  }, [mylocale, pathname, myuser, language, dispatch]);

  React.useEffect(() => {
    const currentMessages = getMessages()[language];
    if (currentMessages) {
      setMessages(getMessages());
    } else if (portal && translationContext) {
      const newMessages = getTranslationsFromContext(translationContext, portal);
      pushMessages(newMessages);
      setMessages(newMessages);
    }
  }, [portal, language, translationContext]);

  if (!messages) {
    return <LinearProgress />;
  }

  return (
    <languageContext.Provider value={{ textComponent, language }}>
      <IntlProvider key="app" textComponent={textComponent} locale={language} messages={getMessages()[language]}>
        <Helmet htmlAttributes={{ lang: language }} />
        {children}
      </IntlProvider>
    </languageContext.Provider>
  );
}

export const useLanguage = () => {
  const { language } = React.useContext(languageContext);
  return { language };
};
