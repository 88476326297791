import { countChildNodes, getChildNodes } from '@zert-packages/actions/explorerActions';
import openAttachment from '@zert-packages/utils/openAttachment';
import { useViewport } from '@zert-packages/utils/ViewportProvider';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getNodeContext } from '@zert-packages/plugins/ExplorerPlugin/servicesMidleware';
import { getDocument } from '@zert-packages/actions/coreReducers';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import { defaultKey, getFillFunction } from '@zert-packages/utils/fillTemplateString';
import { v4 as uuidv4 } from 'uuid';
import Portal from '@zert-packages/react/interfaces/Portals';
import { usePortal } from '@zert-packages/utils/PortalProvider';

const fillPath = getFillFunction(defaultKey);

export const useElementOpen = () => {
  const stateToSend = useSelector((state) => state);
  const [uuid, setUuid] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  let portal;
  try {
    portal = usePortal();
  } catch (error) {
    console.error('Error accessing portal:', error);
    portal = null;
  }

  const { isMobile } = useViewport();

  useEffect(() => {
    try {
      if (!stateToSend || !uuid) {
        return;
      }

      localStorage.setItem(uuid, JSON.stringify(stateToSend));
      setUuid(null);
    } catch (error) {
      console.error('Error storing data in localStorage:', error);
    }
  }, [uuid, stateToSend]);

  const open = async (info, columnn, event) => {
    if (info.mimeType == 'application/zert-folder') {
      if (!history.location.pathname.includes('explorer')) {
        history.push('/explorer');
      }
      dispatch(countChildNodes(info.id));
      dispatch(getChildNodes(info.id));
      dispatch(getNodeContext(info, true));
    }
    if (info.mimeType == 'application/zert-search-folder') {
      if (!history.location.pathname.includes('explorer')) {
        history.push('/explorer');
      }
      dispatch(getNodeContext(info, true));
    }
    if (info.mimeType == 'application/zert-document') {
      const document = await getDocument(info.versionId);
      if (document.link) {
        window.open(document.url);
      } else {
        await openAttachment(info);
      }
    } else if (getPluginByMimeType(info.mimeType) && getPluginByMimeType(info.mimeType).openpath) {
      if (isMobile || (portal && portal.identifier === Portal.RM)) {
        history.push(fillPath(info, getPluginByMimeType(info.mimeType).openpath));
      } else {
        const newUuid = uuidv4();
        setUuid(newUuid);
        window.open(fillPath(info, `${getPluginByMimeType(info.mimeType).openpath}?sync=${newUuid}`), '_blank');
      }
    }
  };

  return { open };
};
