import React, { useEffect, useState } from 'react';
import { Dialog, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import SelectedLanguagePopup from '../../../../SelectedLanguagePopup';
import { handleHeadingLabel } from '../../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 350,
    height: 290,
    padding: 11,
    background: '#E2F2F6'
  },
  wrapperTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      cursor: 'pointer'
    }
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 700,
    marginBottom: 15
  },
  titleSmall: {
    fontWeight: 700
  },
  wrapperButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 10
  },
  button: {
    color: '#ffffff',
    background: '#4db1d3',
    marginLeft: 10,
    minWidth: 80,
    border: '1px solid #4db1d3',
    outline: 'none !important',
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
      color: '#212529',
      border: '1px solid #4db1d3'
    },
    '&:focus': {
      backgroundColor: '#4db1d3'
    }
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '70px 1fr',
    gridColumnGap: 10
  },
  templateInput: {
    width: '100%',
    margin: 0,
    '& input': {
      paddingTop: 5.2,
      paddingBottom: 5.2
    }
  },
  wrapperLanguage: {
    marginTop: 15
  }
}));

function DialogHandleLabel({ label, values, setValues, setLabel, selectedLabel }) {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState('');
  const [selectedLocales, setSelectedLocales] = useState(null);
  const { activeLocales } = useSelector((state) => state);

  const handleClose = () => {
    setOpen(false);
  };

  const handleValue = (e) => {
    setValue(e.target.value);
  };

  const handleLocale = (el) => {
    setSelectedLocales(el);
  };

  const handleSave = () => {
    if (!label || !selectedLocales) {
      return;
    }
    const result = {
      ...label,
      defaultLabel: value,
      translations: [value, ...label.translations],
      locales: [selectedLocales, ...label.locales]
    };
    setLabel(result);
    handleHeadingLabel(result, 'text', 'content', values, setValues);
    setOpen(false);
  };

  useEffect(() => {
    if (label && label.defaultLabel) {
      setValue(label.defaultLabel);
    }
  }, [label]);

  useEffect(() => {
    if (activeLocales) {
      setSelectedLocales(activeLocales[0].value);
    }
  }, [activeLocales]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paperScrollPaper: cl.root
      }}
    >
      <div className={cl.wrapperTitle}>
        <span className={cl.title}>Translation...</span>
        <CloseIcon onClick={handleClose} />
      </div>

      <div className={cl.wrapper}>
        <div className={cl.titleSmall}>Text:</div>
        <div>
          <TextField value={value} className={cl.templateInput} variant="outlined" onChange={handleValue} />
        </div>
      </div>
      <div className={cl.wrapperLanguage}>
        <div className={cl.wrapper}>
          <div className={cl.titleSmall}>Locale:</div>
          <div>
            {selectedLocales && (
              <SelectedLanguagePopup
                selectedLocales={selectedLocales}
                setSelectedLocales={handleLocale}
                disable={selectedLabel}
              />
            )}
          </div>
        </div>
      </div>

      <div className={cl.wrapperButton}>
        <Button className={cl.button} onClick={handleSave}>
          OK
        </Button>
        <Button className={cl.button} onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}

export default DialogHandleLabel;
