import { getAllTemplates, getTemplatesBundle } from './API';
import {
  DEVIATION_TEMPLATE,
  DEVIATION_TEMPLATE_WORKFLOW,
  getTemplates,
  RA_MeasureTemplate,
  RA_Template,
  RA_WorkFlowTemplate
} from './deviationReducers';

export const loadDeviationTemplates =
  (isChecklist, loadFirstTemplate) =>
    async (dispatch, getState, { snackbar }) => {
      if (!getState().DEVIATION.templates) {
        const templates = await getAllTemplates(isChecklist);
        dispatch(getTemplates(templates));

        if (loadFirstTemplate && templates.length > 0) {
          dispatch(loadDeviationTemplate(templates[0].versionId));
      }
      }
    };

export const loadDeviationTemplate = (versionId) => async (dispatch) => {
  const templateBundle = await getTemplatesBundle(versionId);
  dispatch(RA_WorkFlowTemplate(templateBundle.measuresWorkflowProcess));
  dispatch(RA_MeasureTemplate(templateBundle.measureTemplate));
  dispatch(RA_Template(templateBundle.riskRowTemplate));
  dispatch(DEVIATION_TEMPLATE_WORKFLOW(templateBundle.workflowProcess));
  dispatch(DEVIATION_TEMPLATE(templateBundle.deviationTemplate));
};
