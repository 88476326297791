import 'date-fns';
import React, { useEffect, useImperativeHandle, forwardRef, useContext } from 'react';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import { FormattedMessage } from 'react-intl';
import {TextField} from '@mui/material';
import MultiSelectField from '@zert-packages/components/shared/FormElements/MultiSelectField';
import { getSchedulerSettings, storeSchedulerSettings } from '@zert-packages/actions/coreReducers';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { SettingsContext } from '../components/Company/ContextProvider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const intervals = [
  { value: 'minutes', label: 'Minutes', id: 'settings.companyeditor.intervalMinutes' },
  { value: 'hours', label: 'Hours', id: 'settings.companyeditor.intervalHours' },
  { value: 'days', label: 'Days', id: 'settings.companyeditor.intervalDays' },
  { value: 'months', label: 'Months', id: 'settings.companyeditor.intervalMonthes' },
  { value: 'years', label: 'Years', id: 'settings.companyeditor.intervalYears' }
];

function getIntervals(intl) {
  return intervals.map((interval) => {
    return { value: interval.value, label: intl.formatMessage({ id: interval.id, defaultMessage: interval.label }) };
  });
}

function getInterval(intl, filter) {
  const interval = intervals.find((interval) => interval.value == filter);
  return { value: interval.value, label: intl.formatMessage({ id: interval.id, defaultMessage: interval.label }) };
}
// <MultiSelectField fieldId="checkinPeriod" isMulti = {false} value={checkinPeriod}  hideLabel={true} label={props.intl.formatMessage({id: "settings.companyeditor.period", defaultMessage: "Period"})}
//                            placeholder={props.intl.formatMessage({id: "settings.companyeditor.placeholder.checkinPeriod", defaultMessage: "Period"})} onStateChanged = {handleChecinIntervalChange} options={getIntervals(props.intl)}/>
// <TextField type = "number" id="checkin-interval"
//                        label={props.intl.formatMessage({id: "settings.ue.caption.checkin-interval", defaultMessage: "Interval"})}
//                          className={props.classes.formControl} value={checkinInterval} onChange={handleChange('checkinInterval')}
//                         placeholder={props.intl.formatMessage({id: "settings.ue.caption.checkin-interval", defaultMessage: "Interval"})}
//                        className={props.classes.formControl} variant="outlined"/>

const ScedulerIntervalEditor = forwardRef((props = props, ref) => {
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [configuration, setConfiguration] = React.useState(null);
  const [checkinInterval, setCheckinInterval] = React.useState(1);
  const [checkinPeriod, setCheckinPeriod] = React.useState({ value: 'hours', label: 'Hours' });
  const [isLoading, setIsLoading] = React.useState(false);
  const [className, setClassName] = React.useState();
  const [isStoring, setIsStoring] = React.useState(false);
  const [earliestTime, setEarliestTime] = React.useState(null);
  const [latestTime, setLatestTime] = React.useState(null);
  const [timeZone, setTimeZone] = React.useState(null);
  const [availableTimesLoaded, setAvaiableTimesLoaded] = React.useState(false);
  const [availableTimezones, setAvailableTimezones] = React.useState([]);
  const myChilrens = React.Children.toArray(props.children);

  const [keepCheckinInterval, setKeepCheckinInterval] = React.useState(-2);

  const hasAllowedTimes = (className) => className === "se.zert.scheduletask.ReminderScheduleTask";

  const timeFromArray = (array, defaultTime) => {
    const date = new Date();
    if (array == null || !Array.isArray(array) || array.length < 2) {
      date.setHours(defaultTime[0]);
      date.setMinutes(defaultTime[1]);
    } else {
      date.setHours(array[0]);
      date.setMinutes(array[1]);
    }
    return date;
  };

  const arrayFromDate = (date) => {
    return [date.getHours(), date.getMinutes()];
  };

  const createStorageObject = () => {
    const baseStorage = {
      interval: checkinInterval,
      unit: checkinPeriod.value,
      nextRun: selectedDate,
      additional: keepCheckinInterval
    };
    if (!hasAllowedTimes(className)) {
      return baseStorage;
    }
    return {
      ...baseStorage,
      earliestTime: arrayFromDate(earliestTime),
      latestTime: arrayFromDate(latestTime),
      timeZone: timeZone
    };
  };

  // const taskSettings = useSelector(state => state.taskSettings);
  // const dispatch = useDispatch();
  useEffect(() => {
    if (!isLoading && !configuration) {
      async function loadScedulerClasses() {
        setIsLoading(true);
        const configurationLoaded = await getSchedulerSettings(props.companyId, props.className);
        setIsLoading(false);
        setCheckinInterval(configurationLoaded.interval);
        setCheckinPeriod(getInterval(props.intl, configurationLoaded.unit));
        setKeepCheckinInterval(configurationLoaded.additional);
        setSelectedDate(configurationLoaded.nextRun);
        setConfiguration(configurationLoaded);
        setClassName(configurationLoaded.className);
        if (hasAllowedTimes(configurationLoaded.className)) {
          setEarliestTime(timeFromArray(configurationLoaded.earliestTime, [8,0]));
          setLatestTime(timeFromArray(configurationLoaded.latestTime, [17,0]));
          setTimeZone(configurationLoaded.timeZone != null
            ? configurationLoaded.timeZone
            : 'Europe/Stockholm');
          setAvailableTimezones(configurationLoaded.availableTimezones);
          setAvaiableTimesLoaded(true);
        }
      }
      loadScedulerClasses();
    }
  });

  useImperativeHandle(ref, () => ({
    storeSettings() {
      async function storeScedulerClasses() {
        setIsStoring(true);
        const storage = createStorageObject();
        const configurationLoaded = await storeSchedulerSettings(props.companyId, props.className, storage);
        setIsStoring(false);
      }
      storeScedulerClasses();
    }
  }));

  function handleDateChange(date) {
    setSelectedDate(date);
    setIsSomethingChanged(true);
  }

  const handleChange = (name) => (period) => {
    setCheckinInterval(period.target.value);
    setIsSomethingChanged(true);
  };

  function handleChecinIntervalChange(state) {
    setCheckinPeriod(state.value);
    setIsSomethingChanged(true);
  }

  const handleKeepCheckedInIntervalChange = (name) => (period) => {
    setKeepCheckinInterval(period.target.value);
    setIsSomethingChanged(true);
  };

  const handleEarliestTimeChange = (time) => {
    setEarliestTime(time);
    setIsSomethingChanged(true);
  };

  const handleLatestTimeChange = (time) => {
    setLatestTime(time);
    setIsSomethingChanged(true);
  };

  const handleTimeZoneChange = (newTimeZone) => {
    setTimeZone(newTimeZone.target.value);
    setIsSomethingChanged(true);
  };

  const { setIsSomethingChanged } = useContext(SettingsContext);

  return isLoading ? (
    <Loader>
      <FormattedMessage id="settings.companyeditor.loadingsceduler" defaultMessage="Scheduler settings" />
    </Loader>
  ) : (
    <div className="question-block">
      <h3>{myChilrens && myChilrens.length > 0 && myChilrens[0]}</h3>
      <div style={{ display: 'inline-flex' }}>
        <TextField
          type="number"
          id="checkin-interval"
          label={<FormattedMessage id="settings.ue.caption.checkin-interval" defaultMessage="Interval" />}
          className={props.classes.formControl}
          value={checkinInterval}
          onChange={handleChange('checkinInterval')}
          variant="outlined"
        />
        <MultiSelectField
          fieldId="checkinPeriod"
          isMulti={false}
          value={checkinPeriod}
          hideLabel
          label={props.intl.formatMessage({ id: 'settings.companyeditor.period', defaultMessage: 'Period' })}
          placeholder={props.intl.formatMessage({
            id: 'settings.companyeditor.placeholder.checkinPeriod',
            defaultMessage: 'Period'
          })}
          onStateChanged={handleChecinIntervalChange}
          options={getIntervals(props.intl)}
        />
      </div>
      {keepCheckinInterval > -2 && (
        <TextField
          type="number"
          id="checkin-interval"
          InputProps={{ inputProps: { min: -1 } }}
          label={
            <FormattedMessage id="settings.ue.caption.keepInterval" defaultMessage="Keep element checked in time" />
          }
          className={props.classes.formControl}
          value={keepCheckinInterval}
          onChange={handleKeepCheckedInIntervalChange('keepCheckinInterval')}
          variant="outlined"
        />
      )}
      <div style={{ display: 'inline-flex' }}>
        <DatePicker
          disableToolbar
          variant="inline"
          margin="normal"
          id="date-picker-inline"
          label={<FormattedMessage id="nextCheckinDate" defaultMessage="Next Execute Date" />}
          invalidDateMessage={<FormattedMessage id="datepicker.invalid" defaultMessage="Invalid date format" />}
          maxDateMessage={
            <FormattedMessage id="datepicker.maxDateMessage" defaultMessage="Date should not be after maximal date" />
          }
          minDateMessage={
            <FormattedMessage id="datepicker.minDateMessage" defaultMessage="Date should not be before minimal date" />
          }
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          renderInput={(props) => <TextField {...props} />}
        />

        <TimePicker
          margin="normal"
          id="time-picker"
          ampm={false}
          label={<FormattedMessage id="nextCheckinTime" defaultMessage="Next Execute Time" />}
          invalidDateMessage={<FormattedMessage id="datepicker.invalid" defaultMessage="Invalid date format" />}
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time'
          }}
          renderInput={(props) => <TextField {...props} />}
        />
      </div>
      {hasAllowedTimes(className) && availableTimesLoaded &&
        <div>
           <TimePicker
             label={<FormattedMessage id="ScedulerIntervalEditor.earliestTime" defaultMessage="Earliest time" />}
             value={earliestTime}
             ampm={false}
             id="time-picker"
             invalidDateMessage={<FormattedMessage id="timepicker.invalid"
                                                   defaultMessage="Invalid time format" />}
             renderInput={(props) => <TextField {...props} />}
             onChange={handleEarliestTimeChange}
           />
          <TimePicker
            label={<FormattedMessage id="ScedulerIntervalEditor.latestTime" defaultMessage="Latest time" />}
            value={latestTime}
            ampm={false}
            id="time-picker"
            invalidDateMessage={<FormattedMessage id="timepicker.invalid"
                                                  defaultMessage="Invalid time format" />}
            renderInput={(props) => <TextField {...props} />}
            onChange={handleLatestTimeChange}
          />
          <FormControl>
            <InputLabel id="tz-select-label">
              <FormattedMessage id="ScedulerIntervalEditor.timezone" defaultMessage="TimeZone (for allowed times)" />
            </InputLabel>
            <Select
              labelId="tz-select-label"
              id="tz-select"
              value={timeZone}
              onChange={handleTimeZoneChange}
            >
              {availableTimezones.map( tz =>
                <MenuItem key={tz} value={tz}>
                  {tz}
                </MenuItem>)}
            </Select>
          </FormControl>
        </div>}

    </div>
  );
});

export default ScedulerIntervalEditor;
