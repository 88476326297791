import React, { useRef, useState, useEffect, useContext, useCallback } from 'react';
import { Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import SearchInput from './SearchInput';
import { getMatchedElementsInContainer } from './utils/getMatchedElementsInContainer';
import getSearchContainer from './utils/getSearchContainer';
import buildIsMatched from './utils/buildIsMatched';
import { offElementHighlight, onElementHighlight } from './utils/highliting';
import useElementSearchConfig from './config/useElementSearchConfig';
import addTotalAndCurrentToCounter from './utils/addTotalAndCurrentToCounter';
import { IntroductionContext } from '../Introduction';

const useStyles = makeStyles({
  rootContainer: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    height: 56,
    padding: '1px 5% 1px 5%',
    background: 'linear-gradient(to bottom, #179fc7 0%, #188ec7 100%)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    zIndex: 1200,
    '@media (min-width: 1020px)': {
      padding: '1px 10% 1px 10%',
      flexFlow: 'row nowrap'
    }
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 600
  }
});

function ElementSearcher({ openSearch, setOpenSearch, backgroundStyle, children }) {
  const classes = useStyles();
  const { setIsSearchingMode } = useContext(IntroductionContext);
  const elementSearchConfig = useElementSearchConfig();
  const lastContainerIndex = elementSearchConfig.containers.length - 1;
  const refValues = useRef({
    isBackCommand: false,
    actionsDisabled: false
  });
  const [searchValue, setSearchValue] = useState('');
  const handleChangeSearchValue = useCallback(
    _.debounce((_value) => {
      const value = _value.trim();
      offElementHighlight(elements[selectedElementIndex]);
      setIsSearchingMode(value.length > 0);
      setSearchValue(value);
    }, 500),
    []
  );

  const [elements, setElements] = useState([]);
  const [selectedElementIndex, setSelectedElementIndex] = useState(-1);
  const [selectedContainerIndex, setSelectedContainerIndex] = useState(-1);

  const [counter, setCounter] = useState({
    current: -1,
    total: -1
  });

  const checkIsDisabledScroll = () => {
    if (!elementSearchConfig.containers) return false;
    if (!elementSearchConfig.containers[selectedContainerIndex]) return false;

    return elementSearchConfig.containers[selectedContainerIndex].disabledScroll;
  };

  useEffect(() => {
    clearSearchElements();
    if (searchValue.length !== 0) {
      const _counter = elementSearchConfig.countMatchedElementsJSON(searchValue);
      setCounter(addTotalAndCurrentToCounter(_counter));
      const firstNotEmptyContainerIndex = elementSearchConfig.containers.findIndex(({ counterField }) => {
        return _counter[counterField] !== 0;
      });
      setSelectedContainerIndex(firstNotEmptyContainerIndex);
    } else {
      clearSearchElements();
    }
  }, [searchValue]);

  useEffect(() => {
    refValues.current.actionsDisabled = elementSearchConfig.actionsDisabled;
  }, [elementSearchConfig.actionsDisabled]);

  useEffect(() => {
    offElementHighlight(elements[selectedElementIndex]);

    if (selectedContainerIndex !== -1) {
      const container = elementSearchConfig.containers[selectedContainerIndex];
      let isMatched;
      if (container.searchByCounter) {
        isMatched = buildIsMatched(container.counterMatcher);
      } else {
        isMatched = buildIsMatched(searchValue);
      }

      refValues.current.actionsDisabled = true;
      getSearchContainer(container, (node) => {
        updateElements(getMatchedElementsInContainer(node, isMatched, elementSearchConfig.searchSettings));
      });
    }
  }, [selectedContainerIndex, searchValue]);

  const setPreviousContainer = () => {
    let newSelectedContainerIndex = -1;

    for (let containerIndex = 0; containerIndex <= lastContainerIndex; containerIndex++) {
      const { counterField } = elementSearchConfig.containers[containerIndex];
      if (counter[counterField] === 0) continue;

      if (selectedContainerIndex === 0) {
        if (selectedContainerIndex < containerIndex) newSelectedContainerIndex = containerIndex;
        continue;
      }
      if (selectedContainerIndex > containerIndex) newSelectedContainerIndex = containerIndex;
    }

    if (newSelectedContainerIndex === selectedContainerIndex || newSelectedContainerIndex === -1) {
      updateElements(elements);
      return;
    }
    setSelectedContainerIndex(newSelectedContainerIndex);
  };

  const backElement = () => {
    if (refValues.current.actionsDisabled) return;
    setCounter((prev) => ({
      ...prev,
      current: prev.current - 1 < 1 ? prev.total : prev.current - 1
    }));

    offElementHighlight(elements[selectedElementIndex]);
    refValues.current.isBackCommand = true;

    const newSelectedElementIndex = selectedElementIndex - 1;
    const goPreviousContainer = newSelectedElementIndex < 0;
    if (goPreviousContainer) {
      setPreviousContainer();
      return;
    }

    setSelectedElementIndex(newSelectedElementIndex);
    onElementHighlight(elements[newSelectedElementIndex], checkIsDisabledScroll());
  };

  const setNextContainer = () => {
    const { containers } = elementSearchConfig;
    let newSelectedContainerIndex = -1;

    for (let containerIndex = 0; containerIndex <= lastContainerIndex; containerIndex++) {
      const { counterField } = containers[containerIndex];
      if (counter[counterField] === 0) continue;
      if (containerIndex > selectedContainerIndex && newSelectedContainerIndex === -1) {
        newSelectedContainerIndex = containerIndex;
      }
    }
    if (newSelectedContainerIndex === -1) {
      const firstNotEmptyContainerIndex = containers.findIndex(({ counterField }) => {
        return counter[counterField] !== 0;
      });
      if (firstNotEmptyContainerIndex === selectedContainerIndex) {
        updateElements(elements);
        return;
      }
      setSelectedContainerIndex(firstNotEmptyContainerIndex);
      return;
    }
    setSelectedContainerIndex(newSelectedContainerIndex);
  };

  const nextElement = () => {
    if (refValues.current.actionsDisabled) return;
    setCounter((prev) => ({
      ...prev,
      current: prev.current + 1 > prev.total ? 1 : prev.current + 1
    }));

    offElementHighlight(elements[selectedElementIndex]);
    refValues.current.isBackCommand = false;

    const newSelectedElementIndex = selectedElementIndex + 1;

    const goNextContainer = newSelectedElementIndex > elements.length - 1;
    if (goNextContainer) {
      setNextContainer();
      return;
    }

    setSelectedElementIndex(newSelectedElementIndex);
    onElementHighlight(elements[newSelectedElementIndex], checkIsDisabledScroll());
  };

  const updateElements = (newElements) => {
    if (searchValue.length === 0) {
      offElementHighlight(newElements[0]);
      clearSearchElements();
      return;
    }
    if (refValues.current.isBackCommand) {
      const lastElementIndex = newElements.length - 1;
      onElementHighlight(newElements[lastElementIndex], checkIsDisabledScroll());
      setSelectedElementIndex(lastElementIndex);
    } else {
      onElementHighlight(newElements[0], checkIsDisabledScroll());
      setSelectedElementIndex(0);
    }
    setElements(newElements);
    refValues.current.actionsDisabled = false;
  };

  const clearSearchElements = () => {
    offElementHighlight(elements[selectedElementIndex]);
    setElements([]);
    setSelectedElementIndex(-1);
    setSelectedContainerIndex(-1);
    setCounter({
      current: -1,
      total: -1
    });
  };

  const handleClose = () => {
    setSearchValue('');
    clearSearchElements();
    setOpenSearch(false);
  };
  if (!openSearch) return null;
  return (
    <Fade in timeout={300}>
      <div className={classes.rootContainer} style={backgroundStyle}>
        <div className={classes.title}>{children}</div>
        <SearchInput
          readyToSearch={elementSearchConfig.readyToSearch}
          currentPosition={counter.current}
          amountOfPositions={counter.total}
          value={searchValue}
          onChange={handleChangeSearchValue}
          onClose={handleClose}
          onBack={backElement}
          onNext={nextElement}
        />
      </div>
    </Fade>
  );
}

export default ElementSearcher;
