import { showSnackbarError, showSnackbarInfo } from '@zert-packages/components/Snackbars';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import ConfirmationDialog from '@zert-packages/components/common/dialogs/ConfirmationDialog';
import HoverWithSnackbarLoaderDialog from '@zert-packages/components/common/dialogs/HoverWithSnackbarLoaderDialog';
import NameEditingDialog from '@zert-packages/components/common/dialogs/NameEditingDialog';
import getDuplicationDialog from '@zert-packages/components/ElementTile/DuplicationDialogs';
import ModalElementTileTable from '@zert-packages/components/ElementTile/ModalElementTileTable';
import ReportDialog from '@zert-packages/components/common/dialogs/ReportDialogs/ReportDialog';
import { createInProgressSnack } from '@zert-packages/components/common/snackBar';
import SearchPane from './SearchPane';
import { analizeIfSearch } from './helpers/analizeIfSearch';
import { getCriterias } from './helpers/getCriterias';
import { checkReportStatus } from '../ReportPlugin/servicesMidleware';
import removeElementsMockUp from './helpers/removeElementsMockUp';
import {
  loadingNodeContent,
  loadNodeChildren,
  loadNodeChildrenCut,
  loadNodeContent,
  loadRoot,
  loadSearchResult,
  setCopyContext,
  setCutNode,
  setNode,
  setSearchCondition,
  setSelectedPath,
  updateTreeNodes
} from './explorerReducers';
import {
  addElementsToFolderApi,
  archiveMultipleApi,
  createChildFolderApi,
  createSearchFolderAPI,
  cutAPI,
  getChildNodesApi,
  getNodeContentApi,
  getRootNodeApi,
  removeMultipleApi,
  renameApi,
  searchApi
} from './API';
import { handlePostFile } from '@zert-packages/actions/api';

export const createSearchFolder =
  (reload) =>
  async (dispatch, getState, { snackbar }) => {
    renderInExceptionRoot(NameEditingDialog, {
      name: '',
      content: <SearchPane />,
      dialogName: <FormattedMessage id="CreateNewSearchFolderDialog.Title" defaultMessage="Create new Search folder" />,
      confirmButtonText: <FormattedMessage id="CreateNew.Create" defaultMessage="Create" />,
      cancelButtonText: <FormattedMessage id="CreateNew.Cancel" defaultMessage="Cancel" />,
      onConfirm: async (newName) => {
        const { searchConditions, selectedNode } = getState().CORE;

        const searchCriteria = getCriterias(searchConditions, false);
        const message = analizeIfSearch(searchCriteria);
        if (message != null) {
          showSnackbarInfo(snackbar, message);
          return;
        }
        try {
          const newFolder = await createSearchFolderAPI(selectedNode.id, newName, searchCriteria);
          if (reload) {
            reload(newFolder);
          } else {
            dispatch(getNodeContext(selectedNode, true));
            dispatch(getChildNodes(selectedNode.id, true));
          }
        } catch (e) {
          showSnackbarError(snackbar, e.message);
          if (reload) {
            reload();
          }
        }
      }
    });
  };

export const updateSearchElements =
  (children) =>
  async (dispatch, getState, { snackbar }) => {
    try {
      dispatch(loadingNodeContent(true));

      dispatch(loadSearchResult(children));
      dispatch(loadingNodeContent(false));
    } catch (e) {
      dispatch(loadingNodeContent(false));
      showSnackbarError(snackbar, e.message);
    }
  };

export const searchElements =
  (isAllCompanies) =>
  async (dispatch, getState, { snackbar }) => {
    try {
      const { searchConditions } = getState().CORE;
      const searchCriteria = getCriterias(searchConditions, isAllCompanies);
      const message = analizeIfSearch(searchCriteria);
      if (message != null) {
        showSnackbarInfo(snackbar, message);
        return;
      }

      dispatch(loadingNodeContent(true));
      dispatch(setSearchCondition(searchCriteria));
      const children = await searchApi(searchCriteria);
      dispatch(loadSearchResult(children));
      dispatch(loadingNodeContent(false));
    } catch (e) {
      dispatch(loadingNodeContent(false));
      showSnackbarError(snackbar, e.message);
    }
  };

export const getChildNodesAfterCut =
  () =>
  async (dispatch, getState, { snackbar }) => {
    const { cutNode, selectedNode, copiedElements } = getState().CORE;
    try {
      const children = await getChildNodesApi(selectedNode.id);
      const children2 = await getChildNodesApi(cutNode.id);
      dispatch(
        loadNodeChildrenCut({ ownerId: selectedNode.id, children, cutNode: cutNode.id, childrenCutNode: children2 })
      );
    } catch (e) {
      showSnackbarError(snackbar, e.message);
    }
  };

export const getChildNodes =
  (ownerId, updateTree) =>
  async (dispatch, getState, { snackbar }) => {
    try {
      const children = await getChildNodesApi(ownerId);
      dispatch(loadNodeChildren({ ownerId, children }));
      if (updateTree) {
        dispatch(updateTreeNodes(true));
      }
    } catch (e) {
      showSnackbarError(snackbar, e.message);
    }
  };

export const getRootNode =
  () =>
  async (dispatch, getState, { snackbar }) => {
    try {
      const root = await getRootNodeApi();
      dispatch(loadRoot(root));
      dispatch(getNodeContext(root, false));
    } catch (e) {
      showSnackbarError(snackbar, e.message);
    }
  };
export const generateReportDialog =
  (elements, catalogIndex, selected) =>
  async (dispatch, getState, { snackbar }) => {
    renderInExceptionRoot(ReportDialog, {
      selectedNode: catalogIndex >= 0 ? selected : 'none',
      selectedValues: elements,
      catalogIndex
    });
  };

export const removeElements =
  (elements, callback) =>
  async (dispatch, getState, { snackbar }) => {
    const { selectedNode } = getState().CORE;
    const ids = elements.map((element) => element.id);

    renderInExceptionRoot(ConfirmationDialog, {
      contextText: <ModalElementTileTable elements={elements} />,
      titleText: (
        <FormattedMessage
          id="ElementTile.ConfirmationDeleteText"
          defaultMessage="Are you sure that you want to remove following elements?"
        />
      ),
      confirmButtonText: <FormattedMessage id="RemoveElements.Remove" defaultMessage="Remove" />,
      onConfirm: async () => {
        const archiveElements = removeElementsMockUp();
        const generatingSnackbar = createInProgressSnack(
          snackbar,
          <FormattedMessage
            id="Explorer.Removing.Progress"
            defaultMessage="...removing {count} elements"
            values={{ count: ids.length }}
          />
        );
        try {
          const res = await removeMultipleApi(archiveElements.uuid, selectedNode, ids);
          await checkReportStatus(res, snackbar, generatingSnackbar, archiveElements, null, callback, elements);
        } catch (e) {
          snackbar.closeSnackbar(generatingSnackbar);
          handleSnackbarError(null, snackbar, e);
          if (callback) {
            callback();
          }
        }
      }
    });
  };

export const createNewFolder =
  (reload) =>
  async (dispatch, getState, { snackbar }) => {
    const { selectedNode } = getState().CORE;
    renderInExceptionRoot(NameEditingDialog, {
      name: '',
      dialogName: <FormattedMessage id="CreateNewFolderDialog.Title" defaultMessage="Create new folder" />,
      confirmButtonText: <FormattedMessage id="CreateNew.Create" defaultMessage="Create" />,
      cancelButtonText: <FormattedMessage id="CreateNew.Cancel" defaultMessage="Cancel" />,
      onConfirm: async (newName) => {
        try {
          const newFolder = await createChildFolderApi(selectedNode.id, newName);
          if (reload) {
            reload(newFolder);
          } else {
            dispatch(getNodeContext(selectedNode, true));
            dispatch(getChildNodes(selectedNode.id, true));
          }
        } catch (e) {
          showSnackbarError(snackbar, e.message);
          if (reload) {
            reload();
          }
        }
      }
    });
  };

export const rename =
  (element, reload) =>
  async (dispatch, getState, { snackbar }) => {
    renderInExceptionRoot(NameEditingDialog, {
      name: element.name,
      onConfirm: async (newName) => {
        try {
          await renameApi(element.versionId, newName);
          if (reload) {
            reload(element);
          }
        } catch (e) {
          showSnackbarError(snackbar, e.message);
          if (reload) {
            reload(element);
          }
        }
      }
    });
  };

const freezeScreen = (snackbar) => renderInExceptionRoot(HoverWithSnackbarLoaderDialog, { snackbar });
const handleSnackbarError = (freezeOff, snackbar, e) => {
  if (freezeOff) freezeOff();
  showSnackbarError(snackbar, e.message);
};

export const archiveElements =
  (type, elements, callback) =>
  async (dispatch, getState, { snackbar }) => {
    const ids = elements.map((element) => element.versionId);

    renderInExceptionRoot(ConfirmationDialog, {
      contextText: <ModalElementTileTable elements={elements} />,
      titleText: (
        <FormattedMessage
          id="ElementTile.ConfirmationArchiveText"
          defaultMessage="Are you sure that you want to archive following elements?"
        />
      ),
      confirmButtonText: <FormattedMessage id="ArchiveElements.Archive" defaultMessage="Archive" />,
      onConfirm: async () => {
        // const freezeOf = freezeScreen();
        const archiveElements = removeElementsMockUp();
        const generatingSnackbar = createInProgressSnack(
          snackbar,
          <FormattedMessage
            id="Explorer.Archiving.Progress"
            defaultMessage="...archiving {count} elements"
            values={{ count: ids.length }}
          />
        );
        try {
          const res = await archiveMultipleApi(type, archiveElements.uuid, ids);
          checkReportStatus(res, snackbar, generatingSnackbar, archiveElements, null, callback, elements);
        } catch (e) {
          snackbar.closeSnackbar(generatingSnackbar);
          if (callback) {
            callback(elements);
          }
          handleSnackbarError(null, snackbar, e);
        }
      }
    });
  };

export const duplicateCommon =
  (info, isFolder, callback) =>
  async (dispatch, getState, { snackbar }) => {
    const { selectedNode } = getState().CORE;

    renderInExceptionRoot(getDuplicationDialog(info), {
      element: info,
      onFinish: async (res) => {
        try {
          if (isFolder) {
            await addElementsToFolderApi(selectedNode.id, [res.left ? res.left.versionId : res.versionId]);
          }
          if (callback) {
            callback(res.left ? res.left : res);
          }
        } catch (e) {
          handleSnackbarError(null, snackbar, e);
          if (callback) {
            callback(res.left ? res.left : res);
          }
        }
      }
    });
  };

export const pasteCopiedElements =
  (callback) =>
  async (dispatch, getState, { snackbar }) => {
    const { cutNode, selectedNode, copiedElements } = getState().CORE;
    const versionIds = copiedElements.map((element) => element.versionId);

    try {
      if (cutNode) {
        const elementIds = copiedElements.map((element) => element.id);
        await cutAPI(cutNode, elementIds);
      }
      await addElementsToFolderApi(selectedNode.id, versionIds);
      dispatch(getNodeContext(selectedNode, true));
      dispatch(getChildNodes(selectedNode.id, true));

      if (callback) {
        callback(copiedElements);
      }
    } catch (e) {
      handleSnackbarError(null, snackbar, e);
      if (callback) {
        callback();
      }
    }
  };

export const uploadFileToExplorer =
  (filedata) =>
  async (dispatch, getState, { snackbar }) => {
    const { selectedNode } = getState().CORE;

    try {
      let uploadedFile = await handlePostFile('/document/uploadDocument', filedata);
      await addElementsToFolderApi(selectedNode.id, [uploadedFile.versionId]);
      dispatch(getNodeContext(selectedNode, true));
      dispatch(getChildNodes(selectedNode.id, true));
    } catch (e) {
      handleSnackbarError(null, snackbar, e);
    }
  };

export const createLinkInExplorer =
  (createdLink) =>
  async (dispatch, getState, { snackbar }) => {
    const { selectedNode } = getState().CORE;

    try {
      await addElementsToFolderApi(selectedNode.id, [createdLink.versionId]);
      dispatch(getNodeContext(selectedNode, true));
      dispatch(getChildNodes(selectedNode.id, true));
    } catch (e) {
      handleSnackbarError(null, snackbar, e);
    }
  };

export const copyElements =
  (selectedElements, isCut) =>
  (dispatch, getState, { snackbar }) => {
    const { selectedNode } = getState().CORE;
    dispatch(setCopyContext(selectedElements));
    dispatch(setCutNode(isCut ? selectedNode : null));

    const message = !isCut ? (
      <FormattedMessage
        id="ExplorerActions.CopiedMessage"
        defaultMessage="{name} - copied"
        values={{
          name: selectedElements.length == 1 ? selectedElements[0].name : selectedElements.length
        }}
      />
    ) : (
      <FormattedMessage
        id="ExplorerActions.CutedMessage"
        defaultMessage="{name} - cut. Paste at folder or node you wants"
        values={{
          name: selectedElements.length == 1 ? selectedElements[0].name : selectedElements.length
        }}
      />
    );
    showSnackbarInfo(snackbar, message);
  };

export const updateNodeContextWithOutRefresh =
  (content) =>
  async (dispatch, getState, { snackbar }) => {
    try {
      dispatch(loadingNodeContent(true));
      dispatch(loadNodeContent(content));
      dispatch(loadingNodeContent(false));
    } catch (e) {
      showSnackbarError(snackbar, e.message);
    }
  };

export const getNodeContext =
  (parent, refresh) =>
  async (dispatch, getState, { snackbar }) => {
    const { selectedNode, selectedPath } = getState().CORE;

    try {
      if (refresh || !selectedNode || selectedNode.id != parent.id) {
        dispatch(loadingNodeContent(true));
        const content = await getNodeContentApi(parent.id);

        dispatch(loadNodeContent(content));
        dispatch(setNode(parent));
        dispatch(loadingNodeContent(false));
        if (!selectedPath.includes(parent.id)) {
          dispatch(setSelectedPath([...selectedPath, parent.id]));
        } else if (selectedPath.includes(parent.id) && selectedNode && selectedNode.id === parent.id) {
          const newSelectedPath = [...selectedPath];
          newSelectedPath.splice(newSelectedPath.indexOf(parent.id), 1);
          dispatch(setSelectedPath([newSelectedPath]));
        }
      }
    } catch (e) {
      showSnackbarError(snackbar, e.message);
    }
  };
