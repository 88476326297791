import React from 'react';
import getTranslation from '@zert-packages/utils/getTranslation.old';

export const findPath = (value, catalog) => {
  const sel = [];
  const gData = [];
  gData.push({
    label: `${getTranslation(catalog.rootNode.label)}`,
    value: `${`${catalog.rootNode.uuid}^${catalog.rootNode.metadata[0].value}`}`,
    key: `${catalog.rootNode.uuid}`,
    expanded: true,
    disabled: false,
    children: _loop(catalog.rootNode)
  });

  function _loop(node) {
    const children = [];
    node.children.map((child) => {
      children.push({
        label: `${getTranslation(child.label)}`,
        value: `${`${child.uuid}^${child.metadata[0].value}`}`,
        key: `${child.uuid}`,
        disabled: child.metadata[0].value == '',
        expanded: false,
        children: _loop(child)
      });
      if (child.children.length != 0) {
        child.children.map((_child) => {
          return _loop(_child);
        });
      } else {
        return '[]';
      }
    });
    return children;
  }

  function loop(selected, children) {
    for (let i = 0; i < children.length; i++) {
      const item = children[i];
      if (selected === item.value) {
        sel.push(item);
        return;
      }
      if (item.children) {
        loop(selected, item.children, item);
        if (sel.length) {
          sel.push(item);
          return;
        }
      }
    }
  }

  loop(value, gData);
  return sel
    .map((i) => i.label)
    .reverse()
    .join(' > ');
};

export const populateCatalog = (catalog) => {
  let gData = {};
  if (catalog != null) {
    gData = {
      label: `${getTranslation(catalog.rootNode.label)}`,
      strongLabel: `${getTranslation(catalog.rootNode.label)}`,
      value: `${`${catalog.rootNode.uuid}^${catalog.rootNode.metadata[0].value}`}`,
      id: `${`${catalog.rootNode.uuid}^${catalog.rootNode.metadata[0].value}`}`,
      key: `${catalog.rootNode.uuid}`,
      expanded: true,
      disabled: false,
      children: _loop(catalog.rootNode, true)
    };

    function _loop(node, isStronglabel) {
      const children = [];
      node.children.map((child) => {
        let strongLabel;
        if (isStronglabel) {
          strongLabel = `${getTranslation(child.label)}`;
        }
        children.push({
          label: `${getTranslation(child.label)}`,
          strongLabel,
          value: `${`${child.uuid}^${child.metadata[0].value}`}`,
          id: `${`${child.uuid}^${child.metadata[0].value}`}`,
          key: `${child.uuid}`,
          disabled: child.metadata[0].value == '',
          expanded: false,
          children: _loop(child)
        });
        if (child.children.length != 0) {
          child.children.map((_child) => {
            return _loop(_child, false);
          });
        } else {
          return '[]';
        }
      });
      return children;
    }
  }
  return gData;
};

export default class CatalogTreeNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.node.expanded,
      selected: this.props.selected,
      wasSelected: false
    };
  }

  toggle = (e) => {
    this.setState({
      visible: !this.state.visible,
      wasSelected: true
    });
    this.props.selectTreeNode(e);
  };

  render() {
    let children;
    let className;
    const selectTreeNod = this.props.selectTreeNode;
    const select = this.props.selected;
    const selectedPath = this.props.path;
    if (this.props.node.children != null && this.props.node.children.length > 0) {
      children = this.props.node.children.map(function (node, index) {
        return (
          <li key={node.key}>
            <CatalogTreeNode node={node} selectTreeNode={selectTreeNod} selected={select} path={selectedPath} />
          </li>
        );
      });

      className = 'togglable';
      if (this.state.visible || (!this.state.wasSelected && this.props.path.indexOf(this.props.node.label) != -1)) {
        className += ' togglable-down';
      } else {
        className += ' togglable-up';
      }
    } else {
      className = 'untogglable';
    }

    let style;
    if (
      this.state.visible == false &&
      (this.state.wasSelected || this.props.path.indexOf(this.props.node.label) == -1)
    ) {
      style = { display: 'none' };
    }

    return (
      <div style={{ padding: '0 0 0 10px' }}>
        <input
          type="radio"
          name="catalog_tree"
          checked={this.props.node.value == this.props.selected}
          id={this.props.node.value}
          value={this.props.node.label}
          style={{ display: 'none' }}
          onClick={this.toggle}
        />
        <label
          data-intro-id={this.props.firstNode ? 'CatalogExplorerPage.RootNode' : ''}
          htmlFor={this.props.node.value}
          className={`noselect ${className}`}
          style={{
            fontSize: '1rem',
            cursor: this.props.node.disabled ? 'not-allowed' : 'pointer',
            color: this.props.firstNode ? '#13c8f9' : 'inherit'
          }}
        >
          {this.props.node.label}
        </label>

        <ul style={style} className="noDecoration">
          {children}
        </ul>
      </div>
    );
  }
}
