import React, { useContext, useEffect, useState } from "react";
import TabsWithVersionController from "@zert-packages/components/common/TabsWithVersionController";
import { generatePreviewReport } from "../PreviewPlugin/servicesMiddlewares";
import { generateTermDBExport } from "../SoftwareTextPlugin/serviceMiddleware";
import useVersionManager from "@zert-packages/utils/useVersionManager";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { saveTemplate } from "./utils/TabsWithVersionControllerAction";
import { TabsContext } from "./FormatTemplatePlugin";
import { createInProgressSnack } from "@zert-packages/components/common/snackBar";
import { useSnackbar } from "notistack";
import { getVersionInfo } from "@zert-packages/actions/coreReducers";
import handleValueState from "@zert-packages/utils/handleValueState";


const FormatTemplateWrapper = ({ children, selectedListItem, selectedTab, leftMenu }) => {
  const [versionManager, versionInfo] = useVersionManager();
  const { formatTemplate } = useSelector((state) => state);
  const { versionId } = useParams();
  const dispatch = useDispatch();
  const { values, setValues } = useContext(TabsContext);
  const snackbar = useSnackbar();
  const [format, setFormat] = useState({
    block: false,
  })



  const handleFormat = async (type) => {
    if (type === "save") {
      handleValueState(true, "block", setFormat)
      const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..." />
      );
      await saveTemplate(versionId, formatTemplate, values, selectedListItem, selectedTab, leftMenu)
      handleValueState(false, "block", setFormat)
      snackbar.closeSnackbar(generatingSnackbar);
    }
  };

  const ButtonList = () => {
    return [
      {
        disabled: format.block,
        type: "Save",
        label: <span onClick={() => handleFormat("save")}><FormattedMessage id="ElementTile.Actions.Save"
                                                                            defaultMessage="Save" /></span>
      }
    ];
  };


  useEffect(() => {
    dispatch(getVersionInfo(versionId));
  }, [versionId])

  useEffect(() => {
    if(versionInfo && versionInfo.info.approvedAt){
      handleValueState(true, "block", setFormat)
    } else {
      handleValueState(false, "block", setFormat)
    }
  }, [versionInfo])


// if
  return (<div>
    <TabsWithVersionController
      canCreateVersion={versionManager.canCreateNewVersion}
      canSaveVersion={false} // versionManager.canSaveVersion}
      canApproveVersion={versionManager.canApproveVersion}
      onCreateNewVersion={versionManager.createNewVersion}
      onApproveVersion={versionManager.approveVersion}
      onSaveVersion={() => false}
      canPreview
      onPreview={() => {
        dispatch(generatePreviewReport(versionInfo.info, -1));
      }}
      canExport
      onExport={() => {
        dispatch(generateTermDBExport(versionId));
      }}
      tabs={ButtonList()}
    />
    {children}
  </div>);
};

export default FormatTemplateWrapper;