import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import {findHeader} from "../../helpers/actionPhraseEditor";
import {makeStyles} from "@mui/styles";
import TooltipCustom from "../../../TooltipCustom";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import getTranslation from "@zert-packages/utils/getTranslation.old";
import {handleBlock} from "@zert-packages/actions/commonReducer";
import TextFormats from "./TextFormats";
import DropMenuStyle from "./DropMenuStyle";
import Loading from "clm/src/plugins/StylesheetPlugin/Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: -7,
        borderRadius: "12px 12px 0px 0px",
        minHeight: 30,
        display: "flex",
        alignItems: "center",
        "& button:focus": {
            outline: "none"
        }

    },
    wrapperDropMenu: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        maxWidth: "fit-content"
    },
    wrapper: {
        display: "flex",
        gap: 10,
        width: "100%",
        alignItems: "center",
        padding: "5px 0px",
        flexWrap: "nowrap"
    },
    icon: {
        cursor: "pointer",
        "&:hover": {
            color: "#4DB1D3"
        }
    },
    iconBlur: {
        opacity: "0.5",
        cursor: "not-allowed"
    },
    loader: {
        "& span": {
            color: '#ffffff'
        }
    }
}));

const dataInsert = [
    {
        name: <FormattedMessage id="PhraseEditor.Head.Actions.Alias" defaultMessage="Alias"/>,
        type: "Alias"
    },
    {
        name: <FormattedMessage id="PhraseEditor.Head.Actions.CrossReference" defaultMessage="Cross reference"/>,
        type: "Cross reference"
    },
    {
        name: <FormattedMessage id="PhraseEditor.Head.Actions.ExistingImage" defaultMessage="Existing image"/>,
        type: "Existing image"
    },
    {
        name: <FormattedMessage id="PhraseEditor.Head.Actions.NewImage" defaultMessage="New image"/>,
        type: "New image"
    },
    {
        name: "Symbol",
        type: "Symbol"
    }
];
const dataButton = ["bold", "italic"];

const PhraseEditorMenu = () => {
    const cl = useStyles();
    const {formatTemplate, block} = useSelector((state) => state);
    const dispatch = useDispatch();
    const undoStackDisable = block?.editorState?.getUndoStack().size === 0;
    const redoStackDisable = block?.editorState?.getRedoStack().size === 0;

    const [header, setHeader] = useState({
        textFormatButton: null,
        textFormatItem: null
    });


    const adobeDropMenu = (list) => {
        return list.map(el => ({name: getTranslation(el.label), style: el.identifier}));
    };


    const handlePhrase = (type) => {
        dispatch(handleBlock(type, 'phraseEditorActionType'))
    }


    useEffect(() => {
        if (formatTemplate?.textFormats) {
            findHeader(formatTemplate, dataButton, setHeader);
        }
    }, [formatTemplate && formatTemplate.textFormats]);



    return (<div className={cl.root}>
        {header.textFormatItem
            ? <div className={cl.wrapper}>
                <TooltipCustom title={"Undo"}>
                    <UndoIcon
                        onClick={() => handlePhrase('undo')}
                        className={undoStackDisable ? cl.iconBlur : cl.icon}
                    />
                </TooltipCustom>
                <TooltipCustom title={"Redo"}>
                    <RedoIcon
                        onClick={() => handlePhrase('redo')}
                        className={redoStackDisable ? cl.iconBlur : cl.icon}
                    />
                </TooltipCustom>
                {block?.editorState && <>
                    {header.textFormatButton && header.textFormatButton.map((el, i) =>
                        <TextFormats key={i} type={el.comboValue.toUpperCase()}/>)}
                    {header.textFormatItem &&
                        <div className={cl.wrapperDropMenu}
                             onMouseDown={(e) => e.preventDefault()}>
                            <DropMenuStyle
                                label={<FormattedMessage id="ElementTile.PhraseEditor.Header.TextFormats"
                                                         defaultMessage="Text formats"/>}
                                dropMenu={adobeDropMenu(header.textFormatItem)}
                                handle={handlePhrase}
                            />
                        </div>}
                    <DropMenuStyle
                        label={<FormattedMessage id="ElementTile.PhraseEditor.Header.Insert" defaultMessage="Insert"/>}
                        dropMenu={dataInsert}
                        handle={handlePhrase}
                    />
                </>}

            </div> : <div className={cl.loader}>
                <Loading />
            </div> }
    </div>);
}

export default PhraseEditorMenu