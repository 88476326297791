import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Preview from './Preview';
import { TabsContext } from '../../Stylesheet';
import GroupBlock from '../blockTabs/pageLayout/GroupBlock';
import { adobeWord, handleParagraphValue } from '../../utils/helpers';
import TemplateItemRow from '../../TemplateItemRow';
import SpacingTemplate from '../blockTabs/topic/SpacingTemplate';

const useStyles = makeStyles((theme) => ({
  wrapperPreview: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10
  },
  wrapperLineHeight: {
    display: 'grid',
    gridTemplateColumns: '1fr 25px',
    gridColumnGap: 10,
    alignItems: 'center'
  },
  title: {
    fontWeight: 700,
    marginBottom: 0
  },
  borderBlock: {
    border: '1px solid #C6C6C5',
    padding: 10,
    display: 'grid',
    gridRowGap: 10
  },
  wrapperContainer: {
    display: 'grid',
    gridRowGap: 10
  }
}));

function IndentSpacing({ paragraph, templatesIdx }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);

  const indentProps = [
    { label: 'Left:', value: paragraph.marginLeft && paragraph.marginLeft, type: 'marginLeft' },
    { label: 'Right:', value: paragraph.marginRight && paragraph.marginRight, type: 'marginRight' }
  ];
  const handleValuesBlock = (val, name) => {
    const res = { ...paragraph[name], value: +val };
    handleParagraphValue(res, name, 'paragraphSettings', templatesIdx, values, setValues);
  };
  const handleSelectedDropMenu = (valDropMenu, name, val) => {
    const res = { ...paragraph[name], unit: valDropMenu, value: +val };
    handleParagraphValue(res, name, 'paragraphSettings', templatesIdx, values, setValues);
  };
  const setValueLineHeight = (val, name) => {
    const res = {
      ...paragraph.lineHeight,
      defaultValue: {
        ...paragraph.lineHeight.defaultValue,
        value: { value: +val, unit: paragraph.lineHeight.defaultValue.value.unit }
      }
    };
    handleParagraphValue(res, name, 'paragraphSettings', templatesIdx, values, setValues);
  };
  const handleLineHeightMenu = (valDropMenu, name, val) => {
    const res = {
      ...paragraph.lineHeight,
      defaultValue: {
        ...paragraph.lineHeight.defaultValue,
        value: { value: +val, unit: valDropMenu }
      }
    };
    handleParagraphValue(res, name, 'paragraphSettings', templatesIdx, values, setValues);
  };

  const handleMenuFirst = (val, name) => {
    const res = {
      ...paragraph.lineHeight,
      defaultValue: {
        ...paragraph.lineHeight.defaultValue,
        type: val.toUpperCase()
      }
    };
    handleParagraphValue(res, name, 'paragraphSettings', templatesIdx, values, setValues);
  };
  const findDisable = () => {
    const lineHeight = adobeWord(paragraph.lineHeight.defaultValue.type, true);
    if (lineHeight === 'Default') {
      return true;
    }
    if (lineHeight === 'Single') {
      return true;
    }
    return false;
  };

  const handleSpacing = (val, name) => {
    const res = {
      ...paragraph[name],
      value: {
        ...paragraph[name].value,
        unit: paragraph[name].value ? paragraph[name].value.unit : 'pt',
        value: +val
      }
    };
    handleParagraphValue(res, name, 'paragraphSettings', templatesIdx, values, setValues);
  };

  const handleSpacingMenu = (valDrop, name, val) => {
    const res = { ...paragraph[name], value: { unit: valDrop, value: +val } };
    handleParagraphValue(res, name, 'paragraphSettings', templatesIdx, values, setValues);
  };

  const handleSpacingBefore = (val, name, nameObject) => {
    const res =
      val === 'Default'
        ? { ...paragraph[name], [nameObject]: val, value: { ...paragraph[name].value, value: 0, unit: 'pt' } }
        : { ...paragraph[name], [nameObject]: val };
    handleParagraphValue(res, name, 'paragraphSettings', templatesIdx, values, setValues);
  };

  const handleValuePropsDialogBefore = (val, name) => {
    const res = {
      ...paragraph.spaceBefore,
      [name]: {
        unit: paragraph.spaceBefore[name] ? paragraph.spaceBefore[name].unit : 'pt',
        value: +val
      }
    };
    handleParagraphValue(res, 'spaceBefore', 'paragraphSettings', templatesIdx, values, setValues);
  };

  const setDropMenuPropsDialogBefore = (valDrop, name, val) => {
    const res = {
      ...paragraph.spaceBefore,
      [name]: {
        unit: valDrop,
        value: +val
      }
    };
    handleParagraphValue(res, 'spaceBefore', 'paragraphSettings', templatesIdx, values, setValues);
  };

  const setDropMenuPrecedenceBefore = (val) => {
    const res = { ...paragraph.spaceBefore, precedence: val.name };
    handleParagraphValue(res, 'spaceBefore', 'paragraphSettings', templatesIdx, values, setValues);
  };

  const handleValuePropsDialogAfter = (val, name) => {
    const res = {
      ...paragraph.spaceAfter,
      [name]: {
        unit: paragraph.spaceAfter[name] ? paragraph.spaceAfter[name].unit : 'pt',
        value: +val
      }
    };
    handleParagraphValue(res, 'spaceAfter', 'paragraphSettings', templatesIdx, values, setValues);
  };

  const setDropMenuPropsDialogAfter = (valDrop, name, val) => {
    const res = {
      ...paragraph.spaceAfter,
      [name]: {
        unit: valDrop,
        value: +val
      }
    };
    handleParagraphValue(res, 'spaceAfter', 'paragraphSettings', templatesIdx, values, setValues);
  };

  const setDropMenuPrecedenceAfter = (val) => {
    const res = { ...paragraph.spaceAfter, precedence: val.name };
    handleParagraphValue(res, 'spaceAfter', 'paragraphSettings', templatesIdx, values, setValues);
  };


  return (
    <div className={cl.wrapperPreview}>
      <div className={cl.wrapperContainer}>
        <GroupBlock
          label="Indent"
          valueProps={indentProps}
          setValueProps={handleValuesBlock}
          setDropMenu={handleSelectedDropMenu}
          style={{ padding: '5px 10px' }}
        />

        <p className={cl.title}>Space</p>
        <div className={cl.borderBlock}>
          <div>
            <SpacingTemplate
              title="Before:"
              type="spaceBefore"
              valueProps={paragraph.spaceBefore}
              grid="100px 150px 1fr 55px"
              setValueProps={handleSpacing}
              setDropMenu={handleSpacingMenu}
              handleBefore={handleSpacingBefore}
              setValuePropsDialog={handleValuePropsDialogBefore}
              setDropMenuPropsDialog={setDropMenuPropsDialogBefore}
              setDropMenuPrecedence={setDropMenuPrecedenceBefore}
            />
          </div>
          <div>
            <SpacingTemplate
              title="After:"
              type="spaceAfter"
              valueProps={paragraph.spaceAfter}
              grid="100px 150px 1fr 55px"
              setValueProps={handleSpacing}
              setDropMenu={handleSpacingMenu}
              handleBefore={handleSpacingBefore}
              setValuePropsDialog={handleValuePropsDialogAfter}
              setDropMenuPropsDialog={setDropMenuPropsDialogAfter}
              setDropMenuPrecedence={setDropMenuPrecedenceAfter}
            />
          </div>
        </div>
        <p className={cl.title}>Line height:</p>
        <div className={cl.borderBlock}>
          <div className={cl.wrapperLineHeight}>
            <TemplateItemRow
              type="lineHeight"
              value={paragraph?.lineHeight?.defaultValue?.value }
              dropMenuFirst={{
                label: ['Default', 'Single', 'At least', 'Fixed'],
                selected: adobeWord(paragraph.lineHeight.defaultValue.type, true)
              }}
              grid="150px 1fr 55px"
              setDropMenuFirst={handleMenuFirst}
              setDropMenu={handleLineHeightMenu}
              setValueProps={setValueLineHeight}
              disabled={findDisable()}
            />
            <InsertDriveFileIcon style={{ color: 'gray', cursor: 'pointer' }} />
          </div>
        </div>
      </div>
      <div>
         <p className={cl.title} style={{marginBottom: 5}}>Preview</p>
        <Preview paragraph={paragraph} />
      </div>

    </div>
  );
}

export default IndentSpacing;
