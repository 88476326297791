import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import dialogCustom from '../common/dialogCustom';
import './ErrorGlobalFallbackComponent.css';
import {API_ROOT_WAR, handlePostResultAsString, handlePostToApi} from '@zert-packages/actions/api';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

function ErrorGlobalFallbackComponent({ errorState }) {
  const user = useSelector((state) => state.myuser);

  const handleTakeMeOutOfHere = () => {
    window.location.assign(`${API_ROOT_WAR}/`);
  };
  const handleSendReport = () => {
    dialogCustom(ReportToSupportDialog, {
      errorState,
      user
    });
  };

  const handleShowErrors = () => {
    dialogCustom(ErrorDialog, { errorState });
  };

  return (
    <div className="error-global-fallback-component__container">
      <div>
        <h1 className="error-global-fallback-component__header">
          <FormattedMessage id="error.errorMessage" defaultMessage="Aaah! Something went wrong" />
        </h1>
      </div>
      <div className="error-global-fallback-component__actions-container">
        <Button variant="contained" color="secondary" onClick={handleSendReport}>
          <FormattedMessage id="error.report" defaultMessage="Report" />
        </Button>
        <Button variant="contained" onClick={handleShowErrors}>
          <FormattedMessage id="error.show" defaultMessage="Show error" />
        </Button>
        <Button variant="contained" color="primary" onClick={handleTakeMeOutOfHere}>
          <FormattedMessage id="error.take.meout" defaultMessage="Take me out here!" />
        </Button>
      </div>
    </div>
  );
}

export default ErrorGlobalFallbackComponent;

function ReportToSupportDialog({ errorState, user, handleClose }) {
  const [state, setState] = useState({
    subject: '',
    description: ''
  });

  const handleChangeState = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSendReport = () => {
    handlePostToApi('/common/sendError', {
      description: state.description,
      subject: state.subject,
      title: errorState.message,
      trace: errorState.stack,
      name: user ? user.fullname : '',
      company: user ? user.companyName : '',
      email: user ? user.email : '',
      url: window.location.href
    }).then(() => {
      window.location.assign(`${API_ROOT_WAR}/`);
      // document.location.reload();
    });
  };

  return (
    <Dialog fullWidth open onClose={handleClose}>
      <DialogTitle>
        {' '}
        <FormattedMessage id="error.reporting" defaultMessage="Error Reporting" />
      </DialogTitle>
      <DialogContent>
        <div className="error-global-fallback-component__dialog-container">
          <TextField fullWidth label="Subject" name="subject" value={state.subject} onChange={handleChangeState} />
          <TextField
            fullWidth
            multiline
            variant="outlined"
            label="Description"
            name="description"
            value={state.description}
            onChange={handleChangeState}
            rows={8}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSendReport} color="secondary" autoFocus>
          <FormattedMessage id="error.send" defaultMessage="Send" />
        </Button>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="error.cancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ErrorDialog({ errorState, handleClose }) {
  const handleCopy = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(`Message:${errorState.message}\nStack: ${errorState.stack}`)
        .catch((err) => console.log(err));
    }
    handleClose();
  };
  return (
    <Dialog fullWidth maxWidth="md" open onClose={handleClose}>
      <DialogTitle>
        {' '}
        <FormattedMessage id="error.reporting" defaultMessage="Error Reporting" />
      </DialogTitle>
      <DialogContent>
        <div className="error-global-fallback-component__dialog-container">
          <p className="error-global-fallback-component__error-message">
            <FormattedMessage id="error.message" defaultMessage="Message" />: {errorState && errorState.message}
          </p>
          <pre className="error-global-fallback-component__error-pre">{errorState && errorState.stack}</pre>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCopy} color="primary" variant="outlined" autoFocus>
          <FormattedMessage id="error.copy" defaultMessage="Copy" />
        </Button>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="error.back" defaultMessage="Back" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
