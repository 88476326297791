import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LanguageIcon from '@mui/icons-material/Language';
import React, { useContext, useEffect, useState } from 'react';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { handleTabsColumn } from '../../utils/helpers';
import { TabsContext } from '../../Stylesheet';
import DialogEditLabelPart from '../blockTabs/tableContents/dialog/DialogEditLabelPart';

const useStyles = makeStyles((theme) => ({
  wrapperRowContent: {
    display: 'grid',
    gridColumnGap: 10,
    alignItems: 'center'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    marginBottom: 0
  },
  notesInput: {
    margin: 0,
    width: '100%',
    '& input': {
      paddingTop: 5.2,
      paddingBottom: 5.2
    }
  }
}));

function Translatable({ selected, selectedColumn, title = 'Title:', style = 110 }) {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const [type, setType] = useState(null);
  const propsLabel = ['Text', 'Translatable text', 'Include'];
  const include = [
    'attention: Attention!',
    'figure: Figure',
    'function: Function',
    'note: Note!',
    'page: Page',
    'part-name: Part name',
    'part-number: Part number',
    'pieces: Qty',
    'position: Pos',
    'recomended: Rec',
    'table: Table',
    'warning: Warning!',
    'wear-and-tear: W&amp;T'
  ];
  const language = document.getElementsByTagName('html')[0].getAttribute('lang');
  const [test, setTest] = useState(false);

  const handleContentLabel = (e) => {
    const val = e.target.value;
    handleTabsColumn(handleContent(val, type), 'title', 'partSettings', selectedColumn, values, setValues);
  };

  const handleTypeInclude = (val, reset) => {
    handleTabsColumn(handleContent(val, type, reset), 'title', 'partSettings', selectedColumn, values, setValues);
  };

  const findTitleType = (type) => {
    if (type) {
      if (type === 'Include') {
        return { type: 'include', values: selected.title.include, expander: 'include' };
      }
      if (type === 'Translatable text') {
        return {
          type: 'defaultLabel',
          values: selected.title.text.defaultLabel ? selected.title.text.defaultLabel : '',
          expander: 'Translatable text'
        };
      }
      if (type === 'Text') {
        return {
          type: 'untranslatableText',
          values: selected.title.untranslatableText ? selected.title.untranslatableText : '',
          expander: 'Text'
        };
      }
    }

    if (selected.title) {
      if (selected.title.include.length > 0) {
        return { type: 'include', values: selected.title.include, expander: 'include' };
      }
      if (selected.title.text.defaultLabel && selected.title.text.defaultLabel.length > 0) {
        return { type: 'defaultLabel', values: selected.title.text.defaultLabel, expander: 'Translatable text' };
      }
      if (selected.title.untranslatableText.length > 0) {
        return { type: 'untranslatableText', values: selected.title.untranslatableText, expander: 'Text' };
      }
    }
    return { values: '', type: 'defaultLabel', expander: 'Translatable text' };
  };

  const handleContent = (val, type, reset) => {
    if (type === 'Text' || type === 'include') {
      return { ...selected.title, include: val };
    }
    if (type === 'Translatable text' || type === 'defaultLabel') {
      return {
        ...selected.title,
        text: {
          ...selected.title.text,
          defaultLabel: val,
          locales: reset ? [''] : [language],
          translations: [val]
        }
      };
    }
    if (type === 'Include' || type === 'untranslatableText') {
      return { ...selected.title, untranslatableText: val };
    }
  };

  const handleType = (val) => {
    setType(val);
    setTest(true);
  };

  const handleLabel = () => {
    const { text } = selected.title;
    renderInExceptionRoot(DialogEditLabelPart, { values, setValues, text, handleTypeInclude });
  };

  useEffect(() => {
    setType(findTitleType().expander);
  }, [selectedColumn]);

  return (
    <>
      {type && (
        <div
          style={{
            gridTemplateColumns:
              type && type === 'Translatable text' ? `${style}px 180px 1fr 25px` : `${style}px 180px 1fr`
          }}
          className={cl.wrapperRowContent}
        >
          <p className={cl.title}>{title}</p>
          <ExpanderItem valueProps={type} propsLabel={propsLabel} setProps={handleType} />

          {type === 'Include' || type === 'include' ? (
            <ExpanderItem
              valueProps={findTitleType(test ? type : false).values}
              propsLabel={include}
              setProps={handleTypeInclude}
            />
          ) : (
            <TextField
              value={findTitleType(test ? type : false).values}
              className={cl.notesInput}
              variant="outlined"
              onChange={handleContentLabel}
            />
          )}
          {type === 'Translatable text' && (
            <LanguageIcon onClick={handleLabel} style={{ color: 'grey', cursor: 'pointer' }} />
          )}
        </div>
      )}
    </>
  );
}

export default Translatable;
