import { useSelector } from 'react-redux';
import { descendingComparator } from '@zert-packages/components/ElementTile/utils/descendingComparator';
import { getTileElementStatus } from '@zert-packages/components/ElementTile/utils/getTileStatus';

export const usePublishingSorter = () => {
  const myUser = useSelector((state) => state.myuser);
  const getSorter = (name) => {
    switch (name) {
      case 'name':
        return (a, b, sortBy, sortDirection) => {
          if (a.info.mimeType == 'application/zert-folder' && b.info.mimeType != 'application/zert-folder') {
            return sortDirection == 'asc' ? 1 : -1;
          }
          if (b.info.mimeType == 'application/zert-folder' && a.info.mimeType != 'application/zert-folder') {
            return sortDirection == 'asc' ? -1 : 1;
          }

          if (
            a.info.mimeType == 'application/zert-search-folder' &&
            b.info.mimeType != 'application/zert-search-folder'
          ) {
            return sortDirection == 'asc' ? 1 : -1;
          }
          if (
            b.info.mimeType == 'application/zert-search-folder' &&
            a.info.mimeType != 'application/zert-search-folder'
          ) {
            return sortDirection == 'asc' ? -1 : 1;
          }
          /*  if (b.info["name"] ==  a.info["name"]) {
                          return descendingComparator(a, b, "uuid", sortDirection)
                      } */

          // NUMBER_PATTERN.
          return descendingComparator(a.info, b.info, 'name', sortDirection);
        };
      case 'version':
        return (a, b, sortBy, sortDirection) => {
          if (a.info.label == b.info.label) {
            return 0;
          }

          // Special sorting algorithm compares version labels:
          // 1.2.3 vs 4.23.7

          const parts1 = a.info.label.split('.');
          const parts2 = b.info.label.split('.');

          const size = Math.min(parts1.length, parts2.length);
          for (let i = 0; i < size; i++) {
            if (parts1[i] == parts2[i]) {
              // proceed to next point
              continue;
            }
            try {
              return parseInt(parts1[i]) - parseInt(parts2[i]);
            } catch (e) {
              return parts1[i].compareTo(parts2[i]);
            }
            /// jakso ne pryvesty do integera porivnujuts dovzyny v string
          }
          return parts1.length - parts2.length;
        };
      case 'mimeType':
        return (a, b, orderBy) => {
          return descendingComparator(a.info, b.info, 'mimeType');
        };
      case 'status':
        return (a, b, orderBy) => {
          const status1 = getTileElementStatus(a, myUser);
          const status2 = getTileElementStatus(b, myUser);
          if (status1 < status2) {
            return -1;
          }
          if (status1 > status2) {
            return 1;
          }
          return 0;
        };

      case 'updatedAt':
        return (a, b, orderBy) => {
          const sortA = { updatedAt: null };
          const sortB = { updatedAt: null };
          if (
            a.publishings &&
            a.publishings.length > 0 &&
            a.publishings[0].properties &&
            a.publishings[0].publishedAt
          ) {
            sortA.updatedAt = a.publishedAt;
          }

          if (
            a.publishings &&
            a.publishings.length > 0 &&
            a.publishings[0].properties &&
            a.publishings[0].properties['zert:PublishingUpdateAt']
          ) {
            sortA.updatedAt = a.publishings[0].properties['zert:PublishingUpdateAt'];
          }

          if (
            b.publishings &&
            b.publishings.length > 0 &&
            b.publishings[0].properties &&
            b.publishings[0].publishedAt
          ) {
            sortB.updatedAt = b.publishedAt;
          }

          if (
            b.publishings &&
            b.publishings.length > 0 &&
            b.publishings[0].properties &&
            b.publishings[0].properties['zert:PublishingUpdateAt']
          ) {
            sortB.updatedAt = b.publishings[0].properties['zert:PublishingUpdateAt'];
          }

          return descendingComparator(sortA, sortB, 'updatedAt');
        };
      default:
        return descendingComparator;
    }
  };

  return { getSorter };
};
