import React from 'react';

const styles = {
  root: {
    width: '100%',
    height: 'auto',
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '50px 1fr'
  },
  title: {
    fontSize: '1.5rem',
    color: 'rgba(0, 0, 0, 0.5)',
    padding: '10px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
  },
  container: {
    padding: '10px'
  }
};

function BoxWithTitle({ title, children }) {
  return (
    <div style={styles.root}>
      <div style={styles.title}>{title}</div>
      <div style={styles.container}>{children}</div>
    </div>
  );
}

export default BoxWithTitle;
