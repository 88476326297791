import React from 'react';
import { AppBar, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { VersionActionsPanel } from './VersionActionsPanel';

const useStyles = makeStyles({
  appBar: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    zIndex: 10
  }
});

function TabsWithVersionPanelOld({ children, context, value, onChange }) {
  const classes = useStyles();
  return (
    <AppBar position="static" color="default" className={classes.appBar}>
      <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={onChange}>
        {children}
      </Tabs>
      <VersionActionsPanel context={context} />
    </AppBar>
  );
}

export default TabsWithVersionPanelOld;
