import { Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 500,
    height: 345,
    padding: 11,
    background: '#E2F2F6'
  },
  wrapperUnderLabel: {
    marginTop: 10,
    marginBottom: 5
  },

  wrapperTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      cursor: 'pointer'
    }
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 700
  },
  wrapperButton: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 20
  },
  button: {
    color: '#ffffff',
    background: '#4db1d3',
    marginLeft: 10,
    minWidth: 80,
    border: '1px solid #4db1d3',
    outline: 'none !important',
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
      color: '#212529',
      border: '1px solid #4db1d3'
    },
    '&:focus': {
      backgroundColor: '#4db1d3'
    }
  },
  wrapperList: {
    border: '1px solid #BDBDBC',
    fontSize: 13,
    background: '#ffffff',
    marginBottom: 30,
    overflow: 'auto',
    maxHeight: 215
  },
  listItem: {
    padding: '2px 4px',
    cursor: 'pointer'
  },
  listItemActive: {
    padding: '2px 4px',
    color: '#ffffff',
    background: '#4db1d3'
  }
}));

function DialogAddReferences({ values, title = '', setValues }) {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState(0);

  const handleSelected = (idx) => {
    setSelected(idx);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveItem = () => {
    setValues(values[selected].identifier, values[selected]);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paperScrollPaper: cl.root
      }}
    >
      <div className={cl.wrapperTitle}>
        <span className={cl.title}>{title}</span>
        <CloseIcon onClick={handleClose} />
      </div>
      {values && values.length > 0 && (
        <div className={cl.wrapperList}>
          {values.map((el, i) => (
            <div key={i} className={selected === i ? cl.listItemActive : cl.listItem} onClick={() => handleSelected(i)}>
              {el.label ? el.label : `${el.type}: ${el.identifier}`}
            </div>
          ))}
        </div>
      )}
      <div className={cl.wrapperButton}>
        <Button disabled={selected < 0} className={cl.button} onClick={() => saveItem()}>
          OK
        </Button>
        <Button className={cl.button} onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
export default DialogAddReferences;
