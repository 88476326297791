import React from 'react';
import { TextField } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  noteActiveInputWrapper: {
    '& input': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15
    },
    '& textarea': {
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  notesInput: {
    margin: 0,
    width: '100%'
  }
}));

function TextFieldItem({ values = '', name, setProps, type = 'text' }) {
  const cl = useStyles();

  const handleChangeNote = (event) => {
    const val = event.target.value;
    const { name } = event.target;
    if (setProps) {
      setProps(val, name);
    }
  };
  return (
    <div className={cl.noteActiveInputWrapper}>
      <TextField
        type={type}
        value={values}
        className={cl.notesInput}
        variant="outlined"
        onChange={handleChangeNote}
        name={name}
      />
    </div>
  );
}

export default TextFieldItem;
