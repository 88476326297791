import React, { useEffect, useContext, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { TreeItem } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getPluginByMimeTypeIcon } from '@zert-packages/utils/getPluginByMimeType';
import { getFormatTemplate } from '@zert-packages/actions/coreReducers';
import TreeItemBranch from './TreeItemBranch';
import { PanelContext } from "../../InfoElementWrapper/InfoElementWrapper";
import BlockIcon from "@zert-packages/muiadopticons/BlockIcon";

const useStyles = makeStyles((theme) => ({
  treeItemRoot: {
    '& .MuiTreeItem-iconContainer': {
      display: 'none'
    },
    '& .MuiTreeItem-label': {
      paddingLeft: 0
    }
  },
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '20px 20px 1fr 20px'
  },
  imgArrow: {
    height: 15,
    transform: 'rotate(270deg)',
    position: 'relative',
    top: 2
  },
  imgArrowExpend: {
    height: 15,
    display: 'flex',
    alignItems: 'center'
  },
  textTitle: {
    fontSize: 12,
    marginLeft: 3
  }
}));

function TreeItemParentsBranch({ expand, rootNode, setExpanded, selected, structureMain, context }) {
  const cl = useStyles();
  const { versionInfo, formatTemplate } = useSelector((state) => state);
  const dispatch = useDispatch();
  const {setValues } = context ? context : useContext(PanelContext);


  const scrollTop = () => {
    setValues(pre=> ({...pre, scrollTop: true}))
  }

  useEffect(() => {
    if (!formatTemplate) {
      dispatch(getFormatTemplate());
    }
  }, []);


  if(!rootNode){
    return
  }
  // console.log('rootNode', rootNode);
  return (
    <TreeItem
      className={cl.treeItemRoot}
      nodeId="1"
      label={
        <div className={cl.wrapper}>
          <span className={expand.find((item) => +item === 1) ? cl.imgArrowExpend : cl.imgArrow}>
            <ArrowDropDownIcon />
          </span>

          <span>{versionInfo &&
            <BlockIcon
              svg={getPluginByMimeTypeIcon(versionInfo.info.mimeType, true)} />
          }</span>
          <span className={cl.textTitle} style={{ color: +selected === 1 ? '#01A1C7' : '' }} onClick={() => scrollTop()}>
            {versionInfo && versionInfo.info.name}
          </span>
        </div>
      }
    >
      {rootNode && rootNode.map((el, i) => (
        <TreeItemBranch
          context={context}
          key={`${el.elementInfo.versionId} ${i} ${el?.elementInfo?.changedAt}`}
          structureMain={structureMain}
          el={el}
          expand={expand}
          setExpanded={setExpanded}
          selected={selected} />
      ))}
    </TreeItem>
  );
}

export default TreeItemParentsBranch;
