import { handlePostToApi } from '@zert-packages/actions/api';

export function getTermAPI(versionId, locale = 'en') {
  return handlePostToApi(`/termdb/getAllTerms/${locale}/${versionId}`);
}
export function addTerm(termVersionId, termName, locale = 'en') {
  const data = {
    termName,
    locale
  };

  return handlePostToApi(`/termdb/addTerm/${termVersionId}`, data);
}
export function createExport(termVersionId) {
  return handlePostToApi(`/termdb/createExport/${termVersionId}`);
}
export function loadCurrentTerm(versionId, elementId, locale = 'en') {
  return handlePostToApi(`/termdb/loadCurrentTerm/${locale}/${versionId}/${elementId}`);
}
export function addTermSpec(versionId, ownerId, identifier, termName, locale = 'en') {
  return handlePostToApi(`/termdb/addTermSpec/${versionId}`, { identifier, ownerId, termName, locale });
}

export function addTermImage(versionId, ownerId, identifier, imageElementId, locale = 'en') {
  return handlePostToApi(`/termdb/addTermImage/${versionId}`, {
    identifier,
    ownerId,
    versionId: imageElementId,
    locale
  });
}

export function removeTermSpec(versionId, ownerId, elementId, locale = 'en') {
  return handlePostToApi(`/termdb/removeTermSpec/${versionId}`, { elementId, ownerId, locale });
}
export function loadTerms(versionId, phrasesListId) {
  return handlePostToApi(`/termdb/loadTerms/${versionId}`, { phrasesListId });
}
export function removeTerm(versionId, ownerId) {
  return handlePostToApi(`/termdb/removeTerm/${versionId}`, { ownerId });
}
export function exportTermDB(versionId, localesList, reportUUID, format, availiableMetas = {}) {
  return handlePostToApi(`/termdb/export/${versionId}/${reportUUID}`, { localesList, availiableMetas, format });
}
export function getReferences() {
  return handlePostToApi(`/termdb/getReferences`);
}
