import { createAction, createReducer } from '@reduxjs/toolkit';
import makeProjectTree from '../utils/makeProjectTree';

export const updateProjectViewIdAction = createAction('RMP: Updated view id');
export const updateProjectAction = createAction('RMP: Loaded project');
export const createRAAction = createAction('RMP: Create new demand project RiskAsssement');
export const createRAActionDone = createAction('RMP: Create new demand project RiskAsssement and reload everything');
export const makeProjectTreeAction = createAction('RMP: Made project tree');
export const selectNodeAction = createAction('RMP: Selected node');
export const loadCoverageAction = createAction('RMP: Loaded coverage');
export const loadTemplateNewRMPAction = createAction('RMP: Loaded the template to create new RMP');
export const loadLogListAction = createAction('RMP: Loaded log');

export const setRAEntriesAction = createAction('RMP: load ra entries');
export const selectDemandsRA = createAction('RMP: select demands RA');

export const turnOnRMPLoader = createAction('RMP: Global loader is on');
export const turnOffRMPLoader = createAction('RMP: Global loader is off');

export const selectMatrixDemandsItemAction = createAction('RMP: Selected item in Matrix Demands');
export const updateDemandCoverageCellsAction = createAction(`
RMP: Updated Matrix Demand Coverage cells
`);

export const resetEverythingButProject = createAction('RMP: New project has been loaded');
export const resetRMPAction = createAction('RMP: RMP state was reset');

export const updateCopyTreeBufferAction = createAction('RMP: TreeBuffer was updated');

export const selectedNodeInDetailViewAction = createAction(`RMP: Node has been selected`);
export const updateExpandedNodesInDetailViewAction = createAction('RMP: Updated expanded nodes');

export const updateNodeInProjectAction = createAction('RMP: Node has been updated');
export const loadDocumentAction = createAction('RMP: Load Document');

export const resetRMPDocumentAction = createAction('RMP: Reset document');

export const updateStatusSummaryAction = createAction('RMP: Status summary has been updated');

export const initialState = {
  project: null,
  coverage: null,
  isLoading: true,
  projectTree: null,
  viewId: null,
  templateNewRMP: null,
  logList: null,

  isCreateRA: false,
  raEntries: [],
  selectDemandsRA: null,
  demandCoverageCells: null,
  selectedMatrixDemandsItem: null,
  documentsRMP: {},

  copyTreeBuffer: {
    nodeType: null,
    node: null,
    type: null
  },
  openDetailTree: false,

  selectedNodeInDetailView: null,
  expandedNodesInDetailView: [],

  statusSummary: null,

  updateNodeInProject: false // temporarily
};

export const RMPReducer = createReducer(initialState, {
  [selectDemandsRA]: (state, action) => {
    state.selectDemandsRA = action.payload;
  },
  [updateProjectViewIdAction]: (state, action) => {
    state.viewId = action.payload;
  },
  [setRAEntriesAction]: (state, action) => {
    state.raEntries = action.payload;
  },
  [resetRMPAction]: (state, action) => {
    state.project = null;
    state.coverage = null;
    state.projectTree = null;
    state.logList = null;
    state.isLoading = false;
    state.statusSummary = null;
  },
  [resetRMPDocumentAction]: (state, action) => {
    state.documentsRMP = action.payload;
  },
  [loadDocumentAction]: (state, action) => {
    state.documentsRMP = {
      ...state.documentsRMP,
      [action.payload.versionId]: action.payload
    };
  },
  [resetEverythingButProject]: (state, action) => {
    state.coverage = null;
    state.projectTree = null;
    state.logList = null;
    state.isLoading = true;
  },
  [updateProjectAction]: (state, action) => {
    state.project = action.payload;
  },
  [createRAAction]: (state, action) => {
    state.isCreateRA = true;
  },
  [createRAActionDone]: (state, action) => {
    state.isCreateRA = false;
  },
  [makeProjectTreeAction]: (state) => {
    state.projectTree = makeProjectTree(state.project);
  },
  [selectNodeAction]: (state, action) => {
    state.selectedNodeId = action.payload;
  },
  [loadCoverageAction]: (state, action) => {
    state.coverage = action.payload;
  },
  [loadTemplateNewRMPAction]: (state, action) => {
    state.templateNewRMP = action.payload;
  },
  [loadLogListAction]: (state, action) => {
    state.logList = action.payload;
  },
  [selectMatrixDemandsItemAction]: (state, action) => {
    state.selectedMatrixDemandsItem = action.payload;
  },
  [updateDemandCoverageCellsAction]: (state, action) => {
    state.demandCoverageCells = action.payload;
  },

  [turnOnRMPLoader]: (state) => {
    state.isLoading = true;
  },
  [turnOffRMPLoader]: (state) => {
    state.isLoading = false;
  },
  [updateCopyTreeBufferAction]: (state, { payload }) => {
    if (payload.node === null) state.copyTreeBuffer = payload;
    if (state.copyTreeBuffer.node === null) {
      state.copyTreeBuffer = payload;
      return;
    }

    const differentAction = payload.type !== state.copyTreeBuffer.type;
    const differentNode = payload.node.uuid !== state.copyTreeBuffer.node.uuid;

    if (differentAction || differentNode) state.copyTreeBuffer = payload;
  },

  [selectedNodeInDetailViewAction]: () => {},
  [updateExpandedNodesInDetailViewAction]: (state, action) => {
    state.expandedNodesInDetailView = action.payload;
  },

  [updateNodeInProjectAction]: (state) => {
    state.updateNodeInProject = !state.updateNodeInProject;
  },
  [updateStatusSummaryAction]: (state, { payload }) => {
    state.statusSummary = payload;
  }
});
