import { Checkbox, FormControlLabel, Tab, Tabs, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ExpanderItem from "@zert-packages/components/shared/ExpanderItem";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import { TabsContext } from "../../../../Stylesheet";
import { adobeWord, handleAddFormat, handleColumType, handlePageLayoutSingleLevel } from "../../../../utils/helpers";
import Column from "../tabs/Column";
import useStyles from "./styles";
import Format from "../tabs/Format";
import DialogAddReferences from "../../../../dialog/DialogAddReferences";
import DialogAddColumn from "../dialog/DialogAddColumn";
import { addColumnData } from "../../../../data/addColumnData";

function Structure() {
  const cl = useStyles();
  const { values, setValues } = useContext(TabsContext);
  const structure = values.stylesheet.blockSettings[values.selectedLeftMenu];
  const tabs = ["Column", "Format"];
  const columData = [
    "Paragraph number",
    "Paragraph text",
    "Paragraph number and text",
    "Page layout number",
    "Page number",
    "Page layout and page number",
    "User defined"
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  const [selected, setSelected] = useState(0);
  const [selectedColumn, setSelectedColumn] = useState(0);
  const dropMenuScope = [{ name: "Publication" }, { name: "Page layout" }];
  const [textFormat, setTextFormat] = useState([]);



  const columnLength = (structure && structure.paragraphFormats.length > 0 && structure.paragraphFormats[selected].columns )
    ? structure.paragraphFormats[selected].columns.filter((el) => !el.disable).length : 0;


  const columSelected = structure && structure.paragraphFormats.length > 0 && structure.paragraphFormats[selected].columns[selectedColumn];

  const handleChangeSelectedTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSelected = (idx) => {
    setSelected(idx);
    setSelectedTab(0);
    setSelectedColumn(0);
  };

  const deleteColumn = () => {
    if (columnLength === 0) {
      return;
    }
    const result = structure.paragraphFormats[selected].columns.filter((el, i) => i !== selectedColumn);
    handleColumType(result, selected, values, setValues, true);
  };

  const findSelectedColum = (type) => {
    if (type === "right") {
      if (selectedColumn === columnLength - 1 || columnLength === 0) {
        return false;
      }
      return columnLength > 0;
    }
    if (type === "left") {
      if (selectedColumn === 0 || columnLength === 0) {
        return false;
      }
      return columnLength > 0;
    }
  };

  const handleAddColumn = (column) => {
    handleColumType(column, selected, values, setValues);
  };

  const openColumnDialog = () => {
    if (textFormat.length === 0) {
      return;
    }
    renderInExceptionRoot(DialogAddColumn, {
      dropMenu: columData,
      handleAddColumn
    });
  };

  const handleSelectedLeft = () => {
    if (selectedColumn === columnLength - 1 || columnLength === 0) {
      return;
    }
    setSelectedColumn(selectedColumn + 1);
  };
  const handleSelectedRight = () => {
    if (selectedColumn === 0 || columnLength === 0) {
      return;
    }
    setSelectedColumn(selectedColumn - 1);
  };

  const adobeScope = (word) => {
    if (word === "PAGELAYOUT") {
      return "Page layout";
    }
    if (word === "PUBLICATION") {
      return "Page layout";
    }
    return word;
  };

  const handleDropMenu = (valDropMenu, name) => {
    handlePageLayoutSingleLevel(valDropMenu.name, name, values, setValues);
  };

  const handleCheckbox = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    handlePageLayoutSingleLevel(val, name, values, setValues);
  };
  const handleValue = (e) => {
    const val = e.target.value;
    const { name } = e.target;
    handlePageLayoutSingleLevel(val, name, values, setValues);
  };

  const addTextFormat = (paragraph) => {
    handleAddFormat(
      { identifier: paragraph, label: null, columns: [], }, "paragraphFormats", values, setValues);
  };

  const openParagraphDialog = () => {
    renderInExceptionRoot(DialogAddReferences, {
      values: values.stylesheet.paragraphSettings,
      title: "Add text format",
      setValues: addTextFormat
    });
  };

  const deleteParagraph = () => {
    const res = structure.paragraphFormats.filter((el, i) => i !== selected);
    handlePageLayoutSingleLevel(res, "paragraphFormats", values, setValues);
  };
  const handleSelectedColumn = (i) => {
    setSelectedColumn(i);
  };

  const findFormat = () => {
    const result = structure.paragraphFormats.reduce((acu, cur) => {
      const findParagraphSettings = values.stylesheet.paragraphSettings.find((el) => el.identifier === cur.identifier);
      if (findParagraphSettings) {
        return [...acu, findParagraphSettings];
      }
      return [...acu, cur];
    }, []);
    setTextFormat(result);
  };

  const addDefaultColumn = () => {
    handleColumType([addColumnData[0]], selected, values, setValues, true, true);
  };

  useEffect(() => {
    if (structure && structure.class.includes("TableOfContents")) {
      findFormat();
    }
  }, [structure]);
  //
  useEffect(() => {
    if (!columSelected) {
      addDefaultColumn();
    }
  }, []);

  return (
    <>
      {structure && structure.class.includes("TableOfContents") && (
        <div className={cl.root}>
          <div className={cl.wrapperRow}>
            <div className={cl.title}>Scope:</div>
            <ExpanderItem
              valueProps={adobeScope(structure.scope)}
              propsLabel={dropMenuScope}
              type="scope"
              setProps={handleDropMenu}
            />
          </div>
          <div className={cl.wrapperRow}>
            <div />
            <div className={cl.wrapperCheckbox}>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckbox}
                      checked={structure.includingAllLanguages}
                      name="includingAllLanguages"
                    />
                  }
                  label={<span>Include all languages</span>}
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckbox}
                      checked={structure.includePrecedingParagraphs}
                      name="includePrecedingParagraphs"
                    />
                  }
                  label={<span>Include preceding paragraphs</span>}
                />
              </div>
            </div>
          </div>

          <div className={cl.title}>Paragraph formats:</div>
          <FormControlLabel
            control={<Checkbox onChange={handleCheckbox} checked={structure.useUserSetting} name="useUserSetting" />}
            label={<span>Use user settings</span>}
          />

          {structure.useUserSetting && (
            <>
              <div className={cl.title}>XSL template:</div>
              <TextField
                value={structure.userSettingXSL}
                className={cl.templateInput}
                variant="outlined"
                multiline
                minRows={4}
                name="userSettingXSL"
                onChange={handleValue}
              />
            </>
          )}

          <div
            className={cl.blockWrapper}
            style={{
              height: structure.useUserSetting ? "Calc(100% - 310px)" : "Calc(100% - 183px)"
            }}
          >
            <div className={cl.selectedBlock}>
              {textFormat.length > 0 &&
                textFormat.map((el, i) => (
                  <div
                    key={i}
                    className={selected === i ? cl.listItemActive : cl.listItem}
                    onClick={() => handleSelected(i)}
                  >
                    {el.label}
                  </div>
                ))}
            </div>

            <div>
              <div
                className={cl.columnWrapper}
                style={{
                  gridTemplateColumns: Array.from(
                    {length: structure.paragraphFormats.length > 0 ? structure.paragraphFormats[selected].columns.length : 0 },
                    (_, i) => "1fr"
                  ).join(" ")
                }}
              >
                {columSelected &&
                  !columSelected.disable &&
                  structure.paragraphFormats[selected].columns.map((el, i) => (
                    <div
                      key={i}
                      style={{ border: selectedColumn === i ? "2px solid #4db1d3" : "2px solid #C6C6C5" }}
                      onClick={() => handleSelectedColumn(i)}
                      className={cl.columnItem}
                    >
                      {adobeWord(el.content).replaceAll("_", " ")}
                    </div>
                  ))}
              </div>
              <div className={cl.wrapperIcons}>
                <ArrowBackIosNewIcon
                  style={{
                    color: findSelectedColum("left") ? "#4db1d3" : "#C6C6C5",
                    cursor: findSelectedColum("left") ? "pointer" : "default"
                  }}
                  onClick={handleSelectedRight}
                />
                <AddIcon
                  style={{
                    color: textFormat.length > 0 ? "#4db1d3" : "#C6C6C5",
                    cursor: textFormat.length > 0 ? "pointer" : "default"
                  }}
                  onClick={openColumnDialog}
                />
                <DeleteIcon
                  style={{
                    color: columnLength !== 0 ? "#4db1d3" : "#C6C6C5",
                    cursor: columnLength !== 0 ? "pointer" : "default"
                  }}
                  onClick={deleteColumn}
                />
                <ArrowBackIosNewIcon
                  className={cl.rightImg}
                  style={{
                    color: findSelectedColum("right") ? "#4db1d3" : "#C6C6C5",
                    cursor: findSelectedColum("right") ? "pointer" : "default"
                  }}
                  onClick={handleSelectedLeft}
                />
              </div>

              <Tabs
                indicatorColor=""
                value={selectedTab}
                className={cl.tabsRoot}
                onChange={handleChangeSelectedTab}
                textColor="primary"
                variant="standard"
                scrollButtons="auto"
                centered
              >
                {tabs.map((el, i) => (
                  <Tab label={el} key={i} />
                ))}
              </Tabs>
              {selectedTab === 0 && (
                <Column
                  colum={columSelected}
                  index={selectedColumn}
                  indexFormat={selected}
                  columData={columData}
                  disable={columSelected && columSelected.disable}
                />
              )}
              {selectedTab === 1 && (
                <Format
                  colum={columSelected}
                  index={selectedColumn}
                  indexFormat={selected}
                  columData={columData}
                  disable={columSelected && columSelected.disable}
                />
              )}
            </div>
          </div>
          <div className={cl.wrapperEditor}>
            <AddIcon onClick={() => openParagraphDialog()} />
            <DeleteIcon onClick={deleteParagraph} />
          </div>
        </div>
      )}
    </>
  );
}

export default Structure;
