import React, { useEffect } from 'react';

export function useWindowKeydown(callback) {
  useEffect(() => {
    window.addEventListener('keydown', callback);
    return () => {
      window.removeEventListener('keydown', callback);
    };
  });
}

export function useEscape(callback) {
  useWindowKeydown((e) => {
    const escape_Key = 27;
    if (e.keyCode === escape_Key) {
      e.preventDefault();
      callback();
    }
  });
}

export function useCTRL_F(callback) {
  useWindowKeydown((e) => {
    const f_Key = 70;
    if ((e.ctrlKey || e.metaKey) && e.keyCode === f_Key) {
      e.preventDefault();
      callback();
    }
  });
}

/* export function useSearchContainers() {
  const { setExpandEverything } = useContext(IntroductionContext);
  let nodes = [];
  // if (useRouteMatch(checkListPlugin.openpath)) {
  //   nodes.push({ item: document.getElementById('react'),});
  // }
  // if (useRouteMatch(projectPlugin.openpath)) {
  //   nodes.push({ item: document.getElementById('react'), });
  // }
  if (useRouteMatch(riskAnal.openpath)) {
    nodes.push({
      item: document.getElementById('right_panel'),
      open: () => setExpandEverything(true),
    });
  }
  return nodes;
} */
