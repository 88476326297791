import React, { useRef, useState } from 'react';
import Modal from '../Modal/Modal';
import './style.css';
import Slider from '@mui/material/Slider';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import {validateFileType} from "../AddFileButton/AddFileButton";

function ImageUploader({ onChange, onClose, restrictedFileTypes = '' }) {
  const [originalImageFile, setOriginalImageFile] = useState(null);
  const [image, setImage] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const fileInputRef = useRef(null);
  const [cropXRange, setCropXRange] = React.useState([0, 0]);
  const [cropYRange, setCropYRange] = React.useState([0, 0]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (restrictedFileTypes && validateFileType(restrictedFileTypes, file) !== null) {
        return;
      }
      setOriginalImageFile(file);
      useFileAsImage(file);
    }
  };

  const handleReset = () => {
    useFileAsImage(originalImageFile);
  };

  const useFileAsImage = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        setWidth(img.width);
        setHeight(img.height);
        setCropXRange([0, img.width]);
        setCropYRange([0, img.height]);
        setRotation(0);
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.drawImage(img, -img.width / 2, -img.height / 2);
        const dataUrl = canvas.toDataURL('image/jpeg');
        setImage(dataUrl);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleRotate = (degrees) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const { width } = img;
      const { height } = img;
      const angle = degrees * (Math.PI / 180);
      const newWidth = Math.abs(width * Math.cos(angle)) + Math.abs(height * Math.sin(angle));
      const newHeight = Math.abs(width * Math.sin(angle)) + Math.abs(height * Math.cos(angle));
      canvas.width = newWidth;
      canvas.height = newHeight;
      setWidth(Math.floor(newWidth));
      setHeight(Math.floor(newHeight));
      setCropXRange([0, Math.floor(newWidth)]);
      setCropYRange([0, Math.floor(newHeight)]);
      ctx.translate(newWidth / 2, newHeight / 2);
      setRotation((rotation + degrees) % 360);
      ctx.rotate(angle);
      ctx.drawImage(img, -width / 2, -height / 2);
      const dataUrl = canvas.toDataURL('image/jpeg');
      setImage(dataUrl);
    };
    img.src = image;
  };

  const handleXCropRangeChange = (event, newValue) => {
    setCropXRange(newValue);
  };

  const handleYCropRangeChange = (event, newValue) => {
    setCropYRange(newValue);
  };

  const handleCrop = () => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = cropXRange[1] - cropXRange[0];
      canvas.height = cropYRange[1] - cropYRange[0];
      ctx.drawImage(
        img,
        cropXRange[0],
        height - cropYRange[1],
        canvas.width,
        canvas.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
      setWidth(canvas.width);
      setHeight(canvas.height);
      setCropXRange([0, canvas.width]);
      setCropYRange([0, canvas.height]);
      const dataUrl = canvas.toDataURL('image/jpeg');
      setImage(dataUrl);
    };
    img.src = image;
  };

  const handleSubmit = () => {
    if (image) {
      fetch(image)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], originalImageFile.name, { type: 'image/jpeg' });
        onChange(file);
      });
      onClose();
    }
  };

  return (
    <Modal show onClose={() => onClose()} onOK={handleSubmit} showFullSize shouldBeDisabled={image === null}>
      <h4 className="title">
        <FormattedMessage id="imageuploader.title" defaultMessage="Edit image" />
      </h4>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Button className="image-uploader-button" variant="contained" onClick={() => fileInputRef.current.click()}>
          <FormattedMessage id="imageuploader.select.image" defaultMessage="Select image" />
        </Button>
        {restrictedFileTypes ?
          <input type="file" ref={fileInputRef} style={{display: 'none'}} accept={restrictedFileTypes} onChange={handleFileChange}/> :
          <input type="file" ref={fileInputRef} style={{display: 'none'}} onChange={handleFileChange}/>
        }

        {!image ? null : (
          <div>
            <Button className="image-uploader-button" variant="contained" onClick={() => handleRotate(90)}>
              <FormattedMessage id="imageuploader.rotate.clockwise" defaultMessage="Rotate 90° clockwise" />
            </Button>
            <Button className="image-uploader-button" variant="contained" onClick={() => handleRotate(-90)}>
              <FormattedMessage
                id="imageuploader.rotate.counterclockwise"
                defaultMessage="Rotate 90° counterclockwise"
              />
            </Button>
            <Button className="image-uploader-button" variant="contained" onClick={handleCrop}>
              <FormattedMessage id="imageuploader.crop" defaultMessage="Crop" />
            </Button>
            <Button className="image-uploader-reset-button" variant="contained" onClick={handleReset}>
              <FormattedMessage id="imageuploader.reset" defaultMessage="Reset" />
            </Button>
          </div>
        )}
        {!height || !width ? null : (
          <div
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}
          >
            <div>
              <label className="image-uploader-label">
                Height: <span className="image-uploader-number">{height}</span>px
              </label>
            </div>
            <div>
              <label className="image-uploader-label">
                Width: <span className="image-uploader-number">{width}</span>px
              </label>
            </div>
          </div>
        )}
      </div>
      <div style={{ height: '100%' }}>
        {image && (
          <div style={{ height: '100%' }}>
            <div style={{ height: '90%', backgroundColor: '#f4f8fa', display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', height: `${height}px`, width: `${width + 50}px` }}>
                <img src={image} alt="Uploaded image" />
                <div
                  style={{ height: `${height}px`, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                >
                  <Slider
                    sx={{
                      '& input[type="range"]': {
                        WebkitAppearance: 'slider-vertical'
                      }
                    }}
                    orientation="vertical"
                    value={cropYRange}
                    onChange={handleYCropRangeChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={height}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <FormattedMessage id="imageuploader.crop.yrange" defaultMessage="Height to maintain when cropping" />
                </div>
              </div>
              <div style={{ width: `${width}px`, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Slider
                  value={cropXRange}
                  onChange={handleXCropRangeChange}
                  valueLabelDisplay="auto"
                  min={0}
                  max={width}
                />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  <FormattedMessage id="imageuploader.crop.xrange" defaultMessage="Width to maintain when cropping" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ImageUploader;
