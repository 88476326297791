import makeStyles from '@mui/styles/makeStyles';
import ExpanderItem from '@zert-packages/components/shared/ExpanderItem';
import React, { useContext, useEffect, useState } from 'react';
import { adobeWord, handleTabsValue } from '../../utils/helpers';
import { TabsContext } from '../../Stylesheet';
import SpacingTemplate from '../blockTabs/topic/SpacingTemplate';
import TemplateItemRow from '../../TemplateItemRow';
import Columns from './Columns';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10
  },
  title: {
    fontSize: '1rem',
    fontWeight: '700',
    marginBottom: 0
  },
  borderBlock: {
    border: '1px solid #C6C6C5',
    padding: '5px 10px',
    display: 'grid',
    gridRowGap: 10,
    marginTop: 5
    // height: '100%',
  },
  wrapperRowTitle: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gridColumnGap: 10,
    maxHeight: 27,
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    }
  }
}));

function Content({ part }) {
  const cl = useStyles();
  const breakDropMenu = ['Auto', 'Column', 'Page', 'Even page', 'Odd page'];
  const { values, setValues } = useContext(TabsContext);
  const [maxImageWidth, setMaxImageWidth] = useState('User defined');

  const handleContentType = (val, name) => {
    handleTabsValue(val.toUpperCase().replace(' ', '_'), name, 'partSettings', values, setValues);
  };

  const handleSpacing = (val, name) => {
    const res = {
      ...part[name],
      value: {
        ...part[name].value,
        unit: part[name].value ? part[name].value.unit : 'pt',
        value: +val
      }
    };
    handleTabsValue(res, name, 'partSettings', values, setValues);
  };
  const handleSpacingMenu = (valDrop, name, val) => {
    const res = { ...part[name], value: { unit: valDrop, value: +val } };
    handleTabsValue(res, name, 'partSettings', values, setValues);
  };

  const handleSpacingBefore = (val, name, nameObject) => {
    const res = { ...part[name], [nameObject]: val };
    handleTabsValue(res, name, 'partSettings', values, setValues);
  };

  const handleValuePropsDialogAfter = (val, name) => {
    const res = {
      ...part.spaceAfter,
      [name]: {
        unit: part.spaceAfter[name] ? part.spaceAfter[name].unit : 'pt',
        value: +val
      }
    };
    handleTabsValue(res, 'spaceAfter', 'partSettings', values, setValues);
  };

  const setDropMenuPropsDialogAfter = (valDrop, name, val) => {
    const res = {
      ...part.spaceAfter,
      [name]: {
        unit: valDrop,
        value: +val
      }
    };
    handleTabsValue(res, 'spaceAfter', 'partSettings', values, setValues);
  };

  const setDropMenuPrecedenceAfter = (val) => {
    const res = { ...part.spaceAfter, precedence: val.name };
    handleTabsValue(res, 'spaceAfter', 'partSettings', values, setValues);
  };

  const handleValuePropsDialogBefore = (val, name) => {
    const res = {
      ...part.spaceBefore,
      [name]: {
        unit: part.spaceBefore[name] ? part.spaceBefore[name].unit : 'pt',
        value: +val
      }
    };
    handleTabsValue(res, 'spaceBefore', 'partSettings', values, setValues);
  };

  const setDropMenuPropsDialogBefore = (valDrop, name, val) => {
    const res = {
      ...part.spaceBefore,
      [name]: {
        unit: valDrop,
        value: +val
      }
    };
    handleTabsValue(res, 'spaceBefore', 'partSettings', values, setValues);
  };

  const setDropMenuPrecedenceBefore = (val) => {
    const res = { ...part.spaceBefore, precedence: val.name };
    handleTabsValue(res, 'spaceBefore', 'partSettings', values, setValues);
  };

  const handleValueDialogSpaceAfterImage = (val, name) => {
    const res = {
      ...part.spaceAfterImage,
      [name]: {
        unit: part.spaceAfterImage[name] ? part.spaceAfterImage[name].unit : 'pt',
        value: +val
      }
    };
    handleTabsValue(res, 'spaceAfterImage', 'partSettings', values, setValues);
  };

  const setDropMenuPropsDialogSpaceAfterImage = (valDrop, name, val) => {
    const res = {
      ...part.spaceAfterImage,
      [name]: {
        unit: valDrop,
        value: +val
      }
    };
    handleTabsValue(res, 'spaceAfterImage', 'partSettings', values, setValues);
  };

  const setDropMenuPrecedenceSpaceAfterImage = (val) => {
    const res = { ...part.spaceAfterImage, precedence: val.name };
    handleTabsValue(res, 'spaceAfterImage', 'partSettings', values, setValues);
  };

  const handleValues = (val, name) => {
    const res = { value: +val, unit: part[name] ? part[name].unit : 'POINTS' };
    handleTabsValue(res, name, 'partSettings', values, setValues);
  };
  const handleSelectedDropMenu = (valDropMenu, name, val) => {
    const res = { value: +val, unit: valDropMenu };
    handleTabsValue(res, name, 'partSettings', values, setValues);
  };
  const setDropMenuFirst = (val, name) => {
    if (val === 'No') {
      const res = { value: 12, unit: 'POINTS' };
      handleTabsValue(res, name, 'partSettings', values, setValues);
    }
    setMaxImageWidth(val);
  };

  useEffect(() => {
    if (!part.maxImageWidth) {
      setMaxImageWidth('No');
    }
  }, [values.selectedLeftMenu]);

  return (
    <>
      <div className={cl.wrapper}>
        <div>
          <p className={cl.title}>Text flow</p>
          <div className={cl.borderBlock}>
            <SpacingTemplate
              title="Space before:"
              type="spaceBefore"
              valueProps={part.spaceBefore}
              grid="100px 150px 1fr 55px"
              setValueProps={handleSpacing}
              setDropMenu={handleSpacingMenu}
              handleBefore={handleSpacingBefore}
              setValuePropsDialog={handleValuePropsDialogBefore}
              setDropMenuPropsDialog={setDropMenuPropsDialogBefore}
              setDropMenuPrecedence={setDropMenuPrecedenceBefore}
            />

            <SpacingTemplate
              title="Space after:"
              type="spaceAfter"
              valueProps={part.spaceAfter}
              grid="100px 150px 1fr 55px"
              setValueProps={handleSpacing}
              setDropMenu={handleSpacingMenu}
              handleBefore={handleSpacingBefore}
              setValuePropsDialog={handleValuePropsDialogAfter}
              setDropMenuPropsDialog={setDropMenuPropsDialogAfter}
              setDropMenuPrecedence={setDropMenuPrecedenceAfter}
            />
            <div className={cl.wrapperRowTitle}>
              <p className={cl.title}>Break before: </p>
              <ExpanderItem
                valueProps={adobeWord(part.breakBefore, true).replace('_', ' ')}
                propsLabel={breakDropMenu}
                type="breakBefore"
                setProps={handleContentType}
              />
            </div>
            <div className={cl.wrapperRowTitle}>
              <p className={cl.title}>Break after: </p>
              <ExpanderItem
                valueProps={adobeWord(part.breakAfter, true).replace('_', ' ')}
                propsLabel={breakDropMenu}
                type="breakAfter"
                setProps={handleContentType}
              />
            </div>
          </div>
        </div>
        <div>
          <p className={cl.title}>Images</p>
          <div className={cl.borderBlock}>
            <TemplateItemRow
              label="Max width:"
              type="maxImageWidth"
              dropMenuFirst={{
                label: ['No', 'User defined'],
                selected: maxImageWidth
              }}
              value={{
                value: part.maxImageWidth ? part.maxImageWidth.value : '12',
                unit: part.maxImageWidth ? part.maxImageWidth.unit : 'POINTS'
              }}
              grid="100px 150px 1fr 55px"
              setValueProps={handleValues}
              setDropMenuFirst={setDropMenuFirst}
              setDropMenu={handleSelectedDropMenu}
              disabled={maxImageWidth === 'No'}
            />
            <SpacingTemplate
              title="Space after:"
              type="spaceAfterImage"
              valueProps={part.spaceAfterImage}
              grid="100px 150px 1fr 55px"
              setValueProps={handleSpacing}
              setDropMenu={handleSpacingMenu}
              handleBefore={handleSpacingBefore}
              setValuePropsDialog={handleValueDialogSpaceAfterImage}
              setDropMenuPropsDialog={setDropMenuPropsDialogSpaceAfterImage}
              setDropMenuPrecedence={setDropMenuPrecedenceSpaceAfterImage}
            />
          </div>
        </div>
      </div>
      <Columns part={part.columns} />
    </>
  );
}
export default Content;
