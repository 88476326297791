import React, { Fragment } from 'react';
import { ICONS } from '@zert-packages/icons/SvgIcon/SvgIconConstant';

const isOnDoneActivity = (identifier, workflowtemplate) => {
  const doneActivity = workflowtemplate.doneActivities.find(function (activity) {
    if (identifier && identifier.toLowerCase() === activity.id.toLowerCase()) {
      return true;
    }
  });
  return doneActivity !== undefined;
};

export const getStatusIcon = (measure, workflowtemplate) => {
  if (measure.status && measure.status.archived) {
    return <Fragment key={measure.status.versionid}>{ICONS.ARCHIVED_MEASURE.file}</Fragment>;
  }
  if (
    measure.currentTaskActivityId &&
    measure.currentTaskActivityId.toLowerCase() === workflowtemplate.startActivity.id.toLowerCase()
  ) {
    return <Fragment key={measure.status.versionid}>{ICONS.PLANNED_MEASURE.file}</Fragment>;
  }
  if (measure.currentTaskActivityId && isOnDoneActivity(measure.currentTaskActivityId, workflowtemplate)) {
    return <Fragment key={measure.status.versionid}>{ICONS.APPROVED_MEASURE.file}</Fragment>;
  }
  return <Fragment key={measure.status.versionid}>{ICONS.ONGOING_MEASURE.file}</Fragment>;
};
