import React, { useCallback, useState } from 'react';

function useClientRect() {
  const [state, setState] = useState(null);
  const ref = useCallback((node) => {
    if (node !== null) setState(node.getBoundingClientRect());
  }, []);
  return [ref, state];
}

export default useClientRect;
