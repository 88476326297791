import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  ExpandIcon: {
    '&.MuiAccordionSummary-root': {
      minHeight: 48
    },
    '& .MuiAccordionSummary-expandIcon': (props) => ({
      transform: props.expanded[props.id] ? 'rotate(180deg)' : 'rotate(270deg)'
    })
  },
  rootAccordion: {
    borderRadius: '0px !important',
    borderBottom: '1px solid #E2F2F6',
    flex: '0 1 auto',
    '&.Mui-expanded': {
      margin: 0
    },
    '& .MuiCollapse-entered': {
      height: 'calc(100% - 48px) !important'
    }
  },
  AccordionDetailsRoot: {
    padding: '6px 6px 6px 16px',
    display: 'block',
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    },
    position: 'relative'
  },
  AccordionDetailsTitle: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));

function InfoExpander({ expanded, setExpanded, id, title, children }) {
  const classes = useStyles({ expanded, id });

  const handleChange = () => {
    setExpanded({ ...expanded, [id]: !expanded[id] });
  };

  return (
    <Accordion expanded={expanded[id]} onChange={() => handleChange()} className={classes.rootAccordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.ExpandIcon}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails className={classes.AccordionDetailsRoot}>{children}</AccordionDetails>
    </Accordion>
  );
}

export default InfoExpander;
