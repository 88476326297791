export const normalData = {
  class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientNormalImageSettingsTemplate',
  identifier: 'simple',
  label: 'Simple',
  fontFamily: 'zert-category-heading',
  fontSize: null,
  fontStyle: ['ITALIC'],
  lineHeight: {
    class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientConditionType',
    tagName: 'line-height',
    resetValue: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
      type: 'DEFAULT',
      value: {
        value: 12,
        unit: 'POINTS'
      }
    },
    defaultValue: {
      class: 'se.zert.clm.rest.stylesheet.pojo.ClientStylesheet$ClientLineHeight',
      type: 'SINGLE',
      value: {
        value: 12,
        unit: 'POINTS'
      }
    },
    valueCount: 0
  },
  color: null,
  backgroundColor: {
    id: -1,
    metadataList: null,
    references: {},
    properties: {},
    parents: [],
    approvedAt: null,
    archivedAt: null,
    approvedBy: null,
    archivedBy: null,
    changedBy: null,
    changedAt: null,
    createdBy: null,
    createdAt: null,
    name: null,
    translatable: false,
    checkedOut: false,
    readOnly: false,
    checkoutByUser: null,
    published: false,
    locale: null,
    label: null,
    mimeType: null,
    versionId: -1,
    additionalInfo: null,
    companyId: 0,
    versionUUID: null,
    elementUUID: null,
    currentVersionId: -1,
    cmyk: true,
    alpha: 255,
    blue: 255,
    red: 255,
    green: 255
  },
  spaceBefore: {
    name: 'before',
    conditionality: 'DISCARD',
    value: null,
    minimum: null,
    maximum: null,
    precedence: 'DEFAULT'
  },
  spaceAfter: {
    name: 'after',
    conditionality: 'DISCARD',
    value: null,
    minimum: null,
    maximum: null,
    precedence: 'DEFAULT'
  },
  borderTop: {
    edge: 'top',
    width: {
      value: 0,
      unit: 'POINTS'
    },
    style: 'NONE',
    color: {
      id: -1,
      metadataList: null,
      references: {},
      properties: {},
      parents: [],
      approvedAt: null,
      archivedAt: null,
      approvedBy: null,
      archivedBy: null,
      changedBy: null,
      changedAt: null,
      createdBy: null,
      createdAt: null,
      name: null,
      translatable: false,
      checkedOut: false,
      readOnly: false,
      checkoutByUser: null,
      published: false,
      locale: null,
      label: null,
      mimeType: null,
      versionId: -1,
      additionalInfo: null,
      companyId: 0,
      versionUUID: null,
      elementUUID: null,
      currentVersionId: -1,
      cmyk: false,
      alpha: 255,
      blue: 0,
      red: 0,
      green: 0
    }
  },
  borderBottom: {
    edge: 'bottom',
    width: {
      value: 0,
      unit: 'POINTS'
    },
    style: 'NONE',
    color: {
      id: -1,
      metadataList: null,
      references: {},
      properties: {},
      parents: [],
      approvedAt: null,
      archivedAt: null,
      approvedBy: null,
      archivedBy: null,
      changedBy: null,
      changedAt: null,
      createdBy: null,
      createdAt: null,
      name: null,
      translatable: false,
      checkedOut: false,
      readOnly: false,
      checkoutByUser: null,
      published: false,
      locale: null,
      label: null,
      mimeType: null,
      versionId: -1,
      additionalInfo: null,
      companyId: 0,
      versionUUID: null,
      elementUUID: null,
      currentVersionId: -1,
      cmyk: false,
      alpha: 255,
      blue: 0,
      red: 0,
      green: 0
    }
  },
  borderLeft: {
    edge: 'left',
    width: {
      value: 0,
      unit: 'POINTS'
    },
    style: 'NONE',
    color: {
      id: -1,
      metadataList: null,
      references: {},
      properties: {},
      parents: [],
      approvedAt: null,
      archivedAt: null,
      approvedBy: null,
      archivedBy: null,
      changedBy: null,
      changedAt: null,
      createdBy: null,
      createdAt: null,
      name: null,
      translatable: false,
      checkedOut: false,
      readOnly: false,
      checkoutByUser: null,
      published: false,
      locale: null,
      label: null,
      mimeType: null,
      versionId: -1,
      additionalInfo: null,
      companyId: 0,
      versionUUID: null,
      elementUUID: null,
      currentVersionId: -1,
      cmyk: false,
      alpha: 255,
      blue: 0,
      red: 0,
      green: 0
    }
  },
  borderRight: {
    edge: 'right',
    width: {
      value: 0,
      unit: 'POINTS'
    },
    style: 'NONE',
    color: {
      id: -1,
      metadataList: null,
      references: {},
      properties: {},
      parents: [],
      approvedAt: null,
      archivedAt: null,
      approvedBy: null,
      archivedBy: null,
      changedBy: null,
      changedAt: null,
      createdBy: null,
      createdAt: null,
      name: null,
      translatable: false,
      checkedOut: false,
      readOnly: false,
      checkoutByUser: null,
      published: false,
      locale: null,
      label: null,
      mimeType: null,
      versionId: -1,
      additionalInfo: null,
      companyId: 0,
      versionUUID: null,
      elementUUID: null,
      currentVersionId: -1,
      cmyk: false,
      alpha: 255,
      blue: 0,
      red: 0,
      green: 0
    }
  },
  imageAlign: 'NONE',
  paddingTop: {
    value: 0,
    unit: 'POINTS'
  },
  paddingBottom: {
    value: 0,
    unit: 'POINTS'
  },
  paddingLeft: {
    value: 0,
    unit: 'POINTS'
  },
  paddingRight: {
    value: 0,
    unit: 'POINTS'
  },
  imageLabel: 'VERSION_ID',
  labelPosition: 'LEFT',
  labelFontFamily: 'default',
  labelFontSize: {
    value: 6,
    unit: 'POINTS'
  },
  labelFontStyle: [],
  labelColor: null,
  inlineWidth: null,
  inlineHeight: null,
  inlineKeepRatio: 'FALSE',
  inlineVerticalAlign: 'BOTTOM',
  inlineVerticalAlignDistance: null,
  maxWidth: null,
  maxHeight: null
};
