import React, { useContext } from "react";
import InfoElementWrapper, { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import Loader from "@zert-packages/components/shared/Loader/Loader";

import { useParams } from "react-router-dom";
import ImgTypeWrapper from "./ImgTypeWrapper";

const ImageView = () => {
    return (<InfoElementWrapper>
        <ImageWrapper />
    </InfoElementWrapper>);
};

export default ImageView;

const ImageWrapper = () => {
    const { values, setValues } = useContext(PanelContext);
    const el = values?.parentBlock && { ...values.parentBlock, elementInfo: values.parentBlock.info }
    const { versionId } = useParams();

    if (!el ||  +el.info.versionId !== +versionId) {
        return (
            <span>
        <Loader displayText={false} />
      </span>
        );
    }

    return (<div style={{marginTop: 5}}>
        <ImgTypeWrapper
            // setBlock={setBlock}
            el={el}
            vue={false}
        />
    </div>)
};