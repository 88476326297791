import { handlePost, handleQuery, matrixImage } from '@zert-packages/actions/api';
import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';

import { renderInExceptionRoot } from '@zert-packages/utils';
import getTranslation from '@zert-packages/utils/getTranslation.new';
import ActionableEditableTable from '@zert-packages/components/shared/ActionableTable/ActionEditableTable.new';
import TextFieldNumberWithMemo from '@zert-packages/components/common/TextFieldNumberWithMemo';

import SelectedColor from '@zert-packages/components/common/dialogs/ReportDialogs/utils/SelectedColor';
import TextFieldWithLanguageSwitchValue from '@zert-packages/components/common/TextFieldWithLangaugeSwitchValue';
import ConfirmationDialog from '@zert-packages/components/common/dialogs/ConfirmationDialog';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import Loader from '@zert-packages/components/shared/Loader/Loader';
import { useSelector } from 'react-redux';
import { loadColumnsAPI } from '@zert-packages/components/common/dialogs/ReportDialogs/API';
export function RiskRowGroupButton({ unmountComponent, onConfirm, onCancel, selected }) {
  return (
    <Button
      color="primary"
      onClick={() => {
        renderInExceptionRoot(RiskRowGroupDialog, { selected, onConfirm });
      }}
    >
      <FormattedMessage
        id="RiskRowGroupDialog.AlternativesTable.RiskRowGroupButton"
        defaultMessage="Select risk row groups"
      />
    </Button>
  );
}

export function RiskRowGroupDialog({ unmountComponent, onConfirm, onCancel, selected }) {
  const [rows, setRows] = useState([]);
  const { rrtemplates: templates } = useSelector((state) => state);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [checked, setChecked] = useState(true);
  const getDisplayValue = (field) => (id) => getTranslation(rows[id][field]);
  const onChange = (identifier) => (rowId) => (event) => {
    const newValue = event.target ? event.target.value : event;
    setRows((prevState) => prevState.map((row, index) => (index == rowId ? { ...row, [identifier]: newValue } : row)));
  };
  const tableColumns = [
    {
      identifier: 'minValue',
      label: <FormattedMessage id="RiskRowGroupDialog.AlternativesTable.min" defaultMessage="Min" />,
      editor: TextFieldNumberWithMemo,
      onChange: onChange('minValue'),
      style: { width: 50 }
    },
    {
      identifier: 'maxValue',
      label: <FormattedMessage id="RiskRowGroupDialog.AlternativesTable.max" defaultMessage="Max" />,
      editor: TextFieldNumberWithMemo,
      onChange: onChange('maxValue'),
      style: { width: 50 }
    },
    {
      identifier: 'color',
      label: <FormattedMessage id="RiskRowGroupDialog.AlternativesTable.Color" defaultMessage="Color" />,
      editor: SelectedColor,
      onChange: onChange('color')
    },

    {
      identifier: 'label',
      label: <FormattedMessage id="RiskRowGroupDialog.AlternativesTable.Label" defaultMessage="Label" />,
      editor: TextFieldWithLanguageSwitchValue,
      onChange: onChange('label'),
      displayValue: getDisplayValue('label')
    }
  ];

  const handleConfirm = () => {
    unmountComponent();
    onConfirm(rows);
  };

  const handleCancel = () => {
    unmountComponent();
    // onCancel();
  };
  useEffect(() => {
    if (selected && 'rrGroupButton' in selected && selected.rrGroupButton.length) {
      setRows(selected.rrGroupButton);
      setLoading(false);
      setChecked(false);
    } else {
      loadColumns();
    }
  }, []);

  const addElement = () => {
    const { length } = rows;
    setRows((p) => [
      ...p,
      {
        minValue: 0,
        maxValue: 1,
        color: '#FFFFF',
        label: {
          defaultLabel: '0 - 1',
          locales: ['en', 'sv'],
          translations: ['0 - 1', '0 - 1']
        },
        id: p.length
      }
    ]);
    setSelectedRow(length);
  };

  const handleCheckbox = (event) => {
    const res = event.target.checked;
    if (res) {
      setLoading(true);
      setRows([]);
      setChecked(res);
      loadColumns();
    } else {
      setChecked(res);
    }
  };

  const removeValue = () =>
    renderInExceptionRoot(ConfirmationDialog, {
      onConfirm: () => {
        setRows((p) => {
          const newP = [...p];
          newP.splice(selectedRow, 1);
          return newP;
        });
        // setSelectedMeta(defaultSelectedMeta);
      }
    });

  const loadColumns = async () => {
    const rowsList = await loadColumnsAPI(selected, templates);

    setRows(rowsList.map((row, index) => ({ ...row, id: index })));
    setLoading(false);
  };

  return (
    <Dialog open onClose={unmountComponent}>
      <DialogTitle>
        <FormattedMessage id="RiskRowGroupDialog.Title" defaultMessage="Risk Group " />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage
            id="RiskRowGroupDialog.Description"
            defaultMessage="In diagrams risk will be collected into the following groups."
          />
        </DialogContentText>
        <DialogContentText>
          <FormControlLabel
            control={<Checkbox onChange={handleCheckbox} checked={checked} />}
            label={
              <FormattedMessage
                id="RiskRowGroupDialog.Automatically"
                defaultMessage="Automatically generate Risk Groups"
              />
            }
          />
        </DialogContentText>
        {loading ? (
          <Loader />
        ) : (
          <ActionableEditableTable
            actions={[
              {
                icon: AddIcon,
                onClick: addElement,
                disabled: checked || loading
              },

              {
                icon: DeleteIcon,
                onClick: removeValue,
                disabled: checked || selectedRow == -1
              }
            ]}
            columns={checked ? tableColumns.map((column) => ({ ...column, editor: null })) : tableColumns}
            rows={rows}
            selectedRowId={selectedRow}
            setSelectedRowId={setSelectedRow}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary" disabled={loading}>
          <FormattedMessage id="RiskRowGroupDialog.buttonOk" defaultMessage="Ok" />
        </Button>
        <Button onClick={unmountComponent} color="primary" variant="outlined" autoFocus>
          <FormattedMessage id="RiskRowGroupDialog.buttonCancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
