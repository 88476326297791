import React, { useState } from "react";
import { Dialog } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import InputItem from "../InputItem";
import DropMenu from "@zert-packages/components/DropMenu";
import { TextFieldWithLanguageSwitchMemo } from "@zert-packages/components/common/TextFieldWithLangaugeSwitch";
import handleValueState from "@zert-packages/utils/handleValueState";
import getTranslation from "@zert-packages/utils/getTranslation.old";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 482,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700
  },
  wrapper: {
    display: "grid",
    gridRowGap: 10
  },
  row: {
    display: "grid",
    gridTemplateColumns: "80px 1fr"
  },
  wrapperLanguage: {
    display: "grid",
    gridTemplateColumns: "80px 1fr",
    "& button": {
      padding: 9,
      position: "relative",
      bottom: 4
    },
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginBottom: 0
    }
  },
  label: {
    fontWeight: 900
  },
  wrapperButton: {
    marginTop: 10,
    display: "flex",
    justifyContent: "end",
    paddingRight: 20
  },
  button: {
    color: "#ffffff",
    background: "#4db1d3",
    marginLeft: 10,
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

const style = {
  display: "flex",
  flexDirection: "row-reverse"
};

const DialogListItem = ({ label, items, setSetting, index, addItem, setSelectSettings }) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState(addItem ? items[0] :  items[index]);
 const labelObject  = value.labelObject || {
    defaultLabel: "",
    locales: ["en", "sv"],
    translations: ["", ""]
  }


  const handleState = (value, name) => {
    handleValueState(value, name, setValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    handleClose();
    setSetting(pre => ({
      ...pre,
      selected: {
        ...pre.selected,
        items: addItem
          ? [...pre.selected.items, value]
          : pre.selected.items.reduce((acu, cur, idx) => {
            if (idx === index) {
              return [...acu, value];
            }
            return [...acu, cur];
          }, [])
      }
    }));
    if(addItem){
      setSelectSettings(items.length +2)
    }
  };

  const adobeSelected = (val) => {
    if (val) {
      return "Yes";
    }
    return "No";
  };

  const adobeSelectedSave = (type) => {
    if (type === "Yes") {
      return true;
    }
    return false;
  };

  const handleSelected = (val) => {
    const res = adobeSelectedSave(val);
    handleState(res, "selected");
  };

  const updateName = (value) => {
    setValue(pre => ({ ...pre, labelObject: value, label: value }));
  };


  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{ paperScrollPaper: cl.root }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>{label && label}</span>
      <CloseIcon onClick={handleClose} />
    </div>
    <div className={cl.wrapper}>
      <div className={cl.wrapperLanguage}>
        <div className={cl.label}>Label:</div>
        <TextFieldWithLanguageSwitchMemo
          state={labelObject}
          disabled={false}
          onChange={updateName}
          style={style}
        />
      </div>
      <InputItem
        classStyle={cl.row}
        label={"Value"}
        valueProps={value?.value}
        setValueProps={handleState}
        Identifier="value"
      />
      <div className={cl.row}>
        <div className={cl.label}>Type:</div>
        <DropMenu
          selected={adobeSelected(value.selected)}
          dropItem={["No", "Yes"]}
          setDropItem={handleSelected}
          name={"selected"}
        />
      </div>
    </div>

    <div className={cl.wrapperButton}>
      <Button
        disabled={!(0 !== getTranslation(labelObject).length) }
        className={cl.button}
        onClick={handleSave}
      >
        OK
      </Button>
      <Button className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>
  </Dialog>);
};

export default DialogListItem;
