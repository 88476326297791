import React from 'react';
import PropTypes from 'prop-types';

require('./style.css');

function SvgIcon({ icon, fill, width, height, className, fillRule, notvisible, background, padding, paddingLeft }) {
  return (
    <svg
      style={{
        display: notvisible ? 'none' : 'block',
        backgroundColor: background || 'none',
        padding: padding || 'none'
      }}
      className={className}
      width={width || icon.width}
      height={height || icon.height}
      viewBox={icon.viewBox}
    >
      <path style={{ fill: fill || '#1BA1C5' }} d={icon.path} fillRule={fillRule} />
    </svg>
  );
}

SvgIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  notvisible: PropTypes.bool,
  background: PropTypes.string,
  padding: PropTypes.string
};

export default SvgIcon;
