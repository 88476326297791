import React from "react";
import { Breadcrumbs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import handleValueState from "@zert-packages/utils/handleValueState";

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#000000',
    cursor: 'pointer',
    fontSize: 12,
    '&:hover': {
      color: '#4db1d3',
    }
  }
}))


const BreadCrumbs = ({items,  setBrowse}) => {
  const cl = useStyles();

  if(items?.length === 0){
    return
  }
  const handleCrumbs = (el) => {
    handleValueState(el, 'activeBreadCrumbs', setBrowse, )
  }

  return(<div>
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      {items.map((el , i) => <div className={cl.link} key={i} onInput={() => handleCrumbs(el)}>
        {el.name}
      </div>)}
    </Breadcrumbs>
  </div>)
}

export default BreadCrumbs