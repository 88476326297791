import { FormattedMessage } from 'react-intl';
import React from 'react';

export const generalCheckBoxes = [
  {
    name: 'cb_include_archived',
    label: <FormattedMessage id="reports.riskassesment.includeArchied" defaultMessage="Include archived" />,
    section: "scope",
    checked: false
  },
  {
    name: 'cb_separateImages',
    label: (
      <FormattedMessage id="reports.riskassesment.separateImages" defaultMessage="Generate separate pages for images" />
    ),
    checked: false
  }
];
