import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import { TabsContext } from '../../../Stylesheet';
import GroupBlock from '../../blockTabs/pageLayout/GroupBlock';
import SelectedColor from '../../../SelectedColor';
import { adobeColor, adobeRgba, handleTableSingleLevel } from "../../../utils/helpers";
import DialogColors from '../../../dialog/DialogColors';
import TemplateItemRow from '../../../TemplateItemRow';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: 10
  },
  wrapperCheckbox: {
    display: 'grid',
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    },
    border: '1px solid #C6C6C5',
    padding: '7px 10px 20px',
    height: 'Calc(100% - 26px)'
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10
  },
  title: {
    fontSize: '1rem',
    fontWeight: '700',
    marginBottom: 5
  },
  borderBlock: {
    border: '1px solid #C6C6C5',
    padding: '5px 10px'
  }
}));

function General({ table }) {
  const { values, setValues } = useContext(TabsContext);
  const cl = useStyles();
  const [color, setColor] = useState({
    backgroundColor: '#ffffff',
    load: false,
  })

  const spacingToContentsProps = [
    { label: 'Left:', value: table.paddingLeft && table.paddingLeft, type: 'paddingLeft' },
    { label: 'Right:', value: table.paddingRight && table.paddingRight, type: 'paddingRight' },
    { label: 'Top:', value: table.paddingTop && table.paddingTop, type: 'paddingTop' },
    { label: 'Bottom:', value: table.paddingBottom && table.paddingBottom, type: 'paddingBottom' }
  ];

  const indentProps = [
    { label: 'Left:', value: table.marginLeft && table.marginLeft, type: 'marginLeft' },
    { label: 'Right:', value: table.marginRight && table.marginRight, type: 'marginRight' }
  ];

  const handleCheckbox = (e) => {
    const val = e.target.checked;
    const { name } = e.target;
    handleTableSingleLevel(val, name, 'tableSettings', values, setValues);
  };

  const handleValuesBlock = (val, name) => {
    handleTableSingleLevel(val, name, 'tableSettings', values, setValues, 'value');
  };

  const handleSelectedDropMenu = (valDropMenu, name, val) => {
    handleTableSingleLevel(val, name, 'tableSettings', values, setValues, 'drop', valDropMenu);
  };

  const handleColor = (val, name) => {
    setColor(pre => ({ ...pre, backgroundColor:  val}))
    handleTableSingleLevel(adobeRgba(val), name, 'tableSettings', values, setValues);
  };

  const handleOpenDialog = (type) => {
    renderInExceptionRoot(DialogColors, {
      color: table[type],
      title: 'Select Color',
      setText: handleColor,
      type
    });
  };

  const [selectedContentLimits, setSelectedContentLimits] = useState({
    widowContentLimit: 'User defined',
    orphanContentLimit: 'User defined'
  });

  const setDropMenuFirst = (val, name) => {
    if (val === 'Default') {
      handleTableSingleLevel(12, name, 'tableSettings', values, setValues, 'drop', 'pt');
    }
    setSelectedContentLimits((pre) => ({ ...pre, [name]: val }));
  };

  const findContentLimits = () => {
    if (!table.widowContentLimit && !table.orphanContentLimit) {
      return setSelectedContentLimits({ widowContentLimit: 'Default', orphanContentLimit: 'Default' });
    }
    if (!table.widowContentLimit) {
      return setSelectedContentLimits((pre) => ({ ...pre, widowContentLimit: 'Default' }));
    }
    if (!table.orphanContentLimit) {
      setSelectedContentLimits((pre) => ({ ...pre, orphanContentLimit: 'Default' }));
    }
  };

  useEffect(() => {
    if(!color.load && table.backgroundColor){
      setColor(pre => ({
        ...pre,
        backgroundColor: adobeColor(table.backgroundColor),
        load: true}))
    }
  }, [table.backgroundColor])

  useEffect(() => {
    findContentLimits();
  }, [table]);

  return (
    <div className={cl.root}>
      <div className={cl.row}>
        <div>
          <p className={cl.title}>Text flow</p>
          <div className={cl.wrapperCheckbox}>
            <FormControlLabel
              control={<Checkbox onChange={handleCheckbox} checked={table.spanAll} name="spanAll" />}
              label={<span>Span all columns</span>}
            />
            <FormControlLabel
              control={<Checkbox onChange={handleCheckbox} checked={table.hideEmptyRows} name="hideEmptyRows" />}
              label={<span>Hide empty rows</span>}
            />
            <FormControlLabel
              control={<Checkbox onChange={handleCheckbox} checked={table.hideEmptyColumns} name="hideEmptyColumns" />}
              label={<span>Hide empty columns</span>}
            />
          </div>
        </div>
        <GroupBlock
          label="Spacing to contents"
          valueProps={spacingToContentsProps}
          setValueProps={handleValuesBlock}
          setDropMenu={handleSelectedDropMenu}
        />
      </div>

      <div>
        <p className={cl.title}>Decorations</p>
        <div className={cl.borderBlock}>
          <SelectedColor
            label="Background color:"
            type="backgroundColor"
            setValueProps={handleColor}
            handleOpenDialog={handleOpenDialog}
            valueProps={color.backgroundColor}
            adobe={true}
          />
        </div>
      </div>
      <GroupBlock
        label="Indent"
        valueProps={indentProps}
        setValueProps={handleValuesBlock}
        setDropMenu={handleSelectedDropMenu}
        style={{ padding: '5px 10px' }}
      />
      <div>
        <p className={cl.title}>Content limits (FOP only)</p>
        <div className={cl.borderBlock}>
          <div style={{ marginBottom: 10 }}>
            <TemplateItemRow
              label="Widow:"
              type="widowContentLimit"
              value={{
                value: table.widowContentLimit && table.widowContentLimit.value ? table.widowContentLimit.value : '12',
                unit: table.widowContentLimit && table.widowContentLimit.unit ? table.widowContentLimit.unit : 'POINTS'
              }}
              dropMenuFirst={{
                label: ['Default', 'User defined'],
                selected: selectedContentLimits.widowContentLimit
              }}
              grid="100px 150px 1fr 55px"
              setDropMenuFirst={setDropMenuFirst}
              setDropMenu={handleSelectedDropMenu}
              setValueProps={handleValuesBlock}
              disabled={selectedContentLimits.widowContentLimit === 'Default'}
            />
          </div>
          <TemplateItemRow
            label="Orphan:"
            type="orphanContentLimit"
            value={{
              value: table.orphanContentLimit && table.orphanContentLimit.value ? table.orphanContentLimit.value : '12',
              unit: table.orphanContentLimit && table.orphanContentLimit.unit ? table.orphanContentLimit.unit : 'POINTS'
            }}
            dropMenuFirst={{
              label: ['Default', 'User defined'],
              selected: selectedContentLimits.orphanContentLimit
            }}
            grid="100px 150px 1fr 55px"
            setDropMenuFirst={setDropMenuFirst}
            setDropMenu={handleSelectedDropMenu}
            setValueProps={handleValuesBlock}
            disabled={selectedContentLimits.orphanContentLimit === 'Default'}
          />
        </div>
      </div>
    </div>
  );
}

export default General;
