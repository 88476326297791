export const tableData = {
    "elementInfo": {
        // "id": 100265662,
        "metadataList": null,
        "references": {},
        "properties": {
            "zert:MimeInheritance": "'application/zert-table'"
        },
        "parents": [],
        "approvedAt": null,
        "archivedAt": null,
        "approvedBy": null,
        "archivedBy": null,
        "changedBy": "zert-komatsu",
        "changedAt": "2024-09-24 10:57:29",
        "createdBy": "zert-komatsu",
        "createdAt": "2024-09-24 10:57:29",
        "name": null,
        "translatable": false,
        "checkedOut": false,
        "readOnly": false,
        "checkoutByUser": null,
        "published": false,
        "locale": "sv_SE",
        "label": "1.1",
        "mimeType": "application/zert-table",
        // "versionId": 2403395,
        "additionalInfo": null,
        // "companyId": 1592653,
        "versionUUID": "10438605-94ba-724b-74b2-de448e50ab41",
        "elementUUID": "10438604-3d1d-4036-aecb-e64de7a8ea02",
        "latestApprovedChecklistDateForSafetyInspection": null,
        // "currentVersionId": 2403395
    },
    "name": "",
    // "targetLocale": {
    //     "language": "sv",
    //     "country": "SE",
    //     "variant": "",
    //     "displayName": "шведська (Швеція)"
    // },
    "format": "data",
    "formatSettings": {
        "template": {
            "properties": []
        },
        "settings": []
    },
    "columns": [],
    "internalContent": [],
    "internalSpanmap": [],
    "cellsBackgroundColors": [],
    "rowsBackgroundColors": [],
    "rowsKeepTogethers": [],
    "rowCount": 2,
    "columnCount": 1,
    "aliasValues": {}
}