import React from 'react'
import {makeStyles} from "@mui/styles";
import {Checkbox, FormControlLabel} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'grid',
        gridTemplateColumns: '150px 1fr',
        gridColumnGap: 5,
        '& label': {
            margin: 0,
        },
        '& .MuiButtonBase-root': {
            padding: 5,
        }
    },
}))

const CheckboxSettings = ({meta, setMeta}) => {
    const cl = useStyles();

    const handleCheckbox = (e) => {
        const val = e.target.checked
        const name = e.target.name
        setMeta(pre=> ({...pre, [name]: val}))
    }

    return(<>
        <div className={cl.row}>
            <div></div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={handleCheckbox}
                            name="inheritable"
                            checked={meta.inheritable}
                        />
                    }
                    label={<span>Inherit from parent</span>}
                />
            </div>
        </div>

        <div className={cl.row}>
            <div></div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={handleCheckbox}
                            name="copiedToNewVersions"
                            checked={meta.copiedToNewVersions}
                            disabled={meta.mode === 1}
                        />
                    }
                    label={<span>Copy metadata to new versions</span>}
                />
            </div>
        </div>

        <div className={cl.row}>
            <div></div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={handleCheckbox}
                            name="unique"
                            checked={meta.unique}
                        />
                    }
                    label={<span>Metadata can only be used once</span>}
                />
            </div>
        </div>

        <div className={cl.row}>
            <div></div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={handleCheckbox}
                            name="group"
                            checked={meta.group}
                            disabled={meta.mode === 0}
                        />
                    }
                    label={<span>Use as content group</span>}
                />
            </div>
        </div>

        <div className={cl.row}>
            <div></div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={handleCheckbox}
                            name="security"
                            checked={meta.security}
                        />
                    }
                    label={<span>Use as security metadata</span>}
                />
            </div>
        </div>
    </>)
}

export default CheckboxSettings