import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import withSearch, { controlStyles, styles } from '../withSearch';
import SearchComponent from './SearchComponent';

class TranslatableSearch extends SearchComponent {
  constructor(props) {
    super(props);
    if (!this.state || !this.state.Translatable) {
      this.state = { Translatable: true };
    }
    this.textInput = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  onChangeState = (field, value) => {
    this.setStatus({ Translatable: value });
  };

  handleChange = (name) => (event) => {
    this.setStatus({ [name]: event.target.value });
  };

  onSubmit = (e) => {
    if (this.props.startSearchOnSubmit) this.props.startSearchOnSubmit(e);
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    this.props.expanded && this.textInput.current ? this.textInput.current.blur() : this.textInput.current.focus();
  }

  render() {
    const { classes } = this.props;
    //   this.props.handleChange(this.state);
    return (
      <form onSubmit={this.onSubmit} style={{ width: '100%' }}>
        <FormControlLabel
          autoFocus
          inputRef={this.textInput}
          value="Translatable"
          control={
            <Radio
              color="primary"
              checked={this.state.Translatable}
              onChange={(event) => this.onChangeState('Translatable', true)}
              value={this.state.Translatable}
              name="Translatable"
              label={<FormattedMessage id="search.translatable" defaultMessage="Translatable" />}
            />
          }
          label="Translatable"
        />
        <FormControlLabel
          value="Not Translatable"
          control={
            <Radio
              color="primary"
              checked={!this.state.Translatable}
              onChange={(event) => this.onChangeState('Translatable', false)}
              value={!this.state.Translatable}
              name="Not Translatable"
              label={<FormattedMessage id="search.nottranslatable" defaultMessage="Not translatable" />}
            />
          }
          label="Not Translatable"
        />
      </form>
    );
  }
}

TranslatableSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

withSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

const selectData = {
  header: <FormattedMessage id="search.translatable.header" defaultMessage="Search if translatable" />,
  index: 'translatable',
  className: 'se.zert.backend.search.TranslatableCriteria',
  default: {
    Translatable: true
  }
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(
  withStyles(styles)(withSearch(withStyles(controlStyles)(TranslatableSearch), selectData))
);
